import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { BaseComponent } from '../../../../../core/abstracts/baseComponent';
import { State } from '../../../../../core/store';
import { getEmployeeReview, getEmployeeReviewSuccess } from '../../../../../core/store/employee-review/employee-review.actions';
import { deepClone, isBright } from '../../../../../helpers/utils';
import { EmployeeReviewOversightLoadModel, EmployeeTemplateReviews } from '../../../../../models/employeeReviewModels';
import { AlertService } from '../../../../../shared/services/alert.service';
import { Location } from '@angular/common';
import { GeneralSettingsModel } from 'src/app/models/generalSettingsModel';

@Component({
  selector: 'app-employee-review-view',
  templateUrl: './employee-review-view.component.html',
  styleUrls: ['./employee-review-view.component.scss']
})
export class EmployeeReviewViewComponent extends BaseComponent implements OnInit {

  oversightData: EmployeeReviewOversightLoadModel;
  loading: boolean;
  reviewId: number;
  templateReviews: EmployeeTemplateReviews;
  generalSettings: GeneralSettingsModel;

  constructor(
    private store: Store<State>,
    private actions$: Actions,
    private route: ActivatedRoute,
    private location: Location,
    private alertService: AlertService) {

    super();
  }

  ngOnInit() {
    this.loading = false;
    this.oversightData = {
      canReview: false,
      employeeFirstName: "",
      employeeLastName: "",
      oversightUserID: 0,
      templateReviews: [],
      userID: 0,
      isOpen: false,
      canApprove: false,
      canSeeApproval: false
    };
    this.templateReviews = {
      reviews: [],
      template: {
        description: "",
        kras: [],
        requiresOversightReview: false
      },
      templateID: 0
    }
    this.reviewId = 0;
    this.subs.push(
      this.store.select(s => s.accountState.generalSettings).subscribe(generalSettings => {
        if (generalSettings) {
          this.generalSettings = generalSettings;
        }
      }),
      this.actions$.pipe(
        ofType(getEmployeeReviewSuccess),
        tap(action => {
          this.oversightData = deepClone(action.review);
          if (this.oversightData.templateReviews.length > 0)
            this.templateReviews = this.oversightData.templateReviews[0];
        })
      ).subscribe()
    );
    this.route.params.subscribe(params => {
      this.reviewId = parseInt(params.reviewId);
      if (this.reviewId) {
        this.store.dispatch(getEmployeeReview({ reviewId: this.reviewId }));
      }
    });
  }

  getAnswer(review, measureable) {
    if (review.answers.some(s => s.value == measureable.measureableId && s.selected))
      return "Yes";
    return "No";
  }

  back() {
    this.location.back()
  }

  blackOrWhite(color: string) {
    if (color && !isBright(color)) {
      return '#FFFFFF';
    } else {
      return '#000000';
    }
  }
}
