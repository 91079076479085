import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { CriticalNewsPostModel, NewsFeedFilterModel } from 'src/app/models/criticalNewsFeedModel';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { ActivatedRoute, Router } from '@angular/router';
import { filterFeed, loadPinnedPosts, loadPosts, loadUserPinnedPosts, refreshFeed, resetPosts, setRefreshFilter, setScrollLoading, startRefreshing, stopRefreshing } from 'src/app/core/store/critical-news-feed/critical-news-feed.action';
import { deepClone } from 'src/app/helpers/utils';
import { AlertService } from 'src/app/shared/services/alert.service';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-critical-news-feed-view',
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({transform: 'translateY(-1%)', opacity: 0}),
          animate('350ms', style({transform: 'translateY(0)', opacity: 1}))
        ]),
        transition(':leave', [
          style({transform: 'translateY(0)', opacity: 1}),
          animate('350ms', style({transform: 'translateY(-1%)', opacity: 0}))
        ])
      ]
    )
  ],
  templateUrl: './critical-news-feed-view.component.html',
  styleUrls: ['./critical-news-feed-view.component.scss']
})
export class CriticalNewsFeedViewComponent extends BaseComponent implements OnInit {

  @ViewChild('newsFeed') newsFeed: ElementRef;
  @ViewChild('myCalendar') datePicker;

  @HostListener('document:click', ['$event']) onDocumentClick(event) {
    this.showFeedFilter = false;
  }
  
  posts: CriticalNewsPostModel[] = null;
  filter: NewsFeedFilterModel;
  scrollLoading: boolean;
  totalPosts: number = 0;
  totalPostsLoaded: number = null;
  userPinnedPosts: CriticalNewsPostModel[] = null;
  pinnedPosts: CriticalNewsPostModel[] = null;
  showFeedFilter: boolean = false;
  viewLength: number = 0;
  showScrollTop: boolean = false;
  isIframe: boolean = false;
  filterType: string = "User";
  filterValue: string;
  sortBy: string;
  filterDateString: string[];
  filterDateDisplay: string = null;
  showPinnedPosts: boolean = true;
  showUserPinnedPosts: boolean = false;
  lastSyncedDate: Date = new Date();
  isFiltered: boolean = false;
  showJustPinned: boolean = false;
  //Permissions
  canPost: boolean = false;
  canView: boolean = false;
  manageAll: boolean = false;
  getScreenWidth: number;
  showFeed: boolean = true;
  
  constructor(private actions$: Actions,
    private router: Router,
    private store: Store<State>,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    ) {
    super();
  }

  ngOnInit(): void {
    let comp = this.activatedRoute.snapshot;
    if (comp.url.length == 0) { // or whatever your "home" route is
      this.posts = [];
      this.store.dispatch(resetPosts());
    }
    let newFilter = {
      viewLength: 0,
      filterType: "",
      filterValue: "",
      dateFilterValues: [],
      sortBy: "",
      syncDate: this.lastSyncedDate.toISOString(),
    }
    this.store.dispatch(setRefreshFilter({filter: newFilter}))
    this.store.dispatch(startRefreshing());
    this.subs.push(
      this.store.select(s => s.criticalNewsFeedState.PostsToLoad).subscribe(posts => {
        if(this.posts == null){
          this.posts = [];
        }
        if (posts != null && posts.result != null){
          this.posts = this.posts.concat(posts.result);
        }
      }),
      this.store.select(s => s.criticalNewsFeedState.AllPosts).subscribe(posts => {
        if(posts != null){
          this.viewLength = posts.length;
        }
        
        if(this.router.url.endsWith('criticalnewsfeed')){
          this.isIframe = true;
        }
      }),
      this.store.select(s => s.criticalNewsFeedState.totalFilteredPosts).subscribe(total => {
        if(total != null){
          this.totalPosts = total;
        }
      }),
      this.store.select(s => s.criticalNewsFeedState.pinnedPosts).subscribe(posts => {
        if(this.pinnedPosts == null){
          this.pinnedPosts = [];
        }
        if (posts != null){
          this.pinnedPosts = posts;
        }
      }),
      this.store.select(s => s.criticalNewsFeedState.userPinnedPosts).subscribe(posts => {
        if(this.userPinnedPosts == null){
          this.userPinnedPosts = [];
        }
        if (posts != null){
          this.userPinnedPosts = posts;
        }
      }),
      this.store.select(s => s.criticalNewsFeedState.totalPostsLoaded).subscribe(totalPostsLoaded => {
        if (totalPostsLoaded != null){
          this.totalPostsLoaded = totalPostsLoaded;
        }
      }),
      this.store.select(s => s.criticalNewsFeedState.PostJustAdded).subscribe(post => {
        if(post != null){
          if (this.posts != null){
            let updatedPosts = deepClone(this.posts);
            updatedPosts.unshift(post);
            this.posts = updatedPosts;
          }
          else{
            this.posts = [post];
          }
        }
      }),
      this.store.select(s => s.criticalNewsFeedState.postJustArchived).subscribe(post => {
        if(post != null){
          if (this.posts != null){
            this.posts = this.posts.filter(x => x.id != post.id);
            this.pinnedPosts = this.pinnedPosts.filter(x => x.id != post.id);
            this.userPinnedPosts = this.userPinnedPosts.filter(x => x.id != post.id);
            this.totalPostsLoaded = this.totalPostsLoaded - 1;
            this.totalPosts = this.totalPosts - 1;
          }
        }
      }),
      this.store.select(s => s.criticalNewsFeedState.postJustEdited).subscribe(post => {
        if(post != null){
          let postToUpdate= deepClone(this.posts);
          postToUpdate = postToUpdate.filter(x => x.id == post.id);
          let postIndex = this.posts.findIndex(x => x.id === postToUpdate[0].id);
          if (postIndex !== -1){
            this.posts[postIndex] = post;
          }
        }
      }),
      this.store.select(s => s.criticalNewsFeedState.scrollLoading).subscribe(loading => {
        this.scrollLoading = loading;
      }),
      this.store.select(s => s.criticalNewsFeedState.filterFeedPosts).subscribe(posts => {
          this.posts = posts;
      }),
      this.store.select(s => s.criticalNewsFeedState.filter).subscribe(filter => {
        this.filter = filter;
      }),
      this.store.select(s => s.criticalNewsFeedState.refreshFeedPosts).subscribe(posts => {
        if(posts != null && posts.length > 0){
        let pinnedExist = false;
        let newPosts = [];
          for (let post of posts){
            if(post.isPinned){
              pinnedExist = true;
            }
            if(post.archived){
              var updatedPosts = this.posts.filter(x => x.id !== post.id); 
              this.posts = updatedPosts;
              this.totalPostsLoaded = this.totalPostsLoaded - 1;
              this.totalPosts = this.totalPosts - 1;
              continue;
            }
            let postToUpdate= this.posts.filter(x => x.id == post.id);
            if (postToUpdate != null && postToUpdate.length > 0){
              let i = this.posts.indexOf(postToUpdate[0]);
                this.posts[i] = post;
            }
            else{
              this.showScrollTop = true;
              newPosts.unshift(post);
              setTimeout(()=>{this.showScrollTop = false}, 10000);
            }
          }
          if (newPosts != null && newPosts.length > 0){
            this.posts = [...newPosts, ...this.posts];
          }
          if (pinnedExist){
            this.store.dispatch(loadPinnedPosts());
          }
        }
      }),
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.canPost = permissionIDs && !!permissionIDs.find(p => p === 9502 || p === 9500);
        this.canView = permissionIDs && !!permissionIDs.find(p => p === 9501 || p === 9500 || p === 9505 || p === 9504 || p === 9503 || p === 9502);
        this.manageAll = permissionIDs && !!permissionIDs.find(p => p === 9500);
      }),
    );
    
    setTimeout(()=>{this.showPinnedPosts = false}, 6000);

    this.store.dispatch(loadPosts({filter: this.filter}));
    this.store.dispatch(loadPinnedPosts());
    this.store.dispatch(loadUserPinnedPosts());

  }
  
  onScroll(event: any) {
    if(this.totalPostsLoaded != null && this.totalPosts == this.totalPostsLoaded && this.scrollLoading){
      this.store.dispatch(setScrollLoading({loading: false}))
      return;
    }
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !this.scrollLoading) {
      let newFilter = {
        viewLength: this.viewLength,
        filterType: this.filter.filterType,
        filterValue: this.filter.filterValue,
        sortBy: this.filter.sortBy,
        dateFilterValues: this.filterDateString,
        syncDate: this.filter.syncDate,
      }
      this.store.dispatch(setRefreshFilter({filter: newFilter}))
      if(this.totalPostsLoaded != null && this.totalPosts != this.totalPostsLoaded && !this.scrollLoading){
        this.store.dispatch(loadPosts({filter: newFilter}))
        this.store.dispatch(setScrollLoading({loading: true}))
      }
    }
  }

  filterTypeChanged(){
    this.filterValue = "";
  }

  scrollTop(){
    setTimeout(() => {this.newsFeed.nativeElement.scrollIntoView( 0, 0 )}, 0);
    this.showScrollTop = false;
  }

  refreshFeed() {
    let newFilter = deepClone(this.filter);
    newFilter.syncDate = this.lastSyncedDate.toISOString();
    this.store.dispatch(refreshFeed({filter: newFilter}))
    this.lastSyncedDate = new Date();
  }

  showFilter(e){
    this.showFeedFilter = !this.showFeedFilter;
    e.stopPropagation();
  }

  close() {
    this.datePicker.overlayVisible = false;
  }

  closeFilter(e){
    this.showFeedFilter = false;
    this.filterDateString = null;
    e.stopPropagation();
  }
  
  stopPropagation(e) {
    e.stopPropagation();
  }

  togglePinnedPosts(){
    this.showPinnedPosts = !this.showPinnedPosts;
  }

  toggleUserPinnedPosts(){
    this.showUserPinnedPosts = !this.showUserPinnedPosts;
  }

  filterPosts(e){
    this.scrollTop();
    if (this.showJustPinned){
      this.showFeed = false;
      return;
    }
    else{
      this.showFeed = true;
      if ((this.filterValue == null || this.filterValue == "") && (this.filterDateString == null || this.filterDateString.length < 1)
          && (this.sortBy == null || this.sortBy == "") && !this.showJustPinned){
              this.alertService.error("Enter a Filter or Sorting Value in order to filter the feed")
              return;
            }
    }
    let newFilter = {
      viewLength: this.viewLength,
      filterType: this.filterType,
      filterValue: this.filterValue,
      dateFilterValues: this.filterDateString,
      sortBy: this.sortBy,
      syncDate: this.filter.syncDate,
    }
    if (this.filterDateString != null && this.filterDateString.length > 0){
      this.filterDateDisplay = (this.filterDateString[0] != null ? this.filterDateString[0] : "") + (this.filterDateString[1] != null ? ' - ' + this.filterDateString[1] : "")
    }
    else{
      this.filterDateDisplay = null;
    }
    this.store.dispatch(setRefreshFilter({filter: newFilter}))
    this.store.dispatch(filterFeed({filter: newFilter}))
    this.isFiltered = true;
    this.filterDateString = null;
    this.showPinnedPosts = false;
    this.showUserPinnedPosts = false;
    this.showFeedFilter = false;
    e.stopPropagation();
  }

  clearFilterPosts(e){
    this.filterType = "User";
    this.filterValue = null;
    this.sortBy = null;
    this.filterDateString = null;
    this.isFiltered = false;
    this.showJustPinned = false;
    this.showFeed = true;
    this.filterDateDisplay = null;
    let newFilter = {
      viewLength: this.viewLength,
      filterType: this.filterType,
      filterValue: this.filterValue,
      dateFilterValues: this.filterDateString,
      sortBy: this.sortBy,
      syncDate: this.filter.syncDate,
    }

    this.store.dispatch(setRefreshFilter({filter: newFilter}))
    this.store.dispatch(filterFeed({filter: newFilter}))
    this.showFeedFilter = false;
    e.stopPropagation();
  }
  
  ngOnDestroy(): void {
   this.store.dispatch(stopRefreshing()); 
  }
}
