import { downloadFile } from 'src/app/helpers/utils';
import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { AlertService } from 'src/app/shared/services/alert.service';
import { OnBoardingDocumentUserModel } from 'src/app/models/onBoardingDocumentUserModel';
import { Router, ActivatedRoute } from '@angular/router';
import { getOnboardingDocsList, loadOnboardingDoc } from 'src/app/core/store/onboardingdocs/onboardingdocs.actions';

@Component({
  selector: 'app-list-my-onboardingdocs',
  templateUrl: './list-my-onboardingdocs.component.html',
  styleUrls: ['./list-my-onboardingdocs.component.scss']
})
export class ListMyOnboardingDocsComponent extends BaseComponent implements OnInit {

  onboardingDocs: OnBoardingDocumentUserModel[];
  loadingOnboardingDocsList = false;
  @Input() managedUserId;
  seeOldDocs=false;

  constructor(private actions$: Actions,
    private alertService: AlertService,
    private store: Store<State>,
    private router: Router,
    private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    if (this.route.snapshot.parent.params.userid) {
      this.managedUserId = this.route.snapshot.parent.params.userid;
    }
    this.store.dispatch(getOnboardingDocsList({ userId: this.managedUserId }));
    this.subs.push(
      this.store.select(s => s.onBoardingDocsState.docsList).subscribe(onboardingDocs => {
        this.onboardingDocs = onboardingDocs;
      }),

      this.store.select(s => s.onBoardingDocsState).subscribe(onBoardingDocsState => {
        this.loadingOnboardingDocsList = onBoardingDocsState.loadingList || onBoardingDocsState.loadingDoc;
      }),

    );

  }

  sign(onboardingDocsUser: OnBoardingDocumentUserModel) {
    this.router.navigate(['../../onboardingdocs/sign/' + onboardingDocsUser.id], { relativeTo: this.route });
  }

  fillInfo(onboardingDocsUser: OnBoardingDocumentUserModel) {
    this.router.navigate(['../../onboardingdocs/fill-info/' + onboardingDocsUser.id], { relativeTo: this.route });
  }

  fillInfoHR(onboardingDocsUser: OnBoardingDocumentUserModel) {
    this.router.navigate(['../../onboardingdocs/fill-info-hr/' + onboardingDocsUser.id], { relativeTo: this.route });
  }

  viewDoc(onboardingDocsUser: OnBoardingDocumentUserModel) {
    this.store.dispatch(loadOnboardingDoc({ onboardingDocUserId: onboardingDocsUser.id }));
    const subDocBuffer = this.store.select(s => s.onBoardingDocsState.docArrayBuffer).subscribe(docArrayBuffer => {
      if (docArrayBuffer) {
        downloadFile(new Blob([docArrayBuffer], { type: 'application/pdf' }), onboardingDocsUser.onBoarding_Document.fileName);
        subDocBuffer.unsubscribe();
      }
    });
  }

}

