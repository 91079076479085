<div class="card mt-2">
  <div class="card-body">
    <h1 class="mb-3">Staffs Injuries Queue</h1>
    <form>
      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Staff involved:</h5>
        </div>
        <div class="col-10">
          <input type="text" class="form-control ml-2 w-100 mb-2" placeholder="All" [(ngModel)]="filterStaffInvolved" [ngModelOptions]="{standalone: true}" />
        </div>
      </div>
      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Reported By:</h5>
        </div>
        <div class="col-10">
          <input type="text" class="form-control ml-2 w-100 mb-2" placeholder="All" [(ngModel)]="filterReportedBy" [ngModelOptions]="{standalone: true}" />
        </div>
      </div>
      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Sent By:</h5>
        </div>
        <div class="col-10">
          <input type="text" class="form-control ml-2 w-100 mb-2" placeholder="All" [(ngModel)]="filterSentBy" [ngModelOptions]="{standalone: true}" />
        </div>
      </div>
      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Show Completed:</h5>
        </div>
        <div class="col-10">
          <input type="checkbox" class="form-control ml-2 w-100 mb-2" [(ngModel)]="filterShowCompleted" [ngModelOptions]="{standalone: true}" />
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Sent After Date:</h5>
        </div>
        <div class="col-10">
          <input type="date" class="form-control ml-2 w-100 mb-2" [(ngModel)]="filterSentAtStartDate" [ngModelOptions]="{standalone: true}" />
        </div>
      </div>

      <div class="row">

        <div class="col-2 d-flex align-items-center">
          <h5>Sent Before Date:</h5>
        </div>
        <div class="col-10">
          <input type="date" class="form-control ml-2 w-100 mb-2" [(ngModel)]="filterSentAtEndDate" [ngModelOptions]="{standalone: true}" />
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Occurred After Date:</h5>
        </div>
        <div class="col-10">
          <input type="date" class="form-control ml-2 w-100 mb-2" [(ngModel)]="filterOccurredStartDate" [ngModelOptions]="{standalone: true}" />
        </div>
      </div>

      <div class="row">

        <div class="col-2 d-flex align-items-center">
          <h5>Occurred Before Date:</h5>
        </div>
        <div class="col-10">
          <input type="date" class="form-control ml-2 w-100 mb-2" [(ngModel)]="filterOccurredEndDate" [ngModelOptions]="{standalone: true}" />
        </div>
      </div>

      <div class="row mb-2">
        <div class="col">
          <button class="btn btn-primary" *ngIf="!loadingQueue" (click)="reload()">Reload</button>
          <i *ngIf="!!loadingQueue" class="material-icons rotate text-muted">loop</i>
        </div>
      </div>

    </form>
    <p-table [value]="injuriesQueue" [responsive]="true"
             [paginator]="true" [rows]="20" [totalRecords]="injuriesQueue?.length">
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 100px"></th>
          <th style="width: 200px">Staffs involved</th>
          <th style="width: 100px">Occurred Date</th>
          <th style="width: 100px">Sent Date</th>
          <th style="width: 150px">Sent By</th>
          <th style="width: 150px">Reported By</th>
          <th style="width: 110px">Location</th>
          <th style="width: 100px">Treatment</th>
          <th>Description</th>
          <th>Notes</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-staffinjury>
        <tr>
          <td>
            <div class="d-flex">
              <i (click)="openInOpto(staffinjury.incidentReportID)" style="cursor:pointer" title="Open Incident Report in Optomiser" class="m-auto fas fa-external-link-alt"></i>
              <i (click)="openUpdateModal(staffinjury)" style="cursor:pointer" title="Update Staff Injury Status" class="m-auto fas fa-edit"></i>
              <i *ngIf="!staffinjury.doneAt" (click)="markAsResolved(staffinjury.id)" style="cursor:pointer" title="Flag as resolved" class="m-auto fas fa-check-circle"></i>
              <i *ngIf="staffinjury.doneAt" title="Resolved" class="m-auto fas fa-check-circle text-success"></i>
              <i *ngIf="staffinjury.fileName && downloadingInvoiceId != staffinjury.id" style="cursor:pointer" title="Download Invoice" (click)="downloadInvoice(staffinjury)" class="m-auto fas fa-download"></i>
              <i *ngIf="downloadingInvoiceId == staffinjury.id" title="Downloading" class="material-icons rotate text-muted">loop</i>

            </div>
          </td>
          <td>
            <div [innerHTML]="staffinjury.staffInvolved_Names | stringJoin"></div>
          </td>
          <td>
            {{staffinjury.dateOcurred| date:'shortDate' }}
          </td>
          <td>
            {{staffinjury.sentAt | date:'shortDate' }}
          </td>
          <td>
            {{staffinjury.sentBy_Name }}
          </td>
          <td>
            {{staffinjury.reportedBy_Name}}
          </td>
          <td>
            {{staffinjury.location}}
          </td>
          <td>
            <span *ngIf="staffinjury.hadMedClinicTreatment" title="Had Med Clinic Treatment" class="text-success">Primary Care</span>
            <span *ngIf="staffinjury.hadWorkMedTreatment" title="Had Work Med Treatment" class="text-warning">Work Med</span>
          </td>
          <td>
            {{staffinjury.incidentReportDescription}}
          </td>
          <td>
            {{staffinjury.notes}}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>


  <p-dialog header="Staff Injury" [modal]="true" appendTo="body" [style]="{width: '70vw'}" [(visible)]="displayStaffInjury">
    <app-update-staff-injury></app-update-staff-injury>
  </p-dialog>


</div>
