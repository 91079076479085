<h1>Upload Documents</h1>
<div class="row">
  <div class="col-12 mt-2 text-center" *ngIf="loadingVaccineCardExemption || loadingUploadedFiles || uploadingDoc"   >
      <i class="material-icons rotate text-muted ml-2">loop</i>
  </div>
  <ng-container *ngIf="!(loadingVaccineCardExemption || loadingUploadedFiles || uploadingDoc)">
    <div  class="col-12 mt-2" *ngFor="let uploadType of uploadTypes">
      <div class="d-flex  align-items-center" [id]="'doctype'+uploadType.id" >
        <h3>{{uploadType.docType}} {{isOptional(uploadType.docType)?'(Optional)':'' }}  </h3>

        <div *ngIf="!readonly">
          &nbsp;&nbsp;&nbsp; Uploaded &nbsp;
          <i class="fas fa-check-circle text-success" *ngIf="!!uploadedFilesPerType[uploadType.id.toString()] "></i>
          <i class="fas fa-dot-circle" *ngIf="! (uploadedFilesPerType[uploadType.id.toString()])"></i>
          &nbsp;&nbsp;&nbsp; Approved &nbsp;
          <i class="fas fa-check-circle text-success" *ngIf="allFilesApproved(uploadType.id)"></i>
          <i class="fas fa-dot-circle" [class.pointer]="managedUserId" *ngIf="! (allFilesApproved(uploadType.id))"></i>
        </div>
      </div>
      <div *ngIf="uploadType.docType=='Vaccine Card'" style="font-weight: 500;">
        <span> You can request an exemption for the Vaccine Card on the section bellow</span>
      </div>


      <div class="mb-3" *ngIf="uploadType.docType.toString() === 'Driving Record'">
        <a href="https://secure.utah.gov/mvr-personal/" target="_blank" rel="noopener noreferrer">Get your driving record here!</a>
       </div>
      <div class="mt-3 mb-3" *ngIf="!uploadedFilesPerType[uploadType.id.toString()]">No files uploaded</div>

      <div class="row mt-3 mb-3 p-0 ml-0 mr-0 w-100 d-flex" *ngIf="uploadedFilesPerType[uploadType.id.toString()]">
        <ng-container *ngFor="let file of uploadedFilesPerType[uploadType.id.toString()]; let i = index;">
          <div class="col-4 mt-4 text-center" *ngIf="uploadType.docType!='Vaccine Card Exemption' || (managedUserId || !file.vaccineCardExemption.removedDate)" >
            <i *ngIf="deletingOnBoardingUploadId==file.id" class="material-icons rotate text-muted ml-2">loop</i>
            <ng-container *ngIf="deletingOnBoardingUploadId!=file.id && !(file.vaccineCardExemption && !managedUserId && file.vaccineCardExemption.removedDate)">

              <div id="mobilePhoto" style="font-size: 14px">Click to View {{file.files.length}} Doc(s)</div>
              <div *ngIf="!readonly" id="computerPhoto" class="w-100">
                <div *ngIf="!hasUploadOnlyPermission" class="mb-2 p-0">
                  <i class="fas fa-check-circle text-success pointer ml-2 mr-2" title="Approved" *ngIf="file.approved"></i>
                  <i class="fas fa-times-circle text-danger pointer ml-2 mr-2" [title]="'Rejected ('+file.denyMessage+')'" *ngIf="file.denyMessage && !file.vaccineCardExemption?.removedDate"></i>
                  <i class="fas fa-trash-alt pointer ml-2 mr-2" title="Click here to remove this file" (click)="deleteUploadDoc(file)" *ngIf="(!file.approved && !managedUserId) || hasPermissionToDeleteDocs"></i>
                  <i class="fas fa-check-circle text-primary pointer ml-2 mr-2" title="Click to approve this file" (click)="approveFile(file)" *ngIf="managedUserId && !file.approved && (!file.vaccineCardExemption?.removedDate)"></i>
                  <i class="fas fa-times-circle text-primary pointer ml-2 mr-2" title="Click to reject this file" (click)="openCloseDenyMessage(file)" *ngIf="managedUserId && !file.denyMessage"></i>
                  <div *ngIf="getDenyInfo(file).denyMessageOpen" class="d-inline-flex ml-1">
                    <input type="text" class="form-control ml-2" [(ngModel)]="denyInfo[file.id].denyMessageToSend" />
                    <button class="btn btn-secondary ml-2 " [disabled]="!getDenyInfo(file).denyMessageToSend" (click)="denyFile(file,getDenyInfo(file).denyMessageToSend)">Reject</button>
                  </div>
                </div>
                <span class="text-danger" *ngIf="file.denyMessage"><b>Rejected</b><br>Reason: {{file.denyMessage}}</span>

              </div>
              <div class="w-100" *ngIf="file?.files?.length>0 && file.files[0]">
                <div class="d-flex flex-column justify-content-center align-items-center">
                  <i *ngIf="hasUploadOnlyPermission" style="font-size: 32px;" class="fas fa-file"></i>
                  <img *ngIf="!hasUploadOnlyPermission" id="computerPhoto" class="pointer" [src]="getPreviewUrl(file.files[0])" (click)="downloaFile(file.files[0])" />
                  <img *ngIf="!hasUploadOnlyPermission" id="mobilePhoto" class="pointer" [src]="getPreviewUrl(file.files[0])" (click)="open(mymodal, file.files)" />
                  <button *ngIf="file.files.length > 1 && !hasUploadOnlyPermission" id="computerPhoto" class="btn btn-secondary w-50 mt-1" (click)="open(mymodal, file.files)">View All {{file.files.length}} pages</button>
                  <div id="computerPhoto" class="file-label" [title]="file.files[0].fileName"> {{file.files[0].fileName}} </div>
                  <div id="computerPhoto" class="file-label">Date Uploaded: {{file.files[0].fileDate != null ? (file.files[0].fileDate | date: 'MM/dd/yyyy') : "-"}}</div>
                </div>
                <div *ngIf="file.files.length > 1 && !hasUploadOnlyPermission" class="d-flex justify-content-around align-items-center mt-3">
                  <button class="mobilePhotoSmall btn-sm btn-secondary w-100" (click)="open(mymodal, file.files)">View File - {{file.id}}</button>
                </div>
              </div>

              <!-- Modal to show multiple pages if document is longer than one page and are images -->
              <ng-template #mymodal let-modal>
                <div class="modal-header">
                  <h4 class="modal-title" id="modal-basic-title">View Document Pages</h4>
                  <button type="button" class="close btn btn-outline-dark" aria-label="Close"
                    (click)="closeModal()">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="d-flex flex-column justify-content-center w-100">
                    <ng-container *ngFor="let fileFile of modalDocuments; let i = index;" class="m-0 w-100">
                      <div class="w-100" [ngClass]="{'mt-4': i != 0, 'mt-0': i == 0}">
                        <div class="d-flex flex-column justify-content-center">
                          <div  class="file-label mb-1" [title]="fileFile.fileName"> {{fileFile.fileName}} </div>
                          <div  class="file-label mb-1">Date Uploaded: {{fileFile.fileDate != null ? (fileFile.fileDate | date: 'MM/dd/yyyy') : "-"}}</div>
                          <img  class="file-image pointer" [src]="getPreviewUrl(fileFile)" (click)="downloaFile(fileFile)" />
                        </div>
                      </div>
                    </ng-container>
                    <div *ngIf="!readonly && !file.vaccineCardExemption?.removedDate" class="d-flex flex-column justify-content-center align-items-center mt-4">
                      <div class="badge-success rounded p-2 mt-1 w-50 text-center pointer" title="Click to turn not visible" *ngIf="managedUserId && file.visibleToUser" (click)="changeVisibility(file)">Visible</div>
                      <div class="badge-danger rounded p-2 mt-1 w-50 text-center pointer" title="Click to turn visible" *ngIf="managedUserId &&  !file.visibleToUser" (click)="changeVisibility(file)">Not Visible</div>
                    </div>
                    <p class="text-center mt-3 text-primary">Click on an Image to download individual pages.</p>
                    <div *ngIf="!readonly" class="mt-4 d-flex justify-content-center">
                      <i class="fas fa-cloud-download pointer fa-4x ml-3" title="Download All Pages" (click)="modalDownloadFile()"></i>
                      <div class="float-left">
                        <i class="fas fa-check-circle text-success fa-4x ml-3" title="Approved" *ngIf="file.approved"></i>
                        <i class="fas fa-times-circle text-danger fa-4x ml-3" [title]="'Rejected ('+file.denyMessage+')'" *ngIf="file.denyMessage && !file.vaccineCardExemption?.removedDate"></i>
                      </div>
                      <div class="float-right">
                        <i class="fas fa-times-circle pointer fa-4x ml-3" title="Click here to remove this file" (click)="deleteUploadDoc(file)" *ngIf="!file.approved && !managedUserId"></i>
                        <i class="fas fa-check-circle text-primary pointer fa-4x ml-3" title="Click to approve this file" (click)="approveFile(file)" *ngIf="managedUserId && !file.approved && (!file.vaccineCardExemption?.removedDate)"></i>

                        <i class="fas fa-times-circle text-primary pointer fa-4x ml-3" title="Click to reject this file" (click)="openCloseDenyMessage(file)" *ngIf="managedUserId && !file.denyMessage"></i>
                        <div *ngIf="getDenyInfo(file).denyMessageOpen" class="d-inline-flex">
                          <input type="text" class="form-control ml-2" [(ngModel)]="denyInfo[file.id].denyMessageToSend" />
                          <button class="btn btn-secondary ml-2 " [disabled]="!getDenyInfo(file).denyMessageToSend" (click)="denyFile(file,getDenyInfo(file).denyMessageToSend)">Reject</button>
                        </div>
                      </div>
                      <span class="text-danger" *ngIf="file.denyMessage"><b>Rejected</b><br>Reason: {{file.denyMessage}}</span>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-outline-dark" (click)="closeModal()">Close</button>
                </div>
              </ng-template>

              <div class="mt-1 mb-1" *ngIf="uploadType.docType.toString() === 'Licenses/Certifications'">
                <b>License Number:</b> {{file.licenseInfo?.licenseNumber }}<br>
                <b>Type:</b> {{file.licenseInfo?.isBoardCertification?'Board Certification':'License/Certificate' }}<br>
                <b>Origination Date:</b> {{file.licenseInfo?.originationDate | date:'shortDate'}}<br>
                <b>Issue Date:</b> {{file.licenseInfo?.issueDate | date:'shortDate'}}<br>
                <b>Expiration Date:</b> {{file.licenseInfo?.expirationDate? (file.licenseInfo?.expirationDate | date:'shortDate'):'N/A'}}
              </div>
              <div class="mt-1 mb-1" *ngIf="uploadType.docType.toString() === 'Paneling Docs'">
                <b>License Number:</b> {{file.panelingInfo?.licenseNumber }}<br>
                <b>Origination Date:</b> {{file.panelingInfo?.originationDate | date:'shortDate'}}<br>
                <b>Issue Date:</b> {{file.panelingInfo?.issueDate | date:'shortDate'}}<br>
                <b>Expiration Date:</b> {{file.panelingInfo?.expirationDate? (file.panelingInfo?.expirationDate | date:'shortDate'):'N/A'}}
              </div>
              <div class="mt-1 mb-1" *ngIf="uploadType.docType.toString() === 'Covid Test Results'">
                <b>Positive:</b> {{file.covidTestInfo?.positive?'Yes':'No' }}<br>
                <b>Test Date:</b> {{file.covidTestInfo?.testDate | date:'shortDate'}}<br>
                <b>Sympthoms Started Date:</b> {{file.covidTestInfo?.sympthomsStartedDate? (file.covidTestInfo?.sympthomsStartedDate | date:'shortDate'):'N/A'}}<br>
                <b>Quarantine Ended Date:</b> {{file.covidTestInfo?.quarantineEndedDate? (file.covidTestInfo?.quarantineEndedDate | date:'shortDate'):'N/A'}}<br>
              </div>
              <div class="mt-1 mb-1" *ngIf="uploadType.docType.toString() === 'Drivers License'">
                <b>Expiration Date:</b> {{file.driversLicenseInfo?.expirationDate? (file.driversLicenseInfo?.expirationDate| date:'shortDate'):''}}<br>
                <span class="text-danger"><b>{{file.driversLicenseInfo?.isExpired?'*** Expired ***':''}}</b></span>
              </div>
              <div class="mt-1 mb-1" *ngIf="uploadType.docType=='Vaccine Card Exemption' && file.vaccineCardExemption && (managedUserId || !file.vaccineCardExemption?.removedDate)">
                <b>Vaccine Card exemption requested on:</b> {{file.vaccineCardExemption?.createdDate  | date:'shortDate'}}<br/>
                <span *ngIf="file.vaccineCardExemption?.approvedDate">
                  <b>Vaccine Card exemption approved on:</b> {{file.vaccineCardExemption?.approvedDate  | date:'shortDate'}}<br>
                </span>
                <b>Exemption Type:</b> {{file.vaccineCardExemption?.exemptionType==0?'Medical Exemption':'Other Exemption'}}<br/>
                <b>Notes:</b> {{file.vaccineCardExemption?.notes}}<br/>
                <span *ngIf="managedUserId && file.vaccineCardExemption?.removedDate" class="text-danger">Removed</span>
                <span *ngIf="managedUserId && !file.vaccineCardExemption?.approvedDate && !file.vaccineCardExemption?.removedDate">Approve: <i class="fas fa-check-circle text-primary pointer" title="Click to approve" (click)="approveVaccineCardExemption(file.vaccineCardExemption?.id)" ></i> <br/></span>
                <button class="btn btn-primary mt-2" *ngIf="(!file.vaccineCardExemption?.approvedDate ||managedUserId)  && !file.vaccineCardExemption?.removedDate" (click)="removeVaccineCardExemption(file.vaccineCardExemption?.id)">Remove Exemption</button>
              </div>
              <div *ngIf="!readonly && !file.vaccineCardExemption?.removedDate && !hasUploadOnlyPermission" class="d-flex flex-column justify-content-center align-items-center">
                <div id="computerPhoto" class="badge-success rounded p-2 mt-1 w-50 pointer" title="Click to turn not visible" *ngIf="managedUserId && file.visibleToUser" (click)="changeVisibility(file)">Visible</div>
                <div id="computerPhoto" class="badge-danger rounded p-2 mt-1 w-50 pointer" title="Click to turn visible" *ngIf="managedUserId &&  !file.visibleToUser" (click)="changeVisibility(file)">Not Visible</div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
      <div class="card" *ngIf="!readonly && !this.showOnlyOnApprovalList ">
        <div class="card-body">
          <div class="mt-3 mb-3" *ngIf="uploadType.docType.toString() === 'Vaccine Card Exemption'">
            <select class="form-control " [(ngModel)]="exemptionFormType">
              <option value="0">Medical Exemption</option>
              <option value="1">Other Exemption</option>
            </select>
          </div>
          <div >
            <h5>Upload new <small>(you can select multiple files in case of several pages):</small></h5>
            <button class="btn mt-2 btn-secondary" (click)="openDocPreview(docpreviewmodal, uploadType)">Select Files</button>
            <div *ngIf="managedUserId" class="mt-1">
              <p-checkbox [(ngModel)]="visibleToUser[uploadType.id]" [binary]="true" [ngModelOptions]="{standalone: true}"></p-checkbox> Visible to user
            </div>
          </div>
          <ng-template #docpreviewmodal let-modal>
            <div class="modal-header">
              <h4 class="modal-title" id="modal-basic-title">Upload Files for {{ uploadType.docType.toString() }}</h4>
              <button type="button" class="close btn btn-outline-dark" aria-label="Close"
                (click)="closePreviewModal()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body w-100">
              <app-doc-preview
                [docTypeID]="uploadType.id"
                [docType]="uploadType.docType.toString()"
                [userId]="userId"
                [visibleToUser]="visibleToUser"
                [managedUserId]="managedUserId"
                (closeModal)="closePreviewModal()"
                (uploadFiles)="uploadFile($event)"
              ></app-doc-preview>
            </div>
          </ng-template>
          <div class="mt-3 mb-3" *ngIf="uploadType.docType.toString() === 'Paneling Docs'">
            License Number: <input type="text" class="form-control" [(ngModel)]="panelingInfos[uploadType.id].licenseNumber" [ngModelOptions]="{standalone: true}">
            Origination Date: <input type="date" class="form-control" [(ngModel)]="panelingInfos[uploadType.id].originationDate" [ngModelOptions]="{standalone: true}">
            Issue Date: <input type="date" class="form-control" [(ngModel)]="panelingInfos[uploadType.id].issueDate" [ngModelOptions]="{standalone: true}">
            Expiration Date: <input type="date" class="form-control" [(ngModel)]="panelingInfos[uploadType.id].expirationDate" [ngModelOptions]="{standalone: true}">
          </div>
          <div class="mt-3 mb-3" *ngIf="uploadType.docType.toString() === 'Licenses/Certifications'">
            License Number: <input type="text" class="form-control" [(ngModel)]="licenseInfos[uploadType.id].licenseNumber" [ngModelOptions]="{standalone: true}">
            Type:  <select class="form-control" [(ngModel)]="licenseInfos[uploadType.id].isBoardCertification">
              <option value="false">License/Certificate</option>
              <option value="true">Board Certification</option>
            </select>

            Origination Date: <input type="date" class="form-control" [(ngModel)]="licenseInfos[uploadType.id].originationDate" [ngModelOptions]="{standalone: true}">
            Issue Date: <input type="date" class="form-control" [(ngModel)]="licenseInfos[uploadType.id].issueDate" [ngModelOptions]="{standalone: true}">
            Expiration Date: <input type="date" class="form-control" [(ngModel)]="licenseInfos[uploadType.id].expirationDate" [ngModelOptions]="{standalone: true}">
          </div>
          
          <div class="mt-3 mb-3" *ngIf="uploadType.docType.toString() === 'Drivers License'">
            Drivers License Expiration Date: <input type="date" class="form-control" [(ngModel)]="driversLicenseInfos[uploadType.id].expirationDate" [ngModelOptions]="{standalone: true}">
          </div>
          <div class="mt-3 mb-3" *ngIf="!uploadedFilesPerType[uploadType.id.toString()]">No files uploaded</div>

          <div class="mt-3 mb-3" *ngIf="uploadType.docType.toString() === 'Covid Test Results'">
            Result:  <select class="form-control" [(ngModel)]="covidTestInfos[uploadType.id].positive">
              <option value="true">Positive</option>
              <option value="false">Negative</option>
            </select>

            Test Date: <input type="date" max="{{today | date: 'yyyy-MM-dd'}}" class="form-control" [(ngModel)]="covidTestInfos[uploadType.id].testDate" [ngModelOptions]="{standalone: true}">
            Sympthoms Started Date: <input type="date" max="{{today | date: 'yyyy-MM-dd'}}" class="form-control" [(ngModel)]="covidTestInfos[uploadType.id].sympthomsStartedDate" [ngModelOptions]="{standalone: true}">
          <div *ngIf="!(!covidTestInfos[uploadType.id].positive || covidTestInfos[uploadType.id].positive=='false')">  Estimated Quarantine End: <input type="date" min="{{today | date: 'yyyy-MM-dd'}}" class="form-control" [(ngModel)]="covidTestInfos[uploadType.id].quarantineEndedDate" [ngModelOptions]="{standalone: true}"></div>
          </div>
          <div *ngIf="uploadType.docType.toString() == 'Vaccine Card Exemption' && vaccineExemptionDocuments != null && vaccineExemptionDocuments.length > 0" class="text-warning">{{vaccineExemptionDocuments.length}} Documents queued to upload</div>
          <div class="mt-3 mb-3" *ngIf="uploadType.docType.toString() === 'Vaccine Card Exemption'">
            Please add here any relevant info about why you´re asking an exemption for the Vaccine Card.<br>
            <textarea class="form-control w-100 mt-2" [(ngModel)]="exemptionFormNotes" ></textarea>
            <span *ngIf="exemptionFormType==='1' && !exemptionFormNotes" class="small text-danger">Required</span><br>
            <button class="btn btn-primary mt-2" [disabled]="(exemptionFormType==='0' && vaccineExemptionDocuments === null) || (exemptionFormType==='1' && !exemptionFormNotes) || !exemptionFormType" (click)="requestVaccineCardExemption(uploadType.id)">Request Exemption</button>
        </div>
      </div>
    </div>
  </div>
  </ng-container>
</div>
