<div class="card mb-2">
  <div class="card-body">
    <form class="" *ngIf="form && category" [formGroup]="form" >

      <button  class="btn btn-secondary float-right pointer" routerLink="/selfcare/management">Back</button>
      <h2 class="">Edit Category</h2>
      <div class="row" formGroupName="category">
        <div class="col-12 col-lg-8 col-xl-8">
          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Category Name</b>
            </div>
            <div class="col-8 ">
                <input id="categoryNameInput" type="text" class="form-control mt-2" formControlName="categoryName"/>
                <app-validation-message [form]="form.get('category')" [path]="'categoryName'" [name]="'categoryName'" [label]="'Category Name'"> </app-validation-message>
            </div>
          </div>
          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Category Image</b>
            </div>
            <div class="col-8 ">
              <p-dropdown styleClass="form-control w-100 mt-2"  [options]="catImages" formControlName="categoryImage" placeholder="Select a category...">
                <ng-template pTemplate="selectedItem">
                  <div class="d-flex">
                    {{form.get('category.categoryImage').value}}
                      <img [src]="'/assets/img/'+form.get('category.categoryImage').value" class="category-img" />
                  </div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div class="d-flex">
                    {{item.value}}
                      <img [src]="'/assets/img/'+item.value" class="category-img" />
                  </div>
                </ng-template>
              </p-dropdown>

                <app-validation-message [form]="form.get('category')" [path]="'categoryImage'" [name]="'categoryImage'" [label]="'Category Image'"> </app-validation-message>
            </div>
          </div>
          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Main Color</b>
            </div>
            <div class="col-8 ">
              <p-colorPicker styleClass="mt-2 mr-2" formControlName="mainColor" (onChange)="changedColor($event)"></p-colorPicker>
              <input id="mainColorInput" type="text" class="form-control mt-2" formControlName="mainColor" (keyup)="changedColorInput($event)"/>
                <app-validation-message [form]="form.get('category')" [path]="'mainColor'" [name]="'mainColor'" [label]="'Main Color'"> </app-validation-message>
            </div>
          </div>
          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Slideshow Link</b>
            </div>
            <div class="col-8 ">
                <input id="slideshowLinkInput" type="text" class="form-control mt-2" formControlName="slideshowLink"/>
                <app-validation-message [form]="form.get('category')" [path]="'slideshowLink'" [name]="'slideshowLink'" [label]="'Slideshow Link'"> </app-validation-message>
            </div>
          </div>

          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Description</b>
            </div>
            <div class="col-8 ">
                <input id="categoryDescriptionInput" type="text" class="form-control mt-2" formControlName="categoryDescription"/>
                <app-validation-message [form]="form.get('category')" [path]="'categoryDescription'" [name]="'categoryDescription'" [label]="'Description'"> </app-validation-message>
            </div>
          </div>


          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Link Text</b>
            </div>
            <div class="col-8 ">
                <input id="linkTextInput" type="text" class="form-control mt-2" formControlName="linkText"/>
                <app-validation-message [form]="form.get('category')" [path]="'linkText'" [name]="'linkText'" [label]="'Link Text'"> </app-validation-message>
            </div>
          </div>


          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Link Descripton</b>
            </div>
            <div class="col-8 ">
                <input id="linkDescriptonInput" type="text" class="form-control mt-2" formControlName="linkDescripton"/>
                <app-validation-message [form]="form.get('category')" [path]="'linkDescripton'" [name]="'linkDescripton'" [label]="'Link Descripton'"> </app-validation-message>
            </div>
          </div>


          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Link Url</b>
            </div>
            <div class="col-8 ">
                <input id="linkUrlInput" type="text" class="form-control mt-2" formControlName="linkUrl"/>
                <app-validation-message [form]="form.get('category')" [path]="'linkUrl'" [name]="'linkUrl'" [label]="'Link Url'"> </app-validation-message>
            </div>
          </div>


          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Uploaded Doc Type</b>
            </div>
            <div class="col-8 ">
              <p-dropdown id="onboarding_UploadDocTypeIDInput"  styleClass="form-control w-100 mt-2" [options]="uploadedDocTypes" formControlName="onboarding_UploadDocTypeID" ></p-dropdown>

                <app-validation-message [form]="form.get('category')" [path]="'onboarding_UploadDocTypeID'" [name]="'onboarding_UploadDocTypeID'" [label]="'Uploaded Doc Type'"> </app-validation-message>
            </div>
          </div>

          <div class="row" *ngIf="form.get('category.onboarding_UploadDocTypeID').value">
            <div class="col-4 d-flex align-items-center">
              <b>Uploaded Doc Link Text</b>
            </div>
            <div class="col-8 ">
                <input id="uploadDocLinkTextInput" type="text" class="form-control mt-2" formControlName="uploadDocLinkText"/>
                <app-validation-message [form]="form.get('category')" [path]="'uploadDocLinkText'" [name]="'uploadDocLinkText'" [label]="'Uploaded Doc Link Text'"> </app-validation-message>
            </div>
          </div>


          <div class="row" *ngIf="form.get('category.onboarding_UploadDocTypeID').value">
            <div class="col-4 d-flex align-items-center">
              <b>Uploaded Doc Link Descripton</b>
            </div>
            <div class="col-8 ">
                <input id="uploadDocLinkDescriptionInput" type="text" class="form-control mt-2" formControlName="uploadDocLinkDescription"/>
                <app-validation-message [form]="form.get('category')" [path]="'uploadDocLinkDescription'" [name]="'uploadDocLinkDescription'" [label]="'Uploaded Doc Link Descripton'"> </app-validation-message>
            </div>
          </div>

        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-lg-8 col-xl-8">
          <div class="card">
            <div class="card-body" formArrayName="questions">
              <h4>Questions <i class="fas fa-plus-circle pointer" (click)="addQuestion()"></i></h4>
                <table class="mt-2 w-100" >
                      <tr>
                          <th width="50px"></th>
                          <th >Question Text</th>
                          <th >Display Text</th>
                          <th width="100px">OrderNr</th>
                          <th >Planner Section</th>
                          <th >Allow Public</th>
                          <th >Question Type</th>
                          <th >Question Options</th>
                      </tr>
                      <tr *ngFor="let question of getControls(form.get('questions')); let i=index" [formGroup]="question">
                          <td>
                            <i class="fas fa-trash mr-2 pointer" title="Remove"  (click)="removeQuestion(i)"></i>
                          </td>
                          <td>
                              <input class="form-control" type="text" formControlName="questionText"/>
                              <app-validation-message [form]="question" [path]="'questionText'" [name]="'questionText'" [label]="'Question Text'"> </app-validation-message>

                          </td>

                          <td>
                            <input class="form-control" type="text" formControlName="displayText"/>
                          </td>

                          <td>
                            <input class="form-control" type="number" formControlName="categoryOrderNr"/>
                            <app-validation-message [form]="question" [path]="'categoryOrderNr'" [name]="'categoryOrderNr'" [label]="'Order Nr'"> </app-validation-message>

                          </td>

                          <td>
                            <p-dropdown styleClass="form-control w-100 " [options]="plannerSectionItemList" formControlName="plannerSectionItemID"></p-dropdown>
                          </td>

                          <td class="text-center">
                            <p-checkbox styleClass="" [binary]="true" [formControlName]="'allowPublic'"></p-checkbox>
                          </td>
                          <td>
                            <p-dropdown styleClass="form-control w-100 " [options]="questionTypeList" formControlName="questionType"></p-dropdown>
                          </td>
                          <td>
                            <input class="form-control" type="text" formControlName="questionOptions"/>
                          </td>
                      </tr>
                </table>
              </div>
            </div>
        </div>
      </div>
      <i *ngIf="loading" class="material-icons rotate text-muted">loop</i>
      <button class="btn btn-primary mt-3" id="saveButton" [class.disabled]="form.invalid || loading" (click)="save()">Save</button>
    </form>
  </div>
</div>
