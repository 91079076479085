import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { SelectItem } from 'primeng/api';
import { formatDate } from '@angular/common';
import { deepClone, getDateWithOffset } from '../../../../helpers/utils';
import { enableSelectTimeOffEmployee, getManageTimeOffList, loadViewableCampuses, resetTimeOffApproval, resetTimeOffApprovalSuccess, saveTimeOffApproval, saveTimeOffApprovalSuccess, saveTimeOffRequestSuccess, showApproveTimeOffRequest, showEditTimeOffRequest } from '../../../../core/store/timeoff/timeoff.actions';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { TimeOffModel } from '../../../../models/timeoffModel';
import { tap } from 'rxjs/operators';
import { loadEmployeeUsers } from '../../../../core/store/employee/employee.action';
import { stringify } from 'querystring';

@Component({
  selector: 'app-list-timeoff-manage',
  templateUrl: './list-timeoff-manage.component.html',
  styleUrls: ['./list-timeoff-manage.component.scss']
})
export class ListTimeoffManageComponent extends BaseComponent implements OnInit {
  filter: any;
  timeOffList: PagingResultsModel<TimeOffModel>;

  loadingList: boolean = true;
  canEdit: boolean = false;
  canViewPto: boolean = false;

  campusList: SelectItem[];
  campusId: number = 0;

  prefixText: string;
  userList: SelectItem[];
  userId: number = 0;

  startDate: string = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
  endDate: string = formatDate(getDateWithOffset(14), 'yyyy-MM-dd', 'en-US');

  statusList: SelectItem[];
  statusId: number = 1;

  constructor(
    private actions$: Actions,
    private store: Store<State>) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.timeoffState.loadingManageTimeOffList).subscribe(loadingList => {
        this.loadingList = loadingList;
      }),
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.canEdit = permissionIDs && !!permissionIDs.find(p => p === 186);
        this.canViewPto = permissionIDs && !!permissionIDs.find(p => p === 3103 || p === 3104);
      }),
      this.store.select(s => s.timeoffState.campuses).subscribe(campuses => {
        this.campusList = campuses && campuses.length > 0 ? campuses
          : [{ label: 'loading...', value: undefined }];
        if (campuses.length == 1) {
          this.campusId = this.campusList[0].value;
        }
      }),      
      this.store.select(s => s.employeeState.employeeUsers).subscribe(users => {
        this.userList = users;
      }),
      this.store.select(s => s.timeoffState.manageTimeOffList).subscribe(list => {
        this.timeOffList = list;
      }),
      this.actions$.pipe(
        ofType(saveTimeOffRequestSuccess),
        tap(action => {
          this.reloadList();
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(saveTimeOffApprovalSuccess),
        tap(action => {
          this.reloadList();
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(resetTimeOffApprovalSuccess),
        tap(action => {
          this.reloadList();
        })
      ).subscribe(),
    );

    this.store.dispatch(loadViewableCampuses());
    this.statusList = [{ label: "All", value: 0 }, { label: "Pending", value: 1 }, { label: "Approved", value: 2 },
      { label: "Denied", value: 3 }, { label: "Pending & Approved", value: 4 }];
  }

  reloadFilter(event) {
    this.filter = event;
    this.reloadList();
  }


  getStaffList(event) {
    this.store.dispatch(loadEmployeeUsers({
      prefixText: event.query,
      programId: this.campusId,
      payrollClassificationId: 0,
      showTerminated: true,
      showInactive: true
    }));
  }

  reloadListAfterStaffChage(selectedUser) {
    if (typeof selectedUser.value !== 'number') {
      return;
    }

    this.userId = selectedUser.value;
    this.reloadList();
  }

  reloadListAfterClear(event) {
    if (event.data !== null) {
      return;
    }

    this.userId = 0;
    this.reloadList();
  }

  reloadList() {
    this.filter = deepClone(this.filter);

    this.filter.filters.userId = { Name: 'userId', Value: { value: this.userId.toString() } };
    this.filter.filters.programId = { Name: 'programId', Value: { value: this.campusId.toString() } };
    this.filter.filters.startDate = { Name: 'startDate', Value: { value: this.startDate } };
    this.filter.filters.endDate = { Name: 'endDate', Value: { value: this.endDate } };
    this.filter.filters.statusId = { Name: 'statusId', Value: { value: this.statusId.toString() } };

    this.store.dispatch(getManageTimeOffList({
      filter: this.filter
    }));
  }

  newTimeOffRequest() {
    this.store.dispatch(enableSelectTimeOffEmployee());
    this.editTimeOffRequest([]);
  }

  editTimeOffRequest(request) {
    this.store.dispatch(showEditTimeOffRequest({ timeOffRequest: request }));        
  }

  splitChanges(changes: string){
    if(changes == null || changes == ""){
      return;
    }
    var changesArray = changes.split(",");
    return changesArray;
  }

  approveTimeOffRequest(request: TimeOffModel) {
    let timeOff = deepClone(request) as TimeOffModel;    
    timeOff.approved = true;
    timeOff.denied = false;       
    this.store.dispatch(saveTimeOffApproval({ timeOffRequest: timeOff }));
  }

  denyTimeOffRequest(request: TimeOffModel) {
    let timeOff = deepClone(request) as TimeOffModel;
    timeOff.approved = false;
    timeOff.denied = true;
    this.store.dispatch(saveTimeOffApproval({ timeOffRequest: timeOff }));
  }

  commentTimeOffRequest(request: TimeOffModel) {      
    this.store.dispatch(showApproveTimeOffRequest({ timeOffRequest: request }));
  }

  resetTimeOffRequest(request: TimeOffModel) {    
    this.store.dispatch(resetTimeOffApproval({ timeOffRequest: request }));
  }
}
