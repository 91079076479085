import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../../core/store';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { EmployeeReviewTemplateCategoryModel, EmployeeReviewTemplateKPIModel, EmployeeReviewTemplateValueColorModel, KPIHasRBTEthicsCodeModel } from '../../../../../models/employeeReviewTemplateModels';
import { BaseComponent } from '../../../../../core/abstracts/baseComponent';
import { addAndCloseKpiEthicsCodes, removeKpi, removeKpiCategory, showKpiEthicsCodes } from '../../../../../core/store/employee-review-template/employee-review-template.actions';
import { AlertService } from '../../../../../shared/services/alert.service';
import { deepClone } from '../../../../../helpers/utils';

@Component({
  selector: 'app-kpi-template-component',
  templateUrl: './kpi-template-component.component.html',
  styleUrls: ['./kpi-template-component.component.scss']
})
export class KpiTemplateComponentComponent extends BaseComponent implements OnInit {
  @Input() kpi: EmployeeReviewTemplateKPIModel;
  @Input() kpiIndex: any;

  loading: boolean;

  addingEthicCode: boolean = false;

  categories: EmployeeReviewTemplateCategoryModel[];
  category: EmployeeReviewTemplateCategoryModel;
  valueColors: EmployeeReviewTemplateValueColorModel[];

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private alertService: AlertService) {
    super();
  }

  ngOnInit() {
    this.addingEthicCode = false;
    this.subs.push(
      this.store.select(s => s.employeeReviewTemplateState.categories).subscribe(categories => {
        this.categories = categories;
      }),
    this.store.select(s => s.ethicCodeState.loading).subscribe(loading => {
      this.loading = loading;
    }),
    this.store.select(s => s.ethicCodeState.savingEthicCode).subscribe(savingEthicCode => {
      this.loading = savingEthicCode;
    }),
    this.store.select(s => s.employeeReviewTemplateState.valueColors).subscribe(valueColors => {
      this.valueColors = valueColors;
      if (this.kpi.valueColorID) {
        this.kpi.valueColor = valueColors.find(x => x.id === this.kpi.valueColorID);
      }
    }),
      this.store.select(s => s.employeeReviewTemplateState.loading).subscribe(loading => {
        this.loading = loading;
      }),
      this.actions$.pipe(
        ofType(removeKpiCategory),
        tap(action => {
          if (this.kpi.categories.some(s => s == action.kpiCategory)) {
            var index = this.kpi.categories.indexOf(action.kpiCategory);
            this.kpi.categories.splice(index, 1);
          }
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(addAndCloseKpiEthicsCodes),
        tap(action => {
          if (action.kpi && this.addingEthicCode) {
            this.kpi.ethicsCodes = deepClone(action.kpi.ethicsCodes);
            this.addingEthicCode = false;
          }
        })
      ).subscribe(),
      
    );

  }

  addCategory() {
    if (!this.category) {
      this.alertService.error("Please, select a category.");
      return;
    }
    if (this.kpi.categories.some(s => s.category == this.category)) {
      this.alertService.error("Category already added to the KPI.");
      return;
    }
    this.kpi.categories.push({
      category: this.category,
      measureables: [{
        measureableId: null,
        measureable: '',
        childrenMeasureables: [],
        isChild: false
      }]
    });
  }

  remove() {
    this.store.dispatch(removeKpi({ kpi: this.kpi }));
  }

  changedColor(event, kpi) {
    if (event) {
      kpi.valueColorID = event.id;
    } else {
      kpi.valueColorID = null;
    }
  }

  openKpiEthicsCodeModal(kpi) {
    this.addingEthicCode = true;
    this.store.dispatch(showKpiEthicsCodes({
      kpi: deepClone(kpi)
    }));
  }
}
