import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { Actions, ofType } from '@ngrx/effects';
import { State } from '../../../../core/store';
import { Store } from '@ngrx/store';
import { DateRangeModel } from '../../../../models/dateRangeModel';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { DropdownFilterService } from '../../../../core/services/dropdown-filter.service';
import { exportEmployeeTerminationListToExcel, exportEmployeeTerminationListToExcelSuccess, getEmployeeTerminationList, loadEmployeeUsers, loadViewableCampuses } from '../../../../core/store/employee/employee.action';
import { TerminatedEmployeeModel } from '../../../../models/userProfileModel';
import { deepClone, downloadFile } from '../../../../helpers/utils';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-report-terminated',
  templateUrl: './report-terminated.component.html',
  styleUrls: ['./report-terminated.component.scss']
})
export class ReportTerminatedComponent extends BaseComponent implements OnInit {
  list: PagingResultsModel<TerminatedEmployeeModel>;
  filter: any;
  canEditUser: boolean = false;
  
  loadingTerminationList: boolean = true;
  loadingTerminationExcel: boolean = false;

  campusList: SelectItem[];
  campusId: number = 0;

  prefixText: string;
  userEmployeeList: SelectItem[];
  userId: number = 0;

  dateRangeList: SelectItem[];
  dateRange: DateRangeModel;
  startDate: string = null;
  endDate: string = null;
  isCustomDateRange: boolean = false; 

  constructor(
    private router: Router,
    private actions$: Actions,
    private store: Store<State>,
    private dropdownFilterService: DropdownFilterService) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.canEditUser = permissionIDs && !!permissionIDs.find(p => p === 6001 || p === 6002); //HR Manager, HR Super Admin
      }),
      this.store.select(s => s.employeeState.loadingTerminationList).subscribe(loadingTerminationList => {
        this.loadingTerminationList = loadingTerminationList;
      }),
      this.store.select(s => s.employeeState.loadingTerminationExcel).subscribe(loadingTerminationExcel => {
        this.loadingTerminationExcel = loadingTerminationExcel;
      }),
      this.store.select(s => s.employeeState.campuses).subscribe(campuses => {
        this.campusList = campuses && campuses.length > 0 ? campuses
          : [{ label: 'loading...', value: undefined }];
      }),
      this.store.select(s => s.employeeState.employeeUsers).subscribe(users => {
        this.userEmployeeList = users;
      }),
      this.store.select(s => s.employeeState.terminationList).subscribe(terminationList => {
        this.list = terminationList;
      }),
      this.actions$.pipe(
        ofType(exportEmployeeTerminationListToExcelSuccess),
        tap(action => {
          downloadFile(action.doc, 'EmployeeSeparation.xlsx');
        })
      ).subscribe()
    );

    this.dateRangeList = this.dropdownFilterService.getLast120DayDateRanges().map(c => ({ label: c.text, value: c }));    
    this.store.dispatch(loadViewableCampuses());
  }

  changedDateRange() {
    if (this.dateRange.isCustom) {
      this.isCustomDateRange = true;
      return; //reload after manually changing date
    }

    this.isCustomDateRange = false;
    this.startDate = this.dateRange.startDate;
    this.endDate = this.dateRange.endDate;
    this.reloadList(this.filter);
  }


  reloadList(event) {
    this.filter = event;
    this.filter = deepClone(this.filter);
    this.filter.filters.userId = { Name: 'userId', Value: { value: this.userId.toString() } };
    this.filter.filters.programId = { Name: 'programId', Value: { value: this.campusId.toString() } };
    this.filter.filters.startDate = { Name: 'startDate', Value: { value: this.startDate } };
    this.filter.filters.endDate = { Name: 'endDate', Value: { value: this.endDate } };

    this.store.dispatch(getEmployeeTerminationList({
      filter: this.filter
    }));
  }

  getStaffList(event) {    
    this.store.dispatch(loadEmployeeUsers({
      prefixText: event.query,
      programId: this.campusId,
      payrollClassificationId: null,
      showTerminated: true,
      showInactive: true
    }));
  }

  reloadListAfterStaffChage(selectedUser) {
    if (typeof selectedUser.value !== 'number') {
      return;
    }

    this.userId = selectedUser.value;
    this.reloadList(this.filter);
  }

  reloadListAfterClear(event) {
    if (event.data !== null) {
      return;
    }

    this.userId = 0;
    this.reloadList(this.filter);
  }

  exportToExcel() {
    this.filter = deepClone(this.filter);
    this.filter.filters.userId = { Name: 'userId', Value: { value: this.userId.toString() } };
    this.filter.filters.programId = { Name: 'programId', Value: { value: this.campusId.toString() } };
    this.filter.filters.startDate = { Name: 'startDate', Value: { value: this.startDate } };
    this.filter.filters.endDate = { Name: 'endDate', Value: { value: this.endDate } };

    this.store.dispatch(exportEmployeeTerminationListToExcel({
      filter: this.filter
    }));
  }

  editEmployee(employee: TerminatedEmployeeModel = null) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/employee-file/detail/' + employee.userId])
    );

    window.open(url, '_blank');

    //this.router.navigate(['/employee-file/detail/' + employee.userId]);
  }
}
