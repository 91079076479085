<div>
  <div class="row">
    <h4 class="m-2">#{{kpiIndex + 1}} KPI</h4>
    <button class="btn btn-danger" [disabled]="loading" (click)="remove()">Remove</button>
  </div>
  <div class="row">
    <div class="col-2 d-flex">
      <b>Description:</b>
    </div>
    <div class="col-10">
      <textarea class="form-control ml-2 w-100 mb-2" [(ngModel)]="kpi.description" [ngModelOptions]="{standalone: true}"></textarea>
    </div>
  </div>
  <div class="row">
    <div class="col-2 d-flex">
      <b>Value:</b>
    </div>
    <div class="col-10">
      <p-dropdown styleClass="form-control w-100 ml-2 mb-2" [showClear]="true" [options]="valueColors" optionLabel="valueName" optionValue="id" [(ngModel)]="kpi.valueColor" (ngModelChange)="changedColor($event,kpi)" [ngModelOptions]="{standalone: true}" placeholder="Select a value...">
        <ng-template pTemplate="selectedItem">
          <div class="valuecolor-item d-flex" *ngIf="kpi.valueColor">
            <div class="valuecolor-colorsquare mr-2" [style.backgroundColor]="kpi.valueColor.colorRGB"></div>
            <div>{{kpi.valueColor.valueName}}</div>
          </div>
        </ng-template>
        <ng-template let-valueColorOption pTemplate="item">
          <div class="valuecolor-item d-flex">
            <div class="valuecolor-colorsquare mr-2" [style.backgroundColor]="valueColorOption.value.colorRGB"></div>
            <div>{{valueColorOption.value.valueName}}</div>
          </div>
        </ng-template>

      </p-dropdown>

    </div>
  </div>
  <div class="row">
    <div class="col-2 d-flex">
      <b>Why:</b>
    </div>
    <div class="col-10">
      <textarea class="form-control ml-2 w-100 mb-2" [(ngModel)]="kpi.reason" [ngModelOptions]="{standalone: true}"></textarea>
    </div>
  </div>
  <div class="row">
    <div class="col-2 d-flex">
      <b>How/Core Play:</b>
    </div>
    <div class="col-10">
      <textarea class="form-control ml-2 w-100 mb-2" placeholder="Core play..." [(ngModel)]="kpi.corePlay" [ngModelOptions]="{standalone: true}"></textarea>
    </div>
  </div>
  <div class="row">
    <div class="col-2 d-flex">
      <b>Measurement:</b>
    </div>
    <div class="col-10">
      <textarea class="form-control ml-2 w-100 mb-2" placeholder="What Winning Looks Like..." [(ngModel)]="kpi.measurement" [ngModelOptions]="{standalone: true}"></textarea>
    </div>
  </div>
  <div class="row">
    <div class="col-2 d-flex">
      <b>Ethics Codes:</b>
      <div>
        <button class="btn btn-success" (click)="openKpiEthicsCodeModal(kpi)">Add Ethics Codes</button>
      </div>
    </div>
    <div class="col-10">
      <div class="d-flex m-2 w-100" [ngClass]="{'bg-warning': ec.highlight}" *ngFor="let ec of kpi.ethicsCodes" >
        <strong class="col">{{ec.section}}</strong>
        <pre class="col-9">{{ec.description}}</pre>
      </div>
    </div>
  </div>

  <div class="w-100">
    <div *ngFor="let category of kpi.categories; let i=index" class="w-100">
      <app-kpi-category-template-component [kpiCategory]="category" class="w-100"></app-kpi-category-template-component>
    </div>
  </div>
  <div class="row">
    <div class="col-2 d-flex">
      <b>Add Category</b>
    </div>
    <div class="col-10">
      <div class="w-100 d-flex">
        <div class="col-10">
          <p-dropdown styleClass="form-control w-100" optionLabel="category" [options]="categories" [(ngModel)]="category" [ngModelOptions]="{standalone: true}" placeholder="Select a category..."></p-dropdown>
        </div>
        <button class="btn btn-success col-2" [disabled]="loading" (click)="addCategory()">Add Category</button>
      </div>
    </div>
  </div>
</div>
