import { AbstractControl } from '@angular/forms';
export class FormBuilderHelpDescription{
    static create(id: number) {

        var type = id == 6 ? "notice": "form";

        var example = id == 6 ? "For example, you could create or edit service interruption notices to send when invoices are unpaid that you can fill and then generate a PDF document to send to the client"
        :"For example, you could create or edit client intake documents that will allow the client to fill out a form on their computer and generate it as a PDF document";

        var html = `<small class="w-100 text-left">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Here you are able to create or edit ${type}s that can be filled out to
                        generate PDF documents. ${example}. You are able to enter information dynamically by pressing the plus sign
                        found to the left. After clicking the plus sign, a new field will appear directly below, click the config button to the right of the new field
                        to edit. Once you have completed adding the field you will be able to use that new field in the form with a '#' immediately followed
                        by the field name. <em>(ex. '#Name')</em>. `;
        if (id == 6){
            html += `If you create a field for a due date, you will need to input, on the document, something like the following: <em>"Please note,
                    your due date is <b>#Due Date</b>."</em> <br />

                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;When the notice is sent or downloaded, this will populate the document with: <em>"Please note,
                    your due date is xx/xx/xxx."</em>, xx/xx/xxxx being the actual due date on file. <u><b>Please do not</b></u>
                    enter names or dates without using the field option explained above,
                    as this will populate all notices sent out. <br/ >
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Below are some fields already created and ready for your use: <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - #Signature, #DateSignature, #Initials, #SignatureOptional, #InitialsOptional, #Signature#HRUser# <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Please use these in the form for signature sections, initial lines and signature dates. Place them on the document where you want them to appear
                    and they will automatically create the signature/initial label and line. For notices that require a signature from the recipient, #Signature is required
                    to be placed on the document. For notices not requiring a signature from the recipient, do not place #Signature on the document. This setting is
                    found in the "<em>See advanced settings</em>" section under the select form type field.`
        }
        else {
            html += `If you create a field for a due date, you will need to input, on the document, something like the following:
                     <em>"Due Date: <b>#Due Date</b>."</em> <br />

                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Below are some fields already created and ready for your use: <br />
                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - #Signature, #DateSignature, #Initials, #SignatureOptional, #InitialsOptional <br />
                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Please use these in the form for signature sections, initial lines and signature dates. Place them on the document where you want them to appear
                     and they will automatically create the signature/initial label and line. For this form type, #Signature is <u><b>REQUIRED</b></u> to be placed on the document.`
        }
        html += `</small>`

        return html;
      }
}
