import { createReducer, on, Action } from '@ngrx/store';
import { CompletedKraModel, EmployeeReviewInfoModel, EmployeeReviewOpenPeriod, KraReportModel, OverseenEmployeeModel } from '../../../models/employeeReviewModels';
import { EmployeeReviewModel } from 'src/app/models/employeeReviewModels';

import { EmployeeReviewTemplateModel } from '../../../models/employeeReviewTemplateModels';
import { PagingResultsModel } from '../../../models/pagingResultsModel';
import { autoSaveOversightReview, autoSaveOversightReviewFailure, autoSaveOversightReviewSuccess, autoSavePlayerReview, autoSavePlayerReviewFailure, autoSavePlayerReviewSuccess, deleteEmployeeReview, deleteEmployeeReviewOversight, deleteEmployeeReviewOversightFail, deleteEmployeeReviewOversightSuccess, deleteEmployeeReviewSuccess, getCompletedKraList, getCompletedKraListSuccess, getKraList, getKraListSuccess, getOverseenEmployee, getOverseenEmployeeSuccess, getReviewOpenPeriodSuccess, loadMyTemplates, loadMyTemplatesSuccess, loadReviews, loadReviewsSuccess, loadTemplateFilterSuccess, postPlayerReview, postPlayerReviewFailure, postPlayerReviewSuccess, save2ndOversightReview, save2ndOversightReviewSuccess, saveMeetsExpectations, saveMeetsExpectationsSuccess } from './employee-review.actions';
import { ModifiedSelectItem } from '../../../models/utilModels';


export interface EmployeeReviewState {
  templates: EmployeeReviewTemplateModel[];
  loading: boolean;
  reviews: PagingResultsModel<EmployeeReviewInfoModel>;
  overseenEmployee: OverseenEmployeeModel;
  completedKraList: PagingResultsModel<CompletedKraModel>;
  kraList: PagingResultsModel<KraReportModel>;
  templateFilter: ModifiedSelectItem[];

  savingEmployeeReview: boolean;
  employeeReview: EmployeeReviewModel;
  savingOversightReview: boolean;
  oversightReview: EmployeeReviewModel;
  loadingCompletedKraList: boolean;
  loadingKraList: boolean;

  savingMeetsExpectations: boolean;
  saving2ndOversightReview: boolean;

  reviewOpenPeriod: EmployeeReviewOpenPeriod;
};

const initialState: EmployeeReviewState = {
  templates: null,
  loading: false,
  reviews: null,
  overseenEmployee: null,

  savingEmployeeReview: false,
  employeeReview: null,
  savingOversightReview: false,
  oversightReview: null,
  completedKraList: null,
  loadingCompletedKraList: true,
  kraList: null,
  loadingKraList: true,

  savingMeetsExpectations: false,
  saving2ndOversightReview: false,
  reviewOpenPeriod: null,

  templateFilter: []
};

export const employeeReviewReducer = createReducer(initialState,
  on(loadMyTemplates, (state, action) => {
    return {
      ...state,
      loading: true
    };
  }),
  on(loadMyTemplatesSuccess, (state, action) => {
    return {
      ...state,
      templates: action.templates,
      loading: false
    };
  }),
  on(loadReviews, (state, action) => {
    return {
      ...state,
      loading: true
    };
  }),
  on(loadReviewsSuccess, (state, action) => {
    return {
      ...state,
      reviews: action.employeeReviews,
      loading: false
    };
  }),
  on(deleteEmployeeReview, (state, action) => {
    return {
      ...state,
      loading: true
    };
  }),
  on(deleteEmployeeReviewSuccess, (state, action) => {
    return {
      ...state,
      loading: false
    };
  }),
  on(deleteEmployeeReviewSuccess, (state, action) => {
    return {
      ...state,
      loading: false
    };
  }),
  on(deleteEmployeeReviewOversight, (state, action) => {
    return {
      ...state,
      loading: true
    };
  }),
  on(deleteEmployeeReviewOversightSuccess, (state, action) => {
    return {
      ...state,
      loading: false
    };
  }),
  on(deleteEmployeeReviewOversightFail, (state, action) => {
    return {
      ...state,
      loading: false
    };
  }),
  on(postPlayerReview, (state, action) => {
    return {
      ...state,
      savingEmployeeReview: true
    };
  }),
  on(postPlayerReviewSuccess, (state, action) => {
    return {
      ...state,
      savingEmployeeReview: false
    };
  }),
  on(getOverseenEmployee, (state, action) => {
    return {
      ...state,
      loading: true,
      overseenEmployee: null
    };
  }),
  on(getOverseenEmployeeSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      overseenEmployee: action.user
    };
  }),
  on(postPlayerReviewFailure, (state, action) => {
    return {
      ...state,
      savingEmployeeReview: false
    };
  }),
  on(autoSavePlayerReview, (state, action) => {
    return {
      ...state,
      savingEmployeeReview: true
    };
  }),
  on(autoSavePlayerReviewSuccess, (state, action) => {
    return {
      ...state,
      savingEmployeeReview: false
    };
  }),
  on(autoSavePlayerReviewFailure, (state, action) => {
    return {
      ...state,
      savingEmployeeReview: false
    };
  }),
  on(autoSaveOversightReview, (state, action) => {
    return {
      ...state,
      savingOversightReview: true
    };
  }),
  on(autoSaveOversightReviewSuccess, (state, action) => {
    return {
      ...state,
      savingOversightReview: false
    };
  }),
  on(autoSaveOversightReviewFailure, (state, action) => {
    return {
      ...state,
      savingOversightReview: false
    };
  }),
  on(getCompletedKraList, (state, action) => {
    return {
      ...state,
      loadingCompletedKraList: true
    };
  }),
  on(getCompletedKraListSuccess, (state, action) => {
    return {
      ...state,
      loadingCompletedKraList: false,
      completedKraList: action.completedKraList
    };
  }),
  on(saveMeetsExpectations, (state, action) => {
    return {
      ...state,
      savingMeetsExpectations: true
    }
  }),
  on(saveMeetsExpectationsSuccess, (state, action) => {
    return {
      ...state,
      savingMeetsExpectations: false
    }
  }),
  on(save2ndOversightReview, (state, action) => {
    return {
      ...state,
      saving2ndOversightReview: true
    }
  }),
  on(save2ndOversightReviewSuccess, (state, action) => {
    return {
      ...state,
      saving2ndOversightReview: false
    }
  }),
  on(getReviewOpenPeriodSuccess, (state, action) => {
    return {
      ...state,      
      reviewOpenPeriod: action.openPeriod
    };
  }),
  on(getKraList, (state, action) => {
    return {
      ...state,
      loadingKraList: true
    };
  }),
  on(getKraListSuccess, (state, action) => {
    return {
      ...state,
      loadingKraList: false,
      kraList: action.kraList
    };
  }),
  on(loadTemplateFilterSuccess, (state, action) => {
    return {
      ...state,
      templateFilter: action.templates,
    };
  }),
);

export function employeeReviewReducerFunc(state: EmployeeReviewState | undefined, action: Action) {
  return employeeReviewReducer(state, action);
}



