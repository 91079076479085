import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import * as moment from 'moment';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { deepClone } from 'src/app/helpers/utils';
import { AssignedEquipmentModel, EquipmentWithAssignmentsModel } from 'src/app/models/equipmentsModel';
import { AlertService } from 'src/app/shared/services/alert.service';
import { GeneralSettingsModel } from 'src/app/models/generalSettingsModel';

@Component({
  selector: 'app-equipment-not-returned',
  templateUrl: './equipment-not-returned.component.html',
  styleUrls: ['./equipment-not-returned.component.scss']
})
export class EquipmentNotReturnedComponent extends BaseComponent implements OnInit {

  @Input() equipment: EquipmentWithAssignmentsModel = null;
  @Output() myEvent = new EventEmitter<string>();
  @Output() saveEquipment = new EventEmitter<EquipmentWithAssignmentsModel>();

  serialNumberCheck: string = null;
  showReturnForm: boolean = false;
  returnStatus: string = "";
  dateReturned: string = "";
  returnNotes: string = "";
  generalSettings: GeneralSettingsModel;
  

  constructor(
    private alertService: AlertService,
    private store: Store<State>
  ) {
    super();
   }

  ngOnInit(): void {
    var date = new Date;
    this.dateReturned = moment(date).format("YYYY-MM-DD");
    this.subs.push(
      this.store.select(s => s.accountState.generalSettings).subscribe(generalSettings => {
        if (generalSettings) {
          this.generalSettings = generalSettings;
        }
      }),
    )
  }

  verifiedEquipment(){
    this.showReturnForm = true;
  }

  save(){
    if(this.returnStatus == "" || this.returnStatus == null ){
      this.alertService.error("Status is required")
      return;
    }
    if(this.dateReturned == null || this.dateReturned == ""){
      this.alertService.error("Return Date is required")
      return;
    }
    var updatedEquipment = deepClone(this.equipment)
    updatedEquipment.lastAssignedEquipment.dateReturned = new Date(this.dateReturned);
    updatedEquipment.lastAssignedEquipment.returnStatus = this.returnStatus;
    updatedEquipment.lastAssignedEquipment.returnNotes = this.returnNotes;
    this.saveEquipment.emit(updatedEquipment);
    this.close();
  }

  close(){
    this.showReturnForm = false;
    this.myEvent.emit('eventDesc');
  }
}
