<h3 *ngIf="!canEdit && !canView">
  You do not have permission to view this page
</h3>
<form *ngIf="canEdit || canView" class="card mt-2">
  <div class="card-body">
      <button *ngIf="canEdit" class="btn btn-primary float-right pointer" routerLink="/prospective-candidate/addit">New Candidate</button>
      <button *ngIf="canEdit || canView" class="btn btn-primary mr-2 float-right pointer" (click)="clearFilters()">Clear Filters</button>
      <button class="btn btn-primary float-right pointer mr-2" (click)="openLink('jobapplications/management')">Job Application Page</button>
      <h2 class="mb-3">Prospective Candidates</h2>

      <h5>Filter <button class="btn btn-link" (click)="toggleHideFilter()">{{showHideString}}</button></h5>
      <div class="row mb-1" [hidden]="hideFilter">
        <div class="col-6">
          <div class="row mb-1" >
            <div class="col-4 d-flex align-items-center">
              <h6>Candidate Name:</h6>
            </div>
            <div class="col-8 ">
              <input type="text" [(ngModel)]="employeeNameFilter" class="form-control" (ngModelChange)="changedFilters()"  [ngModelOptions]="{standalone: true}">
            </div>
          </div>

          <div class="row mb-1" >
            <div class="col-4 d-flex align-items-center">
              <h6>Email :</h6>
            </div>
            <div class="col-8 ">
              <input type="text" [(ngModel)]="emailFilter" class="form-control" (ngModelChange)="changedFilters()"  [ngModelOptions]="{standalone: true}">
            </div>
          </div>

          <div class="row mb-1" >
            <div class="col-4 d-flex align-items-center">
              <h6>Created Date:</h6>
              <h6 [hidden] = "!customDates" style="color: red; margin-left:5px;">{{ createdDateFilter }}</h6>
            </div>
            <div class="col-8 ">
              <p-dropdown styleClass="form-control  w-100" [options]="datesOptions" placeholder="Select ..."  [(ngModel)]="createdDateOptionFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"></p-dropdown>

              <p-calendar *ngIf="createdDateOptionFilter==0"
                inputStyleClass="form-control "
                [monthNavigator]="true"
                [yearNavigator]="true"
                styleClass="w-75"
                panelStyleClass="calendarPanel"
                [hourFormat]="12"
                [yearRange]="'2010:2100'"
                [showTime]="false"
                [selectionMode]="'range'"
                [(ngModel)]="createdDateFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"
                dataType="string"
              ></p-calendar>
            </div>
          </div>
          <div class="row  mb-1" >
            <div class="col-4 d-flex align-items-center">
              <b>Positions</b>
            </div>
            <div class="col-8">
              <i *ngIf="!jobTitlesList" class="material-icons rotate text-muted">loop</i>
              <p-multiSelect #positionsMultiSelect (onFocus)="openMultiSelect(positionsMultiSelect)" autofocusFilter="true" class="w-100" styleClass="w-100 d-flex " inputStyleClass="form-control"
               [options]="jobTitlesList" [defaultLabel]="'Select ...'"
               [(ngModel)]="positionsFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"></p-multiSelect>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="row  mb-1" >
            <div class="col-4 d-flex align-items-center">
              <b>Region</b>
            </div>
            <div class="col-8">
              <i *ngIf="!campusesList" class="material-icons rotate text-muted">loop</i>
              <p-dropdown styleClass="form-control  w-100" [options]="campusesList" placeholder="Select campus..."  [(ngModel)]="regionFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"></p-dropdown>
            </div>
          </div>

          <div class="row  mb-1" >
            <div class="col-4 d-flex align-items-center">
              <b>Include Archived</b>
            </div>
            <div class="col-8">
              <p-checkbox  [(ngModel)]="removedFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}" [binary]="true" ></p-checkbox>
            </div>
          </div>
          <div class="row mt-2 mb-1" >
            <div class="col-4 d-flex align-items-center">
              <b>Only Referrals</b>
            </div>
            <div class="col-8">
              <p-checkbox  [(ngModel)]="onlyReferralsFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}" [binary]="true" ></p-checkbox>
            </div>
          </div>

          <div class="row mt-2 mb-1" >
            <div class="col-4 d-flex align-items-center">
              <h6>Referred By</h6>
            </div>
            <div class="col-8 ">
              <input type="text" [(ngModel)]="referredByFilter" class="form-control" (ngModelChange)="changedFilters()"  [ngModelOptions]="{standalone: true}">
            </div>
          </div>
        </div>
      </div>

      <div class="ui-column-buttons">
        <div>Display: </div>
        <div>
          <button (click)="editColumns($event.target.value)" [style.background-color]="!date ? 'white': 'lightgrey'" value="date">Date</button>
          <button (click)="editColumns($event.target.value)" [style.background-color]="!region ? 'white': 'lightgrey'" value="region">Region</button>
          <button (click)="editColumns($event.target.value)" [style.background-color]="!position ? 'white': 'lightgrey'" value="position">Position</button>
          <button (click)="editColumns($event.target.value)" [style.background-color]="!name ? 'white': 'lightgrey'" value="name">Name</button>
          <button (click)="editColumns($event.target.value)" [style.background-color]="!email ? 'white': 'lightgrey'" value="email">Email</button>
          <button *ngIf="canEdit" (click)="editColumns($event.target.value)" [style.background-color]="!references ? 'white': 'lightgrey'" value="references">References</button>
          <button (click)="editColumns($event.target.value)" [style.background-color]="!offerLetter ? 'white': 'lightgrey'" value="offerLetter">Offer&nbsp;Letter</button>
          <button (click)="editColumns($event.target.value)" [style.background-color]="!interviewDate ? 'white': 'lightgrey'" value="interviewDate">Interview&nbsp;Date</button>
          <button (click)="editColumns($event.target.value)" [style.background-color]="!contactLog ? 'white': 'lightgrey'" value="contactLog">Contact&nbsp;Log</button>
          <button (click)="editColumns($event.target.value)" [style.background-color]="!referredBy ? 'white': 'lightgrey'" value="referredBy">Referred&nbsp;By</button>
        </div>
      </div>

    <i *ngIf="loadingProspectiveCandidates" class="material-icons rotate text-muted">loop</i>
    <p-table  [value]="prospectiveCandidates?.result" [responsive]="true"
    [paginator]="true" [rows]="20" [totalRecords]="prospectiveCandidates?.totalRecords" [lazy]="true"
    (onLazyLoad)="loadPage($event)">
        <ng-template pTemplate="header">
          <tr>
            <th width="130px" *ngIf="canEdit"></th>
            <th [hidden]="!date" [pSortableColumn]="'CreatedDate'" width="90px">
              Date
              <p-sortIcon [field]="'CreatedDate'"></p-sortIcon>
            </th>
            <th [hidden]="!region" [pSortableColumn]="'Region'" width="140px">
              Region
              <p-sortIcon [field]="'Region'"></p-sortIcon>
            </th>
            <th [hidden]="!name" [pSortableColumn]="'EmployeeName'">
              Employee
              <p-sortIcon [field]="'EmployeeName'"></p-sortIcon>
            </th>
            <th [hidden]="!position" [pSortableColumn]="'Position'">
              Position
              <p-sortIcon [field]="'Position'"></p-sortIcon>
            </th>
            <th [hidden]="!email" [pSortableColumn]="'Email'"  width="240px" >
              Email
              <p-sortIcon [field]="'Email'"></p-sortIcon>
            </th>
            <th *ngIf="canEdit" [hidden]="!references" [pSortableColumn]="'ReferredBy'">
              References
              <p-sortIcon [field]="'ReferredBy'"></p-sortIcon>
            </th>
            <th [hidden]="!offerLetter" [pSortableColumn]="'OfferLetterID'" width="100px">
              Offer letter
              <p-sortIcon [field]="'OfferLetterID'"></p-sortIcon>
            </th>
            <th [hidden]="!interviewDate" [pSortableColumn]="'InterviewDate'">
              Interview Date
              <p-sortIcon [field]="'InterviewDate'"></p-sortIcon>
            </th>
            <th [hidden]="!contactLog" [pSortableColumn]="'ContactLog'" width="260px">
              Contact Log
              <p-sortIcon [field]="'ContactLog'"></p-sortIcon>
            </th>
            <th [hidden]="!referredBy" [pSortableColumn]="'Referred By'">
              Referred By
              <p-sortIcon [field]="'Referred By'"></p-sortIcon>
            </th>
            <th *ngIf="removedFilter">Archived</th>
            <th *ngIf="removedFilter">No Show</th>


          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-prospectiveCandidate>
          <tr>
            <td *ngIf="canEdit">
              <div class="table-buttons font-sm">
                <span class="pointer mr-2" (click)="editProspectiveCandidate(prospectiveCandidate)"><i title="Edit Prospective Candidate" class="fas fa-edit"></i></span>
                <span class="pointer " *ngIf="!prospectiveCandidate.removed" (click)="deleteProspectiveCandidate(prospectiveCandidate)"><i title="Archive Prospective Candidate" class="fas fa-trash-alt"></i></span>
                <span class="pointer " *ngIf="!prospectiveCandidate.removed" (click)="noShowProspectiveCandidate(prospectiveCandidate)"><i title="Set Prospective Candidate No Show" class="ml-2 fas fa-calendar-times"></i></span>
                <span class="pointer " *ngIf="prospectiveCandidate.removed" (click)="deleteProspectiveCandidate(prospectiveCandidate)"><i title="Unarchive Prospective Candidate" class="fas fa-trash-restore-alt"></i></span>
                <span class="pointer ml-2" *ngIf="!prospectiveCandidate.removed" (click)="getResume(prospectiveCandidate)"><i title="Resume" class="fas fa-file-alt"></i></span>
                <span class="pointer ml-2" *ngIf="hasScreeningForm(prospectiveCandidate)" (click)="openPhoneScreening(myscreeningmodal, prospectiveCandidate)"><i title="View Phone Screening Interview" class="fas fa-phone text-info"></i></span>
              </div>
            </td>
            <td [hidden]="!date">
              <span class="ui-column-title">Date</span>
              {{prospectiveCandidate.createdDate | date:'shortDate' }}
            </td>
            <td [hidden]="!region">
              <span class="ui-column-title">Region</span>
              {{prospectiveCandidate.region }}
            </td>
            <td [hidden]="!name">
              <span class="ui-column-title">Employee</span>
              {{prospectiveCandidate.firstName }} {{prospectiveCandidate.lastName }}
            </td>
            <td [hidden]="!position">
              <span class="ui-column-title">Position</span>
              {{prospectiveCandidate.position }}
            </td>
            <td [hidden]="!email">
              <span class="ui-column-title">Email</span>
              {{prospectiveCandidate.email }}
            </td>
            <td *ngIf="canEdit" [hidden]="!references">
              <span class="ui-column-title">References</span>
              <span *ngIf="prospectiveCandidate.references && prospectiveCandidate.references.length"
                    class="badge pointer"
                    [class.badge-success]="prospectiveCandidate.references.length==prospectiveCandidate.referencesAnswered"
                    [class.badge-danger]="prospectiveCandidate.references.length!=prospectiveCandidate.referencesAnswered"
                    title="References"
                    (click)="editProspectiveCandidate(prospectiveCandidate)">{{prospectiveCandidate.referencesAnswered}} of {{prospectiveCandidate.references.length}}</span>

              <span *ngIf="prospectiveCandidate.referencesAnswersNotSeen>0" class="badge badge-danger ml-2 pointer"
                    (click)="editProspectiveCandidate(prospectiveCandidate)">New</span>
            </td>
            <td [hidden]="!offerLetter">
              <span class="ui-column-title">Offer letter</span>
              <i *ngIf="!prospectiveCandidate.offerLetterID && !prospectiveCandidate.removed" class="fas fa-paper-plane pointer" title="Send Offer Letter" [routerLink]="'/offerletter/create'" [queryParams]="{ prospectiveCandidateID: prospectiveCandidate.id }"></i>
            </td>
            <td [hidden]="!interviewDate">
              <span class="ui-column-title">Interview Date</span>
              {{prospectiveCandidate.interviewDate | date: 'MM-dd-yyyy hh:mm' }}
            </td>
            <td [hidden]="!contactLog">
              <span class="ui-column-title">Contact Log</span>
              {{(prospectiveCandidate && prospectiveCandidate.contactLogs && prospectiveCandidate.contactLogs.length>0) ? (prospectiveCandidate.contactLogs[prospectiveCandidate.contactLogs.length-1].contactDetails +
                "(" + (prospectiveCandidate.contactLogs[prospectiveCandidate.contactLogs.length-1].contactDate )+ ", " +
                prospectiveCandidate.contactLogs[prospectiveCandidate.contactLogs.length-1].usersName + ")" ) : '' }}
            </td>
            <td [hidden]="!referredBy">
              <span class="ui-column-title">Referred By</span>
              {{prospectiveCandidate.referredBy }}
            </td>
            <td *ngIf="removedFilter">
              <span class="ui-column-title">Deleted</span>
              <i *ngIf="prospectiveCandidate.removed" class="fas fa-check-circle text-danger" title="Deleted"></i>
            </td>

            <td *ngIf="removedFilter">
              <span class="ui-column-title">No Show</span>
              <i *ngIf="prospectiveCandidate.noShow" class="fas fa-check-circle text-danger" title="No Show"></i>
            </td>
          </tr>
        </ng-template>
    </p-table>
  </div>
</form>
<ng-template #myscreeningmodal class="modal-index" let-modal>
  <div class="modal-body">
      <app-screening-interview
        [applicationID]="jobAppID"
        [firstName]="firstName"
        [lastName]="lastName"
        [position]="positionValue"
        [formSubmissionID]="formSubmissionID"
        [programID]="programID"
        [prospectiveCandidateID]="prospectiveCandidateID"
        (myEvent)="closeScreener($event)"
      ></app-screening-interview>
  </div>
</ng-template>
