import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { Actions } from '@ngrx/effects';
import { State } from '../../../../core/store';
import { Store } from '@ngrx/store';
import { BenefitLogReportModel } from '../../../../models/benefitsModels';
import { DateRangeModel } from '../../../../models/dateRangeModel';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { DropdownFilterService } from '../../../../core/services/dropdown-filter.service';
import { deepClone } from '../../../../helpers/utils';
import { loadEmployeeUsers, loadViewableCampuses } from '../../../../core/store/employee/employee.action';
import { getBenefitLogList, loadBenefitLogTypes } from '../../../../core/store/benefits/benefits.actions';

@Component({
  selector: 'app-list-benefit-logs',
  templateUrl: './list-benefit-logs.component.html',
  styleUrls: ['./list-benefit-logs.component.scss']
})
export class ListBenefitLogsComponent extends BaseComponent implements OnInit {
  list: PagingResultsModel<BenefitLogReportModel>;
  filter: any;
  canEdit: boolean = false;

  loadingList: boolean = true;

  campusList: SelectItem[];
  campusId: number = 0;

  prefixText: string;
  userEmployeeList: SelectItem[];
  userId: number = 0;

  dateRangeList: SelectItem[];
  dateRange: DateRangeModel;
  startDate: string = null;
  endDate: string = null;
  isCustomDateRange: boolean = false;

  logTypes: SelectItem[];
  logTypeId: number = 0;

  details: string = "";


  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private dropdownFilterService: DropdownFilterService) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.benefitsState.loadingLogList).subscribe(loadingLogList => {
        this.loadingList = loadingLogList;
      }),
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.canEdit = permissionIDs && !!permissionIDs.find(p => p === 3075);
      }),
      this.store.select(s => s.employeeState.campuses).subscribe(campuses => {
        this.campusList = campuses && campuses.length > 0 ? campuses
          : [{ label: 'loading...', value: undefined }];
      }),
      this.store.select(s => s.benefitsState.logTypes).subscribe(logTypes => {
        if (logTypes) {
          this.logTypes = logTypes.map(x => ({ label: x.label, value: x.value }));
          this.logTypes.unshift({ label: 'All', value: 0 });
        }
        else {
          this.logTypes = [{ label: 'loading...', value: undefined }]
        }
      }),      
      this.store.select(s => s.employeeState.employeeUsers).subscribe(users => {
        this.userEmployeeList = users;
      }),
      this.store.select(s => s.benefitsState.logList).subscribe(list => {
        this.list = list;
      })
    );

    this.dateRangeList = this.dropdownFilterService.getLast120DayDateRanges().map(c => ({ label: c.text, value: c }));
    this.store.dispatch(loadViewableCampuses());
    this.store.dispatch(loadBenefitLogTypes());
  }

  changedDateRange() {
    if (this.dateRange.isCustom) {
      this.isCustomDateRange = true;
      return; //reload after manually changing date
    }

    this.isCustomDateRange = false;
    this.startDate = this.dateRange.startDate;
    this.endDate = this.dateRange.endDate;
    this.reloadList();
  }

  reloadFilter(event) {
    this.filter = event;
    this.reloadList();
  }

  reloadList() {
    this.filter = deepClone(this.filter);
    this.filter.filters.userId = { Name: 'userId', Value: { value: this.userId.toString() } };
    this.filter.filters.programId = { Name: 'programId', Value: { value: this.campusId.toString() } };
    this.filter.filters.startDate = { Name: 'startDate', Value: { value: this.startDate } };
    this.filter.filters.endDate = { Name: 'endDate', Value: { value: this.endDate } };
    this.filter.filters.logTypeId = { Name: 'logTypeId', Value: { value: this.logTypeId } };
    this.filter.filters.details = { Name: 'details', Value: { value: this.details } };

    this.store.dispatch(getBenefitLogList({
      filter: this.filter
    }));
  }

  getStaffList(event) {
    this.store.dispatch(loadEmployeeUsers({
      prefixText: event.query,
      programId: this.campusId,
      payrollClassificationId: null,
      showInactive: false,
      showTerminated: false
    }));
  }

  reloadListAfterStaffChage(selectedUser) {
    if (typeof selectedUser.value !== 'number') {
      return;
    }

    this.userId = selectedUser.value;
    this.reloadList();
  }

  reloadListAfterClear(event) {
    if (event.data !== null) {
      return;
    }

    this.userId = 0;
    this.reloadList();
  }  
}
