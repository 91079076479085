<form class="card mt-2">
  <div class="card-body">
    <div *ngIf="canEdit" class="float-right">
      <button type="button" routerLink="/benefits-management/status-check/queue" class="btn btn-secondary mr-2">Queue</button>
      <button type="button" routerLink="/benefits-management/status-check/list" class="btn btn-secondary mr-2">Status Checks</button>
      <button type="button" routerLink="/benefits-management/declined" class="btn btn-secondary mr-2">Declined</button>
      <button type="button" routerLink="/benefits-management/withdrawn" class="btn btn-secondary mr-2">Withdrawn</button>
      <button type="button" routerLink="/benefits-management/logs" class="btn btn-secondary">Logs</button>
    </div>
    <h2 class="mb-3">HSA Report</h2>
    <div class="mb-3">

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Campus:</h5>
        </div>
        <div class="col-10">
          <i *ngIf="!campusList" class="material-icons rotate text-muted">loop</i>
          <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="campusList" [(ngModel)]="campusId" (ngModelChange)="reloadFilter(this.filter)" [ngModelOptions]="{standalone: true}"></p-dropdown>
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Employee:</h5>
        </div>
        <div class="col-10">
          <div class="ml-2 w-100 mb-2">
            <p-autoComplete [style]="{'width': '100%'}" [inputStyle]="{'width': '100%'}" [(ngModel)]="prefixText"
                            [suggestions]="userEmployeeList" (completeMethod)="getStaffList($event)" field="label"
                            [ngModelOptions]="{standalone: true}" [minLength]="1" [autoHighlight]="true"
                            (onSelect)="reloadListAfterStaffChage($event)" [forceSelection]="true"
                            (onClear)="reloadListAfterClear($event)"></p-autoComplete>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Date:</h5>
        </div>
        <div class="col-10">
          <input type="date" class="form-control ml-2 w-100 mb-2" [(ngModel)]="enrollmentDate" (blur)="reloadFilter(this.filter)" [ngModelOptions]="{standalone: true}" />
        </div>       
      </div>

    </div>

    <i [hidden]="!loadingList && !loadingExcel" class="material-icons rotate text-muted">loop</i>
    <button type="button" title="Export all pages to excel" class="btn btn-success mr-2 mb-2" (click)="exportToExcel()"><i class="far fa-file-excel"></i></button>
    <p-table [value]="list?.result" [responsive]="true" [paginator]="true" [rows]="20"
             [totalRecords]="list?.totalRecords" [lazy]="true" (onLazyLoad)="reloadFilter($event)">
      <ng-template pTemplate="header">
        <tr>
          <th [pSortableColumn]="'EmployeeName'">Employee <p-sortIcon [field]="'EmployeeName'"></p-sortIcon></th>
          <th [pSortableColumn]="'PrimaryProgramName'">Primary Campus <p-sortIcon [field]="'PrimaryProgramName'"></p-sortIcon></th>
          <th [pSortableColumn]="'EmailAddress'">Email <p-sortIcon [field]="'EmailAddress'"></p-sortIcon></th>
          <th [pSortableColumn]="'EmployeeCost'">Employee Contribution <p-sortIcon [field]="'EmployeeCost'"></p-sortIcon></th>
          <th [pSortableColumn]="'EmployerContribution'">Employer Contribution <p-sortIcon [field]="'EmployerContribution'"></p-sortIcon></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-list>
        <tr>
          <td>
            <span class="ui-column-title">Employee</span>
            {{list.firstName}} {{list.lastName}}
          </td>          
          <td>
            <span class="ui-column-title">Primary Campus</span>
            {{list.primaryProgramName}}
          </td>
          <td>
            <span class="ui-column-title">Email Address</span>
            {{list.emailAddress}}
          </td>
          <td>
            <span class="ui-column-title">EmployeeCost</span>
            {{list.employeeCost}}
          </td>
          <td>
            <span class="ui-column-title">EmployerContribution</span>
            {{list.employerContribution}}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</form>
