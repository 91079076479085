import { PagingResultsModel } from './../../../../models/pagingResultsModel';
import {
  getOfferLettersList, getOfferLetterPdf,
  deleteOfferLetter, deleteOfferLetterSuccess, markOfferLetterSeen
} from './../../../../core/store/offerletter/offerletter.actions';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Router } from '@angular/router';
import { OnBoardingUserListModel } from 'src/app/models/onBoardingModels';
import { loadOnBoardingUsersList, completeHRFile, completeHRFileSuccess } from 'src/app/core/store/onboarding/onboarding.actions';
import { deepClone } from 'src/app/helpers/utils';

@Component({
  selector: 'app-onboarding-manage-list',
  templateUrl: './onboarding-manage-list.component.html',
  styleUrls: ['./onboarding-manage-list.component.scss']
})
export class OnboardingManageListComponent extends BaseComponent implements OnInit {

  onBoardingUsers: PagingResultsModel<OnBoardingUserListModel>;
  canViewOnBoarding: boolean;
  loadingOnBoardingUsers = false;
  filter: any;

  yesNoAllOptions = [
    { label: 'All', value: null },
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  employeeFirstNameFilter: string;
  employeeLastNameFilter: string;
  consentFilter?: boolean = null;
  allUploadedFilter?: boolean = null;
  uploadApprovedFilter?: boolean = null;
  i9SignedFilter?: boolean = null;
  i9ApprovedFilter?: boolean = null;
  employeeInfoFilter?: boolean = null;
  allDocsSignedFilter?: boolean = null;
  allDocsApprovedFilter?: boolean = null;
  finishedFilter?: boolean = null;
  orientationDateFilter;
  loading;
  displayCompleteHRDialog;
  onBoardingUserForCompleteHR;
  eVerifyCompletionDate;
  terminatedEmployeesFilter = false;
  inactiveUsersFilter = false;

  constructor(
    private actions$: Actions,
    private alertService: AlertService,
    private store: Store<State>,
    private router: Router) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      // Check if user is an HR manager
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.canViewOnBoarding = permissionIDs && !!permissionIDs.find(p => p === 3059 || p === 3060); //view on boarding, manage on boarding
      }),
      this.store.select(s => s.onboardingState.onBoardingUsers).subscribe(onBoardingUsers => {
        if (onBoardingUsers) {
          this.onBoardingUsers = onBoardingUsers;
          this.loading = false;
        }
      }),

      this.store.select(s => s.onboardingState.loadingOnBoardingUsers).subscribe(loadingOnBoardingUsers => {
        this.loadingOnBoardingUsers = loadingOnBoardingUsers;
      }),
      this.actions$.pipe(
        ofType(completeHRFileSuccess),
        tap(action => {
          this.closeCompleteHR();
          this.loadPage(this.filter);
        })
      ).subscribe(),

    );

  }

  loadPage(event) {
    if (!this.loading) {
      this.loading = true;
      this.filter = deepClone(event);
      this.filter.filters.employeeFirstNameFilter = { Name: 'employeeFirstNameFilter', Value: { value: this.employeeFirstNameFilter } };
      this.filter.filters.employeeLastNameFilter = { Name: 'employeeLastNameFilter', Value: { value: this.employeeLastNameFilter } };
      this.filter.filters.consentFilter = { Name: 'consentFilter', Value: { value: this.consentFilter } };
      this.filter.filters.allUploadedFilter = { Name: 'allUploadedFilter', Value: { value: this.allUploadedFilter } };
      this.filter.filters.uploadApprovedFilter = { Name: 'uploadApprovedFilter', Value: { value: this.uploadApprovedFilter } };
      this.filter.filters.i9SignedFilter = { Name: 'i9SignedFilter', Value: { value: this.i9SignedFilter } };
      this.filter.filters.i9ApprovedFilter = { Name: 'i9ApprovedFilter', Value: { value: this.i9ApprovedFilter } };
      this.filter.filters.employeeInfoFilter = { Name: 'employeeInfoFilter', Value: { value: this.employeeInfoFilter } };
      this.filter.filters.allDocsSignedFilter = { Name: 'allDocsSignedFilter', Value: { value: this.allDocsSignedFilter } };
      this.filter.filters.allDocsApprovedFilter = { Name: 'allDocsApprovedFilter', Value: { value: this.allDocsApprovedFilter } };
      this.filter.filters.finishedFilter = { Name: 'finishedFilter', Value: { value: this.finishedFilter } };
      if (this.orientationDateFilter) {
        this.filter.filters.orientationDateFilterStart = { Name: 'orientationDateFilterStart', Value: { value: this.orientationDateFilter[0] } };
        this.filter.filters.orientationDateFilterEnd = { Name: 'orientationDateFilterEnd', Value: { value: this.orientationDateFilter[1] } };
      } else {
        this.filter.filters.orientationDateFilterEnd = { Name: 'orientationDateFilterEnd', Value: { value: null } };
      }
      this.filter.filters.terminatedEmployeesFilter = { Name: 'terminatedEmployeesFilter', Value: { value: this.terminatedEmployeesFilter } };
      this.filter.filters.inactiveUsersFilter = { Name: 'inactiveUsersFilter', Value: { value: this.inactiveUsersFilter } };


      this.store.dispatch(loadOnBoardingUsersList({
        filter: deepClone(this.filter)
      }));
    }


    return event;
  }



  seeUserDetails(onBoardingUser: OnBoardingUserListModel) {
    this.router.navigate(['/onboarding-manage/detail/' + onBoardingUser.user.id]);
  }

  changedFilters() {
    this.loadPage(this.filter);
  }

  completeHR(onBoardingUser: OnBoardingUserListModel) {
    this.alertService.confirm('Are you sure?',
      'By completing the HR File, this user will not be listed as Onboarding anymore and will be moved to the Employee File. Are you sure?')
      .subscribe(
        anwser => {
          if (anwser) {
            this.store.dispatch(completeHRFile({ userId: onBoardingUser.user.id, eVerifyCompletionDate: this.eVerifyCompletionDate }));
          }
        }, error => {
        }
      );
  }

  openCompleteHR(onBoardingUser: OnBoardingUserListModel) {
    this.displayCompleteHRDialog = true;
    this.onBoardingUserForCompleteHR = onBoardingUser;
    this.eVerifyCompletionDate = onBoardingUser.employee.eVerifyCompletionDate ? new Date(onBoardingUser.employee.eVerifyCompletionDate) : null;


  }
  closeCompleteHR() {
    this.displayCompleteHRDialog = false;
    this.onBoardingUserForCompleteHR = null;
    this.eVerifyCompletionDate = null;
  }

  showCompleteHRButton(onBoardingUser: OnBoardingUserListModel) {
    return onBoardingUser.onboarding &&
      onBoardingUser.onboarding.consentDate &&
      onBoardingUser.allUploaded &&
      onBoardingUser.onboarding.uploadedDocsApprovedDate &&
      onBoardingUser.i9FormSigned &&
      onBoardingUser.onboarding.i9ApprovedDate &&
      onBoardingUser.onboarding.employeeInfoFilled &&
      onBoardingUser.allDocsSigned &&
      onBoardingUser.onboarding.allDocumentsSignedApprovedDate  &&
      onBoardingUser.onboarding.showedUpOnOrientation;
  }

  seeReferences(onBoardingUser: OnBoardingUserListModel) {
    this.router.navigate(['/prospective-candidate/employee-references/' + onBoardingUser.prospectiveCandidateID], { queryParams: { backTo: '/onboarding-manage/list' } });
  }
}

