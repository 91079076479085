
<i *ngIf="!payrollItems" class="material-icons rotate text-muted">loop</i>
<ng-container *ngIf="payrollItems && payrollItems.length">
  <div class="w-100 text-center mb-2">
    <h5 id="payableTypeHeader">{{ title ? title : payableType }}</h5>
  </div>
  <p-table [value]="payrollItems" [responsive]="true">
    <ng-template pTemplate="header">
      <tr>
        <th width="70px"></th>
        <th width="90px" *ngIf="adjustmentView">
          <span  ></span>
        </th>
        <th width="110px" *ngIf="!payableType">Payroll Type</th>
        <th width="80px">Date</th>
        <th>Time</th>
        <th width="110px" *ngIf="payableType==='Manual Entry'">Productive
        </th>
        <th width="70px" *ngIf="selectable">
          <p-checkbox  [(ngModel)]="allSelected"  binary="true" (onChange)="changedAllSelected()" ></p-checkbox>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-payrollItem>
      <tr [class.text-danger]="payrollItem.overlap" [class.text-info]="payrollItem.overlapClient" [class.text-success]="payrollItem.overlapAcademy" [title]="payrollItem.overlap?'Employee time overlap':(payrollItem.overlapClient?'Client time overlap':(payrollItem.overlapAcademy?'Academy note time overlap':''))" >
        <td [colSpan]="getColspan()">
          <table>
            <tr>
              <td width="70px" class="text-center">
                
                <i *ngIf="payrollItem.payableItem.timeOffRequest && canEditItems" class="fas fa-edit pointer" (click)="editTimeOffRequest(payrollItem.payableItem.timeOffRequest)" title="Edit Time Off"></i> <!--canEdit && item.canEditRequest  need to figure out what this is-->
                
                <i (click)="editSelectedPayableItem(payrollItem)" title="Edit Payable Item" *ngIf="payrollItem.payableItem.approvalCanEdit && canEditItems"
                   class="fas fa-edit pointer"></i>
                <i *ngIf="payrollItem !== undefined && payrollItem.documentationFixRequest !== undefined && payrollItem.documentationFixRequest !== null"
                   [title]="'Documentation Fix Request Feedback -- ' + payrollItem.documentationFixRequest" class="fas fa-exclamation-circle"></i>
              </td>
              <td width="70px" class="text-center" *ngIf="adjustmentView">
                <i (click)="selectPayrollItem(payrollItem)" title="Rejected - click to view reason"
                *ngIf="!payrollItem.payableItem.response" class="fas fa-exclamation-triangle pointer"></i>
                  <i (click)="selectPayrollItem(payrollItem)" title="Response"
                  *ngIf="payrollItem.payableItem.response" class="fas fa-comment-dots  pointer"></i>
              </td>
              <td width="110px" *ngIf="!payableType">{{payrollItem.payableItem.payableType}}</td>
              <td width="80px">
                <span class="ui-column-title">Date</span>
                <i class="fas fa-flag text-danger" *ngIf="showOldFlag(payrollItem.payableItem.date)" title="Old entry: Check with Compliance before approving"></i>
                {{ payrollItem.payableItem.date | date: "shortDate" }}
              </td>
              <td>
                <span class="ui-column-title">Clock in</span>
                {{ payrollItem.payableItem.startTime | date: payrollItem.payableItem.payableTypeID !== 7 && payrollItem.payableItem.payableTypeID !== 9 && payrollItem.payableItem.payableTypeID !== 15 ? "shortTime" : "shortDate" }} -
                {{ payrollItem.payableItem.endTime | date: payrollItem.payableItem.payableTypeID !== 7 && payrollItem.payableItem.payableTypeID !== 9 && payrollItem.payableItem.payableTypeID !== 15 ? "shortTime" : "shortDate" }} ({{
                  payrollItem.payableItem.timeDiff
                }})
                <span *ngIf="payrollItem.payableItem.isExempt" style="color: red; font-weight: bold;">EXEMPT</span>
                <span *ngIf="payrollItem.payableItem.isMinWageRate && payrollItem.payableItem.payableTypeID != 13" style="color: red; font-weight: bold;">Min Wage Rate</span>
                <i *ngIf="payrollItem.payableItem.isProductive && payrollItem.payableItem.payableTypeID === 2" class="material-icons" style="color: gold; cursor: default" title="Productive">grade</i>
                <i (click)="changeClientServiceNoteToManualEntry(payrollItem.payableItem)" *ngIf="payrollItem.payableItem.payableTypeID === 10" class="material-icons" style="color: blue; cursor: pointer" title="Change to Manual Entry">change_circle</i>
                <ng-container *ngIf="!adjustmentView && (payrollItem.payableItem.needsDirectorApproval && !payrollItem.payableItem.hasDirectorApproval)">
                  <span style="color: orange; float: right;">Pending Director Approval</span>
                </ng-container>
              </td>
              <td width="110px" class="text-center" *ngIf="payableType==='Manual Entry' || payableType==='Minimum Wage'">
                <span class="ui-column-title">Productive</span>
                <p-checkbox  [(ngModel)]="payrollItem.payableItem.isProductive" [disabled]="!selectable"  binary="true" (onChange)="changedSelection()" ></p-checkbox>

              </td>
              <td width="70px" class="text-center" *ngIf="selectable">
                <span class="ui-column-title">Select</span>
                <p-checkbox id="entryCheckbox" [(ngModel)]="payrollItem.selected" [disabled]="payrollItem.payableItem.needsDirectorApproval && !payrollItem.payableItem.hasDirectorApproval"  binary="true" (onChange)="changedSelection()"
                            [title]="(payrollItem.payableItem.needsDirectorApproval && !payrollItem.payableItem.hasDirectorApproval)?'Needs Director Approval':''"></p-checkbox>

              </td>

            </tr>
            <tr>
              <td [colSpan]="getColspan()">
                {{ payrollItem.description }} (Date Submitted: {{getShortDateFormatted(payrollItem.payableItem.dateSubmitted)}}{{payrollItem.payableItem.dateSubmittedDiff}})
              </td>
            </tr>
            <tr>
              <td [colSpan]="getColspan()">
                {{ payrollItem.manualEntryReason }}<ng-container *ngIf="payrollItem.manualEntryReason == 'Other'"> - {{payrollItem.manualEntryOtherReason}}</ng-container>
              </td>
            </tr>
          </table>
        </td>

      </tr>
    </ng-template>
    <ng-template pTemplate="summary">
      <span class="text-right mr-2">Total</span>
      <span>{{ totalTime }}</span>
    </ng-template>
  </p-table>
</ng-container>
