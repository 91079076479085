import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { markFormGroupTouched, updateFormGroupValidity } from '../../../../helpers/utils';
import { closeEditInternUploads, getInternUploads, saveInternUploads, saveInternUploadsSuccess } from '../../../../core/store/intern/intern.action';
import { InternUploadedFilesModel } from '../../../../models/internModels';

@Component({
  selector: 'app-upload-intern-doc',
  templateUrl: './upload-intern-doc.component.html',
  styleUrls: ['./upload-intern-doc.component.scss']
})
export class UploadInternDocComponent extends BaseComponent implements OnInit {
  display: boolean = false;
  title: string;
  form: FormGroup;
  disableButtons: boolean = true;
  applicationId: number = 0;
  loadingUploads: boolean = false;

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.form = this.createForm();
    this.subs.push(
      this.store.select(s => s.internState.editingUploads).subscribe(editing => {
        if (this.display && !editing || (editing && this.applicationId == 0)) {
          this.resetForm();
        }
        this.display = editing;

      }),
      this.store.select(s => s.internState.savingUploads).subscribe(saving => {
        this.disableButtons = saving;
      }),
      this.store.select(s => s.internState.loadingUploads).subscribe(loading => {
        this.loadingUploads = loading;
      }),
      this.store.select(s => s.internState.uploadApplicationId).subscribe(id => {
        this.applicationId = id;
        this.title = "";
        if (typeof id !== 'undefined' && id > 0) {
          this.store.dispatch(getInternUploads({ applicationId: id }));
        }
      }),
      this.store.select(s => s.internState.selectedUploads).subscribe(model => {
        if (model === null) {
          this.resetForm();
          return;
        }
        this.title = "Edit Uploads for Application #" + model.applicationId;
        this.form.get("applicationId").setValue(model.applicationId);

        this.resetUploadedFiles();                
        this.resetUploads();

        if (model.uploads != null && model.uploads.length > 0) {

          let uploads = this.form.get('uploads') as FormArray;
          model.uploads.forEach(u => {
            uploads.push(this.fb.group({
              id: this.fb.control(u.id),
              fileName: this.fb.control(u.fileName),
              fileAwsBucket: this.fb.control(u.fileAwsBucket),
              fileAwsKey: this.fb.control(u.fileAwsKey)
            }));
          });
        }
      }),                  
      this.actions$.pipe(
        ofType(saveInternUploadsSuccess),
        tap(action => {
          this.closeModal();
        })
      ).subscribe(),
    );
  }

  createForm() {
    let f = this.fb.group({
      applicationId: this.fb.control(0),            
      uploadedFiles: this.fb.array([]),
      uploads: this.fb.array([])
    });

    return f;
  }

  resetForm() {
    this.form.reset({
      applicationId: 0,      
    });    
    this.resetUploadedFiles();
    this.resetUploads();
  }

  resetUploads() {
    let files = this.form.get('uploads') as FormArray;
    files.clear();
  }

  resetUploadedFiles() {
    let files = this.form.get('uploadedFiles') as FormArray;
    files.clear();
  }

  closeModal() {
    this.store.dispatch(closeEditInternUploads());
  }

  saveUploads() {
    updateFormGroupValidity(this.form);
    if (this.form.valid) {
      this.store.dispatch(saveInternUploads({ uploads: this.form.value as InternUploadedFilesModel }));
    } else {
      markFormGroupTouched(this.form);
    }
  }

  removeUpload(index) {
    let uploads = this.form.get('uploads') as FormArray;
    uploads.removeAt(index);
    return false;
  }

  removeUploaded(index) {
    let uploads = this.form.get('uploadedFiles') as FormArray;
    uploads.removeAt(index);
    return false;
  }

  changedFile(fileInput) {
    let files = Object.assign([], fileInput.files);

    let uploadedFiles = this.form.get('uploadedFiles') as FormArray;
    files.forEach(f => {
      uploadedFiles.push(this.fb.control(f));
    });
    return false;
  }

  getUploads() {
    return (this.form.get('uploads') as FormArray).controls;
  }

  getUploadedFiles() {
    return (this.form.get('uploadedFiles') as FormArray).controls;
  }

}
