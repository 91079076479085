import { Component, OnInit } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { State } from '../../../../core/store';
import { closeEthicCodeModal, saveEthicCodes, saveEthicCodeSuccess } from '../../../../core/store/ethic-code/ethic-code.actions';
import { deepClone } from '../../../../helpers/utils';
import { EthicCodeModel } from '../../../../models/ethicCodeModels';
import { AlertService } from '../../../../shared/services/alert.service';

@Component({
  selector: 'app-ethic-code',
  templateUrl: './ethic-code.component.html',
  styleUrls: ['./ethic-code.component.scss']
})
export class EthicCodeComponent extends BaseComponent implements OnInit {
  display: boolean = false;
  savingEthicCode: boolean = false;
  ethicCode: EthicCodeModel;
  title: string;
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private alertService: AlertService) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.ethicCodeState.ethicCode).subscribe(ethicCode => {
        if (ethicCode !== null) {
          this.display = true;
          this.ethicCode = deepClone(ethicCode);
        }
        else {
          this.display = false;
          this.ethicCode = null;
        }
      }),
      this.store.select(s => s.ethicCodeState.savingEthicCode).subscribe(savingEthicCode => {
        this.savingEthicCode = savingEthicCode;
      }),
      this.actions$.pipe(
        ofType(saveEthicCodeSuccess),
        tap(action => {
          this.closeModal();
        })
      ).subscribe(),
    );
  }

  closeModal() {
    this.store.dispatch(closeEthicCodeModal());
  }

  saveEthicCode() {
    if (!this.ethicCode.section) {
      this.alertService.error("Ethics Code section cannot be empty.");
      return;
    }
    if (!this.ethicCode.description) {
      this.alertService.error("Description cannot be empty.");
      return;
    }

    this.store.dispatch(saveEthicCodes({ ethicCode: this.ethicCode }));
  }
}
