import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { CeuEventModel, CeuUploadModel } from '../../../../models/certificationModel';
import { DateRangeModel } from '../../../../models/dateRangeModel';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { DropdownFilterService } from '../../../../core/services/dropdown-filter.service';
import { Actions, ofType } from '@ngrx/effects';
import { State } from '../../../../core/store';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { AlertService } from '../../../../shared/services/alert.service';
import { deleteCeuEvent, deleteCeuEventSuccess, exportCeuEventListToExcel, exportCeuEventListToExcelSuccess, generateMissingCeuEventCertifications, generateMissingCeuEventCertificationsSuccess, getCeuEventFile, getCeuEventFileSuccess, getCeuEventList, loadCertificationTypes, loadCeuCategories, loadCeuTypes, loadInstructors, saveCeuEventSuccess, showEditCeuEvent } from '../../../../core/store/certification/certification.action';
import { deepClone, downloadFile } from '../../../../helpers/utils';

@Component({
  selector: 'app-list-ceu-event',
  templateUrl: './list-ceu-event.component.html',
  styleUrls: ['./list-ceu-event.component.scss']
})
export class ListCeuEventComponent extends BaseComponent implements OnInit {

  list: PagingResultsModel<CeuEventModel>;
  filter: any;

  loadingList: boolean = true;
  loadingExcel: boolean = false;
  loadingDownload: boolean = false;
  savingCeu: boolean = false;

  eventNameFilter: string = "";
  modalityFilter: string = "";

  prefixText: string;
  userEmployeeList: SelectItem[];
  userId: number = 0;

  instructorPrefixText: string;
  instructorList: SelectItem[];
  instructorId: number = 0;

  certificationTypes: SelectItem[];
  certificationTypeId: number = 0;

  ceuCategories: SelectItem[];
  ceuCategoryId: number = 0;

  eventDateRangeList: SelectItem[];
  eventDateRange: DateRangeModel;
  eventStartDate: string = null;
  eventEndDate: string = null;
  isCustomEventDateRange: boolean = false;

  showOnlyActiveEmployees: boolean = true;
  showOnlyActiveInstructors: boolean = true;
  canManage;
  downloadFileName: string;

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private dropdownFilterService: DropdownFilterService,
    private alertService: AlertService
  ) {
    super();
  }

  ngOnInit(): void {
    this.subs.push(
      this.store.select(s => s.certificationState.loadingCeuEventList).subscribe(loading => {
        this.loadingList = loading;
      }),
      this.store.select(s => s.certificationState.loadingCeuEventExcel).subscribe(loading => {
        this.loadingExcel = loading;
      }),
      this.store.select(s => s.certificationState.loadingCeuEventFile).subscribe(loading => {
        this.loadingDownload = loading;
      }),
      this.store.select(s => s.certificationState.savingCeuEvent).subscribe(loading => {
        this.savingCeu = loading;
      }),
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.canManage = permissionIDs && !!permissionIDs.find(p => p === 3112); //Manage Certifications
      }),
      this.store.select(s => s.certificationState.certificationTypes).subscribe(types => {
        if (types && types.length > 0) {
          this.certificationTypes = types.map(x => ({ label: x.label, value: x.value }));
          this.certificationTypes.unshift({ label: 'All', value: 0 });
        }
        else {
          this.certificationTypes = [{ label: 'loading...', value: undefined }]
        }
      }),
      this.store.select(s => s.certificationState.ceuCategories).subscribe(categories => {
        if (categories && categories.length > 0) {
          this.ceuCategories = categories.map(x => ({ label: x.label, value: x.value }));
          this.ceuCategories.unshift({ label: 'All', value: 0 });
        }
        else {
          this.ceuCategories = [{ label: 'loading...', value: undefined }]
        }
      }),
      this.store.select(s => s.employeeState.employeeUsers).subscribe(users => {
        this.userEmployeeList = users;
      }),
      this.store.select(s => s.certificationState.instructors).subscribe(instructors => {
        this.instructorList = instructors;
      }),
      this.store.select(s => s.certificationState.ceuEventList).subscribe(report => {
        this.list = report;
      }),
      this.actions$.pipe(
        ofType(saveCeuEventSuccess),
        tap(action => {
          this.reloadList();
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(deleteCeuEventSuccess),
        tap(action => {
          this.reloadList();
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(exportCeuEventListToExcelSuccess),
        tap(action => {
          downloadFile(action.doc, 'CEUEventReport.xlsx');
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(getCeuEventFileSuccess),
        tap(action => {
          downloadFile(action.doc, this.downloadFileName);
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(generateMissingCeuEventCertificationsSuccess),
        tap(action => {
          this.alertService.success("Certifications generated");
        })
      ).subscribe()
    );

    this.eventDateRangeList = this.dropdownFilterService.getLast120DayDateRanges().map(c => ({ label: c.text, value: c }));    
    this.store.dispatch(loadCeuCategories());
    this.store.dispatch(loadCeuTypes());
    this.store.dispatch(loadCertificationTypes());
  }

  changedEventDateRange() {
    if (this.eventDateRange.isCustom) {
      this.isCustomEventDateRange = true;
      return; //reload after manually changing date
    }

    this.isCustomEventDateRange = false;
    this.eventStartDate = this.eventDateRange.startDate;
    this.eventEndDate = this.eventDateRange.endDate;
    this.reloadList();
  }

  reloadFilter(event) {
    this.filter = event;
    this.reloadList();
  }

  reloadList() {
    this.setFilter();

    this.store.dispatch(getCeuEventList({
      filter: this.filter
    }));
  }

  getInstructorList(event) {
    this.store.dispatch(loadInstructors({
      prefixText: event.query,
      certificationTypeId: this.certificationTypeId,
      showArchived: true,
      showTerminated: true,
      showInactive: true
    }));
  }

  reloadListAfterInstructorChange(selectedInstructor) {
    if (typeof selectedInstructor.value !== 'number') {
      return;
    }

    this.instructorId = selectedInstructor.value;
    this.reloadList();
  }

  reloadListAfterInstructorClear(event) {
    if (event.data !== null) {
      return;
    }

    this.instructorId = 0;
    this.reloadList();
  }

  setFilter() {
    this.filter = deepClone(this.filter);    
    this.filter.filters.instructorId = { Name: 'instructorId', Value: { value: this.instructorId.toString() } };
    this.filter.filters.startDate = { Name: 'startDate', Value: { value: this.eventStartDate } };
    this.filter.filters.endDate = { Name: 'endDate', Value: { value: this.eventEndDate } };
    this.filter.filters.eventName = { Name: 'eventName', Value: { value: this.eventNameFilter } };
    this.filter.filters.modality = { Name: 'modality', Value: { value: this.modalityFilter } };
    this.filter.filters.certificationTypeId = { Name: 'certificationTypeId', Value: { value: this.certificationTypeId } };
    this.filter.filters.ceuCategoryId = { Name: 'ceuCategoryId', Value: { value: this.ceuCategoryId } };
  }

  exportToExcel() {
    if (this.loadingList || this.loadingExcel) {
      return;
    }
    this.setFilter();

    this.store.dispatch(exportCeuEventListToExcel({
      filter: this.filter
    }));
  }

  editEvent(ev) { 
    const eventId = typeof ev.ceuEventId !== 'undefined' ? ev.ceuEventId : 0
    this.store.dispatch(showEditCeuEvent({ eventId: eventId }));
  }  

  deleteEvent(list: CeuEventModel) {
    this.alertService.confirm('Delete Event',
      'Are you sure you want to delete this event?')
      .subscribe(
        answer => {
          if (answer) {
            this.store.dispatch(deleteCeuEvent({ eventId: list.ceuEventId }));
          }
        }, error => {
        }
      );
  }

  downloadUploadedFile(upload: CeuUploadModel) {
    if (!this.loadingDownload) {
      this.downloadFileName = upload.fileName;
      this.store.dispatch(getCeuEventFile({ uploadId: upload.id }));
    }
  }

  generateMissingCertifications(list: CeuEventModel) {
    this.store.dispatch(generateMissingCeuEventCertifications({ eventId: list.ceuEventId }));
  }

}
