import { loadProfile } from 'src/app/core/store/profile/profile.actions';
import { Router } from '@angular/router';
import { loadTimeCards } from 'src/app/core/store/timecard/timecard.actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, tap, catchError, filter } from 'rxjs/operators';
import { of, pipe } from 'rxjs';
import { errorHappened } from '../profile/profile.actions';
import { Store, Action } from '@ngrx/store';
import { State } from '..';
import { loadApprovedTime, loadInvalidTime, loadAdjustmentTime, loadPendingTime, loadApprovedTotals } from '../payroll/payroll.actions';
import {
  loadOfferLetterDoc, loadOfferLetterDocSuccess, signOfferLetterDoc,
  signOfferLetterDocSuccess, createOfferLetter, createOfferLetterSuccess,
  getOfferLettersList, getOfferLettersListSuccess, getOfferLetterPdf, getOfferLetterPdfSuccess,
  deleteOfferLetter, deleteOfferLetterSuccess, createOfferLetterFail, previewOfferLetter,
  previewOfferLetterSuccess, previewOfferLetterFail, getOfferLettersInfo, getOfferLettersInfoSuccess,
  saveOfferLetter, saveOfferLetterSuccess, saveOfferLetterFail, markOfferLetterSeen, markOfferLetterSeenSuccess, getOldOfferLetterPdf, getOldOfferLetterPdfSuccess, signOfferLetterDocFail, checkUsername, checkUsernameSuccess, getOfferletterAvailableForms, getOfferletterAvailableFormsSuccess
} from './offerletter.actions';
import { OfferLetterService } from '../../services/offerletter.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { downloadFile } from 'src/app/helpers/utils';

@Injectable()
export class OfferLetterEffects {
  constructor(
    private actions$: Actions,
    private offerLetterService: OfferLetterService,
    private store: Store<State>,
    private alertService: AlertService,
    private router: Router) {

  }

  loadOfferLetterDoc = createEffect(() => this.actions$.pipe(
    ofType(loadOfferLetterDoc),
    switchMap(action => {
      return this.offerLetterService.getOfferLetterDoc(action.offerLetterID).pipe(
        map(doc => loadOfferLetterDocSuccess({ doc })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  signOfferLetterDoc = createEffect(() => this.actions$.pipe(
    ofType(signOfferLetterDoc),
    switchMap(action => {
      return this.offerLetterService.signOfferLetterDoc(action.offerLetterID, action.signedFields).pipe(
        map(docSigned => signOfferLetterDocSuccess({ docSigned })),
        catchError(err => {
          this.store.dispatch(signOfferLetterDocFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));

  createOfferLetter = createEffect(() => this.actions$.pipe(
    ofType(createOfferLetter),
    switchMap(action => {
      return this.offerLetterService.createOfferLetter(action.offerLetterWithUser).pipe(
        map(doc => createOfferLetterSuccess({ doc })),
        catchError(err => {
          this.store.dispatch(createOfferLetterFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));

  createOfferLetterSuccess = createEffect(() => this.actions$.pipe(
    ofType(createOfferLetterSuccess),
    tap(action => {
      downloadFile(action.doc, 'OfferLetter.pdf');
      this.alertService.success(
        'The offer letter was created and an email was sent to the user with login instructions to digitally sign it.', false);
      this.router.navigate(['/offerletter/list']);
    })
  ), { dispatch: false });

  previewOfferLetter = createEffect(() => this.actions$.pipe(
    ofType(previewOfferLetter),
    switchMap(action => {
      return this.offerLetterService.previewOfferLetter(action.offerLetterWithUser).pipe(
        map(doc => previewOfferLetterSuccess({ doc })),
        catchError(err => {
          this.store.dispatch(previewOfferLetterFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));

  previewOfferLetterSuccess = createEffect(() => this.actions$.pipe(
    ofType(previewOfferLetterSuccess),
    tap(action => {
      downloadFile(action.doc, 'PreviewOfferLetter.pdf');
    })
  ), { dispatch: false });


  getOfferLettersList = createEffect(() => this.actions$.pipe(
    ofType(getOfferLettersList),
    switchMap(action => {
      return this.offerLetterService.getList(action.filter).pipe(
        map(offerLetterList => getOfferLettersListSuccess({ offerLetterList })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));


  getOfferLetterPdf = createEffect(() => this.actions$.pipe(
    ofType(getOfferLetterPdf),
    switchMap(action => {
      return this.offerLetterService.getOfferLetterPdf(action.offerLetterID).pipe(
        map(doc => getOfferLetterPdfSuccess({ doc })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  getOfferLetterPdfSuccess = createEffect(() => this.actions$.pipe(
    ofType(getOfferLetterPdfSuccess),
    tap(action => {
      downloadFile(action.doc, 'OfferLetter.pdf');
    })
  ), { dispatch: false });


  getOldOfferLetterPdf = createEffect(() => this.actions$.pipe(
    ofType(getOldOfferLetterPdf),
    switchMap(action => {
      return this.offerLetterService.getOldOfferLetterPdf(action.oldOfferLetterID).pipe(
        map(doc => getOldOfferLetterPdfSuccess({ doc })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  getOldOfferLetterPdfSuccess = createEffect(() => this.actions$.pipe(
    ofType(getOldOfferLetterPdfSuccess),
    tap(action => {
      downloadFile(action.doc, 'OfferLetter.pdf');
    })
  ), { dispatch: false });


  deleteOfferLetter = createEffect(() => this.actions$.pipe(
    ofType(deleteOfferLetter),
    switchMap(action => {
      return this.offerLetterService.deleteOfferLetter(action.offerLetterID).pipe(
        map(result => deleteOfferLetterSuccess()),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));


  getOfferLettersInfo = createEffect(() => this.actions$.pipe(
    ofType(getOfferLettersInfo),
    switchMap(action => {
      return this.offerLetterService.getOfferLetterInfo(action.offerLetterID).pipe(
        map(offerLetter => getOfferLettersInfoSuccess({ offerLetter })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));


  saveOfferLetter = createEffect(() => this.actions$.pipe(
    ofType(saveOfferLetter),
    switchMap(action => {
      return this.offerLetterService.saveOfferLetter(action.offerLetterWithUser).pipe(
        map(offerletterWithUser => saveOfferLetterSuccess({ offerletterWithUser })),
        catchError(err => {
          this.store.dispatch(saveOfferLetterFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));

  saveOfferLetterSuccess = createEffect(() => this.actions$.pipe(
    ofType(saveOfferLetterSuccess),
    tap(action => {
      this.alertService.success(
        'Saved', false);
      this.router.navigate(['/offerletter/list']);
    })
  ), { dispatch: false });

  checkUsername = createEffect(() => this.actions$.pipe(
    ofType(checkUsername),
    switchMap(action => {
      return this.offerLetterService.checkUsername(action.username).pipe(
        map(usernameTaken => checkUsernameSuccess({ usernameTaken })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  checkUsernameSuccess = createEffect(() => this.actions$.pipe(
    ofType(checkUsernameSuccess),
    tap(action => {
    })
  ), { dispatch: false });

  markOfferLetterSeen = createEffect(() => this.actions$.pipe(
    ofType(markOfferLetterSeen),
    switchMap(action => {
      return this.offerLetterService.markOfferLetterSeen(action.offerLetterID).pipe(
        map(offerLetter => markOfferLetterSeenSuccess({ offerLetter })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));


  markOfferLetterSeenSuccess = createEffect(() => this.actions$.pipe(
    ofType(markOfferLetterSeenSuccess),
    tap(action => {
      this.store.dispatch(loadProfile());
    })
  ), { dispatch: false });

  getOfferletterAvailableForms = createEffect(() => this.actions$.pipe(
    ofType(getOfferletterAvailableForms),
    switchMap(action => {
      return this.offerLetterService.getAvailableForms().pipe(
        map(availableForms => getOfferletterAvailableFormsSuccess({ availableForms })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));


}
