<div>
  <div class="w-100 text-center mb-3 d-sm-flex justify-content-center ">
    <span class="d-inline-flex align-items-center " >
      <h1>Form Builder</h1>
    </span>
  </div>
  <div class="row">
    <div class="col-12 col-sm-6 col-md-4 col-lg-2 mt-2 ">
      <div class="card pointer" (click)="openLink('/form-builder/forms')">
        <div class="card-body">
          <div class="text-center font-big-icon">
            <i class="far fa-file-invoice"></i><br />
            Forms
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-2 mt-2 ">
      <div class="card pointer" (click)="openLink('/form-builder/forms-assignment')">
        <div class="card-body">
          <div class="text-center font-big-icon">
            <i class="fal fa-file-user"></i>
            Forms Assignment
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
