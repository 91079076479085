<form class="card mt-2">
  <div class="card-body">
      <h2 class="mb-3">Survey Report</h2>
      <div class="row mb-3">
        <div class="col-2 d-flex align-items-center">
            <h5>Date range:</h5>
        </div>
        <div class="col-10 col-lg-5 ">
          <p-calendar inputStyleClass="form-control mt-2" [monthNavigator]="true" [yearNavigator]="true"
          styleClass="w-100" panelStyleClass="calendarPanel" [hourFormat]="12" [showTime]="false"
          id="dateRange" dateFormat="yy-mm-dd" name="dateRange" dataType="string" selectionMode="range" [hideOnDateTimeSelect]="true" [(ngModel)]="dateRange" (ngModelChange)="changedFilter()" ></p-calendar>
        </div>
      </div>
      <div class="row ">
        <div class="col-2 d-flex align-items-center">
            <h5> Survey Type:</h5>
        </div>
        <div class="col-10 col-lg-5 ">
          {{surveyTypeName}}
        </div>
      </div>

      <div class="row ">
        <div class="col-2 d-flex align-items-center">
            <h5> Mentor:</h5>
        </div>
        <div class="col-10 col-lg-5 ">
          {{mentorName}}
        </div>
      </div>

    <i *ngIf="loadingSurveyReportDetail" class="material-icons rotate text-muted">loop</i>
    <p-table  [value]="surveyReportDetail" [responsive]="true"
    [paginator]="true" [rows]="20" [totalRecords]="surveyReportDetail.length" [lazy]="false"
   >
        <ng-template pTemplate="header">
            <tr>
                <th [pSortableColumn]="'QuestionText'">Question  <p-sortIcon [field]="'QuestionText'"></p-sortIcon></th>
                <th [pSortableColumn]="'AnswerComment'">Comments  <p-sortIcon [field]="'AnswerComment'"></p-sortIcon></th>
                <th width="120px"  [pSortableColumn]="'Average'">Average <p-sortIcon [field]="'Average'"></p-sortIcon></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-question>
            <tr >
                <td>
                    <span class="ui-column-title">Question</span>
                    {{question.questionText}}
                </td>

                <td>
                  <span class="ui-column-title">Comments</span>
                  <ul>
                    <li *ngFor="let comment of question.comments | slice:0:3">
                      {{comment}}
                    </li>
                  </ul>
                </td>
                <td>
                  <span class="ui-column-title">Average</span>
                  {{question.average}}
                </td>
            </tr>
        </ng-template>
    </p-table>
  </div>
</form>
