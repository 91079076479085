import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { EmployeeReviewTemplateModel } from '../../../../models/employeeReviewTemplateModels';
import { SelectItem } from 'primeng/api';
import { loadJobTitles } from '../../../../core/store/profile/profile.actions';
import { KraTemplateComponentComponent } from '../template/kra-template-component/kra-template-component.component';
import {
  deleteEmployeeReviewTemplateDraft, deleteEmployeeReviewTemplateDraftSuccess, findEmployeeReviewTemplateDraft, findEmployeeReviewTemplateDraftSuccess, getScoreOptionsSets, getScoreTypes, getTemplate, getTemplateSuccess,
  getValueColors, loadCategories, removeKra, saveEmployeeReviewTemplate, saveEmployeeReviewTemplateDraft, saveEmployeeReviewTemplateSuccess
} from '../../../../core/store/employee-review-template/employee-review-template.actions';
import { AlertService } from '../../../../shared/services/alert.service';
import { deepClone, getShortDateFormatted } from '../../../../helpers/utils';
import { ModifiedSelectItem } from '../../../../models/utilModels';

@Component({
  selector: 'app-employee-review-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss']
})
export class EmployeeReviewTemplateComponent extends BaseComponent implements OnInit {
  canEdit: boolean;
  canView: boolean;
  loading: boolean;
  jobTitleList: ModifiedSelectItem[] = [{ label: 'loading...', value: undefined }];
  allJobTitleList: ModifiedSelectItem[] = [{ label: 'loading...', value: undefined }];
  scoreTypes;

  errors: string[];
  template: EmployeeReviewTemplateModel = {
    description: "",
    kras: [],
    scoreType: null,
    requiresOversightReview: false
  };
  templateId?: number;

  intervalAutoSave: any;
  draftId?: number;

  constructor(
    private store: Store<State>,
    private actions$: Actions,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService) {

    super();
    this.canEdit = true;
  }

  ngOnInit() {

    this.store.select(s => s.profileState.jobTitles).subscribe(jobTitles => {


      this.allJobTitleList = jobTitles && jobTitles.length > 0 ? jobTitles.map(c => ({ label: c.jobTitle, value: c.id, archived: c.archived }))
        : [{ label: 'loading...', value: undefined, archived: false }];

      this.getJobTitles();
    });


    this.errors = [];
    this.store.dispatch(loadJobTitles());
    this.store.dispatch(loadCategories());
    this.store.dispatch(getValueColors());
    this.store.dispatch(getScoreOptionsSets());
    this.store.dispatch(getScoreTypes());
    this.subs.push(
      this.store.select(s => s.employeeReviewTemplateState.loading).subscribe(loading => {
        this.loading = loading;
      }),
      this.actions$.pipe(
        ofType(removeKra),
        tap(action => {
          if (this.template.kras.some(s => s == action.kra)) {
            var index = this.template.kras.indexOf(action.kra);
            this.template.kras.splice(index, 1);
          }
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(saveEmployeeReviewTemplateSuccess),
        tap(action => {
          this.alertService.success('Template saved!');
          this.router.navigate(['/employee-review/template/list']);
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(getTemplateSuccess),
        tap(action => {
          this.template = deepClone(action.template);
          this.getJobTitles();
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(deleteEmployeeReviewTemplateDraftSuccess),
        tap(action => {
          this.startAutosave();
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(findEmployeeReviewTemplateDraftSuccess),
        tap(action => {
          if (action.templateDraft && action.templateDraft.draft) {

            this.alertService.confirm(`Restore draft.`, `Restore draft saved on ${getShortDateFormatted(action.templateDraft.date)}?`)
              .subscribe(
                answer => {
                  
                  if (answer) {
                    var draft = action.templateDraft.draft;
                    this.template = deepClone(draft);
                    this.getJobTitles();
                    this.startAutosave();

                  } else {
                    this.draftId = action.templateDraft.draftId;
                    this.deleteDraft();
                  }
                }, error => {
                }
              );
          }
          else {
            this.startAutosave();
          }
        })
      ).subscribe(),
      this.store.select(s => s.employeeReviewTemplateState.scoreTypes).subscribe(scoreTypes => {
        this.scoreTypes = scoreTypes;
        if (this.template.scoreType) {
          this.template.scoreType = scoreTypes.find(x => x.scoreTypeId === this.template.scoreType.scoreTypeId);
        }
      }),
    );

    this.route.params.subscribe(params => {

      this.templateId = parseInt(params.templateid, 10);
      if (this.templateId) {
        this.store.dispatch(getTemplate({ templateId: this.templateId }));
        this.store.dispatch(findEmployeeReviewTemplateDraft({ templateId: this.templateId }));
      } else {
        this.store.dispatch(findEmployeeReviewTemplateDraft({ templateId: null }));
      }
    });
    this.initForm();
  }

  initForm() {
    // Initialize the form
    if (this.template.kras == null)
      this.template.kras = [];
    if (this.template.kras.length == 0)
      this.addNewKra();

   
  }

  addNewKra() {
    this.template.kras.push({
      description: '',
      kra_id: null,
      kpis: [],
      measureables: [],
      corePlay: '',
      measurement: '',
      reason: '',
      optionSet: null
    });
  }

  save() {

    if (this.isValid()) {
      this.store.dispatch(saveEmployeeReviewTemplate({ template: this.template }));
    }
  }

  isValid() {
    this.errors = [];

    if (!this.template.jobTitleIDs || this.template.jobTitleIDs.length === 0) {
      this.errors.push("Job title is required.");
    }
    if (!this.template.kras || this.template.kras.length === 0) {
      this.errors.push("At least one KRA must be added.");
    }
    else if (this.template.kras.some(s => (!s.kpis || s.kpis.length === 0) && (!s.measureables || s.measureables.length === 0))) {
      this.errors.push("Each KRA must have at least one KPI or one measurable.");
    }
    else if (this.template.kras.some(s => s.kpis.some(k => !k.categories || k.categories.length === 0))) {
      this.errors.push("Each KPI must have at least one Category.");
    }
    else if (this.template.kras.some(s => s.kpis.some(k => k.categories.some(c => !c.measureables || c.measureables.length === 0)))) {
      this.errors.push("Each Category must have at least one Measureable.");
    }
    else if (this.template.kras.some(s =>
      s.kpis.some(k =>
        k.categories.some(c =>
          c.measureables.some(m =>
            !m.measureable || m.childrenMeasureables.some(
              cm => !cm.measureable)))) ||
      s.measureables.some(s => !s.measureable))) {
      this.errors.push("Measureable cannot be empty.");
    }

    return this.errors.length == 0;
  }

  startAutosave() {
    if (this.intervalAutoSave) {
      clearInterval(this.intervalAutoSave);
    }
    this.intervalAutoSave = setInterval(() => {
      if (!this.loading && this.template) {
        this.store.dispatch(saveEmployeeReviewTemplateDraft({ template: deepClone(this.template) }));
      }
    }, 10000);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.intervalAutoSave) {
      clearInterval(this.intervalAutoSave);
    }
  }

  deleteDraft() {
    if (this.draftId)
      this.store.dispatch(deleteEmployeeReviewTemplateDraft({ draftId: this.draftId }));
  }

  getJobTitles() {
    const jobTitleIds = this.template.jobTitleIDs ? this.template.jobTitleIDs : [];
    this.jobTitleList = this.allJobTitleList.filter(x => x.archived == false || jobTitleIds.includes(x.value));
  }
}

