import { deleteBenefitsDependent, deleteBenefitsDependentSuccess, loadAvailableBenefitPlans, loadBenfitPlanTypes, saveBenefitsDependent, saveBenefitsDependentSuccess, loadBenefitsDeclineReasons, loadEmployerContribution, getBenefitsFormDocumentsList } from './../../../../core/store/benefits/benefits.actions';
import { BaseComponent } from './../../../../core/abstracts/baseComponent';
import { BenefitsState, initBenefitsDependentForm } from './../../../../core/store/benefits/benefits.reducer';
import { BenefitPlanModel, BenefitsDependentModel, BenefitPlanTypeModel, BenefitPlanFullModel, BenefitPlanDeclineReasonModel, EmployerContributionModel, BenefitPlanDeclinedModel,  BenefitPlanEnrollmentSetModel, BenefitPlanEnrollmentModel, BenefitsSettingsModel } from './../../../../models/benefitsModels';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { Actions, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { MultiSelect } from 'primeng/multiselect';
import { AlertService } from '../../../../shared/services/alert.service';
import { addDays, deepClone, getAge, getAgeAtDate, getAllControlsErrors, getDateString, markFormGroupTouched, roundNumber, roundUp, updateFormGroupValidity } from '../../../../helpers/utils';
import { loadBenefitsDependents, loadBenefitsPlans, updateBenefitsDependentForm } from '../../../../core/store/benefits/benefits.actions';
import { FormGroup } from '@angular/forms';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { tap, filter } from 'rxjs/operators';
import { loadProfile } from 'src/app/core/store/profile/profile.actions';
import { UserProfileModel } from 'src/app/models/userProfileModel';
import { BenefitsService } from '../../../../core/services/benefits.service';

@Component({
  selector: 'app-benefits-enrollment-detail',
  templateUrl: './benefits-enrollment-detail.component.html',
  styleUrls: ['./benefits-enrollment-detail.component.scss']
})
export class BenefitsEnrollmentDetailComponent extends BaseComponent implements OnInit {

  _selectedBenefitPlans: BenefitPlanFullModel[];
  get selectedBenefitPlans(): BenefitPlanFullModel[] {
    return this._selectedBenefitPlans;
  }
  @Input() set selectedBenefitPlans(value: BenefitPlanFullModel[]) {
    this._selectedBenefitPlans = value;
    this.initAvailableBenefitTypes();
  }
  @Input() checkedDependent = {};
  @Input() rejectedBenefitPlanTypes: { type: BenefitPlanTypeModel, reason: BenefitPlanDeclineReasonModel }[] = [];
  @Input() plansYear;
  @Input() hasUpdatedProfile;
  
  @Input() activatedDate;
  @Input() lifeChangingEventDate;
  @Input() lifeChangingEventID;
  @Input() availableBenefitPlans: BenefitPlanFullModel[];
  @Input() previousBenefitPlanSet: BenefitPlanEnrollmentSetModel;
  @Input() benefitPlanEnrollmentSetID;
  
  @Output() benefitPlanEnrollmentSaved = new EventEmitter<BenefitPlanEnrollmentSetModel>();

  @Input() savingBenefitEnrollment: boolean;
  @Output() savingBenefitEnrollmentChange = new EventEmitter<boolean>();

  benefitPlanTypes: BenefitPlanTypeModel[];
  benefitsDependents: BenefitsDependentModel[];
  allBenefitPlanTypes: BenefitPlanTypeModel[];
  openedPlanDetail = false;
  planDetailToOpen = null;
  dependentsWithPlanTypes: { dependent: BenefitsDependentModel, planTypeIds: number[] }[];
  notSignedDocs;
  hasAnyDoc;
  profile: UserProfileModel;
  employerContribution: EmployerContributionModel;
  benefitsSettings: BenefitsSettingsModel;
  
  openedPlanEnroll = false;
  planTypeToEnroll: BenefitPlanTypeModel;
  availableBenefitPlansToEnroll : BenefitPlanFullModel[];
  currentSelectedPlanToEnroll: BenefitPlanFullModel;
  projectedDeferral = 0;

  showDeclineReason = false;
  decliningTypeId;
  selectedReason: BenefitPlanDeclineReasonModel;
  otherReason: string;
  declineReasons: BenefitPlanDeclineReasonModel[];

  // to be removed
  openEmployerContributionSummary;
  openWhatIsThis;

  constructor(
    private actions$: Actions,
    private alertService: AlertService,
    private confirmationService: ConfirmationService,
    private store: Store<State>,
    private router: Router,
    private benefitService: BenefitsService,
  ) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.benefitsState.benefitsDependentsList).subscribe(benefitsDependentsList => {
        if (benefitsDependentsList && benefitsDependentsList.length && this.checkedDependent) {
          this.benefitsDependents = benefitsDependentsList;

          this.dependentsWithPlanTypes = [];
          this.benefitsDependents.forEach(dep => {
            for (const typeId in this.checkedDependent) {
              if (this.checkedDependent[typeId][dep.id]) {
                let depWithTypes = this.dependentsWithPlanTypes.find(x => x.dependent.id === dep.id);
                if (!depWithTypes) {
                  depWithTypes = { dependent: dep, planTypeIds: [] };
                  this.dependentsWithPlanTypes.push(depWithTypes);
                }
                depWithTypes.planTypeIds.push(Number(typeId));
              }
            }
          });
        }
      }),

      this.store.select(s => s.benefitsState.benefitPlanTypesList).subscribe(benefitPlanTypesList => {
        if (benefitPlanTypesList) {
          this.allBenefitPlanTypes = benefitPlanTypesList;
          this.initAvailableBenefitTypes();
        }
      }),



      this.store.select(s => s.profileState.profile).subscribe(profile => {
        this.profile = profile;
      }),


      this.store.select(s => s.benefitsState.benefitsSettings).subscribe(benefitsSettings => {
        if (benefitsSettings) {
          this.benefitsSettings = benefitsSettings;
        }
      }),
      this.store.select(s => s.benefitsState.employerContribution).subscribe(employerContribution => {
        if (employerContribution) {
          this.employerContribution = employerContribution;
        }
      }),
      this.store.select(s => s.benefitsState.declineReasonsOptions).subscribe(declineReasonsOption => {
        if (declineReasonsOption) {
          this.declineReasons = declineReasonsOption;
        }
      }),

    );

    this.store.dispatch(getBenefitsFormDocumentsList({ userId: null }));
    this.store.dispatch(loadBenefitsDependents());
    this.store.dispatch(loadBenfitPlanTypes());
    this.store.dispatch(loadBenefitsDeclineReasons());
    this.store.dispatch(loadProfile());
  }

  getPlanTypeClass(planTypeID: number) {

    const baseClass = 'display-3 ';
    if (planTypeID === 9) { // CriticalIllness  
      return baseClass + 'far fa-procedures redicon';
    }
    if (planTypeID === 10) { // HospitalIndemnity  
      return baseClass + 'far fa-hospital-alt teal';
    }
    const planTypeName: string = this.getPlanTypeName(planTypeID);
    if (planTypeName === 'Medical') {
      return baseClass + 'fas fa-clinic-medical redicon';
    }
    if (planTypeName === 'Cash Rewards') {
      return baseClass + 'fas fa-badge-dollar greenicon';
    }
    if (planTypeName === 'Universal') {
      return baseClass + 'far fa-handshake';
    }
    if (planTypeName === 'Select Health (Prescription)') {
      return baseClass + 'fas fa-prescription-bottle-alt';
    }
    if (planTypeName === 'Dental') {
      return baseClass + 'far fa-tooth oceanblue';
    }
    if (planTypeName === 'Vision') {
      return baseClass + 'fas fa-glasses purple';
    }
    if (planTypeName === 'Accident') {
      return baseClass + 'far fa-hospital orange';
    }
    if (planTypeName === 'Supplemental Medical') {
      return baseClass + 'far fa-heartbeat';
    }
    if (planTypeName === 'Health Savings Account') {
      return baseClass + 'fas fa-badge-dollar greenicon';
    }
    if (planTypeName === 'Employee Perk') {
      return baseClass + 'fad fa-users-crown';
    }
  }

  getPlanOfType(planType: BenefitPlanTypeModel): BenefitPlanFullModel[] {
    return this.selectedBenefitPlans.filter(x => x.benefitPlan.benefitPlanTypeID === planType.id);
  }

  getPreviousPlanOfType(planType: BenefitPlanTypeModel): BenefitPlanEnrollmentModel[] {
    if (this.previousBenefitPlanSet && this.previousBenefitPlanSet.enrollments) {
      return this.previousBenefitPlanSet.enrollments
        .filter(x => x.benefitPlan.benefitPlan.benefitPlanTypeID === planType.id);
    }
    return null;
  }
  wasPreviousPlanOfTypeDeclined(planType: BenefitPlanTypeModel): boolean {
    if (this.previousBenefitPlanSet && this.previousBenefitPlanSet.declineds) {
      return this.previousBenefitPlanSet.declineds
        .some(x => x.benefitPlanTypeID === planType.id);
    }
    return false;
  }


  getDeclinedPlanOfType(planType: BenefitPlanTypeModel) {
    return this.rejectedBenefitPlanTypes.find(x => x.type.id === planType.id);
  }

  getCost(benefitPlan: BenefitPlanFullModel): number {


    if (benefitPlan.benefitPlan.benefitPlanTypeID == 7 ) { //'Health Savings Account'
      return (benefitPlan.enrollment.employeePerPayElection ? benefitPlan.enrollment.employeePerPayElection : 0)
        + (benefitPlan.enrollment.employerContribution ? benefitPlan.enrollment.employerContribution : 0);
    }

    if (benefitPlan.enrollment && benefitPlan.enrollment.id
      && benefitPlan.enrollment.costPerPayPeriod != null
      && benefitPlan.enrollment.costPerPayPeriod != undefined
      && this.activatedDate) {
      return Number(benefitPlan.enrollment.costPerPayPeriod);
    }

    if (!benefitPlan.costs) {
      return 0;
    }
    let CostCoverageId: number = this.getCostCoverageId(benefitPlan);
    let cost = benefitPlan.costs.find(x => x.coverageLevelID === CostCoverageId);
    while (!cost && CostCoverageId > 0) {
      CostCoverageId--;
      cost = benefitPlan.costs.find(x => x.coverageLevelID === CostCoverageId);
    }
    if (!cost) {
      return 0;
    }
    let monthlyCost = cost.monthlyCost;
    if (cost.costByAge) {
      let planStart = this.getEffectiveDate(benefitPlan);
      let age = (this.profile.employee.birthdate) ? getAgeAtDate(new Date(this.profile.employee.birthdate), planStart) : 81;
      
      monthlyCost =(cost.monthlyCost25 && age <= 25) ? cost.monthlyCost25 :
                        (cost.monthlyCost30 && age <= 30) ? cost.monthlyCost30:
                        (cost.monthlyCost35 && age <= 35) ? cost.monthlyCost35:
                        (cost.monthlyCost40 && age <= 40) ? cost.monthlyCost40:
                        (cost.monthlyCost45 && age <= 45) ? cost.monthlyCost45:
                        (cost.monthlyCost50 && age <= 50) ? cost.monthlyCost50:
                        (cost.monthlyCost55 && age <= 55) ? cost.monthlyCost55:
                        (cost.monthlyCost60 && age <= 60) ? cost.monthlyCost60:
                        (cost.monthlyCost65 && age <= 65) ? cost.monthlyCost65:
                        (cost.monthlyCost70 && age <= 70) ? cost.monthlyCost70:
                        (cost.monthlyCost75 && age <= 75) ? cost.monthlyCost75:
                        (cost.monthlyCost80 && age <= 80) ? cost.monthlyCost80:
                        cost.monthlyCost;
    }
    let costValue = roundUp(monthlyCost * 12 / 26);
    if (benefitPlan.enrollment && benefitPlan.enrollment.employerContribution && this.activatedDate) {
      costValue = costValue - benefitPlan.enrollment.employerContribution;
    }
    return costValue;
  }

  getCostCoverageId(benefitPlan: BenefitPlanFullModel) {

    if (benefitPlan.enrollment && benefitPlan.enrollment.id
      && !this.checkedDependent[benefitPlan.benefitPlan.benefitPlanTypeID]) {
      return benefitPlan.enrollment.coverageLevelID;
    }

    let hasSpouse = false;
    let hasChild = false;
    let dependentCount = 0;
    // tslint:disable-next-line:forin
    for (const i in this.checkedDependent[benefitPlan.benefitPlan.benefitPlanTypeID]) {
      if (this.checkedDependent[benefitPlan.benefitPlan.benefitPlanTypeID][i]) {
        dependentCount++;
        const dependent = this.benefitsDependents.find(x => (x.id + '') === i);
        if (dependent.relationship === 'Spouse') {
          hasSpouse = true;
        }
        if (dependent.relationship === 'Child' ||
          dependent.relationship === 'Legal Guardian') {
          hasChild = true;
        }
      }
    }

    let coverageLevelID;
    if (benefitPlan.costs.find(x => x.coverageLevelID === 5) && dependentCount === 1 && hasChild) {
      coverageLevelID = 5;
    } else if (hasSpouse && hasChild) {
      coverageLevelID = 4;
    } else if (hasChild) {
      coverageLevelID = 3;
    } else if (hasSpouse) {
      coverageLevelID = 2;
    } else {
      coverageLevelID = 1;
    }


    //Check if the coverage level has any cost, if not, get the family
    if (benefitPlan.costs && benefitPlan.costs.length) {
      // use employee+famaily if not found
      if (!benefitPlan.costs.find(x => x.coverageLevelID === coverageLevelID)
        && benefitPlan.costs.find(x => x.coverageLevelID === 4)) {
        return 4;
      }
    }
    return coverageLevelID;
  }
  getCostCoverageLabel(benefitPlan: BenefitPlanFullModel) {
    const CostCoverageId = this.getCostCoverageId(benefitPlan);

    if (CostCoverageId === 5) {
      return 'Employee + Child';
    } else if (CostCoverageId === 4) {
      return 'Employee + Family';
    } else if (CostCoverageId === 3) {
      return 'Employee + Children';
    } else if (CostCoverageId === 2) {
      return 'Employee + Spouse';
    } else {
      return 'Employee';
    }
  }

  getEffectiveDate(benefitPlan: BenefitPlanFullModel) {

    let d = new Date();
    if (benefitPlan.enrollment && benefitPlan.enrollment.dateStart) {
      d = new Date(benefitPlan.enrollment.dateStart);
    }
    else if (this.lifeChangingEventID == 2 && this.lifeChangingEventDate) {  // New Hire
      d = new Date(this.lifeChangingEventDate);
      d = new Date(d.getFullYear(), d.getMonth() + 1, 1);
    }

    else if (benefitPlan.benefitPlan.ellegibilityBeginID === 1 ) {  // New Hire
      d = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1); // '1st of the month after '
    }
    else if (benefitPlan.benefitPlan.ellegibilityBeginID === 2 && this.lifeChangingEventDate) { // event data
      d = new Date(this.lifeChangingEventDate);
    }

    if (d < new Date(benefitPlan.benefitPlan.dateStart)) {
      d = new Date(benefitPlan.benefitPlan.dateStart);
    }
    return d;

  }
  getEndDate(benefitPlan: BenefitPlanFullModel) {
    return new Date(benefitPlan.benefitPlan.dateEnd);
  }


  getPlanTypeName(id: number) {
    return this.allBenefitPlanTypes.find(x => x.id === id) ? this.allBenefitPlanTypes.find(x => x.id === id).planTypeName : '';
  }

  openPlanDetail(benefitPlan: BenefitPlanFullModel) {
    this.openedPlanDetail = true;
    this.planDetailToOpen = benefitPlan;
  }

  getDifferentPlanTypes() {
    const distinctBenefitPlanType = (value, index, self) => {
      return self.findIndex(x => x.benefitPlan.benefitPlanTypeID === value.benefitPlan.benefitPlanTypeID) === index;
    };
    return this.selectedBenefitPlans.filter(distinctBenefitPlanType).length;
  }

  getTotalCost() {
    return this.selectedBenefitPlans.reduce((sum, plan) => {
      const cost = this.getMyCost(plan);
      return sum + Number(cost);
    }, 0);
  }

  hsaPlan(): BenefitPlanFullModel {
    return this.selectedBenefitPlans.find(x => x.benefitPlan.benefitPlanTypeLabel === 'Health Savings Account');
  }


  initAvailableBenefitTypes() {
    if (this.availableBenefitPlans && this.availableBenefitPlans.length > 0
      && this.allBenefitPlanTypes && this.allBenefitPlanTypes.length > 0) {

      this.benefitPlanTypes = this.allBenefitPlanTypes
        .filter(type =>
          this.availableBenefitPlans
            .filter(x => !x.benefitPlan.dependOnPlanID || this.selectedBenefitPlans.find(y => y.benefitPlan.id === x.benefitPlan.dependOnPlanID))
            .find(plan => plan.benefitPlan.benefitPlanTypeID === type.id));
    }
  }
  


  dependentsOfPlan(plan: BenefitPlanFullModel) {
    const result = [];
    const objectWithDependentIds = this.checkedDependent[plan.benefitPlan.benefitPlanTypeID];
    for (const dependenId in objectWithDependentIds) {
      if (objectWithDependentIds[dependenId] && this.benefitsDependents.find(x => x.id.toString() === dependenId)) {
        result.push(this.benefitsDependents.find(x => x.id.toString() === dependenId));
      }
    }
    return result;
  }

  isTypeDeclined(typeId: number) {
    return !!this.rejectedBenefitPlanTypes.find(x => x.type.id === typeId);
  }

  
  


  getCanEditPlanOfType(planType: BenefitPlanTypeModel): boolean {
    if (this.activatedDate) {
      if (planType.id != 7)
        return false;
      var hsa = this.selectedBenefitPlans.filter(x => x.benefitPlan.benefitPlanTypeID === 7);
      if (!hsa || !hsa.length )
        return false
      return new Date(hsa[0].benefitPlan.dateEnd) > new Date();
    };
    return this.hasUpdatedProfile && planType.id !== 8; // 'Employee Perk';
    
  }


  openPlanEnroll(planType: BenefitPlanTypeModel) {

    this.planTypeToEnroll = planType;
    if (!this.checkedDependent[this.planTypeToEnroll.id + ''])  {
      this.checkedDependent[this.planTypeToEnroll.id + ''] = {};
    }
    this.currentSelectedPlanToEnroll = null;
    this.availableBenefitPlansToEnroll = [];

    this.selectedBenefitPlans.forEach(plan => {
      if (this.planTypeToEnroll && plan.benefitPlan.benefitPlanTypeID === this.planTypeToEnroll.id) {
        if (!this.currentSelectedPlanToEnroll) {
          this.selectPlan(plan);
        }
      }
    });

    //initAvailableBenefitPlansOfCurrentType
    if (this.availableBenefitPlans && this.planTypeToEnroll) {
      this.availableBenefitPlansToEnroll =
        this.availableBenefitPlans.filter(x => {
          if (this.activatedDate && this.currentSelectedPlanToEnroll) {
            // only the select plan
            if (x.benefitPlan.id != this.currentSelectedPlanToEnroll.benefitPlan.id)
              return false;
            return true;
          }

          if (x.benefitPlan.benefitPlanTypeID != this.planTypeToEnroll.id)
            return false;

          if (x.benefitPlan.dependOnPlanID) {
            if (!this.selectedBenefitPlans.find(y => y.benefitPlan.id === x.benefitPlan.dependOnPlanID)) {
              return false;
            }

          }

          if (!x.benefitPlan.active)
            return false;

          if (this.plansYear) {
            if (x.benefitPlan.dateStart && new Date(x.benefitPlan.dateStart).getFullYear() > this.plansYear)
              return false;
            if (x.benefitPlan.dateEnd && new Date(x.benefitPlan.dateEnd).getFullYear() < this.plansYear)
              return false;
          }
          else if (x.benefitPlan.dateEnd && new Date(x.benefitPlan.dateEnd) < new Date())
            return false;

          return true;
        });
    }

    this.openedPlanEnroll = true;

  }
  
  closePlanEnroll() {

    this.openedPlanEnroll = false;
   // this.alertService.error('Changes not saved');
  }

  shouldShowCoverageList() {
    return (this.planTypeToEnroll && this.planTypeToEnroll.id !== 7 // 'Health Savings Account'
      && this.planTypeToEnroll.id !== 8 // 'Employee Perk'
    );
  }
  shouldShowPlanList() {

    return (this.planTypeToEnroll
      && this.planTypeToEnroll.id !== 3 //'Universal'
      && this.planTypeToEnroll.id !== 8 // 'Employee Perk'
    );
  }

  isPlanSelected(benefitPlan: BenefitPlanFullModel) {
    return !!this.selectedBenefitPlans.find(x => x.benefitPlan.id === benefitPlan.benefitPlan.id);
  }


  selectPlan(benefitPlan: BenefitPlanFullModel) {
    if (this.currentSelectedPlanToEnroll === benefitPlan) {
      const filteredPlans = this.selectedBenefitPlans
        .filter(x => x.benefitPlan.benefitPlanTypeID !== benefitPlan.benefitPlan.benefitPlanTypeID);
      this.selectedBenefitPlans = filteredPlans;
      this.currentSelectedPlanToEnroll = null;

    } else {
      this.currentSelectedPlanToEnroll = benefitPlan;

      const filteredPlans = this.selectedBenefitPlans
        .filter(x => x.benefitPlan.benefitPlanTypeID !== benefitPlan.benefitPlan.benefitPlanTypeID);
      filteredPlans.push(benefitPlan);
      this.selectedBenefitPlans = filteredPlans;

      if (this.currentSelectedPlanToEnroll.benefitPlan.specialPercentageContribution) {
        this.selectedBenefitPlans.forEach(x => {
          if (x.enrollment && x.enrollment.employerContribution) {
            x.enrollment.employerContribution = 0;
          }
        });
      }

      this.rejectedBenefitPlanTypes = this.rejectedBenefitPlanTypes.filter(x => x.type.id !== this.planTypeToEnroll.id);
    }

    //check if there is any selected plan that should not be available anymore
    let shouldRemove = [];
    this.selectedBenefitPlans.forEach(selectedPlan => {
      if (selectedPlan.benefitPlan.dependOnPlanID) {
        if (!this.selectedBenefitPlans.find(y => y.benefitPlan.id === selectedPlan.benefitPlan.dependOnPlanID)) {
          shouldRemove.push(selectedPlan);
        }
      }
    });
    this.selectedBenefitPlans = this.selectedBenefitPlans.filter(x => !shouldRemove.find(y => x.benefitPlan.id === y.benefitPlan.id));
  }

  getIrsContributionLimit() {
    let total = this.benefitsSettings.irsContributionLimit;
    const medicalPlan = this.selectedBenefitPlans.find(x => x.benefitPlan.benefitPlanTypeLabel === 'Medical');
    if (!medicalPlan) return 0;
    const coverage = this.getCostCoverageId(medicalPlan);
    if (coverage > 1) { //Any above employee
      total = total * 2;
    }
    if (getAge(this.profile.employee.birthdate) >= 55) {
      total += 1000;
    }
    return total;
  }

  getPayperiodElegibleYearContribuion(value) {
    return (Math.floor((value / 26) * 100) / 100).toFixed(2);
  }
  getElegibleYearlyContribution(plan: BenefitPlanFullModel) {
    let total = plan.benefitPlan.elegibleYearlyContribution;
    const medicalPlan = this.selectedBenefitPlans.find(x => x.benefitPlan.benefitPlanTypeLabel === 'Medical');
    if (!medicalPlan) return 0;
    const coverage = this.getCostCoverageId(medicalPlan);
    if (coverage > 1) { //Any above employee
      total = total * 2;
    }
    if (getAge(this.profile.employee.birthdate) >= 55) {
      total += 1000;
    }
    return total;
  }
  changedEmployeePerPayElection() {
    const dateDiff = this.getEndDate(this.currentSelectedPlanToEnroll).valueOf() - this.getEffectiveDate(this.currentSelectedPlanToEnroll).valueOf();
    const dateDiffDays = (dateDiff / 1000 / 60 / 60 / 24);
    const dateDiffPayPeriod = Math.round(dateDiffDays / 14);
    this.projectedDeferral = (Number(this.currentSelectedPlanToEnroll.enrollment.employeePerPayElection ?
      this.currentSelectedPlanToEnroll.enrollment.employeePerPayElection : 0) +
      Number(this.currentSelectedPlanToEnroll.enrollment.employerContribution ?
        this.currentSelectedPlanToEnroll.enrollment.employerContribution : 0)) * dateDiffPayPeriod;
  }
  hasSelectedPlanWithSpecialPercentage() {
    return !!this.selectedBenefitPlans.find(x => !!x.benefitPlan.specialPercentageContribution);
  }
  getSpecialPercentageContributionTotal(plan: BenefitPlanFullModel) {
    const costEmployee = plan.costs.find(x => x.coverageLevelID === 1).monthlyCost * 12 / 26; // 'Employee'

    return costEmployee * plan.benefitPlan.specialPercentageContribution / 100;
  }

  getRemainingEmployerContribution() {
    return (this.hasSelectedPlanWithSpecialPercentage() ? 0 : this.employerContribution.employerContribution) - this.totalUsedEmployerContribution();
  }
  totalUsedEmployerContribution() {
    let total = 0;
    this.selectedBenefitPlans.forEach(x => {
      if (x.enrollment && x.enrollment.employerContribution) {
        total += x.enrollment.employerContribution;
      }
    });
    return total;
  }
  getMyCost(plan: BenefitPlanFullModel) {
    if (plan && plan.enrollment && plan.enrollment.employerContribution && !this.activatedDate) {
      return Number(this.getCost(plan) - plan.enrollment.employerContribution);
    }
    if (plan && plan.benefitPlan.specialPercentageContribution && !this.activatedDate) {
      return Number(this.getCost(plan) - this.getSpecialPercentageContributionTotal(plan));
    }
    return this.getCost(plan);
  }
  shouldDisableSave() {
    if (!this.currentSelectedPlanToEnroll) {
      return true;
    }


    if (this.savingBenefitEnrollment) {
      return true;
    }
    return false;
  }

  currentRejected() {
    return this.planTypeToEnroll &&  this.rejectedBenefitPlanTypes.find(x => x.type.id === this.planTypeToEnroll.id);
  }
  confirmSelectedPlanToEnroll() {
    if (!this.currentSelectedPlanToEnroll) {
      this.alertService.error('Please select a plan or decline coverage.');
      return;
    }
    if (this.getRemainingEmployerContribution() < -0.00999999999) {
      this.alertService.error('You cannot use more employer contribution than available');
      return;
    }
    if (!this.currentRejected() && this.getMyCost(this.currentSelectedPlanToEnroll) < -0.00999999999) {
      this.alertService.error('You cannot use more employer contribution than the cost of the plan');
      return;
    }

    if (this.planTypeToEnroll.id === 7
      && this.projectedDeferral > this.getElegibleYearlyContribution(this.currentSelectedPlanToEnroll)) {
      this.alertService.error('You cannot exceed the Eligible Yearly Contribution');
      return;
    }

    if (!this.currentRejected() && this.currentSelectedPlanToEnroll.benefitPlan.disclosure) {
      this.confirmationService.confirm({
        header: 'Disclosure',
        message: this.currentSelectedPlanToEnroll.benefitPlan.disclosure,
        acceptLabel: 'Agree',
        rejectLabel: 'Cancel',
        accept: () => {
          this.saveAndSignEnrollment(false);
        }
      });
    } else {
      this.saveAndSignEnrollment(false);
    }
  }

  openPlanReject(planType: BenefitPlanTypeModel) {
    //this.openedPlanEnroll = false;
    this.decliningTypeId = null;
    this.planTypeToEnroll = planType;
    if (!this.checkedDependent[this.planTypeToEnroll.id + '']) {
      this.checkedDependent[this.planTypeToEnroll.id + ''] = {};
    }
    this.showDeclineReason = true;
  }
  cancelRejectCurrentPlan() {
    this.showDeclineReason = false;
    //if (this.planTypeToEnroll)
     //   this.openPlanEnroll(this.planTypeToEnroll);
  }
  confirmRejectCurrentPlan() {
    const typeId = this.decliningTypeId ? this.decliningTypeId : this.planTypeToEnroll.id;
    const planType = this.decliningTypeId ? this.allBenefitPlanTypes.find(x => x.id === this.decliningTypeId) : this.planTypeToEnroll;
    //this.showDeclineReason = false;
    // Erase dependents
    if (this.benefitsDependents) {
      this.benefitsDependents.forEach(x => {
        this.checkedDependent[typeId][x.id] = false;
      });
    }
    
    this.selectedBenefitPlans = this.selectedBenefitPlans.filter(x => x.benefitPlan.benefitPlanTypeID !== typeId);

    let reason = (this.selectedReason.reason == 'Other') ? { id: this.selectedReason.id, reason: this.otherReason } : this.selectedReason;
    if (!this.rejectedBenefitPlanTypes.find(x => x.type.id === typeId)) {
      this.rejectedBenefitPlanTypes.push({ type: planType, reason: reason });
    } else {
      this.rejectedBenefitPlanTypes.find(x => x.type.id === typeId).reason = reason;
    }

    //check if there is any selected plan that should not be available anymore
    let shouldRemove = [];
    this.selectedBenefitPlans.forEach(selectedPlan => {
      if (selectedPlan.benefitPlan.dependOnPlanID) {
        if (!this.selectedBenefitPlans.find(y => y.benefitPlan.id === selectedPlan.benefitPlan.dependOnPlanID)) {
          shouldRemove.push(selectedPlan);
        }
      }
    });
    this.selectedBenefitPlans = this.selectedBenefitPlans.filter(x => !shouldRemove.find(y => x.benefitPlan.id === y.benefitPlan.id));


    this.selectedReason = null;
    this.otherReason = null;
    this.decliningTypeId = null;
    this.saveAndSignEnrollment(false);
  }

  saveAndSignEnrollment(generateDocs  = true) {
    if (!this.savingBenefitEnrollment) {

      const enrollmentSet: BenefitPlanEnrollmentSetModel = {
        id: this.benefitPlanEnrollmentSetID,
        enrollments: [],
        declineds: [],
        generateDocs: generateDocs,
        plansYear: this.plansYear
      };

      this.selectedBenefitPlans.forEach(plan => {
        const employerContribution = plan.enrollment ? plan.enrollment.employerContribution : 0;
        const enrollment: BenefitPlanEnrollmentModel = {
          id: plan.enrollment ? plan.enrollment.id : null,
          dateStart: getDateString(this.getEffectiveDate(plan)),
          dateEnd: (plan.benefitPlan.dateEnd)?getDateString(new Date(plan.benefitPlan.dateEnd)): null,
          benefitPlanID: plan.benefitPlan.id,
          costPerPayPeriod: this.getMyCost(plan),
          coverageLevelID: this.getCostCoverageId(plan),
          employeePerPayElection: plan.benefitPlan.benefitPlanTypeID === 7 ? (plan.enrollment.employeePerPayElection) : null,
          employerContribution: employerContribution,
          dependents: [],
        };

        for (const dependentID in this.checkedDependent[plan.benefitPlan.benefitPlanTypeID]) {
          if (this.checkedDependent[plan.benefitPlan.benefitPlanTypeID][dependentID]) {
            const dependent = this.benefitsDependents.find(x => x.id === Number(dependentID));
            enrollment.dependents.push(dependent);
          }
        }
        if (plan.benefitPlan.dateStart)
          enrollmentSet.plansYear = new Date(plan.benefitPlan.dateStart).getFullYear();
        enrollmentSet.enrollments.push(enrollment);
      });
      var rejectedDateStart = new Date();
      if (this.plansYear > new Date().getFullYear())
        rejectedDateStart = new Date(this.plansYear, 0, 1);
      else if (this.lifeChangingEventID == 2) // new hire
        rejectedDateStart = new Date(rejectedDateStart.getFullYear(), rejectedDateStart.getMonth() + 1, 1);
      else if (this.lifeChangingEventDate)
        rejectedDateStart = new Date(this.lifeChangingEventDate);

      this.rejectedBenefitPlanTypes.forEach(planType => {
        const declined: BenefitPlanDeclinedModel = {
          dateStart: getDateString(rejectedDateStart),
          dateEnd: getDateString(new Date(this.plansYear, 11, 31)),
          benefitPlanTypeID: planType.type.id,
          declineReasonID: planType.reason.id,
          otherReason: planType.reason.reason
        };
        enrollmentSet.declineds.push(declined);
      });

      this.savingBenefitEnrollment = true;
      this.savingBenefitEnrollmentChange.emit(true);

      this.benefitService.postBenefitsEnrollment(enrollmentSet)
        .subscribe(x => {
          this.benefitPlanEnrollmentSaved.emit(x);
          this.showDeclineReason = false;
          this.openedPlanEnroll = false;
          this.savingBenefitEnrollment = false;
          this.savingBenefitEnrollmentChange.emit(false);
        }, (error) => {
          this.savingBenefitEnrollment = false;
          this.savingBenefitEnrollmentChange.emit(false);
          this.alertService.error('Error saving enrollment.' + (error.message || error));
        });


      //this.store.dispatch(saveBenefitsEnrollment({ enrollmentSet }));
    }
  }
}


