import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'src/app/core/store';
import { loadProfile, getPhoto } from 'src/app/core/store/profile/profile.actions';
import { UserProfileModel } from 'src/app/models/userProfileModel';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';

@Component({
  selector: 'app-form-builder-home',
  templateUrl: './form-builder-home.component.html',
  styleUrls: ['./form-builder-home.component.scss']
})
export class FormBuilderHomeComponent extends BaseComponent implements OnInit {

  profile: UserProfileModel;

  constructor(
    private store: Store<State>,
    private router: Router,
  ) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.profileState.profile).subscribe(profile => {
        this.profile = profile;
      }),
    );

    this.store.dispatch(loadProfile());
  }


  openLink(path) {
    this.router.navigate([path]);
  }

}
