import { createImprovementPlanSuccess, updateCreateImprovementPlanReviewForm, createImprovementPlanReviewSuccess, createImprovementPlanReview } from './../../../../core/store/improvementPlan/improvementPlan.actions';
import { ImprovementPlanModel, ImprovementPlanReviewModel } from './../../../../models/improvementPlanModel';
import {
  updateCreateCoachingLogForm,
  createCoachingLog, getCoachableUsersList
} from './../../../../core/store/coachingLog/coahingLog.actions';
import { getUsersList } from './../../../../core/store/users/users.actions';
import { SelectItem } from 'primeng/api';
import { UserModel } from 'src/app/models/userModel';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { FormGroup } from '@angular/forms';
import { getAllControlsErrors, markFormGroupTouched, deepClone, getShortDateFormatted } from 'src/app/helpers/utils';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { Router, ActivatedRoute } from '@angular/router';
import { initImprovementPlanForm, initImprovementPlanReviewForm } from 'src/app/core/store/improvementPlan/improvementPlan.reducer';
import { updateCreateImprovementPlanForm, createImprovementPlan, getImprovementPlan, clearImprovementPlan } from 'src/app/core/store/improvementPlan/improvementPlan.actions';
import { ofType, Actions } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-create-improvementplanreview',
  templateUrl: './create-improvementplanreview.component.html',
  styleUrls: ['./create-improvementplanreview.component.scss']
})
export class CreateImprovementPlanReviewComponent extends BaseComponent implements OnInit {

  improvementPlan: ImprovementPlanModel;
  improvementPlanReview: ImprovementPlanReviewModel;
  improvementPlanID: number;
  improvementPlanReviewID: number;
  form: FormGroup;
  canAddCoach: boolean;
  loadingUsersList = false;
  usersList: SelectItem[] = [{ label: 'loading...', value: undefined }];
  allUsersList: SelectItem[] = [{ label: 'loading...', value: undefined }];
  creatingImprovementPlanReview = false;
  initedForm = false;
  yesNoOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];
  campusesHash;

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private router: Router,
    private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {


    this.subs.push(
      this.store.select(s => s.improvementPlanState.improvementPlanReview).subscribe(improvementPlanReview => {
        if (this.improvementPlanReviewID && !this.initedForm) {
          const improvementPlanReviewClone: ImprovementPlanReviewModel = deepClone(improvementPlanReview);
          this.initForm(improvementPlanReviewClone);
        }
        this.improvementPlanReview = improvementPlanReview;
      }),

      this.store.select(s => s.payrollApprovalState.campuses).subscribe(campuses => {
        const campusesList = campuses && campuses.length > 0 ? campuses.map(c => ({ label: c.programName, value: c.id }))
          : [{ label: 'loading...', value: undefined }];

        //this.campusesList = campusesList;
        this.campusesHash = {};
        campuses.forEach(c => {
          this.campusesHash[c.id] = c.programName;
        });

      }),
    );

    this.route.params.subscribe(params => {
      if (params.improvementPlanID) {
        this.improvementPlanID = params.improvementPlanID;
        this.store.dispatch(getImprovementPlan({ improvementPlanId: this.improvementPlanID }));
      }
      if (params.improvementPlanReviewID) {
        this.improvementPlanReviewID = params.improvementPlanReviewID;
        //this.store.dispatch(getImprovementPlanReview({ improvementPlanReviewId: this.improvementPlanReviewID }));
      }
    });

    if (!this.improvementPlanReviewID) {
      const emptyImprovementPlanReview = {} as ImprovementPlanReviewModel;
      this.initForm(emptyImprovementPlanReview);
      this.improvementPlanReview = emptyImprovementPlanReview;
      //this.store.dispatch(clearImprovementPlanReview());
    }
  }

  initForm(improvementPlanReviewClone: ImprovementPlanReviewModel) {
    this.initedForm = true;
    // Initialize the form
    this.form = initImprovementPlanReviewForm(improvementPlanReviewClone);


    this.subs.push(

      this.store.select(s => s.improvementPlanState.improvementPlan).subscribe(improvementPlan => {
        this.improvementPlan = improvementPlan;
        if (this.improvementPlan) {
          this.form.get('improvementPlanReview.improvementPlanID').setValue(improvementPlan.id);
        }
      }),
      // Update form values on the store
      this.form.valueChanges.subscribe(formValues => {
        const improvementPlanReviewValues = this.form.get('improvementPlanReview').value;
        const formErrorsReview = getAllControlsErrors(this.form);
        this.store.dispatch(updateCreateImprovementPlanReviewForm({
          improvementPlanReview: improvementPlanReviewValues,
          formErrorsReview
        }));
      }),

      // Check if user is an HR manager
      this.store.select(s => s.accountState.user.permissionIDs).subscribe(permissionIDs => {
        this.canAddCoach = permissionIDs && !!permissionIDs.find(p => p === 6004 || p === 6005 || p === 6006);
      }),
      this.store.select(s => s.improvementPlanState.creatingImprovementPlanReview).subscribe(creatingImprovementPlanReview => {
        this.creatingImprovementPlanReview = creatingImprovementPlanReview;
      }),
      this.actions$.pipe(
        ofType(createImprovementPlanReviewSuccess),
        tap(action => {
          this.router.navigate(['/improvementplan/view/' + this.improvementPlanID]);
        })
      ).subscribe(),
    );
  }

  getForm() {
    return this.form;
  }


  save() {
    if (this.form.valid) {
      this.store.dispatch(createImprovementPlanReview({ improvementPlanReviewModel: this.improvementPlanReview }));
    } else {
      markFormGroupTouched(this.form);
    }
  }

  getFormatedDate(date) {
    if (date) {
      return getShortDateFormatted(new Date(date));
    }
  }

}

