import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { Actions, ofType } from '@ngrx/effects';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { closeTerminateEmployee, loadSeparationSubReasons, loadTerminationReasons, saveTerminationReason, saveTerminationReasonSuccess, updateTerminateEmployeeForm } from '../../../../core/store/employee/employee.action';
import { FormGroup, } from '@angular/forms';
import { TerminatedEmployeeModel} from '../../../../models/userProfileModel';
import { SelectItem } from 'primeng/api';
import { getAllControlsErrors, markFormGroupTouched, updateFormGroupValidity } from '../../../../helpers/utils';
import { initTerminateEmployee } from '../../../../core/store/employee/employee.reducer';
import { tap } from 'rxjs/operators';
import { MultiSelect } from 'primeng/multiselect';
import { ModifiedSelectItem } from '../../../../models/utilModels';

@Component({
  selector: 'app-termination-reason',
  templateUrl: './termination-reason.component.html',
  styleUrls: ['./termination-reason.component.scss']
})
export class TerminationReasonComponent extends BaseComponent implements OnInit {
  display: boolean = false;
  terminateUser: TerminatedEmployeeModel;
  title: string;
  form: FormGroup;

  reasonsList: SelectItem[];
  allSubReasonsList: ModifiedSelectItem[];
  availableSubReasonsList: ModifiedSelectItem[];

  selectedSubReasons;

  disableButtons: boolean = false;
  constructor(
    private actions$: Actions,
    private store: Store<State>,
  ) {
    super();
  }

  ngOnInit() {
    this.form = initTerminateEmployee({} as TerminatedEmployeeModel);
    this.subs.push(
      this.store.select(s => s.employeeState.terminatingEmployee).subscribe(terminatingEmployee => {
        this.display = terminatingEmployee;
      }),
      this.store.select(s => s.employeeState.terminationReasons).subscribe(reasons => {
        this.reasonsList = reasons;
      }),
      this.store.select(s => s.employeeState.separationSubReasons).subscribe(reasons => {
        this.allSubReasonsList = reasons;
      }),
      this.store.select(s => s.employeeState.terminateUser).subscribe(e => {
        this.title = "Separate Employee";
        if (e === null) {
          this.terminateUser = null;
          return;
        }

        const changedUser = !this.terminateUser || (this.terminateUser.userId !== e.userId);
        this.terminateUser = JSON.parse(JSON.stringify(e));

        if (changedUser) {
          this.form = initTerminateEmployee(this.terminateUser);
          this.subs.push(
            this.form.valueChanges.subscribe(formValues => {
              const formErrors = getAllControlsErrors(this.form);
              this.store.dispatch(updateTerminateEmployeeForm({ formValues, formErrors }));
            })
          )
          this.title = "Separate Employee - " + this.terminateUser.firstName + " " + this.terminateUser.lastName;
          this.store.dispatch(updateTerminateEmployeeForm({ formValues: this.form.value, formErrors: getAllControlsErrors(this.form) }));
        }
      }),
      this.actions$.pipe(
        ofType(saveTerminationReasonSuccess),
        tap(action => {
          this.closeModal();
        })
      ).subscribe(),
    );

    this.store.dispatch(loadTerminationReasons());
    this.store.dispatch(loadSeparationSubReasons());
  }

  closeModal() {
    this.store.dispatch(closeTerminateEmployee());
  }

  saveTerminationReason() {
    updateFormGroupValidity(this.form);
    if (this.form.valid) {
      this.store.dispatch(saveTerminationReason({ employee: this.terminateUser }));
    } else {
      markFormGroupTouched(this.form);
    }
  }

  openMultiSelect(multiselect: MultiSelect) {
    multiselect.show();
  }

  changedReason() {
    this.availableSubReasonsList = this.allSubReasonsList.filter((r) => r.filterId === this.form.value.terminationReasonId);    
  }
}
