import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { SelectItem } from 'primeng/api';
import { deepClone, downloadFile } from '../../../../helpers/utils';
import { DateRangeModel } from '../../../../models/dateRangeModel';
import {
  exportBenefitPlanEDITransmissionData, exportBenefitPlanEDITransmissionDataSuccess,
  getBenefitPlanEDITransmissionMembers, getBenefitPlanEDITransmissionMembersSuccess,
  putBenefitPlanEDITransmissionSuccess, putBenefitPlanEDITransmission
} from '../../../../core/store/benefitPlanEDI/benefitPlanEDI.action';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { BenefitPlanEDITransmissionModel } from '../../../../models/benefitPlanEDITransmissionModel';
import { tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { BenefitPlanEDIMemberInformationModel } from '../../../../models/benefitPlanEDIMemberInformationModel';
import { AlertService } from '../../../../shared/services/alert.service';

@Component({
  selector: 'app-transmission-benefitPlanEDI',
  templateUrl: './transmission-benefitPlanEDI.component.html',
  styleUrls: ['./transmission-benefitPlanEDI.component.scss']
})
 
export class TransmissionBenefitPlanEDIComponent extends BaseComponent implements OnInit {
  filter: any = {};
  list: PagingResultsModel<BenefitPlanEDIMemberInformationModel>;
  filteredList: BenefitPlanEDIMemberInformationModel[] = [];

  loadingList: boolean = true;
  exportingExcel: boolean = false;
  uploadingFile: boolean = false;


  filterType: string = "Current";
  dateSent: Date = null;
  filename: string = null;
  transmissionId: number = null;
  carrier: string = null;
  

  
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router,) {
    super();
  }

  ngOnInit() {
    this.subs.push(

      this.store.select(s => s.benefitPlanEDIState.loadingTransmissionMembers).subscribe(loading => {
        this.loadingList = loading || this.list == null;
      }),
      this.store.select(s => s.benefitPlanEDIState.exportingExcel).subscribe(loading => {
        this.exportingExcel = loading;
      }),
      this.store.select(s => s.benefitPlanEDIState.transmissionMembers).subscribe(list => {
        this.list = list;
        this.loadingList = false;
        this.filterTypeChanged();
      }),
      this.store.select(s => s.benefitPlanEDIState.uploadingFile).subscribe(loading => {
        this.uploadingFile = loading;
      }),
      this.actions$.pipe(
        ofType(putBenefitPlanEDITransmissionSuccess),
        tap((action) => {
          console.info(action);
          this.alertService.success(`File uploaded!
          ${action.transmission.description}
          ${action.transmission.notes}`);
          this.reloadList(this.filter);
        })
      ).subscribe(),

      this.actions$.pipe(
        ofType(exportBenefitPlanEDITransmissionDataSuccess),
        tap(action => {
          downloadFile(action.doc, (this.transmissionId > 0 && this.list?.additionalProperties?.fileName != null ? `${this.list?.additionalProperties?.fileName}.xlsx` : `${this.carrier}Subscribers.xlsx`));
        })
      ).subscribe()
    );


    this.route.params.subscribe(params => {
      if (params.carrier)
        this.carrier = params.carrier;
      if (params.transmissionId) {
        this.transmissionId = parseInt(params.transmissionId);
      }
    });
  }  


  reloadList(event) {
    this.filter = event;
    this.filter = deepClone(this.filter || { filters: {} });


    this.filter.filters.transmissionId = { Name: 'transmissionId', Value: { value: this.transmissionId } };
    this.filter.filters.carrier = { Name: 'carrier', Value: { value: this.carrier } };

    this.store.dispatch(getBenefitPlanEDITransmissionMembers({
      filter: this.filter
    }));
  }


  formatSSN(ssn: string) {
    if (ssn.length == 9)
      return ssn.substring(0,3) + '-' + ssn.substring(3,5) + '-' + ssn.substring(5,9);
    return ssn;
  }


  filterTypeChanged() {
    var today = new Date();
    var year = today.getFullYear();
    //this.filteredList = this.list?.result;
    if (this.list && this.list.result) {
      if (this.transmissionId > 0)
        this.filteredList = this.list?.result;
      else if (this.filterType == "Current")
        this.filteredList = this.list.result.filter(x => new Date(x.benefitBeginDate).getFullYear() <= year && (!x.benefitEndDate || new Date(x.benefitEndDate) >= today ));
      else if (this.filterType == "Removed")
        this.filteredList = this.list.result.filter(x => x.benefitEndDate &&  new Date(x.benefitEndDate) < today); // && x.employmentEndDate != null
      else if (this.filterType == "OpenEnrolment")
        this.filteredList = this.list.result.filter(x => new Date(x.benefitBeginDate).getFullYear() > year);
      else
        this.filteredList = this.list?.result;
    }
  }

  exportToExcel() {

    this.store.dispatch(exportBenefitPlanEDITransmissionData({
      carrier: this.carrier,
      transmissionId: this.transmissionId||0
    }));
  }

  uploadFile() {

    this.store.dispatch(putBenefitPlanEDITransmission({
      carrier: this.carrier
    }));
  }
}
