import { getProspectiveCandidatesList } from './../../../../core/store/prospective-candidates/prospective-candidate.actions';
import { getOfferLettersInfo, saveOfferLetter, clearOfferletter, getOldOfferLetterPdf, getOfferLetterPdf, checkUsername, getOfferletterAvailableForms } from './../../../../core/store/offerletter/offerletter.actions';
import { ActivatedRoute } from '@angular/router';
import { getUsersList, getUsersReportingList } from './../../../../core/store/users/users.actions';
import { SelectItem } from 'primeng/api';
import { UserModel } from 'src/app/models/userModel';
import { OfferLetterModel, OfferLetterWithUserModel } from 'src/app/models/offerletterModel';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { FormGroup, RequiredValidator, Validators } from '@angular/forms';
import { getAllControlsErrors, markFormGroupTouched, deepClone, getShortDateFormatted } from 'src/app/helpers/utils';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { initCreateOfferletterForm } from 'src/app/core/store/offerletter/offerletter.reducer';
import { updateCreateOfferletterForm, createOfferLetter, previewOfferLetter } from 'src/app/core/store/offerletter/offerletter.actions';
import { MultiSelectModule, MultiSelect } from 'primeng/multiselect';
import { loadCampuses } from 'src/app/core/store/payroll-approval/payroll-approval.actions';
import { loadJobTitles } from 'src/app/core/store/profile/profile.actions';
import { formatDate } from "@angular/common";
import { clearRehireEmployee } from '../../../../core/store/employee/employee.action';
import { ProspectiveCandidateModel } from './../../../../models/prospectiveCandidateModel';
import { Dropdown } from 'primeng/dropdown';
import { ModifiedSelectItem } from '../../../../models/utilModels';

@Component({
  selector: 'app-create-offerletter',
  templateUrl: './create-offerletter.component.html',
  styleUrls: ['./create-offerletter.component.scss']
})
export class CreateOfferLetterComponent extends BaseComponent implements OnInit {


  form: FormGroup;
  canEdit: boolean;
  canView: boolean;
  canEditBehaviorTechs: boolean;
  canEditBehaviorTechsAA: boolean;
  canEditOnlyBehaviorTechs: boolean;
  canEditOnlyBehaviorTechsAA: boolean;
  total = 0;
  total12 = 0;
  offerletterWithUser: OfferLetterWithUserModel;
  loadingUsersList = false;
  usersList: SelectItem[] = [{ label: 'loading...', value: undefined }];
  usersReportingList: SelectItem[] = [{ label: 'loading...', value: undefined }];
  creatingOfferletter = false;
  previewingOfferletter = false;
  offerLetterID;
  initedForm;
  campusesList: SelectItem[] = [{ label: 'loading...', value: undefined }];
  jobTitleList: ModifiedSelectItem[] = [{ label: 'loading...', value: undefined }];
  otherJobTitleID: number;
  rehireUser: UserModel
  candidatesList: SelectItem[] = [{ label: 'loading...', value: undefined }];
  prospectiveCandidatesList: ProspectiveCandidateModel[];
  usernameTaken: boolean;
  formOfferlettersOptions: SelectItem[] = [{ label: 'loading...', value: undefined }];
  allJobTitlesList: ModifiedSelectItem[] = [] as ModifiedSelectItem[];

  constructor(
    private store: Store<State>,
    private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.offerLetterState.offerletterWithUser).subscribe(offerletterWithUser => {
        this.offerletterWithUser = offerletterWithUser;
        if (this.offerLetterID && !this.initedForm) {
          const offerletterWithUserClone = deepClone(offerletterWithUser);
          offerletterWithUserClone.offerLetter.validUntilDate = new Date(offerletterWithUserClone.offerLetter.validUntilDate)
            .toISOString().substr(0, 10);
          if (offerletterWithUserClone.offerLetter.orientationDate) {
            offerletterWithUserClone.offerLetter.orientationDate = new Date(offerletterWithUserClone.offerLetter.orientationDate)
              .toISOString().substr(0, 10);
          }
          offerletterWithUserClone.offerLetter.salaryOrPayRate = offerletterWithUserClone.offerLetter.salaryOrPayRate ? 'true' : 'false';
          this.initForm(offerletterWithUserClone);
        }
      }),
      this.store.select(s => s.employeeState.rehireUser).subscribe(user => {
        this.rehireUser = user;
      }),
      this.store.select(s => s.offerLetterState.usernameTaken).subscribe(taken => {
        this.usernameTaken = taken;
      }),

    );
    this.store.dispatch(loadCampuses());
    this.store.dispatch(loadJobTitles());

    this.route.params.subscribe(params => {
      if (params.offerLetterID) {
        this.store.dispatch(clearRehireEmployee());
        this.offerLetterID = params.offerLetterID;
        this.store.dispatch(getOfferLettersInfo({ offerLetterID: params.offerLetterID }));
      }
    });

    if (!this.offerLetterID) {
      const emptyOfferLetterWitUser = { offerLetter: { existingUser: this.rehireUser != null, userID: this.rehireUser != null ? this.rehireUser.id : null } as OfferLetterModel, user: this.rehireUser || {} as UserModel } as OfferLetterWithUserModel;

      let prospectiveCandidateID;
      this.route.queryParams.subscribe(queryParams => {
        if (queryParams.prospectiveCandidateID) {
          prospectiveCandidateID = queryParams.prospectiveCandidateID;
        }
      });
      if (prospectiveCandidateID) {
        emptyOfferLetterWitUser.offerLetter.prospectiveCandidateID = parseInt(prospectiveCandidateID, 10);
        this.store.dispatch(getProspectiveCandidatesList());
      }

      this.initForm(emptyOfferLetterWitUser);
      this.offerletterWithUser = emptyOfferLetterWitUser;
      this.store.dispatch(clearOfferletter({ prospectiveCandidateID }));
    }
  }

  initForm(offerLetterWithUser: OfferLetterWithUserModel) {
    this.initedForm = true;
    // Initialize the form
    this.form = initCreateOfferletterForm(offerLetterWithUser);

    let startValues = true;
    if (offerLetterWithUser.offerLetter.id) {
      startValues = false;

      if (this.form.get('offerLetter.typePosition').value === 'Administrative') {
        this.form.get('offerLetter.requiredBilledHours').disable();
      }
    }
    // Set the starting value of the checkboxes
    this.checkChanged('offerLetter.studentLoanRepaymentCheck', startValues);
    this.checkChanged('offerLetter.cellphoneStipendCheck', startValues);
    this.checkChanged('offerLetter.gasCardCheck', startValues);
    this.checkChanged('offerLetter.flexibleScheduleCheck', startValues);
    this.checkChanged('offerLetter.healthBenefitAnnualCostCheck', startValues);
    this.checkChanged('offerLetter.relocationStipendCheck', startValues);
    this.checkChanged('offerLetter.eletronicDevicesCheck', startValues);
    this.checkChanged('offerLetter.receivingSupervisionCheck', startValues);
    this.checkChanged('offerLetter.tuitionAssistanceBenefitCheck', startValues);
    this.checkChanged('offerLetter.otherBenefitNameCheck', startValues);
    this.checkChanged('offerLetter.salaryPayRateAfterMonthsCheck', startValues);
    this.checkChanged('offerLetter.regulatoryOversightCheck', startValues);
    this.checkChanged('offerLetter.bonusByTypePositionCheck', startValues);
    this.checkChanged('offerLetter.ptoCheck', startValues);
    this.checkChanged('offerLetter.holidayCheck', startValues);
    this.checkChanged('offerLetter.professionalLiabilityInsuranceCheck', startValues);
    this.checkChanged('offerLetter.plan401kCheck', startValues);
    this.checkChanged('offerLetter.trainingRateCheck', startValues);
    this.checkChanged('offerLetter.medClinicCheck', startValues);
    this.checkChanged('offerLetter.mentalHealthCheck', startValues);
    this.checkChanged('offerLetter.gymCheck', startValues);
    this.checkChanged('offerLetter.hasIncentivePrograms', startValues);
    this.processExistingUserCheck();

    if (offerLetterWithUser.offerLetter.id) {
      this.total = this.calculateTotal();
      this.total12 = this.calculateTotal(true);
    }

    this.store.dispatch(getUsersList());
    this.store.dispatch(getUsersReportingList());
    this.store.dispatch(getOfferletterAvailableForms());

    this.subs.push(
      // Update values based on Type Position
      this.form.get('offerLetter.typePosition').valueChanges.subscribe(value => {
        this.typePositionChanged();
      }),
      this.form.get('user.username').valueChanges.subscribe(value => {
        this.store.dispatch(checkUsername({ username: value }))
      }),
      // Update form values on the store
      this.form.valueChanges.subscribe(formValues => {
        const offerValues = this.form.get('offerLetter').value;
        const userValues = this.form.get('user').value;
        const formErrors = getAllControlsErrors(this.form);
        this.store.dispatch(updateCreateOfferletterForm({ offerValues, userValues, formErrors }));

        this.total = this.calculateTotal();
        this.total12 = this.calculateTotal(true);
      }),

      // Check changes on the checkboxes
      this.form.get('offerLetter.studentLoanRepaymentCheck').valueChanges.
        subscribe(values => this.checkChanged('offerLetter.studentLoanRepaymentCheck')),

      this.form.get('offerLetter.cellphoneStipendCheck').valueChanges.
        subscribe(values => this.checkChanged('offerLetter.cellphoneStipendCheck')),

      this.form.get('offerLetter.gasCardCheck').valueChanges.
        subscribe(values => this.checkChanged('offerLetter.gasCardCheck')),

      this.form.get('offerLetter.flexibleScheduleCheck').valueChanges.
        subscribe(values => {
          this.checkChanged('offerLetter.flexibleScheduleCheck');
          this.update401kPtoAndHoliday();
        }),

      this.form.get('offerLetter.bonusByTypePositionCheck').valueChanges.
        subscribe(values => {
          this.checkChanged('offerLetter.bonusByTypePositionCheck');
          if (this.form.get('offerLetter.bonusByTypePositionCheck').value) {
            this.typePositionChanged(true);
          }
          this.update401kPtoAndHoliday();
        }),



      this.form.get('offerLetter.healthBenefitAnnualCostCheck').valueChanges.
        subscribe(values => this.checkChanged('offerLetter.healthBenefitAnnualCostCheck')),

      this.form.get('offerLetter.relocationStipendCheck').valueChanges.
        subscribe(values => this.checkChanged('offerLetter.relocationStipendCheck')),

      this.form.get('offerLetter.eletronicDevicesCheck').valueChanges.
        subscribe(values => this.checkChanged('offerLetter.eletronicDevicesCheck')),

      this.form.get('offerLetter.receivingSupervisionCheck').valueChanges.
        subscribe(values => this.checkChanged('offerLetter.receivingSupervisionCheck')),

      this.form.get('offerLetter.tuitionAssistanceBenefitCheck').valueChanges.
        subscribe(values => this.checkChanged('offerLetter.tuitionAssistanceBenefitCheck')),

      this.form.get('offerLetter.otherBenefitNameCheck').valueChanges.
        subscribe(values => this.checkChanged('offerLetter.otherBenefitNameCheck')),

      this.form.get('offerLetter.salaryPayRateAfterMonthsCheck').valueChanges.
        subscribe(values => this.checkChanged('offerLetter.salaryPayRateAfterMonthsCheck')),


      this.form.get('offerLetter.regulatoryOversightCheck').valueChanges.
        subscribe(values => this.checkChanged('offerLetter.regulatoryOversightCheck')),


      this.form.get('offerLetter.ptoCheck').valueChanges.
        subscribe(values => this.checkChanged('offerLetter.ptoCheck')),

      this.form.get('offerLetter.holidayCheck').valueChanges.
        subscribe(values => this.checkChanged('offerLetter.holidayCheck')),

      this.form.get('offerLetter.professionalLiabilityInsuranceCheck').valueChanges.
        subscribe(values => this.checkChanged('offerLetter.professionalLiabilityInsuranceCheck')),

      this.form.get('offerLetter.plan401kCheck').valueChanges.
        subscribe(values => this.checkChanged('offerLetter.plan401kCheck')),

      this.form.get('offerLetter.trainingRateCheck').valueChanges.
        subscribe(values => this.checkChanged('offerLetter.trainingRateCheck')),
      this.form.get('offerLetter.medClinicCheck').valueChanges.
        subscribe(values => this.checkChanged('offerLetter.medClinicCheck')),

      this.form.get('offerLetter.mentalHealthCheck').valueChanges.
        subscribe(values => this.checkChanged('offerLetter.mentalHealthCheck')),

      this.form.get('offerLetter.gymCheck').valueChanges.
        subscribe(values => this.checkChanged('offerLetter.gymCheck')),

      this.form.get('offerLetter.hasIncentivePrograms').valueChanges.
      subscribe(values => this.checkChanged('offerLetter.hasIncentivePrograms')),

      this.form.get('offerLetter.flexibleSchedule').valueChanges.
        subscribe(values => {
          this.update401kPtoAndHoliday();
        }),

      this.form.get('offerLetter.salaryPayRate').valueChanges.
        subscribe(values => {
          this.update401kPtoAndHoliday();
        }),


      this.form.get('offerLetter.salaryOrPayRate').valueChanges.
        subscribe(values => {
          if (!this.form.get('offerLetter.salaryOrPayRate').value || this.form.get('offerLetter.salaryOrPayRate').value === 'false') {
            if (!this.form.get('offerLetter.holiday').value) {
              this.form.get('offerLetter.holiday').setValue(0);
              this.form.get('offerLetter.holidayCheck').setValue(false);
            }
            if (!this.form.get('offerLetter.trainingRate').value) {
              this.form.get('offerLetter.trainingRate').setValue(0);
              this.form.get('offerLetter.trainingRateCheck').setValue(false);
            }
            this.update401kPtoAndHoliday();
          } else {
            this.form.get('offerLetter.holidayCheck').setValue(true);
            this.form.get('offerLetter.trainingRate').setValue(0);
            this.form.get('offerLetter.trainingRateCheck').setValue(false);
            this.update401kPtoAndHoliday();
          }
        }),

      this.form.get('offerLetter.ptoPercentage').valueChanges.
        subscribe(values => {
          this.update401kPtoAndHoliday();
        }),

      this.form.get('offerLetter.existingUser').valueChanges.subscribe(values => {
        this.processExistingUserCheck();
      }),

      this.form.get('offerLetter.prospectiveCandidateID').valueChanges.subscribe(values => {
        if (this.form.get('offerLetter.prospectiveCandidateID')) {
          //fill candidate info into offerletter user info
          const prospectiveCandidateID = this.form.get('offerLetter.prospectiveCandidateID').value;
          const prospectiveCandidate = this.prospectiveCandidatesList.find(x => x.id === prospectiveCandidateID);
          this.form.get('user.firstName').setValue(prospectiveCandidate.firstName);
          this.form.get('user.lastName').setValue(prospectiveCandidate.lastName);
          this.form.get('user.personalEmail').setValue(prospectiveCandidate.email);
          let jobTitle = this.jobTitleList.find(x => x.label === prospectiveCandidate.position);
          if (!jobTitle) {
            jobTitle = this.allJobTitlesList.find(x => x.label === prospectiveCandidate.position);
          }
          if (jobTitle) {
            this.form.get('offerLetter.jobTitleID').setValue(jobTitle.value);
          }
        }
      }),
      this.form.get('offerLetter.prospectiveCandidate').valueChanges.subscribe(values => {
        if (this.form.get('offerLetter.prospectiveCandidate').value) {
          this.store.dispatch(getProspectiveCandidatesList());
        } else {
          //TODO: erase candidate info and offerletter user info
          this.form.get('user.firstName').setValue('');
          this.form.get('user.lastName').setValue('');
          this.form.get('user.personalEmail').setValue('');
          this.form.get('offerLetter.jobTitleID').setValue(null);
          this.form.get('offerLetter.prospectiveCandidate').setValue(false);
        }
      }),
      // Check if user is an HR manager
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.canEdit = permissionIDs && !!permissionIDs.find(p => p === 3062); //manage Offer letter
        this.canView = permissionIDs && !!permissionIDs.find(p => p === 3062 || p === 3061); //manage offer letter, view offer letter
        this.canEditBehaviorTechs = permissionIDs && !!permissionIDs.find(p => p === 3065) // view offer letter, manage offer letter for behavior techs and RBT
        this.canEditBehaviorTechsAA = permissionIDs && !!permissionIDs.find(p => p === 3066) // view offer letter, manage offer letter for behavior techs and RBT
        this.canEditOnlyBehaviorTechs = this.canEditBehaviorTechs && !this.canView;
        this.canEditOnlyBehaviorTechsAA = this.canEditBehaviorTechsAA && !this.canView;
        this.subs.push(
          this.store.select(s => s.profileState.jobTitles).subscribe(jobTitles => {
            if (jobTitles && jobTitles.length > 0) {
              this.allJobTitlesList = jobTitles.map(c => ({ label: c.jobTitle, value: c.id, archived: c.archived }));
              this.otherJobTitleID = jobTitles.find(x => x.jobTitle === 'Other') ? jobTitles.find(x => x.jobTitle === 'Other').id : -1;
              if (this.form.get('offerLetter.jobTitleID').value > 0) {
                this.getJobTitles();
              }
            }
            else{
              this.otherJobTitleID = -1;
              this.allJobTitlesList = [{ label: 'loading...', value: 0 }];
            }                                               
          }),
        );
      }),
      this.store.select(s => s.accountState.user.programIDs).subscribe(programIDs => {
        if (programIDs || this.canEdit) {
          this.subs.push(
            this.store.select(s => s.payrollApprovalState.campuses).subscribe(campuses => {
              const campusesList = campuses && campuses.length > 0 ? campuses.filter(x => this.canEdit || programIDs.includes(x.id)).map(c => ({ label: c.programName, value: c.programName }))
                : [{ label: 'loading...', value: undefined }];
              this.campusesList = campusesList;
            }),
          );
        }
      }),
      this.store.select(s => s.offerLetterState.creatingOfferletter).subscribe(creatingOfferletter => {
        this.creatingOfferletter = creatingOfferletter;
      }),

      this.store.select(s => s.offerLetterState.previewingOfferletter).subscribe(previewingOfferletter => {
        this.previewingOfferletter = previewingOfferletter;
      }),

      this.store.select(s => s.usersState.usersList).subscribe(usersList => {
        this.usersList = usersList ? usersList.map(u => ({ label: u.firstName + ' ' + u.lastName, value: u.id }))
          : [{ label: 'loading...', value: undefined }];
        this.usersList.unshift({ label: '', value: null });
      }),
      this.store.select(s => s.usersState.usersReportingList).subscribe(usersReportingList => {
        this.usersReportingList = usersReportingList ? usersReportingList.map(u => ({ label: u.firstName + ' ' + u.lastName, value: u.id }))
          : [];
      }),

      this.store.select(s => s.prospectiveCandidateState.prospectiveCandidatesList).subscribe(prospectiveCandidatesList => {
        this.prospectiveCandidatesList = prospectiveCandidatesList;
        this.candidatesList = prospectiveCandidatesList ?
          prospectiveCandidatesList.map(u => ({ label: u.firstName + ' ' + u.lastName, value: u.id }))
          : [{ label: 'loading...', value: undefined }];        
        if (this.offerletterWithUser.offerLetter.prospectiveCandidateID) {
          const prospectiveCandidate = this.prospectiveCandidatesList.find(x => x.id == this.offerletterWithUser.offerLetter.prospectiveCandidateID);
          if (prospectiveCandidate) {
            this.form.get('user.firstName').setValue(prospectiveCandidate.firstName);
            this.form.get('user.lastName').setValue(prospectiveCandidate.lastName);
            this.form.get('user.personalEmail').setValue(prospectiveCandidate.email);
            let jobTitle = this.jobTitleList.find(x => x.label === prospectiveCandidate.position);
            if (!jobTitle) {
              jobTitle = this.allJobTitlesList.find(x => x.label === prospectiveCandidate.position);
            }
            if (jobTitle) {
              this.form.get('offerLetter.jobTitleID').setValue(jobTitle.value);
              this.getJobTitles();
            }
          }
        }
      }),

      this.store.select(s => s.offerLetterState.availableForms).subscribe(availableForms => {
        if (availableForms) {
          this.formOfferlettersOptions = availableForms.map(f => ({ label: f.name, value: f.id }));
          this.formOfferlettersOptions.unshift({ label: 'Default Offer Letter', value: null });

        }

      }),

    );
    this.getJobTitles();
  }

  isDraft() {
    return (this.offerLetterID > 0
      && !this.offerletterWithUser.offerLetter.documentToSignID);
  }
  processExistingUserCheck() {
    if (!this.form.get('offerLetter.existingUser').value && (!this.offerletterWithUser || !this.offerletterWithUser.offerLetter.documentToSignID)) {
      if (this.isDraft() == false) {
        this.form.get('user.id').setValue(0);
        this.form.get('user.id').clearValidators();
        this.form.get('user.id').updateValueAndValidity();
      }
      this.form.get('user.personalEmail').setValidators([Validators.required, Validators.email]);
      this.form.get('user.personalEmail').updateValueAndValidity();
      this.form.get('user.username').setValidators([Validators.required]);
      this.form.get('user.username').updateValueAndValidity();
      this.form.get('user.firstName').setValidators([Validators.required]);
      this.form.get('user.firstName').updateValueAndValidity();
      this.form.get('user.lastName').setValidators([Validators.required]);
      this.form.get('user.lastName').updateValueAndValidity();
      this.form.get('user.password').setValidators([Validators.required]);
      this.form.get('user.password').updateValueAndValidity();

    } else {
      this.form.get('user.id').setValidators([Validators.required, Validators.min(1)]);
      this.form.get('user.id').updateValueAndValidity();
      this.form.get('user.personalEmail').clearValidators();
      this.form.get('user.personalEmail').updateValueAndValidity();
      this.form.get('user.username').clearValidators();
      this.form.get('user.username').updateValueAndValidity();
      this.form.get('user.firstName').clearValidators();
      this.form.get('user.firstName').updateValueAndValidity();
      this.form.get('user.lastName').clearValidators();
      this.form.get('user.lastName').updateValueAndValidity();
      this.form.get('user.password').clearValidators();
      this.form.get('user.password').updateValueAndValidity();
    }
  }

  typePositionChanged(onlyBonusTypeValue = false) {
    const typePosition = this.form.get('offerLetter.typePosition').value;
    switch (typePosition) {
      case 'Administrative': {
        if (!onlyBonusTypeValue) {
          this.form.get('offerLetter.hasIncentivePrograms').setValue(true);
          this.form.get('offerLetter.bonusByTypePositionCheck').setValue(true);
          this.form.get('offerLetter.requiredBilledHours').setValue(0);
          this.form.get('offerLetter.requiredBilledHours').disable();
        }
        this.form.get('offerLetter.bonusByTypePosition').setValue(1500);
        break;
      }
      case 'Psychologist':
      case 'Mental Health Clinician': {
        if (!onlyBonusTypeValue) {
          this.form.get('offerLetter.hasIncentivePrograms').setValue(true);
          this.form.get('offerLetter.bonusByTypePositionCheck').setValue(true);
          this.form.get('offerLetter.requiredBilledHours').enable();
          if (typePosition === 'Mental Health Clinician') {
            this.form.get('offerLetter.requiredBilledHours').setValue(80);
          } else {
            this.form.get('offerLetter.requiredBilledHours').setValue(75);
          }
          this.form.get('offerLetter.professionalLiabilityInsuranceCheck').setValue(true);
          this.form.get('offerLetter.professionalLiabilityInsurance').setValue(300);
          this.form.get('offerLetter.flexibleScheduleCheck').setValue(true);
        }
        this.form.get('offerLetter.bonusByTypePosition').setValue(4000);
        break;
      }
      case 'Behavior Clinician': {
        if (!onlyBonusTypeValue) {
          this.form.get('offerLetter.hasIncentivePrograms').setValue(true);
          this.form.get('offerLetter.bonusByTypePositionCheck').setValue(true);
          this.form.get('offerLetter.requiredBilledHours').enable();
          this.form.get('offerLetter.requiredBilledHours').setValue(90);
          this.form.get('offerLetter.professionalLiabilityInsuranceCheck').setValue(true);
          this.form.get('offerLetter.professionalLiabilityInsurance').setValue(300);
          this.form.get('offerLetter.flexibleScheduleCheck').setValue(true);
        }
        this.form.get('offerLetter.bonusByTypePosition').setValue(4000);
        break;
      }
      case 'Behavior Technician': {
        if (!onlyBonusTypeValue) {
          this.form.get('offerLetter.hasIncentivePrograms').setValue(true);
          this.form.get('offerLetter.bonusByTypePosition').setValue(0);
          this.form.get('offerLetter.bonusByTypePositionCheck').setValue(false);
          this.form.get('offerLetter.requiredBilledHours').enable();
          this.form.get('offerLetter.flexibleScheduleCheck').setValue(true);

          if (typePosition === 'Behavior Technician') {
            this.form.get('offerLetter.requiredBilledHours').setValue(95);
          } else {
            this.form.get('offerLetter.requiredBilledHours').setValue(90);
          }

        } else {
          this.form.get('offerLetter.bonusByTypePosition').setValue(1000);
        }
        break;
      }
      case 'Dually-Credentialed': {
        if (!onlyBonusTypeValue) {
          this.form.get('offerLetter.hasIncentivePrograms').setValue(true);
          this.form.get('offerLetter.bonusByTypePositionCheck').setValue(true);
          this.form.get('offerLetter.requiredBilledHours').enable();
          this.form.get('offerLetter.requiredBilledHours').setValue(85);
          this.form.get('offerLetter.professionalLiabilityInsuranceCheck').setValue(true);
          this.form.get('offerLetter.professionalLiabilityInsurance').setValue(300);
          this.form.get('offerLetter.flexibleScheduleCheck').setValue(true);
        }
        this.form.get('offerLetter.bonusByTypePosition').setValue(4000);
        break;
      }
      case 'Director': {
        if (!onlyBonusTypeValue) {
          this.form.get('offerLetter.hasIncentivePrograms').setValue(true);
          this.form.get('offerLetter.bonusByTypePositionCheck').setValue(true);
          this.form.get('offerLetter.requiredBilledHours').setValue(0);
          this.form.get('offerLetter.requiredBilledHours').disable();
          this.form.get('offerLetter.professionalLiabilityInsuranceCheck').setValue(true);
          this.form.get('offerLetter.professionalLiabilityInsurance').setValue(300);
          this.form.get('offerLetter.flexibleScheduleCheck').setValue(true);
        }
        this.form.get('offerLetter.bonusByTypePosition').setValue(4000);
        break;
      }
    }
  }

  update401kPtoAndHoliday() {
    const plan401kPtoAndHoliday = this.get401kPtoAndHoliday();

    if (this.form.get('offerLetter.plan401kCheck').value) {
      this.form.get('offerLetter.plan401k').setValue(plan401kPtoAndHoliday.plan401k);
    }
    if (this.form.get('offerLetter.ptoCheck').value) {
      this.form.get('offerLetter.pto').setValue(plan401kPtoAndHoliday.pto);
    }
    if (this.form.get('offerLetter.holidayCheck').value) {
      this.form.get('offerLetter.holiday').setValue(plan401kPtoAndHoliday.holiday);
    }
  }


  isHourly() {
    return !(this.form.get('offerLetter.salaryOrPayRate').value === true || this.form.get('offerLetter.salaryOrPayRate').value === 'true');
  }

  get existingUser() {
    return this.form.get('offerLetter.existingUser').value;
  }

  get prospectiveCandidate() {
    return this.form.get('offerLetter.prospectiveCandidate').value;
  }

  minDate() {
    const format = 'yyyy-MM-dd'; //dd/MM/yyyy';
    const myDate = new Date();
    const locale = 'en-US';
    return formatDate(myDate, format, locale);
  }

  get401kPtoAndHoliday(after12months: boolean = false): { plan401k: number; pto: number; holiday: number } {
    const salaryAndHorly = this.calculateSalaryAndHorly(after12months);


    const salary = salaryAndHorly.salary;
    const hourlyRate = salaryAndHorly.hourlyRate;

    let holidayAmount = 65;

    const isSalary = (this.form.get('offerLetter.salaryOrPayRate').value === true
      || this.form.get('offerLetter.salaryOrPayRate').value === 'true');

    if (this.isNext4JulyWeekend() && isSalary) {
      holidayAmount = 65 - 8;
    }

    const holidayEnabled = this.form.get('offerLetter.holidayCheck').value;

    const totalWithoutHoliday = holidayEnabled ? 2080 - holidayAmount : 2080;
    const ptoPercentage
      = (this.form.get('offerLetter.ptoPercentage').value ? (this.form.get('offerLetter.ptoPercentage').value / 100) : 0.02);
    const ptoHours = (totalWithoutHoliday * ptoPercentage);

    const ptoEnabled = this.form.get('offerLetter.ptoCheck').value;

    return {
      plan401k: this.isHourly() ? +(hourlyRate * 2080 * 0.03).toFixed(2) : +(salary * 0.03).toFixed(2),
      pto: ptoEnabled ? +(hourlyRate * ptoHours).toFixed(2) : 0,
      holiday: holidayEnabled ? +(hourlyRate * holidayAmount).toFixed(2) : 0
    };
  }

  calculateSalaryAndHorly(after12months: boolean = false): { salary: number; hourlyRate: number; } {
    let salary = 0;
    let hourlyRate = 0;
    let hoursPerWeek = 0;

    if (!this.form.get('offerLetter.flexibleScheduleCheck').value || Number(this.form.get('offerLetter.flexibleSchedule').value) === 0) {
      hoursPerWeek = 40;
    } else {
      hoursPerWeek = Number(this.form.get('offerLetter.flexibleSchedule').value);
    }

    const isSalary = (this.form.get('offerLetter.salaryOrPayRate').value === true
      || this.form.get('offerLetter.salaryOrPayRate').value === 'true');

    let holidayAmount = 65;

    if (this.isNext4JulyWeekend() && isSalary) {
      holidayAmount = 65 - 8;
    }

    const holidayEnabled = this.form.get('offerLetter.holidayCheck').value;
    const hoursPerYear = holidayEnabled ? 2080 - holidayAmount : 2080;
    const hoursPerYearFull = (hoursPerWeek * 52);
    if (isSalary) {
      salary = Number(this.form.get('offerLetter.salaryPayRate').value);
      if (after12months) {
        salary = Number(this.form.get('offerLetter.salaryPayRateAfterMonths').value);
      }

      hourlyRate = +(salary / hoursPerYearFull).toFixed(2);
    } else {
      hourlyRate = this.form.get('offerLetter.salaryPayRate').value;
      if (after12months) {
        hourlyRate = Number(this.form.get('offerLetter.salaryPayRateAfterMonths').value);
      }
      salary = +(hourlyRate * hoursPerYear).toFixed(2);
    }

    return {
      salary,
      hourlyRate
    };
  }

  calculateTotal(after12months: boolean = false): number {
    const salaryAndHorly = this.calculateSalaryAndHorly(after12months);

    const salary = salaryAndHorly.salary;
    const hourlyRate = salaryAndHorly.hourlyRate;

    let studentLoanRepayment = this.form.get('offerLetter.studentLoanRepaymentCheck').value ?
      Number(this.form.get('offerLetter.studentLoanRepayment').value) : 0;
    studentLoanRepayment = studentLoanRepayment * 12;

    let cellphoneStipend = this.form.get('offerLetter.cellphoneStipendCheck').value ?
      Number(this.form.get('offerLetter.cellphoneStipend').value) : 0;
    cellphoneStipend = cellphoneStipend * 12;

    let gasCard = this.form.get('offerLetter.gasCardCheck').value ?
      Number(this.form.get('offerLetter.gasCard').value) : 0;
    gasCard = gasCard * 12;

    const professionalLiabilityInsurance = Number(this.form.get('offerLetter.professionalLiabilityInsurance').value);

    const healthBenefitAnnualCost = this.form.get('offerLetter.healthBenefitAnnualCostCheck').value ?
      Number(this.form.get('offerLetter.healthBenefitAnnualCost').value) : 0;

    const relocationStipend = this.form.get('offerLetter.relocationStipendCheck').value ?
      Number(this.form.get('offerLetter.relocationStipend').value) : 0;

    const eletronicDevices = this.form.get('offerLetter.eletronicDevicesCheck').value ?
      Number(this.form.get('offerLetter.eletronicDevices').value) : 0;

    let receivingSupervision = this.form.get('offerLetter.receivingSupervisionCheck').value ?
      Number(this.form.get('offerLetter.receivingSupervision').value) : 0;
    receivingSupervision = receivingSupervision * 12;

    const tuitionAssistanceBenefit = this.form.get('offerLetter.tuitionAssistanceBenefitCheck').value ?
      Number(this.form.get('offerLetter.tuitionAssistanceBenefit').value) : 0;

    const bonusByTypePosition = Number(this.form.get('offerLetter.bonusByTypePosition').value);

    const regulatoryOversight = this.form.get('offerLetter.regulatoryOversightCheck').value ?
      Number(this.form.get('offerLetter.regulatoryOversight').value) : 0;

    let plan401k = Number(this.form.get('offerLetter.plan401k').value);
    let pto = Number(this.form.get('offerLetter.pto').value);
    let holiday = Number(this.form.get('offerLetter.holiday').value);
    if (after12months) {
      const plan401kPtoAndHoliday = this.get401kPtoAndHoliday(after12months);
      plan401k = plan401kPtoAndHoliday.plan401k;
      pto = plan401kPtoAndHoliday.pto;
      holiday = plan401kPtoAndHoliday.holiday;
    }

    const otherBenefitAmount = this.form.get('offerLetter.otherBenefitNameCheck').value ?
      Number(this.form.get('offerLetter.otherBenefitAmount').value) : 0;

    const medClinic = this.form.get('offerLetter.medClinicCheck').value ?
      Number(this.form.get('offerLetter.medClinic').value) : 0; //2400;
    const mentalHealth = this.form.get('offerLetter.mentalHealthCheck').value ?
      Number(this.form.get('offerLetter.mentalHealth').value) : 0; //1200;
    const gym = this.form.get('offerLetter.gymCheck').value ?
      Number(this.form.get('offerLetter.gym').value) : 0; //300;

    let total = salary;
    if (!this.form.get('offerLetter.medClinicRemoveFromEstimated').value) {
      total += medClinic;
    }
    if (!this.form.get('offerLetter.mentalHealthRemoveFromEstimated').value) {
      total += mentalHealth;
    }
    if (!this.form.get('offerLetter.gymRemoveFromEstimated').value) {
      total += gym;
    }
    if (!this.form.get('offerLetter.studentLoanRepaymentRemoveFromEstimated').value) {
      total += studentLoanRepayment;
    }
    if (!this.form.get('offerLetter.cellphoneStipendRemoveFromEstimated').value) {
      total += cellphoneStipend;
    }
    if (!this.form.get('offerLetter.gasCardRemoveFromEstimated').value) {
      total += gasCard;
    }
    if (!this.form.get('offerLetter.professionalLiabilityInsuranceRemoveFromEstimated').value) {
      total += professionalLiabilityInsurance;
    }
    if (!this.form.get('offerLetter.healthBenefitAnnualCostRemoveFromEstimated').value) {
      total += healthBenefitAnnualCost;
    }
    if (!this.form.get('offerLetter.relocationStipendRemoveFromEstimated').value) {
      total += relocationStipend;
    }
    if (!this.form.get('offerLetter.eletronicDevicesRemoveFromEstimated').value) {
      total += eletronicDevices;
    }
    if (!this.form.get('offerLetter.receivingSupervisionRemoveFromEstimated').value) {
      total += receivingSupervision;
    }
    if (!this.form.get('offerLetter.tuitionAssistanceBenefitRemoveFromEstimated').value) {
      total += tuitionAssistanceBenefit;
    }
    if (!this.form.get('offerLetter.bonusByTypePositionRemoveFromEstimated').value) {
      total += bonusByTypePosition;
    }
    if (!this.form.get('offerLetter.plan401kRemoveFromEstimated').value) {
      total += plan401k;
    }
    if (!this.form.get('offerLetter.ptoRemoveFromEstimated').value) {
      total += pto;
    }
    if (!this.form.get('offerLetter.holidayRemoveFromEstimated').value) {
      total += holiday;
    }
    if (!this.form.get('offerLetter.otherBenefitRemoveFromEstimated').value) {
      total += otherBenefitAmount;
    }
    if (!this.form.get('offerLetter.regulatoryOversightRemoveFromEstimated').value) {
      total += regulatoryOversight;
    }

    return total;
  }

  checkChanged(path: string, start: boolean = true) {
    if (this.form.get(path).value) {
      if (path !== 'offerLetter.hasIncentivePrograms') {
        this.form.get(path.substring(0, path.length - 5)).enable();
      }
      if (path === 'offerLetter.bonusByTypePositionCheck') {
        this.form.get('offerLetter.hasIncentivePrograms').setValue(true);
      }
      if (path === 'offerLetter.otherBenefitNameCheck') {
        this.form.get('offerLetter.otherBenefitAmount').enable();
      }
      if (path === 'offerLetter.salaryPayRateAfterMonthsCheck') {
        this.form.get('offerLetter.monthsAfter').enable();
      } else if (path === 'offerLetter.ptoCheck') {
        this.form.get('offerLetter.ptoPercentage').enable();
      }
      if (start) {
        if (path === 'offerLetter.healthBenefitAnnualCostCheck') {
          this.form.get(path.substring(0, path.length - 5)).setValue(5067.12);
        } else if (path === 'offerLetter.cellphoneStipendCheck') {
          this.form.get(path.substring(0, path.length - 5)).setValue(50);
        } else if (path === 'offerLetter.gasCardCheck') {
          this.form.get(path.substring(0, path.length - 5)).setValue(100);
        } else if (path === 'offerLetter.flexibleScheduleCheck') {
          this.form.get(path.substring(0, path.length - 5)).setValue(40);
        } else if (path === 'offerLetter.relocationStipendCheck') {
          this.form.get(path.substring(0, path.length - 5)).setValue(5000);
        } else if (path === 'offerLetter.studentLoanRepaymentCheck') {
          this.form.get(path.substring(0, path.length - 5)).setValue(500);
        } else if (path === 'offerLetter.eletronicDevicesCheck') {
          this.form.get(path.substring(0, path.length - 5)).setValue(1500);
        } else if (path === 'offerLetter.receivingSupervisionCheck') {
          this.form.get(path.substring(0, path.length - 5)).setValue(1900);
        } else if (path === 'offerLetter.tuitionAssistanceBenefitCheck') {
          this.form.get(path.substring(0, path.length - 5)).setValue(50000);
        } else if (path === 'offerLetter.regulatoryOversightCheck') {
          this.form.get(path.substring(0, path.length - 5)).setValue(18200);
        } else if (path === 'offerLetter.professionalLiabilityInsuranceCheck') {
          this.form.get(path.substring(0, path.length - 5)).setValue(300);
        } else if (path === 'offerLetter.medClinicCheck') {
          this.form.get(path.substring(0, path.length - 5)).setValue(2400);
        } else if (path === 'offerLetter.mentalHealthCheck') {
          this.form.get(path.substring(0, path.length - 5)).setValue(1200);
        } else if (path === 'offerLetter.gymCheck') {
          this.form.get(path.substring(0, path.length - 5)).setValue(300);
        } else if (path === 'offerLetter.salaryPayRateAfterMonthsCheck') {
          this.form.get('offerLetter.monthsAfter').setValue(12);
        } else if (path === 'offerLetter.ptoCheck') {
          this.form.get('offerLetter.ptoPercentage').setValue(2);
          this.update401kPtoAndHoliday();
        } else if (path === 'offerLetter.holidayCheck') {
          this.update401kPtoAndHoliday();
        } else if (path === 'offerLetter.plan401kCheck') {
          this.update401kPtoAndHoliday();
        }
      }
    } else {
      if (start) {
        if (path === 'offerLetter.otherBenefitNameCheck') {
          this.form.get('offerLetter.otherBenefitName').setValue('');
          this.form.get('offerLetter.otherBenefitAmount').setValue(0);
        } else if (path === 'offerLetter.salaryPayRateAfterMonthsCheck') {
          this.form.get('offerLetter.salaryPayRateAfterMonths').setValue(0);
          this.form.get('offerLetter.monthsAfter').setValue(0);
        } else if (path === 'offerLetter.ptoCheck') {
          this.form.get('offerLetter.pto').setValue(0);
          this.form.get('offerLetter.ptoPercentage').setValue(0);
        } else if (path === 'offerLetter.hasIncentivePrograms') {

        } else {
          this.form.get(path.substring(0, path.length - 5)).setValue(0);
        }
      }
      if (path === 'offerLetter.otherBenefitNameCheck') {
        this.form.get('offerLetter.otherBenefitName').disable();
        this.form.get('offerLetter.otherBenefitAmount').disable();
      } else if (path === 'offerLetter.salaryPayRateAfterMonthsCheck') {
        this.form.get('offerLetter.salaryPayRateAfterMonths').disable();
        this.form.get('offerLetter.monthsAfter').disable();
      } else if (path === 'offerLetter.ptoCheck') {
        this.form.get('offerLetter.pto').disable();
        this.form.get('offerLetter.ptoPercentage').disable();
      } else if (path === 'offerLetter.hasIncentivePrograms') {
        this.form.get('offerLetter.bonusByTypePosition').setValue(0);
        this.form.get('offerLetter.bonusByTypePositionCheck').setValue(false);
        this.form.get('offerLetter.bonusByTypePositionHideAmount').setValue(false);
        this.form.get('offerLetter.bonusByTypePositionRemoveFromEstimated').setValue(false);
      } else {
        this.form.get(path.substring(0, path.length - 5)).disable();
      }
      if (path === 'offerLetter.holidayCheck') {
        this.update401kPtoAndHoliday();
      }
    }
  }

  save() {
    if (this.form.valid) {
      this.store.dispatch(clearRehireEmployee());
      this.store.dispatch(saveOfferLetter({ offerLetterWithUser: this.offerletterWithUser }));
    } else {
      markFormGroupTouched(this.form);
    }
  }

  create() {
    if (this.form.valid) {
      this.store.dispatch(clearRehireEmployee());
      this.store.dispatch(createOfferLetter({ offerLetterWithUser: this.offerletterWithUser }));
    } else {
      markFormGroupTouched(this.form);
    }
  }

  preview() {
    if (this.form.valid) {
      this.store.dispatch(previewOfferLetter({ offerLetterWithUser: this.offerletterWithUser }));
    } else {
      markFormGroupTouched(this.form);
    }
  }

  isNext4JulyWeekend() {
    const thisYear = new Date().getFullYear();
    const isBefore4July = new Date() < new Date(thisYear + '-07-04');
    let dayOfWeek4July;

    if (isBefore4July) {
      dayOfWeek4July = new Date(thisYear + '-07-04').getDay();
    } else {
      const nextYear = thisYear + 1;
      dayOfWeek4July = new Date(nextYear + '-07-04').getDay();
    }

    if ((dayOfWeek4July === 5) || (dayOfWeek4July === 6)) {
      return true;
    }
    return false;
  }

  openMultiSelect(multiselect: MultiSelect) {
    multiselect.show();
  }

  downloadOldFile(id: number) {
    this.store.dispatch(getOldOfferLetterPdf({ oldOfferLetterID: id }));
  }

  viewOffer(offerLetter: OfferLetterWithUserModel) {
    this.store.dispatch(getOfferLetterPdf({ offerLetterID: offerLetter.offerLetter.id }));
  }

  getShortDateFormatted(date) {
    return getShortDateFormatted(new Date(date));
  }

  getJobTitles() {
    var currentJobTitleId = this.form.get('offerLetter.jobTitleID').value;    
    this.jobTitleList = this.allJobTitlesList.filter(x => currentJobTitleId == x.value ||
      (x.archived == false &&
      (!this.canEditOnlyBehaviorTechsAA ||
          (x.label === 'Behavior Technician' || x.label === 'Registered Behavior Technician' || x.label === 'Administrative Assistant')) &&
      (!this.canEditOnlyBehaviorTechs ||
        (x.label === 'Behavior Technician' || x.label === 'Registered Behavior Technician'))
      )
    );
  }
}

