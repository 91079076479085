import { deleteFBFormAssignment, deleteFBFormAssignmentSuccess, getAllForms, getAllUsersWithForms, getFBFormAssignmentTable, getFBFormAssignmentTableSuccess, getFormsFilter, getFormTypesList, getInquiryList, getUsersWithFormAssignmentTable, loadFormDoc, loadFormDocSuccess } from './../../../../core/store/form-builder/form-builder.actions';
import { FormBuilderState } from './../../../../core/store/form-builder/form-builder.reducer';
import { FBFormModel, FBFormAssignmentModel, UsersWithFormsModel } from './../../../../models/formBuilderModels';
import { BaseComponent } from './../../../../core/abstracts/baseComponent';
import { BenefitsState } from './../../../../core/store/benefits/benefits.reducer';
import { BenefitPlanModel } from './../../../../models/benefitsModels';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { Actions, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { MultiSelect } from 'primeng/multiselect';
import { AlertService } from '../../../../shared/services/alert.service';
import { deepClone, downloadFile, getTimeStampString } from '../../../../helpers/utils';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { tap } from 'rxjs/operators';
import { DateRangeModel } from '../../../../models/dateRangeModel';
import { DropdownFilterService } from '../../../../core/services/dropdown-filter.service';
import { deleteFBForm, getFBFormTable } from 'src/app/core/store/form-builder/form-builder.actions';
import { getUsersIncludingTerminated, getUsersList } from 'src/app/core/store/users/users.actions';
import { loadClients } from 'src/app/core/store/timecard/timecard.actions';
import {NgbModal, ModalDismissReasons, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { InputAutocompleteComponent } from '../../../../shared/input-helpers/input-autocomplete/input-autocomplete.component';
import { Table } from 'primeng';


@Component({
  selector: 'app-forms-assignment',
  templateUrl: './forms-assignment.component.html',
  styleUrls: ['./forms-assignment.component.scss']
})
export class FormsAssignmentComponent extends BaseComponent implements OnInit {
  @ViewChild('usersWithFormTable') formTable: Table;

  @ViewChild('clientTab') clientTab: ElementRef;
  @ViewChild('employeeTab') employeeTab: ElementRef;
  tabSelection: string = "Employees";

  modalReference: any;
  modalOptions: NgbModalOptions;

  formsAssignmentList: PagingResultsModel<UsersWithFormsModel>;
  canEdit: boolean;
  loadingFormsAssignementList = false;
  filter: any;

  showFilter: string = "Hide";
  inputPatient: SelectItem;

  filterFormID;
  filterFormTypeID;
  filterSigned;
  filterRequired;
  filterUserID;
  filterInquiryID;
  filterShowTerminatedUsers;
  loadingDoc;
  loadingDocAssignmentId;


  userOptions: SelectItem[];
  formOptions: SelectItem[];
  formTypeOptions: SelectItem[];
  inquiryList: SelectItem[];

  yesNoAllOptions = [
    { label: 'All', value: null },
    { label: 'Show Only Users With Forms Missing Signatures', value: 'true' },
    { label: 'Show Users With Forms That Do Not Have Missing Signatures', value: 'false' },
  ];

  yesNoAllOptionsRequired = [
    { label: 'All', value: null },
    { label: 'Show Only Users With Forms Missing Req. Signatures', value: 'true' },
    { label: 'Show Users With Forms That Do Not Have Missing Req. Signatures', value: 'false' },
  ];

  selectedForm;
  formsList;
  selectedUserID: number;
  selectedInquiryID: number;
  selectedUsersName: string;
  includeTerminatedUsers: boolean = false;
  constructor(
    private actions$: Actions,
    private alertService: AlertService,
    private store: Store<State>,
    private router: Router,
    private confirmationService: ConfirmationService,
    private dropdownFilterService: DropdownFilterService,
    private modalService: NgbModal,
  ) {
    super();
    this.modalOptions = {
      backdrop:'static',
      backdropClass:'customBackdrop',
      size: 'xl'
    }
  }

  ngOnInit() {
    this.store.dispatch(getFormsFilter());
    this.store.dispatch(getUsersList());
    this.store.dispatch(getUsersIncludingTerminated());
    this.store.dispatch(getFormTypesList());

    this.subs.push(
      this.store.select(s => s.usersState.usersList).subscribe(usersList => {
        this.userOptions = usersList ? usersList.map(u => ({ label: u.firstName + ' ' + u.lastName, value: u.id }))
          : [{ label: 'loading...', value: undefined }];
      }),
      this.store.select(s => s.formBuilderState.simpleFormsList).subscribe(formsList => {
        this.formOptions = formsList ? formsList.map(c => ({ label: c.name, value: c.id }))
          : [{ label: 'loading...', value: undefined }];
          this.formsList = formsList;
      }),
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.canEdit = permissionIDs && !!permissionIDs.find(p => p === 9001);
      }),
      this.store.select(s => s.formBuilderState.usersWithFormsAssignment).subscribe(formsAssignementList => {
        if (formsAssignementList) {
          this.formsAssignmentList = formsAssignementList;
        }
      }),
      this.store.select(s => s.formBuilderState.loadingFormsAssignment).subscribe(loadingFormsAssignementList => {
        this.loadingFormsAssignementList = loadingFormsAssignementList;
      }),
      this.store.select(s => s.formBuilderState.loadingDoc).subscribe(loadingDoc => {
        this.loadingDoc = loadingDoc;
      }),
      this.actions$.pipe(
        ofType(deleteFBFormAssignmentSuccess),
        tap(action => {
          this.alertService.success('Assignment deleted');
          this.loadPage(this.filter);
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(loadFormDocSuccess),
        tap(action => {
          downloadFile(action.doc, 'FormSubmission.pdf');
        })
      ).subscribe(),
    );
    if (window.sessionStorage && window.sessionStorage.getItem('formFilter')) {
      var storedFilter = JSON.parse(sessionStorage.getItem('formFilter'));
      if(storedFilter.tabSelection){
        this.tabSelection = storedFilter.tabSelection;
        if(storedFilter.tabSelection == "Clients"){
          setTimeout(() => {
            this.clientTabClick();
          }, 200); 
        }
      }
    }
  }

  loadPage(event) {
    if (!this.loadingFormsAssignementList) {
      this.loadingFormsAssignementList = true;
      this.filter = deepClone(event);
      this.filter.filters.filterTab = { Name: 'filterTab', Value: { value: this.tabSelection } };
      this.filter.filters.filterFormID = { Name: 'filterFormID', Value: { value: this.filterFormID ? this.filterFormID.join(',') : null } };
      this.filter.filters.filterFormTypeID = { Name: 'filterFormTypeID', Value: { value: this.filterFormTypeID } };
      this.filter.filters.filterSigned = { Name: 'filterSigned', Value: { value: this.filterSigned } };
      this.filter.filters.filterRequired = { Name: 'filterRequired', Value: { value: this.filterRequired } };
      this.filter.filters.filterUserID = { Name: 'filterUserID', Value: { value: this.filterUserID ? this.filterUserID.join(',') : null } };
      this.filter.filters.filterInquiryID = { Name: 'filterInquiryID', Value: { value: this.filterInquiryID } };
      this.filter.filters.filterShowTerminatedUsers = { Name: 'filterShowTerminatedUsers', Value: { value: this.filterShowTerminatedUsers } };
      this.store.dispatch(getUsersWithFormAssignmentTable({
        filter: deepClone(this.filter)
      }));
    }
  }

  changedFilters() {
    this.selectedForm = this.formsList.find(x => x.id === this.filterFormID);
    this.formTable.reset();
    this.loadPage(this.filter);
  }

  setPatient(e){
    this.filterInquiryID = e.value;
    this.changedFilters();
  }

  reloadListAfterClear(event) {
    if (event.data !== null) {
      return;
    }

    this.filterInquiryID = 0;
    this.changedFilters();
  }  

  reloadListAfterPatientChange(selectedUser) {
    if (typeof selectedUser.value !== 'number') {
      return;
    }
    
    this.filterInquiryID = 0;
    this.changedFilters();
  }
  
  toggleFilter(){
    if(this.showFilter == "Hide"){
      this.showFilter = "Show"
    }
    else {
      this.showFilter = "Hide"
    }
  }

  seeFormResult(formAssignment: FBFormAssignmentModel) {
    this.router.navigate(['/form-builder/form-submission/ ' + formAssignment.formID + '/' + formAssignment.formSubmissionID], { queryParams: { backLink: '/form-builder/forms-assignment' } });
  }

  deleteForm(formAssignment: FBFormAssignmentModel) {
    this.confirmationService.confirm({
      header: 'Delete',
      message: 'Are you sure you want to delete this form assignment?',
      acceptLabel: 'Delete',
      rejectLabel: 'Cancel',
      accept: () => {
        this.store.dispatch(deleteFBFormAssignment({ formAssignmentID: formAssignment.id }));
      }
    });
  }

  downloadForm(formAssignment: FBFormAssignmentModel) {
    this.loadingDocAssignmentId = formAssignment.id;
    this.store.dispatch(loadFormDoc({ formSubmissionID: formAssignment.formSubmissionID }));
  }

  clientTabClick() {
    this.tabSelection = "Clients";
    this.formsAssignmentList = null;
    this.saveFilters();

    if(!this.clientTab.nativeElement.classList.contains('active'))
    {
      this.clientTab.nativeElement.classList.add('active');
    }

    if(this.employeeTab.nativeElement.classList.contains('active'))
    {
        this.employeeTab.nativeElement.classList.remove('active');
    }
    this.loadPage(this.filter);
  }

  employeeTabClick() {
    this.tabSelection = "Employees";
    this.formsAssignmentList = null;
    this.saveFilters();
    if (window.sessionStorage){

    }
    this.formsAssignmentList = null;
    if(this.clientTab.nativeElement.classList.contains('active'))
    {
        this.clientTab.nativeElement.classList.remove('active');
    }

    if(!this.employeeTab.nativeElement.classList.contains('active'))
    {
        this.employeeTab.nativeElement.classList.add('active');
    }
    this.loadPage(this.filter);
  }

  saveFilters(){
    var jsonData ={}
    if (window.sessionStorage) {
      // save filter to storage
      jsonData = {
        filterFormID: this.filterFormID,
        filterFormTypeID: this.filterFormTypeID,
        filterSigned: this.filterSigned,
        filterRequired: this.filterRequired,
        filterUserID: this.filterUserID,
        filterInquiryID: this.filterInquiryID,
        tabSelection: this.tabSelection,
      };
    }
    sessionStorage.setItem("formFilter", JSON.stringify(jsonData));
  }
  openMultiSelect(multiselect: MultiSelect) {
    multiselect.show();
  }
  
  open(content, userID, inquiryID, usersName) {
    this.selectedUserID = userID;
    this.selectedInquiryID = inquiryID;
    this.selectedUsersName = usersName;
    this.modalReference = this.modalService.open(content, this.modalOptions);
    this.modalReference.result.then((result) => {
      if (result != null){
        this.loadPage(this.filter);
      }
    }, (reason) => {
    });
  }

  includeTerminatedChange(event){
    if (event.checked == false){
      this.filterShowTerminatedUsers = false;
      this.store.select(s => s.usersState.usersList).subscribe(usersList => {
        this.userOptions = usersList ? usersList.map(u => ({ label: u.firstName + ' ' + u.lastName, value: u.id }))
          : [{ label: 'loading...', value: undefined }];
      })
    }
    else{
      this.store.select(s => s.usersState.usersListIncludingTerminated).subscribe(usersList => {
        this.userOptions = usersList ? usersList.map(u => ({ label: u.firstName + ' ' + u.lastName, value: u.id }))
          : [{ label: 'loading...', value: undefined }];
      })
    }
    this.loadPage(this.filter);
  }
}


