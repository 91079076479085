import { AlertService } from 'src/app/shared/services/alert.service';
import { loadInsuranceProviders, loadMedClinicPatients, saveMedClinicPatientSuccess } from './../../../../core/store/medclinic/medclinic.actions';
import { loadProfile } from './../../../../core/store/profile/profile.actions';
import { CreateEmployeePatientModel } from 'src/app/models/medClinicModels';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { UserModel } from 'src/app/models/userModel';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { FormGroup, RequiredValidator, Validators } from '@angular/forms';
import { getAllControlsErrors, markFormGroupTouched } from 'src/app/helpers/utils';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { getAge } from '../../../../helpers/utils';
import { saveMedClinicPatient, updateCreatePatientForm } from 'src/app/core/store/medclinic/medclinic.actions';
import { initCreatePatientForm } from 'src/app/core/store/medclinic/medclinic.reducer';

@Component({
  selector: 'app-medclinic-create-patient',
  templateUrl: './medclinic-create-patient.component.html',
  styleUrls: ['./medclinic-create-patient.component.scss']
})
export class MedclinicCreatePatientComponent extends BaseComponent implements OnInit {
  form: FormGroup;
  patient: CreateEmployeePatientModel;
  creatingPatient = false;
  hasMedicalPlan;
  personalEmail;

  insuranceProvidersOptions: SelectItem[] = [
    { label: 'loading...', value: null },
  ];

  relationshipOptions: SelectItem[] = [
    { label: '-', value: null },
    { label: 'Self', value: 'Self' },
    { label: 'Spouse', value: 'Spouse' },
    { label: 'Child', value: 'Child' },
  ];

  genderOptions: SelectItem[] = [
    { label: '-', value: null },
    { label: 'Male', value: 'Male' },
    { label: 'Female', value: 'Female' },
  ];


  yesNoOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  medclinicPatientsList: CreateEmployeePatientModel[];
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private router: Router,
    private alertService: AlertService,) {
    super();
  }

  ngOnInit() {
    // Initialize the form

    this.subs.push(
      this.store.select(s => s.profileState.profile).subscribe(profile => {
        if (profile) {

          this.hasMedicalPlan = profile.hasMedicalPlan;
          if (!this.form) {
            this.personalEmail = profile.employee.personalEmail;
            this.form = initCreatePatientForm(this.personalEmail);

            this.subs.push(
              // Update form values on the store
              this.form.valueChanges.subscribe(formValues => {
                const patientValues = this.form.value;
                const formErrors = getAllControlsErrors(this.form);
                this.store.dispatch(updateCreatePatientForm({ patientValues, formErrors }));
              })
            );

            if (!this.hasMedicalPlan){
              this.form.get('useBenefits').setValue(false);
            }
          }
        }
      }),
      this.store.select(s => s.medClinicState.savingPatients).subscribe(savingPatients => {
        this.creatingPatient = savingPatients;
      }),
      this.store.select(s => s.medClinicState.patient).subscribe(patient => {

        this.patient = JSON.parse(JSON.stringify(patient));

      }),

      this.store.select(s => s.medClinicState.insuranceProviders).subscribe(insuranceProviders => {
        if (insuranceProviders) {
          this.insuranceProvidersOptions = insuranceProviders.map(x => ({ label: x.insuranceProvider, value: x.id }));
          this.insuranceProvidersOptions.unshift({ label: '-', value: null });
        }
      }),
      this.store.select(s => s.medClinicState.patientsList).subscribe(medclinicPatientsList => {
        if (medclinicPatientsList) {
          this.medclinicPatientsList = medclinicPatientsList;
          if (this.medclinicPatientsList.some(x => x.relationshipToClient === 'Self')) {
            this.relationshipOptions = this.relationshipOptions.filter(x => x.label !== 'Self');
          }
          if (this.medclinicPatientsList.some(x => x.relationshipToClient === 'Spouse')) {
            this.relationshipOptions = this.relationshipOptions.filter(x => x.label !== 'Spouse');
          }
        }
      }),

      this.actions$.pipe(
        ofType(saveMedClinicPatientSuccess),
        tap(action => {
            this.alertService.success('The client will receive an email at '+  this.form.get('email').value + ' with access to the Client Portal where they should fill in the intake forms and handle any information related to their services from now on.');
            // this.alertService.success('New client added to your Client Portal account (username: ' + this.clientLoginEmail +  ') where you should fill in the intake forms and handle any information related to your services from now on.');


          this.router.navigate(['/medclinic/patients-list']);
        })).subscribe(),
    );

    this.store.dispatch(loadProfile());
    this.store.dispatch(loadMedClinicPatients());
    this.store.dispatch(loadInsuranceProviders());
  }

  relationshipChange(event) {
    if (this.form.get('relationshipToClient').value == 'Self') {
      this.form.get('firstName').setValue(null);
      this.form.get('lastName').setValue(null);
      this.form.get('birthdate').setValue(null);
      this.form.get('gender').setValue(null);
      this.form.get('email').setValue(this.personalEmail);
    } else {
      this.form.get('email').setValue(null);
    }
  }

  benefitsChange(event){
    // if (this.form.get('useBenefits').value) {
    //   this.form.get('insuranceProviderID').setValue(null);
    //   this.form.get('policyHolderFirstName').setValue(null);
    //   this.form.get('policyHolderLastName').setValue(null);
    //   this.form.get('policyHolderDoB').setValue(null);
    //   this.form.get('memberID').setValue(null);
    //   this.form.get('policyNumber').setValue(null);
    //   this.form.get('startDate').setValue(null);
    //   this.form.get('expirationDate').setValue(null);
    // }
  }

  save() {
    if (this.form.valid) {
      var patientOlderThan18 = getAge(this.form.get('birthdate').value) >= 18;
      var patientNotSelf = this.form.get('relationshipToClient').value != 'Self';
      var emailSameAsPersonal = this.form.get('email').value == this.personalEmail;
      if (patientOlderThan18 && patientNotSelf && emailSameAsPersonal) {
        this.alertService.error('Because this family member is 18 years of age or older, they require their own email address and client portal login. Please enter the personal email address of your family member.');
        return;
      }
      if (!this.form.get('isMedClinic').value && !this.form.get('isPsychMed').value){
        this.alertService.error('You have to select at least one of the options: Primary Care and/or Psych Med.');
        return;
      } else {
        this.store.dispatch(saveMedClinicPatient({ patient: this.patient }));
      }
    } else {
      markFormGroupTouched(this.form);
    }
  }

}


