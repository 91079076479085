import { exportEmployeeVaccineInfoListToExcel, exportEmployeeVaccineInfoListToExcelFailure, exportEmployeeVaccineInfoListToExcelSuccess, getEmployeeVaccineInfoList, getEmployeeVaccineInfoListSuccess, uploadOnboardingDoc } from 'src/app/core/store/onboarding/onboarding.actions';
import { OnBoardingUploadModel, OnBoardingModel, OnBoardingUserModel, VaccineCardExemptionModel, UploadDocsApprovalListModel, EmployeeVaccineListModel, OnBoardingUserListModel } from './../../../models/onBoardingModels';
import { approveOnboardingUpload, approveOnboardingUploadFailure, approveOnboardingUploadSuccess } from 'src/app/core/store/onboarding/onboarding.actions';
import { createReducer, on, Action } from '@ngrx/store';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserProfileModel } from 'src/app/models/userProfileModel';
import { PagingResultsModel } from 'src/app/models/pagingResultsModel';
import { OnBoardingUploadType } from 'src/app/models/onBoardingModels';
import {
    loadOnboardingUploadTypes, loadOnboardingUploadTypesSuccess, loadOnboardingUploadTypesFailure, loadOnboardingUploads, loadOnboardingUploadsSuccess, loadOnboardingUploadsFailure, loadOnboardingInfo, loadOnboardingInfoSuccess, loadOnboardingInfoFailure, deleteOnboardingUpload, deleteOnboardingUploadSuccess, deleteOnboardingUploadFailure, loadOnBoardingUsersList, loadOnBoardingUsersListSuccess, loadOnBoardingUsersListFailure, downloadOnboardingUpload, downloadOnboardingUploadSuccess, loadManageOnboardingUserInfo, loadManageOnboardingUserInfoSuccess, loadManageOnboardingUserInfoFailure, updateUserProfileForm, loadEmployeeFileUsersListSuccess, loadEmployeeFileUsersList, loadEmployeeFileUsersListFailure, updateProfileBCIHRForm, updateProfileI9HRForm, clearManageOnboardingUserInfo, uploadOnboardingDocSuccess, uploadOnboardingDocFailure
  , requestVaccineCardExemption, requestVaccineCardExemptionSuccess, requestVaccineCardExemptionFailure, getVaccineCardExemption, getVaccineCardExemptionSuccess, getVaccineCardExemptionFailure, approveVaccineCardExemptionSuccess, approveVaccineCardExemption, approveVaccineCardExemptionFailure, loadUploadDocsApprovalList, loadUploadDocsApprovalListSuccess, loadUploadDocsApprovalListFailure
  , cancelVaccineCardExemption, cancelVaccineCardExemptionSuccess } from './onboarding.actions';

export interface OnboardingState {
  uploadingDoc: boolean;

  uploadedFiles: OnBoardingUploadModel[];
  loadingUploadedFiles: boolean;


  loadingUploadTypes: boolean;
  uploadTypes: OnBoardingUploadType[];


  loadingOnBoardingInfo: boolean;
  onBoardingInfo: OnBoardingModel;

  deletingOnBoardingUploadId: number;

  onBoardingUsers: PagingResultsModel<OnBoardingUserListModel>;
  loadingOnBoardingUsers: boolean;


  employeeFileUsers: PagingResultsModel<OnBoardingUserListModel>;
  loadingEmployeeFileUsers: boolean;

  loadingDoc: boolean;
  docArrayBuffer: ArrayBuffer;

  loadingManageOnboardingUserInfo: boolean;
  manageOnboardingUserInfo: OnBoardingUserModel;

  vaccineCardExemption: VaccineCardExemptionModel;
  loadingVaccineCardExemption: boolean;

  uploadDocsApprovalList: UploadDocsApprovalListModel[];
  loadingUploadDocsApprovalList: boolean;

  loadingEmployeeVaccineInfoList: boolean;
  employeeVaccineInfoList: PagingResultsModel<EmployeeVaccineListModel>;

  downloadingEmployeeVaccineInfoExcel: boolean;
  employeeVaccineInfoArrayBuffer: ArrayBuffer;

}

const initialState: OnboardingState = {
  uploadingDoc: false,

  uploadedFiles: [],
  loadingUploadedFiles: false,

  loadingUploadTypes: false,
  uploadTypes: [],
  loadingOnBoardingInfo: false,
  onBoardingInfo: null,

  deletingOnBoardingUploadId: 0,

  onBoardingUsers: null,
  loadingOnBoardingUsers: false,



  employeeFileUsers: null,
  loadingEmployeeFileUsers: true,

  loadingDoc: false,
  docArrayBuffer: null,


  loadingManageOnboardingUserInfo: false,
  manageOnboardingUserInfo: null,

  loadingVaccineCardExemption: false,
  vaccineCardExemption: null,

  uploadDocsApprovalList: null,
  loadingUploadDocsApprovalList: false,

  loadingEmployeeVaccineInfoList: true,
  employeeVaccineInfoList: null,

  downloadingEmployeeVaccineInfoExcel: false,
  employeeVaccineInfoArrayBuffer: null
};

export const onboardingReducer = createReducer(initialState,
  on(loadOnboardingUploadTypes, (state, action) => {
    return {
      ...state,
      loadingUploadTypes: true,
      uploadTypes: []
    };
  }),
  on(loadOnboardingUploadTypesSuccess, (state, action) => {
    return {
      ...state,
      uploadTypes: action.uploadTypes,
      loadingUploadTypes: false
    };
  }),

  on(loadOnboardingUploadTypesFailure, (state, action) => {
    return {
      ...state,
      loadingUploadTypes: false
    };
  }),

  on(loadOnboardingUploads, (state, action) => {
    return {
      ...state,
      loadingUploadedFiles: true,
      uploadedFiles: []
    };
  }),
  on(loadOnboardingUploadsSuccess, (state, action) => {
    return {
      ...state,
      uploadedFiles: action.uploadedFiles,
      loadingUploadedFiles: false
    };
  }),

  on(loadOnboardingUploadsFailure, (state, action) => {
    return {
      ...state,
      loadingUploadedFiles: false
    };
  }),

  on(loadOnboardingInfo, (state, action) => {
    return {
      ...state,
      loadingOnBoardingInfo: true,
      onBoardingInfo: null
    };
  }),
  on(loadOnboardingInfoSuccess, (state, action) => {
    return {
      ...state,
      onBoardingInfo: action.onBoardingInfo,
      loadingOnBoardingInfo: false
    };
  }),

  on(loadOnboardingInfoFailure, (state, action) => {
    return {
      ...state,
      loadingOnBoardingInfo: false
    };
  }),

  on(deleteOnboardingUpload, (state, action) => {
    return {
      ...state,
      deletingOnBoardingUploadId: action.OnBoardingUpload.id,
    };
  }),
  on(deleteOnboardingUploadSuccess, (state, action) => {
    return {
      ...state,
      deletingOnBoardingUploadId: 0
    };
  }),

  on(deleteOnboardingUploadFailure, (state, action) => {
    return {
      ...state,
      deletingOnBoardingUploadId: 0
    };
  }),

  on(loadOnBoardingUsersList, (state, action) => {
    return {
      ...state,
      loadingOnBoardingUsers: true,
      onBoardingUsers: null
    };
  }),
  on(loadOnBoardingUsersListSuccess, (state, action) => {
    return {
      ...state,
      onBoardingUsers: action.onBoardingUsers,
      loadingOnBoardingUsers: false
    };
  }),

  on(loadOnBoardingUsersListFailure, (state, action) => {
    return {
      ...state,
      loadingOnBoardingUsers: false
    };
  }),


  on(loadEmployeeFileUsersList, (state, action) => {
    return {
      ...state,
      loadingEmployeeFileUsers: true,
      employeeFileUsers: null
    };
  }),
  on(loadEmployeeFileUsersListSuccess, (state, action) => {
    return {
      ...state,
      employeeFileUsers: action.employeeFileUsers,
      loadingEmployeeFileUsers: false
    };
  }),

  on(loadEmployeeFileUsersListFailure, (state, action) => {
    return {
      ...state,
      loadingEmployeeFileUsers: false
    };
  }),

  on(downloadOnboardingUpload, (state, action) => {
    return {
      ...state,
      loadingDoc: true,
      docArrayBuffer: null
    };
  }),
  on(downloadOnboardingUploadSuccess, (state, action) => {
    return {
      ...state,
      docArrayBuffer: action.doc,
      loadingDoc: false
    };
  }),

  on(uploadOnboardingDoc, (state, action) => {
    return {
      ...state,
      uploadingDoc: true,
    };
  }),
  on(uploadOnboardingDocSuccess, (state, action) => {
    return {
      ...state,
      uploadingDoc: false
    };
  }),
  on(uploadOnboardingDocFailure, (state, action) => {
    return {
      ...state,
      uploadingDoc: false
    };
  }),


  on(approveOnboardingUpload, (state, action) => {
    return {
      ...state,
      uploadingDoc: true,
    };
  }),
  on(approveOnboardingUploadSuccess, (state, action) => {
    return {
      ...state,
      uploadingDoc: false
    };
  }),
  on(approveOnboardingUploadFailure, (state, action) => {
    return {
      ...state,
      uploadingDoc: false
    };
  }),


  on(loadManageOnboardingUserInfo, (state, action) => {
    return {
      ...state,
      loadingManageOnboardingUserInfo: true,
      manageOnboardingUserInfo: null
    };
  }),
  on(loadManageOnboardingUserInfoSuccess, (state, action) => {
    return {
      ...state,
      manageOnboardingUserInfo: action.onBoardingUser,
      loadingManageOnboardingUserInfo: false
    };
  }),

  on(loadManageOnboardingUserInfoFailure, (state, action) => {
    return {
      ...state,
      loadingManageOnboardingUserInfo: false
    };
  }),

  on(clearManageOnboardingUserInfo, (state, action) => {
    return {
      ...state,
      manageOnboardingUserInfo: null
    };
  }),


  on(updateProfileBCIHRForm, (state, action) => {
    return returnProfileReduced(state, action);
  }),
  on(updateProfileI9HRForm, (state, action) => {
    return returnProfileReduced(state, action);
  }),

  on(updateUserProfileForm, (state, action) => {
    return returnProfileReduced(state, action);
  }),

  on(requestVaccineCardExemption, (state, action) => {
    return {
      ...state,
      loadingVaccineCardExemption: true
    };
  }),

  on(requestVaccineCardExemptionSuccess, (state, action) => {
    return {
      ...state,
      loadingVaccineCardExemption: false,
      vaccineCardExemption: action.vaccineCardExemptionModel
    };
  }),

  on(requestVaccineCardExemptionFailure, (state, action) => {
    return {
      ...state,
      loadingVaccineCardExemption: false
    };
  }),


  on(cancelVaccineCardExemption, (state, action) => {
    return {
      ...state,
      loadingVaccineCardExemption: true
    };
  }),

  on(cancelVaccineCardExemptionSuccess, (state, action) => {
    return {
      ...state,
      loadingVaccineCardExemption: false,
      vaccineCardExemption: null,
    };
  }),

  on(cancelVaccineCardExemptionSuccess, (state, action) => {
    return {
      ...state,
      loadingVaccineCardExemption: false
    };
  }),

  on(getVaccineCardExemption, (state, action) => {
    return {
      ...state,
      loadingVaccineCardExemption: true,
      vaccineCardExemption: null
    };
  }),

  on(getVaccineCardExemptionSuccess, (state, action) => {
    return {
      ...state,
      loadingVaccineCardExemption: false,
      vaccineCardExemption: action.vaccineCardExemptionModel
    };
  }),

  on(getVaccineCardExemptionFailure, (state, action) => {
    return {
      ...state,
      loadingVaccineCardExemption: false
    };
  }),


  on(approveVaccineCardExemption, (state, action) => {
    return {
      ...state,
      loadingVaccineCardExemption: true
    };
  }),

  on(approveVaccineCardExemptionSuccess, (state, action) => {
    return {
      ...state,
      loadingVaccineCardExemption: false,
      vaccineCardExemption: action.vaccineCardExemptionModel
    };
  }),

  on(approveVaccineCardExemptionFailure, (state, action) => {
    return {
      ...state,
      loadingVaccineCardExemption: false
    };
  }),


  on(loadUploadDocsApprovalList, (state, action) => {
    return {
      ...state,
      loadingUploadDocsApprovalList: true
    };
  }),

  on(loadUploadDocsApprovalListSuccess, (state, action) => {
    return {
      ...state,
      loadingUploadDocsApprovalList: false,
      uploadDocsApprovalList: action.uploadDocsApprovalList
    };
  }),

  on(loadUploadDocsApprovalListFailure, (state, action) => {
    return {
      ...state,
      loadingUploadDocsApprovalList: false
    };
  }),

  on(getEmployeeVaccineInfoList, (state, action) => {
    return {
      ...state,
      loadingEmployeeVaccineInfoList: true
    };
  }),
  on(getEmployeeVaccineInfoListSuccess, (state, action) => {
    return {
      ...state,
      loadingEmployeeVaccineInfoList: false,
      employeeVaccineInfoList: action.list
    };
  }),

  on(exportEmployeeVaccineInfoListToExcel, (state, action) => {
    return {
      ...state,
      downloadingEmployeeVaccineInfoExcel: true,
      employeeVaccineInfoArrayBuffer: null
    };
  }),
  on(exportEmployeeVaccineInfoListToExcelSuccess, (state, action) => {
    return {
      ...state,
      employeeVaccineInfoArrayBuffer: action.doc,
      downloadingEmployeeVaccineInfoExcel: false
    };
  }),
  on(exportEmployeeVaccineInfoListToExcelFailure, (state, action) => {
    return {
      ...state,
      downloadingEmployeeVaccineInfoExcel: false
    };
  }),

);


function returnProfileReduced(state, action) {
  const newUser = { ...state.manageOnboardingUserInfo.user.user, ...action.userValues };
  const newEmployee = { ...state.manageOnboardingUserInfo.user.employee, ...action.employeeValues };
  const newProfile = { ...state.manageOnboardingUserInfo.user, ...{ user: newUser, employee: newEmployee } };
  const newManageOnboardingUserInfo = { ...state.manageOnboardingUserInfo, ...{ user: newProfile } };
  const newState = { ...state, ...{ manageOnboardingUserInfo: newManageOnboardingUserInfo, formErrors: action.formErrors } };
  return newState;
}

export function onboardingReducerFunc(state: OnboardingState | undefined, action: Action) {
  return onboardingReducer(state, action);
}



export function initProfileBCIHRForm(profile: UserProfileModel) {
  const form = new FormGroup({
    user: new FormGroup({
    }),
    employee: new FormGroup({
      bciIDType: new FormControl(profile.employee.bciIDType, [Validators.required, Validators.maxLength(255)]),
      bciIDState: new FormControl(profile.employee.bciIDState, [Validators.maxLength(255)]),
      bciIDNumber: new FormControl(profile.employee.bciIDNumber, [Validators.required, Validators.maxLength(255)]),
      bciIDExpirationDate: new FormControl(profile.employee.bciIDExpirationDate, [Validators.required, Validators.maxLength(255)]),
      bciGender: new FormControl(profile.employee.bciGender, [Validators.required, Validators.maxLength(255)]),
      bciRace: new FormControl(profile.employee.bciRace, [Validators.required, Validators.maxLength(255)]),
      bciEyeColor: new FormControl(profile.employee.bciEyeColor, [Validators.required, Validators.maxLength(255)]),
      bciHairColor: new FormControl(profile.employee.bciHairColor, [Validators.required, Validators.maxLength(255)]),
      bciHeight: new FormControl(profile.employee.bciHeight, [Validators.required, Validators.maxLength(255)]),
      bciWeight: new FormControl(profile.employee.bciWeight, [Validators.required, Validators.maxLength(255)]),
      bciLicenseTypes: new FormControl(profile.employee.bciLicenseTypes, [Validators.required, Validators.maxLength(255)]),
      bciProgramName: new FormControl(profile.employee.bciProgramName ? profile.employee.bciProgramName : 'Utah Behavior Services', [Validators.required, Validators.maxLength(255)]),
      bciSiteNameOrAddress: new FormControl(profile.employee.bciSiteNameOrAddress, [Validators.required, Validators.maxLength(255)]),
      bciProgramPhoneNumber: new FormControl(profile.employee.bciProgramPhoneNumber ? profile.employee.bciProgramPhoneNumber : '801-255-5131', [Validators.required, Validators.maxLength(255)]),
      stateOfBirth: new FormControl(profile.employee.stateOfBirth, [Validators.required, Validators.maxLength(255)]),
    }
    )
  });

  return form;
}



export function initProfileI9HRForm(profile: UserProfileModel) {
  const form = new FormGroup({
    user: new FormGroup({
    }),
    employee: new FormGroup({
      i9AdditionalInfo: new FormControl(profile.employee.i9AdditionalInfo, [Validators.maxLength(255)]),
      i9EmploymentFirstDate: new FormControl(
        profile.employee.i9EmploymentFirstDate ? new Date(profile.employee.i9EmploymentFirstDate) : (profile.employee.orientationDate ? new Date(profile.employee.orientationDate) : null),
        [Validators.required, Validators.maxLength(255)]),
      i9OrganizationName: new FormControl(
        profile.employee.i9OrganizationName ? profile.employee.i9OrganizationName : 'Utah Behavior Services',
        [Validators.required, Validators.maxLength(255)]),
      i9OrganizationAddress: new FormControl(
        profile.employee.i9OrganizationAddress ? profile.employee.i9OrganizationAddress : '6013 S Redwood Road',
        [Validators.required, Validators.maxLength(255)]),
      i9OrganizationCity: new FormControl(
        profile.employee.i9OrganizationCity ? profile.employee.i9OrganizationCity : 'Taylorsville',
        [Validators.required, Validators.maxLength(255)]),
      i9OrganizationState: new FormControl(
        profile.employee.i9OrganizationState ? profile.employee.i9OrganizationState : 'UT',
        [Validators.required, Validators.maxLength(255)]),
      i9OrganizationZIP: new FormControl(
        profile.employee.i9OrganizationZIP ? profile.employee.i9OrganizationZIP : '84123',
        [Validators.required, Validators.maxLength(255)]),
      i9NewFirstName: new FormControl(profile.employee.i9NewFirstName, [Validators.maxLength(255)]),
      i9NewLastName: new FormControl(profile.employee.i9NewLastName, [Validators.maxLength(255)]),
      i9NewMiddleInitial: new FormControl(profile.employee.i9NewMiddleInitial, [Validators.maxLength(255)]),
      i9RehireDate: new FormControl(profile.employee.i9RehireDate, [Validators.maxLength(255)]),
      i9RehireDocumentTitle: new FormControl(profile.employee.i9RehireDocumentTitle, [Validators.maxLength(255)]),
      i9RehireDocumentNumber: new FormControl(profile.employee.i9RehireDocumentNumber, [Validators.maxLength(255)]),
      i9RehireExpirationDate: new FormControl(profile.employee.i9RehireExpirationDate, [Validators.maxLength(255)]),
      i9ListA1DocumentTitle: new FormControl(profile.employee.i9ListA1DocumentTitle, [Validators.maxLength(255)]),
      i9ListA1IssuingAuthority: new FormControl(profile.employee.i9ListA1IssuingAuthority, [Validators.maxLength(255)]),
      i9ListA1DocumentNumber: new FormControl(profile.employee.i9ListA1DocumentNumber, [Validators.maxLength(255)]),
      i9ListA1ExpirationDate: new FormControl(profile.employee.i9ListA1ExpirationDate, [Validators.maxLength(255)]),
      i9ListA2DocumentTitle: new FormControl(profile.employee.i9ListA2DocumentTitle, [Validators.maxLength(255)]),
      i9ListA2IssuingAuthority: new FormControl(profile.employee.i9ListA2IssuingAuthority, [Validators.maxLength(255)]),
      i9ListA2DocumentNumber: new FormControl(profile.employee.i9ListA2DocumentNumber, [Validators.maxLength(255)]),
      i9ListA2ExpirationDate: new FormControl(profile.employee.i9ListA2ExpirationDate, [Validators.maxLength(255)]),
      i9ListA3DocumentTitle: new FormControl(profile.employee.i9ListA3DocumentTitle, [Validators.maxLength(255)]),
      i9ListA3IssuingAuthority: new FormControl(profile.employee.i9ListA3IssuingAuthority, [Validators.maxLength(255)]),
      i9ListA3DocumentNumber: new FormControl(profile.employee.i9ListA3DocumentNumber, [Validators.maxLength(255)]),
      i9ListA3ExpirationDate: new FormControl(profile.employee.i9ListA3ExpirationDate, [Validators.maxLength(255)]),
      i9ListBDocumentTitle: new FormControl(profile.employee.i9ListBDocumentTitle, [Validators.maxLength(255)]),
      i9ListBIssuingAuthority: new FormControl(profile.employee.i9ListBIssuingAuthority, [Validators.maxLength(255)]),
      i9ListBDocumentNumber: new FormControl(profile.employee.i9ListBDocumentNumber, [Validators.maxLength(255)]),
      i9ListBExpirationDate: new FormControl(profile.employee.i9ListBExpirationDate, [Validators.maxLength(255)]),
      i9ListCDocumentTitle: new FormControl(profile.employee.i9ListCDocumentTitle, [Validators.maxLength(255)]),
      i9ListCIssuingAuthority: new FormControl(profile.employee.i9ListCIssuingAuthority, [Validators.maxLength(255)]),
      i9ListCDocumentNumber: new FormControl(profile.employee.i9ListCDocumentNumber, [Validators.maxLength(255)]),
      i9ListCExpirationDate: new FormControl(profile.employee.i9ListCExpirationDate, [Validators.maxLength(255)]),
    }
    )
  });

  return form;
}
