import { isBright } from './../../../../../helpers/utils';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent } from '../../../../../core/abstracts/baseComponent';
import { EmployeeReviewTemplateModel } from '../../../../../models/employeeReviewTemplateModels';
import { AlertService } from '../../../../../shared/services/alert.service';
import { deepClone, getShortDateFormatted } from '../../../../../helpers/utils';
import { Store } from '@ngrx/store';
import { State } from '../../../../../core/store';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { autoSavePlayerReview, autoSavePlayerReviewSuccess, getReviewOpenPeriod, loadMyTemplates, postPlayerReview, postPlayerReviewSuccess } from '../../../../../core/store/employee-review/employee-review.actions';
import { EmployeeReviewModel, EmployeeReviewOpenPeriod, EmployeeTemplateReviews } from '../../../../../models/employeeReviewModels';
import { GeneralSettingsModel } from 'src/app/models/generalSettingsModel';



@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss']
})
export class ReviewComponent extends BaseComponent implements OnInit, OnDestroy {
  errors: string[];
  canEdit: boolean;
  loading: boolean;
  reviewId?: number;
  isSavingToLater = false; 

  availableTemplates: any[];

  template: EmployeeReviewTemplateModel = null;
  comments: string;
  focusArea: string;

  openPeriod: EmployeeReviewOpenPeriod = null;
  userId: number;

  templateId?: number;
  score: number;
  savingEmployeeReview = false;
  intervalAutoSave;
  generalSettings: GeneralSettingsModel;

  constructor(
    private store: Store<State>,
    private actions$: Actions,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService) {

    super();
    this.canEdit = true;
  }

  ngOnInit() {
    this.score = 0;
    this.comments = "";
    this.focusArea = "";
    this.errors = [];
    this.reviewId = null;
    this.userId = 0;
    this.startAutosave();
    this.subs.push(
      this.store.select(s => s.accountState.generalSettings).subscribe(generalSettings => {
        if (generalSettings) {
          this.generalSettings = generalSettings;
        }
      }),
      this.store.select(s => s.accountState.user).subscribe(user => {
        if (user != null) {
          this.getOpenPeriod(user.id);
        }
      }),
      this.store.select(s => s.employeeReviewState.templates).subscribe(templates => {
        this.availableTemplates = [];
        var clone = deepClone(templates);
        if (clone) {
          this.availableTemplates = clone.map(function (v, i) {
            return { label: `(${getShortDateFormatted(v.periodStart)} - ${v.periodEnd ? getShortDateFormatted(v.periodEnd) : "Current"}) - ${v.description ? v.description : ''}`, value: v }
          });
          this.availableTemplates.sort(function (a, b) {
            var labelA = a.label.toUpperCase(); // ignore upper and lowercase
            var labelB = b.label.toUpperCase(); // ignore upper and lowercase
            if (labelA < labelB) {
              return -1;
            }
            if (labelA > labelB) {
              return 1;
            }

            // names must be equal
            return 0;
          });
        }

        if (this.availableTemplates && this.availableTemplates.length) {
          let templateOptionToSelect;
          if (this.reviewId > 0) {
            templateOptionToSelect =
              this.availableTemplates.find(x => (x.value as EmployeeReviewTemplateModel).draftReview.reviewID === this.reviewId);
          } else if (this.availableTemplates.length === 1) {
            templateOptionToSelect = this.availableTemplates[0];
          }
          if (templateOptionToSelect) {
            this.template = templateOptionToSelect.value;
            this.changedTemplate();
          }
        }

      }),
      this.store.select(s => s.employeeReviewState.loading).subscribe(loading => {
        this.loading = loading;
      }),
      this.store.select(s => s.employeeReviewState.savingEmployeeReview).subscribe(savingEmployeeReview => {
        this.savingEmployeeReview = savingEmployeeReview;
      }),
      this.store.select(s => s.employeeReviewState.reviewOpenPeriod).subscribe(reviewPeriod => {
        this.openPeriod = reviewPeriod;
      }),
      this.actions$.pipe(
        ofType(postPlayerReviewSuccess),
        tap(action => {
          this.alertService.success('Review submitted!');
          this.router.navigate(['/']);
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(autoSavePlayerReviewSuccess),
        tap(action => {
          if (this.isSavingToLater) {
            this.alertService.success('Review saved!');
            this.router.navigate(['/']);
          }
        })
      ).subscribe()
    );

    this.route.params.subscribe(params => {
      this.reviewId = parseInt(params.reviewId);
    });

    this.store.dispatch(loadMyTemplates());
  }

  getOpenPeriod(userId) {
    if (userId && userId > 0 && userId != this.userId) {
      this.userId = userId;
      this.store.dispatch(getReviewOpenPeriod({ userId: userId }));
    }
  }

  changedTemplate() {
    this.comments = this.template.draftReview ? this.template.draftReview.comments : '';
    this.focusArea = this.template.draftReview ? this.template.draftReview.focusArea : '';
    if (this.template.draftReview && this.template.draftReview.kraScores) {
      for (var i = 0; i < this.template.draftReview.kraScores.length; i++) {
        var score = this.template.draftReview.kraScores[i];
        var kra = this.template.kras.find(f => f.kra_id == score.krA_ID);
        kra.score = score.optionValue;
        if (kra.optionSet && kra.optionSet.scoreOptions) {
          kra.scoreOption = kra.optionSet.scoreOptions.find(f => f.scoreOptionValue == kra.score);
        }

      }
    }
    
    this.calcScore();
  }

  changeComment() {

    if (!this.template.draftReview) {
      this.template.draftReview = {} as EmployeeReviewModel;
    }
    this.template.draftReview.comments = this.comments;

  }

  getEmployeeReviewModel(shouldAlertAndStop = false) {
    const review: EmployeeReviewModel = {
      templateID: this.template.id,
      answers: [],
      kraScores: [],
      comments: this.comments,
      focusArea: this.focusArea
    };

    for (var kraIndex = 0; kraIndex < this.template.kras.length; kraIndex++) {
      const kra = this.template.kras[kraIndex];
      if (kra.optionSet && kra.optionSet.scoreOptions && kra.optionSet.scoreOptions.length > 0) {
        if (shouldAlertAndStop) {
          if (!kra.scoreOption) {
            this.alertService.error("Score not answered.");
            return null;
          }
        }
        if (kra.scoreOption) {
          review.kraScores.push({
            optionScoreID: kra.scoreOption.scoreOptionId,
            krA_ID: kra.kra_id,
            optionValue: kra.scoreOption.scoreOptionValue
          });
        }
      }
      for (var kpiIndex = 0; kpiIndex < kra.kpis.length; kpiIndex++) {
        const kpi = kra.kpis[kpiIndex];
        for (var categoryIndex = 0; categoryIndex < kpi.categories.length; categoryIndex++) {
          const category = kpi.categories[categoryIndex];
          for (var measureableIndex = 0; measureableIndex < category.measureables.length; measureableIndex++) {
            const measureable = category.measureables[measureableIndex];
            if (shouldAlertAndStop) {
              if (measureable.answer != 0 && measureable.answer != 1) {
                this.alertService.error('Measureable not answered.');
                return null;
              }
            }
            review.answers.push({
              value: measureable.measureableId,
              selected: measureable.answer ? measureable.answer == 1 : null
            });
            for (var childIndex = 0; childIndex < measureable.childrenMeasureables.length; childIndex++) {
              const child = measureable.childrenMeasureables[childIndex];
              if (shouldAlertAndStop) {
                if (child.answer != 0 && child.answer != 1) {
                  this.alertService.error('Measureable not answered.');
                  return null;
                }
              }
              review.answers.push({
                value: child.measureableId,
                selected: child.answer ? child.answer == 1 : null
              });
            }
          }
        }
      }

      for (var measureableIndex = 0; measureableIndex < kra.measureables.length; measureableIndex++) {
        const measureable = kra.measureables[measureableIndex];
        
        review.answers.push({
          value: measureable.measureableId,
          selected: measureable.answer
        });
        for (var childIndex = 0; childIndex < measureable.childrenMeasureables.length; childIndex++) {
          const child = measureable.childrenMeasureables[childIndex];
          
          review.answers.push({
            value: child.measureableId,
            selected: child.answer 
          });
        }
      }
    }
    return review;
  }

  saveToLater() {
    if (this.intervalAutoSave) {
      clearInterval(this.intervalAutoSave);
    }
    this.isSavingToLater = true;
    this.autosave();
  }

  save() {
    if (!this.template || !this.template.id) {
      this.alertService.error('Template not selected.');
      return;
    }

    const review = this.getEmployeeReviewModel(true);

    if (!review) {
      return;
    }


    this.store.dispatch(postPlayerReview({ review }));
  }

  startAutosave() {
    this.isSavingToLater = false;
    if (this.intervalAutoSave) {
      clearInterval(this.intervalAutoSave);
    }
    this.intervalAutoSave = setInterval(() => {
      if (!this.savingEmployeeReview && this.template) {
        this.autosave();
      }
    }, 10000);
  }

  autosave() {
    const review = this.getEmployeeReviewModel(false);
    this.store.dispatch(autoSavePlayerReview({ review }));
  }


  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.intervalAutoSave) {
      clearInterval(this.intervalAutoSave);
    }
  }

  calcScore() {
    let score = null;
    for (var kraI = 0; kraI < this.template.kras.length; kraI++) {
      const kra = this.template.kras[kraI];
      if (kra.kpis.length > 0) {
        for (var kpiI = 0; kpiI < kra.kpis.length; kpiI++) {
          const kpi = kra.kpis[kpiI];
          let currentKpiScore = 0;
          let allChecked = true;
          const categoriesSorted = kpi.categories.sort((a, b) => {
            return a.category.scoreValue - b.category.scoreValue;
          });
          for (var ci = 0; ci < categoriesSorted.length; ci++) {
            const category = categoriesSorted[ci];

            for (var mi = 0; mi < category.measureables.length; mi++) {
              const measureable = category.measureables[mi];
              if (measureable.answer != 1) {
                allChecked = false;
                break;
              }
              for (var childIndex = 0; childIndex < measureable.childrenMeasureables.length; childIndex++) {
                const child = measureable.childrenMeasureables[childIndex];
                if (child.answer != 1) {
                  allChecked = false;
                  break;
                }
              }
            }
            if (allChecked === false) {
              break;
            } else {
              currentKpiScore = category.category.scoreValue;
            }
          }

          if (this.template.scoreType.scoreType == "Sum") {
            score += currentKpiScore;
          } else if (this.template.scoreType.scoreType == "Highest Value") {
            if (score == null || score < currentKpiScore) {
              score = currentKpiScore;
            }
          } else if (this.template.scoreType.scoreType == "Lowest Value Value") {
            if (score == null || score > currentKpiScore) {
              score = currentKpiScore;
            }
          }

        }
      }
      else {
        if (kra.scoreOption) {
          kra.score = kra.scoreOption.scoreOptionValue;
        } else {
          kra.score = null;
        }
        let currentScore = kra.score;

        if (this.template.scoreType.scoreType == "Sum") {
          score += currentScore;
        } else if (this.template.scoreType.scoreType == "Highest Value") {
          if (score == null || score < currentScore) {
            score = currentScore;
          }
        } else if (this.template.scoreType.scoreType == "Lowest Value") {
          if (score == null || score > currentScore) {
            score = currentScore;
          }
        }
      }
    }
    this.score = score;
  }

  blackOrWhite(color: string) {
    if (color && !isBright(color)) {
      return '#FFFFFF';
    } else {
      return '#000000';
    }
  }
}
