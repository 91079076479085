import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'src/app/core/store';
import { loadProfile, getPhoto, getReturnEquipmentAlerts } from 'src/app/core/store/profile/profile.actions';
import { UserProfileModel } from 'src/app/models/userProfileModel';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';

@Component({
  selector: 'app-management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.scss']
})
export class ManagementComponent extends BaseComponent implements OnInit {

  profile: UserProfileModel;
  profilePicture;
  canViewHolidays: boolean;
  canViewOfferLetter;
  canViewOfferLetterBehaviorTech;
  canViewOfferLetterBehaviorTechAA;
  canViewEquipment;
  canViewPayrollApproval;
  canViewOnboardingDocuments;
  canViewOnBoarding;
  canViewEmployeeFile;
  canViewFullTimeStatusReport;
  canViewFullTimeStatusQueue;
  canViewEmployeePayrollReports;
  canViewProspectiveCandidate;
  canViewStaffInjuriesQueue;
  canViewPtoSummary;
  canViewManageTimeOff;
  canManageEmployeeReviewTemplate;
  canViewKraReports;
  canViewCompletedKraReports;
  canManageSelfcare;
  canSeeSelfcare;
  canViewPaylocityReports;
  canViewBenefitPlanEDIReports;
  canManageApplications;
  canViewBenefitsManagement;
  canViewBenefitReports;
  canViewDocumentUploadApproval;
  canManageFormBuilder;
  canViewSelfCareReport;
  canViewCertificationReport;
  canViewInternApplications;
  canViewTicketManagement;
  paylocityEnabled;
  MotivHealthEnabled;
  SameraHealthEnabled;

  returnEquipmentAlert: string = "There are Equipments that need to be returned";
  returnEquipmentAlertAllCount: number = 0;
  currentUser = JSON.parse(localStorage.getItem('currentUser'));

  constructor(
    private store: Store<State>,
    private router: Router,
  ) {
    super();
  }

  ngOnInit() {
    this.store.dispatch(getReturnEquipmentAlerts({id: this.currentUser.id}))

    this.subs.push(
      this.store.select(s => s.profileState.profile).subscribe(profile => {
        this.profile = profile;
      }),
      this.store.select(s => s.profileState.returnEquipmentAlertAllCount).subscribe(returnEquipmentAlertAllCount => {
        this.returnEquipmentAlertAllCount = returnEquipmentAlertAllCount;
      }),
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.canViewHolidays = permissionIDs && !!permissionIDs.find(p => p === 3037 || p === 3036);
        this.canViewOfferLetter = permissionIDs && !!permissionIDs.find(p => p === 3061 || p === 3062); //view offer letter, manage offer letter
        this.canViewOfferLetterBehaviorTech = permissionIDs && !!permissionIDs.find(p => p === 3065);
        this.canViewOfferLetterBehaviorTechAA = permissionIDs && !!permissionIDs.find(p => p === 3066);
        this.canViewEquipment = permissionIDs && !!permissionIDs.find(p => p === 3057 || p === 3058); //view equipment, manage equipment
        this.canViewPayrollApproval = permissionIDs && !!permissionIDs.find(p => p === 154 || p === 3022|| p === 6030 || 3120); //PayrollApproval_View && Payroll Approval Director View (view only) && PayrollApprovalAA && Director campus approval
        this.canViewOnboardingDocuments = permissionIDs && !!permissionIDs.find(p => p === 3059 || p === 3060); //view on boarding, manage on boarding
        this.canViewOnBoarding = permissionIDs && !!permissionIDs.find(p => p === 3059 || p === 3060); //view on boarding, manage on boarding
        this.canViewEmployeeFile = permissionIDs && !!permissionIDs.find(p => p === 3063 || p === 3064 || p === 3068); //view employee file, manage employee file, manage incomplete
        this.canViewFullTimeStatusReport = permissionIDs && !!permissionIDs.find(p => p === 3070); //View Full Time Status Report
        this.canViewFullTimeStatusQueue = permissionIDs && !!permissionIDs.find(p => p === 3071); //Manage Full Time Status Checks
        this.canViewEmployeePayrollReports = permissionIDs && !!permissionIDs.find(p => p === 3040); //View employee payroll reports
        this.canViewProspectiveCandidate = permissionIDs && !!permissionIDs.find(p => p === 3090 || p === 3091);
        this.canViewStaffInjuriesQueue = permissionIDs && !!permissionIDs.find(p => p === 4053);//HRPortal_StaffInjuriesQueue
        this.canViewPtoSummary = permissionIDs && !!permissionIDs.find(p => p === 3103 || p === 3104); //Manage PTO, View PTO
        this.canViewManageTimeOff = permissionIDs && !!permissionIDs.find(p => p === 186); //Manage Scheduling Time Off
        this.canManageEmployeeReviewTemplate = permissionIDs && !!permissionIDs.find(p => p === 4091); //Manage Employee Review Templates
        this.canViewKraReports = permissionIDs && !!permissionIDs.find(p => p === 3208); //View KRA Report
        this.canViewCompletedKraReports = permissionIDs && !!permissionIDs.find(p => p === 3081); //View completed KRA Report
        this.canManageSelfcare = permissionIDs && !!permissionIDs.find(p => p === 3082); //Manage Self-Care
        this.canSeeSelfcare = permissionIDs && !!permissionIDs.find(p => p === 3083); //See Self-Care
        this.canViewPaylocityReports = permissionIDs && !!permissionIDs.find(p => p === 3073 || p === 3074) //View || Manage Paylocity Tranmissions
        this.canViewBenefitPlanEDIReports = permissionIDs && !!permissionIDs.find(p => p === 6050) //Benefits_Manage
        this.canViewBenefitsManagement = permissionIDs && !!permissionIDs.find(p => p === 6050); //Benefits_Manage
        this.canViewBenefitReports = permissionIDs && !!permissionIDs.find(p => p === 3075); //Manage Benefits Reports
        this.canManageApplications = permissionIDs && !!permissionIDs.find(p => p === 7500 || p === 7501 || p === 7502); //Manage Application Dashboard
        this.canViewDocumentUploadApproval = permissionIDs && !!permissionIDs.find(p => p === 6100); //Approve_Document_Upload
        this.canManageFormBuilder = permissionIDs && !!permissionIDs.find(p => p === 9001); //Manage Form Builder
        this.canViewSelfCareReport = permissionIDs && !!permissionIDs.find(p => p === 3086); //View Self Care Report
        this.canViewCertificationReport = permissionIDs && !!permissionIDs.find(p => p === 3111 || p === 3112); //View Self Care Report
        this.canViewInternApplications = permissionIDs && !!permissionIDs.find(p => p === 3127 || p === 3128); //View Intern Applications
        this.canViewTicketManagement = permissionIDs && !!permissionIDs.find(p => p === 6060 ); //Manage ticket types;
        //make sure to add permissions to nav-menu.component.ts so the "Management" button shows
      }),
      this.store.select(s => s.accountState.featureSwitchesIds).subscribe(featureSwitchesIds => {
        this.paylocityEnabled = featureSwitchesIds && !!featureSwitchesIds.find(p => p === 85);
        this.MotivHealthEnabled = featureSwitchesIds && !!featureSwitchesIds.find(p => p === 121);
        this.SameraHealthEnabled = featureSwitchesIds && !!featureSwitchesIds.find(p => p === 122);
      }),
    );

    this.store.dispatch(loadProfile());
  }


  openLink(path) {
    this.router.navigate([path]);
  }

  openTimeOffLink() {
    if (this.canViewManageTimeOff) {
      this.openLink("/timeoff/scheduling/manage");
    }
    else if (this.canViewPtoSummary) {
      this.openLink("/timeoff/ptoSummary");
    }
  }

  openSelfCareOversightLink() {
    if (this.canSeeSelfcare) {
      this.openLink("/selfcare/oversight");
    }
    else if (this.canViewSelfCareReport) {
      this.openLink("/selfCare/report");
    }
  }

  openKraLink() {
    if (this.canViewCompletedKraReports) {
      this.openLink('/employee-review/report/completedKra');
    }
    else if (this.canViewKraReports) {
      this.openLink('/employee-review/report/kra');
    }
  }

  getKraCardTitle() {
    if (this.canViewCompletedKraReports) {
      return "Completed KRAs"
    }

    return "KRAs";
  }
}
