import { Action, createReducer, on, State } from '@ngrx/store';
import { deepClone } from 'src/app/helpers/utils';
import { CriticalNewsPostCommentModel, CriticalNewsPostModel, CriticalNewsPostReactionModel, NewsFeedFilterModel } from 'src/app/models/criticalNewsFeedModel';
import { PagingResultsModel } from 'src/app/models/pagingResultsModel';
import { editPostSuccess, loadPinnedPostsSuccess, loadPostsSuccess, filterFeedSuccess, resetPosts, 
  saveCommentSuccess, savePostSuccess, saveReactionSuccess, setScrollLoading, unpinPostSuccess, 
  unpinUserPostSuccess, loadUserPinnedPostsSuccess, archivePostSuccess, archiveCommentSuccess, refreshFeedSuccess, deleteReactionSuccess, unpinCommentSuccess, editCommentSuccess, pinPostSuccess, pinUserPostSuccess, setRefreshFilter, startRefreshing, stopRefreshing, setLastSyncDate, pinCommentSuccess } from './critical-news-feed.action';

export interface CriticalNewsFeedState {
    AllPosts: CriticalNewsPostModel[];
    PostsToLoad: PagingResultsModel<CriticalNewsPostModel>;
    filterFeedPosts: CriticalNewsPostModel[];
    refreshFeedPosts: CriticalNewsPostModel[];
    PostJustAdded: CriticalNewsPostModel;
    postJustArchived: CriticalNewsPostModel;
    postJustEdited: CriticalNewsPostModel;
    scrollLoading: boolean;
    totalFilteredPosts: number;
    totalPostsLoaded: number;
    userPinnedPosts: CriticalNewsPostModel[];
    pinnedPosts: CriticalNewsPostModel[];
    commentJustAdded: CriticalNewsPostCommentModel;
    commentJustPinned: CriticalNewsPostCommentModel;
    commentJustEdited: CriticalNewsPostCommentModel;
    commentJustUnpinned:CriticalNewsPostCommentModel;
    commentJustArchived: CriticalNewsPostCommentModel;
    reactionJustAdded: CriticalNewsPostReactionModel;
    reactionJustDeleted: CriticalNewsPostReactionModel;
    filter: NewsFeedFilterModel;
    refreshCountController: number,
    syncActive: boolean,
};

const initialState: CriticalNewsFeedState = {
    AllPosts: null,
    PostsToLoad: null,
    filterFeedPosts: null,
    refreshFeedPosts: null,
    PostJustAdded: null,
    postJustArchived: null,
    postJustEdited: null,
    scrollLoading: false,
    totalFilteredPosts: null,
    totalPostsLoaded: null,
    userPinnedPosts: null,
    pinnedPosts: null,
    commentJustAdded: null,
    commentJustPinned: null,
    commentJustEdited: null,
    commentJustUnpinned: null,
    commentJustArchived: null,
    reactionJustAdded: null,
    reactionJustDeleted: null,
    filter: null,
    refreshCountController: 0,
    syncActive: false,
};

export const criticalNewsFeedReducer = createReducer(initialState,
  on(loadPostsSuccess, (state, action) => {
    let posts = [];
    let totalLoaded = 0;
    if( state.AllPosts == null){
      posts = action.posts.result;
      totalLoaded = action.posts.result.length
    }
    else{
      posts = state.AllPosts.concat(action.posts.result);
      totalLoaded = state.AllPosts.length;
    }
    return {
      ...state,
      PostsToLoad: action.posts,
      AllPosts: posts,
      scrollLoading: false,
      totalFilteredPosts: action.posts.totalRecords,
      totalPostsLoaded: totalLoaded,
    };
  }),
  on(loadPinnedPostsSuccess, (state, action) => {
    return {
      ...state,
      pinnedPosts: action.posts,
    };
  }),
  on(loadUserPinnedPostsSuccess, (state, action) => {
    return {
      ...state,
      userPinnedPosts: action.posts,
    };
  }),
  on(pinPostSuccess, (state, action) => {
    return {
      ...state,
      pinnedPosts: action.posts,
    };
  }),
  on(pinUserPostSuccess, (state, action) => {
    return {
      ...state,
      userPinnedPosts: action.posts,
    };
  }),
  on(unpinPostSuccess, (state, action) => {
    return {
      ...state,
      pinnedPosts: action.posts,
    };
  }),
  on(unpinUserPostSuccess, (state, action) => {
    return {
      ...state,
      userPinnedPosts: action.posts,
    };
  }),
  on(archivePostSuccess, (state, action) => {
    return {
      ...state,
      postJustArchived: action.post,
    };
  }),
  on(archiveCommentSuccess, (state, action) => {
    return {
      ...state,
      commentJustArchived: action.comment,
    };
  }),
  on(resetPosts, (state, action) => {
    return {
      ...state,
      AllPosts: null,
      PostsToLoad: null,
      PostJustAdded: null,
      scrollLoading: false,
      totalFilteredPosts: null,
      totalPostsLoaded: null,
      pinnedPosts: null,
      commentJustAdded: null,
    };
  }),
  on(setRefreshFilter, (state, action) => {
    return {
      ...state,
      filter: action.filter,
    };
  }),
  on(startRefreshing, (state, action) => {
    return {
      ...state,
      syncActive: true,
      refreshCountController: state.refreshCountController + 1,
    };
  }),
  on(stopRefreshing, (state, action) => {
    return {
      ...state,
      syncActive: false,
      refreshCountController: 1,
    };
  }),
  on(setScrollLoading, (state, action) => {
    return {
      ...state,
      scrollLoading: action.loading,
    };
  }),
  on(savePostSuccess, (state, action) => {
      return {
        ...state,
        PostJustAdded: action.post,
      };
  }),
  on(editPostSuccess, (state, action) => {
    return {
      ...state,
      postJustEdited: action.post,
    };
}),
  on(saveCommentSuccess, (state, action) => {
      return {
        ...state,
        commentJustAdded: action.comment,
      };
  }),
  on(editCommentSuccess, (state, action) => {
      return {
        ...state,
        commentJustEdited: action.comment,
      };
  }),
  on(pinCommentSuccess, (state, action) => {
    return {
      ...state,
      commentJustPinned: action.comment,
    };
}),
  on(unpinCommentSuccess, (state, action) => {
      return {
        ...state,
        commentJustUnpinned: action.comment,
      };
  }),
  on(saveReactionSuccess, (state, action) => {
    return {
      ...state,
      reactionJustAdded: action.reaction,
    };
  }),
  on(deleteReactionSuccess, (state, action) => {
    return {
      ...state,
      reactionJustDeleted: action.reaction,
    };
  }),
  on(filterFeedSuccess, (state, action) => {
    return {
      ...state,
      filterFeedPosts: action.posts.result,
      AllPosts: action.posts.result,
      totalPostsLoaded: action.posts.result.length,
      totalFilteredPosts: action.posts.totalRecords,
    };
  }),  
  on(refreshFeedSuccess, (state, action) => {
    let count = 0;
    if (action.posts != null && action.posts.result != null && action.posts.result.length > 0){
      let posts = action.posts.result;
      let newPosts = deepClone(posts).filter(x => x.updatedDate == null)
      if (newPosts != null && newPosts.length > 0){
        count = newPosts.length;
      }
    }
    return {
      ...state,
      refreshFeedPosts: action.posts.result,
      totalPostsLoaded: state.totalPostsLoaded + count,
      totalFilteredPosts: state.totalFilteredPosts + count,
      commentJustAdded: null,
      reactionJustAdded: null,
      reactionJustDeleted: null,
      commentJustArchived: null,
    };
  }),
);

export function criticalNewsFeedReducerFunc(state: CriticalNewsFeedState | undefined, action: Action) {
  return criticalNewsFeedReducer(state, action);
}
