import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { JobApplicationsModel } from 'src/app/models/jobApplicationsModel';
import { Store } from '@ngrx/store';
import { State } from '../../../core/store';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Router } from '@angular/router';
import { deepClone, getAllControlsErrors } from 'src/app/helpers/utils';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { archiveJobApplication, getCoverLetter, getJobApplications, getResume, loadCampuses, loadJobApplicationForms, saveAsProspectiveCandidate, savePhoneInterview, setPhoneInterviewNull } from 'src/app/core/store/job-applications/job-applications.action';
import * as XLSX from 'xlsx';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ScreeningInterviewModel } from 'src/app/models/screeningInterviewModel';
import { UserProfileModel } from 'src/app/models/userProfileModel';
import * as moment from 'moment';
import { FormFillComponent } from '../form-filling/form-fill/form-fill.component';
import { saveFBFormSubmissionFormSuccess } from 'src/app/core/store/form-builder/form-builder.actions';

@Component({
  selector: 'app-screening-interview',
  templateUrl: './screening-interview.component.html',
  styleUrls: ['./screening-interview.component.scss']
})
export class ScreeningInterviewComponent extends BaseComponent implements OnInit {

  @Input() jobApp: JobApplicationsModel = null;
  @Input() firstName: string = "";
  @Input() lastName: string = "";
  @Input() position: string = "";
  @Input() applicationID: number = null;
  @Input() formSubmissionID: number;
  @Input() programID: number = null;
  @Input() prospectiveCandidateID: number = null;
  @Output() myEvent = new EventEmitter<string>();

  @ViewChild('formFilling') formFillingComponent: FormFillComponent;

  modalReference: any;
  loading: boolean = false;
  canEdit: boolean;
  canView: boolean;
  archiveReason: string = "";
  campusesList = [{ label: 'loading...', value: undefined }];

  preInterviewPrepComplete: boolean = true;
  applicationInformationComplete: boolean = true;
  connectionQuestionsComplete: boolean = true;
  talentPassionQuestionsComplete: boolean = true;
  experienceQuestionsComplete: boolean = true;
  logisticalQuestionsComplete: boolean = true;
  nextStepsComplete: boolean = true;
  screeningCompleted: boolean = true;

  showOtherHowDidYourHearAboutUs: boolean = false;
  responsibilityOther: boolean = false;
  hasReferences: boolean = false;
  whyInterestedOther: boolean = false;
  stillInterestedOther: boolean = false;
  criticalThinkingOther: boolean = false;

  closeResult: string;

  yesNoAllOptions = [
    { label: 'All', value: null },
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];
  showArchiveReason: boolean;
  interviewID: number;
  intDate: Date;

  formID: number;

  constructor(
    private actions$: Actions,
    private alertService: AlertService,
    private store: Store<State>,
    private router: Router,
  ) {
    super();
  }

  ngOnInit() {
    var ai = this.applicationID ? this.applicationID : 0;
    var pi = this.prospectiveCandidateID ? this.prospectiveCandidateID : 0;
    this.subs.push(
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.canEdit = permissionIDs && !!permissionIDs.find(p => p === 7500 || p === 7501 || p === 7502);
      }),
      this.store.select(s => s.jobApplicationsState.loading).subscribe(loading => {
        this.loading = loading;
      }),
      this.store.select(s => s.jobApplicationsState.campuses).subscribe(campuses => {
        const campusesList = campuses && campuses.length > 0 ? campuses.map(c => ({ label: c.city, value: c.id }))
          : [{ label: 'loading...', value: undefined }];
        this.campusesList = campusesList;
        this.campusesList.unshift({ label: "", value: "" })
      }),
      this.store.select(s => s.jobApplicationsState.jobTitleForms).subscribe(jobTitleForms => {
        if (jobTitleForms != null) {
          let jobTitleForm = jobTitleForms.find(x => x.jobTitle === this.position);
          if (jobTitleForm) {
            this.formID = jobTitleForm.formID;
          }
        }
      }),
    );
    this.store.dispatch(loadCampuses());
    this.store.dispatch(loadJobApplicationForms());
  }

  moveToCandidate() {
    if (this.Validate()) {
      // If All Good
        this.decisionApplicant(this.jobApp);
    }
  }

  decisionApplicant(app) {
    if (this.Validate()) {
      this.alertService.confirm("Confirm", "Are you sure you want to move " + app.firstName + " " + app.lastName + " To the Prospective Candidates Queue?")
        .subscribe(
          anwser => {
            if (anwser) {
              this.Save();
              this.subs.push(this.actions$.pipe(
                ofType(saveFBFormSubmissionFormSuccess),
                tap(action => {
                  this.store.dispatch(saveAsProspectiveCandidate({ app: app }));
                  this.closeModal();
                })
              ).subscribe());
            }
          }, error => {
          }
        );
    }
  }

  archiveApplicantFromScreeningModal() {
    if (this.archiveReason != null && this.archiveReason != "") {
      this.showArchiveReason = false;
      this.archiveApplication(this.archiveReason);
      this.closeModal();
    }
    else {
      this.showArchiveReason = true;
    }
  }

  archiveApplication(reason: string) {
    if (this.Validate()) {
      this.Save();
      this.store.dispatch(archiveJobApplication({ jobAppID: this.applicationID, archiveReason: reason }));
      this.archiveReason = "";
    }
  }

  Validate(){
    if (this.formFillingComponent) {
      this.formFillingComponent.forceValidation();
      return this.formFillingComponent.isFormValid();
    }
    return true;
  }

  Save(close: boolean = false) {
    if (this.Validate()) {
      this.formFillingComponent.save();
      if (close) {
        this.closeModal();
      }
    }
  }

  SaveAndClose() {
    this.Save(true);
  }

  closeModal() {
    this.store.dispatch(setPhoneInterviewNull());
    this.myEvent.emit('eventDesc');
  }
}
