import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, tap, catchError, filter } from 'rxjs/operators';
import { Observable, of, pipe } from 'rxjs';
import { errorHappened } from '../profile/profile.actions';
import { Store, Action } from '@ngrx/store';
import { State } from '..';
import { AlertService } from 'src/app/shared/services/alert.service';
import { downloadFile } from 'src/app/helpers/utils';
import { getAssignedEquipmentList, getAssignedEquipmentListSuccess, createEquipment,
createEquipmentSuccess, createEquipmentFail, getMyEquipmentList, getMyEquipmentListSuccess,
getMyEquipmentListFail, searchEquipment, searchEquipmentSuccess, searchEquipmentFail,
saveAssignEquipment, saveAssignEquipmentSuccess, saveAssignEquipmentFail, getEquipmentAssignPdf,
getEquipmentAssignPdfSuccess, saveReturnEquipment, saveReturnEquipmentSuccess, saveReturnEquipmentFail,
loadUserEquipments, loadUserEquipmentsSuccess, loadUserEquipmentsFail, signEquipmentReturn, signEquipmentReturnSuccess,
  signEquipmentAssign, signEquipmentAssignSuccess, deleteEquipment, deleteEquipmentSuccess, deleteEquipmentFail, unarchiveEquipment,
  unarchiveEquipmentSuccess, unarchiveEquipmentFail, loadCampuses, loadCampusesSuccess, loadClassCodes, loadClassCodesSuccess, loadClassifications, loadClassificationsSuccess,
  saveEditAssignEquipment, saveEditAssignEquipmentSuccess, saveEditAssignEquipmentFail, getAssignedEquipments, getAssignedEquipmentsSuccess, loadEquipmentPermissionModalOptions, loadEquipmentPermissionModalOptionsSuccess, loadEquipmentPermissions, loadEquipmentPermissionsSuccess, saveEquipmentPermissions, saveEquipmentPermissionsSuccess, getEquipmentTypes, getEquipmentTypesSuccess
} from './equipment.actions';
import { EquipmentService } from '../../services/equipment.service';
import { EquipmentModel } from '../../../models/equipmentsModel';

@Injectable()
export class EquipmentEffects {
  constructor(private actions$: Actions,
              private equipmentService: EquipmentService,
              private store: Store<State>,
              private alertService: AlertService,
              private router: Router) {

  }

  loadCampuses = createEffect(() => this.actions$.pipe(
    ofType(loadCampuses),
    switchMap(action => this.equipmentService.getAllCampuses().pipe(
      map(campuses => loadCampusesSuccess({ campuses })),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))));

  loadClassCodes = createEffect(() => this.actions$.pipe(
    ofType(loadClassCodes),
    switchMap(action => this.equipmentService.getAllClassCodes().pipe(
      map(classCode => loadClassCodesSuccess({ classCode })),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))));

  loadClassifications = createEffect(() => this.actions$.pipe(
    ofType(loadClassifications),
    switchMap(action => this.equipmentService.getAllClassifications().pipe(
      map(classification => loadClassificationsSuccess({ classification })),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))));
    
  getEquipmentTypes = createEffect(() => this.actions$.pipe(
    ofType(getEquipmentTypes),
    switchMap(action => this.equipmentService.getEquipmentTypes().pipe(
      map(equipmentTypes => getEquipmentTypesSuccess({ equipmentTypes: equipmentTypes })),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))))

  loadEquipmentPermissionModalOptions = createEffect(() => this.actions$.pipe(
  ofType(loadEquipmentPermissionModalOptions),
  switchMap(action => this.equipmentService.loadEquipmentPermissionModalOptions().pipe(
    map(equipmentPermissionOptions => loadEquipmentPermissionModalOptionsSuccess({ equipmentPermissionOptions })),
    catchError(err => {
      return of(errorHappened({ err }));
    })
  ))));

  loadEquipmentPermissions = createEffect(() => this.actions$.pipe(
  ofType(loadEquipmentPermissions),
  switchMap(action => this.equipmentService.loadEquipmentPermissions(action.userId, action.roleId).pipe(
    map(equipmentPermissions => loadEquipmentPermissionsSuccess({ equipmentPermissions })),
    catchError(err => {
      return of(errorHappened({ err }));
    })
  ))));

  saveEquipmentPermissions = createEffect(() => this.actions$.pipe(
    ofType(saveEquipmentPermissions),
    switchMap(action => this.equipmentService.saveEquipmentPermissions(action.equipmentPermissions).pipe(
      map(resp => saveEquipmentPermissionsSuccess({ resp })),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))));

    saveEquipmentPermissionsSuccess = createEffect(() => this.actions$.pipe(
    ofType(saveEquipmentPermissionsSuccess),
    tap(action => {
      this.store.dispatch(loadEquipmentPermissionsSuccess({equipmentPermissions: action.resp}));
      this.alertService.success('Permissions Saved');
    })
  ), { dispatch: false });

  createEquipment = createEffect(() => this.actions$.pipe(
    ofType(createEquipment),
    switchMap(action => {
      let observable: Observable<EquipmentModel>;

      if (action.equipment.id > 0) {
        observable = this.equipmentService.editEquipment(action.equipment);
      }
      else {
        observable = this.equipmentService.createEquipment(action.equipment);
      }

      return observable.pipe(
        map(equipment => createEquipmentSuccess({ equipment })),
        catchError(err => {
          this.store.dispatch(createEquipmentFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));

  createEquipmentSuccess = createEffect(() => this.actions$.pipe(
    ofType(createEquipmentSuccess),
    tap(action => {
      this.alertService.success('Equipment Saved');
    })
  ), { dispatch: false });

  deleteEquipment = createEffect(() => this.actions$.pipe(
    ofType(deleteEquipment),
    switchMap(action => {
      return this.equipmentService.deleteEquipment(action.equipment).pipe(
        map(equipment => deleteEquipmentSuccess()),
        catchError(err => {
          this.store.dispatch(deleteEquipmentFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));

  deleteEquipmentSuccess = createEffect(() => this.actions$.pipe(
    ofType(deleteEquipmentSuccess),
    tap(action => {
      this.alertService.success('Equipment Deleted/Archived');
    })
  ), { dispatch: false });

  unarchiveEquipment = createEffect(() => this.actions$.pipe(
    ofType(unarchiveEquipment),
    switchMap(action => {
      return this.equipmentService.unarchiveEquipment(action.equipment).pipe(
        map(equipment => unarchiveEquipmentSuccess()),
        catchError(err => {
          this.store.dispatch(unarchiveEquipmentFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));

  unarchiveEquipmentSuccess = createEffect(() => this.actions$.pipe(
    ofType(unarchiveEquipmentSuccess),
    tap(action => {
      this.alertService.success('Equipment Unarchived');
    })
  ), { dispatch: false });

  getMyEquipmentList = createEffect(() => this.actions$.pipe(
    ofType(getMyEquipmentList),
    switchMap(action => {
      return this.equipmentService.getMyAssignedEquipments(action.userID).pipe(
        map(equipments => getMyEquipmentListSuccess({ equipments })),
        catchError(err => {
          this.store.dispatch(getMyEquipmentListFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));

  getAssignedEquipmentList = createEffect(() => this.actions$.pipe(
    ofType(getAssignedEquipmentList),
    switchMap(action => {
      return this.equipmentService.getAllEquipments(action.filter).pipe(
        map(equipmentWithAssignmentList => getAssignedEquipmentListSuccess({ equipmentWithAssignmentList })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  // get data for complete excel sheet
  getAssignedEquipments = createEffect(() => this.actions$.pipe(
    ofType(getAssignedEquipments),
    switchMap(action => {
      return this.equipmentService.getAllEquipmentsExcel(action.filter).pipe(
        map(equipmentWithAssignment => getAssignedEquipmentsSuccess({ equipmentWithAssignment })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  searchEquipment = createEffect(() => this.actions$.pipe(
    ofType(searchEquipment),
    switchMap(action => {
      return this.equipmentService.getSearchEquipments(action.filter).pipe(
        map(equipments => searchEquipmentSuccess({ equipments })),
        catchError(err => {
          this.store.dispatch(searchEquipmentFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));

  saveAssignEquipment = createEffect(() => this.actions$.pipe(
    ofType(saveAssignEquipment),
    switchMap(action => {
      return this.equipmentService.assignEquipments(action.assigningEquipments).pipe(
        map(equipments => saveAssignEquipmentSuccess()),
        catchError(err => {
          this.store.dispatch(saveAssignEquipmentFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));

  saveAssignEquipmentSuccess = createEffect(() => this.actions$.pipe(
    ofType(saveAssignEquipmentSuccess),
    tap(action => {
      this.alertService.success('Assignment created. The user will receive a document to sign.', true);
    })
  ), { dispatch: false });

  saveEditAssignEquipment = createEffect(() => this.actions$.pipe(
    ofType(saveEditAssignEquipment),
    switchMap(action => {
      return this.equipmentService.editAssignEquipments(action.assigningEquipments).pipe(
        map(equipments => saveEditAssignEquipmentSuccess()),
        catchError(err => {
          this.store.dispatch(saveEditAssignEquipmentFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));

  saveEditAssignEquipmentSuccess = createEffect(() => this.actions$.pipe(
    ofType(saveEditAssignEquipmentSuccess),
    tap(action => {
      this.alertService.success('Assignement saved.', true);
    })
  ), { dispatch: false });

  getEquipmentAssignPdf = createEffect(() => this.actions$.pipe(
    ofType(getEquipmentAssignPdf),
    switchMap(action => {
      return this.equipmentService.getEquipmentAssignDocument(action.assignedEquipmentID, action.returned).pipe(
        map(doc => getEquipmentAssignPdfSuccess({ doc, download: action.download })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  getEquipmentAssignPdfSuccess = createEffect(() => this.actions$.pipe(
    ofType(getEquipmentAssignPdfSuccess),
    tap(action => {
      if (action.download) {
        downloadFile(action.doc, 'DocumentAssigned.pdf');
      }
    })
  ), { dispatch: false });


  loadUserEquipments = createEffect(() => this.actions$.pipe(
    ofType(loadUserEquipments),
    switchMap(action => {
      return this.equipmentService.getMyAssignedEquipments(action.userID).pipe(
        map(assignedEquipments => loadUserEquipmentsSuccess({ assignedEquipments: assignedEquipments })),
        catchError(err => {
          this.store.dispatch(loadUserEquipmentsFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));

  loadUserEquipmentsSuccess = createEffect(() => this.actions$.pipe(
    ofType(loadUserEquipmentsSuccess),
    tap(action => {
    })
  ), { dispatch: false });

  saveReturnEquipment = createEffect(() => this.actions$.pipe(
    ofType(saveReturnEquipment),
    switchMap(action => {
      return this.equipmentService.returnEquipments(action.returningEquipments).pipe(
        map(equipments => {
          const assignedEquipment = equipments[0];
          return saveReturnEquipmentSuccess({ assignedEquipment });
        }),
        catchError(err => {
          this.store.dispatch(saveReturnEquipmentFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));

  saveReturnEquipmentSuccess = createEffect(() => this.actions$.pipe(
    ofType(saveReturnEquipmentSuccess),
    tap(action => {
      this.alertService.success('Equipment return document created.', true);
      this.router.navigate(['/equipment/signReturn/' + action.assignedEquipment.id]);
    })
  ), { dispatch: false });


  signEquipmentReturn = createEffect(() => this.actions$.pipe(
    ofType(signEquipmentReturn),
    switchMap(action => {
      return this.equipmentService.signReturnDocument(action.assignedEquipmentID, action.signedFields).pipe(
        map(docSigned => signEquipmentReturnSuccess({ docSigned })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));


  signEquipmentAssign = createEffect(() => this.actions$.pipe(
    ofType(signEquipmentAssign),
    switchMap(action => {
      return this.equipmentService.signAssignedDocument(action.assignedEquipmentID, action.signedFields).pipe(
        map(docSigned => signEquipmentAssignSuccess({ docSigned })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

}
