import { SelfCarePlannerSectionModel } from './../../../models/selfcareModels';
import { SelfCareCategoryModel } from 'src/app/models/selfcareModels';
import { createReducer, on, Action } from '@ngrx/store';
import { deepClone, getAllControlsErrors } from 'src/app/helpers/utils';
import { clearManageSelfcareCategory, loadManageSelfcareCategories, loadManageSelfcareCategoriesFailure, loadManageSelfcareCategoriesSuccess, loadManageSelfcareCategory, loadManageSelfcareCategoryFailure, loadManageSelfcareCategorySuccess, loadManageSelfcarePlanner, loadManageSelfcarePlannerFailure, loadManageSelfcarePlannerSuccess, saveManageSelfcareCategory, saveManageSelfcareCategoryFailure, saveManageSelfcareCategorySuccess, updateManageSelfcareCategoryForm, loadManageSelfcarePlannerSection, loadManageSelfcarePlannerSectionSuccess, loadManageSelfcarePlannerSectionFailure, saveManageSelfcarePlannerSection, saveManageSelfcarePlannerSectionSuccess, saveManageSelfcarePlannerSectionFailure, clearManageSelfcarePlannerSection, updateManageSelfcarePlannerSectionForm } from './manage-selfcare.actions';
import { FormError } from 'src/app/models/utilModels';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

export interface ManageSelfcareState {
  plannerSections: SelfCarePlannerSectionModel[];
  plannerSection: SelfCarePlannerSectionModel;
  category: SelfCareCategoryModel;
  categories: SelfCareCategoryModel[];
  loading: boolean;
  savingCategory: boolean;
  savingPlannerSection: boolean;
  formErrors: FormError[];
}

const initialState: ManageSelfcareState = {
  plannerSections: null,
  plannerSection: null,
  category: null,
  categories: null,
  loading: false,
  savingCategory: false,
  savingPlannerSection: false,
  formErrors: null,

};

export const manageSelfcareStateReducer = createReducer(initialState,
  on(loadManageSelfcarePlanner, (state, action) => {
    return {
      ...state,
      loading: true
    };
  }),
  on(loadManageSelfcarePlannerSuccess, (state, action) => {
    return {
      ...state,
      plannerSections: action.plannerSections,
      loading: false
    };
  }),
  on(loadManageSelfcarePlannerFailure, (state, action) => {
    return {
      ...state,
      plannerSections: null,
      loading: false
    };
  }),


  on(loadManageSelfcarePlannerSection, (state, action) => {
    return {
      ...state,
      loading: true
    };
  }),
  on(loadManageSelfcarePlannerSectionSuccess, (state, action) => {
    let formErrors;
    if (action.plannerSection) {
      const form = initSelfcarePlannerSectionForm(action.plannerSection);
      formErrors = getAllControlsErrors(form);
    }
    return {
      ...state,
      plannerSection: deepClone(action.plannerSection),
      formErrors,
      loading: false,
    };
  }),
  on(loadManageSelfcarePlannerSectionFailure, (state, action) => {
    return {
      ...state,
      loading: false
    };
  }),
  on(saveManageSelfcarePlannerSection, (state, action) => ({ ...state, savingPlannerSection: true })),
  on(saveManageSelfcarePlannerSectionSuccess, (state, action) => ({ ...state, savingPlannerSection: false })),
  on(saveManageSelfcarePlannerSectionFailure, (state, action) => ({ ...state, savingPlannerSection: false })),

  on(clearManageSelfcarePlannerSection, (state, action) => {
    return {
      ...state,
      plannerSection: null,
      formErrors: null,
      loading: false,
    };
  }),
  on(updateManageSelfcarePlannerSectionForm, (state, action) => {

    const newValue = { ...state.plannerSection, ...action.plannerSectionValues, ...{ items: action.itemsValues } };
    const newState = { ...state, ...{ plannerSection: newValue, formErrors: action.formErrors } };
    return newState;
  }),

  on(loadManageSelfcareCategories, (state, action) => {
    return {
      ...state,
      loading: true
    };
  }),
  on(loadManageSelfcareCategoriesSuccess, (state, action) => {
    return {
      ...state,
      categories: action.categories,
      loading: false
    };
  }),
  on(loadManageSelfcareCategoriesFailure, (state, action) => {
    return {
      ...state,
      loading: false
    };
  }),

  on(loadManageSelfcareCategory, (state, action) => {
    return {
      ...state,
      loading: true
    };
  }),
  on(loadManageSelfcareCategorySuccess, (state, action) => {
    let formErrors;
    if (action.category) {
      const form = initSelfcareCategoryForm(action.category);
      formErrors = getAllControlsErrors(form);
    }
    return {
      ...state,
      category: deepClone(action.category),
      formErrors,
      loading: false,
    };
  }),
  on(loadManageSelfcareCategoryFailure, (state, action) => {
    return {
      ...state,
      loading: false
    };
  }),
  on(saveManageSelfcareCategory, (state, action) => ({ ...state, savingCategory: true })),
  on(saveManageSelfcareCategorySuccess, (state, action) => ({ ...state, savingCategory: false })),
  on(saveManageSelfcareCategoryFailure, (state, action) => ({ ...state, savingCategory: false })),

  on(clearManageSelfcareCategory, (state, action) => {
    return {
      ...state,
      category: null,
      formErrors: null,
      loading: false,
    };
  }),
  on(updateManageSelfcareCategoryForm, (state, action) => {

    const newValue = { ...state.category, ...action.categoryValues, ...{ questions: action.questionValues } };
    const newState = { ...state, ...{ category: newValue, formErrors: action.formErrors } };
    return newState;
  }),
);

export function manageSelfcareStateReducerFunc(state: ManageSelfcareState | undefined, action: Action) {
  return manageSelfcareStateReducer(state, action);
}


export function initSelfcareCategoryForm(category: SelfCareCategoryModel) {

  const questionsArray = new FormArray([]);
  if (category.questions) {
    for (const question of category.questions) {
      questionsArray.push(new FormGroup({
        questionID: new FormControl(question.questionID),
        questionText: new FormControl(question.questionText, [Validators.required, Validators.maxLength(2000)]),
        displayText: new FormControl(question.displayText, [Validators.maxLength(2000)]),
        categoryOrderNr: new FormControl(question.categoryOrderNr, [Validators.required]),
        plannerSectionItemID: new FormControl(question.plannerSectionItemID),
        allowPublic: new FormControl(question.allowPublic),
        questionType: new FormControl(question.questionType),
        questionOptions: new FormControl(question.questionOptions),
      }));
    }
  }
  const form = new FormGroup({
    category: new FormGroup({
      categoryName: new FormControl(category.categoryName, [Validators.required, Validators.maxLength(250)]),
      categoryImage: new FormControl(category.categoryImage, [Validators.required, Validators.maxLength(250)]),
      mainColor: new FormControl(category.mainColor, [Validators.required]),
      slideshowLink: new FormControl(category.slideshowLink, [Validators.required, Validators.maxLength(250)]),
      categoryDescription: new FormControl(category.categoryDescription, [Validators.required, Validators.maxLength(4000)]),
      linkText: new FormControl(category.linkText, [Validators.maxLength(1000)]),
      linkDescripton: new FormControl(category.linkDescripton, [Validators.maxLength(1000)]),
      linkUrl: new FormControl(category.linkUrl, [Validators.maxLength(250)]),
      onboarding_UploadDocTypeID: new FormControl(category.onboarding_UploadDocTypeID),
      uploadDocLinkText: new FormControl(category.uploadDocLinkText, [Validators.maxLength(1000)]),
      uploadDocLinkDescription: new FormControl(category.uploadDocLinkDescription, [Validators.maxLength(1000)]),
    }),
    questions: questionsArray
  });

  return form;
}


export function initSelfcarePlannerSectionForm(plannerSection: SelfCarePlannerSectionModel) {

  const itemsArray = new FormArray([]);
  if (plannerSection.items) {
    for (const item of plannerSection.items) {
      itemsArray.push(new FormGroup({
        plannerSectionItemID: new FormControl(item.plannerSectionItemID),
        sectionItem: new FormControl(item.sectionItem, [Validators.maxLength(2000)]),
        orderNr: new FormControl(item.orderNr, [Validators.required]),
      }));
    }
  }
  const form = new FormGroup({
    plannerSection: new FormGroup({
      sectionTitle: new FormControl(plannerSection.sectionTitle, [Validators.required, Validators.maxLength(250)]),
      sectionDescription: new FormControl(plannerSection.sectionDescription, [Validators.maxLength(4000)]),
      mainColor: new FormControl(plannerSection.mainColor, [Validators.required]),
      layoutType: new FormControl(plannerSection.layoutType, [Validators.required]),

    }),
    items: itemsArray
  });

  return form;
}
