<form class="card mt-2">
  <div class="card-body">
    <div class="float-right">
      <button *ngIf="canViewKraReport" type="button" routerLink="/employee-review/report/kra" class="btn btn-secondary">KRAs</button>
    </div>
    <h2 class="mb-3">Completed Oversight Reviews</h2>
    <div class="mb-3">

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Employee:</h5>
        </div>
        <div class="col-10">
          <div class="ml-2 w-100 mb-2">
            <p-autoComplete [style]="{'width': '100%'}" [inputStyle]="{'width': '100%'}" [(ngModel)]="prefixText"
                            [suggestions]="userEmployeeList" (completeMethod)="getStaffList($event)" field="label"
                            [ngModelOptions]="{standalone: true}" [minLength]="1" [autoHighlight]="true"
                            (onSelect)="reloadListAfterStaffChage($event)" [forceSelection]="true"
                            (onClear)="reloadListAfterClear($event)"></p-autoComplete>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Campus:</h5>
        </div>
        <div class="col-10">
          <i *ngIf="!campusList" class="material-icons rotate text-muted">loop</i>
          <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="campusList" [(ngModel)]="campusId" (ngModelChange)="reloadFilter(this.filter)" [ngModelOptions]="{standalone: true}"></p-dropdown>
        </div>
      </div>      

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Status:</h5>
        </div>
        <div class="col-10">          
          <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="statusOptions" [(ngModel)]="meetsRequirements" (ngModelChange)="reloadFilter(this.filter)" [ngModelOptions]="{standalone: true}"></p-dropdown>
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>From Date:</h5>
        </div>
        <div class="col-10">
          <input type="date" class="form-control ml-2 w-100 mb-2" [(ngModel)]="startDate" (blur)="reloadFilter(this.filter)" [ngModelOptions]="{standalone: true}" />
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>To Date:</h5>
        </div>
        <div class="col-10">
          <input type="date" class="form-control ml-2 w-100 mb-2" [(ngModel)]="endDate" (blur)="reloadFilter(this.filter)" [ngModelOptions]="{standalone: true}" />
        </div>
      </div>

    </div>

    <i [hidden]="!loadingList" class="material-icons rotate text-muted">loop</i>
    <p-table [value]="list?.result" [responsive]="true" [paginator]="true" [rows]="20"
             [totalRecords]="list?.totalRecords" [lazy]="true" (onLazyLoad)="reloadFilter($event)">
      <ng-template pTemplate="header">
        <tr>
          <th width="100px"></th>          
          <th [pSortableColumn]="'PlayerName'">Employee <p-sortIcon [field]="'PlayerName'"></p-sortIcon></th>
          <th [pSortableColumn]="'Campus'">Campus <p-sortIcon [field]="'Campus'"></p-sortIcon></th>
          <th [pSortableColumn]="'TemplateDescription'">KRA Description <p-sortIcon [field]="'TemplateDescription'"></p-sortIcon></th>
          <th>Oversight User(s)</th>
          <th [pSortableColumn]="'OversightReviewDate'">Oversight Review Date <p-sortIcon [field]="'OversightReviewDate'"></p-sortIcon></th>
          <th [pSortableColumn]="'OversightScore'">Oversight Score <p-sortIcon [field]="'OversightScore'"></p-sortIcon></th>
          <th>Self Review Date</th>
          <th>Self Score</th>
          <th>Actions</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-review>
        <tr>
          <td>
            <button class="btn btn-secondary mr-2" (click)="openReview(review)" title="View Oversight Review">
              <i class="fas fa-file-alt"></i>
            </button>
          </td>          
          <td>
            <span class="ui-column-title">Employee</span>
            {{review.employeeLastName}}, {{review.employeeFirstName}}
          </td>          
          <td>
            <span class="ui-column-title">Campus</span>
            {{review.primaryProgram}}
          </td>
          <td>
            <span class="ui-column-title">KRA Description</span>
            {{review.templateDescription}}
          </td>
          <td>
            <span class="ui-column-title">Oversight User(s)</span>
            <span *ngFor="let o of review.oversightNames">{{o}}<br /></span>
          </td>
          <td>
            <span class="ui-column-title">Oversight Review Date</span>
            {{review.oversightReviewDate | date: 'MM/dd/yyyy'}}
          </td>          
          <td>
            <span class="ui-column-title">Oversight Score</span>
            {{review.oversightScore}}
          </td>
          <td>
            <span class="ui-column-title">Self Review Date</span>
            {{(review.reviewDate !== null) ? (review.reviewDate | date: 'MM/dd/yyyy') : ""}}
          </td>
          <td>
            <span class="ui-column-title">Self Score</span>
            {{review.score}}
          </td>
          <td>
            <button class="btn btn-link mr-2" *ngIf="review.meetsNewOfferLetterRequirements === null || review.meetsNewOfferLetterRequirements === true" (click)="saveDoesNotMeetExpections(review)" title="Does Not Meet Expectations">
              <i class="fas fa-frown-open" style="color: red; font-size: x-large;"></i>
            </button>
            <button class="btn btn-link mr-2" *ngIf="review.meetsNewOfferLetterRequirements === null || review.meetsNewOfferLetterRequirements === false" (click)="saveOfferLetterSent(review)" title="Offer Letter Sent">
              <i class="fas fa-hand-holding-usd" style="color: green;  font-size: x-large;"></i>
            </button>
            <button class="btn btn-link mr-2" *ngIf="review.meetsNewOfferLetterRequirements !== null" (click)="saveReset(review)" title="Reset">
              <i class="fas fa-undo" style="font-size: x-large;"></i>
            </button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</form>
