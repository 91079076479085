import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { State } from '..';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { errorHappened } from '../profile/profile.actions';
import { EmployeeReviewService } from "../../services/employee-review.service";
import { autoSaveOversightReview, autoSaveOversightReviewFailure, autoSaveOversightReviewSuccess, autoSavePlayerReview, autoSavePlayerReviewFailure, autoSavePlayerReviewSuccess, deleteEmployeeReview, deleteEmployeeReviewFail, deleteEmployeeReviewOversight, deleteEmployeeReviewOversightFail, deleteEmployeeReviewOversightSuccess, deleteEmployeeReviewSuccess, getCompletedKraList, getCompletedKraListSuccess, getEmployeeReview, getEmployeeReviews, getEmployeeReviewsFailure, getEmployeeReviewsSuccess, getEmployeeReviewSuccess, getKraList, getKraListSuccess, getOverseenEmployee, getOverseenEmployeeSuccess, getOversightReview, getOversightReviewSuccess, getReviewOpenPeriod, getReviewOpenPeriodSuccess, loadMyTemplates, loadMyTemplatesSuccess, loadReviews, loadReviewsSuccess, loadTemplateFilter, loadTemplateFilterSuccess, postOversightReview, postOversightReviewFailure, postOversightReviewSuccess, postPlayerReview, postPlayerReviewFailure, postPlayerReviewSuccess, save2ndOversightReview, save2ndOversightReviewSuccess, saveMeetsExpectations, saveMeetsExpectationsSuccess} from "./employee-review.actions";




@Injectable()
export class EmployeeReviewEffects {
  constructor(
    private actions$: Actions,
    private employeeReviewService: EmployeeReviewService,
    private store: Store<State>) { }

  loadTemplates = createEffect(() => this.actions$.pipe(
    ofType(loadMyTemplates),
    switchMap(action => {
      return this.employeeReviewService.getMyTemplates().pipe(
        map(employeeReviewTemplates => loadMyTemplatesSuccess({ templates: employeeReviewTemplates })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  savePlayerReview = createEffect(() => this.actions$.pipe(
    ofType(postPlayerReview),
    switchMap(action => {
      return this.employeeReviewService.postReview(action.review).pipe(
        map(a => postPlayerReviewSuccess()),
        catchError(err => {
          this.store.dispatch(postPlayerReviewFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  getOversightData = createEffect(() => this.actions$.pipe(
    ofType(getEmployeeReviews),
    switchMap(action => {
      return this.employeeReviewService.getOversightData(action.userId).pipe(
         map(oversightData => getEmployeeReviewsSuccess({ oversightData: oversightData })),
         catchError(err => {
           this.store.dispatch(getEmployeeReviewsFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  saveOversightReview = createEffect(() => this.actions$.pipe(
    ofType(postOversightReview),
    switchMap(action => {
      return this.employeeReviewService.postOversightReview(action.review).pipe(
        map(a => postOversightReviewSuccess()),
        catchError(err => {
          this.store.dispatch(postOversightReviewFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));
  loadReviews = createEffect(() => this.actions$.pipe(
    ofType(loadReviews),
    switchMap(action => {
      return this.employeeReviewService.getReviews(action.userId, action.filter).pipe(
        map(employeeReviews => loadReviewsSuccess({ employeeReviews })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  getOversightEmployee = createEffect(() => this.actions$.pipe(
    ofType(getOverseenEmployee),
    switchMap(action => {
      return this.employeeReviewService.getOverseenEmployee(action.userId).pipe(
        map(user => getOverseenEmployeeSuccess({ user })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));
  getEmployeeReview = createEffect(() => this.actions$.pipe(
    ofType(getEmployeeReview),
    switchMap(action => {
      return this.employeeReviewService.getReview(action.reviewId).pipe(
        map(review => getEmployeeReviewSuccess({ review: review })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));
  getOversightReview = createEffect(() => this.actions$.pipe(
    ofType(getOversightReview),
    switchMap(action => {
      return this.employeeReviewService.getOversightReview(action.reviewId).pipe(
        map(review => getOversightReviewSuccess({ review: review })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));
  autoSavePlayerReview = createEffect(() => this.actions$.pipe(
    ofType(autoSavePlayerReview),
    switchMap(action => {
      return this.employeeReviewService.autoSaveEmployeeReview(action.review).pipe(
        map(a => autoSavePlayerReviewSuccess()),
        catchError(err => {
          this.store.dispatch(autoSavePlayerReviewFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  autoSaveOversightReview = createEffect(() => this.actions$.pipe(
    ofType(autoSaveOversightReview),
    switchMap(action => {
      return this.employeeReviewService.autoSaveOversightReview(action.review).pipe(
        map(a => autoSaveOversightReviewSuccess()),
        catchError(err => {
          this.store.dispatch(autoSaveOversightReviewFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  getCompletedKraList = createEffect(() => this.actions$.pipe(
    ofType(getCompletedKraList),
    switchMap(action => {
      return this.employeeReviewService.getCompletedKraList(action.filter).pipe(
        map(completedKraList => getCompletedKraListSuccess({ completedKraList })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  saveMeetsExpectations = createEffect(() => this.actions$.pipe(
    ofType(saveMeetsExpectations),
    switchMap(action => {
      return this.employeeReviewService.saveMeetsExpectations(action.review).pipe(
        map(review => saveMeetsExpectationsSuccess()),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));
  
  save2ndOversightReview = createEffect(() => this.actions$.pipe(
    ofType(save2ndOversightReview),
    switchMap(action => {
      return this.employeeReviewService.saveOversightReview(action.secondOversightReview).pipe(
        map(review => save2ndOversightReviewSuccess()),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  getReviewOpenPeriod = createEffect(() => this.actions$.pipe(
    ofType(getReviewOpenPeriod),
    switchMap(action => {
      return this.employeeReviewService.getReviewOpenPeriod(action.userId).pipe(
        map(openPeriod => getReviewOpenPeriodSuccess({ openPeriod })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  getKraList = createEffect(() => this.actions$.pipe(
    ofType(getKraList),
    switchMap(action => {
      return this.employeeReviewService.getKraList(action.filter).pipe(
        map(kraList => getKraListSuccess({ kraList })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  loadTemplateFilter = createEffect(() => this.actions$.pipe(
    ofType(loadTemplateFilter),
    switchMap(action => this.employeeReviewService.getTemplateFilter().pipe(
      map(templates => loadTemplateFilterSuccess({ templates })),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))));

  deleteEmployeeReview = createEffect(() => this.actions$.pipe(
    ofType(deleteEmployeeReview),
    switchMap(action => {
      return this.employeeReviewService.deleteKRA(action.reviewId).pipe(
        map(review => deleteEmployeeReviewSuccess()),
        catchError(err => {
          this.store.dispatch(deleteEmployeeReviewFail(err));
          return of(errorHappened({ err }));
        }));
    })
  ));

  deleteEmployeeReviewOversight = createEffect(() => this.actions$.pipe(
    ofType(deleteEmployeeReviewOversight),
    switchMap(action => {
      return this.employeeReviewService.deleteEmployeeReviewOversight(action.reviewId).pipe(
        map(review => deleteEmployeeReviewOversightSuccess()),
        catchError(err => {
          this.store.dispatch(deleteEmployeeReviewOversightFail(err));
          return of(errorHappened({ err }));
        }));
    })
  ));

}
