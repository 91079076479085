<div class="card mt-2" >
  <div class="card-body">
    <button  class="btn btn-secondary float-right pointer"  routerLink="/employee-review/template/list">Back</button>
    <h2 class="mb-3">Values </h2>
    <p></p>
    <button  class="btn btn-secondary pointer mb-3" (click)="newValueColorInfo()">New Value</button>
    <div class="row">
      <div class="col-12">

        <i *ngIf="loadingValueColor" class="material-icons rotate text-muted">loop</i>
        <p-table  [value]="valueColors" [responsive]="true" >
            <ng-template pTemplate="header">
                <tr>
                    <th width="120px"></th>
                    <th [pSortableColumn]="'ValueName'" >Value Name
                        <p-sortIcon [field]="'ValueName'"></p-sortIcon></th>

                    <th [pSortableColumn]="'ColorRGB'">Color
                      <p-sortIcon [field]="'ColorRGB'"></p-sortIcon>
                    </th>


                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-valueColor>
                <tr >
                    <td>
                      <span class="pointer mr-2" (click)="editValueColor(valueColor)">
                        <i title="Edit Value" class="fas fa-edit"></i>
                      </span>
                      <span class="pointer mr-2" (click)="deleteValueColor(valueColor)">
                        <i title="Delete Value" class="fas fa-trash"></i>
                      </span>
                    </td>
                    <td>
                        <span class="ui-column-title">Value Name</span>
                      {{valueColor.valueName }}
                    </td>

                    <td>
                        <span class="ui-column-title">Color</span>
                        <div class="d-flex">
                          <div class="valuecolor-colorsquare mr-2" [style.backgroundColor]="valueColor.colorRGB"></div>
                          {{ valueColor.colorRGB}}
                        </div>
                    </td>

                </tr>
            </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>


<p-dialog [header]="(valueColorInfo.id?'Edit':'Add')+ ' Value'" [focusTrap]="false" [modal]="true" [styleClass]="'top-dialog'"
          [style]="{width: '70vw'}" [(visible)]="openValueInfo">

            <form class="" [formGroup]="form" *ngIf="form && valueColorInfo">
              <div class="row m-0">
                <div class="col-4 d-flex align-items-center">
                  <b>Value Name</b>
                </div>
                <div class="col-8 ">
                    <input id="valueNameInput" type="text" class="form-control mt-2" formControlName="valueName"/>
                    <app-validation-message [form]="form" [path]="'valueName'" [name]="'valueName'" [label]="'Value Name'"> </app-validation-message>
                </div>

                <div class="col-4 d-flex align-items-center">
                  <b>Color</b>
                </div>
                <div class="col-8 d-flex ">
                  <p-colorPicker styleClass="mt-2 mr-2" [(ngModel)]="colorRGB" (ngModelChange)="changedColor()" [ngModelOptions]="{standalone: true}"></p-colorPicker>
                  <input id="colorRGBInput" type="text" class="form-control mt-2" [(ngModel)]="colorRGB" (ngModelChange)="changedColor()" [ngModelOptions]="{standalone: true}"/>
                    <app-validation-message [form]="form" [path]="'colorRGB'" [name]="'colorRGB'" [label]="'Color'"> </app-validation-message>
                </div>

              </div>

              <button class="btn btn-primary mt-3" id="saveButton" [class.disabled]="form.invalid" (click)="save()">Save</button>
            </form>
</p-dialog>
