<div class="wrapper-comments w-100">
    <div *ngIf="allComments != null && allComments.length > 0" class="w-100">
        <div *ngFor="let comment of allComments; let i = index" [ngStyle]="{'background-color': i%2 == 0 ? 'rgb(189, 228, 245)' : 'rgb(234, 249, 255)'}" class="d-flex flex-column mt-1 mb-1 individual-comment">
            <div class="comment-menu-icon d-flex justify-content-end align-items-center" (click)="stopPropagation($event)">
                <i *ngIf="comment.pinned" class="fas fa-map-pin" ></i>
                <i *ngIf="manageAll || managePinnedComments || comment.userID === userID" (click)="toggleCommentMenu($event, comment.id)" class="ml-2 pointer fas fa-angle-down"></i>
            </div>
            <div *ngIf="showCommentMenu && selectedCommentID == comment.id" class="comment-menu-options d-flex flex-column justify-content-center align-items-center" (mouseleave)="closeCommentMenu()">
                <div *ngIf="manageAll || comment.userID === userID" (click)="editComment(comment)">Edit&nbsp;Comment</div>
                <div *ngIf="manageAll || managePinnedComments" (click)="pinComment(comment)">{{ comment.pinned != true ? 'Pin' : 'Unpin' }}&nbsp;Comment</div>
                <div *ngIf="manageAll || comment.userID === userID" (click)="archiveComment(comment)">Archive&nbsp;Comment</div>
            </div>
            <div class="d-flex align-items-center w-100 comments-container">
                <div class="comment-data-container row">
                    <div class="col-12 w-100 p-0 m-0 d-flex justify-content-center align-content-center">
                        {{comment.userFirstName}}&nbsp;{{comment.userLastName}}
                    </div>
                    <div class="col-6 p-0 m-0  d-flex justify-content-center align-items-center">
                        <img [src]="comment.userImage ? comment.userImage : 'assets/img/Portrait_Placeholder.png'" class="img-rounded comment-img-sm">
                    </div>
                    <div class="col-6 p-0 m-0">
                        <p class="mb-0 mt-1">{{ (comment.updatedDate == null ? comment.createdDate : comment.updatedDate) | date:'MM/dd/yyyy' }} <br> {{ (comment.updatedDate == null ? comment.createdDate : comment.updatedDate) | date:'hh:mm&nbsp;a' }} {{comment.updatedDate == null ? '' : '(edited)' }}</p>
                    </div>
                </div>
                <div *ngIf="!editSelectedComment || comment.id != selectedEditCommentID" class="posts-comment d-flex justify-content-between align-items-center">
                    <div class="comment-message d-flex justify-content-center align-items-center">
                        <p class="mb-0 w-100">{{comment.comment}}</p>
                    </div>
                </div>
                <div *ngIf="editSelectedComment && comment.id == selectedEditCommentID" class="posts-comment mt-3 pt-3 d-flex justify-content-between align-items-center">
                    <div class="comment-message-edit d-flex flex-column align-items-center">
                        <textarea class="edit-comment-input w95" #commentEditInput (input)="onCommentEditInput($event)" (focus)="onCommentEditInput($event)" rows="1" [(ngModel)]='editCommentMessage' (ngModelChange)="countEditCommentCharacters()" ></textarea>
                        <p class="d-flex mb-0 pb-0 w95 justify-content-end" style="font-size:9px;">{{editCharCount}} / 2000</p>
                        <div class="w95 d-flex justify-content-end mt-2">
                            <button class="edit-save mr-2" (click)="saveEditComment(comment)">Save</button>
                            <button class="edit-cancel" (click)="closeEditComment()">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="canComment" class="d-flex w-100 justify-content-center align-items-center m-0 mt-2 add-comment-container">
        <div class="d-flex flex-column justify-content-end align-items-center mt-2 ml-2 comment-input-count-container">
            <textarea class="comment-input w-100" #commentInput (input)="onCommentInput($event)" (focus)="onCommentInput($event)" rows="1" [(ngModel)]='commentMessage' (ngModelChange)="countCharacters()" ></textarea>
            <div class="w-100 m-0 p-0 mr-3 d-flex justify-content-end">
                <p class="m-0 mr-3 p-0 pr-3" style="font-size:9px;">{{commentCharCount}} / 2000</p>
            </div>
        </div>
        <div class="comment-button-container d-flex justify-content-center mb-2 mr-2 align-items-center">
            <button class="comment-button" (click)="saveComment()">Comment</button>
        </div>
    </div>
</div>
