import {
  SurveyModel, SurveyFullAnswerModel, SurveyReportModel,
  SurveyReportFilterModel,
  SurveyReportDetailFilterModel,
  SurveyReportDetailModel
} from './../../../models/surveyModel';
import { createAction, props } from '@ngrx/store';
import { FormError } from 'src/app/models/utilModels';

export const loadSurveyInfo = createAction('[Survey] Load SurveyInfo', props<{ surveyID: number }>());
export const loadSurveyInfoSuccess = createAction('[Survey] Load SurveyInfo Success', props<{ survey: SurveyModel }>());
export const loadSurveyInfoFail = createAction('[Survey] Load SurveyInfo Fail');

export const updateSurveyAnswerForm = createAction('[Survey] Update SurveyAnswer Form',
  props<{ answerValues: any; generalValues: any; formErrors: FormError[] }>());
export const saveSurveyAnswer = createAction('[Survey] Save SurveyAnswer', props<{ answer: SurveyFullAnswerModel }>());
export const saveSurveyAnswerSuccess = createAction('[Survey] Save SurveyAnswer Success');
export const saveSurveyAnswerFail = createAction('[Survey] Save SurveyAnswer Fail');

export const loadMyUnansweredSurvey = createAction('[Survey] Load My Unswered Survey');
export const loadMyUnansweredSurveySuccess = createAction('[Survey] Load My Unswered Survey Success', props<{ surveys: SurveyModel[] }>());
export const loadMyUnansweredSurveyFail = createAction('[Survey] Load My Unswered Survey Fail');

export const loadSurveyReport = createAction('[Survey] Load Survey Report', props<{ filter: SurveyReportFilterModel }>());
export const loadSurveyReportSuccess = createAction('[Survey] Load Survey Report Success', props<{ surveyReport: SurveyReportModel[] }>());
export const loadSurveyReportFail = createAction('[Survey] Load Survey Report Fail');

export const loadSurveyReportDetail = createAction('[Survey] Load Survey Report Detail',
  props<{ filter: SurveyReportDetailFilterModel }>());
export const loadSurveyReportDetailSuccess = createAction('[Survey] Load Survey Report Detail Success',
  props<{ surveyReportDetail: SurveyReportDetailModel[] }>());
export const loadSurveyReportDetailFail = createAction('[Survey] Load Survey Report Detail Fail');

