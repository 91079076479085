import { loadMedClinicPatients } from './../../../../core/store/medclinic/medclinic.actions';
import { FormBuilderState } from './../../../../core/store/form-builder/form-builder.reducer';
import { FBFormModel } from './../../../../models/formBuilderModels';
import { BaseComponent } from './../../../../core/abstracts/baseComponent';
import { BenefitsState } from './../../../../core/store/benefits/benefits.reducer';
import { BenefitPlanModel } from './../../../../models/benefitsModels';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { Actions, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { MultiSelect } from 'primeng/multiselect';
import { AlertService } from '../../../../shared/services/alert.service';
import { deepClone, downloadFile, getTimeStampString } from '../../../../helpers/utils';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { tap } from 'rxjs/operators';
import { DateRangeModel } from '../../../../models/dateRangeModel';
import { DropdownFilterService } from '../../../../core/services/dropdown-filter.service';
import { deleteFBForm, getFBFormTable } from 'src/app/core/store/form-builder/form-builder.actions';
import { CreateEmployeePatientModel } from 'src/app/models/medClinicModels';

@Component({
  selector: 'app-medclinic-patients-list',
  templateUrl: './medclinic-patients-list.component.html',
  styleUrls: ['./medclinic-patients-list.component.scss']
})
export class MedclinicPatientsListComponent extends BaseComponent implements OnInit {

  medclinicPatientsList: CreateEmployeePatientModel[];
  canEdit: boolean;
  loadingPatients = false;
  filter: any;

  formNameFilter;

  constructor(
    private actions$: Actions,
    private alertService: AlertService,
    private store: Store<State>,
    private router: Router,
    private confirmationService: ConfirmationService,
    private dropdownFilterService: DropdownFilterService
  ) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.canEdit = permissionIDs && !!permissionIDs.find(p => p === 11001);
      }),
      this.store.select(s => s.medClinicState.patientsList).subscribe(medclinicPatientsList => {
        if (medclinicPatientsList) {
          this.medclinicPatientsList = medclinicPatientsList;
        }
      }),

      this.store.select(s => s.medClinicState.loadingPatients).subscribe(loadingPatients => {
        this.loadingPatients = loadingPatients;
      }),
    );
    this.store.dispatch(loadMedClinicPatients());
  }

  accessClientPortal() {
    //Link to client portal
  }

}

