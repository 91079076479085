import { downloadImprovementPlanDoc } from './../../../../core/store/improvementPlan/improvementPlan.actions';
import { refreshUpdateCoachingLog, markCoachingLogAsChecked, markCoachingLogAsCheckedSuccess, updateCoachingLogSuccess, closeUpdateCoachingLog } from './../../../../core/store/coachingLog/coahingLog.actions';
import { CoachingLogWithUsersModel } from 'src/app/models/coachingLogWithUsersModel';
import { PagingResultsModel } from './../../../../models/pagingResultsModel';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { Actions, ofType } from '@ngrx/effects';
import { AlertService } from 'src/app/shared/services/alert.service';
import { getMyGivenCoachingLog } from 'src/app/core/store/coachingLog/coahingLog.actions';
import { getUsersList } from 'src/app/core/store/users/users.actions';
import { SelectItem } from 'primeng/api';
import { deepClone, downloadFile, getImprovementModelStatus } from 'src/app/helpers/utils';
import { tap } from 'rxjs/operators';
import { ImprovementPlanModel } from 'src/app/models/improvementPlanModel';
import { loadProfile } from 'src/app/core/store/profile/profile.actions';

@Component({
  selector: 'app-list-given-coachinglog',
  templateUrl: './list-given-coachinglog.component.html',
  styleUrls: ['./list-given-coachinglog.component.scss']
})
export class ListGivenCoachingLogsComponent extends BaseComponent implements OnInit {

  coachingLogsList: PagingResultsModel<CoachingLogWithUsersModel>;
  canSeeCoach: boolean;
  canAddCoach: boolean;
  loadingCoachingLogsList;
  filter: any;
  usersList: SelectItem[] = [{ label: 'loading...', value: undefined }];
  seenOptions: SelectItem[] = [{ label: 'All', value: null },
  { label: 'Acknowledged', value: true }, { label: 'Not Acknowledged', value: false }];
  checkedOptions: SelectItem[] = [{ label: 'All', value: null }, { label: 'Done', value: true }, { label: 'Not Done', value: false }];
  levelOptions: SelectItem[] = [{ label: 'All', value: null }, { label: 'Re-Focus', value: 1 }, { label: 'Corrective + Plan', value: 2 },
  { label: 'Improvement Plan', value: 4 }];
  selectedUserID: number;
  selectedSeenFilter?: boolean;
  selectedCheckedFilter?: boolean;
  selectedLevelFilter?: number;
  currentUserId?: string;
  displayCoachingLogFollowUp: boolean;

  constructor(private actions$: Actions,
    private alertService: AlertService,
    private store: Store<State>) {
    super();
  }

  ngOnInit() {
    this.displayCoachingLogFollowUp = false;
    this.subs.push(
      this.store.select(s => s.profileState.profile).subscribe(p => {
        if (p) {
          this.currentUserId = p.userId;
        }
      }),
      this.store.select(s => s.accountState.user.permissionIDs).subscribe(permissionIDs => {
        this.canAddCoach = permissionIDs && !!permissionIDs.find(p => p === 6004 || p === 6005 || p === 6006);
        this.canSeeCoach = permissionIDs && !!permissionIDs.find(p => p === 6003);
      }),
      this.store.select(s => s.coachingLogState.givenCoachingLogsList).subscribe(givenCoachingLogsList => {
        this.coachingLogsList = givenCoachingLogsList;
      }),

      this.store.select(s => s.coachingLogState.loadingGivenCoachingLogsList).subscribe(loadingGivenCoachingLogsList => {
        this.loadingCoachingLogsList = loadingGivenCoachingLogsList;
      }),
      this.store.select(s => s.coachingLogState.updateCoachingLog).subscribe(coachingLog => {
        if (!coachingLog) {
          this.displayCoachingLogFollowUp = false;
        }
        else
          this.displayCoachingLogFollowUp = true;
      }),

      this.store.select(s => s.usersState.usersList).subscribe(usersList => {
        this.usersList = usersList ? usersList.map(u => ({ label: u.firstName + ' ' + u.lastName, value: u.id }))
          : [{ label: 'loading...', value: undefined }];
        this.usersList.unshift({ label: 'All', value: null });
      }),
      this.actions$.pipe(
        ofType(markCoachingLogAsCheckedSuccess),
        tap(action => {
          this.loadPage(this.filter);
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(updateCoachingLogSuccess),
        tap(action => {
          this.loadPage(this.filter);
        })
      ).subscribe(),
    );
    this.store.dispatch(getUsersList());
    this.store.dispatch(loadProfile());
  }

  changedFilter() {
    this.loadPage(this.filter);
  }


  loadPage(event) {
    this.filter = event;
    this.filter.filters.userID = { Name: 'userID', Value: { value: this.selectedUserID } };
    this.filter.filters.seen = { Name: 'seen', Value: { value: this.selectedSeenFilter } };
    this.filter.filters.checked = { Name: 'checked', Value: { value: this.selectedCheckedFilter } };
    this.filter.filters.level = { Name: 'level', Value: { value: this.selectedLevelFilter } };

    this.store.dispatch(getMyGivenCoachingLog({ filter: deepClone(this.filter) }));
  }

  hasSeenDate(coachingLogWithUsersModel) {
    return !(coachingLogWithUsersModel.seenDate === new Date(null));
  }

  hasCheckedDate(coachingLogWithUsersModel) {
    return !(coachingLogWithUsersModel.checkedDate === new Date(null));
  }
  hideCoachingLogFollowUp() {
    this.store.dispatch(closeUpdateCoachingLog());
  }

  getLevel(level: number) {
    if (level === 1) {
      return 'Re-Focus';
    } else if (level === 2) {
      return 'Coaching';
    } else if (level === 3) {
      return 'Coaching + Plan';
    } else if (level === 4) {
      return 'Corrective + Plan';
    }

  }

  markAsChecked(coachingLogWithUsersModel: CoachingLogWithUsersModel) {
    if (this.canAddCoach) {
      this.store.dispatch(markCoachingLogAsChecked({ coachingLogID: coachingLogWithUsersModel.id }));
    }
  }

  getImprovementModelStatus(improvementPlan: ImprovementPlanModel) {
    return getImprovementModelStatus(improvementPlan);
  }

  viewCoachingLog(coachingLog: CoachingLogWithUsersModel) {
    
    if (coachingLog) {
      this.store.dispatch(refreshUpdateCoachingLog({ updateCoachingLog: coachingLog }));
    }
  }

  downloadImprovementPlan(improvementPlan: ImprovementPlanModel) {
    const subDocBuffer = this.store.select(s => s.improvementPlanState.docArrayBuffer).subscribe(docArrayBuffer => {
      if (docArrayBuffer) {
        downloadFile(new Blob([docArrayBuffer], { type: 'application/pdf' }), 'ImprovementPlan' + improvementPlan.id + '.pdf');
        subDocBuffer.unsubscribe();
      }
    });

    this.store.dispatch(downloadImprovementPlanDoc({ improvementPlanID: improvementPlan.id }));
  }
}

