import { GeneralSettingsModel } from 'src/app/models/generalSettingsModel';
import { createReducer, on, Action, createSelector } from '@ngrx/store';
import { UserModel } from 'src/app/models/userModel';
import { authenticateSuccess, setReturnUrl, authenticateUser, authenticateCode, authenticateToken, authenticateFailure, getGeneralSettingsSuccess, getFeatureSwitchesSuccess, setSessionConfirm } from './account.actions';
import { State, getAsyncValue } from '..';
declare let ga: any;

export interface AccountState {
  user: UserModel;
  returnUrl: string;
  loading: boolean;
  checkingCode: boolean;
  generalSettings: GeneralSettingsModel;
  featureSwitchesIds: number[];
  sessionConfirmVisible: boolean;
}

const currentUserJ = localStorage.getItem('currentUser');
let currentUser = null;
if (currentUserJ != null) {
  currentUser = JSON.parse(currentUserJ);
  ga('set', 'userId', currentUser.ID + '');
}

const featureSwitchesIdsJ = localStorage.getItem('featureSwitchesIds');
let currentFeatureSwitchesIds = null;
if (featureSwitchesIdsJ != null) {
  currentFeatureSwitchesIds = JSON.parse(featureSwitchesIdsJ);
}
const generalSettingsJ = localStorage.getItem('generalSettings');
let currentGeneralSettings = null;
if (generalSettingsJ != null) {
  currentGeneralSettings = JSON.parse(generalSettingsJ);
}

const initialState: AccountState = {
  user: currentUser,
  returnUrl: '',
  loading: false,
  checkingCode: false,
  generalSettings: currentGeneralSettings,
  featureSwitchesIds: currentFeatureSwitchesIds,
  sessionConfirmVisible: false,
};

export const accountReducer = createReducer(initialState,
  on(authenticateUser, (state, action) => {
    return {
      ...state,
      loading: true
    };
  }),
  on(authenticateToken, (state, action) => {
    return {
      ...state,
      loading: true,
      checkingCode: true
    };
  }),
  on(authenticateCode, (state, action) => {
    return {
      ...state,
      loading: true,
      checkingCode: true
    };
  }),
  on(authenticateSuccess, (state, action) => {
    ga('set', 'userId', action.user.id + '');
    localStorage.setItem('currentUser', JSON.stringify(action.user));
    return {
      ...state,
      user: action.user,
      loading: false,
      checkingCode: false
    };
  }),
  on(authenticateFailure, (state, action) => {
    return {
      ...state,
      user: null,
      loading: false,
      checkingCode: false
    };
  }),
  on(setReturnUrl, (state, action) => {
    return {
      ...state,
      returnUrl: action.returnUrl
    };
  }),
  on(getGeneralSettingsSuccess, (state, action) => {
    localStorage.setItem('generalSettings', JSON.stringify(action.generalSettings));
    return {
      ...state,
      generalSettings: action.generalSettings
    };
  }),
  on(getFeatureSwitchesSuccess, (state, action) => {
    localStorage.setItem('featureSwitchesIds', JSON.stringify(action.featureSwitches));
    return {
      ...state,
      featureSwitchesIds: action.featureSwitches
    };
  }),
  on(setSessionConfirm, (state, action) => {
    return {
      ...state,
      sessionConfirmVisible: action.isVisible
    };
  }),
);


export function accountReducerFunc(state: AccountState | undefined, action: Action) {
  return accountReducer(state, action);
}

