import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { Actions, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { SurveyModel } from '../../../../models/surveyModel';
import { loadMyUnansweredSurvey } from '../../../../core/store/survey/survey.actions';

@Component({
  selector: 'app-survey-list',
  templateUrl: './survey-list.component.html',
  styleUrls: ['./survey-list.component.scss']
})
export class SurveyListComponent extends BaseComponent implements OnInit {

  mySurveys: SurveyModel[];
  loadingMySurvey = false;

  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store<State>) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.surveyState.mySurveys).subscribe(mySurveys => {
        this.mySurveys = mySurveys;
      }),
      this.store.select(s => s.surveyState.loadingMySurvey).subscribe(loadingMySurvey => {
        this.loadingMySurvey = loadingMySurvey;
      }),
    );

    this.store.dispatch(loadMyUnansweredSurvey());

  }

  viewSurvey(survey: SurveyModel) {
    this.router.navigate(['/survey/answer/' + survey.id]);
  }

}

