<div class="individual-post" (click)="stopPropagation($event)" (mouseleave)="leavePost()">
    <div *ngIf="loading" class="w-100 text-center loading-icon">
        <i class="material-icons rotate text-muted ml-2">loop</i>
    </div>
    <div class="d-flex flex-column post-head-container">
        <div class="mr-2 post-header d-flex justify-content-start align-items-center" (click)="stopPropagation($event)">
            <img [src]="post.userImage ? post.userImage : 'assets/img/Portrait_Placeholder.png'" class="img-rounded post-img-sm">
            <div class="d-flex flex-column ml-2">
                <div class="post-header-name">
                    {{post.userFirstName}}&nbsp;{{post.userLastName}} <br>
                    <span class="post-header-date">{{ (post.updatedDate == null ? post.createdDate : post.updatedDate) | date:'MM/dd/yyyy' }}&nbsp;@&nbsp;{{ (post.updatedDate == null ? post.createdDate : post.updatedDate) | date:'hh:mm&nbsp;a' }}&nbsp;{{post.updatedDate == null ? '' : '(edited)' }}</span>
                </div>
            </div>
        </div>
        <div class="m-0 mt-1 p-0 pinned-icon d-flex justify-content-between align-items-center">
            <i  *ngIf="isPinned || isUserPinned" class="fas fa-map-pin mr-3" ></i>
            <div (click)="togglePostMenu($event)" class="d-flex flex-column mt-2">
                <i class="fas fa-angle-down pointer post-menu"></i>
                <div *ngIf="showPostMenu" class="post-menu-options d-flex flex-column justify-content-center align-items-center" (mouseleave)="closePostMenu()">
                    <div *ngIf="post.userID == userID || manageAll" (click)="edit()">Edit&nbsp;Post</div>
                    <div *ngIf="managePinnedPosts || manageAll" (click)="managePinPostAllUsers()">{{isPinned  ? 'Unpin' : 'Pin'}}&nbsp;Post&nbsp;(For&nbsp;All&nbsp;Users)</div>
                    <div (click)="userPinPost()">{{isUserPinned  ? 'Unpin' : 'Pin'}}&nbsp;Post&nbsp;{{managePinnedPosts || manageAll ? "(Personal&nbsp;Feed)" : "" }}</div>
                    <div *ngIf="post.userID == userID || manageAll" (click)="archivePost(post)">Archive&nbsp;Post</div>
                </div>
            </div>
        </div>
        <div class="w-100 d-flex mb-2 justify-content-between">
            <div *ngIf="showEdit != true" class="w-100 mt-4 d-flex align-items-center">
                {{post.message}}
            </div>
            <div *ngIf="showEdit  && (post.userID == userID || manageAll)" class="w-100 mt-4 d-flex align-items-center">
                <div class="w-100 d-flex flex-column justify-content-around mb-0 mt-1 pb-0">
                    <div class="d-flex flex-column mr-2 w-100">
                        <textarea class="edit-input" #postEditInput (input)="onPostEditInput($event)" (focus)="onPostEditInput($event)" rows="1" [(ngModel)]='editPostMessage' (ngModelChange)="countEditCharacters()" ></textarea>
                        <p class="w-100 text-right mb-0 pb-0" style="font-size:9px;">{{postEditCharCount}} / 8000</p>
                    </div>
                    <div class="w-100 row ml-2">
                        <div class="custom-control custom-switch mt-1 col ml-2">
                            <input *ngIf="managePinnedPosts" class="custom-control-input" type="checkbox" id="pinned" [(ngModel)]="isPinned"/>
                            <label *ngIf="managePinnedPosts" class="custom-control-label" for="pinned">Pin</label>
                        </div>
                        <div class="d-flex justify-content-between mt-1 col">
                            <button class="edit-cancel" style="white-space: nowrap;  hyphens: none;" (click)="openImages(mymodal)">Manage&nbsp;<i class="fas fa-paperclip p-0 m-0"></i></button>
                            <div class="d-flex justify-content-center align-items-center">
                                <p *ngIf="newImageUrls != null && newImageUrls.length > 0" class="mt-1 ml-2 d-flex justify-content-center font-italic" style="font-size:9px;">{{ newImageUrls.length }} Files Uploaded</p>
                            </div>
                        </div>
                        <div class="col d-flex mt-1 justify-content-end align-items-center">
                            <button class="edit-save mr-1" (click)="saveEdit()">Save</button>
                            <button class="edit-cancel btn-light mr-1" (click)="cancelEdit()">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="currentLinks != null && currentLinks.length > 0" class="w-100 m-0 p-0">
            <form>
                <div class="d-flex flex-column mt-2 mb-2 w-90 ml-3 mr-3 file-container" *ngFor="let file of currentLinks; let i = index;">
                    <a [href]="file.url" target="_blank" class="w-90 ml-3 mr-3 mt-1 d-flex justify-content-center align-items-center pointer">
                        <i [ngClass]="file.icon" [style.color]="file.iconColor"></i>
                        <p *ngIf="screenWidth < 993 && screenWidth >= 430 && file.url != null" class="m-0 ml-3 p-0">{{ file.url | slice:0:22 }}{{file.url.length > 22 ? '...': ''}}</p>
                        <p *ngIf="screenWidth < 430 && file.url != null" class="m-0 ml-3 p-0">{{ file.url | slice:0:16 }}{{file.url.length > 16 ? '...': ''}}</p>
                        <p *ngIf="screenWidth >= 993 && file.url != null" class="m-0 ml-3 p-0">{{ file.url | slice:0:35 }}{{file.url.length > 35 ? '...': ''}}</p>
                    </a>
                </div>
            </form>
        </div>
        <div *ngIf="newFileUrls != null && newFileUrls.length > 0" class="w-100 m-0 p-0">
            <div class="w-100 mt-2 mb-2" *ngFor="let file of newFileUrls">
                <div class="w-100 d-flex justify-content-center align-items-center file-container pointer" (click)="downloadFile(file)">
                    <i class="fas fa-file-alt mt-1"></i>
                    <p class="m-0 ml-3 p-0">{{ file.fileName }}</p>
                </div>
            </div>
        </div>
        <div *ngIf="fileUrls != null && fileUrls.length > 0" class="w-100 m-0 p-0">
            <div class="w-100 mt-2 mb-2" *ngFor="let file of fileUrls">
                <div class="w-100 d-flex justify-content-center align-items-center file-container pointer" (click)="downloadFile(file)">
                    <i class="fas fa-file-alt mt-1"></i>
                    <p class="m-0 ml-3 p-0">{{ file.fileName }}</p>
                </div>
            </div>
        </div>
        <div *ngIf="mainPostPhoto != null" class="w-100 mt-2 mb-2 all-images-container" [ngClass]="{'all-images-container' : additionalPhotos != null, 'main-photo-container': additionalPhotos == null }">
            <div class="col d-flex justify-content-center align-items-center" [ngClass]="{'post-image-wrapper-with-additional' : additionalPhotos != null, 'post-image-wrapper': additionalPhotos == null }" #mainPhoto>
                <img [src]="mainPostPhoto.url" alt="img.jpg" class="pointer m-0 p-0" (click)="openImages(mymodal, mainPostPhoto)" />     
            </div>
            <div *ngIf="moreThanOnePhoto" class="w-100 m-0 p-0 additional-photo-element">
                <div class="ml-1 mr-0 d-flex additional-photos-container">
                    <div class="additional-image-container">
                        <div *ngFor="let file of additionalPhotos"  class="additional-image-wrapper d-flex justify-content-center align-items-center mt-1">
                            <img [src]="file.url" alt="img.jpg" class="pointer" (click)="openImages(mymodal, file)" />     
                        </div>
                    </div>
                    <div *ngIf="moreThanOnePhoto" class="additional-button-wrapper d-flex justify-content-center align-items-center mt-1 pointer" (click)="openImages(mymodal)">
                        <p class="text-primary">View&nbsp;{{fileCount}}&nbsp;Files</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-between post-reactions-row border-top border-bottom">
        <div class="ml-3 d-flex justify-content-center align-content-center position-relative">
            <div class="reaction-wrapper d-flex justify-content-center align-items-center mr-1 mt-1 mb-1 pointer" [ngClass]="userLovedPost ? 'reaction-background-clicked' : 'reaction-background'" (click)="saveReaction(2)">
                <i *ngIf="!reactionLoading" class="fas fa-heart text-danger reaction-font"></i>
                <i *ngIf="reactionLoading" class="material-icons rotate text-muted reaction-font">loop</i>
                <div *ngIf="lovedCount != null && lovedCount > 0" class="ml-1 font-italic">{{lovedCount}}</div>
            </div>
            <div class="reaction-wrapper d-flex justify-content-center align-items-center mr-1 mt-1 mb-1 ml-1 pointer" [ngClass]="userLikedPost ? 'reaction-background-clicked' : 'reaction-background'" (click)="saveReaction(1)">
                <i *ngIf="!reactionLoading" class="fas fa-thumbs-up text-primary reaction-font"></i>
                <i *ngIf="reactionLoading" class="material-icons rotate text-muted ml-2 reaction-font">loop</i>
                <div *ngIf="likedCount != null && likedCount > 0" class="ml-1 font-italic">{{likedCount}}</div>
            </div>
            <div class="mr-1 mt-1 mb-1 ml-2 pointer d-flex align-items-center justify-content-center" (click)="openAllReactions()">
                <i *ngIf="post.reactions != null && post.reactions.length > 0" class="fas fa-ellipsis-h text-secondary reaction-font"></i>
            </div>
        </div>
        <div class="mr-3 d-flex justify-content-center align-items-center">
            <p *ngIf="allComments != null && allComments.length > 0" class="text-primary pointer text-center reaction-font m-0 p-0" (click)="openAllComments()">
                 View All Comments
            </p>
        </div>
    </div>
    <div class="w-100 d-flex flex-column comment-container-wrapper">
        <div *ngIf="showAllReactions && post.reactions != null && post.reactions.length > 0" [@enterAnimation] class="all-reactions-container" (mouseleave)="closeAllReactions()">
            <div class="w-100 m-0 row d-flex justify-content-center align-items-start">
               <div class="col-6 d-flex flex-column justify-content-center align-items-start mt-2 border-right">
                   <div class="d-flex w-100 justify-content-center" [ngClass]="{'font-small': screenWidth <= 650, 'font-large': screenWidth > 650}">
                       <p>Loved {{screenWidth <= 650 ? '' : 'Reactions'}}: {{loveReactions != null && loveReactions.length > 0 ? loveReactions.length : 0}}</p>
                   </div>
                   <div *ngIf="loveReactions != null && loveReactions.length > 0" class="d-flex flex-column justify-content-center w-100">
                        <div *ngFor="let reaction of loveReactions; let i = index" [ngStyle]="{'background-color': i%2 == 0 ? 'rgb(189, 228, 245)' : 'rgb(234, 249, 255)'}" class="d-flex flex-column all-reactions-reaction">
                            <div class="d-flex position-relative" [ngClass]="{'flex-column': screenWidth <= 520}">
                                <div class="p-0 m-0 ml-1 mt-1 d-flex justify-content-center align-items-center">
                                    <img [src]="!!reaction.userImage ? reaction.userImage : 'assets/img/Portrait_Placeholder.png'" class="img-rounded reaction-img-sm">
                                </div>
                                <div *ngIf="screenWidth > 650" class="p-0 m-0 mt-1 ml-2">
                                    <p class="mb-0 mt-1 text-sm-italic">{{ reaction.createdDate | date:'MM/dd/yyyy' }} <br> {{ reaction.createdDate | date:'hh:mm&nbsp;a' }}</p>
                                </div>
                                <div class="w-100 p-0 m-0 ml-1 mt-1 d-flex justify-content-center align-items-center">
                                    {{reaction.userFirstName}} {{reaction.userLastName}}
                                </div>
                                <i class="fas fa-heart text-danger modal-reaction-icon"></i>
                            </div>
                        </div>
                   </div>
               </div>
               <div class="col-6 d-flex flex-column mt-2">
                   <div class="d-flex justify-content-center w-100" [ngClass]="{'font-small': screenWidth <= 650, 'font-large': screenWidth > 650}">
                       <p>Liked {{screenWidth <= 650 ? '' : 'Reactions'}}: {{likeReactions != null && likeReactions.length > 0 ? likeReactions.length : 0}}</p>
                   </div>
                   <div *ngIf="likeReactions != null && likeReactions.length > 0" class="d-flex flex-column ml-2 justify-content-center w-100">
                        <div *ngFor="let reaction of likeReactions; let i = index" [ngStyle]="{'background-color': i%2 == 0 ? 'rgb(234, 249, 255)' : 'rgb(189, 228, 245)'}" class="d-flex flex-column all-reactions-reaction">
                            <div class="d-flex position-relative" [ngClass]="{'flex-column': screenWidth <= 520}">
                                <div class="p-0 m-0 mt-1 ml-1 d-flex justify-content-center align-items-center">
                                    <img [src]="reaction.userImage ? reaction.userImage : 'assets/img/Portrait_Placeholder.png'" class="img-rounded reaction-img-sm">
                                </div>
                                <div *ngIf="screenWidth > 650" class="p-0 m-0 mt-1 ml-2">
                                    <p class="mb-0 mt-1 text-sm-italic">{{ reaction.createdDate | date:'MM/dd/yyyy' }} <br> {{ reaction.createdDate | date:'hh:mm&nbsp;a' }}</p>
                                </div>
                                <div class="w-100 p-0 m-0 mt-1 ml-1 d-flex justify-content-center align-items-center">
                                    {{reaction.userFirstName}} {{reaction.userLastName}}
                                </div>
                                <i class="fas fa-thumbs-up text-primary modal-reaction-icon"></i>
                            </div>
                        </div>
                   </div>
               </div>  
            </div>               
       </div>
        <div *ngIf="showAllComments &&  allComments != null && allComments.length > 0" [@enterAnimation] class="all-comments-container" (mouseleave)="closeAllComments()">
            <div (click)="closeAllComments()" class="pointer close-all-comments d-flex justify-content-center align-items-center" title="close">x</div>
            <div class="ml-1 mr-2">
                <app-critical-news-post-comments
                    [comments]="allComments"
                    [postId]="post.id"
                    [managePinnedComments]="managePinnedComments"
                    [manageAll]="manageAll"
                    [canComment]="canComment"
                ></app-critical-news-post-comments>
            </div>
        </div>
        <div class="w-100 p-0 m-0">
            <app-critical-news-post-comments
                [comments]="commentsToDisplay"
                [postId]="post.id"
                [managePinnedComments]="managePinnedComments"
                [manageAll]="manageAll"
                [canComment]="canComment"
            ></app-critical-news-post-comments>
        </div>
    </div>
</div>
<ng-template #mymodal let-modal>
    <div class="m-0 p-0 w-100 h-100" (click)="stopPropagation($event)">
        <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{ showEdit  ? 'Edit' : '' }} Post's Attachments</h4>
        <button type="button" class="close btn btn-outline-dark" aria-label="Close"
            (click)="closeImageModal($event)">
            <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div class="modal-body d-flex flex-column justify-content-center align-items-center">
            <div *ngIf="showEdit" class="d-flex w-100 justify-content-start align-items-center">
                <label class="mr-2 mt-1">Add Images: </label>
                <input type="file" multiple #PostFile accept="image/*,.pdf,.doc,.docx" (change)="uploadFile($event)" />
            </div>
            <div *ngIf="showEdit" class=" w-100 row mr-2 mt-2 mb-3">
                <label class="col-2 mr-2">Add Link:</label>
                <input class="form-control col-6 mr-2" type="text" [(ngModel)]="newLinkInput" />
                <button class="col-2 btn btn-sm btn-secondary" (click)="addNewLink()">Add Link</button>
            </div>
            <div *ngIf="showOtherUrls && currentLinks != null && currentLinks.length > 0" class="w-100 m-0 p-0">
                <form>
                    <div class="d-flex flex-column mt-2 mb-2 w-90 ml-3 mr-3 file-container" *ngFor="let file of currentLinks; let i = index;">
                        <div *ngIf="showEdit" class="remove-file d-flex justify-content-center align-items-center pointer" (click)="removeCurrentLink($event, file)">
                            <i class="fas fa-trash pointer"></i>
                        </div>
                        <a [href]="file.url" target="_blank" class="w-90 ml-3 mr-3 mt-1 d-flex justify-content-center align-items-center pointer">
                            <i [ngClass]="file.icon" [style.color]="file.iconColor"></i>
                            <p *ngIf="screenWidth < 993 && screenWidth >= 430 && file.url != null" class="m-0 ml-3 p-0">{{ file.url | slice:0:22 }}{{file.url.length > 22 ? '...': ''}}</p>
                            <p *ngIf="screenWidth < 430 && file.url != null" class="m-0 ml-3 p-0">{{ file.url | slice:0:16 }}{{file.url.length > 16 ? '...': ''}}</p>
                            <p *ngIf="screenWidth >= 993 && file.url != null" class="m-0 ml-3 p-0">{{ file.url | slice:0:35 }}{{file.url.length > 35 ? '...': ''}}</p>
                        </a>
                    </div>
                </form>
            </div>
            <div *ngIf="showOtherUrls && newLinks != null && newLinks.length > 0" class="w-100 m-0 p-0">
                <form>
                    <div class="d-flex flex-column mt-2 mb-2 w-90 ml-3 mr-3 file-container" *ngFor="let file of newLinks; let i = index;">
                        <div class="remove-file d-flex justify-content-center align-items-center pointer" (click)="removeAddedLink($event, file)">
                            <i class="fas fa-trash pointer"></i>
                        </div>
                        <a [href]="file.url" target="_blank" class="w-90 ml-3 mr-3 mt-1 d-flex justify-content-center align-items-center pointer">
                            <i [ngClass]="file.icon" [style.color]="file.iconColor"></i>
                            <p *ngIf="screenWidth < 993 && screenWidth >= 430 && file.url != null" class="m-0 ml-3 p-0">{{ file.url | slice:0:22 }}{{file.url.length > 22 ? '...': ''}}</p>
                            <p *ngIf="screenWidth < 430 && file.url != null" class="m-0 ml-3 p-0">{{ file.url | slice:0:16 }}{{file.url.length > 16 ? '...': ''}}</p>
                            <p *ngIf="screenWidth >= 993 && file.url != null" class="m-0 ml-3 p-0">{{ file.url | slice:0:35 }}{{file.url.length > 35 ? '...': ''}}</p>
                        </a>
                    </div>
                </form>
            </div>
            <div *ngIf="showOtherUrls && newFileUrls != null && newFileUrls.length > 0" class="w-100 m-0 p-0">
                <form>
                    <div class="d-flex flex-column w-100 mt-2 mb-2" *ngFor="let file of newFileUrls; let i = index;">
                        <div class="w-90 ml-3 mr-3 d-flex justify-content-center align-items-center new-file-container pointer"  (click)="downloadFile(file)">
                            <div class="remove-file d-flex justify-content-center align-items-center pointer" (click)="removeAddedImage(file)">
                                <i class="fas fa-trash pointer"></i>
                            </div>
                            <div class="new-file-alert d-flex justify-content-center align-items-center pointer">
                                <p class="p-0 m-0">File Just Added</p>
                            </div>
                            <i class="fas fa-file-alt mt-3"></i>
                            <p class="m-0 ml-3 mt-3 p-0">{{ file.fileName }}</p>
                        </div>
                        <div class="w-100 d-flex ml-1 mt-2 justify-content-center align-items-center row">
                            <div class="col-4 w-100 d-flex justify-content-center" >
                                <label for="editFileName" class="form-control-label">Edit&nbsp;File's&nbsp;Name:</label>
                            </div>
                            <div class=" ml-2 col-6 w-100 d-flex justify-content-center" >
                                <input type="text" id="editFileName" class="form-control w-100" [(ngModel)]="file.fileName" (focusout)="updateNewFileName(file)" [ngModelOptions]="{standalone: true}"/>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div *ngIf="showOtherUrls && fileUrls != null && fileUrls.length > 0" class="w-100 m-0 p-0">
                <form>
                    <div class="d-flex flex-column w-100 mt-2 mb-2" *ngFor="let file of fileUrls; let i = index;">
                        <div class="w-90 ml-3 mr-3 d-flex justify-content-center align-items-center file-container pointer"  (click)="downloadFile(file)">
                            <div class="remove-file d-flex justify-content-center align-items-center pointer" (click)="removeImage(file)">
                                <i class="fas fa-trash pointer"></i>
                            </div>
                            <i class="fas fa-file-alt mt-1"></i>
                            <p class="m-0 ml-3 mt-1 p-0">{{ file.fileName }}</p>
                        </div>
                        <div *ngIf="showEdit" class="w-100 d-flex ml-1 mt-2 justify-content-center align-items-center row">
                            <div class="col-4 w-100 d-flex justify-content-center" >
                                <label for="editFileName" class="form-control-label">Edit&nbsp;File's&nbsp;Name:</label>
                            </div>
                            <div class=" ml-2 col-6 w-100 d-flex justify-content-center" >
                                <input type="text" id="editFileName" class="form-control w-100" [(ngModel)]="file.fileName" [ngModelOptions]="{standalone: true}"/>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div *ngIf="newImageUrls != null && newImageUrls.length > 0" class="m-0 p-0 w-100">
                <div *ngFor="let file of newImageUrls" class="d-flex modal-images justify-content-center align-items-center mb-3">
                    <div class="remove-image d-flex justify-content-center align-items-center pointer" (click)="removeAddedImage(file)">
                        <i class="fas fa-trash pointer"></i>
                    </div>
                    <div class="new-file-alert d-flex justify-content-center mt-1 align-items-center pointer">
                        <p class="p-0 m-0">Image Just Added</p>
                    </div>
                    <img [src]="file.url" [alt]="file" class="rounded"/>
                </div>
            </div>
            <div *ngFor="let file of imagesToDisplay" class="d-flex modal-images justify-content-center align-items-center mb-3">
                <div *ngIf="showEdit" class="remove-image d-flex justify-content-center align-items-center pointer" (click)="removeImage(file)">
                    <i class="fas fa-trash"></i>
                </div>
                <img [src]="file.url" [alt]="file" class="rounded"/>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark" (click)="closeImageModal($event)">Close</button>
        </div>
    </div>
  </ng-template>
