import { loadTimeCardsSuccess } from './../timecard/timecard.actions';
import { loadTimeCards } from 'src/app/core/store/timecard/timecard.actions';
import { AlertService } from './../../../shared/services/alert.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, tap, catchError, filter } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { errorHappened } from '../profile/profile.actions';
import { Store, Action } from '@ngrx/store';
import { State } from '..';
import { loadPayPeriods, loadPayPeriodsSuccess, changePayPeriod } from './payperiod.actions';
import { PayPeriodService } from '../../services/payPeriod.service';
import { loadApprovedTime, loadInvalidTime, loadAdjustmentTime, loadPendingTime, loadApprovedTotals } from '../payroll/payroll.actions';

@Injectable()
export class PayPeriodEffects {
  constructor(private actions$: Actions,
    private payPeriodService: PayPeriodService,
    private store: Store<State>) {

  }

  loadPayPeriods = createEffect(() => this.actions$.pipe(
    ofType(loadPayPeriods),
    switchMap(action => {
      return this.payPeriodService.loadPayPeriods(false).pipe(
        map(periods => loadPayPeriodsSuccess({ periods })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    }
    )));

  loadPayPeriodsSuccess = createEffect(() => this.actions$.pipe(
    ofType(loadPayPeriodsSuccess),
    map(action => {
      let selectedPeriodID;
      this.store.select(s => s.payPeriodState.selectedPeriodID).subscribe(storeSelectedPeriodID => {
        selectedPeriodID = storeSelectedPeriodID;
      });
     // if (action.periods && action.periods.length > 1
     //   && !action.periods.find(p => p.payPeriodID.toString() === selectedPeriodID.toString())) {
        return changePayPeriod({ periodID: action.periods[0].payPeriodID });
     // }
    }),
    filter(action => !!action)
  ));

  changePayPeriod = createEffect(() => this.actions$.pipe(
    ofType(changePayPeriod),
    tap(action => {
      this.store.dispatch(loadTimeCards({ periodID: action.periodID }));
      this.store.dispatch(loadApprovedTime({ periodID: action.periodID }));
      this.store.dispatch(loadInvalidTime({ periodID: action.periodID }));
      this.store.dispatch(loadAdjustmentTime({ periodID: action.periodID }));
      this.store.dispatch(loadPendingTime({ periodID: action.periodID }));
      this.store.dispatch(loadApprovedTotals({ periodID: action.periodID }));
    })
  ), { dispatch: false });
}
