import { forceOnBoardingSuccess } from './../../../../core/store/onboarding/onboarding.actions';
import { PagingResultsModel } from './../../../../models/pagingResultsModel';
import {
  getOfferLettersList, getOfferLetterPdf,
  deleteOfferLetter, deleteOfferLetterSuccess, markOfferLetterSeen
} from './../../../../core/store/offerletter/offerletter.actions';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Router } from '@angular/router';
import { OnBoardingUserListModel } from 'src/app/models/onBoardingModels';
import { loadEmployeeFileUsersList, forceOnBoarding } from 'src/app/core/store/onboarding/onboarding.actions';
import { deepClone, getDateNumberComparison } from 'src/app/helpers/utils';
import { rehireEmployee, rehireEmployeeSuccess, saveTerminationReasonSuccess, setRehireEmployee, showTerminateEmployee, willNotRehireEmployee, willNotRehireEmployeeSuccess } from '../../../../core/store/employee/employee.action';
import { TerminatedEmployeeModel } from '../../../../models/userProfileModel';

@Component({
  selector: 'app-employee-file-list',
  templateUrl: './employee-file-list.component.html',
  styleUrls: ['./employee-file-list.component.scss']
})
export class EmployeeFileListComponent extends BaseComponent implements OnInit {

  employeeFileUsers: PagingResultsModel<OnBoardingUserListModel>;
  canViewEmployeeFile: boolean;
  canViewAfterOnboarding: boolean;
  canForceOnboarding: boolean;
  loadingEmployeeFileUsers = false;
  filter: any;

  yesNoAllOptions = [
    { label: 'All', value: null },
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  employeeFirstNameFilter: string;
  employeeLastNameFilter: string;
  consentFilter?: boolean = null;
  allUploadedFilter?: boolean = null;
  uploadApprovedFilter?: boolean = null;
  i9SignedFilter?: boolean = null;
  i9ApprovedFilter?: boolean = null;
  employeeInfoFilter?: boolean = null;
  allDocsSignedFilter?: boolean = null;
  allDocsApprovedFilter?: boolean = null;
  finishedFilter?: boolean = null;
  terminatedEmployeesFilter?: boolean = false;
  inactiveUsersFilter?: boolean = false;

  constructor(
    private actions$: Actions,
    private alertService: AlertService,
    private store: Store<State>,
    private router: Router) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      // Check if user is an HR manager
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.canViewEmployeeFile = permissionIDs && !!permissionIDs.find(p => p === 3063 || p === 3064 || p === 3068);
        this.canViewAfterOnboarding = permissionIDs && !!permissionIDs.find(p => p === 3063 || p === 3064);
        this.canForceOnboarding = permissionIDs && !!permissionIDs.find(p => p === 3047);
      }),
      this.store.select(s => s.onboardingState.employeeFileUsers).subscribe(employeeFileUsers => {
        if (employeeFileUsers) {
          this.employeeFileUsers = employeeFileUsers;
        }
      }),

      this.store.select(s => s.onboardingState.loadingEmployeeFileUsers).subscribe(loadingEmployeeFileUsers => {
        this.loadingEmployeeFileUsers = loadingEmployeeFileUsers;
      }),

      this.actions$.pipe(
        ofType(forceOnBoardingSuccess),
        tap(action => {
          this.loadPage(this.filter);
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(rehireEmployeeSuccess),
        tap(action => {
          this.router.navigate(['/offerletter/create/']);
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(saveTerminationReasonSuccess),
        tap(action => {
          this.loadPage(this.filter);
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(willNotRehireEmployeeSuccess),
        tap(action => {
          this.loadPage(this.filter);
        })
      ).subscribe(),
    );
  }

  loadPage(event) {
    this.filter = event;
    this.filter.filters.employeeFirstNameFilter = { Name: 'employeeFirstNameFilter', Value: { value: this.employeeFirstNameFilter } };
    this.filter.filters.employeeLastNameFilter = { Name: 'employeeLastNameFilter', Value: { value: this.employeeLastNameFilter } };
    this.filter.filters.consentFilter = { Name: 'consentFilter', Value: { value: this.consentFilter } };
    this.filter.filters.allUploadedFilter = { Name: 'allUploadedFilter', Value: { value: this.allUploadedFilter } };
    this.filter.filters.uploadApprovedFilter = { Name: 'uploadApprovedFilter', Value: { value: this.uploadApprovedFilter } };
    this.filter.filters.i9SignedFilter = { Name: 'i9SignedFilter', Value: { value: this.i9SignedFilter } };
    this.filter.filters.i9ApprovedFilter = { Name: 'i9ApprovedFilter', Value: { value: this.i9ApprovedFilter } };
    this.filter.filters.employeeInfoFilter = { Name: 'employeeInfoFilter', Value: { value: this.employeeInfoFilter } };
    this.filter.filters.allDocsSignedFilter = { Name: 'allDocsSignedFilter', Value: { value: this.allDocsSignedFilter } };
    this.filter.filters.allDocsApprovedFilter = { Name: 'allDocsApprovedFilter', Value: { value: this.allDocsApprovedFilter } };
    this.filter.filters.finishedFilter = { Name: 'finishedFilter', Value: { value: this.finishedFilter } };
    this.filter.filters.terminatedEmployeesFilter = { Name: 'terminatedEmployeesFilter', Value: { value: this.terminatedEmployeesFilter } };
    this.filter.filters.inactiveUsersFilter = { Name: 'inactiveUsersFilter', Value: { value: this.inactiveUsersFilter } };

    this.store.dispatch(loadEmployeeFileUsersList({
      filter: deepClone(this.filter)
    }));
  }



  seeUserDetails(onBoardingUser: OnBoardingUserListModel) {
    this.router.navigate(['/employee-file/detail/' + onBoardingUser.user.id]);
  }

  changedFilters() {

  }
  doFilter(){
    this.loadPage(this.filter);
  }

  setOnBoarding(onBoardingUser: OnBoardingUserListModel) {
    this.alertService.confirm('Force Onboarding',
      'Are you sure you want to force onboarding for ' + onBoardingUser.user.firstName + ' ' + onBoardingUser.user.lastName + '?')
      .subscribe(
        answer => {
          if (answer) {
            this.store.dispatch(forceOnBoarding({ userId: onBoardingUser.user.id }));
          }
        }, error => {
        }
      );
  }

  willNotHireEmployee(onBoardingUser: OnBoardingUserListModel) {
    this.alertService.confirm('Will Not Rehire Employee',
      'Are you sure you want abort the rehire process for ' + onBoardingUser.user.firstName + ' ' + onBoardingUser.user.lastName + '?')
      .subscribe(
        answer => {
          if (answer) {
            this.store.dispatch(willNotRehireEmployee({ employee: onBoardingUser.employee }));
          }
        }, error => {
        }
      );
  }

  rehireEmployee(onBoardingUser: OnBoardingUserListModel) {
    this.alertService.confirm('Rehire Employee',
      'Are you sure you want to rehire ' + onBoardingUser.user.firstName + ' ' + onBoardingUser.user.lastName + '?')
      .subscribe(
        answer => {
          if (answer) {
            this.store.dispatch(rehireEmployee({ employee: onBoardingUser.employee }));
          }
        }, error => {
        }
      );
  }

  terminateEmployee(onBoardingUser: OnBoardingUserListModel) {
    console.log(onBoardingUser);
    this.store.dispatch(showTerminateEmployee({
      employeeUser: {
        firstName: onBoardingUser.user.firstName,
        lastName: onBoardingUser.user.lastName,
        userId: onBoardingUser.user.id
      } as TerminatedEmployeeModel
    }));
  }

  createRehireOfferLetter(onBoardingUser: OnBoardingUserListModel) {
    //if current offer letter exists navigate to that one..otherwise set rehire and create new
    if (onBoardingUser.offerletter != null &&
      onBoardingUser.offerletter.validUntilDate != null &&
      getDateNumberComparison(new Date()) <= getDateNumberComparison(onBoardingUser.offerletter.validUntilDate)) {
      this.router.navigate(['/offerletter/create/' + onBoardingUser.offerletter.id]);
      return;
    }

    this.store.dispatch(setRehireEmployee({ employeeUser: onBoardingUser.user }));
    this.router.navigate(['/offerletter/create/']);
  }

  canViewHiredEmployeeFile(onBoardingUser: OnBoardingUserListModel) {
    if (this.canViewAfterOnboarding) return true;

    if (onBoardingUser == null) return false;

    if (onBoardingUser.employee != null &&
      onBoardingUser.employee.trainingStartDate != null &&
      new Date(onBoardingUser.employee.trainingStartDate) < new Date()) return false;

    if (onBoardingUser.onboarding != null &&
      onBoardingUser.onboarding.completeHRFileDate != null) return false;

    return true;
  }

  seeReferences(onBoardingUser: OnBoardingUserListModel) {
    this.router.navigate(['/prospective-candidate/employee-references/' + onBoardingUser.prospectiveCandidateID], { queryParams: { backTo: '/employee-file/list' } });
  }
}

