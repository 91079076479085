import { UserModel } from 'src/app/models/userModel';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PayrollItemModel, PayrollApprovedTotalsModel, PayableItemModel } from 'src/app/models/payableItem';
import { PayrollClassificationModel } from 'src/app/models/payrollClassificationModel';
import { CampusModel } from 'src/app/models/campusModel';
import { PayPeriodModel } from 'src/app/models/payPeriodModel';
import { PtoRequestedModel } from '../../models/timeoffModel';


@Injectable()
export class PayrollService {

  constructor(private http: HttpClient) {
  }

  loadApprovedTime(periodID: number, userID: number = 0): Observable<PayrollItemModel[]> {
    return this.http.get<PayrollItemModel[]>(`${environment.apiServer.webApiURL}/Payroll/ApprovedTime/${periodID}/?userID=${userID}`);
  }

  loadInvalidTime(periodID: number, userID: number = 0): Observable<PayrollItemModel[]> {
    return this.http.get<PayrollItemModel[]>(`${environment.apiServer.webApiURL}/Payroll/InvalidTime/${periodID}/?userID=${userID}`);
  }

  loadAdjustmentTime(periodID: number, userID: number = 0, showAll: boolean = false): Observable<PayrollItemModel[]> {
    return this.http.get<PayrollItemModel[]>(`${environment.apiServer.webApiURL}/Payroll/AdjustmentTime/${periodID}/?userID=${userID}&showAll=${showAll}`);
  }

  loadPendingTime(periodID: number, userID: number = 0, showAll: boolean = false): Observable<PayrollItemModel[]> {
    return this.http.get<PayrollItemModel[]>(`${environment.apiServer.webApiURL}/Payroll/PendingTime/${periodID}/?userID=${userID}&showAll=${showAll}`);
  }

  loadApprovedTotals(periodID: number, userID: number = 0): Observable<PayrollApprovedTotalsModel> {
    return this.http.get<PayrollApprovedTotalsModel>
      (`${environment.apiServer.webApiURL}/Payroll/ApprovedTotals/${periodID}/?userID=${userID}`);
  }

  approvePayrollItems(payableItems: PayableItemModel[], userID: number, payPeriodID: number, approvalType: string): Observable<PayrollItemModel[]> {
    return this.http.post<PayrollItemModel[]>
      (`${environment.apiServer.webApiURL}/Payroll/ApprovePayrollItems`,
        {
          payableItems,
          userID,
          payPeriodID,
          approvalType
        });
  }

  rejectPayrollItems(payableItems: PayableItemModel[], userID: number, payPeriodID: number): Observable<PayrollItemModel[]> {
    return this.http.post<PayrollItemModel[]>
      (`${environment.apiServer.webApiURL}/Payroll/RejectPayrollItems`,
        {
          payableItems,
          userID,
          payPeriodID
        });
  }

  savePayrollItems(payableItems: PayableItemModel[], userID: number, payPeriodID: number): Observable<PayrollItemModel[]> {
    return this.http.post<PayrollItemModel[]>
      (`${environment.apiServer.webApiURL}/Payroll/SavePayrollItems`,
        {
          payableItems,
          userID,
          payPeriodID
        });
  }

  unapprovePayrollItems(payableItems: PayableItemModel[], userID: number, payPeriodID: number): Observable<PayrollItemModel[]> {
    return this.http.post<PayrollItemModel[]>
      (`${environment.apiServer.webApiURL}/Payroll/UnapprovePayrollItems`,
        {
          payableItems,
          userID,
          payPeriodID
        });
  }

  generatePayrollItems(userID: number, payPeriodID: number): Observable<any> {
    return this.http.post<any>
      (`${environment.apiServer.webApiURL}/Payroll/GeneratePayrollItems`,
        {
          userID,
          payPeriodID
        });
  }

  savePayableItem(payableItem: PayableItemModel): Observable<PayableItemModel> {
    return this.http.put<any>
      (`${environment.apiServer.webApiURL}/Payroll/SavePayableItem`,
        payableItem);
  }

  lockPayrollPeriod(userID: number, payPeriodID: number): Observable<any> {
    return this.http.post<any>
      (`${environment.apiServer.webApiURL}/Payroll/LockPeriod`,
        {
          userID,
          payPeriodID
        });
  }


  unlockPayrollPeriod(userID: number, payPeriodID: number): Observable<any> {
    return this.http.post<any>
      (`${environment.apiServer.webApiURL}/Payroll/UnlockPeriod`,
        {
          userID,
          payPeriodID
        });
  }

  changeClientServiceNoteToManualEntry(payableItem: PayableItemModel): Observable<PayableItemModel> {
    return this.http.put<any>
      (`${environment.apiServer.webApiURL}/Payroll/ChangeClientServiceNoteToManualEntry`,
        payableItem);
  }

  getIsLockedPeriod(userID: number, periodID: number): Observable<boolean> {
    return this.http.get<boolean>(`${environment.apiServer.webApiURL}/Payroll/IsLockedPeriod/${periodID}/?userID=${userID}`);
  }

  getPayrollClassifications(): Observable<PayrollClassificationModel[]> {
    return this.http.get<PayrollClassificationModel[]>(`${environment.apiServer.webApiURL}/Payroll/PayrollClassifications`);
  }

  getCampuses(): Observable<CampusModel[]> {
    return this.http.get<CampusModel[]>(`${environment.apiServer.webApiURL}/Payroll/Campuses`);
  }
  getPayrollCampuses(): Observable<CampusModel[]> {
    return this.http.get<CampusModel[]>(`${environment.apiServer.webApiURL}/Payroll/PayrollCampuses`);
  }

  getFilteredUsers(campusID: number, payrollClassificationID: number, payPeriodID: number): Observable<UserModel[]> {
    return this.http.post<UserModel[]>(`${environment.apiServer.webApiURL}/Payroll/FilterUsers`,
      {
        campusID,
        payrollClassificationID,
        payPeriodID
      });
  }

  loadPayrollPeriods(): Observable<PayPeriodModel[]> {
    const url = `${environment.apiServer.webApiURL}/Payroll/PayrollPeriods`;
    return this.http.get<PayPeriodModel[]>(url);
  }

  editPtoRequest(ptoRequest: PtoRequestedModel): Observable<any> {
    return this.http.put<PtoRequestedModel>(`${environment.apiServer.webApiURL}/Payroll/PtoRequest/${ptoRequest.payableItemId}`, ptoRequest);
  }

  addPtoRequest(ptoRequest: PtoRequestedModel): Observable<any> {
    return this.http.post<PtoRequestedModel>(`${environment.apiServer.webApiURL}/Payroll/PtoRequest`, ptoRequest);
  }  
}
