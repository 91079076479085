import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit,} from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { State } from 'src/app/core/store';
import { AlertService } from 'src/app/shared/services/alert.service';
import { loadSelfcareOverSightEmployees } from 'src/app/core/store/selfcare/selfcare.actions';
@Component({
  selector: 'app-selfcare-oversight-header',
  templateUrl: './selfcare-oversight-header.component.html'
})
export class SelfcareOversightHeaderComponent extends BaseComponent implements OnInit {

  userID;
  oversightUserName;

  constructor(
    private store: Store<State>,
    private route: ActivatedRoute,) {
    super();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params.userID) {
        this.userID = Number(params.userID);
        this.subs.push(
          this.store.select(s => s.selfcareState.oversightList).subscribe(oversightList => {
            if (oversightList) {
              this.oversightUserName = oversightList.find(x => x.id === this.userID).fullName;
            }
          }),

        );
        if (!this.oversightUserName) {
          this.store.dispatch(loadSelfcareOverSightEmployees());
        }
      } else {
        this.userID = null;
      }
    });
  }
}
