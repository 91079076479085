import { SpokenLanguageModel, UserTypeModel } from './../../../models/userProfileModel';
import { createAction, props } from '@ngrx/store';
import { UserProfileModel, RaceEthnicityModel, JobTitleModel, ProfileOptionsModel } from '../../../models/userProfileModel';
import { FormError } from 'src/app/models/utilModels';
import { ReturnEquipmentAlertModel } from 'src/app/models/equipmentsModel';

export const loadProfile = createAction('[Profile] Load');
export const loadProfileSuccess = createAction('[Profile] Load Success', props<{ profile: UserProfileModel }>());
export const saveProfileFail = createAction('[Profile] Load Fail');

export const updateProfileForm = createAction('[Profile] Update Form',
  props<{ userValues: any; employeeValues: any; formErrors: FormError[] }>());
export const saveProfile = createAction('[Profile] Save', props<{ profile: UserProfileModel }>());
export const saveProfileSuccess = createAction('[Profile] Save Success', props<{ profile: UserProfileModel }>());
export const changePassword = createAction('[Profile] Change Password', props<{ newPassword: string }>());
export const changePasswordSuccess = createAction('[Profile] Change Password Success');
export const uploadPhoto = createAction('[Profile] Upload Photo', props<{ file: File }>());
export const uploadPhotoSuccess = createAction('[Profile] Upload Photo Success');
export const getPhoto = createAction('[Profile] Get Photo');
export const getPhotoSuccess = createAction('[Profile] Get Photo Success', props<{ url: string }>());

export const getReturnEquipmentAlerts = createAction('[Profile] Get Return Equipment Alerts', 
  props<{id: number}>());
export const getReturnEquipmentAlertsSuccess = createAction('[Profile] Get Return Equipment Alerts Success',
  props<{alert: ReturnEquipmentAlertModel}>());

export const errorHappened = createAction('errorHappened', props<{ err: any }>());

export const updateProfileBCIForm = createAction('[Onboarding Docs] Update BCI Form',
  props<{ userValues: any; employeeValues: any; formErrors: FormError[] }>());

export const updateProfileCBSForm = createAction('[Onboarding Docs] Update CBS Form',
  props<{ employeeValues: any; formErrors: FormError[] }>());


export const updateProfilePRISMForm = createAction('[Onboarding Docs] Update PRISM Form',
  props<{ employeeValues: any; formErrors: FormError[] }>());


export const updateProfileI9Form = createAction('[Onboarding Docs] Update I9 Form',
  props<{ userValues: any; employeeValues: any; formErrors: FormError[] }>());


export const updateProfileW4Form = createAction('[Onboarding Docs] Update W4 Form',
  props<{ userValues: any; employeeValues: any; employeeFederalTaxInformationValues: any; formErrors: FormError[] }>());

export const loadSpokenLanguageOptions = createAction('[Profile] loadSpokenLanguageOptions');
export const loadSpokenLanguageOptionsSuccess = createAction('[Profile] loadSpokenLanguageOptions Success', props<{ spokenLanguageOptions: SpokenLanguageModel[] }>());

export const loadRaceOptions = createAction('[Profile] loadRaceOptions');
export const loadRaceOptionsSuccess = createAction('[Profile] loadRaceOptions Success', props<{ raceOptions: RaceEthnicityModel }>());

export const loadJobTitles = createAction('[Profile] loadJobTitles options');
export const loadJobTitlesSuccess = createAction('[Profile] loadJobTitles options Success', props<{ jobTitles: JobTitleModel }>());

export const loadUserTypes = createAction('[Profile] loadUserTypes options');
export const loadUserTypesSuccess = createAction('[Profile] loadUserTypes options Success', props<{ userTypes: UserTypeModel[] }>());


export const saveSignature = createAction('[Profile] Save Signature', props<{ data: any }>());
export const saveSignatureSuccess = createAction('[Profile] Save Signature Success', props<{ profile: UserProfileModel }>());
export const saveSignatureFailure = createAction('[Profile] Save Signature Failure');

export const loadProfileOptions = createAction('[Profile] Load profile options');
export const loadProfileOptionsSuccess = createAction('[Profile] Load profile options Success', props<{ profileOptions: ProfileOptionsModel }>());
