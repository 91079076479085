import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HolidayModel, RecurringHolidayModel } from '../../models/holidayModel';
import { environment } from '../../../environments/environment';
import { PagingResultsModel } from '../../models/pagingResultsModel';
import { deepClone } from '../../helpers/utils';
import { SelectItem } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})

export class HolidayService {

  constructor(private http: HttpClient) {
  }

  getHolidayYears(): Observable<SelectItem[]> {
    return this.http.get<SelectItem[]>(`${environment.apiServer.webApiURL}/Holiday/Years`);
  }

  changeHolidayUserFilter(): Observable<SelectItem[]>{
    return this.http.get<SelectItem[]>(`${environment.apiServer.webApiURL}/Holiday/FilterUsers`);
  }

  getHolidayList(filter: any, holidayName: string, year: number, month: number, userID: number, startDate: string, endDate: string)
    : Observable<PagingResultsModel<HolidayModel>> {
    if (!filter) {
      filter = {};
      filter.filters = {};
    }    
    filter = deepClone(filter);

    filter.filters.holidayName = { Name: 'holidayName', Value: { value: holidayName } };
    filter.filters.year = { Name: 'year', Value: { value: year.toString() } };
    filter.filters.month = { Name: 'month', Value: { value: month.toString() } };
    filter.filters.userID = { Name: 'userID', Value: { value: userID.toString() } };
    filter.filters.startDate = { Name: 'startDate', Value: { value: startDate } };
    filter.filters.endDate = { Name: 'endDate', Value: { value: endDate } };

    return this.http.post<PagingResultsModel<HolidayModel>>(`${environment.apiServer.webApiURL}/Holiday/HolidayList`,
      filter)
  }

  editHoliday(holiday: HolidayModel): Observable<any> {    
    return this.http.put<HolidayModel>(`${environment.apiServer.webApiURL}/Holiday/${holiday.holidayId}`, holiday);
  }

  addHoliday(holiday: HolidayModel): Observable<any> {
    return this.http.post<HolidayModel>(`${environment.apiServer.webApiURL}/Holiday`, holiday);
  }

  deleteHoliday(holidayId: number): Observable<any> {
    return this.http.delete<any>(`${environment.apiServer.webApiURL}/Holiday/${holidayId}`);
  }

  getRecurringHolidayList(filter: any, holidayName: string, month: number, startDate: string, endDate: string, showArchived: boolean)
    : Observable<PagingResultsModel<RecurringHolidayModel>> {
    if (!filter) {
      filter = {};
      filter.filters = {};
    }
    filter = deepClone(filter);

    filter.filters.holidayName = { Name: 'holidayName', Value: { value: holidayName } };    
    filter.filters.month = { Name: 'month', Value: { value: month.toString() } };    
    filter.filters.startDate = { Name: 'startDate', Value: { value: startDate } };
    filter.filters.endDate = { Name: 'endDate', Value: { value: endDate } };
    filter.filters.showArchived = { Name: 'showArchived', Value: { value: showArchived.toString() } };

    return this.http.post<PagingResultsModel<RecurringHolidayModel>>(`${environment.apiServer.webApiURL}/Holiday/RecurringHolidayList`,
      filter)
  }

  editRecurringHoliday(holiday: RecurringHolidayModel): Observable<any> {      
    return this.http.put<HolidayModel>(`${environment.apiServer.webApiURL}/Holiday/Recurring/${holiday.recurringHolidayId}`, holiday);
  }

  addRecurringHoliday(holiday: RecurringHolidayModel): Observable<any> {
    return this.http.post<HolidayModel>(`${environment.apiServer.webApiURL}/Holiday/Recurring`, holiday);
  }  

  getRecurringTypes(): Observable<SelectItem[]> {
    return this.http.get<SelectItem[]>(`${environment.apiServer.webApiURL}/Holiday/RecurringTypes`);
  }

  getRecurringWeeks(): Observable<SelectItem[]> {
    return this.http.get<SelectItem[]>(`${environment.apiServer.webApiURL}/Holiday/RecurringWeeks`);
  }
}
