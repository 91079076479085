import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { State } from '..';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { errorHappened } from '../profile/profile.actions';
import { deleteEthicCode, deleteEthicCodeFailure, deleteEthicCodeSuccess, getEthicCode, getEthicCodeSuccess, loadEthicCodes, loadEthicCodesSuccess, saveEthicCodeFailure, saveEthicCodes, saveEthicCodeSuccess } from "./ethic-code.actions";
import { EthicCodesService } from "../../services/ethic-codes.service";


@Injectable()
export class EthicCodeEffects {
  constructor(private actions$: Actions,
    private ethicCodeService: EthicCodesService,
    private store: Store<State>) { }

  loadEthicCodes = createEffect(() => this.actions$.pipe(
    ofType(loadEthicCodes),
    switchMap(action => {
      return this.ethicCodeService.getAll().pipe(
        map(ethicCodes => loadEthicCodesSuccess({ ethicCodes })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  getEthicCode = createEffect(() => this.actions$.pipe(
    ofType(getEthicCode),
    switchMap(action => {
      return this.ethicCodeService.get(action.ethicCodeId).pipe(
        map(ethicCode => getEthicCodeSuccess({ ethicCode })));
    })
   ));

  saveEthicCode = createEffect(() => this.actions$.pipe(
    ofType(saveEthicCodes),
    switchMap(action => {
      return this.ethicCodeService.save(action.ethicCode).pipe(
        map(template => saveEthicCodeSuccess()),
          catchError(err => {
            this.store.dispatch(saveEthicCodeFailure());
            return of(errorHappened({ err }));
          }));
      })
    ));
  
  deleteEthicCode = createEffect(() => this.actions$.pipe(
    ofType(deleteEthicCode),
    switchMap(action => {
      return this.ethicCodeService.delete(action.ethicCodeId).pipe(
        map(template => deleteEthicCodeSuccess()),
          catchError(err => {
            this.store.dispatch(deleteEthicCodeFailure());
            return of(errorHappened({ err }));
          }));
      })
    ));
}
