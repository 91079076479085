import { loadManageOnboardingUserInfo, saveUserProfile, updateProfileBCIHRForm, saveUserProfileSuccess } from './../../../../../core/store/onboarding/onboarding.actions';
import { UserProfileModel } from './../../../../../models/userProfileModel';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from '../../../../../shared/services/alert.service';
import { Component, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../../core/store';
import { Actions, ofType } from '@ngrx/effects';
import * as moment from 'moment';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { FormGroup } from '@angular/forms';
import { getAllControlsErrors, markFormGroupTouched, deepClone } from 'src/app/helpers/utils';
import { tap } from 'rxjs/operators';
import { setOnboardingDocsInfoFilled, setOnboardingDocsInfoFilledSuccess } from 'src/app/core/store/onboardingdocs/onboardingdocs.actions';
import { MultiSelect } from 'primeng/multiselect';
import { initProfileBCIHRForm } from 'src/app/core/store/onboarding/onboarding.reducer';
import { SelectItem } from 'primeng/api';
import { loadCampuses } from 'src/app/core/store/payroll-approval/payroll-approval.actions';

@Component({
  selector: 'app-doc-fill-info-bci-hr',
  templateUrl: './doc-fill-info-bci-hr.component.html',
  styleUrls: ['./doc-fill-info-bci-hr.component.scss']
})
export class DocFillInfoBciHRComponent extends BaseComponent implements OnInit {

  @Input()
  onboardingDocUserId;

  @Input()
  readOnly;

  profile: UserProfileModel;
  yearRange = '1900:' + new Date(new Date().setFullYear(new Date().getFullYear() + 15)).getFullYear();
  bciIDTypeOptions = [
    { label: 'Driver License', value: 'Driver License' },
    { label: 'Passport', value: 'Passport' },
    { label: 'State ID', value: 'State ID' },
    { label: 'Military ID', value: 'Military ID' },
  ];
  bciGenderOptions = [
    { label: 'Female', value: 'Female' },
    { label: 'Male', value: 'Male' },
  ];
  bciRaceOptions = [
    { label: 'Asian', value: 'Asian' },
    { label: 'Black', value: 'Black' },
    { label: 'White', value: 'White' },
    { label: 'Native', value: 'Native' },
    { label: 'Unknown', value: 'Unknown' },
  ];
  bciLicenseTypesOptions = [
    { label: 'Out Patient Treatment', value: 'Out Patient Treatment' },
    { label: 'Intermediate Secure Care', value: 'Intermediate Secure Care' },
    { label: 'Residential Treatment (Adult/Youth)', value: 'Residential Treatment (Adult/Youth)' },
    { label: 'Adult Day Care', value: 'Adult Day Care' },
    { label: 'Outdoor Youth Treatment', value: 'Outdoor Youth Treatment' },
    { label: 'Social Detoxification', value: 'Social Detoxification' },
    { label: 'Adult Foster Care', value: 'Adult Foster Care' },
    { label: 'Recovery Residence', value: 'Recovery Residence' },
    { label: 'Therapeutic School', value: 'Therapeutic School' },
    { label: 'Child Placing Adoption Employee', value: 'Child Placing Adoption Employee' },
    { label: 'Residential Support (Adult/Youth)', value: 'Residential Support (Adult/Youth)' },
    { label: 'Child Placing Foster Employee', value: 'Child Placing Foster Employee' },
    { label: 'Day Treatment', value: 'Day Treatment' },
    { label: 'SAS', value: 'SAS' },
    { label: 'DSPD Certified', value: 'DSPD Certified' },
  ];
  form: FormGroup;
  campusesList: SelectItem[] = [{ label: 'loading...', value: undefined }];

  constructor(
    private store: Store<State>,
    private actions$: Actions,
    private alert: AlertService,
    private router: Router,
    private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.onboardingState.manageOnboardingUserInfo).subscribe(manageOnboardingUserInfo => {
        if (manageOnboardingUserInfo) {
          this.profile = deepClone(manageOnboardingUserInfo.user);
          if (this.profile.employee.bciIDExpirationDate) {
            this.profile.employee.bciIDExpirationDate = new Date(this.profile.employee.bciIDExpirationDate);
          }
          if (this.profile.employee.bciLicenseTypes && typeof (this.profile.employee.bciLicenseTypes) === 'string') {
            this.profile.employee.bciLicenseTypes = this.profile.employee.bciLicenseTypes.split(',') as any;
          }
          if (!this.form) {
            this.form = initProfileBCIHRForm(this.profile);
            this.subs.push(
              this.form.valueChanges.subscribe(formValues => {
                const userValues = this.form.get('user').value;
                const employeeValues = this.form.get('employee').value;
                const formErrors = getAllControlsErrors(this.form);
                this.store.dispatch(updateProfileBCIHRForm({ userValues, employeeValues, formErrors }));
              })
            );
          }
        }
      }),
      this.actions$.pipe(
        ofType(saveUserProfileSuccess),
        tap(action => {
          this.store.dispatch(setOnboardingDocsInfoFilled({ onboardingDocUserId: this.onboardingDocUserId }));
        })
      ).subscribe(),

      this.actions$.pipe(
        ofType(setOnboardingDocsInfoFilledSuccess),
        tap(action => {
          this.router.navigate(['../../sign/' + this.onboardingDocUserId], { relativeTo: this.route });
        })
      ).subscribe(),
      this.store.select(s => s.payrollApprovalState.campuses).subscribe(campuses => {
        const campusesList = campuses && campuses.length > 0 ? campuses.map(c => ({ label: c.programName + " - " + c.address, value: c.programName + " - " + c.address }))
          : [{ label: 'loading...', value: undefined }];

        this.campusesList = campusesList;

      }),
    );
    this.store.dispatch(loadCampuses());

    this.store.dispatch(loadManageOnboardingUserInfo({ userId: this.route.snapshot.parent.params.userid }));
  }

  save() {
    if (this.form.valid) {
      if (this.profile.employee.bciLicenseTypes && Array.isArray(this.profile.employee.bciLicenseTypes)) {
        this.profile.employee.bciLicenseTypes = (this.profile.employee.bciLicenseTypes as any[]).join(',');
      }
      this.store.dispatch(saveUserProfile({ userId: this.profile.user.id, profile: this.profile }));
    } else {
      markFormGroupTouched(this.form);
    }
  }

  openMultiSelect(multiselect: MultiSelect) {
    multiselect.show();
  }
}
