import { Component, HostListener, OnInit } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { State } from 'src/app/core/store';
import { loadProfile, getPhoto, getReturnEquipmentAlerts } from 'src/app/core/store/profile/profile.actions';
import { UserProfileModel } from 'src/app/models/userProfileModel';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { daysBetween } from '../../../helpers/utils';
import { FormBuilderService } from '../../../core/services/formBuilder.service';
import {NgbModal, ModalDismissReasons, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { getFeatureSwitches } from 'src/app/core/store/account/account.actions';
import { getSelfCareAppURL, getSelfCareAppURLSuccess } from '../../../core/store/selfcare/selfcare.actions';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends BaseComponent implements OnInit {

  @HostListener('window:resize', ['$event']) onWindowResize() {
    this.screenWidth = window.innerWidth;
  }

  modalReference: any;
  modalOptions: NgbModalOptions;
  profile: UserProfileModel;
  profilePicture;
  isHRManager;
  canAddCoach;
  canSeeCoach;
  canSeeSelfcare;
  canViewEquipment;
  canAccessCriticalNewsFeed: boolean = false;
  canViewCriticalNewsFeed;
  canViewBenefits;
  hasAnyDoc;
  notSignedDocs;
  canViewForms;
  canViewMedClinic;
  returnEquipmentAlert: string = null;
  returnEquipmentAlertCount: number = 0;
  screenWidth: number;
  showNewsFeedModal: boolean = false;
  profileLoaded: boolean;

  currentUser = JSON.parse(localStorage.getItem('currentUser'));

  gettingCaqhForm: boolean = false;

  constructor(
    private http: HttpClient,
    private actions$: Actions,
    private store: Store<State>,
    private router: Router,
    private modalService: NgbModal,
    private formBuilderService: FormBuilderService
  ) {
    super();
    this.screenWidth = window.innerWidth;
    this.modalOptions = {
      backdrop:'static',
      backdropClass:'customBackdrop',
      windowClass : "newsFeedModal",
    }
  }

  ngOnInit() {
    this.profileLoaded = false;
    this.store.dispatch(getReturnEquipmentAlerts({id: this.currentUser.id}))
    this.subs.push(
      this.store.select(s => s.profileState.profile).subscribe(profile => {
        this.profile = profile;
        if (profile) {
          this.profileLoaded = true;
          setTimeout(()=> {window.scrollTo(0,0)}, 100);
          if (profile.isOnBoarding) {
            this.router.navigate(['/onboarding']);
          }
        }
      }),
      this.store.select(s => s.profileState.photoUrl).subscribe(photoUrl => {
        this.profilePicture = photoUrl;
      }),
      this.store.select(s => s.profileState.returnEquipmentAlert).subscribe(returnEquipmentAlert => {
        this.returnEquipmentAlert = returnEquipmentAlert;
      }),
      this.store.select(s => s.profileState.returnEquipmentAlertCount).subscribe(returnEquipmentAlertCount => {
        this.returnEquipmentAlertCount = returnEquipmentAlertCount;
      }),
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.canSeeSelfcare = permissionIDs && !!permissionIDs.find(p => p === 3083); //See Self-Care
        this.isHRManager = permissionIDs && !!permissionIDs.find(p => p === 6001 || p === 6002);
        this.canAddCoach = permissionIDs && !!permissionIDs.find(p => p === 6004 || p === 6005 || p === 6006);
        this.canSeeCoach = permissionIDs && !!permissionIDs.find(p => p === 6003);
        this.canViewBenefits = permissionIDs && !!permissionIDs.find(p =>
          p === 6051
        );
        this.canViewBenefits = permissionIDs && !!permissionIDs.find(p => p === 6051);
        this.canViewForms = permissionIDs && !!permissionIDs.find(p => p === 9002);
        this.canViewCriticalNewsFeed = permissionIDs && !!permissionIDs.find(p => p === 9501 || p === 9500 || p === 9505 || p === 9504 || p === 9503 || p === 9502);
        this.canViewMedClinic = permissionIDs && !!permissionIDs.find(p => p === 11001);
      }),
      this.store.select(s => s.accountState.featureSwitchesIds).subscribe(featureSwitchesIds => {
        this.canViewEquipment = featureSwitchesIds && !!featureSwitchesIds.find(p => p === 80); // Equipment
        this.canAccessCriticalNewsFeed = featureSwitchesIds && !!featureSwitchesIds.find(p => p === 114); // Critical News Feed
      }),
      this.store.select(s => s.benefitsState.docsListBenefit).subscribe(enrollmentFormDocs => {
        if (enrollmentFormDocs) {

          this.hasAnyDoc = enrollmentFormDocs.length > 0;
          this.notSignedDocs = enrollmentFormDocs.filter(doc => !doc.signedDate).length;
        }
      }),

      this.actions$.pipe(
        ofType(getSelfCareAppURLSuccess),
        tap((action) => {
          console.info(action);

          window.open(action.url, 'iAM360');

        })
      ).subscribe(),
    );
    
    this.store.dispatch(getFeatureSwitches());
    this.store.dispatch(loadProfile());
    this.store.dispatch(getPhoto());


   

  }

  openNewsFeed(e) {
    this.showNewsFeedModal = !this.showNewsFeedModal;
    e.stopPropagation();
  }

  openSelfCare() {


    return this.http.get<{ url: string }>(`${environment.apiServer.webApiURL}/SelfCare/GetSelfCareAppURL`).
      subscribe((res) => {
        

        window.open(res.url, 'iAM360');

      });

    //this.store.dispatch(getSelfCareAppURL(null));
  }

  openLink(path) {
    this.router.navigate([path]);
  }

  openEnrollment() {
      this.router.navigate(['benefits-enrollment/enrollment']);
  }

  openMedClinic() {
    this.router.navigate(['medclinic/patients-list']);
}

  fillCaqhForm() {
    this.gettingCaqhForm = true;
    this.formBuilderService.fillCAQHForm().subscribe(form => {
      if (form.formSubmission) {
        this.router.navigate(['/form-filling/fill/' + form.form.id + '/' + form.formSubmission.id]);
      } else {
        this.router.navigate(['/form-filling/fill/' + form.form.id]);
      }
      this.gettingCaqhForm = false;
    });
  }

  isCaqhQuestionnaireNewer() {
    if (this.profile == null ||
      this.profile.caqhQuestionnaireSentDate == null)
      return false;
    if (this.profile.caqhExpirationDate == null)
      return true;
    if (daysBetween(new Date(this.profile.caqhQuestionnaireSentDate), new Date()) > 120)
      return false;
    return true;
  }

  caqhDaysToExpire() {
    if (this.profile != null && this.profile.caqhExpirationDate != null)
      return daysBetween(new Date(), new Date(this.profile.caqhExpirationDate));
    return null;
  }

  checkTermed() {
    return this.profile != null && this.profile.employee != null && this.profile.employee.terminationDate != null;
  }

  checkDisable() {
    return (this.profile != null && this.profile.pendingRequiredDocumentsCount > 0) || (this.profile.driversLicenseExpired == true) ||
      (this.profile != null && this.profile.caqhExpirationDate != null && new Date(this.profile.caqhExpirationDate) < new Date()
       && this.isCaqhQuestionnaireNewer() == false);
  }

  setAssetAlertTitle(pendingDocCount, returnEquipmentCount){
    if (pendingDocCount == 0 && returnEquipmentCount > 0){
      return `You have ${returnEquipmentCount} equipment(s) to return`;
    }
    else if (pendingDocCount > 0 && returnEquipmentCount == 0){
      return `You have ${pendingDocCount} documents`;
    }
    else{
      return `You have ${pendingDocCount} documents to sign and ${returnEquipmentCount} equipment(s) to return`;
    }
  }

}
