<form class="card mt-2" >
  <div class="card-body">
    <button class="float-right btn btn-primary ml-2 "    (click)="openEnrollment()">See enrolled plans</button>
      <h1 class="mb-3">Enrollment Docs</h1>
    <i *ngIf="loadingEnrollmentFormDocsList" class="material-icons rotate text-muted">loop</i>
    <div class="alert alert-success" role="alert" *ngIf="!loadingEnrollmentFormDocsList && !hasNotSignedDoc">
      Finished signing enrollment forms.
    </div>

    <p-table  [value]="enrollmentFormDocs" [responsive]="true"
    [paginator]="true" [rows]="20" [totalRecords]="enrollmentFormDocs?.length"
    >
        <ng-template pTemplate="header">
            <tr>
                <th width="250px"></th>
                <th width="90px">Signed</th>
                <!-- <th width="90px">Signed by HR</th> -->
                <th> Document</th>
                <!-- <th  width="90px">Due Date</th> -->
                <!-- <th  width="90px">Required</th> -->
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-enrollmentFormDoc>
            <tr >
                <td>
                  <button class="btn btn-secondary mr-2" *ngIf="(!managedUserId  && enrollmentFormDoc?.signedDate) || (managedUserId  && enrollmentFormDoc?.signedByHRDate) "  (click)="viewDoc(enrollmentFormDoc)">View Doc</button>
                  <!-- <button class="btn btn-secondary" *ngIf="!managedUserId  && !enrollmentFormDoc?.signedDate && (enrollmentFormDoc?.onBoarding_Document.needFillInfo )" (click)="fillInfo(enrollmentFormDoc)">Fill info and sign</button> -->
                  <button class="btn btn-secondary" *ngIf="managedUserId  && enrollmentFormDoc?.signedDate && !enrollmentFormDoc?.signedByHRDate" (click)="signAsHR(enrollmentFormDoc)">Sign as HR</button>
                  <button class="btn btn-secondary" *ngIf="!managedUserId  && !enrollmentFormDoc?.signedDate" (click)="sign(enrollmentFormDoc)">Sign</button>
                </td>
                <td>
                  <span class="ui-column-title">Signed by User</span>
                  <i class="fas fa-check-circle text-success mr-2" title="Signed" *ngIf="enrollmentFormDoc?.signedDate"></i>
                  <i class="fas fa-dot-circle mr-2" title="Missing" *ngIf="!enrollmentFormDoc.signedDate"></i>
                  {{enrollmentFormDoc.signedDate | date:'shortDate' }}
                </td>
                 <!-- <td>
                  <span class="ui-column-title">Signed by HR</span>
                  <i class="fas fa-check-circle text-success mr-2" title="Signed" *ngIf="enrollmentFormDoc?.signedByHRDate"></i>
                  <i class="fas fa-dot-circle mr-2" title="Missing" *ngIf="!enrollmentFormDoc?.signedByHRDate"></i>
                  {{enrollmentFormDoc.signedByHRDate | date:'shortDate' }}
                </td> -->
                <td>
                  <span class="ui-column-title">Document</span>
                  {{enrollmentFormDoc.benefitPlanFormDocument.documentName}}
                </td>
                <!-- <td>
                  <span class="ui-column-title">Due Date</span>
                  {{(enrollmentFormDoc.dueDate?enrollmentFormDoc.dueDate:enrollmentFormDoc.createdDate) | date:'shortDate' }}
                </td> -->
                <!-- <td>
                  <span class="ui-column-title">Required</span>
                  <i *ngIf="!!enrollmentFormDoc.isReallyRequired" class="fas fa-asterisk text-danger" title="Required"></i>
                </td> -->
            </tr>
        </ng-template>
    </p-table>


  </div>
</form>
