import { Action, createReducer, on } from "@ngrx/store";
import { PagingResultsModel } from "../../../models/pagingResultsModel";
import { BenefitPlanEDITransmissionModel } from "../../../models/benefitPlanEDITransmissionModel";
import {
  getBenefitPlanEDITransmissionMembers, getBenefitPlanEDITransmissionMembersSuccess,
  getBenefitPlanEDITransmissions, getBenefitPlanEDITransmissionsSuccess,
  putBenefitPlanEDITransmission, putBenefitPlanEDITransmissionSuccess, putBenefitPlanEDITransmissionFailure,
  exportBenefitPlanEDITransmissionData, exportBenefitPlanEDITransmissionDataSuccess, exportBenefitPlanEDITransmissionDataFailure
} from "./benefitPlanEDI.action";
import { BenefitPlanEDIMemberInformationModel } from "../../../models/benefitPlanEDIMemberInformationModel";
 
export interface BenefitPlanEDIState {
  transmissions: PagingResultsModel<BenefitPlanEDITransmissionModel>;
  transmissionMembers: PagingResultsModel<BenefitPlanEDIMemberInformationModel>;

  loadingTransmissions: boolean;
  loadingTransmissionMembers: boolean;
  uploadingFile: boolean;
  exportingExcel: boolean;
  transmissionExcelArrayBuffer: ArrayBuffer;
}

const initialState: BenefitPlanEDIState = {
  transmissions: null,
  transmissionMembers: null,

  loadingTransmissions: true,
  loadingTransmissionMembers: false,
  uploadingFile: false,
  exportingExcel: false,
  transmissionExcelArrayBuffer: null,
}

export const benefitPlanEDIReducer = createReducer(initialState,
  on(getBenefitPlanEDITransmissions, (state, action) => {
    return {
      ...state,
      loadingTransmissions: true
    };
  }),
  on(getBenefitPlanEDITransmissionsSuccess, (state, action) => {
    return {
      ...state,
      loadingTransmissions: false,
      transmissions: action.transmissions
    };
  }),

  on(getBenefitPlanEDITransmissionMembers, (state, action) => {
    return {
      ...state,
      loadingTransmissionMembers: true
    };
  }),
  on(getBenefitPlanEDITransmissionMembersSuccess, (state, action) => {
    return {
      ...state,
      loadingTransmissionMembers: false,
      transmissionMembers: action.transmissionMembers   
    };
  }),
  on(putBenefitPlanEDITransmission, (state, action) => {
    return {
      ...state,
      uploadingFile: true
    };
  }),
  on(putBenefitPlanEDITransmissionSuccess, (state, action) => {
    return {
      ...state,
      uploadingFile: false
    };
  }),
  on(putBenefitPlanEDITransmissionFailure, (state, action) => {
    return {
      ...state,
      uploadingFile: false
    };
  }),
  on(exportBenefitPlanEDITransmissionData, (state, action) => {
    return {
      ...state,
      exportingExcel: true,
      enrollmentExcelArrayBuffer: null
    };
  }),
  on(exportBenefitPlanEDITransmissionDataSuccess, (state, action) => {
    return {
      ...state,
      exportingExcel: false,
      transmissionExcelArrayBuffer: action.doc
    };
  }),
  on(exportBenefitPlanEDITransmissionDataFailure, (state, action) => {
    return {
      ...state,
      exportingExcel: false
    };
  }),
)

export function benefitPlanEDIReducerFunc(state: BenefitPlanEDIState | undefined, action: Action) {
  return benefitPlanEDIReducer(state, action);
}
