import { createAction, props } from '@ngrx/store';
import { SelectItem } from 'primeng/api';
import { FBFormSubmissionModel } from '../../../models/formBuilderModels';
import { InternApplicationActionModel, InternApplicationActionTemplateModel, InternApplicationActionTypeModel, InternApplicationModel, InternReviewFormModel, InternshipSubstatusModel, InternUploadedFilesModel, InternUploadModel } from '../../../models/internModels';
import { PagingResultsModel } from '../../../models/pagingResultsModel';
import { ModifiedSelectItem } from '../../../models/utilModels';

export const loadInternForms = createAction('[Intern] Load Forms');
export const loadInternFormsSuccess = createAction('[Intern] Load Forms Success',
  props<{ forms: SelectItem[] }>());

export const loadInternshipStatuses = createAction('[Intern] Load Internship Statuses');
export const loadInternshipStatusesSuccess = createAction('[Intern] Load Internship Statuses Success',
  props<{ statuses: ModifiedSelectItem[] }>());

export const loadInternshipSubstatuses = createAction('[Intern] Load Internship Substatuses');
export const loadInternshipSubstatusesSuccess = createAction('[Intern] Load Internship Substatuses Success',
  props<{ statuses: ModifiedSelectItem[] }>());

export const loadInternshipActionTypes = createAction('[Intern] Load Internship Action Types');
export const loadInternshipActionTypesSuccess = createAction('[Intern] Load Internship Action Types Success',
  props<{ actionTypes: SelectItem[] }>());

export const getMyApplicationsList = createAction('[Intern] Get My Applications List',
  props<{ filter: any }>());
export const getMyApplicationsListSuccess = createAction('[Intern] Get My Applications List Success',
  props<{ list: PagingResultsModel<InternApplicationModel> }>());

export const getManageApplicationsList = createAction('[Intern] Get Manage Applications List',
  props<{ filter: any }>());
export const getManageApplicationsListSuccess = createAction('[Intern] Get Manage Applications List Success',
  props<{ list: PagingResultsModel<InternApplicationModel> }>());

export const getInternActionTypeList = createAction('[Intern] Get Action Type List',
  props<{ filter: any }>());
export const getInternActionTypeListSuccess = createAction('[Intern] Get Action Type List Success',
  props<{ list: PagingResultsModel<InternApplicationActionTypeModel> }>());

export const getInternshipSubstatusList = createAction('[Intern] Get Substatus List',
  props<{ filter: any }>());
export const getInternshipSubstatusListSuccess = createAction('[Intern] Get Substatus List Success',
  props<{ list: PagingResultsModel<InternshipSubstatusModel> }>());

export const getInternActionItemTemplateList = createAction('[Intern] Get Action Item Template List',
  props<{ filter: any }>());
export const getInternActionItemTemplateListSuccess = createAction('[Intern] Get Action Item Template List Success',
  props<{ list: PagingResultsModel<InternApplicationActionTemplateModel> }>());

export const changeApplicationStatus = createAction('[Intern] Change Internship Application Status',
  props<{ applicationId: number, statusId: number }>());
export const changeApplicationStatusSuccess = createAction('[Intern] Change Internship Application Status Success');
export const changeApplicationStatusFail = createAction('[Intern] Change Internship Application Status Fail',
  props<{ err: any }>());

export const changeApplicationSubstatus = createAction('[Intern] Change Internship Application Substatus',
  props<{ applicationId: number, substatusId: number }>());
export const changeApplicationSubstatusSuccess = createAction('[Intern] Change Internship Application Substatus Success');
export const changeApplicationSubstatusFail = createAction('[Intern] Change Internship Application Substatus Fail',
  props<{ err: any }>());

export const updateHourAndProductivityCalculations = createAction('[Intern] Update Hour And Productivity Calculations',
  props<{ applicationId: number }>());
export const updateHourAndProductivityCalculationsSuccess = createAction('[Intern] Update Hour And Productivity Calculations Success');
export const updateHourAndProductivityCalculationsFail = createAction('[Intern] Update Hour And Productivity Calculations Fail',
  props<{ err: any }>());

export const createInternshipApplication = createAction('[Intern] Create Internship Application',
  props<{ formId: number }>());
export const createInternshipApplicationSuccess = createAction('[Intern] Create Internship Application Pdf Success',
  props<{ formSubmission: FBFormSubmissionModel }>());
export const createInternshipApplicationFailure = createAction('[Intern] Create Internship Application Failure');

export const exportManageInternApplicationsListToExcel = createAction('[Intern] Export Manage Intern Applications To Excel',
  props<{ filter: any }>());
export const exportManageInternApplicationsListToExcelSuccess = createAction('[Intern] Export Manage Intern Applications To Excel Success',
  props<{ doc: ArrayBuffer }>());
export const exportManageInternApplicationsListToExcelFailure = createAction('[Intern] Export Manage Intern Applications To Excel Failure');

export const saveInternActionType = createAction('[Intern] Save Intern Action Type',
  props<{ actionType: InternApplicationActionTypeModel }>());
export const saveInternActionTypeSuccess = createAction('[Intern] Save Intern Action Type Success');
export const saveInternActionTypeFail = createAction('[Intern] Save Intern Action Type Fail',
  props<{ err: any }>());

export const deleteInternActionType = createAction('[Intern] Delete Intern Action Type', props<{ actionTypeId: number }>());
export const deleteInternActionTypeSuccess = createAction('[Intern] Delete Intern Action Type Success');
export const deleteInternActionTypeFail = createAction('[Intern] Delete Intern Action Type Fail',
  props<{ err: any }>());

export const getInternActionType = createAction('[Intern] Get Intern Action Type',
  props<{ actionTypeId: number }>());
export const getInternActionTypeSuccess = createAction('[Intern] Get Intern Action Type Success',
  props<{ actionType: InternApplicationActionTypeModel }>());
export const getInternActionTypeFailure = createAction('[Intern] Get Intern Action Type Failure');

export const showEditInternActionType = createAction('[Intern] Show Edit Intern Action Type Modal',
  props<{ actionTypeId: number }>());
export const closeEditInternActionType = createAction('[Intern] Close Edit Intern Action Type Modal');

export const getInternApplication = createAction('[Intern] Get Intern Application',
  props<{ applicationId: number }>());
export const getInternApplicationSuccess = createAction('[Intern] Get Intern Application Success',
  props<{ application: InternApplicationModel }>());
export const getInternApplicationFailure = createAction('[Intern] Get Intern Application Failure');

export const getInternApplicationReviewForms = createAction('[Intern] Get Intern Application Review Forms',
  props<{ applicationId: number }>());
export const getInternApplicationReviewFormsSuccess = createAction('[Intern] Get Intern Application Review Forms Success',
  props<{ forms: InternReviewFormModel[] }>());
export const getInternApplicationReviewFormsFailure = createAction('[Intern] Get Intern Application Review Forms Failure');

export const saveInternActionItem = createAction('[Intern] Save Intern Action Item',
  props<{ actionItem: InternApplicationActionModel }>());
export const saveInternActionItemSuccess = createAction('[Intern] Save Intern Action Item Success',
  props<{ actionItems: InternApplicationActionModel[] }>());
export const saveInternActionItemFail = createAction('[Intern] Save Intern Action Item Fail',
  props<{ err: any }>());

export const deleteInternActionItem = createAction('[Intern] Delete Intern Action Item', props<{ actionId: number }>());
export const deleteInternActionItemSuccess = createAction('[Intern] Delete Intern Action Item Success',
  props<{ actionItems: InternApplicationActionModel[] }>());
export const deleteInternActionItemFail = createAction('[Intern] Delete Intern Action Item Fail',
  props<{ err: any }>());

export const getInternActionItem = createAction('[Intern] Get Intern Action Item',
  props<{ actionId: number }>());
export const getInternActionItemSuccess = createAction('[Intern] Get Intern Action Item Success',
  props<{ actionItem: InternApplicationActionModel }>());
export const getInternActionItemFailure = createAction('[Intern] Get Intern Action Item Failure');

export const showEditInternActionItem = createAction('[Intern] Show Edit Intern Action Item Modal',
  props<{ actionId: number, actionApplicationId: number }>());
export const closeEditInternActionItem = createAction('[Intern] Close Edit Intern Action Item Modal');

export const getInternUploads = createAction('[Intern] Get Intern Application Uploads',
  props<{ applicationId: number }>());
export const getInternUploadsSuccess = createAction('[Intern] Get Intern Application Uploads Success',
  props<{ uploads: InternUploadedFilesModel }>());
export const getInternUploadsFailure = createAction('[Intern] Get Intern Application Uploads Failure');

export const showEditInternUploads = createAction('[Intern] Show Edit Intern Application Uploads Modal',
  props<{ applicationId: number }>());
export const closeEditInternUploads = createAction('[Intern] Close Edit Intern Application Uploads Modal');

export const saveInternUploads = createAction('[Intern] Save Intern Application Uploads',
  props<{ uploads: InternUploadedFilesModel }>());
export const saveInternUploadsSuccess = createAction('[Intern] Save Intern Application Uploads Success',
  props<{ uploads: InternUploadModel[] }>());
export const saveInternUploadsFail = createAction('[Intern] Save Intern Application Uploads Fail',
  props<{ err: any }>());

export const deleteInternUpload = createAction('[Intern] Delete Intern Application Upload', props<{ uploadId: number }>());
export const deleteInternUploadSuccess = createAction('[Intern] Delete Intern Application Upload Success',
  props<{ uploads: InternUploadModel[] }>());
export const deleteInternUploadFail = createAction('[Intern] Delete Intern Application Upload Fail',
  props<{ err: any }>());

export const getInternApplicationUpload = createAction('[Intern] Get Intern Application Upload',
  props<{ uploadId: number }>());
export const getInternApplicationUploadSuccess = createAction('[Intern] Get Intern Application Upload Success',
  props<{ doc: ArrayBuffer }>());
export const getInternApplicationUploadFailure = createAction('[Intern] Get Intern Application Upload Failure');


export const saveInternshipSubstatus = createAction('[Intern] Save Internship Substatus',
  props<{ substatus: InternshipSubstatusModel }>());
export const saveInternshipSubstatusSuccess = createAction('[Intern] Save Internship Substatus Success');
export const saveInternshipSubstatusFail = createAction('[Intern] Save Internship Substatus Fail',
  props<{ err: any }>());

export const deleteInternshipSubstatus = createAction('[Intern] Delete Internship Substatus', props<{ substatusId: number }>());
export const deleteInternshipSubstatusSuccess = createAction('[Intern] Delete Internship Substatus Success');
export const deleteInternshipSubstatusFail = createAction('[Intern] Delete Internship Substatus Fail',
  props<{ err: any }>());

export const getInternshipSubstatus = createAction('[Intern] Get Internship Substatus',
  props<{ substatusId: number }>());
export const getInternshipSubstatusSuccess = createAction('[Intern] Get Internship Substatus Success',
  props<{ substatus: InternshipSubstatusModel }>());
export const getInternshipSubstatusFailure = createAction('[Intern] Get Internship Substatus Failure');

export const showEditInternshipSubstatus = createAction('[Intern] Show Edit Internship Substatus Modal',
  props<{ substatusId: number }>());
export const closeEditInternshipSubstatus = createAction('[Intern] Close Edit Internship Substatus Modal');



export const saveInternActionItemTemplate = createAction('[Intern] Save Intern Action Item Template',
  props<{ template: InternApplicationActionTemplateModel }>());
export const saveInternActionItemTemplateSuccess = createAction('[Intern] Save Intern Action Item Template Success');
export const saveInternActionItemTemplateFail = createAction('[Intern] Save Intern Action Item Template Fail',
  props<{ err: any }>());

export const deleteInternActionItemTemplate = createAction('[Intern] Delete Intern Action Item Template', props<{ templateId: number }>());
export const deleteInternActionItemTemplateSuccess = createAction('[Intern] Delete Intern Action Item Template Success');
export const deleteInternActionItemTemplateFail = createAction('[Intern] Delete Intern Action Item Template Fail',
  props<{ err: any }>());

export const getInternActionItemTemplate = createAction('[Intern] Get Intern Action Item Templates',
  props<{ templateId: number }>());
export const getInternActionItemTemplateSuccess = createAction('[Intern] Get Intern Action Item Template Success',
  props<{ template: InternApplicationActionTemplateModel }>());
export const getInternActionItemTemplateFailure = createAction('[Intern] Get Intern Action Item Template Failure');

export const showEditInternActionItemTemplate = createAction('[Intern] Show Edit Intern Action Item Template Modal',
  props<{ templateId: number }>());
export const closeEditInternActionItemTemplate = createAction('[Intern] Close Edit Intern Action Item Template Modal');

export const saveInternApplicationReview = createAction('[Intern] Save Intern Application Review',
  props<{ application: InternApplicationModel }>());
export const saveInternApplicationReviewSuccess = createAction('[Intern] Save Intern Application Review Success');
export const saveInternApplicationReviewFail = createAction('[Intern] Save Intern Application Review Fail',
  props<{ err: any }>());

export const createInternship = createAction('[Intern] Create Intership',
  props<{ applicationId: number }>());
export const createInternshipSuccess = createAction('[Intern] Create Intership Success');
export const createInternshipFail = createAction('[Intern] Create Intership Fail',
  props<{ err: any }>());

export const loadInternApplicationDocToSign = createAction('[Form Builder] Get loadInternApplicationDocToSign',
  props<{ documentToSignId: number }>());
export const loadInternApplicationDocToSignSuccess = createAction('[Form Builder] Get loadInternApplicationDocToSign Success',
  props<{ doc: ArrayBuffer }>());
export const loadInternApplicationDocToSignFail = createAction('[Form Builder] Get loadInternApplicationDocToSign Fail',
  props<{ err: any }>());
