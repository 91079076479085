import { Component, Input, OnInit } from '@angular/core';
import { EmployeeReviewTemplateKRAModel } from '../../../../../models/employeeReviewTemplateModels';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { BaseComponent } from '../../../../../core/abstracts/baseComponent';
import { State } from '../../../../../core/store';
import { removeKpi, removeKra, removeMeasureable } from '../../../../../core/store/employee-review-template/employee-review-template.actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-kra-template-component',
  templateUrl: './kra-template-component.component.html',
  styleUrls: ['./kra-template-component.component.scss']

})
export class KraTemplateComponentComponent extends BaseComponent implements OnInit {
  @Input() kra: EmployeeReviewTemplateKRAModel;
  @Input() kraIndex: any;

  loading: boolean;
  valueColors;
  
  scoreOptionsSet;

  constructor(
    private actions$: Actions,
    private store: Store<State>
  ) {
    super();
  }

  ngOnInit() {
    if (this.kra.kpis == null)
      this.kra.kpis = [];
    //if (this.kra.kpis.length == 0)
    //  this.addNewKpi();

    this.subs.push(
      this.store.select(s => s.employeeReviewTemplateState.loading).subscribe(loading => {
        this.loading = loading;
      }),
      this.store.select(s => s.employeeReviewTemplateState.valueColors).subscribe(valueColors => {
        this.valueColors = valueColors;
        if (this.kra.valueColorID) {
          this.kra.valueColor = valueColors.find(x => x.id === this.kra.valueColorID);
        }
      }),
      this.store.select(s => s.employeeReviewTemplateState.scoreOptionsSet).subscribe(scoreOptionsSet => {
        this.scoreOptionsSet = scoreOptionsSet;
        if (this.kra.optionSet) {
          this.kra.optionSet = scoreOptionsSet.find(x => x.scoreSetID === this.kra.optionSet.scoreSetID);
        }
      }),
      this.actions$.pipe(
        ofType(removeKpi),
        tap(action => {
          if (this.kra.kpis.some(s => s == action.kpi)) {
            var index = this.kra.kpis.indexOf(action.kpi);
            this.kra.kpis.splice(index, 1);
          }
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(removeMeasureable),
        tap(action => {
          if (this.kra.measureables.some(s => s == action.measureable)) {
            var index = this.kra.measureables.indexOf(action.measureable);
            this.kra.measureables.splice(index, 1);
          }
        })
      ).subscribe()
    );
  }

  addNewKpi() {
    this.kra.kpis.push({
      kpi_id: null,
      measurement: '',
      reason: '',
      corePlay: '',
      categories: []
    });
  }

  remove() {
    this.store.dispatch(removeKra({ kra: this.kra }));
  }

  addNewMeasurable() {
    if (this.kra.measureables == null)
      this.kra.measureables = [];

    this.kra.measureables.push({
      measureableId: null,
      childrenMeasureables: [],
      measureable: '',
      isChild: false
    });
  }
  
  changedColor(event, kra) {
    if (event) {
      kra.valueColorID = event.id;
    } else {
      kra.valueColorID = null;
    }
  }
}
