import { createReducer, on, Action } from '@ngrx/store';
import { SelectItem } from 'primeng/api';
import { PagingResultsModel } from '../../../models/pagingResultsModel';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { errorHappened } from '../profile/profile.actions';
import { closeEditCertification, closeEditCeuEvent, closeEditInstructor, deleteCertification, deleteCertificationSuccess, deleteCeuEvent, deleteCeuEventSuccess, deleteInstructor, deleteInstructorSuccess, exportCertificationInstructorListToExcel, exportCertificationInstructorListToExcelFailure, exportCertificationInstructorListToExcelSuccess, exportCertificationListToExcel, exportCertificationListToExcelFailure, exportCertificationListToExcelSuccess, exportCeuEventListToExcel, exportCeuEventListToExcelSuccess, generateMissingCeuEventCertifications, generateMissingCeuEventCertificationsFail, generateMissingCeuEventCertificationsSuccess, getCertificationInstructorList, getCertificationInstructorListSuccess, getCertificationList, getCertificationListSuccess, getCertificationPdf, getCertificationPdfFailure, getCertificationPdfSuccess, getCeuEvent, getCeuEventFailure, getCeuEventFile, getCeuEventFileFailure, getCeuEventFileSuccess, getCeuEventList, getCeuEventListSuccess, getCeuEventSuccess, loadCertificationTypesSuccess, loadCertificationUploads, loadCertificationUploadsFailure, loadCertificationUploadsSuccess, loadCeuCategoriesSuccess, loadCeuTypesSuccess, loadInstructorsSuccess, saveCertification, saveCertificationFail, saveCertificationSuccess, saveCeuEvent, saveCeuEventFail, saveCeuEventSuccess, saveInstructor, saveInstructorFail, saveInstructorSuccess, showEditCertification, showEditCeuEvent, showEditInstructor, updateCertificationForm, updateCeuEventForm, updateInstructorForm } from './certification.action';
import { CertificationInstructorModel, CertificationReportModel, CertificationTypeUploadModel, CeuEventCertificationHasCeuModel, CeuEventHasCertificationTypeModel, CeuEventHasUserModel, CeuEventModel } from '../../../models/certificationModel';
import { formatDate } from '@angular/common';
import { FormError } from '../../../models/utilModels';


export interface CertificationState {
  certificationTypes: SelectItem[];
  ceuCategories: SelectItem[];
  ceuTypes: SelectItem[];
  instructors: SelectItem[];

  loadingCertificationList: boolean;
  certificationList: PagingResultsModel<CertificationReportModel>;

  loadingCertificationInstructorList: boolean;
  certificationInstructorList: PagingResultsModel<CertificationInstructorModel>;

  editingCertification: boolean;
  savingCertification: boolean;

  selectedCertification: CertificationReportModel;
  formErrors: FormError[];

  editingInstructor: boolean;
  savingInstructor: boolean;

  selectedInstructor: CertificationReportModel;
  formErrorsInstructor: FormError[];

  loadingPdf: boolean;
  pdfArrayBuffer: ArrayBuffer;

  loadingCertificationExcel: boolean;
  certificationExcelArrayBuffer: ArrayBuffer;

  loadingCertificationInstructorExcel: boolean;
  certificationInstructorExcelArrayBuffer: ArrayBuffer;

  uploadedFiles: CertificationTypeUploadModel[];
  loadingUploadedFiles: boolean;

  loadingCeuEventList: boolean;
  ceuEventList: PagingResultsModel<CeuEventModel>;

  loadingCeuEventExcel: boolean;
  ceuEventExcelArrayBuffer: ArrayBuffer;

  ceuEventId: number;
  editingCeuEvent: boolean;
  savingCeuEvent: boolean;
  loadingEvent: boolean;

  selectedCeuEvent: CeuEventModel;
  formErrorsCeuEvent: FormError[];

  loadingCeuEventFile: boolean;
  ceuEventFileArrayBuffer: ArrayBuffer;

}

const initialState: CertificationState = {
  certificationTypes: [],
  ceuCategories: [],
  ceuTypes: [],
  instructors: [],

  loadingCertificationList: true,
  certificationList: null,

  loadingCertificationInstructorList: true,
  certificationInstructorList: null,

  editingCertification: false,
  savingCertification: false,

  selectedCertification: null,
  formErrors: [],

  editingInstructor: false,
  savingInstructor: false,

  selectedInstructor: null,
  formErrorsInstructor: [],

  loadingPdf: false,
  pdfArrayBuffer: null,

  loadingCertificationExcel: false,
  certificationExcelArrayBuffer: null,

  loadingCertificationInstructorExcel: false,
  certificationInstructorExcelArrayBuffer: null,

  uploadedFiles: [],
  loadingUploadedFiles: true,

  loadingCeuEventList: true,
  ceuEventList: null,

  loadingCeuEventExcel: false,
  ceuEventExcelArrayBuffer: null,

  ceuEventId: 0,
  editingCeuEvent: false,
  savingCeuEvent: false,
  loadingEvent: false,

  selectedCeuEvent: null,
  formErrorsCeuEvent: [],

  loadingCeuEventFile: false,
  ceuEventFileArrayBuffer: null
};

export const certificationReducer = createReducer(initialState,
  on(loadCertificationTypesSuccess, (state, action) => {
    return {
      ...state,
      certificationTypes: action.certificationTypes,
    };
  }),
  on(loadCeuCategoriesSuccess, (state, action) => {
    return {
      ...state,
      ceuCategories: action.ceuCategories,
    };
  }),
  on(loadCeuTypesSuccess, (state, action) => {
    return {
      ...state,
      ceuTypes: action.ceuTypes,
    };
  }),
  on(loadInstructorsSuccess, (state, action) => {
    return {
      ...state,
      instructors: action.instructors,
    };
  }),
  on(getCertificationInstructorList, (state, action) => {
    return {
      ...state,
      loadingCertificationInstructorList: true
    };
  }),
  on(getCertificationInstructorListSuccess, (state, action) => {
    return {
      ...state,
      loadingCertificationInstructorList: false,
      certificationInstructorList: action.list
    };
  }),
  on(showEditInstructor, (state, action) => {
    return {
      ...state,
      selectedInstructor: action.instructor,
      editingInstructor: true
    };
  }),
  on(closeEditInstructor, (state, action) => {
    return {
      ...state,
      selectedInstructor: null,
      editingInstructor: false
    };
  }),
  on(saveInstructor, (state, action) => {
    return {
      ...state,
      savingInstructor: true
    }
  }),
  on(saveInstructorSuccess, (state, action) => {
    return {
      ...state,
      savingInstructor: false
    }
  }),
  on(saveInstructorFail, (state, action) => {
    return {
      ...state,
      savingInstructor: false
    }
  }),
  on(deleteInstructor, (state, action) => {
    return {
      ...state,
      savingInstructor: true
    }
  }),
  on(deleteInstructorSuccess, (state, action) => {
    return {
      ...state,
      savingInstructor: false
    }
  }),
  on(updateInstructorForm, (state, action) => {
    const editingInstructor = { ...state.selectedInstructor, ...action.formValues };
    const newState = { ...state, ...{ selectedInstructor: editingInstructor, formErrorsInstructor: action.formErrors } };
    return newState;
  }),
  on(getCertificationList, (state, action) => {
    return {
      ...state,
      loadingCertificationList: true
    };
  }),
  on(getCertificationListSuccess, (state, action) => {
    return {
      ...state,
      loadingCertificationList: false,
      certificationList: action.list
    };
  }),
  on(showEditCertification, (state, action) => {
    return {
      ...state,
      selectedCertification: action.certification,
      editingCertification: true
    };
  }),
  on(closeEditCertification, (state, action) => {
    return {
      ...state,
      selectedCertification: null,
      editingCertification: false
    };
  }),
  on(saveCertification, (state, action) => {
    return {
      ...state,
      savingCertification: true
    }
  }),
  on(saveCertificationSuccess, (state, action) => {
    return {
      ...state,
      savingCertification: false
    }
  }),
  on(saveCertificationFail, (state, action) => {
    return {
      ...state,
      savingCertification: false
    }
  }),
  on(deleteCertification, (state, action) => {
    return {
      ...state,
      savingCertification: true
    }
  }),
  on(deleteCertificationSuccess, (state, action) => {
    return {
      ...state,
      savingCertification: false
    }
  }),
  on(updateCertificationForm, (state, action) => {
    const editingCertication = { ...state.selectedCertification, ...action.formValues };
    const newState = { ...state, ...{ selectedCertification: editingCertication, formErrors: action.formErrors } };
    return newState;
  }),
  on(getCertificationPdf, (state, action) => {
    return {
      ...state,
      loadingPdf: true
    };
  }),
  on(getCertificationPdfSuccess, (state, action) => {
    return {
      ...state,
      pdfArrayBuffer: action.doc,
      loadingPdf: false
    };
  }),
  on(getCertificationPdfFailure, (state, action) => {
    return {
      ...state,
      loadingPdf: false
    };
  }),
  on(exportCertificationListToExcel, (state, action) => {
    return {
      ...state,
      loadingCertificationExcel: true,
      certificationExcelArrayBuffer: null
    };
  }),
  on(exportCertificationListToExcelSuccess, (state, action) => {
    return {
      ...state,
      loadingCertificationExcel: false,
      certificationExcelArrayBuffer: action.doc
    };
  }),
  on(exportCertificationListToExcelFailure, (state, action) => {
    return {
      ...state,
      loadingCertificationExcel: false
    };
  }),
  on(exportCertificationInstructorListToExcel, (state, action) => {
    return {
      ...state,
      loadingCertificationInstructorExcel: true,
      certificationInstructorExcelArrayBuffer: null
    };
  }),
  on(exportCertificationInstructorListToExcelSuccess, (state, action) => {
    return {
      ...state,
      loadingCertificationInstructorExcel: false,
      certificationInstructorExcelArrayBuffer: action.doc
    };
  }),
  on(exportCertificationInstructorListToExcelFailure, (state, action) => {
    return {
      ...state,
      loadingCertificationInstructorExcel: false
    };
  }),

  on(loadCertificationUploads, (state, action) => {
    return {
      ...state,
      loadingUploadedFiles: true,
      uploadedFiles: []
    };
  }),
  on(loadCertificationUploadsSuccess, (state, action) => {
    return {
      ...state,
      uploadedFiles: action.uploadedFiles,
      loadingUploadedFiles: false
    };
  }),

  on(loadCertificationUploadsFailure, (state, action) => {
    return {
      ...state,
      loadingUploadedFiles: false
    };
  }),
  on(getCeuEventList, (state, action) => {
    return {
      ...state,
      loadingCeuEventList: true
    };
  }),
  on(getCeuEventListSuccess, (state, action) => {
    return {
      ...state,
      loadingCeuEventList: false,
      ceuEventList: action.list
    };
  }),
  on(exportCeuEventListToExcel, (state, action) => {
    return {
      ...state,
      loadingCeuEventExcel: true,
      ceuEventExcelArrayBuffer: null
    };
  }),
  on(exportCeuEventListToExcelSuccess, (state, action) => {
    return {
      ...state,
      loadingCeuEventExcel: false,
      ceuEventExcelArrayBuffer: action.doc
    };
  }),
  on(exportCertificationListToExcelFailure, (state, action) => {
    return {
      ...state,
      loadingCeuEventExcel: false
    };
  }),
  on(showEditCeuEvent, (state, action) => {
    return {
      ...state,
      //selectedCeuEvent: action.ceuEvent,
      ceuEventId: action.eventId,
      editingCeuEvent: true
    };
  }),
  on(closeEditCeuEvent, (state, action) => {
    return {
      ...state,
      selectedCeuEvent: null,
      ceuEventId: 0,
      editingCeuEvent: false
    };
  }),
  on(saveCeuEvent, (state, action) => {
    return {
      ...state,
      savingCeuEvent: true
    }
  }),
  on(saveCeuEventSuccess, (state, action) => {
    return {
      ...state,
      savingCeuEvent: false
    }
  }),
  on(saveCeuEventFail, (state, action) => {
    return {
      ...state,
      savingCeuEvent: false
    }
  }),
  on(updateCeuEventForm, (state, action) => {
    const editingCeuEvent = { ...state.selectedCeuEvent, ...action.formValues };
    const newState = { ...state, ...{ selectedCeuEvent: editingCeuEvent, formErrors: action.formErrors } };
    return newState;
  }),
  on(getCeuEvent, (state, action) => {
    return {
      ...state,
      loadingEvent: true
    };
  }),
  on(getCeuEventSuccess, (state, action) => {
    return {
      ...state,
      selectedCeuEvent: action.event,
      loadingEvent: false
    };
  }),
  on(getCeuEventFailure, (state, action) => {
    return {
      ...state,
      loadingEvent: false
    };
  }),
  on(getCeuEventFile, (state, action) => {
    return {
      ...state,
      loadingCeuEventFile: true
    };
  }),
  on(getCeuEventFileSuccess, (state, action) => {
    return {
      ...state,
      ceuEventFileArrayBuffer: action.doc,
      loadingCeuEventFile: false
    };
  }),
  on(getCeuEventFileFailure, (state, action) => {
    return {
      ...state,
      loadingCeuEventFile: false
    };
  }),
  on(deleteCeuEvent, (state, action) => {
    return {
      ...state,
      savingCeuEvent: true
    }
  }),
  on(deleteCeuEventSuccess, (state, action) => {
    return {
      ...state,
      savingCeuEvent: false
    }
  }),
  on(generateMissingCeuEventCertifications, (state, action) => {
    return {
      ...state,
      savingCeuEvent: true
    }
  }),
  on(generateMissingCeuEventCertificationsSuccess, (state, action) => {
    return {
      ...state,
      savingCeuEvent: false
    }
  }),
  on(generateMissingCeuEventCertificationsFail, (state, action) => {
    return {
      ...state,
      savingCeuEvent: false
    }
  }),
)

export function certificationReducerFunc(state: CertificationState | undefined, action: Action) {
  return certificationReducer(state, action);
}

export function initEditInstructorForm(instructor: CertificationInstructorModel) {
  if (instructor == null) {
    instructor = {} as CertificationReportModel;
  }
  if (typeof instructor.instructorId === 'undefined') { //initializing new instructor
    instructor.instructorId = 0;
    instructor.archived = false;
  }

  const form = new FormGroup({
    instructorId: new FormControl(instructor.instructorId),
    userId: new FormControl(instructor.userId, [Validators.required]),
    certificationTypeId: new FormControl(instructor.certificationTypeId, [Validators.required]),    
    archived: new FormControl(instructor.archived),
  });

  return form
}

export function initEditCertificationForm(certification: CertificationReportModel) {
  if (certification == null) {
    certification = {} as CertificationReportModel;
  }
  if (typeof certification.certificationId === 'undefined') { //initializing new certification
    certification.certificationId = 0;
  }
  else {
    //can not edit existing since we need to generate a new PDF everytime. They can renew a certification => start day after expiration
    certification.issueDate = new Date(certification.expirationDate);
    certification.issueDate.setDate(certification.issueDate.getDate() + 1);
    certification.expirationDate = null;
  }

  const form = new FormGroup({
    certificationId: new FormControl(certification.certificationId),
    certificationTypeId: new FormControl(certification.certificationTypeId, [Validators.required]),
    issueDate: new FormControl(certification.issueDate),
    expirationDate: new FormControl(certification.expirationDate),
    issueDateString: new FormControl(typeof certification.issueDate !== 'undefined' && certification.issueDate !== null ? formatDate(certification.issueDate, 'yyyy-MM-dd', 'en-US') : '', [Validators.required]), //add pattern
    expirationDateString: new FormControl(typeof certification.expirationDate !== 'undefined' && certification.expirationDate !== null ? formatDate(certification.expirationDate, 'yyyy-MM-dd', 'en-US') : '', [Validators.required]), //add pattern
    userId: new FormControl(certification.userId, [Validators.required]),
    instructorId: new FormControl(certification.instructorId, [Validators.required]),
  });

  return form
}
