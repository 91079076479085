import { downloadFile } from 'src/app/helpers/utils';
import { GeneralSettingsModel } from 'src/app/models/generalSettingsModel';
import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError
} from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from 'src/app/core/store';
import { getClientFromUrl } from './helpers/utils';
import { getFeatureSwitches, getGeneralSettings, logNavigation } from './core/store/account/account.actions';

declare let ga: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'HR Portal';
  showTopbar = true;
  isIframeInOpto = false;
  generalSettings: GeneralSettingsModel;
  constructor(private router: Router, private store: Store<State>) {
    ga('create', environment.googleUAID, 'auto');
    const clientFromUrl = getClientFromUrl();
    ga('set', 'app_name', 'HR Portal');
    ga('set', 'client', clientFromUrl);
    ga('set', 'page_type', 'Angular');

    this.store.select(s => s.accountState.generalSettings).subscribe(generalSettings => {
      if (generalSettings) {
        this.title = generalSettings.businessName + ' HR Portal';
        this.generalSettings = generalSettings;
      }
    });
    this.store.dispatch(getGeneralSettings());
    this.store.dispatch(getFeatureSwitches());
    // this.store.dispatch(startSessionTimer());

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        let urlTree = this.router.parseUrl(event.urlAfterRedirects);
        let parameters = urlTree.queryParams;
        urlTree.queryParams = {};
        let cleanUrl = urlTree.toString();

        this.store.dispatch(logNavigation({ Url: cleanUrl, parameters: JSON.stringify(parameters) }))

        ga('set', 'page', cleanUrl);
        ga('send', 'pageview');

        if (event.urlAfterRedirects.indexOf('prospective-candidate/survey') >= 0
          || event.urlAfterRedirects.indexOf('application/utbs') >= 0 || event.urlAfterRedirects.indexOf('application/utbs1') >= 0
          || event.urlAfterRedirects.indexOf('app-application-success-page') >= 0
        ) {
          this.showTopbar = false;
        } else {
          this.showTopbar = true;
        }
        if (event.urlAfterRedirects.indexOf('criticalnewsfeed') >= 0) {
          this.isIframeInOpto = true;
        } else {
          this.isIframeInOpto = false;
        }
        (window as any).NavigationRouterState = 'NavigationEnd: ' + cleanUrl;
      } else if (event instanceof NavigationStart) {
        (window as any).NavigationRouterState = 'NavigationStart';
      }
      else if (event instanceof NavigationCancel) {
        (window as any).NavigationRouterState = 'NavigationCancel';
      }
      else if (event instanceof NavigationError) {
        (window as any).NavigationRouterState = 'NavigationError';
      }
      console.debug((window as any).NavigationRouterState);
    });
  }
}
