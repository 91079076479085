<form class="card mt-2" [formGroup]="form" *ngIf="canAddCoach && improvementPlanReview && improvementPlan">
  <div class="card-body">
    <h4 class="mb-3">{{improvementPlanReview.id?'Edit':'Create'}} Improvement Plan Review</h4>
    <div class="row">
      <div class="col-12 col-lg-8 ">

        <div class="row" formGroupName="improvementPlanReview" >
          <div class="col-4 d-flex align-items-center">
            <b>Review Notes</b>
          </div>
          <div class="col-8">
            <textarea  class="form-control mt-2" formControlName="reviewNotes"></textarea>
            <app-validation-message [form]="form" [path]="'improvementPlanReview.reviewNotes'" [name]="'reviewNotes'" [label]="'Review Notes'"> </app-validation-message>
          </div>
        </div>

        <div class="row" formGroupName="improvementPlanReview" >
          <div class="col-4 d-flex align-items-center">
            <b>Data Included</b>
          </div>
          <div class="col-8">
            <textarea  class="form-control mt-2" formControlName="dataIncluded"></textarea>
            <app-validation-message [form]="form" [path]="'improvementPlanReview.dataIncluded'" [name]="'dataIncluded'" [label]="'Data Included'"> </app-validation-message>
          </div>
        </div>

        <div class="row"   formGroupName="improvementPlanReview">
          <div class="col-4 d-flex align-items-center">
            <b>Next Review Date</b>
          </div>
          <div class="col-8">
              <input type="date" class="form-control mt-2" formControlName="nextReviewDate"/>
            <app-validation-message [form]="form" [path]="'improvementPlanReview.nextReviewDate'" [name]="'nextReviewDate'" [label]="'Next Review Date'"> </app-validation-message>
          </div>
        </div>

        <div class="row"   formGroupName="improvementPlanReview">
          <div class="col-4 d-flex align-items-center">
            <b>Plan finished with success?</b>
          </div>
          <div class="col-8 pt-3">
            <p-selectButton [options]="yesNoOptions"  formControlName="success"></p-selectButton>
          </div>
        </div>

      </div>
    </div>

    <div class="row mt-4">
      <div class="col-6">
        <i *ngIf="creatingImprovementPlanReview" class="material-icons rotate text-muted">loop</i>
        <button *ngIf="!creatingImprovementPlanReview" class="btn btn-secondary mr-3" [routerLink]="['/improvementplan/view/' + improvementPlanID]">Back</button>
        <button *ngIf="!creatingImprovementPlanReview" class="btn btn-primary" [class.disabled]="form.invalid" (click)="save()">{{improvementPlanReview.id?'Save':'Create'}}</button>
      </div>
    </div>
  </div>
</form>
<div class="card mt-2" [formGroup]="form" *ngIf="improvementPlan">
  <div class="card-body">
    <h4 class="mb-3">Improvement Plan</h4>
    <div class="row">
      <div class="col-12 col-lg-12 ">
        <div class="row"  >
          <div class="col-3 ">
            <b>User</b><br/> {{improvementPlan.user.firstName+' '+improvementPlan.user.lastName}}
          </div>
          <div class="col-3 ">
            <b>Supervisor</b><br/> {{improvementPlan.supervisorUser.firstName+' '+improvementPlan.supervisorUser.lastName}}
          </div>
          <div class="col-3 ">
            <b>Region</b><br/> {{campusesHash[improvementPlan.user.primaryProgramID]}}
          </div>
          <div class="col-3 ">
            <b>Next Review Date</b><br/> {{getFormatedDate(improvementPlan.nextReviewDate)}}
          </div>
        </div>


        <div class="row mt-3" >
          <div class="col-4 ">
            <b>Benchmark</b><br/> {{improvementPlan.benchmark}}
          </div>
          <div class="col-4 ">
            <b>Performance Concern</b><br/> {{improvementPlan.performanceConcern}}
          </div>
          <div class="col-4 ">
            <b>Expected Standard</b><br/> {{improvementPlan.expectedStandard}}
          </div>
        </div>


        <div class="row"  >
          <div class="col-4">
            <b>Measured By</b><br/> {{improvementPlan.measuredBy}}
          </div>
          <div class="col-4">
            <b>Improvement Actions</b><br/> {{improvementPlan.improvementActions}}
          </div>
          <div class="col-4 ">
            <b>Support</b><br/> {{improvementPlan.support}}
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-12 ">
            <b>Additional Info:</b> {{improvementPlan.additionalInfo}}
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-12 ">
            <b>Signed by Employee:</b> {{getFormatedDate(improvementPlan.signedDateEmployee)}}
          </div>
        </div>

        <div class="row ">
          <div class="col-12 ">
            <b>Signed by Supervisor:</b> {{getFormatedDate(improvementPlan.signedDateSupervisor)}}
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
