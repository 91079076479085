import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { InternshipSubstatusModel } from '../../../../models/internModels';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { Actions, ofType } from '@ngrx/effects';
import { State } from '../../../../core/store';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { AlertService } from '../../../../shared/services/alert.service';
import { deepClone } from '../../../../helpers/utils';
import { deleteInternshipSubstatus, deleteInternshipSubstatusSuccess, getInternshipSubstatusList, loadInternshipStatuses, saveInternshipSubstatusSuccess, showEditInternshipSubstatus } from '../../../../core/store/intern/intern.action';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-list-intern-substatus',
  templateUrl: './list-intern-substatus.component.html',
  styleUrls: ['./list-intern-substatus.component.scss']
})
export class ListInternSubstatusComponent extends BaseComponent implements OnInit {
  list: PagingResultsModel<InternshipSubstatusModel>;
  filter: any = {};
  loadingList: boolean = true;
  loadingExcel: boolean = false;
  savingItem: boolean = false;

  canManage;

  primaryStatusList: SelectItem[];
  primaryStatusId: number = 0;


  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private alertService: AlertService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.subs.push(
      this.store.select(s => s.accountState.user.permissionIDs).subscribe(permissionIDs => {
        this.canManage = permissionIDs && !!permissionIDs.find(p => p === 3128); //Manage Intern Applications
      }),
      this.store.select(s => s.internState.loadingSubstatusList).subscribe(loading => {
        this.loadingList = loading;
      }),
      this.store.select(s => s.internState.subStatusList).subscribe(report => {
        this.list = report;
      }),
      this.store.select(s => s.internState.savingSubstatus).subscribe(saving => {
        this.savingItem = saving;
      }),
      this.store.select(s => s.internState.internshipStatuses).subscribe(items => {
        if (items && items.length > 0) {
          this.primaryStatusList = items.map(x => ({ label: x.label, value: x.value }));
          this.primaryStatusList.unshift({ label: 'All', value: 0 });
        }
        else {
          this.primaryStatusList = [{ label: 'loading...', value: undefined }]
        }
      }),
      this.actions$.pipe(
        ofType(saveInternshipSubstatusSuccess),
        tap(action => {
          this.alertService.success("Success");
          this.reloadList();
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(deleteInternshipSubstatusSuccess),
        tap(action => {
          this.alertService.success("Success");
          this.reloadList();
        })
      ).subscribe(),
    );

    this.store.dispatch(loadInternshipStatuses());
  }

  reloadFilter(event) {
    this.filter = event;
    this.reloadList();
  }

  reloadList() {
    this.setFilter();

    this.store.dispatch(getInternshipSubstatusList({
      filter: this.filter
    }));
  }

  setFilter() {
    this.filter = deepClone(this.filter);

    this.filter.filters.primaryStatusId = { Name: 'primaryStatusId', Value: { value: this.primaryStatusId.toString() } };
  }

  editSubstatus(item?: InternshipSubstatusModel) {
    const substatusId = typeof item !== 'undefined' && typeof item.id !== 'undefined' ? item.id : 0;
    this.store.dispatch(showEditInternshipSubstatus({ substatusId: substatusId }));
  }

  deleteSubstatus(list: InternshipSubstatusModel) {
    this.alertService.confirm('Delete Substatus',
      'Are you sure you want to delete this substatus? The substatus will be archived if it has already been assigned to an application.')
      .subscribe(
        answer => {
          if (answer) {
            this.store.dispatch(deleteInternshipSubstatus({ substatusId: list.id }));
          }
        }, error => {
        }
      );
  }

}
