import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CertificationService } from '../../services/certification.service';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { errorHappened } from '../profile/profile.actions';
import { deleteCertification, deleteCertificationSuccess, deleteCeuEvent, deleteCeuEventSuccess, deleteInstructor, deleteInstructorSuccess, exportCertificationInstructorListToExcel, exportCertificationInstructorListToExcelFailure, exportCertificationInstructorListToExcelSuccess, exportCertificationListToExcel, exportCertificationListToExcelFailure, exportCertificationListToExcelSuccess, exportCeuEventListToExcel, exportCeuEventListToExcelFailure, exportCeuEventListToExcelSuccess, generateMissingCeuEventCertifications, generateMissingCeuEventCertificationsFail, generateMissingCeuEventCertificationsSuccess, getCertificationInstructorList, getCertificationInstructorListSuccess, getCertificationList, getCertificationListSuccess, getCertificationPdf, getCertificationPdfFailure, getCertificationPdfSuccess, getCeuEvent, getCeuEventFailure, getCeuEventFile, getCeuEventFileFailure, getCeuEventFileSuccess, getCeuEventList, getCeuEventListSuccess, getCeuEventSuccess, loadCertificationTypes, loadCertificationTypesSuccess, loadCertificationUploads, loadCertificationUploadsFailure, loadCertificationUploadsSuccess, loadCeuCategories, loadCeuCategoriesSuccess, loadCeuTypes, loadCeuTypesSuccess, loadInstructors, loadInstructorsSuccess, saveCertification, saveCertificationFail, saveCertificationSuccess, saveCeuEvent, saveCeuEventFail, saveCeuEventSuccess, saveInstructor, saveInstructorFail, saveInstructorSuccess } from './certification.action';
import { CertificationReportModel, CeuEventModel } from '../../../models/certificationModel';
import { Store } from '@ngrx/store';
import { State } from '..';
import { downloadFile } from '../../../helpers/utils';

@Injectable()
export class CertificationEffects {
  constructor(private actions$: Actions,
    private certificationService: CertificationService,
    private store: Store<State>
  ) { }

  loadCertificationTypes = createEffect(() => this.actions$.pipe(
    ofType(loadCertificationTypes),
    switchMap(action => this.certificationService.getCertificationTypes().pipe(
      map(certificationTypes => loadCertificationTypesSuccess({ certificationTypes })),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))));

  loadCeuCategories = createEffect(() => this.actions$.pipe(
    ofType(loadCeuCategories),
    switchMap(action => this.certificationService.getCeuCategories().pipe(
      map(ceuCategories => loadCeuCategoriesSuccess({ ceuCategories })),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))));

  loadCeuTypes = createEffect(() => this.actions$.pipe(
    ofType(loadCeuTypes),
    switchMap(action => this.certificationService.getCeuTypes().pipe(
      map(ceuTypes => loadCeuTypesSuccess({ ceuTypes })),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))));

  loadInstructors = createEffect(() => this.actions$.pipe(
    ofType(loadInstructors),
    switchMap(action => this.certificationService.getInstructorList(action.prefixText, action.certificationTypeId, action.showArchived, action.showTerminated, action.showInactive).pipe(
      map(instructors => loadInstructorsSuccess({ instructors })),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))));

  getCertificationInstructorList = createEffect(() => this.actions$.pipe(
    ofType(getCertificationInstructorList),
    switchMap(action => {
      return this.certificationService.getCertificationInstructorList(action.filter).pipe(
        map(list => getCertificationInstructorListSuccess({ list })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  getCertificationList = createEffect(() => this.actions$.pipe(
    ofType(getCertificationList),
    switchMap(action => {
      return this.certificationService.getCertificationList(action.filter).pipe(
        map(list => getCertificationListSuccess({ list })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  saveInstructor = createEffect(() => this.actions$.pipe(
    ofType(saveInstructor),
    switchMap(action => {
      let observable: Observable<CertificationReportModel>;

      if (action.instructor.instructorId > 0) {
        observable = this.certificationService.editInstructor(action.instructor);
      }
      else {
        observable = this.certificationService.addInstructor(action.instructor);
      }

      return observable.pipe(
        map(instructor => saveInstructorSuccess()),
        catchError(err => {
          this.store.dispatch(saveInstructorFail({ err }));
          return of(errorHappened({ err }));
        }));
    })));

  deleteInstructor = createEffect(() => this.actions$.pipe(
    ofType(deleteInstructor),
    switchMap(action =>
      this.certificationService.deleteInstructor(action.instructorId).pipe(
        map(certification => deleteInstructorSuccess()),
        catchError(err => {
          return of(errorHappened({ err }));
        }))
    )));

  saveCertification = createEffect(() => this.actions$.pipe(
    ofType(saveCertification),
    switchMap(action =>
       this.certificationService.addCertification(action.certification).pipe(
        map(certification => saveCertificationSuccess()),
         catchError(err => {
           this.store.dispatch(saveCertificationFail({ err }));
           return of(errorHappened({ err }));
         }))
    )));

  deleteCertification = createEffect(() => this.actions$.pipe(
    ofType(deleteCertification),
    switchMap(action =>
      this.certificationService.deleteCertification(action.certificationId).pipe(
        map(certification => deleteCertificationSuccess()),
        catchError(err => {
          return of(errorHappened({ err }));
        }))
    )));

  getCertificationPdf = createEffect(() => this.actions$.pipe(
    ofType(getCertificationPdf),
    switchMap(action => {
      return this.certificationService.getCertificationPdf(action.certificationId).pipe(
        map(doc => getCertificationPdfSuccess({ doc })),
        catchError(err => {
          this.store.dispatch(getCertificationPdfFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  getCertificationPdfSuccess = createEffect(() => this.actions$.pipe(
    ofType(getCertificationPdfSuccess),
    tap(action => {
      downloadFile(action.doc, 'Certification.pdf');
    })
  ), { dispatch: false });

  exportCertificationListToExcel = createEffect(() => this.actions$.pipe(
    ofType(exportCertificationListToExcel),
    switchMap(action => {
      return this.certificationService.exportCertificationListToExcel(action.filter).pipe(
        map(doc => exportCertificationListToExcelSuccess({ doc })),
        catchError(err => {
          this.store.dispatch(exportCertificationListToExcelFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  exportCertificationInstructorListToExcel = createEffect(() => this.actions$.pipe(
    ofType(exportCertificationInstructorListToExcel),
    switchMap(action => {
      return this.certificationService.exportCertificationInstructorListToExcel(action.filter).pipe(
        map(doc => exportCertificationInstructorListToExcelSuccess({ doc })),
        catchError(err => {
          this.store.dispatch(exportCertificationInstructorListToExcelFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  loadCertificationUploads = createEffect(() => this.actions$.pipe(
    ofType(loadCertificationUploads),
    switchMap(action => {
      return this.certificationService.getUploadedFiles(action.userId).pipe(
        map(uploadedFiles => loadCertificationUploadsSuccess({ uploadedFiles })),
        catchError(err => {
          this.store.dispatch(loadCertificationUploadsFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  getCeuEventList = createEffect(() => this.actions$.pipe(
    ofType(getCeuEventList),
    switchMap(action => {
      return this.certificationService.getCeuEventList(action.filter).pipe(
        map(list => getCeuEventListSuccess({ list })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  exportCeuEventListToExcel = createEffect(() => this.actions$.pipe(
    ofType(exportCeuEventListToExcel),
    switchMap(action => {
      return this.certificationService.exportCeuEventListToExcel(action.filter).pipe(
        map(doc => exportCeuEventListToExcelSuccess({ doc })),
        catchError(err => {
          this.store.dispatch(exportCeuEventListToExcelFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  saveCeuEvent = createEffect(() => this.actions$.pipe(
    ofType(saveCeuEvent),
    switchMap(action => {
      let observable: Observable<CeuEventModel>;

      if (action.ceuEvent.ceuEventId > 0) {
        observable = this.certificationService.editCeuEvent(action.ceuEvent);
      }
      else {
        observable = this.certificationService.addCeuEvent(action.ceuEvent);
      }

      return observable.pipe(
        map(ceuEvent => saveCeuEventSuccess()),
        catchError(err => {
          this.store.dispatch(saveCeuEventFail({ err }));
          return of(errorHappened({ err }));
        }));
    })));

  getCeuEvent = createEffect(() => this.actions$.pipe(
    ofType(getCeuEvent),
    switchMap(action => {
      return this.certificationService.getCeuEvent(action.ceuEventId).pipe(
        map(event => getCeuEventSuccess({ event })),
        catchError(err => {
          this.store.dispatch(getCeuEventFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  getCeuEventFile = createEffect(() => this.actions$.pipe(
    ofType(getCeuEventFile),
    switchMap(action => {
      return this.certificationService.getCeuEventFile(action.uploadId).pipe(
        map(doc => getCeuEventFileSuccess({ doc })),
        catchError(err => {
          this.store.dispatch(getCeuEventFileFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  deleteCeuEvent = createEffect(() => this.actions$.pipe(
    ofType(deleteCeuEvent),
    switchMap(action =>
      this.certificationService.deleteCeuEvent(action.eventId).pipe(
        map(event => deleteCeuEventSuccess()),
        catchError(err => {
          return of(errorHappened({ err }));
        }))
    )));

  generateMissingCeuEventCertifications = createEffect(() => this.actions$.pipe(
    ofType(generateMissingCeuEventCertifications),
    switchMap(action =>
      this.certificationService.generateMissingCeuEventCertifications(action.eventId).pipe(
        map(event => generateMissingCeuEventCertificationsSuccess()),
        catchError(err => {
          this.store.dispatch(generateMissingCeuEventCertificationsFail(err));
          return of(errorHappened({ err }));
        }))
    )));

}
