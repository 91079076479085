import { finishFBFormSubmissionFormSuccess } from './../../../core/store/form-builder/form-builder.actions';
import { loadFormDoc, signFormSubmissionDocSuccess } from 'src/app/core/store/form-builder/form-builder.actions';
import { GeneralSettingsModel } from 'src/app/models/generalSettingsModel';
import { saveProfileSuccess } from './../../../core/store/profile/profile.actions';
import { OnBoardingMessageModel } from './../../../models/onBoardingModels';
import { finishOnboardingSuccess, uploadSignedI9, uploadSignedI9Success, uploadSignedI9Failure, denyI9, setOrientationDate, rejectOrientationDate, sendOnBoardingMessage, sendOnBoardingMessageSuccess, sendOnBoardingMessageFailure, clearManageOnboardingUserInfo } from './../../../core/store/onboarding/onboarding.actions';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'src/app/core/store';
import { loadProfile, getPhoto } from 'src/app/core/store/profile/profile.actions';
import { UserProfileModel } from 'src/app/models/userProfileModel';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { Actions, ofType } from '@ngrx/effects';
import { AlertService } from 'src/app/shared/services/alert.service';
import { signOnboardingDocSuccess, setOnboardingDocsInfoFilledSuccess, loadOnboardingDoc } from 'src/app/core/store/onboardingdocs/onboardingdocs.actions';
import { tap } from 'rxjs/operators';
import { downloadFile, getOptoLinkFromUrl, getShortDateFormatted, markFormGroupTouched } from 'src/app/helpers/utils';
import { OnBoardingModel } from 'src/app/models/onBoardingModels';
import { approveI9, consentToProceedOnboarding, loadOnboardingInfo, consentToProceedOnboardingSuccess, loadManageOnboardingUserInfo, approveAlluploads, approveSignedDocs, finishOnboarding, saveUserProfileSuccess } from 'src/app/core/store/onboarding/onboarding.actions';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { formatDate } from "@angular/common";

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent extends BaseComponent implements OnInit {

  profile: UserProfileModel;
  profilePicture;
  step = 'start';
  onBoardingInfo: OnBoardingModel;
  managedUserId?: number;
  allDocsSigned = false;
  allUploaded = false;
  oversightEmployeeReviewFilled = false;
  hasSelectedi9Upload = false;
  uploadingI9 = false;
  i9DenyInfo = {} as any;
  orientationDate;
  isEmployeeManage = false;
  openedApprovedi9 = false;
  displayAskForHelp: boolean;
  canApproveI9Form: boolean;
  seeDocsOnI9: boolean;
  formAskHelp: FormGroup = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.maxLength(255)]),
    phone: new FormControl(null, [Validators.required, Validators.maxLength(255)]),
    preferedContact: new FormControl(null, [Validators.required]),
    message: new FormControl(null, [Validators.required, Validators.maxLength(4000)]),
  });

  emailPhoneOptions = [
    { label: 'Email', value: true },
    { label: 'Phone', value: false },
  ];
  generalSettings: GeneralSettingsModel;

  showi9sign = false;
  showi9signHR = false;

  constructor(
    private actions$: Actions,
    private alertService: AlertService,
    private store: Store<State>,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit() {
    //this.store.dispatch(clearManageOnboardingUserInfo());
    if (this.router.url.indexOf('onboardingdocs') >= 0) {
      this.step = 'sign';
    }

    if (this.router.url.indexOf('employee-file/') >= 0) {
      this.isEmployeeManage = true;
      this.step = 'upload';
    }

    this.subs.push(
      this.store.select(s => s.accountState.generalSettings).subscribe(generalSettings => {
        if (generalSettings) {
          this.generalSettings = generalSettings;
        }
      }),
    );

    if (this.route.snapshot.params.userid) {
      this.managedUserId = this.route.snapshot.params.userid;
      this.subs.push(
        this.store.select(s => s.onboardingState.manageOnboardingUserInfo).subscribe(manageOnboardingUserInfo => {
          if (manageOnboardingUserInfo) {
            this.profile = manageOnboardingUserInfo.user;
            if (manageOnboardingUserInfo.user && manageOnboardingUserInfo.employee) {
              this.orientationDate = manageOnboardingUserInfo.employee.orientationDate ? new Date(manageOnboardingUserInfo.employee.orientationDate).toISOString().substr(0, 10) : null;
            }
            this.onBoardingInfo = manageOnboardingUserInfo.onboarding;
            this.allDocsSigned = manageOnboardingUserInfo.allDocsSigned;
            this.allUploaded = manageOnboardingUserInfo.allUploaded;
            this.oversightEmployeeReviewFilled = manageOnboardingUserInfo.oversightEmployeeReviewFilled;
          }
        }),

        this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
          this.canApproveI9Form = permissionIDs && !!permissionIDs.find(p => p === 3067);
        }),
        this.actions$.pipe(
          ofType(finishFBFormSubmissionFormSuccess),
          tap(action => {
            this.showi9signHR = true;
            this.store.dispatch(loadManageOnboardingUserInfo({ userId: this.managedUserId }));

          })
        ).subscribe(),

        this.actions$.pipe(
          ofType(finishFBFormSubmissionFormSuccess),
          tap(action => {
            this.openedApprovedi9 = false;
            this.store.dispatch(loadManageOnboardingUserInfo({ userId: this.managedUserId }));

          })
        ).subscribe(),
        this.actions$.pipe(
          ofType(saveUserProfileSuccess),
          tap(action => {
            this.store.dispatch(loadManageOnboardingUserInfo({ userId: this.managedUserId }));
          })
        ).subscribe(),
      );

      this.store.dispatch(loadManageOnboardingUserInfo({ userId: this.route.snapshot.params.userid }));
    } else {
      this.subs.push(
        this.store.select(s => s.profileState.profile).subscribe(profile => {
          this.profile = profile;
          if (profile) {
            this.allDocsSigned = profile.allDocsSigned;
            this.allUploaded = profile.allUploaded;
          }

          if (this.profile && !this.profile.isOnBoarding) {
            this.router.navigate(['/']);
          }

          if (this.profile && !this.profile.offerLetter.signed) {
            this.router.navigate(['/offerletter']);
          }
        }),

        this.store.select(s => s.onboardingState.onBoardingInfo).subscribe(onBoardingInfo => {
          this.onBoardingInfo = onBoardingInfo;

          if (this.onBoardingInfo && this.step === 'start') {
            if (!onBoardingInfo.consentDate) {
              this.step = 'consent';
            } else {
              if (!onBoardingInfo.uploadedDocsApprovedDate) {
                this.step = 'upload';
              } else {
                if (!onBoardingInfo.i9ApprovedDate) {
                  this.step = 'i9Form';
                } else {
                  if (!onBoardingInfo.employeeInfoFilled) {
                    this.step = 'employee info';
                  } else {
                    if (!onBoardingInfo.allDocumentsSignedApprovedDate) {
                      this.step = 'sign';
                    } else {
                      this.step = 'summary';
                    }
                  }
                }
              }
            }
          }
        }),

        this.actions$.pipe(
          ofType(finishFBFormSubmissionFormSuccess, signFormSubmissionDocSuccess),
          tap(action => {
            this.showi9sign = true;

            this.store.dispatch(loadProfile());

          })
        ).subscribe(),
        this.actions$.pipe(
          ofType(consentToProceedOnboardingSuccess),
          tap(action => {
            this.step = 'upload';
          })
        ).subscribe(),

        this.actions$.pipe(
          ofType(finishOnboardingSuccess),
          tap(action => {
            this.store.dispatch(loadProfile());
          })
        ).subscribe(),

        this.actions$.pipe(
          ofType(saveProfileSuccess),
          tap(action => {
            this.store.dispatch(loadProfile());
            this.store.dispatch(loadOnboardingInfo());
          })
        ).subscribe(),

        this.actions$.pipe(
          ofType(uploadSignedI9Success, uploadSignedI9Failure),
          tap(action => {
            this.uploadingI9 = false;
            this.hasSelectedi9Upload = false;
            this.store.dispatch(loadProfile());

          })
        ).subscribe(),


        this.actions$.pipe(
          ofType(sendOnBoardingMessageSuccess),
          tap(action => {
            this.displayAskForHelp = false;
          })
        ).subscribe(),
      );

      this.store.dispatch(loadOnboardingInfo());
      this.store.dispatch(loadProfile());
    }


  }

  openStep(step) {
    if (this.managedUserId || step === 'consent' || this.onBoardingInfo.consentDate) {
      this.step = step;
    }
  }

  openLink(path) {
    this.router.navigate([path]);
  }

  proceedConsent() {
    this.store.dispatch(consentToProceedOnboarding({ onBoardingInfo: this.onBoardingInfo }));
  }

  proceedUpload() {
    if (this.isEmployeeManage) {
      this.step = 'employee info';
    } else {
      this.step = 'i9Form';
    }
  }
  proceedi9Form() {
    this.step = 'employee info';
  }
  proceedEmployeeInfo() {
    if (this.onBoardingInfo.employeeInfoFilled) {
      this.openSign();
    }
  }

  openSign() {
    if (this.managedUserId || this.onBoardingInfo.consentDate) {
      this.step = 'sign';
      this.router.navigate(['onboardingdocs/list'], { relativeTo: this.route });
    }
  }

  proceedSign() {
    this.step = 'summary';
  }


  setOrientationDate() {
    this.store.dispatch(setOrientationDate({ userId: this.managedUserId, orientationDate: this.orientationDate }));
  }

  rejectOrientationDate() {
    this.store.dispatch(rejectOrientationDate());
  }

  finish() {
    this.store.dispatch(finishOnboarding());
  }

  downloadI9Form() {
    this.store.dispatch(loadFormDoc({ formSubmissionID: this.profile.i9FormSubmissionId }));
    const subDocBuffer = this.store.select(s => s.formBuilderState.docArrayBuffer).subscribe(docArrayBuffer => {
      if (docArrayBuffer) {
        downloadFile(new Blob([docArrayBuffer], { type: 'application/pdf' }), 'I-9Form.pdf');
        subDocBuffer.unsubscribe();
      }
    });
  }

  checkEnableFinish() {
    return (this.onBoardingInfo && this.onBoardingInfo.allDocumentsSignedApprovedDate
      && this.onBoardingInfo.uploadedDocsApprovedDate
      && this.onBoardingInfo.i9ApprovedDate
      && this.onBoardingInfo.consentDate
      && this.onBoardingInfo.employeeInfoFilled)
      && this.orientationDate;
  }

  allDoneByUser() {
    return (this.onBoardingInfo && this.allDocsSigned
      && this.allUploaded
      && this.profile.i9FormSignedDate
      && this.onBoardingInfo.consentDate
      && this.onBoardingInfo.employeeInfoFilled);
  }

  optomiserUrl() {
    return getOptoLinkFromUrl() + '/Administration/ManageUsers/Edit/' + this.managedUserId;
  }

  approveAllUploads() {
    this.alertService.confirm('Are you sure?',
      'By finish approving uploads, all uploads of this user will be approved. Are you sure?')
      .subscribe(
        anwser => {
          if (anwser) {
            this.store.dispatch(approveAlluploads({ userId: this.managedUserId }));
          }
        }, error => {
        }
      );
  }

  approvei9() {
    if (this.canApproveI9Form) {
      this.openedApprovedi9 = true;
    }
  }

  denyI9() {
    this.store.dispatch(denyI9({ userId: this.managedUserId, denyMessage: this.i9DenyInfo.denyMessageToSend }));
    this.i9DenyInfo = {};
  }

  openCloseI9DenyMessage() {
    if (this.canApproveI9Form) {
      this.i9DenyInfo.denyMessageOpen = !this.i9DenyInfo.denyMessageOpen;
    }
  }

  approveSignedDocs() {
    this.store.dispatch(approveSignedDocs({ userId: this.managedUserId }));
  }
  backtoOnboardingEmployees() {
    this.router.navigate(['/onboarding-manage/list']);
  }


  changedi9UploadFile(fileInput) {
    this.hasSelectedi9Upload = fileInput.files.length > 0;
  }

  uploadi9File(fileInput) {
    if (fileInput.files) {
      this.uploadingI9 = true;
      const file = fileInput.files[0];

      this.store.dispatch(uploadSignedI9({ formSubmissionID: this.profile.i9FormSubmissionId, file }));
    }
  }

  getShortDateFormatted(date) {
    return getShortDateFormatted(new Date(date));
  }

  viewI9Doc() {
    this.store.dispatch(loadFormDoc({ formSubmissionID: this.profile.i9FormSubmissionId }));
    const subDocBuffer = this.store.select(s => s.formBuilderState.docArrayBuffer).subscribe(docArrayBuffer => {
      if (docArrayBuffer) {
        downloadFile(new Blob([docArrayBuffer], { type: 'application/pdf' }), 'i9Form.pdf');
        subDocBuffer.unsubscribe();
      }
    });
  }

  askHelp() {
    const keepPreferedContact = this.formAskHelp.get('preferedContact').value;
    this.formAskHelp.reset();
    this.formAskHelp.get('email').setValue(this.profile.user.email);
    this.formAskHelp.get('phone').setValue(this.profile.user.phoneNumber);
    this.formAskHelp.get('preferedContact').setValue(keepPreferedContact);
    this.formAskHelp.get('message').setValue('');

    this.displayAskForHelp = true;
  }

  sendAskForHelp() {
    if (this.formAskHelp.valid) {
      const onBoardingMessage: OnBoardingMessageModel = {
        onboardingId: this.onBoardingInfo.id,
        phone: this.formAskHelp.get('phone').value,
        email: this.formAskHelp.get('email').value,
        message: this.formAskHelp.get('message').value,
        preferedContact: this.formAskHelp.get('preferedContact').value,
      };
      this.store.dispatch(sendOnBoardingMessage({ onBoardingMessage }));
    } else {
      markFormGroupTouched(this.formAskHelp);
    }
  }

  minDate() {
    const format = 'yyyy-MM-dd'; //dd/MM/yyyy';
    const myDate = new Date();
    const locale = 'en-US';
    return formatDate(myDate, format, locale);
  }
}
