import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'src/app/core/store';
import { loadProfile } from 'src/app/core/store/profile/profile.actions';
import { UserProfileModel } from 'src/app/models/userProfileModel';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';

@Component({
  selector: 'app-uploaddocs',
  templateUrl: './uploaddocs.component.html',
  styleUrls: ['./uploaddocs.component.scss']
})
export class UploadDocsComponent extends BaseComponent implements OnInit {

  profile: UserProfileModel;

  constructor(
    private store: Store<State>,
  ) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.profileState.profile).subscribe(profile => {
        this.profile = profile;
      }),
    );

    this.store.dispatch(loadProfile());
  }

}
