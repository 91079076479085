<form class="card mt-2">
  <div class="card-body">
    <div class="float-right">
      <button *ngIf="canViewSchedulingTimeOff" type="button" routerLink="/timeoff/scheduling/manage" class="btn btn-secondary" style="margin-right: 5px;">Manage Time Off</button>
      <button type="button" routerLink="/timeoff/pto/manage" class="btn btn-secondary" >Manage PTO</button>
    </div>
    <h2 class="mb-3">PTO Summary Report</h2>
    <div class="mb-3">

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Campus:</h5>
        </div>
        <div class="col-10">
          <i *ngIf="!campusList" class="material-icons rotate text-muted">loop</i>
          <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="campusList" [(ngModel)]="campusId" (ngModelChange)="reloadSummaries(this.filter)" [ngModelOptions]="{standalone: true}"></p-dropdown>
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Classification:</h5>
        </div>
        <div class="col-10">
          <i *ngIf="!classificationList" class="material-icons rotate text-muted">loop</i>
          <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="classificationList" [(ngModel)]="payrollClassificationId" (ngModelChange)="reloadSummaries(this.filter)" [ngModelOptions]="{standalone: true}"></p-dropdown>
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Employee:</h5>
        </div>
        <div class="col-10">          
          <!--<p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="userList" [(ngModel)]="userId" (ngModelChange)="reloadSummaries(this.filter)" [ngModelOptions]="{standalone: true}"></p-dropdown>-->
          <div class="ml-2 w-100 mb-2">
            <p-autoComplete  [style]="{'width': '100%'}" [inputStyle]="{'width': '100%'}" [(ngModel)]="prefixText"
                            [suggestions]="userList" (completeMethod)="getStaffList($event)" field="label"
                            [ngModelOptions]="{standalone: true}" [minLength]="1" [autoHighlight]="true"
                            (onSelect)="reloadSummaryAfterStaffChage($event)" [forceSelection]="true"
                            (onClear)="reloadSummaryAfterClear($event)"></p-autoComplete>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Show only active users:</h5>
        </div>
        <div class="col-10" style="padding-left: 25px;">
          <p-checkbox [(ngModel)]="showOnlyActive" (ngModelChange)="reloadSummaries(this.filter)" [binary]="true" [ngModelOptions]="{standalone: true}"></p-checkbox>
        </div>
      </div>

    </div>

    <i [hidden]="!loadingSummaryList" class="material-icons rotate text-muted">loop</i>
    <p-table [value]="summaries?.result" [responsive]="true" [paginator]="true" [rows]="20"
             [totalRecords]="summaries?.totalRecords" [lazy]="true" (onLazyLoad)="reloadSummaries($event)">
      <ng-template pTemplate="header">
        <tr>
          <th [pSortableColumn]="'PayrollEmployeeNumber'">Payroll Employee Number<p-sortIcon [field]="'PayrollEmployeeNumber'"></p-sortIcon></th>
          <th [pSortableColumn]="'EmployeeName'">Employee<p-sortIcon [field]="'EmployeeName'"></p-sortIcon></th>
          <th [pSortableColumn]="'TotalEarned'">Total Earned<p-sortIcon [field]="'TotalEarned'"></p-sortIcon></th>
          <th [pSortableColumn]="'TotalUsed'">Total Used<p-sortIcon [field]="'TotalUsed'"></p-sortIcon></th>
          <th [pSortableColumn]="'TotalNet'">Total Net<p-sortIcon [field]="'TotalNet'"></p-sortIcon></th>
          <th [pSortableColumn]="'TotalPending'">Total Pending<p-sortIcon [field]="'TotalPending'"></p-sortIcon></th>
          <th [pSortableColumn]="'TotalAvailable'">Total Available<p-sortIcon [field]="'TotalAvailable'"></p-sortIcon></th>
          <th [pSortableColumn]="'TotalYtd'">Total Ytd<p-sortIcon [field]="'TotalYtd'"></p-sortIcon></th>
          <th [pSortableColumn]="'CurrentPercentage'">Current Percentage<p-sortIcon [field]="'CurrentPercentage'"></p-sortIcon></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-summary>
        <tr>
          <td>
            <span class="ui-column-title">Payroll Employee Number</span>
            {{summary.payrollEmployeeNumber}}
          </td>
          <td>
            <span class="ui-column-title">Employee Name</span>
            <a href="/timeoff/pto/my/{{summary.userId}}" target="_blank">{{summary.employeeName}}</a>
          </td>
          <td>
            <span class="ui-column-title">Total Earned</span>
            {{summary.totalEarnedString}}
          </td>
          <td>
            <span class="ui-column-title">Total Used</span>
            {{summary.totalUsedString}}
          </td>
          <td>
            <span class="ui-column-title">Total Net</span>
            {{summary.totalNetString}}
          </td>
          <td>
            <span class="ui-column-title">Total Pending</span>
            {{summary.totalPendingString}}
          </td>
          <td>
            <span class="ui-column-title">Total Earned</span>
            {{summary.totalAvailableString}}
          </td>
          <td>
            <span class="ui-column-title">Total Ytd</span>
            {{summary.totalYtdString}}
          </td>
          <td>
            <span class="ui-column-title">Current Percentage</span>
            {{summary.currentPercentageString}}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</form>
