<form class="card mt-2" *ngIf="canView">
  <div class="card-body">
      <button *ngIf="canEdit || canEditBehaviorTechs || canEditBehaviorTechsAA" class="btn btn-primary float-right pointer" routerLink="/offerletter/create">Create Offer Letter</button>
      <h2 class="mb-3">Offer Letters</h2>

      <h5>Filter</h5>
      <div class="row mb-1" >
        <div class="col-6">
          <div class="row mb-1" >
            <div class="col-4 d-flex align-items-center">
              <h6>Employee Name:</h6>
            </div>
            <div class="col-8 ">
              <input type="text" [(ngModel)]="employeeNameFilter" class="form-control" (ngModelChange)="changedFilters()"  [ngModelOptions]="{standalone: true}">
            </div>
          </div>

          <div class="row mb-1" >
            <div class="col-4 d-flex align-items-center">
              <h6>Email :</h6>
            </div>
            <div class="col-8 ">
              <input type="text" [(ngModel)]="emailFilter" class="form-control" (ngModelChange)="changedFilters()"  [ngModelOptions]="{standalone: true}">
            </div>
          </div>

          <div class="row mb-1" >
            <div class="col-4 d-flex align-items-center">
              <h6>Sent Date:</h6>
            </div>
            <div class="col-8 ">
              <p-dropdown styleClass="form-control  w-100" [options]="datesOptions" placeholder="Select ..."  [(ngModel)]="sentDateOptionFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"></p-dropdown>

              <p-calendar *ngIf="sentDateOptionFilter==0"
                inputStyleClass="form-control "
                [monthNavigator]="true"
                [yearNavigator]="true"
                styleClass="w-75"
                panelStyleClass="calendarPanel"
                [hourFormat]="12"
                [yearRange]="'2010:2100'"
                [showTime]="false"
                [selectionMode]="'range'"
                [(ngModel)]="sentDateFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"
                dataType="string"
              ></p-calendar>
            </div>
          </div>


          <div class="row mb-1" >
            <div class="col-4 d-flex align-items-center">
              <h6>Signed Date:</h6>
            </div>
            <div class="col-8 ">
              <p-dropdown styleClass="form-control w-100" [options]="datesOptions" placeholder="Select ..."  [(ngModel)]="signedDateOptionFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"></p-dropdown>

              <p-calendar *ngIf="signedDateOptionFilter==0"
                inputStyleClass="form-control "
                [monthNavigator]="true"
                [yearNavigator]="true"
                styleClass="w-75"
                panelStyleClass="calendarPanel"
                [hourFormat]="12"
                [yearRange]="'2010:2100'"
                [showTime]="false"
                [selectionMode]="'range'"
                [(ngModel)]="signedDateFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"
                dataType="string"
              ></p-calendar>
            </div>
          </div>
          <div class="row  mb-1" *ngIf="canEdit || canViewSpecific">
            <div class="col-4 d-flex align-items-center">
              <b>Region</b>
            </div>
            <div class="col-8">
              <i *ngIf="!campusesList" class="material-icons rotate text-muted">loop</i>
              <p-dropdown styleClass="form-control  w-100" [options]="campusesList" placeholder="Select campus..." [(ngModel)]="regionFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"></p-dropdown>
            </div>
          </div>
        </div>
        <div class="col-6 mb-3">

          <div class="row  mb-1">
            <div class="col-4 d-flex align-items-center">
              <b>Positions</b>
            </div>
            <div class="col-8">
              <i *ngIf="!positionsList" class="material-icons rotate text-muted">loop</i>
              <p-multiSelect #positionsMultiSelect (onFocus)="openMultiSelect(positionsMultiSelect)" autofocusFilter="true" class="w-100" styleClass="w-100 d-flex " inputStyleClass="form-control"
                             [options]="positionsList" [defaultLabel]="'Select ...'"
                             [(ngModel)]="positionsFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"></p-multiSelect>
            </div>
          </div>

          <div class="row  mb-1">
            <div class="col-4 d-flex align-items-center">
              <b>Exclude Positions</b>
            </div>
            <div class="col-8">
              <i *ngIf="!positionsList" class="material-icons rotate text-muted">loop</i>
              <p-multiSelect #excludePositionsMultiSelect (onFocus)="openMultiSelect(excludePositionsMultiSelect)" autofocusFilter="true" class="w-100" styleClass="w-100 d-flex " inputStyleClass="form-control"
                             [options]="positionsList" [defaultLabel]="'Select ...'"
                             [(ngModel)]="excludePositionsFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"></p-multiSelect>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-4 d-flex align-items-center">
              <h6>Type:</h6>
            </div>
            <div class="col-8 ">
              <p-selectButton styleClass="w-100" [options]="newExisitngAllOptions" [(ngModel)]="newExistingFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"></p-selectButton>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-4 d-flex align-items-center">
              <h6>Sent:</h6>
            </div>
            <div class="col-8 ">
              <p-selectButton styleClass="w-100" [options]="yesNoAllOptions" [(ngModel)]="sentFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"></p-selectButton>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-4 d-flex align-items-center">
              <h6>Accepted:</h6>
            </div>
            <div class="col-8 ">
              <p-selectButton styleClass="w-100" [options]="yesNoAllOptions" [(ngModel)]="acceptedFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"></p-selectButton>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-4 d-flex align-items-center">
              <h6>Expired:</h6>
            </div>
            <div class="col-8 ">
              <p-selectButton styleClass="w-100" [options]="yesNoAllOptions" [(ngModel)]="expiredFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"></p-selectButton>
            </div>
          </div>
        </div>
      </div>

    <i *ngIf="loadingOfferLetterList" class="material-icons rotate text-muted">loop</i>
    <p-table  [value]="offerLetters?.result" [responsive]="true"
    [paginator]="true" [rows]="20" [totalRecords]="offerLetters?.totalRecords" [lazy]="true"
    (onLazyLoad)="loadPage($event)">
        <ng-template pTemplate="header">
            <tr>
                <th width="120px"></th>
                <th [pSortableColumn]="'ValidUntilDate'"width="90px">Valid Date
                  <p-sortIcon [field]="'ValidUntilDate'"></p-sortIcon>
                </th>
                <th [pSortableColumn]="'User'">User
                  <p-sortIcon [field]="'User'"></p-sortIcon>
                </th>
                <th [pSortableColumn]="'Position'">Position
                  <p-sortIcon [field]="'Position'"></p-sortIcon>
                </th>
                <th [pSortableColumn]="'ServiceRegion'" >Region
                  <p-sortIcon [field]="'ServiceRegion'"></p-sortIcon></th>
                <th [pSortableColumn]="'SalaryPayRate'">Salary
                    <p-sortIcon [field]="'SalaryPayRate'"></p-sortIcon></th>
                <th width="120px" [pSortableColumn]="'NewOrAdjustment'" >Employee <p-sortIcon [field]="'NewOrAdjustment'"></p-sortIcon></th>
                <th width="90px" [pSortableColumn]="'DocumentToSignID'">Sent <p-sortIcon [field]="'DocumentToSignID'"></p-sortIcon></th>
                <th width="120px" [pSortableColumn]="'sentDate'">Sent Date
                  <p-sortIcon [field]="'sentDate'"></p-sortIcon></th>
                <th width="120px" [pSortableColumn]="'signed'">Accepted
                    <p-sortIcon [field]="'signed'"></p-sortIcon></th>
                <th width="120px" [pSortableColumn]="'signed'">Signed Date
                      <p-sortIcon [field]="'signedDate'"></p-sortIcon></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-offerletterWithUser>
          <tr>
            <td>
              <span class="pointer mr-2" *ngIf="canView" (click)="editOffer(offerletterWithUser)"><i title="Edit Offer" class="fas fa-edit"></i></span>
              <span class="pointer mr-2 " *ngIf="canView && offerletterWithUser?.offerLetter?.documentToSignID" (click)="viewOffer(offerletterWithUser)"><i title="Download Offer Doc" class="fas fa-file-download"></i></span>
              <span class="pointer " *ngIf="(canEdit || (canEditBehaviorTechs && jobTitleIdsBehaviotTech.includes(offerletterWithUser.offerLetter.jobTitleID)) || (canEditBehaviorTechsAA && jobTitleIdsBehaviotTechAA.includes(offerletterWithUser.offerLetter.jobTitleID))) && !offerletterWithUser?.offerLetter?.signed" (click)="deleteOffer(offerletterWithUser)"><i title="Delete Offer" class="fas fa-trash-alt"></i></span>
            </td>
            <td>
              <span class="ui-column-title">Date</span>
              {{offerletterWithUser.offerLetter.validUntilDate | date:'shortDate' }}
            </td>
            <td>
              <span class="ui-column-title">User</span>
              {{offerletterWithUser.user.firstName }} {{offerletterWithUser.user.lastName }}
            </td>

            <td>
              <span class="ui-column-title">Position</span>
              {{offerletterWithUser.offerLetter.position }} - {{offerletterWithUser.offerLetter.typePosition }}
            </td>

            <td>
              <span class="ui-column-title">Region</span>
              {{offerletterWithUser.offerLetter.serviceRegion }}
            </td>

            <td>
              <span class="ui-column-title">Salary</span>
              {{offerletterWithUser.offerLetter.salaryPayRate}} per {{offerletterWithUser.offerLetter.salaryOrPayRate?'year':'hour' }}
            </td>
            <td>
              <span class="ui-column-title">New Employee</span>
              {{!!offerletterWithUser.offerLetter.existingUser?'Existing':'New'}}
            </td>
            <td>
              <span class="ui-column-title">Sent</span>
              {{!!offerletterWithUser.offerLetter.documentToSignID?'Sent':'Draft'}}
            </td>
            <td>
              <span class="ui-column-title">Sent Date</span>
              {{offerletterWithUser.offerLetter.createdDate | date:'shortDate' }}
            </td>
            <td *ngIf="!offerletterWithUser.offerLetter.isExpired">
              <span class="ui-column-title">Accepted</span>
              <i *ngIf="!!offerletterWithUser.offerLetter.signed" class="fas fa-check-circle text-success" title="Signed"></i>
              <span *ngIf="canEdit && !!offerletterWithUser.offerLetter.signed && !offerletterWithUser.offerLetter.signedSeen" title="Click here to mark as read" (click)="markAsSeen(offerletterWithUser)" class="badge badge-danger ml-2 pointer">New</span>
              <i *ngIf="!offerletterWithUser.offerLetter.signed" title="Not signed yet" class="fas fa-minus-circle text-secondary"></i>
            </td>
            <td *ngIf="offerletterWithUser.offerLetter.isExpired">
              <span class="ui-column-title">Accepted</span>
              <h5 style="color: darkred">Expired</h5>
            </td>
            <td>
              <span class="ui-column-title">Signed Date</span>
              {{offerletterWithUser.offerLetter.signedDate | date:'shortDate' }}
            </td>
          </tr>
        </ng-template>
    </p-table>
  </div>
</form>
