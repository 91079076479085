
<i *ngIf="!payrollItems" class="material-icons rotate text-muted">loop</i>
<ng-container *ngIf="payrollItems && payrollItems.length">
  <div class="w-100 text-center mb-2">
    <h5>{{ title ? title : payableType }}</h5>
  </div>
  <p-table [value]="payrollItems" [responsive]="true" [rowTrackBy]="trackByFunction">
    <ng-template pTemplate="header">
      <tr>
        <th width="80px">Date</th>
        <th>Time</th>
        <th width="80px" *ngIf="!employeeView">Resolved
        </th>
        <th width="80px" *ngIf="!employeeView">Invalid
        </th>
        <th colspan=2  *ngIf="employeeView">Approver Name
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-payrollItem>
      <tr>
        <td [colSpan]="4">
          <table>
            <tr>
              <td width="80px">
                <span class="ui-column-title">Date</span>
                {{ payrollItem.payableItem.date | date: "shortDate" }}
              </td>
              <td>
                <span class="ui-column-title">Clock in</span>
                  {{ payrollItem.payableItem.startTime | date: payrollItem.payableItem.payableTypeID !== 7 ? "shortTime" : "shortDate" }} -
                  {{ payrollItem.payableItem.endTime | date: payrollItem.payableItem.payableTypeID !== 7 ? "shortTime" : "shortDate" }} ({{
                  payrollItem.payableItem.timeDiff
                }}) <i *ngIf="payrollItem.payableItem.isProductive && payrollItem.payableItem.payableTypeID === 2" class="material-icons" style="color: gold; cursor: default" title="Productive">grade</i>
              </td>
              <td width="80px" class="text-center" *ngIf="!employeeView">
                <span class="ui-column-title">Resolved</span>
                <p-checkbox  [(ngModel)]="payrollItem.payableItem.resolved"  binary="true" (onChange)="changed()" ></p-checkbox>
              <td width="80px" class="text-center" *ngIf="!employeeView">
                <span class="ui-column-title">Invalid</span>
                <p-checkbox [(ngModel)]="payrollItem.payableItem.invalid"  binary="true" (onChange)="changed()" ></p-checkbox>

              </td>
              <td colspan=2 *ngIf="employeeView">
                <span class="ui-column-title">Approver Name</span>
                {{payrollItem.payableItem.approverName}}
              </td>
            </tr>
            <tr>
              <td [colSpan]="4">
                <span class="mt-3 d-block font-weight-bold">Reason</span>
                <input *ngIf="!employeeView" type="text" class="form-control w-100" [(ngModel)]="payrollItem.payableItem.reason" (ngModelChange)="changed()"/>
                <span class="w-100 mt-2 ml-3" *ngIf="employeeView"  >{{payrollItem.payableItem.reason}}</span>
              </td>
            </tr>

            <tr *ngIf="payrollItem.payableItem.response || employeeView">
              <td [colSpan]="4">
                <span class="mt-3 d-block font-weight-bold">Response</span>
                <span class="w-100 mt-2 ml-3" *ngIf="!employeeView" >{{payrollItem.payableItem.response}}</span>
                <input *ngIf="employeeView" type="text" class="form-control w-100" [(ngModel)]="payrollItem.payableItem.response" (ngModelChange)="changed()"/>
              </td>
            </tr>
          </table>
        </td>

      </tr>
    </ng-template>
    <ng-template pTemplate="summary">
      <span class="text-right mr-2" *ngIf="!employeeView">Total</span>
      <span *ngIf="!employeeView">{{ totalTime }}</span>
    </ng-template>
  </p-table>
</ng-container>
