import { PayrollService } from './../../services/payroll.service';
import { AlertService } from './../../../shared/services/alert.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, tap, catchError, filter } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { errorHappened } from '../profile/profile.actions';
import { Store, Action } from '@ngrx/store';
import { State } from '..';
import {
  loadApprovedTime, loadApprovedTimeSuccess, loadInvalidTime, loadInvalidTimeSuccess,
  loadAdjustmentTime, loadAdjustmentTimeSuccess, loadPendingTime, loadPendingTimeSuccess,
  loadApprovedTotals, loadApprovedTotalsSuccess
} from './payroll.actions';

@Injectable()
export class PayrollEffects {
  constructor(private actions$: Actions,
              private payrollService: PayrollService,
              private alertService: AlertService,
              private store: Store<State>) { }

  loadApprovedTime = createEffect(() => this.actions$.pipe(
    ofType(loadApprovedTime),
    switchMap(action => this.payrollService.loadApprovedTime(action.periodID).pipe(
      map(payrollItems => loadApprovedTimeSuccess({ payrollItems })),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))));
  loadInvalidTime = createEffect(() => this.actions$.pipe(
    ofType(loadInvalidTime),
    switchMap(action => this.payrollService.loadInvalidTime(action.periodID).pipe(
      map(payrollItems => loadInvalidTimeSuccess({ payrollItems })),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))));
  loadAdjustmentTime = createEffect(() => this.actions$.pipe(
    ofType(loadAdjustmentTime),
    switchMap(action => this.payrollService.loadAdjustmentTime(action.periodID).pipe(
      map(payrollItems => loadAdjustmentTimeSuccess({ payrollItems })),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))));
  loadPendingTime = createEffect(() => this.actions$.pipe(
    ofType(loadPendingTime),
    switchMap(action => this.payrollService.loadPendingTime(action.periodID).pipe(
      map(payrollItems => loadPendingTimeSuccess({ payrollItems })),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))));

  loadApprovedTotals = createEffect(() => this.actions$.pipe(
    ofType(loadApprovedTotals),
    switchMap(action => this.payrollService.loadApprovedTotals(action.periodID).pipe(
      map(approvedTotals => loadApprovedTotalsSuccess({ approvedTotals })),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))));
}
