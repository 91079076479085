import { loadProfile } from './../../../core/store/profile/profile.actions';
import { Router } from '@angular/router';
import { AlertService } from './../../../shared/services/alert.service';
import { signOfferLetterDoc, signOfferLetterDocSuccess } from './../../../core/store/offerletter/offerletter.actions';
import { UserModel } from 'src/app/models/userModel';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../core/store';
import { BaseComponent } from '../../../core/abstracts/baseComponent';
import { OfferLetterModel } from '../../../models/offerletterModel';
import { loadOfferLetterDoc } from 'src/app/core/store/offerletter/offerletter.actions';
import { Actions, ofType } from '@ngrx/effects';
import { switchMap, tap } from 'rxjs/operators';
import { Utils, downloadFile } from 'src/app/helpers/utils';
import * as moment from 'moment';
import { SignDocComponentBase } from '../../abstract/signDocBase.component';

@Component({
  selector: 'app-offerletter',
  templateUrl: './offerletter.component.html',
  styleUrls: ['./offerletter.component.scss']
})
export class OfferLetterComponent extends SignDocComponentBase implements OnInit, AfterViewInit {

  offerletter: OfferLetterModel;
  loadingSign = false;
  loadingDoc = true;
  user: UserModel;

  constructor(
    private store: Store<State>,
    private actions$: Actions,
    private alert: AlertService,
    private router: Router) {
    super(store);
  }

  ngOnInit() {
    this.store.dispatch(loadProfile());
    this.subs.push(
      this.store.select(s => s.profileState.profile).subscribe(p => {
        if (p) {
          this.offerletter = p.offerLetter;
          this.store.dispatch(loadOfferLetterDoc({ offerLetterID: this.offerletter.id }));
          this.user = p.user;
        }
      }),
      this.store.select(s => s.offerLetterState.docArrayBuffer).subscribe(docArrayBuffer => {
        if (docArrayBuffer) {
          this.url =
            URL.createObjectURL(new Blob([docArrayBuffer], {
              type: 'application/pdf'
            }));
        }
      }),
      this.store.select(s => s.offerLetterState.loadingDoc).subscribe(loadingDoc => {
        this.loadingDoc = loadingDoc;
      }),
      this.store.select(s => s.offerLetterState.loadingSign).subscribe(loadingSign => {
        this.loadingSign = loadingSign;
      }),
      this.actions$.pipe(
        ofType(signOfferLetterDocSuccess),
        tap(action => {
          this.store.dispatch(loadProfile());
          this.alert.confirm('Documentation signed succesfully',
            'Documentation signed succesfully. Do you want to download the signed file now?')
            .subscribe(
              answer => {
                if (answer) {
                  downloadFile(action.docSigned, 'Offer Letter Signed.pdf');
                }
                this.router.navigate(['/']);
              }, error => {
                this.router.navigate([`/`]);
              }
            );
        })
      ).subscribe()

    );
  }

  get loggedUserName() {
    return !this.user ? '' : (this.user.firstName + ' ' + this.user.lastName);
  }

  get loggedUserEmail() {
    return !this.user ? '' : this.user.email;
  }

  sign() {
    if (!this.finishSignDisabled) {
      this.store.dispatch(signOfferLetterDoc({ offerLetterID: this.offerletter.id, signedFields: this.signedFields }));
    }
  }

}
