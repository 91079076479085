import { createAction, props } from '@ngrx/store';
import { GeneralSettingsModel } from 'src/app/models/generalSettingsModel';
import { PayPeriodModel } from 'src/app/models/payPeriodModel';
import { UserModel } from 'src/app/models/userModel';

export const authenticateUser = createAction('[Account] Authenticate User', props<{ username: string, pass: string }>());
export const authenticateSuccess = createAction('[Account] Authenticate Success', props<{ user: UserModel }>());
export const authenticateFailure = createAction('[Account] Authenticate Failure');
export const authenticateToken = createAction('[Account] Authenticate Token', props<{ token: string }>());
export const authenticateCode = createAction('[Account] Authenticate Code', props<{ code: string }>());

export const setReturnUrl = createAction('[Account] Set Return Url', props<{ returnUrl: string }>());
export const logout = createAction('[Account] logout');

export const logNavigation = createAction('[Account] log Navigation', props<{ Url: string, parameters: string }>());
export const logNavigationSuccess = createAction('[Account] log Navigation Success');

export const setSessionConfirm = createAction('[Account] Set Confirmation Visible', props<{ isVisible: boolean}>());

export const getGeneralSettings = createAction('[Account] Get General Settings');
export const getGeneralSettingsSuccess = createAction('[Account] Get General Settings Success',
  props<{ generalSettings: GeneralSettingsModel }>());

export const getFeatureSwitches = createAction('[Account] Get Feature Switches');
export const getFeatureSwitchesSuccess = createAction('[Account] Get Feature Switches Success',
  props<{ featureSwitches: number[] }>());

