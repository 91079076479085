<div class="card mb-2">
  <div class="card-body">

      <h2 class="">Request Time Card Fix</h2>
      <div class="row">
          <div class="col-12 col-xl-6">
          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Fix Reason</b>
            </div>
            <div class="col-12 ">
                <input type="text" class="form-control mt-2"  [(ngModel)]="fixReason" />
                <small *ngIf="clickedSave && !fixReason"  class="text-danger">Fix Reason is required</small>
            </div>

          </div>
        </div>
      </div>
      <button class="btn btn-primary mt-3" (click)="save()">Save</button>

  </div>
</div>
