<p-dialog [(visible)]="display" header="{{title}}" [modal]="true" [style]="{width: '70vw'}" (onHide)="closeModal()" [focusOnShow]="false">
  <form class="card mt-2" [formGroup]="form" (ngSubmit)="saveTimeOffRequest()">
    <div class="card-body">
      <div class="row">
        <div class="col-12">

          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Employee</b>
            </div>
            <div class="col-8">
              <p-autoComplete *ngIf="disableName === false" [style]="{'width': '100%'}" [inputStyle]="{'width': '100%'}" [(ngModel)]="prefixText"
                            [suggestions]="userList" (completeMethod)="getStaffList($event)" field="label"
                            [ngModelOptions]="{standalone: true}" [minLength]="1" [autoHighlight]="true"
                            (onSelect)="selectStaff($event)" [forceSelection]="true"
                            (onClear)="clearStaff($event)"></p-autoComplete>
              <input *ngIf="disableName" type="text" disabled="disabled" class="form-control mt-2" [(ngModel)]="prefixText" [ngModelOptions]="{standalone: true}" />
            </div>            
          </div>

          <div class="row mt-2">
            <div class="col-4 d-flex align-items-center">
              <b>Date Range</b>
            </div>
            <div class="col-4">
              <input type="date" class="form-control mt-2" formControlName="startDateString" />
              <app-validation-message [form]="form" [path]="'startDateString'" [label]="'Start Date'"></app-validation-message>
            </div>
            <div class="col-4">
              <input type="date" class="form-control mt-2" formControlName="endDateString" />
              <app-validation-message [form]="form" [path]="'endDateString'" [label]="'End Date'"></app-validation-message>
            </div>
          </div>

          <div class="row mt-2" style="min-height: 46px;">
            <div class="col-4 d-flex align-items-center">
              <b>Time Range</b>
            </div>
            <div class="col-8 d-flex align-items-center">
              <p-checkbox [binary]="true" inputId="allDay" formControlName="allDay"></p-checkbox>
              <label for="allDay" style="margin-left: 0.5rem; margin-bottom: 0;">All Day</label>
            </div>
          </div>

          <div class="row" *ngIf="timeOffRequest === null || timeOffRequest.allDay === false">
            <div class="col-4 d-flex align-items-center">              
            </div>            
              <div class="col-4">
                <p-calendar appendTo="body" inputStyleClass="form-control mt-2" styleClass="w-100" panelStyleClass="calendarPanel" dataType="string" name="startTimeString" formControlName="startTimeString" [timeOnly]="true" [hourFormat]="12"></p-calendar>
                <app-validation-message [form]="form" [path]="'startTimeString'" [label]="'Start Time'"></app-validation-message>
              </div>
              <div class="col-4">
                <p-calendar appendTo="body" inputStyleClass="form-control mt-2" styleClass="w-100" panelStyleClass="calendarPanel" dataType="string" name="endTimeString" formControlName="endTimeString" [timeOnly]="true" [hourFormat]="12"></p-calendar>
                <app-validation-message [form]="form" [path]="'endTimeString'" [label]="'End Time'"></app-validation-message>
              </div> 
          </div>

          <div *ngIf="currentUserId == userId || (timeOffRequest !== null && timeOffRequest.isPayroll)" class="row" style="min-height: 46px;">
            <div class="col-4 d-flex align-items-center">
              <b>PTO Available</b>
            </div>
            <div class="col-8 d-flex align-items-center">
              <span style="font-style: italic;">{{totalAvailable}}</span>
            </div>
          </div>

          <div *ngIf="currentUserId == userId" class="row mt-2">
            <div class="col-4 d-flex align-items-center">
              <b>Would you like to use PTO?</b>
            </div>
            <div class="col-8">              
              <p-checkbox *ngIf="currentUserId === userId" [binary]="true" inputId="usePto" formControlName="usePto" ></p-checkbox>
              <label for="usePto" style="margin-left: 0.5rem; margin-bottom: 0;"></label>
            </div>
          </div>          

          <div class="row mt-2" *ngIf="timeOffRequest !== null && timeOffRequest.usePto">
            <div class="col-4 d-flex align-items-center">
              <b>PTO Requested</b>
            </div>
            <div class="col-4" *ngIf="currentUserId == userId || (timeOffRequest !== null && timeOffRequest.isPayroll)">
              <input type="number" min="0" step="1" style="width: 85%; display: inline-block;" placeholder="PTO Hours" class="form-control mt-2" formControlName="ptoHours" /> h
              <app-validation-message [form]="form" [path]="'ptoHours'" [label]="'Hours'"></app-validation-message>
            </div>
            <div class="col-4" *ngIf="currentUserId == userId || (timeOffRequest !== null && timeOffRequest.isPayroll)">
              <input type="number" min="0" max="59" step="1" style="width: 85%; display: inline-block;" placeholder="PTO Minutes" class="form-control mt-2" formControlName="ptoMinutes" /> m
              <app-validation-message [form]="form" [path]="'ptoMinutes'" [label]="'Minutes'"></app-validation-message>
            </div>
            <div class="col-8" *ngIf="currentUserId !== userId && !timeOffRequest.isPayroll">
              {{timeOffRequest.ptoHours}}h {{timeOffRequest.ptoMinutes}}m
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-4 d-flex align-items-center">
              <b>Comments</b>
            </div>
            <div class="col-8">
              <textarea style="width: 100%;" class="form-control mt-2" formControlName="comments"></textarea>
              <app-validation-message [form]="form" [path]="'comments'" [label]="'Comments'"></app-validation-message>
            </div>
          </div>

        </div>
      </div>
    </div>
  </form>
  <p-footer>
    <button class="btn btn-secondary" type="button" (click)="closeModal()">Cancel</button>
    <button class="btn btn-primary" type="submit" (click)="saveTimeOffRequest()" [disabled]="disableButtons">Save</button>
  </p-footer>
</p-dialog>
