import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { savePost, savePostWithFiles } from 'src/app/core/store/critical-news-feed/critical-news-feed.action';
import { CriticalNewsPostLinkModel, CriticalNewsPostModel, CriticalNewsPostUIFileModel, NewFileNames } from 'src/app/models/criticalNewsFeedModel';
import { deepClone } from 'src/app/helpers/utils';
import { ActivatedRoute } from '@angular/router';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-add-post',
  templateUrl: './add-post.component.html',
  styleUrls: ['./add-post.component.scss']
})
export class AddPostComponent extends BaseComponent implements OnInit {

  @Output() scrollTop = new EventEmitter<number>();

  @ViewChild('postMessage') postMessage: ElementRef;
  screenWidth: number = window.innerWidth;
  @HostListener('window:resize', ['$event']) onWindowResize() {
    this.screenWidth = window.innerWidth;
  }
  //Permissions
  managePinnedPosts: boolean = false;
  canPost: boolean = true;
  manageAll: boolean = false;

  modalReference: any;
  linkModalReference: any;
  modalOptions: NgbModalOptions;
  messageToLong: boolean;
  postCharCount: number = 0;
  message: string;
  isPinned: boolean = false;
  post: CriticalNewsPostModel;
  files: {id: number; file: File;}[] = null;
  urls: CriticalNewsPostUIFileModel[];
  displayUrls: CriticalNewsPostUIFileModel[];
  otherFiles: CriticalNewsPostUIFileModel[];
  newFilesDynamicIndex: number = 1;
  mainPostPhoto: CriticalNewsPostUIFileModel;
  additionalPhotos: CriticalNewsPostUIFileModel[];
  moreThanOnePhoto: boolean;
  fileCount: number;
  showImages: boolean;
  newFileNames: NewFileNames[] = null;
  currentEditingFileName: number = null;

  newLinkInput: string = "";
  newLinks: CriticalNewsPostLinkModel[] = null;

  constructor(private actions$: Actions,
    private store: Store<State>,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private alertService: AlertService,
    ) {
    super();
    this.modalOptions = {
      backdrop:'static',
      backdropClass:'customBackdrop',
      size: "lg",
    }
   }

  ngOnInit(): void {
    this.post = {
      message: "string",
      createdDate: null,
      updatedDate: null,
      isPinned: false,
      isPinnedByUser: false,
      archived: false,
      hasFiles: false,
      fileIdsToRemove: [],
      userImage: "",
      userFirstName: "",
      userLastName: "",
      imageURLs: [],
      otherFileUrls: [],
      reactions: [],
      comments: [],
    } 
    this.subs.push(
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.managePinnedPosts = permissionIDs && !!permissionIDs.find(p => p === 9504 || p === 9500);
        this.canPost = permissionIDs && !!permissionIDs.find(p => p === 9502 || p === 9500 || p === 9505 || p === 9504);
        this.manageAll = permissionIDs && !!permissionIDs.find(p => p === 9500);
      }),
    );
  }

  open(content) {
    this.modalReference = this.modalService.open(content, this.modalOptions);
    this.modalReference.result.then((result) => {
    }, (reason) => {
    });
  }

  openAddLink(content) {
    this.linkModalReference = this.modalService.open(content, this.modalOptions);
    this.linkModalReference.result.then((result) => {
    }, (reason) => {
    });
  }

  addNewLink(){
    if (this.newLinkInput == "" || this.newLinkInput == null){
      this.alertService.error("You have not input a new link");
      return;
    }
    let links = [];
    if (this.newLinks != null){
      links = [...this.newLinks];
    }
    let icon = "fas fa-link";
    let iconColor = "#000000"
    if (this.newLinkInput.toLocaleLowerCase().includes("youtu.be")  || this.newLinkInput.toLocaleLowerCase().includes("youtube") ){
      icon = "fab fa-youtube"; 
      iconColor = "#FF0000";
    }
    else if (this.newLinkInput.toLocaleLowerCase().includes("facebook") ){
      icon = "fab fa-facebook-square";
      iconColor = "#385898";
    }
    else if (this.newLinkInput.toLocaleLowerCase().includes("instagram") ){
      icon = "fab fa-instagram";
      iconColor = "#833AB4";
    }
    else if (this.newLinkInput.toLocaleLowerCase().includes("twitter") ){
      icon = "fab fa-twitter";
      iconColor = "#1DA1F2";
    }
    links.push({id: 0, icon: icon, iconColor: iconColor, url: this.newLinkInput});
    this.newLinkInput = "";
    this.newLinks = links;
  }
  removeAddedLink(e, link){
    this.newLinks = Array.from(this.newLinks).filter( function(x){ 
      if (x.url != link.url){
        return x ;
      }
    });
    e.stopPropagation();
  }
  uploadFile(event){
    var duplicates = false;
    let files = [];
    if(this.urls != null && this.urls.length > 0){
      for (let file of event.target.files){
        let duplicateExist = Array.from(this.urls).filter( function(x){ 
                                if (x.fileName === file.name && x.fileType === file.type){
                                  return x;
                                }
                                else{
                                  return null;
                                }
                              });
        if (duplicateExist.length > 0){
          duplicates = true;
          continue;
        }
        else{
          files.push(file);
        }
      }
    }
    else{
      files = event.target.files;
    }
    if(this.files == null){
      this.files = [];
      this.urls = [];
    }
    if (files) {
      for (let file of files) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.urls.push({id: this.newFilesDynamicIndex, fileName: file.name, fileType: file.type, url: e.target.result});
          this.files = Object.assign([], this.files);
          this.files.push({id: this.newFilesDynamicIndex, file: file});
          this.newFilesDynamicIndex = this.newFilesDynamicIndex + 1;
        }
        reader.readAsDataURL(file);
      }
    }
    if(duplicates){
      this.alertService.info("duplicate images were not added");
    }
    setTimeout(() =>{this.manageImageDisplay(this.urls)}, 300);
  }
  
  manageImageDisplay(files: CriticalNewsPostUIFileModel[]){
    let images = [];
    let othFiles = [];
    for (var file of files){
      if(file.fileType.includes("image")){
        images.push(file)
      }
      else {
        othFiles.push(file);
      }
    }
    this.mainPostPhoto = images[0];
    if (images.length > 1){
      this.additionalPhotos = images.slice(1, images.length);
      this.moreThanOnePhoto = true;
    }
    else{
      this.additionalPhotos = null;
      this.moreThanOnePhoto = false;
    }
    this.otherFiles = othFiles;
    this.displayUrls = images;
    this.fileCount = images.length + othFiles.length;
    this.showImages = images != null && images.length > 0 ? true : false;
  }
  
  updateNewFileName(file){
    let fileIndex = this.files.findIndex(x => {return x.id == file.id});
    if (fileIndex !== -1){
      let newName = {newName: file.fileName, oldName: this.files[fileIndex].file.name, oldType: this.files[fileIndex].file.type}
      if (this.newFileNames == null){
        this.newFileNames = []
      }
      this.newFileNames.push(newName);
    }
  }

  removeAddedImage(file){
    this.urls = Array.from(this.urls).filter( function(x){ 
                  if (x.id != file.id){
                    return x ;
                  }
                });
    this.files = Array.from(this.files).filter( function(x){ 
      if (x.id != file.id){
        return x ;
      }
    });
    this.manageImageDisplay(this.urls);
  }

  onInput(e) {
    const tx = this.postMessage.nativeElement;
    for (let i = 0; i < tx.length; i++) {
      tx[i].setAttribute("style", "height:" + (tx[i].scrollHeight) + "px;overflow-y:hidden;");
    }
    e.target.style.height = 0;
    e.target.style.height = (e.target.scrollHeight) + "px";
  }

  countCharacters(){
    var count = this.message.length;
    if(count > 8000){
      this.messageToLong = true;
    }
    else{
      this.messageToLong = false;
    }
    this.postCharCount = count;
  }

  savePost(){
    if (this.message == null || this.message == "" ){
      this.alertService.error("Post's Message cannot be empty");
      return;
    }
    const postToSave = deepClone(this.post)
    postToSave.message = this.message;
    postToSave.isPinned = this.isPinned;
    postToSave.fileIdsToRemove = null;
    postToSave.newLinks = this.newLinks;
    if (this.files != null && this.files.length > 0){
      let date = new Date();
      postToSave.createdDate = date.toISOString();
      postToSave.updatedDate = date.toISOString();
      postToSave.hasFiles = true;
      postToSave.newFileNames = this.newFileNames;
      let filesToSave = this.files.map(x => {return x.file})
      this.store.dispatch(savePostWithFiles({post: postToSave, files:  Object.assign([], filesToSave)}))
    }
    else{
      this.store.dispatch(savePost({post: postToSave}));
    }
    this.isPinned = false;
    this.message = "";
    this.files = null;
    this.urls = null;
    this.mainPostPhoto = null;      
    this.additionalPhotos = null;
    this.showImages = false;
    this.otherFiles = null;
    this.displayUrls = null;
    this.postCharCount = 0;
    this.newLinks = null;
    this.newFilesDynamicIndex = 1;
    setTimeout(()=>{ // this will make the execution after the above boolean has changed
      this.postMessage.nativeElement.focus();
    }, 0); 
    this.scrollTop.emit();
  }
}
