import { BaseComponent } from './../../../core/abstracts/baseComponent';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../core/store';
import { Actions, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { AlertService } from '../../../shared/services/alert.service';
import { SelectItem } from 'primeng';
import { clearAutoCompleteList, getInputAutocompleteList } from '../store/input-autocomplete/input-autocomplete.action';

@Component({
  selector: 'app-input-autocomplete',
  templateUrl: './input-autocomplete.component.html',
  styleUrls: ['./input-autocomplete.component.scss']
})
export class InputAutocompleteComponent extends BaseComponent implements OnInit {
  @ViewChild('multiSelectInput') msInput: ElementRef;
  @HostListener('document:click')
  clickout() {
    this.showDropDown = false;
  }
 
  @Input() type: string = "inquiry";
      // user options will decide what table is used in the backend to collect the data. Please CAPITALIZE the 
      // first letter for new tables that you use as this will show in the error messages and alerts that are set up.
      // if there is another table that is needed look in the HRPortal Controllers in InputHelperControllers and add functionality there Then add new method 
      // in C:...\MiniMiserData\Logic\AutoComplete\AutoCompleteHelper.cs (Add In Data so that method is aavcailable everywhere in System) to be called 
      // in HRPortal Controller. Then add it to the list here So available options are known. 
      // CURRENT TABLES:
      //    - OP_Inquiries -> type: "Inquiry";
      //    - EFI_User -> type: "User";
      //    - OP_Patient -> type: "Patient";
      
  @Input() length: number = 10;
      // length determines how many values are returned for the drop down Menu

  @Input() selectType: string = "AutoComplete";
      // Determines type of input. Current available options
      //    - AutoComplete
      //    - MultiSelect
  
  
  @Input() responseValue: string = null;
  // responseValue is used to determine what value you would like to return to your component. 
  // This will return a SelectItem with the Value being either The Value(typically the ID) or the Label(Typically a name)
  // If no parameter is passed this will default to the Value.
  //  CHOICES:
  //    - label
      
  @Input() disabled: boolean = false;
      // Functionality to come. will disable the input field when passed in. NOT IMPLEMENTED YET
  
  @Input() inputClass: string;
      // Class passed in to set class on input field

  _model: any
  @Input()
  set model(model: any) {
    if (model != null){
      if(this.selectType == "AutoComplete"){
        this.searchString = (model.label && model.label.trim()) || '';
      }
      this._model = model;
    }
  }
  get model() { return this._model; }
  @Output() modelChange = new EventEmitter<any>();
      // model will track value and emit the value selected to the parent component.
  
  searchString: string = null;

  multiSelectOptions: any[] = [];
  currentSelectedOptions: any[] = [];
  showCurrentSelections: boolean = false;

  addClass: boolean;
  options: SelectItem[]
  inputPlaceholder: string = "Start Typing to select..."
  showDropDown: boolean = false;
  selectedOptionValue: any = null;
  selectedIndex: number = null;

  constructor(
    private actions$: Actions,
    private alertService: AlertService,
    private store: Store<State>,
    private router: Router,
  ) {
    super();
   }

  ngOnInit(): void {
    if (this.inputClass != null){
      this.addClass = true;
    }
    this.subs.push(
      this.store.select(s => s.inputAutoCompleteState.autoCompleteList).subscribe(list => {
        if (list && this.responseValue == "label") {
          if (this.responseValue == "label"){
            this.options = list.map(x => {
              return {label: x.label, value:x.label}
            });
          }
        }
        else{
          this.options = list
          this.showDropDown = true;
        }
      }),
    );
  }

  setOption(e, item){
    if (item != null){
      this.model = item;
      this.searchString = item.label;
      this.modelChange.emit(item);
      this.selectedOptionValue = item.value;
    }
    else{
      this.alertService.error("Error selecting " + this.type);
    }
  }

  clickIntoInput(){
    if(this.options != null){
      setTimeout(x => {this.onChange()}, 100);
    }
  }

  hoverItem(item, index){
    this.selectedOptionValue = item.value;
    this.selectedIndex = index;
  }

  keyboardButtonPress(event: KeyboardEvent){
    if (event.code == "ArrowDown"){
      if(this.selectedIndex == null){
        this.selectedIndex = 0;
      }
      else{
        if(this.selectedIndex < this.length){
          this.selectedIndex++;
        }
      }
      if (this.options != null){
        var selectedOption = this.options.filter(x => this.options.indexOf(x) == this.selectedIndex)
        this.selectedOptionValue = selectedOption[0].value;
        document.getElementById("autoCompleteIndex" + this.selectedIndex).scrollIntoView({block: "nearest", inline: "nearest"});
      }
      return false;
    }
    if(event.code == "ArrowUp"){
      if(this.selectedIndex == null){
        this.selectedIndex = 0;
      }
      else{
        if(this.selectedIndex <= this.options.length - 1){
          this.selectedIndex--;
        }
      }
      if (this.options != null){
        var selectedOption = this.options.filter(x => this.options.indexOf(x) == this.selectedIndex)
        this.selectedOptionValue = selectedOption[0].value;
        document.getElementById("autoCompleteIndex" + this.selectedIndex).scrollIntoView({block: "nearest", inline: "nearest"});
      }
      return false;
    }
    if(event.code == "Enter" || event.code == "Tab"){
      if (this.options != null){
          var selectedOption = this.options.filter(x => this.options.indexOf(x) == this.selectedIndex)
          this.searchString = selectedOption[0].label;
          this.setOption(null, selectedOption[0]);
          this.showDropDown = false;
        if (event.code == "Enter"){
          return false;
        }
      }
    }
  }

  leaveDropdown(){
    this.showDropDown = false;
  }

  onChange(){
    this.selectedIndex = null;
    this.selectedOptionValue = null;
    if (this.searchString == null || this.searchString == ""){
      this.showDropDown = false;
      if(this.selectType == "AutoComplete"){
        this.modelChange.emit({label: null, value: null});
      }
      this.store.dispatch(clearAutoCompleteList());
    }
    var options = {
      type: this.type,
      searchString: this.searchString,
      length: this.length,
    }
    this.store.dispatch(getInputAutocompleteList({options: options}))    
  }

// MULTISELECT OPTIONS

  // With MultiSelect option you will need to create a viewchild in order to 
  // call this method when saving or clearing selections on Parent Component
  onSave(){
    this.clearSelection(null);
    this.showDropDown = false;
    this.store.dispatch(clearAutoCompleteList());
    this.modelChange.emit([]);
  }

  toggleShowingCurrentSelection(e){
    e.preventDefault();
    e.stopPropagation();
    if(this.showCurrentSelections == false){
      this.showCurrentSelections = true;
    }
    else{
      this.showCurrentSelections = false;
    }
  }
  clearSelection(e){
    if (e != null){
      e.preventDefault();
      e.stopPropagation();
    }
    this.multiSelectOptions = [];
    this.currentSelectedOptions = [];
    this.modelChange.emit(this.multiSelectOptions);
  }
  hoverMultiSelectItem(item, index){
    this.selectedOptionValue = item.value;
    this.selectedIndex = index;
  }

  keyboardMultiSelectButtonPress(event: KeyboardEvent){
    if(event.code == "Tab"){
      this.showDropDown = false;
    }
  }
  
  leaveMultiSelectDropdown(){
    this.showDropDown = false;
  }
  
  clickIntoMultiSelectInput(){
    if(this.options != null){
      setTimeout(x => {this.onMultiSelectChange()}, 100);
    }
  }

  onMultiSelectChange(){
    var options = {
      type: this.type,
      searchString: this.searchString,
      length: this.length,
    }
    this.store.dispatch(getInputAutocompleteList({options: options})) 
  }
  
  multiSelectSetOption(e, item){
    if(e != null){
      e.preventDefault();
      e.stopPropagation();
    }
    if(this.multiSelectOptions == null || this.multiSelectOptions.length <= 0){
      this.multiSelectOptions = [];
    }
    if(this.currentSelectedOptions == null || this.currentSelectedOptions.length <= 0){
      this.currentSelectedOptions = [];
    }
    if(this.multiSelectOptions.indexOf(item.value) == -1){
      this.multiSelectOptions.push(item.value);
    }
    else{
      var rmso = this.multiSelectOptions.filter(x => x != item.value);
      this.multiSelectOptions = rmso;
    }
    if(this.currentSelectedOptions.indexOf(item) == -1){
      this.currentSelectedOptions.push(item);
    }
    else{
      var cso = this.currentSelectedOptions.filter(x => x != item);
      this.currentSelectedOptions = cso;
    }
    this.modelChange.emit(this.multiSelectOptions);
    this.msInput.nativeElement.focus();
  }

  isChecked(value){
    if(this.multiSelectOptions == null || this.multiSelectOptions.length <= 0){
      return false;
    }
    else{
      var found = this.multiSelectOptions.indexOf(value);
      if (found == -1){
        return false;
      }
      else{
        return true;
      }
    }
  }

}
