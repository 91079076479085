import { createAction, props } from '@ngrx/store';
import { PagingResultsModel } from '../../../models/pagingResultsModel';
import { FullTimeStatusCheckModel } from '../../../models/fullTimeStatusCheckModel';
import { SelectItem } from 'primeng/api';
import { EmployeeCensusModel, EmployeeModel, TerminatedEmployeeModel, UserProfileModel } from '../../../models/userProfileModel';
import { UserModel } from '../../../models/userModel';
import { FormError, ModifiedSelectItem } from '../../../models/utilModels';

export const loadViewableCampuses = createAction('[Employee] Load Campuses');
export const loadViewableCampusesSuccess = createAction('[Employee] Load Campuses Success',
  props<{ campuses: SelectItem[] }>());

export const loadEmployeeUsers = createAction('[Employee] Load Employee Users',
  props<{ prefixText: string, programId: number, payrollClassificationId: number, showTerminated: boolean, showInactive: boolean }>());
export const loadEmployeeUsersSuccess = createAction('[Employee] Load Employee Users Success',
  props<{ users: SelectItem[] }>());

export const getFullTimeStatusCheckList = createAction('[Employee] Get Full Time Status Check List',
  props<{ filter: any, userId: number, programId: number, startDate: string, endDate: string, minHours: string, maxHours: string, fullTimeStatus: string, includeInactive: boolean }>());
export const getFullTimeStatusCheckListSuccess = createAction('[Employee] Get Full Time Status Check List Success',
  props<{ statusList: PagingResultsModel<FullTimeStatusCheckModel> }>());

export const getFullTimeStatusQueue = createAction('[Employee] Get Full Time Status Queue',
  props<{ filter: any, userId: number, programId: number, startDate: string, endDate: string, minHours: string, maxHours: string, fullTimeStatus: string, includeInactive: boolean }>());
export const getFullTimeStatusQueueSuccess = createAction('[Employee] Get Full Time Status Queue Success',
  props<{ queue: PagingResultsModel<FullTimeStatusCheckModel> }>());


export const verifyCheck = createAction('[Employee] Verify Full Time Status Check',
  props<{ check: FullTimeStatusCheckModel }>());
export const verifyCheckSuccess = createAction('[Employee] Verify Full Time Status Check Success');

export const switchStatus = createAction('[Employee] Switch Full Time Status',
  props<{ check: FullTimeStatusCheckModel }>());
export const switchStatusSuccess = createAction('[Employee] Switch Full Time Status Success');

export const markAsExempt = createAction('[Employee] Mark Full Time Status Check As Exempt',
  props<{ check: FullTimeStatusCheckModel }>());
export const markAsExemptSuccess = createAction('[Employee] Mark Full Time Status Check As Exempt Success');

export const getEmployeeTerminationList = createAction('[Employee] Get Employee Termination List',
  props<{ filter: any }>());
export const getEmployeeTerminationListSuccess = createAction('[Employee] Get Employee Termination List Success',
  props<{ terminationList: PagingResultsModel<TerminatedEmployeeModel> }>());

export const getEmployeeCensusList = createAction('[Employee] Get Employee Census List',
  props<{ filter: any }>());
export const getEmployeeCensusListSuccess = createAction('[Employee] Get Employee Census List Success',
  props<{ censusList: PagingResultsModel<EmployeeCensusModel> }>());


export const exportEmployeeTerminationListToExcel = createAction('[Employee] Export Employee Termination List To Excel',
  props<{ filter: any }>());
export const exportEmployeeTerminationListToExcelSuccess = createAction('[Employee] Export Employee Termination List To Excel Success',
  props<{ doc: ArrayBuffer }>());
export const exportEmployeeTerminationListToExcelFailure = createAction('[Employee] Export Employee Termination List To Excel Failure');

export const exportEmployeeCensusListToExcel = createAction('[Employee] Export Employee Census List To Excel',
  props<{ filter: any }>());
export const exportEmployeeCensusListToExcelSuccess = createAction('[Employee] Export Employee Census List To Excel Success',
  props<{ doc: ArrayBuffer }>());
export const exportEmployeeCensusListToExcelFailure = createAction('[Employee] Export Employee Census List To Excel Failure');

export const exportEmployeeFullTimeStatusListToExcel = createAction('[Employee] Export Employee Full Time Status List To Excel',
  props<{ filter: any }>());
export const exportEmployeeFullTimeStatusListToExcelSuccess = createAction('[Employee] Export Employee Full Time Status List To Excel Success',
  props<{ doc: ArrayBuffer }>());
export const exportEmployeeFullTimeStatusListToExcelFailure = createAction('[Employee] Export Employee Full Time Status List To Excel Failure');

export const willNotRehireEmployee = createAction('[Employee] Will Not Rehire Employee', props<{ employee: EmployeeModel }>());
export const willNotRehireEmployeeSuccess = createAction('[Employee] Will Not Rehire Employee Success',
  props<{ employeeUser: UserModel }>());
export const willNotRehireEmployeeFailure = createAction('[Employee] Will Not Rehire Employee Failure');

export const rehireEmployee = createAction('[Employee] Rehire Employee', props<{ employee: EmployeeModel }>());
export const rehireEmployeeSuccess = createAction('[Employee] Rehire Employee Success',
  props<{ employeeUser: UserModel }>());
export const rehireEmployeeFailure = createAction('[Employee] Rehire Employee Failure');

export const clearRehireEmployee = createAction('[Employee] Clear Rehire Employee');
export const setRehireEmployee = createAction('[Employee] Clear Rehire Employee',
  props<{ employeeUser: UserModel }>());

export const showTerminateEmployee = createAction('[Employee] Show Terminate Employee Modal',
  props<{ employeeUser: TerminatedEmployeeModel }>());
export const closeTerminateEmployee = createAction('[Employee] Close Terminate Employee Modal');

export const loadTerminationReasons = createAction('[Employee] Load Termination Reasons');
export const loadTerminationReasonsSuccess = createAction('[Employee] Load Termination Reasons Success'
  , props<{ reasons: SelectItem[] }>());

export const loadSeparationSubReasons = createAction('[Employee] Load Separation Sub Reasons');
export const loadSeparationSubReasonsSuccess = createAction('[Employee] Load Separation Sub Reasons Success'
  , props<{ reasons: ModifiedSelectItem[] }>());

export const updateTerminateEmployeeForm = createAction('[Employee] Update Terminate Employee Form',
  props<{ formValues: any, formErrors: FormError[] }>());

export const saveTerminationReason = createAction('[Employee] Save Termination Reason',
  props<{ employee: TerminatedEmployeeModel }>());
export const saveTerminationReasonSuccess = createAction('[Employee] Save Termination Reason Success');
