import { CoachingLogService } from './../../services/coachingLog.service';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, tap, catchError, filter } from 'rxjs/operators';
import { of, } from 'rxjs';
import { errorHappened } from '../profile/profile.actions';
import { Store } from '@ngrx/store';
import { State } from '..';
import { AlertService } from 'src/app/shared/services/alert.service';
import {
  createCoachingLog, createCoachingLogSuccess, createCoachingLogFail,
  getMyGivenCoachingLog, getMyCoahingLog, markCoachingLogAsChecked, getMyGivenCoachingLogSuccess,
  getMyCoahingLogSuccess, markCoachingLogAsCheckedSuccess, markCoachingLogAsSeenSuccess,
  markCoachingLogAsSeen,
  getCoachableUsersList,
  getCoachableUsersListSuccess,
  getCategoriesByLevelListSuccess,
  getCategoriesByLevelList,
  getUserCoachingLogsList,
  getUserCoachingLogsListSuccess,
  updateCoachingLogSuccess,
  updateCoachingLogFail,
  updateCoachingLog,
  getCoachingLog,
  getCoachingLogSuccess
} from './coahingLog.actions';

@Injectable()
export class CoachingLogEffects {
  constructor(private actions$: Actions,
    private coachingLogService: CoachingLogService,
    private store: Store<State>,
    private alertService: AlertService,
    private router: Router) {

  }

  createCoachingLog = createEffect(() => this.actions$.pipe(
    ofType(createCoachingLog),
    switchMap(action => {
      return this.coachingLogService.addCoachingLog(action.coachingLogWithUsersModel).pipe(
        map(coachingLogWithUsersModel => createCoachingLogSuccess({ coachingLogWithUsersModel })),
        catchError(err => {
          this.store.dispatch(createCoachingLogFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));

  createCoachingLogSuccess = createEffect(() => this.actions$.pipe(
    ofType(createCoachingLogSuccess),
    tap(action => {
      this.alertService.success(
        'Coaching log added', false);
    })
  ), { dispatch: false });

  updateCoachingLog = createEffect(() => this.actions$.pipe(
    ofType(updateCoachingLog),
    switchMap(action => {
      return this.coachingLogService.updateCoachingLog(action.coachingLogWithUsersModel).pipe(
        map(coachingLogWithUsersModel => updateCoachingLogSuccess({ coachingLogWithUsersModel })),
        catchError(err => {
          this.store.dispatch(updateCoachingLogFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));

  updateCoachingLogSuccess = createEffect(() => this.actions$.pipe(
    ofType(updateCoachingLogSuccess),
    tap(action => {
      this.alertService.success('Coaching log updated', false);
    })
  ), { dispatch: false });


  getMyGivenCoahingLog = createEffect(() => this.actions$.pipe(
    ofType(getMyGivenCoachingLog),
    switchMap(action => {
      return this.coachingLogService.getMyGivenCoches(action.filter).pipe(
        map(coachingLogList => getMyGivenCoachingLogSuccess({ coachingLogList })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  getMyCoahingLog = createEffect(() => this.actions$.pipe(
    ofType(getMyCoahingLog),
    switchMap(action => {
      return this.coachingLogService.getMyCoches(action.filter).pipe(
        map(coachingLogList => getMyCoahingLogSuccess({ coachingLogList })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  getCoahingLog = createEffect(() => this.actions$.pipe(
    ofType(getCoachingLog),
    switchMap(action => {
      return this.coachingLogService.getCoachingLog(action.coachingLogID).pipe(
        map(coachingLog => getCoachingLogSuccess({ coachingLog })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  markCoachingLogAsChecked = createEffect(() => this.actions$.pipe(
    ofType(markCoachingLogAsChecked),
    switchMap(action => {
      return this.coachingLogService.markAsChecked(action.coachingLogID).pipe(
        map(coachingLogWithUsersModel => markCoachingLogAsCheckedSuccess({ coachingLogWithUsersModel })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));



  markCoachingLogAsSeen = createEffect(() => this.actions$.pipe(
    ofType(markCoachingLogAsSeen),
    switchMap(action => {
      return this.coachingLogService.markAsSeen(action.coachingLogID).pipe(
        map(coachingLogWithUsersModel => markCoachingLogAsSeenSuccess({ coachingLogWithUsersModel })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  getCoachableUsersList = createEffect(() => this.actions$.pipe(
    ofType(getCoachableUsersList),
    switchMap(action => {
      return this.coachingLogService.getCoachableUsers().pipe(
        map(usersList => getCoachableUsersListSuccess({ usersList })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  getCategoriesList = createEffect(() => this.actions$.pipe(
    ofType(getCategoriesByLevelList),
    switchMap(action => {
      return this.coachingLogService.getCategories(action.levelID).pipe(
        map(categoriesList => getCategoriesByLevelListSuccess({ categoriesList })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));
  
  getUserCoachingLogs = createEffect(() => this.actions$.pipe(
    ofType(getUserCoachingLogsList),
    switchMap(action => {
      return this.coachingLogService.getUserCoachingLogs(action.userID).pipe(
        map(userCoachingLogs => getUserCoachingLogsListSuccess({ userCoachingLogs })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));


}
