import { CreateEmployeePatientModel, InsuranceProviderModel } from './../../models/medClinicModels';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable()
export class MedClinicService {

  constructor(private http: HttpClient) {
  }

  getPatients(): Observable<CreateEmployeePatientModel[]> {
    return this.http.get<CreateEmployeePatientModel[]>(`${environment.apiServer.webApiURL}/MedClinic/GetPatients`);
  }

  save(patient: CreateEmployeePatientModel): Observable<CreateEmployeePatientModel> {
    return this.http.post<CreateEmployeePatientModel>(`${environment.apiServer.webApiURL}/MedClinic/CreatePatient`,
      patient);
  }
  getInsuranceProviders(): Observable<InsuranceProviderModel[]> {
    return this.http.get<InsuranceProviderModel[]>(`${environment.apiServer.webApiURL}/MedClinic/GetInsuranceProviders`);
  }

}

