import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { CertificationReportModel } from '../../../../models/certificationModel';
import { formatDate } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { getAllControlsErrors, updateFormGroupValidity, markFormGroupTouched } from '../../../../helpers/utils';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { initEditCertificationForm } from '../../../../core/store/certification/certification.reducer';
import { closeEditCertification, loadInstructors, saveCertification, saveCertificationSuccess, updateCertificationForm } from '../../../../core/store/certification/certification.action';
import { SelectItem } from 'primeng/api';
import { loadEmployeeUsers } from '../../../../core/store/employee/employee.action';

@Component({
  selector: 'app-edit-certification',
  templateUrl: './edit-certification.component.html',
  styleUrls: ['./edit-certification.component.scss']
})
export class EditCertificationComponent extends BaseComponent implements OnInit {
  display: boolean = false;
  certification: CertificationReportModel;
  title: string;
  form: FormGroup;
  disableButtons: boolean = true;
  campusId: number = 0;
  showOnlyActiveEmployees: boolean = true;
  showOnlyCurrentInstructors: boolean = true;

  certificationTypes: SelectItem[];
  selectedUser: SelectItem;
  userEmployeeList: SelectItem[];
  selectedInstructor: SelectItem;
  instructorList: SelectItem[];

  constructor(
    private actions$: Actions,
    private store: Store<State>) {
    super();
  }

  ngOnInit() {
    this.form = initEditCertificationForm({} as CertificationReportModel);
    this.subs.push(
      this.store.select(s => s.certificationState.editingCertification).subscribe(editingCertification => {
        this.display = editingCertification;
      }),
      this.store.select(s => s.certificationState.savingCertification).subscribe(savingCertification => {
        this.disableButtons = savingCertification;
      }),
      this.store.select(s => s.certificationState.selectedCertification).subscribe(cert => {        
        if (cert === null) {
          this.certification = null;
          return;
        }

        const changedCertification = !this.certification || (this.certification.certificationId !== cert.certificationId);
        this.certification = JSON.parse(JSON.stringify(cert));

        if (changedCertification) {
          this.form = initEditCertificationForm(this.certification);
          this.subs.push(
            this.form.valueChanges.subscribe(formValues => {
              const formErrors = getAllControlsErrors(this.form);
              this.store.dispatch(updateCertificationForm({ formValues, formErrors }));
            })
          )
          if (typeof cert.certificationId === 'undefined' || cert.certificationId === null || cert.certificationId < 1) {
            this.title = "Add Certification";
            this.selectedUser = { label: "", value: 0 };
            this.selectedInstructor = { label: "", value: 0 };
            this.store.dispatch(updateCertificationForm({ formValues: this.form.value, formErrors: getAllControlsErrors(this.form) }));
          } else {
            this.title = "Renew Certification #" + cert.certificationId;
            this.selectedUser = { label: cert.firstName + " " + cert.lastName, value: cert.userId };
            this.selectedInstructor = { label: cert.instructorFirstName + " " + cert.instructorLastName, value: cert.instructorId };
            this.certification.issueDateString = formatDate(cert.issueDate, 'yyyy-MM-dd', 'en-US');
            this.certification.expirationDateString = formatDate(cert.expirationDate, 'yyyy-MM-dd', 'en-US');
          }
        }
      }),
      this.store.select(s => s.certificationState.certificationTypes).subscribe(types => {
        if (types && types.length > 0) {
          this.certificationTypes = types.map(x => ({ label: x.label, value: x.value }));
          this.certificationTypes.unshift({ label: '', value: 0 });
        }
        else {
          this.certificationTypes = [{ label: 'loading...', value: undefined }]
        }
      }),
      this.store.select(s => s.employeeState.employeeUsers).subscribe(users => {
        this.userEmployeeList = users;
      }),
      this.store.select(s => s.certificationState.instructors).subscribe(instructors => {
        this.instructorList = instructors;
      }),      
      this.actions$.pipe(
        ofType(saveCertificationSuccess),
        tap(action => {
          this.closeModal();
        })
      ).subscribe(),      
    );
  }

  getStaffList(event) {
    this.store.dispatch(loadEmployeeUsers({
      prefixText: event.query,
      programId: this.campusId,
      payrollClassificationId: null,
      showTerminated: !this.showOnlyActiveEmployees,
      showInactive: !this.showOnlyActiveEmployees
    }));
  }

  setUserAfterStaffChange(selectedUser) {
    if (typeof selectedUser.value !== 'number') {
      return;
    }

    this.form.get('userId').setValue(selectedUser.value);
  }

  clearUserAfterClear(event) {
    if (event.data !== null) {
      return;
    }

    this.form.get('userId').setValue(0);
  }

  getInstructorList(event) {
    this.store.dispatch(loadInstructors({
      prefixText: event.query,
      certificationTypeId: this.certification.certificationTypeId != null ? this.certification.certificationTypeId : 0,
      showArchived: !this.showOnlyCurrentInstructors,
      showTerminated: !this.showOnlyCurrentInstructors,
      showInactive: !this.showOnlyCurrentInstructors
    }));
  }

  setInstructorAfterInstructorChange(selectedInstructor) {
    if (typeof selectedInstructor.value !== 'number') {
      return;
    }

    this.form.get('instructorId').setValue(selectedInstructor.value);
  }

  clearInstructorAfterClear(event) {
    if (event.data !== null) {
      return;
    }

    this.form.get('instructorId').setValue(0);
  }

  closeModal() {
    this.store.dispatch(closeEditCertification());
  }

  saveCertificationEdits() {
    updateFormGroupValidity(this.form);
    if (this.form.valid) {
      this.store.dispatch(saveCertification({ certification: this.certification }));
    } else {
      markFormGroupTouched(this.form);
    }
  }  
}
