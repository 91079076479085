import { Action, createReducer, on } from '@ngrx/store';
import { TicketType } from 'src/app/models/ticketTypeModels';
import { loadTicketTypes, loadTicketTypesSuccess, loadTicketTypesFailure, loadTicketType, loadTicketTypeSuccess, loadTicketTypeFailure, createTicketType, createTicketTypeSuccess, createTicketTypeFailure, updateTicketType, updateTicketTypeSuccess, updateTicketTypeFailure, deleteTicketType, deleteTicketTypeSuccess, deleteTicketTypeFailure } from './ticket-types.actions';

export interface TicketTypesState {
  ticketTypes: TicketType[];
  selectedTicketType: TicketType | null;
  error: any;
  loading: boolean;
}

export const initialState: TicketTypesState = {
  ticketTypes: [],
  selectedTicketType: null,
  error: null,
  loading: false,
};

export const ticketTypesReducer = createReducer(
  initialState,
  on(loadTicketTypes, state => ({
    ...state,
    loading: true,
  })),
  on(loadTicketTypesSuccess, (state, { ticketTypes }) => ({
    ...state,
    ticketTypes,
    loading: false,
  })),
  on(loadTicketTypesFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),
  on(loadTicketType, state => ({
    ...state,
    loading: true,
  })),
  on(loadTicketTypeSuccess, (state, { ticketType }) => ({
    ...state,
    selectedTicketType: ticketType,
    loading: false,
  })),
  on(loadTicketTypeFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),
  on(createTicketType, state => ({
    ...state,
    loading: true,
  })),
  on(createTicketTypeSuccess, (state, { ticketType }) => ({
    ...state,
    ticketTypes: [...state.ticketTypes, ticketType],
    loading: false,
  })),
  on(createTicketTypeFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),
  on(updateTicketType, state => ({
    ...state,
    loading: true,
  })),
  on(updateTicketTypeSuccess, state => ({
    ...state,
    loading: false,
  })),
  on(updateTicketTypeFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),
  on(deleteTicketType, state => ({
    ...state,
    loading: true,
  })),
  on(deleteTicketTypeSuccess, state => ({
    ...state,
    loading: false,
  })),
  on(deleteTicketTypeFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  }))
);

export function ticketTypesStateReducerFunc(state: TicketTypesState | undefined, action: Action) {
  return ticketTypesReducer(state, action);
}