import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { SelectItem } from 'primeng/api';
import { deepClone } from '../../../../helpers/utils';
import { DateRangeModel } from '../../../../models/dateRangeModel';
import { AlertService } from '../../../../shared/services/alert.service';
import { DropdownFilterService } from '../../../../core/services/dropdown-filter.service';
import { getBenefitPlanEDITransmissions } from '../../../../core/store/benefitPlanEDI/benefitPlanEDI.action';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { BenefitPlanEDITransmissionModel } from '../../../../models/benefitPlanEDITransmissionModel';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-report-benefitPlanEDI',
  templateUrl: './report-benefitPlanEDI.component.html',
  styleUrls: ['./report-benefitPlanEDI.component.scss']
})
export class ReportBenefitPlanEDIComponent extends BaseComponent implements OnInit {
  filter: any = {  };
  list: PagingResultsModel<BenefitPlanEDITransmissionModel>;

  loadingList: boolean = true;

  fileNameFilter;
  carrierFilter;
  MotivHealthEnabled;
  SameraHealthEnabled;
  
  dateRangeList: SelectItem[];
  dateRange: DateRangeModel;
  startDate: string = null;
  endDate: string = null;
  isCustomDateRange: boolean = false;

  
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private dropdownFilterService: DropdownFilterService  ) {
    super();
  }

  ngOnInit() {
    this.subs.push(

      this.store.select(s => s.accountState.featureSwitchesIds).subscribe(featureSwitchesIds => {
        this.MotivHealthEnabled = featureSwitchesIds && !!featureSwitchesIds.find(p => p === 121);
        this.SameraHealthEnabled = featureSwitchesIds && !!featureSwitchesIds.find(p => p === 122);
      }),

      this.store.select(s => s.benefitPlanEDIState.loadingTransmissions).subscribe(loading => {
        this.loadingList = loading;
      }),
      this.store.select(s => s.benefitPlanEDIState.transmissions).subscribe(list => {
        this.list = list;
      }),
    );

    this.dateRangeList = this.dropdownFilterService.getLast120DayDateRanges().map(c => ({ label: c.text, value: c }));

    
  }  

  changedDateRange() {
    if (this.dateRange.isCustom) {
      this.isCustomDateRange = true;
      return; //reload after manually changing date
    }

    this.isCustomDateRange = false;
    this.startDate = this.dateRange.startDate;
    this.endDate = this.dateRange.endDate;
    this.reloadList(this.filter);
  }

  changedFilters() {
    this.reloadList(this.filter);
  }


  reloadList(event) {
    this.filter = event;
    this.filter = deepClone(this.filter || { filters: {} });
    this.filter.filters.fileNameFilter = { Name: 'FileName', Value: { value: this.fileNameFilter }, MatchMode: 'contains' };
    this.filter.filters.carrierFilter = { Name: 'Carrier', Value: { value: this.carrierFilter }, MatchMode: 'contains' };
    this.filter.filters.startDate = { Name: 'DateSent', Value: { value: this.startDate }, MatchMode: 'gte' };
    this.filter.filters.endDate = { Name: 'DateSent', Value: { value: this.endDate }, MatchMode: 'lte'  };
    
    this.store.dispatch(getBenefitPlanEDITransmissions({
      filter: this.filter
    }));
  }

}
