import { OnBoardingDocumentUserModel } from './../../../../models/onBoardingDocumentUserModel';
import { UserProfileModel } from '../../../../models/userProfileModel';
import { loadProfile } from '../../../../core/store/profile/profile.actions';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from '../../../../shared/services/alert.service';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { Actions, ofType } from '@ngrx/effects';
import * as moment from 'moment';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { loadOnboardingDocInfo } from 'src/app/core/store/onboardingdocs/onboardingdocs.actions';

@Component({
  selector: 'app-doc-fill-info-hr',
  templateUrl: './doc-fill-info-hr.component.html',
  styleUrls: ['./doc-fill-info-hr.component.scss']
})
export class DocFillInfoHRComponent extends BaseComponent implements OnInit {

  profile: UserProfileModel;
  isBCI: boolean;
  isI9Form: boolean;
  onboardingDocUserId;
  docInfo: OnBoardingDocumentUserModel;

  constructor(
    private store: Store<State>,
    private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params.onboardingDocUserId) {
        this.onboardingDocUserId = params.onboardingDocUserId;
        this.store.dispatch(loadOnboardingDocInfo({ onboardingDocUserId: this.onboardingDocUserId }));
      }
    });
    this.subs.push(
      this.store.select(s => s.onBoardingDocsState.docInfo).subscribe(docInfo => {
        if (docInfo) {
          this.docInfo = docInfo;
          this.isBCI = docInfo.onBoarding_Document.fileName.startsWith('BCI');
          this.isI9Form = docInfo.onBoarding_Document.fileName.startsWith('i9Form');
        }
      }),
    );

  }

}
