<form class="card mt-2">
  <div class="card-body">
    <div class="float-right">
      <button *ngIf="userId == 0 || userId == currentUserId" type="button" routerLink="/timeoff/my" class="btn btn-secondary">View Time Off</button>
    </div>
    <div *ngIf="summary">
      <h2 class="mb-3">{{summary.employeeName}}'s PTO <i class="fas fa-sync" title="Refresh Data" (click)="refreshData()" style="cursor: pointer; font-size: small;"></i></h2>
      <div class="mb-3 row">

        <div class="col-xl-4 col-lg-12">
          <div class="row">
            <div class="col-6">
              <h5>Total Earned</h5>
            </div>
            <div class="col-6">
              {{summary.totalEarnedString}}
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <h5>Total Requests Approved</h5>
            </div>
            <div class="col-6">
              {{summary.totalUsedString}}
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <h5>Net Total</h5>
            </div>
            <div class="col-6">
              {{summary.totalNetString}}
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-12">
          <div class="row">
            <div class="col-6">
              <h5>Total Requests Pending</h5>
            </div>
            <div class="col-6">
              {{summary.totalPendingString}}
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <h5>Total Available</h5>
            </div>
            <div class="col-6">
              {{summary.totalAvailableString}}
            </div>
          </div>
        </div>

        <div class="col-xl-4 col-lg-12">
          <div class="row">
            <!--Based on date hired-->
            <div class="col-6">
              <h5>YTD Total Earned</h5>
            </div>
            <div class="col-6">
              {{summary.totalYtdString}}
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <h5>Current Percentage</h5>
            </div>
            <div class="col-6">
              {{summary.currentPercentageString}}
            </div>
          </div>

        </div>
      </div>
      <div [hidden]="!showEarned">
        <h3>
          PTO Earned
          <span style="color: blue; font-size:small; cursor: pointer;" (click)="toggleShowEarned()">View Requests</span>
        </h3>

        <i [hidden]="!loadingEarnedList" class="material-icons rotate text-muted">loop</i>
        <p-table [value]="earnedList?.result" [responsive]="true" [paginator]="true" [rows]="20"
                 [totalRecords]="earnedList?.totalRecords" [lazy]="true" (onLazyLoad)="reloadEarnedList($event)">
          <ng-template pTemplate="header">
            <tr>
              <th [pSortableColumn]="'DateEarned'">Date Earned<p-sortIcon [field]="'DateEarned'"></p-sortIcon></th>
              <th [pSortableColumn]="'ApprovedPayrollMinutes'">Approved Payroll Hours<p-sortIcon [field]="'ApprovedPayrollMinutes'"></p-sortIcon></th>
              <th [pSortableColumn]="'PayrollStartDate'">Pay Period<p-sortIcon [field]="'PayrollStartDate'"></p-sortIcon></th>
              <th [pSortableColumn]="'PercentTimeOff'">Percent Time Off<p-sortIcon [field]="'PercentTimeOff'"></p-sortIcon></th>
              <th [pSortableColumn]="'MinutesEarned'">Pto Earned<p-sortIcon [field]="'MinutesEarned'"></p-sortIcon></th>
              <th [pSortableColumn]="'MinutesLost'">Minutes Lost<p-sortIcon [field]="'MinutesLost'"></p-sortIcon></th>
              <th [pSortableColumn]="'ExceedsMaxNet'">Reason Lost<p-sortIcon [field]="'ExceedsMaxNet'"></p-sortIcon></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-ptoEarned>
            <tr>
              <td>
                <span class="ui-column-title">Date Earned</span>
                {{ptoEarned.dateEarned | date: 'MM/dd/yyyy'}}
              </td>
              <td>
                <span class="ui-column-title">Approved Payroll Hours</span>
                {{(ptoEarned.approvedPayrollMinutes !== null) ? (ptoEarned.approvedPayrollMinutes | duration) : '-'}}
              </td>
              <td>
                <span class="ui-column-title">Pay Period</span>
                {{ptoEarned.payrollStartDate | date: 'MM/dd/yyyy' || ''}} - {{ptoEarned.payrollEndDate | date: 'MM/dd/yyyy' || ''}}
              </td>
              <td>
                <span class="ui-column-title">Percent Time Off</span>
                {{ptoEarned.percentTimeOff | percent: '1.2'}}
              </td>
              <td>
                <span class="ui-column-title">Pto Earned</span>
                {{ptoEarned.minutesEarned | duration}}
              </td>
              <td>
                <span class="ui-column-title">Pto Lost</span>
                {{ptoEarned.minutesLost | duration}}
              </td>
              <td>
                <span class="ui-column-title">Reason Lost</span>
                <span *ngIf="ptoEarned.approvedPayrollMinutes !== null && (ptoEarned.approvedPayrollMinutes / 60 > 80 || (ptoEarned.payPeriodApprovedMinutes / 60 > 80 && ptoEarned.minutesLost > 0))" style="display: block;">Approved Pay Period Hours > 80</span>
                <span *ngIf="ptoEarned.exceedsMaxNet" style="display: block;">Net Total > max allowed</span>
                <span *ngIf="ptoEarned.exceedsMaxYtd" style="display: block;">YTD Total > max allowed</span>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div [hidden]="showEarned">
        <h3>
          PTO Requests
          <span style="color: blue; font-size:small; cursor: pointer;" (click)="toggleShowEarned()">View Earned</span>
        </h3>
        <div class="mb-3">
          <button *ngIf="userId === 0 || userId === currentUserId" (click)="editPtoRequest([])" class="btn btn-secondary">New PTO Request</button>
        </div>
        <i [hidden]="!loadingRequestedPtoList" class="material-icons rotate text-muted">loop</i>
        <p-table [value]="requestedList?.result" [responsive]="true" [paginator]="true" [rows]="20"
                 [totalRecords]="requestedList?.totalRecords" [lazy]="true" (onLazyLoad)="reloadRequestedList($event)">
          <ng-template pTemplate="header">
            <tr>
              <th width="100px"></th>
              <th [pSortableColumn]="'Date'">Date(s) Used<p-sortIcon [field]="'Date'"></p-sortIcon></th>
              <th [pSortableColumn]="'PtoMinutesUsed'">Pto Used<p-sortIcon [field]="'PtoMinutesUsed'"></p-sortIcon></th>
              <th [pSortableColumn]="'Status'">Status<p-sortIcon [field]="'Status'"></p-sortIcon></th>
              <th [pSortableColumn]="'Comments'">Comments<p-sortIcon [field]="'Comments'"></p-sortIcon></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-ptoRequested>
            <tr>
              <td>
                <button class="btn btn-secondary mr-2" *ngIf="(userId === 0 || userId === currentUserId) && !ptoRequested.approved && !ptoRequested.denied && !ptoRequested.addedByPayroll" (click)="editPtoRequest(ptoRequested)" title="Edit PTO Request">
                  <i class="fas fa-edit"></i>
                </button>
              </td>
              <td>
                <span class="ui-column-title">Date</span>
                {{ptoRequested.date | date: 'MM/dd/yyyy'}}<span *ngIf="ptoRequested.startTime !== ptoRequested.endTime"> - {{ptoRequested.endTime | date: 'MM/dd/yyyy'}}</span>
              </td>
              <td>
                <span class="ui-column-title">Pto Used</span>
                {{ptoRequested.ptoMinutesUsed | duration}}
              </td>
              <td>
                <span class="ui-column-title">Status</span>
                {{ptoRequested.status}}
              </td>
              <td>
                <span class="ui-column-title">Comments</span>
                {{ptoRequested.comments}}
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</form>

<app-edit-pto-request></app-edit-pto-request>
