<form #templateForm="ngForm" class="card mt-2" *ngIf="canView || canEdit">
  <div class="card-body">
    <div class="float-right">
      <button type="button" routerLink="/employee-review/template/list" class="btn btn-secondary">Manage Templates</button>
    </div>
    <h2 class="mb-3">Employee Review Template</h2>
    <div class="mb-3">
      <div class="row">
        <div class="col-2 d-flex">
          <b>Description:</b>
        </div>
        <div class="col-10">
          <textarea class="form-control w-100" [(ngModel)]="template.description" [ngModelOptions]="{standalone: true}"></textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <b>Job Titles</b>
        </div>
        <div class="col-10">
          <p-multiSelect class="w-100" styleClass="w-100 d-flex mt-2" inputStyleClass="form-control" [options]="jobTitleList" [(ngModel)]="template.jobTitleIDs" [ngModelOptions]="{standalone: true}" [defaultLabel]="'Select ...'"></p-multiSelect>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-2 d-flex">
          <b>Score Type:</b>
        </div>
        <div class="col-10">
          <p-dropdown styleClass="form-control w-100 mb-2" [showClear]="true" [options]="scoreTypes" optionLabel="scoreType" [(ngModel)]="template.scoreType" [ngModelOptions]="{standalone: true}" placeholder="Select a value...">
          </p-dropdown>

        </div>
      </div>
      <div class="row mt-2">
        <div class="col-2 d-flex">
          <b>Requires 2nd Oversight Review:</b>
        </div>
        <div class="col-10">
          <div class="col-lg-1 col-md-2">
            <p-checkbox [(ngModel)]="template.requiresOversightReview" [binary]="true" [ngModelOptions]="{standalone: true}"></p-checkbox>
          </div>
        </div>
      </div>
      <div *ngFor="let kra of template.kras; let i=index">
        <app-kra-template-component [kra]="kra" [kraIndex]="i"></app-kra-template-component>
      </div>

      <div *ngIf="errors && errors.length > 0">
        <div class=" col-12 alert alert-danger">
          <ul *ngFor="let e of errors;">
            <li>{{e}}</li>
          </ul>
        </div>
      </div>
      <div class="row">

        <div class="col d-flex">
          <button type="button" [disabled]="loading" class="btn btn-success ml-2 " (click)="addNewKra()">Add KRA</button>
          <button type="button" [disabled]="loading" class="btn btn-primary ml-2 " (click)="save()">Save</button>
          <i *ngIf="!!loading" class="material-icons rotate text-muted">loop</i>
        </div>
      </div>
    </div>
  </div>
</form>
<app-kpi-ethics-codes-component></app-kpi-ethics-codes-component>
