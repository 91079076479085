<form class="card mt-2">
  <div class="card-body">
    <div class="float-right">
      <button *ngIf="canViewSelfCareReport" type="button" routerLink="/selfcare/report" class="btn btn-secondary">Report</button>
    </div>
    <h2 class="mb-3">Oversight Self Care</h2>

    <i [hidden]="!loadingList" class="material-icons rotate text-muted">loop</i>
    <p-table [value]="list" [responsive]="true" [paginator]="true" [rows]="20">
      <ng-template pTemplate="header">
        <tr>
          <th width="100px"></th>
          <th [pSortableColumn]="'fullName'">Employee <p-sortIcon [field]="'fullName'"></p-sortIcon></th>
          <th [pSortableColumn]="'primaryProgram'">Campus <p-sortIcon [field]="'primaryProgram'"></p-sortIcon></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-employee>
        <tr>
          <td>
            <button class="btn btn-secondary mr-2" (click)="openSelfcarePlanner(employee)" title="View Oversight Employee Self Care">
              <i class="far fa-window-frame"></i>
            </button>

          </td>
          <td>
            <span class="ui-column-title">Employee</span>
            {{employee.fullName}}
          </td>
          <td>
            <span class="ui-column-title">Campus</span>
            {{employee.primaryProgram}}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</form>
