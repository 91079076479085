import { additTimeCard, loadManualEntryReasons, updateTimeCardForm } from './../../../../core/store/timecard/timecard.actions';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { loadClients } from 'src/app/core/store/timecard/timecard.actions';
import { TimeCardModel } from 'src/app/models/timeCardModel';
import { FormGroup } from '@angular/forms';
import { initTimeCardEditForm } from 'src/app/core/store/timecard/timecard.reducer';
import { SelectItem } from 'primeng/api';
import { markFormGroupTouched, getAllControlsErrors, updateFormGroupValidity } from 'src/app/helpers/utils';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';

@Component({
  selector: 'app-timecard-addit',
  templateUrl: './timecard-addit.component.html',
  styleUrls: ['./timecard-addit.component.scss']
})
export class TimeCardAddItComponent extends BaseComponent  implements OnInit {

  timeCard: TimeCardModel;
  clients: SelectItem[];
  form: FormGroup;
  manualEntryReasonOptions: SelectItem[];

  constructor(
    private store: Store<State>) {
      super();
     }

  ngOnInit() {
    this.store.dispatch(loadClients());
    this.store.dispatch(loadManualEntryReasons());
    this.subs.push(
    this.store.select(s => s.timecardState.clients).subscribe(clients => {
      this.clients = clients ? clients.map(c => ({ label: c.clientName, value: c.id })) : [];
    }),
      this.store.select(s => s.timecardState.manualEntryReasons).subscribe(reasons => {
        this.manualEntryReasonOptions = reasons ? reasons.map(c => ({ label: c.reason, value: c.id })) : [];
      }),
    this.store.select(s => s.timecardState.editingTimeCard).subscribe(timeCard => {
      const changedTimeCard = !this.timeCard || (this.timeCard.id !== timeCard.id);
      this.timeCard = JSON.parse(JSON.stringify(timeCard));
      if (changedTimeCard) {
        this.form = initTimeCardEditForm(this.timeCard);
        this.subs.push(
          this.form.valueChanges.subscribe(formValues => {
          const formErrors = getAllControlsErrors(this.form);
          this.store.dispatch(updateTimeCardForm({ formValues, formErrors }));
        }));
      }
    })
    );
  }

  save() {
    updateFormGroupValidity(this.form);
    if (this.form.valid) {
      this.store.dispatch(additTimeCard({ timeCard: this.timeCard }));
    } else {
      markFormGroupTouched(this.form);
    }
  }

}
