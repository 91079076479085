import { FBFormModel } from './../../models/formBuilderModels';
import { PagingResultsModel } from './../../models/pagingResultsModel';
import { UserModel } from 'src/app/models/userModel';
import { OfferLetterModel, OfferLetterWithUserModel } from 'src/app/models/offerletterModel';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PayPeriodModel } from 'src/app/models/payPeriodModel';
import { deepClone } from 'src/app/helpers/utils';


@Injectable()
export class OfferLetterService {

  constructor(private http: HttpClient) {
  }

  getOfferLetterDoc(offerLetterID: number): Observable<ArrayBuffer> {
    return this.http.get<ArrayBuffer>(`${environment.apiServer.webApiURL}/OfferLetter/OfferLetterDoc/${offerLetterID}`,
      { responseType: 'arraybuffer' as 'json' });
  }

  signOfferLetterDoc(offerLetterID: number, signedFields: string[]): Observable<ArrayBuffer> {
    return this.http.post<ArrayBuffer>(`${environment.apiServer.webApiURL}/OfferLetter/AcceptAndSignOffer/${offerLetterID}`,
      signedFields,
      { responseType: 'arraybuffer' as 'json' });
  }

  checkOfferLetter(offerLetterWithUser: OfferLetterWithUserModel) {
    let newOfferLetterWithUser;
    if (offerLetterWithUser.offerLetter.typePosition === 'Other') {
      newOfferLetterWithUser = deepClone(offerLetterWithUser);
      newOfferLetterWithUser.offerLetter.typePosition = newOfferLetterWithUser.offerLetter.typePositionOther;
    } else {
      newOfferLetterWithUser = offerLetterWithUser;
    }
    return newOfferLetterWithUser;
  }
  createOfferLetter(offerLetterWithUser: OfferLetterWithUserModel): Observable<ArrayBuffer> {
    const newOfferLetterWithUser = this.checkOfferLetter(offerLetterWithUser);
    return this.http.post<ArrayBuffer>(`${environment.apiServer.webApiURL}/OfferLetter/Create`,
    newOfferLetterWithUser,
      { responseType: 'arraybuffer' as 'json' });
  }

  previewOfferLetter(offerLetterWithUser: OfferLetterWithUserModel): Observable<ArrayBuffer> {
    const newOfferLetterWithUser = this.checkOfferLetter(offerLetterWithUser);
    return this.http.post<ArrayBuffer>(`${environment.apiServer.webApiURL}/OfferLetter/Preview`,
      offerLetterWithUser,
      { responseType: 'arraybuffer' as 'json' });
  }

  saveOfferLetter(offerLetterWithUser: OfferLetterWithUserModel) {
    const newOfferLetterWithUser = this.checkOfferLetter(offerLetterWithUser);
    return this.http.post<OfferLetterWithUserModel>(`${environment.apiServer.webApiURL}/OfferLetter/Save`,
    newOfferLetterWithUser);
  }

  getList(filters: any): Observable<PagingResultsModel<OfferLetterWithUserModel>> {
    return this.http.post<PagingResultsModel<OfferLetterWithUserModel>>(`${environment.apiServer.webApiURL}/OfferLetter/Search`, filters);
  }

  getOfferLetterPdf(offerLetterID: number): Observable<ArrayBuffer> {
    return this.http.get<ArrayBuffer>(`${environment.apiServer.webApiURL}/OfferLetter/GetOfferPdf/${offerLetterID}`,
      { responseType: 'arraybuffer' as 'json' });
  }

  getOldOfferLetterPdf(oldOfferLetterID: number): Observable<ArrayBuffer> {
    return this.http.get<ArrayBuffer>(`${environment.apiServer.webApiURL}/OfferLetter/GetOldOfferPdf/${oldOfferLetterID}`,
      { responseType: 'arraybuffer' as 'json' });
  }

  deleteOfferLetter(offerLetterID: number) {
    return this.http.delete<any>(`${environment.apiServer.webApiURL}/OfferLetter/${offerLetterID}`);
  }

  getOfferLetterInfo(offerLetterID: number) {
    return this.http.get<OfferLetterWithUserModel>(`${environment.apiServer.webApiURL}/OfferLetter/${offerLetterID}`);
  }


  markOfferLetterSeen(offerLetterID: number) {
    return this.http.get<OfferLetterWithUserModel>(`${environment.apiServer.webApiURL}/OfferLetter/MarkAsSeen/${offerLetterID}`);
  }

  checkUsername(username: string) {
    return this.http.post<any>(`${environment.apiServer.webApiURL}/OfferLetter/checkUsername`, {username});
  }

  getAvailableForms() {
    return this.http.get<FBFormModel[]>(`${environment.apiServer.webApiURL}/OfferLetter/AvailableForms`);
  }
}
