import { createAction, props } from '@ngrx/store';
import { HolidayModel, RecurringHolidayModel } from '../../../models/holidayModel';
import { PagingResultsModel } from '../../../models/pagingResultsModel';
import { FormError } from '../../../models/utilModels';
import { SelectItem } from 'primeng/api';
import { StaffInjuryModel } from '../../../models/staffInjuryModel';

export const loadInjuriesQueue = createAction('[Injury Queue] Load Injuries', props<{ filter: any}>());
export const loadInjuriesQueueSuccess = createAction('[Injury Queue] Load Injuries Success', props<{ injuriesQueue: PagingResultsModel<StaffInjuryModel> }>());

export const markInjuryAsCompleted = createAction('[Injury Queue] Mark as Completed', props<{ staffInjuryId: number }>());
export const markInjuryAsCompletedSuccess = createAction('[Injury Queue] Mark as Completed Success', props());

export const updateStaffInjuryForm = createAction('[Injury Queue] Update Staff Injury Form',
  props<{ staffInjuryValues: any, formErrors: FormError[] }>());
export const clearStaffInjuryForm = createAction('[Injury Queue]  Clear Staff Injury Form');

export const setStaffInjuryToUpdate = createAction('[Injury Queue] Set Staff Injury to Update',
  props<{ staffInjury: StaffInjuryModel }>());
export const updateStaffInjury = createAction('[Injury Queue] Update Staff Injury',
  props<{ staffInjury: StaffInjuryModel }>());
export const updateStaffInjurySuccess = createAction('[Injury Queue] Update Staff Injury Success',
  props<{ staffInjury: StaffInjuryModel }>());
export const updateStaffInjuryFail = createAction('[Injury Queue] Update Staff Injury Fail',
  props<{ err: any }>());


export const uploadStaffInjuryInvoice = createAction('[Injury Queue] Upload Invoice', props<{ staffInjuryId: number, file: File }>());
export const uploadStaffInjuryInvoiceSuccess = createAction('[Injury Queue] Upload Invoice Success');
export const uploadStaffInjuryInvoiceFailure = createAction('[Injury Queue] Upload Invoice Failure');

export const downloadStaffInjuryInvoice = createAction('[Injury Queue] Download Invoice', props<{ staffInjuryId: number }>());
export const downloadStaffInjuryInvoiceSuccess = createAction('[Injury Queue] Download Invoice Success', props<{ invoice: ArrayBuffer }>());
