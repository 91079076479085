<form class="card mt-2">
    <div class="card-body">
        <button *ngIf="canEdit" class="btn btn-primary mr-2 float-right pointer" (click)="clearFilters()">Clear Filters</button>
        <button class="btn btn-primary float-right pointer mr-2" (click)="openLink('/prospective-candidate/list')">Prospective Candidates Page</button>
        <h2 class="mb-3">Job Applicants</h2>


        <h5>Filter <button class="btn btn-link" (click)="toggleHideFilter()">{{showHideString}}</button></h5>
        <div class="row mb-1" [hidden]="hideFilter">
          <div class="col-md-6 col-sm-12 col-xs-12">
            <div class="row mb-1" >
              <div class="col-4 d-flex align-items-center">
                <h6>Applicant Name</h6>
              </div>
              <div class="col-8 ">
                <input type="text" [(ngModel)]="employeeNameFilter" class="form-control" (ngModelChange)="changedFilters()"  [ngModelOptions]="{standalone: true}">
              </div>
            </div>

            <div class="row mb-1" >
              <div class="col-4 d-flex align-items-center">
                <h6>Email</h6>
              </div>
              <div class="col-8 ">
                <input type="text" [(ngModel)]="emailFilter" class="form-control" (ngModelChange)="changedFilters()"  [ngModelOptions]="{standalone: true}">
              </div>
            </div>

            <div class="row mb-1" >
              <div class="col-4 d-flex align-items-center">
                <h6>Application Date</h6>
              </div>
              <div class="col-8 ">
                <p-dropdown styleClass="form-control  w-100" [options]="datesOptions" placeholder="Select ..."  [(ngModel)]="createdDateOptionFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"></p-dropdown>

                <p-calendar *ngIf="createdDateOptionFilter==0"
                  inputStyleClass="form-control "
                  [monthNavigator]="true"
                  [yearNavigator]="true"
                  styleClass="w-75"
                  panelStyleClass="calendarPanel"
                  [hourFormat]="12"
                  [yearRange]="'2010:2100'"
                  [showTime]="false"
                  [selectionMode]="'range'"
                  [(ngModel)]="createdDateFilter" (ngModelChange)="changedDateFilters()" [ngModelOptions]="{standalone: true}"
                  dataType="string"
                ></p-calendar>
              </div>
            </div>
            <div class="row  mb-1" >
              <div class="col-4 d-flex align-items-center">
                <b>Positions</b>
              </div>
              <div class="col-8">
                <i *ngIf="!jobTitlesList" class="material-icons rotate text-muted">loop</i>
                <p-multiSelect #positionsMultiSelect (onFocus)="openMultiSelect(positionsMultiSelect)" autofocusFilter="true" class="w-100" styleClass="w-100 d-flex " inputStyleClass="form-control"
                 [options]="jobTitlesList" [defaultLabel]="'Select ...'"
                 [(ngModel)]="positionsFilter" (ngModelChange)="multiSelectChange()" [ngModelOptions]="{standalone: true}"></p-multiSelect>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-12 col-xs-12">
            <div class="row  mb-1" >
              <div class="col-4 d-flex align-items-center">
                <b>Region</b>
              </div>
              <div class="col-8">
                <i *ngIf="!campusesList" class="material-icons rotate text-muted">loop</i>
                <p-dropdown styleClass="form-control  w-100" [options]="campusesList" placeholder="Select campus..."  [(ngModel)]="regionFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"></p-dropdown>
              </div>
            </div>

            <div class="row mt-2 mb-1" >
              <div class="col-4 d-flex align-items-center">
                <b>Include Archived</b>
              </div>
              <div class="col-8">
                <p-checkbox  [(ngModel)]="removedFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}" [binary]="true" ></p-checkbox>
              </div>
            </div>
            <div class="row mt-2 mb-1" >
              <div class="col-4 d-flex align-items-center">
                <b>Include Prospective Candidates</b>
              </div>
              <div class="col-8">
                <p-checkbox  [(ngModel)]="hiredFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}" [binary]="true" ></p-checkbox>
              </div>
            </div>

            <div class="row mt-2 mb-1" >
              <div class="col-4 d-flex align-items-center">
                <b>Only Referrals</b>
              </div>
              <div class="col-8">
                <p-checkbox  [(ngModel)]="onlyReferralsFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}" [binary]="true" ></p-checkbox>
              </div>
            </div>

            <div class="row mt-2 mb-1" >
              <div class="col-4 d-flex align-items-center">
                <h6>Referred By</h6>
              </div>
              <div class="col-8 ">
                <input type="text" [(ngModel)]="referredByFilter" class="form-control" (ngModelChange)="changedFilters()"  [ngModelOptions]="{standalone: true}">
              </div>
            </div>

          </div>
        </div>

    <div class="ui-column-buttons">
      <div>Display: </div>
      <div>
        <button (click)="editColumns($event.target.value)" [style.background-color]="!appDate ? 'white': 'lightgrey'"
          value="appDate">App&nbsp;Date</button>
        <button (click)="editColumns($event.target.value)" [style.background-color]="!region ? 'white': 'lightgrey'"
          value="region">Region</button>
        <button (click)="editColumns($event.target.value)" [style.background-color]="!position ? 'white': 'lightgrey'"
          value="position">Position</button>
        <button (click)="editColumns($event.target.value)" [style.background-color]="!name ? 'white': 'lightgrey'"
          value="name">Name</button>
        <button (click)="editColumns($event.target.value)"
          [style.background-color]="!phoneNumber ? 'white': 'lightgrey'" value="phoneNumber">Phone&nbsp;Number</button>
        <button (click)="editColumns($event.target.value)" [style.background-color]="!email ? 'white': 'lightgrey'"
          value="email">Email</button>
        <button (click)="editColumns($event.target.value)"
          [style.background-color]="!callbackTime ? 'white': 'lightgrey'"
          value="callbackTime">Callback&nbsp;Time</button>
        <button (click)="editColumns($event.target.value)" [style.background-color]="!source ? 'white': 'lightgrey'"
          value="source">Hiring&nbsp;Source</button>
        <button (click)="editColumns($event.target.value)" [style.background-color]="!referredBy ? 'white': 'lightgrey'"
          value="referredBy">Referred&nbsp;By</button>
        <button (click)="editColumns($event.target.value)" [style.background-color]="!keyQuestions ? 'white': 'lightgrey'"
          value="keyQuestions">Key&nbsp;Questions</button>
      </div>
    </div>


    <i *ngIf="loading" class="material-icons rotate text-muted">loop</i>
    <p-table [value]="jobApplications?.result" [responsive]="true" [paginator]="true" [rows]="20" [autoLayout]="true"
      [totalRecords]="jobApplications?.totalRecords" [lazy]="true" (onLazyLoad)="loadPage($event)">
      <ng-template pTemplate="caption">
        <div class="p-d-flex exportbuttons">
          <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()"
            class="p-button-excel p-mr-2" pTooltip="XLS" tooltipPosition="bottom"></button>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th width="140px"></th>
          <th [hidden]="!appDate" [pSortableColumn]="'ApplicationDate'" class="text-center" width="120px">
            App Date
            <p-sortIcon [field]="'ApplicationDate'"></p-sortIcon>
          </th>
          <th [hidden]="!region" [pSortableColumn]="'RegionID'" class="text-center" style="min-width: 120px;">
            Region
            <p-sortIcon [field]="'RegionID'"></p-sortIcon>
          </th>
          <th [hidden]="!position" width="13%" [pSortableColumn]="'Position'" class="text-center" style="min-width: 120px;">
            Position
            <p-sortIcon [field]="'Position'"></p-sortIcon>
          </th>
          <th [hidden]="!name" [pSortableColumn]="'FirstName'" class="text-center" style="min-width: 120px;">
            Name
            <p-sortIcon [field]="'FirstName'"></p-sortIcon>
          </th>
          <th [hidden]="!phoneNumber" [pSortableColumn]="'PhoneNumber'" class="text-center" style="min-width: 120px;">
            Phone Number
            <p-sortIcon [field]="'PhoneNumber'"></p-sortIcon>
          </th>
          <th [hidden]="!email" width="13%" [pSortableColumn]="'Email'" class="text-center" style="min-width: 120px;">
            Email
            <p-sortIcon [field]="'Email'"></p-sortIcon>
          </th>
          <th [hidden]="!callbackTime" [pSortableColumn]="'ContactTime'" class="text-center" style="min-width: 120px;">
            Callback Time
            <p-sortIcon [field]="'ContactTime'"></p-sortIcon>
          </th>
          <th [hidden]="!source" [pSortableColumn]="'HeardOfUsThrough'" class="text-center" style="min-width: 120px;">
            Hiring Source
            <p-sortIcon [field]="'HeardOfUsThrough'"></p-sortIcon>
          </th>
          <th [hidden]="!referredBy" [pSortableColumn]="'ReferredBy'" class="text-center" style="min-width: 120px;">
            Referred By
            <p-sortIcon [field]="'ReferredBy'"></p-sortIcon>
          </th>
          <th [hidden]="!keyQuestions" class="text-center" style="min-width: 120px;">
            Key Questions
          </th>
        </tr>
      </ng-template>
      <ng-template #mymodal let-modal>
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">Archive Applicant</h4>
          <button type="button" class="close btn btn-outline-dark" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>In order to proceed, please explain why this Applicant is to be Archived: </p>
          <textarea type="text" [(ngModel)]="archiveReason" class="form-control"
            [ngModelOptions]="{standalone: true}"></textarea>
        </div>
        <div class="modal-footer">
          <button type="button" [disabled]="archiveReason == null" class="btn btn-outline-dark"
            (click)="modal.close('Archive')">Archive</button>
          <button type="button" class="btn btn-outline-dark" (click)="modal.close('Cancel')">Cancel</button>
        </div>
      </ng-template>
      <ng-template pTemplate="body" let-jobApplications>
        <tr>
          <td>
            <span class="ui-column-title"></span>
            <div class="table-buttons">
              <div>
                <span class="pointer ml-2" (click)="getResume(jobApplications)"><i title="Resume"
                    class="fas fa-file-alt"></i></span>
              </div>
              <div *ngIf="jobApplications.coverLetterURL">
                <span class="pointer ml-2" (click)="getCoverLetter(jobApplications)"><i title="Cover Letter"
                    class="fas fa-file-alt"></i></span>
              </div>
              <div *ngIf="!jobApplications.isCandidate || jobApplications.isCandidate == null">
                <i title="Move Application to Prospective Candidate Queue" (click)="decisionApplicant(jobApplications)"
                  class="fas pointer fa-child text-success"></i>
              </div>
              <div *ngIf="jobApplications.reasonNotCandidate == ''">
                <i title="Archive Applicant" (click)="open(mymodal, jobApplications)"
                  class="fas pointer fa-trash-alt"></i>
              </div>
              <div *ngIf="jobApplications.dealBreaker">
                <i [title]="jobApplications.dealBreaker" class="fas pointer fas fa-flag text-danger"></i>
              </div>
              <div
                *ngIf="hasScreeningForm(jobApplications)">
                <i title="BT Phone Screening" class="fas pointer fas fa-phone text-info"
                  (click)="openPhoneScreening(myscreeningmodal, jobApplications)"></i>
              </div>
            </div>
          </td>
          <td [hidden]="!appDate" class="text-center">
            <span class="ui-column-title">App Date</span>
            {{jobApplications.applicationDate | date:'shortDate' }}
          </td>
          <td [hidden]="!region" class="text-center">
            <span class="ui-column-title">Region</span>
            {{ jobApplications.regionName }}
          </td>
          <td [hidden]="!position" class="text-center">
            <span class="ui-column-title">Position</span>
            {{ jobApplications.position }}
          </td>
          <td [hidden]="!name" class="text-center">
            <span class="ui-column-title">Name</span>
            {{ jobApplications.firstName + " " + jobApplications.lastName}}
            <i title="Applicant is a Referral" *ngIf="jobApplications.referredBy"
              class="fas fa-address-card text-primary"></i>
            <i title="Applicant Moved to Prospective Candidate Queue" *ngIf="jobApplications.isCandidate"
              class="fas fa-laugh-beam text-success"></i>
            <i title="Applicant Was Archived" *ngIf="jobApplications.reasonNotCandidate != ''"
              class="fas fa-sad-tear text-warning"></i>
          </td>
          <td [hidden]="!phoneNumber" class="text-center">
            <span class="ui-column-title">Phone Number</span>
            {{ jobApplications.phoneNumber }}
          </td>
          <td [hidden]="!email" style="font-size: 12px;" class="text-center">
            <span class="ui-column-title">Email</span>
            {{ jobApplications.email }}
          </td>
          <td [hidden]="!callbackTime" class="text-center">
            <span class="ui-column-title">Callback Time</span>
            {{ jobApplications.contactTime }}
          </td>
          <td [hidden]="!source" class="text-center">
            <span class="ui-column-title">Hiring Source</span>
            {{ jobApplications.heardOfUsThrough }}
          </td>
          <td [hidden]="!referredBy" class="text-center">
            <span class="ui-column-title">Referred By</span>
            {{ jobApplications.referredBy }}
          </td>
          <td [hidden]="!keyQuestions" class="text-center">
            <span class="ui-column-title">Key Questions</span>
            <div class="w-100 row">
               <div class="col-12 d-flex justify-content-center m-0 p-0 mb-1" style="font-size: 13px;">
                  <div class="m-0 p-0">Is 18 yrs old - </div>
                  <div class="m-0 p-0 ml-2" [ngClass]="{'text-success': jobApplications.isAgeLimit, 'text-danger': !jobApplications.isAgeLimit}">{{jobApplications.isAgeLimit ? "Yes" : "No"}}</div>
                </div>
                <div class="col-12 d-flex justify-content-center m-0 p-0 mb-1" style="font-size: 13px;">
                  <div class="m-0 p-0">Has Diploma - </div>
                  <div class="m-0 p-0 ml-2" [ngClass]="{'text-success': jobApplications.diploma, 'text-danger': !jobApplications.diploma}">{{jobApplications.diploma ? "Yes" : "No"}}</div>
                </div>
                <div class="col-12 d-flex justify-content-center m-0 p-0 mb-1" style="font-size: 13px;">
                  <div class="m-0 p-0">Has Reliable Transportation - </div>
                  <div class="m-0 p-0 ml-2" [ngClass]="{'text-success': jobApplications.reliableTransportation, 'text-danger': !jobApplications.reliableTransportation}">{{jobApplications.reliableTransportation ? "Yes" : "No"}}</div>
                </div>
                <div class="col-12 d-flex justify-content-center m-0 p-0 mb-1" style="font-size: 13px;" *ngIf="jobApplications.hasValidDriversLicense != null">
                  <div class="m-0 p-0">Has Valid Drivers License - </div>
                  <div class="m-0 p-0 ml-2" [ngClass]="{'text-success': jobApplications.hasValidDriversLicense, 'text-danger': !jobApplications.hasValidDriversLicense}">{{jobApplications.hasValidDriversLicense ? "Yes" : "No"}}</div>
                </div>  
                <div class="col-12 d-flex justify-content-center m-0 p-0 mb-1" style="font-size: 13px;">
                  <div class="m-0 p-0">Can Pass Background Check - </div>
                  <div class="m-0 p-0 ml-2" [ngClass]="{'text-success': jobApplications.backgroundCheck, 'text-danger': !jobApplications.backgroundCheck}">{{jobApplications.backgroundCheck ? "Yes" : "No"}}</div>
                </div>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</form>
<ng-template #myscreeningmodal class="modal-index" let-modal>
  <div class="modal-body">
      <app-screening-interview
        [applicationID]="jobApplicationID"
        [jobApp]="convertingApplication"
        [position]="convertingApplication.position"
        [formSubmissionID]="convertingApplication.formSubmissionID"
        [programID]="programID"
        [firstName]="firstName"
        [lastName]="lastName"
        (myEvent)="modal.close($event)"
      ></app-screening-interview>
  </div>
</ng-template>
