
  <div class="row w-100 m-2 pb-2">
    <div class="col-2">
      <b *ngIf="measureable.isChild == false">Measureable:</b>
      <b *ngIf="measureable.isChild">Sub category:</b>

    </div>
    <div class="col-10">
      <textarea class="form-control w-100" [(ngModel)]="measureable.measureable" placeholder=""></textarea>
      <button *ngIf="measureable.isChild == false" [disabled]="loading" class="btn btn-success m-2" (click)="addNewMeasureable()">Add Sub Category</button>
      <button class="btn btn-danger m-2" [disabled]="loading" (click)="remove()">Remove</button>
    </div>

  </div>
  <div *ngIf="measureable.isChild == false">
    <div class="w-100 pl-5" *ngFor="let subCategory of measureable.childrenMeasureables; let i=index">

      <app-kpi-measureable-template-component [measureable]="subCategory" class="w-100"></app-kpi-measureable-template-component>

    </div>
  </div>

