import { EmployeeReviewTemplateValueColorModel } from './../../../../../models/employeeReviewTemplateModels';
import { BaseComponent } from './../../../../../core/abstracts/baseComponent';
import { PagingResultsModel } from './../../../../../models/pagingResultsModel';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from './../../../../../core/store';
import { Actions, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { MultiSelect } from 'primeng/multiselect';
import { AlertService } from './../../../../../shared/services/alert.service';
import { deepClone, getAllControlsErrors, markFormGroupTouched, updateFormGroupValidity } from './../../../../../helpers/utils';
import { FormGroup } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { tap } from 'rxjs/operators';
import {
  deleteValueColor, deleteValueColorSuccess, getValueColors,
  saveValueColor, saveValueColorSuccess, updateValueColorForm
} from 'src/app/core/store/employee-review-template/employee-review-template.actions';
import { initValueColorForm } from 'src/app/core/store/employee-review-template/employee-review-template.reducer';

@Component({
  selector: 'app-value-color-management',
  templateUrl: './value-color-management.component.html',
  styleUrls: ['./value-color-management.component.scss']
})
export class ValueColorManagementComponent extends BaseComponent implements OnInit {

  valueColors: EmployeeReviewTemplateValueColorModel[];
  loadingValueColor = false;
  loading = false;
  openValueInfo = false;
  valueColorInfo: EmployeeReviewTemplateValueColorModel = {} as EmployeeReviewTemplateValueColorModel;
  form: FormGroup;
  colorRGB: string;

  constructor(
    private actions$: Actions,
    private alertService: AlertService,
    private store: Store<State>,
    private router: Router,
  ) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.employeeReviewTemplateState.valueColors).subscribe(valueColors => {
        if (valueColors) {
          this.valueColors = valueColors;
        }
      }),

      this.store.select(s => s.employeeReviewTemplateState.loading).subscribe(loading => {
        this.loadingValueColor = loading;
      }),

      this.store.select(s => s.employeeReviewTemplateState.valueColorInfo).subscribe(valueColorInfo => {
        if (valueColorInfo) {
          this.valueColorInfo = valueColorInfo;
        }
      }),
      this.actions$.pipe(
        ofType(saveValueColorSuccess),
        tap(action => {
          this.alertService.success('Value saved!');
          this.store.dispatch(getValueColors());
          this.openValueInfo = false;
          this.valueColorInfo = {} as EmployeeReviewTemplateValueColorModel;
          this.initForm();
          this.store.dispatch(getValueColors());
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(deleteValueColorSuccess),
        tap(action => {
          this.alertService.success('Value removed!');
          this.store.dispatch(getValueColors());
          this.openValueInfo = false;
          this.valueColorInfo = {} as EmployeeReviewTemplateValueColorModel;
          this.initForm();
          this.store.dispatch(getValueColors());
        })
      ).subscribe(),


    );

    this.store.dispatch(getValueColors());
  }

  initForm() {
    this.form = initValueColorForm(this.valueColorInfo);
    this.subs.push(
      this.form.valueChanges.subscribe(formValues => {
        const formErrors = getAllControlsErrors(this.form);
        this.store.dispatch(updateValueColorForm({ values: formValues, formErrors }));
      }));
  }

  editValueColor(valueColor: EmployeeReviewTemplateValueColorModel) {
    this.openValueInfo = true;
    this.valueColorInfo = valueColor;
    this.colorRGB = this.valueColorInfo.colorRGB;
    this.initForm();
  }

  deleteValueColor(valueColor: EmployeeReviewTemplateValueColorModel) {
    this.store.dispatch(deleteValueColor({ valueColorID: valueColor.id }));
  }

  newValueColorInfo() {
    this.openValueInfo = true;
    this.valueColorInfo = {} as EmployeeReviewTemplateValueColorModel;
    this.colorRGB = null;
    this.initForm();
  }

  save() {
    updateFormGroupValidity(this.form);
    if (this.form.valid) {
      this.store.dispatch(saveValueColor({ valueColor: this.valueColorInfo }));
    } else {
      markFormGroupTouched(this.form);
    }
  }

  changedColor() {
    this.form.get('colorRGB').setValue(this.colorRGB);
  }

}

