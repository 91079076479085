<form class="card mt-2">
  <div class="card-body">
    <div class="float-right">
      <button type="button" routerLink="/certification/report" class="btn btn-secondary mr-2">Certifications</button>
      <button type="button" routerLink="/certification/events" class="btn btn-secondary">Events</button>
    </div>
    <h2 class="mb-3">Certification Instructor Report</h2>
    <div class="mb-3">

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Campus:</h5>
        </div>
        <div class="col-10">
          <i *ngIf="!campusList" class="material-icons rotate text-muted">loop</i>
          <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="campusList" [(ngModel)]="campusId" (ngModelChange)="reloadFilter(this.filter)" [ngModelOptions]="{standalone: true}"></p-dropdown>
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Certification Type:</h5>
        </div>
        <div class="col-10">
          <i *ngIf="!certificationTypes" class="material-icons rotate text-muted">loop</i>
          <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="certificationTypes" [(ngModel)]="certificationTypeId" (ngModelChange)="reloadFilter(this.filter)" [ngModelOptions]="{standalone: true}"></p-dropdown>
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Employee:</h5>
        </div>
        <div class="col-10">
          <div class="ml-2 w-100 mb-2">
            <p-autoComplete [style]="{'width': '100%'}" [inputStyle]="{'width': '100%'}" [(ngModel)]="prefixText"
                            [suggestions]="userEmployeeList" (completeMethod)="getStaffList($event)" field="label"
                            [ngModelOptions]="{standalone: true}" [minLength]="1" [autoHighlight]="true"
                            (onSelect)="reloadListAfterStaffChange($event)" [forceSelection]="true"
                            (onClear)="reloadListAfterClear($event)"></p-autoComplete>
          </div>
        </div>
      </div>                             

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Show only active instructors:</h5>
        </div>
        <div class="col-10" style="padding-left: 25px;">
          <p-checkbox [(ngModel)]="showOnlyActiveEmployees" (ngModelChange)="reloadFilter(this.filter)" [binary]="true" [ngModelOptions]="{standalone: true}"></p-checkbox>
        </div>
      </div>

    </div>

    <i [hidden]="!loadingList && !loadingExcel" class="material-icons rotate text-muted">loop</i>
    <button (click)="editInstructor([])" class="btn btn-secondary mr-2 mb-2">New Instructor</button>
    <button type="button" title="Export all pages to excel" class="btn btn-success mr-2 mb-2" (click)="exportToExcel()"><i class="far fa-file-excel"></i></button>
    <p-table [value]="list?.result" [responsive]="true" [paginator]="true" [rows]="20"
             [totalRecords]="list?.totalRecords" [lazy]="true" (onLazyLoad)="reloadFilter($event)">
      <ng-template pTemplate="header">
        <tr>
          <th width="125px"></th>
          <th [pSortableColumn]="'EmployeeName'">Employee <p-sortIcon [field]="'EmployeeName'"></p-sortIcon></th>
          <th [pSortableColumn]="'CertificationType'">Certification Type<p-sortIcon [field]="'CertificationType'"></p-sortIcon></th>      
          <th [pSortableColumn]="'PrimaryProgramName'">Primary Campus <p-sortIcon [field]="'PrimaryProgramName'"></p-sortIcon></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-list>
        <tr>
          <td>
            <button class="btn btn-secondary mr-2" *ngIf="canManage" (click)="editInstructor(list)" title="Edit Instructor">
              <i class="fas fa-edit"></i>
            </button>         
          </td>
          <td>
            <span class="ui-column-title">Instructor</span>
            {{list.firstName}} {{list.lastName}} <span style="font-style:italic; color: red; font: 0.9rem;">{{getTerminationString(list)}}</span>
          </td>
          <td>
            <span class="ui-column-title">Certification Type</span>
            {{list.certificationType}}
          </td>
          <td>
            <span class="ui-column-title">Primary Campus</span>
            {{list.primaryProgramName}}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</form>

<app-instructor-edit-certification></app-instructor-edit-certification>
