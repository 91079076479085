
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../core/store';
import { loadPayPeriods, changePayPeriod } from 'src/app/core/store/payperiod/payperiod.actions';
import { PayPeriodModel } from 'src/app/models/payPeriodModel';
import { PayrollItemModel, PayrollApprovedTotalsModel } from 'src/app/models/payableItem';
import { formatCurrencyNotZero, getTimeFormatted } from 'src/app/helpers/utils';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { savePayrollItems, savePayrollItemsSuccess } from 'src/app/core/store/payroll-approval/payroll-approval.actions';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/internal/operators/tap';

@Component({
  selector: 'app-payroll',
  templateUrl: './payroll.component.html',
  styleUrls: ['./payroll.component.scss']
})
export class PayrollComponent extends BaseComponent implements OnInit {

  payPeriods: PayPeriodModel[];
  selectedPeriodID: number;

  approvedPayrollItems: PayrollItemModel[] = null;
  invalidPayrollItems: PayrollItemModel[] = null;
  adjustmentPayrollItems: PayrollItemModel[] = null;
  pendingPayrollItems: PayrollItemModel[] = null;
  approvedTotals: PayrollApprovedTotalsModel;

  differentPendingPayableTypes: { payableType: string, payrollItems: PayrollItemModel[] }[];
  differentApprovedPayableTypes: { payableType: string, payrollItems: PayrollItemModel[] }[];

  approvedOpened = true;
  invalidOpened = true;
  adjustmentsOpened = true;
  pendingOpened = true;

  adjustmentsPayrollItemsSelected: PayrollItemModel[] = [];
  displayAdjustmentsItems: boolean;

  invalidPayrollItemsSelected: PayrollItemModel[] = [];
  displayInvalidItems: boolean;

  userId: number;

  constructor(
    private actions$: Actions,
    private store: Store<State>) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.accountState).subscribe(account => {
        this.userId = account ? (account.user ? account.user.id : 0) : 0;
      }),
      this.store.select(s => s.payPeriodState.periods).subscribe(periods => {
        this.payPeriods = periods;
      }),
      this.store.select(s => s.payPeriodState.selectedPeriodID).subscribe(selectedPeriodID => {
        this.selectedPeriodID = selectedPeriodID;
      }),
      this.store.select(s => s.payrollState.approvedPayrollItems).subscribe(approvedPayrollItems => {
        this.approvedPayrollItems = approvedPayrollItems;
        if (approvedPayrollItems) {
          this.differentApprovedPayableTypes = approvedPayrollItems
            .filter((p, i) => approvedPayrollItems.findIndex(pi => pi.payableItem.payableType === p.payableItem.payableType) === i)
            .map(p => ({
              payableType: p.payableItem.payableType,
              payrollItems: approvedPayrollItems.filter(pf => pf.payableItem.payableType === p.payableItem.payableType)
            }));
        }
      }),
      this.store.select(s => s.payrollState.invalidPayrollItems).subscribe(invalidPayrollItems => {
        this.invalidPayrollItems = invalidPayrollItems;
      }),
      this.store.select(s => s.payrollState.adjustmentPayrollItems).subscribe(adjustmentPayrollItems => {
        this.adjustmentPayrollItems = adjustmentPayrollItems;
      }),
      this.store.select(s => s.payrollState.pendingPayrollItems).subscribe(pendingPayrollItems => {
        this.pendingPayrollItems = pendingPayrollItems;
        if (pendingPayrollItems) {
          this.differentPendingPayableTypes = pendingPayrollItems
            .filter((p, i) => pendingPayrollItems.findIndex(pi => pi.payableItem.payableType === p.payableItem.payableType) === i)
            .map(p => ({
              payableType: p.payableItem.payableType,
              payrollItems: pendingPayrollItems.filter(pf => pf.payableItem.payableType === p.payableItem.payableType)
            }));

        }
      }),
      this.store.select(s => s.payrollState.approvedTotals).subscribe(approvedTotals => {
        this.approvedTotals = approvedTotals;
      }),
      this.actions$.pipe(
        ofType(savePayrollItemsSuccess),
        tap(action => {
          this.store.dispatch(changePayPeriod({ periodID: this.selectedPeriodID }));
        })
      ).subscribe()
    );

    this.store.dispatch(loadPayPeriods());
  }

  changePeriod(periodID: number) {
    this.store.dispatch(changePayPeriod({ periodID }));
  }

  getTimeFormatted(time) {
    return getTimeFormatted(time);
  }

  formatCurrencyNotZero(bonusPaid) {
    return formatCurrencyNotZero(bonusPaid);
  }

  // ### Adjustment
  adjustmentsPayrollItemsEditClicked(payrollItemsSelected: PayrollItemModel[]) {
    this.adjustmentsPayrollItemsSelected = payrollItemsSelected;
    this.displayAdjustmentsItems = true;
  }

  adjustmentsItemsEditingChanged(items: PayrollItemModel[]) {
    this.adjustmentsPayrollItemsSelected = items;
  }

  saveAdjustmentTimes() {
    this.store.dispatch(savePayrollItems(
      { payrollItems: this.adjustmentsPayrollItemsSelected, userID: this.userId, payPeriodID: this.selectedPeriodID }));
    this.displayAdjustmentsItems = false;
  }

  // ### edit

  invalidPayrollItemsEditClicked(payrollItemsSelected: PayrollItemModel[]) {
    this.invalidPayrollItemsSelected = payrollItemsSelected;
    this.displayInvalidItems = true;
  }


  invalidItemsEditingChanged(items: PayrollItemModel[]) {
    this.invalidPayrollItemsSelected = items;
  }

  saveInvalidTimes() {
    this.store.dispatch(savePayrollItems(
      { payrollItems: this.invalidPayrollItemsSelected, userID: this.userId, payPeriodID: this.selectedPeriodID }));
    this.displayInvalidItems = false;
  }

}
