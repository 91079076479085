import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { State } from '..';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { errorHappened } from '../profile/profile.actions';
import { downloadStaffInjuryInvoice, downloadStaffInjuryInvoiceSuccess, loadInjuriesQueue, loadInjuriesQueueSuccess, markInjuryAsCompleted, markInjuryAsCompletedSuccess, updateStaffInjury, updateStaffInjuryFail, updateStaffInjurySuccess, uploadStaffInjuryInvoice, uploadStaffInjuryInvoiceFailure, uploadStaffInjuryInvoiceSuccess } from "./injuryqueue.actions";
import { StaffInjuryService } from "../../services/staff-injury.service";


@Injectable()
export class InjuryQueueEffects {
  constructor(private actions$: Actions,
    private staffInjuryService: StaffInjuryService,
    private store: Store<State>) { }

   loadInjuriesQueue = createEffect(() => this.actions$.pipe(
    ofType(loadInjuriesQueue),
     switchMap(action => {
       return this.staffInjuryService.getStaffInjuries(action.filter).pipe(
         map(injuriesQueue => loadInjuriesQueueSuccess({ injuriesQueue })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
   ));

  markAsResolved = createEffect(() => this.actions$.pipe(
    ofType(markInjuryAsCompleted),
    switchMap(action => {
      return this.staffInjuryService.markAsResolved(action.staffInjuryId).pipe(
        map(m => markInjuryAsCompletedSuccess(m)),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  updateStaffInjury = createEffect(() => this.actions$.pipe(
    ofType(updateStaffInjury),
    switchMap(action => {
      return this.staffInjuryService.updateStaffInjury(action.staffInjury).pipe(
        map(m => updateStaffInjurySuccess({
          staffInjury: m
        })),
        catchError(err => {
          this.store.dispatch(updateStaffInjuryFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));

  uploadInvoice = createEffect(() => this.actions$.pipe(
    ofType(uploadStaffInjuryInvoice),
    switchMap(action =>
      this.staffInjuryService.uploadInvoice(action.staffInjuryId, action.file).pipe(
        map(m => uploadStaffInjuryInvoiceSuccess(),
          catchError(err => {
            this.store.dispatch(uploadStaffInjuryInvoiceFailure());
            return of(errorHappened({ err }));
          }))
      )
    )));

  downloadInvoice = createEffect(() => this.actions$.pipe(
    ofType(downloadStaffInjuryInvoice),
    switchMap(action => {
      return this.staffInjuryService.downloadInvoice(action.staffInjuryId).pipe(
        map(invoice => downloadStaffInjuryInvoiceSuccess({ invoice })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));


}

