import { UserTypeModel } from './../../../../../models/userProfileModel';
import { getOnboardingDoc, getOnboardingDocs } from './../../../../../core/store/onboardingdocs/onboardingdocs.actions';
import { OnBoardingDocumentModelWithRoles } from './../../../../../models/onBoardingDocumentUserModel';
import { PagingResultsModel } from './../../../../../models/pagingResultsModel';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../../core/store';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Router } from '@angular/router';
import { deepClone } from 'src/app/helpers/utils';
import { loadUserTypes } from 'src/app/core/store/profile/profile.actions';

@Component({
  selector: 'app-list-onboardingdocs',
  templateUrl: './list-onboardingdocs.component.html',
  styleUrls: ['./list-onboardingdocs.component.scss']
})
export class ListOnboardingDocsComponent extends BaseComponent implements OnInit {

  onboardingDocs: PagingResultsModel<OnBoardingDocumentModelWithRoles>;
  canEdit: boolean;
  canView: boolean;
  loadingAllDocs = false;
  filter: any;
  userTypes: UserTypeModel[];

  constructor(
    private actions$: Actions,
    private alertService: AlertService,
    private store: Store<State>,
    private router: Router) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.canEdit = permissionIDs && !!permissionIDs.find(p => p === 3060); // manage
        this.canView = permissionIDs && !!permissionIDs.find(p => p === 3059 || p === 3060); // view
      }),
      this.store.select(s => s.onBoardingDocsState.allDocs).subscribe(onboardingDocs => {
        if (onboardingDocs) {
          this.onboardingDocs = onboardingDocs;
        }
      }),

      this.store.select(s => s.onBoardingDocsState.loadingAllDocs).subscribe(loadingAllDocs => {
        this.loadingAllDocs = loadingAllDocs;
      }),
      this.store.select(s => s.profileState.userTypes).subscribe(userTypes => {
        this.userTypes = userTypes;
      }),
    );

    this.store.dispatch(loadUserTypes());
  }

  loadPage(event) {
    this.filter = event;
    this.store.dispatch(getOnboardingDocs({ filter: deepClone(this.filter) }));
  }

  // deleteOffer(offerLetter: OfferLetterWithUserModel) {
  //   this.alertService.confirm('Are you sure?',
  //     'Deleting the offer will erase any document associated with it. Are you sure?')
  //     .subscribe(
  //       anwser => {
  //         if (anwser) {
  //           this.store.dispatch(deleteOfferLetter({ offerLetterID: offerLetter.offerLetter.id }));
  //         }
  //       }, error => {
  //       }
  //     );
  // }

  editDoc(doc: OnBoardingDocumentModelWithRoles) {
    this.router.navigate(['/manage/edit-onboardingdoc/' + doc.onBoardingDocument.id]);
  }

  getRolesNames(doc: OnBoardingDocumentModelWithRoles, roleType: string) {
    if (this.userTypes) {
      const listOfRoleIds = doc[roleType];
      const listOfRoles: string = listOfRoleIds.map(x => this.userTypes
        .find(y => y.id === x) ? this.userTypes.find(y => y.id === x).userType : '')
        .join(', ');
      return listOfRoles;
    }
    return '';
  }


  getRolesNamesTruncated(listOfRoles: string) {
    if (listOfRoles.length > 30) {
      listOfRoles = listOfRoles.substring(0, 30) + '...';
    }
    return listOfRoles;
  }
}

