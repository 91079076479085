
<div [formGroup]="form" *ngIf="profile!=null">
    <h5 class="card-title">Update profile for filling the document</h5>
    This document require some extra infos to be filled, please provide the infos bellow:<br>
    (the info will be saved on your profile and used to fill the document)
    <div class="row">
      <div class="col-12 col-xl-6 ">

    <div class="row mt-3" formGroupName="employee" >
      <div class="col-4 d-flex align-items-center">
        <b>NPI Number</b>
      </div>
      <div class="col-8 pt-2 pb-2">
        {{ profile.renderingProvider?.npi }}
      </div>
    </div>
    <div class="row" formGroupName="employee">
      <div class="col-4 d-flex align-items-center">
        <b>Request Date</b>
      </div>
      <div class="col-8  pt-2 pb-2" >
        <p-calendar inputStyleClass="form-control mt-2" [monthNavigator]="true" [yearNavigator]="true" [yearRange]="yearRange"
        styleClass="w-100" panelStyleClass="calendarPanel" [hourFormat]="12" [showTime]="false"
        id="prismRequestDate" name="prismRequestDate" dataType="string" formControlName="prismRequestDate" ></p-calendar>
        <app-validation-message [form]="form" [path]="'employee.prismRequestDate'" [name]="'prismRequestDate'" [label]="'Request Date'"> </app-validation-message>

      </div>
    </div>

    <div class="row" formGroupName="employee" >
      <div class="col-4 d-flex align-items-center">
        <b>Effective Date</b>
      </div>
      <div class="col-8">
        <p-calendar inputStyleClass="form-control mt-2" [monthNavigator]="true" [yearNavigator]="true" [yearRange]="yearRange"
        styleClass="w-100" panelStyleClass="calendarPanel" [hourFormat]="12" [showTime]="false"
        id="prismEffectiveDate" name="prismEffectiveDate" dataType="string" formControlName="prismEffectiveDate" ></p-calendar>
        <app-validation-message [form]="form" [path]="'employee.prismEffectiveDate'" [name]="'prismEffectiveDate'" [label]="'Effective Date'"> </app-validation-message>
      </div>
    </div>



    <div class="row mt-4">
      <div class="col-12">
        <div class="text-danger mb-3" *ngIf="!profile.renderingProvider?.npi">The user must be a Rendering Provider with an NPI number to be able to sign this file</div>
        <button class="btn btn-primary" [class.disabled]="form.invalid ||  !profile.renderingProvider?.npi" (click)="save()">Update and Sign</button>
      </div>
    </div>
  </div>
</div>
  </div>
