import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { deepClone } from '../../helpers/utils';
import { PagingResultsModel } from '../../models/pagingResultsModel';
import { StaffInjuryModel } from '../../models/staffInjuryModel';

@Injectable({
  providedIn: 'root'
})
export class StaffInjuryService {

  constructor(private http: HttpClient) { }


  getStaffInjuries(filterValues: any)
    : Observable<PagingResultsModel<StaffInjuryModel>> {
    var filter = {
      filters: {
        staffNameFilter: { Name: 'staffNameFilter', Value: { value: filterValues.staffNameFilter } },
        reportedByFilter: { Name: 'reportedByFilter', Value: { value: filterValues.reportedByFilter } },
        sentByFilter: { Name: 'sentByFilter', Value: { value: filterValues.sentByFilter } },
        sentAtFilterStart: { Name: 'sentAtFilterStart', Value: { value: filterValues.sentAtFilterStart } },
        sentAtFilterEnd: { Name: 'sentAtFilterEnd', Value: { value: filterValues.sentAtFilterEnd } },
        ocurredDateFilterStart: { Name: 'ocurredDateFilterStart', Value: { value: filterValues.ocurredDateFilterStart } },
        ocurredDateFilterEnd: { Name: 'ocurredDateFilterEnd', Value: { value: filterValues.ocurredDateFilterEnd } },
        showCompletedFilter: { Name: 'showCompletedFilter', Value: { value: filterValues.showCompletedFilter } },
      }
    };
    
    return this.http.post<PagingResultsModel<StaffInjuryModel>>
      (`${environment.apiServer.webApiURL}/StaffInjury/GetStaffInjuries`,
        filter
      );
  }

  markAsResolved(staffInjuryId: number) {
    return this.http.get
      (`${environment.apiServer.webApiURL}/StaffInjury/MarkAsResolved/${staffInjuryId}`
      );
  }

  updateStaffInjury(staffInjury: StaffInjuryModel): Observable<StaffInjuryModel> {
    return this.http.put<StaffInjuryModel>
      (`${environment.apiServer.webApiURL}/StaffInjury/Update/`,
        staffInjury
      );
  }

  uploadInvoice(staffInjuryId: number, file: File) {
    if (staffInjuryId) {
      const formData = new FormData();
      formData.append('File', file);

      const options = { headers: new HttpHeaders() };
      options.headers.append('enctype', 'multipart/form-data');

      return this.http.post(`${environment.apiServer.webApiURL}/StaffInjury/UploadInvoice/${staffInjuryId}`, formData, options);
    }
  }


  downloadInvoice(staffInjuryId: number): Observable<ArrayBuffer> {
    return this.http.get<ArrayBuffer>(`${environment.apiServer.webApiURL}/StaffInjury/DownloadInvoice/${staffInjuryId}`,
      { responseType: 'arraybuffer' as 'json' });
  }


}
