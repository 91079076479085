<form class="card mt-2">
  <div class="card-body">
    <div class="float-right">
      <button type="button" routerLink="/holiday/list" class="btn btn-secondary">Manage Holidays</button>
    </div>
    <h2 class="mb-3">Recurring Holidays</h2>
    <div class="mb-3">

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Holiday Name:</h5>
        </div>
        <div class="col-10">
          <input type="text" class="form-control ml-2 w-100 mb-2" placeholder="All" [(ngModel)]="holidayName" (blur)="reloadHolidays(this.filter)" [ngModelOptions]="{standalone: true}" /> <!--change to text-->
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Month:</h5>
        </div>
        <div class="col-10">
          <i *ngIf="!monthList" class="material-icons rotate text-muted">loop</i>
          <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="monthList" [(ngModel)]="month" (ngModelChange)="reloadHolidays(this.filter)" [ngModelOptions]="{standalone: true}"></p-dropdown>
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Date Range:</h5>
        </div>
        <div class="col-10">
          <i *ngIf="!dateRangeList" class="material-icons rotate text-muted">loop</i>
          <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="dateRangeList" [(ngModel)]="dateRange" (ngModelChange)="changedDateRange()" [ngModelOptions]="{standalone: true}"></p-dropdown>
        </div>
      </div>

      <div class="row" *ngIf="isCustomDateRange">
        <div class="col-2 d-flex align-items-center">
          <h5>Start Date:</h5>
        </div>
        <div class="col-10">
          <input type="date" class="form-control ml-2 w-100 mb-2" [(ngModel)]="startDate" (blur)="reloadHolidays(this.filter)" [ngModelOptions]="{standalone: true}" />
        </div>
        <div class="col-2 d-flex align-items-center">
          <h5>End Date:</h5>
        </div>
        <div class="col-10">
          <input type="date" class="form-control ml-2 w-100 mb-2" [(ngModel)]="endDate" (blur)="reloadHolidays(this.filter)" [ngModelOptions]="{standalone: true}" />
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Include Archived:</h5>
        </div>
        <div class="col-10" style="padding-left: 25px;">
          <p-checkbox [(ngModel)]="showArchived" (ngModelChange)="reloadHolidays(this.filter)" [binary]="true" [ngModelOptions]="{standalone: true}"></p-checkbox>
        </div>
      </div>

    </div>

    <div class="mb-3">
      <button (click)="editHoliday([])" class="btn btn-secondary">New Holiday</button>
    </div>

    <i [hidden]="!loadingHolidayList" class="material-icons rotate text-muted">loop</i>
    <p-table [value]="holidays?.result" [responsive]="true" [paginator]="true" [rows]="20"
             [totalRecords]="holidays?.totalRecords" [lazy]="true" (onLazyLoad)="reloadHolidays($event)">
      <ng-template pTemplate="header">
        <tr>
          <th width="100px"></th>
          <th [pSortableColumn]="'HolidayName'">Holiday Name <p-sortIcon [field]="'HolidayName'"></p-sortIcon></th>
          <th [pSortableColumn]="'Date'">Date <p-sortIcon [field]="'Date'"></p-sortIcon></th>
          <th [pSortableColumn]="'StartTime'">Time Range <p-sortIcon [field]="'StartTime'"></p-sortIcon></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-holiday>
        <tr>
          <td>
            <button class="btn btn-secondary mr-2" *ngIf="canEdit" (click)="editHoliday(holiday)" title="Edit Holiday">
              <i class="fas fa-edit"></i>
            </button>
          </td>
          <td>
            <span class="ui-column-title">Holiday Name</span>
            {{holiday.holidayName}}
          </td>
          <td>
            <span class="ui-column-title">Date</span>
            {{holiday.date | date: 'MM/dd/yyyy'}}
          </td>
          <td>
            <span class="ui-column-title">Time Range</span>
            {{holiday.startTime | date: 'shortTime'}} - {{holiday.endTime | date: 'shortTime'}}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</form>

<app-edit-recurring></app-edit-recurring>
