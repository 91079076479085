<form class="card mt-2" *ngIf="canViewEmployeeFile">
  <div class="card-body">
    <h2 class="mb-3">Employees File</h2>
    <h5>Filter</h5>
    <div class="row mb-1">
      <div class="col-6">
        <div class="row mb-1">
          <div class="col-4 d-flex align-items-center">
            <h6>Employee Legal First Name:</h6>
          </div>
          <div class="col-8 ">
            <input type="text" [(ngModel)]="employeeFirstNameFilter" class="form-control" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}">
          </div>
        </div>

        <div class="row mb-1">
          <div class="col-4 d-flex align-items-center">
            <h6>Employee Legal Last Name:</h6>
          </div>
          <div class="col-8 ">
            <input type="text" [(ngModel)]="employeeLastNameFilter" class="form-control" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}">
          </div>
        </div>

        <div class="row mb-1">
          <div class="col-4 d-flex align-items-center">
            <h6>Show Separated Users:</h6>
          </div>
          <div class="col-8 ">
            <p-selectButton styleClass="w-100" [options]="yesNoAllOptions" [(ngModel)]="terminatedEmployeesFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"></p-selectButton>
          </div>
        </div>

        <div class="row mb-1">
          <div class="col-4 d-flex align-items-center">
            <h6>Show Inactive Users:</h6>
          </div>
          <div class="col-8 ">
            <p-selectButton styleClass="w-100" [options]="yesNoAllOptions" [(ngModel)]="inactiveUsersFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"></p-selectButton>
          </div>
        </div>

      </div>
      <div class="col-6">

        <div class="row mb-1">
          <div class="col-4 d-flex align-items-center">
            <h6>All Docs Uploaded:</h6>
          </div>
          <div class="col-8 ">
            <p-selectButton styleClass="w-100" [options]="yesNoAllOptions" [(ngModel)]="allUploadedFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"></p-selectButton>
          </div>
        </div>

        <div class="row mb-1">
          <div class="col-4 d-flex align-items-center">
            <h6>Uploaded Docs Approved:</h6>
          </div>
          <div class="col-8 ">
            <p-selectButton styleClass="w-100" [options]="yesNoAllOptions" [(ngModel)]="uploadApprovedFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"></p-selectButton>
          </div>
        </div>

        <div class="row mb-1">
          <div class="col-4 d-flex align-items-center">
            <h6>Employee Info filled:</h6>
          </div>
          <div class="col-8 ">
            <p-selectButton styleClass="w-100" [options]="yesNoAllOptions" [(ngModel)]="employeeInfoFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"></p-selectButton>
          </div>
        </div>

        <div class="row mb-1">
          <div class="col-4 d-flex align-items-center">
            <h6>Sign Docs signed:</h6>
          </div>
          <div class="col-8 ">
            <p-selectButton styleClass="w-100" [options]="yesNoAllOptions" [(ngModel)]="allDocsSignedFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"></p-selectButton>
          </div>
        </div>

        <div class="row mb-1">
          <div class="col-4 d-flex align-items-center">
            <h6>Sign Docs approved:</h6>
          </div>
          <div class="col-8 ">
            <p-selectButton styleClass="w-100" [options]="yesNoAllOptions" [(ngModel)]="allDocsApprovedFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"></p-selectButton>
          </div>
        </div>
      </div>
    </div>
    <button class="btn btn-primary mb-2" (click)="doFilter()">Filter</button>
    <i *ngIf="loadingEmployeeFileUsers" class="material-icons rotate text-muted">loop</i>

    <p-table  [value]="employeeFileUsers?.result" [responsive]="true"
    [paginator]="true" [rows]="20" [totalRecords]="employeeFileUsers?.totalRecords" [lazy]="true"
    (onLazyLoad)="loadPage($event)" [loading]="loadingEmployeeFileUsers">
        <ng-template pTemplate="header">
            <tr>
              <th width="110px" > References </th>
              <th width="180px">Employee #</th>
                <th >Employee</th>
                <th width="180px"> Uploaded Docs </th>
                <th width="180px"> Employee Info</th>
                <th width="180px"> Sign Docs</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-employeeFileUser>
            <tr >
              <td>
                <span class="ui-column-title">References</span>
                <span class="pointer mr-2" *ngIf="employeeFileUser.prospectiveCandidateID"  (click)="seeReferences(employeeFileUser)"><i title="See references" class="fas fa-people-arrows"></i></span>
            </td>
              <td>
                  <span class="ui-column-title">employeeFileUser.user.employee.employeeNumber</span>
                  </td>

                 <td>
                    <span class="ui-column-title">Employee</span>
                    <span *ngIf="employeeFileUser.employee.inRehireProcess && employeeFileUser.employee.terminationDate != null" class="pointer mr-2" style="color: green" title="Create Offer Letter" (click)="createRehireOfferLetter(employeeFileUser)"><i class="fas fa-hand-holding-usd"></i></span>
                    <span *ngIf="canViewHiredEmployeeFile(employeeFileUser)" class="pointer mr-2" (click)="seeUserDetails(employeeFileUser)"><i title="See employee" class="fas fa-edit"></i></span>
                    <span *ngIf="canViewHiredEmployeeFile(employeeFileUser)" class="pointer mr-2" (click)="seeUserDetails(employeeFileUser)">{{employeeFileUser.user.firstName }} {{employeeFileUser.user.lastName }}</span>
                    <span *ngIf="!canViewHiredEmployeeFile(employeeFileUser)" class="mr-2" style="cursor: default;">{{employeeFileUser.user.firstName }} {{employeeFileUser.user.lastName }}</span>
                    <span *ngIf="canForceOnboarding" class="pointer mr-2" (click)="setOnBoarding(employeeFileUser)" style="color: darkblue;"><i title="Force Onboarding" class="fas fa-user-check"></i></span>
                    <button *ngIf="employeeFileUser.employee.terminationDate == null && !employeeFileUser.employee.inRehireProcess" class="btn btn-danger mr-2" (click)="terminateEmployee(employeeFileUser)">Separate</button>
                    <button *ngIf="employeeFileUser.employee.terminationDate != null && !employeeFileUser.employee.inRehireProcess" class="btn btn-success mr-2" (click)="rehireEmployee(employeeFileUser)">Rehire</button>
                    <button *ngIf="employeeFileUser.employee.terminationDate != null && employeeFileUser.employee.inRehireProcess" class="btn btn-danger mr-2" (click)="willNotHireEmployee(employeeFileUser)">Will Not Be Rehired</button>
                  </td>

          <td>
            <span class="ui-column-title">Uploaded Docs</span>
            <div>
              <div class="d-flex p-2 align-items-center">
                <i class="fas fa-check-circle text-success" *ngIf="employeeFileUser.allUploaded"></i>
                <i class="fas fa-dot-circle" *ngIf="!employeeFileUser.allUploaded"></i>
                &nbsp;&nbsp; All Uploaded
              </div>

              <div class="d-flex p-2 align-items-center">
                <i class="fas fa-check-circle text-success" *ngIf="employeeFileUser.onboarding?.uploadedDocsApprovedDate"></i>
                <i class="fas fa-dot-circle" *ngIf="!employeeFileUser.onboarding?.uploadedDocsApprovedDate"></i>
                &nbsp;&nbsp; Approved
              </div>
            </div>
          </td>

          <td>
            <span class="ui-column-title">Employee Info</span>
            <div>
              <div class="d-flex p-2 align-items-center">
                <i class="fas fa-check-circle text-success" *ngIf="employeeFileUser.onboarding?.employeeInfoFilled"></i>
                <i class="fas fa-dot-circle" *ngIf="!employeeFileUser.onboarding?.employeeInfoFilled"></i>
              </div>
            </div>
          </td>


          <td>
            <span class="ui-column-title">Sign Docs</span>
            <div>
              <div class="d-flex p-2 align-items-center">

                <i class="fas fa-check-circle text-success" *ngIf="employeeFileUser.allDocsSigned"></i>
                <i class="fas fa-dot-circle" *ngIf="!employeeFileUser.allDocsSigned"></i>
                &nbsp;&nbsp;   All Signed
              </div>

              <div class="d-flex p-2 align-items-center">

                <i class="fas fa-check-circle text-success" *ngIf="employeeFileUser.onboarding?.allDocumentsSignedApprovedDate"></i>
                <i class="fas fa-dot-circle" *ngIf="!employeeFileUser.onboarding?.allDocumentsSignedApprovedDate"></i>
                &nbsp;&nbsp; Approved
              </div>
            </div>
          </td>

        </tr>
      </ng-template>
    </p-table>
  </div>
</form>

<app-termination-reason></app-termination-reason>
