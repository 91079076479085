<form class="card mt-2">
  <div class="card-body">
    <div class="float-right">
    </div>
    <h2 class="mb-3">Documents</h2>
    <h5>Filter</h5>
    <div class="row mb-1" >
      <div class="col-2 d-flex align-items-center">
        <h6>Campus:</h6>
      </div>
      <div class="col-10 ">
        <i *ngIf="!campusesList" class="material-icons rotate text-muted">loop</i>
        <p-multiSelect styleClass="w-100" [options]="campusesList" [(ngModel)]="campusID" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}" placeholder="Select campus..."></p-multiSelect>
      </div>
    </div>
    <div class="row mb-1" >
      <div class="col-2 d-flex align-items-center">
        <h6>User required on files:</h6>
      </div>
      <div class="col-10 ">
        <i *ngIf="!activeDocs" class="material-icons rotate text-muted">loop</i>
        <p-multiSelect styleClass="w-100" [options]="activeDocs" [(ngModel)]="filesID" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}" placeholder="Select files..."></p-multiSelect>
      </div>
    </div>

    <div class="row mb-1" >
      <div class="col-2 d-flex align-items-center">
        <h6>Only users missing signature on this files:</h6>
      </div>
      <div class="col-10 ">
        <p-checkbox class=""  [(ngModel)]="onlyMissingSignature" (ngModelChange)="changedFilters()"[binary]="true" [ngModelOptions]="{standalone: true}"></p-checkbox>

      </div>
    </div>


    <i *ngIf="loadingAllUserDocs" class="material-icons rotate text-muted">loop</i>

    <i *ngIf="loadingReport" class="material-icons rotate text-muted">loop</i>
    <button class="btn btn-primary mr-2" *ngIf="!loadingReport && !loadingAllUserDocs && !loadingReportExcel && allUserDocs?.result"  (click)="getReport()">Export PDF</button>
    <i *ngIf="loadingReportExcel" class="material-icons rotate text-muted">loop</i>
    <button class="btn btn-primary mr-2" *ngIf="!loadingReport && !loadingAllUserDocs && !loadingReportExcel && allUserDocs?.result"  (click)="getReportExcel()">Export Excel</button>

    <p-table class="mt-2" [value]="allUserDocs?.result" [responsive]="true"
    [paginator]="true" [rows]="20" [totalRecords]="allUserDocs?.totalRecords" [lazy]="true"
    (onLazyLoad)="loadPage($event)"
   >
        <ng-template pTemplate="body" let-row let-rowIndex="rowIndex">

          <tr class="text-white bg-dark" *ngIf="!allUserDocs?.result[(rowIndex % 20) -1] || row.user.primaryProgramID != allUserDocs?.result[(rowIndex % 20)-1].user.primaryProgramID  " >
            <td colspan="2">
              <span class="ml-2 font-weight-bold pointer" >
                {{campusesHash[row.user.primaryProgramID]}}
                </span>
            </td>

        </tr>
            <tr  [class.bg-light-dark]="expandeds[row.user.id+'']"  [class.text-white]="expandeds[row.user.id+'']" >
                <td colspan="2">
                  <span class="ml-2 font-weight-bold pointer" (click)="expand(row.user.id+'')">
                     {{row.user.firstName}} {{row.user.lastName}}
                    </span>
                    <span> {{docsSignedCount[row.user.id+'']}}/{{row.onBoardingDocuments?.length}} </span>
                </td>

            </tr>
            <ng-container *ngIf="expandeds[row.user.id+'']">
              <tr *ngIf="row.onBoardingDocuments?.length" class="bg-light-dark text-white">

                <td><span class="ml-3 font-weight-bold">File</span>
                </td>
                <td><span class="font-weight-bold">Signed</span>
                </td>
              </tr>
              <tr *ngFor="let doc of row.onBoardingDocuments">

                <td>{{doc.title}}
                  <i class="far fa-file-alt pointer" title="Download file"
                   *ngIf="doc.onBoardingDocumentsUser?.signedDate" (click)="viewDoc(doc.onBoardingDocumentsUser)"></i>
                </td>
                <td>
                  <i class="fas fa-check-circle text-success" *ngIf="doc.onBoardingDocumentsUser?.signedDate"></i>
                  {{doc.onBoardingDocumentsUser?.signedDate  | date:'shortDate'}}
                  <i class="fas fa-times-circle text-danger" *ngIf="!doc.onBoardingDocumentsUser?.signedDate"></i>
                </td>
              </tr>
            </ng-container>
        </ng-template>
    </p-table>
  </div>
</form>
