<div *ngIf="loading">
    Loading...
  </div>
  <div *ngIf="!loading">
    <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Manage Asset Permissions - By Type</h4>
    <button class="close btn" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body">
        <div class="row d-flex">
            <div class="col">
                <h6>Assignment Type:</h6>
            </div>
            <div class="col">
                <p-dropdown styleClass="form-control w-100 mb-2" [resetFilterOnHide]="true" [options]="assignmentTypeOptions" [(ngModel)]="assignmentType" [ngModelOptions]="{standalone: true}" (ngModelChange)="changeAssignmentType()"></p-dropdown>
            </div>
        </div>
        <hr />
        <div *ngIf="assignmentType == 2" class="row d-flex">
            <div class="col">
                <h6>User:</h6>
            </div>
            <div class="col">
                <p-dropdown styleClass="form-control w-100 mb-2" [filter]="true" [resetFilterOnHide]="true" [options]="userList" [(ngModel)]="assignUserId" (ngModelChange)="loadEquipmentPermissions(this.assignUserId, null)" [ngModelOptions]="{standalone: true}"></p-dropdown>
            </div>
        </div>
        <div *ngIf="assignmentType == 1" class="row d-flex">
            <div class="col">
                <h6>Role:</h6>
            </div>
            <div class="col">
                <p-dropdown styleClass="form-control w-100 mb-2" [filter]="true" [resetFilterOnHide]="true" [options]="roleList" [(ngModel)]="assignRoleId" (ngModelChange)="loadEquipmentPermissions(null, this.assignRoleId)" [ngModelOptions]="{standalone: true}"></p-dropdown>
            </div>
        </div>
        <hr />
        <div *ngIf="showPermissiontypes == true">
            <div class="d-flex justify-content-between align-content-center mb-5">
                <h6>Select the Type of Asset(s) That This {{assignmentType == 1 ? "Role" : "User"}} Can View And Assign Out:</h6>
                <div>
                    <button class="btn btn-primary mr-3" (click)="changePermissionSelections('All')">Select All</button>
                    <button class="btn btn-secondary mr-3" (click)="changePermissionSelections('Clear')">Clear</button>
                </div>
            </div>
            <div class="grid-permission">
                <div *ngFor="let permission of equipmentTypePermissions; let i=index">
                    <div class="w-100" [ngClass]="{'odd-row': 0 === i % 2}">
                        <input type="checkbox" [(checked)]="permission.selected" [(ngModel)]="permission.selected"> - {{permission.type}}
                    </div>
                </div>
             <div>
        </div>
    </div>
    <div class="modal-footer mt-5">
        <button [disabled]="assignRoleId == null && assignUserId == null" class="btn btn-success" (click)="saveEquipmentPermissions()">Save</button>
        <button class="btn btn-secondary" (click)="closeModal()">Close</button>
    </div>
  </div>

