<form class="card mt-2" *ngIf="canEdit">
  <div class="card-body">
      <button *ngIf="canEdit" class="btn btn-primary float-right pointer" routerLink="/form-builder/form">Create new Form</button>
      <button *ngIf="canEdit" class="btn btn-link float-right pointer" routerLink="/form-builder/forms-assignment">Forms Assignment</button>
      <h2 class="mb-3">Forms</h2>
      <h5>Filter <button class="btn btn-link" (click)="toggleHideFilter()">{{showHideString}}</button></h5>
      <div class="row mb-3" [hidden]="hideFilter">
        <div class="col-6">
          <div class="row  mb-2">
            <div class="col-4 d-flex align-items-center">
              <b>Form Name:</b>
            </div>
            <div class="col-8">
              <i *ngIf="!formNames" class="material-icons rotate text-muted">loop</i>
              <p-multiSelect #formNameMultiSelect (onFocus)="openMultiSelect(formNameMultiSelect)" autofocusFilter="true" class="w-100" styleClass="w-100 d-flex " inputStyleClass="form-control"
                             [options]="formNames" [defaultLabel]="'Select ...'"
                             [(ngModel)]="formNameFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"></p-multiSelect>
            </div>
          </div>
          <div class="row mb-2" >
            <div class="col-4 d-flex align-items-center">
              <h6>Form Type:</h6>
            </div>
            <div class="col-8 ">
              <p-dropdown styleClass="form-control  w-100" [options]="formTypes" placeholder="Select ..."  [(ngModel)]="formTypeFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"></p-dropdown>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-4 d-flex align-items-center">
              <h6>Active:</h6>
            </div>
            <div class="col-8 ">
              <p-selectButton styleClass="w-100" [options]="yesNoAllOptions" [(ngModel)]="formActiveFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"></p-selectButton>
            </div>
          </div>
        <button class="btn btn-secondary float-left pointer" (click)="clearFilter()">Clear Filter</button>
        </div>
      </div>

    <i *ngIf="!formsList" class="material-icons rotate text-muted">loop</i>
    <p-table #formTable [value]="formsList?.result" [responsive]="true"
    [paginator]="true" [rows]="20" [totalRecords]="formsList?.totalRecords" [lazy]="true"
    (onLazyLoad)="loadPage($event)">
        <ng-template pTemplate="header">
            <tr>
                <th width="145px"></th>
                <th [pSortableColumn]="'Name'" >Form Name
                  <p-sortIcon [field]="'Name'"></p-sortIcon></th>
                <th [pSortableColumn]="'FormTypeID'">Form Type
                  <p-sortIcon [field]="'FormTypeID'"></p-sortIcon>
                </th>
                <th [pSortableColumn]="'Inactive'">Active
                  <p-sortIcon [field]="'Inactive'"></p-sortIcon>
                </th>

            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-fbForm>
            <tr >
                <td>
                  <span class="pointer mr-2" *ngIf="canEdit"   (click)="editForm(fbForm)"><i title="Edit Form" class="fas fa-edit"></i></span>
                  <span class="pointer mr-2" *ngIf="canEdit"   (click)="deleteForm(fbForm)"><i title="Delete Form" class="fas fa-trash"></i></span>
                  <span class="pointer mr-2" *ngIf="canEdit && fbForm.formTypeID==1"   (click)="editFormRolesAssignment(fbForm)"><i title="Manage Form Role Assignment" class="fas fa-user-tag"></i></span>
                  <span class="pointer mr-2" *ngIf="canEdit"   (click)="copyForm(fbForm)"><i title="Duplicate Form" class="fas fa-copy"></i></span>
                </td>
                <td>
                    <span class="ui-column-title">Form Name</span>
                  {{fbForm.name }}
                </td>

                <td>
                  <span class="ui-column-title">Form Type</span>
                  {{fbForm.formType.formType }}
                </td>

                <td>
                  <span class="ui-column-title">Active</span>
                  {{fbForm.inactive?'No':'Yes' }}
                </td>
            </tr>
        </ng-template>
    </p-table>
  </div>
</form>

<p-confirmDialog></p-confirmDialog>
