import { OnBoardingDocumentUserModel, UserOnBoardingDocumentsModel } from 'src/app/models/onBoardingDocumentUserModel';
import { deepClone, downloadFile } from 'src/app/helpers/utils';
import {
  getAssignedEquipmentList, saveAssignEquipmentSuccess,
  getEquipmentAssignPdf,
  saveReturnEquipmentSuccess,
  createEquipmentSuccess,
  clearCreateEquipmentForm,
  changedReturnEquipment,
  loadUserEquipments,
  addEquipmentToAssign,
  clearEquipmentsToAssign,
  changedUserOnEquipmentToAssign
} from './../../../../core/store/equipment/equipment.actions';
import { AssignedEquipmentModel, EquipmentWithAssignmentsModel } from './../../../../models/equipmentsModel';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { AlertService } from 'src/app/shared/services/alert.service';
import { getMyEquipmentList } from 'src/app/core/store/equipment/equipment.actions';
import { Router, ActivatedRoute } from '@angular/router';
import {
  getAllUserDocs, getActiveDocs, getUsersDocsReportSuccess,
  getUsersDocsReport,
  getUsersDocsReportExcel,
  getUsersDocsReportExcelSuccess,
  loadOnboardingDoc
} from 'src/app/core/store/onboardingdocs/onboardingdocs.actions';
import { loadCampuses } from 'src/app/core/store/payroll-approval/payroll-approval.actions';

@Component({
  selector: 'app-list-report-onboardingdocs',
  templateUrl: './list-report-onboardingdocs.component.html',
  styleUrls: ['./list-report-onboardingdocs.component.scss']
})
export class ListReportOnboardingdocsComponent extends BaseComponent implements OnInit {

  allUserDocs: PagingResultsModel<UserOnBoardingDocumentsModel>;
  canEdit: boolean;
  loadingAllUserDocs;
  filter: any;
  myUserID;
  expandeds = {};
  docsSignedCount = {};
  campusesList = [];
  campusesHash = {};
  campusID = [];
  activeDocs = [];
  filesID = [];
  onlyMissingSignature = false;
  loadingReport;
  loadingReportExcel;

  constructor(private actions$: Actions,
              private router: Router,
              private route: ActivatedRoute,
              private store: Store<State>,
              private alertService: AlertService, ) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.canEdit = permissionIDs && !!permissionIDs.find(p => p === 3060); //On boarding Manage      
      }),
      this.store.select(s => s.accountState.user.id).subscribe(myUserID => {
        this.myUserID = myUserID;
      }),
      this.store.select(s => s.onBoardingDocsState.allUserDocs).subscribe(allUserDocs => {
        this.allUserDocs = allUserDocs;
        if (this.allUserDocs && this.allUserDocs.result) {
          this.allUserDocs.result.forEach(d => {
            this.docsSignedCount[d.user.id] = d.onBoardingDocuments ?
              d.onBoardingDocuments.reduce((l, o) => {
                return l + ((o.onBoardingDocumentsUser && o.onBoardingDocumentsUser.signedDate) ? 1 : 0);
              }, 0)
              : 0;
          }
          );
        }

      }),
      this.store.select(s => s.onBoardingDocsState.loadingAllUserDocs).subscribe(loadingAllUserDocs => {
        this.loadingAllUserDocs = loadingAllUserDocs;
      }),
      this.store.select(s => s.payrollApprovalState.campuses).subscribe(campuses => {
        const campusesList = campuses && campuses.length > 0 ? campuses.map(c => ({ label: c.programName, value: c.id }))
          : [{ label: 'loading...', value: undefined }];

        this.campusesList = campusesList;
        this.campusesHash = {};
        campuses.forEach(c => {
          this.campusesHash[c.id] = c.programName;
        });

      }),
      this.store.select(s => s.onBoardingDocsState.activeDocs).subscribe(activeDocs => {
        const activeDocsList = activeDocs && activeDocs.length > 0 ? activeDocs.map(c => ({ label: c.title, value: c.id }))
          : [{ label: 'loading...', value: undefined }];

        this.activeDocs = activeDocsList;
        this.campusesHash = {};

      }),

      this.store.select(s => s.onBoardingDocsState.loadingReport).subscribe(loadingReport => {
        this.loadingReport = loadingReport;
      }),
      this.store.select(s => s.onBoardingDocsState.loadingReportExcel).subscribe(loadingReportExcel => {
        this.loadingReportExcel = loadingReportExcel;
      }),
      this.actions$.pipe(
        ofType(getUsersDocsReportSuccess),
        tap(action => {
          downloadFile(action.doc, 'ReportHrDocs.pdf');
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(getUsersDocsReportExcelSuccess),
        tap(action => {
          downloadFile(action.doc, 'ReportHrDocs.xlsx');
        })
      ).subscribe()
    );

    this.store.dispatch(loadCampuses());
    this.store.dispatch(getActiveDocs());
  }


  loadPage(event) {
    this.filter = event;
    this.filter.filters.onlyMissingSignature = { Name: 'onlyMissingSignature', Value: { value: this.onlyMissingSignature.toString() } };
    this.filter.filters.campusIDs = { Name: 'campusIDs', Value: { value: this.campusID.join(',') } };
    this.filter.filters.filesIDs = { Name: 'filesIDs', Value: { value: this.filesID.join(',') } };

    this.store.dispatch(getAllUserDocs({
      filter: deepClone(this.filter)
    }));
  }


  expand(id: string) {
    if (this.expandeds[id + '']) {
      this.expandeds[id + ''] = false;
    } else {
      for (const key in this.expandeds) {
        if (this.expandeds.hasOwnProperty(key)) {
          this.expandeds[key] = false;
        }
      }
      this.expandeds[id + ''] = true;


    }
  }

  changedFilters() {
    this.loadPage(this.filter);
  }


  getReport() {
    this.store.dispatch(getUsersDocsReport({ filter: deepClone(this.filter) }));
  }
  getReportExcel() {
    this.store.dispatch(getUsersDocsReportExcel({ filter: deepClone(this.filter) }));
  }

  viewDoc(onboardingDocsUser: OnBoardingDocumentUserModel) {
    this.store.dispatch(loadOnboardingDoc({ onboardingDocUserId: onboardingDocsUser.id }));
    const subDocBuffer = this.store.select(s => s.onBoardingDocsState.docArrayBuffer).subscribe(docArrayBuffer => {
      if (docArrayBuffer) {
        downloadFile(new Blob([docArrayBuffer], { type: 'application/pdf' }), onboardingDocsUser.onBoarding_Document.fileName);
        subDocBuffer.unsubscribe();
      }
    });
  }
}

