<div class="d-flex w-100 justify-content-center" *ngIf="!profile">
      <i class="material-icons rotate text-muted mt-1 mb-5">loop</i>
</div>

<div class="w-100 text-center mb-3 d-sm-flex justify-content-center " *ngIf="profile">
    <span class="mr-sm-2 align-items-center">
      <i *ngIf="uploadingPhoto" class="material-icons rotate text-muted">loop</i>
      <ng-container *ngIf="!uploadingPhoto">
        <img [src]="profilePicture?profilePicture:'assets/img/Portrait_Placeholder.png'" class="img-rounded"><br>
        <div *ngIf="!readonly">
          <a href="javascript:void(0)" (click)="activateFileInput(fileInput)">Change Photo</a>
          <input type="file" class="d-none" #fileInput  accept="image/*" (change)="onFileChange($event)"  />
        </div>
      </ng-container>
    </span>
    <span class="d-inline-flex align-items-center mr-2 ml-2">
      <h3 class="pointer" [routerLink]="['/profile']">{{ profile.user.firstName }} {{ profile.user.lastName }}</h3>
    </span>
    <span class="d-inline-flex align-items-center ml-sm-3"  *ngIf="hasClockPunchRight && !profile.pendingRequiredDocumentsCount">

        <i *ngIf="clockInOutLoading" class="material-icons rotate text-muted">loop</i>
        <ng-container *ngIf="!clockInOutLoading">
          <button class="btn" *ngIf="ipValid" [class.btn-success]="!isClockedIn" [class.btn-danger]="isClockedIn"
          [class.disable]="profile.pendingRequiredDocumentsCount>0"
          (click)="clockInOut()">{{isClockedIn?'Clock Out':'Clock In'}}</button>
          <p-dropdown *ngIf="optionalPayableTypes.length > 0 && isClockedIn" styleClass="form-control ml-2" [options]="optionalPayableTypes" [(ngModel)]="payableTypeId" [ngModelOptions]="{standalone: true}"></p-dropdown>
          <span *ngIf="!ipValid"><i class="material-icons text-warning" title="You cannot clock in or out from your current IP address, if you are using a mobile device you must connect to the company WiFi">error</i></span>
        </ng-container>
    </span>
  </div>
