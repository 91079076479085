import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { SelectItem } from 'primeng/api';
import { loadEmployeeUsers } from '../../../../core/store/employee/employee.action';
import { deepClone } from '../../../../helpers/utils';
import { DateRangeModel } from '../../../../models/dateRangeModel';
import { DropdownFilterService } from '../../../../core/services/dropdown-filter.service';
import { dismissTransmission, dismissTransmissionSuccess, getPaylocityTransmissions } from '../../../../core/store/paylocity/paylocity.action';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { PaylocityTransmissionModel } from '../../../../models/paylocityTranmissionModel';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-report-paylocity',
  templateUrl: './report-paylocity.component.html',
  styleUrls: ['./report-paylocity.component.scss']
})
export class ReportPaylocityComponent extends BaseComponent implements OnInit {
  filter: any;
  canEdit: boolean = false;
  list: PagingResultsModel<PaylocityTransmissionModel>;

  loadingPaylocityList: boolean = true;

  prefixText: string;
  userEmployeeList: SelectItem[];
  userId: number = 0

  typeList: SelectItem[] = [{ label: 'All', value: null }, { label: 'CREATE', value: 'CREATE' }, { label: 'UPDATE', value: 'UPDATE' }, { label: 'EARNING', value: 'EARNING' }, { label: 'DELETE EARNING', value: 'DELETE EARNING' }, { label: 'DEDUCTION', value: 'DEDUCTION' }, { label: 'DELETE DEDUCTION', value: 'DELETE DEDUCTION' }];
  transmissionType: string;

  dateRangeList: SelectItem[];
  dateRange: DateRangeModel;
  startDate: string = null;
  endDate: string = null;
  isCustomDateRange: boolean = false;

  yesNoAllOptions: SelectItem[] = [
    { label: 'All', value: null },
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  hasError: boolean = null;
  dismissed: boolean = false;

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private dropdownFilterService: DropdownFilterService  ) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.canEdit = permissionIDs && !!permissionIDs.find(p => p === 3074); //Manage Paylocity
      }),
      this.store.select(s => s.employeeState.employeeUsers).subscribe(users => {
        this.userEmployeeList = users;
      }),
      this.store.select(s => s.paylocityState.loadingTransmissions).subscribe(loading => {
        this.loadingPaylocityList = loading;
      }),
      this.store.select(s => s.paylocityState.transmissions).subscribe(list => {
        this.list = list;
      }),
      this.actions$.pipe(
        ofType(dismissTransmissionSuccess),
        tap(() => {
          this.reloadList(this.filter);
        })
      ).subscribe(),
    );

    this.dateRangeList = this.dropdownFilterService.getLast120DayDateRanges().map(c => ({ label: c.text, value: c }));
  }  

  getStaffList(event) {
    this.store.dispatch(loadEmployeeUsers({
      prefixText: event.query,
      programId: null,
      payrollClassificationId: null,
      showTerminated: true,
      showInactive: true
    }));
  }

  changedDateRange() {
    if (this.dateRange.isCustom) {
      this.isCustomDateRange = true;
      return; //reload after manually changing date
    }

    this.isCustomDateRange = false;
    this.startDate = this.dateRange.startDate;
    this.endDate = this.dateRange.endDate;
    this.reloadList(this.filter);
  }

  reloadListAfterStaffChage(selectedUser) {
    if (typeof selectedUser.value !== 'number') {
      return;
    }

    this.userId = selectedUser.value;
    this.reloadList(this.filter);
  }

  reloadListAfterClear(event) {
    if (event.data !== null) {
      return;
    }

    this.userId = 0;
    this.reloadList(this.filter);
  }

  reloadList(event) {    
    this.filter = event;
    this.filter = deepClone(this.filter);
    this.filter.filters.userId = { Name: 'userId', Value: { value: this.userId.toString() } };
    this.filter.filters.transmissionType = { Name: 'transmissionType', Value: { value: this.transmissionType } };
    this.filter.filters.startDate = { Name: 'startDate', Value: { value: this.startDate } };
    this.filter.filters.endDate = { Name: 'endDate', Value: { value: this.endDate } };
    this.filter.filters.hasError = { Name: 'hasError', Value: { value: this.hasError } };
    this.filter.filters.dismissed = { Name: 'dismissed', Value: { value: this.dismissed } };

    this.store.dispatch(getPaylocityTransmissions({
      filter: this.filter
    }));
  }

  dismissTransmission(transmission: PaylocityTransmissionModel) {
    if (typeof transmission === 'undefined' || transmission === null) {
      //display error
      return;
    }

    this.store.dispatch(dismissTransmission({transmission}));
  }

}
