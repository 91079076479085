
<div [formGroup]="form" *ngIf="profile!=null">
    <h5 class="card-title">Update profile for filling the document</h5>
    This document require some extra infos to be filled, please provide the infos bellow:<br>
    (the info will be saved on your profile and used to fill the document)
    <div class="row">
      <div class="col-12 col-xl-6 ">
    <div class="row">
      <div class="col-4 d-flex align-items-center">
        <b>Legal First Name</b>
      </div>
      <div class="col-8  pt-2 pb-2" >
        {{ profile.user.firstName }}
      </div>
    </div>
    <div class="row">
      <div class="col-4 d-flex align-items-center">
        <b>Legal Last Name</b>
      </div>
      <div class="col-8  pt-2 pb-2">
        {{ profile.user.lastName }}
      </div>
    </div>

    <div class="row" formGroupName="user" >
      <div class="col-4 d-flex align-items-center">
        <b>Middle Name</b>
      </div>
      <div class="col-8">
        <input type="text" class="form-control mt-2" formControlName="middleName"/>
        <app-validation-message [form]="form" [path]="'user.middleName'" [name]="'middleName'" [label]="'Middle Name'"> </app-validation-message>
      </div>
    </div>

    <div class="row" formGroupName="user">
      <div class="col-4 d-flex align-items-center">
        <b>Previous Names</b>
      </div>
      <div class="col-8">
        <input type="text" class="form-control mt-2" formControlName="previousName"/>
        <app-validation-message [form]="form" [path]="'user.previousName'" [name]="'previousName'" [label]="'Previous Name'"> </app-validation-message>
      </div>
    </div>

    <div class="row" formGroupName="employee">
      <div class="col-4 d-flex align-items-center">
        <b>Birth Date</b>
      </div>
      <div class="col-8">
        <p-calendar inputStyleClass="form-control mt-2" [monthNavigator]="true" [yearNavigator]="true" [yearRange]="yearRange"
         styleClass="w-100" panelStyleClass="calendarPanel" [hourFormat]="12" [showTime]="false"
         id="birthdate" name="birthdate" dataType="string" formControlName="birthdate" ></p-calendar>

        <app-validation-message [form]="form" [path]="'employee.birthdate'" [name]="'birthdate'" [label]="'Birth Date'"> </app-validation-message>
      </div>
    </div>

    <div class="row"  formGroupName="employee">
      <div class="col-4 d-flex align-items-center">
        <b>SSN</b>
      </div>
      <div class="col-8">
        <input type="text" class="form-control mt-2" formControlName="ssn"/>
        <app-validation-message [form]="form" [path]="'employee.ssn'" [name]="'ssn'" [label]="'SSN'"> </app-validation-message>
      </div>
    </div>

    <div class="row"  formGroupName="employee">
      <div class="col-4 d-flex align-items-center">
        <b>Personal Email</b>
      </div>
      <div class="col-8">
        <input type="text" class="form-control mt-2" formControlName="personalEmail"/>
        <app-validation-message [form]="form" [path]="'employee.personalEmail'" [name]="'personalEmail'" [label]="'Email'"> </app-validation-message>

      </div>
    </div>

    <div class="row"  formGroupName="employee">
      <div class="col-4 d-flex align-items-center">
        <b>Address</b>
      </div>
      <div class="col-8">
        <input
          type="text"
          class="form-control mt-2"
          formControlName="address"
        />
        <app-validation-message [form]="form" [path]="'employee.address'" [name]="'address'" [label]="'Address'"> </app-validation-message>
      </div>
    </div>
    <div class="row"  formGroupName="employee">
      <div class="col-4 d-flex align-items-center">

      </div>
      <div class="col-8">
        <input
          type="text"
          class="form-control mt-2"
          formControlName="addressExtra"
        />
        <app-validation-message [form]="form" [path]="'employee.addressExtra'" [name]="'addressExtra'" [label]="'Address Extra'"> </app-validation-message>
      </div>
    </div>
    <div class="row"  formGroupName="employee">
      <div class="col-4 d-flex align-items-center">
        <b>City</b>
      </div>
      <div class="col-8">
        <input
          type="text"
          class="form-control mt-2"
          formControlName="city"
        />
        <app-validation-message [form]="form" [path]="'employee.city'" [name]="'city'" [label]="'City'"> </app-validation-message>
      </div>
    </div>
    <div class="row"  formGroupName="employee">
      <div class="col-4 d-flex align-items-center">
        <b>State</b>
      </div>
      <div class="col-8">
        <input
          type="text"
          class="form-control mt-2"
          formControlName="state"
        />
        <app-validation-message [form]="form" [path]="'employee.state'" [name]="'state'" [label]="'State'"> </app-validation-message>

      </div>
    </div>
    <div class="row"  formGroupName="employee">
      <div class="col-4 d-flex align-items-center">
        <b>Zip Code</b>
      </div>
      <div class="col-8">
        <input
          type="text"
          class="form-control mt-2"
          formControlName="zipCode"
        />
        <app-validation-message [form]="form" [path]="'employee.zipCode'" [name]="'zipCode'" [label]="'Zip Code'"> </app-validation-message>

      </div>
    </div>
    <div class="row"  formGroupName="employee">
      <div class="col-4 d-flex align-items-center">
        <b>New Applicant, Renewal or Transfer?</b>
      </div>
      <div class="col-8  pt-2 pb-2">
        <p-selectButton [options]="newRenewOptions" formControlName="bciNewRenewTransfer"></p-selectButton>
        <app-validation-message [form]="form" [path]="'employee.bciNewRenewTransfer'" [name]="'bciNewRenewTransfer'" [label]="'New Renew Transfer'"> </app-validation-message>

      </div>
    </div>
    <div class="row" formGroupName="employee">
      <div class="col-4 d-flex align-items-center">
        <b>Have your ever been arrested?</b>
      </div>
      <div class="col-8  pt-2 pb-2">
        <p-selectButton [options]="yesNoOptions" formControlName="bciBeenArrested"></p-selectButton>
        <app-validation-message [form]="form" [path]="'employee.bciBeenArrested'" [name]="'bciBeenArrested'" [label]="'Been Arrested'"> </app-validation-message>


      </div>
    </div>

    <div class="row" formGroupName="employee">
      <div class="col-4 d-flex align-items-center">
        <b>Have you ever been investigated for child or adult abuse, neglect or exploitation?</b>
      </div>
      <div class="col-8  pt-2 pb-2">
        <p-selectButton [options]="yesNoOptions" formControlName="bciBeenInvestigated"></p-selectButton>
        <app-validation-message [form]="form" [path]="'employee.bciBeenInvestigated'" [name]="'bciBeenInvestigated'" [label]="'Been Investigated'"> </app-validation-message>

      </div>
    </div>


    <div class="row" formGroupName="employee">
      <div class="col-4 d-flex align-items-center">
        <b>Live Scan completed?</b>
      </div>
      <div class="col-8  pt-2 pb-2">
        <p-selectButton [options]="yesNoOptions" formControlName="bciLiveScanCompleted"></p-selectButton>
        <app-validation-message [form]="form" [path]="'employee.bciLiveScanCompleted'" [name]="'bciLiveScanCompleted'" [label]="'Live Scan Completed'"> </app-validation-message>

      </div>
    </div>
    <div class="row"  formGroupName="employee">
      <div class="col-4 d-flex align-items-center">
        <b>TCN</b>
      </div>
      <div class="col-8">
        <input
          type="text"
          class="form-control mt-2"
          formControlName="bciTCN"
        />
        <app-validation-message [form]="form" [path]="'employee.bciTCN'" [name]="'bciTCN'" [label]="'TCN'"> </app-validation-message>

      </div>
    </div>


    <div class="row" formGroupName="employee">
      <div class="col-4 d-flex align-items-center">
        <b>Are you applying to work in a youth residential program?</b>
      </div>
      <div class="col-8  pt-2 pb-2">
        <p-selectButton [options]="yesNoOptions" formControlName="bciIsYouthResidential"></p-selectButton>
        <app-validation-message [form]="form" [path]="'employee.bciIsYouthResidential'" [name]="'bciIsYouthResidential'" [label]="'Youth Residential'"> </app-validation-message>

      </div>
    </div>

    <div class="row" formGroupName="employee" *ngIf="form && form.get('employee.bciIsYouthResidential').value">
      <div class="col-4 d-flex align-items-center">
        <b>Have you lived outside the State of Utah in the last 5 years?</b>
      </div>
      <div class="col-8  pt-2 pb-2">
        <p-selectButton [options]="yesNoOptions" formControlName="bciLivedOutside"></p-selectButton>
        <app-validation-message [form]="form" [path]="'employee.bciLivedOutside'" [name]="'bciLivedOutside'" [label]="'Lived outside'"> </app-validation-message>
      </div>
    </div>

    <div class="row" formGroupName="employee" *ngIf="form && form.get('employee.bciIsYouthResidential').value && form.get('employee.bciLivedOutside').value">
      <div class="col-4 d-flex align-items-center">
        <b>I certify out-of-state registry records are in process and I will be ineligible for renewal if this process is not completed. (please submit out of state registry records for each state resided in. Instructions are located at https://hslic.utah.gov/Out-of-state-registries)</b>
      </div>
      <div class="col-8  pt-2 pb-2">
        <p-selectButton [options]="yesNoOptions" formControlName="bciLivedOutsideCertify"></p-selectButton>
        <app-validation-message [form]="form" [path]="'employee.bciLivedOutsideCertify'" [name]="'bciLivedOutsideCertify'" [label]="'Lived outside Certify'"> </app-validation-message>
      </div>
    </div>

    <div class="row" formGroupName="employee" *ngIf="form && form.get('employee.bciIsYouthResidential').value && form.get('employee.bciLivedOutside').value">
      <div class="col-4 d-flex align-items-center">
        <b>Please list city and state within the last 5 years</b>
      </div>
      <div class="col-8  pt-2 pb-2">
        <input
        type="text"
        class="form-control mt-2"
        formControlName="bciLivedOutsideList"
      />
        <app-validation-message [form]="form" [path]="'employee.bciLivedOutsideList'" [name]="'bciLivedOutsideList'" [label]="'List of cities lived outside '"> </app-validation-message>
      </div>
    </div>


    <div class="row mt-4">
      <div class="col-6">
        <button class="btn btn-primary" [class.disabled]="form.invalid" (click)="save()">Update and Sign</button>
      </div>
    </div>
  </div>
</div>
</div>
