import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { InternshipSubstatusModel } from '../../../../models/internModels';
import { closeEditInternshipSubstatus, deleteInternshipSubstatus, deleteInternshipSubstatusSuccess, getInternshipSubstatus, loadInternshipStatuses, saveInternshipSubstatus, saveInternshipSubstatusSuccess } from '../../../../core/store/intern/intern.action';
import { ModifiedSelectItem } from '../../../../models/utilModels';
import { markFormGroupTouched, updateFormGroupValidity } from '../../../../helpers/utils';

@Component({
  selector: 'app-edit-intern-substatus',
  templateUrl: './edit-intern-substatus.component.html',
  styleUrls: ['./edit-intern-substatus.component.scss']
})
export class EditInternSubstatusComponent extends BaseComponent implements OnInit {
  display: boolean = false;
  model: InternshipSubstatusModel;
  title: string;
  form: FormGroup;
  disableButtons: boolean = true;
  substatusId: number = 0;  

  applicationStatuses: ModifiedSelectItem[];


  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.form = this.createForm();
    this.subs.push(
      this.store.select(s => s.internState.editingSubstatus).subscribe(editing => {
        if (this.display && !editing || (editing && this.substatusId == 0)) {
          this.resetForm();
        }
        this.display = editing;

      }),
      this.store.select(s => s.internState.savingSubstatus).subscribe(saving => {
        this.disableButtons = saving;
      }),
      this.store.select(s => s.internState.substatusId).subscribe(id => {
        this.substatusId = id;
        this.title = "New Substatus";
        if (typeof id !== 'undefined' && id > 0) {
          this.store.dispatch(getInternshipSubstatus({ substatusId: id }));
        }
      }),
      this.store.select(s => s.internState.selectedSubstatus).subscribe(selected => {
        if (selected === null) {
          this.model = null;
          return;
        }

        this.title = "Edit Substatus #" + selected.id;
        this.form.get("id").setValue(selected.id);
        this.form.get("substatus").setValue(selected.substatus);
        this.form.get("primaryStatusId").setValue(selected.primaryStatusId);
        this.form.get("archived").setValue(selected.archived);

      }),
      this.store.select(s => s.internState.internshipStatuses).subscribe(statuses => {
        if (statuses && statuses.length > 0) {
          this.applicationStatuses = statuses.map(x => ({ label: x.label, value: x.value }));
          this.applicationStatuses.unshift({ label: '', value: null });
        }
        else {
          this.applicationStatuses = [{ label: 'loading...', value: undefined }];
        }
      }),
      this.actions$.pipe(
        ofType(saveInternshipSubstatusSuccess),
        tap(action => {
          this.closeModal();
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(deleteInternshipSubstatusSuccess),
        tap(action => {
          this.closeModal();
        })
      ).subscribe(),
    );

    if (this.applicationStatuses == null || this.applicationStatuses.length == 0 || this.applicationStatuses[0].label === 'loading...') {
      this.store.dispatch(loadInternshipStatuses());
    }
  }


  createForm() {
    let f = this.fb.group({
      id: this.fb.control(0),
      substatus: this.fb.control("", [Validators.required]),
      primaryStatusId: this.fb.control(null, [Validators.required]),      
      archived: this.fb.control(false)
    });

    return f;
  }

  resetForm() {
    this.form.reset({
      id: 0,
      substatus: "",
      primaryStatusId: null,
      archived: false
    });
  }

  closeModal() {
    this.store.dispatch(closeEditInternshipSubstatus());
  }

  saveSubstatus() {
    updateFormGroupValidity(this.form);
    if (this.form.valid) {
      this.store.dispatch(saveInternshipSubstatus({ substatus: this.form.value as InternshipSubstatusModel }));
    } else {
      markFormGroupTouched(this.form);
    }
  }

  deleteSubstatus() {
    if (this.substatusId > 0) {
      this.store.dispatch(deleteInternshipSubstatus({ substatusId: this.substatusId }));
    }
    else {
      this.closeModal(); //delete button should be hidden if not > 0
    }
  }
}
