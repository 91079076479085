import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { FBFormJobTitleAssignmentModel } from '../../../../models/formBuilderModels';
import { SelectItem } from 'primeng/api';
import { Actions, ofType } from '@ngrx/effects';
import { Router, ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { getFBFormJobTitleAssignment, getFormsFilter, updateFBFormJobTitleAssignment, updateFBFormJobTitleAssignmentSuccess } from '../../../../core/store/form-builder/form-builder.actions';
import { loadJobTitles } from '../../../../core/store/profile/profile.actions';
import { deepClone } from '../../../../helpers/utils';

@Component({
  selector: 'app-form-assignment-jobtitle',
  templateUrl: './form-assignment-jobtitle.component.html',
  styleUrls: ['./form-assignment-jobtitle.component.scss']
})
export class FormAssignmentJobtitleComponent extends BaseComponent implements OnInit {

  formJobTitleAssignment: FBFormJobTitleAssignmentModel =
    {
      id: null,
      formAssignNotJobTitles: [],
      formAssignJobTitles: [],
      formRequiredNotJobTitles: [],
      formRequiredJobTitles: []
    };
  formOptions: SelectItem[];
  updatingFormJobTitleAssignment = false;
  assignOptions: SelectItem[] = [
    { label: 'Assign to selected job titles', value: 1 },
    { label: 'Assign to all job titles except the selected ones', value: 2 },
  ];

  requiredOptions: SelectItem[] = [
    { label: 'Required for selected job titles', value: 1 },
    { label: 'Required for all job titles except the selected ones', value: 2 },
  ];
  requiredOption;
  assignOption;

  assignJobTitles;
  assignNotJobTitles;
  requiredJobTitles;
  requiredNotJobTitles;

  jobTitlesOptions: SelectItem[] = [];

  constructor(
    private actions$: Actions,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<State>) {
    super();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params.formID) {
        this.formJobTitleAssignment.id = params.formID;
        this.assignOption = null;
        this.requiredOption = null;
        this.store.dispatch(getFBFormJobTitleAssignment({ formID: this.formJobTitleAssignment.id }));
      }
    });
    this.store.dispatch(getFormsFilter());
    this.store.dispatch(loadJobTitles());
    this.subs.push(
      this.store.select(s => s.profileState.jobTitles).subscribe(jobTitles => {
        if (jobTitles) {
          this.jobTitlesOptions = jobTitles.map(x => ({ label: x.jobTitle, value: x.id }));
        }
      }),
      this.store.select(s => s.formBuilderState.simpleFormsList).subscribe(formsList => {
        this.formOptions = formsList ? formsList.filter(x => x.formTypeID === 1 && !x.inactive && !x.hidden).map(c => ({ label: c.name, value: c.id })) : [];
      }),

      this.store.select(s => s.formBuilderState.updatingFormJobTitleAssignment).subscribe(updatingFormJobTitleAssignment => {
        this.updatingFormJobTitleAssignment = updatingFormJobTitleAssignment;
      }),

      this.store.select(s => s.formBuilderState.formJobTitleAssignment).subscribe(formJobTitleAssignment => {
        if (formJobTitleAssignment) {
          this.formJobTitleAssignment = deepClone(formJobTitleAssignment);
          if (this.formJobTitleAssignment.formAssignJobTitles.length > 0) {
            this.assignOption = 1;
          }
          if (this.formJobTitleAssignment.formAssignNotJobTitles.length > 0) {
            this.assignOption = 2;
          }
          if (this.formJobTitleAssignment.formRequiredJobTitles.length > 0) {
            this.requiredOption = 1;
          }
          if (this.formJobTitleAssignment.formRequiredNotJobTitles.length > 0) {
            this.requiredOption = 2;
          }
        } else {
          this.formJobTitleAssignment = {
            id: null,
            formAssignNotJobTitles: [],
            formAssignJobTitles: [],
            formRequiredNotJobTitles: [],
            formRequiredJobTitles: []
          };
        }
      }),

      this.actions$.pipe(
        ofType(updateFBFormJobTitleAssignmentSuccess),
        tap(action => {
          this.router.navigate(['/form-builder/forms-assignment']);
        })
      ).subscribe(),
    );
  }

  save() {
    if (!this.formJobTitleAssignment || !this.formJobTitleAssignment.id) {
      return;
    }

    if (this.assignOption === 1) {
      this.formJobTitleAssignment.formAssignNotJobTitles = [];
    }
    if (this.assignOption === 2) {
      this.formJobTitleAssignment.formAssignJobTitles = [];
    }
    if (this.requiredOption === 1) {
      this.formJobTitleAssignment.formAssignNotJobTitles = [];
    }
    if (this.requiredOption === 2) {
      this.formJobTitleAssignment.formRequiredJobTitles = [];
    }
    this.store.dispatch(updateFBFormJobTitleAssignment({ formJobTitleAssignmentModel: deepClone(this.formJobTitleAssignment) }));
  }

  changedForm(event) {

    this.assignOption = null;
    this.requiredOption = null;
    this.store.dispatch(getFBFormJobTitleAssignment({ formID: this.formJobTitleAssignment.id }));
  }
}
