import { createImprovementPlanSuccess } from './../../../../core/store/improvementPlan/improvementPlan.actions';
import { ImprovementPlanModel } from './../../../../models/improvementPlanModel';
import {
  updateCreateCoachingLogForm,
  createCoachingLog, getCoachableUsersList, getCategoriesByLevelList, getCoachingLogSuccess, getCoachingLog
} from './../../../../core/store/coachingLog/coahingLog.actions';
import { getUsersList } from './../../../../core/store/users/users.actions';
import { SelectItem } from 'primeng/api';
import { UserModel } from 'src/app/models/userModel';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { getAllControlsErrors, markFormGroupTouched, deepClone } from 'src/app/helpers/utils';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { Router, ActivatedRoute } from '@angular/router';
import { initImprovementPlanForm } from 'src/app/core/store/improvementPlan/improvementPlan.reducer';
import { updateCreateImprovementPlanForm, createImprovementPlan, getImprovementPlan, clearImprovementPlan } from 'src/app/core/store/improvementPlan/improvementPlan.actions';
import { ofType, Actions } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { CoachingLogCategoryModel } from '../../../../models/coachingLogCategoryModel';
import { DatePipe } from '@angular/common';
import { CoachingLogWithUsersModel } from '../../../../models/coachingLogWithUsersModel';

@Component({
  selector: 'app-create-improvementplan',
  templateUrl: './create-improvementplan.component.html',
  styleUrls: ['./create-improvementplan.component.scss']
})
export class CreateImprovementPlanComponent extends BaseComponent implements OnInit {

  improvementPlan: ImprovementPlanModel;
  improvementPlanID: number;
  form: FormGroup;
  improvementPlanForm: FormGroup;
  canAddCoach: boolean;
  loadingUsersList = false;
  usersList: SelectItem[] = [{ label: 'loading...', value: undefined }];
  allUsersList: SelectItem[] = [{ label: 'loading...', value: undefined }];
  creatingImprovementPlan = false;
  initedForm = false;
  loadingCategories: boolean = false;
  categoriesList: CoachingLogCategoryModel[];
  hasOtherCategory: boolean = false;
  previousCoachingLog: CoachingLogWithUsersModel;

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private router: Router,
    private route: ActivatedRoute,
    private datepipe: DatePipe) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.improvementPlanState.improvementPlan).subscribe(improvementPlan => {

        if (this.improvementPlanID && !this.initedForm) {
          const improvementPlanClone: ImprovementPlanModel = deepClone(improvementPlan);
          if (improvementPlanClone.nextReviewDate) {
            improvementPlanClone.nextReviewDate = this.datepipe.transform(new Date(improvementPlanClone.nextReviewDate), 'yyyy-MM-dd')
          }
          if (improvementPlanClone.noteDate) {
            improvementPlanClone.noteDate = this.datepipe.transform(new Date(improvementPlanClone.noteDate), 'yyyy-MM-dd')
          } else {
            improvementPlanClone.noteDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd')
          }
          this.improvementPlan = improvementPlanClone;
          this.initForm(improvementPlanClone);
        }
      }),
    );

    this.route.params.subscribe(params => {
      if (params.improvementPlanID) {
        this.improvementPlanID = params.improvementPlanID;
        this.store.dispatch(getImprovementPlan({ improvementPlanId: this.improvementPlanID }));
      }
      if (params.previousCoachingLogID) {
        this.store.dispatch(getCoachingLog({ coachingLogID: params.previousCoachingLogID }));
      }
    });

    if (!this.improvementPlanID) {
      const emptyImprovementPlan = {
        noteDate: this.datepipe.transform(new Date(), 'yyyy-MM-dd')
      } as ImprovementPlanModel;
      this.initForm(emptyImprovementPlan);
      this.store.dispatch(clearImprovementPlan());
      
      this.improvementPlan = emptyImprovementPlan;

    }
  }

  initForm(improvementPlan: ImprovementPlanModel) {
    this.initedForm = true;
    // Initialize the form
    this.form = initImprovementPlanForm(improvementPlan);
    this.improvementPlanForm = this.form.get("improvementPlan") as FormGroup;
    this.store.dispatch(getCoachableUsersList());
    this.store.dispatch(getUsersList());
    this.store.dispatch(getCategoriesByLevelList({ levelID: 4 }));

    this.subs.push(

      // Update form values on the store
      this.form.valueChanges.subscribe(formValues => {
        const improvementPlanValues = this.improvementPlanForm.value;
        const formErrors = getAllControlsErrors(this.form);
        this.store.dispatch(updateCreateImprovementPlanForm({ improvementPlan: improvementPlanValues, formErrors }));
      }),

      // Check if user is an HR manager
      this.store.select(s => s.accountState.user.permissionIDs).subscribe(permissionIDs => {
        this.canAddCoach = permissionIDs && !!permissionIDs.find(p => p === 6004 || p === 6005 || p === 6006);
      }),
      this.store.select(s => s.improvementPlanState.creatingImprovementPlan).subscribe(creatingImprovementPlan => {
        this.creatingImprovementPlan = creatingImprovementPlan;
      }),
      this.store.select(s => s.coachingLogState.coachableUsersList).subscribe(usersList => {
        this.usersList = usersList ? usersList.map(u => ({ label: u.firstName + ' ' + u.lastName, value: u.id }))
          : [{ label: 'loading...', value: undefined }];
        this.usersList.unshift({ label: '', value: null });
      }),
      this.store.select(s => s.usersState.usersList).subscribe(usersList => {
        this.allUsersList = usersList ? usersList.map(u => ({ label: u.firstName + ' ' + u.lastName, value: u.id }))
          : [{ label: 'loading...', value: undefined }];
        this.allUsersList.unshift({ label: '', value: null });
      }),
      this.actions$.pipe(
        ofType(createImprovementPlanSuccess),
        tap(action => {
          this.router.navigate(['/coachinglogs/given']);
        })
      ).subscribe(),

      this.actions$.pipe(
        ofType(getCoachingLogSuccess),
        tap(action => {
          this.previousCoachingLog = action.coachingLog;
          this.improvementPlanForm.get("performanceConcern").setValue(action.coachingLog.description);
          this.improvementPlanForm.get("userID").setValue(action.coachingLog.userID);
          this.improvementPlanForm.get("supervisorUserID").setValue(action.coachingLog.createdByUserID);
          /*this.coachingForm.get("level").setValue(2);
          this.coachingForm.get("userID").setValue(action.coachingLog.userID);
          this.coachingForm.get("description").setValue(action.coachingLog.description);
          */
          this.improvementPlanForm.get("previouslyAddressedCoachingLogID").setValue(this.previousCoachingLog.id);


        })
      ).subscribe(),

      this.store.select(s => s.coachingLogState.categoriesList).subscribe(categories => {
        if (categories == null)
          categories = [];
        this.categoriesList = [];
        const formArray: FormArray = this.categoriesFormArray;
        formArray.clear();
        this.hasOtherCategory = false;
        categories.forEach((cl) => {
          if (cl.categoryName == "Other") {
            this.hasOtherCategory = true;
            return;
          }
          this.categoriesList.push(cl);
          var checked = false;
          if (this.improvementPlan && this.improvementPlan.categories)
            checked = this.improvementPlan.categories.indexOf(cl.categoryName) >= 0;

          formArray.push(new FormControl(checked));
        });
        this.improvementPlanForm.get("otherCategory").setValue("");

        if (categories && categories.length > 0 && this.improvementPlan && this.improvementPlan.categories) {
          var otherCategory = this.improvementPlan.categories.filter(s => categories.some(s2 => s == s2.categoryName) == false);

          if (otherCategory && otherCategory.length > 0) {
            this.improvementPlanForm.get("otherCategory").setValue(otherCategory[0]);
          }
        }

      }),

    );
  }

  getForm() {
    return this.form;
  }
  get categoriesFormArray() {
    return this.improvementPlanForm.get('categories') as FormArray;
  }

  save() {
    if (this.form.valid) {
      const selectedCategories = this.categoriesFormArray.value
        .map((v, i) => v ? this.categoriesList[i].categoryName : null)
        .filter(v => v !== null);
      if (this.hasOtherCategory && this.improvementPlanForm.get("otherCategory").value)
        selectedCategories.push(this.improvementPlanForm.get("otherCategory").value);

      this.store.dispatch(createImprovementPlan({
        improvementPlanModel: {
          ...this.improvementPlanForm.value,
          categories: selectedCategories
        }
      }));
    } else {
      markFormGroupTouched(this.form);
    }
  }


}

