import { loadUserTypes } from './../../../../core/store/profile/profile.actions';
import { FBFormRoleAssignmentModel } from './../../../../models/formBuilderModels';
import { getFormsFilter, updateFBFormRoleAssignmentSuccess, updateFBFormRoleAssignment, getFBFormRoleAssignment } from './../../../../core/store/form-builder/form-builder.actions';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { SelectItem } from 'primeng/api';
import { deepClone } from 'src/app/helpers/utils';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { Actions, ofType } from '@ngrx/effects';
import { Router, ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-form-assignment-role',
  templateUrl: './form-assignment-role.component.html',
  styleUrls: ['./form-assignment-role.component.scss']
})
export class FormAssignmentRoleComponent extends BaseComponent implements OnInit {

  formRoleAssignment: FBFormRoleAssignmentModel =
    {
      id: null,
      formAssignNotInRoles: [],
      formAssignRoles: [],
      formRequiredNotInRoles: [],
      formRequiredRoles: []
    };
  formOptions: SelectItem[];
  updatingFormRoleAssignment = false;
  assingOptions: SelectItem[] = [
    { label: 'Assign to selected roles', value: 1 },
    { label: 'Assign to all roles except the selected ones', value: 2 },
  ];

  requiredOptions: SelectItem[] = [
    { label: 'Required for selected roles', value: 1 },
    { label: 'Required for all roles except the selected ones', value: 2 },
  ];
  requiredOption;
  assingOption;

  assignRoles;
  assignNotInRoles;
  requiredRoles;
  requiredNotInRoles;

  rolesOptions: SelectItem[] = [];

  constructor(
    private actions$: Actions,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<State>) {
    super();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params.formID) {
        this.formRoleAssignment.id = params.formID;
        this.assingOption = null;
        this.requiredOption = null;
        this.store.dispatch(getFBFormRoleAssignment({ formID: this.formRoleAssignment.id }));
      }
    });
    this.store.dispatch(getFormsFilter());
    this.store.dispatch(loadUserTypes());
    this.subs.push(      
      this.store.select(s => s.profileState.userTypes).subscribe(userTypes => {
        if (userTypes) {
          this.rolesOptions = userTypes.map(x => ({ label: x.userType, value: x.id }));
        }
      }),
      this.store.select(s => s.formBuilderState.simpleFormsList).subscribe(formsList => {
        this.formOptions = formsList ? formsList.filter(x => x.formTypeID === 1 && !x.hidden && !x.inactive).map(c => ({ label: c.name, value: c.id })) : [];
      }),

      this.store.select(s => s.formBuilderState.updatingFormRoleAssignment).subscribe(updatingFormRoleAssignment => {
        this.updatingFormRoleAssignment = updatingFormRoleAssignment;
      }),

      this.store.select(s => s.formBuilderState.formRoleAssignment).subscribe(formRoleAssignment => {
        if (formRoleAssignment) {
          this.formRoleAssignment =  deepClone(formRoleAssignment);
          if (this.formRoleAssignment.formAssignRoles.length > 0) {
            this.assingOption = 1;
          }
          if (this.formRoleAssignment.formAssignNotInRoles.length > 0) {
            this.assingOption = 2;
          }
          if (this.formRoleAssignment.formRequiredRoles.length > 0) {
            this.requiredOption = 1;
          }
          if (this.formRoleAssignment.formRequiredNotInRoles.length > 0) {
            this.requiredOption = 2;
          }
        } else {
          this.formRoleAssignment = {
            id: null,
            formAssignNotInRoles: [],
            formAssignRoles: [],
            formRequiredNotInRoles: [],
            formRequiredRoles: []
          };
        }
      }),

      this.actions$.pipe(
        ofType(updateFBFormRoleAssignmentSuccess),
        tap(action => {
          this.router.navigate(['/form-builder/forms-assignment']);
        })
      ).subscribe(),
    );
  }

  save() {
    if (!this.formRoleAssignment || !this.formRoleAssignment.id) {
      return;
    }

    if (this.assingOption === 1) {
      this.formRoleAssignment.formAssignNotInRoles = [];
    }
    if (this.assingOption === 2) {
      this.formRoleAssignment.formAssignRoles = [];
    }
    if (this.requiredOption === 1) {
      this.formRoleAssignment.formRequiredNotInRoles = [];
    }
    if (this.requiredOption === 2) {
      this.formRoleAssignment.formRequiredRoles = [];
    }
    this.store.dispatch(updateFBFormRoleAssignment({ formRoleAssignmentModel: deepClone(this.formRoleAssignment) }));
  }

  changedForm(event) {

    this.assingOption = null;
    this.requiredOption = null;
    this.store.dispatch(getFBFormRoleAssignment({ formID: this.formRoleAssignment.id }));
  }
}
