import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../../core/abstracts/baseComponent';
import { EmployeeReviewTemplateKpiCategoryModel } from '../../../../../models/employeeReviewTemplateModels';
import { State } from '../../../../../core/store';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { removeKpi, removeKpiCategory, removeMeasureable } from '../../../../../core/store/employee-review-template/employee-review-template.actions';

@Component({
  selector: 'app-kpi-category-template-component',
  templateUrl: './kpi-category-template-component.component.html'
})
export class KpiCategoryTemplateComponentComponent extends BaseComponent implements OnInit {
  @Input() kpiCategory: EmployeeReviewTemplateKpiCategoryModel;

  loading: boolean;
  constructor(
    private actions$: Actions,
    private store: Store<State>
  ) {
    super();
  }

  ngOnInit() {
    if (this.kpiCategory.measureables == null || this.kpiCategory.measureables.length == 0)
      this.addNewMeasureable();
    this.subs.push(
      this.store.select(s => s.employeeReviewTemplateState.loading).subscribe(loading => {
        this.loading = loading;
      }),
      this.actions$.pipe(
        ofType(removeMeasureable),
        tap(action => {
          if (this.kpiCategory.measureables.some(s => s == action.measureable)) {
            var index = this.kpiCategory.measureables.indexOf(action.measureable);
            this.kpiCategory.measureables.splice(index, 1);
          }
        })
      ).subscribe()
    );
  }


  addNewMeasureable() {
    if (this.kpiCategory.measureables == null)
      this.kpiCategory.measureables = [];

    this.kpiCategory.measureables.push({
      measureableId: null,
      childrenMeasureables: [],
      measureable: '',
      isChild: false
    });
  }

  remove() {
    this.store.dispatch(removeKpiCategory({ kpiCategory: this.kpiCategory }));
  }

}
