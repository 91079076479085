import { JobApplicationsModel, JobApplicationsWithFileModel } from '../../../models/jobApplicationsModel';
import { PagingResultsModel } from './../../../models/pagingResultsModel';
import { createAction, props } from '@ngrx/store';
import { FormError } from './../../../models/utilModels';
import { CampusModel } from 'src/app/models/campusModel';
import { JobTitleHasJobApplicationFormModel, ScreeningInterviewModel } from 'src/app/models/screeningInterviewModel';

export const updateJobApplicationsForm = createAction('[Application]  Update Application Form',
  props<{ jobApplication: any; formErrors: FormError[] }>());

export const clearJobApplication = createAction('[Application] Clear');
export const setPhoneInterviewNull = createAction('[Application] Clear Phone Interview');

export const loadCampuses = createAction('[Application] Load Campuses');
export const loadCampusesSuccess = createAction('[Application] Load Campuses Success', props<{ campuses: CampusModel[] }>());

export const logNavigation = createAction('[Application] Log Navigation');
export const logNavigationSuccess = createAction('[Application] Log Navigation Success');

export const saveJobApplication = createAction('[Application] Save application',
  props<{ jobApplication: JobApplicationsWithFileModel, resume: File, coverLetter?: File }>());
export const saveJobApplicationSuccess = createAction('[Application] Save application Success',
  props<{ jobApplication: JobApplicationsWithFileModel }>());
export const saveJobApplicationFail = createAction('[Application] Save application Fail',
  props<{ err: any }>());

export const archiveJobApplication = createAction('[Application] Archive application',
  props<{ jobAppID: number, archiveReason: string }>());
export const archiveJobApplicationSuccess = createAction('[Application] Archive application Success',
  props<{ jobApplication: JobApplicationsModel }>());
export const archiveJobApplicationFail = createAction('[Application] Archive application Fail',
  props<{ err: any }>());


export const savePhoneInterview = createAction('[Application] Save Phone Interview',
  props<{ interview: ScreeningInterviewModel }>());
export const savePhoneInterviewSuccess = createAction('[Application] Save Phone Interview Success',
  props<{ interview: ScreeningInterviewModel }>());
export const savePhoneInterviewFail = createAction('[Application] Save Phone Interview Fail',
  props<{ err: any }>());

export const getResume = createAction('[Application] Get Resume',
  props<{ applicationID: number }>());
export const getResumeSuccess = createAction('[Application] Get Resume Success',
  props<{ url: string }>());

export const getCoverLetter = createAction('[Application] Get Cover Letter',
  props<{ applicationID: number }>());
export const getCoverLetterSuccess = createAction('[Application] Get Cover Letter Success',
  props<{ url: string }>());

export const saveAsProspectiveCandidate = createAction('[Application] Save Candidate',
  props<{ app: JobApplicationsModel }>());
export const saveAsProspectiveCandidateSuccess = createAction('[Application] Save Candidate Success',
  props<{ jobApplication: JobApplicationsModel }>());
export const saveAsProspectiveCandidateFail = createAction('[Application] Save Candidate Fail',
  props<{ err: any }>());

export const getJobApplications = createAction('[Application] Get  list',
  props<{ filter: any }>());
export const getJobApplicationsSuccess = createAction('[Application] Get  list Success',
  props<{ jobApplications: PagingResultsModel<JobApplicationsModel> }>());
export const getJobApplicationsFail = createAction('[Application] Get  list Fail',
  props<{ err: any }>());



export const loadJobApplicationForms = createAction('[Application] Load JobApplicationForms');
export const loadJobApplicationFormsSuccess = createAction('[Application] Load JobApplicationForms Success',
  props<{ jobTitleForms: JobTitleHasJobApplicationFormModel[] }>());
export const loadJobApplicationFormsFail = createAction('[Application] Load JobApplicationForms Fail',
  props<{ err: any }>());
