<form class="card mt-2">
  <div class="card-body">
    <div class="float-right">
      <button type="button" routerLink="/certification/report" class="btn btn-secondary mr-2">Certifications</button>
      <button *ngIf="canManage" type="button" routerLink="/certification/instructors" class="btn btn-secondary">Instructors</button>
    </div>
    <h2 class="mb-3">CEU Events Report</h2>
    <div class="mb-3">

      <div class="row mb-2">
        <div class="col-2 d-flex align-items-center">
          <h5>Event Name:</h5>
        </div>
        <div class="col-10 ">
          <input type="text" [(ngModel)]="eventNameFilter" class="form-control" (ngModelChange)="reloadList()" [ngModelOptions]="{standalone: true}">
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-2 d-flex align-items-center">
          <h5>Modality:</h5>
        </div>
        <div class="col-10 ">
          <input type="text" [(ngModel)]="modalityFilter" class="form-control" (ngModelChange)="reloadList()" [ngModelOptions]="{standalone: true}">
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Instructor:</h5>
        </div>
        <div class="col-10">
          <div class="w-100 mb-2">
            <p-autoComplete [style]="{'width': '100%'}" [inputStyle]="{'width': '100%'}" [(ngModel)]="instructorPrefixText"
                            [suggestions]="instructorList" (completeMethod)="getInstructorList($event)" field="label"
                            [ngModelOptions]="{standalone: true}" [minLength]="1" [autoHighlight]="true"
                            (onSelect)="reloadListAfterInstructorChange($event)" [forceSelection]="true"
                            (onClear)="reloadListAfterInstructorClear($event)"></p-autoComplete>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Certification Types:</h5>
        </div>
        <div class="col-10">
          <i *ngIf="!certificationTypes" class="material-icons rotate text-muted">loop</i>
          <p-dropdown styleClass="form-control w-100 mb-2" [options]="certificationTypes" [(ngModel)]="certificationTypeId" (ngModelChange)="reloadList()" [ngModelOptions]="{standalone: true}"></p-dropdown>
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>CEU Categories:</h5>
        </div>
        <div class="col-10">
          <i *ngIf="!ceuCategories" class="material-icons rotate text-muted">loop</i>
          <p-dropdown styleClass="form-control w-100 mb-2" [options]="ceuCategories" [(ngModel)]="ceuCategoryId" (ngModelChange)="reloadList()" [ngModelOptions]="{standalone: true}"></p-dropdown>
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Event Date:</h5>
        </div>
        <div class="col-10">
          <i *ngIf="!eventDateRangeList" class="material-icons rotate text-muted">loop</i>
          <p-dropdown styleClass="form-control w-100 mb-2" [options]="eventDateRangeList" [(ngModel)]="eventDateRange" (ngModelChange)="changedEventDateRange()" [ngModelOptions]="{standalone: true}"></p-dropdown>
        </div>
      </div>

      <div class="row" *ngIf="isCustomEventDateRange">
        <div class="col-2 d-flex align-items-center">
          <h5 style="padding-left:10px;">Start Date:</h5>
        </div>
        <div class="col-10">
          <input type="date" class="form-control ml-2 w-100 mb-2" [(ngModel)]="eventStartDate" (blur)="reloadFilter(this.filter)" [ngModelOptions]="{standalone: true}" />
        </div>
        <div class="col-2 d-flex align-items-center">
          <h5 style="padding-left:10px;">End Date:</h5>
        </div>
        <div class="col-10">
          <input type="date" class="form-control ml-2 w-100 mb-2" [(ngModel)]="eventEndDate" (blur)="reloadFilter(this.filter)" [ngModelOptions]="{standalone: true}" />
        </div>
      </div>

    </div>

    <i [hidden]="!loadingList && !loadingExcel && !loadingDownload && !savingCeu" class="material-icons rotate text-muted">loop</i>
    <button *ngIf="canManage" (click)="editEvent([])" class="btn btn-secondary mr-2 mb-2">New Event</button>
    <button type="button" title="Export all pages to excel" class="btn btn-success mr-2 mb-2" (click)="exportToExcel()"><i class="far fa-file-excel"></i></button>
    <p-table [value]="list?.result" [responsive]="true" [paginator]="true" [rows]="20"
             [totalRecords]="list?.totalRecords" [lazy]="true" (onLazyLoad)="reloadFilter($event)">
      <ng-template pTemplate="header">
        <tr>
          <th width="175px"></th>
          <th [pSortableColumn]="'EventName'">Event Name <p-sortIcon [field]="'EventName'"></p-sortIcon></th>
          <th [pSortableColumn]="'Date'">Date(s) <p-sortIcon [field]="'PrimaryProgramName'"></p-sortIcon></th>
          <th [pSortableColumn]="'Modality'">Modality<p-sortIcon [field]="'Modality'"></p-sortIcon></th>
          <th [pSortableColumn]="'InstructorName'">Instructor<p-sortIcon [field]="'InstructorName'"></p-sortIcon></th>
          <th>Certification Types</th>
          <th>Uploads</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-list>
        <tr>
          <td>
            <button class="btn btn-secondary mr-2" *ngIf="canManage" (click)="editEvent(list)" title="Edit Event">
              <i class="fas fa-pencil"></i>
            </button>
            <button class="btn btn-secondary mr-2" *ngIf="canManage" (click)="deleteEvent(list)" title="Delete Event">
              <i class="fas fa-trash-alt"></i>
            </button>
            <button class="btn btn-secondary mr-2" *ngIf="canManage" (click)="generateMissingCertifications(list)" title="Generating Missing Certifications">
              <i class="fas fa-file-plus"></i>
            </button>
          </td>
          <td>
            <span class="ui-column-title">Event Name</span>
            {{list.eventName}} {{list.lastName}}
          </td>
          <td>
            <span class="ui-column-title">Date</span>
            {{list.startDate | date: 'MM/dd/yyyy'}} {{(list.startDate !== list.endDate) ? (" - " + (list.endDate | date: 'MM/dd/yyyy')) : ""}}
          </td>
          <td>
            <span class="ui-column-title">Modality</span>
            {{list.modality}}
          </td>
          <td>
            <span class="ui-column-title">Instructor</span>
            {{list.instructorFirstName}} {{list.instructorLastName}}
          </td>
          <td>
            <span class="ui-column-title">Certification Types</span>
            <div *ngFor="let t of list.certificationTypes">
              {{t.certificationType}}
              <ul>
                <li *ngFor="let c of t.ceUs" style="font-size: 0.8rem;">
                  {{c.units}} CEU(s) of {{(c.type != "" ? c.type : c.category)}}
                </li>
              </ul>
            </div>
          </td>
          <td>
            <span class="ui-column-title">Uploads</span>
            <div *ngFor="let u of list.uploads" class="btn btn-link pointer" (click)="downloadUploadedFile(u)" title="Download">
              {{u.fileName}}
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</form>

<app-edit-ceu-event></app-edit-ceu-event>
