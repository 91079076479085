import { getBenefitsFormDocumentsList } from 'src/app/core/store/benefits/benefits.actions';
import { loadBenefitsFormDocument } from './../../../../core/store/benefits/benefits.actions';
import { downloadFile } from 'src/app/helpers/utils';
import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { AlertService } from 'src/app/shared/services/alert.service';
import { OnBoardingDocumentUserModel } from 'src/app/models/onBoardingDocumentUserModel';
import { Router, ActivatedRoute } from '@angular/router';
import { getOnboardingDocsList, loadOnboardingDoc } from 'src/app/core/store/onboardingdocs/onboardingdocs.actions';
import { BenefitPlanFormDocumentsEnrollmentModel } from 'src/app/models/benefitsModels';

@Component({
  selector: 'app-list-my-enrollmentformdocs',
  templateUrl: './list-my-enrollmentformdocs.component.html',
  styleUrls: ['./list-my-enrollmentformdocs.component.scss']
})
export class ListMyEnrollmentFormDocsComponent extends BaseComponent implements OnInit {

  enrollmentFormDocs: BenefitPlanFormDocumentsEnrollmentModel[];
  loadingEnrollmentFormDocsList = false;
  hasNotSignedDoc = false;
  @Input() managedUserId;
  hasAnyDoc;
  notSignedDocs;

  constructor(
    private actions$: Actions,
    private alertService: AlertService,
    private store: Store<State>,
    private router: Router,
    private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    if (this.route.snapshot.params.userId) {
      this.managedUserId = this.route.snapshot.params.userId;
    }
    this.subs.push(
      this.store.select(s => s.benefitsState.docsListBenefit).subscribe(enrollmentFormDocs => {
        this.enrollmentFormDocs = enrollmentFormDocs;
        if (enrollmentFormDocs) {
          this.hasNotSignedDoc = enrollmentFormDocs.some(doc => !doc.signedDate);
        }
      }),

      this.store.select(s => s.benefitsState).subscribe(benefitsState => {
        this.loadingEnrollmentFormDocsList = benefitsState.loadingListBenefit || benefitsState.loadingDocBenefit;
      }),

      this.store.select(s => s.benefitsState.docsListBenefit).subscribe(enrollmentFormDocs => {
        if (enrollmentFormDocs) {

          this.hasAnyDoc = enrollmentFormDocs.length > 0;
          this.notSignedDocs = enrollmentFormDocs.filter(doc => !doc.signedDate).length;
        }
      }),

    );

    this.store.dispatch(getBenefitsFormDocumentsList({ userId: this.managedUserId }));
  }

  sign(enrollmentFormDoc: BenefitPlanFormDocumentsEnrollmentModel) {
    this.router.navigate(['../sign/' + enrollmentFormDoc.id], { relativeTo: this.route });
  }

  signAsHR(enrollmentFormDoc: BenefitPlanFormDocumentsEnrollmentModel) {
    this.router.navigate(['../sign/' + enrollmentFormDoc.id], { relativeTo: this.route });
  }

  // fillInfo(onboardingDocsUser: BenefitPlanFormDocumentsEnrollmentModel) {
  //   this.router.navigate(['../fill-info/' + onboardingDocsUser.id], { relativeTo: this.route });
  // }

  // fillInfoHR(onboardingDocsUser: BenefitPlanFormDocumentsEnrollmentModel) {
  //   this.router.navigate(['../fill-info-hr/' + onboardingDocsUser.id], { relativeTo: this.route });
  // }

  viewDoc(enrollmentFormDoc: BenefitPlanFormDocumentsEnrollmentModel) {
    this.store.dispatch(loadBenefitsFormDocument({ benefitPlanFormDocumentsEnrollmentID: enrollmentFormDoc.id }));
    const subDocBuffer = this.store.select(s => s.benefitsState.docArrayBufferBenefit).subscribe(docArrayBuffer => {
      if (docArrayBuffer) {
        downloadFile(new Blob([docArrayBuffer], { type: 'application/pdf' }), enrollmentFormDoc.benefitPlanFormDocument.fileName);
        subDocBuffer.unsubscribe();
      }
    });
  }

  openEnrollment() {
      this.router.navigate(['benefits-enrollment/enrollment']);
  }


}

