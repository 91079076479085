import { deleteBenefitsDependent, deleteBenefitsDependentSuccess, loadAvailableBenefitPlans, loadBenfitPlanTypes, saveBenefitsDependent, saveBenefitsDependentSuccess } from './../../../../core/store/benefits/benefits.actions';
import { BaseComponent } from './../../../../core/abstracts/baseComponent';
import { BenefitsState, initBenefitsDependentForm } from './../../../../core/store/benefits/benefits.reducer';
import { BenefitPlanModel, BenefitsDependentModel, BenefitPlanTypeModel, BenefitPlanFullModel, BenefitPlanCostModel } from './../../../../models/benefitsModels';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { Actions, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { MultiSelect } from 'primeng/multiselect';
import { AlertService } from '../../../../shared/services/alert.service';
import { deepClone, getAllControlsErrors, markFormGroupTouched, roundUp, updateFormGroupValidity } from '../../../../helpers/utils';
import { loadBenefitsDependents, loadBenefitsPlans, updateBenefitsDependentForm } from '../../../../core/store/benefits/benefits.actions';
import { FormGroup } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { tap, filter } from 'rxjs/operators';

@Component({
  selector: 'app-benefit-plan-detail',
  templateUrl: './benefit-plan-detail.component.html',
  styleUrls: ['./benefit-plan-detail.component.scss']
})
export class BenefiPlanDetailComponent extends BaseComponent implements OnInit {

  @Input() benefitPlan: BenefitPlanFullModel;

  constructor(
    private actions$: Actions,
    private alertService: AlertService,
    private store: Store<State>,
    private router: Router,
  ) {
    super();
  }

  ngOnInit() {
    this.subs.push(

    );

  }

  formatLink(url: string) {
    if (!(url.toLowerCase().startsWith('http://') || url.toLowerCase().startsWith('https://'))) {
      return 'http://' + url;
    }
    return url;

  }


  getMonthlyCost(cost: BenefitPlanCostModel) {

    if (!cost.costByAge) {
      if (cost.monthlyCost)
        return '$' + cost.monthlyCost.toFixed(2);
      return '-';
    }

    var vals = [];

    var lastAge = 17;
    if (cost.monthlyCost25) { vals.push((++lastAge) + '-25: $' + (cost.monthlyCost25).toFixed(2)); lastAge = 25; };
    if (cost.monthlyCost30) { vals.push((++lastAge) + '-30: $' + (cost.monthlyCost30).toFixed(2)); lastAge = 30; };
    if (cost.monthlyCost35) { vals.push((++lastAge) + '-35: $' + (cost.monthlyCost35).toFixed(2)); lastAge = 35; };
    if (cost.monthlyCost40) { vals.push((++lastAge) + '-40: $' + (cost.monthlyCost40).toFixed(2)); lastAge = 40; };
    if (cost.monthlyCost45) { vals.push((++lastAge) + '-45: $' + (cost.monthlyCost45).toFixed(2)); lastAge = 45; };
    if (cost.monthlyCost50) { vals.push((++lastAge) + '-50: $' + (cost.monthlyCost50).toFixed(2)); lastAge = 50; };
    if (cost.monthlyCost55) { vals.push((++lastAge) + '-55: $' + (cost.monthlyCost55).toFixed(2)); lastAge = 55; };
    if (cost.monthlyCost60) { vals.push((++lastAge) + '-60: $' + (cost.monthlyCost60).toFixed(2)); lastAge = 60; };
    if (cost.monthlyCost65) { vals.push((++lastAge) + '-65: $' + (cost.monthlyCost65).toFixed(2)); lastAge = 65; };
    if (cost.monthlyCost70) { vals.push((++lastAge) + '-70: $' + (cost.monthlyCost70).toFixed(2)); lastAge = 70; };
    if (cost.monthlyCost75) { vals.push((++lastAge) + '-75: $' + (cost.monthlyCost75).toFixed(2)); lastAge = 75; };
    if (cost.monthlyCost80) { vals.push((++lastAge) + '-80: $' + (cost.monthlyCost80).toFixed(2)); lastAge = 80; };
    if (cost.monthlyCost) { vals.push((++lastAge) + '+: $' + (cost.monthlyCost).toFixed(2)); };
    if (vals.length == 0) return '-';
    return vals.join('<br/>');

  }

  convertMonthlyCostToCostPerPayPeriod(monthlyCost: number): number {
    return roundUp(monthlyCost * 12 / 26);
  }

  getCostPerPayPeriod(cost: BenefitPlanCostModel) {
    

    if (!cost.costByAge) {
      if (cost.monthlyCost)
        return '$' + this.convertMonthlyCostToCostPerPayPeriod(cost.monthlyCost).toFixed(2);
      return '-';
    }

    var vals = [];

    var lastAge = 17;
    if (cost.monthlyCost25) { vals.push((++lastAge) + '-25: $' + this.convertMonthlyCostToCostPerPayPeriod(cost.monthlyCost25).toFixed(2)); lastAge = 25; };
    if (cost.monthlyCost30) { vals.push((++lastAge) + '-30: $' + this.convertMonthlyCostToCostPerPayPeriod(cost.monthlyCost30).toFixed(2)); lastAge = 30; };
    if (cost.monthlyCost35) { vals.push((++lastAge) + '-35: $' + this.convertMonthlyCostToCostPerPayPeriod(cost.monthlyCost35).toFixed(2)); lastAge = 35; };
    if (cost.monthlyCost40) { vals.push((++lastAge) + '-40: $' + this.convertMonthlyCostToCostPerPayPeriod(cost.monthlyCost40).toFixed(2)); lastAge = 40; };
    if (cost.monthlyCost45) { vals.push((++lastAge) + '-45: $' + this.convertMonthlyCostToCostPerPayPeriod(cost.monthlyCost45).toFixed(2)); lastAge = 45; };
    if (cost.monthlyCost50) { vals.push((++lastAge) + '-50: $' + this.convertMonthlyCostToCostPerPayPeriod(cost.monthlyCost50).toFixed(2)); lastAge = 50; };
    if (cost.monthlyCost55) { vals.push((++lastAge) + '-55: $' + this.convertMonthlyCostToCostPerPayPeriod(cost.monthlyCost55).toFixed(2)); lastAge = 55; };
    if (cost.monthlyCost60) { vals.push((++lastAge) + '-60: $' + this.convertMonthlyCostToCostPerPayPeriod(cost.monthlyCost60).toFixed(2)); lastAge = 60; };
    if (cost.monthlyCost65) { vals.push((++lastAge) + '-65: $' + this.convertMonthlyCostToCostPerPayPeriod(cost.monthlyCost65).toFixed(2)); lastAge = 65; };
    if (cost.monthlyCost70) { vals.push((++lastAge) + '-70: $' + this.convertMonthlyCostToCostPerPayPeriod(cost.monthlyCost70).toFixed(2)); lastAge = 70; };
    if (cost.monthlyCost75) { vals.push((++lastAge) + '-75: $' + this.convertMonthlyCostToCostPerPayPeriod(cost.monthlyCost75).toFixed(2)); lastAge = 75; };
    if (cost.monthlyCost80) { vals.push((++lastAge) + '-80: $' + this.convertMonthlyCostToCostPerPayPeriod(cost.monthlyCost80).toFixed(2)); lastAge = 80; };
    if (cost.monthlyCost) { vals.push((++lastAge) + '+: $' + this.convertMonthlyCostToCostPerPayPeriod(cost.monthlyCost).toFixed(2)); };
    if (vals.length == 0) return '-';
    return vals.join('<br/>');
  }

}


