<form class="card mt-2" [formGroup]="form" *ngIf="canAddCoach">
  <div class="card-body">
    <h4 class="mb-3">{{improvementPlan.id?'Edit':'Create'}} Improvement Plan</h4>
    <div class="row">
      <div class="col-12 col-lg-8 ">
        <div class="row" formGroupName="improvementPlan">
          <div class="col-4 d-flex align-items-center">
            <b>User</b>
          </div>
          <div class="col-8">
            <p-dropdown styleClass="form-control mt-2" [disabled]="previousCoachingLog" [options]="usersList" formControlName="userID" placeholder="Select user..."></p-dropdown>
            <app-validation-message [form]="form" [path]="'improvementPlan.userID'" [name]="'userID'" [label]="'User'"> </app-validation-message>
          </div>
        </div>

        <div class="row" formGroupName="improvementPlan">
          <div class="col-4 d-flex align-items-center">
            <b>Supervisor</b>
          </div>
          <div class="col-8">
            <p-dropdown styleClass="form-control mt-2" [disabled]="previousCoachingLog" [options]="allUsersList" formControlName="supervisorUserID" placeholder="Select user..."></p-dropdown>
            <app-validation-message [form]="form" [path]="'improvementPlan.supervisorUserID'" [name]="'supervisorUserID'" [label]="'Supervisor'"> </app-validation-message>
          </div>
        </div>
        <div class="row" formGroupName="improvementPlan">
          <div class="col-4 d-flex align-items-center">
            <b>Note Date</b>
          </div>
          <div class="col-8">
            <input type="date" class="form-control mt-2" formControlName="noteDate" />
            <app-validation-message [form]="form" [path]="'improvementPlan.noteDate'" [name]="'noteDate'" [label]="'Note Date'"> </app-validation-message>
          </div>
        </div>
        <div class="row" formGroupName="improvementPlan">
          <div class="col-4 d-flex align-items-center">
            <b>Next Review Date</b>
          </div>
          <div class="col-8">
            <input type="date" class="form-control mt-2" formControlName="nextReviewDate" />
            <app-validation-message [form]="form" [path]="'improvementPlan.nextReviewDate'" [name]="'nextReviewDate'" [label]="'Next Review Date'"> </app-validation-message>
          </div>
        </div>
        <div *ngIf="categoriesList != null && categoriesList.length > 0" class="row" formGroupName="improvementPlan">
          <div class="col-4 d-flex align-items-center">
            <b>Categories of the Issue</b>
          </div>
          <div class="col-8 mt-2 mb-2">

            <label formArrayName="categories" class="col" *ngFor="let category of categoriesFormArray.controls; let i=index">
              <input [formControlName]="i" class="" type="checkbox" [value]="categoriesList[i].categoryName">
              {{categoriesList[i].categoryName}}
            </label>

            <div class="row" *ngIf="hasOtherCategory">
              <div class="col-4 d-flex align-items-center">
                <label>Other Category</label>
              </div>
              <div class="col-8">
                <input type="text" maxlength="200" class="form-control mt-2" formControlName="otherCategory" />
              </div>
            </div>
            <app-validation-message [form]="form" [path]="'improvementPlan.categories'" [name]="'categories'" [label]="'Categories'"> </app-validation-message>
          </div>
        </div>

        <div class="row" formGroupName="improvementPlan">
          <div class="col-4 d-flex align-items-center">
            <b>Benchmark</b>
          </div>
          <div class="col-8">
            <textarea class="form-control mt-2" formControlName="benchmark"></textarea>
            <app-validation-message [form]="form" [path]="'improvementPlan.benchmark'" [name]="'benchmark'" [label]="'Benchmark'"> </app-validation-message>
          </div>
        </div>

        <div class="row" formGroupName="improvementPlan">
          <div class="col-4 d-flex align-items-center">
            <b>Performance Concern</b>
          </div>
          <div class="col-8">
            <textarea class="form-control mt-2" formControlName="performanceConcern"></textarea>
            <app-validation-message [form]="form" [path]="'improvementPlan.performanceConcern'" [name]="'performanceConcern'" [label]="'Performance Concern'"> </app-validation-message>
          </div>
        </div>

        <div class="row" formGroupName="improvementPlan">
          <div class="col-4 d-flex align-items-center">
            <b>Expected Standard</b>
          </div>
          <div class="col-8">
            <textarea class="form-control mt-2" formControlName="expectedStandard"></textarea>
            <app-validation-message [form]="form" [path]="'improvementPlan.expectedStandard'" [name]="'expectedStandard'" [label]="'Expected Standard'"> </app-validation-message>
          </div>
        </div>
        <div class="row" formGroupName="improvementPlan">
          <div class="col-4 d-flex align-items-center">
            <b>Measured By</b>
          </div>
          <div class="col-8">
            <textarea class="form-control mt-2" formControlName="measuredBy"></textarea>
            <app-validation-message [form]="form" [path]="'improvementPlan.measuredBy'" [name]="'measuredBy'" [label]="'Measured By'"> </app-validation-message>
          </div>
        </div>
        <div class="row" formGroupName="improvementPlan">
          <div class="col-4 d-flex align-items-center">
            <b>Improvement Actions</b>
          </div>
          <div class="col-8">
            <textarea class="form-control mt-2" formControlName="improvementActions"></textarea>
            <app-validation-message [form]="form" [path]="'improvementPlan.improvementActions'" [name]="'improvementActions'" [label]="'Improvement Actions'"> </app-validation-message>
          </div>
        </div>
        <div class="row" formGroupName="improvementPlan">
          <div class="col-4 d-flex align-items-center">
            <b>Support</b>
          </div>
          <div class="col-8">
            <textarea class="form-control mt-2" formControlName="support"></textarea>
            <app-validation-message [form]="form" [path]="'improvementPlan.support'" [name]="'support'" [label]="'Support'"> </app-validation-message>
          </div>
        </div>
        <div class="row" formGroupName="improvementPlan">
          <div class="col-4 d-flex align-items-center">
            <b>Additional Info</b>
          </div>
          <div class="col-8">
            <textarea class="form-control mt-2" formControlName="additionalInfo"></textarea>
            <app-validation-message [form]="form" [path]="'improvementPlan.additionalInfo'" [name]="'additionalInfo'" [label]="'Additional Info'"> </app-validation-message>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-6">
        <i *ngIf="creatingImprovementPlan" class="material-icons rotate text-muted">loop</i>
        <button *ngIf="!creatingImprovementPlan" class="btn btn-secondary mr-3" [routerLink]="['/coachinglogs/given']">Back</button>
        <button *ngIf="!creatingImprovementPlan" class="btn btn-primary" [class.disabled]="form.invalid" (click)="save()">{{improvementPlan.id?'Save':'Create'}}</button>
      </div>
    </div>
  </div>
</form>
