<div class="card mt-3" *ngIf="loadingProspectiveCandidateReference">
  <div class="card-body">
    Loading ...
  </div>
</div>
<div class="card mt-3" *ngIf="!isById && answered">
  <div class="card-body">
    Survey Answered
  </div>
</div>
<form class="card mt-2" [formGroup]="form" *ngIf="form && prospectiveCandidateReference && (!answered || isById)">
  <div class="card-body text-center">

    <button *ngIf="isById && !isFromEmployee"  class="btn btn-secondary float-right pointer" [routerLink]="'/prospective-candidate/addit/'+prospectiveCandidateReference.prospectiveCandidateID">Back</button>
    <button *ngIf="isById && isFromEmployee"  class="btn btn-secondary float-right pointer" [routerLink]="'/prospective-candidate/employee-references/'+prospectiveCandidateReference.prospectiveCandidateID" [queryParamsHandling]="'merge'">Back</button>

    <h3 class="">Reference Survey </h3>
    <h6 class="mb-3">You're giving reference for: <b>{{prospectiveCandidateReference.candidateFirstName}} {{prospectiveCandidateReference.candidateLastName}}</b></h6>
    <h6 class="mb-3" *ngIf="isById">Reference given by: <b>{{prospectiveCandidateReference.firstName}} {{prospectiveCandidateReference.lastName}}</b></h6>

    <div class="row">
      <div class="col-12 offset-lg-3 col-lg-6">
        <div class="row mt-3">
          <div class="col-12 text-center">
            <b>Are you a former employer of the applicant in question?</b>
          </div>
          <div class="col-12 pt-2">
            <p-selectButton styleClass="w-100" [options]="yesNoOptions" formControlName="isFormerEmployer" [disabled]="isById"></p-selectButton>
            <app-validation-message [form]="form" [path]="'isFormerEmployer'" [name]="'isFormerEmployer'" [label]="'Is Former Employer'"> </app-validation-message>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-12 text-center">
            <b>Were you the employee's supervisor, or have you acted in a leadership role over this person in another setting? </b>
          </div>
          <div class="col-12 pt-2">
            <p-selectButton styleClass="w-100" [options]="yesNoOptions" formControlName="wasSupervisor" [disabled]="isById"></p-selectButton>
            <app-validation-message [form]="form" [path]="'wasSupervisor'" [name]="'wasSupervisor'" [label]="'Was Supervisor'"> </app-validation-message>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-12 text-center">
            <b>Briefly describe the job duties performed by this person, or say 'Not applicable' if you are not a former employer.</b>
          </div>
          <div class="col-12 pt-2">
            <textarea type="text" class="form-control mt-2" formControlName="dutiesPerformed" [attr.disabled]="isById?true:null"></textarea>
            <app-validation-message [form]="form" [path]="'dutiesPerformed'" [name]="'dutiesPerformed'" [label]="'Duties Performed'"> </app-validation-message>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-12 text-center">
            <b>Please rate the following for this person while they were employed by you, or to the best of your knowledge if you are not a former employer.</b>
          </div>
          <div class="col-12 pt-2">
            <p-selectButton styleClass="w-100" [options]="rateOptions" formControlName="rate" [disabled]="isById"></p-selectButton>
            <app-validation-message [form]="form" [path]="'rate'" [name]="'rate'" [label]="'Rate'"> </app-validation-message>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-12 text-center">
            <b>Would you rehire this person? Select N/A option if not former employer.</b>
          </div>
          <div class="col-12 pt-2">
            <p-selectButton styleClass="w-100" [options]="yesNoNAOptions" formControlName="wouldRehire" [disabled]="isById"></p-selectButton>
            <app-validation-message [form]="form" [path]="'wouldRehire'" [name]="'wouldRehire'" [label]="'Would Rehire'"> </app-validation-message>
          </div>
        </div>

        <div class="row mt-5" *ngIf="form.get('wouldRehire').value===false">
          <div class="col-12 text-center">
            <b>Why Not?</b>
          </div>
          <div class="col-12 pt-2">
            <textarea type="text" class="form-control mt-2" formControlName="whyNotRehire" [attr.disabled]="isById?true:null"></textarea>
            <app-validation-message [form]="form" [path]="'whyNotRehire'" [name]="'whyNotRehire'" [label]="'Why Not Rehire'"> </app-validation-message>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-12 text-center">
            <b>Please describe why you feel this person would be a good fit to work in an environment serving people with autism and other mental health-related issues. Feel free to include comments about work ethic, outstanding personality traits, and other positive attributes you deem relevant.</b>
          </div>
          <div class="col-12 pt-2">
            <textarea type="text" class="form-control mt-2" formControlName="whyShouldHire" [attr.disabled]="isById?true:null"></textarea>
            <app-validation-message [form]="form" [path]="'whyShouldHire'" [name]="'whyShouldHire'" [label]="'Why Should Hire'"> </app-validation-message>
          </div>
        </div>

      <div class="row mt-4" *ngIf="!isById">
        <div class="col-12 text-center">
          <i *ngIf="savingProspectiveCandidateReference" class="material-icons rotate text-muted">loop</i>
          <button *ngIf="!savingProspectiveCandidateReference" class="btn btn-primary" [class.disabled]="form.invalid" (click)="save()">Answer Survey</button>
        </div>
      </div>
      </div>
    </div>
  </div>
</form>
