import { FBFormModel } from './../../../models/formBuilderModels';
import { PagingResultsModel } from './../../../models/pagingResultsModel';
import { UserModel } from 'src/app/models/userModel';
import { OfferLetterModel, OfferLetterWithUserModel } from 'src/app/models/offerletterModel';
import { createAction, props } from '@ngrx/store';
import { FormError } from 'src/app/models/utilModels';

export const loadOfferLetterDoc = createAction('[Offer Letter] Load Offer Letter Doc', props<{ offerLetterID: number }>());
export const loadOfferLetterDocSuccess = createAction('[Offer Letter] Load Offer Letter Doc Success', props<{ doc: ArrayBuffer }>());

export const signOfferLetterDoc = createAction('[Offer Letter] Sign Offer Letter Doc',
  props<{ offerLetterID: number, signedFields: string[] }>());
export const signOfferLetterDocSuccess = createAction('[Offer Letter]  Sign Offer Letter Doc Success', props<{ docSigned: ArrayBuffer }>());
export const signOfferLetterDocFail = createAction('[Offer Letter]  Sign Offer Letter Fail',
  props<{ err: any }>());


export const updateCreateOfferletterForm = createAction('[Offer Letter]  Update Offer Letter Form',
  props<{ offerValues: any; userValues: any, formErrors: FormError[] }>());

export const createOfferLetter = createAction('[Offer Letter]  Create Offer Letter',
  props<{ offerLetterWithUser: OfferLetterWithUserModel }>());
export const createOfferLetterSuccess = createAction('[Offer Letter]  Create Offer Letter Success',
  props<{ doc: ArrayBuffer }>());
export const createOfferLetterFail = createAction('[Offer Letter]  Create Offer Letter Fail',
  props<{ err: any }>());


export const saveOfferLetter = createAction('[Offer Letter]  Save Offer Letter',
  props<{ offerLetterWithUser: OfferLetterWithUserModel }>());
export const saveOfferLetterSuccess = createAction('[Offer Letter]  Save Offer Letter Success',
  props<{ offerletterWithUser: OfferLetterWithUserModel }>());
export const saveOfferLetterFail = createAction('[Offer Letter]  Save Offer Letter Fail',
  props<{ err: any }>());



export const previewOfferLetter = createAction('[Offer Letter]  Preview Offer Letter',
  props<{ offerLetterWithUser: OfferLetterWithUserModel }>());
export const previewOfferLetterSuccess = createAction('[Offer Letter]  Preview Offer Letter Success',
  props<{ doc: ArrayBuffer }>());
export const previewOfferLetterFail = createAction('[Offer Letter]  Preview Offer Letter Fail',
  props<{ err: any }>());


export const getOfferLettersList = createAction('[Offer Letter] Get  list',
  props<{ filter: any }>());
export const getOfferLettersListSuccess = createAction('[Offer Letter] Get  list Success',
  props<{ offerLetterList: PagingResultsModel<OfferLetterWithUserModel> }>());

export const getOfferLetterPdf = createAction('[Offer Letter] Get Offer Letter Pdf',
  props<{ offerLetterID: number }>());
export const getOfferLetterPdfSuccess = createAction('[Offer Letter]  Get Offer Letter Pdf Success',
  props<{ doc: ArrayBuffer }>());

export const deleteOfferLetter = createAction('[Offer Letter] Delete Offer Letter',
  props<{ offerLetterID: number }>());
export const deleteOfferLetterSuccess = createAction('[Offer Letter]  Delete Offer Letter Success');


export const getOfferLettersInfo = createAction('[Offer Letter] Get  info',
  props<{ offerLetterID: number }>());
export const getOfferLettersInfoSuccess = createAction('[Offer Letter] Get info Success',
  props<{ offerLetter: OfferLetterWithUserModel }>());


export const clearOfferletter = createAction('[Offer Letter]  Clear Offer Letter ',
  props<{ prospectiveCandidateID: number }>());

export const checkUsername = createAction('[Offer Letter]  Check Username ',
  props<{ username: string }>());
export const checkUsernameSuccess = createAction('[Offer Letter]  Check Username Success ',
  props<{ usernameTaken: boolean }>());

export const markOfferLetterSeen = createAction('[Offer Letter] Mark Offer Letter seen',
  props<{ offerLetterID: number }>());
export const markOfferLetterSeenSuccess = createAction('[Offer Letter]  Mark Offer Letter Seen Success',
  props<{ offerLetter: OfferLetterWithUserModel }>());


export const getOldOfferLetterPdf = createAction('[Offer Letter] Get Old Offer Letter Pdf',
  props<{ oldOfferLetterID: number }>());
export const getOldOfferLetterPdfSuccess = createAction('[Offer Letter]  Get Old  Offer Letter Pdf Success',
  props<{ doc: ArrayBuffer }>());


export const getOfferletterAvailableForms = createAction('[Offer Letter] Get Offer Forms');
export const getOfferletterAvailableFormsSuccess = createAction('[Offer Letter]  Get Offer Forms Success',
  props<{ availableForms: FBFormModel[] }>());

