<form class="card mt-2">
  <div class="card-body">
      <h2 class="mb-3">My Equipment</h2>
    <i *ngIf="loadingMyEquipments" class="material-icons rotate text-muted">loop</i>
    <p-table  [value]="equipments" [responsive]="true" [paginator]="true" [rows]="20" [totalRecords]="equipments.length" [lazy]="false"
   >
        <ng-template pTemplate="header">
            <tr>
                <th width="150px"></th>
                <th width="8%" [pSortableColumn]="'type'">Type <p-sortIcon [field]="'type'"></p-sortIcon></th>
                <th [pSortableColumn]="'dateAssigned'">Assigned Date <p-sortIcon [field]="'dateAssigned'"></p-sortIcon></th>
                <th [pSortableColumn]="'returnDate'">Return Date <p-sortIcon [field]="'returnDate'"></p-sortIcon></th>
                <th [pSortableColumn]="'assignSigned'">Assign Signed <p-sortIcon [field]="'assignSigned'"></p-sortIcon></th>
                <th [pSortableColumn]="'dateReturned'">Returned Date <p-sortIcon [field]="'dateReturned'"></p-sortIcon></th>
                <th [pSortableColumn]="'serialNumber'">Serial <p-sortIcon [field]="'serialNumber'"></p-sortIcon></th>
                <th [pSortableColumn]="'brand-model'">Brand - Model <p-sortIcon [field]="'brand-model'"></p-sortIcon></th>
                <th [pSortableColumn]="'assetTag'">{{generalSettings?.businessName}} # <p-sortIcon [field]="'assetTag'"></p-sortIcon></th>
                <th [pSortableColumn]="'serviceTag'">Service Tag <p-sortIcon [field]="'serviceTag'"></p-sortIcon></th>
                <th [pSortableColumn]="'expressServiceCode'">Express Service Code <p-sortIcon [field]="'expressServiceCode'"></p-sortIcon></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-equipment>
            <tr >
                <td>
                  <button class="btn btn-secondary mr-2"  (click)="viewAssignedDocument(equipment)" title="View Assign Document">
                      <i class="fas fa-file-download"></i></button>
                  <button class="btn btn-secondary mr-2" *ngIf="!!equipment.dateReturned" (click)="viewReturnedDocument(equipment)" title="Return Document">
                      <i class="fas fa-file-upload"></i></button>
                  <button class="btn btn-secondary mr-2" *ngIf="!equipment.assignSigned" (click)="signAssignement(equipment)"  title="Sign">
                      <i class="fas fa-file-signature"></i></button>
                  <td>
                    <span class="ui-column-title">Type</span>
                    {{equipment.equipment.type}}</td>

                <td>
                  <span class="ui-column-title">Assigned Date</span>
                  {{equipment.dateAssigned | date:'shortDate' }}</td>
                <td>
                    <span class="ui-column-title">Return Date</span>
                    {{equipment.returnDate | date:'shortDate' }}</td>

                  <td>
                      <span class="ui-column-title">Assign Signed</span>
                    {{equipment.assignSigned }}</td>
                  <td>
                    <span class="ui-column-title">Returned Date</span>
                    {{equipment.dateReturned? (equipment.dateReturned | date:'shortDate'):'-' }}</td>
                  <td>
                    <span class="ui-column-title">Serial</span>
                  {{equipment.equipment.serialNumber }}</td>

                  <td>
                    <span class="ui-column-title">Brand - Model</span>
                  {{equipment.equipment.brand }} - {{equipment.equipment.model }}</td>

                  <td>
                    <span class="ui-column-title">{{generalSettings?.businessName}} #</span>
                  {{equipment.equipment.assetTag }}</td>
                  <td>
                    <span class="ui-column-title">Service Tag</span>
                  {{equipment.equipment.serviceTag }}</td>
                  <td>
                    <span class="ui-column-title">Express Service Code</span>
                  {{equipment.equipment.expressServiceCode }}</td>
            </tr>
        </ng-template>
    </p-table>
  </div>
</form>
