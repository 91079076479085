<p-dialog [(visible)]="display" header="{{title}}" [modal]="true" [style]="{width: '70vw'}" (onHide)="closeModal()" [focusOnShow]="false">
  <form class="card mt-2" [formGroup]="form" (ngSubmit)="savePtoEarned()">
    <div class="card-body">
      <div class="row">
        <div class="col-12">

          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Employee</b>
            </div>
            <div class="col-8">
              <p-autoComplete *ngIf="disableName === false" [style]="{'width': '100%'}" [inputStyle]="{'width': '100%'}" [(ngModel)]="prefixText"
                            [suggestions]="userList" (completeMethod)="getStaffList($event)" field="label"
                            [ngModelOptions]="{standalone: true}" [minLength]="1" [autoHighlight]="true"
                            (onSelect)="selectStaff($event)" [forceSelection]="true"
                            (onClear)="clearStaff($event)"></p-autoComplete>
              <input *ngIf="disableName" type="text" disabled="disabled" class="form-control mt-2" [(ngModel)]="prefixText" [ngModelOptions]="{standalone: true}" />
            </div>
          </div>

          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Date Earned</b>
            </div>
            <div class="col-8">
              <input type="date" class="form-control mt-2" formControlName="dateEarnedString" />
              <app-validation-message [form]="form" [path]="'dateEarnedString'" [label]="'Date Earned'"></app-validation-message>
            </div>            
          </div>

          <div class="row" style="min-height: 46px;">
            <div class="col-4 d-flex align-items-center">
              <b>Net PTO</b>
            </div>
            <div class="col-8 mt-2 d-flex align-items-center">
              <span style="font-style: italic;">{{totalNet}}</span>              
            </div>
          </div>

          <div class="row" style="min-height: 46px;">
            <div class="col-4 d-flex align-items-center">
              <b>YTD PTO</b>
            </div>
            <div class="col-8 mt-2 d-flex align-items-center">
              <span style="font-style: italic;">{{totalYtd}}</span>              
            </div>
          </div>

          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>PTO Earned</b>
            </div>
            <div class="col-4">
              <input type="number" min="0" step="1" style="width: 85%; display: inline-block;" placeholder="PTO Hours" class="form-control mt-2" formControlName="ptoHours" /> h
              <app-validation-message [form]="form" [path]="'ptoHours'" [label]="'Hours'"></app-validation-message>
            </div>
            <div class="col-4">
              <input type="number" min="0" max="59" step="1" style="width: 85%; display: inline-block;" placeholder="PTO Minutes" class="form-control mt-2" formControlName="ptoMinutes" /> m
              <app-validation-message [form]="form" [path]="'ptoMinutes'" [label]="'Minutes'"></app-validation-message>
            </div>
          </div>

          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Comments</b>
            </div>
            <div class="col-8">
              <textarea style="width: 100%;" class="form-control mt-2" formControlName="comments"></textarea>
              <app-validation-message [form]="form" [path]="'comments'" [label]="'Comments'"></app-validation-message>
            </div>            
          </div>

        </div>
      </div>
    </div>    
  </form>
  <p-footer>    
    <button class="btn btn-secondary" type="button" (click)="closeModal()">Cancel</button>
    <button class="btn btn-primary" type="submit" (click)="savePtoEarned()" [disabled]="disableButtons">Save</button>
  </p-footer>
</p-dialog>
