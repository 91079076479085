<form class="card mt-2" [formGroup]="form" *ngIf="canView || canEditBehaviorTechs || canEditBehaviorTechsAA">
  <div class="card-body">
    <div class="float-right">
      <button routerLink="/offerletter/list" class="btn btn-secondary" >Back to list</button>
    </div>
    <h4 class="mb-3">Create Offer Letter</h4>
    <h5 class="mb-3  mt-4">Information</h5>
    <div class="row">
      <div class="col-12 col-sm-6">
        <div class="row"   formGroupName="offerLetter" >
          <div class="col-4 d-flex align-items-center">
            <b>Form Builder template</b>
          </div>
          <div class="col-8">
            <p-dropdown styleClass="form-control mt-2" [options]="formOfferlettersOptions" formControlName="formID" placeholder="Select form..."></p-dropdown>
            <small>You can create an Offer Letter template on Form Builder -> <a target="newwindow" routerLink="/form-builder/form">New Template</a></small>
            <app-validation-message [form]="form" [path]="'offerLetter.formID'" [name]="'formID'" [label]="'Form'"> </app-validation-message>
          </div>
        </div>
        <div class="row"   formGroupName="offerLetter">
          <div class="col-4 d-flex align-items-center">
            <b>Salary or Hourly</b>
          </div>
          <div class="col-8">
            <select class="form-control mt-2" formControlName="salaryOrPayRate" >
              <option value="true">Salary</option>
              <option value="false">Hourly</option>
            </select>
            <app-validation-message [form]="form" [path]="'offerLetter.salaryOrPayRate'" [name]="'salaryOrPayRate'" [label]="'Salary or Hourly'"> </app-validation-message>
          </div>
        </div>
        <div class="row" formGroupName="offerLetter" >
          <div class="col-4 d-flex align-items-center">
            <b>Existing user?</b>
          </div>
          <div class="col-8 mt-2 mb-2">
            <p-checkbox [disabled]="!!offerletterWithUser.offerLetter.id"  formControlName="existingUser" [binary]="true" ></p-checkbox>
            <app-validation-message [form]="form" [path]="'offerLetter.existingUser'" [name]="'existingUser'" [label]="'Existing User'"> </app-validation-message>
          </div>
        </div>
        <div class="row" formGroupName="user" *ngIf="existingUser || offerletterWithUser.offerLetter.documentToSignID">
          <div class="col-4 d-flex align-items-center">
            <b>User</b>
          </div>
          <div class="col-8">
            <p-dropdown [disabled]="!!offerletterWithUser.offerLetter.id || rehireUser"  styleClass="form-control mt-2" [options]="usersList" formControlName="id" placeholder="Select user..."></p-dropdown>
            <app-validation-message [form]="form" [path]="'user.id'" [name]="'id'" [label]="'User'"> </app-validation-message>
          </div>
        </div>
        <div class="row" formGroupName="offerLetter"  *ngIf="!existingUser && !offerletterWithUser.offerLetter.documentToSignID">
          <div class="col-4 d-flex align-items-center">
            <b>Prospective candidate?</b>
          </div>
          <div class="col-8 mt-2 mb-2">
            <p-checkbox [disabled]="!!offerletterWithUser.offerLetter.id"  formControlName="prospectiveCandidate" [binary]="true" ></p-checkbox>
            <app-validation-message [form]="form" [path]="'offerLetter.prospectiveCandidate'" [name]="'prospectiveCandidate'" [label]="'Prospective Candidate'"> </app-validation-message>
          </div>
        </div>
        <div class="row" formGroupName="offerLetter" *ngIf="prospectiveCandidate">
          <div class="col-4 d-flex align-items-center">
            <b>Candidate</b>
          </div>
          <div class="col-8">
            <p-dropdown [disabled]="!!offerletterWithUser.offerLetter.id"  styleClass="form-control mt-2" [options]="candidatesList" formControlName="prospectiveCandidateID" placeholder="Select..."></p-dropdown>
            <app-validation-message [form]="form" [path]="'offerLetter.prospectiveCandidateID'" [name]="'prospectiveCandidateID'" [label]="'Prospective Candidate'"> </app-validation-message>
          </div>
        </div>
        <div class="row" formGroupName="user" *ngIf="(!existingUser || rehireUser) && !offerletterWithUser.offerLetter.documentToSignID">
          <div class="col-4 d-flex align-items-center">
            <b>Personal Email</b>
          </div>
          <div class="col-8">
              <input  type="text" class="form-control mt-2" formControlName="personalEmail"/>
            <app-validation-message [form]="form" [path]="'user.personalEmail'" [name]="'personalEmail'" [label]="'Personal Email'"> </app-validation-message>
          </div>
        </div>
        <div class="row"  formGroupName="user" *ngIf="!existingUser && !offerletterWithUser.user.inRehireProcess && !offerletterWithUser.offerLetter.documentToSignID">
          <div class="col-4 d-flex align-items-center">
            <b>Username</b>
          </div>
          <div class="col-8">
              <input  type="text" class="form-control mt-2" formControlName="username" name="username"
              autocomplete="off"/>
            <app-validation-message [form]="form" [path]="'user.username'" [name]="'username'" [label]="'Username'"> </app-validation-message>
            <div class="text-danger" [hidden]="!usernameTaken">Username is already in use, please enter a new one.</div>
          </div>
        </div>
        <div class="row"  formGroupName="user" *ngIf="!existingUser && !offerletterWithUser.user.inRehireProcess && !offerletterWithUser.offerLetter.documentToSignID">
          <div class="col-4 d-flex align-items-center">
            <b>Password</b>
          </div>
          <div class="col-8">
              <input  type="password" class="form-control mt-2" formControlName="password" name="password"
              autocomplete="off"/>
            <app-validation-message [form]="form" [path]="'user.password'" [name]="'password'" [label]="'Password'"> </app-validation-message>
          </div>
        </div>
        <div class="row" formGroupName="user" *ngIf="!existingUser && !offerletterWithUser.offerLetter.documentToSignID">
          <div class="col-4 d-flex align-items-center">
            <b>Legal First Name</b>
          </div>
          <div class="col-8">
              <input  type="text" class="form-control mt-2" formControlName="firstName"
              name="firstName"
              autocomplete="off"/>
            <app-validation-message [form]="form" [path]="'user.firstName'" [name]="'firstName'" [label]="'Legal First Name'"> </app-validation-message>
          </div>
        </div>
        <div class="row" formGroupName="user" *ngIf="!existingUser && !offerletterWithUser.offerLetter.documentToSignID">
          <div class="col-4 d-flex align-items-center">
            <b>Legal Last Name</b>
          </div>
          <div class="col-8">
              <input  type="text" class="form-control mt-2" formControlName="lastName"
              name="lastName"
              autocomplete="off"/>
            <app-validation-message [form]="form" [path]="'user.lastName'" [name]="'lastName'" [label]="'Legal Last Name'"> </app-validation-message>
          </div>
        </div>
        <div class="row" formGroupName="offerLetter" >
          <div class="col-4 d-flex align-items-center">
            <b>Require Diploma</b>
          </div>
          <div class="col-8 mt-2 mb-2">
            <p-checkbox [formControl]="form.get('offerLetter.requireDiploma')" [binary]="true" ></p-checkbox>
          </div>
        </div>
        <div class="row" formGroupName="offerLetter" >
          <div class="col-4 d-flex align-items-center">
            <b>Require Transcripts</b>
          </div>
          <div class="col-8 mt-2 mb-2">
            <p-checkbox [formControl]="form.get('offerLetter.requireTranscripts')" [binary]="true" ></p-checkbox>
          </div>
        </div>
        <div class="row" formGroupName="offerLetter" >
          <div class="col-4 d-flex align-items-center">
            <b>Require Licenses</b>
          </div>
          <div class="col-8 mt-2 mb-2">
            <p-checkbox [formControl]="form.get('offerLetter.requireLicenses')" [binary]="true" ></p-checkbox>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6" formGroupName="offerLetter">
        <div class="row"  >
          <div class="col-4 d-flex align-items-center">
            <b>Respond by</b>
          </div>
          <div class="col-8">
              <input type="date" class="form-control mt-2" formControlName="validUntilDate" min="{{minDate()}}"/>
            <app-validation-message [form]="form" [path]="'offerLetter.validUntilDate'" [name]="'validUntilDate'" [label]="'Respond by Date'"> </app-validation-message>
          </div>
        </div>
        <div class="row" *ngIf="!existingUser || rehireUser" >
          <div class="col-4 d-flex align-items-center">
            <b>Orientation Date</b>
          </div>
          <div class="col-8">
              <input type="date" class="form-control mt-2" formControlName="orientationDate" min="{{minDate()}}"/>
            <app-validation-message [form]="form" [path]="'offerLetter.orientationDate'" [name]="'orientationDate'" [label]="'Orientation Date'"> </app-validation-message>
          </div>
        </div>
        <div class="row" >
          <div class="col-4 d-flex align-items-center">
            <b>Region Service</b>
          </div>
          <div class="col-8">
            <i *ngIf="!campusesList" class="material-icons rotate text-muted">loop</i>
            <p-dropdown styleClass="form-control mt-2 w-100" [options]="campusesList" formControlName="serviceRegion" placeholder="Select campus..."></p-dropdown>
            <app-validation-message [form]="form" [path]="'offerLetter.serviceRegion'" [name]="'serviceRegion'" [label]="'Region Service'"> </app-validation-message>
          </div>
        </div>
        <div class="row" >
          <div class="col-4 d-flex align-items-center">
            <b>Job Title</b>
          </div>
          <div class="col-8">
            <p-dropdown #jobTitleDropdown styleClass="form-control mt-2 w-100" [options]="jobTitleList" formControlName="jobTitleID" placeholder="Select job title..."></p-dropdown>
            <input type="text" class="form-control mt-2" formControlName="position" *ngIf="form?.get('offerLetter.jobTitleID')?.value === otherJobTitleID" />
            <app-validation-message [form]="form" [path]="'offerLetter.jobTitleID'" [name]="'jobTitleID'" [label]="'Job Title'"> </app-validation-message>
          </div>
        </div>
        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Type Position</b>
          </div>
          <div class="col-8">
            <select class="form-control mt-2" formControlName="typePosition">
              <option *ngIf="!canEditOnlyBehaviorTechs" value="Administrative">Administrative</option>
              <option *ngIf="!canEditOnlyBehaviorTechs && !canEditOnlyBehaviorTechsAA" value="Mental Health Clinician">Mental Health Clinician</option>
              <option *ngIf="!canEditOnlyBehaviorTechs && !canEditOnlyBehaviorTechsAA" value="Behavior Clinician">Behavior Clinician</option>
              <option value="Behavior Technician">Behavior Technician</option>
              <option *ngIf="!canEditOnlyBehaviorTechs  && !canEditOnlyBehaviorTechsAA" value="Dually-Credentialed">Dually-Credentialed</option>
              <option *ngIf="!canEditOnlyBehaviorTechs  && !canEditOnlyBehaviorTechsAA" value="Psychologist">Psychologist</option>
              <option *ngIf="!canEditOnlyBehaviorTechs && !canEditOnlyBehaviorTechsAA" value="Director">Director</option>
              <option *ngIf="!canEditOnlyBehaviorTechs && !canEditOnlyBehaviorTechsAA" value="Other">Other</option>
            </select>
            <input *ngIf="form.get('offerLetter.typePosition').value==='Other'"  type="text" class="form-control mt-2" formControlName="typePositionOther"/>
            <app-validation-message [form]="form" [path]="'offerLetter.typePosition'" [name]="'typePosition'" [label]="'Type Position'"> </app-validation-message>
          </div>
        </div>
        <div class="row" >
          <div class="col-4 d-flex align-items-center">
            <b>Required work billed hours (%)</b>
          </div>
          <div class="col-8">
              <input  type="number" step="1" class="form-control mt-2" formControlName="requiredBilledHours"/>
            <app-validation-message [form]="form" [path]="'offerLetter.requiredBilledHours'" [name]="'requiredBilledHours'" [label]="'Required work billed hours'"> </app-validation-message>
          </div>
        </div>
        <div class="row" >
          <div class="col-4 d-flex align-items-center">
            <b>Reporting To</b>
          </div>
          <div class="col-8">
            <p-multiSelect #reportingUsersMultiSelect (onFocus)="openMultiSelect(reportingUsersMultiSelect)" autofocusFilter="true" class="w-100" styleClass="w-100 d-flex mt-2" inputStyleClass="form-control" [options]="usersReportingList" formControlName="reportingToUserIDs" [defaultLabel]="'Select users...'"></p-multiSelect>
            <app-validation-message [form]="form" [path]="'offerLetter.reportingToUserIDs'" [name]="'personalEmail'" [label]="'Reporting To'"> </app-validation-message>
          </div>
        </div>
      </div>
    </div>

    <h5 class="mb-3 mt-4">Economic Benefits</h5>
    <div class="row mt-4" formGroupName="offerLetter">
      <div class="col-12 ">

        <div class="row">
          <div class="col-3 d-flex align-items-center">
            <b>Benefit</b>
          </div>
          <div class="col-6">
            Amount
          </div>
          <div class="col-1 p-0 d-flex justify-content-center align-items-center">
            Enable
          </div>
          <div class="col-1 p-0 d-flex justify-content-center align-items-center">
            Hide amount<br> on letter
          </div>
          <div class="col-1 p-0 d-flex justify-content-center align-items-center">
            Remove from Total Estimated Value
          </div>
        </div>
        <div class="row">
          <div class="col-3 d-flex align-items-center">
            <b>{{isHourly()?'Hourly billable rate':'Annual Salary'}}</b>
          </div>
          <div class="col-6">
            <input  type="number" step="0.01" class="form-control mt-2" formControlName="salaryPayRate" />
            <app-validation-message [form]="form" [path]="'offerLetter.salaryPayRate'" [name]="'salaryPayRate'" [label]="'Salary'"> </app-validation-message>
          </div>

          <div class="col-1 p-0 d-flex justify-content-center align-items-center">
          </div>
          <div class="col-1 p-0 d-flex justify-content-center align-items-center">
          </div>
          <div class="col-1 p-0 d-flex justify-content-center align-items-center">
          </div>
        </div>

        <div class="row" *ngIf="isHourly()">
          <div class="col-3 d-flex align-items-center">
            <b>Hourly training rate</b>
          </div>
          <div class="col-6">
            <input  type="number" step="0.01" class="form-control mt-2" formControlName="trainingRate" />
            <app-validation-message [form]="form" [path]="'offerLetter.trainingRate'" [name]="'trainingRate'" [label]="'Training Rate'"> </app-validation-message>
          </div>
          <div class="col-1 p-0 d-flex justify-content-center align-items-center">
            <p-checkbox [formControl]="form.get('offerLetter.trainingRateCheck')" [binary]="true" ></p-checkbox>
          </div>
          <div class="col-1 p-0 d-flex justify-content-center align-items-center">
          </div>
          <div class="col-1 p-0 d-flex justify-content-center align-items-center">
          </div>
        </div>
        <div class="row">
          <div class="col-3 d-flex align-items-center">
            <b>Med Clinic</b>
          </div>
          <div class="col-6">
            <input
            type="number" step="0.01"
              class="form-control mt-2"
              formControlName="medClinic"
            />
            <app-validation-message [form]="form" [path]="'offerLetter.medClinic'" [name]="'medClinic'" [label]="'Primary Care'"> </app-validation-message>
          </div>
          <div class="col-1 p-0 d-flex justify-content-center align-items-center">
            <p-checkbox [formControl]="form.get('offerLetter.medClinicCheck')" [binary]="true" ></p-checkbox>
          </div>

          <div class="col-1 p-0 d-flex justify-content-center align-items-center">
            <p-checkbox [formControl]="form.get('offerLetter.medClinicHideAmount')" [binary]="true" ></p-checkbox>
          </div>

          <div class="col-1 p-0 d-flex justify-content-center align-items-center">
            <p-checkbox [formControl]="form.get('offerLetter.medClinicRemoveFromEstimated')" [binary]="true" ></p-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="col-3 d-flex align-items-center">
            <b>Mental Health</b>
          </div>
          <div class="col-6">
            <input
            type="number" step="0.01"
              class="form-control mt-2"
              formControlName="mentalHealth"
            />
            <app-validation-message [form]="form" [path]="'offerLetter.mentalHealth'" [name]="'mentalHealth'" [label]="'Mental Health'"> </app-validation-message>
          </div>
          <div class="col-1 p-0 d-flex justify-content-center align-items-center">
            <p-checkbox [formControl]="form.get('offerLetter.mentalHealthCheck')" [binary]="true" ></p-checkbox>
          </div>

          <div class="col-1 p-0 d-flex justify-content-center align-items-center">
            <p-checkbox [formControl]="form.get('offerLetter.mentalHealthHideAmount')" [binary]="true" ></p-checkbox>
          </div>

          <div class="col-1 p-0 d-flex justify-content-center align-items-center">
            <p-checkbox [formControl]="form.get('offerLetter.mentalHealthRemoveFromEstimated')" [binary]="true" ></p-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="col-3 d-flex align-items-center">
            <b>Gym</b>
          </div>
          <div class="col-6">
            <input
            type="number" step="0.01"
              class="form-control mt-2"
              formControlName="gym"
            />
            <app-validation-message [form]="form" [path]="'offerLetter.gym'" [name]="'gym'" [label]="'Gym'"> </app-validation-message>
          </div>
          <div class="col-1 p-0 d-flex justify-content-center align-items-center">
            <p-checkbox [formControl]="form.get('offerLetter.gymCheck')" [binary]="true" ></p-checkbox>
          </div>

          <div class="col-1 p-0 d-flex justify-content-center align-items-center">
            <p-checkbox [formControl]="form.get('offerLetter.gymHideAmount')" [binary]="true" ></p-checkbox>
          </div>

          <div class="col-1 p-0 d-flex justify-content-center align-items-center">
            <p-checkbox [formControl]="form.get('offerLetter.gymRemoveFromEstimated')" [binary]="true" ></p-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="col-3 d-flex align-items-center">
            <b>Student Loan Repayment ($/month)</b>
          </div>
          <div class="col-6">
            <input
            type="number" step="0.01"
              class="form-control mt-2"
              formControlName="studentLoanRepayment"
            />
            <app-validation-message [form]="form" [path]="'offerLetter.studentLoanRepayment'" [name]="'studentLoanRepayment'" [label]="'Student Loan Repayment'"> </app-validation-message>
          </div>
          <div class="col-1 p-0 d-flex justify-content-center align-items-center">
            <p-checkbox [formControl]="form.get('offerLetter.studentLoanRepaymentCheck')" [binary]="true" ></p-checkbox>
          </div>

          <div class="col-1 p-0 d-flex justify-content-center align-items-center">
            <p-checkbox [formControl]="form.get('offerLetter.studentLoanRepaymentHideAmount')" [binary]="true" ></p-checkbox>
          </div>

          <div class="col-1 p-0 d-flex justify-content-center align-items-center">
            <p-checkbox [formControl]="form.get('offerLetter.studentLoanRepaymentRemoveFromEstimated')" [binary]="true" ></p-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="col-3 d-flex align-items-center">
            <b>Cellphone stipend ($/month)</b>
          </div>
          <div class="col-6">
            <input
            type="number" step="0.01"
              class="form-control mt-2"
              formControlName="cellphoneStipend"
            />
            <app-validation-message [form]="form" [path]="'offerLetter.cellphoneStipend'" [name]="'cellphoneStipend'" [label]="'Cellphone Stipend'"> </app-validation-message>
          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">
            <p-checkbox [formControl]="form.get('offerLetter.cellphoneStipendCheck')" [binary]="true" ></p-checkbox>
          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">
            <p-checkbox [formControl]="form.get('offerLetter.cellphoneStipendHideAmount')" [binary]="true" ></p-checkbox>
          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">
            <p-checkbox [formControl]="form.get('offerLetter.cellphoneStipendRemoveFromEstimated')" [binary]="true" ></p-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="col-3 d-flex align-items-center">
            <b>Transportation Stipend ($/month)</b>
          </div>
          <div class="col-6">
            <input
            type="number" step="0.01"
              class="form-control mt-2"
              formControlName="gasCard"
            />
            <app-validation-message [form]="form" [path]="'offerLetter.gasCard'" [name]="'gasCard'" [label]="'Gas Stipend'"> </app-validation-message>

          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">
            <p-checkbox [formControl]="form.get('offerLetter.gasCardCheck')" [binary]="true" ></p-checkbox>
          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">
            <p-checkbox [formControl]="form.get('offerLetter.gasCardHideAmount')" [binary]="true" ></p-checkbox>
          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">
            <p-checkbox [formControl]="form.get('offerLetter.gasCardRemoveFromEstimated')" [binary]="true" ></p-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="col-3 d-flex align-items-center">
            <b>Professional Liability Insurance ($/year)</b>
          </div>
          <div class="col-6">
            <input
            type="number" step="0.01"
              class="form-control mt-2"
              formControlName="professionalLiabilityInsurance"
            />
            <app-validation-message [form]="form" [path]="'offerLetter.professionalLiabilityInsurance'" [name]="'professionalLiabilityInsurance'" [label]="'Professional Liability Insurance '"> </app-validation-message>

          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">
            <p-checkbox [formControl]="form.get('offerLetter.professionalLiabilityInsuranceCheck')" [binary]="true" ></p-checkbox>
          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">
            <p-checkbox [formControl]="form.get('offerLetter.professionalLiabilityInsuranceHideAmount')" [binary]="true" ></p-checkbox>
          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">
            <p-checkbox [formControl]="form.get('offerLetter.professionalLiabilityInsuranceRemoveFromEstimated')" [binary]="true" ></p-checkbox>
          </div>
        </div>

        <div class="row">
          <div class="col-3 d-flex align-items-center">
            <b>Flexible schedule (hours)</b>
          </div>
          <div class="col-6">
            <input
            type="number" step="1"
              class="form-control mt-2"
              formControlName="flexibleSchedule"
            />
            <app-validation-message [form]="form" [path]="'offerLetter.flexibleSchedule'" [name]="'flexibleSchedule'" [label]="'Flexible Schedule'"> </app-validation-message>

          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">
            <p-checkbox [formControl]="form.get('offerLetter.flexibleScheduleCheck')" [binary]="true" ></p-checkbox>
          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">
          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">
          </div>
        </div>

        <div class="row">
          <div class="col-3 d-flex align-items-center">
            <b>Health benefit annual cost ($/year)</b>
          </div>
          <div class="col-6">
            <input
            type="number" step="0.01"
              class="form-control mt-2"
              formControlName="healthBenefitAnnualCost"
            />
            <app-validation-message [form]="form" [path]="'offerLetter.healthBenefitAnnualCost'" [name]="'healthBenefitAnnualCost'" [label]="'Health benefit annual cost'"> </app-validation-message>

          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">
            <p-checkbox [formControl]="form.get('offerLetter.healthBenefitAnnualCostCheck')" [binary]="true" ></p-checkbox>
          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">
            <p-checkbox [formControl]="form.get('offerLetter.healthBenefitAnnualCostHideAmount')" [binary]="true" ></p-checkbox>
          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">
            <p-checkbox [formControl]="form.get('offerLetter.healthBenefitAnnualCostRemoveFromEstimated')" [binary]="true" ></p-checkbox>
          </div>
        </div>

        <div class="row">
          <div class="col-3 d-flex align-items-center">
            <b>Relocation stipend ($/year)</b>
          </div>
          <div class="col-6">
            <input
            type="number" step="0.01"
              class="form-control mt-2"
              formControlName="relocationStipend"
            />
            <app-validation-message [form]="form" [path]="'offerLetter.relocationStipend'" [name]="'relocationStipend'" [label]="'Relocation stipend'"> </app-validation-message>

          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">
            <p-checkbox [formControl]="form.get('offerLetter.relocationStipendCheck')" [binary]="true" ></p-checkbox>
          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">
            <p-checkbox [formControl]="form.get('offerLetter.relocationStipendHideAmount')" [binary]="true" ></p-checkbox>
          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">
            <p-checkbox [formControl]="form.get('offerLetter.relocationStipendRemoveFromEstimated')" [binary]="true" ></p-checkbox>
          </div>
        </div>

        <div class="row">
          <div class="col-3 d-flex align-items-center">
            <b>Eletronic Devices ($/year)</b>
          </div>
          <div class="col-6">
            <input
            type="number" step="0.01"
              class="form-control mt-2"
              formControlName="eletronicDevices"
            />
            <app-validation-message [form]="form" [path]="'offerLetter.eletronicDevices'" [name]="'eletronicDevices'" [label]="'Eletronic Devices'"> </app-validation-message>

          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">
            <p-checkbox [formControl]="form.get('offerLetter.eletronicDevicesCheck')" [binary]="true" ></p-checkbox>
          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">
            <p-checkbox [formControl]="form.get('offerLetter.eletronicDevicesHideAmount')" [binary]="true" ></p-checkbox>
          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">
            <p-checkbox [formControl]="form.get('offerLetter.eletronicDevicesRemoveFromEstimated')" [binary]="true" ></p-checkbox>
          </div>
        </div>

        <div class="row">
          <div class="col-3 d-flex align-items-center">
            <b>Regulatory Oversight ($/year)</b>
          </div>
          <div class="col-6">
            <input
            type="number" step="0.01"
              class="form-control mt-2"
              formControlName="regulatoryOversight"
            />
            <app-validation-message [form]="form" [path]="'offerLetter.regulatoryOversight'" [name]="'regulatoryOversight'" [label]="'Regulatory Oversight'"> </app-validation-message>

          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">
            <p-checkbox [formControl]="form.get('offerLetter.regulatoryOversightCheck')" [binary]="true" ></p-checkbox>
          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">
            <p-checkbox [formControl]="form.get('offerLetter.regulatoryOversightHideAmount')" [binary]="true" ></p-checkbox>
          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">
            <p-checkbox [formControl]="form.get('offerLetter.regulatoryOversightRemoveFromEstimated')" [binary]="true" ></p-checkbox>
          </div>
        </div>
      </div>
      <div class="col-12 ">
        <div class="row">
          <div class="col-3 d-flex align-items-center">
            <b>Intern/Trainee Supervision ($/month)</b>
          </div>
          <div class="col-6">
            <input
            type="number" step="0.01"
              class="form-control mt-2"
              formControlName="receivingSupervision"
            />
            <app-validation-message [form]="form" [path]="'offerLetter.receivingSupervision'" [name]="'receivingSupervision'" [label]="'Receiving Supervision'"> </app-validation-message>

          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">
            <p-checkbox [formControl]="form.get('offerLetter.receivingSupervisionCheck')" [binary]="true" ></p-checkbox>
          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">
            <p-checkbox [formControl]="form.get('offerLetter.receivingSupervisionHideAmount')" [binary]="true" ></p-checkbox>
          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">
            <p-checkbox [formControl]="form.get('offerLetter.receivingSupervisionRemoveFromEstimated')" [binary]="true" ></p-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="col-3 d-flex align-items-center">
            <b>Tuition assistance benefit ($/year)</b>
          </div>
          <div class="col-6">
            <input
            type="number" step="0.01"
              class="form-control mt-2"
              formControlName="tuitionAssistanceBenefit"
            />
            <app-validation-message [form]="form" [path]="'offerLetter.tuitionAssistanceBenefit'" [name]="'tuitionAssistanceBenefit'" [label]="'Tuition assistance benefit'"> </app-validation-message>

          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">
            <p-checkbox [formControl]="form.get('offerLetter.tuitionAssistanceBenefitCheck')" [binary]="true" ></p-checkbox>
          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">
            <p-checkbox [formControl]="form.get('offerLetter.tuitionAssistanceBenefitHideAmount')" [binary]="true" ></p-checkbox>
          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">
            <p-checkbox [formControl]="form.get('offerLetter.tuitionAssistanceBenefitRemoveFromEstimated')" [binary]="true" ></p-checkbox>
          </div>
        </div>
        <div class="row mt-3 mb-3">
          <div class="col-3 d-flex align-items-center">
            <b>Profit Sharing</b>
          </div>
          <div class="col-6">

          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">
            <p-checkbox [formControl]="form.get('offerLetter.hasProfitSharing')" [binary]="true" ></p-checkbox>
          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">

          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">
          </div>
        </div>
        <div class="row mt-3 mb-3">
          <div class="col-3 d-flex align-items-center">
            <b>Incentive programs</b>
          </div>
          <div class="col-6">

          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">
            <p-checkbox [formControl]="form.get('offerLetter.hasIncentivePrograms')" [binary]="true" ></p-checkbox>
          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">

          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">
          </div>
        </div>
        <div class="row">
          <div class="col-3 d-flex align-items-center">
            <b>Bonus by Type position ($/year)</b>
          </div>
          <div class="col-6">
            <input
            type="number" step="0.01"
              class="form-control mt-2"
              formControlName="bonusByTypePosition"
            />
            <app-validation-message [form]="form" [path]="'offerLetter.bonusByTypePosition'" [name]="'bonusByTypePosition'" [label]="'Bonus by Type position'"> </app-validation-message>

          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">
            <p-checkbox [formControl]="form.get('offerLetter.bonusByTypePositionCheck')" [binary]="true" ></p-checkbox>
          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">
            <p-checkbox [formControl]="form.get('offerLetter.bonusByTypePositionHideAmount')" [binary]="true" ></p-checkbox>
          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">
            <p-checkbox [formControl]="form.get('offerLetter.bonusByTypePositionRemoveFromEstimated')" [binary]="true" ></p-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="col-3 d-flex align-items-center">
            <b>401(k) plan ($/year)</b>
          </div>
          <div class="col-6">
            <input
            type="number" step="0.01"
              class="form-control mt-2"
              formControlName="plan401k"
            />
            <app-validation-message [form]="form" [path]="'offerLetter.plan401k'" [name]="'plan401k'" [label]="'401(k) plan'"> </app-validation-message>

          </div>
          <div  class="col-1 p-0 d-flex align-items-center justify-content-center">
            <p-checkbox [formControl]="form.get('offerLetter.plan401kCheck')" [binary]="true" ></p-checkbox>
          </div>
          <div  class="col-1 p-0 d-flex align-items-center justify-content-center">
            <p-checkbox [formControl]="form.get('offerLetter.plan401kHideAmount')" [binary]="true" ></p-checkbox>
          </div>
          <div  class="col-1 p-0 d-flex align-items-center justify-content-center">
            <p-checkbox [formControl]="form.get('offerLetter.plan401kRemoveFromEstimated')" [binary]="true" ></p-checkbox>
          </div>
        </div>
        <div class="row" >
          <div class="col-3 d-flex align-items-center">
            <b>PTO (Annually)</b>
          </div>
          <div class="col-3  pr-1">
            <input
            type="number" step="0.01"
              class="form-control mt-2"
              formControlName="pto"
            />
            <app-validation-message [form]="form" [path]="'offerLetter.pto'" [name]="'pto'" [label]="'PTO'"> </app-validation-message>

          </div>
          <div class="col-3   pl-0 d-flex align-items-center">
            <input
            type="number" step="0.5" min="2" max="6"
              class="form-control mt-2 mr-1"
              formControlName="ptoPercentage"
            /> <span class="mt-2">%</span>
          </div>
          <div  class="col-1 p-0 d-flex align-items-center justify-content-center">
            <p-checkbox [formControl]="form.get('offerLetter.ptoCheck')" [binary]="true" ></p-checkbox>
          </div>
          <div  class="col-1 p-0 d-flex align-items-center justify-content-center">
            <p-checkbox [formControl]="form.get('offerLetter.ptoHideAmount')" [binary]="true" ></p-checkbox>
          </div>
          <div  class="col-1 p-0 d-flex align-items-center justify-content-center">
            <p-checkbox [formControl]="form.get('offerLetter.ptoRemoveFromEstimated')" [binary]="true" ></p-checkbox>
          </div>
        </div>
        <div class="row" >
          <div class="col-3 d-flex align-items-center">
            <b>Holiday (Annually)</b>
          </div>
          <div class="col-6">
            <input
            type="number" step="0.01"
              class="form-control mt-2"
              formControlName="holiday"
            />
            <app-validation-message [form]="form" [path]="'offerLetter.holiday'" [name]="'holiday'" [label]="'Holiday'"> </app-validation-message>

          </div>

          <div class="col-1 p-0 d-flex align-items-center justify-content-center">
            <p-checkbox [formControl]="form.get('offerLetter.holidayCheck')" [binary]="true" ></p-checkbox>
          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">
            <p-checkbox [formControl]="form.get('offerLetter.holidayHideAmount')" [binary]="true" ></p-checkbox>
          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">
            <p-checkbox [formControl]="form.get('offerLetter.holidayRemoveFromEstimated')" [binary]="true" ></p-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="col-3 d-flex align-items-center">
            <b>Annual salary after (months)</b>
          </div>
          <div class="col-6">
            <input
            type="number" step="0.01"
              class="form-control mt-2"
              formControlName="monthsAfter"
            />
            <app-validation-message [form]="form" [path]="'offerLetter.monthsAfter'" [name]="'monthsAfter'" [label]="'Months'"> </app-validation-message>

          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">
            <p-checkbox [formControl]="form.get('offerLetter.salaryPayRateAfterMonthsCheck')" [binary]="true" ></p-checkbox>
          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">

          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">

          </div>
        </div>
        <div class="row">
          <div class="col-3 d-flex align-items-center">
            <b>Annual salary after {{form?.get('offerLetter.monthsAfter')?.value}} months ($)</b>
          </div>
          <div class="col-6">
            <input
            type="number" step="0.01"
              class="form-control mt-2"
              formControlName="salaryPayRateAfterMonths"
            />
            <app-validation-message [form]="form" [path]="'offerLetter.salaryPayRateAfterMonths'" [name]="'salaryPayRateAfterMonths'" [label]="'Annual salary after 12 months'"> </app-validation-message>

          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">

          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">

          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">

          </div>
        </div>
        <div class="row">
          <div class="col-3 d-flex align-items-center">
            <b>Other Benefit (Name)</b>
          </div>
          <div class="col-6">
            <input
              type="text"
              class="form-control mt-2"
              formControlName="otherBenefitName"
            />
            <app-validation-message [form]="form" [path]="'offerLetter.otherBenefitName'" [name]="'otherBenefitName'" [label]="'Other Benefit (Name)'"> </app-validation-message>

          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">
            <p-checkbox [formControl]="form.get('offerLetter.otherBenefitNameCheck')" [binary]="true" ></p-checkbox>
          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">
            <p-checkbox [formControl]="form.get('offerLetter.otherBenefitHideAmount')" [binary]="true" ></p-checkbox>
          </div>
          <div class="col-1 p-0 d-flex align-items-center justify-content-center">
            <p-checkbox [formControl]="form.get('offerLetter.otherBenefitRemoveFromEstimated')" [binary]="true" ></p-checkbox>
          </div>
        </div>

        <div class="row">
          <div class="col-3 d-flex align-items-center">
            <b>Other Benefit ($/year)</b>
          </div>
          <div class="col-6">
            <input
            type="number" step="0.01"
              class="form-control mt-2"
              formControlName="otherBenefitAmount"
            />
            <app-validation-message [form]="form" [path]="'offerLetter.otherBenefitAmount'" [name]="'otherBenefitAmount'" [label]="'Other Benefit ($/year)'"> </app-validation-message>

          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-6">
        <b>Total annually:</b> {{total.toFixed(2)}}<br>
        <span *ngIf="form?.get('offerLetter.salaryPayRateAfterMonthsCheck')?.value"><b>Total annually after 12 months:</b> {{total12.toFixed(2)}}<br></span>
        <br>
        <i *ngIf="creatingOfferletter || previewingOfferletter" class="material-icons rotate text-muted">loop</i>
        <button *ngIf="!creatingOfferletter && !previewingOfferletter" class="btn btn-primary mr-3 mt-2" [class.disabled]="form.invalid" (click)="preview()">Preview Doc</button>
        <button *ngIf="(canEdit || canEditBehaviorTechs || canEditBehaviorTechsAA) && !creatingOfferletter && !previewingOfferletter && !offerletterWithUser.offerLetter.documentToSignID" class="btn btn-primary mr-3 mt-2" [class.disabled]="form.invalid" (click)="save()">Save Draft</button>
        <button *ngIf="(canEdit || canEditBehaviorTechs || canEditBehaviorTechsAA) && !creatingOfferletter && !previewingOfferletter" class="btn btn-primary mt-2 " [class.disabled]="form.invalid" (click)="create()">{{offerletterWithUser.offerLetter.documentToSignID?'Create and send new offer':'Create and Send'}}</button>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12" *ngIf="offerletterWithUser.offerLetter.documentToSignID">
        <b>Last sent file:</b> {{getShortDateFormatted(offerletterWithUser.offerLetter.documentCreated)}} <span class="pointer mr-2 "  (click)="viewOffer(offerletterWithUser)"><i title="Download Offer Doc" class="fas fa-file-download"></i> Download</span>
      </div>
      <div class="col-12 mt-3" *ngIf="offerletterWithUser.offerLetter.oldFiles && offerletterWithUser.offerLetter.oldFiles.length">
        <b>Previous sent files:</b>
      </div>
      <div class="col-12"  *ngFor="let oldFile of offerletterWithUser.offerLetter.oldFiles">
       <b>File created:</b> {{getShortDateFormatted(oldFile.createdDate)}} <span class="pointer mr-2 "  (click)="downloadOldFile(oldFile.id)"><i title="Download Offer Doc" class="fas fa-file-download"></i> Download</span>
      </div>
    </div>
  </div>
</form>
