import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { EmployeePtoSummaryModel } from '../../../../models/timeoffModel';
import { SelectItem } from 'primeng/api';
import { loadViewableCampuses, loadClassifications, getSummaryList } from '../../../../core/store/timeoff/timeoff.actions';
import { Router } from '@angular/router';
import { loadEmployeeUsers } from '../../../../core/store/employee/employee.action';

@Component({
  selector: 'app-list-pto-summary',
  templateUrl: './list-pto-summary.component.html',
  styleUrls: ['./list-pto-summary.component.scss']
})
export class ListPtoSummaryComponent extends BaseComponent implements OnInit {
  summaries: PagingResultsModel<EmployeePtoSummaryModel>;
  filter: any;

  loadingSummaryList: boolean = true;
  canEdit: boolean = false;
  canViewSchedulingTimeOff: boolean = false;

  campusList: SelectItem[];
  campusId: number = 0;

  classificationList: SelectItem[];
  payrollClassificationId: number = 0;

  prefixText: string;
  userList: SelectItem[];
  userId: number = 0;

  showOnlyActive: boolean = false;

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private router: Router) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.timeoffState.loadingSummaryList).subscribe(loadingSummaryList => {
        this.loadingSummaryList = loadingSummaryList;
      }),
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.canEdit = permissionIDs && !!permissionIDs.find(p => p === 3103);
        this.canViewSchedulingTimeOff = permissionIDs && !!permissionIDs.find(p => p === 186);
      }),
      this.store.select(s => s.timeoffState.campuses).subscribe(campuses => {
        this.campusList = campuses && campuses.length > 0 ? campuses
          : [{ label: 'loading...', value: undefined }];
      }),
      this.store.select(s => s.timeoffState.classifications).subscribe(classifications => {
        this.classificationList = classifications && classifications.length > 0 ? classifications
          : [{ label: 'loading...', value: undefined }];
      }),
      this.store.select(s => s.employeeState.employeeUsers).subscribe(users => {
        this.userList = users;
      }),
      this.store.select(s => s.timeoffState.summaryList).subscribe(summaryList => {
        this.summaries = summaryList;
      }),
    );

    this.store.dispatch(loadViewableCampuses());
    this.store.dispatch(loadClassifications());
  }

  reloadSummaries(event) {
    this.filter = event;
    this.store.dispatch(getSummaryList({
      filter: this.filter,
      campusId: this.campusId,
      payrollClassificationId: this.payrollClassificationId,
      userId: this.userId,
      showOnlyActive: this.showOnlyActive
    }));
  }

  getStaffList(event) {
    this.store.dispatch(loadEmployeeUsers({
      prefixText: event.query,
      programId: this.campusId,
      payrollClassificationId: this.payrollClassificationId,
      showInactive: !this.showOnlyActive,
      showTerminated: !this.showOnlyActive
    }));
  }

  reloadSummaryAfterStaffChage(selectedUser) {
    if (typeof selectedUser.value !== 'number') {
      return;
    }

    this.userId = selectedUser.value;
    this.store.dispatch(getSummaryList({
      filter: this.filter,
      campusId: this.campusId,
      payrollClassificationId: this.payrollClassificationId,
      userId: this.userId,
      showOnlyActive: this.showOnlyActive
    }));
  }

  reloadSummaryAfterClear(event) {
    if (event.data !== null) {
      return;
    }

    this.userId = 0;
    this.store.dispatch(getSummaryList({
      filter: this.filter,
      campusId: this.campusId,
      payrollClassificationId: this.payrollClassificationId,
      userId: this.userId,
      showOnlyActive: this.showOnlyActive
    }));
  }  
}
