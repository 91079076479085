<form class="card mt-2" *ngIf="canViewOnBoarding">
  <div class="card-body">
      <h2 class="mb-3">Onboarding Employees</h2>
      <h5>Filter</h5>
    <div class="row mb-1" >
      <div class="col-6">
        <div class="row mb-1" >
          <div class="col-4 d-flex align-items-center">
            <h6>Employee Legal First Name:</h6>
          </div>
          <div class="col-8 ">
            <input type="text" [(ngModel)]="employeeFirstNameFilter" class="form-control" (ngModelChange)="changedFilters()"  [ngModelOptions]="{standalone: true}">
          </div>
        </div>

        <div class="row mb-1" >
          <div class="col-4 d-flex align-items-center">
            <h6>Employee Legal Last Name:</h6>
          </div>
          <div class="col-8 ">
            <input type="text" [(ngModel)]="employeeLastNameFilter" class="form-control" (ngModelChange)="changedFilters()"  [ngModelOptions]="{standalone: true}">
          </div>
        </div>


        <div class="row mb-1">
          <div class="col-4 d-flex align-items-center">
            <h6>Show Separated Users:</h6>
          </div>
          <div class="col-8 ">
            <p-selectButton styleClass="w-100" [options]="yesNoAllOptions" [(ngModel)]="terminatedEmployeesFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"></p-selectButton>
          </div>
        </div>

        <div class="row mb-1">
          <div class="col-4 d-flex align-items-center">
            <h6>Show Inactive Users:</h6>
          </div>
          <div class="col-8 ">
            <p-selectButton styleClass="w-100" [options]="yesNoAllOptions" [(ngModel)]="inactiveUsersFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"></p-selectButton>
          </div>
        </div>

        <div class="row mb-1" >
          <div class="col-4 d-flex align-items-center">
            <h6>Orientation Done:</h6>
          </div>
          <div class="col-8 ">
            <p-selectButton styleClass="w-100" [options]="yesNoAllOptions" [(ngModel)]="finishedFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"></p-selectButton>
          </div>
        </div>
        <div class="row mb-1" >
          <div class="col-4 d-flex align-items-center">
            <h6>Orientation Date:</h6>
          </div>
          <div class="col-8 ">

            <p-calendar
              inputStyleClass="form-control mt-2"
              [monthNavigator]="true"
              [yearNavigator]="true"
              styleClass="w-75"
              panelStyleClass="calendarPanel"
              [hourFormat]="12"
              [yearRange]="'2010:2100'"
              [showTime]="false"
              [selectionMode]="'range'"
              [(ngModel)]="orientationDateFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"
              dataType="string"
            ></p-calendar>
          </div>
        </div>


      </div>
      <div class="col-6">

        <div class="row mb-1" >
          <div class="col-4 d-flex align-items-center">
            <h6>Consent:</h6>
          </div>
          <div class="col-8 ">
            <p-selectButton styleClass="w-100" [options]="yesNoAllOptions" [(ngModel)]="consentFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"></p-selectButton>
          </div>
        </div>
        <div class="row mb-1" >
          <div class="col-4 d-flex align-items-center">
            <h6>All Docs Uploaded:</h6>
          </div>
          <div class="col-8 ">
            <p-selectButton styleClass="w-100" [options]="yesNoAllOptions" [(ngModel)]="allUploadedFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"></p-selectButton>
          </div>
        </div>

        <div class="row mb-1" >
          <div class="col-4 d-flex align-items-center">
            <h6>Uploaded Docs Approved:</h6>
          </div>
          <div class="col-8 ">
            <p-selectButton styleClass="w-100" [options]="yesNoAllOptions" [(ngModel)]="uploadApprovedFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"></p-selectButton>
          </div>
        </div>
        <div class="row mb-1" >
          <div class="col-4 d-flex align-items-center">
            <h6>I-9 Form signed:</h6>
          </div>
          <div class="col-8 ">
            <p-selectButton styleClass="w-100" [options]="yesNoAllOptions" [(ngModel)]="i9SignedFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"></p-selectButton>
          </div>
        </div>
        <div class="row mb-1" >
          <div class="col-4 d-flex align-items-center">
            <h6>I-9 Form approved:</h6>
          </div>
          <div class="col-8 ">
            <p-selectButton styleClass="w-100" [options]="yesNoAllOptions" [(ngModel)]="i9ApprovedFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"></p-selectButton>
          </div>
        </div>

        <div class="row mb-1" >
          <div class="col-4 d-flex align-items-center">
            <h6>Employee Info filled:</h6>
          </div>
          <div class="col-8 ">
            <p-selectButton styleClass="w-100" [options]="yesNoAllOptions" [(ngModel)]="employeeInfoFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"></p-selectButton>
          </div>
        </div>

        <div class="row mb-1" >
          <div class="col-4 d-flex align-items-center">
            <h6>Sign Docs signed:</h6>
          </div>
          <div class="col-8 ">
            <p-selectButton styleClass="w-100" [options]="yesNoAllOptions" [(ngModel)]="allDocsSignedFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"></p-selectButton>
          </div>
        </div>

        <div class="row mb-1" >
          <div class="col-4 d-flex align-items-center">
            <h6>Sign Docs approved:</h6>
          </div>
          <div class="col-8 ">
            <p-selectButton styleClass="w-100" [options]="yesNoAllOptions" [(ngModel)]="allDocsApprovedFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"></p-selectButton>
          </div>
        </div>
      </div>
    </div>
    <i *ngIf="loadingOnBoardingUsers" class="material-icons rotate text-muted">loop</i>
    <p-table  [value]="onBoardingUsers?.result" [responsive]="true"
    [paginator]="true" [rows]="25" [totalRecords]="onBoardingUsers?.totalRecords" [lazy]="true"
    (onLazyLoad)="loadPage($event)"  [loading]="loading">
      <ng-template pTemplate="header">
        <tr>
          <th width="110px" [pSortableColumn]="'OrientationDone'"> Orientation Done <p-sortIcon [field]="'OrientationDone'"></p-sortIcon></th>
          <th width="85px"> References </th>
          <th width="190px" [pSortableColumn]="'EmployeeName'">Employee <p-sortIcon [field]="'EmployeeName'"></p-sortIcon></th>
          <th width="80px"> Consent </th>
          <th width="150px"> Uploaded Docs </th>
          <th width="150px"> I-9 Form</th>
          <th width="90px">Employee Info</th>
          <th width="130px"> Sign Docs</th>
          <th width="150px" [pSortableColumn]="'OrientationDate'">Orientation Date <p-sortIcon [field]="'OrientationDate'"></p-sortIcon></th>
        </tr>
      </ng-template>
        <ng-template pTemplate="body" let-onBoardingUser>
            <tr >
              <td>
                <span class="ui-column-title">Orientation Done</span>
                <div class=" p-2 ">
                  <div class="w-100 d-flex justify-content-center">
                    <i class="fas fa-check-circle text-success" title="Orientation done" *ngIf="onBoardingUser.onboarding?.showedUpOnOrientation"></i>
                    <i class="fas fa-dot-circle"  title="Orientation not done" *ngIf="!onBoardingUser.onboarding?.showedUpOnOrientation"></i>
                  </div>
                  <button class="btn btn-primary btn-sm mt-1" *ngIf="showCompleteHRButton(onBoardingUser)"
                  (click)="openCompleteHR(onBoardingUser)" >Complete HR File</button>
                </div>
              </td>

              <td>
                <span class="ui-column-title">References</span>
                <span class="pointer mr-2" *ngIf="onBoardingUser.prospectiveCandidateID"  (click)="seeReferences(onBoardingUser)"><i title="See references" class="fas fa-people-arrows"></i></span>
            </td>
                <td>
                    <span class="ui-column-title">Employee</span>
                    <span class="pointer mr-2"   (click)="seeUserDetails(onBoardingUser)"><i title="See employee" class="fas fa-edit"></i></span>
                    <span class="pointer" (click)="seeUserDetails(onBoardingUser)">{{onBoardingUser.user.firstName }} {{onBoardingUser.user.lastName }}</span>
                    <span style="color: red;" *ngIf="onBoardingUser.employee?.inRehireProcess"> (Rehire)</span>
                </td>
                <td>
                  <span class="ui-column-title">Consent</span>
                  <i class="fas fa-check-circle text-success" *ngIf="onBoardingUser.onboarding?.consentDate"></i>
                  <i class="fas fa-dot-circle" *ngIf="!onBoardingUser.onboarding?.consentDate"></i>
                </td>

                <td>
                  <span class="ui-column-title">Uploaded Docs</span>
                  <div>
                    <div class="d-flex p-2 align-items-center">
                      <i class="fas fa-check-circle text-success" *ngIf="onBoardingUser.allUploaded"></i>
                      <i class="fas fa-dot-circle" *ngIf="!onBoardingUser.allUploaded"></i>
                      &nbsp;&nbsp; All Uploaded
                    </div>

                    <div class="d-flex p-2 align-items-center">
                      <i class="fas fa-check-circle text-success" *ngIf="onBoardingUser.onboarding?.uploadedDocsApprovedDate"></i>
                      <i class="fas fa-dot-circle" *ngIf="!onBoardingUser.onboarding?.uploadedDocsApprovedDate"></i>
                      &nbsp;&nbsp; Approved
                    </div>
                  </div>
                </td>
                <td>
                  <span class="ui-column-title">I-9 Form</span>
                  <div>
                    <div class="d-flex p-2 align-items-center">

                      <i class="fas fa-check-circle text-success" *ngIf="onBoardingUser.i9FormSigned"></i>
                      <i class="fas fa-dot-circle" *ngIf="!onBoardingUser.i9FormSigned"></i>
                      &nbsp;&nbsp; Signed
                    </div>

                    <div class="d-flex p-2 align-items-center">

                      <i class="fas fa-check-circle text-success" *ngIf="onBoardingUser.onboarding?.i9ApprovedDate"></i>
                      <i class="fas fa-dot-circle" *ngIf="!onBoardingUser.onboarding?.i9ApprovedDate"></i>
                      &nbsp;&nbsp; Approved
                    </div>
                  </div>
                </td>

                <td>
                  <span class="ui-column-title">Employee Info</span>
                  <div>
                    <div class="d-flex p-2 align-items-center">
                      <i class="fas fa-check-circle text-success" *ngIf="onBoardingUser.onboarding?.employeeInfoFilled"></i>
                      <i class="fas fa-dot-circle" *ngIf="!onBoardingUser.onboarding?.employeeInfoFilled"></i>
                    </div>
                  </div>
                </td>


                <td>
                  <span class="ui-column-title">Sign Docs</span>
                  <div>
                    <div class="d-flex p-2 align-items-center">

                      <i class="fas fa-check-circle text-success" *ngIf="onBoardingUser.allDocsSigned"></i>
                      <i class="fas fa-dot-circle" *ngIf="!onBoardingUser.allDocsSigned"></i>
                      &nbsp;&nbsp;   All Signed
                    </div>

                    <div class="d-flex p-2 align-items-center">

                      <i class="fas fa-check-circle text-success" *ngIf="onBoardingUser.onboarding?.allDocumentsSignedApprovedDate"></i>
                      <i class="fas fa-dot-circle" *ngIf="!onBoardingUser.onboarding?.allDocumentsSignedApprovedDate"></i>
                      &nbsp;&nbsp; Approved
                    </div>
                  </div>
                </td>


                <td>
                  <span class="ui-column-title">Orientation Date</span>
                  <div>
                    <div class="p-2 align-items-center">
                      <div>{{onBoardingUser?.employee?.orientationDate | date:'shortDate'}}</div>
                      <div class="badge-danger rounded p-2 mb-1" *ngIf="onBoardingUser.onboarding?.rejectOrientationDate">Orientation Date Rejected</div>
                     </div>
                  </div>
                </td>

            </tr>
        </ng-template>
    </p-table>
  </div>
</form>

<p-dialog header="Complete HR File" [modal]="true" appendTo="body" [style]="{width: '70vw'}" [(visible)]="displayCompleteHRDialog">
  <div class="container">
    <div div class="row">
        <div class="col-4 d-flex align-items-center">
          <b>e-Verify Completion Date</b>
        </div>
        <div class="col-8">
          <p-calendar
          inputStyleClass="form-control mt-2"
          [monthNavigator]="true"
          [yearNavigator]="true"
          styleClass="w-100"
          panelStyleClass="calendarPanel"
          appendTo="body"
          [hourFormat]="12"
          [yearRange]="'2010:2100'"
          [showTime]="false"
          [(ngModel)]="eVerifyCompletionDate" placeholder="Select date..."
          [ngModelOptions]="{standalone: true}"
          dataType="string"
          ></p-calendar>
        </div>
    </div>
  </div>

  <div class="text-right mt-2">
    <div class="col-12" >
      <button class="btn btn-secondary mr-2" (click)="closeCompleteHR()">Cancel</button>
      <button class="btn btn-primary" [disabled]="!eVerifyCompletionDate" (click)="completeHR(onBoardingUserForCompleteHR)">Save</button>
    </div>
  </div>
</p-dialog>
