import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SelectItem } from 'primeng/api';
import { tap } from 'rxjs/operators';
import { loadSelfcareOverSightEmployees } from 'src/app/core/store/selfcare/selfcare.actions';
import { SelfCareEmployeeModel } from 'src/app/models/selfcareModels';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { DropdownFilterService } from '../../../../core/services/dropdown-filter.service';
import { State } from '../../../../core/store';
import { getCompletedKraList, saveMeetsExpectations, saveMeetsExpectationsSuccess } from '../../../../core/store/employee-review/employee-review.actions';
import { loadEmployeeUsers, loadViewableCampuses } from '../../../../core/store/employee/employee.action';
import { deepClone, getDateWithOffset } from '../../../../helpers/utils';
import { DateRangeModel } from '../../../../models/dateRangeModel';
import { CompletedKraModel } from '../../../../models/employeeReviewModels';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';

@Component({
  selector: 'app-selfcare-oversight',
  templateUrl: './selfcare-oversight.component.html',
  styleUrls: ['./selfcare-oversight.component.scss']
})
export class SelfcareOversightComponent extends BaseComponent implements OnInit {
  list: SelfCareEmployeeModel[];
  filter: any;
  loadingList = true;

  canViewSelfCareReport;

  constructor(
    private router: Router,
    private actions$: Actions,
    private store: Store<State>,
    private dropdownFilterService: DropdownFilterService) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.selfcareState.loadingOversightList).subscribe(loadingOversightList => {
        this.loadingList = loadingOversightList;
      }),
      this.store.select(s => s.selfcareState.oversightList).subscribe(oversightList => {
        this.list = oversightList;
      }),
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.canViewSelfCareReport = permissionIDs && !!permissionIDs.find(p => p === 3086); //View Self Care Report
      }),
    );

    this.store.dispatch(loadSelfcareOverSightEmployees());
  }


  openSelfcarePlanner(user) {
    this.openLink(`/selfcare/oversight/${user.id}/main`);
  }

  openLink(path) {
    this.router.navigate([path]);
  }

}
