import { Component, OnInit } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { BaseComponent } from '../../../../../core/abstracts/baseComponent';
import { State } from '../../../../../core/store';
import { deleteEthicCode, deleteEthicCodeSuccess, loadEthicCodes, saveEthicCodeSuccess, showEthicCodeModal } from '../../../../../core/store/ethic-code/ethic-code.actions';
import { EthicCodeModel } from '../../../../../models/ethicCodeModels';
import { AlertService } from '../../../../../shared/services/alert.service';

@Component({
  selector: 'app-list-ethic-code',
  templateUrl: './list-ethic-code.component.html',
  styleUrls: ['./list-ethic-code.component.scss']
})
export class ListEthicCodeComponent extends BaseComponent implements OnInit {

  loading: boolean = false;
  ethicsCodes: EthicCodeModel[];

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private alertService: AlertService  ) {
    super();
  }

  ngOnInit() {
    this.ethicsCodes = null;

    this.subs.push(
      this.store.select(s => s.ethicCodeState.ethicCodes).subscribe(ethicCodes => {
        this.ethicsCodes = ethicCodes;
      }),
      
      this.actions$.pipe(
        ofType(saveEthicCodeSuccess),
        tap(action => {
          this.reload();
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(deleteEthicCodeSuccess),
        tap(action => {
          this.reload();
        })
      ).subscribe(),
    );
    this.reload();
  }

  reload() {
    this.store.dispatch(loadEthicCodes({}));
  }

  addEthicCode() {
    this.store.dispatch(showEthicCodeModal({
      ethicCode: {
        section: '',
        description: ''
      }
    }));
  }

  editEthicCode(ethicCode) {
    this.store.dispatch(showEthicCodeModal({ ethicCode: ethicCode }));
  }

  deleteEthicCode(ethicCode) {
    
      this.alertService.confirm(`Delete Ethics Code?.`, `It will remove from all templates were it is being used.`)
        .subscribe(
          answer => {
            if (answer) {
              this.store.dispatch(deleteEthicCode({ ethicCodeId: ethicCode.id }));
            } 
          }, error => {
          }
    );

    
  }

}
