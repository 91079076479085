<app-selfcare-oversight-header></app-selfcare-oversight-header>

<div class="row justify-content-center">
  <div class="col" style="max-width: 800px">
    <div class="card mt-2" >
      <div class="card-body ">
        <div class="d-flex text-center flex-column-u800">
          <div class="p-2">
            <img id="img_ID" class="w-100" src="/assets/img/selfcare.png" alt="SelfCare" usemap="#workmap">
            <map id="map_ID" name="workmap" class="w-100">
              <area shape="poly" coords="625,519,793,586,598,788,526,620" alt="" [href]="getCategoryLink(1)">
              <area shape="poly" coords="509,630,581,797,301,802,368,630" alt="" [href]="getCategoryLink(2)">
              <area shape="poly" coords="348,623,280,793,78,597,247,525" alt="" [href]="getCategoryLink(3)">
              <area shape="poly" coords="240,507,71,580,69,300,238,368" alt="" [href]="getCategoryLink(4)">
              <area shape="poly" coords="246,349,76,282,270,84,343,250" alt="" [href]="getCategoryLink(5)">
              <area shape="poly" coords="271,30,582,30,504,237,360,237" alt="" [href]="getCategoryLink(6)">
              <area shape="poly" coords="589,74,789,271,620,343,519,245" alt="" [href]="getCategoryLink(7)">
              <area shape="poly" coords="630,362,795,290,801,570,633,504" alt="" [href]="getCategoryLink(8)">
              <area shape="circle" coords="440,438,385" alt="" [href]="getPlannerLink()">
            </map>
          </div>
          <div class="p-2 d-flex justify-content-center">
            <div class="d-flex flex-column">
              <div class="personal-attributes-panel">
                <div class="personal-attributes-header">
                  <div class="w-100 text-left ">Personal </div>
                  <div class="w-100 text-right "> Attributes</div>
                </div>
                <div class="p-2 text-center">
                  <img src="/assets/img/preferences.png" class="personal-attributes-img pointer" [routerLink]="[getCategoryLink('preferences')]">
                  <div class="personal-attributes-link pointer" [routerLink]="[getCategoryLink('preferences')]">
                    Preferences
                  </div>
                </div>
                <div class="p-2 text-center" *ngIf="!this.userID">
                  <img src="/assets/img/assessments.png" class="personal-attributes-img pointer" [routerLink]="['/uploaddocs']" [queryParams]="{scrollTo:10}">
                  <div class="personal-attributes-link pointer"  [routerLink]="['/uploaddocs']" [queryParams]="{scrollTo:10}">
                    Assessments
                  </div>
                </div>
              </div>
              <div class="p-2">
                <img src="/assets/img/roughday.png" class="personal-attributes-img-big pointer" [routerLink]="[getPlannerLink()]"  [queryParams]="{scrollTo:7}">

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
