<div class="card mt-2" >
  <div class="card-body">
    <button  class="btn btn-secondary float-right pointer" (click)="newBenefitClassInfo()">New Class</button>
    <h2 class="mb-3">Benefit Classes</h2>
    <div class="row">
      <div class="col-12">

        <i *ngIf="loadingBenefitClasses" class="material-icons rotate text-muted">loop</i>
        <p-table  [value]="benefitClasses" [responsive]="true" >
            <ng-template pTemplate="header">
                <tr>
                    <th width="120px"></th>
                    <th [pSortableColumn]="'Class'" >Class
                        <p-sortIcon [field]="'Class'"></p-sortIcon></th>

                    <th [pSortableColumn]="'EmployerContribution'">Employer Contribution
                      <p-sortIcon [field]="'EmployerContribution'"></p-sortIcon>
                    </th>


                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-benefitClass>
                <tr >
                    <td>
                      <span class="pointer mr-2" (click)="editBenefitClass(benefitClass)">
                        <i title="Edit Class" class="fas fa-edit"></i>
                      </span>

                      <span class="pointer mr-2" (click)="deleteBenefitClass(benefitClass)">
                        <i title="Delete Benefit Class" class="fas fa-trash"></i>
                      </span>
                    </td>
                    <td>
                        <span class="ui-column-title">Class</span>
                      {{benefitClass.class }}
                    </td>

                    <td>
                        <span class="ui-column-title">Employer Contribution</span>
                      {{benefitClass.employerContribution }}
                    </td>

                </tr>
            </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>


<p-dialog [header]="(benefitClassInfo.id?'Edit':'Add')+ ' Class'" [focusTrap]="false" [modal]="true" [styleClass]="'top-dialog'"
          [style]="{width: '70vw'}" [(visible)]="openClassInfo">

            <form class="" [formGroup]="form" *ngIf="form && benefitClassInfo">
              <div class="row m-0">
                <div class="col-4 d-flex align-items-center">
                  <b>Class Name</b>
                </div>
                <div class="col-8 ">
                    <input id="classNameInput" type="text" class="form-control mt-2" formControlName="class"/>
                    <app-validation-message [form]="form" [path]="'class'" [name]="'class'" [label]="'Class'"> </app-validation-message>
                </div>

                <div class="col-4 d-flex align-items-center">
                  <b>Employer Contribution (per period)</b>
                </div>
                <div class="col-8 ">
                    <input id="employerContributionInput" type="number" class="form-control mt-2" formControlName="employerContribution"/>
                    <app-validation-message [form]="form" [path]="'employerContribution'" [name]="'employerContribution'" [label]="'Employer Contribution'"> </app-validation-message>
                </div>




              </div>

              <button class="btn btn-primary mt-3" id="saveButton" [class.disabled]="form.invalid" (click)="save()">Save</button>
            </form>
</p-dialog>
