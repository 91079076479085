<div class="container-fluid page" *ngIf="!isIframeInOpto">
  <app-nav-menu *ngIf="showTopbar"></app-nav-menu>
  <div class="d-flex flex-column container-fluid body" [class.notopbar]="!showTopbar">
    <app-alert></app-alert>
    <div class="body-content p-3">
      <router-outlet></router-outlet>
    </div>
    <div class="p-1" *ngIf="generalSettings && !isIframeInOpto">
      <div class="col text-center" style="margin-top: 25px">
        <a [href]="generalSettings?.businessWebsite" target="_blank">
          <img [src]="generalSettings?.businessLogoUrl" height="40" [alt]="generalSettings?.businessFullName">
        </a>
        <h5>HR Portal</h5>
        <br />
        <footer>
          <small>&copy; 2022 Meridian Technologies Inc. All Rights Reserved</small>
        </footer>
      </div>
    </div>
  </div>
</div>
<div class="m-0 p-0" *ngIf="isIframeInOpto">
  <div class="m-0 p-1">
    <router-outlet></router-outlet>
  </div>
</div>



