import { createReducer, on, Action } from '@ngrx/store';
import { PagingResultsModel } from '../../../models/pagingResultsModel';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { formatDate } from '@angular/common';
import { getFormattedTime } from '../../../helpers/utils';
import * as moment from 'moment';
import { FormError } from '../../../models/utilModels';
import { SelectItem } from 'primeng/api';
import { errorHappened } from '../profile/profile.actions';
import { EmployeeReviewTemplateCategoryModel, EmployeeReviewTemplateKPIModel, EmployeeReviewTemplateModel, EmployeeReviewTemplateScoreOptionsSet, EmployeeReviewTemplateScoreType, EmployeeReviewTemplateValueColorModel } from '../../../models/employeeReviewTemplateModels';
import {
    addAndCloseKpiEthicsCodes,
    closeKpiEthicsCodes,
  deleteEmployeeReviewTemplateDraft, deleteEmployeeReviewTemplateDraftFailure, deleteEmployeeReviewTemplateDraftSuccess, findEmployeeReviewTemplateDraft, findEmployeeReviewTemplateDraftFailure, findEmployeeReviewTemplateDraftSuccess,
  getValueColors, getValueColorsSuccess, loadCategories, loadCategoriesSuccess, loadTemplates, loadTemplatesSuccess,
  saveEmployeeReviewTemplate, saveEmployeeReviewTemplateFailure, saveEmployeeReviewTemplateSuccess, updateValueColorForm, showKpiEthicsCodes, getScoreOptionsSets, getScoreOptionsSetsSuccess, getScoreTypes, getScoreTypesSuccess,
  toggleTemplateArchived, toggleTemplateArchivedFail, toggleTemplateArchivedSuccess
} from './employee-review-template.actions';

export interface EmployeeReviewTemplateState {
  templates: PagingResultsModel<EmployeeReviewTemplateModel>;
  categories: EmployeeReviewTemplateCategoryModel[];
  filter: any;
  loading: boolean;
  valueColors: EmployeeReviewTemplateValueColorModel[];
  valueColorInfo: EmployeeReviewTemplateValueColorModel;
  formErrors: FormError[];
  addingEthicsCodesToKPI: EmployeeReviewTemplateKPIModel;
  scoreTypes: EmployeeReviewTemplateScoreType[];
  scoreOptionsSet: EmployeeReviewTemplateScoreOptionsSet[];
}

const initialState: EmployeeReviewTemplateState = {
  templates: null,
  categories: null,
  filter: {},
  loading: true,
  valueColors: [],
  valueColorInfo: null,
  formErrors: [],
  addingEthicsCodesToKPI: null,
  scoreTypes: [],
  scoreOptionsSet: []
};

export const employeeReviewTemplateReducer = createReducer(initialState,
  on(loadTemplates, (state, action) => {
    return {
      ...state,
      filter: action.filter,
      loading: true
    };
  }),
  on(loadTemplatesSuccess, (state, action) => {
    return {
      ...state,
      templates: action.employeeReviewTemplates,
      loading: false

    };
  }),
  on(toggleTemplateArchived, (state, action) => {
    return {
      ...state,
      loading: true
    };
  }),
  on(toggleTemplateArchivedSuccess, (state, action) => {
    return {
      ...state,
      loading: false
    };
  }),
  on(toggleTemplateArchivedFail, (state, action) => {
    return {
      ...state,
      loading: false
    };
  }),
  on(loadCategories, (state, action) => {
    return {
      ...state,
      loading: true
    };
  }),
  on(loadCategoriesSuccess, (state, action) => {
    return {
      ...state,
      categories: action.categories,
      loading: false

    };
  }),

  on(saveEmployeeReviewTemplate, (state, action) => {
    return {
      ...state,
      loading: true
    }
  }),

  on(saveEmployeeReviewTemplateSuccess, (state, action) => {
    return {
      ...state,
      loading: false
    }
  }),

  on(deleteEmployeeReviewTemplateDraft, (state, action) => {
    return {
      ...state,
      loading: true
    }
  }),

  on(deleteEmployeeReviewTemplateDraftSuccess, (state, action) => {
    return {
      ...state,
      loading: false
    }
  }),
  on(deleteEmployeeReviewTemplateDraftFailure, (state, action) => {
    return {
      ...state,
      loading: false
    }
  }),


  on(findEmployeeReviewTemplateDraft, (state, action) => {
    return {
      ...state,
      loading: true
    }
  }),

  on(findEmployeeReviewTemplateDraftSuccess, (state, action) => {
    return {
      ...state,
      loading: false
    }
  }),
  on(findEmployeeReviewTemplateDraftFailure, (state, action) => {
    return {
      ...state,
      loading: false
    }
  }),

  on(saveEmployeeReviewTemplateFailure, (state, action) => {
    return {
      ...state,
      loading: false
    }
  }),

  on(getValueColors, (state, action) => {
    return {
      ...state,
      loading: true
    };
  }),
  on(getValueColorsSuccess, (state, action) => {
    return {
      ...state,
      valueColors: action.valueColors,
      loading: false

    };
  }),
  on(updateValueColorForm, (state, action) => {
    const newValueColor = { ...state.valueColorInfo, ...action.values };
    const newState = { ...state, ...{ valueColorInfo: newValueColor, formErrors: action.formErrors } };
    return newState;
  }),
  on(getScoreOptionsSets, (state, action) => {
    return {
      ...state,
      loading: true
    };
  }),
  on(getScoreOptionsSetsSuccess, (state, action) => {
    return {
      ...state,
      scoreOptionsSet: action.scoreOptionsSets,
      loading: false

    };
  }),
  on(getScoreTypes, (state, action) => {
    return {
      ...state,
      loading: true
    };
  }),
  on(getScoreTypesSuccess, (state, action) => {
    return {
      ...state,
      scoreTypes: action.scoreTypes,
      loading: false

    };
  }),
  on(showKpiEthicsCodes, (state, action) => {
    return {
      ...state,
      addingEthicsCodesToKPI: action.kpi
    };
  }),
  on(closeKpiEthicsCodes, (state, action) => {
    return {
      ...state,
      addingEthicsCodesToKPI: null
    };
  }),
  on(addAndCloseKpiEthicsCodes, (state, action) => {
    return {
      ...state,
      addingEthicsCodesToKPI: null
    };
  }),

);

export function employeeReviewTemplateReducerFunc(state: EmployeeReviewTemplateState | undefined, action: Action) {
  return employeeReviewTemplateReducer(state, action);
}




export function initValueColorForm(valueColor: EmployeeReviewTemplateValueColorModel) {

  const form = new FormGroup({
    id: new FormControl(valueColor.id),
    valueName: new FormControl(valueColor.valueName, [Validators.required]),
    colorRGB: new FormControl(valueColor.colorRGB, [Validators.required, Validators.maxLength(7)]),
  });

  return form;
}
