<p-dialog [(visible)]="display" header="{{title}}" [modal]="true" [style]="{width: '70vw'}" (onHide)="closeModal()" [focusOnShow]="false">
  <form class="card mt-2" [formGroup]="form" (ngSubmit)="saveSubstatus()">
    <div class="card-body">
      <div class="row">
        <div class="col-12">

          <div class="row mb-2">
            <div class="col-4 d-flex align-items-center">
              <b>Substatus</b>
            </div>
            <div class="col-8">
              <input type="text" class="form-control mt-2" formControlName="substatus" />
              <app-validation-message [form]="form" [path]="'substatus'" [label]="'Substatus'"></app-validation-message>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-4 d-flex align-items-center">
              <b>Primary Status</b>
            </div>
            <div class="col-8">
              <p-dropdown appendTo="body" styleClass="form-control w-100" [options]="applicationStatuses" formControlName="primaryStatusId"></p-dropdown>
              <app-validation-message [form]="form" [path]="'primaryStatusId'" [label]="'Primary Status'"></app-validation-message>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-4 d-flex align-items-center">
            </div>
            <div class="col-8">
              <p-checkbox formControlName="archived" [binary]="true"></p-checkbox>
              <b style="margin-left: 2px;">Archived</b>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </form>
  <p-footer>
    <i class="material-icons rotate text-muted ml-2" *ngIf="disableButtons">loop</i>
    <button class="btn btn-danger" *ngIf="substatusId > 0" type="submit" (click)="deleteSubstatus()" [disabled]="disableButtons">Delete</button>
    <button class="btn btn-secondary" type="button" (click)="closeModal()">Cancel</button>
    <button class="btn btn-primary" type="submit" (click)="saveSubstatus()" [disabled]="disableButtons">Save</button>
  </p-footer>
</p-dialog>
