<i *ngIf="!this.form" class="material-icons rotate text-muted">loop</i>
<form class="card mt-2" [formGroup]="form" *ngIf="this.form">
  <div class="card-body">
    <h4 class="mb-3">Request Med Clinic Services</h4>
    <div class="row">
      <div class="col-12 col-xl-6 ">


        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Relationship</b>
          </div>
          <div class="col-8">
            <p-dropdown styleClass="form-control mt-2 w-100" [options]="relationshipOptions" (onChange)="relationshipChange($event)" placeholder="Select relationship..." formControlName="relationshipToClient"></p-dropdown>
            <app-validation-message [form]="form" [path]="'relationshipToClient'" [name]="'relationshipToClient'" [label]="'Relationship To Client'"> </app-validation-message>
          </div>
        </div>

        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Birthdate</b>
          </div>
          <div class="col-8">
            <input type="date" class="form-control mt-2" formControlName="birthdate" />
            <app-validation-message [form]="form" [path]="'birthdate'" [name]="'birthdate'" [label]="'Birthdate'"> </app-validation-message>
          </div>
        </div>

        <div class="row" >
          <div class="col-4 d-flex align-items-center">
            <b>Personal Email</b>
            <i class="fas fa-info-circle ml-2" [escape]="false" pTooltip="Family members 18 years of age or older require their own email address and client portal login."></i>
          </div>
          <div class="col-8">
            <input type="text" class="form-control mt-2" formControlName="email" />
               <app-validation-message [form]="form" [path]="'email'" [name]="'email'" [label]="'Email'"> </app-validation-message>
          </div>
        </div>

        <ng-container *ngIf="this.form.get('relationshipToClient').value !== 'Self'">
          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>First Name</b>
            </div>
            <div class="col-8">
              <input type="text" class="form-control mt-2" formControlName="firstName" />
              <app-validation-message [form]="form" [path]="'firstName'" [name]="'firstName'" [label]="'First Name'"> </app-validation-message>
            </div>
          </div>

          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Last Name</b>
            </div>
            <div class="col-8">
              <input type="text" class="form-control mt-2" formControlName="lastName" />
              <app-validation-message [form]="form" [path]="'lastName'" [name]="'lastName'" [label]="'Last Name'"> </app-validation-message>
            </div>
          </div>

          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Gender</b>
            </div>
            <div class="col-8">
              <p-dropdown styleClass="form-control mt-2 w-100" [options]="genderOptions" placeholder="Select gender..." formControlName="gender"></p-dropdown>
              <app-validation-message [form]="form" [path]="'gender'" [name]="'gender'" [label]="'Gender'"> </app-validation-message>
            </div>
          </div>
        </ng-container>

        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Services</b>
          </div>
          <div class="col-8 pt-2 pb-2">
            <p-checkbox    formControlName="isMedClinic" [binary]="true" ></p-checkbox>
            Primary Care
            <p-checkbox class="ml-3" formControlName="isPsychMed" [binary]="true" ></p-checkbox>
            Psych Med
            <app-validation-message [form]="form" [path]="'isMedClinic'" [name]="'isMedClinic'" [label]="'Med Clinic'"> </app-validation-message>
          </div>
        </div>
        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Reason for inquiry</b>
          </div>
          <div class="col-8">
            <textarea  class="form-control mt-2" formControlName="reasonForInquiry" ></textarea>
            <app-validation-message [form]="form" [path]="'reasonForInquiry'" [name]="'reasonForInquiry'" [label]="'ReasonForInquiry'"> </app-validation-message>
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-6 ">



        <ng-container >

          <div class="row">
            <div class="col-12 ">
              <h4>Insurance Provider (Optional)</h4>
              <small>You can optionally add an additional insurance provider to be used for your Med Clinic services.</small>
            </div>
          </div>
          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Insurance Provider</b>
            </div>
            <div class="col-8">
              <p-dropdown styleClass="form-control mt-2 w-100" [options]="insuranceProvidersOptions"  placeholder="Select provider..." formControlName="insuranceProviderID"></p-dropdown>
                <app-validation-message [form]="form" [path]="'insuranceProviderID'" [name]="'insuranceProviderID'" [label]="'Insurance Provider'"> </app-validation-message>
            </div>
          </div>
          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Policy Holder First Name</b>
            </div>
            <div class="col-8">
              <input type="text" class="form-control mt-2" formControlName="policyHolderFirstName" />
              <app-validation-message [form]="form" [path]="'policyHolderFirstName'" [name]="'policyHolderFirstName'" [label]="'Policy Holder First Name To Client'"> </app-validation-message>
            </div>
          </div>
            <div class="row">
              <div class="col-4 d-flex align-items-center">
                <b>Policy Holder Last Name</b>
              </div>
              <div class="col-8">
                <input type="text" class="form-control mt-2" formControlName="policyHolderLastName" />
                <app-validation-message [form]="form" [path]="'policyHolderLastName'" [name]="'policyHolderLastName'" [label]="'Policy Holder Last Name'"> </app-validation-message>
              </div>
            </div>

            <div class="row">
              <div class="col-4 d-flex align-items-center">
                <b>Policy Holder's Birth Date</b>
              </div>
              <div class="col-8">
                <input type="date" class="form-control mt-2" formControlName="policyHolderDoB" />
                <app-validation-message [form]="form" [path]="'policyHolderDoB'" [name]="'policyHolderDoB'" [label]="'Birthdate'"> </app-validation-message>
              </div>
            </div>

            <div class="row">
              <div class="col-4 d-flex align-items-center">
                <b>Member ID</b>
              </div>
              <div class="col-8">
                <input type="text" class="form-control mt-2" formControlName="memberID" />
                <app-validation-message [form]="form" [path]="'memberID'" [name]="'memberID'" [label]="'Member ID'"> </app-validation-message>
              </div>
            </div>
            <div class="row">
              <div class="col-4 d-flex align-items-center">
                <b>Group ID</b>
              </div>
              <div class="col-8">
                <input type="text" class="form-control mt-2" formControlName="policyNumber" />
                <app-validation-message [form]="form" [path]="'policyNumber'" [name]="'policyNumber'" [label]="'Group ID'"> </app-validation-message>
              </div>
            </div>

            <div class="row">
              <div class="col-4 d-flex align-items-center">
                <b>Start Date</b>
              </div>
              <div class="col-8">
                <input type="date" class="form-control mt-2" formControlName="startDate" />
                <app-validation-message [form]="form" [path]="'startDate'" [name]="'startDate'" [label]="'Start Date'"> </app-validation-message>
              </div>
            </div>

            <div class="row">
              <div class="col-4 d-flex align-items-center">
                <b>Expiration Date</b>
              </div>
              <div class="col-8">
                <input type="date" class="form-control mt-2" formControlName="expirationDate" />
                <app-validation-message [form]="form" [path]="'expirationDate'" [name]="'expirationDate'" [label]="'Expiration Date'"> </app-validation-message>
              </div>
            </div>
          </ng-container>
      </div>
    </div>
    <div class="row">
      <div class="mt-4 float-right">
        <i *ngIf="creatingPatient" class="material-icons rotate text-muted">loop</i>
        <button *ngIf="!creatingPatient" class="btn btn-primary" [class.disabled]="form.invalid" (click)="save()">Save</button>
      </div>
    </div>
  </div>
</form>
