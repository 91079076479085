import { AlertService } from 'src/app/shared/services/alert.service';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { PayrollItemModel, PayableItemModel } from 'src/app/models/payableItem';
import { getTimeFormatted, deepClone, getShortDateFormatted } from 'src/app/helpers/utils';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { changeClientServiceNoteToManualEntry } from '../../../../core/store/payroll-approval/payroll-approval.actions';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { TimeOffModel } from '../../../../models/timeoffModel';

@Component({
  selector: 'app-payroll-items-table',
  templateUrl: './payroll-items-table.component.html',
  styleUrls: ['./payroll-items-table.component.scss']
})
export class PayrollItemsTableComponent extends BaseComponent implements OnInit {

  @Input() set payrollItems(value: PayrollItemModel[]) {
    if (value) {
      let totalTimeMinutes = 0;
      value.forEach(payrollItem => {
        totalTimeMinutes += payrollItem.payableItem.timeDiffMinutes;
      });
      this.totalTime = getTimeFormatted(totalTimeMinutes);
    } else {
      this.totalTime = '';
    }
    this._payrollItems = deepClone(value);
  }

  get payrollItems() {
    return this._payrollItems;
  }

  // tslint:disable-next-line:variable-name
  _payrollItems: PayrollItemModel[];

  @Input() payableType: string;
  @Input() title: string;
  @Input() selectable: boolean;
  @Input() adjustmentView: boolean;

  @Output() selectedItemsChanged = new EventEmitter<PayrollItemModel[]>();
  @Output() adjustmentEditClicked = new EventEmitter<PayrollItemModel[]>();
  @Output() payableItemEditClicked = new EventEmitter<PayrollItemModel>();
  @Output() ptoEditClicked = new EventEmitter<TimeOffModel>();



  totalTime: string;
  selectedItems = [];
  allSelected: boolean;
  today = new Date();
  days180Ago = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() - 180);
  canEditItems: boolean;


  constructor(
    private store: Store<State>,
    private alertService: AlertService) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.canEditItems = permissionIDs && !!permissionIDs.find(p => p === 3126); //Can edit payable item date/times/assign PTO
      }),     
    );
  }

  changedSelection() {
    this.selectedItems = this.payrollItems.filter(p => (p as any).selected);
    this.allSelected = this.payrollItems.every(p => ((p as any).selected));
    this.selectedItemsChanged.emit(this.selectedItems);
  }

  getShortDateFormatted(date) {
    return getShortDateFormatted(date);
  }

  selectPayrollItem(payrollItems) {
    this.payrollItems.forEach(element => {
      (element as any).selected = false;
    });

    payrollItems.selected = true;
    this.changedSelection();

    this.adjustmentEditClicked.emit(this.selectedItems);
  }

  editSelectedPayableItem(payrollItem) {
    this.payableItemEditClicked.emit(payrollItem);
  }

  editTimeOffRequest(timeOffRequest) {
    this.ptoEditClicked.emit(timeOffRequest);
  }

  changedAllSelected() {
    if (this.allSelected) {
      this.payrollItems.forEach(p => {
        if (!p.payableItem.needsDirectorApproval || p.payableItem.hasDirectorApproval) {
          (p as any).selected = true;
        }
      });
      this.changedSelection();
    } else {
      this.payrollItems.forEach(p => {
        if (!p.payableItem.needsDirectorApproval || p.payableItem.hasDirectorApproval) {
          (p as any).selected = false;
        }
      });
      this.changedSelection();
    }
  }

  getColspan() {
    let colspan = 3;
    if (!this.payableType) {
      colspan++;
    }
    if (this.selectable) {
      colspan++;
    }
    if (this.adjustmentView) {
      colspan++;
    }
    if (this.payableType === 'Manual Entry') {
      colspan++;
    }
    return colspan;
  }

  showOldFlag(dateString) {
    return new Date(dateString) < this.days180Ago;
  }

  changeClientServiceNoteToManualEntry(payableItem: PayableItemModel) {
    this.alertService.confirm('Change to Manual Entry',
      'Are you sure you want to change the payable type to Manual Entry?')
      .subscribe(
        answer => {
          if (answer) {
            this.store.dispatch(changeClientServiceNoteToManualEntry({ payableItem: payableItem }));
          }
        }, error => {
        }
      );
  }

}
