import { AssignedEquipmentModel } from './../../../../models/equipmentsModel';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { getOfferLettersList, getOfferLetterPdf, deleteOfferLetter, deleteOfferLetterSuccess } from '../../../../core/store/offerletter/offerletter.actions';
import { OfferLetterWithUserModel } from 'src/app/models/offerletterModel';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { AlertService } from 'src/app/shared/services/alert.service';
import { getMyEquipmentList, getEquipmentAssignPdf } from 'src/app/core/store/equipment/equipment.actions';
import { Router } from '@angular/router';
import { GeneralSettingsModel } from 'src/app/models/generalSettingsModel';

@Component({
  selector: 'app-list-my-equipment',
  templateUrl: './list-my-equipment.component.html',
  styleUrls: ['./list-my-equipment.component.scss']
})
export class ListMyEquipmentComponent extends BaseComponent implements OnInit {

  equipments: AssignedEquipmentModel[];
  loadingMyEquipments = false;
  filter: any;
  generalSettings: GeneralSettingsModel;

  constructor(private actions$: Actions,
    private router: Router,
    private store: Store<State>) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.accountState.generalSettings).subscribe(generalSettings => {
        if (generalSettings) {
          this.generalSettings = generalSettings;
        }
      }),
      this.store.select(s => s.accountState.user).subscribe(user => {
        if (user && user.id) {
          this.store.dispatch(getMyEquipmentList({ userID: user.id }));
        }
      }),
      this.store.select(s => s.equipmentState.myEquipments).subscribe(myEquipments => {
        this.equipments = myEquipments;
      }),
      this.store.select(s => s.equipmentState.loadingMyEquipments).subscribe(loadingMyEquipments => {
        this.loadingMyEquipments = loadingMyEquipments;
      }),
    );

  }


  viewAssignedDocument(equipment: AssignedEquipmentModel) {
    this.store.dispatch(getEquipmentAssignPdf({ assignedEquipmentID: equipment.id, returned: false, download: true }));
  }


  viewReturnedDocument(equipment: AssignedEquipmentModel) {
    this.store.dispatch(getEquipmentAssignPdf({ assignedEquipmentID: equipment.id, returned: true, download: true }));
  }

  signAssignement(equipment: AssignedEquipmentModel = null) {
    this.router.navigate(['/equipment/signAssign/' + equipment.id]);
  }

}

