
<div>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Screening Interview for {{ firstName }} {{
        lastName }}</h4>
      <button type="button" class="close btn btn-outline-dark" aria-label="Close"
        (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <b *ngIf="!prospectiveCandidateID" style="font-size: 18px;" class="ml-2">Before making the phone call, review their resume.</b>
        <p *ngIf="!prospectiveCandidateID" style="font-size: 18px;" class="ml-2">
          Look for these in the Resume and when calling:<br>
          RED FLAGS: <i class="fas fa-sad-tear text-danger"></i> <br>
          - Poor formatting, messy layout<br>
          - Typos and spelling errors<br>
          - List of jobs but missing dates or missing description of duties<br>
          - Jumping from job to job (short timeframes at each job)<br>
          - Gaps between employment<br>
          - Doesn`t answer, doesn`t call back, or takes days to call back.<br>
          <br>
          GREEN FLAGS! <i class="fas fa-laugh-beam text-success"></i><br>
          - Life experience or service that relates<br>
          - College relating to the position (education, psychology, family life, neuroscience, autism, etc.)<br>
          - Clear layout, clean formatting, no typos<br>
          - Someone who applied externally (ZipRecruiter, Indeed) AND applied through our website.<br>
          - Answers our call or returns the message the same day!</p>
          <br>
      </div>
      <app-form-fill *ngIf="formID" #formFilling [formID]="formID" [formSubmissionID]="formSubmissionID"
      [jobApplicationID]="applicationID" [prospectiveCandidateID]="prospectiveCandidateID" [hideFinishAndSign]="true"
      [hideSaveButon]="true" [hideTitle]="true" [forceFullWidth]="true"></app-form-fill>

      <div class="row" *ngIf="showArchiveReason">
        <div  class="form-group col-12">
          <p>In order to proceed, please explain why this Applicant is to be Archived: </p>
          <textarea type="text" [(ngModel)]="archiveReason" class="form-control"
            [ngModelOptions]="{standalone: true}"></textarea>
          <span *ngIf="showArchiveReason" class="text-danger">**** Please fill out this field in order to Archive ****</span>
        </div>
      </div>
    </div>
    <div class="table-buttons modal-footer">
      <span *ngIf="screeningCompleted == false" class="text-danger">**** There Are Sections Not Completed ****</span>
      <div>
        <button *ngIf="true" [class.disabled]="loading || !this.formFillingComponent?.isFormValid()" type="button" class="btn mr-2 btn-outline-dark" (click)="SaveAndClose()">Save
        <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        </button>
        <button *ngIf="!prospectiveCandidateID" [disabled]="loading" type="button" class="btn btn-success mr-2 btn-outline-dark"
        (click)="moveToCandidate()">Move To Prospective Candidates
        <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        </button>
        <button *ngIf="!prospectiveCandidateID" [disabled]="loading" type="button" class="btn btn-warning mr-2 btn-outline-dark"
          (click)="archiveApplicantFromScreeningModal()">
          Archive Applicants
          <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        </button>
        <button type="button" class="btn btn-outline-dark" (click)="closeModal()">{{!prospectiveCandidateID ? 'Cancel' : 'Close'}}</button>
      </div>
    </div>
</div>
