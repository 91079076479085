<div class="card mt-2" *ngIf="improvementPlan" >
  <div class="card-body">
    <h4 class="mb-3">Improvement Plan</h4>
    <div class="row">
      <div class="col-12 col-lg-12 ">
        <div class="row">
          <div class="col-3 ">
            <b>User</b><br /> {{improvementPlan.user.firstName+' '+improvementPlan.user.lastName}}
          </div>
          <div class="col-3 ">
            <b>Supervisor</b><br /> {{improvementPlan.supervisorUser.firstName+' '+improvementPlan.supervisorUser.lastName}}
          </div>
          <div class="col-3 ">
            <b>Region</b><br /> {{campusesHash[improvementPlan.user.primaryProgramID]}}
          </div>
          <div class="col-3 ">
            <b>Next Review Date</b><br /> {{getFormatedDate(improvementPlan.nextReviewDate)}}
          </div>
        </div>

        <div *ngIf="improvementPlan.categories != null && improvementPlan.categories.length > 0" class="row mt-3">
          <div class="col">
            <b>Categories of the Issue:</b><br />{{improvementPlan.categories.join(", ")}}
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-4 ">
            <b>Benchmark</b><br /> {{improvementPlan.benchmark}}
          </div>
          <div class="col-4 ">
            <b>Performance Concern</b><br /> {{improvementPlan.performanceConcern}}
          </div>
          <div class="col-4 ">
            <b>Expected Standard</b><br /> {{improvementPlan.expectedStandard}}
          </div>
        </div>


        <div class="row">
          <div class="col-4">
            <b>Measured By</b><br /> {{improvementPlan.measuredBy}}
          </div>
          <div class="col-4">
            <b>Improvement Actions</b><br /> {{improvementPlan.improvementActions}}
          </div>
          <div class="col-4 ">
            <b>Support</b><br /> {{improvementPlan.support}}
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-12 ">
            <b>Additional Info:</b> {{improvementPlan.additionalInfo}}
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-12 ">
            <b>Signed by Employee:</b> {{getFormatedDate(improvementPlan.signedDateEmployee)}}
            <a class="mr-2" *ngIf="!improvementPlan.signedDateEmployee && improvementPlan.userID?.toString() === currentUserId?.toString()" href="javascript:void(0)" [routerLink]="'/improvementplan/sign/'+improvementPlan.id" [queryParams]="{backTo:'given'}">Sign</a>
            <span class="mr-2" *ngIf="!improvementPlan.signedDateEmployee && improvementPlan.userID?.toString() !== currentUserId?.toString()">-</span>
          </div>
        </div>

        <div class="row ">
          <div class="col-12 ">
            <b>Signed by Supervisor:</b> {{getFormatedDate(improvementPlan.signedDateSupervisor)}}
            <a class="mr-2" *ngIf="!improvementPlan.signedDateSupervisor && improvementPlan.supervisorUserID?.toString() === currentUserId?.toString()" href="javascript:void(0)" [routerLink]="'/improvementplan/sign/'+improvementPlan.id" [queryParams]="{backTo:'given'}">Sign</a>
            <span class="mr-2" *ngIf="!improvementPlan.signedDateSupervisor && improvementPlan.supervisorUserID?.toString() !== currentUserId?.toString()">-</span>

          </div>
        </div>

      </div>
    </div>

    <div class="row mt-4">
      <div class="col-6">
        <i *ngIf="creatingImprovementPlan" class="material-icons rotate text-muted">loop</i>
        <button *ngIf="!creatingImprovementPlan" class="btn btn-secondary mr-3" (click)="back()">Back</button>
        <button *ngIf="canAddCoach && !creatingImprovementPlan && improvementPlan.signedDateSupervisor && improvementPlan.signedDateEmployee" class="btn btn-primary" [routerLink]="['/improvementplan/'+improvementPlanID+'/review/create']" >Add Review</button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="improvementPlan && improvementPlan.reviews && improvementPlan.reviews.length>0">
  <div class="card mt-2"  *ngFor="let improvementPlanReview of improvementPlan.reviews">
    <div class="card-body">
      <h4 class="mb-3">Review {{getFormatedDate(improvementPlanReview.createdDate)}}</h4>
      <div class="row">
        <div class="col-12 col-lg-12 ">

          <div class="row mt-3" >
            <div class="col-6 ">
              <b>Review Notes</b><br/> {{improvementPlanReview.reviewNotes}}
            </div>
            <div class="col-6 ">
              <b>Data Included</b><br/> {{improvementPlanReview.dataIncluded}}
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12 ">
              <b>Signed by Employee:</b> {{getFormatedDate(improvementPlanReview.signedDateEmployee)}}
              <a class="mr-2" *ngIf="!improvementPlanReview.signedDateEmployee && improvementPlan.userID?.toString() === currentUserId?.toString()" href="javascript:void(0)" [routerLink]="'/improvementplan/'+improvementPlanID+'/review/sign/'+improvementPlanReview.id" [queryParams]="{backTo:'given'}">Sign</a>
              <span class="mr-2" *ngIf="!improvementPlanReview.signedDateEmployee && improvementPlan.userID?.toString() !== currentUserId?.toString()" >-</span>
            </div>
          </div>

          <div class="row ">
            <div class="col-12 ">
              <b>Signed by Supervisor:</b> {{getFormatedDate(improvementPlanReview.signedDateSupervisor)}}
              <a class="mr-2" *ngIf="!improvementPlanReview.signedDateSupervisor && improvementPlan.supervisorUserID?.toString() === currentUserId?.toString()" href="javascript:void(0)" [routerLink]="'/improvementplan/'+improvementPlanID+'/review/sign/'+improvementPlanReview.id" [queryParams]="{backTo:'given'}">Sign</a>
              <span class="mr-2" *ngIf="!improvementPlanReview.signedDateSupervisor && improvementPlan.supervisorUserID?.toString() !== currentUserId?.toString()" >-</span>
            </div>
          </div>


          <div class="row ">
            <div class="col-12 ">
              <a class="mr-2" href="javascript:void(0)" (click)="downloadImprovementPlanReview(improvementPlanReview)">Download</a>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</div>
