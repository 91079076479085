import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { InternApplicationActionTypeModel } from '../../../../models/internModels';
import { closeEditInternActionType, deleteInternActionType, deleteInternActionTypeSuccess, getInternActionType, loadInternshipStatuses, saveInternActionType, saveInternActionTypeSuccess } from '../../../../core/store/intern/intern.action';
import { markFormGroupTouched, updateFormGroupValidity } from '../../../../helpers/utils';
import { ModifiedSelectItem } from '../../../../models/utilModels';

@Component({
  selector: 'app-edit-intern-action-type',
  templateUrl: './edit-intern-action-type.component.html',
  styleUrls: ['./edit-intern-action-type.component.scss']
})
export class EditInternActionTypeComponent extends BaseComponent implements OnInit {
  display: boolean = false;
  actionType: InternApplicationActionTypeModel;
  title: string;
  form: FormGroup;
  disableButtons: boolean = true;  
  actionTypeId: number = 0;  

  applicationStatuses: ModifiedSelectItem[];

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.form = this.createForm();
    this.subs.push(
      this.store.select(s => s.internState.editingActionType).subscribe(editing => {
        if (this.display && !editing || (editing && this.actionTypeId == 0)) {
          this.resetForm();
        }
        this.display = editing;

      }),
      this.store.select(s => s.internState.savingActionType).subscribe(saving => {
        this.disableButtons = saving;
      }),
      this.store.select(s => s.internState.actionTypeId).subscribe(id => {
        this.actionTypeId = id;
        this.title = "New Action Type";
        if (typeof id !== 'undefined' && id > 0) {
          this.store.dispatch(getInternActionType({ actionTypeId: id }));
        }
      }),
      this.store.select(s => s.internState.selectedActionType).subscribe(selectedType => {
        if (selectedType === null) {
          this.actionType = null;
          return;
        }

        this.title = "Edit Action Type #" + selectedType.actionTypeId;
        this.form.get("actionTypeId").setValue(selectedType.actionTypeId);
        this.form.get("actionType").setValue(selectedType.actionType);
        this.form.get("archived").setValue(selectedType.archived);
       
      }),
      this.store.select(s => s.internState.internshipStatuses).subscribe(statuses => {
        if (statuses && statuses.length > 0) {
          this.applicationStatuses = statuses.map(x => ({ label: x.label, value: x.value }));
          this.applicationStatuses.unshift({ label: '', value: 0 });
        }
        else {
          this.applicationStatuses = [{ label: 'loading...', value: undefined }];
        }
      }),                  
      this.actions$.pipe(
        ofType(saveInternActionTypeSuccess),
        tap(action => {
          this.closeModal();
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(deleteInternActionTypeSuccess),
        tap(action => {
          this.closeModal();
        })
      ).subscribe(),
    );

    if (this.applicationStatuses == null || this.applicationStatuses.length == 0 || this.applicationStatuses[0].label === 'loading...') {
      this.store.dispatch(loadInternshipStatuses());
    }
  }

  createForm() {
    let f = this.fb.group({
      actionTypeId: this.fb.control(0),
      actionType: this.fb.control("", [Validators.required]),      
      archived: this.fb.control(false),       
    });

    return f;
  }

  resetForm() {
    this.form.reset({
      actionTypeId: 0,
      actionType: "",
      archived: false
    });
  }

  closeModal() {
    this.store.dispatch(closeEditInternActionType());
  }

  saveActionType() {
    updateFormGroupValidity(this.form);
    if (this.form.valid) {
      this.store.dispatch(saveInternActionType({ actionType: this.form.value as InternApplicationActionTypeModel }));
    } else {
      markFormGroupTouched(this.form);
    }
  }

  deleteActionType() {
    if (this.actionTypeId > 0) {
      this.store.dispatch(deleteInternActionType({ actionTypeId: this.actionTypeId }));
    }
    else {
      this.closeModal(); //delete button should be hidden if not > 0
    }
  }
}
