import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SelectItem } from 'primeng/api';
import { environment } from '../../../environments/environment';
import { PagingResultsModel } from '../../models/pagingResultsModel';
import { EmployeePtoSummaryModel, PtoEarnedModel, PtoRequestedModel, TimeOffApprovalModel, TimeOffModel } from '../../models/timeoffModel';
import { deepClone, getDateWithOffset } from '../../helpers/utils';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class TimeoffService {

  constructor(private http: HttpClient) {
  }

  getCampusList(): Observable<SelectItem[]> {
    return this.http.get<SelectItem[]>(`${environment.apiServer.webApiURL}/TimeOff/Campuses`);
  }

  getClassificationList(): Observable<SelectItem[]> {
    return this.http.get<SelectItem[]>(`${environment.apiServer.webApiURL}/TimeOff/PayrollClassifications`);
  }

  getUsersList(prefixText: string, programId: number, payrollClassificationId: number, showAll: boolean): Observable<SelectItem[]> {
    return this.http.post<SelectItem[]>(`${environment.apiServer.webApiURL}/TimeOff/Employees`, {
      prefixText, programId, payrollClassificationId, showInactive: showAll, showTerminated: showAll
      });
  }

  getSummaryList(filter: any, campusId: number, payrollClassificationId: number, userId: number, showOnlyActive: boolean)
    : Observable<PagingResultsModel<EmployeePtoSummaryModel>> {
    if (!filter) {
      filter = {};
      filter.filters = {};
    }
    filter = deepClone(filter);

    filter.filters.campusId = { Name: 'campusId', Value: { value: campusId.toString() } };
    filter.filters.payrollClassificationId = { Name: 'payrollClassificationId', Value: { value: payrollClassificationId.toString() } };
    filter.filters.userId = { Name: 'userId', Value: { value: userId.toString() } };
    filter.filters.showOnlyActive = { Name: 'showOnlyActive', Value: { value: showOnlyActive.toString() } };

    return this.http.post<PagingResultsModel<EmployeePtoSummaryModel>>(`${environment.apiServer.webApiURL}/TimeOff/PtoSummaryList`,
      filter)
  }

  getEmployeePtoSummary(userId: number): Observable<EmployeePtoSummaryModel> {
    return this.http.get<EmployeePtoSummaryModel>(`${environment.apiServer.webApiURL}/TimeOff/EmployeePtoSummary/${userId}`);
  }

  getEmployeeEarnedPto(filter: any, userId: number): Observable<PagingResultsModel<PtoEarnedModel>> {
    if (!filter) {
      filter = {};
      filter.filters = {};
    }
    filter = deepClone(filter);

    filter.filters.userId = { Name: 'userId', Value: { value: userId.toString() } };
    return this.http.post<PagingResultsModel<PtoEarnedModel>>(`${environment.apiServer.webApiURL}/TimeOff/EmployeePtoEarned`,
      filter);
  }

  getEmployeeRequestedPto(filter: any, userId: number): Observable<PagingResultsModel<PtoRequestedModel>> {
    if (!filter) {
      filter = {};
      filter.filters = {};
    }
    filter = deepClone(filter);

    filter.filters.userId = { Name: 'userId', Value: { value: userId.toString() } };
    return this.http.post<PagingResultsModel<PtoRequestedModel>>(`${environment.apiServer.webApiURL}/TimeOff/EmployeePtoRequested`,
      filter);
  }

  getRecentTimeOff(filter: any, userId: number): Observable<PagingResultsModel<TimeOffModel>> {
    if (!filter) {
      filter = {};
      filter.filters = {};
    }
    filter = deepClone(filter);

    filter.filters.userId = { Name: 'userId', Value: { value: userId.toString() } };
    filter.filters.startDate = { Name: 'startDate', Value: { value: formatDate(getDateWithOffset(-180), 'yyyy-MM-dd', 'en-US') } }
    filter.filters.endDate = { Name: 'endDate', Value: { value: formatDate(getDateWithOffset(-1), 'yyyy-MM-dd', 'en-US') } }
    return this.http.post<PagingResultsModel<TimeOffModel>>(`${environment.apiServer.webApiURL}/TimeOff/Query`,
      filter);
  }

  getUpcomingTimeOff(filter: any, userId: number): Observable<PagingResultsModel<TimeOffModel>> {
    if (!filter) {
      filter = {};
      filter.filters = {};
    }
    filter = deepClone(filter);

    filter.filters.userId = { Name: 'userId', Value: { value: userId.toString() } };
    filter.filters.startDate = { Name: 'startDate', Value: { value: formatDate(new Date(), 'yyyy-MM-dd', 'en-US') } }   
    return this.http.post<PagingResultsModel<TimeOffModel>>(`${environment.apiServer.webApiURL}/TimeOff/Query`,
      filter);
  }

  editTimeOffRequest(timeOffRequest: TimeOffModel): Observable<any> {
    return this.http.put<TimeOffModel>(`${environment.apiServer.webApiURL}/TimeOff/TimeOffRequest/${timeOffRequest.timeOffId}`, timeOffRequest);
  }

  addTimeOffRequest(timeOffRequest: TimeOffModel): Observable<any> {
    return this.http.post<TimeOffModel>(`${environment.apiServer.webApiURL}/TimeOff/TimeOffRequest`, timeOffRequest);
  }

  getManagePtoEarnedList(filter: any): Observable<PagingResultsModel<PtoEarnedModel>> {
    if (!filter) {
      filter = {};
      filter.filters = {};
    }
    filter = deepClone(filter);

    return this.http.post<PagingResultsModel<PtoEarnedModel>>(`${environment.apiServer.webApiURL}/TimeOff/ManagePtoEarnedList`,
      filter);
  }

  getManageTimeOffList(filter: any): Observable<PagingResultsModel<TimeOffModel>> {
    if (!filter) {
      filter = {};
      filter.filters = {};
    }
    filter = deepClone(filter);

    return this.http.post<PagingResultsModel<TimeOffModel>>(`${environment.apiServer.webApiURL}/TimeOff/ManageTimeOffList`,
      filter);
  }

  removeTimeOffRequest(id: number){
    return this.http.delete<any>(`${environment.apiServer.webApiURL}/TimeOff/RemoveTimeOffRequest/${id}`);
  }

  editPtoEarned(ptoEarned: PtoEarnedModel): Observable<any> {
    return this.http.put<PtoEarnedModel>(`${environment.apiServer.webApiURL}/TimeOff/PtoEarned/${ptoEarned.ptoId}`, ptoEarned);
  }

  addPtoEarned(ptoEarned: PtoEarnedModel): Observable<any> {
    return this.http.post<PtoEarnedModel>(`${environment.apiServer.webApiURL}/TimeOff/PtoEarned`, ptoEarned);
  }

  saveTimeOffApproval(timeOff: TimeOffModel): Observable<any> {
    return this.http.put<TimeOffModel>(`${environment.apiServer.webApiURL}/TimeOff/TimeOffApproval/${timeOff.timeOffId}`, timeOff);
  }

  resetTimeOffApproval(timeOff: TimeOffModel): Observable<any> {
    return this.http.put<TimeOffModel>(`${environment.apiServer.webApiURL}/TimeOff/ResetTimeOffApproval/${timeOff.timeOffId}`, timeOff);
  }

  getTimeOffApprovalSummary(timeOffId: number): Observable<TimeOffApprovalModel> {
    return this.http.get<TimeOffApprovalModel>(`${environment.apiServer.webApiURL}/TimeOff/TimeOffApprovalSummary/${timeOffId}`);
  }
}
