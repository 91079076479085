<div class="card mt-3" *ngIf="loadingSurvey">
  <div class="card-body">
    Loading ...
  </div>
</div>
<form class="card mt-2" [formGroup]="form" *ngIf="survey">
  <div class="card-body text-center">
    <h3 class="">Survey <br/> {{survey.surveyType.name}}</h3>
    <h6 class="">{{survey.surveyType.purpose}}</h6>
    <h6 class="mb-3" *ngIf="survey.userAbout">This survey is about the user: <b>{{survey.userAbout.firstName}} {{survey.userAbout.lastName}}</b></h6>
    <h6 class="mb-3" *ngIf="survey.serviceID">This survey is about the service: <b>#{{survey.serviceID}}</b></h6>
    <div class="row">
      <div class="col-12 offset-lg-3 col-lg-6">
        <div formArrayName="answers">
          <div *ngFor="let line of getControls(form.get('answers')); let i=index" >
            <div [formGroup]="line">
              <div class="row mt-5">
                <div class="col-12 text-center">
                  <b>{{surveyQuestionsMap[line.get('surveyQuestionID').value+0].questionText}}</b>
                </div>
                <div class="col-12 pt-2">
                  <span class="star-rating">  <input type="radio" [name]="'rating'+i" value="1" (change)="changedRating($event,line,1)"><i></i>
                    <input type="radio" [name]="'rating'+i" value="2"  (change)="changedRating($event,line,2)"><i></i>
                    <input type="radio" [name]="'rating'+i" value="3"  (change)="changedRating($event,line,3)"><i></i>
                    <input type="radio" [name]="'rating'+i" value="4"  (change)="changedRating($event,line,4)"><i></i>
                    <input type="radio" [name]="'rating'+i" value="5"  (change)="changedRating($event,line,5)"><i></i></span>
                    <br>
                  <app-validation-message [form]="line" [path]="'answer'" [name]="'answer'" [label]="'Answer'"> </app-validation-message>
                </div>
              </div>
              <div class="row" *ngIf="surveyQuestionsMap[line.get('surveyQuestionID').value+0].allowComment">
                <div class="col-12 d-flex align-items-center text-center">
                  <b>Comment</b>
                </div>
                <div class="col-12">
                  <textarea type="text" class="form-control mt-2" formControlName="comment"></textarea>
                  <app-validation-message [form]="line" [path]="'comment'" [name]="'comment'" [label]="'Comment'"> </app-validation-message>
                </div>
              </div>
            </div>
          </div>
       </div>
       <div class="row mt-5" formGroupName="general"  *ngIf="survey.surveyType.allowGeneralComment">
        <div class="col-12 d-flex align-items-center text-center  mt-5">
          <b>General Comment</b>
        </div>
        <div class="col-12">
            <textarea class="form-control mt-2" formControlName="generalComment"></textarea>
          <app-validation-message [form]="form" [path]="'general.generalComment'" [name]="'generalComment'" [label]="'General Comment'"> </app-validation-message>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-12 text-center">
          <i *ngIf="savingAnswer" class="material-icons rotate text-muted">loop</i>
          <button *ngIf="!savingAnswer" class="btn btn-primary" [class.disabled]="form.invalid" (click)="save()">Answer Survey</button>
        </div>
      </div>
      </div>
    </div>
  </div>
</form>
