import { OnBoardingUserModel, OnBoardingMessageModel, LicenseInfosModel, PanelingInfosModel, CovidTestInfosModel, VaccineCardExemptionModel, UploadDocsApprovalListModel, EmployeeVaccineListModel, DriversLicenseInfosModel } from './../../models/onBoardingModels';
import { PagingResultsModel } from './../../models/pagingResultsModel';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { OnBoardingUploadType, OnBoardingUploadModel, OnBoardingModel } from '../../../app/models/onBoardingModels';
import { deepClone } from '../../helpers/utils';


@Injectable()
export class OnboardingService {

  constructor(private http: HttpClient) {
  }

  getOnBoardingUploadTypes(): Observable<OnBoardingUploadType[]> {
    return this.http.get<OnBoardingUploadType[]>
      (`${environment.apiServer.webApiURL}/OnBoarding/UploadTypes`);
  }

  uploadFile(docTypeID: string, files: File[], userId?: number, visibleToUser?: boolean, uploadID?: number,
    licenseInfos?: LicenseInfosModel, panelingInfos?: PanelingInfosModel, covidTestInfos?: CovidTestInfosModel, vaccineExemption?: any,
    driversLicenseInfos?: DriversLicenseInfosModel) {
    const formData = new FormData();
    let i = 0;
    for (const file of files) {

      formData.append('files.Index', i.toString());
      formData.append('files[' + i + '].File', file);
      if (uploadID) {
        formData.append('files[' + i + '].uploadID', uploadID.toString());
      }
      formData.append('files[' + i + '].docTypeID', docTypeID);
      if (userId) {
        formData.append('files[' + i + '].userId', userId.toString());
      }
      formData.append('files[' + i + '].visibleToUser', visibleToUser ? 'true' : 'false');

      if (licenseInfos && licenseInfos.originationDate && licenseInfos.issueDate) {
        formData.append('files[' + i + '].OriginationDate', licenseInfos.originationDate);
        formData.append('files[' + i + '].IssueDate', licenseInfos.issueDate);
        if (licenseInfos.expirationDate) {
          formData.append('files[' + i + '].ExpirationDate', licenseInfos.expirationDate);
        }


        formData.append('files[' + i + '].LicenseNumber', licenseInfos.licenseNumber);
        formData.append('files[' + i + '].IsBoardCertification', licenseInfos.isBoardCertification ? licenseInfos.isBoardCertification : 'false');
      }
      if (panelingInfos && panelingInfos.originationDate && panelingInfos.issueDate) {
        formData.append('files[' + i + '].OriginationDate', panelingInfos.originationDate);
        formData.append('files[' + i + '].IssueDate', panelingInfos.issueDate);
        if (panelingInfos.expirationDate) {
          formData.append('files[' + i + '].ExpirationDate', panelingInfos.expirationDate);
        }


        formData.append('files[' + i + '].LicenseNumber', panelingInfos.licenseNumber);
      }

      if (driversLicenseInfos && driversLicenseInfos.expirationDate) {
        formData.append('files[' + i + '].ExpirationDate', driversLicenseInfos.expirationDate + "");
      }

      if (covidTestInfos && covidTestInfos.testDate) {
        formData.append('files[' + i + '].TestDate', covidTestInfos.testDate);
        formData.append('files[' + i + '].Positive', (covidTestInfos.positive as any === 'false' || !covidTestInfos.positive) ? 'false' : 'true');
        if (covidTestInfos.quarantineEndedDate) {
          formData.append('files[' + i + '].QuarantineEndedDate', covidTestInfos.quarantineEndedDate);
        } if (covidTestInfos.sympthomsStartedDate) {
          formData.append('files[' + i + '].SympthomsStartedDate', covidTestInfos.sympthomsStartedDate);
        }
      }

      if (vaccineExemption && vaccineExemption.exemptionType) {
        formData.append('files[' + i + '].ExemptionType', vaccineExemption.exemptionType);
        formData.append('files[' + i + '].Notes', vaccineExemption.notes);
      }


      i++;
    }


    // formData.append('File', file);
    // if (uploadID) {
    //   formData.append('uploadID', uploadID.toString());
    // }
    // formData.append('docTypeID', docTypeID);
    // if (userId) {
    //   formData.append('userId', userId.toString());
    // }
    // formData.append('visibleToUser', visibleToUser ? 'true' : 'false');

    const options = { headers: new HttpHeaders() };
    options.headers.append('enctype', 'multipart/form-data');

    return this.http.post(`${environment.apiServer.webApiURL}/OnBoarding/UploadFile`, formData, options);
  }


  getUploadedFiles(userId: number): Observable<OnBoardingUploadModel[]> {
    if (userId) {
      return this.http.get<OnBoardingUploadModel[]>
        (`${environment.apiServer.webApiURL}/OnBoarding/UploadedFiles/${userId}`);
    } else {
      return this.http.get<OnBoardingUploadModel[]>
        (`${environment.apiServer.webApiURL}/OnBoarding/UploadedFiles`);
    }
  }

  loadOnBoardingInfo(): Observable<OnBoardingModel> {
    return this.http.get<OnBoardingModel>
      (`${environment.apiServer.webApiURL}/OnBoarding`);
  }

  deleteOnboardingUpload(file: OnBoardingUploadModel): Observable<number> {
    return this.http.delete<number>
      (`${environment.apiServer.webApiURL}/OnBoarding/DeleteUpload/${file.id}`);
  }

  changeVisibilityOnboardingUpload(file: OnBoardingUploadModel): Observable<boolean> {
    return this.http.get<boolean>
      (`${environment.apiServer.webApiURL}/OnBoarding/ChangeVisibilityUpload/${file.id}`);
  }

  approveOnboardingUpload(file: OnBoardingUploadModel): Observable<boolean> {
    return this.http.get<boolean>
      (`${environment.apiServer.webApiURL}/OnBoarding/ApproveUpload/${file.id}`);
  }

  denyOnboardingUpload(file: OnBoardingUploadModel, denyMessage: string): Observable<boolean> {
    return this.http.put<boolean>
      (`${environment.apiServer.webApiURL}/OnBoarding/DenyUpload/${file.id}`, { denyMessage });
  }

  approveAlluploads(userid: number): Observable<boolean> {
    return this.http.get<boolean>
      (`${environment.apiServer.webApiURL}/OnBoarding/ApproveAllUpload/${userid}`);
  }
  approveI9(userid: number): Observable<boolean> {
    return this.http.get<boolean>
      (`${environment.apiServer.webApiURL}/OnBoarding/ApproveI9/${userid}`);
  }

  denyI9(userid: number, denyMessage: string): Observable<boolean> {
    return this.http.put<boolean>
      (`${environment.apiServer.webApiURL}/OnBoarding/DenyI9/${userid}`, { denyMessage });
  }

  approveSignedDocs(userid: number): Observable<boolean> {
    return this.http.get<boolean>
      (`${environment.apiServer.webApiURL}/OnBoarding/ApproveSignedDocs/${userid}`);
  }

  finishOnboarding(): Observable<boolean> {
    return this.http.get<boolean>
      (`${environment.apiServer.webApiURL}/OnBoarding/Finish`);
  }

  rejectOrientationDate(): Observable<boolean> {
    return this.http.get<boolean>
      (`${environment.apiServer.webApiURL}/OnBoarding/RejectOrientationDate`);
  }


  setOrientationDate(userId: number, orientationDate: string): Observable<boolean> {
    return this.http.post<boolean>
      (`${environment.apiServer.webApiURL}/OnBoarding/OrientationDate`, { userId, orientationDate });
  }



  consentToProceedOnboarding(onBoardingModel: OnBoardingModel): Observable<OnBoardingModel> {
    return this.http.get<OnBoardingModel>
      (`${environment.apiServer.webApiURL}/OnBoarding/ConsentToProceedOnboarding/${onBoardingModel.id}`);
  }

  loadOnBoardingUsers(filters: any): Observable<PagingResultsModel<OnBoardingUserModel>> {
    return this.http.post<PagingResultsModel<OnBoardingUserModel>>
      (`${environment.apiServer.webApiURL}/OnBoarding/OnboardingUsersDatatable`, filters);
  }


  loadEmployeeFileUsers(filters: any): Observable<PagingResultsModel<OnBoardingUserModel>> {
    return this.http.post<PagingResultsModel<OnBoardingUserModel>>
      (`${environment.apiServer.webApiURL}/OnBoarding/EmployeeFileUsersDatatable`, filters);
  }



  downloadOnboardingUpload(onBoardingUploadId: number): Observable<ArrayBuffer> {
    return this.http.get<ArrayBuffer>(`${environment.apiServer.webApiURL}/OnBoarding/DownloadUploadFile/${onBoardingUploadId}`,
      { responseType: 'arraybuffer' as 'json' });
  }



  getOnboardingUser(userId: number): Observable<OnBoardingUserModel> {
    return this.http.get<OnBoardingUserModel>
      (`${environment.apiServer.webApiURL}/OnBoarding/OnboardingUser/${userId}`);
  }



  forceOnBoarding(userId: number): Observable<OnBoardingUserModel> {
    return this.http.get<OnBoardingUserModel>
      (`${environment.apiServer.webApiURL}/OnBoarding/ForceOnboardingUser/${userId}`);
  }

  uploadSignedI9(formSubmissionID: number, file: File) {
    const formData = new FormData();
    formData.append('File', file);
    formData.append('formSubmissionID', formSubmissionID.toString());

    const options = { headers: new HttpHeaders() };
    options.headers.append('enctype', 'multipart/form-data');

    return this.http.post(`${environment.apiServer.webApiURL}/OnBoarding/UploadSignedI9`, formData, options);
  }


  sendOnboardingMessage(onBoardingMessageModel: OnBoardingMessageModel): Observable<OnBoardingMessageModel> {
    return this.http.post<OnBoardingMessageModel>
      (`${environment.apiServer.webApiURL}/OnBoarding/SendOnboardingMessage`, onBoardingMessageModel);
  }

  completeHRFile(userId: number, eVerifyCompletionDate: Date): Observable<OnBoardingUserModel> {
    return this.http.post<OnBoardingUserModel>
      (`${environment.apiServer.webApiURL}/OnBoarding/CompleteHRFile`,
        {
          userId,
          eVerifyCompletionDate
        });
  }


  requestVaccineCardExemption(vaccineCardExemptionModel: VaccineCardExemptionModel): Observable<VaccineCardExemptionModel> {
    return this.http.post<VaccineCardExemptionModel>
      (`${environment.apiServer.webApiURL}/OnBoarding/ExemptionRequest`, vaccineCardExemptionModel);
  }

  cancelVaccineCardExemption(vaccineCardExemptionModel: VaccineCardExemptionModel): Observable<VaccineCardExemptionModel> {
    return this.http.post<VaccineCardExemptionModel>
      (`${environment.apiServer.webApiURL}/OnBoarding/CancelExemptionRequest`, vaccineCardExemptionModel);
  }

  getVaccineCardExemption(userID: number): Observable<VaccineCardExemptionModel> {
    return this.http.get<VaccineCardExemptionModel>
      (`${environment.apiServer.webApiURL}/OnBoarding/ExemptionRequest/${userID}`);
  }

  approveVaccineCardExemption(id: number): Observable<VaccineCardExemptionModel> {
    return this.http.get<VaccineCardExemptionModel>
      (`${environment.apiServer.webApiURL}/OnBoarding/ApproveExemptionRequest/${id}`);
  }

  loadUploadDocsApprovalList(): Observable<UploadDocsApprovalListModel[]> {
    return this.http.get<UploadDocsApprovalListModel[]>
      (`${environment.apiServer.webApiURL}/OnBoarding/UploadDocsApprovalList`);
  }

  getEmployeeVaccineInfoList(filter: any): Observable<PagingResultsModel<EmployeeVaccineListModel>> {
    if (!filter) {
      filter = {};
      filter.filters = {};
    }
    filter = deepClone(filter);

    return this.http.post<PagingResultsModel<EmployeeVaccineListModel>>(`${environment.apiServer.webApiURL}/OnBoarding/UploadedVaccineReport`, filter);
  }

  exportEmployeeVaccineInfoToExcel(filter: any): Observable<ArrayBuffer> {
    return this.http.post<ArrayBuffer>(`${environment.apiServer.webApiURL}/OnBoarding/ExportUploadedVaccineReportToExcel`, filter, { responseType: 'arraybuffer' as 'json' });
  }

}
