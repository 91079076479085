import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { PayableItemModel } from '../../../../models/payableItem';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { AlertService } from '../../../../shared/services/alert.service';
import { Actions, ofType } from '@ngrx/effects';
import { initEditPayableItemForm } from '../../../../core/store/payroll-approval/payroll-approval.reducer';
import { getAllControlsErrors, getFormattedTime, markFormGroupTouched, updateFormGroupValidity } from '../../../../helpers/utils';
import { closeEditPayableItem, savePayableItem, savePayableItemSuccess, updatePayableItemForm } from '../../../../core/store/payroll-approval/payroll-approval.actions';
import { formatDate } from '@angular/common';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-edit-payable-item',
  templateUrl: './edit-payable-item.component.html',
  styleUrls: ['./edit-payable-item.component.scss']
})
export class EditPayableItemComponent extends BaseComponent implements OnInit {
  display: boolean = false;
  payableItem: PayableItemModel;
  title: string;
  form: FormGroup;
  disableButtons: boolean = true;

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private alertService: AlertService) {
    super();
  }

  ngOnInit() {
    this.form = initEditPayableItemForm({} as PayableItemModel);
    this.subs.push(
      this.store.select(s => s.payrollApprovalState.editingPayableItem).subscribe(editingPayableItem => {
        this.display = editingPayableItem;
      }),
      this.store.select(s => s.payrollApprovalState.savingPayableItem).subscribe(savingPayableItem => {
        this.disableButtons = savingPayableItem;
      }),
      this.store.select(s => s.payrollApprovalState.selectedPayableItem).subscribe(pbi => {        
        if (pbi === null) {
          this.payableItem = null;
          return;
        }

        const changedPayableItem = !this.payableItem || (this.payableItem.id !== pbi.id);
        this.payableItem = JSON.parse(JSON.stringify(pbi));

        if (changedPayableItem) {
          this.form = initEditPayableItemForm(this.payableItem);
          this.subs.push(
            this.form.valueChanges.subscribe(formValues => {
              const formErrors = getAllControlsErrors(this.form);
              this.store.dispatch(updatePayableItemForm({ formValues, formErrors }));
            })
          )
          if (typeof pbi.id === 'undefined' || pbi.id === null || pbi.id < 1) {
            this.title = "Add Payable Item"; //not allowed yet...
            this.store.dispatch(updatePayableItemForm({ formValues: this.form.value, formErrors: getAllControlsErrors(this.form) }));
          } else {
            this.title = "Edit Payable Item #" + pbi.id;
            this.payableItem.dateString = formatDate(pbi.date, 'yyyy-MM-dd', 'en-US');
            this.payableItem.startTimeString = getFormattedTime(pbi.startTime);
            this.payableItem.endTimeString = getFormattedTime(pbi.endTime);            
          }
        }
      }),
      this.actions$.pipe(
        ofType(savePayableItemSuccess),
        tap(action => {
          this.closeModal();
        })
      ).subscribe(),      
    );
  }

  closeModal() {
    this.store.dispatch(closeEditPayableItem());
  }

  savePayableItemEdits() {
    updateFormGroupValidity(this.form);
    if (this.form.valid) {
      this.store.dispatch(savePayableItem({ payableItem: this.payableItem }));
    } else {
      markFormGroupTouched(this.form);
    }
  }

}
