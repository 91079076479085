<app-profile-header [readonly]="true"></app-profile-header>

<div class="row">
  <div class="col-12 col-lg-12">
    <div class="card">
      <div class="card-body">
          <span class="float-right"><button class="btn btn-secondary" [routerLink]="['/timecard']"> Time Card</button></span>
        <h2 class="">Payroll</h2>
        <div class="row">
          <div class="col-12">
            <div class="d-flex w-100 align-items-center">
            <b>Period:</b>
            <i *ngIf="!payPeriods" class="material-icons rotate text-muted">loop</i>
            <select class="form-control ml-2" *ngIf="payPeriods"  [ngModel]="selectedPeriodID" (ngModelChange)="changePeriod($event)">
              <option *ngFor="let period of payPeriods" [value]="period.payPeriodID">{{period.payPeriod}}</option>
            </select>
          </div>
          <div class="row mt-3 ">
            <div class="col-12 col-lg-4 offset-lg-4" >
              <div class="card">
                  <div class="card-body" >
                    <h5>Approved</h5>
                    <hr>

                    <i *ngIf="!approvedTotals" class="material-icons rotate text-muted">loop</i>
                    <ng-container *ngIf="approvedTotals">
                      Total hours: <span class="float-right">{{getTimeFormatted(approvedTotals.totalTime) }}</span><br>
                      Pay Period hours: <span class="float-right">{{getTimeFormatted(approvedTotals.payPeriodMinutes) }}</span><br>
                      <ng-container *ngIf="approvedTotals.overtimeMinutes > 0">
                        Overtime Hours: <span class="float-right">{{getTimeFormatted(approvedTotals.overtimeMinutes) }}</span><br>
                      </ng-container>
                      Productivity:<span class="float-right">{{approvedTotals.productivity.toFixed(0)}} % </span><br>
                      Ceiling Productivity:<span class="float-right">{{approvedTotals.ceilingProductivity.toFixed(0)}} %</span><br>
                      <ng-container *ngIf="approvedTotals.showBonusPaid">
                        Profit Sharing: <span class="float-right">{{formatCurrencyNotZero(approvedTotals.bonusPaid) }}</span><br>
                      </ng-container>
                    </ng-container>
                </div>
              </div>
            </div>
          </div>
          <div class="ml-2">
            <div class=" mt-4 mb-2">
                <div class="d-flex align-items-center">
                    <i class="material-icons mr-2 pointer" (click)="approvedOpened=!approvedOpened" >{{approvedOpened?'keyboard_arrow_up':'keyboard_arrow_down'}}</i>
                    <h4 class="mb-0 ">Approved Time</h4>
                </div>

                <div class="card  mt-3">
                    <div class="card-body" *ngIf="approvedOpened">
                      <div class="row mt-2">
                        <i *ngIf="!differentApprovedPayableTypes" class="material-icons rotate text-muted">loop</i>
                        <div class="col-12 col-lg-4" *ngFor="let diffPayableType of differentApprovedPayableTypes">
                          <app-payroll-items-table  [payableType]="diffPayableType.payableType" [payrollItems]="diffPayableType.payrollItems"></app-payroll-items-table>
                        </div>
                      </div>
                  </div>
                </div>
            </div>
          </div>
          <div class="ml-2">
              <div class=" mt-4 mb-2">
                  <div class="d-flex align-items-center">
                      <i class="material-icons mr-2 pointer" (click)="pendingOpened=!pendingOpened" >{{pendingOpened?'keyboard_arrow_up':'keyboard_arrow_down'}}</i>
                      <h4 class="mb-0 ">Pending Approval Time</h4>
                  </div>
                  <div class="card  mt-3">
                    <div class="card-body" *ngIf="pendingOpened">
                      <div class="row mt-2">
                        <i *ngIf="!differentPendingPayableTypes" class="material-icons rotate text-muted">loop</i>
                        <div class="col-12 col-lg-4" *ngFor="let diffPayableType of differentPendingPayableTypes">
                          <app-payroll-items-table [payableType]="diffPayableType.payableType" [payrollItems]="diffPayableType.payrollItems"></app-payroll-items-table>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="ml-2">
                <div class=" mt-4 mb-2">
                    <div class="d-flex align-items-center">
                        <i class="material-icons mr-2 pointer" (click)="adjustmentsOpened=!adjustmentsOpened" >{{adjustmentsOpened?'keyboard_arrow_up':'keyboard_arrow_down'}}</i>
                        <h4 class="mb-0 ">Adjustments Needed</h4>
                    </div>
                    <div class="card  mt-3">
                      <div class="card-body" *ngIf="adjustmentsOpened">
                        <div class="row mt-2" >
                            <div class="col-12 col-lg-4">
                                <app-payroll-items-table [title]="'Rejected'"  [payrollItems]="adjustmentPayrollItems"
                                [adjustmentView]="true" (adjustmentEditClicked)="adjustmentsPayrollItemsEditClicked($event)"></app-payroll-items-table>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
              <div class="ml-2">
                <div class=" mt-4 mb-2">
                    <div class="d-flex align-items-center">
                        <i class="material-icons mr-2 pointer" (click)="invalidOpened=!invalidOpened" >{{invalidOpened?'keyboard_arrow_up':'keyboard_arrow_down'}}</i>
                        <h4 class="mb-0 ">Invalid</h4>
                    </div>
                    <div class="card mt-3">
                      <div class="card-body" *ngIf="invalidOpened">
                        <div class="row mt-2">
                          <div class="col-12 col-lg-4">
                                    <app-payroll-items-table [title]="'Invalid'"  [payrollItems]="invalidPayrollItems"
                                    [adjustmentView]="true" (adjustmentEditClicked)="invalidPayrollItemsEditClicked($event)"></app-payroll-items-table>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog header="Edit Adjustment Item" [modal]="true" appendTo="body" [style]="{width: '70vw'}" [(visible)]="displayAdjustmentsItems">
  <app-payroll-reject-items-table [title]="'Edit Payable Item'"  [payrollItems]="adjustmentsPayrollItemsSelected"
  (itemsChanged)="adjustmentsItemsEditingChanged($event)" [employeeView]="true"></app-payroll-reject-items-table>

  <div class="text-right mt-2">
    <div class="col-12" >
      <button class="btn btn-primary" *ngIf="adjustmentsPayrollItemsSelected.length" (click)="saveAdjustmentTimes()">Save</button>
    </div>
  </div>
</p-dialog>

<p-dialog header="Edit Item" [modal]="true" appendTo="body" [style]="{width: '70vw'}" [(visible)]="displayInvalidItems">
  <app-payroll-reject-items-table [title]="'Edit Item'"  [payrollItems]="invalidPayrollItemsSelected"
  (itemsChanged)="invalidItemsEditingChanged($event)" [employeeView]="true"></app-payroll-reject-items-table>

  <div class="text-right mt-2">
    <div class="col-12" >
      <button class="btn btn-primary" *ngIf="invalidPayrollItemsSelected.length" (click)="saveInvalidTimes()">Save</button>
    </div>
  </div>
</p-dialog>
