import { GeneralSettingsModel } from 'src/app/models/generalSettingsModel';
import { UserProfileModel } from './../../../models/userProfileModel';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Menu } from 'primeng/menu';
import { AccountService } from '../../../core/services/account.service';
import { Store } from '@ngrx/store';
import { State } from 'src/app/core/store';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { environment } from 'src/environments/environment';
import { getOptoLinkFromUrl } from 'src/app/helpers/utils';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent extends BaseComponent {
  items: MenuItem[];
  loggedUser;
  profile: UserProfileModel;
  canManageOfferLetters: boolean;
  hasManagementPermission: boolean;
  hasStaffInjuriesPermission: boolean;
  hasManagePaylocityTransmissionPermission: boolean = false;
  hasManageInternApplicationPermission: boolean;
  canViewEquipment: boolean;
  notificationCount: number = 0;
  profileLoaded: boolean = false;
  permissionsLoaded: boolean = false;
  generalSettings: GeneralSettingsModel;

  constructor(
    private store: Store<State>,
  ) {
    super();

    this.subs.push(

      this.store.select(s => s.accountState.generalSettings).subscribe(generalSettings => {
        if (generalSettings) {
          this.generalSettings = generalSettings;
        }
      }),
      this.store.select(s => s.accountState.user).subscribe(u => {
        this.loggedUser = u;
        if (u) {
          this.hasManagementPermission = u.permissionIDs && !!u.permissionIDs.find(p => p === 3059 //View On Boarding
            || p === 3060 //Manage On Boarding
            || p === 154  //View Payroll Approval
            || p === 3022 //View Payroll Approval Director (View Only)
            || p === 3057 //View Equipment
            || p === 3058 //Manage Equipment
            || p === 3061 //View Offer Letter
            || p === 3062 //Manage Offer Letter
            || p === 3063 //View Employee File
            || p === 3064 //Manage Employee File
            || p === 3070 //View Full Time Status
            || p === 3071 //Manage Full Time Status
            || p === 3040 //View Employee Payroll Reports
            || p === 3065 // View offerletter behaviortech
            || p === 3066 // View offerletter behaviortech AA
            || p === 3068 //Mange incomplete onboarding employee files
            || p === 6030 // Payroll Approval AA
            || p === 3103 // Manage PTO
            || p === 3104 // View PTO
            || p === 186 // Manage Scheduling Time Off
            || p === 4091 //Manage Employee Review Templates
            || p === 3081 //View Completed KRA Report
            || p === 3208 //View KRA Report
            || p === 3082 //Manage Self-Care
            || p === 3082 //See Self-Care
            || p === 3073 //View Paylocity Tranmissions
            || p === 3074 //Manage Paylocity Tranmissions
            || p === 7500 //View application dashboard - Technicians
            || p === 7501 //View application dashboard - All for Region
            || p === 7502 //View application dashboard - Entire Company
            || p === 6050 //Manage Benefits
            || p === 3075 //Manage Benefit Reports
            || p === 6100 //Approve Document Uploads
            || p === 9001
            || p === 3086 //View Self Care Report
            || p === 3090 //View Prospective Candidates
            || p === 3091 //Manage Prospective Candidates
            || p === 3111 //View Certification
            || p === 3112 //Manage Certification
            || p === 3120 //Payroll Director campus approval
            || p === 3127 //View Intern Applications
            || p === 3128 //Manage Intern Applications
          );
          this.canManageOfferLetters = u.permissionIDs && !!u.permissionIDs.find(p => p === 3062); //Manage Offer Letter
          this.hasStaffInjuriesPermission = u.permissionIDs && !!u.permissionIDs.find(p => p === 4053);
          this.hasManageInternApplicationPermission = u.permissionIDs && !!u.permissionIDs.find(p => p === 3128);
          this.canViewEquipment = u.permissionIDs && !!u.permissionIDs.find(p => p === 3057 || p === 3058);
          this.hasManagePaylocityTransmissionPermission = u.permissionIDs && !!u.permissionIDs.find(p => p === 3074);
          if (this.profileLoaded) {
            this.setNotificationCount();
          }
        } else {
          this.canManageOfferLetters = false;
          this.hasManagementPermission = false;
          this.hasStaffInjuriesPermission = false;
        }
        this.permissionsLoaded = true;
      }),
      this.store.select(s => s.profileState.profile).subscribe(p => {
        if (p) {
          this.profile = p;
          this.loadMenuItems();
          this.profileLoaded = true;
          if (this.permissionsLoaded) {
            this.setNotificationCount();
          }
        }
      }),
    );
  }

  setNotificationCount() {
    this.notificationCount = 0;
    if (this.canManageOfferLetters) {
      if (this.profile.newSignedOfferLettersCount != null) {
        this.notificationCount += this.profile.newSignedOfferLettersCount;
      }
      if (this.profile.newAnsweredReferenceSurveyCount != null) {
        this.notificationCount += this.profile.newAnsweredReferenceSurveyCount;
      }
    }
    if (this.hasManagePaylocityTransmissionPermission && this.profile.paylocityTransmissionsWithErrors != null) {
      this.notificationCount += this.profile.paylocityTransmissionsWithErrors;
    }
    if (this.hasStaffInjuriesPermission && this.profile.staffInjuriesQueueCount != null) {
      this.notificationCount += this.profile.staffInjuriesQueueCount;
    }
    if (this.hasManageInternApplicationPermission && this.profile.pendingInternApplications != null) {
      this.notificationCount += this.profile.pendingInternApplications;
    }
  }

  loadMenuItems() {
    this.items = [];
    const hrItems = [];
    if (this.profile.employee) {
      if ((!this.profile.offerLetter || this.profile.offerLetter.signed) && !this.profile.isOnBoarding) {
        hrItems.push(
          // ...patients,
          {
            label: 'Profile',
            icon: 'pi pi-fw pi-users',
            routerLink: ['/profile'],
            // queryParams: { show: 'all' }
          },
          {
            label: 'Documents',
            icon: 'fas fa-file-signature',
            routerLink: ['/onboardingdocs/list'],
            // queryParams: { show: 'all' }
          },
        );

        if (this.profile.pendingRequiredDocumentsCount <= 0 && (this.profile.caqhExpirationDate == null || new Date(this.profile.caqhExpirationDate) > new Date())) {
          hrItems.push(
            {
              label: 'Time Card',
              icon: 'pi pi-fw pi-clock',
              routerLink: ['/timecard'],
              // queryParams: { show: 'all' }
            }, {
            label: 'Payroll',
            icon: 'pi pi-fw pi-dollar',
            routerLink: ['/payroll'],
            // queryParams: { show: 'all' }
            }            
          );
          if (this.canViewEquipment) {
            hrItems.push({
              label: 'My Equipments',
              icon: 'fas fa-mobile-alt',
              routerLink: ['/equipment/my'],
              // queryParams: { show: 'all' }
            });
          }
        }

        if (this.hasStaffInjuriesPermission) {
          hrItems.push(
            {
              label: 'Staff Injuries Queue',
              icon: 'fas fa-medkit',
              routerLink: ['/injuryqueue']
            });
        }
      }
      if (this.profile.isOnBoarding) {
        hrItems.push(
          {
            label: 'Onboarding',
            icon: 'pi pi-fw pi-users',
            routerLink: ['/onboarding'],
            // queryParams: { show: 'all' }
          }
        );
      }
      if (this.profile.offerLetter) {
        hrItems.push(
          {
            label: 'Offer Letter',
            icon: 'fas fa-file-invoice-dollar',
            routerLink: ['/offerletter'],
            // queryParams: { show: 'all' }
          }
        );
      }
    }

    this.items.push({
      label: 'HR',
      items: hrItems
    });
    if (!this.profile.isOnBoarding) {
      this.items.push({
        label: 'Optomiser',
        items: [
          {
            label: 'Back to optomiser', icon: 'pi pi-fw pi-times', id: 'backToOptoButton',
            url: getOptoLinkFromUrl(),
          }]
      });
    }
    this.items.push({
      label: 'Account',
      items: [
        {
          label: 'Logout', icon: 'pi pi-fw pi-times', id: 'logoutButton',
          routerLink: ['/login']
        }]
    });
  }
}
