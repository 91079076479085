<div class="planner-panel">
  <div class="  mt-2" *ngIf="benefitPlanEnrollmentSet?.activatedDate">
    <h2 class="benefit-headers-font green">Enrollment {{benefitPlanEnrollmentSet.plansYear}} Completed</h2>
  </div>
  <div class="  mt-2" *ngIf="!benefitPlanEnrollmentSet?.activatedDate">
    <h2 class="benefit-headers-font red">Enrollment {{benefitPlanEnrollmentSet.plansYear}} not completed</h2>
  </div>

  <div class="  mt-2">
    <div class="description" *ngIf="isOpenEnrollment && !benefitPlanEnrollmentSet?.activatedDate">
      You can enrollment during the open enrollment period.
    </div>
    <div class="description" *ngIf="isOpenEnrollment && benefitPlanEnrollmentSet?.activatedDate">
      You can change your enrollment during the open enrollment period.
    </div>
    <div class="description" *ngIf="!isOpenEnrollment && benefitPlanEnrollmentSet?.activatedDate">
      You can change your enrollment only in case of life events such as marriage, divorce, change from Part Time to Full Time, loss of coverage, ...
    </div>

    <div class="description" *ngIf="!benefitPlanEnrollmentSet?.activatedDate && benefitPlanEnrollmentSet?.createdDate">
      If you want to cancel changing your benefits enrollment click below.

      <p class="description" *ngIf="benefitPlanEnrollmentSet?.lifeChangingEventID>1">
        Life changing event: {{getLifeEventLabel(benefitPlanEnrollmentSet)}}
        <br />
        Event date: {{benefitPlanEnrollmentSet?.lifeChangingEventDate | date:'shortDate'}}
      </p>
    </div>
  </div>
  <div class=" mt-2" *ngIf="!openChangeEnrollment">
    <button class="benefit-btn " *ngIf="benefitPlanEnrollmentSet?.activatedDate || !benefitPlanEnrollmentSet?.createdDate" (click)="clickChangeEnrollment()">{{benefitPlanEnrollmentSet?.createdDate ? 'Change Enrollment' : 'Start Enrollment'}}</button>

    <button class="benefit-btn " *ngIf="!benefitPlanEnrollmentSet?.activatedDate && benefitPlanEnrollmentSet?.createdDate" (click)="clickCancelEnrollment()">Cancel Enrollment</button>
    <button class="benefit-btn green mb-2 btn-lg btn-block  "
             *ngIf="(notSignedDocs>0)"
            [class.disabled]="savingBenefitEnrollment" routerLink="/benefits-enrollment/docs/list">
      SIGN PLANS FORMS
      <span class="badge badge-danger" *ngIf="notSignedDocs>0">{{notSignedDocs}}</span>
    </button>
    <button class="benefit-btn green ml-3  "
            *ngIf=" benefitPlanEnrollmentSet?.documentToSignID  && !benefitPlanEnrollmentSet?.signedDate"
            [class.disabled]="savingBenefitEnrollment"
            [routerLink]="'/benefits-enrollment/sign/'+benefitPlanEnrollmentSet?.id">
      FINISH AND SIGN
    </button>

  </div>
  <div class=" mb-2" *ngIf="openChangeEnrollment">
    <div class="description">Select the life changing event in order to proceed with the changes on your enrollment.</div>
    <p-dropdown styleClass="form-control mt-2 w-100" [options]="lifeEventOptions" placeholder="Select life event..." [(ngModel)]="lifeEventSelected"></p-dropdown>
    <div *ngIf="lifeEventSelected == 7">
      <input type="text" placeholder="Describe here the other life changing event" [value]="benefitPlanEnrollmentSet?.lifeChangingEventOther" [(ngModel)]="lifeEventOther" class="mt-2 form-control" />
    </div>
    <div class="mt-2">
      Event date:
      <p-calendar inputStyleClass="form-control mt-1"
                  [monthNavigator]="true"
                  [yearNavigator]="false"
                  styleClass="w-100"
                  panelStyleClass="calendarPanel"
                  appendTo="body"
                  [hourFormat]="12"
                  [minDate]="lifeEventMinDate"
                  [maxDate]="lifeEventMaxDate"
                  [showTime]="false"
                  placeholder="Select event date..."
                  [(ngModel)]="lifeEventDate"
                  dataType="string"></p-calendar>
    </div>
    <i *ngIf="savingBenefitEnrollment" class="material-icons rotate text-muted">loop</i>
    <button class="benefit-btn mt-3 " (click)="clickStartChangingEnrollment()">Start changing</button>
    <button class="benefit-btn gray mt-3 ml-3 " (click)="clickCancelChangingEnrollment()">Cancel</button>
  </div>
</div>
<div class="mt-2 " *ngIf="benefitPlanEnrollmentSet?.createdDate">
  <app-benefits-enrollment-detail [selectedBenefitPlans]="selectedBenefitPlans"
                                  [checkedDependent]="checkedDependent"
                                  [rejectedBenefitPlanTypes]="rejectedBenefitPlanTypes"
                                  [availableBenefitPlans]="availableBenefitPlans"
                                  [previousBenefitPlanSet]="previousBenefitPlanEnrollmentSet"

                                  (benefitPlanEnrollmentSaved)="benefitPlanEnrollmentSaved($event)"
                                  [(savingBenefitEnrollment)]="savingBenefitEnrollment"

                                  [hasUpdatedProfile]="hasUpdatedProfile"
                                  [activatedDate]="benefitPlanEnrollmentSet?.activatedDate"
                                  [benefitPlanEnrollmentSetID]="benefitPlanEnrollmentSet?.id"
                                  [plansYear]="benefitPlanEnrollmentSet?.plansYear"
                                  [lifeChangingEventDate]="benefitPlanEnrollmentSet?.lifeChangingEventDate"
                                  [lifeChangingEventID]="benefitPlanEnrollmentSet?.lifeChangingEventID"></app-benefits-enrollment-detail>

</div>
<div class=" mt-3" *ngIf="(notSignedDocs>0)">
  <i *ngIf="savingBenefitEnrollment" class="material-icons rotate text-muted">loop</i>
  <button class="benefit-btn green mb-2 btn-lg btn-block  " [class.disabled]="savingBenefitEnrollment" routerLink="/benefits-enrollment/docs/list">
    SIGN PLANS FORMS
    <span class="badge badge-danger" *ngIf="notSignedDocs>0">{{notSignedDocs}}</span>
  </button>
</div>
<div class=" mt-3" *ngIf=" benefitPlanEnrollmentSet?.documentToSignID  && !benefitPlanEnrollmentSet?.signedDate">
  <i *ngIf="savingBenefitEnrollment" class="material-icons rotate text-muted">loop</i>
  <button class="benefit-btn green mb-2 btn-lg btn-block " [class.disabled]="savingBenefitEnrollment" [routerLink]="'/benefits-enrollment/sign/'+benefitPlanEnrollmentSet?.id">
    FINISH AND SIGN
  </button>
</div>

