import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { Actions, ofType } from '@ngrx/effects';
import { State } from '../../../../core/store';
import { Store } from '@ngrx/store';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { TimeCardModel } from '../../../../models/timeCardModel';
import { loadEmployeeUsers, loadViewableCampuses } from '../../../../core/store/employee/employee.action';
import { deepClone, downloadFile, getTimeStampString } from '../../../../helpers/utils';
import { exportManualEntriesReportToExcel, exportManualEntriesReportToExcelSuccess, getManualEntriesReport, loadManualEntryReasons } from '../../../../core/store/timecard/timecard.actions';
import { tap } from 'rxjs/operators';
import { loadPayrollApprovalPeriods, loadPayrollClassifications } from '../../../../core/store/payroll-approval/payroll-approval.actions';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-list-timecard-manual-entries',
  templateUrl: './list-timecard-manual-entries.component.html',
  styleUrls: ['./list-timecard-manual-entries.component.scss']
})
export class ListTimecardManualEntriesComponent extends BaseComponent implements OnInit {
  list: PagingResultsModel<TimeCardModel>;
  filter: any;

  @ViewChild('timeCardTable', { static: true }) table!: Table;

  loadingList: boolean = true;

  campusList: SelectItem[];
  campusId: number = 0;

  descriptionFilter: string; 
  prefixText: string;
  userEmployeeList: SelectItem[];
  userId: number = 0;
  inputUser: SelectItem;
  
  payPeriods: SelectItem[];
  payPeriodId: number = 0;

  payrollClassificationId: number = 0;
  payrollClassificationList: SelectItem[];

  manualEntryReasonList: SelectItem[];
  manualEntryReasonId: number = 0;

  approvalStatusList: SelectItem[] = [
    { label: "All", value: "All" },
    { label: "Approved", value: "Approved" },
    { label: "Denied", value: "Denied" },
    { label: "Pending", value: "Pending" },
    { label: "Adjustment", value: "Adjustment" },
    { label: "Fix", value: "Fix" },
  ];
  approvalStatus: string;

  showTerminatedEmployees: boolean = false;
  showInactiveUsers: boolean = false;
  showEntriesWithoutReason: boolean = false;

  constructor(
    private actions$: Actions,
    private store: Store<State>) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.timecardState.loadingManualEntriesReport).subscribe(loadingManualEntryList => {
        this.loadingList = loadingManualEntryList;
      }),
      this.store.select(s => s.employeeState.campuses).subscribe(campuses => {
        this.campusList = campuses && campuses.length > 0 ? campuses
          : [{ label: 'loading...', value: undefined }];
      }),
      this.store.select(s => s.payrollApprovalState.payrollClassifications).subscribe(payrollClassifications => {
        if (payrollClassifications) {
          this.payrollClassificationList = payrollClassifications.map(x => ({ label: x.payrollClassification, value: x.id }));
          this.payrollClassificationList.unshift({ label: 'All', value: 0 });
        }
        else {
          this.payrollClassificationList = [{ label: 'loading...', value: undefined }];
        }
      }),
      this.store.select(s => s.payrollApprovalState.periods).subscribe(periods => {
        if (periods) {
          this.payPeriods = periods.map(x => ({ label: x.payPeriod, value: x.payPeriodID }));
          this.payPeriods.unshift({ label: 'All', value: 0 });
        }
        else {
          this.payPeriods = [{ label: 'loading...', value: undefined }];
        }
      }),
      this.store.select(s => s.timecardState.manualEntryReasons).subscribe(reasons => {
        if (reasons) {
          this.manualEntryReasonList = reasons.map(x => ({ label: x.reason, value: x.id }));
          this.manualEntryReasonList.unshift({ label: 'All', value: 0 });
        }
        else {
          this.manualEntryReasonList = [{ label: 'loading...', value: undefined }];
        }
      }),
      this.store.select(s => s.employeeState.employeeUsers).subscribe(users => {
        this.userEmployeeList = users;
      }),
      this.store.select(s => s.timecardState.manualEntriesList).subscribe(list => {
        this.list = list;
      }),
      this.actions$.pipe(
        ofType(exportManualEntriesReportToExcelSuccess),
        tap(action => {
          downloadFile(action.doc, this.getExcelFileName());
        })
      ).subscribe(),
    );

    this.store.dispatch(loadViewableCampuses());
    this.store.dispatch(loadPayrollClassifications());
    this.store.dispatch(loadPayrollApprovalPeriods());
    this.store.dispatch(loadManualEntryReasons());
  }

  getExcelFileName() {
    let fileName = 'ManualEntriesReport_';
    
    fileName += getTimeStampString();
    fileName += ".xlsx"

    return fileName;
  }

  changeFilters(event){
    this.reloadFilter(event);
    this.table.reset();
  }

  reloadFilter(event) {
    this.filter = event;
    this.reloadList();
  }

  reloadList() {
    this.setFilter()
    this.store.dispatch(getManualEntriesReport({
      filter: this.filter
    }));
  }

  setFilter() {
    this.filter = deepClone(this.filter);
    this.filter.filters.userId = { Name: 'userId', Value: { value: this.userId.toString() } };
    this.filter.filters.programId = { Name: 'programId', Value: { value: this.campusId.toString() } };
    this.filter.filters.payrollClassificationId = { Name: 'payrollClassificationId', Value: { value: this.payrollClassificationId.toString() } };
    this.filter.filters.payPeriodId = { Name: 'payPeriodId', Value: { value: this.payPeriodId.toString() } };
    this.filter.filters.manualEntryReasonId = { Name: 'manualEntryReasonId', Value: { value: this.manualEntryReasonId.toString() } };
    this.filter.filters.approvalStatus = { Name: 'approvalStatus', Value: { value: this.approvalStatus } };
    this.filter.filters.showTerminatedEmployees = { Name: 'showTerminatedEmployees', Value: { value: this.showTerminatedEmployees.toString() } };
    this.filter.filters.showInactiveUsers = { Name: 'showInactiveUsers', Value: { value: this.showInactiveUsers.toString() } };
    this.filter.filters.showEntriesWithoutReason = { Name: 'showEntriesWithoutReason', Value: { value: this.showEntriesWithoutReason.toString() } };
    this.filter.filters.descriptionFilter = { Name: 'descriptionFilter', Value: { value: this.descriptionFilter } };
  }

  getStaffList(event) {
    this.store.dispatch(loadEmployeeUsers({
      prefixText: event.query,
      programId: this.campusId,
      payrollClassificationId: null,
      showInactive: false,
      showTerminated: false
    }));
  }

  reloadListAfterStaffChage(selectedUser) {
    if (typeof selectedUser.value !== 'number') {
      return;
    }
    this.table.reset();
    this.userId = selectedUser.value;
    this.reloadList();
  }

  reloadListAfterClear(event) {
    if (event.data !== null) {
      return;
    }
    this.table.reset();
    this.userId = 0;
    this.reloadList();
  }

  setUserID(e){
    this.userId = e.value;
    if (this.userId == null){
      this.userId = 0;
    }
    this.changeFilters(this.filter);
  }

  exportToExcel() {
    if (this.loadingList) {
      return;
    }
    this.setFilter();
    this.store.dispatch(exportManualEntriesReportToExcel({
      filter: this.filter
    }));
  }

}
