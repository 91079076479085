import { UserProfileModel } from './../../../../../models/userProfileModel';
import { saveProfile, loadProfile, saveProfileSuccess, updateProfileI9Form } from '../../../../../core/store/profile/profile.actions';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from '../../../../../shared/services/alert.service';
import { Component, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../../core/store';
import { Actions, ofType } from '@ngrx/effects';
import * as moment from 'moment';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { FormGroup } from '@angular/forms';
import { getAllControlsErrors, markFormGroupTouched, deepClone } from 'src/app/helpers/utils';
import { initProfileI9Form } from 'src/app/core/store/profile/profile.reducer';
import { tap } from 'rxjs/operators';
import { setOnboardingDocsInfoFilled, setOnboardingDocsInfoFilledSuccess } from 'src/app/core/store/onboardingdocs/onboardingdocs.actions';
import { loadManageOnboardingUserInfo, saveUserProfile, updateProfileI9HRForm, saveUserProfileSuccess } from 'src/app/core/store/onboarding/onboarding.actions';
import { initProfileI9HRForm } from 'src/app/core/store/onboarding/onboarding.reducer';

@Component({
  selector: 'app-doc-fill-info-i9form-hr',
  templateUrl: './doc-fill-info-i9form-hr.component.html',
  styleUrls: ['./doc-fill-info-i9form-hr.component.scss']
})
export class DocFillInfoi9FormHRComponent extends BaseComponent implements OnInit {

  @Input()
  onboardingDocUserId;

  @Input()
  userId;

  @Input()
  doNotNavigate;

  profile: UserProfileModel;
  yearRange = '1900:' + new Date(new Date().setFullYear(new Date().getFullYear() + 15)).getFullYear();
  i9USSituationOptions = [
    { label: 'A citizen of the United States', value: 'USCitizen' },
    { label: 'A noncitizen national of the United States', value: 'National' },
    { label: 'A lawful permanent resident', value: 'PermanentResident' },
    { label: 'An alien authorized to work', value: 'AuthorizedToWork' },
  ];
  yesNoOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];
  form: FormGroup;
  i9ListA1ExpirationDateNA;
  i9ListA2ExpirationDateNA;
  i9ListA3ExpirationDateNA;
  i9ListBExpirationDateNA;
  i9ListCExpirationDateNA;
  i9RehireExpirationDateNA;

  constructor(
    private store: Store<State>,
    private actions$: Actions,
    private alert: AlertService,
    private router: Router,
    private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.onboardingState.manageOnboardingUserInfo).subscribe(manageOnboardingUserInfo => {
        if (manageOnboardingUserInfo) {
          this.profile = deepClone(manageOnboardingUserInfo.user);
          if (this.profile.employee.i9EmploymentFirstDate) {
            this.profile.employee.i9EmploymentFirstDate = new Date(this.profile.employee.i9EmploymentFirstDate);
          }
          if (this.profile.employee.i9RehireDate) {
            this.profile.employee.i9RehireDate = new Date(this.profile.employee.i9RehireDate);
          }
          if (this.profile.employee.i9RehireExpirationDate) {
            this.profile.employee.i9RehireExpirationDate = new Date(this.profile.employee.i9RehireExpirationDate);
          }


          if (this.profile.employee.i9ListA1ExpirationDate) {
            this.profile.employee.i9ListA1ExpirationDate = new Date(this.profile.employee.i9ListA1ExpirationDate);
          }

          if (this.profile.employee.i9ListA2ExpirationDate) {
            this.profile.employee.i9ListA2ExpirationDate = new Date(this.profile.employee.i9ListA2ExpirationDate);
          }
          if (this.profile.employee.i9ListA3ExpirationDate) {
            this.profile.employee.i9ListA3ExpirationDate = new Date(this.profile.employee.i9ListA3ExpirationDate);
          }
          if (this.profile.employee.i9ListBExpirationDate) {
            this.profile.employee.i9ListBExpirationDate = new Date(this.profile.employee.i9ListBExpirationDate);
          }
          if (this.profile.employee.i9ListCExpirationDate) {
            this.profile.employee.i9ListCExpirationDate = new Date(this.profile.employee.i9ListCExpirationDate);
          }

          if (!this.form) {
            this.form = initProfileI9HRForm(this.profile);
            this.subs.push(
              this.form.valueChanges.subscribe(formValues => {
                const userValues = this.form.get('user').value;
                const employeeValues = this.form.get('employee').value;
                const formErrors = getAllControlsErrors(this.form);
                this.store.dispatch(updateProfileI9HRForm({ userValues, employeeValues, formErrors }));
              })
            );
          }
        }
      }),
      this.actions$.pipe(
        ofType(saveUserProfileSuccess),
        tap(action => {
          this.store.dispatch(setOnboardingDocsInfoFilled({ onboardingDocUserId: this.onboardingDocUserId }));
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(setOnboardingDocsInfoFilledSuccess),
        tap(action => {
          if (!this.doNotNavigate) {
            this.router.navigate(['../../sign/' + this.onboardingDocUserId], { relativeTo: this.route });
          }
        })
      ).subscribe()
    );

    if (!this.userId) {
      this.userId = this.route.snapshot.parent.params.userid;
    }
    this.store.dispatch(loadManageOnboardingUserInfo({ userId: this.userId }));
  }

  save() {
    if (this.form.valid) {
      this.store.dispatch(saveUserProfile({ userId: this.profile.user.id, profile: this.profile }));
    } else {
      markFormGroupTouched(this.form);
    }
  }

  eraseDate(check, datePath) {
    if (check) {
      this.form.get('employee.' + datePath).setValue(null);
    }
  }
}
