import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'stringJoin'
})

export class StringJoinPipe implements PipeTransform {


  transform(value: string[], separator?: string): string {
    if (!value)
      return "";
    if (!separator)
      separator = "<br />";
    return value.join(separator);
  }
}

