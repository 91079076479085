
<div [formGroup]="form" *ngIf="profile!=null">
    <h5 class="card-title">Update profile for filling the document</h5>
    This document require some extra infos to be filled, please provide the infos bellow:<br>
    (the info will be saved on your profile and used to fill the document)
    <div class="row">
      <div class="col-12 col-xl-6 ">
    <div class="row">
      <div class="col-4 d-flex align-items-center">
        <b>Applicant Name</b>
      </div>
      <div class="col-8  pt-2 pb-2" >
        {{ profile.user.firstName }} {{ profile.user.lastName }}
      </div>
    </div>

    <div class="row" formGroupName="employee" >
      <div class="col-4 d-flex align-items-center">
        <b>Application Number</b>
      </div>
      <div class="col-8">
        <input type="text" class="form-control mt-2" formControlName="cbsApplicationNumber"/>
        <app-validation-message [form]="form" [path]="'employee.cbsApplicationNumber'" [name]="'cbsApplicationNumber'" [label]="'Application Number'"> </app-validation-message>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-6">
        <button class="btn btn-primary" [class.disabled]="form.invalid" (click)="save()">Update and Sign</button>
      </div>
    </div>
  </div>
</div>
  </div>
