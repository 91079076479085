<form class="card mt-2">
  <div class="card-body">
    
    <div class="float-right">

      <a class="btn btn-secondary  ml-2 float-right" [routerLink]="'/benefitPlanEDI/current/MotivHealth'">MotivHealth Members</a>
      <a class="btn btn-secondary ml-2 float-right" [routerLink]="'/benefitPlanEDI/current/Samera Health'">Samera Health Members</a>

    </div>
    <h2 class="mb-3">Benefit Plan EDI Transmission Report</h2>
    <div class="row mb-3">

      <div class="col-6">

        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <h5>Carrier:</h5>
          </div>
          <div class="col-8">
            <input type="text" [(ngModel)]="carrierFilter" class="form-control  ml-2 w-100 mb-2" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}" />
          </div>
        </div>
        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <h5>FileName:</h5>
          </div>
          <div class="col-8">
            <input type="text" [(ngModel)]="fileNameFilter" class="form-control  ml-2 w-100 mb-2" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}" />
          </div>
        </div>
        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <h5>Date Sent:</h5>
          </div>
          <div class="col-8">
            <i *ngIf="!dateRangeList" class="material-icons rotate text-muted">loop</i>
            <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="dateRangeList" [(ngModel)]="dateRange" (ngModelChange)="changedDateRange()" [ngModelOptions]="{standalone: true}"></p-dropdown>
          </div>
        </div>

        <div class="row" *ngIf="isCustomDateRange">
          <div class="col-4 d-flex align-items-center">
            <h5>Start Date:</h5>
          </div>
          <div class="col-8">
            <input type="date" class="form-control ml-2 w-100 mb-2" [(ngModel)]="startDate" (blur)="reloadList(this.filter)" [ngModelOptions]="{standalone: true}" />
          </div>
          <div class="col-4 d-flex align-items-center">
            <h5>End Date:</h5>
          </div>
          <div class="col-8">
            <input type="date" class="form-control ml-2 w-100 mb-2" [(ngModel)]="endDate" (blur)="reloadList(this.filter)" [ngModelOptions]="{standalone: true}" />
          </div>
        </div>

      </div>

    </div>
    <i [hidden]="!loadingList" class="material-icons rotate text-muted">loop</i>
    <p-table [value]="list?.result" [responsive]="true" [paginator]="true" [rows]="20"
             [totalRecords]="list?.totalRecords" [lazy]="true" (onLazyLoad)="reloadList($event)">
      <ng-template pTemplate="header">
        <tr>
          <th [pSortableColumn]="'DateSent'">Date Sent <p-sortIcon [field]="'DateSent'" style="width: 120px;"></p-sortIcon></th>
          <th [pSortableColumn]="'Carrier'">
            Carrier <p-sortIcon [field]="'Carrier'"></p-sortIcon>
          </th>
          <th [pSortableColumn]="'FileName'">
            FileName <p-sortIcon [field]="'FileName'"></p-sortIcon>
          </th>
          <th [pSortableColumn]="'Description'">Description <p-sortIcon [field]="'Description'"></p-sortIcon></th>
          <th [pSortableColumn]="'Notes'">Notes <p-sortIcon [field]="'Notes'"></p-sortIcon></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-list>
        <tr>
          <td>
            <span class="ui-column-title">DateSent</span>
            {{list.dateSent | date: 'MM/dd/yyyy HH:mm:ss a'}}
          </td>
          <td>
            <span class="ui-column-title">Carrier</span>
            {{list.carrier}}
          </td>
          <td>
            <span class="ui-column-title">FileName</span>
            <a [routerLink]="'/benefitPlanEDI/tramission/'+list?.transmissionId">{{list.fileName}}</a>
          </td>
          <td>
            <span class="ui-column-title">Description</span>
            {{list.description}}
          </td>
          <td>
            <span class="ui-column-title">Notes</span>
            {{list.notes}}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</form>
