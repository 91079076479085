import { JobTitleHasJobApplicationFormModel } from './../../../../models/screeningInterviewModel';
import { getResume, getResumeSuccess, noShowProspectiveCandidateSuccess } from './../../../../core/store/prospective-candidates/prospective-candidate.actions';
import { ProspectiveCandidateModel } from './../../../../models/prospectiveCandidateModel';
import { loadCampuses } from 'src/app/core/store/payroll-approval/payroll-approval.actions';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import {
  getOfferLettersList, getOfferLetterPdf,
  deleteOfferLetter, deleteOfferLetterSuccess, markOfferLetterSeen
} from '../../../../core/store/offerletter/offerletter.actions';
import { OfferLetterWithUserModel } from 'src/app/models/offerletterModel';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Router } from '@angular/router';
import { loadJobTitles } from 'src/app/core/store/profile/profile.actions';
import { deepClone } from 'src/app/helpers/utils';
import { SelectItem } from 'primeng/api';
import { MultiSelect } from 'primeng/multiselect';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { deleteProspectiveCandidate, deleteProspectiveCandidateSuccess, getProspectiveCandidates, noShowProspectiveCandidate } from 'src/app/core/store/prospective-candidates/prospective-candidate.actions';
import { ScreeningInterviewModel } from 'src/app/models/screeningInterviewModel';
import { loadJobApplicationForms } from 'src/app/core/store/job-applications/job-applications.action';

@Component({
  selector: 'app-list-prospective-candidates',
  templateUrl: './list-prospective-candidates.component.html',
  styleUrls: ['./list-prospective-candidates.component.scss']
})
export class ListProspectiveCandidatesComponent extends BaseComponent implements OnInit {

  modalReference: any;
  screeningModalOptions: NgbModalOptions;
  prospectiveCandidates: PagingResultsModel<ProspectiveCandidateModel>;
  canEdit: boolean;
  canView: boolean;
  loadingProspectiveCandidates = false;
  filter: any;
  loading = false;
  jobTitlesList = [{ label: 'loading...', value: undefined }];
  yesNoAllOptions = [
    { label: 'All', value: null },
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];
  datesOptions = [
    { label: 'Last 30 days', value: 30 },
    { label: 'Last 60 days', value: 60 },
    { label: 'Last 90 days', value: 90 },
    { label: 'Custom', value: 0 },
  ];

  showHideString: string = "Hide";
  hideFilter: boolean = false;

  employeeNameFilter;
  emailFilter;
  createdDateFilter;
  positionsFilter;
  createdDateOptionFilter;
  removedFilter;
  regionFilter;
  onlyReferralsFilter;
  referredByFilter;
  campusesList = [];

  date = true;
  region = true;
  position = true;
  name = true;
  email = true;
  references = true;
  offerLetter = true;
  interviewDate = true;
  contactLog = true;
  referredBy = true;

  mostRecentLog;
  customDates: boolean;
  resumeURL: string;
  prospectiveCandidateID: number;
  programID: number;
  jobAppID: number;
  firstName: string;
  lastName: string;
  positionValue: string;
  formSubmissionID?: number;

  jobTitleForms: JobTitleHasJobApplicationFormModel[] = [];

  constructor(
    private actions$: Actions,
    private alertService: AlertService,
    private store: Store<State>,
    private modalService: NgbModal,
    private router: Router,
  ) {
    super();
    this.screeningModalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop',
      size: 'lg'
    }
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.canEdit = permissionIDs && !!permissionIDs.find(p => p === 3091);
        this.canView = permissionIDs && !!permissionIDs.find(p => p === 3090);
      }),
      this.store.select(s => s.profileState.jobTitles).subscribe(jobTitles => {
        this.jobTitlesList = jobTitles && jobTitles.length > 0 ? jobTitles.filter(x => x.archived == false).map(c => ({ label: c.jobTitle, value: c.jobTitle }))
          : [{ label: 'loading...', value: undefined }];
      }),
      this.store.select(s => s.prospectiveCandidateState.prospectiveCandidates).subscribe(prospectiveCandidates => {
        if (prospectiveCandidates) {
          this.prospectiveCandidates = prospectiveCandidates;
        }
      }),

      this.store.select(s => s.prospectiveCandidateState.loadingProspectiveCandidates).subscribe(loadingProspectiveCandidates => {
        this.loading = loadingProspectiveCandidates;
      }),

      this.actions$.pipe(
        ofType(deleteProspectiveCandidateSuccess, noShowProspectiveCandidateSuccess),
        tap(action => {
          this.alertService.success('Prospective Candidate deleted.');
          this.store.dispatch(getProspectiveCandidates({ filter: this.filter }));
        })
      ).subscribe(),     

      this.store.select(s => s.accountState.user.programIDs).subscribe(programIDs => {
        if (programIDs) {
          this.subs.push(
            this.store.select(s => s.payrollApprovalState.campuses).subscribe(campuses => {
              let campusesList = campuses && campuses.length > 0 ? campuses.filter(x => programIDs.includes(x.id)).map(c => ({ label: c.programName, value: c.id }))
                : [{ label: 'loading...', value: undefined }];
              this.campusesList = campusesList;
              this.campusesList.unshift({ label: 'All', value: null });
            }),
          );
        }
      }),

      this.store.select(s => s.jobApplicationsState.jobTitleForms).subscribe(jobTitleForms => {
        if (jobTitleForms != null) {
          this.jobTitleForms = jobTitleForms;

        }
      }),
    );
    if (window.sessionStorage) {

      var jsonDataText = sessionStorage.getItem("prospectiveCandidateFilter");
      if (jsonDataText) {
        const jsonData = JSON.parse(jsonDataText);
        this.employeeNameFilter = jsonData.employeeNameFilter == "" ? null : jsonData.employeeNameFilter;
        this.emailFilter = jsonData.emailFilter == "" ? null : jsonData.emailFilter;
        this.createdDateFilter = jsonData.createdDateFilter;
        this.positionsFilter = jsonData.positionsFilter;
        if (!this.createdDateFilter) {
          this.createdDateOptionFilter = jsonData.createdDateOptionFilter;
        }
        else {
          this.customDates = true;
        }
        this.removedFilter = jsonData.removedFilter;
        this.regionFilter = jsonData.regionFilter;
        this.campusesList = jsonData.campusesList;

      }
    }
    this.store.dispatch(loadJobTitles());
    this.store.dispatch(loadCampuses());
    this.store.dispatch(loadJobApplicationForms());
  }


  loadPage(event) {
    if (!this.loading) {
      this.loading = true;
      this.filter = deepClone(event);
      this.filter.filters.employeeNameFilter = { Name: 'employeeNameFilter', Value: { value: this.employeeNameFilter } };
      this.filter.filters.emailFilter = { Name: 'emailFilter', Value: { value: this.emailFilter } };
      this.filter.filters.positionsFilter = { Name: 'positionsFilter', Value: { value: this.positionsFilter ? this.positionsFilter.join(',') : null } };
      this.filter.filters.removedFilter = { Name: 'removedFilter', Value: { value: this.removedFilter ? 'true' : 'false' } };
      this.filter.filters.regionFilter = { Name: 'regionFilter', Value: { value: this.regionFilter } };
      this.filter.filters.referredByFilter = { Name: 'referredByFilter', Value: { value: this.referredByFilter } };
      this.filter.filters.onlyReferralsFilter = { Name: 'onlyReferralsFilter', Value: { value: this.onlyReferralsFilter } };
      if (this.createdDateOptionFilter > 0) {
        const today = new Date();
        const tomorrow = new Date(new Date().setDate(today.getDate() + 1));
        const priorDate = new Date(new Date().setDate(today.getDate() - this.createdDateOptionFilter));
        this.filter.filters.createdDateFilterStart = { Name: 'createdDateFilterStart', Value: { value: priorDate.toISOString() } };
        this.filter.filters.createdDateFilterEnd = { Name: 'createdDateFilterEnd', Value: { value: tomorrow.toISOString() } };
      } else {
        if (this.createdDateFilter) {
          this.filter.filters.createdDateFilterStart = { Name: 'createdDateFilterStart', Value: { value: this.createdDateFilter[0] } };
          this.filter.filters.createdDateFilterEnd = { Name: 'createdDateFilterEnd', Value: { value: this.createdDateFilter[1] } };
        } else {
          this.filter.filters.createdDateFilterStart = { Name: 'createdDateFilterStart', Value: { value: null } };
          this.filter.filters.createdDateFilterEnd = { Name: 'createdDateFilterEnd', Value: { value: null } };
        }
      }

      this.store.dispatch(getProspectiveCandidates({
        filter: deepClone(this.filter)
      }));
    }
  }

  toggleHideFilter() {
    if (this.hideFilter) {
      this.hideFilter = false;
      this.showHideString = "Hide";
    }
    else {
      this.hideFilter = true;
      this.showHideString = "Show";
    }
  }


  changedFilters() {
    this.loadPage(this.filter);
    this.saveFilters();
  }

  editColumns(columnValue) {
    if (columnValue == "date") { this.date = !this.date }
    else if (columnValue == "region") { this.region = !this.region }
    else if (columnValue == "position") { this.position = !this.position }
    else if (columnValue == "name") { this.name = !this.name }
    else if (columnValue == "email") { this.email = !this.email }
    else if (columnValue == "references") { this.references = !this.references }
    else if (columnValue == "offerLetter") { this.offerLetter = !this.offerLetter }
    else if (columnValue == "interviewDate") { this.interviewDate = !this.interviewDate }
    else if (columnValue == "contactLog") { this.contactLog = !this.contactLog }
    else if (columnValue == "referredBy") { this.referredBy = !this.referredBy }
  }

  clearFilters() {
    this.employeeNameFilter = null;
    this.emailFilter = null;
    this.createdDateFilter = null;
    this.positionsFilter = null;
    this.createdDateOptionFilter = null;
    this.removedFilter = null;
    this.regionFilter = null;
    this.customDates = false;
    this.referredByFilter = null;
    this.onlyReferralsFilter = null;
    sessionStorage.clear();
    this.loadPage(this.filter);
  }

  openLink(path) {
    this.router.navigate([path]);
  }

  saveFilters() {
    var jsonData = {}
    if (window.sessionStorage) {
      // save filter to storage
      jsonData = {
        employeeNameFilter: this.employeeNameFilter,
        emailFilter: this.emailFilter,
        createdDateFilter: this.createdDateOptionFilter != 0 ? null : this.createdDateFilter,
        positionsFilter: this.positionsFilter,
        createdDateOptionFilter: this.createdDateOptionFilter,
        removedFilter: this.removedFilter,
        regionFilter: this.regionFilter,
        campusesList: this.campusesList
      };
      if (jsonData["createdDateFilter"] == null) {
        this.customDates = false;
      }
    }
    sessionStorage.setItem("prospectiveCandidateFilter", JSON.stringify(jsonData));
  }

  deleteProspectiveCandidate(prospectiveCandidate: ProspectiveCandidateModel) {
    this.alertService.confirm('Are you sure?',
      'Deleting the candidate. Are you sure?')
      .subscribe(
        anwser => {
          if (anwser) {
            this.store.dispatch(deleteProspectiveCandidate({ prospectiveCandidateID: prospectiveCandidate.id }));
          }
        }, error => {
        }
      );
  }

  noShowProspectiveCandidate(prospectiveCandidate: ProspectiveCandidateModel) {
    this.alertService.confirm('Are you sure?',
      'This will delete the candidate and set him as No Show. Are you sure?')
      .subscribe(
        anwser => {
          if (anwser) {
            this.store.dispatch(noShowProspectiveCandidate({ prospectiveCandidateID: prospectiveCandidate.id }));
          }
        }, error => {
        }
      );
  }

  getResume(prospectiveCandidate: ProspectiveCandidateModel) {
    this.store.dispatch(getResume({ prospectiveCandidateID: prospectiveCandidate.id }));
  }

  editProspectiveCandidate(prospectiveCandidate: ProspectiveCandidateModel) {
    this.router.navigate(['/prospective-candidate/addit/' + prospectiveCandidate.id]);
  }

  openMultiSelect(multiselect: MultiSelect) {
    multiselect.show();
  }

  openPhoneScreening(content, app: ProspectiveCandidateModel) {
    this.jobAppID = app.jobApplicationID;
    this.firstName = app.firstName;
    this.lastName = app.lastName;
    this.positionValue = app.position;
    this.formSubmissionID = app.formSubmissionID;
    this.prospectiveCandidateID = app.id;
    this.programID = app.programID;
    this.modalReference = this.modalService.open(content, this.screeningModalOptions)
    this.modalReference.result.then((result) => {
    }, (reason) => {
    });
  }

  closeScreener($event){
    this.modalReference.close($event);
    this.loadPage(this.filter);
  }

  hasScreeningForm(prospectiveCandidate: ProspectiveCandidateModel) {
    return !!this.jobTitleForms.find(x => x.jobTitle === prospectiveCandidate.position);
  }
}

