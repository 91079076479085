<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Form Assignments - {{ usersName }}</h4>
    <button type="button" class="close btn" aria-label="Close"
    (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
    </button>
</div>
<form class="card mt-2" *ngIf="canEdit">
    <div class="card-body">
      <h5>Filter <button class="btn btn-link ml-2" (click)="toggleFilter()">{{ showFilter }}</button></h5>
      <div class="row mb-1" [hidden]="showFilter == 'Show'">
        <div class="row col-12">
            <div class="row col-6 col-sm-12 mb-1">
            <div class="col-4 d-flex align-items-center">
              <h6>Form:</h6>
            </div>
            <div class="col-8 ">
              <p-multiSelect #formNameMultiSelect class="w-100" styleClass="w-100 d-flex mt-2" inputStyleClass="form-control" (onFocus)="openMultiSelect(formNameMultiSelect)" [options]="formOptions" [defaultLabel]="'Select Form...'" [ngModelOptions]="{standalone: true}" [(ngModel)]="filterFormID" (ngModelChange)="changedFilters()"></p-multiSelect>
            </div>
          </div>
          <div class="row col-6 col-sm-12 mb-1">
            <div class="col-4 d-flex align-items-center">
              <h6>Signed:</h6>
            </div>
            <div class="col-8 ">
              <p-dropdown styleClass="form-control mt-2 w-100" [options]="yesNoAllOptions" placeholder="Select type..." [ngModelOptions]="{standalone: true}" [(ngModel)]="filterSigned" (ngModelChange)="changedFilters()"></p-dropdown>
            </div>
          </div>
          <div class="row col-6 col-sm-12 mb-1">
            <div class="col-4 d-flex align-items-center">
              <h6>Required:</h6>
            </div>
            <div class="col-8 ">
              <p-dropdown styleClass="form-control mt-2 w-100" [options]="yesNoAllOptions" placeholder="Select type..." [ngModelOptions]="{standalone: true}" [(ngModel)]="filterRequired" (ngModelChange)="changedFilters()"></p-dropdown>
            </div>
          </div>
        </div>
      </div>
  
      <i *ngIf="!formsAssignementList " class="material-icons rotate text-muted">loop</i>
      <p-table #userFormsTable [value]="formsAssignementList?.result" [responsive]="true"
               [paginator]="true" [rows]="20" [totalRecords]="formsAssignementList?.totalRecords" [lazy]="true"
               (onLazyLoad)="loadPage($event)">
        <ng-template pTemplate="header">
          <tr>
            <th width="7%" class="text-center"></th>
            <th width="11%" class="text-center">Can opt out</th>
            <th class="text-center" [pSortableColumn]="'Name'">
              Form Name
              <p-sortIcon [field]="'Name'"></p-sortIcon>
            </th>
            <th width="15%" class="text-center" [pSortableColumn]="'User'">
              User
              <p-sortIcon [field]="'User'"></p-sortIcon>
            </th>
            <th width="15%" class="text-center" [pSortableColumn]="'User'">
              Patient Inquiry
              <p-sortIcon [field]="'User'"></p-sortIcon>
            </th>
            <th width="11%" class="text-center" [pSortableColumn]="'IsRequired'">
              Required
              <p-sortIcon [field]="'IsRequired'"></p-sortIcon>
            </th>
            <th width="11%" class="text-center" [pSortableColumn]="'DueDate'">
              Due Date
              <p-sortIcon class="text-center" [field]="'DueDate'"></p-sortIcon>
            </th>
            <th width="11%" class="text-center" [pSortableColumn]="'Status'">
              Status
              <p-sortIcon [field]="'Status'"></p-sortIcon>
            </th>
  
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-formAssignment>
          <tr>
            <td class="text-center">
  
              <i *ngIf="loadingDoc && loadingDocAssignmentId===formAssignment?.id" class="material-icons rotate text-muted">loop</i>
              <ng-container *ngIf="!loadingDoc">
                <span class="pointer mr-2" *ngIf="formAssignment?.formSubmission?.signedDate" (click)="downloadForm(formAssignment)"><i title="Download signed Form Submission" class="fas fa-file-download"></i></span>
                <span class="pointer mr-2" *ngIf="formAssignment?.formSubmission" (click)="seeFormResult(formAssignment)"><i title="See Form Information" class="fab fa-wpforms"></i></span>
                <span class="pointer mr-2" *ngIf="!formAssignment?.formSubmission?.signedDate" (click)="deleteForm(formAssignment)"><i title="Delete Form Assignment" class="fas fa-trash"></i></span>
              </ng-container>
            </td>
            <td class="text-center">
              <p-checkbox [binary]="true" [disabled]="!formAssignment?.form?.canOptOut" [ngModel]="formAssignment.canOptOut" (ngModelChange)="changedOptOut(formAssignment)" [ngModelOptions]="{standalone: true}"></p-checkbox>
            </td>
            <td class="text-center">
              <span class="ui-column-title">Form Name</span>
              {{formAssignment.form.name }}
            </td>
  
            <td class="text-center">
              <span class="ui-column-title">User</span>
              {{formAssignment.user?.firstName }} {{formAssignment.user?.lastName }}
            </td>
  
            <td class="text-center">
              <span class="ui-column-title">Patient</span>
              {{formAssignment.inquiry?.name }}
            </td>
            <td class="text-center">
              <span class="ui-column-title">Required</span>
              {{formAssignment.isRequired?'Required':''}}
            </td>
  
            <td class="text-center">
              <span class="ui-column-title">Due Date</span>
              {{formAssignment.dueDate | date:'shortDate' }}
            </td>
  
            <td class="text-center">
              <span class="ui-column-title">Status</span>
              
              {{(formAssignment?.formSubmission && !formAssignment?.formSubmission?.signedDate && !formAssignment?.formSubmission?.optOutDate) ? 'Filling':''  }}
              {{formAssignment?.formSubmission?.signedDate ? 'Signed':'' }}
              {{formAssignment?.formSubmission?.optOutDate ? 'Opted Out':''  }}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </form>
  <div class="table-buttons modal-footer">
    <button type="button" class="btn btn-secondary float-right" (click)="closeModal()">Close</button>
  </div>
  
  <p-confirmDialog></p-confirmDialog>