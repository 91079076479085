import { CreateEquipmentComponent } from './../create-equipment/create-equipment.component';
import { EquipmentModel, AssignedEquipmentModel, EquipmentAcessoryModel, EquipmentWithAssignmentsModel } from './../../../../models/equipmentsModel';
import { getUsersIncludingTerminated, getUsersList } from '../../../../core/store/users/users.actions';
import { SelectItem } from 'primeng/api';
import { UserModel } from 'src/app/models/userModel';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { FormGroup, RequiredValidator, Validators } from '@angular/forms';
import { deepClone, getAllControlsErrors, markFormGroupTouched } from 'src/app/helpers/utils';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import {
  addEquipmentToAssign, removeEquipmentToAssign, searchEquipment,
  saveAssignEquipment, changedUserOnEquipmentToAssign, updateCreateEquipmentForm,
  clearCreateEquipmentForm,
  createEquipmentSuccess,
  addAssigningAcessory,
  removeAssigningAcessory,
  loadCampuses,
  saveEditAssignEquipment,
  AddReturnDate,
  RemoveReturnDate,
  SetReturnDate,
  changedAssignedProgramOnEquipmentToAssign
} from 'src/app/core/store/equipment/equipment.actions';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { formatDate } from '@angular/common';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { GeneralSettingsModel } from 'src/app/models/generalSettingsModel';
@Component({
  selector: 'app-assign-equipment',
  templateUrl: './assign-equipment.component.html',
  styleUrls: ['./assign-equipment.component.scss']
})
export class AssignEquipmentComponent extends BaseComponent implements OnInit, OnDestroy {

  form: FormGroup;
  canEdit: boolean;
  assigningEquipments: AssignedEquipmentModel[];
  assigningEquipment = false;
  usersList: SelectItem[] = [{ label: 'loading...', value: undefined }];
  selectedEquipment: EquipmentModel;
  equipmentSearchResult: EquipmentModel[];
  userID: number;
  assignedProgramID: number;
  myUserID: number;
  myProgramID?: number;
  showErrors = false;
  savingAssignEquipments = false;
  displayCreateEquipment;
  assigningAcessories: EquipmentAcessoryModel[];
  acessoryName: string;
  acessoryEquipmentID: number;
  equipmentsList: SelectItem[];
  programList: SelectItem[];
  assignmentTypes: SelectItem[];
  isEditingAssignment: boolean = false;
  assignButtonText: string = "Assign";
  selectedEquipmentID: number;
  isAssigned: boolean = false;
  isDestroyed: boolean = false;
  assignedEquipments: PagingResultsModel<EquipmentWithAssignmentsModel>;
  equipmentsStatus: number[] = [];
  awaitingResults: boolean = true;
  generalSettings: GeneralSettingsModel;
  currentDate: string;
  returnDateString: string = "";
  includeTerminated: boolean = false;

  assignmentType: number = 0;
  assignUser: boolean = false;
  assignCampus: boolean = false;

  constructor(private actions$: Actions,
    private store: Store<State>) {
    super();
    this.currentDate = new Date().toISOString().slice(0, 10);
  }

  ngOnInit() {
    this.store.dispatch(getUsersList());
    this.subs.push(
      this.store.select(s => s.accountState.generalSettings).subscribe(generalSettings => {
        if (generalSettings) {
          this.generalSettings = generalSettings;
        }
      }),
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.canEdit = permissionIDs && !!permissionIDs.find(p => p === 3058); //manage equipment
      }),
      this.store.select(s => s.accountState.user).subscribe(myUser => {
        this.myUserID = myUser.id;
        this.myProgramID = myUser.primaryProgramID;
      }),
      this.store.select(s => s.equipmentState.assigningEquipment).subscribe(assigningEquipment => {
        this.assigningEquipment = assigningEquipment;
      }),
      this.store.select(s => s.equipmentState.assigningEquipments).subscribe(assigningEquipments => {
        this.assigningEquipments = deepClone(assigningEquipments);
        this.selectedEquipment = null;
        this.equipmentsList = assigningEquipments.map(ae =>
          ({ label: ae.equipment.type + ' - ' + ae.equipment.brand + ' - ' + ae.equipment.model + ' - ' + ae.equipment.serialNumber, value: ae.equipment.id }));
        const acessories = [];
        this.assigningEquipments.forEach(ea => {
          if (ea.equipmentAcessories) {
            acessories.push(...ea.equipmentAcessories);
          }
        });
        this.assigningAcessories = acessories;
        if (this.assigningEquipments.length > 0) {
          this.userID = this.assigningEquipments[0].userID;
          this.isEditingAssignment = this.assigningEquipments[0].id > 0;
          if (this.assigningEquipments[0].userID != null){
            this.assignmentType = 1;
          }
          if (this.assigningEquipments[0].userID == null && this.assigningEquipments[0].programID != null){
            this.assignmentType = 2;
            this.assignedProgramID = this.assigningEquipments[0].programID;
          }
        }
        else {
          this.userID = null;
          this.isEditingAssignment = false;
        }

        this.assignButtonText = this.isEditingAssignment ? "Save" : "Assign"
      }),
      this.store.select(s => s.equipmentState.assignedEquipments).subscribe(assigningEquipment => {
        this.assignedEquipments = assigningEquipment;
      }),
      this.store.select(s => s.equipmentState.assignedEquipments).subscribe(assignedEquipments => {
        this.assignedEquipments = deepClone(assignedEquipments);
        this.awaitingResults = false;
      }),
      this.store.select(s => s.equipmentState.savingAssignEquipments).subscribe(savingAssignEquipments => {
        this.savingAssignEquipments = savingAssignEquipments;
      }),

      this.store.select(s => s.equipmentState.equipmentSearchResult).subscribe(equipmentSearchResult => {
        this.equipmentSearchResult = equipmentSearchResult;
      }),
      this.store.select(s => s.usersState.usersList).subscribe(usersList => {
        this.usersList = usersList ? usersList.map(u => ({ label: u.firstName + ' ' + u.lastName, value: u.id }))
          : [{ label: 'loading...', value: undefined }];
        this.usersList.unshift({ label: '', value: null });
      }),
      this.store.select(s => s.equipmentState.campuses).subscribe(campuses => {
        const campusesList = campuses && campuses.length > 0 ? campuses.map(c => ({ label: c.programName, value: c.id }))
          : [{ label: 'loading...', value: undefined }];

        this.programList = campusesList;

      }),
      this.actions$.pipe(
        ofType(createEquipmentSuccess),
        tap(action => {
          this.savedNewEquipment(action.equipment);
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(changedUserOnEquipmentToAssign),
        tap(action => {
          this.userID = Number(action.userID);
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(changedAssignedProgramOnEquipmentToAssign),
        tap(action => {
          this.assignedProgramID = Number(action.assignedProgramID);
          if (action.assignedProgramID != null){
            this.myProgramID = Number(action.assignedProgramID);
          }
        })
      ).subscribe(),
    );
    this.assignmentTypes = [
      {label: "Select Assignment Type...", value: 0},
      {label: "User", value: 1},
      {label: "Campus", value: 2},
    ]
    this.store.dispatch(getUsersIncludingTerminated());
    this.store.dispatch(loadCampuses());
  }

  save() {
    if ((this.userID || this.assignedProgramID) && this.assignmentType > 0 && this.assigningEquipments.length > 0) {
      if (this.isEditingAssignment) {
        this.store.dispatch(saveEditAssignEquipment({ assigningEquipments: this.assigningEquipments }));
        this.assignUser = false;
        this.assignCampus = false;
        this.assignmentType = 0;
      }
      else {
        this.store.dispatch(saveAssignEquipment({ assigningEquipments: this.assigningEquipments }));
        this.assignUser = false;
        this.assignCampus = false;
        this.assignmentType = 0;
      }
    } else {
      this.showErrors = true;
    }
  }
  
  includeTerminatedChange(event){
    if (event.checked == false){
      this.store.select(s => s.usersState.usersList).subscribe(usersList => {
        this.usersList = usersList ? usersList.map(u => ({ label: u.firstName + ' ' + u.lastName, value: u.id }))
          : [{ label: 'loading...', value: undefined }];
        this.usersList.unshift({ label: '', value: null });
      })
    }
    else{
      this.store.select(s => s.usersState.usersListIncludingTerminated).subscribe(usersList => {
        this.usersList = usersList ? usersList.map(u => ({ label: u.firstName + ' ' + u.lastName, value: u.id }))
          : [{ label: 'loading...', value: undefined }];
        this.usersList.unshift({ label: '', value: null });
      })
    }
  }

  removeEquipment(equipment: EquipmentModel) {
    const assignedEquipment: AssignedEquipmentModel = {
      userID: this.userID,
      equipmentID: equipment.id
    };
    this.store.dispatch(removeEquipmentToAssign({ assignedEquipment }));
  }

  searchEquipment(event) {
    this.store.dispatch(searchEquipment({ filter: event.query }));
  }

  isAlreadyAssigned(equipment) {
    if (equipment) {
      if (equipment.id === this.selectedEquipmentID) {
        this.isAssigned = false;
      }
      else if (this.selectedEquipmentID == null || this.selectedEquipmentID < 1) {
        this.isAssigned = true;
      }
      else {
        this.isAssigned = true;
      }
    }
    else {
      this.isAssigned = true;
    }
    return this.isAssigned;
  }
  statusDestroyed(equipment) {
    if (equipment) {
      let equipments = this.assignedEquipments.result
      this.equipmentsStatus = [];
      if (equipments) {
        for (let e of equipments) {
          if (e.lastAssignedEquipment) {
            if (e.lastAssignedEquipment.returnStatus == "Destroyed") {
              this.equipmentsStatus.push(e.lastAssignedEquipment.equipment.id)
            }
          }
        }
        for (let e of this.equipmentsStatus) {
          if (e == this.selectedEquipmentID) {

            return true;
          }
        }
      }
      return false;
    }
    else {
      return false;
    }
  }
  addEquipment(equipment: EquipmentModel) {
    const assignedEquipment: AssignedEquipmentModel = {
      userID: this.userID,
      equipmentID: equipment.id,
      assignedByUserID: this.myUserID,
      programID: this.myProgramID,
      assignedProgramID: this.assignedProgramID,
      dateAssignedString: formatDate(new Date(), 'yyyy-MM-dd', 'en-US'),
      equipment
    };
    this.selectedEquipmentID = equipment.id;
    this.store.dispatch(addEquipmentToAssign({ assignedEquipment }));
  }

  setReturnDate(equipment){
    const assignedEquipment: AssignedEquipmentModel = {
      userID: this.userID,
      equipmentID: equipment.equipment.id,
      assignedByUserID: this.myUserID,
      programID: this.myProgramID,
      dateAssignedString: formatDate(new Date(), 'yyyy-MM-dd', 'en-US'),
      returnDateString: equipment.returnDateString,
      equipment: equipment.equipment
    };
    this.store.dispatch(SetReturnDate({assignedEquipment}));
  }

  removeReturnDate(equipment){
    const assignedEquipment: AssignedEquipmentModel = {
      userID: this.userID,
      equipmentID: equipment.equipment.id,
      assignedByUserID: this.myUserID,
      programID: this.myProgramID,
      dateAssignedString: formatDate(new Date(), 'yyyy-MM-dd', 'en-US'),
      equipment: equipment.equipment
    };
    this.store.dispatch(RemoveReturnDate({assignedEquipment}));
  }

  AddReturnDate(equipment){
    const assignedEquipment: AssignedEquipmentModel = {
      userID: this.userID,
      equipmentID: equipment.equipment.id,
      assignedByUserID: this.myUserID,
      programID: this.myProgramID,
      dateAssignedString: formatDate(new Date(), 'yyyy-MM-dd', 'en-US'),
      equipment: equipment.equipment
    };
    this.store.dispatch(AddReturnDate({assignedEquipment}));
  }

  changedUser() {
    this.store.dispatch(changedUserOnEquipmentToAssign({ userID: this.userID }));
  }

  changedAssignedProgramID(){
    this.store.dispatch(changedAssignedProgramOnEquipmentToAssign({ assignedProgramID: this.assignedProgramID }));
  }

  openCreateEquipment() {
    this.store.dispatch(clearCreateEquipmentForm());
    this.displayCreateEquipment = true;
  }

  savedNewEquipment(equipment) {
    this.displayCreateEquipment = false;
    this.addEquipment(equipment);
  }

  removeAcessory(acessory: EquipmentAcessoryModel) {
    this.store.dispatch(removeAssigningAcessory({ acessory }));
  }

  addAcessory(name: string, equipmentID: number) {
    this.store.dispatch(addAssigningAcessory({ name, equipmentID }));
  }
}

