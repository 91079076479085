
import { AbstractControl, ValidatorFn, FormGroup, FormArray } from '@angular/forms';

export function DateValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let isValid = (/^(\d{1,2}\/\d{1,2}\/(\d{2}|\d{4}))|(\d{4}-\d{2}-\d{2})$/.test(control.value));
    if (isValid) {
      const dateObject = new Date(control.value);
      if (isNaN(dateObject.getTime())) {
        isValid = false;
      } else if (dateObject.getFullYear() < 1753) {
        isValid = false;
      } else if (dateObject.getFullYear() > 2199) {
        isValid = false;
      }
    }

    return !isValid ? { invalidDate: { value: control.value } } : null;
  };
}

export function ValidatorRequiredIfBool(requiredControl: string, ifControl: string): ValidatorFn {
  return (form: FormGroup): { [key: string]: any } | null => {
    if (!form || !form.get(ifControl).value) {
      return null;
    } else if (form.get(requiredControl).value === null || form.get(requiredControl).value === undefined) {
      form.get(requiredControl).setErrors({ required: { value: form.get(requiredControl).value } });
      return { required: { value: form.get(requiredControl).value } };
    }
  };
}

export function ValidatorRequiredNotEmptyIfBool(requiredControl: string, ifControl: string): ValidatorFn {
  return (form: FormGroup): { [key: string]: any } | null => {
    if (!form || !form.get(ifControl).value) {
      return null;
    } else if (form.get(requiredControl).value === null || form.get(requiredControl).value === undefined || form.get(requiredControl).value === "") {
      form.get(requiredControl).setErrors({ required: { value: form.get(requiredControl).value } });
      return { required: { value: form.get(requiredControl).value } };
    }
  };
}


export function ValidatorRequiredNotEmptyIfFalse(requiredControl: string, ifControl: string): ValidatorFn {
  return (form: FormGroup): { [key: string]: any } | null => {
    if (!form || form.get(ifControl).value == null || form.get(ifControl).value == undefined || form.get(ifControl).value == true) {
      return null;
    } else if (form.get(requiredControl).value === null || form.get(requiredControl).value === undefined || form.get(requiredControl).value === "") {
      form.get(requiredControl).setErrors({ required: { value: form.get(requiredControl).value } });
      return { required: { value: form.get(requiredControl).value } };
    }
  };
}


export function ValidatorRequiredTrueIfBool(requiredControl: string, ifControl: string): ValidatorFn {
  return (form: FormGroup): { [key: string]: any } | null => {
    if (!form || !form.get(ifControl).value) {
      return null;
    } else if (!form.get(requiredControl).value) {
      form.get(requiredControl).setErrors({ required: { value: form.get(requiredControl).value } });
      return { required: { value: form.get(requiredControl).value } };
    }
  };
}

export function ValidatorConfirmField(confirmControl: string, originalControl: string): ValidatorFn {
  return (form: FormGroup): { [key: string]: any } | null => {
    if (!form || !form.get(originalControl) || !form.get(confirmControl)) {
      return null;
    } else if (form.get(originalControl).value !== form.get(confirmControl).value) {
      form.get(confirmControl).setErrors({ confirmNotEqual: { value: form.get(confirmControl).value } });
      return { confirmNotEqual: { value: form.get(confirmControl).value } };
    }
  };
}


export function ValidatorRequiredIfOtherFieldNotEquals(requiredControls: string[], ifControl: string, notEqualsTo: any): ValidatorFn {
  return (form: FormGroup): { [key: string]: any } | null => {
    if (!form || !form.get(ifControl) || form.get(ifControl).value === notEqualsTo) {
      for (const requiredControl of requiredControls) {
          form.get(requiredControl).setErrors(null);
      }
      return null;
    } else {
      let anyError = false;
      for (const requiredControl of requiredControls) {
        if (form.get(requiredControl).value === '' || form.get(requiredControl).value === null || form.get(requiredControl).value === undefined) {
          form.get(requiredControl).setErrors({ required: { value: form.get(requiredControl).value } });
          anyError = true;
        } else {
          form.get(requiredControl).setErrors(null);
        }
      }
      return anyError ? { required: { value: form.get(requiredControls[0]).value } } : null;
    }
  };
}

export function MinSelectedCheckboxes(min = 1) {
  const validator: ValidatorFn = (formArray: FormArray) => {
    const totalSelected = formArray.controls
      // get a list of checkbox values (boolean)
      .map(control => control.value)
      // total up the number of checked checkboxes
      .reduce((prev, next) => next ? prev + next : prev, 0);

    // if the total is not greater than the minimum, return the error message
    return totalSelected >= min ? null : { required: true };
  };

  return validator;
}

export function HasSelectedCheckboxesOrOther(formArrayName: string, otherControlName: string) {
const validator: ValidatorFn = (form: FormGroup): { [key: string]: any } | null => {

  const checkboxes = form.get(formArrayName) as FormArray;
  const otherControl = form.get(otherControlName);

  var hasCheckboxSelected = checkboxes.controls.some(s => s.value);
  
  if (((otherControl.value && otherControl.value.trim())|| hasCheckboxSelected) == false) {
    checkboxes.setErrors({ required: { value: true } });
    return { required: { value: true } };
  }
  else {
    checkboxes.setErrors(null);
    return null;// { required: { value: false } };
  }
  }
  return validator;
};
