<form class="card mt-2" [formGroup]="form" *ngIf="canEdit">
  <div class="card-body">
    <h4 class="mb-3">{{title}}</h4>
    <div class="row">
      <div class="col-12">

        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Type</b>
          </div>
          <div class="col-8">
            <p-dropdown styleClass="form-control mt-2 w-100" [options]="equipmentTypes" (onChange)="typeChange($event)" placeholder="Select Type..." formControlName="equipmentTypeID"></p-dropdown>
            <app-validation-message [form]="form" [path]="'equipmentTypeID'" [name]="'equipmentTypeID'" [label]="'Type'"> </app-validation-message>
          </div>
        </div>
        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Serial Number</b>
          </div>
          <div class="col-8">
            <input type="text" class="form-control mt-2" formControlName="serialNumber" />
            <app-validation-message [form]="form" [path]="'serialNumber'" [name]="'serialNumber'" [label]="'Serial Number'"> </app-validation-message>
          </div>
        </div>

        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Brand</b>
          </div>
          <div class="col-8">
            <input type="text" class="form-control mt-2" formControlName="brand" />
            <app-validation-message [form]="form" [path]="'brand'" [name]="'brand'" [label]="'Brand'"> </app-validation-message>
          </div>
        </div>

        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Model</b>
          </div>
          <div class="col-8">
            <input type="text" class="form-control mt-2" formControlName="model" />
            <app-validation-message [form]="form" [path]="'model'" [name]="'model'" [label]="'Model'"> </app-validation-message>
          </div>
        </div>

        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Service Tag</b>
          </div>
          <div class="col-8">
            <input type="text" class="form-control mt-2" formControlName="serviceTag" />
            <app-validation-message [form]="form" [path]="'serviceTag'" [name]="'serviceTag'" [label]="'Service Tag'"> </app-validation-message>
          </div>
        </div>

        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Express Service Code</b>
          </div>
          <div class="col-8">
            <input type="text" class="form-control mt-2" formControlName="expressServiceCode" />
            <app-validation-message [form]="form" [path]="'expressServiceCode'" [name]="'expressServiceCode'" [label]="'Express Service Code'"> </app-validation-message>
          </div>
        </div>

        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>{{generalSettings?.businessName}} #</b>
          </div>
          <div class="col-8">
            <input type="text" class="form-control mt-2" formControlName="assetTag" />
            <app-validation-message [form]="form" [path]="'assetTag'" [name]="'assetTag'" [label]="generalSettings?.businessName+' #'"> </app-validation-message>
          </div>
        </div>
        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Main Program</b>
          </div>
          <div class="col-8">
            <p-dropdown styleClass="form-control mt-2 w-100" [options]="programList" placeholder="Select campus..." formControlName="mainProgramID"></p-dropdown>
            <app-validation-message [form]="form" [path]="'mainProgramID'" [name]="'mainProgramID'" [label]="'Main Program'"> </app-validation-message>
          </div>
        </div>
        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Purchase Date</b>
          </div>
          <div class="col-8">
            <input type="date" class="form-control mt-2" formControlName="purchaseDate" max="{{today | date: 'yyyy-MM-dd'}}" />
            <app-validation-message [form]="form" [path]="'purchaseDate'" [name]="'purchaseDate'" [label]="'Purchase Date'"> </app-validation-message>
          </div>
        </div>
        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Department</b>
          </div>
          <div class="col-8">
            <select class="form-control mt-2" formControlName="department">
              <option value="IT">IT</option>
              <option value="Human Resources">Human Resources</option>
              <option value="Administration">Administration</option>
              <option value="Finance">Finance</option>
              <option value="Marketing">Marketing</option>
              <option value="Personal">Personal</option>
              <option value="Other">Other</option>
            </select>
            <app-validation-message [form]="form" [path]="'department'" [name]="'department'" [label]="'Department'"> </app-validation-message>
          </div>
        </div>
        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Cost or Fair Market Value</b>
          </div>
          <div class="col-8">
            <input type="text" class="form-control mt-2" formControlName="pricing" />
            <app-validation-message [form]="form" [path]="'pricing'" [name]="'pricing'" [label]="'Pricing'"> </app-validation-message>
          </div>
        </div>
        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Purchase Vendor</b>
          </div>
          <div class="col-8">
            <input type="text" class="form-control mt-2" formControlName="purchaseVendor" />
            <app-validation-message [form]="form" [path]="'purchaseVendor'" [name]="'purchaseVendor'" [label]="'Vendor'"> </app-validation-message>
          </div>
        </div>
        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Storage Location</b>
          </div>
          <div class="col-8">
            <p-dropdown styleClass="form-control mt-2 w-100" [options]="storageProgramList" placeholder="Select location..." (onChange)="storageLocationChange($event)" formControlName="storedLocationID"></p-dropdown>
            <app-validation-message [form]="form" [path]="'storedLocationID'" [name]="'storedLocationID'" [label]="'Storage Location'"> </app-validation-message>
          </div>
        </div>
        <div *ngIf="showStorageOther" class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Storage Other</b>
          </div>
          <div class="col-8">
            <input type="text" class="form-control mt-2" formControlName="storedOther" />
            <app-validation-message [form]="form" [path]="'storedOther'" [name]="'storedOther'" [label]="'Storage Other'"> </app-validation-message>
          </div>
        </div>
        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Payment Method</b>
          </div>
          <div class="col-8">
            <input type="text" class="form-control mt-2" formControlName="paymentMethod" />
            <app-validation-message [form]="form" [path]="'paymentMethod'" [name]="'paymentMethod'" [label]="'Payment Method'"> </app-validation-message>
          </div>
        </div>
        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Size</b>
          </div>
          <div class="col-8">
            <input type="text" class="form-control mt-2" formControlName="size" />
            <app-validation-message [form]="form" [path]="'size'" [name]="'size'" [label]="'Size'"> </app-validation-message>
          </div>
        </div>
        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Class Code</b>
          </div>
          <div class="col-8">
            <p-dropdown styleClass="form-control mt-2" [filter]="true" [resetFilterOnHide]="true" [options]="classCodeOptions" formControlName="classCodeID" placeholder="Select option..."></p-dropdown>
            <app-validation-message [form]="form" [path]="'classCodeID'" [name]="'classCodeID'" [label]="'Class Code'"> </app-validation-message>
          </div>
        </div>
        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Classification</b>
          </div>
          <div class="col-8">
            <p-dropdown styleClass="form-control mt-2" [filter]="true" [resetFilterOnHide]="true" (onChange)="updateOther($event)" [options]="classificationOptions" formControlName="classificationID" placeholder="Select option..."></p-dropdown>
            <app-validation-message [form]="form" [path]="'classificationID'" [name]="'classificationID'" [label]="'Classification'"> </app-validation-message>
          </div>
        </div>
        <div [hidden]="classCodeOther" class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Other</b>
          </div>
          <div class="col-8">
            <input type="text" class="form-control mt-2" formControlName="classificationOther" placeholder="Number - Description"/>
            <app-validation-message [form]="form" [path]="'classificationOther'" [name]="'classificationOther'" [label]="'Other'"> </app-validation-message>
            <p style="color: #FFCC00;">Enter Other Classification (number - description)</p>
          </div>
        </div>
      </div>

    <div class="mt-4 float-right">
      <i *ngIf="creatingEquipment" class="material-icons rotate text-muted">loop</i>
      <button *ngIf="!creatingEquipment" class="btn btn-primary" [class.disabled]="form.invalid" (click)="save()">Save</button>
    </div>
  </div>
  </div>
</form>
