<form id="formEmployeeReview">
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-2 col-sm-12">
          <b>Template</b>
        </div>
        <div class="col-md-10 col-sm-12">
          <p-dropdown class=" w-100 " styleClass="form-control mt-2 w-100" [disabled]="!openPeriod?.isOpen" [options]="availableTemplates" [(ngModel)]="template" (ngModelChange)="changedTemplate()" [ngModelOptions]="{standalone: true}" placeholder="Select a template..."></p-dropdown>
          <span *ngIf="openPeriod !== null && !openPeriod.isOpen" style="color: red; font-style: italic;">Review period is currently closed. You can create a new review between {{openPeriod?.startDate | date: 'MM/dd/yyyy' || ''}} and {{openPeriod?.endDate | date: 'MM/dd/yyyy' || ''}}</span>
        </div>
      </div>
      <div *ngIf="template">
        <hr />
        <pre *ngIf="template.description">The {{template.description}}(s) support the {{generalSettings?.businessName}} Mission & Values by striving each day to meet the following Key Results Areas and Key Performance Indicators.</pre>

        <div *ngFor="let kra of template.kras; let kraIndex=index">
          <div [style.backgroundColor]="kra.valueColor?.colorRGB" [style.color]="blackOrWhite(kra.valueColor?.colorRGB)">
            <h2>#{{kraIndex+1}} KRA</h2>
            <pre [style.color]="blackOrWhite(kra.valueColor?.colorRGB)">{{kra.description}}</pre>
          </div>

          <div *ngIf="kra.reason">
            <strong>Why...</strong>
            <pre>{{kra.reason}}</pre>
          </div>
          <div *ngIf="kra.corePlay">
            <strong>How/Core Play...</strong>
            <pre>{{kra.corePlay}}</pre>
          </div>
          <div *ngIf="kra.measurement">
            <strong>Measurement/What Winning Looks Like…</strong>
            <pre>{{kra.measurement}}</pre>
          </div>

          <div *ngFor="let kpi of kra.kpis; let kpiIndex=index" class=" ml-2">
            <h3>#{{kraIndex+1}}.{{kpiIndex+1}} KPI</h3>
            <pre>{{kpi.description}}</pre>
            <strong>Why...</strong>
            <pre [style.backgroundColor]="kpi.valueColor?.colorRGB" [style.color]="blackOrWhite(kpi.valueColor?.colorRGB)">{{kpi.reason}}</pre>
            <strong>How/Core Play...</strong>
            <pre>{{kpi.corePlay}}</pre>
            <div class="w-100" *ngIf="kpi.ethicsCodes && kpi.ethicsCodes.length > 0">
              <div class="d-flex m-2 w-100" [ngClass]="{'bg-warning': ec.highlight}" *ngFor="let ec of kpi.ethicsCodes">
                <strong class="col-2">{{ec.section}}</strong>
                <pre class="col-9">{{ec.description}}</pre>
              </div>
            </div>
            <strong>Measurement/What Winning Looks Like…</strong>
            <pre>{{kpi.measurement}}</pre>
            <div *ngFor="let kpiCategory of kpi.categories; let categoryIndex=index" class="w-100  ml-2">
              <h5>{{kpiCategory.category.category}}</h5>
              <div class="w-100" *ngFor="let measureable of kpiCategory.measureables; let i=index">
                <div class="row m-2">
                  <div class="col">
                    <pre>{{measureable.measureable}}</pre>
                  </div>

                  <div class="col-lg-1 col-md-2">
                    <select class="form-control w-100" [(ngModel)]="measureable.answer" (change)='calcScore()' [ngModelOptions]="{standalone: true}">
                      <option value=""></option>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                  </div>

                </div>
                <div class="w-100 m-2" *ngFor="let child of measureable.childrenMeasureables; let i=index">
                  <div class="row m-2">
                    <div class="col">

                      <pre>{{child.measureable}}</pre>

                    </div>
                    <div class="col-lg-1 col-md-2">
                      <select class="form-control w-100" [(ngModel)]="child.answer" (change)='calcScore()' [ngModelOptions]="{standalone: true}">
                        <option value=""></option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </select>
                    </div>

                  </div>
                </div>
                <hr />
              </div>
            </div>
          </div>


          <div *ngFor="let measureable of kra.measureables; let measurableIndex=index" class=" ml-2">
            <h3>#{{kraIndex+1}}.{{measurableIndex+1}}</h3>
            <div class="row m-2">
              <div class="col">
                <pre>{{measureable.measureable}}</pre>
              </div>

              <div class="col-lg-1 col-md-2">
                <p-checkbox [(ngModel)]="measureable.answer" [binary]="true" [ngModelOptions]="{standalone: true}"></p-checkbox>
              </div>

            </div>
            <div class="w-100 m-2" *ngFor="let child of measureable.childrenMeasureables; let i=index">
              <div class="row m-2">
                <div class="col">
                  <pre>{{child.measureable}}</pre>
                </div>

                <div class="col-lg-1 col-md-2">
                  <p-checkbox [(ngModel)]="child.answer" [binary]="true" [ngModelOptions]="{standalone: true}"></p-checkbox>
                </div>

              </div>
            </div>
            <hr />

          </div>

          <div class="row" *ngIf="kra.optionSet && kra.optionSet.scoreOptions && kra.optionSet.scoreOptions.length > 0">
            <div class="col-md-2 col-sm-12">
              <b>Score</b>
            </div>
            <div class="col-md-10 col-sm-12">
              <p-dropdown styleClass="form-control w-100 mb-2" [showClear]="true" [options]="kra.optionSet.scoreOptions" optionLabel="scoreOptionDescription" [(ngModel)]="kra.scoreOption" (ngModelChange)='calcScore()' optionValue="scoreOptionValue" [ngModelOptions]="{standalone: true}" placeholder="Select a value...">
              </p-dropdown>
            </div>
          </div>
        </div>
        <hr />
        <div class="row mb-2">
          <div class="col-md-2 col-sm-12">
            Comments:
          </div>
          <div class="col-md-10 col-sm-12">
            <textarea class="form-control w-100" [(ngModel)]="comments" [ngModelOptions]="{standalone: true}"></textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2 col-sm-12">
            Area to focus on:
          </div>
          <div class="col-md-10 col-sm-12">
            <textarea class="form-control w-100" [(ngModel)]="focusArea" [ngModelOptions]="{standalone: true}"></textarea>
          </div>
        </div>
        <div class="row">
          <h2 class="col">
            Score:
            <strong class="m-2">
              {{
score
              }} / {{template.maximumScore}}
            </strong>
          </h2>
        </div>


        <div *ngIf="template.kras.length > 0">
          <div class="row" *ngIf="template.kras[0].optionSet && template.kras[0].optionSet.scoreOptions && template.kras[0].optionSet.scoreOptions.length > 0">
            <div class="col-md-2 col-sm-12">
              <b>Score</b>
            </div>
            <ul class="w-100 m-2" *ngFor="let option of template.kras[0].optionSet.scoreOptions; let i=index">
              <li>
                {{option.scoreOptionValue}} - {{option.scoreOptionSummaryDescription}}
              </li>
            </ul>
          </div>
        </div>


        <div class="row">

          <div class="col d-flex">
            <button type="button" [disabled]="loading || savingEmployeeReview" class="btn btn-success ml-2 " (click)="save()">Submit</button>
            <button type="button" [disabled]="loading || savingEmployeeReview" class="btn btn-primary ml-2 " (click)="saveToLater()">Save And Finish Later</button>
            <i *ngIf="!!loading" class="material-icons rotate text-muted">loop</i>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
