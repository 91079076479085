import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { Actions } from '@ngrx/effects';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Router } from '@angular/router';
import { CriticalNewsPostCommentModel, CriticalNewsPostLinkModel, CriticalNewsPostModel, CriticalNewsPostReactionModel, CriticalNewsPostUIFileModel, FilesToRemove, NewFileNames } from 'src/app/models/criticalNewsFeedModel';
import {NgbModal,  NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { archivePost, deleteReaction, editPost, editPostWithFiles, pinPost, pinUserPost, saveReaction, unpinPost, unpinUserPost } from 'src/app/core/store/critical-news-feed/critical-news-feed.action';
import { deepClone } from 'src/app/helpers/utils';
import { animate, style, transition, trigger } from '@angular/animations';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-critical-news-post',
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({transform: 'translateY(-5%)', opacity: 0}),
          animate('250ms', style({transform: 'translateY(0)', opacity: 1}))
        ]),
        transition(':leave', [
          style({transform: 'translateY(0)', opacity: 1}),
          animate('250ms', style({transform: 'translateY(25%)', opacity: 0}))
        ])
      ]
    )
  ],
  templateUrl: './critical-news-post.component.html',
  styleUrls: ['./critical-news-post.component.scss']
})
export class CriticalNewsPostComponent extends BaseComponent implements OnInit {

  @Input() post: CriticalNewsPostModel;

  @ViewChild('postEditInput') postEditInput: ElementRef;
  @ViewChild('mainPhoto') mainPhoto: ElementRef;
  @ViewChild('additionalPhotosElement') additionalPhotosElement: ElementRef;
  screenWidth: number = window.innerWidth;
  @HostListener('window:resize', ['$event']) onWindowResize() {
    this.screenWidth = window.innerWidth;
  }
  @HostListener('document:click', ['$event']) onDocumentClick(event) {
    if (!this.isModalOpen){
      this.cancelEdit();
    }
    if (this.isModalOpen && this.modalReference != null){
      this.modalReference.close();
    }
    this.showAllComments = false;
    this.showAllReactions = false;
    this.showPostMenu = false;
    this.showPinnedCommentMenu = false;
  }
  
  userID: number;
  
  //Permissions
  allComments: CriticalNewsPostCommentModel[];

  managePinnedComments: boolean = false;
  managePinnedPosts: boolean = false;
  canComment: boolean = false;
  manageAll: boolean = false;
  loading: boolean = false;
  showEdit: boolean = false;
  showPostMenu: boolean = false;
  modalReference: any;
  modalOptions: NgbModalOptions;
  isModalOpen: boolean = false;
  fileCount: number = 0;
  moreThanOnePhoto:boolean = false;
  likedCount: number;
  lovedCount: number;
  userLikedPost: boolean = null;
  userLovedPost: boolean = null;
  reactionLoading: boolean = false;
  additionalImagesContainerHeight: { height: string } = { height: '50px'};
  postCharCount: number = 0;
  editPostMessage: string = "";
  editCharCount: number;
  allReactions: CriticalNewsPostReactionModel[];
  loveReactions: CriticalNewsPostReactionModel[];
  likeReactions: CriticalNewsPostReactionModel[];
  commentsToDisplay: CriticalNewsPostCommentModel[];
  pinnedComment: CriticalNewsPostCommentModel = null;
  showPinnedCommentMenu: boolean = false;
  editMessageTooLong: boolean;
  postEditCharCount: number = 0;
  isUserPinned: boolean = false;
  isPinned: boolean = false;
  newFilesDynamicIndex: number = -10000;
  filesToRemove: FilesToRemove[];
  showAllComments: boolean = false;
  showAllReactions: boolean = false;
  showOtherUrls: boolean = true;
  userReactionToPost: CriticalNewsPostReactionModel = null;
  newLinkInput: string = "";
  newLinks: CriticalNewsPostLinkModel[] = null;
  currentLinks: CriticalNewsPostLinkModel[] = null;
  
  // File Upload Controllers
  newFiles: {id: number; file: File;}[] = null;
  newImageUrls: CriticalNewsPostUIFileModel[];
  newFileUrls: CriticalNewsPostUIFileModel[];
  mainPostPhoto: CriticalNewsPostUIFileModel;
  additionalPhotos: CriticalNewsPostUIFileModel[] = null;
  imagesToDisplay: CriticalNewsPostUIFileModel[];
  fileUrls: CriticalNewsPostUIFileModel[];
  allCurrentFiles: CriticalNewsPostUIFileModel[];
  allNewFileUrls: CriticalNewsPostUIFileModel[];
  newFileNames: NewFileNames[] = null;

  constructor(
    private actions$: Actions,
    private alertService: AlertService,
    private store: Store<State>,
    private router: Router,
    private modalService: NgbModal,
    ) {
    super();
    this.modalOptions = {
      backdrop:'static',
      backdropClass:'customBackdrop',
      size: 'lg'
    }
  }

  ngOnInit(): void {
    this.loading = false;
    this.editPostMessage = this.post.message;
    this.isPinned = this.post.isPinned;
    this.isUserPinned = this.post.isPinnedByUser;
    this.postEditCharCount = this.post.message != null && this.post.message != "" ? this.post.message.length : 0;
    if (this.post.imageURLs != null && this.post.imageURLs.length > 0 && this.post.otherFileUrls != null && this.post.otherFileUrls.length > 0){
      this.allCurrentFiles = [ ...this.post.imageURLs, ... this.post.otherFileUrls];
      if (this.allCurrentFiles != null){
        this.manageImageDisplay(this.allCurrentFiles);
      }
    }
    else if (this.post.imageURLs != null && this.post.imageURLs.length > 0){
      this.allCurrentFiles = this.post.imageURLs;
      if (this.allCurrentFiles != null){
        this.manageImageDisplay(this.allCurrentFiles);
      }
    }
    else if (this.post.otherFileUrls != null && this.post.otherFileUrls.length > 0){
      this.allCurrentFiles = this.post.otherFileUrls;
      if (this.allCurrentFiles != null){
        this.manageImageDisplay(this.allCurrentFiles);
      }
    }
    if(this.post.comments != null && this.post.comments.length > 0){
      this.allComments = this.post.comments;
      this.manageComments(this.post.comments);
    }
    if (this.post.reactions != null && this.post.reactions.length > 0){
      this.allReactions = this.post.reactions;
      this.manageReactions(this.post.reactions);
    }   
    if (this.post.currentLinks != null && this.post.currentLinks.length > 0){
      this.currentLinks = deepClone(this.post.currentLinks);
    }

    this.subs.push(
      this.store.select(s => s.criticalNewsFeedState.commentJustAdded).subscribe(comment => {
        if(comment != null && this.post.id == comment.postID ){
          var updatedComments = this.allComments != null ? deepClone(this.allComments) : [];
          updatedComments.unshift(comment);
          this.manageComments(updatedComments)
        }
      }),
      this.store.select(s => s.criticalNewsFeedState.commentJustEdited).subscribe(comment => {
        if(comment != null && this.post.id == comment.postID ){
          var updatedComments = this.allComments != null ? deepClone(this.allComments) : [];
          updatedComments = updatedComments.map(x => {
            if (x.id == comment.id){
              x = comment;
            }
            return x;
          })
          this.manageComments(updatedComments)
        }
      }),
      this.store.select(s => s.criticalNewsFeedState.commentJustPinned).subscribe(comment => {
        if(comment != null && this.post.id == comment.postID ){
          this.pinnedComment = comment;
          var updatedComments = this.allComments != null ? deepClone(this.allComments) : [];
          updatedComments = updatedComments.map(x => {
            if(x.id == comment.id){
              x = comment;
            }
            else{
              x.pinned = false;
            }
            return x;
          })
          this.manageComments(updatedComments);
        }
      }),
      this.store.select(s => s.criticalNewsFeedState.commentJustUnpinned).subscribe(comment => {
        if(comment != null && this.post.id == comment.postID ){
          this.pinnedComment = null;
          var updatedComments = this.allComments != null ? deepClone(this.allComments) : [];
          updatedComments = updatedComments.map(x => {
              x.pinned = false;
              return x;
          })
          this.manageComments(updatedComments);
        }
      }),
      this.store.select(s => s.criticalNewsFeedState.reactionJustAdded).subscribe(reaction => {
        if(reaction != null && this.post.id == reaction.postID){
          this.reactionLoading = false;
          if (reaction.updatedDate == null){
            let updatedReactions = this.allReactions != null ? deepClone(this.allReactions) : [];
            updatedReactions.push(reaction)
            this.manageReactions(updatedReactions);
          }
          if (reaction.updatedDate != null){
            let updatedReactions;
            if (this.allReactions != null){
              updatedReactions = this.allReactions.map(x => {
                if (x.id == reaction.id){
                  x = reaction;
                }
                return x;
              })
            }
            this.manageReactions(updatedReactions);
          }
        }
      }),
      this.store.select(s => s.criticalNewsFeedState.reactionJustDeleted).subscribe(reaction => {
        if(reaction != null && this.post.id == reaction.postID){
          this.reactionLoading = false;
          let updatedReactions = this.allReactions.filter(x => x.id != reaction.id);
          this.manageReactions(updatedReactions);
        }
      }),
      this.store.select(s => s.criticalNewsFeedState.commentJustArchived).subscribe(comment => {
        if(comment != null && this.post.id == comment.postID ){
          this.allComments = this.allComments.filter(x => x.id != comment.id);
          if (this.pinnedComment != null && this.pinnedComment.id == comment.id){
            this.pinnedComment = null;
          }
          this.manageComments(this.allComments);
        }
      }),
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.managePinnedComments = permissionIDs && !!permissionIDs.find(p => p === 9505 || p === 9500);
        this.managePinnedPosts = permissionIDs && !!permissionIDs.find(p => p === 9504 || p === 9500);
        this.canComment = permissionIDs && !!permissionIDs.find(p => p === 9503 || p === 9500);
        this.manageAll = permissionIDs && !!permissionIDs.find(p => p === 9500);
      }),
      this.store.select(s => s.criticalNewsFeedState.userPinnedPosts).subscribe(posts => {
        if (posts != null){
          this.isUserPinned = posts && !!posts.find(x => x.id === this.post.id);
        }
      }),
      this.store.select(s => s.criticalNewsFeedState.pinnedPosts).subscribe(posts => {
        if (posts != null){
          this.isPinned = posts && !!posts.find(x => x.id === this.post.id);
        }
      }),
      this.store.select(s => s.accountState.user).subscribe(u => {
        if(u){
          this.userID = u.id;
        }
      }),
    );
  }

  downloadFile(file: any){
    window.open(file.url, "_blank");
  }

  manageComments(comments){
      var pinnedComment;
      var unpinnedComments;
      if (comments != null && comments.length > 0){
        pinnedComment = comments.filter(x => x.pinned );
        unpinnedComments = comments.filter(x => x.pinned != true);
        comments = [...pinnedComment, ...unpinnedComments];
      }
      this.allComments = comments;
      this.commentsToDisplay = comments.slice(0, 3);
  }

  manageReactions(reactions){
    if(this.allReactions == null){
      this.allReactions = [];
    }
    if (this.userID == null){
      let state: State;
      this.store.pipe(take(1)).subscribe(s => state = s)
      this.userID = state.accountState.user.id;
    }
    let loveReacts = [];
    let likeReacts = [];
    if (reactions.length > 0){
      loveReacts = reactions.filter(x => x.reactionTypeID == 2)
      likeReacts = reactions.filter(x => x.reactionTypeID == 1)
    }
    let userReaction = reactions.filter(x => x.userID === this.userID)
    if (userReaction != null && userReaction.length > 0){
      this.userReactionToPost = userReaction[0];
      if (userReaction[0].reactionTypeID == 2){
        this.userLovedPost = true;
        this.userLikedPost = false;
      }
      else {
        this.userLovedPost = false;
        this.userLikedPost = true;
      }
    }
    else{
      this.userLikedPost = false;
      this.userLovedPost = false;
    }
    this.loveReactions = loveReacts;
    this.likeReactions = likeReacts;
    this.allReactions = reactions;
    this.lovedCount = loveReacts == null ? 0 : loveReacts.length;
    this.likedCount = likeReacts == null ? 0 : likeReacts.length;
  }

  manageImageDisplay(files){
    if (files == null){
      this.mainPostPhoto = null;
      this.additionalPhotos = [];
      return
    }
    let images = [];
    let otherFiles = [];
    for (var file of files){
      if(file.fileType.includes("image")){
        images.push(file)
      }
      else {
        otherFiles.push(file);
      }
    }
    this.mainPostPhoto = images[0];
    if (images.length > 1){
      this.additionalPhotos = images.slice(1, images.length);
      this.moreThanOnePhoto = true;
    }
    else{
      this.additionalPhotos = null;
      this.moreThanOnePhoto = false;
    }
    this.imagesToDisplay = images;
    this.fileUrls = deepClone(otherFiles);
    let imageCount = images != null && images.length > 0 ? images.length : 0;
    let otherCount = otherFiles != null && otherFiles.length > 0 ? otherFiles.length : 0;
    this.fileCount = imageCount + otherCount;
  }

  manageNewFileDisplay(newFiles){
    let images = [];
    let otherFiles = [];
    let additional;
    for (var file of newFiles){
      if(file.fileType.includes("image")){
        images.push(file)
      }
      else {
        otherFiles.push(file);
      }
    }
    if (this.mainPhoto == null && images != null && images.length > 0) {
      this.mainPostPhoto = images[0];
      if(images.length > 1)
      {
        additional = images.slice(1, images.length);
      }
    }
    if (this.mainPhoto != null && images != null && images.length > 0){
      additional = images
    }
    if(this.additionalPhotos == null || this.additionalPhotos.length < 1){
      if (additional != null && additional.length > 0){
        this.additionalPhotos = additional;
        this.moreThanOnePhoto = true;
      }
      else{
        this.additionalPhotos = null;
        this.moreThanOnePhoto = false;
      }
    }
    else{
      this.additionalPhotos = [ ...this.additionalPhotos, ...images];
    }
    this.newFileUrls = otherFiles;
    this.newImageUrls = images;
    let imageCount = images != null && images.length > 0 ? images.length : 0;
    let otherCount = otherFiles != null && otherFiles.length > 0 ? otherFiles.length : 0;
    let currentCount = this.fileCount != null ? this.fileCount : 0;
    this.fileCount = imageCount + otherCount + currentCount;
  }

  addNewLink(){
    if (this.newLinkInput == "" || this.newLinkInput == null){
      this.alertService.error("You have not input a new link");
      return;
    }
    let links = [];
    if (this.newLinkInput == "" || this.newLinkInput == null){
      this.alertService.error("You have not input a new link");
      return;
    }
    if (this.newLinks != null){
      links = [...this.newLinks];
    }
    let icon = "fas fa-link";
    let iconColor = "#000000"
    if (this.newLinkInput.toLocaleLowerCase().includes("youtu.be")  || this.newLinkInput.toLocaleLowerCase().includes("youtube") ){
      icon = "fab fa-youtube"; 
      iconColor = "#FF0000";
    }
    else if (this.newLinkInput.toLocaleLowerCase().includes("facebook") ){
      icon = "fab fa-facebook-square";
      iconColor = "#385898";
    }
    else if (this.newLinkInput.toLocaleLowerCase().includes("instagram") ){
      icon = "fab fa-instagram";
      iconColor = "#833AB4";
    }
    else if (this.newLinkInput.toLocaleLowerCase().includes("twitter") ){
      icon = "fab fa-twitter";
      iconColor = "#1DA1F2";
    }
    links.push({id: 0, icon: icon, iconColor: iconColor, url: this.newLinkInput});
    this.newLinkInput = "";
    this.newLinks = links;
  }

  removeAddedLink(e, link){
    this.newLinks = Array.from(this.newLinks).filter( function(x){ 
      if (x.url != link.url){
        return x ;
      }
    });
    e.stopPropagation();
  }

  removeCurrentLink(e, link){
    let links = deepClone(this.currentLinks).filter(x => x.url != link.url);
    this.currentLinks = links;
    e.stopPropagation();
  }

  closePostMenu(){
    this.showPostMenu = false;
  }

  leavePost(){
    if (!this.isModalOpen){
      this.showPostMenu = false;
      this.showAllComments = false;
      this.showPinnedCommentMenu = false;
    }
  }

  openAllComments(){
      this.showAllComments = !this.showAllComments;
  }

  closeAllComments(){
    this.showAllComments = false;
  }

  openAllReactions(){
    this.showAllReactions = !this.showAllReactions;
  }

  closeAllReactions(){
    this.showAllReactions = false;
  }

  archivePost(post){
    this.alertService.confirm("Confirm Action", "Are you sure you want to Archive this Post?").subscribe(answer =>{
      if(answer){
        this.store.dispatch(archivePost({id: post.id}))
      }
    })
  }
  
  uploadFile(el){
    var duplicates = false;
    let files = [];
    if(this.allNewFileUrls != null && this.allNewFileUrls.length > 0){
      for (let file of el.target.files){
        let duplicateExist = Array.from(this.allNewFileUrls).filter( function(x){ 
                                if (x.fileName === file.name && x.fileType === file.type){
                                  return x;
                                }
                                else{
                                  return null;
                                }
                              });
        if (duplicateExist.length > 0){
          duplicates = true;
          continue;
        }
        else{
          files.push(file);
        }
      }
    }
    else{
      files = el.target.files;
    }
    this.allNewFileUrls = Object.assign([], this.allNewFileUrls);
    this.newFiles = Object.assign([], this.newFiles);
    if (files) {
      for (let file of files) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.allNewFileUrls.push({id: this.newFilesDynamicIndex, url: e.target.result, fileName: file.name, fileType: file.type});
          this.newFiles.push({id: this.newFilesDynamicIndex, file: file});
          this.newFilesDynamicIndex = this.newFilesDynamicIndex + 1;
        }
        reader.readAsDataURL(file);
      }
    }
    if(duplicates){
      this.alertService.info("duplicate images were not added");
    }
    setTimeout(() =>{this.manageNewFileDisplay(this.allNewFileUrls)}, 300);
  }

  openImages(content, file: CriticalNewsPostUIFileModel = null) {
    if (file == null){
      this.showOtherUrls = true;
      if (this.allCurrentFiles != null){
        this.manageImageDisplay(this.allCurrentFiles);
      }
    }
    else {
      this.imagesToDisplay = [file];
      this.showOtherUrls = false;
    }
    this.modalReference = this.modalService.open(content, this.modalOptions);
    this.isModalOpen = true;
    this.modalReference.result.then((result) => {
    }, (reason) => {
    });
  }

  closeImageModal(e){
    this.modalReference.close();
    this.isModalOpen = false;
    e.stopPropagation();
  }

  removeImage(file){
    this.imagesToDisplay = this.imagesToDisplay.filter(x => x.id != file.id);
    this.allCurrentFiles = this.allCurrentFiles.filter(x => x.id != file.id);
    if (this.allCurrentFiles != null){
      this.manageImageDisplay(this.allCurrentFiles);
    }
    if (this.filesToRemove == null){
      this.filesToRemove = [];
    }
    this.filesToRemove.push({id: file.id});
  }

  removeAddedImage(file){
    this.newImageUrls = Array.from(this.newImageUrls).filter( function(x){ 
                  if (x.id != file.id){
                    return x ;
                  }
                });
    this.allNewFileUrls = Array.from(this.allNewFileUrls).filter( function(x){ 
                  if (x.id != file.id){
                    return x ;
                  }
                });
    this.newFiles = Array.from(this.newFiles).filter( function(x){ 
      if (x.id != file.id){
        return x ;
      }
    });
    this.newFileUrls = Array.from(this.newFileUrls).filter( function(x){ 
                  if (x.id != file.id){
                    return x ;
                  }
                });
    if (this.newFileUrls != null){
      this.manageNewFileDisplay(this.allNewFileUrls);
    }
  }

  saveReaction(type: number){
    this.reactionLoading = true;
    if ((this.userLovedPost && type == 2) || (this.userLikedPost && type == 1)){
      let reaction;
      if (this.userReactionToPost != null){
        reaction = this.userReactionToPost;
      }
      this.store.dispatch(deleteReaction({reaction: reaction}))
    }
    else{
      var reaction = {
        id: 0,
        userID: 0,
        postID: this.post.id,
        reactionTypeID: type,
        count: 1,
        userImage: "",
        userFirstName: "",
        userLastName: "",
        createdDate: new Date(),
        updatedDate: null,
      };
      this.store.dispatch(saveReaction({reaction: reaction}))
    }
  }
  
  edit(){
    this.showEdit = true;
    setTimeout(()=>{ 
      this.postEditInput.nativeElement.focus();
    }, 0); 
  }

  togglePostMenu(e){
    this.showPostMenu = !this.showPostMenu;
    e.stopPropagation()
  }
  
  stopPropagation(e) {
    e.stopPropagation();
  }

  onPostEditInput(e) {
    const tx = this.postEditInput.nativeElement;
    for (let i = 0; i < tx.length; i++) {
      tx[i].setAttribute("style", "height:" + (tx[i].scrollHeight) + "px;overflow-y:hidden;");
    }
    e.target.style.height = 0;
    e.target.style.height = (e.target.scrollHeight) + "px";
    this.countEditCharacters()
  }

  resize() {
    setTimeout(() => {
      this.editPostMessage = this.editPostMessage + ""
    }, 250);
  }

  countEditCharacters(){
    var count = this.editPostMessage.length;
    if(count > 8000){
      this.editMessageTooLong = true;
    }
    else{
      this.editMessageTooLong = false;
    }
    this.postEditCharCount = count;
  }

  cancelEdit(){
    this.showEdit = false;
    this.newFileNames = [];
    this.newImageUrls = [];
    this.newFileUrls = [];
    this.newFiles = [];
    this.manageImageDisplay(this.allCurrentFiles);
  }

  managePinPostAllUsers(){
    if(this.isPinned){
      this.alertService.confirm("Confirm Action", "Are you sure you want to unpin this post?").subscribe(answer =>{
        if (answer){
          this.store.dispatch(unpinPost({id: this.post.id}));
        }
      })
    }
    else {
      this.store.dispatch(pinPost({id: this.post.id}));
    }
  }

  userPinPost(){
    if(this.isUserPinned){
      this.alertService.confirm("Confirm Action", "Are you sure you want to unpin this post?").subscribe(answer =>{
        if (answer){
          this.store.dispatch(unpinUserPost({id: this.post.id}));
        }
      })
    }
    else {
      this.store.dispatch(pinUserPost({id: this.post.id}));
    }
  }

  updateNewFileName(file){
    let fileIndex = this.newFiles.findIndex(x => {return x.id == file.id});
    if (fileIndex !== -1){
      let newName = {newName: file.fileName, oldName: this.newFiles[fileIndex].file.name, oldType: this.newFiles[fileIndex].file.type}
      if (this.newFileNames == null){
        this.newFileNames = []
      }
      this.newFileNames.push(newName);
    }
  }

  saveEdit(){
    const postToSave = deepClone(this.post)
    postToSave.message = this.editPostMessage;
    postToSave.isPinned = this.isPinned;
    postToSave.fileIdsToRemove = this.filesToRemove;
    postToSave.otherFileUrls = this.fileUrls;
    postToSave.currentLinks = this.currentLinks;
    postToSave.newLinks = this.newLinks;
    if (this.newFiles != null && this.newFiles.length > 0){
      let date = new Date();
      let filesToSave = this.newFiles.map(x => {return x.file})
      postToSave.newFileNames = this.newFileNames;
      postToSave.createdDate = date.toISOString();
      postToSave.updatedDate = date.toISOString();
      postToSave.hasFiles = true;
      this.store.dispatch(editPostWithFiles({post: postToSave, files:  Object.assign([], filesToSave)}))
    }
    else{
      this.store.dispatch(editPost({post: postToSave}));
    }
    this.loading = true;
    this.postEditCharCount = 0;
    this.showEdit = false;
    this.newImageUrls = [];
    this.newFileUrls = [];
    this.allNewFileUrls=[];
    this.newFiles = [];
    this.newFilesDynamicIndex = -10000;
  }
}
