<ng-container *ngIf="profile">
<app-profile-header></app-profile-header>
  <div class="row">
    <div class="col-12 col-lg-5 pr-0">
      <div class="card h-100">
        <div class="card-body">
          <h4 class="">User Information</h4>
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-6">
                  <b>Legal First Name</b>
                </div>
                <div class="col-6">
                  {{ profile.user.firstName }}
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <b>Legal Last Name</b>
                </div>
                <div class="col-6">
                  {{ profile.user.lastName }}
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <b>User Name</b>
                </div>
                <div class="col-6">
                  {{ profile.user.userName }}
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <b>{{changingPassword?'New':''}} Password</b>
                </div>
                <div class="col-6">
                  <button
                    class="btn btn-thin btn-primary"
                    *ngIf="!changingPassword"
                    (click)="changingPassword = true"
                  >
                    Change Password
                  </button>
                  <div *ngIf="changingPassword" >
                    <input type="password" class="form-control mr-3 mt-2" [(ngModel)]="newPassword" />
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="changingPassword" >
                <div class="col-6">
                  <b>Confirm Password</b>
                </div>
                <div class="col-6">
                  <div>
                    <input type="password" class="form-control mr-3 mt-2" [(ngModel)]="newPasswordConfirm" />
                    <br/>
                    <button class="btn btn-thin btn-primary mr-2" [disabled]="!newPassword || newPassword!=newPasswordConfirm" (click)="changePassword()">Save</button>
                    <button
                      class="btn btn-thin btn-secondary"
                      (click)="changingPassword = false"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="card mt-3">
    <div class="card-body">
      <h1>Employee Information</h1>
      <app-contact-info></app-contact-info>
    </div>
  </div>
</ng-container>
