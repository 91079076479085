<div class="mt-2" *ngIf="coachingLogWithUsersModel && canAddCoach">
  <div class="row">
    <div class="col-3 ">
      <span class="strong">User</span><br /> {{coachingLogWithUsersModel.user.firstName+' '+coachingLogWithUsersModel.user.lastName}}
    </div>
    <div class="col-3 ">
      <span class="strong">Supervisor</span><br /> {{coachingLogWithUsersModel.createdByUser.firstName+' '+coachingLogWithUsersModel.createdByUser.lastName}}
    </div>
    <div class="col-3 ">
      <span class="strong">Region</span><br /> {{campusesHash[coachingLogWithUsersModel.user.primaryProgramID]}}
    </div>
    <div class="col-3 ">
      <span class="strong">First Review Date</span><br /> {{getFormatedDate(coachingLogWithUsersModel.followUpDate)}}
    </div>
    <hr />
  </div>
  <div class="row pt-2">
    <div class="col-3">
      <span class="strong">Level</span><br />
      {{getLevel(coachingLogWithUsersModel.level)}}
    </div>
    <div class="col-9">
      <span class="strong">Description</span><br />
      <span *ngIf="!coachingLogWithUsersModel.improvementPlanID">{{coachingLogWithUsersModel.description}}</span>
      <div *ngIf="coachingLogWithUsersModel.improvementPlanID">
        <span class="font-weight-bold">Benchmark:</span> {{coachingLogWithUsersModel.improvementPlan?.benchmark}}<br />
        <span class="font-weight-bold">Status:</span> {{getImprovementModelStatus(coachingLogWithUsersModel.improvementPlan)}}<br />

      </div>
    </div>
  </div>
  <hr />
  <div>
    <h4>Follow Up</h4>
    <div class="row form-group">
      <div class="col-3 d-flex align-items-center">
        <span class="strong">Complied</span>
      </div>
      <div class="col-9">
        <p-selectButton styleClass="w-100"  [options]="complyOptions" [(ngModel)]="comply" [ngModelOptions]="{standalone: true}"></p-selectButton>
        <small class="text-danger" *ngIf="compliedError">{{compliedError}}</small>
      </div>

    </div>
    <div class="row form-group">
      <div class="col-3 d-flex align-items-center">
        <span class="strong">Follow Up</span>
      </div>
      <div class="col-9 form-group">
        <textarea class="form-control mt-2" *ngIf="!wasUpdated" [(ngModel)]="coachingLogWithUsersModel.followUp"></textarea>
        <p *ngIf="wasUpdated">{{coachingLogWithUsersModel.followUp}}</p>
        <small class="text-danger" *ngIf="followUpError">{{followUpError}}</small>
      </div>
    </div>
    <div class="row form-group" *ngIf="comply == 0 || comply == 2">
      <div class="col-3 d-flex align-items-center">
        <span class="strong">Next Review Date</span>
      </div>
      <div class="col-9">
        <input type="date" class="form-control mt-2" *ngIf="!wasUpdated"  [(ngModel)]="coachingLogWithUsersModel.newFollowUpDate" />
        <span *ngIf="coachingLogWithUsersModel.newFollowUpDate && wasUpdated">{{getFormatedDate(coachingLogWithUsersModel.newFollowUpDate)}}</span>
        <small class="text-danger" *ngIf="newFollowUpDateError">{{newFollowUpDateError}}</small>
      </div>
    </div>
    <div class="row form-group" *ngIf="wasUpdated">
      <div class="col-3 d-flex align-items-center">
        <span class="strong">New Follow Up</span>
      </div>
      <div class="col-9">
        <textarea class="form-control mt-2"  [(ngModel)]="coachingLogWithUsersModel.newFollowUp"></textarea>
        <small class="text-danger" *ngIf="newFollowUpError">{{newFollowUpError}}</small>
      </div>
    </div>
  </div>
  <div class="row ">
    <div class="col">
      <span *ngIf="generalError" class="text-danger">{{generalError}}</span>
    </div>
  </div>
  <div class="">
    <div class="col-12 alert alert-warning">
      <ul class="p-2">
        <li *ngIf="wasUpdated && comply == 0">You will be requested to create a new Coaching Log at the next level. Save and Continue will close the current Coaching Log and redirect you to create a new Coaching Log that will be related to this one.</li>
        <li *ngIf="wasUpdated && comply == 2">You can create a new Coaching Log at the next level when you did not have opportunity to comply the current one. Save and Continue will close the current Coaching Log and redirect you to create a new Coaching Log that will be related to this one.</li>
        <li *ngIf="!wasUpdated">You can can set a second follow up date to remind you this Coaching Log.</li>
        <li>All the follow up will be shown to the user.</li>
      </ul>
    </div>
  </div>
  <div class="row mt-4  float-right">

    <div class="col">
      <i *ngIf="updatingCoachingLog" class="material-icons rotate text-muted">loop</i>
      <button *ngIf="!updatingCoachingLog" class="btn btn-secondary" (click)="cancel()">Cancel</button>
      <button *ngIf="!updatingCoachingLog && (!wasUpdated || comply != 0)" class="btn btn-success ml-2" (click)="save()">Save</button>
      <button *ngIf="!updatingCoachingLog && (wasUpdated && comply != 1)" class="btn btn-primary ml-2" (click)="saveAndContinue()">Save And Continue</button>
    </div>
  </div>
</div>
