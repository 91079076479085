import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State } from '../store';
import { loadProfile } from '../store/profile/profile.actions';
import { logout, setSessionConfirm } from '../store/account/account.actions';
import { AlertService } from 'src/app/shared/services/alert.service';
import { MessageService } from 'primeng';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  timer;
  constructor(private store: Store<State>,
    private alertService: AlertService,
    private messageService: MessageService,) { }
  intercept(request: HttpRequest<any>, next: HttpHandler,): Observable<HttpEvent<any>> {

    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.token) {

      
      // add authorization header with jwt token if available
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.token}`,

        },
      });
    }

    return next.handle(request).pipe(
      tap(evt => {
        if (evt instanceof HttpResponse) {
          // update the token with the new one generatd on every request to increase the expiration to more 2 hours
          const newToken = evt.headers.get('newtoken');
          const Expires = evt.headers.get('Expires');
          currentUser = JSON.parse(localStorage.getItem('currentUser'));
          if(Expires && currentUser && currentUser.token !== newToken){
            localStorage.setItem('Expires', JSON.stringify(Expires));
            console.log("JWinterceptor" + Expires);
          }
          if (newToken && currentUser && currentUser.token !== newToken) {
            currentUser.token = newToken;
            localStorage.setItem('currentUser', JSON.stringify(currentUser));
          }
          
          this.timerAutoLogout();
          
        }
      })
    );
  }

  timerAutoLogout(){
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {


      let Expires = JSON.parse(localStorage.getItem('Expires'));
      var expiration = this.parseToUTCDate(Expires);
      var currentTime = new Date();
      var ctUTC= currentTime;
      var remTime = Number(expiration) - Number(ctUTC);
      var currentUser = JSON.parse(localStorage.getItem('currentUser'));
      if(!currentUser){
        clearTimeout(this.timer);
        return;
      }
      var sessConfirmVisible = false;
      this.store.select(s => s.accountState.sessionConfirmVisible).subscribe(res => {
        sessConfirmVisible = res;
      });
      if(remTime <= 0){
        this.store.dispatch(logout());
        this.store.dispatch(loadProfile());
        this.store.dispatch(setSessionConfirm({ isVisible: false }));
        this.messageService.clear('confirmToat');
      }
      else if (remTime < 300000 && sessConfirmVisible == false){
        this.alertService.confirm("Session Expiring", "You have been inactive for more than 25 minutes. Would you like to continue? If no response is given you will be logged out.").subscribe(res =>{
          if (res) {
            this.store.dispatch(loadProfile());
            this.store.dispatch(setSessionConfirm({ isVisible: false }));
          }
          else {
            this.store.dispatch(logout());
            this.store.dispatch(loadProfile());
            this.store.dispatch(setSessionConfirm({ isVisible: false }));
          }
        });
        this.store.dispatch(setSessionConfirm({ isVisible: true }));
      }

      this.timerAutoLogout();
    },10000);
  }

  parseToUTCDate(expires) {
    // Split the date and time components
    const [datePart, timePart, meridian] = expires.split(' ');

    // Split the date into month, day, and year
    const [month, day, year] = datePart.split('/').map(Number);

    // Split the time into hours, minutes, and seconds
    
    let [hours, minutes, seconds] = timePart.split(':').map(Number);

    // Adjust hours based on AM/PM
    if (meridian === 'PM' && hours !== 12) {
        hours += 12;
    } else if (meridian === 'AM' && hours === 12) {
        hours = 0;
    }

    // Create a Date object in UTC
    const utcDate = new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds));

    return utcDate;
}
}
