<div class="card mb-2">
  <div class="card-body">
    <form class="" *ngIf="form && plannerSection" [formGroup]="form" >

      <button  class="btn btn-secondary float-right pointer" routerLink="/selfcare/management/planner">Back</button>
      <h2 class="">Edit Planner Section</h2>
      <div class="row" formGroupName="plannerSection">
        <div class="col-12 col-lg-8 col-xl-8">
          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Planner Section Title</b>
            </div>
            <div class="col-8 ">
                <input id="sectionTitleInput" type="text" class="form-control mt-2" formControlName="sectionTitle"/>
                <app-validation-message [form]="form.get('plannerSection')" [path]="'sectionTitle'" [name]="'sectionTitle'" [label]="'Planner Section Title'"> </app-validation-message>
            </div>
          </div>
          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Planner Section Layout</b>
            </div>
            <div class="col-8 ">
              <p-dropdown styleClass="form-control w-100 mt-2"  [options]="layoutOptions" formControlName="layoutType" placeholder="Select a layout...">
              </p-dropdown>

                <app-validation-message [form]="form.get('plannerSection')" [path]="'layoutType'" [name]="'layoutType'" [label]="'Planner Section Layout'"> </app-validation-message>
            </div>
          </div>
          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Main Color</b>
            </div>
            <div class="col-8 ">
              <p-colorPicker styleClass="mt-2 mr-2" formControlName="mainColor" (onChange)="changedColor($event)"></p-colorPicker>
              <input id="mainColorInput" type="text" class="form-control mt-2" formControlName="mainColor" (keyup)="changedColorInput($event)"/>
                <app-validation-message [form]="form.get('plannerSection')" [path]="'mainColor'" [name]="'mainColor'" [label]="'Main Color'"> </app-validation-message>
            </div>
          </div>
          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Description</b>
            </div>
            <div class="col-8 ">
                <input id="sectionDescriptionInput" type="text" class="form-control mt-2" formControlName="sectionDescription"/>
                <app-validation-message [form]="form.get('plannerSection')" [path]="'sectionDescription'" [name]="'sectionDescription'" [label]="'Description'"> </app-validation-message>
            </div>
          </div>

        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-lg-8 col-xl-8">
          <div class="card">
            <div class="card-body" formArrayName="items">
              <h4>Items <i class="fas fa-plus-circle pointer" (click)="addItem()"></i></h4>
                <table class="mt-2 w-100" >
                      <tr>
                          <th width="50px"></th>
                          <th >Item</th>
                          <th width="100px">OrderNr</th>
                      </tr>
                      <tr *ngFor="let item of getControls(form.get('items')); let i=index" [formGroup]="item">
                          <td>
                            <i class="fas fa-trash mr-2 pointer" title="Remove"  (click)="removeItem(i)"></i>
                          </td>
                          <td>
                              <input class="form-control" type="text" formControlName="sectionItem"/>
                              <app-validation-message [form]="item" [path]="'sectionItem'" [name]="'sectionItem'" [label]="'Item Text'"> </app-validation-message>

                          </td>
                          <td>
                            <input class="form-control" type="number" formControlName="orderNr"/>
                            <app-validation-message [form]="item" [path]="'orderNr'" [name]="'orderNr'" [label]="'Order Nr'"> </app-validation-message>

                          </td>
                      </tr>
                </table>
              </div>
            </div>
        </div>
      </div>
      <i *ngIf="loading" class="material-icons rotate text-muted">loop</i>
      <button class="btn btn-primary mt-3" id="saveButton" [class.disabled]="form.invalid || loading" (click)="save()">Save</button>
    </form>
  </div>
</div>
