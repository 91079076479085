import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, AfterViewInit, HostListener } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { State } from 'src/app/core/store';
import { AlertService } from 'src/app/shared/services/alert.service';
import { deepClone } from 'src/app/helpers/utils';
import { loadSelfcareOverSightEmployees } from 'src/app/core/store/selfcare/selfcare.actions';
@Component({
  selector: 'app-selfcare-management-menu',
  templateUrl: './selfcare-management-menu.component.html',
  styleUrls: ['./selfcare-management-menu.component.scss']
})
export class SelfcareManagementMenuComponent extends BaseComponent implements OnInit {



  constructor(
    private router: Router,) {
    super();
  }

  ngOnInit() {

  }

  openLink(path) {
    this.router.navigate([path]);
  }
}

