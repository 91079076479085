<div class="card mb-2">
    <div class="card-body">
        <H1>
          <a href="https://utahbehaviorservices.com/" target="_blank">
            <img [src]="generalSettings?.businessLogoUrl" height="80" class="mb-3" [alt]="generalSettings?.businessFullName">
          </a>  Employment Application</H1>
        <p>By filling out the form below, you certify that you are answering correctly and honestly to the best of your ability.</p>
        
      <form class="" [formGroup]="form">
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="row">
              <div class="col-4 d-flex align-items-center">
                <b>Legal First Name</b>
              </div>
              <div class="col-8 ">
                <input id="firstNameInput" type="text" class="form-control mt-3" formControlName="firstName" />
                <app-validation-message [form]="form" [path]="'firstName'" [name]="'firstName'" [label]="'Legal First Name'"> </app-validation-message>
              </div>
              <div class="col-4 d-flex align-items-center">
                <b>Legal Last Name</b>
              </div>
              <div class="col-8 ">
                <input id="lastNameInput" type="text" class="form-control mt-3" formControlName="lastName" />
                <app-validation-message [form]="form" [path]="'lastName'" [name]="'lastName'" [label]="'Legal Last Name'"> </app-validation-message>
              </div>
              <div class="col-4 d-flex align-items-center">
                <b>Phone Number</b>
              </div>
              <div class="col-8 ">
                <input id="phoneNumberInput" type="tel" class="form-control mt-3" minlength="10" [(ngModel)]="phoneNumber" (change)="phoneNumberChange()" formControlName="phoneNumber" />
                <app-validation-message [form]="form" [path]="'phoneNumber'" [name]="'phoneNumber'" [label]="'Phone Number'"> </app-validation-message>
              </div>
              <div class="col-4 d-flex align-items-center">
                <b>Email</b>
              </div>
              <div class="col-8 ">
                <input id="emailInput" type="text" class="form-control mt-3" formControlName="email" />
                <app-validation-message [form]="form" [path]="'email'" [name]="'email'" [label]="'Email'"> </app-validation-message>
              </div>
              <div class="col-4 d-flex align-items-center">
                <b>Region</b>
              </div>
              <div class="col-8">
                <i *ngIf="!campusesList" class="material-icons rotate text-muted">loop</i>
                <p-dropdown styleClass="form-control mt-3 w-100" [options]="campusesList" (onChange)="regionChange($event)" placeholder="Select campus..." formControlName="regionID"></p-dropdown>
                <app-validation-message [form]="form" [path]="'regionID'" [name]="'regionID'" [label]="'Region'"> </app-validation-message>
              </div>
              <div class="col-4 d-flex align-items-center">
                  <b>Best time to contact you</b>
                </div>
                <div class="col-8">
                  <select class="form-control mt-3" id="contactTimeInput"  formControlName="contactTime">
                      <option value="Mornings (9-12)">Mornings (9-12)</option>
                      <option value="Noon (12-3)">Noon (12-3)</option>
                      <option value="Evenings (3-6)">Evenings (3-6)</option>
                  </select>
                  <app-validation-message [form]="form" [path]="'contactTime'" [name]="'contactTime'" [label]="'Best time to contact you'"> </app-validation-message>
                </div>
              <div class="col-4 d-flex align-items-center">
                <b>How did you hear about us?</b>
              </div>
              <div class="col-8">
                <select id="heardOfUsThroughInput" class="form-control mt-3 w-100" (change)="changeReferredStatus($event)"  formControlName="heardOfUsThrough">
                    <option value="">Select...</option>
                    <option value="LinkedIn">LinkedIn</option>
                    <option value="Indeed">Indeed</option>
                    <option value="Glassdoor">Glassdoor</option>
                    <option value="Ziprecruiter">Ziprecruiter</option>
                    <option value="Handshake">Handshake</option>
                    <option value="Google Search/Website">Google Search/Website</option>
                    <option value="Job Fair">Job Fair</option>
                    <option value="Social Media">Social Media</option>
                    <option value="Employee Referral">Employee Referral</option>
                    <option value="Other">Other...</option>
                  </select>
                <!-- <input id="heardOfUsThroughInput" class="form-control mt-3 w-100" disabled [value]="'Indeed'" placeholder="Indeed" formControlName="heardOfUsThrough"/> -->
                <app-validation-message [form]="form" [path]="'heardOfUsThrough'" [name]="'heardOfUsThrough'" [label]="'How did you hear about us?'"> </app-validation-message>
              </div>
              <div class="col-4 d-flex mt-3 align-items-center" *ngIf="employeeReferral">
                <b >Referred By</b>
              </div>
              <div class="col-8 " *ngIf="employeeReferral">
                    <input id="referredByInput" type="text" class="form-control mt-3" formControlName="referredBy" placeholder="Please Specify Who..."/>
                    <app-validation-message [form]="form" [path]="'referredBy'" [name]="'referredBy'" [label]="'Referred By'"> </app-validation-message>
                </div>
                <div class="col-4 d-flex mt-3 align-items-center">
                  <b>Are you at least 18 years of age?</b>
                </div>
                <div class="col-8 ">
                  <select class="form-control mt-3 w-100" id="isAgeLimitInput" formControlName="isAgeLimit" >
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                  </select>
                  <app-validation-message [form]="form" [path]="'isAgeLimit'" [name]="'isAgeLimit'" [label]="'Are you at least 18 years of age?'"> </app-validation-message>
                </div>
                
                <div class="col-4 d-flex mt-3 align-items-center">
                  <b>Do you have at least a High School Diploma or GED?</b>
                </div>
                <div class="col-8 ">
                  <select class="form-control mt-3 w-100" id="diplomaInput" formControlName="diploma" >
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                  </select>
                  <app-validation-message [form]="form" [path]="'diploma'" [name]="'diploma'" [label]="'Do you have at least a High School Diploma or GED?'"> </app-validation-message>
                </div>
                
                <div class="col-4 d-flex mt-3 align-items-center">
                  <b>Can you Pass a Background Check according to state and federal policies and requirements?</b>
                </div>
                <div class="col-8 ">
                  <select class="form-control mt-3 w-100" id="backgroundCheckInput" formControlName="backgroundCheck" >
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                  </select>
                  <app-validation-message [form]="form" [path]="'backgroundCheck'" [name]="'backgroundCheck'" [label]="'Can you Pass a Background Check according to state and federal policies and requirements?'"> </app-validation-message>
                </div>

                <div class="col-4 d-flex mt-3 align-items-center">
                  <b>Do you have a Valid Drivers License?</b>
                </div>
                <div class="col-8 ">
                  <select class="form-control mt-3 w-100" id="hasValidDriversLicense" formControlName="hasValidDriversLicense">
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                  </select>
                  <app-validation-message [form]="form" [path]="'hasValidDriversLicense'" [name]="'hasValidDriversLicense'" [label]="'Do you have a valid drivers license?'"> </app-validation-message>
                </div>
                <hr/>
                
                <div class="col-4 d-flex mt-3 align-items-center">
                  <b>Do you have your own reliable transportation to get to and from work and to transport clients to and from community outings?</b>
                </div>
                <div class="col-8 ">
                  <select class="form-control mt-3 w-100" id="reliableTransportationInput" formControlName="reliableTransportation">
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                  </select>
                  <app-validation-message [form]="form" [path]="'reliableTransportation'" [name]="'reliableTransportation'" [label]="'Do you have your own reliable transporation?'"> </app-validation-message>
                </div>
                
              <div class="col-4 d-flex mt-3 align-items-center">
                <b>Upload Resume</b>
              </div>
              <div class="col-8">
                <input class="mt-3" type="file" multiple #ResumeInput accept="image/*,.pdf,.doc,.docx" (change)="changedResume(ResumeInput)" />
              </div>
              <div class="col-4 d-flex mt-3 align-items-center">
                  <b>Upload Cover Letter (Optional)</b>
                </div>
                <div class="col-8">
                  <input class="mt-3" type="file" multiple #CoverLetterInput accept="image/*,.pdf,.doc,.docx" (change)="changedCoverLetter(CoverLetterInput)" />
                </div>
            </div>
          </div>
        </div>
        <button class="btn btn-primary mt-3" id="saveButton" [class.disabled]="form.invalid || loading" (click)="save()">Save<i [hidden]="!loading" class="material-icons rotate text-muted">loop</i></button>
      </form>
    </div>
  </div>



<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-K92MDMT3"
  height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
  <!-- End Google Tag Manager (noscript) -->