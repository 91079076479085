import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HolidayService } from '../../services/holiday.service';
import { Store } from '@ngrx/store';
import { State } from '..';
import {
  loadHolidayYears, loadHolidayYearsSuccess, changeHolidayUserFilterSuccess, changeHolidayUserFilter,
  getHolidayList, getHolidayListSuccess, saveHoliday, saveHolidaySuccess, deleteHoliday, deleteHolidaySuccess,
  getRecurringHolidayList, getRecurringHolidayListSuccess, saveRecurringHoliday, saveRecurringHolidaySuccess,
  loadRecurringTypes, loadRecurringTypesSuccess, loadRecurringWeeks, loadRecurringWeeksSuccess
} from './holiday.actions';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { errorHappened } from '../profile/profile.actions';
import { HolidayModel, RecurringHolidayModel } from '../../../models/holidayModel';


@Injectable()
export class HolidayEffects {
  constructor(private actions$: Actions,
    private holidayService: HolidayService,
    private store: Store<State>) { }

  loadHolidayYears = createEffect(() => this.actions$.pipe(
    ofType(loadHolidayYears),
    switchMap(action => this.holidayService.getHolidayYears().pipe(
      map(years => loadHolidayYearsSuccess({ years })),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))));

  changeHolidayUserFilter = createEffect(() => this.actions$.pipe(
    ofType(changeHolidayUserFilter),
    switchMap(action =>
      this.holidayService.changeHolidayUserFilter().pipe(
      map(userList => changeHolidayUserFilterSuccess({ userList })),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))));

  getHolidayList = createEffect(() => this.actions$.pipe(
    ofType(getHolidayList),
    switchMap(action => {
      return this.holidayService.getHolidayList(action.filter, action.holidayName, action.year, action.month, action.userID, action.startDate, action.endDate).pipe(
        map(holidayList => getHolidayListSuccess({ holidayList })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  saveHoliday = createEffect(() => this.actions$.pipe(
    ofType(saveHoliday),
    switchMap(action => {
      let observable: Observable<HolidayModel>;

      if (action.holiday.holidayId > 0) {
        observable = this.holidayService.editHoliday(action.holiday);
      }
      else {
        observable = this.holidayService.addHoliday(action.holiday);
      }

      return observable.pipe(
        map(holiday => saveHolidaySuccess()),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })));

  deleteHoliday = createEffect(() => this.actions$.pipe(
    ofType(deleteHoliday),
    switchMap(action =>
      this.holidayService.deleteHoliday(action.holidayId).pipe(
        map(holiday => deleteHolidaySuccess()),
        catchError(err => {
          return of(errorHappened({ err }));
        }))
    )));

  getRecurringHolidayList = createEffect(() => this.actions$.pipe(
    ofType(getRecurringHolidayList),
    switchMap(action => {
      return this.holidayService.getRecurringHolidayList(action.filter, action.holidayName, action.month, action.startDate, action.endDate, action.showArchived).pipe(
        map(holidayList => getRecurringHolidayListSuccess({ holidayList })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  saveRecurringHoliday = createEffect(() => this.actions$.pipe(
    ofType(saveRecurringHoliday),
    switchMap(action => {
      let observable: Observable<RecurringHolidayModel>;

      if (action.holiday.recurringHolidayId > 0) {
        observable = this.holidayService.editRecurringHoliday(action.holiday);
      }
      else {
        observable = this.holidayService.addRecurringHoliday(action.holiday);
      }

      return observable.pipe(
        map(holiday => saveRecurringHolidaySuccess()),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })));  

  loadRecurringTypes = createEffect(() => this.actions$.pipe(
    ofType(loadRecurringTypes),
    switchMap(action => this.holidayService.getRecurringTypes().pipe(
      map(types => loadRecurringTypesSuccess({ types })),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))));

  loadRecurringWeeks = createEffect(() => this.actions$.pipe(
    ofType(loadRecurringWeeks),
    switchMap(action => this.holidayService.getRecurringWeeks().pipe(
      map(weeks => loadRecurringWeeksSuccess({ weeks })),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))));
}
