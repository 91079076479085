import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { Actions, ofType} from '@ngrx/effects';
import { State } from '../../../../core/store';
import { Store } from '@ngrx/store';
import { BenefitPlanWithdrawnReportModel } from '../../../../models/benefitsModels';
import { DateRangeModel } from '../../../../models/dateRangeModel';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { DropdownFilterService } from '../../../../core/services/dropdown-filter.service';
import { deepClone, downloadFile } from '../../../../helpers/utils';
import { loadEmployeeUsers, loadViewableCampuses } from '../../../../core/store/employee/employee.action';
import { exportBenefitWithdrawnToExcel, exportBenefitWithdrawnToExcelSuccess, getBenefitWithdrawnList, loadBenfitPlanTypes, loadWithdrawnReasons } from '../../../../core/store/benefits/benefits.actions';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-list-benefit-withdrawn',
  templateUrl: './list-benefit-withdrawn.component.html',
  styleUrls: ['./list-benefit-withdrawn.component.scss']
})
export class ListBenefitWithdrawnComponent extends BaseComponent implements OnInit {
  list: PagingResultsModel<BenefitPlanWithdrawnReportModel>;
  filter: any;
  canEdit: boolean = false;

  loadingList: boolean = true;
  loadingExcel: boolean = false;

  campusList: SelectItem[];
  campusId: number = 0;

  prefixText: string;
  userEmployeeList: SelectItem[];
  userId: number = 0;

  dateRangeList: SelectItem[];
  dateRange: DateRangeModel;
  startDate: string = null;
  endDate: string = null;
  isCustomDateRange: boolean = false;

  planTypes: SelectItem[];
  planTypeId: number = 0;

  withdrawnReasons: SelectItem[];
  withdrawnReasonId: number = 0;

  carrier: string = "";


  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private dropdownFilterService: DropdownFilterService) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.benefitsState.loadingWithdrawnList).subscribe(loadingWithdrawnList => {
        this.loadingList = loadingWithdrawnList;
      }),
      this.store.select(s => s.benefitsState.loadingWithdrawnExcel).subscribe(loadingWithdrawnExcel => {
        this.loadingExcel = loadingWithdrawnExcel;
      }),
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.canEdit = permissionIDs && !!permissionIDs.find(p => p === 3075);
      }),
      this.store.select(s => s.employeeState.campuses).subscribe(campuses => {
        this.campusList = campuses && campuses.length > 0 ? campuses
          : [{ label: 'loading...', value: undefined }];
      }),
      this.store.select(s => s.benefitsState.benefitPlanTypesList).subscribe(planTypes => {
        if (planTypes) {
          this.planTypes = planTypes.map(x => ({ label: x.planTypeName, value: x.id }));
          this.planTypes.unshift({ label: 'All', value: 0 });
        }
        else {
          this.planTypes = [{ label: 'loading...', value: undefined }]
        }
      }),
      this.store.select(s => s.benefitsState.withdrawnReasons).subscribe(reasons => {
        if (reasons) {
          this.withdrawnReasons = reasons.map(x => ({ label: x.label, value: x.value }));
          this.withdrawnReasons.unshift({ label: 'All', value: 0 });
        }
        else {
          this.withdrawnReasons = [{ label: 'loading...', value: undefined }]
        }
      }),
      this.store.select(s => s.employeeState.employeeUsers).subscribe(users => {
        this.userEmployeeList = users;
      }),
      this.store.select(s => s.benefitsState.withdrawnList).subscribe(list => {
        this.list = list;
      }),
      this.actions$.pipe(
        ofType(exportBenefitWithdrawnToExcelSuccess),
        tap(action => {
          downloadFile(action.doc, 'BenefitWithdrawnReport.xlsx');
        })
      ).subscribe()
    );

    this.dateRangeList = this.dropdownFilterService.getLast120DayDateRanges().map(c => ({ label: c.text, value: c }));
    this.store.dispatch(loadViewableCampuses());
    this.store.dispatch(loadBenfitPlanTypes());
    this.store.dispatch(loadWithdrawnReasons());
  }

  changedDateRange() {
    if (this.dateRange.isCustom) {
      this.isCustomDateRange = true;
      return; //reload after manually changing date
    }

    this.isCustomDateRange = false;
    this.startDate = this.dateRange.startDate;
    this.endDate = this.dateRange.endDate;
    this.reloadList();
  }

  reloadFilter(event) {
    this.filter = event;
    this.reloadList();
  }

  reloadList() {
    this.filter = deepClone(this.filter);
    this.filter.filters.userId = { Name: 'userId', Value: { value: this.userId.toString() } };
    this.filter.filters.programId = { Name: 'programId', Value: { value: this.campusId.toString() } };
    this.filter.filters.startDate = { Name: 'startDate', Value: { value: this.startDate } };
    this.filter.filters.endDate = { Name: 'endDate', Value: { value: this.endDate } };
    this.filter.filters.planTypeId = { Name: 'planTypeId', Value: { value: this.planTypeId } };
    this.filter.filters.withdrawnReasonId = { Name: 'withdrawnReasonId', Value: { value: this.withdrawnReasonId } };
    this.filter.filters.carrier = { Name: 'carrier', Value: { value: this.carrier } };

    this.store.dispatch(getBenefitWithdrawnList({
      filter: this.filter
    }));
  }

  getStaffList(event) {
    this.store.dispatch(loadEmployeeUsers({
      prefixText: event.query,
      programId: this.campusId,
      payrollClassificationId: null,
      showInactive: false,
      showTerminated: false
    }));
  }

  reloadListAfterStaffChage(selectedUser) {
    if (typeof selectedUser.value !== 'number') {
      return;
    }

    this.userId = selectedUser.value;
    this.reloadList();
  }

  reloadListAfterClear(event) {
    if (event.data !== null) {
      return;
    }

    this.userId = 0;
    this.reloadList();
  }

  exportToExcel() {
    this.filter = deepClone(this.filter);
    this.filter.filters.userId = { Name: 'userId', Value: { value: this.userId.toString() } };
    this.filter.filters.programId = { Name: 'programId', Value: { value: this.campusId.toString() } };
    this.filter.filters.startDate = { Name: 'startDate', Value: { value: this.startDate } };
    this.filter.filters.endDate = { Name: 'endDate', Value: { value: this.endDate } };
    this.filter.filters.planTypeId = { Name: 'planTypeId', Value: { value: this.planTypeId } };
    this.filter.filters.withdrawnReasonId = { Name: 'withdrawnReasonId', Value: { value: this.withdrawnReasonId } };
    this.filter.filters.carrier = { Name: 'carrier', Value: { value: this.carrier } };

    this.store.dispatch(exportBenefitWithdrawnToExcel({
      filter: this.filter
    }));
  }
}
