<small  *ngIf="form.get(path).invalid && (form.get(path).dirty || form.get(path).touched)"
  class="text-danger"
>
  {{
    form.get(path).errors.required
      ? label + " is required"
      : form.get(path).errors.email
      ? "Invalid " + label
      : form.get(path).errors.pattern
      ? "Invalid " + label
      : form.get(path).errors.maxlength
      ? label +
        " max length is " +
        form.get(path).errors.maxlength.requiredLength
      : form.get(path).errors.minlength
      ? label +
        " min length is " +
        form.get(path).errors.minlength.requiredLength
      : form.get(path).errors.min
      ? label +
        " min value is " +
        form.get(path).errors.min.min
      : form.get(path).errors.max
      ? label +
        " max value is " +
        form.get(path).errors.max.max
      : form.get(path).errors.confirmNotEqual
      ? "Confirmation field must match"
      :form.get(path).errors.genericMessage
      ? form.get(path).errors.genericMessage
      :""
  }}</small
>
