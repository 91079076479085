<div class="add-post-container d-flex justify-content-center align-content-center">
    <div class="d-flex flex-column add-post-card">
        <textarea class="post-message" (input)="onInput($event)" rows="1" #postMessage [(ngModel)]='message' (ngModelChange)="countCharacters()" ></textarea>
        <div *ngIf="newLinks != null && newLinks.length > 0" class="w-100 m-0 p-0">
            <form>
                <div class="d-flex flex-column mt-2 mb-2 w-90 ml-3 mr-3 file-container" *ngFor="let file of newLinks; let i = index;">
                    <a [href]="file.url" target="_blank" class="w-90 ml-3 mr-3 mt-1 d-flex justify-content-center align-items-center pointer">
                        <i [ngClass]="file.icon" [style.color]="file.iconColor"></i>
                        <p *ngIf="screenWidth < 993 && screenWidth >= 430" class="m-0 ml-3 p-0">{{ file.url | slice:0:22 }}{{file.url.length > 22 ? '...': ''}}</p>
                        <p *ngIf="screenWidth < 430" class="m-0 ml-3 p-0">{{ file.url | slice:0:16 }}{{file.url.length > 16 ? '...': ''}}</p>
                        <p *ngIf="screenWidth >= 993" class="m-0 ml-3 p-0">{{ file.url | slice:0:35 }}{{file.url.length > 35 ? '...': ''}}</p>
                    </a>
                </div>
            </form>
        </div>
        <div *ngIf="otherFiles != null && otherFiles.length > 0" class="w-100 m-0 p-0">
            <div class="w-100 mt-2 mb-2" *ngFor="let file of otherFiles">
                <div class="w-100 d-flex justify-content-center align-items-center file-container pointer">
                    <i class="fas fa-file-alt mt-1"></i>
                    <p class="m-0 ml-3 p-0">{{ file.fileName }}</p>
                </div>
            </div>
        </div>
        <div *ngIf="showImages" class="w-100 mt-2 mb-2" [ngClass]="{'all-images-container' : additionalPhotos != null, 'main-photo-container': additionalPhotos == null }">
            <div class="col d-flex justify-content-center align-items-center" [ngClass]="{'post-image-wrapper-with-additional' : additionalPhotos != null, 'post-image-wrapper': additionalPhotos == null }" #mainPhoto>
                <img [src]="mainPostPhoto.url" alt="img.jpg" class="pointer m-0 p-0" (click)="open(mymodal)" />     
            </div>
            <div *ngIf="moreThanOnePhoto" class="w-100 h-100 m-0 p-0 additional-photo-element">
                <div class="ml-2 mr-0 d-flex additional-photos-container">
                    <div class="d-flex additional-image-container">
                        <div *ngFor="let file of additionalPhotos"  class="additional-image-wrapper d-flex justify-content-center align-items-center mt-1">
                            <img [src]="file.url" alt="img.jpg" class="pointer" (click)="open(mymodal)" />     
                        </div>
                    </div>
                    <div *ngIf="moreThanOnePhoto" class="additional-button-wrapper d-flex justify-content-center align-items-center mt-1 pointer" (click)="open(mymodal)">
                        <p class="text-primary">View&nbsp;{{fileCount}}&nbsp;Files</p>
                    </div>
                </div>
            </div>
        </div>
        <p class="d-flex w-100 justify-content-end mb-0 pb-0" style="font-size:9px;">{{postCharCount}} / 8000</p>
        <div class="row">
            <div class="custom-control custom-switch col d-flex justify-content-start mt-2" [ngClass]="{'ml-2': screenWidth <= 493, 'ml-3': screenWidth > 493}" title="Pins post for all users">
                <input *ngIf="managePinnedPosts" class="custom-control-input" type="checkbox" id="pinned" [(ngModel)]="isPinned"/>
                <label *ngIf="managePinnedPosts" class="custom-control-label" for="pinned">Pin</label>
            </div>
            <div class="d-flex justify-content-center align-items-center col mt-1">
                <button class="btn btn-secondary" (click)="open(mymodal)">Add&nbsp;Files</button>
                <div class="d-flex justify-content-center align-items-center mt-1 add-link-button pointer" [ngClass]="{'ml-1': screenWidth <= 493, 'ml-3': screenWidth > 493}"(click)="openAddLink(linkmodal)">
                    <i class="fas fa-link m-0 p-0"></i>
                </div>
                <p *ngIf="urls != null" class="mt-3 ml-2 d-flex justify-content-center" style="font-size:9px;">{{ urls.length }} Files Uploaded</p>
            </div>
            <div class="col d-flex justify-content-end mt-1">
                <button class="btn btn-primary" [ngClass]="{'w-100': screenWidth <= 493}" (click)="savePost()">Post</button>
            </div>
        </div>
    </div>
</div>
<ng-template #mymodal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Add Images To Post</h4>
      <button type="button" class="close btn btn-outline-dark" aria-label="Close"
        (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body d-flex flex-column justify-content-center">
        <input class="mt-3" type="file" multiple #PostFile accept="image/*,.pdf,.doc,.docx" (change)="uploadFile($event)" />
    </div>
    <div *ngIf="otherFiles != null && otherFiles.length > 0" class="w-100 m-0 p-0">
        <form>
            <div class="d-flex flex-column w-100 mt-2 mb-2" *ngFor="let file of otherFiles; let i = index;">
                <div class="w-90 ml-3 mr-3 d-flex justify-content-center align-items-center file-container pointer">
                    <div class="remove-file d-flex justify-content-center align-items-center pointer" (click)="removeAddedImage(file)">
                        <i class="fas fa-trash pointer"></i>
                    </div>
                    <i class="fas fa-file-alt mt-1"></i>
                    <p class="m-0 ml-3 p-0">{{ file.fileName }}</p>
                </div>
                <div class="w-100 d-flex ml-1 mt-2 justify-content-center align-items-center row">
                    <div class="col-2 w-100 d-flex justify-content-center" >
                        <label for="editFileName" class="form-control-label">Edit&nbsp;File's&nbsp;Name:</label>
                    </div>
                    <div class=" ml-2 col-6 w-100 d-flex justify-content-center" >
                        <input type="text" id="editFileName" class="form-control w-100" (focusout)="updateNewFileName(file)" [(ngModel)]="file.fileName" [ngModelOptions]="{standalone: true}"/>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div *ngFor="let file of displayUrls" class="d-flex modal-images justify-content-center align-items-center m-2">
        <div class="remove-image d-flex justify-content-center align-items-center pointer" (click)="removeAddedImage(file)">
            <i class="fas fa-trash pointer"></i>
        </div>
        <img [src]="file.url" [alt]="file" class="rounded"/>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('Cancel')">Close</button>
    </div>
  </ng-template>
  <ng-template #linkmodal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Add Links To Post</h4>
      <button type="button" class="close btn btn-outline-dark" aria-label="Close"
        (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body row ml-2 mr-2">
        <label class="col-2 mr-2">Add&nbsp;Link:</label>
        <input class="form-control col-6 mr-2" type="text" [(ngModel)]="newLinkInput" />
        <button class="col-2 btn btn-secondary" (click)="addNewLink()">Add Link</button>
    </div>
    <div *ngIf="newLinks != null && newLinks.length > 0" class="w-100 m-0 p-0">
        <form>
            <div class="d-flex flex-column mt-2 mb-2 w-90 ml-3 mr-3 file-container" *ngFor="let file of newLinks; let i = index;">
                <div class="remove-file d-flex justify-content-center align-items-center pointer" (click)="removeAddedLink($event, file)">
                    <i class="fas fa-trash pointer"></i>
                </div>
                <a [href]="file.url" target="_blank" class="w-90 ml-3 mr-3 mt-1 d-flex justify-content-center align-items-center pointer">
                    <i [ngClass]="file.icon" [style.color]="file.iconColor"></i>
                    <p *ngIf="screenWidth < 993 && screenWidth >= 430" class="m-0 ml-3 p-0">{{ file.url | slice:0:22 }}{{file.url.length > 22 ? '...': ''}}</p>
                    <p *ngIf="screenWidth < 430" class="m-0 ml-3 p-0">{{ file.url | slice:0:16 }}{{file.url.length > 16 ? '...': ''}}</p>
                    <p *ngIf="screenWidth >= 993" class="m-0 ml-3 p-0">{{ file.url | slice:0:35 }}{{file.url.length > 35 ? '...': ''}}</p>
                </a>
            </div>
        </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('Cancel')">Close</button>
    </div>
  </ng-template>

