<div *ngIf="selectType == 'AutoComplete'" class="autocomplete-container">
    <input id="firstNameInput" type="text" [placeholder]="inputPlaceholder" autocomplete="off" 
    [ngClass]="addClass ? inputClass : ''" class="mt-3 w-100" [(ngModel)]="searchString" (keydown)="keyboardButtonPress($event)"
    (ngModelChange)="onChange()" (click)="clickIntoInput()" />
    <div *ngIf="options != null && showDropDown" id="autoCompleteContainer" class="autocomplete-dropdown rounded-bottom" (mouseleave)="leaveDropdown()">
        <div *ngFor="let item of options; let i = index" [id]="'autoCompleteIndex' + i" [ngClass]="selectedOptionValue == item.value ? 'selected-dropdown-opt' : ''" 
        class="m-0 pl-3 pr-3 pointer autocomplete-dropdown-options d-flex align-items-center" (click)="setOption($event, item)" (mouseover)="hoverItem(item, i)">
            <p class="m-0 p-0">
                {{ item.label }}
            </p>
        </div>
    </div>
</div>
<div *ngIf="selectType == 'MultiSelect'" class="autocomplete-container">
    <input #multiSelectInput id="firstNameInput" type="text" [placeholder]="inputPlaceholder" autocomplete="off" 
    [ngClass]="addClass ? inputClass : ''" class="mt-3 w-100" [(ngModel)]="searchString" (keydown)="keyboardMultiSelectButtonPress($event)"
    (ngModelChange)="onMultiSelectChange()" (click)="clickIntoMultiSelectInput()" />
    <div *ngIf="options != null && showDropDown" id="autoCompleteContainer" class="autocomplete-dropdown form-check rounded-bottom" (mouseleave)="leaveMultiSelectDropdown()">
        <div class="w-100 d-flex justify-content-between m-0 mb-1 mt-1 pt-1">
            <p class="p-0 m-0 pointer text-primary multi-select-dropdown-buttons" (click)="toggleShowingCurrentSelection($event)"><em> {{showCurrentSelections ? "Hide" : "Show"}} Current Selection</em></p>
            <p class="p-0 m-0 mr-3 pointer text-primary multi-select-dropdown-buttons" title="Clear And Close MultiSelect" (click)="clearSelection($event)"><b>Clear Selection</b></p>
        </div>
        <div *ngIf="showCurrentSelections">
            <div *ngFor="let item of currentSelectedOptions; let i = index" 
            class="m-0 pt-1 pb-1 pointer mulitselect-currently-selected d-flex align-items-center" (click)="multiSelectSetOption($event, item)">
                <p class="m-0 p-0 pl-2"> {{ item.label }} </p>
            </div>
            <hr>
        </div>
        <div *ngFor="let item of options; let i = index" [id]="'multiSelectIndex' + i" [ngClass]="selectedOptionValue == item.value ? 'selected-dropdown-opt' : ''" 
        class="m-0 pointer autocomplete-dropdown-options d-flex align-items-center" (click)="multiSelectSetOption($event, item)" (mouseover)="hoverMultiSelectItem(item, i)">
            <input type="checkbox" class="form-check-input ml-2" id="'multiSelectOptionIndex' + i" value="item.value"
                [checked]="isChecked(item.value)" name="selectMultiSelectOption"  />
            <label class="form-check-label ml-3 pl-2" for="'multiSelectOptionIndex' + i"> - {{ item.label }} </label>
        </div>
    </div>
</div>
