import { ProspectiveCandidateContactLogModel, ProspectiveCandidateReferenceModel } from 'src/app/models/prospectiveCandidateModel';
import { ProspectiveCandidateModel } from './../../../models/prospectiveCandidateModel';
import { PagingResultsModel } from './../../../models/pagingResultsModel';
import { createReducer, on, Action } from '@ngrx/store';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FormError } from 'src/app/models/utilModels';
import { deepClone, getFormattedTime } from 'src/app/helpers/utils';
import {
  addProspectiveCandidateReference,
  addProspectiveCandidateContactLog,
  clearProspectiveCandidate,
  createProspectiveCandidate, createProspectiveCandidateSuccess,
  deleteProspectiveCandidate, deleteProspectiveCandidateFail, deleteProspectiveCandidateSuccess,
  getProspectiveCandidate, getProspectiveCandidateFail, getProspectiveCandidates,
  getProspectiveCandidatesFail, getProspectiveCandidatesList, getProspectiveCandidatesListFail,
  getProspectiveCandidatesListSuccess, getProspectiveCandidatesSuccess, getProspectiveCandidateSuccess, getResume, getResumeSuccess,
  loadPropsectiveCandidateReference,
  loadPropsectiveCandidateReferenceFail,
  loadPropsectiveCandidateReferenceSuccess,
  removeProspectiveCandidateReference,
  savePropsectiveCandidateReference,
  savePropsectiveCandidateReferenceFail,
  savePropsectiveCandidateReferenceSuccess,
  saveProspectiveCandidate, saveProspectiveCandidateFail, saveProspectiveCandidateSuccess,
  updateProspectiveCandidateForm,
  updateProspectiveCandidateSurveyForm,
  addProspectiveCandidateContactLogSuccess,
  editProspectiveCandidateContactLogSuccess,
  saveProspectiveCandidateMiscDocSuccess,
  deleteProspectiveCandidateMiscDocSuccess
} from './prospective-candidate.actions';
import { createOfferLetterFail } from '../offerletter/offerletter.actions';
import { ValidatorRequiredNotEmptyIfBool, ValidatorRequiredNotEmptyIfFalse } from 'src/app/helpers/validators';
import { formatDate } from '@angular/common';
import * as moment from 'moment';

export interface ProspectiveCandidatesState {
  creatingProspectiveCandidate: boolean;
  formErrors: FormError[];
  loadingProspectiveCandidates: boolean;
  deletingProspectiveCandidate: boolean;
  loadingProspectiveCandidate: boolean;
  prospectiveCandidates: PagingResultsModel<ProspectiveCandidateModel>;
  prospectiveCandidate: ProspectiveCandidateModel;
  prospectiveCandidatesList: ProspectiveCandidateModel[];

  prospectiveCandidateReference: ProspectiveCandidateReferenceModel;
  loadingProspectiveCandidateReference: boolean;
  savingProspectiveCandidateReference: boolean;

  prospectiveCandidateContactLog: ProspectiveCandidateContactLogModel;
  loadingProspectiveCandidateContactLog: boolean;
  savingProspectiveCandidateContactLog: boolean;
  miscDocURLs: string[];
}

const initialState: ProspectiveCandidatesState = {
  formErrors: [],
  creatingProspectiveCandidate: false,
  loadingProspectiveCandidates: false,
  deletingProspectiveCandidate: false,
  loadingProspectiveCandidate: false,
  prospectiveCandidates: null,
  prospectiveCandidate: null,
  prospectiveCandidatesList: null,
  prospectiveCandidateReference: null,
  loadingProspectiveCandidateReference: false,
  savingProspectiveCandidateReference: false,
  prospectiveCandidateContactLog: null,
  loadingProspectiveCandidateContactLog: false,
  savingProspectiveCandidateContactLog: false,
  miscDocURLs: [],
};

export const prospectiveCandidateReducer = createReducer(initialState,
  on(updateProspectiveCandidateForm, (state, action) => {
    const prospectiveCandidate = { ...state.prospectiveCandidate, ...action.prospectiveCandidate };
    const newState = { ...state, ...{ prospectiveCandidate, formErrors: action.formErrors } };
    return newState;
  }),

  on(addProspectiveCandidateReference, (state, action) => {
    const prospectiveCandidate: ProspectiveCandidateModel = state.prospectiveCandidate ? deepClone(state.prospectiveCandidate) : {};
    if (!prospectiveCandidate.references) {
      prospectiveCandidate.references = [];
    }
    prospectiveCandidate.references.push(action.reference);
    const newState = { ...state, ...{ prospectiveCandidate } };
    return newState;
  }),

  on(addProspectiveCandidateContactLogSuccess, (state, action) => {
    const prospectiveCandidate: ProspectiveCandidateModel = state.prospectiveCandidate ? deepClone(state.prospectiveCandidate) : {};
    if (!prospectiveCandidate.contactLogs) {
      prospectiveCandidate.contactLogs = [];
    }
    prospectiveCandidate.contactLogs.push(action.log);
    const newState = { ...state, ...{ prospectiveCandidate } };
    return newState;
  }),

  on(editProspectiveCandidateContactLogSuccess, (state, action) => {
    const prospectiveCandidate: ProspectiveCandidateModel = state.prospectiveCandidate ? deepClone(state.prospectiveCandidate) : {};
    const contactLog = prospectiveCandidate.contactLogs.find(x => x.id == action.log.id);
    contactLog.contactDetails = action.log.contactDetails;
    const newState = { ...state, ...{ prospectiveCandidate } };
    return newState;
  }),

  on(removeProspectiveCandidateReference, (state, action) => {
    const prospectiveCandidate: ProspectiveCandidateModel = state.prospectiveCandidate ? deepClone(state.prospectiveCandidate) : {};
    if (prospectiveCandidate.references) {
      const index = prospectiveCandidate.references.findIndex(x => x.firstName === action.reference.firstName
        && x.lastName === action.reference.lastName
        && x.email === action.reference.email);
      if (index >= 0) {
        prospectiveCandidate.references.splice(index, 1);
      }
    }
    const newState = { ...state, ...{ prospectiveCandidate } };
    return newState;
  }),
  on(createProspectiveCandidate, (state, action) => {
    return {
      ...state,
      creatingProspectiveCandidate: true
    };
  }),
  on(createProspectiveCandidateSuccess, (state, action) => {
    return {
      ...state,
      prospectiveCandidate: action.prospectiveCandidate,
      creatingProspectiveCandidate: false
    };
  }),
  on(createOfferLetterFail, (state, action) => {
    return {
      ...state,
      creatingProspectiveCandidate: false
    };
  }),

  on(getProspectiveCandidates, (state, action) => {
    return {
      ...state,
      loadingProspectiveCandidates: true
    };
  }),
  on(getProspectiveCandidatesSuccess, (state, action) => {
    return {
      ...state,
      prospectiveCandidates: action.prospectiveCandidates,
      loadingProspectiveCandidates: false
    };
  }),
  on(getProspectiveCandidatesFail, (state, action) => {
    return {
      ...state,
      loadingProspectiveCandidates: false
    };
  }),
  on(saveProspectiveCandidateMiscDocSuccess, (state, action) => {
    return {
      ...state,
      prospectiveCandidate: action.prospectiveCandidate,
      loadingProspectiveCandidates: false
    };
  }),
  on(deleteProspectiveCandidateMiscDocSuccess, (state, action) => {
    return {
      ...state,
      prospectiveCandidate: action.prospectiveCandidate,
      loadingProspectiveCandidates: false
    };
  }),
  on(getProspectiveCandidate, (state, action) => {
    return {
      ...state,
      loadingProspectiveCandidate: true
    };
  }),
  on(getProspectiveCandidateSuccess, (state, action) => {
    return {
      ...state,
      prospectiveCandidate: action.prospectiveCandidate,
      loadingProspectiveCandidate: false
    };
  }),
  on(getProspectiveCandidateFail, (state, action) => {
    return {
      ...state,
      loadingProspectiveCandidate: false
    };
  }),

  on(saveProspectiveCandidate, (state, action) => {
    return {
      ...state,
      creatingProspectiveCandidate: true
    };
  }),
  on(saveProspectiveCandidateSuccess, (state, action) => {
    return {
      ...state,
      prospectiveCandidate: action.prospectiveCandidate,
      creatingProspectiveCandidate: false
    };
  }),
  on(saveProspectiveCandidateFail, (state, action) => {
    return {
      ...state,
      prospectiveCandidate: null,
      creatingProspectiveCandidate: false
    };
  }),

  on(deleteProspectiveCandidate, (state, action) => {
    return {
      ...state,
      deletingProspectiveCandidate: true
    };
  }),
  on(deleteProspectiveCandidateSuccess, (state, action) => {
    return {
      ...state,
      prospectiveCandidate: action.prospectiveCandidate,
      deletingProspectiveCandidate: false
    };
  }),
  on(deleteProspectiveCandidateFail, (state, action) => {
    return {
      ...state,
      deletingProspectiveCandidate: false
    };
  }),
  on(clearProspectiveCandidate, (state, action) => {
    return {
      ...state,
      prospectiveCandidate: null
    };
  }),

  on(getProspectiveCandidatesListSuccess, (state, action) => {
    return {
      ...state,
      prospectiveCandidatesList: action.prospectiveCandidatesList,
    };
  }),


  on(loadPropsectiveCandidateReference, (state, action) => {
    return {
      ...state,
      loadingProspectiveCandidateReference: true
    };
  }),
  on(loadPropsectiveCandidateReferenceSuccess, (state, action) => {
    return {
      ...state,
      prospectiveCandidateReference: action.prospectiveCandidateReference,
      loadingProspectiveCandidateReference: false
    };
  }),
  on(loadPropsectiveCandidateReferenceFail, (state, action) => {
    return {
      ...state,
      prospectiveCandidateReference: null,
      loadingProspectiveCandidateReference: false
    };
  }),

  on(savePropsectiveCandidateReference, (state, action) => {
    return {
      ...state,
      savingProspectiveCandidateReference: true
    };
  }),
  on(savePropsectiveCandidateReferenceSuccess, (state, action) => {
    return {
      ...state,
      prospectiveCandidateReference: action.prospectiveCandidateReference,
      savingProspectiveCandidateReference: false
    };
  }),
  on(savePropsectiveCandidateReferenceFail, (state, action) => {
    return {
      ...state,
      savingProspectiveCandidateReference: false
    };
  }),
  on(updateProspectiveCandidateSurveyForm, (state, action) => {
    const prospectiveCandidateReference = { ...state.prospectiveCandidateReference, ...action.prospectiveCandidateReference };
    const newState = { ...state, ...{ prospectiveCandidateReference, formErrors: action.formErrors } };
    return newState;
  }),

);

export function prospectiveCandidateReducerFunc(state: ProspectiveCandidatesState | undefined, action: Action) {
  return prospectiveCandidateReducer(state, action);
}

export function initProspectiveCandidateForm(prospectiveCandidate: ProspectiveCandidateModel) {
  const form = new FormGroup({
    firstName: new FormControl(prospectiveCandidate.firstName, [Validators.required, Validators.maxLength(50)]),
    lastName: new FormControl(prospectiveCandidate.lastName, [Validators.required, Validators.maxLength(50)]),
    phoneNumber: new FormControl(prospectiveCandidate.phoneNumber, [Validators.required, Validators.maxLength(50)]),
    email: new FormControl(prospectiveCandidate.email, [Validators.required, Validators.email, Validators.maxLength(255)]),
    position: new FormControl(prospectiveCandidate.position, [Validators.required, Validators.maxLength(255)]),
    programID: new FormControl(prospectiveCandidate.programID, [Validators.required]),
    applicationDate: new FormControl(typeof prospectiveCandidate.applicationDate !== 'undefined' && prospectiveCandidate.applicationDate !== null ? formatDate(prospectiveCandidate.applicationDate, 'yyyy-MM-dd', 'en-US') : ''),
    platform: new FormControl(prospectiveCandidate.platform),
    employeeReferral: new FormControl(prospectiveCandidate.employeeReferral),
    referredBy: new FormControl(prospectiveCandidate.referredBy),
    interviewDate: new FormControl(typeof prospectiveCandidate.interviewDate !== 'undefined' && prospectiveCandidate.interviewDate != null ? moment(prospectiveCandidate.interviewDate).toDate() : ''),
    expectedHours: new FormControl(prospectiveCandidate.expectedHours),
    futureContact: new FormControl(prospectiveCandidate.futureContact),
    noContactReason: new FormControl(prospectiveCandidate.noContactReason),
    isHired: new FormControl(prospectiveCandidate.isHired),
    notHiredExplanation: new FormControl(prospectiveCandidate.notHiredExplanation),
    notes: new FormControl(prospectiveCandidate.notes, [Validators.required]),
  });

  return form;
}

export function initProspectiveCandidateSurveyForm(prospectiveCandidateReference: ProspectiveCandidateReferenceModel) {
  const form = new FormGroup({
    dutiesPerformed: new FormControl(prospectiveCandidateReference.dutiesPerformed, [Validators.required, Validators.maxLength(2000)]),
    isFormerEmployer: new FormControl(prospectiveCandidateReference.isFormerEmployer, [Validators.required]),
    rate: new FormControl(prospectiveCandidateReference.rate, [Validators.required, Validators.maxLength(2000)]),
    wasSupervisor: new FormControl(prospectiveCandidateReference.wasSupervisor, [Validators.required]),
    whyNotRehire: new FormControl(prospectiveCandidateReference.whyNotRehire, [Validators.maxLength(2000)]),
    whyShouldHire: new FormControl(prospectiveCandidateReference.whyShouldHire, [Validators.required, Validators.maxLength(2000)]),
    wouldRehire: new FormControl(prospectiveCandidateReference.wouldRehire, []),
  }, ValidatorRequiredNotEmptyIfFalse('whyNotRehire', 'wouldRehire'));

  return form;
}


