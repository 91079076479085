<div class="card mt-2">
  <div class="card-body">
    <div class="float-right">
      <button type="button" routerLink="/intern/list-manage" class="btn btn-secondary mr-2">Applications</button>
    </div>
    <h2 class="mb-3">Review Intern Application <span *ngIf="applicationId"># {{applicationId}}</span> <i [hidden]="!loadingApplication && !savingApplication" class="material-icons rotate text-muted ml-2">loop</i></h2>

    <div class="mb-3" [hidden]="loadingApplication">
      <h4>Application Information</h4>
      <div class="row">
        <div class="col-12 col-sm-6">
          <div class="row mb-1">
            <div class="col-4 d-flex align-items-center">
              <b>Employee</b>
            </div>
            <div class="col-8">
              {{internApplication.firstName}} {{internApplication.lastName}}
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-4 d-flex align-items-center">
              <b>Form</b>
            </div>
            <div class="col-8">
              {{internApplication.formName}}              
              <button [hidden]="!internApplication.documentToSignId" class="btn btn-link" (click)="downloadInternApplication()" title="Download Application PDF">
                <i class="fas fa-file-download"></i>
              </button>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-4 d-flex align-items-center">
              <b>Primary Status</b>
            </div>
            <div class="col-8">
              <i *ngIf="!primaryStatuses" class="material-icons rotate text-muted">loop</i>
              <p-dropdown styleClass="form-control w-100" [options]="primaryStatuses" [(ngModel)]="internApplication.statusId" [ngModelOptions]="{standalone: true}" (onChange)="getSubstatuses()"></p-dropdown>
            </div>
          </div>

          <div class="row mb-2" [hidden]="!getSubstatusShow()">
            <div class="col-4 d-flex align-items-center">
              <b>Substatus</b>
            </div>
            <div class="col-8">
              <i *ngIf="!allSubstatuses" class="material-icons rotate text-muted">loop</i>
              <p-dropdown styleClass="form-control w-100 mb-2 mt-2" [options]="availableSubstatuses" [(ngModel)]="internApplication.substatusId" [ngModelOptions]="{standalone: true}"></p-dropdown>             
            </div>
          </div>

          <div class="row mb-1">
            <div class="col-4 d-flex align-items-center">
              <b>Hire Date</b>
            </div>
            <div class="col-8">
              {{(internApplication.hireDate !== null) ? (internApplication.hireDate | date: 'MM/dd/yyyy') : ""}}
            </div>
          </div>

          <div class="row mb-1">
            <div class="col-4 d-flex align-items-center">
              <b>Credentials</b>
            </div>
            <div class="col-8">
              {{(!internApplication.credentials) ? "" : internApplication.credentials.join(", ")}}
            </div>
          </div>

          <div class="row mb-1">
            <div class="col-4 d-flex align-items-center">
              <b>90 Day Avg Hours</b> <i class="fas fa-sync ml-2" style="cursor: pointer;" (click)="updateCalculations()" title="Update calcuations for hours and productivity"></i>
            </div>
            <div class="col-8">
              {{internApplication.averageHoursNinetyDays}}
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-4 d-flex align-items-center">
              <b>90 Day Avg Productivity</b>
            </div>
            <div class="col-8">
              {{internApplication.timeCardProductivityNinetyDays}}
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-4 d-flex align-items-center">
              <b>90 Day Avg Contract Productivity</b>
            </div>
            <div class="col-8">
              {{internApplication.contractProductivityNinetyDays}}
            </div>
          </div>


          <div class="row mb-1">
            <div class="col-4 d-flex align-items-center">
              <b>6 Month Avg Hours</b>
            </div>
            <div class="col-8">
              {{internApplication.averageHoursSixMonths}}
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-4 d-flex align-items-center">
              <b>6 Month Avg Productivity</b>
            </div>
            <div class="col-8">
              {{internApplication.timeCardProductivitySixMonths}}
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-4 d-flex align-items-center">
              <b>6 Month Avg Contract Productivity</b>
            </div>
            <div class="col-8">
              {{internApplication.contractProductivitySixMonths}}
            </div>
          </div>
          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Comments</b>
            </div>
            <div class="col-8">
              <textarea style="width: 100%;" class="form-control mt-2" [(ngModel)]="internApplication.reviewComments" [ngModelOptions]="{standalone: true}"></textarea>
            </div>
          </div>

        </div>

        <div class="col-12 col-sm-6">
          <div class="row mb-1">
            <div class="col-4 d-flex align-items-center">
              <b>Created on</b>
            </div>
            <div class="col-8">
              {{(internApplication.createdDate !== null) ? (internApplication.createdDate | date: 'MM/dd/yyyy @ hh:mm:ss a') : ""}}
            </div>
          </div>

          <div class="row mb-1">
            <div class="col-4 d-flex align-items-center">
              <b>Submitted on</b>
            </div>
            <div class="col-8">
              {{(internApplication.submittedDate !== null) ? (internApplication.submittedDate | date: 'MM/dd/yyyy @ hh:mm:ss a') : ""}}
            </div>
          </div>

          <div class="row mb-1">
            <div class="col-4 d-flex align-items-center">
              <b>Last Modified by Applicant</b>
            </div>
            <div class="col-8">
              {{(internApplication.applicantLastModified !== null) ? (internApplication.applicantLastModified | date: 'MM/dd/yyyy @ hh:mm:ss a') : ""}}
            </div>
          </div>

          <div class="row mb-1">
            <div class="col-4 d-flex align-items-center">
              <b>Last Modified by Reviewer</b>
            </div>
            <div class="col-8">
              {{(internApplication.reviewerLastModified !== null) ? (internApplication.reviewerLastModified | date: 'MM/dd/yyyy @ hh:mm:ss a') : ""}}
            </div>
          </div>

          <div class="row mb-1">
            <div class="col-4 d-flex align-items-center">
              <b>Decision Date</b>
            </div>
            <div class="col-8">
              {{(internApplication.decisionDate !== null) ? (internApplication.decisionDate | date: 'MM/dd/yyyy @ hh:mm:ss a') : ""}}
            </div>
          </div>

          <div class="row mt-2 mb-2">
            <div class="col-4 d-flex align-items-center">
              <b>Reviewer Forms</b> <i class="fas fa-plus-circle pointer ml-2 text-success" (click)="newReviewerFormAssignment()" title="Add Reviewer Form"></i>
            </div>
            <div class="col-8">
              <div *ngFor="let f of internApplication.reviewForms">
                <i *ngIf="!f.dateSigned" class="fas fa-trash pointer" title="Delete Form Assignment" (click)="deleteReviewForm(f)"></i>
                <i *ngIf="f.dateSigned" class="fa fa-check-circle text-success" title="{{f.dateSigned | date: 'MM/dd/yyyy @ hh:mm:ss a'}}"></i>
                <div style="display: inline;" class="btn-link pointer" (click)="editFormAssignment(f)" title="View Form">
                  {{f.formName}} - {{f.employeeName}}
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-4 d-flex align-items-center">
              <b>Uploads</b> <i class="fas fa-pencil pointer ml-2" (click)="editApplicationUploads()" title="Edit Uploads"></i>
            </div>
            <div class="col-8">
              <div *ngFor="let u of internApplication.uploads" class="btn-link pointer" (click)="downloadUploadedFile(u)" title="Download">
                {{u.fileName}}
              </div>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-4 d-flex align-items-center">
              <b>Actions</b> <i class="fas fa-plus-circle pointer ml-2 text-success" (click)="editActionItem()" title="Add Action Item"></i>
            </div>
            <div class="col-8">
              <div *ngFor="let a of internApplication.actions">
                <i *ngIf="!a.dateCompleted" class="fas fa-pencil" style="cursor: pointer;" (click)="editActionItem(a)" title="Edit Action Item"></i>
                <i *ngIf="a.dateCompleted" class="fa fa-check-circle text-success" style="cursor: pointer;" (click)="editActionItem(a)" title="Edit Action Item"></i>
                <i *ngIf="a.isAssignedToApplicant" class="far fa-user" title="To be completed by applicant"></i>
                {{a.action}} {{(a.dateCompleted !== null) ? (a.dateCompleted | date: 'MM/dd/yyyy @ hh:mm:ss a') : ""}}
                <i [hidden]="a.showResponse || !a.response" class="fas fa-chevron-down pointer" (click)="toggleShowResponse(a)" title="View Response"></i>
                <i [hidden]="!a.showResponse" class="fas fa-chevron-up pointer" (click)="toggleShowResponse(a)" title="Hide Response"></i>
                <div style="font-size: 0.9rem;" class="ml-4 mb-2" [hidden]="!a.showResponse">
                  {{a.response}}
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-2" [hidden]="!internApplication.discUploadId">
            <div class="col-4 d-flex align-items-center">
              <b>DISC</b>
            </div>
            <div class="col-8">
              <div class="btn-link pointer" (click)="downloadDisc()" title="Download">
                DISC - {{internApplication.firstName}} {{internApplication.lastName}}.pdf
              </div>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-4 d-flex align-items-center">
              <b>Decision Reason</b>
              <i class="fal fa-info-circle ml-2" style="color: #007bff;" title="The Decision Reason will populate to the Notice Of Decision sent to the applicant."></i>
            </div>
            <div class="col-8">
              <textarea style="width: 100%;" class="form-control mt-2" [(ngModel)]="internApplication.decisionReason" [ngModelOptions]="{standalone: true}"></textarea>
            </div>
          </div>

          <div class="row mb-2" [hidden]="!isApprovedOrDenied">
            <div class="col-4 d-flex align-items-center">
              <b>Decision Forms</b> <i class="fas fa-plus-circle pointer ml-2 text-success" (click)="newDecisionFormAssignment()" title="Add Reviewer Form"></i>
            </div>
            <div class="col-8">
              <div *ngFor="let f of internApplication.decisionForms">
                <i *ngIf="!f.dateSigned" class="fas fa-trash pointer" title="Delete Form Assignment" (click)="deleteReviewForm(f)"></i>
                <i *ngIf="f.dateSigned" class="fa fa-check-circle text-success" title="{{f.dateSigned | date: 'MM/dd/yyyy @ hh:mm:ss a'}}"></i>
                <div style="display: inline;" class="btn-link pointer" (click)="editFormAssignment(f)" title="View Form">
                  {{f.formName}} - {{f.employeeName}}
                </div>
              </div>
            </div>
          </div>
        </div>        
      </div>
    </div>
    <div class="mb-1" [hidden]="!showAssignForm">
      <button class="btn btn-secondary" type="button" (click)="toggleFormAssignmentView()">Cancel Form Assignment</button>
    </div>
    <app-form-assignment-create [hidden]="!showAssignForm" [internApplicationId]="applicationId" [isInternDecisionForm]="isInternDecisionForm"></app-form-assignment-create>

    <div class="float-right" [hidden]="loadingApplication">
      <i class="material-icons rotate text-muted ml-2" *ngIf="disableButtons">loop</i>
      <span *ngIf="originalStatusId == 1" style="color: red; font-style: italic; font-weight: bold;">The application has not been submitted.</span>
      <button class="btn btn-success ml-2" type="submit" (click)="createInternship()" [disabled]="disableButtons" *ngIf="showCreateButton()">Create Internship</button>
      <button class="btn btn-primary ml-2" type="submit" (click)="saveReview()" [disabled]="disableButtons || internApplication.statusId == 5">Save Review</button>
    </div>

    <div class="mb-1 mt-1">
      <button *ngIf="formSubmissionID" class="btn btn-secondary" type="button" (click)="toggleFormView()">{{formViewButtonText}}</button>
    </div>
    <app-form-fill *ngIf="formSubmissionID" [readOnly]="true" [formID]="formID" [formSubmissionID]="formSubmissionID" [hidden]="!showForm"></app-form-fill>

  </div>
</div>

<app-edit-intern-action [isEmployeeView]="false"></app-edit-intern-action>
<app-upload-intern-doc></app-upload-intern-doc>

