import { saveFBFormSuccess, getFormTypesList, getFormFieldTypesList, updateFBFormField, removeFBFormField, updateFBFormForm, getFBFormSubmission, updateFBFormSubmissionForm, saveFBFormSubmissionForm, updateFBFormSubmission, saveFBFormSubmissionFormSuccess, finishFBFormSubmissionForm, finishFBFormSubmissionFormSuccess, clearFormFill } from './../../../../core/store/form-builder/form-builder.actions';
import { FBFormFieldModel, FBFormModel, FBFormSubmissionModel, FBFormFieldValueModel, FBFormLanguageModel, FBFormFieldGroupModel } from './../../../../models/formBuilderModels';
import { filter, tap } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { EmailValidator, FormControl, FormGroup, Validators } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { markFormGroupTouched, getAllControlsErrors, updateFormGroupValidity, deepClone, formatDateString } from './../../../../../app/helpers/utils';
import { BaseComponent } from './../../../../core/abstracts/baseComponent';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { AlertService } from 'src/app/shared/services/alert.service';
import { clearForm, getFBForm, saveFBForm } from 'src/app/core/store/form-builder/form-builder.actions';
import { fillFormBuilder, initFormBuilderForm, initFormBuilderSubmissionForm, parseField } from 'src/app/core/store/form-builder/form-builder.reducer';
import { OverlayPanel } from 'primeng/overlaypanel';
import * as Editor from 'src/assets/ckeditor/build/ckeditor.js';
import PageBreak from 'src/assets/ckeditor/build/ckeditor.js';
import Mention from 'src/assets/ckeditor/build/ckeditor.js';
import Base64UploadAdapter from 'src/assets/ckeditor/build/ckeditor.js';

import { environment } from 'src/environments/environment';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import { MultiSelect } from 'primeng/multiselect';
import { FormBuilderService } from '../../../../core/services/formBuilder.service';

@Component({
  selector: 'app-form-fill',
  templateUrl: './form-fill.component.html',
  styleUrls: ['./form-fill.component.scss']
})
export class FormFillComponent extends BaseComponent implements OnInit {

  @Input() formSubmissionID: number;
  @Input() formID: number;
  @Input() jobApplicationID: number;
  @Input() prospectiveCandidateID: number;
  @Input() readOnly: boolean;
  @Input() hideSaveButon: boolean;
  @Input() hideTitle: boolean;
  @Input() forceFullWidth: boolean;
  @Input() doNotNavigate: boolean;
  @Input() hideFinishAndSign: boolean;



  fbForm: FBFormModel;
  formSubmission: FBFormSubmissionModel;
  form: FormGroup;
  formFieldTypeOptions = [];
  formTypeOptions = [];
  savingForm = false;
  loadingForm = false;
  @Input() backLink: string;
  @Input() editingAsHR: boolean;
  @Input() isPrefillingForm: boolean = false;

  isUsedAsComponent;

  yesNoOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  formLanguageOptions = [];
  selectedFormLanguage: FBFormLanguageModel;

  constructor(
    private actions$: Actions,
    private alertService: AlertService,
    private formBuilderService: FormBuilderService,
    private store: Store<State>,
    private route: ActivatedRoute,
    private router: Router) {
    super();
  }

  ngOnInit() {
    this.store.dispatch(clearFormFill());
    this.route.queryParams.subscribe(queryParams => {
      if (queryParams.backLink) {
        if (!this.backLink) {
          this.backLink = queryParams.backLink;
        }
      }
      if (queryParams.editingAsHR) {
        this.editingAsHR = queryParams.editingAsHR === 'true';
      }
    });

    if (this.formID) {
      this.isUsedAsComponent = true;
      this.store.dispatch(getFBFormSubmission({ formID: this.formID, formSubmissionID: this.formSubmissionID, jobApplicationID: this.jobApplicationID, prospectiveCandidateID: this.prospectiveCandidateID }));
    }
    this.route.params.subscribe(params => {
      if (params.formID) {
        this.formSubmissionID = params.formSubmissionID;
        this.store.dispatch(getFBFormSubmission({ formID: params.formID, formSubmissionID: params.formSubmissionID, jobApplicationID: this.jobApplicationID, prospectiveCandidateID: this.prospectiveCandidateID }));
      }
    });


    this.subs.push(
      this.store.select(s => s.formBuilderState.form).subscribe(fbForm => {
        if (fbForm) {
          let loadingFBFormFirstTime = false;
          if (!this.fbForm) {
            loadingFBFormFirstTime = true;
          }
          this.fbForm = deepClone(fbForm);

          this.loadLanguages();
          this.loadFields();

          if (loadingFBFormFirstTime && (!this.fbForm.formFields || this.fbForm.formFields.length === 0)) {
            this.finish();
          }

          this.store.dispatch(updateFBFormSubmission({
            formSubmission: {
              formID: this.fbForm.id,
              fbFormFieldValues: [],
              fbForm: this.fbForm,
            }
          }));
        }
      }),
      this.store.select(s => s.formBuilderState.formSubmission).subscribe(formSubmission => {
        if (formSubmission) {
          this.formSubmission = deepClone(formSubmission);
          this.formSubmissionID = formSubmission.id;
          if (!this.fbForm) {
            this.fbForm = this.formSubmission.fbForm;

            this.loadLanguages();
            this.loadFields();

            if (this.fbForm.formFields && this.fbForm.formFields.length === 0) {
              if (!this.form) {
                this.initForm();
              }
              this.finish();
            }
          }
          if (!this.form) {
            this.initForm();
          }
        }
      }),
      this.store.select(s => s.formBuilderState.formTypes).subscribe(formTypes => {
        if (formTypes) {
          this.formTypeOptions = formTypes.map(x => ({ label: x.formType, value: x.id }));
        }
      }),
      this.store.select(s => s.formBuilderState.formFieldTypes).subscribe(formFieldTypes => {
        if (formFieldTypes) {
          this.formFieldTypeOptions = formFieldTypes.map(x => ({ label: x.formFieldType, value: x.id }));
        }
      }),
      this.store.select(s => s.formBuilderState.loadingForm).subscribe(loadingForm => {
        this.loadingForm = loadingForm;
      }),
      this.store.select(s => s.formBuilderState.savingFormSubmission).subscribe(savingFormSubmission => {
        this.savingForm = savingFormSubmission;
      }),

      this.actions$.pipe(
        ofType(saveFBFormSubmissionFormSuccess),
        tap(action => {
          if (!this.isUsedAsComponent && !this.doNotNavigate) {
            this.alertService.success('Form saved!');
            this.router.navigate([this.getBackLink()], { relativeTo: this.route });            
          }
        })
      ).subscribe(),

      this.actions$.pipe(
        ofType(finishFBFormSubmissionFormSuccess),
        tap(action => {
          if (!this.doNotNavigate) {
            this.alertService.success('Form Finished!');
            let HRQueryParams = {};
            if (this.editingAsHR) {
              HRQueryParams = { signingAsHR: true };
            }
            if (this.route.snapshot.params.formSubmissionID) {
              this.router.navigate(['../../../sign/' + action.formSubmission.id], { relativeTo: this.route, queryParams: HRQueryParams });
            } else {
              this.router.navigate(['../../sign/' + action.formSubmission.id], { relativeTo: this.route, queryParams: HRQueryParams });
            }
          }

        })
      ).subscribe()
    );

    this.store.dispatch(getFormTypesList());
    this.store.dispatch(getFormFieldTypesList());
  }

  private loadFields() {
    if (this.fbForm.formFields) {
      if (this.editingAsHR) {
        this.fbForm.formFields = this.fbForm.formFields.filter(x => x.isHRField);
      } else {
        this.fbForm.formFields = this.fbForm.formFields.filter(x => !x.isHRField);
      }
      this.fbForm.formFields = this.fbForm.formFields.filter(x => !x.inactive);
      this.fbForm.formFields = this.fbForm.formFields.sort((a, b) => a.fieldOrder > b.fieldOrder ? 1 : -1);
      this.fbForm.formFields.forEach(f => {
        parseField(f, this.fbForm, this.selectedFormLanguage);
      });
    }
  }

  loadLanguages() {
    if (this.fbForm.formLanguages && this.fbForm.formLanguages.length > 0) {
      this.formLanguageOptions = this.fbForm.formLanguages.map(x => ({ label: x.languageName, value: x }));
      this.formLanguageOptions.unshift({ label: 'English', value: { languageID: null, languageCode: 'us', languageName: 'English' } });
      if (this.formSubmission.languageID) {
        this.selectedFormLanguage = this.formLanguageOptions.find(x => x.value.languageID === this.formSubmission.languageID).value;
      } else {
        this.selectedFormLanguage = this.formLanguageOptions[0].value;
      }
    } else {
      this.formLanguageOptions = [];
    }
  }

  getBackLink() {
    if (this.route.snapshot.params.formSubmissionID) {
      return this.backLink ? this.backLink : '../../../list';
    } else {
      return this.backLink ? this.backLink : '../../list';
    }

  }
  initForm() {
    this.form = initFormBuilderSubmissionForm(this.fbForm);
    this.subs.push(
      this.form.valueChanges.subscribe(formValues => {
        const formErrors = getAllControlsErrors(this.form);
        this.store.dispatch(updateFBFormSubmissionForm({ formValues, formErrors }));
      }));
    fillFormBuilder(this.form, this.fbForm, this.formSubmission, this.selectedFormLanguage);

  }

  getFieldTypeLabel(field: FBFormFieldModel) {
    const formFieldTypeOption = this.formFieldTypeOptions.find(x => x.value === field.fieldTypeID);
    if (formFieldTypeOption) {
      return formFieldTypeOption.label;
    }
  }

  public save() {
    if (!this.readOnly) {
      this.store.dispatch(saveFBFormSubmissionForm({ formSubmission: this.getSubmissionToSave(this.formSubmission, this.fbForm) }));
    }
  }

  getFieldValuesToSave(fbValue: FBFormFieldModel): FBFormFieldValueModel[] {
    let result: FBFormFieldValueModel[] = [];

    var value = this.getFieldValue(fbValue);

    if (this.containsOther(fbValue)) {
      if (fbValue && fbValue.formFieldType.formFieldType === 'Many Options') {
        value = this.getFieldValue(fbValue) + "<;>" + this.form.get(fbValue.formControlIdentifier + "Other").value;
      } else if (this.form.get(fbValue.formControlIdentifier + "Other")) {
        value = this.form.get(fbValue.formControlIdentifier + "Other").value;
      }
    }

    result.push({
      formFieldID: fbValue.id,
      entryNumber: fbValue.entryNumber,
      readOnly: fbValue.readOnly,
      value: value
    });
    if (fbValue.sectionFields && fbValue.sectionFields.length > 0) {
      for (var i = 0; i < fbValue.sectionFields.length; i++) {
        this.getFieldValuesToSave(fbValue.sectionFields[i]).forEach(fv =>
          result.push(fv)
        );
      }
    }

    return result;
  }

  getSubmissionToSave(formSubmission: FBFormSubmissionModel, fbForm: FBFormModel) {
    const submissionToSave: FBFormSubmissionModel = deepClone(this.formSubmission);
    submissionToSave.fbFormFieldValues = [];
    for (const fbValue of fbForm.formFields) {//.filter(f => this.form.get(f.formControlIdentifier))) {
      this.addSubmissionValues(fbValue, submissionToSave);


    }
    return submissionToSave;
  }
  addSubmissionValues(fbValue: FBFormFieldModel, submissionToSave: FBFormSubmissionModel) {
    if (fbValue.formFieldType.formFieldType == "Section") {
      if (fbValue.sections) {
        for (const section of fbValue.sections) {
          for (const sectionFBValue of section.formFields) {
            this.addSubmissionValues(sectionFBValue, submissionToSave);
          }
        }
      }
    }
    else if (this.form.get(fbValue.formControlIdentifier)) {
      this.getFieldValuesToSave(fbValue).forEach(fv =>
        submissionToSave.fbFormFieldValues.push(fv)
      );
    }
  }

  isFormValid() {
    if (this.form) {
      return this.form.valid;
    } else {
      return false;
    }
  }

  forceValidation() {
    updateFormGroupValidity(this.form);
    markFormGroupTouched(this.form);
  }

  finish() {
    if (!this.readOnly) {
      updateFormGroupValidity(this.form);
      if (this.form.valid) {
        this.store.dispatch(finishFBFormSubmissionForm({ formSubmission: this.getSubmissionToSave(this.formSubmission, this.fbForm) }));
      } else {
        markFormGroupTouched(this.form);
      }
    }
  }

  containsOther(field: FBFormFieldModel) {
    if (this.formSubmission && this.formSubmission.fbFormFieldValues) {
      const fieldValue = this.formSubmission.fbFormFieldValues.find(x => x.formFieldID.toString() === field.id.toString());
      if (field.formFieldType.formFieldType === 'One Option') {
        return fieldValue && fieldValue.value
          && (fieldValue.value === 'Other' || !field.fieldOptions.split('\n').some(x => x === fieldValue.value));
      } else if (field.formFieldType.formFieldType === 'Many Options') {
        return fieldValue && fieldValue.value && fieldValue.value.indexOf('Other') >= 0;
      }
    }
  }


  getFieldValue(field: FBFormFieldModel) {
    if (this.formSubmission && this.formSubmission.fbFormFieldValues) {
      const fieldValue = this.formSubmission.fbFormFieldValues.find(x => x.formFieldID.toString() === field.id.toString());
      if (fieldValue) {
        if (fieldValue.formField && fieldValue.formField.formFieldType && fieldValue.formField.formFieldType.formFieldType == "Many Options" && fieldValue.value) {
          let options;
          if (field.optionsTable) {
            options = field.fieldOptions.split('\n')
              .map(x => ({ label: (x.split('|')[1] ? x.split('|')[1] : x.split('|')[0]), value: x.split('|')[0] }));
          } else {
            options = field.fieldOptions.split('\n').map(x => ({ label: x, value: x }));
          }
          var result = [];
          var valuesList = fieldValue.value.split(",");
          options.forEach(fv => {
            if (valuesList.indexOf(fv.value) >= 0)
              result.push(fv.value);
          });
          return result.join("<;>");
        }
        return fieldValue.value;
      }
    }
  }

  getFieldFileName(field: FBFormFieldModel) {
    if (this.formSubmission && this.formSubmission.fbFormFieldValues) {
      const fieldValue = this.formSubmission.fbFormFieldValues.find(x => x.formFieldID.toString() === field.formControlIdentifier);
      if (fieldValue) {
        return fieldValue.fileName;
      }
    }
  }

  getFieldFileUrl(field: FBFormFieldModel) {
    if (this.formSubmission && this.formSubmission.fbFormFieldValues) {
      const fieldValue = this.formSubmission.fbFormFieldValues.find(x => x.formFieldID.toString() === field.formControlIdentifier);
      if (fieldValue) {
        return fieldValue.fileDownloadUrl;
      }
    }
  }

  openMultiSelect(multiselect: MultiSelect) {
    multiselect.show();
  }

  showField(field: FBFormFieldModel) {

    if (field.sectionFormFieldID) {
      return false;
    }
    if (!field.formControlIdentifier || !this.form.get(field.formControlIdentifier.toString()))
      return false;

    let formControl = this.form.get(field.formControlIdentifier.toString());

    if (!field.relatedFormField)
      return true;
    else {
      let showField = true;
      if (field.relatedFormFieldID) {
        if (field.showIfRelatedAnswerIs.startsWith('>')) {
          showField = Number(this.getFieldValue(field.relatedFormField)) > Number(field.showIfRelatedAnswerIs.replace('>', ''));
        } else if (field.showIfRelatedAnswerIs.startsWith('<')) {
          showField = Number(this.getFieldValue(field.relatedFormField)) < Number(field.showIfRelatedAnswerIs.replace('<', ''));
        } else {
          showField = this.getFieldValue(field.relatedFormField) === field.showIfRelatedAnswerIs;
        }
      }

      formControl.clearValidators();
      if (showField) {
        let validators = [];

        if (field.formFieldType.formFieldType === 'Email') {
          validators.push(Validators.email);
        }
        if (field.formFieldType.formFieldType === 'Phone') {
          validators.push(Validators.pattern('^[+]{0,1}[0-9]{0,4}[( ]{0,2}[0-9]{3,4}[-) ]{0,2}[0-9]{3,4}[-]{0,1}[0-9]{4,5}$'));
        }
        if (field.formFieldType.formFieldType === 'Number') {
          validators.push(Validators.pattern('^([0-9]*[.])?[0-9]+$'));
        }

        if (showField) {//make it required
          if (field.isRequired)
            validators.push(Validators.required);
        }
        formControl.setValidators(validators);
      }
      return showField;
    }
  }

  changedFile(fileInput, field: FBFormFieldModel) {
    if (fileInput.target.files.length > 0) {
      this.savingForm = true;
      var file = fileInput.target.files[0];
      this.formBuilderService.uploadValueFieldFile(this.formSubmission.id, field.id, file).subscribe(fileId => {
        this.savingForm = false;
        if (fileId > 0) {
          this.form.get(field.id.toString()).setValue(fileId.toString());
          var fieldValue = this.formSubmission.fbFormFieldValues.find(x => x.formFieldID.toString() === field.id.toString());
          if (fieldValue) {
            fieldValue.fileName = file.name;
          }
        }
      });
    }
  }

  deleteFile(field: FBFormFieldModel) {
    var fieldValue = this.formSubmission.fbFormFieldValues.find(x => x.formFieldID.toString() === field.id.toString());
    if (fieldValue) {
      fieldValue.value = null;
      fieldValue.fileName = null;
      this.form.get(field.id.toString()).setValue("");
    }
  }

  changedLanguage() {
    this.store.dispatch(updateFBFormSubmission({
            formSubmission: {
              languageID: this.selectedFormLanguage.languageID
            }
          }));
  }

  getFormName(fbForm: FBFormModel) {
    let formLanguage;
    if (this.selectedFormLanguage && this.selectedFormLanguage.languageID) {
      formLanguage = fbForm.formLanguages.find(x => x.languageID === this.selectedFormLanguage.languageID);
      if (formLanguage && formLanguage.name) {
        return formLanguage.name;
      }
    }

    return fbForm.name;
  }

  getFieldGroupName(currentFormFieldGroup: FBFormFieldGroupModel) {
    if (currentFormFieldGroup) {
      let formFieldGroupLanguage;
      if (this.selectedFormLanguage && this.selectedFormLanguage.languageID) {
        formFieldGroupLanguage = currentFormFieldGroup.formFieldGroupLanguages.find(x => x.languageID === this.selectedFormLanguage.languageID);
        if (formFieldGroupLanguage && formFieldGroupLanguage.groupName) {
          return formFieldGroupLanguage.groupName;
        }
      }

      return currentFormFieldGroup.groupName;
    }
    return '';
  }

}
