import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { createTicketType, createTicketTypeFailure, createTicketTypeSuccess, deleteTicketType, deleteTicketTypeFailure, deleteTicketTypeSuccess, loadTicketType, loadTicketTypeFailure, loadTicketTypes, loadTicketTypesFailure, loadTicketTypesSuccess, loadTicketTypeSuccess, updateTicketType, updateTicketTypeFailure, updateTicketTypeSuccess } from './ticket-types.actions';
import { TicketTypesService } from '../../services/ticket-types.service';

@Injectable()
export class TicketTypesEffects {

  loadTicketTypes$ = createEffect(() => this.actions$.pipe(
    ofType(loadTicketTypes),
    mergeMap(() => this.ticketTypesService.getAllTicketTypes().pipe(
      map(ticketTypes => loadTicketTypesSuccess({ ticketTypes })),
      catchError(error => of(loadTicketTypesFailure({ error })))
    ))
  ));

  loadTicketType$ = createEffect(() => this.actions$.pipe(
    ofType(loadTicketType),
    mergeMap(action => this.ticketTypesService.getTicketTypeById(action.id).pipe(
      map(ticketType => loadTicketTypeSuccess({ ticketType })),
      catchError(error => of(loadTicketTypeFailure({ error })))
    ))
  ));

  createTicketType$ = createEffect(() => this.actions$.pipe(
    ofType(createTicketType),
    mergeMap(action => this.ticketTypesService.createTicketType(action.ticketType).pipe(
      map(ticketType => createTicketTypeSuccess({ ticketType })),
      catchError(error => of(createTicketTypeFailure({ error })))
    ))
  ));

  updateTicketType$ = createEffect(() => this.actions$.pipe(
    ofType(updateTicketType),
    mergeMap(action => this.ticketTypesService.updateTicketType(action.id, action.ticketType).pipe(
      map(() => updateTicketTypeSuccess()),
      catchError(error => of(updateTicketTypeFailure({ error })))
    ))
  ));

  deleteTicketType$ = createEffect(() => this.actions$.pipe(
    ofType(deleteTicketType),
    mergeMap(action => this.ticketTypesService.deleteTicketType(action.id).pipe(
      map(() => deleteTicketTypeSuccess()),
      catchError(error => of(deleteTicketTypeFailure({ error })))
    ))
  ));

  constructor(
    private actions$: Actions,
    private ticketTypesService: TicketTypesService
  ) {}
}
