import { createAction, props } from '@ngrx/store';
import { ManualEntryReasonModel, TimeCardModel } from 'src/app/models/timeCardModel';
import { PayPeriodModel } from 'src/app/models/payPeriodModel';
import { ClientSimpleModel } from 'src/app/models/clientSimpleModel';
import { FormError } from 'src/app/models/utilModels';
import { PagingResultsModel } from '../../../models/pagingResultsModel';
import { SelectItem } from 'primeng/api';

export const loadOptionalPayableTypes = createAction('[TimeCard] Load Optional Payable Types');
export const loadOptionalPayableTypesSuccess = createAction('[TimeCard] Load Optional Payable Types Success',
  props<{ optionalPayableTypes: SelectItem[] }>());

export const loadClients = createAction('[TimeCard] Load Clients');
export const loadClientsSuccess = createAction('[TimeCard] Load Clients Success', props<{ clients: ClientSimpleModel[] }>());

export const loadTimeCards = createAction('[TimeCard] Load', props<{ periodID?: number }>());
export const loadTimeCardsSuccess = createAction('[TimeCard] Load Success', props<{ timeCards: TimeCardModel[] }>());

export const loadManualEntryReasons = createAction('[TimeCard] Load Manual Entry Reasons');
export const loadManualEntryReasonsSuccess = createAction('[TimeCard] Load Manual Entry Reasons Success', props<{ reasons: ManualEntryReasonModel[] }>());

export const getIsClockedIn = createAction('[TimeCard] getIsClockedIn');
export const getIsClockedInSuccess = createAction('[TimeCard] getIsClockedIn Success', props<{ isClockedIn: boolean }>());

export const clockInOut = createAction('[TimeCard] ClockInOut', props<{payableTypeId: number}>());
export const clockInOutSuccess = createAction('[TimeCard] ClockInOut Success', props<{ timeCard: TimeCardModel }>());

export const selectTimeCardToAdd = createAction('[TimeCard] Select', props<{ timeCard: TimeCardModel }>());

export const updateTimeCardForm = createAction('[TimeCard] Update Form', props<{ formValues: any; formErrors: FormError[] }>());

export const additTimeCard = createAction('[TimeCard] Add', props<{ timeCard: TimeCardModel }>());
export const additTimeCardSuccess = createAction('[TimeCard] Add Success', props<{ timeCard: TimeCardModel }>());

export const checkIP = createAction('[TimeCard] Check IP');
export const checkIPSuccess = createAction('[TimeCard] Check IP Success', props<{ valid: boolean }>());


export const fixTimeCardSave = createAction('[TimeCard] Fix', props<{ fixReason: string, timecardID: number }>());
export const fixTimeCardSaveSuccess = createAction('[TimeCard] Fix Success', props<{ timeCard: TimeCardModel }>());
export const selectTimeCardToFix = createAction('[TimeCard] Select TimeCard to Fix', props<{ timeCard: TimeCardModel }>());

export const deleteTimeCard = createAction('[TimeCard] Delete', props<{ timeCardID: number }>());
export const deleteTimeCardSuccess = createAction('[TimeCard] Delete Success');

export const getManualEntriesReport = createAction('[TimeCard] Get Manual Entries Report',
  props<{ filter: any }>());
export const getManualEntriesReportSuccess = createAction('[TimeCard] Get Manual Entries Report Success',
  props<{ list: PagingResultsModel<TimeCardModel> }>());
export const getManualEntriesReportFailure = createAction('[TimeCard] Get Manual Entries Report Failure');

export const exportManualEntriesReportToExcel = createAction('[TimeCard] Export Manual Entries Report To Excel',
  props<{ filter: any }>());
export const exportManualEntriesReportToExcelSuccess = createAction('[TimeCard] Export Manual Entries Report To Excel Success',
  props<{ doc: ArrayBuffer }>());
export const exportManualEntriesReportToExcelFailure = createAction('[TimeCard] Export Manual Entries Report To Excel Failure');
