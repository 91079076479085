import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SelectItem } from 'primeng/api';
import { environment } from '../../../environments/environment';
import { PagingResultsModel } from '../../models/pagingResultsModel';
import { CertificationInstructorModel, CertificationReportModel, CertificationTypeUploadModel, CeuEventModel } from '../../models/certificationModel';
import { deepClone } from '../../helpers/utils';

@Injectable({
  providedIn: 'root'
})
export class CertificationService {

  constructor(private http: HttpClient) {
  }

  getCertificationTypes(): Observable<SelectItem[]> {
    return this.http.get<SelectItem[]>(`${environment.apiServer.webApiURL}/Certification/CertificationTypes`);
  }

  getCeuCategories(): Observable<SelectItem[]> {
    return this.http.get<SelectItem[]>(`${environment.apiServer.webApiURL}/Certification/CeuCategories`);
  }

  getCeuTypes(): Observable<SelectItem[]> {
    return this.http.get<SelectItem[]>(`${environment.apiServer.webApiURL}/Certification/CeuTypes`);
  }

  getInstructorList(prefixText: string, certificationTypeId: number, showArchived: boolean, showTerminated: boolean, showInactive: boolean): Observable<SelectItem[]> {
    return this.http.post<SelectItem[]>(`${environment.apiServer.webApiURL}/Certification/Instructors`, {
      prefixText, certificationTypeId, showArchived: showArchived, showTerminated: showTerminated, showInactive: showInactive
    });
  }

  getCertificationList(filter: any): Observable<PagingResultsModel<CertificationReportModel>> {
    if (!filter) {
      filter = {};
      filter.filters = {};
    }
    filter = deepClone(filter);

    return this.http.post<PagingResultsModel<CertificationReportModel>>(`${environment.apiServer.webApiURL}/Certification/CertificationList`, filter);
  }

  getCertificationInstructorList(filter: any): Observable<PagingResultsModel<CertificationInstructorModel>> {
    if (!filter) {
      filter = {};
      filter.filters = {};
    }
    filter = deepClone(filter);

    return this.http.post<PagingResultsModel<CertificationInstructorModel>>(`${environment.apiServer.webApiURL}/Certification/CertificationInstructorList`, filter);
  }

  addCertification(certification: CertificationReportModel): Observable<any> {
    return this.http.post<CertificationReportModel>(`${environment.apiServer.webApiURL}/Certification`, certification);
  }

  deleteCertification(certificationId: number): Observable<any> {
    return this.http.delete<any>(`${environment.apiServer.webApiURL}/Certification/${certificationId}`);
  }

  editInstructor(instructor: CertificationInstructorModel): Observable<any> {
    return this.http.put<CertificationInstructorModel>(`${environment.apiServer.webApiURL}/Certification/Instructor/${instructor.instructorId}`, instructor);
  }

  addInstructor(instructor: CertificationInstructorModel): Observable<any> {
    return this.http.post<CertificationInstructorModel>(`${environment.apiServer.webApiURL}/Certification/Instructor`, instructor);
  }

  deleteInstructor(instructorId: number): Observable<any> {
    return this.http.delete<any>(`${environment.apiServer.webApiURL}/Certification/Instructor/${instructorId}`);
  }

  getCertificationPdf(certificationId: number): Observable<ArrayBuffer> {
    return this.http.get<ArrayBuffer>(`${environment.apiServer.webApiURL}/Certification/GetCertificationPdf/${certificationId}`,
      { responseType: 'arraybuffer' as 'json' });
  }

  getCeuEventFile(uploadId: number): Observable<ArrayBuffer> {
    return this.http.get<ArrayBuffer>(`${environment.apiServer.webApiURL}/Certification/GetCeuEventFile/${uploadId}`,
      { responseType: 'arraybuffer' as 'json' });
  }

  exportCertificationListToExcel(filter: any): Observable<ArrayBuffer> {
    return this.http.post<ArrayBuffer>(`${environment.apiServer.webApiURL}/Certification/ExportCertificationListToExcel`, filter, { responseType: 'arraybuffer' as 'json' });
  }

  exportCertificationInstructorListToExcel(filter: any): Observable<ArrayBuffer> {
    return this.http.post<ArrayBuffer>(`${environment.apiServer.webApiURL}/Certification/ExportCertificationInstructorListToExcel`, filter, { responseType: 'arraybuffer' as 'json' });
  }

  getUploadedFiles(userId: number): Observable<CertificationTypeUploadModel[]> {
    if (userId) {
      return this.http.get<CertificationTypeUploadModel[]>
        (`${environment.apiServer.webApiURL}/Certification/UploadedFiles/${userId}`);
    } else {
      return this.http.get<CertificationTypeUploadModel[]>
        (`${environment.apiServer.webApiURL}/Certification/UploadedFiles`);
    }
  }

  getCeuEventList(filter: any): Observable<PagingResultsModel<CeuEventModel>> {
    if (!filter) {
      filter = {};
      filter.filters = {};
    }
    filter = deepClone(filter);

    return this.http.post<PagingResultsModel<CeuEventModel>>(`${environment.apiServer.webApiURL}/Certification/CeuEventList`, filter);
  }

  exportCeuEventListToExcel(filter: any): Observable<ArrayBuffer> {
    return this.http.post<ArrayBuffer>(`${environment.apiServer.webApiURL}/Certification/ExportCeuEventListToExcel`, filter, { responseType: 'arraybuffer' as 'json' });
  }

  editCeuEvent(ceuEvent: CeuEventModel): Observable<any> {
    let formData = new FormData();
    Object.keys(ceuEvent).forEach(function (key) {
      let value = ceuEvent[key];
      if (Array.isArray(value)) {        
        Object.keys(value).forEach(function (valueIndex) {
          if (value[valueIndex] instanceof File) {
            formData.append(key + ".Index", valueIndex);
            formData.append(key + "[" + valueIndex + "]", value[valueIndex]);
          }
          else {
            Object.keys(value[valueIndex]).forEach(function (k) {
            let arrayValue = value[valueIndex][k];
            if (Array.isArray(arrayValue)) {
              Object.keys(arrayValue).forEach(function (arrayValueIndex) {
                Object.keys(arrayValue[arrayValueIndex]).forEach(function (kk) {
                  let arrayArrayValue = arrayValue[arrayValueIndex][kk]
                  formData.append(key + "[" + valueIndex + "]." + k + "[" + arrayValueIndex + "]." + kk, arrayArrayValue);
                });
              });
            } else {
              formData.append(key + "[" + valueIndex + "]." + k, arrayValue);                        
            }
          });
          }
        });
      } else {
        formData.append(key, ceuEvent[key]);
      }
    });

    const options = { headers: new HttpHeaders() }; 
    options.headers.append('enctype', 'multipart/form-data');

    return this.http.put<CeuEventModel>(`${environment.apiServer.webApiURL}/Certification/CeuEvent/`, formData, options);
  }

  addCeuEvent(ceuEvent: CeuEventModel): Observable<any> {
    let formData = new FormData();
    Object.keys(ceuEvent).forEach(function (key) {
      let value = ceuEvent[key];
      if (Array.isArray(value)) {
        Object.keys(value).forEach(function (valueIndex) {
          if (value[valueIndex] instanceof File) {
            formData.append(key + ".Index", valueIndex);
            formData.append(key + "[" + valueIndex + "]", value[valueIndex]);
          }
          else {
            Object.keys(value[valueIndex]).forEach(function (k) {
              let arrayValue = value[valueIndex][k];
              if (Array.isArray(arrayValue)) {
                Object.keys(arrayValue).forEach(function (arrayValueIndex) {
                  Object.keys(arrayValue[arrayValueIndex]).forEach(function (kk) {
                    let arrayArrayValue = arrayValue[arrayValueIndex][kk]
                    formData.append(key + "[" + valueIndex + "]." + k + "[" + arrayValueIndex + "]." + kk, arrayArrayValue);
                  });
                });
              } else {
                formData.append(key + "[" + valueIndex + "]." + k, arrayValue);
              }
            });
          }
        });
      } else {
        formData.append(key, ceuEvent[key]);
      }
    });

    const options = { headers: new HttpHeaders() };
    options.headers.append('enctype', 'multipart/form-data');
    return this.http.post<CeuEventModel>(`${environment.apiServer.webApiURL}/Certification/CeuEvent`, formData, options);
  }

  deleteCeuEvent(ceuEventId: number): Observable<any> {
    return this.http.delete<any>(`${environment.apiServer.webApiURL}/Certification/CeuEvent/${ceuEventId}`);
  }

  getCeuEvent(ceuEventId: number): Observable<CeuEventModel> {
    
    return this.http.get<CeuEventModel>(`${environment.apiServer.webApiURL}/Certification/CeuEvent/${ceuEventId}`);
  }

  generateMissingCeuEventCertifications(ceuEventId: number): Observable<any> {
    return this.http.post<any>(`${environment.apiServer.webApiURL}/Certification/GenerateMissingCeuCertifications/${ceuEventId}`, ceuEventId);
  }
}
