import { ProspectiveCandidateModel, ProspectiveCandidateReferenceModel } from './../../../../models/prospectiveCandidateModel';
import {
  updateProspectiveCandidateForm, saveProspectiveCandidate,
  createProspectiveCandidate, getProspectiveCandidate, clearProspectiveCandidate,
  removeProspectiveCandidateReference, addProspectiveCandidateReference
} from './../../../../core/store/prospective-candidates/prospective-candidate.actions';
import { additTimeCard, updateTimeCardForm } from './../../../../core/store/timecard/timecard.actions';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { loadClients } from 'src/app/core/store/timecard/timecard.actions';
import { TimeCardModel } from 'src/app/models/timeCardModel';
import { EmailValidator, FormControl, FormGroup, Validators } from '@angular/forms';
import { initTimeCardEditForm } from 'src/app/core/store/timecard/timecard.reducer';
import { SelectItem } from 'primeng/api';
import { markFormGroupTouched, getAllControlsErrors, updateFormGroupValidity, deepClone } from 'src/app/helpers/utils';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { initProspectiveCandidateForm } from 'src/app/core/store/prospective-candidates/prospective-candidate.reducer';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-employee-references',
  templateUrl: './employee-references.component.html',
  styleUrls: ['./employee-references.component.scss']
})
export class EmployeeReferencesComponent extends BaseComponent implements OnInit {

  prospectiveCandidate: ProspectiveCandidateModel;
  prospectiveCandidateID: number;
  references: ProspectiveCandidateReferenceModel[] = [];
  backTo;

  constructor(
    private store: Store<State>,
    private route: ActivatedRoute,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params.prospectiveCandidateID) {
        this.prospectiveCandidateID = params.prospectiveCandidateID;
        this.store.dispatch(getProspectiveCandidate({ prospectiveCandidateID: params.prospectiveCandidateID }));
      }
    });
    this.subs.push(
      this.store.select(s => s.prospectiveCandidateState.prospectiveCandidate).subscribe(prospectiveCandidate => {
        if (prospectiveCandidate) {
          this.prospectiveCandidate = deepClone(prospectiveCandidate);
          this.references = this.prospectiveCandidate.references ? this.prospectiveCandidate.references : [];
        }
      })
    );
    this.route.queryParams.subscribe(queryParams => {
      if (queryParams.backTo) {
        this.backTo = queryParams.backTo;
      }
    });

  }

  back() {
    this.router.navigate([this.backTo]);
  }

}
