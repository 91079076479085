import { loadMedClinicPatients, loadMedClinicPatientsSuccess, saveMedClinicPatientSuccess, saveMedClinicPatientFailure, saveMedClinicPatient, loadInsuranceProviders, loadInsuranceProvidersSuccess } from './medclinic.actions';
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { State } from '..';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { errorHappened } from '../profile/profile.actions';
import { MedClinicService } from '../../services/medclinic.service';


@Injectable()
export class MedClinicEffects {
  constructor(private actions$: Actions,
    private medClinicService: MedClinicService,
    private store: Store<State>) { }

  loadMedClinicPatients = createEffect(() => this.actions$.pipe(
    ofType(loadMedClinicPatients),
    switchMap(action => {
      return this.medClinicService.getPatients().pipe(
        map(patients => loadMedClinicPatientsSuccess({ patients })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));


  saveMedClinicPatient = createEffect(() => this.actions$.pipe(
    ofType(saveMedClinicPatient),
    switchMap(action => {
      return this.medClinicService.save(action.patient).pipe(
        map(template => saveMedClinicPatientSuccess()),
        catchError(err => {
          this.store.dispatch(saveMedClinicPatientFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));


  loadInsuranceProviders = createEffect(() => this.actions$.pipe(
    ofType(loadInsuranceProviders),
    switchMap(action => {
      return this.medClinicService.getInsuranceProviders().pipe(
        map(insuranceProviders => loadInsuranceProvidersSuccess({ insuranceProviders })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

}
