import { OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

export class BaseComponent implements OnDestroy {
  subs: Subscription[] = [];


  ngOnDestroy() {
    for (const sub of this.subs) {
      if (sub && !sub.closed) {
        sub.unsubscribe();
      }
    }
  }
}
