<form class="card mt-2">
  <div class="card-body">

    <div class="float-right">
      <button type="button" routerLink="/employee-review/valuecolor" class="btn btn-secondary">Manage Values</button>
      <button routerLink="/employee-review/ethics-codes" class="ml-2 btn btn-secondary">
        Manage Ethics Codes
      </button>
    </div>
    <h2 class="mb-3">Employee Review Templates</h2>

    <div class="mb-3">
      <button routerLink="/employee-review/template" class="btn btn-secondary">New Template</button>
    </div>
    <div class="row mb-1">
      <div class="col-2 d-flex align-items-center">
        <h5>Archived Templates:</h5>
      </div>
      <div class="col-10 ">
        <p-selectButton styleClass="w-100 ml-2" [options]="yesNoAllOptions" [(ngModel)]="includeArchived" (ngModelChange)="reloadList()" [ngModelOptions]="{standalone: true}"></p-selectButton>
      </div>
    </div>
    <i [hidden]="!loading" class="material-icons rotate text-muted">loop</i>
    <p-table [value]="templates?.result" [responsive]="true" [paginator]="true" [rows]="20"
             [totalRecords]="templates?.totalRecords" [lazy]="true" (onLazyLoad)="reloadTemplates($event)">
      <ng-template pTemplate="header">
        <tr>
          <th width="100px"></th>
          <th [pSortableColumn]="'description'">Description <p-sortIcon [field]="'description'"></p-sortIcon></th>
          <th>Job Title</th>
          <th [pSortableColumn]="'maximumScore'">Max Score<p-sortIcon [field]="'maximumScore'"></p-sortIcon></th>
          <th [pSortableColumn]="'periodStart'">Time Range <p-sortIcon [field]="'periodStart'"></p-sortIcon></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-template>
        <tr>
          <td>
            <span class="pointer mr-2" (click)="editTemplate(template)"><i title="Edit Template" style="font-size: 1.5rem;" class="fas fa-edit"></i></span>
            <span class="pointer mr-2" *ngIf="!template.archived" (click)="toggleArchivedStatus(template)"><i title="Archive" style="font-size: 1.5rem;" class="fas fa-trash-alt"></i></span>
            <span class="pointer mr-2" *ngIf="template.archived" (click)="toggleArchivedStatus(template)"><i title="Unarchive" style="font-size: 1.5rem;" class="fas fa-trash-undo-alt"></i></span>
          </td>
          <td>
            <span class="ui-column-title">Description</span>
            {{template.description}}
          </td>
          <td>
            <span class="ui-column-title">Job Title</span>
            <div *ngFor="let jt of template.jobTitles">
              {{jt}}
            </div>
          </td>
          <td>
            <span class="ui-column-title">Max Score</span>
            {{template.maximumScore}}
          </td>
          <td>
            <span class="ui-column-title">Time Range</span>
            {{template.periodStart | date: 'shortDate'}} - {{template.periodEnd | date: 'shortDate'}}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</form>

