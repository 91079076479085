import { CreateEmployeePatientModel, InsuranceProviderModel } from './../../../models/medClinicModels';
import { createAction, props } from '@ngrx/store';
import { FormError } from 'src/app/models/utilModels';

export const loadMedClinicPatients = createAction('[Med Clinic] Load Med Clinic');
export const loadMedClinicPatientsSuccess = createAction('[Med Clinic] Load Med Clinic Success',
  props<{ patients: CreateEmployeePatientModel[] }>());

export const saveMedClinicPatient = createAction('[Med Clinic] Save Med Clinic Patient',
  props<{ patient: CreateEmployeePatientModel }>());
export const saveMedClinicPatientSuccess = createAction('[Med Clinic] Save Med Clinic Patient Success');
export const saveMedClinicPatientFailure = createAction('[Med Clinic] Save Med Clinic Patient Failure');

export const updateCreatePatientForm = createAction('[Med Clinic] Update Med Clinic Form',
  props<{ patientValues: any; formErrors: FormError[] }>());

export const loadInsuranceProviders = createAction('[Med Clinic] loadInsuranceProviders');
export const loadInsuranceProvidersSuccess = createAction('[Med Clinic] loadInsuranceProviders Success',
  props<{ insuranceProviders: InsuranceProviderModel[] }>());
export const loadInsuranceProvidersFailure = createAction('[Med Clinic] loadInsuranceProviders Failure');

