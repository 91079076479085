import { ProspectiveCandidateModel, ProspectiveCandidateContactLogModel, ProspectiveCandidateReferenceModel } from './../../../models/prospectiveCandidateModel';
import { PagingResultsModel } from './../../../models/pagingResultsModel';
import { createAction, props } from '@ngrx/store';
import { FormError } from 'src/app/models/utilModels';


export const updateProspectiveCandidateForm = createAction('[Prospective Candidate]  Update Prospective Candidate Form',
  props<{ prospectiveCandidate: any; formErrors: FormError[] }>());

export const createProspectiveCandidate = createAction('[Prospective Candidate]  Create Prospective Candidate',
  props<{ prospectiveCandidate: ProspectiveCandidateModel, file: File }>());
export const createProspectiveCandidateSuccess = createAction('[Prospective Candidate]  Create Prospective Candidate Success',
  props<{ prospectiveCandidate: ProspectiveCandidateModel }>());
export const createProspectiveCandidateFail = createAction('[Prospective Candidate]  Create Prospective Candidate Fail',
  props<{ err: any }>());


export const saveProspectiveCandidate = createAction('[Prospective Candidate]  Save Prospective Candidate',
  props<{ prospectiveCandidate: ProspectiveCandidateModel, file: File }>());
export const saveProspectiveCandidateSuccess = createAction('[Prospective Candidate]  Save Prospective Candidate Success',
  props<{ prospectiveCandidate: ProspectiveCandidateModel }>());
export const saveProspectiveCandidateFail = createAction('[Prospective Candidate]  Save Prospective Candidate Fail',
  props<{ err: any }>());

export const saveProspectiveCandidateMiscDoc = createAction('[Prospective Candidate]  Save Prospective Candidate Misc. Doc.',
props<{ prospectiveCandidateID: number, files: File[] }>());
export const saveProspectiveCandidateMiscDocSuccess = createAction('[Prospective Candidate]  Save Prospective Candidate Misc. Doc. Success',
props<{ prospectiveCandidate: ProspectiveCandidateModel }>());

export const deleteProspectiveCandidateMiscDoc = createAction('[Prospective Candidate] Delete Prospective Candidate Misc. Doc.',
props<{ prospectiveCandidateID: number, documentID: number }>());
export const deleteProspectiveCandidateMiscDocSuccess = createAction('[Prospective Candidate] Delete Prospective Candidate Misc. Doc. Success',
props<{ prospectiveCandidate: ProspectiveCandidateModel }>());

export const getProspectiveCandidates = createAction('[Prospective Candidate] Get  list',
  props<{ filter: any }>());
export const getProspectiveCandidatesSuccess = createAction('[Prospective Candidate] Get  list Success',
  props<{ prospectiveCandidates: PagingResultsModel<ProspectiveCandidateModel> }>());
export const getProspectiveCandidatesFail = createAction('[Prospective Candidate] Get  list Fail',
  props<{ err: any }>());


export const getProspectiveCandidate = createAction('[Prospective Candidate] Get  info',
  props<{ prospectiveCandidateID: number }>());
export const getProspectiveCandidateSuccess = createAction('[Prospective Candidate] Get info Success',
  props<{ prospectiveCandidate: ProspectiveCandidateModel }>());
export const getProspectiveCandidateFail = createAction('[Prospective Candidate] Get info Fail',
  props<{ err: any }>());

export const getResume = createAction('[Prospective Candidate] Get Resume',
  props<{ prospectiveCandidateID: number }>());
export const getResumeSuccess = createAction('[Prospective Candidate] Get Resume Success',
  props<{ resumeURL: string }>());

export const deleteProspectiveCandidate = createAction('[Prospective Candidate] Delete ',
  props<{ prospectiveCandidateID: number }>());
export const deleteProspectiveCandidateSuccess = createAction('[Prospective Candidate] Delete Success',
  props<{ prospectiveCandidate: ProspectiveCandidateModel }>());
export const deleteProspectiveCandidateFail = createAction('[Prospective Candidate] Delete Fail',
  props<{ err: any }>());


export const noShowProspectiveCandidate = createAction('[Prospective Candidate] No SHow ',
  props<{ prospectiveCandidateID: number }>());
export const noShowProspectiveCandidateSuccess = createAction('[Prospective Candidate] No show Success',
  props<{ prospectiveCandidate: ProspectiveCandidateModel }>());
export const noShowProspectiveCandidateFail = createAction('[Prospective Candidate] No show Fail',
  props<{ err: any }>());


export const clearProspectiveCandidate = createAction('[Prospective Candidate] Clear');


export const getProspectiveCandidatesList = createAction('[Prospective Candidate] Get  List');
export const getProspectiveCandidatesListSuccess = createAction('[Prospective Candidate] Get List Success',
  props<{ prospectiveCandidatesList: ProspectiveCandidateModel[] }>());
export const getProspectiveCandidatesListFail = createAction('[Prospective Candidate] Get List Fail',
  props<{ err: any }>());


export const removeProspectiveCandidateReference = createAction('[Prospective Candidate] Remove reference',
  props<{ reference: ProspectiveCandidateReferenceModel }>());
export const addProspectiveCandidateReference = createAction('[Prospective Candidate] Add reference',
  props<{ reference: ProspectiveCandidateReferenceModel }>());


export const loadPropsectiveCandidateReference = createAction('[Prospective Candidate] Load reference',
  props<{ code: string }>());
export const loadPropsectiveCandidateReferenceById = createAction('[Prospective Candidate] Load reference by Id',
  props<{ propsectiveCandidateReferenceId: string }>());
export const loadPropsectiveCandidateReferenceSuccess = createAction('[Prospective Candidate] Load reference Success',
  props<{ prospectiveCandidateReference: ProspectiveCandidateReferenceModel }>());
export const loadPropsectiveCandidateReferenceFail = createAction('[Prospective Candidate] Load reference Fail',
  props<{ err: any }>());


export const savePropsectiveCandidateReference = createAction('[Prospective Candidate] Save reference answer',
  props<{ prospectiveCandidateReference: ProspectiveCandidateReferenceModel }>());
export const savePropsectiveCandidateReferenceSuccess = createAction('[Prospective Candidate] Save reference answer Success',
  props<{ prospectiveCandidateReference: ProspectiveCandidateReferenceModel }>());
export const savePropsectiveCandidateReferenceFail = createAction('[Prospective Candidate] Save reference answer Fail',
  props<{ err: any }>());

export const addProspectiveCandidateContactLog = createAction('[Prospective Candidate] Add Log',
  props<{ log: ProspectiveCandidateContactLogModel}>());
export const addProspectiveCandidateContactLogSuccess = createAction('[Prospective Candidate] Add Log Success',
  props<{ log: ProspectiveCandidateContactLogModel}>());
export const addProspectiveCandidateContactLogFail = createAction('[Prospective Candidate] Add Log Fail',
  props<{ err: any }>());

export const editProspectiveCandidateContactLog = createAction('[Prospective Candidate] Edit Log',
  props<{ log: ProspectiveCandidateContactLogModel}>());
export const editProspectiveCandidateContactLogSuccess = createAction('[Prospective Candidate] Edit Log Success',
  props<{ log: ProspectiveCandidateContactLogModel}>());

export const updateProspectiveCandidateSurveyForm = createAction('[Prospective Candidate]  Update Prospective Candidate Survey Form',
  props<{ prospectiveCandidateReference: any; formErrors: FormError[] }>());
