import { SurveyModel, SurveyFullAnswerModel, SurveyReportModel, SurveyReportFilterModel, SurveyReportDetailModel, SurveyReportDetailFilterModel } from './../../models/surveyModel';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { UserProfileModel } from '../../models/userProfileModel';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';


@Injectable()
export class SurveyService {

  constructor(private http: HttpClient) {
  }

  loadSurveyInfo(surveyID: number): Observable<SurveyModel> {
    return this.http.get<SurveyModel>(`${environment.apiServer.webApiURL}/Survey/${surveyID}`);
  }

  saveAnswer(answer: SurveyFullAnswerModel): Observable<boolean> {
    return this.http.post<boolean>(`${environment.apiServer.webApiURL}/Survey/Answer`, answer);
  }

  loadMyUnansweredSurveys(): Observable<SurveyModel[]> {
    return this.http.get<SurveyModel[]>(`${environment.apiServer.webApiURL}/Survey`);
  }

  loadSurveyReport(filter?: SurveyReportFilterModel): Observable<SurveyReportModel[]> {
    return this.http.post<SurveyReportModel[]>(`${environment.apiServer.webApiURL}/Survey/Report`, filter);
  }

  loadSurveyReportDetail(filter?: SurveyReportDetailFilterModel): Observable<SurveyReportDetailModel[]> {
    return this.http.post<SurveyReportDetailModel[]>(`${environment.apiServer.webApiURL}/Survey/Report/Detail`, filter);
  }
}
