import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, catchError, tap } from 'rxjs/operators';
import { PayPeriodModel } from 'src/app/models/payPeriodModel';


@Injectable()
export class PayPeriodService {

  constructor(private http: HttpClient) {
  }

  loadPayPeriods(loadAll: boolean): Observable<PayPeriodModel[]> {
    return this.http.get<PayPeriodModel[]>(`${environment.apiServer.webApiURL}/TimeCard/PayPeriods/?loadAll=${loadAll}`,);
  }


}
