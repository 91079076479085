import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { BaseComponent } from '../../../core/abstracts/baseComponent';
import { State } from '../../../core/store';
import { downloadStaffInjuryInvoice, loadInjuriesQueue, markInjuryAsCompleted, markInjuryAsCompletedSuccess, setStaffInjuryToUpdate, updateStaffInjuryForm, updateStaffInjurySuccess, uploadStaffInjuryInvoiceSuccess } from '../../../core/store/injuryqueue/injuryqueue.actions';
import { downloadFile, getOptoLinkFromUrl } from '../../../helpers/utils';
import { StaffInjuryModel } from '../../../models/staffInjuryModel';
import { AlertService } from '../../../shared/services/alert.service';

@Component({
  selector: 'app-injury-queue',
  templateUrl: './injury-queue.component.html',
  styleUrls: ['./injury-queue.component.scss']
})
export class InjuryQueueComponent extends BaseComponent implements OnInit {

  injuriesQueue: StaffInjuryModel[];
  filterStaffInvolved?: string;
  filterReportedBy?: string;
  filterSentBy?: string;
  filterShowCompleted?: boolean;

  filterSentAtStartDate?: Date;
  filterSentAtEndDate?: Date;
  filterOccurredStartDate?: Date;
  filterOccurredEndDate?: Date;
  loadingQueue: boolean;
  downloadingInvoiceId?: number;

  displayStaffInjury: boolean;


  constructor(private actions$: Actions,
    private alertService: AlertService,
    private store: Store<State>,
    private router: Router,
    private route: ActivatedRoute) {
    super();
  }
  ngOnInit() {
    this.loadingQueue = false;
    this.displayStaffInjury = false;
    this.downloadingInvoiceId = null;
    this.subs.push(
      this.store.select(s => s.injuryQueueState.injuriesQueue).subscribe(injuriesQueue => {
        if (injuriesQueue)
          this.injuriesQueue = injuriesQueue.result;
        else
          this.injuriesQueue = null;
      }),
      this.store.select(s => s.injuryQueueState.loadingQueue).subscribe(loadingQueue => {
        this.loadingQueue = loadingQueue;
      }),
      this.store.select(s => s.injuryQueueState.downloadingInvoice).subscribe(downloadingInvoice => {
        this.downloadingInvoiceId = downloadingInvoice;
      }),
      this.store.select(s => s.injuryQueueState.staffInjury).subscribe(staffInjury => {
        if (staffInjury)
          this.displayStaffInjury = true;
        else
          this.displayStaffInjury = false;
      }),
    );

    this.actions$.pipe(
      ofType(updateStaffInjurySuccess),
      tap(action => {
        this.alertService.success("Staff Injury updated!");
        this.reload();
      })
    ).subscribe();
    this.actions$.pipe(
      ofType(markInjuryAsCompletedSuccess),
      tap(action => {
        this.reload();
      })
    ).subscribe();
    this.actions$.pipe(
      ofType(uploadStaffInjuryInvoiceSuccess),
      tap(action => {
        this.reload();
      })
    ).subscribe();

    this.reload();

  }

  openInOpto(incidentReportId: number) {
    window.open(getOptoLinkFromUrl() + '/IncidentReports/IncidentReport/View/' + incidentReportId);
  }

  markAsResolved(staffInjuryId: number) {

    this.alertService.confirm("Was this injury resolved?", "Confirming will dismiss the injury from this queue.")
      .subscribe(
        anwser => {
          if (anwser) {
            this.store.dispatch(markInjuryAsCompleted({ staffInjuryId: staffInjuryId }));
          }
        }, error => {
        }
      );
  }

  openUpdateModal(staffInjury: StaffInjuryModel) {
    this.store.dispatch(setStaffInjuryToUpdate({ staffInjury: staffInjury }))
  }


  downloadInvoice(staffInjury: StaffInjuryModel) {
    this.store.dispatch(downloadStaffInjuryInvoice({ staffInjuryId: staffInjury.id }));
    const subDocBuffer = this.store.select(s => s.injuryQueueState.invoiceArrayBuffer).subscribe(invoiceArrayBuffer => {
      if (invoiceArrayBuffer) {
        downloadFile(new Blob([invoiceArrayBuffer], { type: staffInjury.fileType }), staffInjury.fileName);
        subDocBuffer.unsubscribe();
      }
    });
  }

  reload() {
    this.store.dispatch(loadInjuriesQueue({
      filter: {
        staffNameFilter: this.filterStaffInvolved,
        reportedByFilter: this.filterReportedBy,
        sentByFilter: this.filterSentBy,
        sentAtFilterStart: this.filterSentAtStartDate,
        sentAtFilterEnd: this.filterSentAtEndDate,
        ocurredDateFilterStart: this.filterOccurredStartDate,
        ocurredDateFilterEnd: this.filterOccurredEndDate,
        showCompletedFilter: this.filterShowCompleted
      }
    }));
  }
}
