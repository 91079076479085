<div class="card mb-2">
  <div class="card-body">
    <form class="" [formGroup]="form" *ngIf="form && timeCard">
      <h2 class="">{{timeCard.id?'Edit':'Add'}} Clock Punch</h2>
      <div class="row">
          <div class="col-12 col-lg-6">
          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Reason</b>
            </div>
            <div class="col-8 ">
                <i *ngIf="!manualEntryReasonOptions" class="material-icons rotate text-muted">loop</i>
                <p-dropdown styleClass="form-control mt-2 w-100" [options]="manualEntryReasonOptions" placeholder="Select reason..." formControlName="manualEntryReasonID"></p-dropdown>              
            </div>
            <ng-container *ngIf="form.get('manualEntryReasonID').value == 9">
              <div class="col-4 d-flex align-items-center">
                <b>Please Specify Reason</b>
              </div>
              <div class="col-8 ">
                  <input id="otherReasonInput" type="text" class="form-control mt-2" formControlName="manualEntryOtherReason"/>
                  <app-validation-message [form]="form" [path]="'manualEntryOtherReason'" [name]="'manualEntryOtherReason'" [label]="'Other Reason'"> </app-validation-message>
              </div>
            </ng-container>
            <div class="col-4 d-flex align-items-center">
              <b>Description</b>
            </div>
            <div class="col-8 ">
                <input id="descriptionInput" type="text" class="form-control mt-2" formControlName="description"/>
                <app-validation-message [form]="form" [path]="'description'" [name]="'description'" [label]="'Description'"> </app-validation-message>
            </div>
            <div class="col-4  d-flex align-items-center">
              <b>Clock In</b>
            </div>
            <div class="col-8 ">
                <p-calendar inputStyleClass="form-control mt-2" styleClass="w-100" panelStyleClass="calendarPanel" [hourFormat]="12" [showTime]="true" [selectOtherMonths]="true" id="clockIn" name="clockIn" dataType="string" formControlName="clockIn" ></p-calendar>
                <app-validation-message [form]="form" [path]="'clockIn'" [name]="'clockIn'" [label]="'Clock In'"> </app-validation-message>
            </div>
            <div class="col-4 d-flex align-items-center">
              <b>Clock Out</b>
            </div>
            <div class="col-8 ">
                <p-calendar inputStyleClass="form-control mt-2" styleClass="w-100" panelStyleClass="calendarPanel" [hourFormat]="12"  [showTime]="true" [selectOtherMonths]="true" id="clockOut" name="clockOut" dataType="string" formControlName="clockOut" ></p-calendar>
                <app-validation-message [form]="form" [path]="'clockOut'" [name]="'clockOut'" [label]="'Clock Out'"> </app-validation-message>
            </div>
          </div>
        </div>

        <ng-container *ngIf="form.get('isClientServiceTime').value">
        <div class="col-12 col-lg-6">
          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Client Service Time</b>
            </div>
            <div class="col-8 ">
                <p-checkbox [formControl]="form.get('isClientServiceTime')" [binary]="true" ></p-checkbox>
                <app-validation-message [form]="form" [path]="'isClientServiceTime'" [name]="'isClientServiceTime'" [label]="'Client Service Time'"> </app-validation-message>
            </div>            
              <div class="col-4  d-flex align-items-center">
                <b>Client</b>
              </div>
              <div class="col-8 ">
                  <p-dropdown styleClass="form-control mt-2" [options]="clients" formControlName="patientID" placeholder="Select client..."></p-dropdown>
                  <app-validation-message [form]="form" [path]="'patientID'" [name]="'patientID'" [label]="'Client'"> </app-validation-message>
              </div>
              <div class="col-4  d-flex align-items-center">
                <b>Code</b>
              </div>
              <div class="col-8 ">
                  <input type="text" class="form-control mt-2" formControlName="code"/>
                  <app-validation-message [form]="form" [path]="'code'" [name]="'code'" [label]="'Code'"> </app-validation-message>
              </div>
            
          </div>
        </div>
          </ng-container>
      </div>
      <button class="btn btn-primary mt-3" id="saveButton" (click)="save()">Save</button>
    </form>
  </div>
</div>
