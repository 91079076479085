import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, tap, catchError, filter } from 'rxjs/operators';
import { of, pipe } from 'rxjs';
import { Store, Action } from '@ngrx/store';
import { State } from '..';
import { AlertService } from './../../../shared/services/alert.service';
import { downloadFile } from './../../../helpers/utils';
import { JobApplicationsService } from '../../services/job-applications.service'
import { loadCampuses,
  loadCampusesSuccess,
   saveJobApplication,
   saveJobApplicationFail,
   saveJobApplicationSuccess,
   getJobApplications,
   getJobApplicationsSuccess,
   getJobApplicationsFail,
   saveAsProspectiveCandidate,
   saveAsProspectiveCandidateSuccess,
   archiveJobApplication,
   archiveJobApplicationSuccess,
   logNavigation,
   logNavigationSuccess,
   getResumeSuccess,
   getCoverLetter,
   getResume,
   getCoverLetterSuccess,
   savePhoneInterview,
   savePhoneInterviewSuccess,
   savePhoneInterviewFail,
   archiveJobApplicationFail,
   saveAsProspectiveCandidateFail,
   loadJobApplicationForms,
   loadJobApplicationFormsSuccess,
   loadJobApplicationFormsFail
   } from './job-applications.action';
import { errorHappened } from '../profile/profile.actions';
import { jobApplicationsReducerFunc } from './job-applications.reducer';

@Injectable()
export class JobApplicationsEffects {
  constructor(
    private actions$: Actions,
    private JobApplicationsService: JobApplicationsService,
    private store: Store<State>,
    private alertService: AlertService,
    private router: Router) { }

    loadCampuses = createEffect(() => this.actions$.pipe(
      ofType(loadCampuses),
      switchMap(action => this.JobApplicationsService.getCampuses().pipe(
        map(campuses => loadCampusesSuccess({ campuses })),
        catchError(err => {
          return of(errorHappened({ err }));
        })
      ))));

      logNavigation = createEffect(() => this.actions$.pipe(
        ofType(logNavigation),
        switchMap(action => this.JobApplicationsService.logNavigation().pipe(
          map(response => logNavigationSuccess()),
          catchError(err => {
            return of(errorHappened({ err }));
          })
        ))));

      saveJobApplication = createEffect(() => this.actions$.pipe(
        ofType(saveJobApplication),
        switchMap(action => {
          return this.JobApplicationsService.saveJobApplication(action.jobApplication, action.resume, action.coverLetter).pipe(
            map(jobApplication => saveJobApplicationSuccess({ jobApplication })),
            catchError(err => {
              this.store.dispatch(saveJobApplicationFail({ err }));
              return of(errorHappened({ err }));
            }));
        })
      ));

      saveJobApplicationSuccess = createEffect(() => this.actions$.pipe(
        ofType(saveJobApplicationSuccess),
        tap(action => {
          this.alertService.success(
            'Application Submitted', false);
          this.router.navigate(['/application-success']);
        })
      ), { dispatch: false });

      archiveJobApplication = createEffect(() => this.actions$.pipe(
        ofType(archiveJobApplication),
        switchMap(action => {
          return this.JobApplicationsService.archiveJobApplication( action.jobAppID, action.archiveReason).pipe(
            map(jobApplication => archiveJobApplicationSuccess({ jobApplication })),
            catchError(err => {
              this.store.dispatch(archiveJobApplicationFail({ err }));
              return of(errorHappened({ err }));
            }));
        })
      ));

      archiveJobApplicationSuccess = createEffect(() => this.actions$.pipe(
        ofType(archiveJobApplication),
        tap(action => {
          this.alertService.success('Application Archived');
          this.router.navigate(['/jobapplications/management']);
          })
        ), { dispatch: false });

      savePhoneInterview = createEffect(() => this.actions$.pipe(
        ofType(savePhoneInterview),
        switchMap(action => {
          return this.JobApplicationsService.savePhoneInterview(action.interview).pipe(
            map(interview => savePhoneInterviewSuccess({ interview: interview })),
            catchError(err => {
              this.store.dispatch(savePhoneInterviewFail({ err }));
              return of(errorHappened({ err }));
            }));
        })
      ));

      savePhoneInterviewSuccess = createEffect(() => this.actions$.pipe(
        ofType(savePhoneInterviewSuccess),
        tap(action => {
          this.alertService.success('Interview Saved');
          })
        ), { dispatch: false });

      saveAsProspectiveCandidate = createEffect(() => this.actions$.pipe(
        ofType(saveAsProspectiveCandidate),
        switchMap(action => {
          return this.JobApplicationsService.saveAsProspectiveCandidate(action.app).pipe(
            map(jobApplication => saveAsProspectiveCandidateSuccess({ jobApplication })),
            catchError(err => {
              this.store.dispatch(saveAsProspectiveCandidateFail({ err }));
              return of(errorHappened({ err }));
            }));
        })
      ));

      saveAsProspectiveCandidateSuccess = createEffect(() => this.actions$.pipe(
        ofType(saveAsProspectiveCandidateSuccess),
        tap(action => {
          this.alertService.success(
            'Added to Prospective Candidates', false);
          this.router.navigate(['/jobapplications/management']);
        })
      ), { dispatch: false });

      getResume = createEffect(() => this.actions$.pipe(
        ofType(getResume),
        switchMap(action => {
          return this.JobApplicationsService.getResume(action.applicationID).pipe(
            map(url => getResumeSuccess({ url: url.url })),
            catchError(err => {
              console.log(err);
              return of(errorHappened({ err }));
            }));
        })
      ));

      getResumeSuccess = createEffect(() => this.actions$.pipe(
        ofType(getResumeSuccess),
        tap(action => {
          if (action.url == null){
            this.alertService.info("There has not been a Resume Uploaded.")
          }
          else{
            window.open(action.url, "_blank");
          }
        })
      ), { dispatch: false });

      getCoverLetter = createEffect(() => this.actions$.pipe(
        ofType(getCoverLetter),
        switchMap(action => {
          return this.JobApplicationsService.getCoverLetter(action.applicationID).pipe(
            map(url => getResumeSuccess({ url: url.url })),
            catchError(err => {
              console.log(err);
              return of(errorHappened({ err }));
            }));
        })
      ));

      getCoverLetterSuccess = createEffect(() => this.actions$.pipe(
        ofType(getCoverLetterSuccess),
        tap(action => {
          if (action.url == null){
            this.alertService.info("There has not been a Cover Letter Uploaded.")
          }
          else{
            window.open(action.url, "_blank");
          }
        })
      ), { dispatch: false });

      getJobApplications = createEffect(() => this.actions$.pipe(
        ofType(getJobApplications),
        switchMap(action => {
          return this.JobApplicationsService.getJobApplicationTable(action.filter).pipe(
            map(jobApplications => getJobApplicationsSuccess({ jobApplications })),
            catchError(err => {
              this.store.dispatch(getJobApplicationsFail({ err }));
              return of(errorHappened({ err }));
            }));
        })
      ));


      loadJobApplicationForms = createEffect(() => this.actions$.pipe(
        ofType(loadJobApplicationForms),
        switchMap(action => {
          return this.JobApplicationsService.loadJobApplicationForms().pipe(
            map(jobTitleForms => loadJobApplicationFormsSuccess({ jobTitleForms: jobTitleForms })),
            catchError(err => {
              this.store.dispatch(loadJobApplicationFormsFail(err));
              return of(errorHappened({ err }));
            }));
        })
      ));
}
