<form class="card mt-2" *ngIf="canEdit">
  <div class="card-body">
      <button *ngIf="canEdit" class="btn btn-primary float-right pointer" routerLink="/medclinic/create-patient">Request Med Clinic Services</button>
      <h2 class="mb-3">Med Clinic - Family Registered</h2>


    <i *ngIf="loadingPatients " class="material-icons rotate text-muted">loop</i>
    <p-table  [value]="medclinicPatientsList" [responsive]="true"
    [paginator]="true" [rows]="20" >
        <ng-template pTemplate="header">
            <tr>
                <th [pSortableColumn]="'FirstName'" >First Name
                  <p-sortIcon [field]="'FirstName'"></p-sortIcon></th>
                <th [pSortableColumn]="'LastName'">Last Name
                  <p-sortIcon [field]="'LastName'"></p-sortIcon>
                </th>
                <th [pSortableColumn]="'RelationshipToClient'">Relationship
                  <p-sortIcon [field]="'RelationshipToClient'"></p-sortIcon>
                </th>

                <th [pSortableColumn]="'Birthdate'">Birthdate
                  <p-sortIcon [field]="'Birthdate'"></p-sortIcon>
                </th>
                <th [pSortableColumn]="'Gender'">Gender
                  <p-sortIcon [field]="'Gender'"></p-sortIcon>
                </th>

            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-patient>
            <tr >
                <td>
                    <span class="ui-column-title">First Name</span>
                  {{patient.firstName }}
                </td>
                <td>
                    <span class="ui-column-title">Last Name</span>
                  {{patient.lastName }}
                </td>

                <td>
                  <span class="ui-column-title">Relationship</span>
                  {{patient.relationshipToClient}}
                </td>
                <td>
                  <span class="ui-column-title">Birthdate</span>
                  {{patient.birthdate | date:'shortDate'}}
                </td>

                <td>
                  <span class="ui-column-title">Gender</span>
                  {{patient.gender}}
                </td>
            </tr>
        </ng-template>
    </p-table>
  </div>
</form>

