import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SelectItem } from 'primeng';
import { InputAutoCompleteModel } from '../models/inputAutoCompleteModel';


@Injectable()
export class InputAutoCompleteService {

  constructor(private http: HttpClient) {
  }
  getInputAutocompleteList(options: any): Observable<SelectItem[]> {
    return this.http.post<SelectItem[]>
      (`${environment.apiServer.webApiURL}/InputHelper/AutoCompleteQuery`, options);
  }
}