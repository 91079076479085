
<div class="card mb-2">
  <div class="card-body">
    <button  class="btn btn-secondary float-right pointer" routerLink="/selfcare/management/menu">Back</button>
    <h2 class="mb-3">Self Care Planner Sections</h2>
    <i [hidden]="!loading" class="material-icons rotate text-muted">loop</i>

    <table class="table table-sm table-striped table-bordered">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Section</th>
          <th scope="col">Color</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let plannerSection of plannerSections;">
          <tr>
            <td class="text-center">
              <button class="btn btn-secondary m-2" (click)="edit(plannerSection)" title="Edit Planner Section">
                <i class="fas fa-edit"></i>
              </button>
            </td>
            <td scope="row">
            {{plannerSection.sectionTitle}}
            </td>
            <td scope="row" [style.color]="plannerSection.mainColor">
              {{plannerSection.mainColor}}
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
