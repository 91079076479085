import { FBFormAssignmentModel } from './../../../../models/formBuilderModels';
import { addFBFormAssignment, getAllForms, addFBFormAssignmentSuccess } from './../../../../core/store/form-builder/form-builder.actions';
import { getUsersList } from './../../../../core/store/users/users.actions';
import { additTimeCard, loadManualEntryReasons, updateTimeCardForm } from './../../../../core/store/timecard/timecard.actions';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { loadClients } from 'src/app/core/store/timecard/timecard.actions';
import { TimeCardModel } from 'src/app/models/timeCardModel';
import { FormGroup } from '@angular/forms';
import { initTimeCardEditForm } from 'src/app/core/store/timecard/timecard.reducer';
import { SelectItem } from 'primeng/api';
import { markFormGroupTouched, getAllControlsErrors, updateFormGroupValidity } from 'src/app/helpers/utils';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { Actions, ofType } from '@ngrx/effects';
import { Router, ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-form-submission',
  templateUrl: './form-submission.component.html',
  styleUrls: ['./form-submission.component.scss']
})
export class FormSubmissionComponent extends BaseComponent implements OnInit {

  formSubmissionID;
  formID;

  constructor(
    private actions$: Actions,
    private route: ActivatedRoute,
    private store: Store<State>) {
    super();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params.formSubmissionID) {
        this.formSubmissionID = params.formSubmissionID;
      }
      if (params.formID) {
        this.formID = params.formID;
      }
    });
  }


}
