<form class="card mt-2">
  <div class="card-body">
    <div *ngIf="canEdit" class="float-right">
      <button type="button" routerLink="/uploaddocs/approval-list" class="btn btn-secondary">Upload Approval</button>
    </div>
    <h2 class="mb-3">Uploaded Vaccine Information</h2>
    <div class="mb-3">

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Campus:</h5>
        </div>
        <div class="col-10">
          <i *ngIf="!campusList" class="material-icons rotate text-muted">loop</i>
          <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="campusList" [(ngModel)]="campusId" (ngModelChange)="reloadFilter(this.filter)" [ngModelOptions]="{standalone: true}"></p-dropdown>
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Employee:</h5>
        </div>
        <div class="col-10">
          <div class="ml-2 w-100 mb-2">
            <p-autoComplete [style]="{'width': '100%'}" [inputStyle]="{'width': '100%'}" [(ngModel)]="prefixText"
                            [suggestions]="userEmployeeList" (completeMethod)="getStaffList($event)" field="label"
                            [ngModelOptions]="{standalone: true}" [minLength]="1" [autoHighlight]="true"
                            (onSelect)="reloadListAfterStaffChage($event)" [forceSelection]="true"
                            (onClear)="reloadListAfterClear($event)"></p-autoComplete>
          </div>
        </div>
      </div>      

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Vaccine Status:</h5>
        </div>
        <div class="col-10">
          <i *ngIf="!vaccineStatusOptions" class="material-icons rotate text-muted">loop</i>
          <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="vaccineStatusOptions" [(ngModel)]="vaccineStatus" optionValue="value" (ngModelChange)="reloadFilter(this.filter)" [ngModelOptions]="{standalone: true}"></p-dropdown>
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Exemption Status:</h5>
        </div>
        <div class="col-10">
          <i *ngIf="!exemptionStatusOptions" class="material-icons rotate text-muted">loop</i>
          <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="exemptionStatusOptions" [(ngModel)]="exemptionStatus" optionValue="value" (ngModelChange)="reloadFilter(this.filter)" [ngModelOptions]="{standalone: true}"></p-dropdown>
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Date Uploaded:</h5>
        </div>
        <div class="col-10">
          <i *ngIf="!dateRangeList" class="material-icons rotate text-muted">loop</i>
          <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="dateRangeList" [(ngModel)]="uploadedDateRange" (ngModelChange)="changedUploadedDateRange()" [ngModelOptions]="{standalone: true}"></p-dropdown>
        </div>
      </div>

      <div class="row" *ngIf="isCustomUploadedDateRange">
        <div class="col-2 d-flex align-items-center">
          <h5>Uploaded Start Date:</h5>
        </div>
        <div class="col-10">
          <input type="date" class="form-control ml-2 w-100 mb-2" [(ngModel)]="uploadedStartDate" (blur)="reloadFilter(this.filter)" [ngModelOptions]="{standalone: true}" />
        </div>
        <div class="col-2 d-flex align-items-center">
          <h5>Uploaded End Date:</h5>
        </div>
        <div class="col-10">
          <input type="date" class="form-control ml-2 w-100 mb-2" [(ngModel)]="uploadedEndDate" (blur)="reloadFilter(this.filter)" [ngModelOptions]="{standalone: true}" />
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Exemption Approval Date:</h5>
        </div>
        <div class="col-10">
          <i *ngIf="!dateRangeList" class="material-icons rotate text-muted">loop</i>
          <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="dateRangeList" [(ngModel)]="approvalDateRange" (ngModelChange)="changedApprovalDateRange()" [ngModelOptions]="{standalone: true}"></p-dropdown>
        </div>
      </div>

      <div class="row" *ngIf="isCustomApprovalDateRange">
        <div class="col-2 d-flex align-items-center">
          <h5>Approval Start Date:</h5>
        </div>
        <div class="col-10">
          <input type="date" class="form-control ml-2 w-100 mb-2" [(ngModel)]="approvalStartDate" (blur)="reloadFilter(this.filter)" [ngModelOptions]="{standalone: true}" />
        </div>
        <div class="col-2 d-flex align-items-center">
          <h5>Approval End Date:</h5>
        </div>
        <div class="col-10">
          <input type="date" class="form-control ml-2 w-100 mb-2" [(ngModel)]="approvalEndDate" (blur)="reloadFilter(this.filter)" [ngModelOptions]="{standalone: true}" />
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Include Separated Employees:</h5>
        </div>
        <div class="col-10 " style="padding-left: 25px;">
          <p-checkbox [(ngModel)]="showTerminatedEmployees" (ngModelChange)="reloadFilter(this.filter)" [binary]="true" [ngModelOptions]="{standalone: true}"></p-checkbox>
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Include Inactive Users:</h5>
        </div>
        <div class="col-10 " style="padding-left: 25px;">
          <p-checkbox [(ngModel)]="showInactiveUsers" (ngModelChange)="reloadFilter(this.filter)" [binary]="true" [ngModelOptions]="{standalone: true}"></p-checkbox>
        </div>
      </div>
    </div>

    <i [hidden]="!loadingList && !loadingExcel" class="material-icons rotate text-muted">loop</i>
    <button type="button" title="Export all pages to excel" class="btn btn-success mr-2 mb-2" (click)="exportToExcel()"><i class="far fa-file-excel"></i></button>
    <p-table [value]="list?.result" [responsive]="true" [paginator]="true" [rows]="20"
             [totalRecords]="list?.totalRecords" [lazy]="true" (onLazyLoad)="reloadFilter($event)">
      <ng-template pTemplate="header">
        <tr>          
          <th [pSortableColumn]="'EmployeeName'">Employee <p-sortIcon [field]="'EmployeeName'"></p-sortIcon></th>
          <th [pSortableColumn]="'PrimaryProgramName'">Primary Campus <p-sortIcon [field]="'PrimaryProgramName'"></p-sortIcon></th>
          <th>Vaccine Status</th>
          <th>Exemption Status</th>          
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-list>
        <tr style="white-space: pre-line;" [class.red-highlight-row]="highlightRed(list)" [class.orange-highlight-row]="highlightOrange(list)">          
          <td>
            <span class="ui-column-title">Employee</span>
            <span class="pointer" style="text-decoration:underline;" title="View Employee File" (click)="seeUserDetails(list)">{{list.firstName}} {{list.lastName}}</span>            
          </td>
          <td>
            <span class="ui-column-title">Primary Campus</span>
            {{list.primaryProgramName}}
          </td>
          <td>
            <span class="ui-column-title">Vaccine Status</span>
            {{getVaccineStatus(list)}}
          </td>
          <td>
            <span class="ui-column-title">Exemption Status</span>
           {{getExemptionStatus(list)}}
          </td>         
        </tr>
      </ng-template>
    </p-table>
  </div>
</form>
