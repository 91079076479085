import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { EmployeeReviewTemplateModel } from '../../../../models/employeeReviewTemplateModels';
import { AlertService } from '../../../../shared/services/alert.service';
import { deepClone, getShortDateFormatted, isBright } from '../../../../helpers/utils';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { autoSaveOversightReview, autoSaveOversightReviewSuccess, getEmployeeReviews, getEmployeeReviewsSuccess, loadMyTemplates, postOversightReview, postOversightReviewSuccess, postPlayerReview, postPlayerReviewSuccess } from '../../../../core/store/employee-review/employee-review.actions';
import { EmployeeReviewModel, EmployeeReviewOversightLoadModel, EmployeeTemplateReviews, SecondOversightReviewViewModel } from '../../../../models/employeeReviewModels';
import { GeneralSettingsModel } from 'src/app/models/generalSettingsModel';


@Component({
  selector: 'app-oversight',
  templateUrl: './oversight.component.html',
  styleUrls: ['./oversight.component.scss']
})
export class OversightComponent extends BaseComponent implements OnInit, OnDestroy {
  canReview: boolean;
  loading: boolean;
  isSavingToLater = false;

  userId: number;
  oversightReview: EmployeeReviewModel;
  oversightData: EmployeeReviewOversightLoadModel;
  templateReviews: EmployeeTemplateReviews;
  templateAvailables: any[];
  score: number;
  comments: string;
  focusArea: string;
  fixComments: string;
  hasOpenFixRequest: boolean = false;
  oversightReviews: SecondOversightReviewViewModel[];

  templateID;
  savingOversightReview = false;
  intervalAutoSave;
  generalSettings: GeneralSettingsModel;

  constructor(
    private store: Store<State>,
    private actions$: Actions,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService) {

    super();
  }

  ngOnInit() {

    this.canReview = false;
    this.loading = false;
    this.oversightData = {
      canReview: false,
      employeeFirstName: "",
      employeeLastName: "",
      oversightUserID: 0,
      templateReviews: [],
      userID: 0,
      isOpen: false,
      canApprove: false,
      canSeeApproval: false
    };
    this.templateAvailables = [];
    this.templateReviews = {
      reviews: [],
      template: {
        description: "",
        kras: [],
        requiresOversightReview: false
      },
      templateID: 0
    }
    this.score = 0;
    this.comments = "";
    this.focusArea = "";

    this.subs.push(
      this.store.select(s => s.accountState.generalSettings).subscribe(generalSettings => {
        if (generalSettings) {
          this.generalSettings = generalSettings;
        }
      }),
      this.store.select(s => s.employeeReviewState.loading).subscribe(loading => {
        this.loading = loading;
      }),

      this.actions$.pipe(
        ofType(getEmployeeReviewsSuccess),
        tap(action => {
          this.oversightData = deepClone(action.oversightData);
          console.log(this.oversightData);
          if (!this.oversightData.isOpen) {
            if (this.intervalAutoSave) {
              clearInterval(this.intervalAutoSave);
            }
          }
          this.templateAvailables = this.oversightData.templateReviews.map(m => {
            return { label: `(${m.reviews.length} reviews) (${getShortDateFormatted(m.template.periodStart)} - ${m.template.periodEnd ? getShortDateFormatted(m.template.periodEnd) : "Current"}) - ${m.template.description ? m.template.description : ''}`, value: m }
          });
          this.templateAvailables.sort(function (a, b) {
            var labelA = a.label.toUpperCase(); // ignore upper and lowercase
            var labelB = b.label.toUpperCase(); // ignore upper and lowercase
            if (labelA < labelB) {
              return -1;
            }
            if (labelA > labelB) {
              return 1;
            }

            // names must be equal
            return 0;
          });
          if (this.oversightData.templateReviews.length > 0)
            this.templateReviews = this.oversightData.templateReviews[0];
          this.canReview = this.oversightData.canReview;

          this.selectParamTemplate();
          this.changedTemplate();

        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(postOversightReviewSuccess),
        tap(action => {
          this.alertService.success('Oversight review submitted!');
          this.router.navigate(['/']);
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(autoSaveOversightReviewSuccess),
        tap(action => {
          if (this.isSavingToLater) {
            this.alertService.success('Oversight review saved!');
            this.router.navigate(['/']);
          }
        })
      ).subscribe(),
      this.store.select(s => s.employeeReviewState.savingOversightReview).subscribe(savingOversightReview => {
        this.savingOversightReview = savingOversightReview;
      }),
    );

    this.route.params.subscribe(params => {
      this.userId = parseInt(params.userId);
      if (this.userId) {
        this.store.dispatch(getEmployeeReviews({ userId: this.userId }));
      }
      if (params.templateID) {
        this.templateID = parseInt(params.templateID);
        this.selectParamTemplate();
      }
    });

    this.startAutosave();
  }

  selectParamTemplate() {
    if (this.templateAvailables && this.templateAvailables.length && this.templateID) {
      const templateOptionToSelect = this.templateAvailables.find(x => (x.value as EmployeeTemplateReviews).templateID === this.templateID);
      if (templateOptionToSelect) {
        this.templateReviews = templateOptionToSelect.value;
        this.changedTemplate();
      }
    }
  }

  changedTemplate() {
    this.comments = this.templateReviews.template.draftReview ? this.templateReviews.template.draftReview.comments : '';
    this.oversightReviews = this.templateReviews.template.draftReview ? this.templateReviews.template.draftReview.oversightReviews : [];
    this.hasOpenFixRequest = this.oversightReviews.some(s => s.approved == false && !s.fixedAt);
    this.focusArea = this.templateReviews.template.draftReview ? this.templateReviews.template.draftReview.focusArea : '';
    if (this.templateReviews.template.draftReview && this.templateReviews.template.draftReview.kraScores) {
      for (var i = 0; i < this.templateReviews.template.draftReview.kraScores.length; i++) {
        var score = this.templateReviews.template.draftReview.kraScores[i];
        var kra = this.templateReviews.template.kras.find(f => f.kra_id == score.krA_ID);
        kra.score = score.optionValue;
        if (kra.optionSet && kra.optionSet.scoreOptions) {
          kra.scoreOption = kra.optionSet.scoreOptions.find(f => f.scoreOptionValue == kra.score);
        }

      }
    }
    this.calcScore();
  }

  changeComment() {
    if (!this.templateReviews.template.draftReview) {
      this.templateReviews.template.draftReview = {} as EmployeeReviewModel;
    }
    this.templateReviews.template.draftReview.comments = this.comments;

  }

  changeFocusArea() {
    if (!this.templateReviews.template.draftReview) {
      this.templateReviews.template.draftReview = {} as EmployeeReviewModel;
    }
    this.templateReviews.template.draftReview.focusArea = this.focusArea;

  }

  getAnswer(review, measureable) {
    if (review.reviewDate === null) {
      return '-';
    }

    if (review.answers.some(s => s.value == measureable.measureableId && s.selected))
      return "Yes";
    return "No";
  }

  save() {
    if (!this.templateReviews.template || !this.templateReviews.template.id) {
      this.alertService.error("Template not selected.");
      return;
    }

    const review = this.getOversightReviewModel(true);

    if (!review) {
      return;
    }

    this.store.dispatch(postOversightReview({ review }));

  }

  saveToLater() {
    this.isSavingToLater = true;
    if (this.intervalAutoSave) {
      clearInterval(this.intervalAutoSave);
    }
    this.autosave();
  }

  getOversightReviewModel(shouldAlertAndStop = false) {

    var review: EmployeeReviewModel = {
      templateID: this.templateReviews.template.id,
      answers: [],
      kraScores: [],
      userID: this.userId,
      comments: this.comments,
      focusArea: this.focusArea
    }

    for (var kraIndex = 0; kraIndex < this.templateReviews.template.kras.length; kraIndex++) {
      var kra = this.templateReviews.template.kras[kraIndex];
      if (kra.optionSet && kra.optionSet.scoreOptions && kra.optionSet.scoreOptions.length > 0) {
        if (shouldAlertAndStop) {
          if (!kra.scoreOption) {
            this.alertService.error("Score not answered.");
            return null;
          }
        }
        if (kra.scoreOption) {
          review.kraScores.push({
            optionScoreID:  kra.scoreOption.scoreOptionId,
            krA_ID: kra.kra_id,
            optionValue: kra.scoreOption.scoreOptionValue
          });
        }
      }

      for (var kpiIndex = 0; kpiIndex < kra.kpis.length; kpiIndex++) {
        var kpi = kra.kpis[kpiIndex];
        for (var categoryIndex = 0; categoryIndex < kpi.categories.length; categoryIndex++) {
          var category = kpi.categories[categoryIndex];
          for (var measureableIndex = 0; measureableIndex < category.measureables.length; measureableIndex++) {
            var measureable = category.measureables[measureableIndex];
            if (shouldAlertAndStop) {
              if (measureable.answer != 0 && measureable.answer != 1) {
                this.alertService.error("Measureable not answered.");
                return null;
              }
            }
            review.answers.push({
              value: measureable.measureableId,
              selected: measureable.answer ? measureable.answer == 1 : null
            });
            for (var childIndex = 0; childIndex < measureable.childrenMeasureables.length; childIndex++) {
              var child = measureable.childrenMeasureables[childIndex];
              if (shouldAlertAndStop) {
                if (child.answer != 0 && child.answer != 1) {
                  this.alertService.error("Measureable not answered.");
                  return null;
                }
              }
              review.answers.push({
                value: child.measureableId,
                selected: child.answer ? child.answer == 1 : null
              });
            }
          }
        }
      }

      for (var measureableIndex = 0; measureableIndex < kra.measureables.length; measureableIndex++) {
        const measureable = kra.measureables[measureableIndex];
        
        review.answers.push({
          value: measureable.measureableId,
          selected: measureable.answer ? measureable.answer == 1 : null
        });
        for (var childIndex = 0; childIndex < measureable.childrenMeasureables.length; childIndex++) {
          const child = measureable.childrenMeasureables[childIndex];
          
          review.answers.push({
            value: child.measureableId,
            selected: child.answer ? child.answer == 1 : null
          });
        }
      }
    }

    if (this.hasOpenFixRequest) {
      review.fixComments = this.fixComments;
    }

    return review;
  }


  startAutosave() {
    this.isSavingToLater = false;
    if (this.intervalAutoSave) {
      clearInterval(this.intervalAutoSave);
    }
    this.intervalAutoSave = setInterval(() => {
      if (!this.savingOversightReview && this.templateReviews && this.templateReviews.template) {
        this.autosave();
      }
    }, 6 * 10000);
  }

  autosave() {
    if (this.canReview) {
      const review = this.getOversightReviewModel(false);
      this.store.dispatch(autoSaveOversightReview({ review }));
    }
  }


  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.intervalAutoSave) {
      clearInterval(this.intervalAutoSave);
    }
  }


  calcScore() {
    var score = null;
    for (var kraI = 0; kraI < this.templateReviews.template.kras.length; kraI++) {
      var kra = this.templateReviews.template.kras[kraI];
      if (kra.kpis.length) {
        for (var kpiI = 0; kpiI < kra.kpis.length; kpiI++) {
          var kpi = kra.kpis[kpiI];
          var currentKpiScore = 0;
          var allChecked = true;
          var categoriesSorted = kpi.categories.sort(function (a, b) {
            return a.category.scoreValue - b.category.scoreValue;
          });
          for (var ci = 0; ci < categoriesSorted.length; ci++) {
            var category = categoriesSorted[ci];

            for (var mi = 0; mi < category.measureables.length; mi++) {
              var measureable = category.measureables[mi];
              if (measureable.answer != 1) {
                allChecked = false;
                break;
              }
              for (var childIndex = 0; childIndex < measureable.childrenMeasureables.length; childIndex++) {
                var child = measureable.childrenMeasureables[childIndex];
                if (child.answer != 1) {
                  allChecked = false;
                  break;
                }
              }
            }
            if (allChecked == false)
              break;
            else
              currentKpiScore = category.category.scoreValue;
          }

          score += currentKpiScore;

        }
      } else {

        if (kra.scoreOption) {
          kra.score = kra.scoreOption.scoreOptionValue;
        } else {
          kra.score = null;
        }
        let currentScore = kra.score;

        if (this.templateReviews.template.scoreType.scoreType == "Sum") {
          score += currentScore;
        } else if (this.templateReviews.template.scoreType.scoreType == "Highest Value") {
          if (score == null || score < currentScore) {
            score = currentScore;
          }
        } else if (this.templateReviews.template.scoreType.scoreType == "Lowest Value") {
          if (score == null || score > currentScore) {
            score = currentScore;
          }
        }
      }
    }
    this.score = score;
  }

  blackOrWhite(color: string) {
    if (color && !isBright(color)) {
      return '#FFFFFF';
    } else {
      return '#000000';
    }
  }
}
