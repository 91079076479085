<div>
  <div class="w-100 text-center mb-3 d-sm-flex justify-content-center ">
    <span class="d-inline-flex align-items-center " >
      <h1>Management</h1>
    </span>
  </div>
  <div class="row">
    <div class="col-12 col-sm-6 col-md-4 col-lg-2 mt-2 " *ngIf="canManageApplications">
      <div class="card pointer" (click)="openLink('jobapplications/management')">
        <div class="card-body">
          <div class="text-center font-big-icon">
            <i class="fas fa-file-contract"></i><br />
            Job Applications
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-2 mt-2 " *ngIf="canViewProspectiveCandidate">
      <div class="card pointer" (click)="openLink('/prospective-candidate/list')">
        <div class="card-body">
          <div class="text-center font-big-icon">
            <i class="fas fa-child"></i><br />
            Prospective Candidates
            <span *ngIf="profile != null && profile.newAnsweredReferenceSurveyCount>0" class="badge badge-danger" title="New Answered Reference Surveys">{{profile.newAnsweredReferenceSurveyCount}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-2 mt-2 " *ngIf="canViewOfferLetter || canViewOfferLetterBehaviorTech || canViewOfferLetterBehaviorTechAA">
      <div class="card pointer" (click)="openLink('/offerletter/list')" id="OfferLetters">
        <div class="card-body">
          <div class="text-center font-big-icon">
            <i class="fas fa-hand-holding-usd"></i><br />
            Offer Letters <span *ngIf="!((canViewOfferLetterBehaviorTech || canViewOfferLetterBehaviorTechAA) && !canViewOfferLetter) && profile != null && profile.newSignedOfferLettersCount>0" class="badge badge-danger" title="New Signed Offer Letters">{{profile.newSignedOfferLettersCount}}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-6 col-md-4 col-lg-2 mt-2 " *ngIf="canViewOnBoarding">
      <div class="card pointer" (click)="openLink('/onboarding-manage/list')" id="OnboardingManage">
        <div class="card-body">
          <div class="text-center font-big-icon">
            <i class="fas fa-user-check"></i><br />
            Employee Onboarding
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-6 col-md-4 col-lg-2 mt-2 " *ngIf="canViewEmployeeFile">
      <div class="card pointer" (click)="openLink('/employee-file/list')">
        <div class="card-body">
          <div class="text-center font-big-icon">
            <i class="fas fa-user"></i><br />
            Employee File
          </div>
        </div>
      </div>
    </div>


    <div class="col-12 col-sm-6 col-md-4 col-lg-2 mt-2 " *ngIf="canViewDocumentUploadApproval">
      <div class="card pointer" (click)="openLink('/uploaddocs/approval-list')" id="DocumentUploadApproval">
        <div class="card-body">
          <div class="text-center font-big-icon">
            <i class="fas fa-file-check"></i><br />
            Document Upload Approval
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-6 col-md-4 col-lg-2 mt-2 " *ngIf="canViewEquipment">
      <div class="card pointer" (click)="openLink('/equipment/list')">
        <div class="card-body">
          <div class="text-center font-big-icon">
            <i class="fas fa-mobile-alt"></i><br />
            Asset Tracker
            <span *ngIf="returnEquipmentAlertAllCount > 0" class="badge badge-danger" [title]="returnEquipmentAlert">{{ returnEquipmentAlertAllCount }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-6 col-md-4 col-lg-2 mt-2 " *ngIf="canViewPayrollApproval">
      <div class="card pointer" (click)="openLink('/payroll/approval')">
        <div class="card-body">
          <div class="text-center font-big-icon">
            <i class="fas fa-money-bill" style="color: green;"></i><br />
            Payroll Approval
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-2 mt-2 " *ngIf="canViewOnboardingDocuments">
      <div class="card pointer" (click)="openLink('/onboardingdocs/report')">
        <div class="card-body">
          <div class="text-center font-big-icon">
            <i class="fas fa-file-contract"></i><br />
            Report Documents
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-6 col-md-4 col-lg-2 mt-2 " *ngIf="canViewOnboardingDocuments">
      <div class="card pointer" (click)="openLink('/manage/list-onboardingdocs')">
        <div class="card-body">
          <div class="text-center font-big-icon">
            <i class="fas fa-file-contract"></i><br />
            Manage Documents
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-6 col-md-4 col-lg-2 mt-2 " *ngIf="canViewOnboardingDocuments">
      <div class="card pointer" (click)="openLink('/manage/list-onboardinguserdocs')">
        <div class="card-body">
          <div class="text-center font-big-icon">
            <i class="fas fa-file-contract"></i><br />
            Employees Documents
          </div>
        </div>
      </div>
    </div> -->

    <div class="col-12 col-sm-6 col-md-4 col-lg-2 mt-2 " *ngIf="canViewHolidays">
      <div class="card pointer" (click)="openLink('/holiday/list')">
        <div class="card-body">
          <div class="text-center font-big-icon">
            <i class="fas fa-gift" style="color: darkred;"></i><br />
            Holidays
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-6 col-md-4 col-lg-2 mt-2 " *ngIf="canViewFullTimeStatusReport && !canViewFullTimeStatusQueue">
      <div class="card pointer" (click)="openLink('/employee/fullTimeStatus/report')">
        <div class="card-body">
          <div class="text-center font-big-icon">
            <i class="fas fa-user-clock"></i><br />
            Full Time Status Checks
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-2 mt-2 " *ngIf="canViewFullTimeStatusQueue">
      <div class="card pointer" (click)="openLink('/employee/fullTimeStatus/queue')">
        <div class="card-body">
          <div class="text-center font-big-icon">
            <i class="fas fa-user-clock"></i><br />
            Full Time Status Checks
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-6 col-md-4 col-lg-2 mt-2 " *ngIf="canViewEmployeePayrollReports">
      <div class="card pointer" (click)="openLink('/employee/census')">
        <div class="card-body">
          <div class="text-center font-big-icon">
            <i class="fas fa-file-alt"></i><br />
            Reports
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-6 col-md-4 col-lg-2 mt-2 ">
      <div class="card pointer" (click)="openLink('/survey/report')">
        <div class="card-body">
          <div class="text-center font-big-icon">
            <i class="fas fa-star"></i><br />
            Survey Report
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-2 mt-2 " *ngIf="canViewStaffInjuriesQueue">
      <div class="card pointer" id="staffInjuriesQueue" (click)="openLink('injuryqueue')">
        <div class="card-body">
          <div class="text-center font-big-icon">
            <i class="fas fa-medkit"></i><br />
            Staff Injuries <span class="badge badge-danger" title="Open Staff Injuries Queue">{{profile != null ? profile.staffInjuriesQueueCount : 0}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-2 mt-2 " *ngIf="canViewPtoSummary || canViewManageTimeOff">
      <div class="card pointer" (click)="openTimeOffLink()">
        <div class="card-body">
          <div class="text-center font-big-icon">
            <i class="fas fa-umbrella-beach" style="color: aqua;"></i><br />
            Time Off
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-2 mt-2 " *ngIf="canViewBenefitsManagement">
      <div class="card pointer" id="benefits" (click)="openLink('/benefits-management/benefit-plan/list')">
        <div class="card-body">
          <div class="text-center font-big-icon">
            <i class="fas fa-hands-helping"></i><br />
            Benefits Plans
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-6 col-md-4 col-lg-2 mt-2 " *ngIf="canViewBenefitsManagement">
      <div class="card pointer" id="benefits-employee" (click)="openLink('/benefits-management/benefit-employees/list')">
        <div class="card-body">
          <div class="text-center font-big-icon">
            <i class="fas fa-hands-helping"></i><br />
            Employees Benefits
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-2 mt-2 " *ngIf="canViewBenefitsManagement">
      <div class="card pointer" id="benefitClasses" (click)="openLink('/benefits-management/benefit-classes')">
        <div class="card-body">
          <div class="text-center font-big-icon">
            <i class="fas fa-hands-helping"></i><br />
            Benefit Classes
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-2 mt-2 " *ngIf="canViewBenefitReports">
      <div class="card pointer" (click)="openLink('/benefits-management/status-check/queue')">
        <div class="card-body">
          <div class="text-center font-big-icon">
            <i style="margin-right:5px;" class="fas fa-hands-helping"></i><i class="fas fa-file-alt"></i><br />
            Benefit Reports
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-2 mt-2 " *ngIf="canViewOnboardingDocuments">
      <div class="card pointer" (click)="openLink('/employee-review/template/list')">
        <div class="card-body">
          <div class="text-center font-big-icon">
            <i class="fas fa-file-contract"></i><br />
            Employee Review Templates
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-2 mt-2 " *ngIf="canViewKraReports || canViewCompletedKraReports">
      <div class="card pointer" (click)="openKraLink()">
        <div class="card-body">
          <div class="text-center font-big-icon">
            <i class="fas fa-user-chart"></i><br />
            {{getKraCardTitle()}}
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-6 col-md-4 col-lg-2 mt-2 " *ngIf="canSeeSelfcare || canViewSelfCareReport">
      <div class="card pointer" (click)="openSelfCareOversightLink()">
        <div class="card-body">
          <div class="text-center font-big-icon">
            <i class="fas fa-hand-heart"></i><br />
            Self Care Oversight
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-2 mt-2 " *ngIf="canManageSelfcare">
      <div class="card pointer" (click)="openLink('/selfcare/management/menu')">
        <div class="card-body">
          <div class="text-center font-big-icon">
            <i class="fas fa-hand-heart"></i><br />
            Self Care Management
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-2 mt-2 " *ngIf="paylocityEnabled && canViewPaylocityReports">
      <div class="card pointer" (click)="openLink('/paylocity/report')">
        <div class="card-body">
          <div class="text-center font-big-icon">
            <i class="fas fa-money-check-edit-alt"></i><br />
            Paylocity
            <span *ngIf="profile != null && profile.paylocityTransmissionsWithErrors>0" class="badge badge-danger" title="New Paylocity Transmissions With Errors">{{profile != null ? profile.paylocityTransmissionsWithErrors : 0}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-2 mt-2 " *ngIf="(MotivHealthEnabled || SameraHealthEnabled) && canViewBenefitPlanEDIReports">
      <div class="card pointer" (click)="openLink('/benefitPlanEDI/tramissions')">
        <div class="card-body">
          <div class="text-center font-big-icon">

            <span class="fa-stack" >
              <i class="fas fa-hands-helping  fa-stack-2x "></i>
              <i class="fas fa-wrench fa-flip-horizontal fa-stack-1x fa-inverse" style="color:Tomato" ></i>
            </span><br /><br />
            Benifit Plan EDI
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-6 col-md-4 col-lg-2 mt-2 " *ngIf="canManageFormBuilder">
      <div class="card pointer" (click)="openLink('/form-builder/home')">
        <div class="card-body">
          <div class="text-center font-big-icon">
            <i class="fal fa-page-break"></i><br />
            Form Builder
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-6 col-md-4 col-lg-2 mt-2 " *ngIf="canViewCertificationReport">
      <div class="card pointer" (click)="openLink('/certification/report')">
        <div class="card-body">
          <div class="text-center font-big-icon">
            <i class="fas fa-file-certificate"></i><br />
            Certifications
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-6 col-md-4 col-lg-2 mt-2 " *ngIf="canViewInternApplications">
      <div class="card pointer" (click)="openLink('/intern/list-manage')">
        <div class="card-body">
          <div class="text-center font-big-icon">
            <i class="fas fa-graduation-cap"></i><br />
            Intern Applications <span *ngIf="profile != null && profile.pendingInternApplications>0" class="badge badge-danger" title="Pending Applications">{{profile.pendingInternApplications}}</span>
          </div>
        </div>
      </div>
    </div>

    
    <div class="col-12 col-sm-6 col-md-4 col-lg-2 mt-2 " *ngIf="canViewTicketManagement">
      <div class="card pointer" (click)="openLink('/ticket-types/list')">
        <div class="card-body">
          <div class="text-center font-big-icon">
            <i class="fas fa-ticket"></i><br />
            Ticket Types
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
