import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { State } from 'src/app/core/store';
import { authenticateUser, authenticateCode, authenticateToken, setReturnUrl, logout, logNavigation } from 'src/app/core/store/account/account.actions';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';

@Component({
  templateUrl: 'login.component.html'
})
export class LoginComponent extends BaseComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  checkingCode = false;
  submitted = false;
  returnUrl: string;
  navigationLogged: boolean = false;


  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private store: Store<State>) {
    super();
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.subs.push(
      this.store.select(s => s.accountState.loading).subscribe(result => {
        this.loading = result;
      }),
      this.store.select(s => s.accountState.checkingCode).subscribe(result => {
        this.checkingCode = result;
      })
    );

    // reset login status
    this.store.dispatch(logout());

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams.returnUrl;// || '/';
    if (this.returnUrl) {
      this.store.dispatch(setReturnUrl({ returnUrl: this.returnUrl }));
    }

    const code = this.route.snapshot.queryParams.code;
    if (code) {
      this.store.dispatch(authenticateCode({ code }));
    }
    const token = this.route.snapshot.queryParams.token;
    if (token) {
      this.store.dispatch(authenticateToken({ token }));
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    
    
    this.store.dispatch(authenticateUser({ username: this.f.username.value, pass: this.f.password.value }));
  
  }
}
