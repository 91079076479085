import { SelectItem } from 'primeng/api';
import { loadManageSelfcareCategory, updateManageSelfcareCategoryForm, saveManageSelfcareCategory, clearManageSelfcareCategory, loadManageSelfcarePlanner, saveManageSelfcareCategorySuccess } from './../../../../../core/store/manage-selfcare/manage-selfcare.actions';
import { manageSelfcareStateReducer, ManageSelfcareState, initSelfcareCategoryForm } from './../../../../../core/store/manage-selfcare/manage-selfcare.reducer';
import { SelfCareQuestionModel } from './../../../../../models/selfcareModels';
import { SelfCareCategoryModel } from 'src/app/models/selfcareModels';
import { loadCampuses } from 'src/app/core/store/payroll-approval/payroll-approval.actions';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../../core/store';
import { EmailValidator, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { markFormGroupTouched, getAllControlsErrors, updateFormGroupValidity, deepClone } from 'src/app/helpers/utils';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { ActivatedRoute } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { AlertService } from 'src/app/shared/services/alert.service';
import { loadOnboardingUploadTypes } from 'src/app/core/store/onboarding/onboarding.actions';

@Component({
  selector: 'app-selfcare-manage-category',
  templateUrl: './selfcare-manage-category.component.html',
  styleUrls: ['./selfcare-manage-category.component.scss']
})
export class SelfcareManageCategoryComponent extends BaseComponent implements OnInit, OnDestroy {

  category: SelfCareCategoryModel;
  form: FormGroup;
  questionForm: FormGroup;
  categoryID: number;
  questions: SelfCareQuestionModel[] = [];
  questionToAdd: SelfCareQuestionModel = {} as SelfCareQuestionModel;
  openAddQuestion;
  catImages: SelectItem[] = [
    { label: 'Emotional.png', value: 'Emotional.png' },
    { label: 'Emotional.png', value: 'Social.png' },
    { label: 'Emotional.png', value: 'Financial.png' },
    { label: 'Emotional.png', value: 'Physical.png' },
    { label: 'Emotional.png', value: 'Spiritual.png' },
    { label: 'Emotional.png', value: 'Family.png' },
    { label: 'Emotional.png', value: 'Intellectual.png' },
    { label: 'Emotional.png', value: 'Career.png' }
  ];

  plannerSectionItemList: SelectItem[] = [
    { label: '-', value: null },
  ];

  questionTypeList: SelectItem[] = [
    { label: 'Simple Text', value: 0 },
    { label: 'Select one', value: 1 },
    { label: 'Select many', value: 2 },
  ];
  loading = false;

  uploadedDocTypes: SelectItem[] = [
    { label: '-', value: null },];

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private route: ActivatedRoute,
    private alertService: AlertService) {
    super();
  }

  ngOnInit() {
    this.store.dispatch(clearManageSelfcareCategory());
    this.route.params.subscribe(params => {
      if (params.categoryID) {
        this.categoryID = params.categoryID;
        this.store.dispatch(loadManageSelfcareCategory({ categoryID: params.categoryID }));
      } else {
        this.store.dispatch(clearManageSelfcareCategory());
        this.category = {} as SelfCareCategoryModel;
        this.initForm();
      }
    });
    this.subs.push(
      this.store.select(s => s.manageSelfcareState.category).subscribe(category => {
        if (category) {
          this.category = deepClone(category);
          this.questions = this.category.questions ? this.category.questions : [];
          if (!this.form) {
            this.initForm();
          }
        }
      }),
      this.store.select(s => s.manageSelfcareState.loading).subscribe(loading => {
        this.loading = loading;
      }),
      this.store.select(s => s.onboardingState.uploadTypes).subscribe(uploadTypes => {
        if (uploadTypes) {
          this.uploadedDocTypes = [{ label: '-', value: null }];
          this.uploadedDocTypes.push(...uploadTypes.map(x => ({ label: x.docType, value: x.id })));
        }
      }),
      this.store.select(s => s.manageSelfcareState.plannerSections).subscribe(plannerSections => {
        if (plannerSections) {
          this.plannerSectionItemList = [{ label: '-', value: null }];
          for (const section of plannerSections) {
            for (const item of section.items) {
              this.plannerSectionItemList.push(
                { label: section.sectionTitle + (item.sectionItem ? (':' + item.sectionItem) : ''), value: item.plannerSectionItemID });
            }
          }
        }
      }),

      this.actions$.pipe(
        ofType(saveManageSelfcareCategorySuccess),
        tap(action => {
          this.alertService.success('Categroy Saved');
          this.form = null;
          this.store.dispatch(loadManageSelfcareCategory({ categoryID: this.categoryID }));
        })
      ).subscribe(),
    );
    this.store.dispatch(loadManageSelfcarePlanner());

    this.store.dispatch(loadOnboardingUploadTypes());
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.store.dispatch(clearManageSelfcareCategory());
  }

  initForm() {
    this.form = initSelfcareCategoryForm(this.category);
    this.subs.push(
      this.form.valueChanges.subscribe(formValues => {
        const formErrors = getAllControlsErrors(this.form);
        const categoryValues = this.form.get('category').value;
        const questionValues = this.form.get('questions').value;
        this.store.dispatch(
          updateManageSelfcareCategoryForm({ categoryValues: categoryValues, questionValues: questionValues, formErrors }));
      }));
  }

  save() {
    updateFormGroupValidity(this.form);
    if (this.form.valid) {
      this.store.dispatch(saveManageSelfcareCategory({ category: this.category }));
    } else {
      markFormGroupTouched(this.form);
    }
  }

  removeQuestion(index: number) {
    const questionsArray: FormArray = this.form.get('questions') as FormArray;
    questionsArray.removeAt(index);
  }


  addQuestion() {
    const questionsArray: FormArray = this.form.get('questions') as FormArray;
    questionsArray.push(new FormGroup({
      questionID: new FormControl(null),
      questionText: new FormControl('', [Validators.required, Validators.maxLength(2000)]),
      displayText: new FormControl('', [Validators.maxLength(2000)]),
      categoryOrderNr: new FormControl(null, [Validators.required]),
      plannerSectionItemID: new FormControl(null),
      allowPublic: new FormControl(true),
      questionType: new FormControl(0),
      questionOptions: new FormControl(''),
    }));
  }

  changedColor(event) {
    this.form.get('category.mainColor').setValue(event.value);
  }

  changedColorInput(event) {
    this.form.get('category.mainColor').setValue(event.target.value);
  }


  getControls(control) {
    return control.controls;
  }

}
