<div class="card mb-2">
  <div class="card-body">
    <h2 class="">Manage Form Assignment to Job Titles</h2>
    <div class="row">
      <div class="col-12 col-lg-6">
        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Form</b>
          </div>
          <div class="col-8 ">
            <i *ngIf="!formOptions" class="material-icons rotate text-muted">loop</i>
            <p-dropdown styleClass="form-control mt-2 w-100" [options]="formOptions" placeholder="Select form..." [ngModelOptions]="{standalone: true}" [(ngModel)]="formJobTitleAssignment.id" (ngModelChange)="changedForm($event)"></p-dropdown>
          </div>

          <i *ngIf="updatingFormJobTitleAssignment" class="material-icons rotate text-muted">loop</i>
          <ng-container *ngIf="formJobTitleAssignment.id && !updatingFormJobTitleAssignment">
            <div class="col-4 d-flex align-items-center">
              <b>Assignment option</b>
            </div>
            <div class="col-8 ">
              <p-dropdown styleClass="form-control mt-2 w-100" [options]="assignOptions" placeholder="Select option..." [ngModelOptions]="{standalone: true}" [(ngModel)]="assignOption"></p-dropdown>
            </div>

            <ng-container *ngIf="assignOption==1">
              <div class="col-4 d-flex align-items-center">
                <b>Assign to job titles</b>
              </div>
              <div class="col-8 ">
                <p-multiSelect autofocusFilter="true"
                               class="w-100" styleClass="w-100 d-flex  mt-2" inputStyleClass="form-control" [options]="jobTitlesOptions" placeholder="Select ..." [ngModelOptions]="{standalone: true}" [(ngModel)]="formJobTitleAssignment.formAssignJobTitles"></p-multiSelect>
              </div>
            </ng-container>
            <ng-container *ngIf="assignOption==2">
              <div class="col-4 d-flex align-items-center">
                <b>Assign to users not in job titles</b>
              </div>
              <div class="col-8 ">
                <p-multiSelect autofocusFilter="true"
                               class="w-100" styleClass="w-100 d-flex mt-2" inputStyleClass="form-control" [options]="jobTitlesOptions" placeholder="Select ..." [ngModelOptions]="{standalone: true}" [(ngModel)]="formJobTitleAssignment.formAssignNotJobTitles"></p-multiSelect>
              </div>
            </ng-container>

            <div class="col-4 d-flex align-items-center">
              <b>Required option</b>
            </div>
            <div class="col-8 ">
              <p-dropdown styleClass="form-control mt-2 w-100" [options]="requiredOptions" placeholder="Select option..." [ngModelOptions]="{standalone: true}" [(ngModel)]="requiredOption"></p-dropdown>
            </div>

            <ng-container *ngIf="requiredOption==1">
              <div class="col-4 d-flex align-items-center">
                <b>Required by job titles</b>
              </div>
              <div class="col-8 ">
                <p-multiSelect autofocusFilter="true"
                               class="w-100" styleClass="w-100 d-flex mt-2" inputStyleClass="form-control" [options]="jobTitlesOptions" placeholder="Select ..." [ngModelOptions]="{standalone: true}" [(ngModel)]="formJobTitleAssignment.formRequiredJobTitles"></p-multiSelect>
              </div>
            </ng-container>
            <ng-container *ngIf="requiredOption==2">
              <div class="col-4 d-flex align-items-center">
                <b>Required by users not in job titles</b>
              </div>
              <div class="col-8 ">
                <p-multiSelect autofocusFilter="true"
                               class="w-100" styleClass="w-100 d-flex mt-2" inputStyleClass="form-control" [options]="jobTitlesOptions" placeholder="Select ..." [ngModelOptions]="{standalone: true}" [(ngModel)]="formJobTitleAssignment.formRequiredNotJobTitles"></p-multiSelect>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
    <button class="btn btn-primary mt-3" id="saveButton" [disabled]="updatingFormJobTitleAssignment || !this.formJobTitleAssignment.id" (click)="save()">Save</button>
  </div>
</div>
