import { Injectable } from "@angular/core";
import { EmployeeService } from '../../services/employee.service';
import { State } from '..';
import { Store } from '@ngrx/store';
import { of, pipe } from 'rxjs';
import { map, switchMap, tap, catchError, filter, take } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { editPost, editPostSuccess, editPostWithFiles, loadPinnedPosts, loadPinnedPostsSuccess, 
  loadPosts, loadPostsSuccess, filterFeed, filterFeedSuccess, saveComment, saveCommentSuccess, 
  savePost, savePostSuccess, savePostWithFiles, saveReaction, saveReactionSuccess, unpinPost, 
  unpinPostSuccess, unpinUserPost, loadUserPinnedPosts, loadUserPinnedPostsSuccess, unpinUserPostSuccess, archivePost, archiveComment, archiveCommentSuccess, archivePostSuccess, refreshFeed, refreshFeedSuccess, deleteReaction, deleteReactionSuccess, unpinComment, unpinCommentSuccess, editComment, editCommentSuccess, pinPost, pinPostSuccess, pinUserPost, pinUserPostSuccess, startRefreshing, setRefreshFilter, pinCommentSuccess, pinComment } from "./critical-news-feed.action";
import { CriticalNewsFeedService } from "../../services/critical-news-feed.service";
import { AlertService } from "src/app/shared/services/alert.service";
import { errorHappened } from "../profile/profile.actions";


@Injectable()
export class CriticalNewsFeedEffects {
  constructor(private actions$: Actions,
    private criticalNewsFeedService: CriticalNewsFeedService,
    private alertService: AlertService,
    private store: Store<State>) { }

      getState(store: Store<State>): State {
        let state: State;
  
        store.pipe(take(1)).subscribe(s => state = s);
  
        return state;
      }

      loadPosts = createEffect(() => this.actions$.pipe(
        ofType(loadPosts),
        switchMap(action => {
          return this.criticalNewsFeedService.loadPosts(action.filter).pipe(
            map(posts => loadPostsSuccess({ posts })),
            catchError(err => {
                return of(errorHappened({ err }))
            }));
        })
      ));

      filterFeed = createEffect(() => this.actions$.pipe(
        ofType(filterFeed),
        switchMap(action => {
          return this.criticalNewsFeedService.filterFeed(action.filter).pipe(
            map(posts => filterFeedSuccess({ posts })),
            catchError(err => {
                return of(errorHappened({ err }))
            }));
        })
      ));
      
      refreshFeed = createEffect(() => this.actions$.pipe(
        ofType(refreshFeed),
        switchMap(action => {
          return this.criticalNewsFeedService.refreshFeed(action.filter).pipe(
            map(posts => refreshFeedSuccess({ posts })),
            catchError(err => {
                return of(errorHappened({ err }))
            }));
        })
      ));

      refreshFeedSuccess = createEffect(() => this.actions$.pipe(
        ofType(refreshFeedSuccess),
        tap(action => {
          let state = this.getState(this.store);
          let syncActive = state.criticalNewsFeedState.syncActive;
          if (syncActive === true){
            let filter = state.criticalNewsFeedState.filter;
            let date = new Date();
            let newFilter = {
              viewLength: filter.viewLength,
              filterType: filter.filterType,
              filterValue: filter.filterValue,
              sortBy: filter.sortBy,
              dateFilterValues: filter.dateFilterValues,
              syncDate: date.toISOString(),
            }
            setTimeout(() => {this.store.dispatch(refreshFeed({filter: newFilter}))}, 600000);
            
            this.store.dispatch(setRefreshFilter({filter: newFilter}));
          }
        })
      ), { dispatch: false });
      
      startRefreshing = createEffect(() => this.actions$.pipe(
        ofType(startRefreshing),
        tap(action => {
          let state = this.getState(this.store);
          let refreshCountController = state.criticalNewsFeedState.refreshCountController;
          let syncActive = state.criticalNewsFeedState.syncActive;
          if (syncActive === true){
            let filter = state.criticalNewsFeedState.filter;
            setTimeout(() => {this.store.dispatch(refreshFeed({filter: filter}))}, 600000);
          }
        })
      ), { dispatch: false });

      loadPinnedPosts = createEffect(() => this.actions$.pipe(
        ofType(loadPinnedPosts),
        switchMap(action => {
          return this.criticalNewsFeedService.loadPinnedPosts().pipe(
            map(posts => loadPinnedPostsSuccess({ posts })),
            catchError(err => {
                return of(errorHappened({ err }))
            }));
        })
      ));

      loadUserPinnedPosts = createEffect(() => this.actions$.pipe(
        ofType(loadUserPinnedPosts),
        switchMap(action => {
          return this.criticalNewsFeedService.loadUserPinnedPosts().pipe(
            map(posts => loadUserPinnedPostsSuccess({ posts })),
            catchError(err => {
                return of(errorHappened({ err }))
            }));
        })
      ));

      unpinPost = createEffect(() => this.actions$.pipe(
        ofType(unpinPost),
        switchMap(action => {
          return this.criticalNewsFeedService.unpinPost(action.id).pipe(
            map(posts => unpinPostSuccess({ posts })),
            catchError(err => {
                return of(errorHappened({ err }))
            }));
        })
      ));

      pinPost = createEffect(() => this.actions$.pipe(
        ofType(pinPost),
        switchMap(action => {
          return this.criticalNewsFeedService.pinPost(action.id).pipe(
            map(posts => pinPostSuccess({ posts })),
            catchError(err => {
                return of(errorHappened({ err }))
            }));
        })
      ));

      pinUserPost = createEffect(() => this.actions$.pipe(
        ofType(pinUserPost),
        switchMap(action => {
          return this.criticalNewsFeedService.pinUserPost(action.id).pipe(
            map(posts => pinUserPostSuccess({ posts })),
            catchError(err => {
                return of(errorHappened({ err }))
            }));
        })
      ));

      unpinUserPost = createEffect(() => this.actions$.pipe(
        ofType(unpinUserPost),
        switchMap(action => {
          return this.criticalNewsFeedService.unpinUserPost(action.id).pipe(
            map(posts => unpinUserPostSuccess({ posts })),
            catchError(err => {
                return of(errorHappened({ err }))
            }));
        })
      ));

      pinComment = createEffect(() => this.actions$.pipe(
        ofType(pinComment),
        switchMap(action => {
          return this.criticalNewsFeedService.pinComment(action.id, action.postId).pipe(
            map(comment => pinCommentSuccess({ comment })),
            catchError(err => {
                return of(errorHappened({ err }))
            }));
        })
      ));

      archivePost = createEffect(() => this.actions$.pipe(
        ofType(archivePost),
        switchMap(action => {
          return this.criticalNewsFeedService.archivePost(action.id).pipe(
            map(post => archivePostSuccess({ post })),
            catchError(err => {
                return of(errorHappened({ err }))
            }));
        })
      ));

      archiveComment = createEffect(() => this.actions$.pipe(
        ofType(archiveComment),
        switchMap(action => {
          return this.criticalNewsFeedService.archiveComment(action.id).pipe(
            map(comment => archiveCommentSuccess({ comment })),
            catchError(err => {
                return of(errorHappened({ err }))
            }));
        })
      ));

      savePost = createEffect(() => this.actions$.pipe(
        ofType(savePost),
        switchMap(action => {
          return this.criticalNewsFeedService.savePost(action.post).pipe(
            map(post => savePostSuccess({ post })),
            catchError(err => {
                return of(errorHappened({ err }))
            }));
        })
      ));
          
      savePostSuccess = createEffect(() => this.actions$.pipe(
        ofType(savePostSuccess),
        tap(action => {
          if (action.post.isPinned){
            this.store.dispatch(loadPinnedPosts());
          }
          if (action.post.isPinnedByUser){
            this.store.dispatch(loadUserPinnedPosts());
          }
        })
      ), { dispatch: false });

      savePostWithFiles = createEffect(() => this.actions$.pipe(
        ofType(savePostWithFiles),
        switchMap(action => {
          return this.criticalNewsFeedService.savePostWithFiles(action.post, action.files).pipe(
            map(post => savePostSuccess({ post })),
            catchError(err => {
                return of(errorHappened({ err }))
            }));
        })
      ));
      
      editPost = createEffect(() => this.actions$.pipe(
        ofType(editPost),
        switchMap(action => {
          return this.criticalNewsFeedService.savePost(action.post).pipe(
            map(post => editPostSuccess({ post })),
            catchError(err => {
                return of(errorHappened({ err }))
            }));
        })
      ));
      
      editPostSuccess = createEffect(() => this.actions$.pipe(
        ofType(editPostSuccess),
        tap(action => {
          if (action.post.isPinned){
            this.store.dispatch(loadPinnedPosts());
          }
          if (action.post.isPinnedByUser){
            this.store.dispatch(loadUserPinnedPosts());
          }
        })
      ), { dispatch: false });

      editPostWithFiles = createEffect(() => this.actions$.pipe(
        ofType(editPostWithFiles),
        switchMap(action => {
          return this.criticalNewsFeedService.savePostWithFiles(action.post, action.files).pipe(
            map(post => editPostSuccess({ post })),
            catchError(err => {
                return of(errorHappened({ err }))
            }));
        })
      ));

      saveReaction = createEffect(() => this.actions$.pipe(
        ofType(saveReaction),
        switchMap(action => {
          return this.criticalNewsFeedService.saveReaction(action.reaction).pipe(
            map(reaction => saveReactionSuccess({ reaction })),
            catchError(err => {
                return of(errorHappened({ err }))
            }));
        })
      ));

      deleteReaction = createEffect(() => this.actions$.pipe(
        ofType(deleteReaction),
        switchMap(action => {
          return this.criticalNewsFeedService.deleteReaction(action.reaction).pipe(
            map(reaction => deleteReactionSuccess({ reaction })),
            catchError(err => {
                return of(errorHappened({ err }))
            }));
        })
      ));

      saveComment = createEffect(() => this.actions$.pipe(
        ofType(saveComment),
        switchMap(action => {
          return this.criticalNewsFeedService.saveComment(action.comment).pipe(
            map(comment => saveCommentSuccess({ comment })),
            catchError(err => {
                return of(errorHappened({ err }))
            }));
        })
      ));

      editComment = createEffect(() => this.actions$.pipe(
        ofType(editComment),
        switchMap(action => {
          return this.criticalNewsFeedService.saveComment(action.comment).pipe(
            map(comment => editCommentSuccess({ comment })),
            catchError(err => {
                return of(errorHappened({ err }))
            }));
        })
      ));

      unpinComment = createEffect(() => this.actions$.pipe(
        ofType(unpinComment),
        switchMap(action => {
          return this.criticalNewsFeedService.unpinComment(action.id).pipe(
            map(comment => unpinCommentSuccess({comment})),
            catchError(err => {
                return of(errorHappened({ err }))
            }));
        })
      ));
}