import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { JobApplicationsModel } from 'src/app/models/jobApplicationsModel';
import { PagingResultsModel } from 'src/app/models/pagingResultsModel';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Router } from '@angular/router';
import { deepClone, getAllControlsErrors } from 'src/app/helpers/utils';
import { MultiSelect } from 'primeng/multiselect';
import {NgbModal, ModalDismissReasons, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { archiveJobApplication, getCoverLetter, getJobApplications, getResume, loadCampuses, loadJobApplicationForms, saveAsProspectiveCandidate} from 'src/app/core/store/job-applications/job-applications.action';
import * as XLSX from 'xlsx';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { JobTitleHasJobApplicationFormModel, ScreeningInterviewModel } from 'src/app/models/screeningInterviewModel';

@Component({
  selector: 'app-application-dashboard',
  templateUrl: './application-dashboard.component.html',
  styleUrls: ['./application-dashboard.component.scss']
})
export class ApplicationDashboardComponent extends BaseComponent implements OnInit {

  modalReference: any;
  modalOptions: NgbModalOptions;
  screeningModalOptions: NgbModalOptions;
  jobApplications: PagingResultsModel<JobApplicationsModel>;
  excelApplications: PagingResultsModel<JobApplicationsModel>;
  convertingApplication: JobApplicationsModel;
  canEdit: boolean;
  canView: boolean;
  loadingjobApplications = false;
  filter: any = {};
  loading = false;
  archiveReason: string = "";
  firstName: string;
  lastName: string;

  jobTitlesList = [
    { label: '', value: 0 },
    { label: 'Behavior Technician', value: 'Behavior Technician' },
    { label: 'Mental Health Clinician I (CSW, ACMHC, AMFT)', value: 'Mental Health Clinician I (CSW, ACMHC, AMFT)' },
    { label: 'Mental Health Clinician II (LCSW, CMHC, LMFT)', value: 'Mental Health Clinician II (LCSW, CMHC, LMFT)' },
    { label: 'Behavior Clinician I (BCaBA)', value: 'Behavior Clinician I (BCaBA)' },
    { label: 'Behavior Clinician II (BCBA, BCBA-D)', value: 'Behavior Clinician II (BCBA, BCBA-D)' },
    { label: 'APRN/Dr/Ph.D/Psy.D', value: 'APRN/Dr/Ph.D/Psy.D' },
    { label: 'Administrative Assistant', value: 'Administrative Assistant' },
    { label: 'Medical Assistant', value: 'Medical Assistant' },
    { label: 'Operations', value: 'Operations'},
  ];
  campusesList = [{ label: 'loading...', value: undefined }];

  employeeNameFilter;
  emailFilter;
  createdDateFilter;
  positionsFilter;
  createdDateOptionFilter;
  removedFilter;
  regionFilter;
  onlyReferralsFilter;
  referredByFilter;
  hiredFilter;

  showHideString: string = "Hide";
  hideFilter: boolean = false;

  closeResult: string;

  appDate = true;
  region = true;
  position = true;
  name = true;
  phoneNumber = true;
  email = true;
  callbackTime = true;
  source = true;
  referredBy = true;
  keyQuestions = false;

  yesNoAllOptions = [
    { label: 'All', value: null },
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  datesOptions = [
    { label: '', value: null },
    { label: 'Last 30 days', value: 30 },
    { label: 'Last 60 days', value: 60 },
    { label: 'Last 90 days', value: 90 },
    { label: 'Custom', value: 0 },
  ];
  jobApplicationID: any;
  programID: number;

  jobTitleForms: JobTitleHasJobApplicationFormModel[] = [];

  constructor(
    private actions$: Actions,
    private alertService: AlertService,
    private store: Store<State>,
    private router: Router,
    private modalService: NgbModal,
    ) {
    super();
    this.modalOptions = {
      backdrop:'static',
      backdropClass:'customBackdrop'
    }
    this.screeningModalOptions = {
      backdrop:'static',
      backdropClass:'customBackdrop',
      size: 'lg'
    }
  }

  ngOnInit() {
    this.filter.filters = {};
    this.subs.push(
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.canEdit = permissionIDs && !!permissionIDs.find(p => p === 7500 || p === 7501 || p === 7502);
      }),
      this.store.select(s => s.jobApplicationsState.jobApplications).subscribe(jobApplications => {
        if (jobApplications) {
          this.jobApplications = jobApplications;
          this.excelApplications = jobApplications;
        }
      }),
      this.store.select(s => s.jobApplicationsState.loadingJobApplications).subscribe(loadingJobApplications => {
        this.loading = loadingJobApplications;
      }),
      this.store.select(s => s.jobApplicationsState.phoneInterview).subscribe(interview => {
        if (interview == null){
          this.loadPage(this.filter);
        }
      }),
      this.store.select(s => s.jobApplicationsState.pageAltered).subscribe(count => {
        if (count > 0){
          this.loadPage(this.filter);
        }
      }),
      this.store.select(s => s.jobApplicationsState.campuses).subscribe(campuses => {
        const campusesList = campuses && campuses.length > 0 ? campuses.map(c => ({ label: c.city, value: c.id }))
          : [{ label: 'loading...', value: undefined }];
        this.campusesList = campusesList;
        this.campusesList.unshift({label: "", value: ""})
      }),

      this.store.select(s => s.jobApplicationsState.jobTitleForms).subscribe(jobTitleForms => {
        if (jobTitleForms != null) {
          this.jobTitleForms = jobTitleForms;

        }
      }),
    );
    this.store.dispatch(loadCampuses());
    this.store.dispatch(loadJobApplicationForms());
    this.loadPage({filters: {}});
  }

  changedFilters() {
    this.loadPage(this.filter);
  }

  clearFilters(){
    this.employeeNameFilter = null;
    this.emailFilter = null;
    this.createdDateFilter = null;
    this.positionsFilter = null;
    this.createdDateOptionFilter = null;
    this.removedFilter = null;
    this.regionFilter = null;
    this.onlyReferralsFilter = null;
    this.referredByFilter = null;
    this.hiredFilter = null;
    sessionStorage.clear();
    this.loadPage(this.filter);
  }

  openLink(path) {
    this.router.navigate([path]);
  }

  changedDateFilters() {
    if (this.createdDateFilter[1]){
      this.loadPage(this.filter);
    }
  }

  toggleHideFilter() {
    if (this.hideFilter) {
      this.hideFilter = false;
      this.showHideString = "Hide";
    }
    else {
      this.hideFilter = true;
      this.showHideString = "Show";
    }
  }


  multiSelectChange() {
    if (this.positionsFilter.length < 1){
      this.positionsFilter = null;
    }
    this.loadPage(this.filter);
  }

  openMultiSelect(multiselect: MultiSelect) {
    multiselect.show();
  }

  getResume(application: any){
    this.store.dispatch(getResume({applicationID: application.id }));
  }
  getCoverLetter(application: any){
    this.store.dispatch(getCoverLetter({applicationID: application.id }));
  }

  decisionApplicant(app){
    this.alertService.confirm("Confirm", "Are you sure you want to move " + app.firstName + " " + app.lastName + " To the Prospective Candidates Queue?")
    .subscribe(
      anwser => {
        if (anwser) {
          this.store.dispatch(saveAsProspectiveCandidate({ app: app }));
          this.modalReference.close();
        }
      }, error => {
      }
    );
  }

  open(content, app) {
    this.convertingApplication = deepClone(app);
    this.modalReference = this.modalService.open(content, this.modalOptions);
    this.modalReference.result.then((result) => {
      if(result == "Archive"){
        this.archiveApplication(app.id, this.archiveReason)
      }
    }, (reason) => {
    });
  }

  openPhoneScreening(content, app) {
    this.convertingApplication = deepClone(app);
    this.convertingApplication.ID = app.id;
    this.jobApplicationID = app.id;
    this.programID = app.programID;
    this.firstName = app.firstName;
    this.lastName = app.lastName;
    this.modalReference = this.modalService.open(content, this.screeningModalOptions)
    this.modalReference.result.then((result) => {
    }, (reason) => {
    });
  }

  archiveApplication(id: number, reason: string) {
    this.store.dispatch(archiveJobApplication({jobAppID: id, archiveReason: reason }));
    this.archiveReason = "";
    setTimeout(() => {this.loadPage(this.filter)}, 3000);
  }

  editColumns(columnValue) {
    if (columnValue == "appDate") { this.appDate = !this.appDate }
    else if (columnValue == "region") { this.region = !this.region }
    else if (columnValue == "position") { this.position = !this.position }
    else if (columnValue == "name") { this.name = !this.name }
    else if (columnValue == "phoneNumber") { this.phoneNumber = !this.phoneNumber }
    else if (columnValue == "email") { this.email = !this.email }
    else if (columnValue == "callbackTime") { this.callbackTime = !this.callbackTime }
    else if (columnValue == "source") { this.source = !this.source }
    else if (columnValue == "referredBy") { this.referredBy = !this.referredBy }
    else if (columnValue == "keyQuestions") { this.keyQuestions = !this.keyQuestions }
  }

  simplifyData(){
    const apps = this.excelApplications.result;
    const array = [];

    for(let app in apps){
      array.push({
        "App Date": apps[app]["applicationDate"] ? new Date(apps[app]["applicationDate"]).toLocaleDateString() : null ,
        "Region":  apps[app]["regionName"] ?  apps[app]["regionName"] : null ,
        "Position":  apps[app]["position"] ?  apps[app]["position"] : null ,
        "Name":  apps[app]["name"] ?  apps[app]["applicationDate"] +  apps[app]["lastName"] : null ,
        "Phone Number":  apps[app]["phoneNumber"] ?  apps[app]["phoneNumber"] : null ,
        "Email":  apps[app]["email"] ?  apps[app]["email"] : null ,
        "Callback Time":  apps[app]["contactTime"] ?  apps[app]["contactTime"] : null ,
        "Hiring Source":  apps[app]["heardOfUsThrough"] ?  apps[app]["heardOfUsThrough"] : null ,
        "Referred By":  apps[app]["referredBy"] ?  apps[app]["referredBy"] : null ,
      })
    }
    return array;
  }

  exportExcel() {
    let array = this.simplifyData()
    console.log(array)
    const fileName = 'exportapplicantsview.xlsx';

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(array);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Applicants');

    XLSX.writeFile(wb, fileName);
  }

  loadPage(event) {
    if (!this.loading) {
      this.loading = true;
      this.filter = deepClone(event);
      this.filter.filters.employeeNameFilter = { Name: 'employeeNameFilter', Value: { value: this.employeeNameFilter } };
      this.filter.filters.emailFilter = { Name: 'emailFilter', Value: { value: this.emailFilter } };
      this.filter.filters.positionsFilter = { Name: 'positionsFilter', Value: { value: this.positionsFilter ? this.positionsFilter.join(',') : null } };
      this.filter.filters.removedFilter = { Name: 'removedFilter', Value: { value: this.removedFilter ? 'true' : 'false' } };
      this.filter.filters.hiredFilter = { Name: 'hiredFilter', Value: { value: this.hiredFilter ? 'true' : 'false' } };
      this.filter.filters.regionFilter = { Name: 'regionFilter', Value: { value: this.regionFilter } };
      this.filter.filters.onlyReferralsFilter = { Name: 'onlyReferralsFilter', Value: {value: this.onlyReferralsFilter ? 'true' : 'false' }};
      this.filter.filters.referredByFilter = { Name: 'referredByFilter', Value: {value: this.referredByFilter}};
      if (this.createdDateOptionFilter > 0) {
        const today = new Date();
        const tomorrow = new Date(new Date().setDate(today.getDate() + 1));
        const priorDate = new Date(new Date().setDate(today.getDate() - this.createdDateOptionFilter));
        this.filter.filters.createdDateFilterStart = { Name: 'createdDateFilterStart', Value: { value: priorDate.toISOString() } };
        this.filter.filters.createdDateFilterEnd = { Name: 'createdDateFilterEnd', Value: { value: tomorrow.toISOString() } };
      } else {
        if (this.createdDateFilter) {
          this.filter.filters.createdDateFilterStart = { Name: 'createdDateFilterStart', Value: { value: this.createdDateFilter[0] } };
          this.filter.filters.createdDateFilterEnd = { Name: 'createdDateFilterEnd', Value: { value: this.createdDateFilter[1] } };
        } else {
          this.filter.filters.createdDateFilterStart = { Name: 'createdDateFilterStart', Value: { value: null } };
          this.filter.filters.createdDateFilterEnd = { Name: 'createdDateFilterEnd', Value: { value: null } };
        }
      }

      this.store.dispatch(getJobApplications({
        filter: deepClone(this.filter)
      }));
    }
  }

  hasScreeningForm(jobApplication: JobApplicationsModel) {
    return !!this.jobTitleForms.find(x => x.jobTitle === jobApplication.position);
  }
}
