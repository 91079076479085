import { deleteBenefitsDependent, deleteBenefitsDependentSuccess, saveBenefitsDependent, saveBenefitsDependentSuccess } from './../../../../core/store/benefits/benefits.actions';
import { BaseComponent } from './../../../../core/abstracts/baseComponent';
import { BenefitsState, initBenefitsDependentForm } from './../../../../core/store/benefits/benefits.reducer';
import { BenefitPlanModel, BenefitsDependentModel } from './../../../../models/benefitsModels';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { Actions, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { MultiSelect } from 'primeng/multiselect';
import { AlertService } from '../../../../shared/services/alert.service';
import { deepClone, getAllControlsErrors, markFormGroupTouched, updateFormGroupValidity } from '../../../../helpers/utils';
import { loadBenefitsDependents, loadBenefitsPlans, updateBenefitsDependentForm } from '../../../../core/store/benefits/benefits.actions';
import { FormGroup } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-dependents',
  templateUrl: './dependents.component.html',
  styleUrls: ['./dependents.component.scss']
})
export class DependentsComponent extends BaseComponent implements OnInit {

  @Input() readOnly: boolean = false;
  benefitsDependents: BenefitsDependentModel[];
  loadingBenefitsDependent = false;
  loading = false;
  openDependentInfo = false;
  benefitsDependentInfo: BenefitsDependentModel = {} as BenefitsDependentModel;
  form: FormGroup;
  relationshipOptions = [
    { label: 'Spouse', value: 'Spouse' },
    { label: 'Child', value: 'Child' },
    { label: 'Legal Guardian', value: 'Legal Guardian' },
  ];

  suffixOptions = [
    { label: '', value: '' },
    { label: 'CPA', value: 'CPA' },
    { label: 'CRNP', value: 'CRNP' },
    { label: 'D.O.', value: 'D.O.' },
    { label: 'DDS', value: 'DDS' },
    { label: 'DMD', value: 'DMD' },
    { label: 'II', value: 'II' },
    { label: 'III', value: 'III' },
    { label: 'IV', value: 'IV' },
    { label: 'JD', value: 'JD' },
    { label: 'Jr.', value: 'Jr.' },
    { label: 'MD', value: 'MD' },
    { label: 'PA-C', value: 'PA-C' },
    { label: 'PhD', value: 'PhD' },
    { label: 'RN', value: 'RN' },
    { label: 'Sr.', value: 'Sr.' },
    { label: 'V', value: 'V' },

  ];

  genderOptions: SelectItem[] = [
    { label: 'Male', value: 1 },
    { label: 'Female', value: 0 }];

  constructor(
    private actions$: Actions,
    private alertService: AlertService,
    private store: Store<State>,
    private router: Router,
  ) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.benefitsState.benefitsDependentsList).subscribe(benefitsDependentsList => {
        if (benefitsDependentsList) {
          this.benefitsDependents = deepClone(benefitsDependentsList.filter(x => !x.isDeleted));
        }
      }),

      this.store.select(s => s.benefitsState.loadingBenefitsDependents).subscribe(loadingBenefitsDependents => {
        this.loadingBenefitsDependent = loadingBenefitsDependents;
      }),

      this.store.select(s => s.benefitsState.benefitsDependentInfo).subscribe(benefitsDependentInfo => {
        if (benefitsDependentInfo) {
          this.benefitsDependentInfo = benefitsDependentInfo;
        }
      }),
      this.actions$.pipe(
        ofType(saveBenefitsDependentSuccess),
        tap(action => {
          this.alertService.success('Benefits Dependent saved!');
          this.store.dispatch(loadBenefitsDependents());
          this.openDependentInfo = false;
          this.benefitsDependentInfo = {} as BenefitsDependentModel;
          this.initForm();
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(deleteBenefitsDependentSuccess),
        tap(action => {
          this.alertService.success('Benefits Dependent removed!');
          this.store.dispatch(loadBenefitsDependents());
          this.openDependentInfo = false;
          this.benefitsDependentInfo = {} as BenefitsDependentModel;
          this.initForm();
        })
      ).subscribe(),


    );

    this.store.dispatch(loadBenefitsDependents());
  }

  initForm() {
    this.form = initBenefitsDependentForm(this.benefitsDependentInfo);
    this.subs.push(
      this.form.valueChanges.subscribe(formValues => {
        const formErrors = getAllControlsErrors(this.form);
        this.store.dispatch(updateBenefitsDependentForm({ benefitsDependentValues: formValues, formErrors }));
      }));
  }

  editBenefitsDependent(benefitsDependent: BenefitsDependentModel) {
    this.openDependentInfo = true;
    this.benefitsDependentInfo = benefitsDependent;
    this.initForm();
  }

  deleteBenefitsDependent(benefitsDependent: BenefitsDependentModel) {
    this.store.dispatch(deleteBenefitsDependent({ benefitsDependentId: benefitsDependent.id }));
  }

  newBenefitsDependentInfo() {
    this.openDependentInfo = true;
    this.benefitsDependentInfo = {
      isAddressSame: true
    } as BenefitsDependentModel;
    this.initForm();
  }

  save() {
    updateFormGroupValidity(this.form);
    if (this.form.valid) {
      this.store.dispatch(saveBenefitsDependent({ benefitsDependent: this.benefitsDependentInfo }));
    } else {
      markFormGroupTouched(this.form);
    }
  }
  getAsDate(date) {
    return new Date(date);
  }
}

