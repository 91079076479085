<div *ngIf="canAccessCriticalNewsFeed && (showNewsFeedModal || (screenWidth < 1160 && profile!=null && profile.employee && (!profile?.offerLetter || profile.offerLetter.signed || profile?.offerLetter.existingUser)))" (click)="openNewsFeed($event)" class="news-feed-modal-button d-flex justify-content-center align-items-center pointer">
  <i *ngIf="!showNewsFeedModal" class="fas fa-book"></i>
  <i *ngIf="showNewsFeedModal" class="fas fa-angle-double-right"></i>
</div>
<div class="home-container" autoscroll="true">
  <div *ngIf="profile!=null && profile.offerLetter && !profile.offerLetter.signed && !profile.offerLetter.existingUser">
    <app-offerletter></app-offerletter>
  </div>
  <div class="w-100 text-center flex-grow-1 align-items-center d-flex justify-content-center" *ngIf="!profile">
    <i  class="material-icons rotate text-muted">loop</i>
  </div>
  <div *ngIf="!!profile" class="row ml-1 mr-1">
      <ng-container *ngIf="!(profile!=null && profile.offerLetter && !profile.offerLetter.signed && !profile.offerLetter.existingUser)">
      <div [ngClass]="{'col-6': screenWidth >= 1160 && canViewCriticalNewsFeed && canAccessCriticalNewsFeed && profile!=null && profile.employee && (!profile?.offerLetter || profile.offerLetter.signed || profile?.offerLetter.existingUser), 'col-12': screenWidth < 1160 && profile!=null && profile.offerLetter && !profile.offerLetter.signed && !profile.offerLetter.existingUser}" class="w-100"> <!--[ngClass]="{'col-4': screenWidth >= 1700, 'col-6': screenWidth < 1700 && screenWidth >= 1160 , 'col-12': screenWidth < 1160}"-->
        <div class="w-100 text-center flex-grow-1 align-items-center d-flex justify-content-center" *ngIf="profile && !profile.employee">
          <div *ngIf="profile==null &&!profile.offerLetter">
            This user is not an employee and has no valid offer letter
          </div>
        </div>
        <div class="w-100 text-center flex-grow-1 align-items-center d-flex justify-content-center alert" [ngClass]="caqhDaysToExpire() > 7 ? 'alert-info' : 'alert-warning'" *ngIf="profile && isCaqhQuestionnaireNewer() == false && caqhDaysToExpire() < 14 && caqhDaysToExpire() > 0 ">

          Your CAQH License is expiring in {{caqhDaysToExpire()}} days. Please verify, review, and sign all information. Click <button [class.disabled]="gettingCaqhForm" class="btn btn-link" (click)="fillCaqhForm()">here</button> to verify.
          <i *ngIf="gettingCaqhForm" class="material-icons rotate text-muted">loop</i>


        </div>
        <!--<div class="w-100 text-center flex-grow-1 align-items-center d-flex justify-content-center alert alert-danger" *ngIf="profile">-->
          <div class="w-100 text-center flex-grow-1 align-items-center d-flex justify-content-center alert alert-danger" *ngIf="profile  && isCaqhQuestionnaireNewer() == false && caqhDaysToExpire() && caqhDaysToExpire() <= 0  ">
            Your CAQH License is expired. Please check verify, review, and sign all information. Click <button [class.disabled]="gettingCaqhForm" class="btn btn-link" (click)="fillCaqhForm()">here</button> to verify.
            <i *ngIf="gettingCaqhForm" class="material-icons rotate text-muted">loop</i>

          </div>
          <div *ngIf="profile!=null && profile.employee && (!profile?.offerLetter || profile.offerLetter.signed || profile?.offerLetter.existingUser)">
            <app-profile-header [readonly]="true"></app-profile-header>
          </div>
          <div class="w-100 text-center flex-grow-1 align-items-center d-flex justify-content-center" *ngIf="profile && profile.driversLicenseExpired">
            <span class="m-2 text-danger">Your most recent drivers license is expired. Please upload a new current license.</span>
          </div>
        <div class="alertHeader">
          <small *ngIf="returnEquipmentAlert != null" class="alert alert-warning returnEquipmentAlert">{{ returnEquipmentAlert }}</small>
        </div>
        <!-- <div *ngIf="screenWidth <= 1700" class="d-flex w-100 justify-content-center align-items-center">
          <div class="place-holder-small">
            <p>Calendar Widget Holder (Coming Soon!) <i class="fas fa-grin-hearts mr-1"></i></p>
          </div>
          <div class="place-holder-small">
            <p>Competition Widget Holder (Coming Soon!) <i class="fas fa-grin-hearts mr-1"></i></p>
          </div>
        </div> -->

        <div class="row">
          <div [ngClass]="{'col-4': screenWidth >= 650, 'col-6': screenWidth < 650}" class="mt-3">
            <div class="card pointer" id="profileDiv" [class.disable]="checkDisable() || checkTermed()" (click)="openLink('profile')">
              <div class="card-body">
                <div class="text-center font-big-icon">
                  <i class="material-icons">account_circle</i><br />
                  Profile
                </div>
              </div>
            </div>
          </div>

          <!-- <div [ngClass]="{'col-4': screenWidth >= 650, 'col-6': screenWidth < 650}" class="mt-3">
            <div class="card pointer" id="documentsDiv" [class.disable]="checkTermed()" (click)="openLink('onboardingdocs/list')">
              <div class="card-body">
                <div class="text-center font-big-icon">
                  <i class="fas fa-file-signature"></i><br />
                  Documents <span *ngIf="profile!=null && profile.pendingDocumentsCount>0 && !profile.pendingRequiredDocumentsCount" class="badge badge-info">{{profile.pendingDocumentsCount}}</span>
                  <span *ngIf="profile!=null && profile.pendingRequiredDocumentsCount>0" class="badge badge-danger">{{profile.pendingRequiredDocumentsCount}}</span>
                </div>
              </div>
            </div>
          </div> -->

          <div [ngClass]="{'col-4': screenWidth >= 650, 'col-6': screenWidth < 650}" class="mt-3">
            <div class="card pointer" (click)="openLink('/form-filling/list')">
              <div class="card-body">
                <div class="text-center font-big-icon">
                  <i class="fal fa-page-break"></i><br />
                  Forms <span *ngIf="profile!=null &&profile.pendingFormsCount>0 && !profile.pendingRequiredFormsCount" class="badge badge-info" title="Forms Available">{{profile.pendingFormsCount}}</span>
                  <span *ngIf="profile!=null &&profile.pendingRequiredFormsCount>0" title="Required Due Forms Pending" class="badge badge-danger">{{profile.pendingRequiredFormsCount}}</span>
                </div>
              </div>
            </div>
          </div>
          <div [ngClass]="{'col-4': screenWidth >= 650, 'col-6': screenWidth < 650}" class="mt-3">
            <div class="card pointer" id="documentsUploadDiv" [class.disable]="checkTermed()" (click)="openLink('uploaddocs')">
              <div class="card-body">
                <div class="text-center font-big-icon">
                  <i class="fas fa-file-archive"></i><br />
                  Uploaded Documents <span *ngIf="profile!=null && profile.rejectedUploadCount>0" class="badge badge-danger">{{profile.rejectedUploadCount}}</span>
                </div>
              </div>
            </div>
          </div>

          <div [ngClass]="{'col-4': screenWidth >= 650, 'col-6': screenWidth < 650}" class="mt-3">
            <div class="card pointer" id="timeCardDiv" [class.disable]="checkDisable() || checkTermed()" (click)="openLink('timecard')">
              <div class="card-body">
                <div class="text-center font-big-icon">
                  <i class="material-icons">access_time</i><br />
                  Time Card
                </div>
              </div>
            </div>
          </div>

          <div [ngClass]="{'col-4': screenWidth >= 650, 'col-6': screenWidth < 650}" class="mt-3">
            <div class="card pointer" id="payrollDiv" [class.disable]="checkDisable()" (click)="openLink('payroll')">
              <div class="card-body">
                <div class="text-center font-big-icon">
                  <i class="material-icons" style="color: green;">monetization_on</i><br />
                  Payroll  <span *ngIf="profile!=null && profile.pendingPayrollFixRequestsCount>0" class="badge badge-danger" title="Pending fix request">{{profile.pendingPayrollFixRequestsCount}}</span>
                </div>
              </div>
            </div>
          </div>
          <div  [ngClass]="{'col-4': screenWidth >= 650, 'col-6': screenWidth < 650}" class="mt-3" *ngIf="profile!=null && profile.offerLetter && !profile.offerLetter.signed">
            <div class="card pointer" id="offerLetterDiv" [class.disable]="checkDisable() || checkTermed()" (click)="openLink('offerletter')">
              <div class="card-body">
                <div class="text-center font-big-icon">
                  <i class="fas fa-file-invoice-dollar"></i><br />
                  Offer Letter <span *ngIf="profile!=null && profile.offerLetter" class="badge badge-danger" title="See Offer Letter">1</span>
                </div>
              </div>
            </div>
          </div>

          <div  [ngClass]="{'col-4': screenWidth >= 650, 'col-6': screenWidth < 650}" class="mt-3" *ngIf="canViewEquipment">
            <div class="card pointer" id="myEquipmentDiv" [class.disable]="checkDisable() || checkTermed()" (click)="openLink('equipment/my')">
              <div class="card-body">
                <div class="text-center font-big-icon">
                  <i class="material-icons">
                    devices
                  </i><br />
                  My Equipment
                  <span *ngIf="profile!=null && profile.pendingEquipmentCount>0 || returnEquipmentAlertCount>0" [title]="setAssetAlertTitle(profile.pendingEquipmentCount, returnEquipmentAlertCount)" class="badge badge-danger">{{(profile.pendingEquipmentCount + returnEquipmentAlertCount)}}</span>
                </div>
              </div>
            </div>
          </div>

    <div [ngClass]="{'col-4': screenWidth >= 650, 'col-6': screenWidth < 650}" class="mt-3">
      <div class="card pointer"  (click)="openLink('/coachinglogs/my')">
        <div class="card-body">
          <div class="text-center font-big-icon">
            <i class="fas fa-people-carry"></i><br />
            My Improvements <span *ngIf="profile.pendingCoachingLogAck>0" class="badge badge-danger">{{profile.pendingCoachingLogAck}}</span>
          </div>
        </div>
      </div>
    </div>


    <div [ngClass]="{'col-4': screenWidth >= 650, 'col-6': screenWidth < 650}" class="mt-3" *ngIf="canSeeCoach || canAddCoach">
      <div class="card pointer" [class.disable]="checkDisable()" (click)="openLink('/coachinglogs/given')">
        <div class="card-body">
          <div class="text-center font-big-icon">
            <i class="fas fa-people-carry"></i><br />
            Coaching <span *ngIf="profile.overdueCoachLogsCount>0" class="badge badge-danger">{{profile.overdueCoachLogsCount}}</span>
          </div>
        </div>
      </div>
    </div>

    <div [ngClass]="{'col-4': screenWidth >= 650, 'col-6': screenWidth < 650}" class="mt-3">
        <div class="card pointer" id="surveyDiv" [class.disable]="checkDisable() || checkTermed()" (click)="openLink('survey/list')">
            <div class="card-body">
            <div class="text-center font-big-icon">
                <i class="fas fa-star"></i><br />
                Surveys <span *ngIf="profile!=null && profile.unansweredSurveyCount>0" class="badge badge-danger">{{profile.unansweredSurveyCount}}</span>
            </div>
            </div>
        </div>
    </div>


          <div [ngClass]="{'col-4': screenWidth >= 650, 'col-6': screenWidth < 650}" class="mt-3">
            <div class="card pointer" [class.disable]="checkDisable() || checkTermed()" (click)="openLink('/timeoff/my')">
              <div class="card-body">
                <div class="text-center font-big-icon">
                  <i class="fas fa-umbrella-beach" style="color: aqua;"></i><br />
                  Time Off
                </div>
              </div>
            </div>
          </div>
          <div [ngClass]="{'col-4': screenWidth >= 650, 'col-6': screenWidth < 650}" class="mt-3">
            <div class="card pointer" [class.disable]="checkDisable()" (click)="openLink('/employee-review/list/'+profile.userId)">
              <div class="card-body">
                <div class="text-center font-big-icon">
                  <i class="fas fa-clipboard-check"></i><br />
                  My Reviews
                </div>
              </div>
            </div>
          </div>

          <div [ngClass]="{'col-4': screenWidth >= 650, 'col-6': screenWidth < 650}" class="mt-3" *ngIf="canSeeSelfcare">
            <div class="card pointer" (click)="openSelfCare()">
              <div class="card-body">
                <div class="text-center font-big-icon">
                  <i class="fas fa-hand-heart"></i><br />
                  Self Care
                </div>
              </div>
            </div>
          </div>

          <div [ngClass]="{'col-4': screenWidth >= 650, 'col-6': screenWidth < 650}" class="mt-3" *ngIf="canViewBenefits"  >
            <div class="card pointer" id="benefitsDiv" [class.disable]="checkDisable()"  (click)="openEnrollment()">
              <div class="card-body">
                <div class="text-center font-big-icon" >
                  <i class="fal fa-hand-holding-medical"></i><br />
                  Benefits Enrollment <span *ngIf="profile!=null && profile.benefitsEnrollmentPending" class="badge badge-danger">*</span>
                </div>
              </div>
            </div>
          </div>


          <div [ngClass]="{'col-4': screenWidth >= 650, 'col-6': screenWidth < 650}" class="mt-3" *ngIf="canViewMedClinic"  >
            <div class="card pointer" id="medClinicDiv" [class.disable]="checkDisable()"  (click)="openMedClinic()">
              <div class="card-body">
                <div class="text-center font-big-icon" >
                  <i class="fas fa-clinic-medical"></i><br />
                 Med Clinic Services

                </div>
              </div>
            </div>
          </div>

          <div [ngClass]="{'col-4': screenWidth >= 650, 'col-6': screenWidth < 650}" class="mt-3">
            <div class="card pointer" id="internDiv" [class.disable]="checkDisable()" (click)="openLink('/intern/list-application')">
              <div class="card-body">
                <div class="text-center font-big-icon">
                  <i class="fas fa-graduation-cap"></i><br />
                  Intern Applications <span *ngIf="profile!=null && profile.notSubmittedInternApplicationsCount>0 && !profile.submittedInternApplicationsNeedingMoreInfoCount" class="badge badge-info mr-1" title="Not submitted applications">{{profile.notSubmittedInternApplicationsCount}}</span>
                     <span *ngIf="profile!=null && profile.submittedInternApplicationsNeedingMoreInfoCount>0" class="badge badge-danger" title="Submitted applications needing more info">{{profile.submittedInternApplicationsNeedingMoreInfoCount}}</span>
                </div>              
              </div>
            </div>
          </div>

        </div>
      </div>
      <!-- <div *ngIf="screenWidth >= 1700" class="col-4 d-flex w-100 flex-column justify-content-center align-items-center">
        <div class="place-holder">
          Calendar Widget Holder (Coming Soon!)&nbsp;<i class="fas fa-grin-hearts"></i>
        </div>
        <div class="place-holder">
          Competition Widget Holder (Coming Soon!)&nbsp;<i class="fas fa-grin-hearts"></i>
        </div>
      </div> -->
      <div *ngIf="canViewCriticalNewsFeed && canAccessCriticalNewsFeed && screenWidth > 1160 && profile!=null && profile.employee && (!profile?.offerLetter || profile.offerLetter.signed || profile?.offerLetter.existingUser)" class="col-6" > <!--[ngClass]="screenWidth >= 1700 ? 'col-4' : 'col-6'"-->
        <app-critical-news-feed-view></app-critical-news-feed-view>
      </div>

    </ng-container>
  </div>
</div>
<div *ngIf="canViewCriticalNewsFeed && canAccessCriticalNewsFeed && screenWidth <= 1160" [hidden]="!showNewsFeedModal" class="news-feed-modal">
    <app-critical-news-feed-view style="height: 100%"></app-critical-news-feed-view>
</div>
