import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { Actions, ofType } from '@ngrx/effects';
import { State } from '../../../../core/store';
import { Store } from '@ngrx/store';
import { BenefitHsaReportModel } from '../../../../models/benefitsModels';
import { DateRangeModel } from '../../../../models/dateRangeModel';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { DropdownFilterService } from '../../../../core/services/dropdown-filter.service';
import { deepClone, downloadFile } from '../../../../helpers/utils';
import { loadEmployeeUsers, loadViewableCampuses } from '../../../../core/store/employee/employee.action';
import { exportBenefitHsaReportToExcel, exportBenefitHsaReportToExcelSuccess, getBenefitHsaReport } from '../../../../core/store/benefits/benefits.actions';
import { formatDate } from '@angular/common';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-list-benefit-hsa',
  templateUrl: './list-benefit-hsa.component.html',
  styleUrls: ['./list-benefit-hsa.component.scss']
})

export class ListBenefitHsaComponent extends BaseComponent implements OnInit {
  list: PagingResultsModel<BenefitHsaReportModel>;
  filter: any;
  canEdit: boolean = false;

  loadingList: boolean = true;
  loadingExcel: boolean = false;

  campusList: SelectItem[];
  campusId: number = 0;

  prefixText: string;
  userEmployeeList: SelectItem[];
  userId: number = 0;

  enrollmentDate: string = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private dropdownFilterService: DropdownFilterService) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.benefitsState.loadingHsaList).subscribe(loadingHsaList => {
        this.loadingList = loadingHsaList;
      }),
      this.store.select(s => s.benefitsState.loadingHsaExcel).subscribe(loadingHsaExcel => {
        this.loadingExcel = loadingHsaExcel;
      }),
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.canEdit = permissionIDs && !!permissionIDs.find(p => p === 3075);
      }),
      this.store.select(s => s.employeeState.campuses).subscribe(campuses => {
        this.campusList = campuses && campuses.length > 0 ? campuses
          : [{ label: 'loading...', value: undefined }];
      }),      
      this.store.select(s => s.employeeState.employeeUsers).subscribe(users => {
        this.userEmployeeList = users;
      }),
      this.store.select(s => s.benefitsState.hsaList).subscribe(list => {
        this.list = list;
      }),
      this.actions$.pipe(
        ofType(exportBenefitHsaReportToExcelSuccess),
        tap(action => {
          downloadFile(action.doc, 'BenefitHsaReport.xlsx');
        })
      ).subscribe()
    );

    this.store.dispatch(loadViewableCampuses());    
  }  

  reloadFilter(event) {
    this.filter = event;
    this.reloadList();
  }

  reloadList() {
    this.filter = deepClone(this.filter);
    this.filter.filters.userId = { Name: 'userId', Value: { value: this.userId.toString() } };
    this.filter.filters.programId = { Name: 'programId', Value: { value: this.campusId.toString() } };
    this.filter.filters.date = { Name: 'date', Value: { value: this.enrollmentDate } };

    this.store.dispatch(getBenefitHsaReport({
      filter: this.filter
    }));
  }

  getStaffList(event) {
    this.store.dispatch(loadEmployeeUsers({
      prefixText: event.query,
      programId: this.campusId,
      payrollClassificationId: null,
      showInactive: false,
      showTerminated: false
    }));
  }

  reloadListAfterStaffChage(selectedUser) {
    if (typeof selectedUser.value !== 'number') {
      return;
    }

    this.userId = selectedUser.value;
    this.reloadList();
  }

  reloadListAfterClear(event) {
    if (event.data !== null) {
      return;
    }

    this.userId = 0;
    this.reloadList();
  }

  exportToExcel() {
    this.filter = deepClone(this.filter);
    this.filter.filters.userId = { Name: 'userId', Value: { value: this.userId.toString() } };
    this.filter.filters.programId = { Name: 'programId', Value: { value: this.campusId.toString() } };
    this.filter.filters.date = { Name: 'date', Value: { value: this.enrollmentDate } };

    this.store.dispatch(exportBenefitHsaReportToExcel({
      filter: this.filter
    }));
  }
}
