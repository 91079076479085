import { createReducer, on, Action } from '@ngrx/store';
import { EthicCodeModel } from '../../../models/ethicCodeModels';
import { closeEthicCodeModal, loadEthicCodes, loadEthicCodesSuccess, saveEthicCodeFailure, saveEthicCodes, saveEthicCodeSuccess, showEthicCodeModal } from './ethic-code.actions';

export interface EthicCodeState {
  ethicCodes: EthicCodeModel[];
  ethicCode: EthicCodeModel;
  loading: boolean;
  savingEthicCode: boolean;
}

const initialState: EthicCodeState = {
  ethicCodes: null,
  ethicCode: null,
  loading: false,
  savingEthicCode: false
};

export const ethicCodeStateReducer = createReducer(initialState,
  on(loadEthicCodes, (state, action) => {
    return {
      ...state,
      loading: true
    };
  }),
  on(loadEthicCodesSuccess, (state, action) => {
    return {
      ...state,
      ethicCodes: action.ethicCodes,
      loading: false
    };
  }),

  on(showEthicCodeModal, (state, action) => {
    return {
      ...state,
      ethicCode: action.ethicCode
    };
  }),
  on(closeEthicCodeModal, (state, action) => {
    return {
      ...state,
      ethicCode: null
    };
  }),

  on(saveEthicCodes, (state, action) => {
    return {
      ...state,
      savingEthicCode: true
    };
  }),
  on(saveEthicCodeSuccess, (state, action) => {
    return {
      ...state,
      savingEthicCode: false
    };
  }),
  
  on(saveEthicCodeFailure, (state, action) => {
    return {
      ...state,
      savingEthicCode: false
    };
  }),

  
);

export function ethicCodeStateReducerFunc(state: EthicCodeState | undefined, action: Action) {
  return ethicCodeStateReducer(state, action);
}



