import { EmployeeFederalTaxInformationModel, UserProfileModel } from './../../../../../models/userProfileModel';
import {  saveProfile, loadProfile, saveProfileSuccess, updateProfileW4Form } from '../../../../../core/store/profile/profile.actions';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from '../../../../../shared/services/alert.service';
import { Component, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../../core/store';
import { Actions, ofType } from '@ngrx/effects';
import * as moment from 'moment';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { FormGroup } from '@angular/forms';
import { getAllControlsErrors, markFormGroupTouched } from 'src/app/helpers/utils';
import {  initProfileW4Form } from 'src/app/core/store/profile/profile.reducer';
import { tap } from 'rxjs/operators';
import { setOnboardingDocsInfoFilled, setOnboardingDocsInfoFilledSuccess } from 'src/app/core/store/onboardingdocs/onboardingdocs.actions';

@Component({
  selector: 'app-doc-fill-info-w4form',
  templateUrl: './doc-fill-info-w4form.component.html',
  styleUrls: ['./doc-fill-info-w4form.component.scss']
})
export class DocFillInfow4FormComponent extends BaseComponent implements OnInit {

  @Input()
  onboardingDocUserId;

  @Input()
  doNotNavigate;

  profile: UserProfileModel;

  filingStatusOptions = [
    { label: 'Single or Married filing separately', value: 'S' },
    { label: 'Married filing jointly or Qualifying widow(er)', value: 'M' },
    { label: 'Head of household', value: 'H' },
  ];
  yesNoOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];
  form: FormGroup;
  constructor(
    private store: Store<State>,
    private actions$: Actions,
    private alert: AlertService,
    private router: Router,
    private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.store.dispatch(loadProfile());
    this.subs.push(
      this.store.select(s => s.profileState).subscribe(profileState => {
        if (profileState && profileState.profile) {
          this.profile = JSON.parse(JSON.stringify(profileState.profile));
          if (!this.profile.employeeFederalTaxInformation) {
            this.profile.employeeFederalTaxInformation = new EmployeeFederalTaxInformationModel();
          }
          if (!this.form) {
            this.form = initProfileW4Form(this.profile);
            this.subs.push(
              this.form.valueChanges.subscribe(formValues => {
                const userValues = this.form.get('user').value;
                const employeeValues = this.form.get('employee').value;
                const employeeFederalTaxInformationValues = this.form.get('employeeFederalTaxInformation').value;
                const formErrors = getAllControlsErrors(this.form);
                this.store.dispatch(updateProfileW4Form({ userValues, employeeValues, employeeFederalTaxInformationValues , formErrors }));
              })
            );
          }
        }
      }),
      this.actions$.pipe(
        ofType(saveProfileSuccess),
        tap(action => {
          this.store.dispatch(setOnboardingDocsInfoFilled({ onboardingDocUserId: this.onboardingDocUserId }));
        })
      ).subscribe(),

      this.actions$.pipe(
        ofType(setOnboardingDocsInfoFilledSuccess),
        tap(action => {
          if (!this.doNotNavigate) {
            this.router.navigate(['../../sign/' + this.onboardingDocUserId],{relativeTo: this.route});
          }
        })
      ).subscribe()
    );
  }

  save() {
    if (this.form.valid) {
      if (this.profile.employeeFederalTaxInformation && this.onboardingDocUserId > 0)
        this.profile.employeeFederalTaxInformation.onboardingDocUserId = this.onboardingDocUserId;
      this.store.dispatch(saveProfile({ profile: this.profile }));
    } else {
      markFormGroupTouched(this.form);
    }
  }

}
