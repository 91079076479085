<form class="card mt-2" *ngIf="canEdit">
  <div class="card-body">
    <div *ngIf="canEdit" class="float-right">
      <button type="button" routerLink="/uploaddocs/vaccine-list" class="btn btn-secondary">Uploaded Vaccine Information</button>
    </div>
      <h2 class="mb-3">Documents Upload Approval</h2>

    <i *ngIf="loadingUploadDocsApprovalList" class="material-icons rotate text-muted">loop</i>
    <p-table  [value]="uploadDocsApprovalList" [responsive]="true"
    [paginator]="true" [rows]="20"  [lazy]="false">
        <ng-template pTemplate="header">
            <tr>
                <th width="145px"></th>
                <th [pSortableColumn]="'FirstName'" >First Name
                   <p-sortIcon [field]="'FirstName'"></p-sortIcon></th>
                    <th [pSortableColumn]="'LastName'">Last Name
                      <p-sortIcon [field]="'LastName'"></p-sortIcon>
                    </th>

                <th >Uploaded docs to approve
                </th>


            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-userToApprove>
            <tr >
                <td>
                  <span class="pointer mr-2" *ngIf="canEdit"   (click)="openDocsToApprove(userToApprove)"><i title="See docs" class="fas fa-edit"></i></span>
                </td>
                <td>
                    <span class="ui-column-title">First Name</span>
                  {{userToApprove.firstName }}
                </td>

                <td>
                  <span class="ui-column-title">Last Name</span>
                {{userToApprove.lastName }}
              </td>
                <td>
                  <span class="ui-column-title">Uploaded docs to approve</span>
                  <div *ngFor="let doc of userToApprove.unApprovedUploadDocs">
                    {{doc.onboarding_UploadDocType}}
                  </div>
                </td>

            </tr>
        </ng-template>
    </p-table>
  </div>
</form>

<p-confirmDialog></p-confirmDialog>
