import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { State } from '../../../../core/store';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { getEmployeePtoSummary, getEmployeeEarnedPto, getEmployeeRequestedPto, showEditPtoRequest, savePtoRequestSuccess } from '../../../../core/store/timeoff/timeoff.actions';
import { EmployeePtoSummaryModel, PtoEarnedModel, PtoRequestedModel } from '../../../../models/timeoffModel';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-list-my-pto',
  templateUrl: './list-my-pto.component.html',
  styleUrls: ['./list-my-pto.component.scss']
})
export class ListMyPtoComponent extends BaseComponent implements OnInit {
  userId: number;
  currentUserId: number;
  summary: EmployeePtoSummaryModel;

  earnedList: PagingResultsModel<PtoEarnedModel>;
  earnedFilter: any;
  loadingEarnedList: boolean = true;

  showEarned: boolean = true;

  requestedList: PagingResultsModel<PtoRequestedModel>
  requestedFilter: any;
  loadingRequestedPtoList: boolean = false;

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {    
    this.subs.push(
      this.store.select(s => s.accountState.user.id).subscribe(id => {
        this.currentUserId = id;
      }),
      this.store.select(s => s.timeoffState.employeePtoSummary).subscribe(summary => {
        this.summary = summary;        
      }),
      this.store.select(s => s.timeoffState.loadingEarnedList).subscribe(loadingEarnedList => {
        this.loadingEarnedList = loadingEarnedList;
      }),
      this.store.select(s => s.timeoffState.ptoEarnedList).subscribe(earnedList => {
        this.earnedList = earnedList;
      }),
      this.store.select(s => s.timeoffState.loadingRequestedPtoList).subscribe(loadingRequestedList => {
        this.loadingRequestedPtoList = loadingRequestedList;
      }),
      this.store.select(s => s.timeoffState.ptoRequestedList).subscribe(requestedList => {
        this.requestedList = requestedList;
      }),
      this.actions$.pipe(
        ofType(savePtoRequestSuccess),
        tap(action => {
          this.refreshData();
        })
      ).subscribe(),
    );

    this.route.params.subscribe(params => {
      if (params.userId) {
        this.userId = params.userId;
      }
    });

    if (!this.userId) {
      this.userId = 0;
    }

    this.store.dispatch(getEmployeePtoSummary({userId: this.userId}));
  }

  reloadEarnedList(event) {
    this.earnedFilter = event;
    this.store.dispatch(getEmployeeEarnedPto({
      filter: this.earnedFilter,
      userId: this.userId
    }));
  }

  reloadRequestedList(event) {
    this.requestedFilter = event;
    this.store.dispatch(getEmployeeRequestedPto({
      filter: this.requestedFilter,
      userId: this.userId
    }));
  }

  toggleShowEarned() {
    this.showEarned = !this.showEarned;
  }

  refreshData() {
    this.store.dispatch(getEmployeePtoSummary({ userId: this.userId }));
    this.reloadEarnedList(this.earnedFilter);
    this.reloadRequestedList(this.requestedFilter);
  }

  editPtoRequest(ptoRequest) {
    this.store.dispatch(showEditPtoRequest({ ptoRequest: ptoRequest }));
  }

}
