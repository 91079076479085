<form class="card mt-2">
  <div class="card-body">
    <div class="float-right">
      <button *ngIf="canViewCompleted" type="button" routerLink="/employee-review/report/completedKra" class="btn btn-secondary">Completed KRAs</button>
    </div>
    <h2 class="mb-3">Employee Reviews</h2>
    <div class="mb-3">

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Employee:</h5>
        </div>
        <div class="col-10">
          <div class="ml-2 w-100 mb-2">
            <p-autoComplete [style]="{'width': '100%'}" [inputStyle]="{'width': '100%'}" [(ngModel)]="prefixText"
                            [suggestions]="userEmployeeList" (completeMethod)="getStaffList($event)" field="label"
                            [ngModelOptions]="{standalone: true}" [minLength]="1" [autoHighlight]="true"
                            (onSelect)="reloadListAfterStaffChage($event)" [forceSelection]="true"
                            (onClear)="reloadListAfterClear($event)"></p-autoComplete>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Campus:</h5>
        </div>
        <div class="col-10">
          <i *ngIf="!campusList" class="material-icons rotate text-muted">loop</i>
          <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="campusList" [(ngModel)]="campusId" (ngModelChange)="reloadFilter(this.filter)" [ngModelOptions]="{standalone: true}"></p-dropdown>
        </div>
      </div>            

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Template:</h5>
        </div>
        <div class="col-10">
          <i *ngIf="!templateList" class="material-icons rotate text-muted">loop</i>
          <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="templateList" [(ngModel)]="templateId" (ngModelChange)="reloadFilter(this.filter)" [ngModelOptions]="{standalone: true}"></p-dropdown>
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>From Review Date:</h5>
        </div>
        <div class="col-10">
          <input type="date" class="form-control ml-2 w-100 mb-2" [(ngModel)]="startDate" (blur)="reloadFilter(this.filter)" [ngModelOptions]="{standalone: true}" />
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>To Review Date:</h5>
        </div>
        <div class="col-10">
          <input type="date" class="form-control ml-2 w-100 mb-2" [(ngModel)]="endDate" (blur)="reloadFilter(this.filter)" [ngModelOptions]="{standalone: true}" />
        </div>
      </div>

      <div class="row mb-1">
          <div class="col-2 d-flex align-items-center">
            <h5>Show Separated Users:</h5>
          </div>
          <div class="col-10 ">
            <p-selectButton styleClass="w-100 ml-2" [options]="yesNoAllOptions" [(ngModel)]="terminatedEmployeesFilter" (ngModelChange)="reloadFilter(this.filter)" [ngModelOptions]="{standalone: true}"></p-selectButton>
          </div>
        </div>

        <div class="row mb-1">
          <div class="col-2 d-flex align-items-center">
            <h5>Show Inactive Users:</h5>
          </div>
          <div class="col-10 ">
            <p-selectButton styleClass="w-100 ml-2" [options]="yesNoAllOptions" [(ngModel)]="inactiveUsersFilter" (ngModelChange)="reloadFilter(this.filter)" [ngModelOptions]="{standalone: true}"></p-selectButton>
          </div>
        </div>

    </div>

    <i [hidden]="!loadingList" class="material-icons rotate text-muted">loop</i>
    <p-table [value]="list?.result" [responsive]="true" [paginator]="true" [rows]="20"
             [totalRecords]="list?.totalRecords" [lazy]="true" (onLazyLoad)="reloadFilter($event)">
      <ng-template pTemplate="header">
        <tr>
          <th width="100px"></th>          
          <th [pSortableColumn]="'EmployeeName'">Employee <p-sortIcon [field]="'EmployeeName'"></p-sortIcon></th>
          <th [pSortableColumn]="'Campus'">Campus <p-sortIcon [field]="'Campus'"></p-sortIcon></th>
          <th [pSortableColumn]="'TemplateDescription'">Template Description <p-sortIcon [field]="'TemplateDescription'"></p-sortIcon></th>
          <th [pSortableColumn]="'ReviewDate'">Review Date <p-sortIcon [field]="'ReviewDate'"></p-sortIcon></th>
          <th [pSortableColumn]="'Score'">Score <p-sortIcon [field]="'Score'"></p-sortIcon></th>
          <th [pSortableColumn]="'Comments'">Comments <p-sortIcon [field]="'Comments'"></p-sortIcon></th>
          <th [pSortableColumn]="'FocusArea'">Focus Area <p-sortIcon [field]="'FocusArea'"></p-sortIcon></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-review>
        <tr>
          <td>
            <button class="btn btn-secondary mr-2" (click)="openReview(review)" title="View Review">
              <i class="fas fa-file-alt"></i>
            </button>
          </td>          
          <td>
            <span class="ui-column-title">Employee</span>
            {{review.userLastName}}, {{review.userFirstName}} <span style="font-style:italic; color: red; font: 0.9rem;">{{getTerminationString(review)}}</span>
          </td>          
          <td>
            <span class="ui-column-title">Campus</span>
            {{review.primaryProgram}}
          </td>
          <td>
            <span class="ui-column-title">KRA Description</span>
            {{review.reviewTemplate}}
          </td>                             
          <td>
            <span class="ui-column-title">Review Date</span>
            {{(review.reviewDate !== null) ? (review.reviewDate | date: 'MM/dd/yyyy') : ""}}
          </td>
          <td>
            <span class="ui-column-title">Score</span>
            {{review.score}}
          </td>
          <td>
            <span class="ui-column-title">Comments</span>
            {{review.comments}}
          </td>
          <td>
            <span class="ui-column-title">Focus Area</span>
            {{review.focusArea}}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</form>
