import { OnBoardingDocumentUserModel } from 'src/app/models/onBoardingDocumentUserModel';
import { UserTypeModel } from './../../../../../models/userProfileModel';
import { getOnboardingDoc, getOnboardingDocs, getOnboardingUserDocs, resendOnboardingDocUserForce, updateOnboardingUserDocForceNotRequired, resendOnboardingDocUserForceSuccess, updateOnboardingUserDocForceNotRequiredSuccess } from './../../../../../core/store/onboardingdocs/onboardingdocs.actions';
import { OnBoardingDocumentModelWithRoles } from './../../../../../models/onBoardingDocumentUserModel';
import { PagingResultsModel } from './../../../../../models/pagingResultsModel';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../../core/store';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Router } from '@angular/router';
import { deepClone } from 'src/app/helpers/utils';
import { loadUserTypes } from 'src/app/core/store/profile/profile.actions';

@Component({
  selector: 'app-list-onboardinguserdocs',
  templateUrl: './list-onboardinguserdocs.component.html',
  styleUrls: ['./list-onboardinguserdocs.component.scss']
})
export class ListOnboardingUserDocsComponent extends BaseComponent implements OnInit {

  onboardingUserDocs: PagingResultsModel<OnBoardingDocumentUserModel>;
  canEdit: boolean;
  canView: boolean;
  loadingUserDocs = false;
  filter: any;
  employeeNameFilter: string;
  documentNameFilter: string;
  createDateFilter;
  signedDateFilter;

  constructor(
    private actions$: Actions,
    private alertService: AlertService,
    private store: Store<State>,
    private router: Router) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.canEdit = permissionIDs && !!permissionIDs.find(p => p === 3060); // manage
        this.canView = permissionIDs && !!permissionIDs.find(p => p === 3059 || p === 3060); // view
      }),
      this.store.select(s => s.onBoardingDocsState.usersDocs).subscribe(usersDocs => {
        if (usersDocs) {
          this.onboardingUserDocs = usersDocs;
        }
      }),

      this.store.select(s => s.onBoardingDocsState.loadingUserDocs).subscribe(loadingUserDocs => {
        this.loadingUserDocs = loadingUserDocs;
      }),

      this.actions$.pipe(
        ofType(updateOnboardingUserDocForceNotRequiredSuccess, resendOnboardingDocUserForceSuccess),
        tap(action => {
          this.loadPage(this.filter);
        })
      ).subscribe(),
    );
  }

  changedFilters() {
    this.loadPage(this.filter);
  }

  loadPage(event) {
    this.filter = event;
    this.filter = deepClone(event);
    this.filter.filters.employee = { Name: 'employee', Value: { value: this.employeeNameFilter } };
    this.filter.filters.document = { Name: 'document', Value: { value: this.documentNameFilter } };

    if (this.createDateFilter) {
      this.filter.filters.createdDateFrom = { Name: 'createdDateFrom', Value: { value: this.createDateFilter[0] } };
      this.filter.filters.createdDateTo = { Name: 'createdDateTo', Value: { value: this.createDateFilter[1] } };
    } else {
      this.filter.filters.createdDateFrom = { Name: 'createdDateFrom', Value: { value: null } };
      this.filter.filters.createdDateTo = { Name: 'createdDateTo', Value: { value: null } };
    }

    if (this.signedDateFilter) {
      this.filter.filters.signedDateFrom = { Name: 'signedDateFrom', Value: { value: this.signedDateFilter[0] } };
      this.filter.filters.signedDateTo = { Name: 'signedDateTo', Value: { value: this.signedDateFilter[1] } };
    } else {
      this.filter.filters.signedDateFrom = { Name: 'signedDateFrom', Value: { value: null } };
      this.filter.filters.signedDateTo = { Name: 'signedDateTo', Value: { value: null } };;
    }

    this.store.dispatch(getOnboardingUserDocs({ filter: deepClone(this.filter) }));
  }

  forceNotRequired(doc: OnBoardingDocumentUserModel) {
    this.store.dispatch(updateOnboardingUserDocForceNotRequired({ model: { onBoardingDocUserID: doc.id, forceNotRequired: true } }));
  }

  unForceNotRequired(doc: OnBoardingDocumentUserModel) {
    this.store.dispatch(updateOnboardingUserDocForceNotRequired({ model: { onBoardingDocUserID: doc.id, forceNotRequired: false } }));
  }


  forceReassign(doc: OnBoardingDocumentUserModel) {
    this.store.dispatch(resendOnboardingDocUserForce({ model: { onBoardingDocUserID: doc.id, force: true } }));
  }
}

