import { createReducer, on, Action } from '@ngrx/store';
import { PayPeriodModel } from 'src/app/models/payPeriodModel';
import { loadPayPeriodsSuccess, changePayPeriod, loadPayPeriods } from './payperiod.actions';

export interface PayPeriodState {
  periods: PayPeriodModel[];
  selectedPeriodID: number;
}

const initialState: PayPeriodState = {
  periods: null,
  selectedPeriodID: 0,
};

export const payPeriodReducer = createReducer(initialState,
  on(loadPayPeriodsSuccess, (state, action) => {
    return {
      ...state,
      periods: action.periods
    };
  }),
  on(changePayPeriod, (state, action) => {
    return {
      ...state,
      selectedPeriodID: action.periodID
    };
  }),
);

export function payPeriodReducerFunc(state: PayPeriodState | undefined, action: Action) {
  return payPeriodReducer(state, action);
}

