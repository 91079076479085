import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, tap, catchError, filter } from 'rxjs/operators';
import { of, pipe } from 'rxjs';
import { errorHappened } from '../profile/profile.actions';
import { Store, Action } from '@ngrx/store';
import { State } from '..';
import { AlertService } from 'src/app/shared/services/alert.service';
import { downloadFile } from 'src/app/helpers/utils';
import { ProspectiveCandidatesService } from '../../services/prospectiveCandidates.service';
import {
  createProspectiveCandidate, createProspectiveCandidateFail, createProspectiveCandidateSuccess, deleteProspectiveCandidate, deleteProspectiveCandidateFail, deleteProspectiveCandidateSuccess, getProspectiveCandidate, getProspectiveCandidateFail, getProspectiveCandidates, getProspectiveCandidatesFail, getProspectiveCandidatesSuccess, getProspectiveCandidateSuccess, saveProspectiveCandidate, saveProspectiveCandidateFail, saveProspectiveCandidateSuccess, getProspectiveCandidatesList, getProspectiveCandidatesListSuccess, getProspectiveCandidatesListFail, loadPropsectiveCandidateReference, loadPropsectiveCandidateReferenceSuccess, loadPropsectiveCandidateReferenceFail, savePropsectiveCandidateReference, savePropsectiveCandidateReferenceSuccess, savePropsectiveCandidateReferenceFail, loadPropsectiveCandidateReferenceById,
  noShowProspectiveCandidate, noShowProspectiveCandidateSuccess, noShowProspectiveCandidateFail, addProspectiveCandidateContactLog, addProspectiveCandidateContactLogSuccess, addProspectiveCandidateContactLogFail, editProspectiveCandidateContactLog, editProspectiveCandidateContactLogSuccess, getResume, getResumeSuccess,
  saveProspectiveCandidateMiscDoc,
  saveProspectiveCandidateMiscDocSuccess,
  deleteProspectiveCandidateMiscDoc,
  deleteProspectiveCandidateMiscDocSuccess
} from './prospective-candidate.actions';

@Injectable()
export class ProspectiveCandidateEffects {
  constructor(
    private actions$: Actions,
    private prospectiveCandidatesService: ProspectiveCandidatesService,
    private store: Store<State>,
    private alertService: AlertService,
    private router: Router) {

  }

  createProspectiveCandidate = createEffect(() => this.actions$.pipe(
    ofType(createProspectiveCandidate),
    switchMap(action => {
      return this.prospectiveCandidatesService.newProspectiveCandidate(action.prospectiveCandidate, action.file).pipe(
        map(prospectiveCandidate => createProspectiveCandidateSuccess({ prospectiveCandidate })),
        catchError(err => {
          this.store.dispatch(createProspectiveCandidateFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));

  addProspectiveCandidateContactLog = createEffect(() => this.actions$.pipe(
    ofType(addProspectiveCandidateContactLog),
    switchMap(action => {
      return this.prospectiveCandidatesService.addProspectiveContactLog(action.log).pipe(
        map(prospectiveCandidateContactLog => addProspectiveCandidateContactLogSuccess({log: prospectiveCandidateContactLog })),
        catchError(err => {
          this.store.dispatch(addProspectiveCandidateContactLogFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));

  addProspectiveCandidateContactLogSuccess = createEffect(() => this.actions$.pipe(
    ofType(addProspectiveCandidateContactLogSuccess),
    tap(action => {
      this.alertService.success(
        'Contact Log added', false);
    })
  ), { dispatch: false });

  editProspectiveCandidateContactLog = createEffect(() => this.actions$.pipe(
    ofType(editProspectiveCandidateContactLog),
    switchMap(action => {
      return this.prospectiveCandidatesService.editProspectiveContactLog(action.log).pipe(
        map(prospectiveCandidateContactLog => editProspectiveCandidateContactLogSuccess({log: prospectiveCandidateContactLog })),
        catchError(err => {
          this.store.dispatch(addProspectiveCandidateContactLogFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));

  editProspectiveCandidateContactLogSuccess = createEffect(() => this.actions$.pipe(
    ofType(editProspectiveCandidateContactLogSuccess),
    tap(action => {
      this.alertService.success(
        'Contact Log edited', false);
    })
  ), { dispatch: false });

  createProspectiveCandidateSuccess = createEffect(() => this.actions$.pipe(
    ofType(createProspectiveCandidateSuccess),
    tap(action => {
      this.alertService.success(
        'Prospective Candidate created', false);
      this.router.navigate(['/prospective-candidate/list']);
    })
  ), { dispatch: false });

  getProspectiveCandidates = createEffect(() => this.actions$.pipe(
    ofType(getProspectiveCandidates),
    switchMap(action => {
      return this.prospectiveCandidatesService.getProspectiveCandidateTable(action.filter).pipe(
        map(prospectiveCandidates => getProspectiveCandidatesSuccess({ prospectiveCandidates })),
        catchError(err => {
          this.store.dispatch(getProspectiveCandidatesFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));

  deleteProspectiveCandidate = createEffect(() => this.actions$.pipe(
    ofType(deleteProspectiveCandidate),
    switchMap(action => {
      return this.prospectiveCandidatesService.deleteProspectiveCandidate(action.prospectiveCandidateID).pipe(
        map(prospectiveCandidate => deleteProspectiveCandidateSuccess({ prospectiveCandidate })),
        catchError(err => {
          this.store.dispatch(deleteProspectiveCandidateFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));


  noShowProspectiveCandidate = createEffect(() => this.actions$.pipe(
    ofType(noShowProspectiveCandidate),
    switchMap(action => {
      return this.prospectiveCandidatesService.noShowProspectiveCandidate(action.prospectiveCandidateID).pipe(
        map(prospectiveCandidate => noShowProspectiveCandidateSuccess({ prospectiveCandidate })),
        catchError(err => {
          this.store.dispatch(noShowProspectiveCandidateFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));

  getResume = createEffect(() => this.actions$.pipe(
    ofType(getResume),
    switchMap(action => {
      return this.prospectiveCandidatesService.getResume(action.prospectiveCandidateID).pipe(
        map(resumeURL => getResumeSuccess({ resumeURL: resumeURL.resumeURL })),
        catchError(err => {
          console.log(err);
          return of(errorHappened({ err }));
        }));
    })
  ));

  getResumeSuccess = createEffect(() => this.actions$.pipe(
    ofType(getResumeSuccess),
    tap(action => {
      if (action.resumeURL == null){
        this.alertService.info("There has not been a Resume Uploaded.")
      }
      else{
        window.open(action.resumeURL, "_blank");
      }
    })
  ), { dispatch: false });

  getProspectiveCandidate = createEffect(() => this.actions$.pipe(
    ofType(getProspectiveCandidate),
    switchMap(action => {
      return this.prospectiveCandidatesService.getProspectiveCandidate(action.prospectiveCandidateID).pipe(
        map(prospectiveCandidate => getProspectiveCandidateSuccess({ prospectiveCandidate })),
        catchError(err => {
          this.store.dispatch(getProspectiveCandidateFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));


  saveProspectiveCandidate = createEffect(() => this.actions$.pipe(
    ofType(saveProspectiveCandidate),
    switchMap(action => {
      return this.prospectiveCandidatesService.editProspectiveCandidate(action.prospectiveCandidate, action.file).pipe(
        map(prospectiveCandidate => saveProspectiveCandidateSuccess({ prospectiveCandidate })),
        catchError(err => {
          this.store.dispatch(saveProspectiveCandidateFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));

  saveProspectiveCandidateSuccess = createEffect(() => this.actions$.pipe(
    ofType(saveProspectiveCandidateSuccess),
    tap(action => {
      this.alertService.success(
        'Saved', false);
      this.router.navigate(['/prospective-candidate/list']);
    })
  ), { dispatch: false });

  
  saveProspectiveCandidateMiscDoc = createEffect(() => this.actions$.pipe(
    ofType(saveProspectiveCandidateMiscDoc),
    switchMap(action => {
      return this.prospectiveCandidatesService.saveProspectiveCandidateMiscDocuments(action.prospectiveCandidateID, action.files).pipe(
        map(prospectiveCandidate => saveProspectiveCandidateMiscDocSuccess({ prospectiveCandidate })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  saveProspectiveCandidateMiscDocSuccess = createEffect(() => this.actions$.pipe(
    ofType(saveProspectiveCandidateMiscDocSuccess),
    tap(action => {
      this.store.dispatch(getProspectiveCandidate({ prospectiveCandidateID: action.prospectiveCandidate.id }))
      this.alertService.info("Document Uploaded")
    })
  ), { dispatch: false });

  deleteProspectiveCandidateMiscDoc = createEffect(() => this.actions$.pipe(
    ofType(deleteProspectiveCandidateMiscDoc),
    switchMap(action => {
      return this.prospectiveCandidatesService.deleteProspectiveCandidateMiscDocuments(action.documentID, action.prospectiveCandidateID).pipe(
        map(prospectiveCandidate => deleteProspectiveCandidateMiscDocSuccess({ prospectiveCandidate })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  deleteProspectiveCandidateMiscDocSuccess = createEffect(() => this.actions$.pipe(
    ofType(deleteProspectiveCandidateMiscDocSuccess),
    tap(action => {
      this.store.dispatch(getProspectiveCandidate({ prospectiveCandidateID: action.prospectiveCandidate.id }))
      this.alertService.info("Document Removed")
    })
  ), { dispatch: false });

  getProspectiveCandidatesList = createEffect(() => this.actions$.pipe(
    ofType(getProspectiveCandidatesList),
    switchMap(action => {
      return this.prospectiveCandidatesService.getProspectiveCandidatesList().pipe(
        map(prospectiveCandidatesList => getProspectiveCandidatesListSuccess({ prospectiveCandidatesList })),
        catchError(err => {
          this.store.dispatch(getProspectiveCandidatesListFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));



  loadPropsectiveCandidateReference = createEffect(() => this.actions$.pipe(
    ofType(loadPropsectiveCandidateReference),
    switchMap(action => {
      return this.prospectiveCandidatesService.getProspectiveCandidateReferenceByCode(action.code).pipe(
        map(prospectiveCandidateReference => loadPropsectiveCandidateReferenceSuccess({ prospectiveCandidateReference })),
        catchError(err => {
          this.store.dispatch(loadPropsectiveCandidateReferenceFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));

  loadPropsectiveCandidateReferenceById = createEffect(() => this.actions$.pipe(
    ofType(loadPropsectiveCandidateReferenceById),
    switchMap(action => {
      return this.prospectiveCandidatesService.getProspectiveCandidateReferenceById(action.propsectiveCandidateReferenceId).pipe(
        map(prospectiveCandidateReference => loadPropsectiveCandidateReferenceSuccess({ prospectiveCandidateReference })),
        catchError(err => {
          this.store.dispatch(loadPropsectiveCandidateReferenceFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));

  savePropsectiveCandidateReference = createEffect(() => this.actions$.pipe(
    ofType(savePropsectiveCandidateReference),
    switchMap(action => {
      return this.prospectiveCandidatesService.editProspectiveCandidateReference(action.prospectiveCandidateReference).pipe(
        map(prospectiveCandidateReference => savePropsectiveCandidateReferenceSuccess({ prospectiveCandidateReference })),
        catchError(err => {
          this.store.dispatch(savePropsectiveCandidateReferenceFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));

}
