import { PagingResultsModel } from './../../../models/pagingResultsModel';
import {
  OnBoardingUploadModel, OnBoardingModel, OnBoardingUserModel,
  OnBoardingMessageModel, LicenseInfosModel, PanelingInfosModel,
  CovidTestInfosModel, VaccineCardExemptionModel, UploadDocsApprovalListModel, EmployeeVaccineListModel,
  DriversLicenseInfosModel
} from './../../../models/onBoardingModels';
import { createAction, props } from '@ngrx/store';
import { OnBoardingUploadType } from 'src/app/models/onBoardingModels';
import { UserProfileModel } from 'src/app/models/userProfileModel';
import { FormError } from 'src/app/models/utilModels';

export const loadOnboardingUploadTypes = createAction('[Onboarding] Load Upload Types');
export const loadOnboardingUploadTypesSuccess = createAction('[Onboarding] Load Upload Types Success',
  props<{ uploadTypes: OnBoardingUploadType[] }>());
export const loadOnboardingUploadTypesFailure = createAction('[Onboarding] Load Upload Types Failure');

export const uploadOnboardingDoc = createAction('[Onboarding] Upload doc',
  props<{
    docTypeID: string, files: File[], userId: number, visibleToUser: boolean, uploadID?: number,
    licenseInfos?: LicenseInfosModel, panelingInfos?: PanelingInfosModel, driversLicenseInfos?: DriversLicenseInfosModel, covidTestInfos?: CovidTestInfosModel, vaccineExemption?: any
  }>());
export const uploadOnboardingDocSuccess = createAction('[Onboarding] Upload doc Success',
  props<{ userId: number }>());
export const uploadOnboardingDocFailure = createAction('[Onboarding] Upload doc Failure');

export const loadOnboardingUploads = createAction('[Onboarding] Load Uploaded Files',
  props<{ userId?: number }>());
export const loadOnboardingUploadsSuccess = createAction('[Onboarding] Load Uploaded Files Success',
  props<{ uploadedFiles: OnBoardingUploadModel[] }>());
export const loadOnboardingUploadsFailure = createAction('[Onboarding] Load Uploaded Files Failure');

export const loadOnboardingInfo = createAction('[Onboarding] Load Onboarding Info');
export const loadOnboardingInfoSuccess = createAction('[Onboarding] Load Onboarding Info Success',
  props<{ onBoardingInfo: OnBoardingModel }>());
export const loadOnboardingInfoFailure = createAction('[Onboarding] Load Onboarding Info Failure');


export const deleteOnboardingUpload = createAction('[Onboarding] Delete Onboarding Upload',
  props<{ OnBoardingUpload: OnBoardingUploadModel }>());
export const deleteOnboardingUploadSuccess = createAction('[Onboarding] Delete Onboarding Upload Success',
props<{ userId?: number }>());
export const deleteOnboardingUploadFailure = createAction('[Onboarding] Delete Onboarding Upload Failure');


export const changeVisibilityOnboardingUpload = createAction('[Onboarding] changeVisibility Onboarding Upload',
  props<{ OnBoardingUpload: OnBoardingUploadModel }>());
export const changeVisibilityOnboardingUploadSuccess = createAction('[Onboarding] changeVisibility Onboarding Upload Success',
  props<{ userId: number }>());
export const changeVisibilityOnboardingUploadFailure = createAction('[Onboarding] changeVisibility Onboarding Upload Failure');




export const approveOnboardingUpload = createAction('[Onboarding] Approve Onboarding Upload',
  props<{ OnBoardingUpload: OnBoardingUploadModel }>());
export const approveOnboardingUploadSuccess = createAction('[Onboarding] Approve Onboarding Upload Success',
  props<{ userId?: number }>());
export const approveOnboardingUploadFailure = createAction('[Onboarding] Approve Onboarding Upload Failure');



export const denyOnboardingUpload = createAction('[Onboarding] Deny Onboarding Upload',
  props<{ OnBoardingUpload: OnBoardingUploadModel, denyMessage: string }>());
export const denyOnboardingUploadSuccess = createAction('[Onboarding] Deny Onboarding Upload Success',
  props<{ userId?: number }>());
export const denyOnboardingUploadFailure = createAction('[Onboarding] Deny Onboarding Upload Failure');


export const approveAlluploads = createAction('[Onboarding] Approve All Onboarding Upload',
  props<{ userId?: number }>());
export const approveAlluploadsSuccess = createAction('[Onboarding] Approve Onboarding Upload Success',
  props<{ userId?: number }>());
export const approveAlluploadsFailure = createAction('[Onboarding] Approve Onboarding Upload Failure');

export const approveI9 = createAction('[Onboarding] Approve i9Form',
  props<{ userId?: number }>());
export const approveI9Success = createAction('[Onboarding] Approve i9Form Success',
  props<{ userId?: number }>());
export const approveI9Failure = createAction('[Onboarding] Approve i9Form Failure');

export const denyI9 = createAction('[Onboarding] Deny i9Form',
  props<{ userId?: number, denyMessage: string }>());
export const denyI9Success = createAction('[Onboarding] Deny i9Form Success',
  props<{ userId?: number }>());
export const denyI9Failure = createAction('[Onboarding] Deny i9Form Failure');




export const approveSignedDocs = createAction('[Onboarding] Approve SignedDocs',
  props<{ userId?: number }>());
export const approveSignedDocsSuccess = createAction('[Onboarding] Approve SignedDocs Success',
  props<{ userId?: number }>());
export const approveSignedDocsFailure = createAction('[Onboarding] Approve SignedDocs Failure');



export const finishOnboarding = createAction('[Onboarding] Finish onboarding');
export const finishOnboardingSuccess = createAction('[Onboarding] Finish onboarding Success');
export const finishOnboardingFailure = createAction('[Onboarding] Finish onboarding Failure');

export const rejectOrientationDate = createAction('[Onboarding] rejectOrientationDate onboarding');
export const rejectOrientationDateSuccess = createAction('[Onboarding] rejectOrientationDate onboarding Success');
export const rejectOrientationDateFailure = createAction('[Onboarding] rejectOrientationDate onboarding Failure');


export const setOrientationDate = createAction('[Onboarding] setOrientationDate onboarding',
  props<{ userId: number, orientationDate: string }>());
export const setOrientationDateSuccess = createAction('[Onboarding] setOrientationDate onboarding Success',
  props<{ userId: number }>());
export const setOrientationDateFailure = createAction('[Onboarding] setOrientationDate onboarding Failure');




export const downloadOnboardingUpload = createAction('[Onboarding] Download Onboarding Upload',
  props<{ onboardingUploadFileId: number }>());
export const downloadOnboardingUploadSuccess = createAction('[Onboarding] Download Onboarding Upload Success',
  props<{ doc: ArrayBuffer }>());




export const consentToProceedOnboarding = createAction('[Onboarding] Consent To Proceed Onboarding ',
  props<{ onBoardingInfo: OnBoardingModel }>());
export const consentToProceedOnboardingSuccess = createAction('[Onboarding] Consent To Proceed Onboarding Success',
  props<{ onBoardingInfo: OnBoardingModel }>());
export const consentToProceedOnboardingFailure = createAction('[Onboarding] Consent To Proceed Onboarding Failure');

export const loadOnBoardingUsersList = createAction('[Onboarding] Load Onboarding Users',
  props<{ filter: any }>());
export const loadOnBoardingUsersListSuccess = createAction('[Onboarding] Load Onboarding Users Success',
  props<{ onBoardingUsers: PagingResultsModel<OnBoardingUserModel> }>());
export const loadOnBoardingUsersListFailure = createAction('[Onboarding] Load Onboarding Users Failure');


export const loadManageOnboardingUserInfo = createAction('[Onboarding] Load Onboarding User Info',
  props<{ userId: number }>());
export const loadManageOnboardingUserInfoSuccess = createAction('[Onboarding] Load  Onboarding User Info Success',
  props<{ onBoardingUser: OnBoardingUserModel }>());
export const loadManageOnboardingUserInfoFailure = createAction('[Onboarding] Load  Onboarding User Info Failure');

export const clearManageOnboardingUserInfo = createAction('[Onboarding] Clear Onboarding User Info');

export const saveUserProfile = createAction('[Onboarding] Save user profile', props<{ userId: number, profile: UserProfileModel }>());
export const saveUserProfileSuccess = createAction('[Onboarding] Save user profile Success',
  props<{ userId: number }>());
export const saveUserProfileFailure = createAction('[Onboarding]  Save user profile Failure');

export const updateUserProfileForm = createAction('[Onboarding] Update user profile Form',
  props<{ userValues: any; employeeValues: any; formErrors: FormError[] }>());


export const uploadSignedI9 = createAction('[Onboarding] Upload Signed i9', props<{ formSubmissionID: number, file: File }>());
export const uploadSignedI9Success = createAction('[Onboarding] Upload Signed i9 Success');
export const uploadSignedI9Failure = createAction('[Onboarding] Upload Signed i9 Failure');


export const loadEmployeeFileUsersList = createAction('[Onboarding] Load EmployeeFile Users',
  props<{ filter: any }>());
export const loadEmployeeFileUsersListSuccess = createAction('[Onboarding] Load EmployeeFile Users Success',
  props<{ employeeFileUsers: PagingResultsModel<OnBoardingUserModel> }>());
export const loadEmployeeFileUsersListFailure = createAction('[Onboarding] Load EmployeeFile Users Failure');




export const forceOnBoarding = createAction('[Onboarding] Force onboarding on user', props<{ userId: number }>());
export const forceOnBoardingSuccess = createAction('[Onboarding] Force onboarding on user Success',
  props<{ userId: number }>());
export const forceOnBoardingFailure = createAction('[Onboarding] Force onboarding on user Failure');


export const saveUserSignature = createAction('[Onboarding] Save User Signature', props<{ data: any, userId: number }>());
export const saveUserSignatureSuccess = createAction('[Onboarding] Save User Signature Success', props<{ userId: number }>());
export const saveUserSignatureFailure = createAction('[Onboarding] Save User Signature Failure');


export const updateProfileBCIHRForm = createAction('[Onboarding Docs] Update BCI HR Form',
  props<{ userValues: any; employeeValues: any; formErrors: FormError[] }>());
export const updateProfileI9HRForm = createAction('[Onboarding Docs] Update i9 HR Form',
  props<{ userValues: any; employeeValues: any; formErrors: FormError[] }>());


export const sendOnBoardingMessage = createAction('[Onboarding] Send onboarding message',
  props<{ onBoardingMessage: OnBoardingMessageModel }>());
export const sendOnBoardingMessageSuccess = createAction('[Onboarding] Send onboarding message Success');
export const sendOnBoardingMessageFailure = createAction('[Onboarding] Send onboarding message Failure');


export const completeHRFile = createAction('[Onboarding] Complete HRFile', props<{ userId: number, eVerifyCompletionDate: Date }>());
export const completeHRFileSuccess = createAction('[Onboarding] Complete HRFile success', props<{ userId: number }>());
export const completeHRFileFailure = createAction('[Onboarding] Complete HRFile failure');

export const requestVaccineCardExemption = createAction('[Onboarding] requestVaccineCardExemption',
  props<{ notes: string, userID: number, exemptionType: number }>());
export const requestVaccineCardExemptionSuccess = createAction('[Onboarding] RequestVaccineCardExemption success',
  props<{ vaccineCardExemptionModel: VaccineCardExemptionModel }>());
export const requestVaccineCardExemptionFailure = createAction('[Onboarding] RequestVaccineCardExemption failure');


export const getVaccineCardExemption = createAction('[Onboarding] getVaccineCardExemption',
  props<{ userID: number }>());
export const getVaccineCardExemptionSuccess = createAction('[Onboarding] getVaccineCardExemption success',
  props<{ vaccineCardExemptionModel: VaccineCardExemptionModel }>());
export const getVaccineCardExemptionFailure = createAction('[Onboarding] getVaccineCardExemption failure');

export const approveVaccineCardExemption = createAction('[Onboarding] approveVaccineCardExemption',
  props<{ id: number }>());
export const approveVaccineCardExemptionSuccess = createAction('[Onboarding] approveVaccineCardExemption success',
  props<{ vaccineCardExemptionModel: VaccineCardExemptionModel }>());
export const approveVaccineCardExemptionFailure = createAction('[Onboarding] approveVaccineCardExemption failure');

export const loadUploadDocsApprovalList = createAction('[Onboarding] loadUploadDocsApprovalList');
export const loadUploadDocsApprovalListSuccess = createAction('[Onboarding] loadUploadDocsApprovalList success',
  props<{ uploadDocsApprovalList: UploadDocsApprovalListModel[] }>());
export const loadUploadDocsApprovalListFailure = createAction('[Onboarding] loadUploadDocsApprovalList failure');


export const cancelVaccineCardExemption = createAction('[Onboarding] cancelVaccineCardExemption',
  props<{ id: number }>());
export const cancelVaccineCardExemptionSuccess = createAction('[Onboarding] cancelVaccineCardExemption success');
export const cancelVaccineCardExemptionFailure = createAction('[Onboarding] cancelVaccineCardExemption failure');


export const getEmployeeVaccineInfoList = createAction('[Onboarding] Get Employee Vaccine Info List',
  props<{ filter: any }>());
export const getEmployeeVaccineInfoListSuccess = createAction('[Onboarding] Get Employee Vaccine Info List Success',
  props<{ list: PagingResultsModel<EmployeeVaccineListModel> }>());

export const exportEmployeeVaccineInfoListToExcel = createAction('[Onboarding] Export Employee Vaccine Info List To Excel',
  props<{ filter: any }>());
export const exportEmployeeVaccineInfoListToExcelSuccess = createAction('[Onboarding] Export Employee Vaccine Info List To Excel Success',
  props<{ doc: ArrayBuffer }>());
export const exportEmployeeVaccineInfoListToExcelFailure = createAction('[Onboarding] Export Employee Vaccine Info List To Excel Failure');
