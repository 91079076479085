import { loadManageOnboardingUserInfo, saveUserProfile, updateUserProfileForm, saveUserSignature, saveUserSignatureSuccess } from './../../../../core/store/onboarding/onboarding.actions';
import { updateProfileForm, loadRaceOptions, saveSignature, saveSignatureSuccess } from './../../../../core/store/profile/profile.actions';
import { UserProfileModel } from './../../../../models/userProfileModel';
import { Component, OnInit, Input, ViewChild, AfterViewInit, ElementRef, HostListener } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { saveProfile } from '../../../../core/store/profile/profile.actions';
import { FormGroup } from '@angular/forms';
import { initProfileForm } from 'src/app/core/store/profile/profile.reducer';
import { getAllControlsErrors, markFormGroupTouched } from 'src/app/helpers/utils';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { SignaturePad } from 'angular2-signaturepad';
import { DomSanitizer } from '@angular/platform-browser';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss']
})
export class SignatureComponent extends BaseComponent implements OnInit, AfterViewInit {

  profile: UserProfileModel;
  isHRManager: boolean;
  @Input() managedUserId;
  @Input() readOnly = false;
  @ViewChild(SignaturePad, { static: false }) signaturePad: SignaturePad;
  changing = false;
  savedSignatureSrc;
  savingSignature = false;

  public signaturePadOptions: any = { // passed through to szimek/signature_pad constructor
    minWidth: 1,
    canvasWidth: 500,
    // canvasHeight: 300
  };

  constructor(
    private actions$: Actions,
    private el: ElementRef,
    private sanitizer: DomSanitizer,
    private store: Store<State>) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.profileState.savingSignature).subscribe(savingSignature => {
        this.savingSignature = savingSignature;
      }),
    );
    if (this.managedUserId) {
      this.subs.push(
        this.store.select(s => s.onboardingState.manageOnboardingUserInfo).subscribe(manageOnboardingUserInfo => {
          if (manageOnboardingUserInfo) {
            this.profile = manageOnboardingUserInfo.user;
            if (this.profile.viewSignatureUrl) {
              this.savedSignatureSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.profile.viewSignatureUrl);
            } else {
              this.savedSignatureSrc = null;
            }
          }
        }),
      );

      this.store.dispatch(loadManageOnboardingUserInfo({ userId: this.managedUserId }));
    } else {
      this.subs.push(
        this.store.select(s => s.profileState).subscribe(profileState => {
          this.profile = JSON.parse(JSON.stringify(profileState.profile));
          if (this.profile.viewSignatureUrl) {
            this.savedSignatureSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.profile.viewSignatureUrl);
          } else {
            this.savedSignatureSrc = null;
          }
        }),

      );
    }
    this.subs.push(
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.isHRManager = permissionIDs && !!permissionIDs.find(p => p === 6001 || p === 6002);
      }),
      this.actions$.pipe(
        ofType(saveSignatureSuccess, saveUserSignatureSuccess),
        tap(action => {
          this.changing = false;
        })
      ).subscribe(),
    );

  }

  save() {
    if (this.managedUserId) {
      this.store.dispatch(saveUserSignature({ userId: this.managedUserId, data: this.signaturePad.toDataURL() }));
    } else {
      this.store.dispatch(saveSignature({ data: this.signaturePad.toDataURL() }));
    }
  }

  ngAfterViewInit() {
    if (this.signaturePad) {
      (this.signaturePad as any).signaturePad._canvas.width = this.el.nativeElement.offsetWidth;
      this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.signaturePad) {
      (this.signaturePad as any).signaturePad._canvas.width = this.el.nativeElement.offsetWidth;
    }
  }

  startChanging() {
    this.changing = true;
    setTimeout(() => {
      if (this.signaturePad) {
        (this.signaturePad as any).signaturePad._canvas.width = this.el.nativeElement.offsetWidth;
      }
    }, 350);
  }

  // drawComplete() {
  //   // will be notified of szimek/signature_pad's onEnd event
  //   console.log(this.signaturePad.toDataURL());
  // }

  // drawStart() {
  //   // will be notified of szimek/signature_pad's onBegin event
  //   console.log('begin drawing');
  // }

}

