import { HolidayModel, RecurringHolidayModel } from '../../../models/holidayModel';
import { createReducer, on, Action } from '@ngrx/store';
import {
  loadHolidayYearsSuccess, changeHolidayUserFilter, changeHolidayUserFilterSuccess, getHolidayList,
  getHolidayListSuccess, showEditHoliday, closeEditHoliday, saveHoliday, saveHolidaySuccess, updateHolidayForm,
  deleteHoliday, deleteHolidaySuccess, getRecurringHolidayList, getRecurringHolidayListSuccess, showEditRecurringHoliday,
  closeEditRecurringHoliday, saveRecurringHoliday, saveRecurringHolidaySuccess,
  updateRecurringHolidayForm, loadRecurringTypesSuccess, loadRecurringWeeksSuccess
} from './holiday.actions';
import { PagingResultsModel } from '../../../models/pagingResultsModel';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { formatDate } from '@angular/common';
import { getFormattedTime } from '../../../helpers/utils';
import * as moment from 'moment';
import { FormError } from '../../../models/utilModels';
import { SelectItem } from 'primeng/api';
import { errorHappened } from '../profile/profile.actions';


export interface HolidayState {
  years: SelectItem[];
  filteredUsers: SelectItem[];
  holidayList: PagingResultsModel<HolidayModel>;

  recurringTypes: SelectItem[];
  recurringWeeks: SelectItem[];
  recurringHolidayList: PagingResultsModel<RecurringHolidayModel>;

  loadingHolidayList: boolean;
  editingHoliday: boolean;
  savingHoliday: boolean;

  loadingRecurringHolidayList: boolean;
  editingRecurringHoliday: boolean;
  savingRecurringHoliday: boolean;

  selectedHoliday: HolidayModel;
  selectedRecurringHoliday: RecurringHolidayModel;
  formErrors: FormError[];
};

const initialState: HolidayState = {
  years: [],
  filteredUsers: [],
  holidayList: null,

  recurringTypes: [],
  recurringWeeks: [],
  recurringHolidayList: null,

  loadingHolidayList: true,
  editingHoliday: false,
  savingHoliday: false,

  loadingRecurringHolidayList: true,
  editingRecurringHoliday: false,
  savingRecurringHoliday: false,

  selectedHoliday: null,
  selectedRecurringHoliday: null,
  formErrors: [],
};

export const holidayReducer = createReducer(initialState,
  on(loadHolidayYearsSuccess, (state, action) => {
    return {
      ...state,
      years: action.years,
    };
  }),
  on(changeHolidayUserFilter, (state, action) => {
    return {
      ...state,
      filteredUsers: [],
      loadingUsersList: true
    };
  }),
  on(changeHolidayUserFilterSuccess, (state, action) => {
    return {
      ...state,
      filteredUsers: action.userList,
      loadingUsersList: false
    };
  }),
  on(getHolidayList, (state, action) => {
    return {
      ...state,
      loadingHolidayList: true
    };
  }),
  on(getHolidayListSuccess, (state, action) => {
    return {
      ...state,
      holidayList: action.holidayList,
      loadingHolidayList: false
    };
  }),
  on(showEditHoliday, (state, action) => {
    return {
      ...state,
      selectedHoliday: action.holiday,
      editingHoliday: true
    };
  }),
  on(closeEditHoliday, (state, action) => {
    return {
      ...state,
      selectedHoliday: null,
      editingHoliday: false
    };
  }),
  on(saveHoliday, (state, action) => {
    return {
      ...state,
      savingHoliday: true
    }
  }),
  on(saveHolidaySuccess, (state, action) => {
    return {
      ...state,
      savingHoliday: false
    }
  }),
  on(deleteHoliday, (state, action) => {
    return {
      ...state,
      savingHoliday: true
    }
  }),
  on(deleteHolidaySuccess, (state, action) => {
    return {
      ...state,
      savingHoliday: false
    }
  }),
  on(updateHolidayForm, (state, action) => {
    const editingHoliday = { ...state.selectedHoliday, ...action.formValues };
    const newState = { ...state, ...{ selectedHoliday: editingHoliday, formErrors: action.formErrors } };
    return newState;
  }),


  on(loadRecurringTypesSuccess, (state, action) => {
    return {
      ...state,
      recurringTypes: action.types,
    };
  }),
  on(loadRecurringWeeksSuccess, (state, action) => {
    return {
      ...state,
      recurringWeeks: action.weeks,
    };
  }),
  on(getRecurringHolidayList, (state, action) => {
    return {
      ...state,
      loadingRecurringHolidayList: true
    };
  }),
  on(getRecurringHolidayListSuccess, (state, action) => {
    return {
      ...state,
      recurringHolidayList: action.holidayList,
      loadingRecurringHolidayList: false
    };
  }),
  on(showEditRecurringHoliday, (state, action) => {
    return {
      ...state,
      selectedRecurringHoliday: action.holiday,
      editingRecurringHoliday: true
    };
  }),
  on(closeEditRecurringHoliday, (state, action) => {
    return {
      ...state,
      selectedRecurringHoliday: null,
      editingRecurringHoliday: false
    };
  }),
  on(saveRecurringHoliday, (state, action) => {
    return {
      ...state,
      savingRecurringHoliday: true
    }
  }),
  on(saveRecurringHolidaySuccess, (state, action) => {
    return {
      ...state,
      savingRecurringHoliday: false
    }
  }), 
  on(updateRecurringHolidayForm, (state, action) => {
    const editingHoliday = { ...state.selectedRecurringHoliday, ...action.formValues };
    const newState = { ...state, ...{ selectedRecurringHoliday: editingHoliday, formErrors: action.formErrors } };
    return newState;
  }),
  on(errorHappened, (state, action) => {
    return {
      ...state,
      savingRecurringHoliday: false,
      savingHoliday: false
    }
  })
);

export function holidayReducerFunc(state: HolidayState | undefined, action: Action) {
  return holidayReducer(state, action);
}

export function initEditHolidayForm(holiday: HolidayModel) {
  if (holiday == null) {
    holiday = {} as HolidayModel;
  }
  if (typeof holiday.holidayId === 'undefined') { //initializing new holiday
    holiday.holidayId = 0;
    holiday.holidayName = "";
    holiday.date = new Date();

    let startTime = new Date();
    startTime.setHours(8);
    startTime.setMinutes(0);
    startTime.setSeconds(0);
    holiday.startTime = startTime;

    let endTime = new Date(startTime);
    endTime.setHours(16);
    holiday.endTime = endTime;
  }
  
  const form = new FormGroup({
    holidayId: new FormControl(holiday.holidayId),
    date: new FormControl(holiday.date),
    dateString: new FormControl(typeof holiday.date !== 'undefined' ? formatDate(holiday.date, 'yyyy-MM-dd', 'en-US') : '', [Validators.required]), //add pattern
    holidayName: new FormControl(holiday.holidayName, [Validators.required, Validators.maxLength(255)]),
    startTime: new FormControl(moment(holiday.startTime).toDate()),
    endTime: new FormControl(moment(holiday.endTime).toDate()),
    startTimeString: new FormControl(getFormattedTime(holiday.startTime), [Validators.required]), //add pattern
    endTimeString: new FormControl(getFormattedTime(holiday.endTime), [Validators.required]) //add pattern
  });

  return form
}

export function initEditRecurringHolidayForm(holiday: RecurringHolidayModel) {
  if (holiday == null) {
    holiday = {} as RecurringHolidayModel;
  }
  if (typeof holiday.recurringHolidayId === 'undefined') { //initializing new holiday
    holiday.recurringHolidayId = 0;
    holiday.holidayName = "";
    holiday.date = new Date();
    holiday.archived = false;
    holiday.recurringTypeId = 1;
    holiday.monthId = 1;
    holiday.dayOfWeekId = 0;
    holiday.weekId = 0;

    let startTime = new Date();
    startTime.setHours(8);
    startTime.setMinutes(0);
    startTime.setSeconds(0);
    holiday.startTime = startTime;

    let endTime = new Date(startTime);
    endTime.setHours(16);
    holiday.endTime = endTime;
  }

  const form = new FormGroup({
    recurringHolidayId: new FormControl(holiday.recurringHolidayId),
    date: new FormControl(holiday.date),
    dateString: new FormControl(typeof holiday.date !== 'undefined' ? formatDate(holiday.date, 'yyyy-MM-dd', 'en-US') : '', [Validators.required]), //add pattern
    holidayName: new FormControl(holiday.holidayName, [Validators.required, Validators.maxLength(255)]),
    startTime: new FormControl(moment(holiday.startTime).toDate()),
    endTime: new FormControl(moment(holiday.endTime).toDate()),
    startTimeString: new FormControl(getFormattedTime(holiday.startTime), [Validators.required]), //add pattern
    endTimeString: new FormControl(getFormattedTime(holiday.endTime), [Validators.required]), //add pattern
    archived: new FormControl(holiday.archived),
    recurringTypeId: new FormControl(holiday.recurringTypeId),
    monthId: new FormControl(holiday.monthId),
    dayOfWeekId: new FormControl(holiday.dayOfWeekId),
    weekId: new FormControl(holiday.weekId),
  });

  return form
}
