import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import {
  changeApplicationStatus, changeApplicationStatusSuccess, changeApplicationSubstatus, changeApplicationSubstatusSuccess,
  createInternship, createInternshipSuccess, deleteInternActionItemSuccess, getInternApplication,
  getInternApplicationReviewForms, getInternApplicationReviewFormsSuccess, getInternApplicationUpload, getInternApplicationUploadSuccess,
  loadInternApplicationDocToSign,
  loadInternshipStatuses, loadInternshipSubstatuses, saveInternActionItemSuccess, saveInternApplicationReview, saveInternApplicationReviewSuccess, saveInternUploadsSuccess,
  showEditInternActionItem, showEditInternUploads, updateHourAndProductivityCalculations, updateHourAndProductivityCalculationsSuccess
} from '../../../../core/store/intern/intern.action';
import { InternApplicationActionModel, InternApplicationModel, InternReviewFormModel, InternUploadModel } from '../../../../models/internModels';
import { deepClone, downloadFile } from '../../../../helpers/utils';
import { AlertService } from '../../../../shared/services/alert.service';
import { ModifiedSelectItem } from '../../../../models/utilModels';
import { addFBFormAssignmentSuccess, deleteFBFormAssignment, deleteFBFormAssignmentSuccess } from '../../../../core/store/form-builder/form-builder.actions';
import { downloadOnboardingUpload } from '../../../../core/store/onboarding/onboarding.actions';
import { SelectItem } from 'primeng';

@Component({
  selector: 'app-intern-review-process',
  templateUrl: './intern-review-process.component.html',
  styleUrls: ['./intern-review-process.component.scss']
})
export class InternReviewProcessComponent extends BaseComponent implements OnInit {
  formSubmissionID?: number;
  formID?: number;
  applicationId: number;
  disableButtons: boolean = false;
  isInternDecisionForm: boolean = false;

  savingApplication: boolean = false;
  loadingApplication: boolean;
  showForm: boolean = true;
  formViewButtonText: string = "Hide Form";
  showAssignForm: boolean = false;
  isApprovedOrDenied: boolean = false; //initially => don't show add decision forms unless decision reason check is complete

  downloadFileName: string;
  loadingDownload: boolean = false;

  internApplication: InternApplicationModel = [] as InternApplicationModel;
  primaryStatuses: SelectItem[];
  allSubstatuses: ModifiedSelectItem[];
  availableSubstatuses: ModifiedSelectItem[];
  originalSubstatusId: number = 0;
  originalStatusId: number = 0;

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
  ) {
    super()
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params.applicationId) {
        this.applicationId = params.applicationId;
        this.store.dispatch(getInternApplication({ applicationId: this.applicationId }));
      }
    });

    this.subs.push(      
      this.store.select(s => s.internState.loadingApplication).subscribe(loading => {
        this.loadingApplication = loading;
      }),
      this.store.select(s => s.internState.savingApplicationReview).subscribe(saving => {
        this.savingApplication = saving;
        this.disableButtons = saving;
      }),
      this.store.select(s => s.internState.loadingApplicationUpload).subscribe(loading => {
        this.loadingDownload = loading;
      }),
      this.store.select(s => s.internState.internshipStatuses).subscribe(statuses => {
        if (statuses && statuses.length > 0) {
          this.primaryStatuses = statuses.filter(x => x.value !== 1); //exclude Not Submitted
          this.primaryStatuses.unshift({ label: '', value: undefined });
        }
        else {
          this.primaryStatuses = [{ label: 'loading...', value: 0 }];
        }
      }),
      this.store.select(s => s.internState.internshipSubstatuses).subscribe(statuses => {
        if (statuses && statuses.length > 0) {
          this.allSubstatuses = statuses.map(x => ({ label: x.label, value: x.value, archived: x.archived, filterId: x.filterId, disabled: x.disabled }));
          this.allSubstatuses.unshift({ label: '', value: 0, disabled: false, archived: false });
          if (this.internApplication.statusId > 0) {
            this.getSubstatuses();
          }
        }
        else {
          this.allSubstatuses = [{ label: 'loading...', value: undefined }];
        }        
        
      }),
      this.store.select(s => s.internState.selectedApplication).subscribe(selectedApplication => {
        if (selectedApplication === null) {
          this.internApplication = [] as InternApplicationModel;
          this.isApprovedOrDenied = false;
          this.originalSubstatusId = 0;
          this.originalStatusId = 0;
          this.getSubstatuses();
          return;
        }

        this.originalSubstatusId = selectedApplication.substatusId;
        this.originalStatusId = selectedApplication.statusId;
        this.isApprovedOrDenied = selectedApplication.statusId == 3 || selectedApplication.statusId == 4;
        this.internApplication = deepClone(selectedApplication);
        this.formSubmissionID = selectedApplication.formSubmissionId;
        this.formID = selectedApplication.formId;
        this.getSubstatuses();

      }),
      this.actions$.pipe(
        ofType(changeApplicationStatusSuccess),
        tap(action => {
          this.alertService.success("Success");
          this.store.dispatch(getInternApplication({ applicationId: this.applicationId }));
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(updateHourAndProductivityCalculationsSuccess),
        tap(action => {
          this.alertService.success("Success");
          this.store.dispatch(getInternApplication({ applicationId: this.applicationId }));
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(saveInternActionItemSuccess),
        tap(action => {
          this.alertService.success("Success");
          this.internApplication.actions = action.actionItems.map(x => (
            {
              actionId: x.actionId,
              dateCompleted: x.dateCompleted,
              isAssignedToApplicant: x.isAssignedToApplicant,
              action: x.action,
              showResponse: x.showResponse,
              response: x.response
            }));
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(deleteInternActionItemSuccess),
        tap(action => {
          this.alertService.success("Success");
          this.internApplication.actions = action.actionItems.map(x => (
            {
              actionId: x.actionId,
              dateCompleted: x.dateCompleted,
              isAssignedToApplicant: x.isAssignedToApplicant,
              action: x.action,
              showResponse: x.showResponse,
              response: x.response
            }));
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(changeApplicationSubstatusSuccess),
        tap(action => {
          this.alertService.success("Success");
          this.store.dispatch(getInternApplication({ applicationId: this.applicationId }));
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(getInternApplicationUploadSuccess),
        tap(action => {
          downloadFile(action.doc, this.downloadFileName);
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(saveInternUploadsSuccess),
        tap(action => {
          this.alertService.success("Success");
          this.internApplication.uploads = action.uploads.map(x => ({ id: x.id, applicationId: x.applicationId, fileName: x.fileName, fileAwsBucket: x.fileAwsBucket, fileAwsKey: x.fileAwsKey }));          
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(addFBFormAssignmentSuccess),
        tap(action => {
          this.alertService.success("Success");
          this.store.dispatch(getInternApplicationReviewForms({ applicationId: this.applicationId }));
          this.toggleFormAssignmentView();
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(deleteFBFormAssignmentSuccess),
        tap(action => {
          this.alertService.success("Success");
          this.store.dispatch(getInternApplicationReviewForms({ applicationId: this.applicationId }));
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(saveInternApplicationReviewSuccess),
        tap(action => {
          this.alertService.success("Success");
          this.store.dispatch(getInternApplication({ applicationId: this.applicationId }));
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(createInternshipSuccess),
        tap(action => {
          this.alertService.success("Success");
          this.store.dispatch(getInternApplication({ applicationId: this.applicationId }));
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(getInternApplicationReviewFormsSuccess),
        tap(action => {          
          this.internApplication.reviewForms = action.forms.filter(x => !x.isDecisionForm);
          this.internApplication.decisionForms = action.forms.filter(x => x.isDecisionForm);
        })
      ).subscribe(),
    );

    this.store.dispatch(loadInternshipStatuses());
    this.store.dispatch(loadInternshipSubstatuses());
  }  

  toggleFormView() {
    this.showForm = !this.showForm;
    this.formViewButtonText = this.showForm ? "Hide Form" : "Show Form";
  }

  toggleFormAssignmentView() {
    this.showAssignForm = !this.showAssignForm;    
  }

  approveApplication() {
    if (this.applicationId > 0) {
      if (this.internApplication.statusId == 1) {
        this.alertService.confirm('Submit and Approve application?',
          'This application has not been submitted yet. You are about to submit and approve the application. Are you sure you want to approve this application?')
          .subscribe(
            answer => {
              if (answer) {
                this.store.dispatch(changeApplicationStatus({ applicationId: this.applicationId, statusId: 3 }));
              }
            }, error => {
            }
          );
      }
      else {
        this.store.dispatch(changeApplicationStatus({ applicationId: this.applicationId, statusId: 3 }));
      }          
    }
    else {
      this.alertService.error("Application not found. Please refresh the page. Submit a support ticket if the error persists.");
    }
  }

  denyApplication() {
    if (this.applicationId > 0) {
      if (this.internApplication.statusId == 1) {
        this.alertService.confirm('Submit and Deny application?',
          'This application has not been submitted yet. You are about to submit and deny the application. Are you sure you want to deny this application?')
          .subscribe(
            answer => {
              if (answer) {
                this.store.dispatch(changeApplicationStatus({ applicationId: this.applicationId, statusId: 4 }));
              }
            }, error => {
            }
          );
      }
      else {
        this.store.dispatch(changeApplicationStatus({ applicationId: this.applicationId, statusId: 4 }));
      }
    }
    else {
      this.alertService.error("Application not found. Please refresh the page. Submit a support ticket if the error persists.");
    }
  }

  updateCalculations() {
    if (this.applicationId > 0) {
      this.store.dispatch(updateHourAndProductivityCalculations({ applicationId: this.applicationId }));
    }
    else {
      this.alertService.error("Application not found. Please refresh the page. Submit a support ticket if the error persists.");
    }
  }

  editActionItem(item?: InternApplicationActionModel) {
    const actionId = typeof item !== 'undefined' && typeof item.actionId !== 'undefined' ? item.actionId : 0;
    this.store.dispatch(showEditInternActionItem({ actionId: actionId, actionApplicationId: this.applicationId }));
  }

  toggleShowResponse(item?: InternApplicationActionModel) {
    item.showResponse = !item.showResponse;
  }

  editApplicationUploads() {    
    this.store.dispatch(showEditInternUploads({ applicationId: this.applicationId }));
  }

  downloadUploadedFile(upload: InternUploadModel) {
    if (!this.loadingDownload) {
      this.downloadFileName = upload.fileName;
      this.store.dispatch(getInternApplicationUpload({ uploadId: upload.id }));
    }
  }  

  getStatusClass(item: InternApplicationModel) {
    var classString = "col-8 ";
    if (item === null || typeof item.statusId === 'undefined') {
      return classString;
    }

    if (item.statusId === 3) { //approved
      classString += "text-success";
    }
    else if (item.statusId === 4) { //denied
      classString += "red-text";
    }

    return classString;
  }

  getSubstatuses() {
    this.availableSubstatuses = this.allSubstatuses.filter((r) => r.value === 0 || (r.filterId == this.internApplication.statusId && (r.archived == false || r.value == this.originalSubstatusId)));
  }

  getSubstatusShow() {
    return this.availableSubstatuses.length > 1;
  }

  changeSubstatus() {
    this.store.dispatch(changeApplicationSubstatus({ applicationId: this.applicationId, substatusId: this.internApplication.substatusId }));
  }

  newReviewerFormAssignment() {
    this.isInternDecisionForm = false;
    if (!this.showAssignForm) {
      this.toggleFormAssignmentView();
    }
  }

  newDecisionFormAssignment() {
    this.isInternDecisionForm = true;
    if (!this.showAssignForm) {
      this.toggleFormAssignmentView();
    }
  }

  editFormAssignment(item?: InternReviewFormModel) { //need to make sure the user can fill out the form....
    if (typeof item.formId === 'undefined' || item.formId === null || item.formId <= 0) {
      this.alertService.error("Form not found. Please submit a support ticket");
      return;
    }
    const url = item.formSubmissionId > 0 ?
      this.router.serializeUrl(this.router.createUrlTree(['../../../form-filling/fill/' + item.formId + '/' + item.formSubmissionId], { relativeTo: this.route })) :
      this.router.serializeUrl(this.router.createUrlTree(['../../../form-filling/fill/' + item.formId], { relativeTo: this.route }));
    

    window.open(url, "_blank");
  }

  deleteReviewForm(item?: InternReviewFormModel) {
    if (typeof item.formAssignmentId === 'undefined' || item.formAssignmentId === null || item.formAssignmentId <= 0) {
      this.alertService.error("Form not found. Please submit a support ticket");
      return;
    }

    this.alertService.confirm('Delete?',
      'Are you sure you want to delete this form assignment?')
      .subscribe(
        answer => {
          if (answer) {
            this.store.dispatch(deleteFBFormAssignment({ formAssignmentID: item.formAssignmentId }));
          }
        }, error => {
        }
      );    
  }

  downloadInternApplication() {
    if (this.internApplication.documentToSignId == null || this.internApplication.documentToSignId <= 0) {
      return;
    }

    this.store.dispatch(loadInternApplicationDocToSign({ documentToSignId: this.internApplication.documentToSignId }));
    const appDocBuffer = this.store.select(s => s.internState.docArrayBuffer).subscribe(docArrayBuffer => {
      if (docArrayBuffer) {
        let fileName = 'Internship Application';
        if (this.internApplication.formName != null) {
          fileName = this.internApplication.formName;
        }
        if (this.internApplication.firstName !== null && this.internApplication.lastName !== null) {
          fileName = fileName + ' - ' + this.internApplication.firstName + ' ' + this.internApplication.lastName;
        }
        fileName = fileName + ".pdf";
        downloadFile(docArrayBuffer, fileName);
        appDocBuffer.unsubscribe();
      }
    });
  }

  downloadDisc() {
    if (this.internApplication.discUploadId == null || this.internApplication.discUploadId <= 0) {
      return;
    }

    this.store.dispatch(downloadOnboardingUpload({ onboardingUploadFileId: this.internApplication.discUploadId }));
    const subDocBuffer = this.store.select(s => s.onboardingState.docArrayBuffer).subscribe(docArrayBuffer => {
      if (docArrayBuffer) {
        let fileName = 'DISC';
        if (this.internApplication.firstName !== null && this.internApplication.lastName !== null) {
          fileName = fileName + ' - ' + this.internApplication.firstName + ' ' + this.internApplication.lastName;
        }
        fileName = fileName + ".pdf";
        downloadFile(docArrayBuffer, fileName);
        subDocBuffer.unsubscribe();
      }
    });
  }

  saveReview() {
    this.store.dispatch(saveInternApplicationReview({ application: deepClone(this.internApplication) }));
  }

  createInternship() {
    this.store.dispatch(createInternship({ applicationId: this.applicationId }));
  }

  showCreateButton() {
    if (this.originalStatusId !== 3 && this.originalStatusId !== 6) { //approved, complete
      return false;
    }

    return !this.internApplication.hasInternship;
  }
}
