import { FBFormModel } from './../../../models/formBuilderModels';
import { PagingResultsModel } from './../../../models/pagingResultsModel';
import { UserModel } from 'src/app/models/userModel';
import { OfferLetterModel, OfferLetterWithUserModel } from './../../../models/offerLetterModel';
import { createReducer, on, Action } from '@ngrx/store';
import {
  loadOfferLetterDocSuccess, loadOfferLetterDoc, signOfferLetterDoc,
  signOfferLetterDocSuccess, updateCreateOfferletterForm, createOfferLetter, createOfferLetterSuccess,
  getOfferLettersList, getOfferLettersListSuccess, getOfferLetterPdf, getOfferLetterPdfSuccess,
  createOfferLetterFail, previewOfferLetter, previewOfferLetterSuccess, previewOfferLetterFail,
  getOfferLettersInfo, getOfferLettersInfoSuccess, saveOfferLetterSuccess, saveOfferLetterFail, saveOfferLetter, clearOfferletter, markOfferLetterSeenSuccess, markOfferLetterSeen, signOfferLetterDocFail, checkUsername, checkUsernameSuccess
  ,getOfferletterAvailableFormsSuccess
} from './offerletter.actions';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FormError } from 'src/app/models/utilModels';
import { deepClone } from 'src/app/helpers/utils';

export interface OfferLetterState {
  loadingDoc: boolean;
  loadingSign: boolean;
  docArrayBuffer: ArrayBuffer;
  docSignedArrayBuffer: ArrayBuffer;
  offerletterWithUser: OfferLetterWithUserModel;
  creatingOfferletter: boolean;
  previewingOfferletter: boolean;
  docOfferLetterPreviewed: ArrayBuffer;
  docOfferLetterCreated: ArrayBuffer;
  formErrors: FormError[];
  usersList: UserModel[];
  loadingUsersList: boolean;
  offerLettersList: PagingResultsModel<OfferLetterWithUserModel>;
  loadingOfferLetterList: boolean;

  loadingOfferLetter: boolean;
  usernameTaken: boolean;

  availableForms: FBFormModel[];

}

const initialState: OfferLetterState = {
  loadingDoc: false,
  loadingSign: false,
  docArrayBuffer: null,
  docSignedArrayBuffer: null,
  offerletterWithUser: { user: {} as UserModel, offerLetter: {} as OfferLetterModel },
  formErrors: [],
  creatingOfferletter: false,
  docOfferLetterCreated: null,
  previewingOfferletter: false,
  docOfferLetterPreviewed: null,
  loadingUsersList: false,
  usersList: null,
  offerLettersList: null,
  loadingOfferLetterList: false,
  loadingOfferLetter: false,
  usernameTaken: false,
  availableForms: null,
};

export const offerLetterReducer = createReducer(initialState,
  on(loadOfferLetterDoc, (state, action) => {
    return {
      ...state,
      loadingDoc: true
    };
  }),
  on(loadOfferLetterDocSuccess, (state, action) => {
    return {
      ...state,
      docArrayBuffer: action.doc,
      loadingDoc: false
    };
  }),
  on(signOfferLetterDoc, (state, action) => {
    return {
      ...state,
      loadingSign: true
    };
  }),
  on(signOfferLetterDocSuccess, (state, action) => {
    return {
      ...state,
      docSignedArrayBuffer: action.docSigned,
      loadingSign: false
    };
  }),
  on(signOfferLetterDocFail, (state, action) => {
    return {
      ...state,
      loadingSign: false
    };
  }),
  on(updateCreateOfferletterForm, (state, action) => {
    const newUser = { ...state.offerletterWithUser.user, ...action.userValues };
    const newOfferLetter = { ...state.offerletterWithUser.offerLetter, ...action.offerValues };
    const newOfferletterWithUser = { ...state.offerletterWithUser, ...{ user: newUser, offerLetter: newOfferLetter } };
    const newState = { ...state, ...{ offerletterWithUser: newOfferletterWithUser, formErrors: action.formErrors } };
    return newState;
  }),

  on(createOfferLetter, (state, action) => {
    return {
      ...state,
      creatingOfferletter: true
    };
  }),
  on(createOfferLetterSuccess, (state, action) => {
    return {
      ...state,
      offerLetterCreated: action.doc,
      creatingOfferletter: false
    };
  }),
  on(createOfferLetterFail, (state, action) => {
    return {
      ...state,
      offerLetterCreated: null,
      creatingOfferletter: false
    };
  }),
  on(previewOfferLetter, (state, action) => {
    return {
      ...state,
      previewingOfferletter: true
    };
  }),
  on(previewOfferLetterSuccess, (state, action) => {
    return {
      ...state,
      docOfferLetterPreviewed: action.doc,
      previewingOfferletter: false
    };
  }),
  on(previewOfferLetterFail, (state, action) => {
    return {
      ...state,
      docOfferLetterPreviewed: null,
      previewingOfferletter: false
    };
  }),
  on(getOfferLettersList, (state, action) => {
    return {
      ...state,
      loadingOfferLetterList: true
    };
  }),
  on(getOfferLettersListSuccess, (state, action) => {
    return {
      ...state,
      offerLettersList: action.offerLetterList,
      loadingOfferLetterList: false
    };
  }),

  on(getOfferLetterPdf, (state, action) => {
    return {
      ...state,
      loadingDoc: true
    };
  }),
  on(getOfferLetterPdfSuccess, (state, action) => {
    return {
      ...state,
      docArrayBuffer: action.doc,
      loadingDoc: false
    };
  }),
  on(getOfferLettersInfo, (state, action) => {
    return {
      ...state,
      loadingOfferLetter: true
    };
  }),
  on(getOfferLettersInfoSuccess, (state, action) => {
    return {
      ...state,
      offerletterWithUser: action.offerLetter,
      loadingOfferLetter: false
    };
  }),

  on(saveOfferLetter, (state, action) => {
    return {
      ...state,
      creatingOfferletter: true
    };
  }),
  on(saveOfferLetterSuccess, (state, action) => {
    return {
      ...state,
      offerletterWithUser: action.offerletterWithUser,
      creatingOfferletter: false
    };
  }),
  on(saveOfferLetterFail, (state, action) => {
    return {
      ...state,
      offerLetterCreated: null,
      creatingOfferletter: false
    };
  }),

  on(clearOfferletter, (state, action) => {
    return {
      ...state,
      offerletterWithUser: { user: {}, offerLetter: { prospectiveCandidateID: action.prospectiveCandidateID } }
    };
  }),

  on(markOfferLetterSeen, (state, action) => {
    return {
      ...state,
      loadingOfferLetterList: true
    };
  }),

  on(markOfferLetterSeenSuccess, (state, action) => {
    const offerLettersList: PagingResultsModel<OfferLetterWithUserModel> = deepClone(state.offerLettersList);
    const offerLetterIndex = offerLettersList.result.findIndex(x => x.offerLetter.id === action.offerLetter.offerLetter.id);
    if (offerLetterIndex >= 0) {
      offerLettersList.result[offerLetterIndex] = deepClone(action.offerLetter);
    }
    return {
      ...state,
      offerLettersList,
      loadingOfferLetterList: false
    };
  }),
  on(checkUsernameSuccess, (state, action) => {
    return {
      ...state,
      usernameTaken: action.usernameTaken,
    };
  }),

  on(getOfferletterAvailableFormsSuccess, (state, action) => {
    return {
      ...state,
      availableForms: action.availableForms,
    };
  }),

);

export function offerLetterReducerFunc(state: OfferLetterState | undefined, action: Action) {
  return offerLetterReducer(state, action);
}

export function initCreateOfferletterForm(offerletterWithUser: OfferLetterWithUserModel) {
  const form = new FormGroup({
    user: new FormGroup({
      id: new FormControl(offerletterWithUser.user.id, []),
      email: new FormControl(offerletterWithUser.user.email),
      personalEmail: new FormControl(offerletterWithUser.user.personalEmail, [Validators.required, Validators.maxLength(255), Validators.email]),
      username: new FormControl(offerletterWithUser.user.userName, [Validators.required, Validators.maxLength(255)]),
      password: new FormControl(offerletterWithUser.user.password, [Validators.required, Validators.maxLength(255)]),
      firstName: new FormControl(offerletterWithUser.user.firstName, [Validators.required, Validators.maxLength(255)]),
      lastName: new FormControl(offerletterWithUser.user.lastName, [Validators.required, Validators.maxLength(255)]),
    }),
    offerLetter: new FormGroup({
      formID: new FormControl(offerletterWithUser.offerLetter.formID, []),
      prospectiveCandidate: new FormControl(!!offerletterWithUser.offerLetter.prospectiveCandidateID, []),
      prospectiveCandidateID: new FormControl(offerletterWithUser.offerLetter.prospectiveCandidateID, []),
      existingUser: new FormControl(!!offerletterWithUser.offerLetter.existingUser, []),
      reportingToUserIDs: new FormControl(offerletterWithUser.offerLetter.reportingToUserIDs,
        [Validators.required]),
      validUntilDate: new FormControl(offerletterWithUser.offerLetter.validUntilDate, [Validators.required]),
      typePosition: new FormControl(offerletterWithUser.offerLetter.typePosition ? (IsValidTyePosition(offerletterWithUser.offerLetter.typePosition) ? offerletterWithUser.offerLetter.typePosition : 'Other') : '',
        [Validators.required, Validators.maxLength(255)]),
      typePositionOther: new FormControl(offerletterWithUser.offerLetter.typePosition, [Validators.maxLength(255)]),
      serviceRegion: new FormControl(offerletterWithUser.offerLetter.serviceRegion, [Validators.required, Validators.maxLength(255)]),
      requiredBilledHours: new FormControl(offerletterWithUser.offerLetter.requiredBilledHours,
        [Validators.required, Validators.pattern('^([0-9]*[.])?[0-9]+$')]),
      salaryPayRate: new FormControl(offerletterWithUser.offerLetter.salaryPayRate,
        [Validators.required, Validators.pattern('^([0-9]*[.])?[0-9]+$')]),
      studentLoanRepayment: new FormControl(offerletterWithUser.offerLetter.studentLoanRepayment,
        [Validators.required, Validators.pattern('^([0-9]*[.])?[0-9]+$')]),
      cellphoneStipend: new FormControl(offerletterWithUser.offerLetter.cellphoneStipend,
        [Validators.required, Validators.pattern('^([0-9]*[.])?[0-9]+$')]),
      gasCard: new FormControl(offerletterWithUser.offerLetter.gasCard,
        [Validators.required, Validators.pattern('^([0-9]*[.])?[0-9]+$')]),
      professionalLiabilityInsurance: new FormControl(!offerletterWithUser.offerLetter.id ? 300 :
        (offerletterWithUser.offerLetter.professionalLiabilityInsurance
          ? offerletterWithUser.offerLetter.professionalLiabilityInsurance
          : 0),
        [Validators.required, Validators.pattern('^([0-9]*[.])?[0-9]+$')]),
      flexibleSchedule: new FormControl(offerletterWithUser.offerLetter.flexibleSchedule,
        [Validators.required, Validators.pattern('^[0-9]+$'), Validators.min(15)]),
      healthBenefitAnnualCost: new FormControl(offerletterWithUser.offerLetter.healthBenefitAnnualCost
        ? offerletterWithUser.offerLetter.healthBenefitAnnualCost
        : 0,
        [Validators.required, Validators.pattern('^([0-9]*[.])?[0-9]+$')]),
      relocationStipend: new FormControl(offerletterWithUser.offerLetter.relocationStipend,
        [Validators.required, Validators.pattern('^([0-9]*[.])?[0-9]+$')]),
      eletronicDevices: new FormControl(offerletterWithUser.offerLetter.eletronicDevices
        ? offerletterWithUser.offerLetter.eletronicDevices
        : 0,
        [Validators.required, Validators.pattern('^([0-9]*[.])?[0-9]+$')]),
      receivingSupervision: new FormControl(offerletterWithUser.offerLetter.receivingSupervision
        ? offerletterWithUser.offerLetter.receivingSupervision
        : 0,
        [Validators.required, Validators.pattern('^([0-9]*[.])?[0-9]+$')]),
      tuitionAssistanceBenefit: new FormControl(offerletterWithUser.offerLetter.tuitionAssistanceBenefit
        ? offerletterWithUser.offerLetter.tuitionAssistanceBenefit
        : 0,
        [Validators.required, Validators.pattern('^([0-9]*[.])?[0-9]+$')]),
      bonusByTypePosition: new FormControl(offerletterWithUser.offerLetter.bonusByTypePosition,
        [Validators.required, Validators.pattern('^([0-9]*[.])?[0-9]+$')]),
      plan401k: new FormControl(offerletterWithUser.offerLetter.plan401k,
        [Validators.required, Validators.pattern('^([0-9]*[.])?[0-9]+$')]),
      pto: new FormControl(offerletterWithUser.offerLetter.pto, [Validators.required, Validators.pattern('^([0-9]*[.])?[0-9]+$')]),
      holiday: new FormControl(offerletterWithUser.offerLetter.holiday, [Validators.required, Validators.pattern('^([0-9]*[.])?[0-9]+$')]),
      salaryPayRateAfterMonths: new FormControl(offerletterWithUser.offerLetter.salaryPayRateAfterMonths,
        [Validators.required, Validators.pattern('^([0-9]*[.])?[0-9]+$')]),
      otherBenefitName: new FormControl(offerletterWithUser.offerLetter.otherBenefitName, [Validators.required, Validators.maxLength(2000)]),
      otherBenefitAmount: new FormControl(offerletterWithUser.offerLetter.otherBenefitAmount,
        [Validators.required, Validators.pattern('^([0-9]*[.])?[0-9]+$')]),
      salaryOrPayRate: new FormControl(offerletterWithUser.offerLetter.salaryOrPayRate
        ? offerletterWithUser.offerLetter.salaryOrPayRate
        : true, [Validators.required]),
      regulatoryOversight: new FormControl(offerletterWithUser.offerLetter.regulatoryOversight
        ? offerletterWithUser.offerLetter.regulatoryOversight
        : 0,
        [Validators.required, Validators.pattern('^([0-9]*[.])?[0-9]+$')]),


      medClinic: new FormControl(offerletterWithUser.offerLetter.medClinic
        ? offerletterWithUser.offerLetter.medClinic
        : 0,
        [Validators.required, Validators.pattern('^([0-9]*[.])?[0-9]+$')]),
      mentalHealth: new FormControl(offerletterWithUser.offerLetter.mentalHealth
        ? offerletterWithUser.offerLetter.mentalHealth
        : 0,
        [Validators.required, Validators.pattern('^([0-9]*[.])?[0-9]+$')]),
      gym: new FormControl(offerletterWithUser.offerLetter.gym
        ? offerletterWithUser.offerLetter.gym
        : 0,
        [Validators.required, Validators.pattern('^([0-9]*[.])?[0-9]+$')]),

      studentLoanRepaymentCheck: new FormControl(offerletterWithUser.offerLetter.studentLoanRepayment > 0),
      cellphoneStipendCheck: new FormControl(offerletterWithUser.offerLetter.cellphoneStipend > 0),
      gasCardCheck: new FormControl(offerletterWithUser.offerLetter.gasCard > 0),
      flexibleScheduleCheck: new FormControl(offerletterWithUser.offerLetter.flexibleSchedule > 0),
      healthBenefitAnnualCostCheck: new FormControl(offerletterWithUser.offerLetter.healthBenefitAnnualCost > 0),
      relocationStipendCheck: new FormControl(offerletterWithUser.offerLetter.relocationStipend > 0),
      eletronicDevicesCheck: new FormControl(offerletterWithUser.offerLetter.eletronicDevices > 0),
      receivingSupervisionCheck: new FormControl(offerletterWithUser.offerLetter.receivingSupervision > 0),
      tuitionAssistanceBenefitCheck: new FormControl(offerletterWithUser.offerLetter.tuitionAssistanceBenefit > 0),
      otherBenefitNameCheck: new FormControl(!!offerletterWithUser.offerLetter.otherBenefitName),
      salaryPayRateAfterMonthsCheck: new FormControl(offerletterWithUser.offerLetter.salaryPayRateAfterMonths > 0),
      regulatoryOversightCheck: new FormControl(offerletterWithUser.offerLetter.regulatoryOversight > 0),
      medClinicCheck: new FormControl(offerletterWithUser.offerLetter.medClinic > 0),
      mentalHealthCheck: new FormControl(offerletterWithUser.offerLetter.mentalHealth > 0),
      gymCheck: new FormControl(offerletterWithUser.offerLetter.gym > 0),

      ptoPercentage: new FormControl(offerletterWithUser.offerLetter.id ? offerletterWithUser.offerLetter.ptoPercentage : 2),
      monthsAfter: new FormControl(offerletterWithUser.offerLetter.id ? offerletterWithUser.offerLetter.monthsAfter : 12),


      bonusByTypePositionCheck: new FormControl(offerletterWithUser.offerLetter.bonusByTypePosition > 0),

      ptoCheck: new FormControl(offerletterWithUser.offerLetter.pto > 0),
      holidayCheck: new FormControl(offerletterWithUser.offerLetter.holiday > 0),
      plan401kCheck: new FormControl(offerletterWithUser.offerLetter.plan401k > 0),
      professionalLiabilityInsuranceCheck: new FormControl(offerletterWithUser.offerLetter.professionalLiabilityInsurance > 0),

      requireTranscripts: new FormControl(offerletterWithUser.offerLetter.requireTranscripts),
      requireDiploma: new FormControl(offerletterWithUser.offerLetter.requireDiploma),
      requireLicenses: new FormControl(offerletterWithUser.offerLetter.requireLicenses),

      orientationDate: new FormControl(offerletterWithUser.offerLetter.orientationDate),
      jobTitleID: new FormControl(offerletterWithUser.offerLetter.jobTitleID, [Validators.required]),
      position: new FormControl(offerletterWithUser.offerLetter.position),



      studentLoanRepaymentHideAmount: new FormControl(offerletterWithUser.offerLetter.studentLoanRepaymentHideAmount),
      cellphoneStipendHideAmount: new FormControl(offerletterWithUser.offerLetter.cellphoneStipendHideAmount),
      gasCardHideAmount: new FormControl(offerletterWithUser.offerLetter.gasCardHideAmount),
      professionalLiabilityInsuranceHideAmount: new FormControl(offerletterWithUser.offerLetter.professionalLiabilityInsuranceHideAmount),
      healthBenefitAnnualCostHideAmount: new FormControl(offerletterWithUser.offerLetter.healthBenefitAnnualCostHideAmount),
      relocationStipendHideAmount: new FormControl(offerletterWithUser.offerLetter.relocationStipendHideAmount),
      eletronicDevicesHideAmount: new FormControl(offerletterWithUser.offerLetter.eletronicDevicesHideAmount),
      receivingSupervisionHideAmount: new FormControl(offerletterWithUser.offerLetter.receivingSupervisionHideAmount),
      tuitionAssistanceBenefitHideAmount: new FormControl(offerletterWithUser.offerLetter.tuitionAssistanceBenefitHideAmount),
      bonusByTypePositionHideAmount: new FormControl(offerletterWithUser.offerLetter.bonusByTypePositionHideAmount),
      plan401kHideAmount: new FormControl(offerletterWithUser.offerLetter.plan401kHideAmount),
      ptoHideAmount: new FormControl(offerletterWithUser.offerLetter.ptoHideAmount),
      holidayHideAmount: new FormControl(offerletterWithUser.offerLetter.holidayHideAmount),
      otherBenefitHideAmount: new FormControl(offerletterWithUser.offerLetter.otherBenefitHideAmount),
      regulatoryOversightHideAmount: new FormControl(offerletterWithUser.offerLetter.regulatoryOversightHideAmount),
      medClinicHideAmount: new FormControl(offerletterWithUser.offerLetter.medClinicHideAmount),
      mentalHealthHideAmount: new FormControl(offerletterWithUser.offerLetter.mentalHealthHideAmount),
      gymHideAmount: new FormControl(offerletterWithUser.offerLetter.gymHideAmount),

      studentLoanRepaymentRemoveFromEstimated: new FormControl(offerletterWithUser.offerLetter.studentLoanRepaymentRemoveFromEstimated),
      cellphoneStipendRemoveFromEstimated: new FormControl(offerletterWithUser.offerLetter.cellphoneStipendRemoveFromEstimated),
      gasCardRemoveFromEstimated: new FormControl(offerletterWithUser.offerLetter.gasCardRemoveFromEstimated),
      professionalLiabilityInsuranceRemoveFromEstimated:
        new FormControl(offerletterWithUser.offerLetter.professionalLiabilityInsuranceRemoveFromEstimated),
      healthBenefitAnnualCostRemoveFromEstimated:
        new FormControl(offerletterWithUser.offerLetter.healthBenefitAnnualCostRemoveFromEstimated),
      relocationStipendRemoveFromEstimated: new FormControl(offerletterWithUser.offerLetter.relocationStipendRemoveFromEstimated),
      eletronicDevicesRemoveFromEstimated: new FormControl(offerletterWithUser.offerLetter.eletronicDevicesRemoveFromEstimated),
      receivingSupervisionRemoveFromEstimated: new FormControl(offerletterWithUser.offerLetter.receivingSupervisionRemoveFromEstimated),
      tuitionAssistanceBenefitRemoveFromEstimated:
        new FormControl(offerletterWithUser.offerLetter.tuitionAssistanceBenefitRemoveFromEstimated),
      bonusByTypePositionRemoveFromEstimated: new FormControl(offerletterWithUser.offerLetter.bonusByTypePositionRemoveFromEstimated),
      plan401kRemoveFromEstimated: new FormControl(offerletterWithUser.offerLetter.plan401kRemoveFromEstimated),
      ptoRemoveFromEstimated: new FormControl(offerletterWithUser.offerLetter.ptoRemoveFromEstimated),
      holidayRemoveFromEstimated: new FormControl(offerletterWithUser.offerLetter.holidayRemoveFromEstimated),
      otherBenefitRemoveFromEstimated: new FormControl(offerletterWithUser.offerLetter.otherBenefitRemoveFromEstimated),
      regulatoryOversightRemoveFromEstimated: new FormControl(offerletterWithUser.offerLetter.regulatoryOversightRemoveFromEstimated),
      medClinicRemoveFromEstimated: new FormControl(offerletterWithUser.offerLetter.medClinicRemoveFromEstimated),
      mentalHealthRemoveFromEstimated: new FormControl(offerletterWithUser.offerLetter.mentalHealthRemoveFromEstimated),
      gymRemoveFromEstimated: new FormControl(offerletterWithUser.offerLetter.gymRemoveFromEstimated),

      trainingRate: new FormControl(offerletterWithUser.offerLetter.trainingRate
        ? offerletterWithUser.offerLetter.trainingRate
        : 0,
        [Validators.pattern('^([0-9]*[.])?[0-9]+$')]),
      trainingRateCheck: new FormControl(!offerletterWithUser.offerLetter.id || (!!offerletterWithUser.offerLetter.trainingRate)),

      hasProfitSharing: new FormControl(offerletterWithUser.offerLetter.hasProfitSharing),
      hasIncentivePrograms: new FormControl(offerletterWithUser.offerLetter.hasIncentivePrograms),
    })
  });

  return form;
}

export function IsValidTyePosition(position) {
  return [
    'Administrative',
    'Mental Health Clinician',
    'Behavior Clinician',
    'Behavior Technician',
    'Dually-Credentialed',
    'Psychologist',
    'Director'].indexOf(position) >= 0;
}

