<p-dialog [(visible)]="display" header="{{title}}" [modal]="true" [style]="{width: '70vw'}" (onHide)="closeModal()" [focusOnShow]="false">
  <form class="card mt-2" [formGroup]="form" (ngSubmit)="saveActionItem()">
    <div class="card-body">
      <div class="row">
        <div class="col-12">

          <div class="row mb-2">
            <div class="col-4 d-flex align-items-center">
              <b>Type</b>
            </div>
            <div class="col-8">
              <p-dropdown styleClass="form-control w-100" [options]="actionTypes" formControlName="actionTypeId" [disabled]="getIsEmployeeView()"></p-dropdown>
              <app-validation-message [form]="form" [path]="'actionTypeId'" [label]="'Action Type'"></app-validation-message>
            </div>
          </div>


          <div class="row mb-2" *ngIf="!getIsEmployeeView()">
            <div class="col-4 d-flex align-items-center">
              <b>Employee(s)</b>
              <i class="fal fa-fw fa-user-plus ml-2" style="cursor: pointer; color: blue;" (click)="addUserToActionItem()" title="Add Employee"></i>
            </div>
            <div class="col-8 mt-2">
              <div *ngIf="form">
                <div formArrayName="assignedUsers">
                  <div *ngFor="let user of getActionItemUsers(); let i=index;" class="mt-2" [formGroupName]="i">
                    <p-autoComplete [style]="{'width': '95%'}" [inputStyle]="{'width': '95%'}" formControlName="selectedUser"
                                    [suggestions]="userEmployeeList" (completeMethod)="getStaffList($event)" field="label"
                                    [minLength]="1" [autoHighlight]="true"
                                    (onSelect)="setUserAfterStaffChange($event, i)" [forceSelection]="true"
                                    (onClear)="clearUserAfterClear($event, i)"></p-autoComplete>
                    <i class="fal fa-fw fa-trash pointer" style="color: red;" (click)="removeUserFromActionItem(i)" title="Remove Employee"></i>
                  </div>
                  <p-checkbox [(ngModel)]="showOnlyActiveEmployees" [binary]="true" [ngModelOptions]="{standalone: true}"></p-checkbox>
                  <b style="margin-left: 2px;">Show only active employees</b>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-4 d-flex align-items-center">
              <b>Request</b>
            </div>
            <div class="col-8">
              <textarea style="width: 100%;" class="form-control mt-2" formControlName="request" [readonly]="getIsEmployeeView()"></textarea>
              <app-validation-message [form]="form" [path]="'request'" [label]="'Request'"></app-validation-message>
            </div>
          </div>

          <div class="row mb-2" *ngIf="actionItemId > 0">
            <div class="col-4 d-flex align-items-center">
              <b>Response</b>
            </div>
            <div class="col-8">
              <textarea style="width: 100%;" class="form-control mt-2" formControlName="response" [readonly]="getResponseReadOnly()"></textarea>
              <app-validation-message [form]="form" [path]="'response'" [label]="'Response'"></app-validation-message>
            </div>
          </div>

          <div class="row mb-2" [hidden]="!actionItemId">
            <div class="col-4 d-flex align-items-center">
              <b>Date Requested</b>
            </div>
            <div class="col-8">
              {{getDateRequested()}}
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-4 d-flex align-items-center">
              <b>Due Date</b>
            </div>
            <div class="col-8">
              <input type="date" class="form-control mt-2" formControlName="dueDateString" />
              <app-validation-message [form]="form" [path]="'dueDateString'" [label]="'Due Date'"></app-validation-message>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-4 d-flex align-items-center">
              Date Completed
            </div>
            <div class="col-8">
              {{getDateCompleted()}}
              <div>
                <p-checkbox formControlName="markCompleted" [binary]="true"></p-checkbox>
                <b style="margin-left: 2px;">Is Completed</b>
              </div>
            </div>
          </div>
          <div class="row mb-2" *ngIf="!getIsEmployeeView()">
            <div class="col-4 d-flex align-items-center">
            </div>
            <div class="col-8">
              <p-checkbox formControlName="isEmployeeAction" [binary]="true"></p-checkbox>
              <b style="margin-left: 2px;">Is Applicant Action</b>
            </div>
          </div>
          <div class="row mb-2" *ngIf="!getIsEmployeeView()">
            <div class="col-4 d-flex align-items-center">
            </div>
            <div class="col-8">
              <p-checkbox formControlName="createUpdateTask" [binary]="true"></p-checkbox>
              <b style="margin-left: 2px;">{{getCreateUpdateTaskString()}}</b>
              <div *ngIf="hasTask()" class="ml-1 btn-link pointer" style="display: inline-block" (click)="openTaskInOpto()" title="Go to task in Optomiser">
                {{getTaskString()}}
              </div>
            </div>
          </div>          
        </div>
      </div>
    </div>
  </form>
  <p-footer>
    <i class="material-icons rotate text-muted ml-2" *ngIf="disableButtons">loop</i>
    <button class="btn btn-danger" *ngIf="actionItemId > 0" type="submit" (click)="deleteActionItem()" [disabled]="disableButtons">Delete</button>
    <button class="btn btn-secondary" type="button" (click)="closeModal()">Cancel</button>
    <button class="btn btn-primary" type="submit" (click)="saveActionItem()" [disabled]="disableButtons">Save</button>
  </p-footer>
</p-dialog>
