<div class="card mb-2">
  <div class="card-body">
    <form class="" [formGroup]="form" *ngIf="form && onBoardingDoc">
      <button routerLink="/manage/list-onboardingdocs/" class="btn btn-secondary mr-2 mt-1 float-right">Back to Employee Documents </button>
      <h2 class="">Edit Document</h2>
      <div class="row">
        <div class="col-12 col-lg-6">
          <div class="row" formGroupName="onBoardingDocument">
          <div class="col-4 d-flex align-items-center">
            <b>Title</b>
          </div>
          <div class="col-8 ">
              <input id="titleInput" type="text" class="form-control mt-2" formControlName="title"/>
              <app-validation-message [form]="form" [path]="'onBoardingDocument.title'" [name]="'title'" [label]="'Title'"> </app-validation-message>
          </div>

          <div class="col-4 d-flex align-items-center">
            <b>Renew after</b>
          </div>
          <div class="col-8 d-flex align-items-center">
              <input id="renewAfterDaysInput" type="number" class="form-control mt-2 w-50 mr-2" formControlName="renewAfterDays"/> days
              <app-validation-message [form]="form" [path]="'onBoardingDocument.renewAfterDays'" [name]="'renewAfterDays'" [label]="'Renew After'"> </app-validation-message>
          </div>

          <div class="col-4 align-items-center">
            <b>Is Required </b>
            <br><small>The document will be required to be signed</small>
          </div>
          <div class="col-8 ">
              <p-checkbox [formControl]="form.get('onBoardingDocument.required')" [binary]="true" ></p-checkbox>
              <app-validation-message [form]="form" [path]="'onBoardingDocument.required'" [name]="'required'" [label]="'Is Required'"> </app-validation-message>
          </div>

          <div class="col-4 align-items-center">
            <b>Is Default Doc </b>
            <br><small>Will be assigned as default to employees</small>
          </div>
          <div class="col-8 ">
              <p-checkbox [formControl]="form.get('onBoardingDocument.isDefaultDoc')" [binary]="true" ></p-checkbox>
              <app-validation-message [form]="form" [path]="'onBoardingDocument.isDefaultDoc'" [name]="'isDefaultDoc'" [label]="'Is Default Doc'"> </app-validation-message>
          </div>

          <div class="col-4 align-items-center">
            <b>Is Credential Doc </b>
            <br><small>Will be available to assign to employees on credentialing</small>
          </div>
          <div class="col-8 ">
              <p-checkbox [formControl]="form.get('onBoardingDocument.isCredentialDoc')" [binary]="true" ></p-checkbox>
              <app-validation-message [form]="form" [path]="'onBoardingDocument.isCredentialDoc'" [name]="'isCredentialDoc'" [label]="'Is Credential Doc'"> </app-validation-message>
          </div>
          </div>
        </div>

        <div class="col-12 col-lg-6">
          <div class="row">
              <div class="col-4   align-items-center">
                <b>Roles</b>
                <br><small>Only users on this roles will  be assigned this document to sign</small>
              </div>
              <div class="col-8 ">
                <p-multiSelect #rolesMultiSelect (onFocus)="openMultiSelect(rolesMultiSelect)" autofocusFilter="true"
                class="w-100" styleClass="w-100 d-flex mt-2" inputStyleClass="form-control"
                [options]="userTypes" formControlName="roles" [defaultLabel]="'Select roles...'"></p-multiSelect>
                <app-validation-message [form]="form" [path]="'roles'" [name]="'roles'" [label]="'Roles'"> </app-validation-message>
              </div>

              <div class="col-4   align-items-center">
                <b>Not in Roles</b>
                <br><small>Only users not on this roles will be assigned this document to sign</small>
              </div>
              <div class="col-8 ">
                <p-multiSelect #notInRolesMultiSelect (onFocus)="openMultiSelect(notInRolesMultiSelect)" autofocusFilter="true"
                class="w-100" styleClass="w-100 d-flex mt-2" inputStyleClass="form-control"
                [options]="userTypes" formControlName="notInRoles" [defaultLabel]="'Select roles...'"></p-multiSelect>
                <app-validation-message [form]="form" [path]="'notInRoles'" [name]="'notInRoles'" [label]="'Not In Roles'"> </app-validation-message>
              </div>

              <div class="col-4   align-items-center">
                <b>Required Roles</b>
                <br><small>Only users on this roles will be required to sign the document</small>
              </div>
              <div class="col-8 ">
                <p-multiSelect #requiredRolesMultiSelect (onFocus)="openMultiSelect(requiredRolesMultiSelect)" autofocusFilter="true"
                class="w-100" styleClass="w-100 d-flex mt-2" inputStyleClass="form-control"
                [options]="userTypes" formControlName="requiredRoles" [defaultLabel]="'Select roles...'"></p-multiSelect>
                <app-validation-message [form]="form" [path]="'requiredRoles'" [name]="'requiredRoles'" [label]="'Required Roles'"> </app-validation-message>
              </div>

              <div class="col-4   align-items-center">
                <b>Not Required Roles</b>
                <br><small>Only users on this roles will not be required to sign the document</small>
              </div>
              <div class="col-8 ">
                <p-multiSelect #notRequiredRolesMultiSelect (onFocus)="openMultiSelect(notRequiredRolesMultiSelect)" autofocusFilter="true"
                class="w-100" styleClass="w-100 d-flex mt-2" inputStyleClass="form-control"
                [options]="userTypes" formControlName="notRequiredRoles" [defaultLabel]="'Select roles...'"></p-multiSelect>
                <app-validation-message [form]="form" [path]="'notRequiredRoles'" [name]="'notRequiredRoles'" [label]="'Not Required Roles'"> </app-validation-message>
              </div>


          </div>
        </div>
      </div>
      <button class="btn btn-primary mt-3" id="saveButton" (click)="save()">Save</button>
    </form>
  </div>
</div>
