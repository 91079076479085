import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { State } from '../store';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class InternGuard implements CanActivate {
  constructor(private router: Router,
    private store: Store<State>,) { }

  canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    if (localStorage.getItem('currentUser')) {
      // logged in so return true
      let hasPermission = false;

      this.store.select(s => s.accountState.user.permissionIDs).subscribe(permissionIDs => {
        hasPermission = permissionIDs && !!permissionIDs.find(p => p === 3127 //view only
          || p === 3128 //manage
        );
      });

      return hasPermission;
    } else {

      // not logged in so redirect to login page with the return url
      if (state.url) {
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      } else {
        this.router.navigate(['/login']);
      }
    }
    return false;
  }
}
