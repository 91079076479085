<div [formGroup]="form" *ngIf="profile!=null">
  <h1>I-9 Form</h1>
  <h4 class="card-title mb-0">Update needed information for the i9 Form document</h4>
      (the info will be saved on the user profile and used to fill the document)
  <div class="row mt-3">
    <div class="col-12 col-xl-12">

      <div class="row" >
        <div class="col-12">
          <h5>Employer or Authorized Representative Review and Verification</h5>
          Employers or their authorized representative must complete and sign Section 2 within 3 business days of the employee's first day of employment. You
must physically examine one document from List A OR a combination of one document from List B and one document from List C as listed on the "Lists
of Acceptable Documents.
        </div>
      </div>
      <div class="row mt-2" >
        <div class="col-12 col-sm-4">
         <div class="w-100 text-center"><h5>List A</h5></div>

          <div class="row" formGroupName="employee">
            <div class="col-4 d-flex align-items-center">
              <b>Document Title</b>
            </div>
            <div class="col-8">
              <input
                type="text"
                class="form-control mt-2"
                formControlName="i9ListA1DocumentTitle"
              />
              <app-validation-message
                [form]="form"
                [path]="'employee.i9ListA1DocumentTitle'"
                [name]="'i9ListA1DocumentTitle'"
                [label]="'Document Title'"
              >
              </app-validation-message>
            </div>
          </div>

          <div class="row" formGroupName="employee">
            <div class="col-4 d-flex align-items-center">
              <b>Issuing Authority</b>
            </div>
            <div class="col-8">
              <input
                type="text"
                class="form-control mt-2"
                formControlName="i9ListA1IssuingAuthority"
              />
              <app-validation-message
                [form]="form"
                [path]="'employee.i9ListA1IssuingAuthority'"
                [name]="'i9ListA1IssuingAuthority'"
                [label]="'Issuing Authority'"
              >
              </app-validation-message>
            </div>
          </div>
          <div class="row" formGroupName="employee">
            <div class="col-4 d-flex align-items-center">
              <b>Document Number</b>
            </div>
            <div class="col-8">
              <input
                type="text"
                class="form-control mt-2"
                formControlName="i9ListA1DocumentNumber"
              />
              <app-validation-message
                [form]="form"
                [path]="'employee.i9ListA1DocumentNumber'"
                [name]="'i9ListA1DocumentNumber'"
                [label]="'Document Number'"
              >
              </app-validation-message>
            </div>
          </div>
          <div class="row" formGroupName="employee">
            <div class="col-4 d-flex align-items-center">
              <b>Document Expiration Date</b>
            </div>
            <div class="col-8">
              <p-calendar
              [disabled]="i9ListA1ExpirationDateNA"
                inputStyleClass="form-control mt-2"
                [monthNavigator]="true"
                [yearNavigator]="true"
                [yearRange]="yearRange"
                styleClass="w-75"
                panelStyleClass="calendarPanel"
                [hourFormat]="12"
                [showTime]="false"
                id="i9ListA1ExpirationDate"
                name="i9ListA1ExpirationDate"
                dataType="string"
                formControlName="i9ListA1ExpirationDate"
              ></p-calendar>
              <div class="d-inline-block ml-2">
                <p-checkbox  [(ngModel)]="i9ListA1ExpirationDateNA" (ngModelChange)="eraseDate(i9ListA1ExpirationDateNA,'i9ListA1ExpirationDate')" [ngModelOptions]="{standalone: true}" [binary]="true" ></p-checkbox>
                N/A
              </div>
              <app-validation-message
                [form]="form"
                [path]="'employee.i9ListA1ExpirationDate'"
                [name]="'i9ListA1ExpirationDate'"
                [label]="'Expiration Date'"
              >
              </app-validation-message>
            </div>
          </div>
          <hr/>

          <div class="row" formGroupName="employee">
            <div class="col-4 d-flex align-items-center">
              <b>Document Title</b>
            </div>
            <div class="col-8">
              <input
                type="text"
                class="form-control mt-2"
                formControlName="i9ListA2DocumentTitle"
              />
              <app-validation-message
                [form]="form"
                [path]="'employee.i9ListA2DocumentTitle'"
                [name]="'i9ListA2DocumentTitle'"
                [label]="'Document Title'"
              >
              </app-validation-message>
            </div>
          </div>

          <div class="row" formGroupName="employee">
            <div class="col-4 d-flex align-items-center">
              <b>Issuing Authority</b>
            </div>
            <div class="col-8">
              <input
                type="text"
                class="form-control mt-2"
                formControlName="i9ListA2IssuingAuthority"
              />
              <app-validation-message
                [form]="form"
                [path]="'employee.i9ListA2IssuingAuthority'"
                [name]="'i9ListA2IssuingAuthority'"
                [label]="'Issuing Authority'"
              >
              </app-validation-message>
            </div>
          </div>
          <div class="row" formGroupName="employee">
            <div class="col-4 d-flex align-items-center">
              <b>Document Number</b>
            </div>
            <div class="col-8">
              <input
                type="text"
                class="form-control mt-2"
                formControlName="i9ListA2DocumentNumber"
              />
              <app-validation-message
                [form]="form"
                [path]="'employee.i9ListA2DocumentNumber'"
                [name]="'i9ListA2DocumentNumber'"
                [label]="'Document Number'"
              >
              </app-validation-message>
            </div>
          </div>
          <div class="row" formGroupName="employee">
            <div class="col-4 d-flex align-items-center">
              <b>Document Expiration Date</b>
            </div>
            <div class="col-8">
              <p-calendar
              [disabled]="i9ListA2ExpirationDateNA"
                inputStyleClass="form-control mt-2"
                [monthNavigator]="true"
                [yearNavigator]="true"
                [yearRange]="yearRange"
                styleClass="w-75"
                panelStyleClass="calendarPanel"
                [hourFormat]="12"
                [showTime]="false"
                id="i9ListA2ExpirationDate"
                name="i9ListA2ExpirationDate"
                dataType="string"
                formControlName="i9ListA2ExpirationDate"
              ></p-calendar>
              <div class="d-inline-block ml-2">
                <p-checkbox  [(ngModel)]="i9ListA2ExpirationDateNA" (ngModelChange)="eraseDate(i9ListA2ExpirationDateNA,'i9ListA2ExpirationDate')" [ngModelOptions]="{standalone: true}" [binary]="true" ></p-checkbox>
                N/A
              </div>
              <app-validation-message
                [form]="form"
                [path]="'employee.i9ListA2ExpirationDate'"
                [name]="'i9ListA2ExpirationDate'"
                [label]="'Expiration Date'"
              >
              </app-validation-message>
            </div>
          </div>
          <hr/>

          <div class="row" formGroupName="employee">
            <div class="col-4 d-flex align-items-center">
              <b>Document Title</b>
            </div>
            <div class="col-8">
              <input
                type="text"
                class="form-control mt-2"
                formControlName="i9ListA3DocumentTitle"
              />
              <app-validation-message
                [form]="form"
                [path]="'employee.i9ListA3DocumentTitle'"
                [name]="'i9ListA3DocumentTitle'"
                [label]="'Document Title'"
              >
              </app-validation-message>
            </div>
          </div>

          <div class="row" formGroupName="employee">
            <div class="col-4 d-flex align-items-center">
              <b>Issuing Authority</b>
            </div>
            <div class="col-8">
              <input
                type="text"
                class="form-control mt-2"
                formControlName="i9ListA3IssuingAuthority"
              />
              <app-validation-message
                [form]="form"
                [path]="'employee.i9ListA3IssuingAuthority'"
                [name]="'i9ListA3IssuingAuthority'"
                [label]="'Issuing Authority'"
              >
              </app-validation-message>
            </div>
          </div>
          <div class="row" formGroupName="employee">
            <div class="col-4 d-flex align-items-center">
              <b>Document Number</b>
            </div>
            <div class="col-8">
              <input
                type="text"
                class="form-control mt-2"
                formControlName="i9ListA3DocumentNumber"
              />
              <app-validation-message
                [form]="form"
                [path]="'employee.i9ListA3DocumentNumber'"
                [name]="'i9ListA3DocumentNumber'"
                [label]="'Document Number'"
              >
              </app-validation-message>
            </div>
          </div>
          <div class="row" formGroupName="employee">
            <div class="col-4 d-flex align-items-center">
              <b>Document Expiration Date</b>
            </div>
            <div class="col-8">
              <p-calendar
              [disabled]="i9ListA3ExpirationDateNA"
                inputStyleClass="form-control mt-2"
                [monthNavigator]="true"
                [yearNavigator]="true"
                [yearRange]="yearRange"
                styleClass="w-75"
                panelStyleClass="calendarPanel"
                [hourFormat]="12"
                [showTime]="false"
                id="i9ListA3ExpirationDate"
                name="i9ListA3ExpirationDate"
                dataType="string"
                formControlName="i9ListA3ExpirationDate"
              ></p-calendar>
              <div class="d-inline-block ml-2">
                <p-checkbox  [(ngModel)]="i9ListA3ExpirationDateNA" (ngModelChange)="eraseDate(i9ListA3ExpirationDateNA,'i9ListA3ExpirationDate')" [ngModelOptions]="{standalone: true}" [binary]="true" ></p-checkbox>
                N/A
              </div>
              <app-validation-message
                [form]="form"
                [path]="'employee.i9ListA3ExpirationDate'"
                [name]="'i9ListA3ExpirationDate'"
                [label]="'Expiration Date'"
              >
              </app-validation-message>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-4">
         <div class="w-100 text-center"><h5>List B</h5></div>

          <div class="row" formGroupName="employee">
            <div class="col-4 d-flex align-items-center">
              <b>Document Title</b>
            </div>
            <div class="col-8">
              <input
                type="text"
                class="form-control mt-2"
                formControlName="i9ListBDocumentTitle"
              />
              <app-validation-message
                [form]="form"
                [path]="'employee.i9ListBDocumentTitle'"
                [name]="'i9ListBDocumentTitle'"
                [label]="'Document Title'"
              >
              </app-validation-message>
            </div>
          </div>

          <div class="row" formGroupName="employee">
            <div class="col-4 d-flex align-items-center">
              <b>Issuing Authority</b>
            </div>
            <div class="col-8">
              <input
                type="text"
                class="form-control mt-2"
                formControlName="i9ListBIssuingAuthority"
              />
              <app-validation-message
                [form]="form"
                [path]="'employee.i9ListBIssuingAuthority'"
                [name]="'i9ListBIssuingAuthority'"
                [label]="'Issuing Authority'"
              >
              </app-validation-message>
            </div>
          </div>
          <div class="row" formGroupName="employee">
            <div class="col-4 d-flex align-items-center">
              <b>Document Number</b>
            </div>
            <div class="col-8">
              <input
                type="text"
                class="form-control mt-2"
                formControlName="i9ListBDocumentNumber"
              />
              <app-validation-message
                [form]="form"
                [path]="'employee.i9ListBDocumentNumber'"
                [name]="'i9ListBDocumentNumber'"
                [label]="'Document Number'"
              >
              </app-validation-message>
            </div>
          </div>
          <div class="row" formGroupName="employee">
            <div class="col-4 d-flex align-items-center">
              <b>Document Expiration Date</b>
            </div>
            <div class="col-8">
              <p-calendar
              [disabled]="i9ListBExpirationDateNA"
                inputStyleClass="form-control mt-2"
                [monthNavigator]="true"
                [yearNavigator]="true"
                [yearRange]="yearRange"
                styleClass="w-75"
                panelStyleClass="calendarPanel"
                [hourFormat]="12"
                [showTime]="false"
                id="i9ListBExpirationDate"
                name="i9ListBExpirationDate"
                dataType="string"
                formControlName="i9ListBExpirationDate"
              ></p-calendar>
              <div class="d-inline-block ml-2">
                <p-checkbox  [(ngModel)]="i9ListBExpirationDateNA" (ngModelChange)="eraseDate(i9ListBExpirationDateNA,'i9ListBExpirationDate')" [ngModelOptions]="{standalone: true}" [binary]="true" ></p-checkbox>
                N/A
              </div>
              <app-validation-message
                [form]="form"
                [path]="'employee.i9ListBExpirationDate'"
                [name]="'i9ListBExpirationDate'"
                [label]="'Expiration Date'"
              >
              </app-validation-message>
            </div>
          </div>
         </div>
         <div class="col-12 col-sm-4">
         <div class="w-100 text-center"><h5>List C</h5></div>

          <div class="row" formGroupName="employee">
            <div class="col-4 d-flex align-items-center">
              <b>Document Title</b>
            </div>
            <div class="col-8">
              <input
                type="text"
                class="form-control mt-2"
                formControlName="i9ListCDocumentTitle"
              />
              <app-validation-message
                [form]="form"
                [path]="'employee.i9ListCDocumentTitle'"
                [name]="'i9ListCDocumentTitle'"
                [label]="'Document Title'"
              >
              </app-validation-message>
            </div>
          </div>

          <div class="row" formGroupName="employee">
            <div class="col-4 d-flex align-items-center">
              <b>Issuing Authority</b>
            </div>
            <div class="col-8">
              <input
                type="text"
                class="form-control mt-2"
                formControlName="i9ListCIssuingAuthority"
              />
              <app-validation-message
                [form]="form"
                [path]="'employee.i9ListCIssuingAuthority'"
                [name]="'i9ListCIssuingAuthority'"
                [label]="'Issuing Authority'"
              >
              </app-validation-message>
            </div>
          </div>
          <div class="row" formGroupName="employee">
            <div class="col-4 d-flex align-items-center">
              <b>Document Number</b>
            </div>
            <div class="col-8">
              <input
                type="text"
                class="form-control mt-2"
                formControlName="i9ListCDocumentNumber"
              />
              <app-validation-message
                [form]="form"
                [path]="'employee.i9ListCDocumentNumber'"
                [name]="'i9ListCDocumentNumber'"
                [label]="'Document Number'"
              >
              </app-validation-message>
            </div>
          </div>
          <div class="row" formGroupName="employee">
            <div class="col-4 d-flex align-items-center">
              <b>Document Expiration Date</b>
            </div>
            <div class="col-8">
              <p-calendar
              [disabled]="i9ListCExpirationDateNA"
                inputStyleClass="form-control mt-2"
                [monthNavigator]="true"
                [yearNavigator]="true"
                [yearRange]="yearRange"
                styleClass="w-75"
                panelStyleClass="calendarPanel"
                [hourFormat]="12"
                [showTime]="false"
                id="i9ListCExpirationDate"
                name="i9ListCExpirationDate"
                dataType="string"
                formControlName="i9ListCExpirationDate"
              ></p-calendar>
              <div class="d-inline-block ml-2">
                <p-checkbox  [(ngModel)]="i9ListCExpirationDateNA" (ngModelChange)="eraseDate(i9ListCExpirationDateNA,'i9ListCExpirationDate')" [ngModelOptions]="{standalone: true}" [binary]="true" ></p-checkbox>
                N/A
              </div>
              <app-validation-message
                [form]="form"
                [path]="'employee.i9ListCExpirationDate'"
                [name]="'i9ListCExpirationDate'"
                [label]="'Expiration Date'"
              >
              </app-validation-message>
            </div>
          </div>
         </div>
      </div>
<hr/>
      <div class="row" formGroupName="employee">
        <div class="col-4 d-flex align-items-center">
          <b>Additional Information</b>
        </div>
        <div class="col-8">
          <textarea
            class="form-control mt-2"
            formControlName="i9AdditionalInfo"
          ></textarea>
          <app-validation-message
            [form]="form"
            [path]="'employee.i9AdditionalInfo'"
            [name]="'i9AdditionalInfo'"
            [label]="'Additional Info'"
          >
          </app-validation-message>
        </div>
      </div>
      <div class="row" formGroupName="employee">
        <div class="col-4 d-flex align-items-center">
          <b>Employment First Date</b>
        </div>
        <div class="col-8">
          <p-calendar
            inputStyleClass="form-control mt-2"
            [monthNavigator]="true"
            [yearNavigator]="true"
            [yearRange]="yearRange"
            styleClass="w-100"
            panelStyleClass="calendarPanel"
            [hourFormat]="12"
            [showTime]="false"
            id="i9EmploymentFirstDate"
            name="i9EmploymentFirstDate"
            dataType="string"
            formControlName="i9EmploymentFirstDate"
          ></p-calendar>

          <app-validation-message
            [form]="form"
            [path]="'employee.i9EmploymentFirstDate'"
            [name]="'i9EmploymentFirstDate'"
            [label]="'Employment First Date'"
          >
          </app-validation-message>
        </div>
      </div>

      <div class="row" formGroupName="employee">
        <div class="col-4 d-flex align-items-center">
          <b>Organization Name</b>
        </div>
        <div class="col-8">
          <input
            type="text"
            class="form-control mt-2"
            formControlName="i9OrganizationName"
          />
          <app-validation-message
            [form]="form"
            [path]="'employee.i9OrganizationName'"
            [name]="'i9OrganizationName'"
            [label]="'Organization Name'"
          >
          </app-validation-message>
        </div>
      </div>


      <div class="row" formGroupName="employee">
        <div class="col-4 d-flex align-items-center">
          <b>Organization Address</b>
        </div>
        <div class="col-8">
          <input
            type="text"
            class="form-control mt-2"
            formControlName="i9OrganizationAddress"
          />
          <app-validation-message
            [form]="form"
            [path]="'employee.i9OrganizationAddress'"
            [name]="'i9OrganizationAddress'"
            [label]="'Organization Address'"
          >
          </app-validation-message>
        </div>
      </div>

      <div class="row" formGroupName="employee">
        <div class="col-4 d-flex align-items-center">
          <b>Organization City</b>
        </div>
        <div class="col-8">
          <input
            type="text"
            class="form-control mt-2"
            formControlName="i9OrganizationCity"
          />
          <app-validation-message
            [form]="form"
            [path]="'employee.i9OrganizationCity'"
            [name]="'i9OrganizationCity'"
            [label]="'Organization City'"
          >
          </app-validation-message>
        </div>
      </div>
      <div class="row"  formGroupName="employee">
        <div class="col-4 d-flex align-items-center">
          <b>Organization State</b>
        </div>
        <div class="col-8">
          <input type="text" class="form-control mt-2" formControlName="i9OrganizationState"/>
          <app-validation-message [form]="form" [path]="'employee.i9OrganizationState'" [name]="'i9OrganizationState'" [label]="'Organization State'"> </app-validation-message>

        </div>
      </div>
      <div class="row" formGroupName="employee">
        <div class="col-4 d-flex align-items-center">
          <b>Organization ZIP</b>
        </div>
        <div class="col-8">
          <input
            type="text"
            class="form-control mt-2"
            formControlName="i9OrganizationZIP"
          />
          <app-validation-message
            [form]="form"
            [path]="'employee.i9OrganizationZIP'"
            [name]="'i9OrganizationZIP'"
            [label]="'Organization ZIP'"
          >
          </app-validation-message>
        </div>
      </div>
      <hr/>
      <div class="row" >
        <div class="col-12">
          <h5>New name</h5>
          Complete this if:<br>
    1. You are reverifying employment authorization and the employee’s name has changed since the previous verification.<br>
    2. You are rehiring an employee whose name has changed since last completing Form I-9.
        </div>
      </div>
      <div class="row" formGroupName="employee">
        <div class="col-4 d-flex align-items-center">
          <b>New Legal First Name</b>
        </div>
        <div class="col-8">
          <input
            type="text"
            class="form-control mt-2"
            formControlName="i9NewFirstName"
          />
          <app-validation-message
            [form]="form"
            [path]="'employee.i9NewFirstName'"
            [name]="'i9NewFirstName'"
            [label]="'New First Name'"
          >
          </app-validation-message>
        </div>
      </div>
      <div class="row" formGroupName="employee">
        <div class="col-4 d-flex align-items-center">
          <b>New Legal Last Name</b>
        </div>
        <div class="col-8">
          <input type="text" class="form-control mt-2" formControlName="i9NewLastName" />
          <app-validation-message
            [form]="form"
            [path]="'employee.i9NewLastName'"
            [name]="'i9NewLastName'"
            [label]="'New Last Name'"
          >
          </app-validation-message>
        </div>
      </div>
      <div class="row" formGroupName="employee">
        <div class="col-4 d-flex align-items-center">
          <b>New Middle Initial</b>
        </div>
        <div class="col-8">
          <input
            type="text"
            class="form-control mt-2"
            formControlName="i9NewMiddleInitial"
          />
          <app-validation-message
            [form]="form"
            [path]="'employee.i9NewMiddleInitial'"
            [name]="'i9NewMiddleInitial'"
            [label]="'New Middle Initial'"
          >
          </app-validation-message>
        </div>
      </div>
      <hr/>
      <div class="row" >
        <div class="col-12">
          <h5>New document</h5>
          If the employee's previous grant of employment authorization has expired, provide the information for the document or receipt that establishes
continuing employment authorization in the space provided below.
        </div>
      </div>
      <div class="row" formGroupName="employee">
        <div class="col-4 d-flex align-items-center">
          <b>Rehire Date</b>
        </div>
        <div class="col-8">
          <p-calendar
            inputStyleClass="form-control mt-2"
            [monthNavigator]="true"
            [yearNavigator]="true"
            [yearRange]="yearRange"
            styleClass="w-100"
            panelStyleClass="calendarPanel"
            [hourFormat]="12"
            [showTime]="false"
            id="i9RehireDate"
            name="i9RehireDate"
            dataType="string"
            formControlName="i9RehireDate"
          ></p-calendar>

          <app-validation-message
            [form]="form"
            [path]="'employee.i9RehireDate'"
            [name]="'i9RehireDate'"
            [label]="'Rehire Date'"
          >
          </app-validation-message>
        </div>
      </div>

      <div class="row" formGroupName="employee">
        <div class="col-4 d-flex align-items-center">
          <b>Rehire Document Title</b>
        </div>
        <div class="col-8">
          <input
            type="text"
            class="form-control mt-2"
            formControlName="i9RehireDocumentTitle"
          />
          <app-validation-message
            [form]="form"
            [path]="'employee.i9RehireDocumentTitle'"
            [name]="'i9RehireDocumentTitle'"
            [label]="'Rehire Document Title'"
          >
          </app-validation-message>
        </div>
      </div>

      <div class="row" formGroupName="employee">
        <div class="col-4 d-flex align-items-center">
          <b>Rehire Document Number</b>
        </div>
        <div class="col-8">
          <input
            type="text"
            class="form-control mt-2"
            formControlName="i9RehireDocumentNumber"
          />
          <app-validation-message
            [form]="form"
            [path]="'employee.i9RehireDocumentNumber'"
            [name]="'i9RehireDocumentNumber'"
            [label]="'Rehire Document Number'"
          >
          </app-validation-message>
        </div>
      </div>
      <div class="row" formGroupName="employee">
        <div class="col-4 d-flex align-items-center">
          <b>Rehire Document Expiration Date</b>
        </div>
        <div class="col-8">
          <p-calendar
          [disabled]="i9RehireExpirationDateNA"
            inputStyleClass="form-control mt-2"
            [monthNavigator]="true"
            [yearNavigator]="true"
            [yearRange]="yearRange"
            styleClass="w-75"
            panelStyleClass="calendarPanel"
            [hourFormat]="12"
            [showTime]="false"
            id="i9RehireExpirationDate"
            name="i9RehireExpirationDate"
            dataType="string"
            formControlName="i9RehireExpirationDate"
          ></p-calendar>
          <div class="d-inline-block ml-2">
            <p-checkbox  [(ngModel)]="i9RehireExpirationDateNA" (ngModelChange)="eraseDate(i9RehireExpirationDateNA,'i9RehireExpirationDate')" [ngModelOptions]="{standalone: true}" [binary]="true" ></p-checkbox>
            N/A
          </div>
          <app-validation-message
            [form]="form"
            [path]="'employee.i9RehireExpirationDate'"
            [name]="'i9RehireExpirationDate'"
            [label]="'Rehire Expiration Date'"
          >
          </app-validation-message>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-6">
          <button
            class="btn btn-primary"
            [class.disabled]="form.invalid"
            (click)="save()"
          >
            Update and Sign
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
