import { UserModel } from 'src/app/models/userModel';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable()
export class UsersService {

  constructor(private http: HttpClient) {
  }

  getUsers(): Observable<UserModel[]> {
    return this.http.get<UserModel[]>(`${environment.apiServer.webApiURL}/Users`);
  }

  getUsersIncludingTerminated() {
    return this.http.get<UserModel[]>(`${environment.apiServer.webApiURL}/Users/GetUsersIncludingTerminated`);
  }

  getUsersReporting(): Observable<UserModel[]> {
    return this.http.get<UserModel[]>(`${environment.apiServer.webApiURL}/Users/ReportingUsers`);
  }
  getUser(userId: number): Observable<UserModel> {
    return this.http.get<UserModel>(`${environment.apiServer.webApiURL}/Users/${userId}`);
  }
}
