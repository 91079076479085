import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../core/store';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { JobApplicationsWithFileModel } from 'src/app/models/jobApplicationsModel';
import { FormGroup } from '@angular/forms';
import { initJobApplicationForm } from 'src/app/core/store/job-applications/job-applications.reducer';
import { getAllControlsErrors, markFormGroupTouched, updateFormGroupValidity } from 'src/app/helpers/utils';
import { clearJobApplication, loadCampuses, logNavigation, saveJobApplication, updateJobApplicationsForm } from 'src/app/core/store/job-applications/job-applications.action';
import { GeneralSettingsModel } from 'src/app/models/generalSettingsModel';

@Component({
  selector: 'app-applications',
  templateUrl: './job-applications.component.html',
  styleUrls: ['./job-applications.component.scss']
})
export class JobApplicationsComponent extends BaseComponent implements OnInit {

  jobApplication: JobApplicationsWithFileModel;
  form: FormGroup;
  resumeToUpload: File = null;
  coverLetterToUpload: File = null;
  campusesList = [];
  employeeReferral: boolean = false;
  phoneNumber = "";
  navigationLogged: boolean = false;


  jobTitlesList = [
    { label: '', value: 0 },
    { label: 'Behavior Technician', value: 'Behavior Technician' },
    { label: 'Mental Health Clinician I (CSW, ACMHC, AMFT)', value: 'Mental Health Clinician I (CSW, ACMHC, AMFT)' },
    { label: 'Mental Health Clinician II (LCSW, CMHC, LMFT)', value: 'Mental Health Clinician II (LCSW, CMHC, LMFT)' },
    { label: 'Behavior Clinician I (BCaBA)', value: 'Behavior Clinician I (BCaBA)' },
    { label: 'Behavior Clinician II (BCBA, BCBA-D)', value: 'Behavior Clinician II (BCBA, BCBA-D)' },
    { label: 'APRN/Dr/Ph.D/Psy.D', value: 'APRN/Dr/Ph.D/Psy.D' },
    { label: 'Administrative Assistant', value: 'Administrative Assistant' },
    { label: 'Operations', value: 'Operations'},
    { label: 'Other...', value: '' },
  ];
  jobOther: boolean;
  regionChanged: boolean = false;
  generalSettings: GeneralSettingsModel;
  loading: boolean = false;



  constructor(
    private store: Store<State>,
    private route: ActivatedRoute) {
    super()
}

  ngOnInit() {
    this.jobApplication = {} as JobApplicationsWithFileModel;
    this.initForm();
    this.subs.push(
      this.store.select(s => s.accountState.generalSettings).subscribe(generalSettings => {
        if (generalSettings) {
          this.generalSettings = generalSettings;
        }
      }),
      this.store.select(s => s.jobApplicationsState.jobApplicationWithFile).subscribe(jobApplication => {
        this.jobApplication = jobApplication;
          if (!this.form) {
            this.initForm();
          }
        }
      ),
      this.store.select(s => s.jobApplicationsState.loading).subscribe(loading => {
        this.loading = loading;
      }),
      this.store.select(s => s.jobApplicationsState.campuses).subscribe(campuses => {
        const campusesList = campuses && campuses.length > 0 ? campuses.map(c => ({ label: c.city, value: c.id }))
          : [{ label: 'loading...', value: undefined }];

        this.campusesList = campusesList;
      }),
      );
    this.store.dispatch(loadCampuses());
    this.logNavigation()
  }

  initForm() {
    this.form = initJobApplicationForm(this.jobApplication, "");
    this.subs.push(
      this.form.valueChanges.subscribe(formValues => {
        const formErrors = getAllControlsErrors(this.form);
        this.store.dispatch(updateJobApplicationsForm({ jobApplication: formValues, formErrors }));
        this.jobApplication = formValues;
      }));
  }

  changedResume(fileInput) {
    if (fileInput.files.length > 0) {
      this.resumeToUpload = fileInput.files[0];
    } else {
      this.resumeToUpload = null;
    }
  }

  logNavigation() {
    if (!this.navigationLogged){
      this.store.dispatch(logNavigation());
      this.navigationLogged = true;
    }
  }

  changedCoverLetter(fileInput) {
    if (fileInput.files.length > 0) {
      this.coverLetterToUpload = fileInput.files[0];
    } else {
      this.coverLetterToUpload = null;
    }
  }

  regionChange(event){
    this.regionChanged = true;
    if(event.value == 9){
      this.jobTitlesList = [
        { label: '', value: 0 },
        { label: 'Mental Health Clinician I (CSW, ACMHC, AMFT)', value: 'Mental Health Clinician I (CSW, ACMHC, AMFT)' },
        { label: 'Mental Health Clinician II (LCSW, CMHC, LMFT)', value: 'Mental Health Clinician II (LCSW, CMHC, LMFT)' },
        { label: 'APRN/Dr/Ph.D/Psy.D', value: 'APRN/Dr/Ph.D/Psy.D' },
        { label: 'Administrative Assistant', value: 'Administrative Assistant' },
        { label: 'Medical Assistant', value: 'Medical Assistant' },
        { label: 'Operations', value: 'Operations'},
        { label: 'Other...', value: '' },
      ];
    }
    else if(event.value == 8){
      this.jobTitlesList = [
        { label: '', value: 0 },
        { label: 'Behavior Technician', value: 'Behavior Technician' },
        { label: 'Mental Health Clinician I (CSW, ACMHC, AMFT)', value: 'Mental Health Clinician I (CSW, ACMHC, AMFT)' },
        { label: 'Mental Health Clinician II (LCSW, CMHC, LMFT)', value: 'Mental Health Clinician II (LCSW, CMHC, LMFT)' },
        { label: 'Behavior Clinician I (BCaBA)', value: 'Behavior Clinician I (BCaBA)' },
        { label: 'Behavior Clinician II (BCBA, BCBA-D)', value: 'Behavior Clinician II (BCBA, BCBA-D)' },
        { label: 'APRN/Dr/Ph.D/Psy.D', value: 'APRN/Dr/Ph.D/Psy.D' },
        { label: 'Administrative Assistant', value: 'Administrative Assistant' },
        { label: 'Medical Assistant', value: 'Medical Assistant' },
        { label: 'Operations', value: 'Operations'},
        { label: 'Other...', value: '' },
      ];
    }
    else{
      this.jobTitlesList = [
        { label: '', value: 0 },
        { label: 'Behavior Technician', value: 'Behavior Technician' },
        { label: 'Mental Health Clinician I (CSW, ACMHC, AMFT)', value: 'Mental Health Clinician I (CSW, ACMHC, AMFT)' },
        { label: 'Mental Health Clinician II (LCSW, CMHC, LMFT)', value: 'Mental Health Clinician II (LCSW, CMHC, LMFT)' },
        { label: 'Behavior Clinician I (BCaBA)', value: 'Behavior Clinician I (BCaBA)' },
        { label: 'Behavior Clinician II (BCBA, BCBA-D)', value: 'Behavior Clinician II (BCBA, BCBA-D)' },
        { label: 'APRN/Dr/Ph.D/Psy.D', value: 'APRN/Dr/Ph.D/Psy.D' },
        { label: 'Administrative Assistant', value: 'Administrative Assistant' },
        { label: 'Operations', value: 'Operations'},
        { label: 'Other...', value: '' },
      ];
    }
  }

  phoneNumberChange() {
    this.phoneNumber.replace(/[^\d]/g, "");
    if (this.phoneNumber.length == 10) {
      this.phoneNumber = this.phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
    }
  }

  changeReferredStatus(e) {
    if (e.target.value == "Employee Referral"){
      this.employeeReferral = true;
    }
    else{
      this.employeeReferral = false;
    }
  }

  jobTitleChange(e){
    if (e.originalEvent != null){
      if (e.originalEvent.target.innerText == "Other..."){
        this.jobOther = true;
      }
      else{
        this.jobOther = false;
      }
    }
    else {
      if (e.value == ""){
        this.jobOther = true;
      }
      else{
        this.jobOther = false;
      }
    }
  }

  getRandomInt(max){
    return Math.floor(Math.random() * max);
  }

  robotCheck(){
    const num1 = this.getRandomInt(10);
    const num2 = this.getRandomInt(10);
    const answer = prompt("To make sure no robots are applying here with us,\n please answer the question below.\n What is  " + num1 + " + " + num2 + " = ?");
    if (answer == (num1 + num2).toLocaleString()) {
      return true;
    }
    else {
      return false;
    }
  }

  save() {
    this.loading = true;
    if (this.robotCheck()){
      updateFormGroupValidity(this.form);
      if (this.form.valid && this.resumeToUpload != null) {
        this.store.dispatch(saveJobApplication({ jobApplication: this.jobApplication, resume: this.resumeToUpload, coverLetter: this.coverLetterToUpload }));
        this.employeeReferral = false;
      } else {
        markFormGroupTouched(this.form);
        this.loading = false;
        alert("Resume required to submit application")
      }
    }
    else{
      this.loading = false;
      setTimeout(function() { alert("Your answer was incorrect."); }, 1000)
    }
  }

}

