import { ImprovementPlanReviewModel } from './../../../models/improvementPlanModel';
import { CoachingLogService } from './../../services/coachingLog.service';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, tap, catchError, filter } from 'rxjs/operators';
import { of, } from 'rxjs';
import { errorHappened } from '../profile/profile.actions';
import { Store } from '@ngrx/store';
import { State } from '..';
import { AlertService } from 'src/app/shared/services/alert.service';
import {
  createImprovementPlan, createImprovementPlanSuccess, createImprovementPlanFail,
  createImprovementPlanReview, createImprovementPlanReviewSuccess, createImprovementPlanReviewFail,
  getImprovementPlan, getImprovementPlanSuccess, downloadImprovementPlanDoc, downloadImprovementPlanDocSuccess,
  downloadImprovementPlanReviewDoc, downloadImprovementPlanReviewDocSuccess, signImprovementPlanDoc,
  signImprovementPlanDocSuccess, signImprovementPlanDocFailure, signImprovementPlanReviewDoc, signImprovementPlanReviewDocSuccess,
  signImprovementPlanReviewDocFailure,
  getImprovementPlanReview,
  getImprovementPlanReviewSuccess
} from './improvementPlan.actions';
import { ImprovementPlanService } from '../../services/improvementPlan.service';

@Injectable()
export class ImprovementPlanEffects {
  constructor(private actions$: Actions,
              private improvementPlanService: ImprovementPlanService,
              private store: Store<State>,
              private alertService: AlertService,
              private router: Router) {

  }

  createImprovementPlan = createEffect(() => this.actions$.pipe(
    ofType(createImprovementPlan),
    switchMap(action => {
      return this.improvementPlanService.createImprovementPlan(action.improvementPlanModel).pipe(
        map(improvementPlanModel => createImprovementPlanSuccess({ improvementPlanModel })),
        catchError(err => {
          this.store.dispatch(createImprovementPlanFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));

  createImprovementPlanSuccess = createEffect(() => this.actions$.pipe(
    ofType(createImprovementPlanSuccess),
    tap(action => {
      this.alertService.success(
        'Improvement Plan saved', false);
    })
  ), { dispatch: false });


  createImprovementPlanReview = createEffect(() => this.actions$.pipe(
    ofType(createImprovementPlanReview),
    switchMap(action => {
      return this.improvementPlanService.createImprovementPlanReview(action.improvementPlanReviewModel).pipe(
        map(improvementPlanReviewModel => createImprovementPlanReviewSuccess({ improvementPlanReviewModel })),
        catchError(err => {
          this.store.dispatch(createImprovementPlanReviewFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));

  createImprovementPlanReviewSuccess = createEffect(() => this.actions$.pipe(
    ofType(createImprovementPlanReviewSuccess),
    tap(action => {
      this.alertService.success(
        'Improvement Plan review saved', false);
    })
  ), { dispatch: false });




  getImprovementPlan = createEffect(() => this.actions$.pipe(
    ofType(getImprovementPlan),
    switchMap(action => {
      return this.improvementPlanService.getImprovementPlan(action.improvementPlanId).pipe(
        map(improvementPlan => getImprovementPlanSuccess({ improvementPlan })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));


  getImprovementPlanReview = createEffect(() => this.actions$.pipe(
    ofType(getImprovementPlanReview),
    switchMap(action => {
      return this.improvementPlanService.getImprovementPlanReview(action.improvementPlanReviewId).pipe(
        map(improvementPlanReview => getImprovementPlanReviewSuccess({ improvementPlanReview })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  downloadImprovementPlanDoc = createEffect(() => this.actions$.pipe(
    ofType(downloadImprovementPlanDoc),
    switchMap(action => {
      return this.improvementPlanService.downloadImprovementPlanDoc(action.improvementPlanID).pipe(
        map(doc => downloadImprovementPlanDocSuccess({ doc })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  downloadImprovementPlanReviewDoc = createEffect(() => this.actions$.pipe(
    ofType(downloadImprovementPlanReviewDoc),
    switchMap(action => {
      return this.improvementPlanService.downloadImprovementPlanReviewDoc(action.improvementPlanReviewID).pipe(
        map(doc => downloadImprovementPlanReviewDocSuccess({ doc })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  signImprovementPlanDoc = createEffect(() => this.actions$.pipe(
    ofType(signImprovementPlanDoc),
    switchMap(action => {
      return this.improvementPlanService.signImprovementPlan(action.improvementPlanID, action.signedFields).pipe(
        map(docSigned => signImprovementPlanDocSuccess({ docSigned })),
        catchError(err => {
          this.store.dispatch(signImprovementPlanDocFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  signImprovementPlanReviewDoc = createEffect(() => this.actions$.pipe(
    ofType(signImprovementPlanReviewDoc),
    switchMap(action => {
      return this.improvementPlanService.signImprovementPlanReview(action.improvementPlanReviewID, action.signedFields).pipe(
        map(docSigned => signImprovementPlanReviewDocSuccess({ docSigned })),
        catchError(err => {
          this.store.dispatch(signImprovementPlanReviewDocFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

}
