import { PagingResultsModel } from '../../models/pagingResultsModel';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EthicCodeModel } from '../../models/ethicCodeModels';


@Injectable()
export class EthicCodesService {

  constructor(private http: HttpClient) {
  }
  getAll()
    : Observable<EthicCodeModel[]> {
   
    return this.http.get<EthicCodeModel[]>(`${environment.apiServer.webApiURL}/EthicCode/All`)
  }

  get(ethicCodeId: number)
    : Observable<EthicCodeModel> {

    return this.http.get<EthicCodeModel>(`${environment.apiServer.webApiURL}/EthicCode/${ethicCodeId}`);
  }


  save(ethicCode: EthicCodeModel): Observable<EthicCodeModel> {
    return this.http.post<EthicCodeModel>(`${environment.apiServer.webApiURL}/EthicCode`,
      ethicCode);
  }

  delete(ethicCodeId: number)
    : Observable<any> {
    return this.http.delete<any>(`${environment.apiServer.webApiURL}/EthicCode/${ethicCodeId}`);
  }

}

