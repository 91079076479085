import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { markFormGroupTouched, updateFormGroupValidity } from '../../../../helpers/utils';
import { ModifiedSelectItem } from '../../../../models/utilModels';
import { InternApplicationActionTemplateModel } from '../../../../models/internModels';
import { closeEditInternActionItemTemplate, deleteInternActionItemTemplate, deleteInternActionItemTemplateSuccess, getInternActionItemTemplate, loadInternshipActionTypes, loadInternshipStatuses, loadInternshipSubstatuses, saveInternActionItemTemplate, saveInternActionItemTemplateSuccess } from '../../../../core/store/intern/intern.action';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-edit-intern-action-item-template',
  templateUrl: './edit-intern-action-item-template.component.html',
  styleUrls: ['./edit-intern-action-item-template.component.scss']
})
export class EditInternActionItemTemplateComponent extends BaseComponent implements OnInit {
  display: boolean = false;
  title: string;
  form: FormGroup;
  disableButtons: boolean = true;
  templateId: number = 0;

  primaryStatuses: ModifiedSelectItem[] = [] as ModifiedSelectItem[];
  allSubstatuses: ModifiedSelectItem[] = [] as ModifiedSelectItem[];
  availableSubstatuses: ModifiedSelectItem[] = [] as ModifiedSelectItem[];
  actionTypes: SelectItem[] = [] as SelectItem[];

  originalSubstatusId: number = 0;

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.form = this.createForm();
    this.subs.push(
      this.store.select(s => s.internState.editingTemplate).subscribe(editing => {
        if (this.display && !editing || (editing && this.templateId == 0)) {
          this.resetForm();
        }
        this.display = editing;

      }),
      this.store.select(s => s.internState.savingTemplate).subscribe(saving => {
        this.disableButtons = saving;
      }),
      this.store.select(s => s.internState.templateId).subscribe(id => {
        this.templateId = id;
        this.title = "New Action Item Template";
        if (typeof id !== 'undefined' && id > 0) {
          this.store.dispatch(getInternActionItemTemplate({ templateId: id }));
        }
      }),
      this.store.select(s => s.internState.selectedTemplate).subscribe(selected => {
        if (selected === null) {          
          this.originalSubstatusId = 0;
          this.resetForm();
          this.getSubstatuses();
          return;
        }

        this.originalSubstatusId = selected.substatusId;        
        this.title = "Edit Action Item Template #" + selected.actionTemplateId;
        this.form.get("actionTemplateId").setValue(selected.actionTemplateId);
        this.form.get("actionTypeId").setValue(selected.actionTypeId);
        this.form.get("primaryStatusId").setValue(selected.primaryStatusId);
        this.form.get("substatusId").setValue(selected.substatusId != null ? selected.substatusId : 0);
        this.form.get("isEmployeeAction").setValue(selected.isEmployeeAction);
        this.form.get("archived").setValue(selected.archived);
        this.form.get("defaultRequest").setValue(selected.defaultRequest != null ? selected.defaultRequest : "");        
        this.getSubstatuses();
      }),
      this.store.select(s => s.internState.internshipStatuses).subscribe(statuses => {
        if (statuses && statuses.length > 0) {
          this.primaryStatuses = statuses.map(x => ({ label: x.label, value: x.value }));
          this.primaryStatuses.unshift({ label: '', value: undefined });
        }
        else {
          this.primaryStatuses = [{ label: 'loading...', value: 0 }];
        }
      }),
      this.store.select(s => s.internState.internshipSubstatuses).subscribe(statuses => {
        if (statuses && statuses.length > 0) {
          this.allSubstatuses = statuses.map(x => ({ label: x.label, value: x.value, archived: x.archived, filterId: x.filterId, disabled: x.disabled }));
          this.allSubstatuses.unshift({ label: '', value: 0, disabled: false, archived: false });
          if (this.form.get('primaryStatusId').value > 0) {
            this.getSubstatuses();
          }
        }
        else {
          this.allSubstatuses = [{ label: 'loading...', value: 0 }];
        }                
      }),
      this.store.select(s => s.internState.internshipActionTypes).subscribe(types => {
        if (types && types.length > 0) {
          this.actionTypes = types.map(x => ({ label: x.label, value: x.value }));
          this.actionTypes.unshift({ label: '', value: undefined });
        }
        else {
          this.actionTypes = [{ label: 'loading...', value: 0 }];
        }
      }),
      this.actions$.pipe(
        ofType(saveInternActionItemTemplateSuccess),
        tap(action => {
          this.closeModal();
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(deleteInternActionItemTemplateSuccess),
        tap(action => {
          this.closeModal();
        })
      ).subscribe(),
    );

    if (this.primaryStatuses == null || this.primaryStatuses.length == 0 || this.primaryStatuses[0].label === 'loading...') {
      this.store.dispatch(loadInternshipStatuses());
    }

    if (this.allSubstatuses == null || this.allSubstatuses.length == 0 || this.allSubstatuses[0].label === 'loading...') {
      this.store.dispatch(loadInternshipSubstatuses());
    }

    if (this.actionTypes == null || this.actionTypes.length == 0 || this.actionTypes[0].label === 'loading...') {
      this.store.dispatch(loadInternshipActionTypes());
    }
  }

  createForm() {
    let f = this.fb.group({
      actionTemplateId: this.fb.control(0),
      actionTypeId: this.fb.control(null, [Validators.required]),
      primaryStatusId: this.fb.control(null, [Validators.required]),
      substatusId: this.fb.control(0),
      isEmployeeAction: this.fb.control(false),
      defaultRequest: this.fb.control(""),
      archived: this.fb.control(false),
    });

    return f;
  }

  resetForm() {
    this.form.reset({
      actionTemplateId: 0,
      actionTypeId: null,
      primaryStatusId: null,
      substatusId: 0,
      isEmployeeAction: false,
      defaultRequest: "",
      archived: false
    });
  }

  closeModal() {
    this.store.dispatch(closeEditInternActionItemTemplate());
  }

  saveTemplate() {
    updateFormGroupValidity(this.form);
    if (this.form.valid) {
      this.store.dispatch(saveInternActionItemTemplate({ template: this.form.value as InternApplicationActionTemplateModel }));
    } else {
      markFormGroupTouched(this.form);
    }
  }

  deleteTemplate() {
    if (this.templateId > 0) {
      this.store.dispatch(deleteInternActionItemTemplate({ templateId: this.templateId }));
    }
    else {
      this.closeModal(); //delete button should be hidden if not > 0
    }
  }

  getSubstatuses() {

    var primaryStatusId = this.form.get('primaryStatusId').value;
    this.availableSubstatuses = this.allSubstatuses.filter((r) => r.value === 0 || (r.filterId == primaryStatusId && r.archived == false));
  }

  getSubstatusShow() {
    return this.availableSubstatuses.length > 1;
  }  
}
