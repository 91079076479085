import { ActivatedRoute, Router } from '@angular/router';
import {
  loadSurveyInfo, updateSurveyAnswerForm,
  saveSurveyAnswer, saveSurveyAnswerSuccess
} from './../../../../core/store/survey/survey.actions';
import { SurveyModel, SurveyFullAnswerModel } from './../../../../models/surveyModel';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { FormGroup, FormControl } from '@angular/forms';
import { getAllControlsErrors, markFormGroupTouched } from '../../../../helpers/utils';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { initSurveyAnswerForm } from '../../../../core/store/survey/survey.reducer';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { AlertService } from '../../../../shared/services/alert.service';
@Component({
  selector: 'app-survey-answer',
  templateUrl: './survey-answer.component.html',
  styleUrls: ['./survey-answer.component.scss']
})
export class SurveyAnswerComponent extends BaseComponent implements OnInit {

  survey: SurveyModel;
  fullAnswers: SurveyFullAnswerModel;
  form: FormGroup;
  savingAnswer: boolean;
  loadingSurvey: boolean;
  surveyQuestionsMap;

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.surveyState).subscribe(surveyState => {
        if (surveyState.survey) {
          this.survey = JSON.parse(JSON.stringify(surveyState.survey));
          this.surveyQuestionsMap = {};
          this.survey.surveyType.surveyQuestions.forEach((item) => {
            this.surveyQuestionsMap[item.id] = item;
          });
          if (!this.form) {
            this.form = initSurveyAnswerForm(this.survey);
            this.subs.push(
              this.form.valueChanges.subscribe(formValues => {
                const answerValues = this.form.get('answers').value;
                const generalValues = this.form.get('general').value;
                const formErrors = getAllControlsErrors(this.form);
                this.store.dispatch(updateSurveyAnswerForm({ answerValues, generalValues, formErrors }));
              })
            );
          }
        }
        this.loadingSurvey = surveyState.loandingSurvey;
        this.savingAnswer = surveyState.savingAnswer;
        this.fullAnswers = surveyState.surveyFullAnswer;
      }),
      this.route.params.subscribe(params => {
        const surveyID = params.surveyID;
        if (params.surveyID) {
          this.store.dispatch(loadSurveyInfo({ surveyID }));
        }
      }),
      this.actions$.pipe(
        ofType(saveSurveyAnswerSuccess),
        tap(action => {
          this.alertService.success('Thanks for answering the survey!');
          this.router.navigate(['/']);
        })
      ).subscribe()
    );

  }



  save() {
    if (this.form.valid) {
      this.store.dispatch(saveSurveyAnswer({ answer: this.fullAnswers }));
    } else {
      markFormGroupTouched(this.form);
    }
  }

  changedRating(radio, line: FormGroup, value) {
    line.get('answer').setValue(value);
  }

  getControls(control) {
    return control.controls;
  }
}

