
<div [formGroup]="form" *ngIf="profile!=null">
    <h5 class="card-title">Update needed information for the BCI document</h5>
        (the info will be saved on the user profile and used to fill the document)
    <div class="row">
      <div class="col-12 col-xl-6 ">

        <div class="row" formGroupName="employee">
          <div class="col-4 d-flex align-items-center">
            <b>Identification Type:</b>
          </div>
          <div class="col-8">
            <p-dropdown styleClass="form-control mt-2"
                        [options]="bciIDTypeOptions" formControlName="bciIDType" placeholder="Select type..."></p-dropdown>
            <app-validation-message [form]="form" [path]="'employee.bciIDType'" [name]="'bciIDType'" [label]="'ID Type'"> </app-validation-message>
          </div>
        </div>

        <div class="row" formGroupName="employee">
          <div class="col-4 d-flex align-items-center">
            <b>ID Issued by (State/Country)</b>
          </div>
          <div class="col-8">
            <input type="text" class="form-control mt-2" formControlName="bciIDState" />
            <app-validation-message [form]="form" [path]="'employee.bciIDState'" [name]="'bciIDState'" [label]="'ID State'"> </app-validation-message>
          </div>
        </div>

        <div class="row" formGroupName="employee">
          <div class="col-4 d-flex align-items-center">
            <b>ID Number</b>
          </div>
          <div class="col-8">
            <input type="text" class="form-control mt-2" formControlName="bciIDNumber" />
            <app-validation-message [form]="form" [path]="'employee.bciIDNumber'" [name]="'bciIDNumber'" [label]="'ID Number'"> </app-validation-message>
          </div>
        </div>
        <div class="row" formGroupName="employee">
          <div class="col-4 d-flex align-items-center">
            <b>ID Expiration Date</b>
          </div>
          <div class="col-8">
            <p-calendar inputStyleClass="form-control mt-2" [monthNavigator]="true" [yearNavigator]="true" [yearRange]="yearRange"
                        styleClass="w-100" panelStyleClass="calendarPanel" [hourFormat]="12" [showTime]="false"
                        id="bciIDExpirationDate" name="bciIDExpirationDate" dataType="string" formControlName="bciIDExpirationDate"></p-calendar>

            <app-validation-message [form]="form" [path]="'employee.bciIDExpirationDate'" [name]="'bciIDExpirationDate'" [label]="'ID Expiration Date'"> </app-validation-message>
          </div>
        </div>

        <div class="row" formGroupName="employee">
          <div class="col-4 d-flex align-items-center">
            <b>Gender:</b>
          </div>
          <div class="col-8">
            <p-dropdown styleClass="form-control mt-2"
                        [options]="bciGenderOptions" formControlName="bciGender" placeholder="Select ..."></p-dropdown>
            <app-validation-message [form]="form" [path]="'employee.bciGender'" [name]="'bciGender'" [label]="'Gender'"> </app-validation-message>
          </div>
        </div>
        <div class="row" formGroupName="employee">
          <div class="col-4 d-flex align-items-center">
            <b>Race:</b>
          </div>
          <div class="col-8">
            <p-dropdown styleClass="form-control mt-2"
                        [options]="bciRaceOptions" formControlName="bciRace" placeholder="Select ..."></p-dropdown>
            <app-validation-message [form]="form" [path]="'employee.bciRace'" [name]="'bciRace'" [label]="'Race'"> </app-validation-message>
          </div>
        </div>
        <div class="row" formGroupName="employee">
          <div class="col-4 d-flex align-items-center">
            <b>Eye Color:</b>
          </div>
          <div class="col-8">
            <input type="text" class="form-control mt-2" formControlName="bciEyeColor" />
            <app-validation-message [form]="form" [path]="'employee.bciEyeColor'" [name]="'bciEyeColor'" [label]="'Eye Color'"> </app-validation-message>
          </div>
        </div>
        <div class="row" formGroupName="employee">
          <div class="col-4 d-flex align-items-center">
            <b>Hair Color:</b>
          </div>
          <div class="col-8">
            <input type="text" class="form-control mt-2" formControlName="bciHairColor" />
            <app-validation-message [form]="form" [path]="'employee.bciHairColor'" [name]="'bciHairColor'" [label]="'Hair Color'"> </app-validation-message>
          </div>
        </div>
        <div class="row" formGroupName="employee">
          <div class="col-4 d-flex align-items-center">
            <b>Height:</b>
          </div>
          <div class="col-8">
            <input type="text" class="form-control mt-2" formControlName="bciHeight" />
            <app-validation-message [form]="form" [path]="'employee.bciHeight'" [name]="'bciHeight'" [label]="'Height'"> </app-validation-message>
          </div>
        </div>
        <div class="row" formGroupName="employee">
          <div class="col-4 d-flex align-items-center">
            <b>Weight:</b>
          </div>
          <div class="col-8">
            <input type="text" class="form-control mt-2" formControlName="bciWeight" />
            <app-validation-message [form]="form" [path]="'employee.bciWeight'" [name]="'bciWeight'" [label]="'Weight'"> </app-validation-message>
          </div>
        </div>

        <div class="row" formGroupName="employee" >
          <div class="col-4 d-flex align-items-center">
            <b>State of Birth</b>
          </div>
          <div class="col-8">
            <input
              type="text"
              class="form-control mt-2"
              formControlName="stateOfBirth"  [attr.readonly]="this.readOnly?true:null"
            />
            <app-validation-message [form]="form" [path]="'employee.stateOfBirth'" [name]="'stateOfBirth'" [label]="'State of Birth'"> </app-validation-message>

          </div>
        </div>
        <div class="row" formGroupName="employee">
          <div class="col-4 d-flex align-items-center">
            <b>LicenseTypes:</b>
          </div>
          <div class="col-8">
            <p-multiSelect #LicenseTypesMultiSelect (onFocus)="openMultiSelect(LicenseTypesMultiSelect)" autofocusFilter="true" class="w-100" styleClass="w-100 d-flex mt-2" inputStyleClass="form-control"
                           [options]="bciLicenseTypesOptions" formControlName="bciLicenseTypes" [defaultLabel]="'Select ...'"></p-multiSelect>

            <app-validation-message [form]="form" [path]="'employee.bciLicenseTypes'" [name]="'bciLicenseTypes'" [label]="'License Types'"> </app-validation-message>
          </div>
        </div>
        <div class="row" formGroupName="employee">
          <div class="col-4 d-flex align-items-center">
            <b>Program Name:</b>
          </div>
          <div class="col-8">
            <input type="text" class="form-control mt-2" formControlName="bciProgramName" />

            <app-validation-message [form]="form" [path]="'employee.bciProgramName'" [name]="'bciProgramName'" [label]="'Program Name'"> </app-validation-message>
          </div>
        </div>

        <div class="row" formGroupName="employee">
          <div class="col-4 d-flex align-items-center">
            <b>Site Name Or Address:</b>
          </div>
          <div class="col-8">
            <i *ngIf="!campusesList" class="material-icons rotate text-muted">loop</i>
            <p-dropdown styleClass="form-control mt-2 w-100" [options]="campusesList" formControlName="bciSiteNameOrAddress" placeholder="Select campus..."></p-dropdown>

            <app-validation-message [form]="form" [path]="'employee.bciSiteNameOrAddress'" [name]="'bciSiteNameOrAddress'" [label]="'Site Name Or Address'"> </app-validation-message>
          </div>
        </div>
        <div class="row" formGroupName="employee">
          <div class="col-4 d-flex align-items-center">
            <b>Program Phone Number:</b>
          </div>
          <div class="col-8">
            <input type="text" class="form-control mt-2" formControlName="bciProgramPhoneNumber" />
            <app-validation-message [form]="form" [path]="'employee.bciProgramPhoneNumber'" [name]="'bciProgramPhoneNumber'" [label]="'Program Phone Number'"> </app-validation-message>
          </div>
        </div>



        <div class="row mt-4">
          <div class="col-6">
            <button class="btn btn-primary" [class.disabled]="form.invalid" (click)="save()">Update and Sign</button>
          </div>
        </div>
      </div>
</div>
</div>
