import { createReducer, on, Action } from '@ngrx/store';
import { PagingResultsModel } from '../../../models/pagingResultsModel';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { formatDate } from '@angular/common';
import { getFormattedTime } from '../../../helpers/utils';
import * as moment from 'moment';
import { FormError } from '../../../models/utilModels';
import { SelectItem } from 'primeng/api';
import { errorHappened } from '../profile/profile.actions';
import { StaffInjuryModel } from '../../../models/staffInjuryModel';
import { downloadStaffInjuryInvoiceSuccess, downloadStaffInjuryInvoice, loadInjuriesQueue, loadInjuriesQueueSuccess, markInjuryAsCompleted, markInjuryAsCompletedSuccess, setStaffInjuryToUpdate, updateStaffInjury, updateStaffInjuryFail, updateStaffInjuryForm, updateStaffInjurySuccess, uploadStaffInjuryInvoice, uploadStaffInjuryInvoiceFailure, uploadStaffInjuryInvoiceSuccess } from './injuryqueue.actions';


export interface InjuryQueueState {
  injuriesQueue: PagingResultsModel<StaffInjuryModel>;
  formErrors: FormError[];
  filter: any,
  loadingQueue: boolean,
  staffInjury: StaffInjuryModel,
  updatingStaffInjury: boolean,
  invoiceArrayBuffer: ArrayBuffer,
  downloadingInvoice?: number
};

const initialState: InjuryQueueState = {
  injuriesQueue: null,
  formErrors: [],
  filter: {},
  loadingQueue: false,
  staffInjury: null,
  updatingStaffInjury: false,
  invoiceArrayBuffer: null,
  downloadingInvoice: null
};

export const injuryQueueReducer = createReducer(initialState,
  on(loadInjuriesQueue, (state, action) => {
    return {
      ...state,
      filter: action.filter,
      loadingQueue: true
    };
  }),
  on(loadInjuriesQueueSuccess, (state, action) => {
    return {
      ...state,
      injuriesQueue: action.injuriesQueue,
      loadingQueue: false

    };
  }),

  on(markInjuryAsCompleted, (state, action) => {
    return {
      ...state,
      loadingQueue: true
    };
  }),

  on(markInjuryAsCompletedSuccess, (state, action) => {
    return {
      ...state,
      loadingQueue: false
    };
  }),

  on(setStaffInjuryToUpdate, (state, action) => {
    return {
      ...state,
      staffInjury: action.staffInjury
    }
  }),

  on(updateStaffInjuryForm, (state, action) => {
    const newStaffInjury = { ...state.staffInjury, ...action.staffInjuryValues };
    const newState = { ...state, ...{ staffInjury: newStaffInjury, staffInjuriesFormErrors: action.formErrors } };
    return newState;
  }),

  on(updateStaffInjury, (state, action) => {
    return {
      ...state,
      updatingStaffInjury: true
    }
  }),
  on(uploadStaffInjuryInvoice, (state, action) => {
    return {
      ...state,
      updatingStaffInjury: true
    }
  }),

  on( updateStaffInjuryFail, (state, action) => {
    return {
      ...state,
      updatingStaffInjury: false
    };
  }),

  on(uploadStaffInjuryInvoiceFailure, (state, action) => {
    return {
      ...state,
      updatingStaffInjury: false
    };
  }),

  on(uploadStaffInjuryInvoiceSuccess, (state, action) => {
    return {
      ...state,
      updatingStaffInjury: false
    };
  }),

  on(updateStaffInjurySuccess, (state, action) => {
    return {
      ...state,
      updatingStaffInjury: false,
      staffInjury: null
    };
  }),

  on(downloadStaffInjuryInvoice, (state, action) => {
    return {
      ...state,
      downloadingInvoice: action.staffInjuryId,
      invoiceArrayBuffer: null
    };
  }),
  on(downloadStaffInjuryInvoiceSuccess, (state, action) => {
    return {
      ...state,
      invoiceArrayBuffer: action.invoice,
      downloadingInvoice: null
   };
  })
);

export function injuryQueueReducerFunc(state: InjuryQueueState | undefined, action: Action) {
  return injuryQueueReducer(state, action);
}


export function initUpdateStaffInjuryForm(staffInjury: StaffInjuryModel) {
  if (staffInjury == null) {
    staffInjury = {} as StaffInjuryModel;
  }

  const form = new FormGroup({
    id: new FormControl(staffInjury.id),
    notes: new FormControl(staffInjury.notes, [Validators.maxLength(255)]),
    monetaryValue: new FormControl(staffInjury.monetaryValue),
    treatmentDate: new FormControl(staffInjury.treatmentDate ? formatDate(staffInjury.treatmentDate, 'yyyy-MM-dd', 'en-US') : ""),
    resolved: new FormControl(staffInjury.doneAt ? true : false),
    invoiceUploaded: new FormControl(staffInjury.invoiceUploaded),
    location: new FormControl(staffInjury.location),
    hadWorkMedTreatment: new FormControl(staffInjury.hadWorkMedTreatment),
    hadMedClinicTreatment: new FormControl(staffInjury.hadMedClinicTreatment),
    done: new FormControl(staffInjury.done)
  });

  return form;
}


