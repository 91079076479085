import {
  EquipmentModel, AssignedEquipmentModel,
  EquipmentAcessoryModel, EquipmentWithAssignmentsModel, EquipmentClassCodeModel,
  EquipmentClassificationModel,
  EquipmentTypeModel
} from './../../../models/equipmentsModel';
import { PagingResultsModel } from './../../../models/pagingResultsModel';
import { UserModel } from 'src/app/models/userModel';
import { createAction, props } from '@ngrx/store';
import { FormError } from 'src/app/models/utilModels';
import { CampusModel } from '../../../models/campusModel';
import { EquipmentPermissionsModel } from 'src/app/models/equipmentTypePermissionModel';

export const clearPermissions = createAction('[Equipment] Clear Permissions');

export const loadCampuses = createAction('[Equipment] Load All Campuses');
export const loadCampusesSuccess = createAction('[Equipment] Load All Campuses Success', props<{ campuses: CampusModel[] }>());

export const loadClassCodes = createAction('[Equipment] Load All ClassCode');
export const loadClassCodesSuccess = createAction('[Equipment] Load All ClassCode Success', props<{ classCode: EquipmentClassCodeModel[] }>());

export const loadEquipmentPermissionModalOptions = createAction('[Equipment] Load Equipment Permission Modal Options');
export const loadEquipmentPermissionModalOptionsSuccess = createAction('[Equipment] Load Equipment Permission Modal Options Success', props<{ equipmentPermissionOptions: any }>());

export const loadEquipmentPermissions = createAction('[Equipment] Load Equipment Permissions', props<{userId: number, roleId: number}>());
export const loadEquipmentPermissionsSuccess = createAction('[Equipment] Load Equipment Permissions Success', props<{ equipmentPermissions: any }>());

export const saveEquipmentPermissions = createAction('[Equipment] Save Equipment Permissions', props<{equipmentPermissions: EquipmentPermissionsModel}>());
export const saveEquipmentPermissionsSuccess = createAction('[Equipment] Save Equipment Permissions Success', props<{ resp: any }>());

export const loadClassifications = createAction('[Equipment] Load All Classification');
export const loadClassificationsSuccess = createAction('[Equipment] Load All Classification Success', props<{ classification: EquipmentClassificationModel[] }>());

export const getEquipmentTypes = createAction('[Equipment] Get Equipment Types');
export const getEquipmentTypesSuccess = createAction('[Equipment] Get Equipment Types Success', props<{ equipmentTypes: EquipmentTypeModel[] }>());

export const getAssignedEquipmentList = createAction('[Equipment] Get assigned equipment list',
  props<{ filter: any}>());
export const getAssignedEquipmentListSuccess = createAction('[Equipment] Get assigned equipment list Success',
  props<{ equipmentWithAssignmentList: PagingResultsModel<EquipmentWithAssignmentsModel> }>());

export const getAssignedEquipments = createAction('[Equipment] Get assigned equipments',
  props<{ filter: any }>());
export const getAssignedEquipmentsSuccess = createAction('[Equipment] Get assigned equipments Success',
  props<{ equipmentWithAssignment: PagingResultsModel<EquipmentWithAssignmentsModel> }>());

export const updateCreateEquipmentForm = createAction('[Equipment]  Update Equipment Form',
  props<{ equipmentValues: any, formErrors: FormError[] }>());
export const clearCreateEquipmentForm = createAction('[Equipment]  Clear Equipment Form');
export const editEquipmentForm = createAction('[Equipment] Edit Equipment Form',
  props<{equipment: EquipmentModel}>());

export const createEquipment = createAction('[Equipment] Create Equipment',
  props<{ equipment: EquipmentModel }>());
export const createEquipmentSuccess = createAction('[Equipment] Create Equipment Success',
  props<{ equipment: EquipmentModel }>());
export const createEquipmentFail = createAction('[Equipment] Create Equipment Fail',
  props<{ err: any }>());

export const deleteEquipment = createAction('[Equipment] Delete Equipment',
  props<{ equipment: EquipmentModel }>());
export const deleteEquipmentSuccess = createAction('[Equipment] Delete Equipment Success');
export const deleteEquipmentFail = createAction('[Equipment] Delete Equipment Fail',
  props<{ err: any }>());

export const unarchiveEquipment = createAction('[Equipment] Unarchive Equipment',
  props<{ equipment: EquipmentModel }>());
export const unarchiveEquipmentSuccess = createAction('[Equipment] Unarchive Equipment Success');
export const unarchiveEquipmentFail = createAction('[Equipment] Unarchive Equipment Fail',
  props<{ err: any }>());

export const getMyEquipmentList = createAction('[Equipment] Get My Equipments',
  props<{ userID: number }>());
export const getMyEquipmentListSuccess = createAction('[Equipment] Get My Equipments Success',
  props<{ equipments: AssignedEquipmentModel[] }>());
export const getMyEquipmentListFail = createAction('[Equipment] Get My Equipments Fail',
  props<{ err: any }>());


export const addEquipmentToAssign = createAction('[Equipment] Add Equipment To Assign',
  props<{ assignedEquipment: AssignedEquipmentModel }>());
export const removeEquipmentToAssign = createAction('[Equipment] Remove Equipment To Assign',
  props<{ assignedEquipment: AssignedEquipmentModel }>());
export const changedUserOnEquipmentToAssign = createAction('[Equipment] Change User Equipment To Assign',
  props<{ userID: number }>());
  export const changedAssignedProgramOnEquipmentToAssign = createAction('[Equipment] Change User Equipment To Assign',
  props<{ assignedProgramID: number }>());
export const clearEquipmentsToAssign = createAction('[Equipment] Clear Equipment To Assign');

export const AddReturnDate = createAction('[Equipment] Add Return Date to Equipment',
  props<{ assignedEquipment: AssignedEquipmentModel }>());
export const RemoveReturnDate = createAction('[Equipment] Remove Return Date for Equipment',
  props<{ assignedEquipment: AssignedEquipmentModel }>());
  export const SetReturnDate = createAction('[Equipment] Set Return Date for Equipment',
  props<{ assignedEquipment: AssignedEquipmentModel }>());

export const searchEquipment = createAction('[Equipment] Search Equipments',
  props<{ filter: string }>());
export const searchEquipmentSuccess = createAction('[Equipment] Search Equipments Success',
  props<{ equipments: PagingResultsModel<EquipmentModel> }>());
export const searchEquipmentFail = createAction('[Equipment] Search Equipments Fail',
  props<{ err: any }>());

export const setCreateStoredValueOther = createAction('[Equipment] set stored location',
  props<{ equipment: EquipmentModel }>());

export const saveAssignEquipment = createAction('[Equipment] Save Assigning Equipments',
  props<{ assigningEquipments: AssignedEquipmentModel[] }>());
export const saveAssignEquipmentSuccess = createAction('[Equipment] Save Assigning Equipments Success');
export const saveAssignEquipmentFail = createAction('[Equipment] Save Assigning Equipments Fail',
  props<{ err: any }>());

export const saveEditAssignEquipment = createAction('[Equipment] Save Edit Assigning Equipments',
  props<{ assigningEquipments: AssignedEquipmentModel[] }>());
export const saveEditAssignEquipmentSuccess = createAction('[Equipment] Save Edit Assigning Equipments Success');
export const saveEditAssignEquipmentFail = createAction('[Equipment] Save Edit Assigning Equipments Fail',
  props<{ err: any }>());

export const getEquipmentAssignPdf = createAction('[Equipment] Get Equipment Assign Pdf',
  props<{ assignedEquipmentID: number, returned: boolean, download: boolean }>());
export const getEquipmentAssignPdfSuccess = createAction('[Equipment]  Get Equipment Assign Pdf Success',
  props<{ doc: ArrayBuffer, download: boolean }>());


export const signEquipmentAssign = createAction('[Equipment] Sign Equipment Assign Doc',
  props<{ assignedEquipmentID: number, signedFields: string[] }>());
export const signEquipmentAssignSuccess = createAction('[Equipment]  Sign Equipment Assign Doc Success',
  props<{ docSigned: ArrayBuffer }>());

export const signEquipmentReturn = createAction('[Equipment] Sign Equipment Return Doc',
  props<{ assignedEquipmentID: number, signedFields: string[] }>());
export const signEquipmentReturnSuccess = createAction('[Equipment]  Sign Equipment Return Doc Success',
  props<{ docSigned: ArrayBuffer }>());




export const addAssigningAcessory = createAction('[Equipment] Add Assign Acessory',
  props<{ name: string, equipmentID: number }>());
export const removeAssigningAcessory = createAction('[Equipment] Remove Assign Acessory',
  props<{ acessory: EquipmentAcessoryModel }>());

export const loadUserEquipments = createAction('[Equipment] Load User Equipments',
  props<{ userID: number }>());
export const loadUserEquipmentsSuccess = createAction('[Equipment] Load User Equipments Success',
  props<{ assignedEquipments: AssignedEquipmentModel[] }>());
export const loadUserEquipmentsFail = createAction('[Equipment] Load User Equipments Fail',
  props<{ err: any }>());

export const saveReturnEquipment = createAction('[Equipment] Save Returning Equipments',
  props<{ returningEquipments: AssignedEquipmentModel[] }>());
export const saveReturnEquipmentSuccess = createAction('[Equipment] Save Returning Equipments Success',
  props<{ assignedEquipment: AssignedEquipmentModel }>());
export const saveReturnEquipmentFail = createAction('[Equipment] Save Returning Equipments Fail',
  props<{ err: any }>());


export const changedReturnEquipment = createAction('[Equipment] Changed Return Equipment',
  props<{ assignedEquipment: AssignedEquipmentModel }>());
