<form class="card mt-2">
  <div class="card-body">
    <h2 class="mb-3">Paylocity Transmission Report</h2>
    <div class="row mb-3">

      <div class="col-6">
        <div class="row">
          <div class="col-2 d-flex align-items-center">
            <h5>Employee:</h5>
          </div>
          <div class="col-10">
            <div class="ml-2 w-100 mb-2">
              <p-autoComplete [style]="{'width': '100%'}" [inputStyle]="{'width': '100%'}" [(ngModel)]="prefixText"
                              [suggestions]="userEmployeeList" (completeMethod)="getStaffList($event)" field="label"
                              [ngModelOptions]="{standalone: true}" [minLength]="1" [autoHighlight]="true"
                              (onSelect)="reloadListAfterStaffChage($event)" [forceSelection]="true"
                              (onClear)="reloadListAfterClear($event)"></p-autoComplete>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-2 d-flex align-items-center">
            <h5>Type:</h5>
          </div>
          <div class="col-10">
            <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="typeList" [(ngModel)]="transmissionType" (ngModelChange)="reloadList(this.filter)" [ngModelOptions]="{standalone: true}"></p-dropdown>
          </div>
        </div>

        <div class="row">
          <div class="col-2 d-flex align-items-center">
            <h5>Date Sent:</h5>
          </div>
          <div class="col-10">
            <i *ngIf="!dateRangeList" class="material-icons rotate text-muted">loop</i>
            <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="dateRangeList" [(ngModel)]="dateRange" (ngModelChange)="changedDateRange()" [ngModelOptions]="{standalone: true}"></p-dropdown>
          </div>
        </div>

        <div class="row" *ngIf="isCustomDateRange">
          <div class="col-2 d-flex align-items-center">
            <h5>Start Date:</h5>
          </div>
          <div class="col-10">
            <input type="date" class="form-control ml-2 w-100 mb-2" [(ngModel)]="startDate" (blur)="reloadList(this.filter)" [ngModelOptions]="{standalone: true}" />
          </div>
          <div class="col-2 d-flex align-items-center">
            <h5>End Date:</h5>
          </div>
          <div class="col-10">
            <input type="date" class="form-control ml-2 w-100 mb-2" [(ngModel)]="endDate" (blur)="reloadList(this.filter)" [ngModelOptions]="{standalone: true}" />
          </div>
        </div>

      </div>

      <div class="col-6">
        <div class="row mb-3">
          <div class="col-2 d-flex align-items-center">
            <h5>Has Error:</h5>
          </div>
          <div class="col-10">
            <p-selectButton styleClass="w-100" [options]="yesNoAllOptions" [(ngModel)]="hasError" (ngModelChange)="reloadList(this.filter)" [ngModelOptions]="{standalone: true}"></p-selectButton>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-2 d-flex align-items-center">
            <h5>Dismissed:</h5>
          </div>
          <div class="col-10">
            <p-selectButton styleClass="w-100" [options]="yesNoAllOptions" [(ngModel)]="dismissed" (ngModelChange)="reloadList(this.filter)" [ngModelOptions]="{standalone: true}"></p-selectButton>
          </div>
        </div>
      </div>

    </div>
    <i [hidden]="!loadingPaylocityList" class="material-icons rotate text-muted">loop</i>    
    <p-table [value]="list?.result" [responsive]="true" [paginator]="true" [rows]="20"
             [totalRecords]="list?.totalRecords" [lazy]="true" (onLazyLoad)="reloadList($event)">
      <ng-template pTemplate="header">
        <tr>          
          <th [pSortableColumn]="'EmployeeName'">Employee <p-sortIcon [field]="'EmployeeName'"></p-sortIcon></th>
          <th [pSortableColumn]="'Type'">Type <p-sortIcon [field]="'Type'"></p-sortIcon></th>
          <th [pSortableColumn]="'DateSent'">Date Sent <p-sortIcon [field]="'DateSent'"></p-sortIcon></th>
          <th>Request</th>
          <th>Response</th>
          <th [pSortableColumn]="'HasError'">Has Error <p-sortIcon [field]="'Has Error'"></p-sortIcon></th>
          <th [pSortableColumn]="'Dismissed'">Dismissed <p-sortIcon [field]="'Dismissed'"></p-sortIcon></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-list>
        <tr>
          <td>
            <span class="ui-column-title">EmployeeName</span>
            {{list.employeeFirstName}} {{list.employeeLastName}}
          </td>
          <td>
            <span class="ui-column-title">Type</span>
            {{list.transmissionType}}
          </td>          
          <td>
            <span class="ui-column-title">Date Sent</span>
            {{list.dateSent | date: 'MM/dd/yyyy @ hh:mm:ss a'}}
          </td>
          <td>
            <span class="ui-column-title">Request</span>
            <div style="max-height: 100px; overflow: auto; scroll-behavior: auto;">
              {{list.request}}            
            </div>
          </td>
          <td>
            <span class="ui-column-title">Response</span>
            {{list.response}}
          </td>
          <td>
            <span class="ui-column-title">Has Error</span>
            <span *ngIf="list.hasError">Yes</span>
            <span *ngIf="!list.hasError">No</span>
          </td>
          <td>
            <span class="ui-column-title">Dismissed</span>
            <span *ngIf="list.dateDismissed == null && canEdit"><i class="fas fa-check-double" style="color: green; cursor: pointer;" title="Dismiss" (click)="dismissTransmission(list)"></i></span>
            <span *ngIf="list.dateDismissed != null">{{(list.dateDismissed !== null) ? (list.dateDismissed | date: 'MM/dd/yyyy @ hh:mm:ss a') : ""}}</span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</form>
