import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from 'src/app/core/store';
import { AlertService } from 'src/app/shared/services/alert.service';
import { loadOnboardingDocInfo, loadOnboardingDoc } from 'src/app/core/store/onboardingdocs/onboardingdocs.actions';
import { OnBoardingDocumentUserModel } from 'src/app/models/onBoardingDocumentUserModel';
import { downloadFile } from 'src/app/helpers/utils';


@Component({
  selector: 'app-download-onboardingdoc',
  templateUrl: './download-onboardingdoc.component.html',
  styleUrls: ['./download-onboardingdoc.component.scss']
})
export class DownloadOnboardingdocComponent extends BaseComponent implements OnInit {

  constructor(private router: Router,
              private route: ActivatedRoute,
              private store: Store<State>,
              private alertService: AlertService, ) {
    super();
  }


  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params.onboardingDocUserId) {
        this.store.dispatch(loadOnboardingDocInfo({ onboardingDocUserId: params.onboardingDocUserId }));
      }
    });
    this.subs.push(
      this.store.select(s => s.onBoardingDocsState.docInfo).subscribe(docInfo => {
        if (docInfo) {
          this.viewDoc(docInfo);
        }
      }),
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        const canView = permissionIDs && !!permissionIDs.find(p => p === 3059 || p === 3060); //on boarding view, on boarding manage
        if (!canView) {
          this.alertService.error('You don´t have permission to download this file');
        }
      }),
    );

  }

  viewDoc(onboardingDocsUser: OnBoardingDocumentUserModel) {
    this.store.dispatch(loadOnboardingDoc({ onboardingDocUserId: onboardingDocsUser.id }));
    const subDocBuffer = this.store.select(s => s.onBoardingDocsState.docArrayBuffer).subscribe(docArrayBuffer => {
      if (docArrayBuffer) {
        downloadFile(new Blob([docArrayBuffer], { type: 'application/pdf' }), onboardingDocsUser.onBoarding_Document.fileName);
        subDocBuffer.unsubscribe();
        window.top.close();
      }
    });
  }
}

