import { createReducer, on, Action } from '@ngrx/store';
import { SelectItem } from 'primeng/api';
import { PagingResultsModel } from '../../../models/pagingResultsModel';
import { FormError, ModifiedSelectItem } from '../../../models/utilModels';
import { InternApplicationActionModel, InternApplicationActionTemplateModel, InternApplicationActionTypeModel, InternApplicationModel, InternshipSubstatusModel, InternUploadedFilesModel } from '../../../models/internModels';
import {
  createInternshipApplication, createInternshipApplicationFailure, createInternshipApplicationSuccess, getManageApplicationsList, getManageApplicationsListSuccess, getMyApplicationsList, getMyApplicationsListSuccess,
  loadInternFormsSuccess, loadInternshipStatusesSuccess, changeApplicationStatus, changeApplicationStatusFail, changeApplicationStatusSuccess, exportManageInternApplicationsListToExcel, exportManageInternApplicationsListToExcelSuccess,
  exportManageInternApplicationsListToExcelFailure, getInternActionTypeList, getInternActionTypeListSuccess, saveInternActionType, saveInternActionTypeSuccess, saveInternActionTypeFail, getInternActionType, getInternActionTypeSuccess,
  getInternActionTypeFailure, showEditInternActionType, closeEditInternActionType, deleteInternActionType, deleteInternActionTypeSuccess, deleteInternActionTypeFail, getInternApplication, getInternApplicationSuccess,
  getInternApplicationFailure, saveInternActionItem, saveInternActionItemSuccess, saveInternActionItemFail, deleteInternActionItem, deleteInternActionItemSuccess, deleteInternActionItemFail, getInternActionItem, getInternActionItemSuccess,
  getInternActionItemFailure, loadInternshipActionTypesSuccess, showEditInternActionItem, closeEditInternActionItem, getInternUploads, getInternUploadsSuccess, getInternUploadsFailure, saveInternUploads, saveInternUploadsSuccess,
  saveInternUploadsFail, deleteInternUpload, deleteInternUploadSuccess, deleteInternUploadFail, showEditInternUploads, getInternApplicationUpload, getInternApplicationUploadSuccess, getInternApplicationUploadFailure, closeEditInternUploads,
  updateHourAndProductivityCalculations, updateHourAndProductivityCalculationsSuccess, updateHourAndProductivityCalculationsFail, loadInternshipSubstatusesSuccess, changeApplicationSubstatus, changeApplicationSubstatusSuccess,
  changeApplicationSubstatusFail, getInternshipSubstatusList, getInternshipSubstatusListSuccess, showEditInternshipSubstatus, saveInternshipSubstatus, saveInternshipSubstatusSuccess, saveInternshipSubstatusFail,
  deleteInternshipSubstatus, deleteInternshipSubstatusSuccess, deleteInternshipSubstatusFail, getInternshipSubstatus, getInternshipSubstatusSuccess, getInternshipSubstatusFailure, closeEditInternshipSubstatus,
  getInternActionItemTemplateList, getInternActionItemTemplateListSuccess, showEditInternActionItemTemplate, closeEditInternActionItemTemplate, saveInternActionItemTemplate, saveInternActionItemTemplateSuccess,
  saveInternActionItemTemplateFail, deleteInternActionItemTemplate, deleteInternActionItemTemplateSuccess, deleteInternActionItemTemplateFail, getInternActionItemTemplate, getInternActionItemTemplateSuccess,
  getInternActionItemTemplateFailure, saveInternApplicationReview, saveInternApplicationReviewSuccess, saveInternApplicationReviewFail, createInternship, createInternshipSuccess, createInternshipFail, loadInternApplicationDocToSign, loadInternApplicationDocToSignFail, loadInternApplicationDocToSignSuccess
} from './intern.action';


export interface InternState {
  formTemplates: SelectItem[];
  internshipStatuses: ModifiedSelectItem[];
  internshipSubstatuses: ModifiedSelectItem[];
  internshipActionTypes: SelectItem[];

  loadingMyApplications: boolean;
  myApplicationList: PagingResultsModel<InternApplicationModel>;

  loadingManageApplications: boolean;
  manageApplicationList: PagingResultsModel<InternApplicationModel>;

  savingMyApplication: boolean;
  savingApplicationReview: boolean;

  loadingManageApplicationsExcel: boolean;
  manageApplicationsExcelArrayBuffer: ArrayBuffer;

  loadingActionTypeList: boolean;
  actionTypeList: PagingResultsModel<InternApplicationActionTypeModel>;

  loadingSubstatusList: boolean;
  subStatusList: PagingResultsModel<InternshipSubstatusModel>;

  loadingActionItemTemplateList: boolean;
  actionItemTemplateList: PagingResultsModel<InternApplicationActionTemplateModel>;

  actionTypeId: number;
  editingActionType: boolean;
  savingActionType: boolean;
  loadingActionType: boolean;

  selectedActionType: InternApplicationActionTypeModel;
  formErrorsActionType: FormError[];
    
  savingApplication: boolean;
  loadingApplication: boolean;
  selectedApplication: InternApplicationModel;
  formErrorsApplication: FormError[];

  actionItemId: number;
  actionApplicationId: number;
  editingActionItem: boolean;
  savingActionItem: boolean;
  loadingActionItem: boolean;

  selectedActionItem: InternApplicationActionModel;
  formErrorsActionItem: FormError[];
  
  uploadApplicationId: number;
  editingUploads: boolean;
  savingUploads: boolean;
  loadingUploads: boolean;

  selectedUploads: InternUploadedFilesModel;
  formErrorsUploads: FormError[];

  loadingApplicationUpload: boolean;
  applicationUploadArrayBuffer: ArrayBuffer;

  loadingDoc: boolean;
  docArrayBuffer: ArrayBuffer;

  substatusId: number;
  editingSubstatus: boolean;
  savingSubstatus: boolean;
  loadingSubstatus: boolean;

  selectedSubstatus: InternshipSubstatusModel;
  formErrorsSubstatus: FormError[];

  templateId: number;
  editingTemplate: boolean;
  savingTemplate: boolean;
  loadingTemplate: boolean;

  selectedTemplate: InternApplicationActionTemplateModel;
  formErrorsTemplate: FormError[];
}

const initialState: InternState = {
  formTemplates: [],
  internshipStatuses: [],
  internshipSubstatuses: [],
  internshipActionTypes: [],

  loadingMyApplications: true,
  myApplicationList: null,

  loadingManageApplications: true,
  manageApplicationList: null,

  savingMyApplication: false,
  savingApplicationReview: false,

  loadingManageApplicationsExcel: false,
  manageApplicationsExcelArrayBuffer: null,

  loadingActionTypeList: true,
  actionTypeList: null,

  loadingSubstatusList: true,
  subStatusList: null,

  loadingActionItemTemplateList: true,
  actionItemTemplateList: null,

  actionTypeId: 0,
  editingActionType: false,
  savingActionType: false,
  loadingActionType: false,

  selectedActionType: null,
  formErrorsActionType: [],

  savingApplication: false,
  loadingApplication: false,
  selectedApplication: null,
  formErrorsApplication: [],

  actionItemId: 0,
  actionApplicationId: 0,
  editingActionItem: false,
  savingActionItem: false,
  loadingActionItem: false,

  selectedActionItem: null,
  formErrorsActionItem: [],

  uploadApplicationId: 0,
  editingUploads: false,
  savingUploads: false,
  loadingUploads: false,

  selectedUploads: null,
  formErrorsUploads: [],

  loadingApplicationUpload: false,
  applicationUploadArrayBuffer: null,

  loadingDoc: false,
  docArrayBuffer: null,

  substatusId: 0,
  editingSubstatus: false,
  savingSubstatus: false,
  loadingSubstatus: false,

  selectedSubstatus: null,
  formErrorsSubstatus: [],

  templateId: 0,
  editingTemplate: false,
  savingTemplate: false,
  loadingTemplate: false,

  selectedTemplate: null,
  formErrorsTemplate: [],
};

export const internReducer = createReducer(initialState,
  on(loadInternApplicationDocToSign, (state, action) => {
    return {
      ...state,
      loadingDoc: true
    };
  }),
  on(loadInternApplicationDocToSignSuccess, (state, action) => {
    return {
      ...state,
      docArrayBuffer: action.doc,
      loadingDoc: false
    };
  }),

  on(loadInternApplicationDocToSignFail, (state, action) => {
    return {
      ...state,
      loadingSign: false
    };
  }),
  on(loadInternFormsSuccess, (state, action) => {
    return {
      ...state,
      formTemplates: action.forms,
    };
  }),
  on(loadInternshipStatusesSuccess, (state, action) => {
    return {
      ...state,
      internshipStatuses: action.statuses,
    };
  }),
  on(loadInternshipSubstatusesSuccess, (state, action) => {
    return {
      ...state,
      internshipSubstatuses: action.statuses,
    };
  }),
  on(loadInternshipActionTypesSuccess, (state, action) => {
    return {
      ...state,
      internshipActionTypes: action.actionTypes,
    };
  }),
  on(getMyApplicationsList, (state, action) => {
    return {
      ...state,
      loadingMyApplications: true
    };
  }),
  on(getMyApplicationsListSuccess, (state, action) => {
    return {
      ...state,
      loadingMyApplications: false,
      myApplicationList: action.list
    };
  }),
  on(getManageApplicationsList, (state, action) => {
    return {
      ...state,
      loadingManageApplications: true
    };
  }),
  on(getManageApplicationsListSuccess, (state, action) => {
    return {
      ...state,
      loadingManageApplications: false,
      manageApplicationList: action.list
    };
  }),
  on(getInternActionTypeList, (state, action) => {
    return {
      ...state,
      loadingActionTypeList: true
    };
  }),
  on(getInternActionTypeListSuccess, (state, action) => {
    return {
      ...state,
      loadingActionTypeList: false,
      actionTypeList: action.list
    };
  }),
  on(getInternshipSubstatusList, (state, action) => {
    return {
      ...state,
      loadingSubstatusList: true
    };
  }),
  on(getInternshipSubstatusListSuccess, (state, action) => {
    return {
      ...state,
      loadingSubstatusList: false,
      subStatusList: action.list
    };
  }),
  on(getInternActionItemTemplateList, (state, action) => {
    return {
      ...state,
      loadingActionItemTemplateList: true
    };
  }),
  on(getInternActionItemTemplateListSuccess, (state, action) => {
    return {
      ...state,
      loadingActionItemTemplateList: false,
      actionItemTemplateList: action.list
    };
  }),
  on(createInternshipApplication, (state, action) => {
    return {
      ...state,
      savingMyApplication: true
    };
  }),
  on(createInternshipApplicationSuccess, (state, action) => {
    return {
      ...state,
      savingMyApplication: false,      
    };
  }),
  on(createInternshipApplicationFailure, (state, action) => {
    return {
      ...state,
      savingMyApplication: false,
    };
  }),
  on(changeApplicationStatus, (state, action) => {
    return {
      ...state,
      savingApplicationReview: true,
    };
  }),
  on(changeApplicationStatusSuccess, (state, action) => {
    return {
      ...state,
      savingApplicationReview: false,
    };
  }),
  on(changeApplicationStatusFail, (state, action) => {
    return {
      ...state,
      savingApplicationReview: false,
    };
  }),
  on(changeApplicationSubstatus, (state, action) => {
    return {
      ...state,
      savingApplicationReview: true,
    };
  }),
  on(changeApplicationSubstatusSuccess, (state, action) => {
    return {
      ...state,
      savingApplicationReview: false,
    };
  }),
  on(changeApplicationSubstatusFail, (state, action) => {
    return {
      ...state,
      savingApplicationReview: false,
    };
  }),
  on(updateHourAndProductivityCalculations, (state, action) => {
    return {
      ...state,
      savingMyApplication: true
    };
  }),
  on(updateHourAndProductivityCalculationsSuccess, (state, action) => {
    return {
      ...state,
      savingMyApplication: false,
    };
  }),
  on(updateHourAndProductivityCalculationsFail, (state, action) => {
    return {
      ...state,
      savingMyApplication: false,
    };
  }),
  on(exportManageInternApplicationsListToExcel, (state, action) => {
    return {
      ...state,
      loadingManageApplicationsExcel: true,
      manageApplicationsExcelArrayBuffer: null
    };
  }),
  on(exportManageInternApplicationsListToExcelSuccess, (state, action) => {
    return {
      ...state,
      loadingManageApplicationsExcel: false,
      manageApplicationsExcelArrayBuffer: action.doc
    };
  }),
  on(exportManageInternApplicationsListToExcelFailure, (state, action) => {
    return {
      ...state,
      loadingManageApplicationsExcel: false
    };
  }),
  on(showEditInternActionType, (state, action) => {
    return {
      ...state,      
      actionTypeId: action.actionTypeId,
      editingActionType: true
    };
  }),
  on(closeEditInternActionType, (state, action) => {
    return {
      ...state,
      selectedActionType: null,
      actionTypeId: 0,
      editingActionType: false
    };
  }),
  on(showEditInternActionItem, (state, action) => {
    return {
      ...state,
      actionItemId: action.actionId,
      actionApplicationId: action.actionApplicationId,
      editingActionItem: true
    };
  }),
  on(closeEditInternActionItem, (state, action) => {
    return {
      ...state,
      selectedActionItem: null,
      actionItemId: 0,
      editingActionItem: false
    };
  }),
  on(saveInternActionType, (state, action) => {
    return {
      ...state,
      savingActionType: true
    }
  }),
  on(saveInternActionTypeSuccess, (state, action) => {
    return {
      ...state,
      savingActionType: false
    }
  }),
  on(saveInternActionTypeFail, (state, action) => {
    return {
      ...state,
      savingActionType: false
    }
  }),
  on(deleteInternActionType, (state, action) => {
    return {
      ...state,
      savingActionType: true
    }
  }),
  on(deleteInternActionTypeSuccess, (state, action) => {
    return {
      ...state,
      savingActionType: false
    }
  }),
  on(deleteInternActionTypeFail, (state, action) => {
    return {
      ...state,
      savingActionType: false
    }
  }),
  on(getInternActionType, (state, action) => {
    return {
      ...state,
      loadingActionType: true
    };
  }),
  on(getInternActionTypeSuccess, (state, action) => {
    return {
      ...state,
      selectedActionType: action.actionType,
      loadingActionType: false
    };
  }),
  on(getInternActionTypeFailure, (state, action) => {
    return {
      ...state,
      loadingActionType: false
    };
  }),
  on(getInternApplication, (state, action) => {
    return {
      ...state,
      loadingApplication: true
    };
  }),
  on(getInternApplicationSuccess, (state, action) => {
    return {
      ...state,
      selectedApplication: action.application,
      loadingApplication: false
    };
  }),
  on(getInternApplicationFailure, (state, action) => {
    return {
      ...state,
      loadingApplication: false
    };
  }),
  on(saveInternActionItem, (state, action) => {
    return {
      ...state,
      savingActionItem: true
    }
  }),
  on(saveInternActionItemSuccess, (state, action) => {
    return {
      ...state,
      savingActionItem: false
    }
  }),
  on(saveInternActionItemFail, (state, action) => {
    return {
      ...state,
      savingActionItem: false
    }
  }),
  on(deleteInternActionItem, (state, action) => {
    return {
      ...state,
      savingActionItem: true
    }
  }),
  on(deleteInternActionItemSuccess, (state, action) => {
    return {
      ...state,
      savingActionItem: false
    }
  }),
  on(deleteInternActionItemFail, (state, action) => {
    return {
      ...state,
      savingActionItem: false
    }
  }),
  on(getInternActionItem, (state, action) => {
    return {
      ...state,
      loadingActionItem: true
    };
  }),
  on(getInternActionItemSuccess, (state, action) => {
    return {
      ...state,
      selectedActionItem: action.actionItem,
      loadingActionItem: false
    };
  }),
  on(getInternActionItemFailure, (state, action) => {
    return {
      ...state,
      loadingActionItem: false
    };
  }),
  on(getInternUploads, (state, action) => {
    return {
      ...state,
      loadingUploads: true
    };
  }),
  on(getInternUploadsSuccess, (state, action) => {
    return {
      ...state,
      selectedUploads: action.uploads,
      loadingUploads: false
    };
  }),
  on(getInternUploadsFailure, (state, action) => {
    return {
      ...state,
      loadingUploads: false
    };
  }),
  on(saveInternUploads, (state, action) => {
    return {
      ...state,
      savingUploads: true
    }
  }),
  on(saveInternUploadsSuccess, (state, action) => {
    return {
      ...state,
      savingUploads: false
    }
  }),
  on(saveInternUploadsFail, (state, action) => {
    return {
      ...state,
      savingUploads: false
    }
  }),
  on(deleteInternUpload, (state, action) => {
    return {
      ...state,
      savingUploads: true
    }
  }),
  on(deleteInternUploadSuccess, (state, action) => {
    return {
      ...state,
      savingUploads: false
    }
  }),
  on(deleteInternUploadFail, (state, action) => {
    return {
      ...state,
      savingUploads: false
    }
  }),
  on(showEditInternUploads, (state, action) => {
    return {
      ...state,      
      uploadApplicationId: action.applicationId,
      editingUploads: true
    };
  }),
  on(closeEditInternUploads, (state, action) => {
    return {
      ...state,
      selectedUploads: null,
      uploadApplicationId: 0,
      editingUploads: false
    };
  }),
  on(getInternApplicationUpload, (state, action) => {
    return {
      ...state,
      loadingApplicationUpload: true
    };
  }),
  on(getInternApplicationUploadSuccess, (state, action) => {
    return {
      ...state,
      applicationUploadArrayBuffer: action.doc,
      loadingApplicationUpload: false
    };
  }),
  on(getInternApplicationUploadFailure, (state, action) => {
    return {
      ...state,
      loadingApplicationUpload: false
    };
  }),
  on(showEditInternshipSubstatus, (state, action) => {
    return {
      ...state,
      substatusId: action.substatusId,
      editingSubstatus: true
    };
  }),
  on(closeEditInternshipSubstatus, (state, action) => {
    return {
      ...state,
      selectedSubstatus: null,
      substatusId: 0,
      editingSubstatus: false
    };
  }),
  on(saveInternshipSubstatus, (state, action) => {
    return {
      ...state,
      savingSubstatus: true
    }
  }),
  on(saveInternshipSubstatusSuccess, (state, action) => {
    return {
      ...state,
      savingSubstatus: false
    }
  }),
  on(saveInternshipSubstatusFail, (state, action) => {
    return {
      ...state,
      savingSubstatus: false
    }
  }),
  on(deleteInternshipSubstatus, (state, action) => {
    return {
      ...state,
      savingSubstatus: true
    }
  }),
  on(deleteInternshipSubstatusSuccess, (state, action) => {
    return {
      ...state,
      savingSubstatus: false
    }
  }),
  on(deleteInternshipSubstatusFail, (state, action) => {
    return {
      ...state,
      savingSubstatus: false
    }
  }),
  on(getInternshipSubstatus, (state, action) => {
    return {
      ...state,
      loadingSubstatus: true
    };
  }),
  on(getInternshipSubstatusSuccess, (state, action) => {
    return {
      ...state,
      selectedSubstatus: action.substatus,
      loadingSubstatus: false
    };
  }),
  on(getInternshipSubstatusFailure, (state, action) => {
    return {
      ...state,
      loadingSubstatus: false
    };
  }),

  on(showEditInternActionItemTemplate, (state, action) => {
    return {
      ...state,
      templateId: action.templateId,
      editingTemplate: true
    };
  }),
  on(closeEditInternActionItemTemplate, (state, action) => {
    return {
      ...state,
      selectedTemplate: null,
      templateId: 0,
      editingTemplate: false
    };
  }),
  on(saveInternActionItemTemplate, (state, action) => {
    return {
      ...state,
      savingTemplate: true
    }
  }),
  on(saveInternActionItemTemplateSuccess, (state, action) => {
    return {
      ...state,
      savingTemplate: false
    }
  }),
  on(saveInternActionItemTemplateFail, (state, action) => {
    return {
      ...state,
      savingTemplate: false
    }
  }),
  on(deleteInternActionItemTemplate, (state, action) => {
    return {
      ...state,
      savingTemplate: true
    }
  }),
  on(deleteInternActionItemTemplateSuccess, (state, action) => {
    return {
      ...state,
      savingTemplate: false
    }
  }),
  on(deleteInternActionItemTemplateFail, (state, action) => {
    return {
      ...state,
      savingTemplate: false
    }
  }),
  on(getInternActionItemTemplate, (state, action) => {
    return {
      ...state,
      loadingTemplate: true
    };
  }),
  on(getInternActionItemTemplateSuccess, (state, action) => {
    return {
      ...state,
      selectedTemplate: action.template,
      loadingTemplate: false
    };
  }),
  on(getInternActionItemTemplateFailure, (state, action) => {
    return {
      ...state,
      loadingTemplate: false
    };
  }),

  on(saveInternApplicationReview, (state, action) => {
    return {
      ...state,
      savingApplicationReview: true,
    };
  }),
  on(saveInternApplicationReviewSuccess, (state, action) => {
    return {
      ...state,
      savingApplicationReview: false,
    };
  }),
  on(saveInternApplicationReviewFail, (state, action) => {
    return {
      ...state,
      savingApplicationReview: false,
    };
  }),

  on(createInternship, (state, action) => {
    return {
      ...state,
      savingApplicationReview: true,
    };
  }),
  on(createInternshipSuccess, (state, action) => {
    return {
      ...state,
      savingApplicationReview: false,
    };
  }),
  on(createInternshipFail, (state, action) => {
    return {
      ...state,
      savingApplicationReview: false,
    };
  }),
)

export function internReducerFunc(state: InternState | undefined, action: Action) {
  return internReducer(state, action);
}
