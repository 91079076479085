import { createAction, props } from '@ngrx/store';
import { PayrollItemModel, PayrollApprovedTotalsModel } from 'src/app/models/payableItem';
import { PayrollClassificationModel } from 'src/app/models/payrollClassificationModel';
import { CampusModel } from 'src/app/models/campusModel';

export const loadApprovedTime = createAction('[Payroll] Load Approved Time', props<{ periodID: number }>());
export const loadApprovedTimeSuccess = createAction('[Payroll] Load Approved Time Success', props<{ payrollItems: PayrollItemModel[] }>());

export const loadInvalidTime = createAction('[Payroll] Load Invalid Time', props<{ periodID: number }>());
export const loadInvalidTimeSuccess = createAction('[Payroll] Load Invalid Time Success', props<{ payrollItems: PayrollItemModel[] }>());

export const loadAdjustmentTime = createAction('[Payroll] Load Adjustment Time', props<{ periodID: number }>());
export const loadAdjustmentTimeSuccess =
  createAction('[Payroll] Load Adjustment Time Success', props<{ payrollItems: PayrollItemModel[] }>());

export const loadPendingTime = createAction('[Payroll] Load Pending Time', props<{ periodID: number }>());
export const loadPendingTimeSuccess = createAction('[Payroll] Load Pending Time Success', props<{ payrollItems: PayrollItemModel[] }>());


export const loadApprovedTotals = createAction('[Payroll] Load Approved Totals', props<{ periodID: number }>());
export const loadApprovedTotalsSuccess =
  createAction('[Payroll] Load Approved TotalsSuccess', props<{ approvedTotals: PayrollApprovedTotalsModel }>());
