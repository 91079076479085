<form class="card mt-2">
  <div class="card-body">
    <div *ngIf="canEdit" class="float-right">
      <button type="button" routerLink="/benefits-management/status-check/queue" class="btn btn-secondary mr-2">Queue</button>
      <button type="button" routerLink="/benefits-management/status-check/list" class="btn btn-secondary mr-2">Status Checks</button>
      <button type="button" routerLink="/benefits-management/withdrawn" class="btn btn-secondary mr-2">Withdrawn</button>
      <button type="button" routerLink="/benefits-management/logs" class="btn btn-secondary mr-2">Logs</button>
      <button type="button" routerLink="/benefits-management/hsa" class="btn btn-secondary">HSA</button>
    </div>
    <h2 class="mb-3">Benefits Declined</h2>
    <div class="mb-3">

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Campus:</h5>
        </div>
        <div class="col-10">
          <i *ngIf="!campusList" class="material-icons rotate text-muted">loop</i>
          <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="campusList" [(ngModel)]="campusId" (ngModelChange)="reloadFilter(this.filter)" [ngModelOptions]="{standalone: true}"></p-dropdown>
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Employee:</h5>
        </div>
        <div class="col-10">
          <div class="ml-2 w-100 mb-2">
            <p-autoComplete [style]="{'width': '100%'}" [inputStyle]="{'width': '100%'}" [(ngModel)]="prefixText"
                            [suggestions]="userEmployeeList" (completeMethod)="getStaffList($event)" field="label"
                            [ngModelOptions]="{standalone: true}" [minLength]="1" [autoHighlight]="true"
                            (onSelect)="reloadListAfterStaffChage($event)" [forceSelection]="true"
                            (onClear)="reloadListAfterClear($event)"></p-autoComplete>
          </div>
        </div>
      </div>      

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5 title="As of the check date">Benefit Plan Type:</h5>
        </div>
        <div class="col-10">
          <i *ngIf="!planTypes" class="material-icons rotate text-muted">loop</i>
          <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="planTypes" [(ngModel)]="planTypeId" optionValue="value" (ngModelChange)="reloadFilter(this.filter)" [ngModelOptions]="{standalone: true}"></p-dropdown>
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5 title="As of the check date">Declined Reason:</h5>
        </div>
        <div class="col-10">
          <i *ngIf="!declineReasons" class="material-icons rotate text-muted">loop</i>
          <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="declineReasons" [(ngModel)]="declineReasonId" optionValue="value" (ngModelChange)="reloadFilter(this.filter)" [ngModelOptions]="{standalone: true}"></p-dropdown>
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Declined Date:</h5>
        </div>
        <div class="col-10">
          <i *ngIf="!dateRangeList" class="material-icons rotate text-muted">loop</i>
          <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="dateRangeList" [(ngModel)]="dateRange" (ngModelChange)="changedDateRange()" [ngModelOptions]="{standalone: true}"></p-dropdown>
        </div>
      </div>

      <div class="row" *ngIf="isCustomDateRange">
        <div class="col-2 d-flex align-items-center">
          <h5>Start Date:</h5>
        </div>
        <div class="col-10">
          <input type="date" class="form-control ml-2 w-100 mb-2" [(ngModel)]="startDate" (blur)="reloadFilter(this.filter)" [ngModelOptions]="{standalone: true}" />
        </div>
        <div class="col-2 d-flex align-items-center">
          <h5>End Date:</h5>
        </div>
        <div class="col-10">
          <input type="date" class="form-control ml-2 w-100 mb-2" [(ngModel)]="endDate" (blur)="reloadFilter(this.filter)" [ngModelOptions]="{standalone: true}" />
        </div>
      </div>      
    </div>

    <i [hidden]="!loadingList" class="material-icons rotate text-muted">loop</i>   
    <p-table [value]="list?.result" [responsive]="true" [paginator]="true" [rows]="20"
             [totalRecords]="list?.totalRecords" [lazy]="true" (onLazyLoad)="reloadFilter($event)">
      <ng-template pTemplate="header">
        <tr>          
          <th [pSortableColumn]="'EmployeeName'">Employee <p-sortIcon [field]="'EmployeeName'"></p-sortIcon></th>
          <th [pSortableColumn]="'PrimaryProgramName'">Primary Campus <p-sortIcon [field]="'PrimaryProgramName'"></p-sortIcon></th>
          <th [pSortableColumn]="'PlanType'">Plan Type <p-sortIcon [field]="'PlanType'"></p-sortIcon></th>
          <th [pSortableColumn]="'DeclineReason'">Decline Reason <p-sortIcon [field]="'DeclineReason'"></p-sortIcon></th>
          <th [pSortableColumn]="'DateDeclined'">Date Declined <p-sortIcon [field]="'DateDeclined'"></p-sortIcon></th>
          <th [pSortableColumn]="'DeclinedBy'">Declined By <p-sortIcon [field]="'DeclinedBy'"></p-sortIcon></th>          
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-list>
        <tr>          
          <td>
            <span class="ui-column-title">Employee</span>
            {{list.firstName}} {{list.lastName}}
          </td>
          <td>
            <span class="ui-column-title">Primary Campus</span>
            {{list.primaryProgramName}}
          </td>
          <td>
            <span class="ui-column-title">Plan Type</span>
            {{list.benefitPlanType}}
          </td>
          <td>
            <span class="ui-column-title">Decline Reason</span>
            {{list.declineReason}} {{list.declineReason == "Other" && list.otherReason && list.otherReason.length > 0 != null ? (" - " + list.otherReason) : ""}}
          </td>         
          <td>
            <span class="ui-column-title">Date Declined</span>
            {{list.createdDate | date: 'MM/dd/yyyy @ hh:mm:ss a'}}
          </td>
          <td>
            <span class="ui-column-title">DeclinedBy</span>
            {{list.declinedByFirstName}} {{list.declinedByLastName}}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</form>
