<form class="card mt-2">
  <div class="card-body">
    <div class="float-right">
      <button type="button" *ngIf="canViewSchedulingTimeOff" routerLink="/timeoff/scheduling/manage" class="btn btn-secondary" style="margin-right: 5px;">Manage Time Off</button>
      <button type="button" routerLink="/timeoff/ptoSummary" class="btn btn-secondary">PTO Summary</button>
    </div>
    <h2 class="mb-3">Paid Time Off Earned</h2>
    <div class="mb-3">

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Campus:</h5>
        </div>
        <div class="col-10">
          <i *ngIf="!campusList" class="material-icons rotate text-muted">loop</i>
          <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="campusList" [(ngModel)]="campusId" (ngModelChange)="reloadFilter(this.filter)" [ngModelOptions]="{standalone: true}"></p-dropdown>
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Employee:</h5>
        </div>
        <div class="col-10">
          <div class="ml-2 w-100 mb-2">
            <p-autoComplete [style]="{'width': '100%'}" [inputStyle]="{'width': '100%'}" [(ngModel)]="prefixText"
                            [suggestions]="userList" (completeMethod)="getStaffList($event)" field="label"
                            [ngModelOptions]="{standalone: true}" [minLength]="1" [autoHighlight]="true"
                            (onSelect)="reloadListAfterStaffChage($event)" [forceSelection]="true"
                            (onClear)="reloadListAfterClear($event)"></p-autoComplete>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>From Date:</h5>
        </div>
        <div class="col-10">
          <input type="date" class="form-control ml-2 w-100 mb-2" [(ngModel)]="startDate" (blur)="reloadFilter(this.filter)" [ngModelOptions]="{standalone: true}" />
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>To Date:</h5>
        </div>
        <div class="col-10">
          <input type="date" class="form-control ml-2 w-100 mb-2" [(ngModel)]="endDate" (blur)="reloadFilter(this.filter)" [ngModelOptions]="{standalone: true}" />
        </div>
      </div>
    </div>

    <div class="mb-3">
      <button *ngIf="canEdit" (click)="editPtoEarned([])" class="btn btn-secondary">New PTO Earned</button>
    </div>
    <i [hidden]="!loadingList" class="material-icons rotate text-muted">loop</i>
    <p-table [value]="ptoEarnedList?.result" [responsive]="true" [paginator]="true" [rows]="20"
             [totalRecords]="ptoEarnedList?.totalRecords" [lazy]="true" (onLazyLoad)="reloadFilter($event)">
      <ng-template pTemplate="header">
        <tr>
          <th width="100px"></th>
          <th [pSortableColumn]="'EmployeeName'">Employee<p-sortIcon [field]="'EmployeeName'"></p-sortIcon></th>
          <th [pSortableColumn]="'DateEarned'">Date Earned<p-sortIcon [field]="'DateEarned'"></p-sortIcon></th>
          <th [pSortableColumn]="'ApprovedPayrollMinutes'">Approved Payroll Hours<p-sortIcon [field]="'ApprovedPayrollMinutes'"></p-sortIcon></th>
          <th [pSortableColumn]="'PayrollStartDate'">Pay Period<p-sortIcon [field]="'PayrollStartDate'"></p-sortIcon></th>
          <th [pSortableColumn]="'PercentTimeOff'">Percent Time Off<p-sortIcon [field]="'PercentTimeOff'"></p-sortIcon></th>
          <th [pSortableColumn]="'MinutesEarned'">Pto Earned<p-sortIcon [field]="'MinutesEarned'"></p-sortIcon></th>
          <th [pSortableColumn]="'MinutesLost'">Minutes Lost<p-sortIcon [field]="'MinutesLost'"></p-sortIcon></th>
          <th [pSortableColumn]="'Comments'">Comments<p-sortIcon [field]="'Comments'"></p-sortIcon></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-pto>
        <tr>
          <td>
            <button class="btn btn-secondary mr-2" *ngIf="canEdit && pto.payrollStartDate == null" (click)="editPtoEarned(pto)" title="Edit PTO Earned">
              <i class="fas fa-edit"></i>
            </button>
          </td>
          <td>
            <span class="ui-column-title">Employee Name</span>            
            {{pto.employeeName}}
          </td>
          <td>
            <span class="ui-column-title">Date Earned<
            </span>

            {{pto.dateEarned | date: 'MM/dd/yyyy'}}
          </td>
          <td>
            <span class="ui-column-title">Approved Payroll Minutes</span>
            {{pto.approvedPayrollMinutes | duration}}
          </td>
          <td>
            <span class="ui-column-title">Pay Period</span>
            {{pto.payrollStartDate | date: 'MM/dd/yyyy' || ''}} - {{pto.payrollEndDate | date: 'MM/dd/yyyy' || ''}}
          </td>
          <td>
            <span class="ui-column-title">Percent Time Off</span>
            {{pto.percentTimeOff | percent: '1.2'}}
          </td>
          <td>
            <span class="ui-column-title">Pto Earned</span>
            {{pto.minutesEarned | duration}}
          </td>
          <td>
            <span class="ui-column-title">Pto Lost</span>
            {{pto.minutesLost | duration}}
          </td>
          <td>
            <span class="ui-column-title">Comments</span>
            <span style="display: block;">{{pto.comments}}</span>
            <span *ngIf="pto.approvedPayrollMinutes !== null && (pto.approvedPayrollMinutes / 60 > 80 || (pto.payPeriodApprovedMinutes / 60 > 80 && pto.minutesLost > 0))" style="display: block;">Approved Pay Period Hours > 80</span>
            <span *ngIf="pto.exceedsMaxNet" style="display: block;">Net Total > max allowed</span>
            <span *ngIf="pto.exceedsMaxYtd" style="display: block;">YTD Total > max allowed</span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</form>

<app-edit-pto-earned></app-edit-pto-earned>
