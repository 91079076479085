import { createAction, props } from '@ngrx/store';
import { SelectItem } from 'primeng/api';
import { CertificationInstructorModel, CertificationReportModel, CertificationTypeUploadModel, CeuEventModel } from '../../../models/certificationModel';
import { PagingResultsModel } from '../../../models/pagingResultsModel';
import { FormError } from '../../../models/utilModels';

export const loadCertificationTypes = createAction('[Certification] Load Certification Types');
export const loadCertificationTypesSuccess = createAction('[Certification] Load Certification Types Success',
  props<{ certificationTypes: SelectItem[] }>());

export const loadCeuCategories = createAction('[Certification] Load CEU Categories');
export const loadCeuCategoriesSuccess = createAction('[Certification] Load CEU Categories Success',
  props<{ ceuCategories: SelectItem[] }>());

export const loadCeuTypes = createAction('[Certification] Load CEU Types');
export const loadCeuTypesSuccess = createAction('[Certification] Load CEU Types Success',
  props<{ ceuTypes: SelectItem[] }>());

export const loadInstructors = createAction('[Certification] Load Instructors',
  props<{ prefixText: string, certificationTypeId: number, showArchived: boolean, showTerminated: boolean, showInactive: boolean }>());
export const loadInstructorsSuccess = createAction('[Certification] Load Instructors Success',
  props<{ instructors: SelectItem[] }>());

export const getCertificationInstructorList = createAction('[Certification] Get Certification Instructor List',
  props<{ filter: any }>());
export const getCertificationInstructorListSuccess = createAction('[Certification] Get Certification Instructor List Success',
  props<{ list: PagingResultsModel<CertificationInstructorModel> }>());

export const showEditInstructor = createAction('[Certification] Show Edit Instructor Modal',
  props<{ instructor: CertificationInstructorModel }>());
export const closeEditInstructor = createAction('[Certification] Close Edit Instructor Modal');

export const saveInstructor = createAction('[Certification] Save Instructor',
  props<{ instructor: CertificationInstructorModel }>());
export const saveInstructorSuccess = createAction('[Certification] Save Instructor Success');
export const saveInstructorFail = createAction('[Certification] Save Instructor Fail',
  props<{ err: any }>());

export const updateInstructorForm = createAction('[Certification] Update Instructor Form',
  props<{ formValues: any, formErrors: FormError[] }>());

export const deleteInstructor = createAction('[Certification] Delete Instructor', props<{ instructorId: number }>());
export const deleteInstructorSuccess = createAction('[Certification] Delete Instructor Success');

export const getCertificationList = createAction('[Certification] Get Certification List',
  props<{ filter: any }>());
export const getCertificationListSuccess = createAction('[Certification] Get Certification List Success',
  props<{ list: PagingResultsModel<CertificationReportModel> }>());

export const showEditCertification = createAction('[Certification] Show Edit Certification Modal',
  props<{ certification: CertificationReportModel }>());
export const closeEditCertification = createAction('[Certification] Close Edit Certification Modal');

export const saveCertification = createAction('[Certification] Save Certification',
  props<{ certification: CertificationReportModel }>());
export const saveCertificationSuccess = createAction('[Certification] Save Certification Success');
export const saveCertificationFail = createAction('[Certification] Save Certification Fail',
  props<{ err: any }>());

export const updateCertificationForm = createAction('[Certification] Update Certification Form',
  props<{ formValues: any, formErrors: FormError[] }>());

export const deleteCertification = createAction('[Certification] Delete Certification', props<{ certificationId: number }>());
export const deleteCertificationSuccess = createAction('[Certification] Delete Certification Success');

export const getCertificationPdf = createAction('[Certification] Get Certification Pdf',
  props<{ certificationId: number }>());
export const getCertificationPdfSuccess = createAction('[Certification] Get Certification Pdf Success',
  props<{ doc: ArrayBuffer }>());
export const getCertificationPdfFailure = createAction('[Certification] Get Certification Pdf Failure');

export const exportCertificationListToExcel = createAction('[Certification] Export Certification List To Excel',
  props<{ filter: any }>());
export const exportCertificationListToExcelSuccess = createAction('[Certification] Export Certification List To Excel Success',
  props<{ doc: ArrayBuffer }>());
export const exportCertificationListToExcelFailure = createAction('[Certification] Export Certification List To Excel Failure');

export const exportCertificationInstructorListToExcel = createAction('[Certification] Export Certification Instructor List To Excel',
  props<{ filter: any }>());
export const exportCertificationInstructorListToExcelSuccess = createAction('[Certification] Export Certification Instructor List To Excel Success',
  props<{ doc: ArrayBuffer }>());
export const exportCertificationInstructorListToExcelFailure = createAction('[Certification] Export Certification Instructor List To Excel Failure');

export const loadCertificationUploads = createAction('[Certification] Load Uploaded Files',
  props<{ userId?: number }>());
export const loadCertificationUploadsSuccess = createAction('[Certification] Load Uploaded Files Success',
  props<{ uploadedFiles: CertificationTypeUploadModel[] }>());
export const loadCertificationUploadsFailure = createAction('[Certification] Load Uploaded Files Failure');

export const getCeuEventList = createAction('[Certification] Get CEU Event List',
  props<{ filter: any }>());
export const getCeuEventListSuccess = createAction('[Certification] Get CEU Event List Success',
  props<{ list: PagingResultsModel<CeuEventModel> }>());

export const exportCeuEventListToExcel = createAction('[Certification] Export CEU Event List To Excel',
  props<{ filter: any }>());
export const exportCeuEventListToExcelSuccess = createAction('[Certification] Export CEU Event List To Excel Success',
  props<{ doc: ArrayBuffer }>());
export const exportCeuEventListToExcelFailure = createAction('[Certification] Export CEU Event List To Excel Failure');

export const showEditCeuEvent = createAction('[Certification] Show Edit CEU Event Modal',
  props<{ eventId: number }>());
export const closeEditCeuEvent = createAction('[Certification] Close Edit CEU Event Modal');

export const saveCeuEvent = createAction('[Certification] Save CEU Event',
  props<{ ceuEvent: CeuEventModel }>());
export const saveCeuEventSuccess = createAction('[Certification] Save CEU Event Success');
export const saveCeuEventFail = createAction('[Certification] Save CEU Event Fail',
  props<{ err: any }>());

export const updateCeuEventForm = createAction('[Certification] Update CEU Event Form',
  props<{ formValues: any, formErrors: FormError[] }>());

export const getCeuEvent = createAction('[Certification] Get CEU Event',
  props<{ ceuEventId: number }>());
export const getCeuEventSuccess = createAction('[Certification] Get CEU Event Success',
  props<{ event: CeuEventModel }>());
export const getCeuEventFailure = createAction('[Certification] Get CEU Event Failure');

export const getCeuEventFile = createAction('[Certification] Get CEU Event File',
  props<{ uploadId: number }>());
export const getCeuEventFileSuccess = createAction('[Certification] Get CEU Event File Success',
  props<{ doc: ArrayBuffer }>());
export const getCeuEventFileFailure = createAction('[Certification] Get CEU Event File Failure');

export const deleteCeuEvent = createAction('[Certification] Delete CEU Event', props<{ eventId: number }>());
export const deleteCeuEventSuccess = createAction('[Certification] Delete CEU Event Success');

export const generateMissingCeuEventCertifications = createAction('[Certification] Generate Missing CEU Event Certifications',
  props<{ eventId: number }>());
export const generateMissingCeuEventCertificationsSuccess = createAction('[Certification] Generate Missing CEU Event Certifications Success');
export const generateMissingCeuEventCertificationsFail = createAction('[Certification] Generate Missing CEU Event Certifications Fail',
  props<{ err: any }>());
