import { loadProfile } from './../../../../core/store/profile/profile.actions';
import { loadCampuses } from './../../../../core/store/payroll-approval/payroll-approval.actions';
import { downloadImprovementPlanReviewDoc } from './../../../../core/store/improvementPlan/improvementPlan.actions';
import { ImprovementPlanModel, ImprovementPlanReviewModel } from './../../../../models/improvementPlanModel';
import {
  updateCreateCoachingLogForm,
  createCoachingLog, getCoachableUsersList
} from './../../../../core/store/coachingLog/coahingLog.actions';
import { getUsersList } from './../../../../core/store/users/users.actions';
import { SelectItem } from 'primeng/api';
import { UserModel } from 'src/app/models/userModel';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { FormGroup } from '@angular/forms';
import { getAllControlsErrors, markFormGroupTouched, deepClone, getShortDateFormatted, downloadFile } from 'src/app/helpers/utils';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { Router, ActivatedRoute } from '@angular/router';
import { initImprovementPlanForm } from 'src/app/core/store/improvementPlan/improvementPlan.reducer';
import { getImprovementPlan } from 'src/app/core/store/improvementPlan/improvementPlan.actions';
import { ofType, Actions } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { Location } from '@angular/common'

@Component({
  selector: 'app-view-improvementplan',
  templateUrl: './view-improvementplan.component.html',
  styleUrls: ['./view-improvementplan.component.scss']
})
export class ViewImprovementPlanComponent extends BaseComponent implements OnInit {

  improvementPlan: ImprovementPlanModel;
  improvementPlanID: number;
  form: FormGroup;
  canAddCoach: boolean;
  loadingUsersList = false;
  usersList: SelectItem[] = [{ label: 'loading...', value: undefined }];
  allUsersList: SelectItem[] = [{ label: 'loading...', value: undefined }];
  creatingImprovementPlan = false;
  initedForm = false;
  campusesHash;
  currentUserId;

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.improvementPlanState.improvementPlan).subscribe(improvementPlan => {
        if (this.improvementPlanID && !this.initedForm) {
          const improvementPlanClone: ImprovementPlanModel = deepClone(improvementPlan);
          if (improvementPlanClone.nextReviewDate) {
            improvementPlanClone.nextReviewDate = new Date(improvementPlanClone.nextReviewDate)
              .toISOString().substr(0, 10);
          }
          this.subs.push(
            // Check if user is an HR manager
            this.store.select(s => s.accountState.user.permissionIDs).subscribe(permissionIDs => {
              this.canAddCoach = permissionIDs && !!permissionIDs.find(p => p === 6004 || p === 6005 || p === 6006);
            }),
          );
        }
        this.improvementPlan = improvementPlan;
      }),


      this.store.select(s => s.payrollApprovalState.campuses).subscribe(campuses => {
        const campusesList = campuses && campuses.length > 0 ? campuses.map(c => ({ label: c.programName, value: c.id }))
          : [{ label: 'loading...', value: undefined }];

        this.campusesHash = {};
        campuses.forEach(c => {
          this.campusesHash[c.id] = c.programName;
        });

      }),

      this.store.select(s => s.profileState.profile).subscribe(p => {
        if (p) {
          this.currentUserId = p.userId;
        }
      }),

    );

    this.route.params.subscribe(params => {
      if (params.improvementPlanID) {
        this.improvementPlanID = params.improvementPlanID;
        this.store.dispatch(getImprovementPlan({ improvementPlanId: this.improvementPlanID }));
      }
    });
    this.store.dispatch(loadCampuses());
    this.store.dispatch(loadProfile());
  }

  getFormatedDate(date) {
    if (date) {
      return getShortDateFormatted(new Date(date));
    }
  }
  back(): void {
    this.location.back()
  }


  downloadImprovementPlanReview(improvementPlanReview: ImprovementPlanReviewModel) {
    const subDocBuffer = this.store.select(s => s.improvementPlanState.docReviewArrayBuffer).subscribe(docReviewArrayBuffer => {
      if (docReviewArrayBuffer) {
        downloadFile(new Blob([docReviewArrayBuffer], { type: 'application/pdf' }),
          'ImprovementPlanReview' + improvementPlanReview.id + '.pdf');
        subDocBuffer.unsubscribe();
      }
    });

    this.store.dispatch(downloadImprovementPlanReviewDoc({ improvementPlanReviewID: improvementPlanReview.id }));
  }

}

