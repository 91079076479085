import { loadCampuses } from 'src/app/core/store/payroll-approval/payroll-approval.actions';
import { ProspectiveCandidateContactLogModel, ProspectiveCandidateModel, ProspectiveCandidateReferenceModel } from './../../../../models/prospectiveCandidateModel';
import {
  updateProspectiveCandidateForm, saveProspectiveCandidate, addProspectiveCandidateContactLog,
  createProspectiveCandidate, getProspectiveCandidate, clearProspectiveCandidate,
  removeProspectiveCandidateReference, addProspectiveCandidateReference, getProspectiveCandidatesListFail, getProspectiveCandidatesList, getProspectiveCandidates, editProspectiveCandidateContactLog,
  saveProspectiveCandidateMiscDoc,
  deleteProspectiveCandidateMiscDoc
} from './../../../../core/store/prospective-candidates/prospective-candidate.actions';
import { additTimeCard, updateTimeCardForm } from './../../../../core/store/timecard/timecard.actions';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { loadClients } from 'src/app/core/store/timecard/timecard.actions';
import { TimeCardModel } from 'src/app/models/timeCardModel';
import { EmailValidator, FormControl, FormGroup, Validators } from '@angular/forms';
import { initTimeCardEditForm } from 'src/app/core/store/timecard/timecard.reducer';
import { markFormGroupTouched, getAllControlsErrors, updateFormGroupValidity, deepClone } from 'src/app/helpers/utils';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { loadJobTitles } from 'src/app/core/store/profile/profile.actions';
import { initProspectiveCandidateForm } from 'src/app/core/store/prospective-candidates/prospective-candidate.reducer';
import { ActivatedRoute } from '@angular/router';
import {NgbModal, ModalDismissReasons, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-prospective-candidates-addit',
  templateUrl: './prospective-candidates-addit.component.html',
  styleUrls: ['./prospective-candidates-addit.component.scss']
})
export class ProspectiveCandidatesAddItComponent extends BaseComponent implements OnInit {
  @ViewChild('fileMiscInput')
  fileMiscInput: ElementRef;

  modalReference: any;
  modalOptions: NgbModalOptions;
  screeningModalOptions: NgbModalOptions;
  prospectiveCandidate: ProspectiveCandidateModel;
  form: FormGroup;
  referenceForm: FormGroup;
  contactLogForm: FormGroup;
  prospectiveCandidateID: number = 0;
  jobTitlesList = [{ label: 'loading...', value: undefined }];
  fileToUpload: File = null;
  miscDocumentsToUpload: File[] = []; 
  references: ProspectiveCandidateReferenceModel[] = [];
  contactLogs: ProspectiveCandidateContactLogModel[] = [];
  referenceToAdd: ProspectiveCandidateReferenceModel = {} as ProspectiveCandidateReferenceModel;
  logToAdd: ProspectiveCandidateContactLogModel = {} as ProspectiveCandidateContactLogModel;
  openAddReference;
  openAddLog;
  campusesList = [];
  employeeReferral: boolean;
  contactStatus: boolean;
  hiredStatus: boolean;
  today = new Date();
  applicationDate: boolean;
  user = "";
  showPhoneInterview: boolean = false;
  createdBy: string = ""; 
  lastEditedBy: string = ""; 
  lastEditDate: Date;
  createdDate: Date;
  fromJobApplication?: boolean;

  contactLogEdit: string;

  closeResult: string;
  logEdited: boolean = false;

  constructor(
    private store: Store<State>,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private alertService: AlertService,
    ) {
    super();
    this.screeningModalOptions = {
      backdrop:'static',
      backdropClass:'customBackdrop',
      size: 'lg'
    }
  }

  ngOnInit() {
    this.store.dispatch(clearProspectiveCandidate());
    this.route.params.subscribe(params => {
      if (params.prospectiveCandidateID) {
        this.prospectiveCandidateID = params.prospectiveCandidateID;
        this.store.dispatch(getProspectiveCandidate({ prospectiveCandidateID: params.prospectiveCandidateID }));
      } else {
        this.store.dispatch(clearProspectiveCandidate());
        this.prospectiveCandidate = {} as ProspectiveCandidateModel;
        this.initForm();
      }
    });
    this.subs.push(
      this.store.select(s => s.profileState.jobTitles).subscribe(jobTitles => {
        this.jobTitlesList = jobTitles && jobTitles.length > 0 ? jobTitles.filter(x => x.archived == false).map(c => ({ label: c.jobTitle, value: c.jobTitle }))
          : [{ label: 'loading...', value: undefined }];
        if (jobTitles && jobTitles.length > 0) {
          this.jobTitlesList.unshift({ label: '', value: null });
        }
      }),
      this.store.select(s => s.prospectiveCandidateState.prospectiveCandidate).subscribe(prospectiveCandidate => {
        if (prospectiveCandidate) {
          this.prospectiveCandidate = deepClone(prospectiveCandidate);
          this.references = this.prospectiveCandidate.references ? this.prospectiveCandidate.references : [];
          this.contactLogs = this.prospectiveCandidate.contactLogs ? this.prospectiveCandidate.contactLogs : [];
          this.employeeReferral = this.prospectiveCandidate.employeeReferral;
          this.contactStatus = this.prospectiveCandidate.futureContact;
          this.hiredStatus = this.prospectiveCandidate.isHired;
          this.createdBy = this.prospectiveCandidate.createdBy;
          this.lastEditedBy = this.prospectiveCandidate.lastEditedBy;
          this.createdDate = this.prospectiveCandidate.createdDate;
          this.lastEditDate = this.prospectiveCandidate.lastEditDate;
          this.fromJobApplication = this.prospectiveCandidate.fromJobApplication
          if (this.prospectiveCandidate.applicationDate != null) {
            this.applicationDate = true
          }
          if (this.prospectiveCandidate.position == "Behavior Technician" || this.prospectiveCandidate.position == "Registered Behavior Technician"){
            this.showPhoneInterview = true;
          }
          if (!this.form) {
            this.initForm();
          }
        }
      }),
      this.store.select(s => s.accountState.user.programIDs).subscribe(programIDs => {
        if (programIDs) {
          this.subs.push(
            this.store.select(s => s.payrollApprovalState.campuses).subscribe(campuses => {
              let campusesList = campuses && campuses.length > 0 ? campuses.filter(x => programIDs.includes(x.id)).map(c => ({ label: c.programName, value: c.id }))
                : [{ label: 'loading...', value: undefined }];
              this.campusesList = campusesList;
            }),
          );
        }
      }),
      this.store.select(s => s.accountState.user).subscribe(user => {
        if (user) {
          this.user = user.firstName + " " + user.lastName;
        }
      }),
    );
    this.store.dispatch(loadJobTitles());
    this.store.dispatch(loadCampuses());
  }

  initForm() {
    this.form = initProspectiveCandidateForm(this.prospectiveCandidate);
    this.subs.push(
      this.form.valueChanges.subscribe(formValues => {
        const formErrors = getAllControlsErrors(this.form);
        this.store.dispatch(updateProspectiveCandidateForm({ prospectiveCandidate: formValues, formErrors }));
      }));

    this.referenceForm = new FormGroup({
      firstName: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      lastName: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(255)]),
    });

    this.contactLogForm = new FormGroup({
      contactDetails: new FormControl('', [Validators.required, Validators.maxLength(800)]),
    });
  }

  save() {
    updateFormGroupValidity(this.form);
    if (this.form.valid) {
      if (this.prospectiveCandidate.isHired == null) {
        this.prospectiveCandidate.isHired = false;
      }
      if (this.prospectiveCandidate.futureContact == null) {
        this.prospectiveCandidate.futureContact = false;
      }
      if (this.prospectiveCandidate.employeeReferral == null) {
        this.prospectiveCandidate.employeeReferral = false;
      }
      if(this.form.value.interviewDate){
        this.prospectiveCandidate.interviewDateString = this.form.value.interviewDate;
      }
      if (this.prospectiveCandidate.id) {
        this.store.dispatch(saveProspectiveCandidate({ prospectiveCandidate: this.prospectiveCandidate, file: this.fileToUpload }));
      } else {
        this.store.dispatch(createProspectiveCandidate({ prospectiveCandidate: this.prospectiveCandidate, file: this.fileToUpload }));
      }
    } else {
      markFormGroupTouched(this.form);
    }
  }

  open(content, id) {
    var cl = this.contactLogs.find(x => x.id == id);
    this.contactLogEdit = cl.contactDetails;
    this.modalService.open(content, this.modalOptions).result.then((result) => {
      this.closeResult = `${result}`;
      if(this.closeResult == "Edit"){
        this.editContactLog(cl);
      }
    }, (reason) => {
    });
  }
  editContactLog(cl) {
    console.log(cl.id);
    if (this.contactLogEdit == cl.contactDetails){
      this.alertService.info("Input was not changed");
      return;
    }
    const newLog = {
      id: cl.id,
      candidateID: cl.candidateID,
      contactDate: cl.contactDate,
      contactDetails: this.contactLogEdit,
      usersName: cl.usersName,
      canEdit: cl.canEdit
    }
    this.store.dispatch(editProspectiveCandidateContactLog({ log: newLog}));
  }

  contactLogEditChange(){
    console.log(this.contactLogEdit);
    this.logEdited = true;
  }

  changedFile(fileInput) {
    if (fileInput.files.length > 0) {
      this.fileToUpload = fileInput.files[0];
    } else {
      this.fileToUpload = null;
    }
  }

  changedMiscFile(fileMiscInput) {
    const clonedFiles = [ ...fileMiscInput.files ];
    if (fileMiscInput.files.length > 0) {
      this.miscDocumentsToUpload = clonedFiles;
    } else {
      this.miscDocumentsToUpload = null;
    }
  }

  deleteMiscDocuments(documentID){
    this.store.dispatch(deleteProspectiveCandidateMiscDoc({ prospectiveCandidateID: this.prospectiveCandidate.id, documentID: documentID }));
  }

  saveMiscDocuments(){
    if(this.miscDocumentsToUpload != null && this.miscDocumentsToUpload.length)
      this.store.dispatch(saveProspectiveCandidateMiscDoc({ prospectiveCandidateID: this.prospectiveCandidate.id, files: this.miscDocumentsToUpload }));
    else
      this.alertService.error("There are no documents selected to upload");

    this.miscDocumentsToUpload = [];
    this.fileMiscInput.nativeElement.value = "";
  }

  getPreviewUrl() {
    if (this.prospectiveCandidate.fileType.indexOf('image') >= 0) {
      return this.prospectiveCandidate.resumeURL;
    }

    if (this.prospectiveCandidate.fileType.indexOf('pdf') >= 0) {
      return '/assets/img/pdf.png';
    }

    if (this.prospectiveCandidate.fileType.indexOf('application/vnd.openxmlformats-officedocument.wordprocessingml.document') >= 0
      || this.prospectiveCandidate.fileType.indexOf('doc') >= 0
      || this.prospectiveCandidate.fileType.indexOf('msword') >= 0) {
      return '/assets/img/doc.png';
    }
  }

  getMiscDocPreviewUrl(doc){
    if (doc.fileType.indexOf('image') >= 0) {
      return this.prospectiveCandidate.resumeURL;
    }
    if (doc.fileType.indexOf('pdf') >= 0) {
      return '/assets/img/pdf.png';
    }
    if (doc.fileType.indexOf('application/vnd.openxmlformats-officedocument.wordprocessingml.document') >= 0
      || doc.fileType.indexOf('doc') >= 0
      || doc.fileType.indexOf('msword') >= 0) {
      return '/assets/img/doc.png';
    }
  }

  removeReference(reference: ProspectiveCandidateReferenceModel) {
    this.store.dispatch(removeProspectiveCandidateReference({ reference }));
  }


  addReference() {
    updateFormGroupValidity(this.referenceForm);
    if (this.referenceForm.valid) {
      this.store.dispatch(addProspectiveCandidateReference({ reference: this.referenceToAdd }));
      this.referenceToAdd = {} as ProspectiveCandidateReferenceModel;
      this.referenceForm.reset();
    } else {
      markFormGroupTouched(this.referenceForm);
    }
  }

  addContactLog() {
    if (this.contactLogForm.valid) {
      const date = new Date();
      const minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      const time = date.getHours() > 12 ? "PM" : "AM";
      let dateTime = (date.getMonth()+1)+'-'+ date.getDate()+'-'+ date.getFullYear() + ' ' + (date.getHours() + 24) % 12 + ':' + minutes + " " + time;
      this.logToAdd.usersName = this.user;
      this.logToAdd.contactDate = dateTime;
      this.logToAdd.candidateID = this.prospectiveCandidateID;
      this.logToAdd.canEdit = true;
      this.store.dispatch(addProspectiveCandidateContactLog({ log: this.logToAdd}));
      this.logToAdd = {} as ProspectiveCandidateContactLogModel;
      this.contactLogForm.reset();
    } else {
      markFormGroupTouched(this.contactLogForm);
    }
  }

  changeReferredStatus() {
    this.employeeReferral = !this.employeeReferral;
  }
  changeContactStatus() {
    this.contactStatus = !this.contactStatus;
  }
  changeHiredStatus() {
    this.hiredStatus = !this.hiredStatus;
  }

  markSeen(reference: ProspectiveCandidateReferenceModel) {
    reference.answerSeen = new Date();
  }

  openPhoneScreening(content, app) {
    this.modalReference = this.modalService.open(content, this.screeningModalOptions)
    this.modalReference.result.then((result) => {
    }, (reason) => {
    });
  }
}
