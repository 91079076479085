import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ManualEntryReasonModel, TimeCardModel } from 'src/app/models/timeCardModel';
import { PayPeriodModel } from 'src/app/models/payPeriodModel';
import { ClientSimpleModel } from 'src/app/models/clientSimpleModel';
import { PagingResultsModel } from '../../models/pagingResultsModel';
import { deepClone } from '../../helpers/utils';
import { SelectItem } from 'primeng/api';


@Injectable()
export class TimeCardService {

  constructor(private http: HttpClient) {
  }

  loadTimeCardsOnPeriod(periodID: number): Observable<TimeCardModel[]> {
    return this.http.get<TimeCardModel[]>(`${environment.apiServer.webApiURL}/TimeCard/TimeCardsOnPeriod/${periodID}?q=${Date.now()}`);
  }

  loadClients(): Observable<ClientSimpleModel[]> {
    return this.http.get<ClientSimpleModel[]>(`${environment.apiServer.webApiURL}/TimeCard/ClientsList`);
  }

  loadManualEntryReasons(): Observable<ManualEntryReasonModel[]> {
    return this.http.get<ManualEntryReasonModel[]>(`${environment.apiServer.webApiURL}/TimeCard/ManualEntryReasons`);
  }

  clockInOut(payableTypeId: number): Observable<TimeCardModel> {
    return this.http.post<TimeCardModel>(`${environment.apiServer.webApiURL}/TimeCard/ClockInOut/${payableTypeId}`, {});
  }

  getIsClockedIn(): Observable<boolean> {
    return this.http.get<boolean>(`${environment.apiServer.webApiURL}/TimeCard/IsClockedIn`);
  }

  addTimeCard(timeCard: TimeCardModel): Observable<TimeCardModel> {
    return this.http.post<TimeCardModel>(`${environment.apiServer.webApiURL}/TimeCard`, timeCard);
  }

  editTimeCard(timeCard: TimeCardModel): Observable<TimeCardModel> {
    return this.http.put<TimeCardModel>(`${environment.apiServer.webApiURL}/TimeCard/${timeCard.id}`, timeCard);
  }

  checkIP(): Observable<boolean> {
    return this.http.get<boolean>(`${environment.apiServer.webApiURL}/TimeCard/CheckIP`);
  }

  fixTimeCard(fixReason: string, timecardID: number): Observable<TimeCardModel> {
    return this.http.put<TimeCardModel>(`${environment.apiServer.webApiURL}/TimeCard/Fix/${timecardID}`, JSON.parse('"' + fixReason + '"'));
  }

  deleteTimeCard(timeCardID: number): Observable<any> {
    return this.http.delete<any>(`${environment.apiServer.webApiURL}/TimeCard/${timeCardID}`);
  }

  getManualEntriesReport(filter: any): Observable<PagingResultsModel<TimeCardModel>> {
    if (!filter) {
      filter = {};
      filter.filters = {};
    }
    filter = deepClone(filter);

    return this.http.post<PagingResultsModel<TimeCardModel>>(`${environment.apiServer.webApiURL}/TimeCard/ManualEntriesReport`, filter);
  }

  exportManualEntriesReportToExcel(filter: any): Observable<ArrayBuffer> {
    if (!filter) {
      filter = {};
      filter.filters = {};
    }
    filter = deepClone(filter);

    return this.http.post<ArrayBuffer>(`${environment.apiServer.webApiURL}/TimeCard/ExportManualEntriesReportToExcel`, filter, { responseType: 'arraybuffer' as 'json' });
  }

  getOptionalPayableTypes(): Observable<SelectItem[]> {
    return this.http.get<SelectItem[]>(`${environment.apiServer.webApiURL}/TimeCard/OptionalPayableTypes`);
  }

}
