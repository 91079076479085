import { ImprovementPlanModel, ImprovementPlanReviewModel } from './../../models/improvementPlanModel';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable()
export class ImprovementPlanService {

  constructor(private http: HttpClient) {
  }

  getImprovementPlan(improvementPlanID: number): Observable<ImprovementPlanModel> {
    return this.http.get<ImprovementPlanModel>(`${environment.apiServer.webApiURL}/ImprovementPlan/${improvementPlanID}`);
  }

  getImprovementPlanReview(improvementPlanReviewID: number): Observable<ImprovementPlanReviewModel> {
    return this.http.get<ImprovementPlanReviewModel>(
      `${environment.apiServer.webApiURL}/ImprovementPlan/review/${improvementPlanReviewID}`);
  }

  createImprovementPlan(improvementPlan: ImprovementPlanModel) {
    return this.http.post<ImprovementPlanModel>(`${environment.apiServer.webApiURL}/ImprovementPlan`, improvementPlan);
  }

  createImprovementPlanReview(improvementPlanReview: ImprovementPlanReviewModel) {
    return this.http.post<ImprovementPlanReviewModel>(`${environment.apiServer.webApiURL}/ImprovementPlan/ImprovementPlanReview`,
      improvementPlanReview);
  }

  downloadImprovementPlanDoc(improvementPlanID: number): Observable<ArrayBuffer> {
    return this.http.get<ArrayBuffer>(`${environment.apiServer.webApiURL}/ImprovementPlan/Document/${improvementPlanID}`,
      { responseType: 'arraybuffer' as 'json' });
  }
  downloadImprovementPlanReviewDoc(improvementPlanReviewID: number): Observable<ArrayBuffer> {
    return this.http.get<ArrayBuffer>(`${environment.apiServer.webApiURL}/ImprovementPlan/ReviewDocument/${improvementPlanReviewID}`,
      { responseType: 'arraybuffer' as 'json' });
  }

  signImprovementPlan(improvementPlanID: number, signedFields: string[]): Observable<ArrayBuffer> {
    return this.http.post<ArrayBuffer>(`${environment.apiServer.webApiURL}/ImprovementPlan/SignImprovementPlan/${improvementPlanID}`,
      signedFields,
      { responseType: 'arraybuffer' as 'json' });
  }

  signImprovementPlanReview(improvementPlanReviewID: number, signedFields: string[]): Observable<ArrayBuffer> {
    return this.http.post<ArrayBuffer>
      (`${environment.apiServer.webApiURL}/ImprovementPlan/SignImprovementPlanReview/${improvementPlanReviewID}`,
        signedFields,
        { responseType: 'arraybuffer' as 'json' });
  }
}
