import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'src/app/core/store';
import { loadProfile } from 'src/app/core/store/profile/profile.actions';
import { UserProfileModel } from 'src/app/models/userProfileModel';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { loadManageOnboardingUserInfo } from 'src/app/core/store/onboarding/onboarding.actions';

@Component({
  selector: 'app-uploaddocs-approval',
  templateUrl: './uploaddocs-approval.component.html',
  styleUrls: ['./uploaddocs-approval.component.scss']
})
export class UploadDocsApprovalComponent extends BaseComponent implements OnInit {

  profile: UserProfileModel;
  managedUserId;

  constructor(
    private store: Store<State>,
    private route: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit() {
    if (this.route.snapshot.params.userid) {
      this.managedUserId = this.route.snapshot.params.userid;
    }
    this.store.select(s => s.onboardingState.manageOnboardingUserInfo).subscribe(manageOnboardingUserInfo => {
      if (manageOnboardingUserInfo) {
        this.profile = manageOnboardingUserInfo.user;
      }
    }),

    this.store.dispatch(loadManageOnboardingUserInfo({ userId: this.managedUserId }));
  }

}
