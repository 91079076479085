<div class="card mt-2">
  <div class="card-body">
    <div class="float-right">
      <button type="button" routerLink="/intern/list-manage" class="btn btn-secondary mr-2">Applications</button>
      <button type="button" routerLink="/intern/actionType/list" class="btn btn-secondary mr-2">Action Item Types</button>
      <button type="button" routerLink="/intern/actionItemTemplate/list" class="btn btn-secondary mr-2">Action Item Templates</button>
    </div>
    <h2 class="mb-3">Internship Substatuses</h2>
    <div class="mb-3">
      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Primary Status:</h5>
        </div>
        <div class="col-10">
          <i *ngIf="!primaryStatusList" class="material-icons rotate text-muted">loop</i>
          <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="primaryStatusList" [(ngModel)]="primaryStatusId" (ngModelChange)="reloadFilter(this.filter)" [ngModelOptions]="{standalone: true}"></p-dropdown>
        </div>
      </div>
    </div>

    <i [hidden]="!loadingList && !loadingExcel && !savingItem" class="material-icons rotate text-muted">loop</i>
    <button (click)="editSubstatus()" class="btn btn-secondary ml-2 mb-2">New Substatus</button>
    <p-table [value]="list?.result" [responsive]="true" [paginator]="true" [rows]="20"
             [totalRecords]="list?.totalRecords" [lazy]="true" (onLazyLoad)="reloadFilter($event)">
      <ng-template pTemplate="header">
        <tr>
          <th width="175px"></th>
          <th [pSortableColumn]="'Substatus'">Substatus<p-sortIcon [field]="'Substatus'"></p-sortIcon></th>
          <th [pSortableColumn]="'PrimaryStatus'">Primary Status<p-sortIcon [field]="'PrimaryStatus'"></p-sortIcon></th>
          <th [pSortableColumn]="'Archived'">Archived<p-sortIcon [field]="'Archived'"></p-sortIcon></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-list>
        <tr>
          <td>
            <button class="btn btn-secondary mr-2" (click)="editSubstatus(list)" title="Edit Substatus" [disabled]="savingItem">
              <i class="fas fa-pencil"></i>
            </button>
            <button class="btn btn-secondary mr-2" (click)="deleteSubstatus(list)" title="Delete Substatus" [disabled]="savingItem">
              <i class="fas fa-trash-alt"></i>
            </button>
          </td>
          <td>
            <span class="ui-column-title">Substatus</span>
            {{list.substatus}}
          </td>
          <td>
            <span class="ui-column-title">Primary Status</span>
            {{list.primaryStatus}}
          </td>
          <td>
            <span class="ui-column-title">Archived</span>
            {{(list.archived == true ? "Yes" : "No")}}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<app-edit-intern-substatus></app-edit-intern-substatus>
