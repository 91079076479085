import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { Actions, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { SurveyModel, SurveyReportModel } from '../../../../models/surveyModel';
import { loadMyUnansweredSurvey, loadSurveyReport } from '../../../../core/store/survey/survey.actions';

@Component({
  selector: 'app-survey-report',
  templateUrl: './survey-report.component.html',
  styleUrls: ['./survey-report.component.scss']
})
export class SurveyReportComponent extends BaseComponent implements OnInit {

  surveyReport: SurveyReportModel[];
  loadingSurveyReport = false;
  dateRange;
  startingDateRange = [
    new Date(new Date().setMonth(new Date().getMonth() - 1)),
    new Date(),
  ];
  minTier: number;
  midTier: number;
  maxTier: number;

  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store<State>) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.surveyState.surveyReport).subscribe(surveyReport => {
        this.surveyReport = surveyReport;
      }),
      this.store.select(s => s.surveyState.loadingSurveyReport).subscribe(loadingSurveyReport => {
        this.loadingSurveyReport = loadingSurveyReport;
      }),
    );
    this.dateRange = this.startingDateRange;
    this.store.dispatch(loadSurveyReport({ filter: { dateRange: this.startingDateRange } }));

  }

  changedFilter() {
    if (this.dateRange && this.dateRange[0] && this.dateRange[1]) {
      this.store.dispatch(loadSurveyReport({ filter: { dateRange: this.dateRange } }));
    }
  }


  getTier(average: number) {
    if (this.minTier && this.midTier && this.maxTier) {
      if (average < this.minTier) {
        return '-';
      } else if (average < this.midTier) {
        return 'Min';
      } else if (average < this.maxTier) {
        return 'Mid';
      } else {
        return 'Max';
      }
    }
    return '-';
  }

  loadDetail(survey: SurveyReportModel) {
    this.router.navigate(['/survey/report/detail/' + survey.surveyTypeID + '/' + survey.mentorUserID]);
  }
}

