<form class="card mt-2">
  <div class="card-body">
    <div *ngIf="canEdit" class="float-right">
      <button type="button" routerLink="/employee/fullTimeStatus/queue" class="btn btn-secondary">Queue</button>
    </div>
    <h2 class="mb-3">Full Time Status Checks</h2>
    <div class="mb-3">

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Campus:</h5>
        </div>
        <div class="col-10">
          <i *ngIf="!campusList" class="material-icons rotate text-muted">loop</i>
          <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="campusList" [(ngModel)]="campusId" (ngModelChange)="reloadChecks(this.filter)" [ngModelOptions]="{standalone: true}"></p-dropdown>
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Employee:</h5>
        </div>
        <div class="col-10">
          <div class="ml-2 w-100 mb-2">
            <p-autoComplete [style]="{'width': '100%'}" [inputStyle]="{'width': '100%'}" [(ngModel)]="prefixText"
                            [suggestions]="userEmployeeList" (completeMethod)="getStaffList($event)" field="label"
                            [ngModelOptions]="{standalone: true}" [minLength]="1" [autoHighlight]="true"
                            (onSelect)="reloadListAfterStaffChage($event)" [forceSelection]="true"
                            (onClear)="reloadListAfterClear($event)"></p-autoComplete>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Include Inactive Users:</h5>
        </div>
        <div class="col-10" style="padding-left: 25px;">
          <p-checkbox [(ngModel)]="includeInactive" (ngModelChange)="reloadChecks(this.filter)" [binary]="true" [ngModelOptions]="{standalone: true}"></p-checkbox>
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Check Date:</h5>
        </div>
        <div class="col-10">
          <i *ngIf="!dateRangeList" class="material-icons rotate text-muted">loop</i>
          <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="dateRangeList" [(ngModel)]="dateRange" (ngModelChange)="changedDateRange()" [ngModelOptions]="{standalone: true}"></p-dropdown>
        </div>
      </div>

      <div class="row" *ngIf="isCustomDateRange">
        <div class="col-2 d-flex align-items-center">
          <h5>Start Date:</h5>
        </div>
        <div class="col-10">
          <input type="date" class="form-control ml-2 w-100 mb-2" [(ngModel)]="startDate" (blur)="reloadChecks(this.filter)" [ngModelOptions]="{standalone: true}" />
        </div>
        <div class="col-2 d-flex align-items-center">
          <h5>End Date:</h5>
        </div>
        <div class="col-10">
          <input type="date" class="form-control ml-2 w-100 mb-2" [(ngModel)]="endDate" (blur)="reloadChecks(this.filter)" [ngModelOptions]="{standalone: true}" />
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Hours Per Week:</h5>
        </div>
        <div class="col-10">
          <i *ngIf="!hourRangeList" class="material-icons rotate text-muted">loop</i>
          <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="hourRangeList" [(ngModel)]="hourRange" (ngModelChange)="changedHourRange()" [ngModelOptions]="{standalone: true}"></p-dropdown>
        </div>
      </div>

      <div class="row" *ngIf="isCustomHourRange">
        <div class="col-2 d-flex align-items-center">
          <h5>Max Hour Per Week:</h5>
        </div>
        <div class="col-10">
          <input type="number" step="1" min="0" class="form-control ml-2 w-100 mb-2" [(ngModel)]="minHours" (blur)="reloadChecks(this.filter)" [ngModelOptions]="{standalone: true}" />
        </div>
        <div class="col-2 d-flex align-items-center">
          <h5>Min Hour Per Week:</h5>
        </div>
        <div class="col-10">
          <input type="number" step="1" min="0" class="form-control ml-2 w-100 mb-2" [(ngModel)]="maxHours" (blur)="reloadChecks(this.filter)" [ngModelOptions]="{standalone: true}" />
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5 title="As of the check date">Full Time Status:</h5>
        </div>
        <div class="col-10">
          <i *ngIf="!fullTimeStatusOptions" class="material-icons rotate text-muted">loop</i>
          <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="fullTimeStatusOptions" [(ngModel)]="fullTimeStatus" optionValue="value" (ngModelChange)="reloadChecks(this.filter)" [ngModelOptions]="{standalone: true}"></p-dropdown>
        </div>
      </div>

    </div>

    <i [hidden]="!loadingStatusList && !loadingStatusExcel" class="material-icons rotate text-muted">loop</i>
    <button type="button" title="Export all pages to excel" class="btn btn-success mr-2 mb-2" (click)="exportToExcel()"><i class="far fa-file-excel"></i></button>
    <p-table [value]="checks?.result" [responsive]="true" [paginator]="true" [rows]="20"
             [totalRecords]="checks?.totalRecords" [lazy]="true" (onLazyLoad)="reloadChecks($event)">
      <ng-template pTemplate="header">
        <tr>
          <th [pSortableColumn]="'EmployeeName'">Employee <p-sortIcon [field]="'EmployeeName'"></p-sortIcon></th>
          <th [pSortableColumn]="'PrimaryProgramName'">Primary Campus <p-sortIcon [field]="'PrimaryProgramName'"></p-sortIcon></th>
          <th [pSortableColumn]="'SeparationDate'">Separation Date <p-sortIcon [field]="'SeparationDate'"></p-sortIcon></th>
          <th [pSortableColumn]="'EndDate'">Date Range <p-sortIcon [field]="'EndDate'"></p-sortIcon></th>
          <th [pSortableColumn]="'HoursPerWeek'">Hours Per Week <p-sortIcon [field]="'HoursPerWeek'"></p-sortIcon></th>
          <th [pSortableColumn]="'ExcludedHours'">Total Excluded Hours <p-sortIcon [field]="'ExcludedHours'"></p-sortIcon></th>
          <th [pSortableColumn]="'IsCurrentlyFullTime'">Full Time Status <p-sortIcon [field]="'IsCurrentlyFullTime'"></p-sortIcon></th>
          <th [pSortableColumn]="'VerifiedDate'">Verified Date <p-sortIcon [field]="'VerifiedDate'"></p-sortIcon></th>
          <th [pSortableColumn]="'VerifiedBy'">Verified By <p-sortIcon [field]="'VerifiedBy'"></p-sortIcon></th>
          <th [pSortableColumn]="'LastModifiedDate'">Last Modified Date <p-sortIcon [field]="'LastModifiedDate'"></p-sortIcon></th>
          <th [pSortableColumn]="'ModifiedReason'">Modification Reason <p-sortIcon [field]="'ModifiedReason'"></p-sortIcon></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-check>
        <tr>
          <td>
            <span class="ui-column-title">Employee</span>
            {{check.employeeName}}
          </td>
          <td>
            <span class="ui-column-title">Primary Campus</span>
            {{check.primaryProgramName}}
          </td>
          <td>
            <span class="ui-column-title">Separation Date</span>
            {{(check.separationDate !== null) ? (check.separationDate | date: 'MM/dd/yyyy') : ""}}
          </td>
          <td>
            <span class="ui-column-title">Date Range</span>
            {{check.startDate | date: 'MM/dd/yyyy'}} - {{check.endDate | date: 'MM/dd/yyyy'}}
          </td>
          <td>
            <span class="ui-column-title">Hours Per Week</span>
            {{check.hoursPerWeek}}
          </td>
          <td>
            <span class="ui-column-title">Total Excluded Hours</span>
            {{check.excludedHours > 0 ? check.excludedHours : "-"}}
          </td>
          <td>
            <span class="ui-column-title">Full Time Status</span>
            {{(check.isCurrentlyFullTime) ? "FULL" : "PART"}} {{(check.isExempt) ? "(EXEMPT)" : ""}}
          </td>
          <td>
            <span class="ui-column-title">Verified Date</span>
            {{(check.verifiedDate !== null) ? (check.verifiedDate | date: 'MM/dd/yyyy @ hh:mm:ss a') : ""}}
          </td>
          <td>
            <span class="ui-column-title">Verified By</span>
            {{(check.verifiedDate !== null) ? (check.verifiedAutomatically ? "System" : check.verifiedByFirstName + " " + check.verifiedByLastName) : ""}}
          </td>
          <td>
            <span class="ui-column-title">Last Modified Date</span>
            {{check.lastModifiedDate | date: 'MM/dd/yyyy @ hh:mm:ss a'}}
          </td>
          <td>
            <span class="ui-column-title">Modification Reason</span>
            {{check.modifiedReason}}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</form>
