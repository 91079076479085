import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../../shared/shared.module';

import { LoginComponent } from './login/login.component';

import { AuthRoutingModule } from './auth-routing.module';

const pages = [
  LoginComponent
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    AuthRoutingModule
  ],
  declarations: [
    ...pages
  ],
  providers: []
})
export class AuthModule {}
