import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { Actions, ofType } from '@ngrx/effects';
import { State } from '../../../../core/store';
import { Store } from '@ngrx/store';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { loadEmployeeUsers, loadViewableCampuses } from '../../../../core/store/employee/employee.action';
import { SelfCareCompletionReportModel } from '../../../../models/selfcareModels';
import { SelectItem } from 'primeng/api';
import { deepClone, downloadFile } from '../../../../helpers/utils';
import { exportSelfCareCompletionListToExcel, exportSelfCareCompletionListToExcelSuccess, getSelfCareCompletionList } from '../../../../core/store/selfcare/selfcare.actions';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-list-selfcare-complete',
  templateUrl: './list-selfcare-complete.component.html',
  styleUrls: ['./list-selfcare-complete.component.scss']
})
export class ListSelfcareCompleteComponent extends BaseComponent implements OnInit {
  list: PagingResultsModel<SelfCareCompletionReportModel>;
  filter: any;

  loadingList: boolean = true;
  loadingExcel: boolean = false;

  campusList: SelectItem[];
  campusId: number = 0;

  prefixText: string;
  userEmployeeList: SelectItem[];
  userId: number = 0;

  statusOptions: SelectItem[];
  status: string = null;

  showOnlyActive: boolean = true;
  canSeeSelfcare;

  constructor(
    private actions$: Actions,
    private store: Store<State>
  ) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.selfcareState.loadingCompletionList).subscribe(loading => {
        this.loadingList = loading;
      }),
      this.store.select(s => s.selfcareState.loadingCompletionExcel).subscribe(loading => {
        this.loadingExcel = loading;
      }),
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.canSeeSelfcare = permissionIDs && !!permissionIDs.find(p => p === 3083); //View Self Care Oversight
      }),
      this.store.select(s => s.employeeState.campuses).subscribe(campuses => {
        this.campusList = campuses && campuses.length > 0 ? campuses
          : [{ label: 'loading...', value: undefined }];
      }),
      this.store.select(s => s.employeeState.employeeUsers).subscribe(users => {
        this.userEmployeeList = users;
      }),
      this.store.select(s => s.selfcareState.completionList).subscribe(report => {
        this.list = report;
      }),
      this.actions$.pipe(
        ofType(exportSelfCareCompletionListToExcelSuccess),
        tap(action => {
          downloadFile(action.doc, 'SelfCareCompletionReport.xlsx');
        })
      ).subscribe()
    );

    this.statusOptions = [
      { label: 'All', value: null },
      { label: 'Complete', value: 'COMPLETE' },
      { label: 'Incomplete', value: 'INCOMPLETE' }
    ];
    this.store.dispatch(loadViewableCampuses());
  }


  reloadFilter(event) {
    this.filter = event;
    this.reloadList();
  }

  reloadList() {
    this.setFilter();

    this.store.dispatch(getSelfCareCompletionList({
      filter: this.filter
    }));
  }

  getStaffList(event) {
    this.store.dispatch(loadEmployeeUsers({
      prefixText: event.query,
      programId: this.campusId,
      payrollClassificationId: null,
      showTerminated: !this.showOnlyActive,
      showInactive: !this.showOnlyActive
    }));
  }

  reloadListAfterStaffChage(selectedUser) {
    if (typeof selectedUser.value !== 'number') {
      return;
    }

    this.userId = selectedUser.value;
    this.reloadList();
  }

  reloadListAfterClear(event) {
    if (event.data !== null) {
      return;
    }

    this.userId = 0;
    this.reloadList();
  }

  setFilter() {
    this.filter = deepClone(this.filter);
    this.filter.filters.userId = { Name: 'userId', Value: { value: this.userId.toString() } };
    this.filter.filters.programId = { Name: 'programId', Value: { value: this.campusId.toString() } };
    this.filter.filters.status = { Name: 'status', Value: { value: this.status } };
    this.filter.filters.showOnlyActive = { Name: 'showOnlyActive', Value: { value: this.showOnlyActive } };
  }

  exportToExcel() {
    if (this.loadingList || this.loadingExcel) {
      return;
    }
    this.setFilter();
    this.store.dispatch(exportSelfCareCompletionListToExcel({
      filter: this.filter
    }));
  }


  getTerminationString(list) {
    if (list.terminationDate != null) {
      return "(Separated)";
    }

    if (list.inactive) {
      return "(Inactive)"
    }

    return "";
  }
}
