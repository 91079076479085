import { CoachingLogWithUsersModel } from './../../../models/coachingLogWithUsersModel';
import { PagingResultsModel } from './../../../models/pagingResultsModel';
import { UserModel } from 'src/app/models/userModel';
import { OfferLetterModel, OfferLetterWithUserModel } from './../../../models/offerLetterModel';
import { CoachingLogCategoryModel } from '../../../models/coachingLogCategoryModel';
import { createReducer, on, Action } from '@ngrx/store';
import { FormGroup, FormControl, Validators, FormArray, ValidatorFn, FormBuilder } from '@angular/forms';
import { FormError } from 'src/app/models/utilModels';
import {
  createCoachingLog, createCoachingLogSuccess, createCoachingLogFail,
  getMyGivenCoachingLog, getMyGivenCoachingLogSuccess, getMyCoahingLog,
  getMyCoahingLogSuccess, updateCreateCoachingLogForm,
  getCoachableUsersList, getCoachableUsersListSuccess,
  getCategoriesByLevelList, getCategoriesByLevelListSuccess, getUserCoachingLogsList,
  getUserCoachingLogsListSuccess,
  updateCoachingLog, updateCoachingLogSuccess, updateCoachingLogFail,
  refreshUpdateCoachingLog, closeUpdateCoachingLog
} from './coahingLog.actions';
import { HasSelectedCheckboxesOrOther, MinSelectedCheckboxes, ValidatorRequiredIfBool } from '../../../helpers/validators';

export interface CoachingLogState {
  coachingLogWithUsersModel: CoachingLogWithUsersModel;
  creatingCoachingLog: boolean;
  formErrors: FormError[];
  loadingGivenCoachingLogs: boolean;
  givenCoachingLogsList: PagingResultsModel<CoachingLogWithUsersModel>;
  loadingGivenCoachingLogsList: boolean;
  loadingMyCoachingLogs: boolean;
  myCoachingLogsList: PagingResultsModel<CoachingLogWithUsersModel>;
  loadingMyCoachingLogsList: boolean;
  coachableUsersList: UserModel[];
  loadingCoachableUsersList: boolean;
  categoriesList: CoachingLogCategoryModel[];
  loadingCategoriesList: boolean;
  userCoachingLogs: CoachingLogWithUsersModel[],
  loadingUserCoachingLogs: boolean,
  updateCoachingLog: CoachingLogWithUsersModel
}

const initialState: CoachingLogState = {
  coachingLogWithUsersModel: null,
  creatingCoachingLog: false,
  formErrors: [],
  loadingGivenCoachingLogs: false,
  givenCoachingLogsList: null,
  loadingGivenCoachingLogsList: false,
  loadingMyCoachingLogs: false,
  myCoachingLogsList: null,
  loadingMyCoachingLogsList: false,
  coachableUsersList: null,
  loadingCoachableUsersList: false,
  categoriesList: null,
  loadingCategoriesList: false,
  userCoachingLogs: null,
  loadingUserCoachingLogs: false,
  updateCoachingLog: null
};

export const coachingLogReducer = createReducer(initialState,
  on(updateCreateCoachingLogForm, (state, action) => {
    const newCoachingLog = { ...state.coachingLogWithUsersModel, ...action.coachingLog };
    const newState = { ...state, ...{ coachingLogWithUsersModel: newCoachingLog, formErrors: action.formErrors } };
    return newState;
  }),

  on(createCoachingLog, (state, action) => {
    return {
      ...state,
      creatingCoachingLog: true
    };
  }),
  on(createCoachingLogSuccess, (state, action) => {
    return {
      ...state,
      coachingLogWithUsersModel: action.coachingLogWithUsersModel,
      creatingCoachingLog: false
    };
  }),
  on(createCoachingLogFail, (state, action) => {
    return {
      ...state,
      creatingCoachingLog: false
    };
  }),
  on(updateCoachingLog, (state, action) => {
    return {
      ...state
    };
  }),
  on(updateCoachingLogSuccess, (state, action) => {
    var result = {
      ...state,
      coachingLogWithUsersModel: action.coachingLogWithUsersModel
    };
    return result;
  }),
  on(createCoachingLogFail, (state, action) => {
    return {
      ...state
    };
  }),
  on(getMyGivenCoachingLog, (state, action) => {
    return {
      ...state,
      loadingGivenCoachingLogsList: true
    };
  }),
  on(getMyGivenCoachingLogSuccess, (state, action) => {
    return {
      ...state,
      givenCoachingLogsList: action.coachingLogList,
      loadingGivenCoachingLogsList: false
    };
  }),

  on(getMyCoahingLog, (state, action) => {
    return {
      ...state,
      loadingGivenCoachingLogsList: true
    };
  }),
  on(getMyCoahingLogSuccess, (state, action) => {
    return {
      ...state,
      myCoachingLogsList: action.coachingLogList,
      loadingMyCoachingLogsList: false
    };
  }),
  on(getCoachableUsersList, (state, action) => {
    return {
      ...state,
      loadingCoachableUsersList: true
    };
  }),
  on(getCoachableUsersListSuccess, (state, action) => {
    return {
      ...state,
      coachableUsersList: action.usersList,
      loadingCoachableUsersList: false
    };
  }),
  on(getCategoriesByLevelList, (state, action) => {
    return {
      ...state,
      loadingCategoriesList: true
    };
  }),
  on(getCategoriesByLevelListSuccess, (state, action) => {
    return {
      ...state,
      categoriesList: action.categoriesList,
      loadingCategoriesList: false
    };
  }),
  on(getUserCoachingLogsList, (state) => {
    return {
      ...state,
      loadingUserCoachingLogs: true
    };
  }),
  on(getUserCoachingLogsListSuccess, (state, action) => {
    return {
      ...state,
      userCoachingLogs: action.userCoachingLogs,
      loadingUserCoachingLogs: false
    };
  }),
  on(refreshUpdateCoachingLog, (state, action) => {
    var result = {
      ...state,
      updateCoachingLog: action.updateCoachingLog,
    };
    return result;
  }),
  on(closeUpdateCoachingLog, (state, action) => {
    return {
      ...state,
      updateCoachingLog: null
    };
  })
);

export function coachingLogReducerFunc(state: CoachingLogState | undefined, action: Action) {
  return coachingLogReducer(state, action);
}

export function initCreateCoachingLogForm(formBuilder: FormBuilder, coachingLogWithUsersModel: CoachingLogWithUsersModel) {
  const form = formBuilder.group({
    coachingLog: formBuilder.group({
      userID: new FormControl(coachingLogWithUsersModel.userID, [Validators.required]),
      previouslyAddressedCoachingLogID: new FormControl(coachingLogWithUsersModel.previouslyAddressedCoachingLogID),
      level: new FormControl(coachingLogWithUsersModel.level, [Validators.required]),
      description: new FormControl(coachingLogWithUsersModel.description, [Validators.required, Validators.maxLength(4000)]),
      otherCategory: new FormControl(coachingLogWithUsersModel.otherCategory),
      noteDate: new FormControl(coachingLogWithUsersModel.noteDate, [Validators.required]),
      followUpDate: new FormControl(coachingLogWithUsersModel.followUpDate, [Validators.required]),

      categories: new FormArray([])
    },
      {
        validators: HasSelectedCheckboxesOrOther("categories", "otherCategory")
      }
    )
  });

  return form;
}
