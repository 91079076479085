import { createReducer, on, Action } from '@ngrx/store';
import { SelectItem } from 'primeng/api';
import {
  loadViewableCampusesSuccess, loadClassificationsSuccess, getSummaryListSuccess,
  getSummaryList, getEmployeePtoSummary, getEmployeePtoSummarySuccess, getEmployeeEarnedPto,
  getEmployeeEarnedPtoSuccess, getEmployeeRequestedPto, getEmployeeRequestedPtoSuccess, showEditPtoRequest,
  closeEditPtoRequest, savePtoRequest, savePtoRequestSuccess, updatePtoRequestForm, getRecentTimeOff,
  getRecentTimeOffSuccess, getUpcomingTimeOff, getUpcomingTimeOffSuccess, showEditTimeOffRequest, closeEditTimeOffRequest, saveTimeOffRequest, saveTimeOffRequestSuccess, updateTimeOffRequestForm, getManagePtoEarnedList, getManagePtoEarnedListSuccess, getManageTimeOffListSuccess, getManageTimeOffList, showEditPtoEarned, closeEditPtoEarned, savePtoEarned, savePtoEarnedSuccess, updatePtoEarnedForm, saveTimeOffApproval, saveTimeOffApprovalSuccess, resetTimeOffApproval, resetTimeOffApprovalSuccess, showApproveTimeOffRequest, closeApproveTimeOffRequest, getTimeOffApprovalSummary, getTimeOffApprovalSummarySuccess, updateTimeOffApprovalForm, enableSelectTimeOffEmployee
} from './timeoff.actions';
import { EmployeePtoSummaryModel, PtoEarnedModel, PtoRequestedModel, TimeOffApprovalModel, TimeOffModel } from '../../../models/timeoffModel';
import { PagingResultsModel } from '../../../models/pagingResultsModel';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { formatDate } from '@angular/common';
import { errorHappened } from '../profile/profile.actions';


export interface TimeoffState {
  campuses: SelectItem[];
  classifications: SelectItem[];  

  loadingSummaryList: boolean;
  summaryList: PagingResultsModel<EmployeePtoSummaryModel>;

  loadingEmployeePtoSummary: boolean;
  employeePtoSummary: EmployeePtoSummaryModel;

  loadingEarnedList: boolean;
  ptoEarnedList: PagingResultsModel<PtoEarnedModel>;

  loadingRequestedPtoList: boolean;
  ptoRequestedList: PagingResultsModel<PtoRequestedModel>;

  editingPtoRequest: boolean;
  savingPtoRequest: boolean;
  selectedPtoRequest: PtoRequestedModel;

  loadingUpcomingList: boolean;
  upcomingList: PagingResultsModel<TimeOffModel>;

  loadingRecentList: boolean;
  recentList: PagingResultsModel<TimeOffModel>;

  editingTimeOffRequest: boolean;
  savingTimeOffRequest: boolean;
  approvingTimeOffRequest: boolean;
  selectedTimeOffRequest: TimeOffModel;
  selectedTimeOffApproval: TimeOffModel;

  loadingManagePtoEarnedList: boolean,
  managePtoEarnedList: PagingResultsModel<PtoEarnedModel>,

  loadingManageTimeOffList: boolean,
  manageTimeOffList: PagingResultsModel<TimeOffModel>,

  editingPtoEarned: boolean;
  savingPtoEarned: boolean;
  selectedPtoEarned: PtoEarnedModel;

  loadingTimeOffApprovalSummary: boolean;
  timeOffApprovalSummary: TimeOffApprovalModel;

  disableEmployeeName: boolean;
};

const initialState: TimeoffState = {
  campuses: [],
  classifications: [],

  loadingSummaryList: true,
  summaryList: null,

  loadingEmployeePtoSummary: true,
  employeePtoSummary: null,

  loadingEarnedList: true,
  ptoEarnedList: null,

  loadingRequestedPtoList: true,
  ptoRequestedList: null,

  editingPtoRequest: false,
  savingPtoRequest: false,
  approvingTimeOffRequest: false,
  selectedPtoRequest: null,
  selectedTimeOffApproval: null,

  loadingUpcomingList: true,
  upcomingList: null,

  loadingRecentList: true,
  recentList: null,

  editingTimeOffRequest: false,
  savingTimeOffRequest: false,
  selectedTimeOffRequest: null,

  loadingManagePtoEarnedList: true,
  managePtoEarnedList: null,

  loadingManageTimeOffList: true,
  manageTimeOffList: null,

  editingPtoEarned: false,
  savingPtoEarned: false,
  selectedPtoEarned: null,

  loadingTimeOffApprovalSummary: false,
  timeOffApprovalSummary: null,

  disableEmployeeName: true,
};

export const timeoffReducer = createReducer(initialState,
  on(loadViewableCampusesSuccess, (state, action) => {
    return {
      ...state,
      campuses: action.campuses,
    };
  }),
  on(loadClassificationsSuccess, (state, action) => {
    return {
      ...state,
      classifications: action.classifications,
    };
  }),  
  on(getSummaryList, (state, action) => {
    return {
      ...state,
      loadingSummaryList: true
    };
  }),
  on(getSummaryListSuccess, (state, action) => {
    return {
      ...state,
      summaryList: action.summaryList,
      loadingSummaryList: false
    };
  }),
  on(getEmployeePtoSummary, (state, action) => {
    return {
      ...state,
      loadingEmployeePtoSummary: true
    };
  }),
  on(getEmployeePtoSummarySuccess, (state, action) => {
    return {
      ...state,
      employeePtoSummary: action.summary,
      loadingEmployeePtoSummary: false
    };
  }),
  on(getEmployeeEarnedPto, (state, action) => {
    return {
      ...state,
      loadingEarnedList: true
    };
  }),
  on(getEmployeeEarnedPtoSuccess, (state, action) => {
    return {
      ...state,
      ptoEarnedList: action.ptoList,
      loadingEarnedList: false
    };
  }),
  on(getEmployeeRequestedPto, (state, action) => {
    return {
      ...state,
      loadingRequestedPtoList: true
    };
  }),
  on(getEmployeeRequestedPtoSuccess, (state, action) => {
    return {
      ...state,
      ptoRequestedList: action.ptoList,
      loadingRequestedPtoList: false
    };
  }),
  on(showEditPtoRequest, (state, action) => {
    return {
      ...state,
      selectedPtoRequest: action.ptoRequest,
      editingPtoRequest: true
    };
  }),
  on(closeEditPtoRequest, (state, action) => {
    return {
      ...state,
      selectedPtoRequest: null,
      editingPtoRequest: false
    };
  }),
  on(savePtoRequest, (state, action) => {
    return {
      ...state,
      savingPtoRequest: true
    }
  }),
  on(savePtoRequestSuccess, (state, action) => {
    return {
      ...state,
      savingPtoRequest: false
    }
  }),
  on(updatePtoRequestForm, (state, action) => {
    const editingPtoRequest = { ...state.selectedPtoRequest, ...action.formValues };
    const newState = { ...state, ...{ selectedPtoRequest: editingPtoRequest, formErrors: action.formErrors } };
    return newState;
  }),
  on(errorHappened, (state, action) => {
    return {
      ...state,
      savingPtoRequest: false,
      savingTimeOffRequest: false,
      savingPtoEarned: false
    }
  }),
  on(getRecentTimeOff, (state, action) => {
    return {
      ...state,
      loadingRecentList: true
    };
  }),
  on(getRecentTimeOffSuccess, (state, action) => {
    return {
      ...state,
      recentList: action.list,
      loadingRecentList: false
    };
  }),
  on(getUpcomingTimeOff, (state, action) => {
    return {
      ...state,
      loadingUpcomingList: true
    };
  }),
  on(getUpcomingTimeOffSuccess, (state, action) => {
    return {
      ...state,
      upcomingList: action.list,
      loadingUpcomingList: false
    };
  }),
  on(showEditTimeOffRequest, (state, action) => {
    return {
      ...state,
      selectedTimeOffRequest: action.timeOffRequest,
      editingTimeOffRequest: true
    };
  }),
  on(closeEditTimeOffRequest, (state, action) => {
    return {
      ...state,
      selectedTimeOffRequest: null,
      editingTimeOffRequest: false,
      disableEmployeeName: true
    };
  }),
  on(saveTimeOffRequest, (state, action) => {
    return {
      ...state,
      savingTimeOffRequest: true
    }
  }),
  on(saveTimeOffRequestSuccess, (state, action) => {
    return {
      ...state,
      savingTimeOffRequest: false
    }
  }),
  on(updateTimeOffRequestForm, (state, action) => {
    const editingTimeOffRequest = { ...state.selectedTimeOffRequest, ...action.formValues };
    const newState = { ...state, ...{ selectedTimeOffRequest: editingTimeOffRequest, formErrors: action.formErrors } };
    return newState;
  }),
  on(updateTimeOffApprovalForm, (state, action) => {
    const editingTimeOffApproval = { ...state.selectedTimeOffApproval, ...action.formValues };
    const newState = { ...state, ...{ selectedTimeOffApproval: editingTimeOffApproval, formErrors: action.formErrors } };
    return newState;
  }),
  on(getManagePtoEarnedList, (state, action) => {
    return {
      ...state,
      loadingManagePtoEarnedList: true
    };
  }),
  on(getManagePtoEarnedListSuccess, (state, action) => {
    return {
      ...state,
      managePtoEarnedList: action.ptoList,
      loadingManagePtoEarnedList: false
    };
  }),

  on(getManageTimeOffList, (state, action) => {
    return {
      ...state,
      loadingManageTimeOffList: true
    };
  }),
  on(getManageTimeOffListSuccess, (state, action) => {
    return {
      ...state,
      manageTimeOffList: action.list,
      loadingManageTimeOffList: false
    };
  }),


  on(showEditPtoEarned, (state, action) => {
    return {
      ...state,
      selectedPtoEarned: action.ptoEarned,
      editingPtoEarned: true
    };
  }),
  on(closeEditPtoEarned, (state, action) => {
    return {
      ...state,
      selectedPtoEarned: null,
      editingPtoEarned: false
    };
  }),
  on(savePtoEarned, (state, action) => {
    return {
      ...state,
      savingPtoEarned: true
    }
  }),
  on(savePtoEarnedSuccess, (state, action) => {
    return {
      ...state,
      savingPtoEarned: false
    }
  }),
  on(updatePtoEarnedForm, (state, action) => {
    const editingPtoEarned = { ...state.selectedPtoEarned, ...action.formValues };
    const newState = { ...state, ...{ selectedPtoEarned: editingPtoEarned, formErrors: action.formErrors } };
    return newState;
  }),
  on(saveTimeOffApproval, (state, action) => {
    return {
      ...state,
      savingTimeOffRequest: true
    }
  }),
  on(saveTimeOffApprovalSuccess, (state, action) => {
    return {
      ...state,
      savingTimeOffRequest: false
    }
  }),
  on(resetTimeOffApproval, (state, action) => {
    return {
      ...state,
      savingTimeOffRequest: true
    }
  }),
  on(resetTimeOffApprovalSuccess, (state, action) => {
    return {
      ...state,
      savingTimeOffRequest: false
    }
  }),
  on(showApproveTimeOffRequest, (state, action) => {
    return {
      ...state,
      selectedTimeOffApproval: action.timeOffRequest,
      approvingTimeOffRequest: true
    };
  }),
  on(closeApproveTimeOffRequest, (state, action) => {
    return {
      ...state,
      selectedTimeOffApproval: null,
      approvingTimeOffRequest: false
    };
  }),
  on(getTimeOffApprovalSummary, (state, action) => {
    return {
      ...state,
      loadingTimeOffApprovalSummary: true
    };
  }),
  on(getTimeOffApprovalSummarySuccess, (state, action) => {
    return {
      ...state,
      timeOffApprovalSummary: action.summary,
      loadingTimeOffApprovalSummary: false
    };
  }),

  on(enableSelectTimeOffEmployee, (state, action) => {
    return {
      ...state,
      disableEmployeeName: false
    }
  }),
);

export function timeoffReducerFunc(state: TimeoffState | undefined, action: Action) {
  return timeoffReducer(state, action);
}


export function initEditPtoRequestForm(ptoRequest: PtoRequestedModel) {
  if (ptoRequest == null) {
    ptoRequest = {} as PtoRequestedModel;
  }
  if (typeof ptoRequest.payableItemId === 'undefined') { //initializing new request
    ptoRequest.payableItemId = 0;
    ptoRequest.userId = 0;
    ptoRequest.employeeName = "";
    ptoRequest.addedByPayroll = false;
    ptoRequest.ptoHours = 0;
    ptoRequest.ptoMinutes = 0;
    ptoRequest.startTime = new Date();
    ptoRequest.endTime = new Date();
  }

  const form = new FormGroup({
    payableItemId: new FormControl(ptoRequest.payableItemId),
    userId: new FormControl(ptoRequest.userId),
    employeeName: new FormControl(ptoRequest.employeeName),
    comments: new FormControl(ptoRequest.comments), //validator, length
    startTime: new FormControl(moment(ptoRequest.startTime).toDate()),
    endTime: new FormControl(moment(ptoRequest.endTime).toDate()),
    startDateString: new FormControl(typeof ptoRequest.startTime !== 'undefined' ? formatDate(ptoRequest.startTime, 'yyyy-MM-dd', 'en-US') : '', [Validators.required]), //add pattern
    endDateString: new FormControl(typeof ptoRequest.endTime !== 'undefined' ? formatDate(ptoRequest.endTime, 'yyyy-MM-dd', 'en-US') : '', [Validators.required]), //add pattern
    addedByPayroll: new FormControl(ptoRequest.addedByPayroll),
    ptoHours: new FormControl(Math.floor(ptoRequest.ptoMinutesUsed / 60)),
    ptoMinutes: new FormControl(Math.floor(ptoRequest.ptoMinutesUsed % 60)),
    payPeriodId: new FormControl(ptoRequest.payPeriodId)
  });

  return form
}

export function initEditTimeOffRequestForm(timeOffRequest: TimeOffModel) {
  if (timeOffRequest == null) {
    timeOffRequest = {} as TimeOffModel;
  }
  if (typeof timeOffRequest.timeOffId === 'undefined') { //initializing new request
    timeOffRequest.timeOffId = 0;
    timeOffRequest.userId = 0;
    timeOffRequest.employeeName = "";
    timeOffRequest.allDay = true;
    timeOffRequest.startDate = new Date();
    timeOffRequest.endDate = new Date();
    timeOffRequest.startTime = new Date();
    timeOffRequest.endTime = new Date();
    timeOffRequest.usePto = false;
    timeOffRequest.ptoHours = 0;
    timeOffRequest.ptoMinutes = 0;
    timeOffRequest.addedByPayroll = false;
  }

  const form = new FormGroup({
    timeOffId: new FormControl(timeOffRequest.timeOffId),
    userId: new FormControl(timeOffRequest.userId),
    addedByPayroll: new FormControl(timeOffRequest.addedByPayroll),
    employeeName: new FormControl(timeOffRequest.employeeName),
    comments: new FormControl(timeOffRequest.comments), //validator, length
    startTime: new FormControl(moment(timeOffRequest.startTime).toDate()),
    endTime: new FormControl(moment(timeOffRequest.endTime).toDate()),
    startDateString: new FormControl(typeof timeOffRequest.startDate !== 'undefined' ? formatDate(timeOffRequest.startDate, 'yyyy-MM-dd', 'en-US') : '', [Validators.required]), //add pattern
    endDateString: new FormControl(typeof timeOffRequest.endDate !== 'undefined' ? formatDate(timeOffRequest.endDate, 'yyyy-MM-dd', 'en-US') : '', [Validators.required]), //add pattern
    startTimeString: new FormControl(typeof timeOffRequest.startTime !== 'undefined' ? formatDate(timeOffRequest.startTime, 'h:mm a', 'en-US') : '', [Validators.required]), //add pattern
    endTimeString: new FormControl(typeof timeOffRequest.endTime !== 'undefined' ? formatDate(timeOffRequest.endTime, 'h:mm a', 'en-US') : '', [Validators.required]), //add pattern
    allDay: new FormControl(timeOffRequest.allDay),
    usePto: new FormControl(timeOffRequest.usePto),
    ptoHours: new FormControl(timeOffRequest.ptoHours),
    ptoMinutes: new FormControl(timeOffRequest.ptoMinutes),
  });

  return form
}

export function initEditPtoEarnedForm(ptoEarned: PtoEarnedModel) {
  if (ptoEarned == null) {
    ptoEarned = {} as PtoEarnedModel;
  }
  if (typeof ptoEarned.ptoId === 'undefined') { //initializing new request
    ptoEarned.ptoId = 0;
    ptoEarned.userId = 0;    
    ptoEarned.employeeName = "";    
    ptoEarned.minutesEarned = 0;
    ptoEarned.ptoHours = 0;
    ptoEarned.ptoMinutes = 0;
    ptoEarned.dateEarned = new Date();
  }

  const form = new FormGroup({
    ptoId: new FormControl(ptoEarned.ptoId),
    userId: new FormControl(ptoEarned.userId),
    employeeName: new FormControl(ptoEarned.employeeName),
    comments: new FormControl(ptoEarned.comments, [Validators.required, Validators.minLength(10)]), //validator, length    
    dateEarnedString: new FormControl(typeof ptoEarned.dateEarned !== 'undefined' ? formatDate(ptoEarned.dateEarned, 'yyyy-MM-dd', 'en-US') : '', [Validators.required]), //add pattern    
    ptoHours: new FormControl(ptoEarned.minutesEarned >= 0 ? Math.floor(ptoEarned.minutesEarned / 60) : Math.ceil(ptoEarned.minutesEarned / 60)),
    ptoMinutes: new FormControl(Math.floor(ptoEarned.minutesEarned % 60)),
    minutesEarned: new FormControl(ptoEarned.minutesEarned)
  });

  return form
}

export function initApproveTimeOffRequestForm(timeOffRequest: TimeOffModel) {
  if (timeOffRequest == null) {
    timeOffRequest = {} as TimeOffModel;
  }
  if (typeof timeOffRequest.timeOffId === 'undefined') { //initializing new request
    timeOffRequest.timeOffId = 0;
    timeOffRequest.approverComments = "";
  }

  const form = new FormGroup({
    timeOffId: new FormControl(timeOffRequest.timeOffId),    
    approverComments: new FormControl(timeOffRequest.approverComments)    
  });

  return form
}
