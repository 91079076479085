import { Component, OnInit } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { BaseComponent } from '../../../../../core/abstracts/baseComponent';
import { State } from '../../../../../core/store';
import { addAndCloseKpiEthicsCodes, closeKpiEthicsCodes } from '../../../../../core/store/employee-review-template/employee-review-template.actions';
import { loadEthicCodes, saveEthicCodes, saveEthicCodeSuccess } from '../../../../../core/store/ethic-code/ethic-code.actions';
import { deepClone } from '../../../../../helpers/utils';
import { EmployeeReviewTemplateKPIModel, KPIHasRBTEthicsCodeModel } from '../../../../../models/employeeReviewTemplateModels';
import { EthicCodeModel } from '../../../../../models/ethicCodeModels';
import { AlertService } from '../../../../../shared/services/alert.service';

@Component({
  selector: 'app-kpi-ethics-codes-component',
  templateUrl: './kpi-ethics-codes-component.component.html',
  styleUrls: ['./kpi-ethics-codes-component.component.scss']
})
export class KpiEthicsCodesComponentComponent extends BaseComponent implements OnInit {

  loading: boolean;

  display: boolean = false;
  savingEthicCode: boolean = false;

  availableEthicsCodes: KPIHasRBTEthicsCodeModel[] = [];
  ethicCode: EthicCodeModel = {
    description: '',
    section: ''
  };
  kpi: EmployeeReviewTemplateKPIModel = null;
  selectedItems: KPIHasRBTEthicsCodeModel[] = [];

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private alertService: AlertService) {
    super();
    this.store.dispatch(loadEthicCodes({}));
  }

  ngOnInit() {
    this.ethicCode = {
      description: '',
      section: ''
    };
    this.kpi = null;
    this.subs.push(
      this.store.select(s => s.employeeReviewTemplateState.addingEthicsCodesToKPI).subscribe(kpi => {
        if (kpi !== null) {
          this.display = true;
          this.kpi = deepClone(kpi);
          if (this.kpi.ethicsCodes) {
            this.selectedItems = this.kpi.ethicsCodes;
            for (var i = 0; i < this.selectedItems.length; i++) {
              var listItem = this.availableEthicsCodes.find(f => f.rbtEthicCodeId == this.selectedItems[i].rbtEthicCodeId);
              if (this.selectedItems[i].highlight) {
                this.selectedItems[i].showOnReport = 2;
                if (listItem)
                  listItem.showOnReport = 2;
              } else {
                this.selectedItems[i].showOnReport = 1;
                if (listItem)
                  listItem.showOnReport = 1;
              }
            }
            
          }
          else
            this.selectedItems = [];
        }
        else {
          this.display = false;
          this.kpi = null;
          this.selectedItems = []
        }
      }),

      this.store.select(s => s.ethicCodeState.savingEthicCode).subscribe(savingEthicCode => {
        this.savingEthicCode = savingEthicCode;
      }),

      this.store.select(s => s.ethicCodeState.ethicCodes).subscribe(ethicsCodes => {
        if (ethicsCodes) {
          this.availableEthicsCodes = ethicsCodes.map(m => ({
            rbtEthicCodeId: m.id,
            description: m.description,
            section: m.section,
            highlight: this.selectedItems.some(s => s.rbtEthicCodeId == m.id && s.highlight),
            showOnReport: this.selectedItems.some(s => s.rbtEthicCodeId == m.id) ? this.selectedItems.find(s => s.rbtEthicCodeId == m.id).showOnReport : 0
          }));
        }
      }),

      this.actions$.pipe(
        ofType(saveEthicCodeSuccess),
        tap(action => {
          this.ethicCode = {
            description: '',
            section: ''
          };
          this.store.dispatch(loadEthicCodes({}));
        })
      ).subscribe()
    );
  }

  closeModal() {
    this.store.dispatch(closeKpiEthicsCodes());
  }

  saveEthicCode() {
    if (!this.ethicCode.section) {
      this.alertService.error("Ethics Code Section cannot be empty.");
      return;
    }
    if (!this.ethicCode.description) {
      this.alertService.error("Description cannot be empty.");
      return;
    }

    this.store.dispatch(saveEthicCodes({ ethicCode: this.ethicCode }));
  }

  onchangeitem(ethicCode, value) {
    var isSelected = this.selectedItems.find(f => f.rbtEthicCodeId == ethicCode.rbtEthicCodeId);
    
    if (value == 0 && isSelected) {
      var index = this.selectedItems.indexOf(isSelected);
      this.selectedItems.splice(index, 1);
    }
    else if (value == 1) {//show
      if (!isSelected) {
        isSelected = deepClone(ethicCode);
        this.selectedItems.push(isSelected);
      }
      isSelected.highlight = false;
      
    }
    else if (value == 2) {//highlight
      if (!isSelected) {
        isSelected = deepClone(ethicCode);
        this.selectedItems.push(isSelected);
      }
      isSelected.highlight = true;
    }
  }

  done() {
    this.kpi.ethicsCodes = this.selectedItems;
    this.store.dispatch(addAndCloseKpiEthicsCodes({ kpi: this.kpi }));
  }

}
