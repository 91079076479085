<div class="card mb-2">
  <div class="card-body">
      <h2 class="">Directly Assign Form</h2>
      <div class="row">
          <div class="col-12 col-lg-6">
          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Form</b>
            </div>
            <div class="col-8 ">
                <i *ngIf="!formOptions" class="material-icons rotate text-muted">loop</i>
                <p-dropdown styleClass="form-control mt-2 w-100" [options]="formOptions" placeholder="Select form..." [ngModelOptions]="{standalone: true}" [(ngModel)]="formAssignment.formID" (ngModelChange)="changedForm()"></p-dropdown>
            </div>
            <ng-container *ngIf="selectedForm?.formTypeID == 1 || showEmployeeInput">
              <div class="col-4 d-flex align-items-center">
                <b>Employee</b>
              </div>
              <div class="col-8 ">
                  <i *ngIf="!usersOptions" class="material-icons rotate text-muted">loop</i>
                  <p-multiSelect autofocusFilter="true"  [virtualScroll]="true" itemSize="30"
                  class="w-100" styleClass="w-100 d-flex  mt-2" inputStyleClass="form-control" [options]="usersOptions" placeholder="Select employees..." [ngModelOptions]="{standalone: true}" [(ngModel)]="formAssignment.userIDs"></p-multiSelect>
              </div>
            </ng-container>
            <ng-container *ngIf="selectedForm?.formTypeID == 2 || showPatientInput">
                <div class="col-4 d-flex align-items-center">
                <b>Patient Inquiry</b>
              </div>
              <div class="col-8 position-relative">
                <app-input-autocomplete
                  id="PatientMultiSelect"
                  [length]="15"
                  [type]="'Inquiry'"
                  [selectType]="'MultiSelect'"
                  [(model)]="inquiryIds"
                  (modelChange)="setPatient($event)"
                  [inputClass]="'form-control'"
                ></app-input-autocomplete>
              </div>
            </ng-container>

            <div class="col-4 d-flex align-items-center">
              <b>Required</b>
            </div>
            <div class="col-8 ">
              <p-selectButton id="RequiredInput" styleClass="mt-2" [options]="yesNoOptions" [ngModelOptions]="{standalone: true}" [(ngModel)]="formAssignment.isRequired" ></p-selectButton>
            </div>
            <div class="col-4 d-flex align-items-center">
              <b>Due Date</b>
            </div>
            <div class="col-8 ">
              <p-calendar dateMask
                              inputStyleClass="form-control"
                              [monthNavigator]="true"
                              [yearNavigator]="true"
                              styleClass="w-100"
                              panelStyleClass="calendarPanel"
                              appendTo="body"
                              [hourFormat]="12"
                              [yearRange]="'1900:2100'"
                              [showTime]="false"
                              placeholder="Select date..."
                              [ngModelOptions]="{standalone: true}" [(ngModel)]="formAssignment.dueDate"
                              dataType="string"></p-calendar>
            </div>
            <div class="col-4 d-flex align-items-center">
              <b>Expiration Date</b>
            </div>
            <div class="col-8 ">
              <p-calendar dateMask
                              inputStyleClass="form-control"
                              [monthNavigator]="true"
                              [yearNavigator]="true"
                              styleClass="w-100"
                              panelStyleClass="calendarPanel"
                              appendTo="body"
                              [hourFormat]="12"
                              [yearRange]="'1900:2100'"
                              [showTime]="false"
                              placeholder="Select date..."
                              [ngModelOptions]="{standalone: true}" [(ngModel)]="formAssignment.expirationDate"
                              dataType="string"></p-calendar>
            </div>
          </div>
        </div>
      </div>
      <i *ngIf="addingFormAssignment" class="material-icons rotate text-muted">loop</i>
      <button class="btn btn-primary mt-3" id="saveButton" [disabled]="addingFormAssignment || !this.didChooseAllOptions()" (click)="save()">Save</button>
  </div>
</div>
