<form class="card mt-2">
  <div class="card-body">
    <div class="float-right">
      <button type="button" routerLink="/payroll/approval" class="btn btn-secondary mr-2">Payroll Approval</button>
    </div>
    <h2 class="mb-3">Manual Entry Report</h2>
    <div class="mb-3">

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Campus:</h5>
        </div>
        <div class="col-10">
          <i *ngIf="!campusList" class="material-icons rotate text-muted">loop</i>
          <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="campusList" [(ngModel)]="campusId" (ngModelChange)="changeFilters(this.filter)" [ngModelOptions]="{standalone: true}"></p-dropdown>
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Payroll Classification:</h5>
        </div>
        <div class="col-10">
          <i *ngIf="!payrollClassificationList" class="material-icons rotate text-muted">loop</i>
          <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="payrollClassificationList" [(ngModel)]="payrollClassificationId" (ngModelChange)="changeFilters(this.filter)" [ngModelOptions]="{standalone: true}"></p-dropdown>
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
           <h5>Period:</h5>
        </div>
        <div class="col-10">
          <i *ngIf="!payPeriods" class="material-icons rotate text-muted">loop</i>
          <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="payPeriods" [(ngModel)]="payPeriodId" (ngModelChange)="changeFilters(this.filter)" [ngModelOptions]="{standalone: true}"></p-dropdown>
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Employee:</h5>
        </div>
        <div class="col-10">
          <div class="ml-2 w-100 mb-2">
            <app-input-autocomplete
              [length]="15"
              [type]="'User'"
              [(model)]="inputUser"
              (modelChange)="setUserID($event)"
              [inputClass]="'form-control'"
            ></app-input-autocomplete>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Approval Status:</h5>
        </div>
        <div class="col-10">          
          <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="approvalStatusList" [(ngModel)]="approvalStatus" (ngModelChange)="changeFilters(this.filter)" [ngModelOptions]="{standalone: true}"></p-dropdown>
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Description:</h5>
        </div>
        <div class="col-10">
          <input type="text" class="form-control ml-2 w-100 mb-2" [(ngModel)]="descriptionFilter" (blur)="changeFilters(this.filter)" [ngModelOptions]="{standalone: true}" />
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Reason:</h5>
        </div>
        <div class="col-10">
          <i *ngIf="!manualEntryReasonList" class="material-icons rotate text-muted">loop</i>
          <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="manualEntryReasonList" [(ngModel)]="manualEntryReasonId" (ngModelChange)="changeFilters(this.filter)" [ngModelOptions]="{standalone: true}"></p-dropdown>
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Include Entries Without Reason:</h5>
        </div>
        <div class="col-10 " style="padding-left: 25px;">
          <p-checkbox [(ngModel)]="showEntriesWithoutReason" (ngModelChange)="changeFilters(this.filter)" [binary]="true" [ngModelOptions]="{standalone: true}"></p-checkbox>
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Include Separated Employees:</h5>
        </div>
        <div class="col-10 " style="padding-left: 25px;">
          <p-checkbox [(ngModel)]="showTerminatedEmployees" (ngModelChange)="changeFilters(this.filter)" [binary]="true" [ngModelOptions]="{standalone: true}"></p-checkbox>
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Include Inactive Users:</h5>
        </div>
        <div class="col-10 " style="padding-left: 25px;">
          <p-checkbox [(ngModel)]="showInactiveUsers" (ngModelChange)="changeFilters(this.filter)" [binary]="true" [ngModelOptions]="{standalone: true}"></p-checkbox>
        </div>
      </div>

    </div>

    <i [hidden]="!loadingList" class="material-icons rotate text-muted">loop</i>
    <button type="button" title="Export all pages to excel" class="btn btn-success mr-2 mb-2" (click)="exportToExcel()"><i class="far fa-file-excel"></i></button>
    <p-table  #timeCardTable [value]="list?.result" [responsive]="true" [paginator]="true" [rows]="20"
             [totalRecords]="list?.totalRecords" [lazy]="true" (onLazyLoad)="reloadFilter($event)">
      <ng-template pTemplate="header">
        <tr>
          <th width="200px" [pSortableColumn]="'EmployeeName'">Employee <p-sortIcon [field]="'EmployeeName'"></p-sortIcon></th>
          <!--<th [pSortableColumn]="'PrimaryProgramName'">Primary Campus <p-sortIcon [field]="'PrimaryProgramName'"></p-sortIcon></th>-->
          <th width="90px" [pSortableColumn]="'Date'">Date <p-sortIcon [field]="'Date'"></p-sortIcon></th>
          <th width="110px" [pSortableColumn]="'ClockIn'">Clock In <p-sortIcon [field]="'ClockIn'"></p-sortIcon></th>
          <th width="120px" [pSortableColumn]="'ClockOut'">Clock Out <p-sortIcon [field]="'ClockOut'"></p-sortIcon></th>
          <th width="90px" [pSortableColumn]="'Total'">Total <p-sortIcon [field]="'Total'"></p-sortIcon></th>
          <!--<th >Status </th>-->
          <th [pSortableColumn]="'Reason'">Reason <p-sortIcon [field]="'Reason'"></p-sortIcon></th>
          <th>Description</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-list>
        <tr>
          <td>
            <span class="ui-column-title">Employee</span>
            {{list.employeeFirstName}} {{list.employeeLastName}}
          </td>
          <!--<td>
            <span class="ui-column-title">Primary Campus</span>
            {{list.primaryProgramName}}
          </td>-->
          <td>
            <span class="ui-column-title">Date</span>
            {{list.clockIn | date:'shortDate' }}
          </td>
          <td>
            <span class="ui-column-title">Clock in</span>
            {{list.clockIn | date:'shortTime' }}
          </td>
          <td>
            <span class="ui-column-title">Clock in</span>
            {{list.clockOut | date:'shortTime' }}
          </td>
          <td>
            <span class="ui-column-title">Total</span>
            {{list.timeDiff }}
          </td>
          <!--<td>
            <span class="ui-column-title">Status</span>
            {{list.approved?'Approved':(list.denied?'Denied':(list.hasOpenPayableFixRequest?'Adjustment':(list.hasOpenDocumentationFixRequest?'Fix':'Pending')))}}
          </td>-->
          <td>
            <span class="ui-column-title">Reason</span>
            {{list.reason}} {{list.reason == "Other" ? '-':""}} {{list.reason == "Other" ? list.manualEntryOtherReason:""}}
          </td>
          <td>
            <span class="ui-column-title">Description</span>
            {{list.description}}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</form>
