<form class="card mt-2">
  <div class="card-body">
    <div class="float-right">
      <button type="button" routerLink="/timeoff/pto/my" class="btn btn-secondary">View PTO</button>
    </div>
    <div>
      <h2 class="mb-3">{{employeeName}}'s Time Off <i class="fas fa-sync" title="Refresh Data" (click)="refreshData()" style="cursor: pointer; font-size: small;"></i></h2>
      <div class="mb-3 row">

        <div class="col-xl-4 col-lg-12">
          <div class="row">
            <div class="col-6">
              <h5>Approved</h5>
            </div>
            <div class="col-6">
              <span *ngIf="showUpcoming">{{numberApprovedUpcoming}} Requests - {{daysApprovedUpcoming}} Total Days</span>
              <span *ngIf="!showUpcoming">{{numberApprovedRecent}} Requests - {{daysApprovedRecent}} Total Days</span>
            </div>
          </div>                    
        </div>
        <div class="col-xl-4 col-lg-12">
          <div class="row">
            <div class="col-6">
              <h5>Pending</h5>
            </div>
            <div class="col-6">
              <span *ngIf="showUpcoming">{{numberPendingUpcoming}} Requests - {{daysPendingUpcoming}} Total Days</span>
              <span *ngIf="!showUpcoming">{{numberPendingRecent}} Requests - {{daysPendingRecent}} Total Days</span>              
            </div>
          </div>          
        </div>

        <div class="col-xl-4 col-lg-12">
          <div class="row">            
            <div class="col-6">
              <h5>Denied</h5>
            </div>
            <div class="col-6">
              <span *ngIf="showUpcoming">{{numberDeniedUpcoming}} Requests - {{daysDeniedUpcoming}} Total Days</span>
              <span *ngIf="!showUpcoming">{{numberDeniedRecent}} Requests - {{daysDeniedRecent}} Total Days</span>              
            </div>
          </div>          
        </div>

      </div>
      <div [hidden]="!showUpcoming">
        <h3>
          Upcoming Requests
          <span style="color: blue; font-size:small; cursor: pointer;" (click)="toggleShowUpcoming()">View Recent</span>
        </h3>
        <div class="mb-3">
          <button *ngIf="userId === 0 || userId === currentUserId" (click)="editTimeOffRequest([])" class="btn btn-secondary">New Time Off Request</button>
        </div>
        <i [hidden]="!loadingUpcomingList" class="material-icons rotate text-muted">loop</i>
        <p-table [value]="upcomingList?.result" [responsive]="true" [paginator]="true" [rows]="20"
                 [totalRecords]="upcomingList?.totalRecords" [lazy]="true" (onLazyLoad)="reloadUpcomingList($event)">
          <ng-template pTemplate="header">
            <tr>
              <th width="100px"></th>
              <th [pSortableColumn]="'DateRange'">Date Range<p-sortIcon [field]="'DateRange'"></p-sortIcon></th>
              <th [pSortableColumn]="'TimeRange'">Time Range<p-sortIcon [field]="'TimeRange'"></p-sortIcon></th>
              <th [pSortableColumn]="'Comments'">Comments<p-sortIcon [field]="'Comments'"></p-sortIcon></th>
              <th [pSortableColumn]="'ApproverComments'">Approver Comments<p-sortIcon [field]="'ApproverComments'"></p-sortIcon></th>
              <th [pSortableColumn]="'Approved'">Status<p-sortIcon [field]="'Approved'"></p-sortIcon></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-upcomingTimeOff>
            <tr>
              <td>
                <div class="d-flex justify-content-around">
                  <i class="fas fa-edit" *ngIf="(userId === 0 || userId === currentUserId) && upcomingTimeOff.canEditRequest" (click)="editTimeOffRequest(upcomingTimeOff)" title="Edit Time Off Request"></i>
                  <i class="fas text-danger fa-trash" *ngIf="(userId === 0 || userId === currentUserId) && upcomingTimeOff.canEditRequest" (click)="deleteTimeOffRequest(upcomingTimeOff.timeOffId)" title="Edit Time Off Request"></i>
                </div>
              </td>
              <td>
                <span class="ui-column-title">Date Range</span>                
                {{upcomingTimeOff.startDate | date: 'MM/dd/yyyy'}} - {{upcomingTimeOff.endDate | date: 'MM/dd/yyyy'}}
              </td>
              <td>
                <span class="ui-column-title">Time Range</span>
                <span *ngIf="upcomingTimeOff.allDay">All Day</span>
                <span *ngIf="!upcomingTimeOff.allDay">{{upcomingTimeOff.startTime | date: 'shortTime'}} - {{upcomingTimeOff.endTime | date: 'shortTime'}}</span>                
              </td>
              <td>
                <span class="ui-column-title">Comments</span>
                {{upcomingTimeOff.comments}}
              </td>
              <td>
                <span class="ui-column-title">Approver Comments</span>
                {{upcomingTimeOff.approverComments}}
              </td>
              <td>
                <span class="ui-column-title">Approved</span>
                <span *ngIf="upcomingTimeOff.approved">Approved</span>
                <span *ngIf="upcomingTimeOff.denied">Denied</span>
                <span *ngIf="!upcomingTimeOff.approved && !upcomingTimeOff.denied">Pending</span>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div [hidden]="showUpcoming">
        <h3>
          Recent Requests (past 6 months)
          <span style="color: blue; font-size:small; cursor: pointer;" (click)="toggleShowUpcoming()">View upcoming</span>
        </h3>
        <div class="mb-3">
          <button *ngIf="userId === 0 || userId === currentUserId" (click)="editTimeOffRequest([])" class="btn btn-secondary">New Time Off Request</button>
        </div>
        <i [hidden]="!loadingRecentList" class="material-icons rotate text-muted">loop</i>
        <p-table [value]="recentList?.result" [responsive]="true" [paginator]="true" [rows]="20"
                 [totalRecords]="recentList?.totalRecords" [lazy]="true" (onLazyLoad)="reloadRecentList($event)">
          <ng-template pTemplate="header">
            <tr>
              <th [pSortableColumn]="'DateRange'">Date Range<p-sortIcon [field]="'DateRange'"></p-sortIcon></th>
              <th [pSortableColumn]="'TimeRange'">Time Range<p-sortIcon [field]="'TimeRange'"></p-sortIcon></th>
              <th [pSortableColumn]="'Comments'">Comments<p-sortIcon [field]="'Comments'"></p-sortIcon></th>
              <th [pSortableColumn]="'ApproverComments'">Approver Comments<p-sortIcon [field]="'ApproverComments'"></p-sortIcon></th>
              <th [pSortableColumn]="'Approved'">Status<p-sortIcon [field]="'Approved'"></p-sortIcon></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-recentTimeOff>
            <tr>              
              <td>
                <span class="ui-column-title">Date Range</span>                
                {{recentTimeOff.startDate | date: 'MM/dd/yyyy'}} - {{recentTimeOff.endDate | date: 'MM/dd/yyyy'}}
              </td>
              <td>
                <span class="ui-column-title">Time Range</span>
                <span *ngIf="recentTimeOff.allDay">All Day</span>
                <span *ngIf="!recentTimeOff.allDay">{{recentTimeOff.startTime | date: 'shortTime'}} - {{recentTimeOff.endTime | date: 'shortTime'}}</span>                
              </td>
              <td>
                <span class="ui-column-title">Comments</span>
                {{recentTimeOff.comments}}
              </td>
              <td>
                <span class="ui-column-title">Approver Comments</span>
                {{recentTimeOff.approverComments}}
              </td>
              <td>
                <span class="ui-column-title">Approved</span>
                <span *ngIf="recentTimeOff.approved">Approved</span>
                <span *ngIf="recentTimeOff.denied">Denied</span>
                <span *ngIf="!recentTimeOff.approved && !recentTimeOff.denied">Pending</span>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</form>

<app-edit-timeoff-request></app-edit-timeoff-request>
