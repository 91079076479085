import { loadBenefitsFormDocument, loadBenefitsFormDocumentInfo, loadBenefitsFormDocumentInfoSuccess, loadBenefitsSetDocument, signBenefitsFormDocument, signBenefitsFormDocumentSuccess, signBenefitsSetDocument, signBenefitsSetDocumentSuccess } from './../../../../core/store/benefits/benefits.actions';
import { UserProfileModel } from 'src/app/models/userProfileModel';
import { loadProfile } from './../../../../core/store/profile/profile.actions';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from './../../../../shared/services/alert.service';
import { UserModel } from 'src/app/models/userModel';
import { Component, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { Actions, ofType } from '@ngrx/effects';
import * as moment from 'moment';
import { SignDocComponentBase } from '../../../abstract/signDocBase.component';
import { tap } from 'rxjs/operators';
import { downloadFile, deepClone, clearArray } from 'src/app/helpers/utils';
import { BenefitPlanFormDocumentsEnrollmentModel } from 'src/app/models/benefitsModels';
import { getBenefitsFormDocumentsList } from 'src/app/core/store/benefits/benefits.actions';

@Component({
  selector: 'app-sign-enrollmentset',
  templateUrl: './sign-enrollmentset.component.html',
  styleUrls: ['./sign-enrollmentset.component.scss']
})
export class SignEnrollmentSetComponent extends SignDocComponentBase implements OnInit, AfterViewInit {

  @Input() benefitPlanEnrollmentSetID: string;
  @Input() doNotNavigate: boolean;
  loadingSign = false;
  loadingDoc = true;
  user: UserModel;
  @Input() profile: UserProfileModel;
  url: any;

  constructor(
    private store: Store<State>,
    private actions$: Actions,
    private alert: AlertService,
    private router: Router,
    private route: ActivatedRoute) {
    super(store);
  }

  ngOnInit() {
    if (this.router.url.indexOf('benefits-management/') >= 0) {
      this.isSigningAsHRManager = true;
    }
    this.store.dispatch(loadProfile());
    if (this.benefitPlanEnrollmentSetID) {
      this.optionalFields.length = 0;
      this.store.dispatch(loadBenefitsSetDocument({ benefitPlanEnrollmentSetID: parseInt(this.benefitPlanEnrollmentSetID, 10) }));
    } else {
      this.route.params.subscribe(params => {
        if (params.benefitPlanEnrollmentSetID) {
          this.benefitPlanEnrollmentSetID = params.benefitPlanEnrollmentSetID;
          this.optionalFields.length = 0;
          this.store.dispatch(loadBenefitsSetDocument({ benefitPlanEnrollmentSetID: parseInt(this.benefitPlanEnrollmentSetID, 10) }));
        }
      });
    }


    this.subs.push(
      this.store.select(s => s.profileState.profile).subscribe(p => {
        if (p) {
          this.user = p.user;
        }
      }),
      this.store.select(s => s.benefitsState.docArrayBufferBenefit).subscribe(docArrayBuffer => {
        if (docArrayBuffer) {
          if (this.intervalFormattingSignature) {
            clearInterval(this.intervalFormattingSignature);
          }
          clearArray(this.signedFields);
          this.url =
            URL.createObjectURL(new Blob([docArrayBuffer], {
              type: 'application/pdf'
            })) + '&avoidcache=' + (new Date()).getTime();

          this.afterPdfViewerInit();
        }
      }),
      this.store.select(s => s.benefitsState.loadingDocBenefit).subscribe(loadingDoc => {
        this.loadingDoc = loadingDoc;
      }),
      this.store.select(s => s.benefitsState.loadingSignBenefit).subscribe(loadingSign => {
        this.loadingSign = loadingSign;
      }),
      this.actions$.pipe(
        ofType(signBenefitsSetDocumentSuccess),
        tap(action => {
          if (!this.doNotNavigate) {
            this.alert.confirm('Documentation signed succesfully',
              'Documentation signed succesfully. Do you want to download the signed file now?')
              .subscribe(
                answer => {
                  if (answer) {
                    downloadFile(action.docSigned, 'document.pdf');
                  }

                  this.router.navigate(['../../enrollment'], { relativeTo: this.route });

                }, error => {
                  this.router.navigate([`../../enrollment`], { relativeTo: this.route });
                }
              );
          }
        })
      ).subscribe()
    );
  }

  get loggedUserName() {
    return !this.user ? '' : (this.user.firstName + ' ' + this.user.lastName);
  }

  get loggedUserEmail() {
    return !this.user ? '' : this.user.email;
  }

  sign() {
    if (!this.finishSignDisabled) {
      this.store.dispatch(
        signBenefitsSetDocument({ benefitPlanEnrollmentSetID: parseInt(this.benefitPlanEnrollmentSetID, 10), signedFields: deepClone(this.signedFields) }));
    }
  }

}
