import { filter } from 'rxjs/operators';
import { UserModel } from 'src/app/models/userModel';
import { PayrollItemModel, PayrollApprovedTotalsModel, PayableItemModel } from './../../../models/payableItem';
import { createReducer, on, Action } from '@ngrx/store';
import {
  loadPayrollApprovalApprovedTimeSuccess, loadPayrollApprovalInvalidTimeSuccess, loadPayrollApprovalAdjustmentTimeSuccess,
  loadPayrollApprovalPendingTimeSuccess, loadPayrollApprovalPendingTime, loadPayrollApprovalAdjustmentTime, loadPayrollApprovalInvalidTime,
  loadPayrollApprovalApprovedTime, loadPayrollApprovalApprovedTotals, loadPayrollApprovalApprovedTotalsSuccess,
  changePayrollApprovalPeriod, loadPayrollApprovalPeriodsSuccess, getIsLockedPeriodSuccess, loadPayrollClassificationsSuccess,
  loadCampusesSuccess, changePayrollApprovalUserFilter, changePayrollApprovalUserFilterSuccess, loadPayrollCampusesSuccess, approvePayrollItems, approvePayrollItemsSuccess, approvePayrollItemsFail,
  generatePayrollItems, generatePayrollItemsSuccess, generatePayrollItemsFail, savePayableItem, savePayableItemSuccess, savePayableItemFail, showEditPayableItem, closeEditPayableItem, updatePayableItemForm
} from './payroll-approval.actions';
import { deepClone, getTimeDiff, getTimeDiffMinusOvertime, getFormattedTime, getTimeDiffDays, getTimeDiffMinutes, getTimeFormatted } from 'src/app/helpers/utils';
import { PayPeriodModel } from 'src/app/models/payPeriodModel';
import { CampusModel } from 'src/app/models/campusModel';
import { PayrollClassificationModel } from 'src/app/models/payrollClassificationModel';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import * as moment from 'moment';

export interface PayrollApprovalState {
  approvedPayrollItems: PayrollItemModel[];
  invalidPayrollItems: PayrollItemModel[];
  adjustmentPayrollItems: PayrollItemModel[];
  pendingPayrollItems: PayrollItemModel[];
  approvedTotals: PayrollApprovedTotalsModel;
  periods: PayPeriodModel[];
  selectedPeriodID: number;
  previouslySelectedPeriodID: number;
  selectedUserID: number;
  previouslySelectedUserID: number;
  isLocked: boolean;
  selectedCampusID: number;
  selectedPayrollClassificationID: number;
  campuses: CampusModel[];
  payrollCampuses: CampusModel[];
  payrollClassifications: PayrollClassificationModel[];
  filteredUsers: UserModel[];
  loadingUsersList: boolean;
  showAll: boolean;
  approvingPayroll: boolean;

  selectedPayableItem: PayableItemModel;
  editingPayableItem: boolean;
  savingPayableItem: boolean;
}

const initialState: PayrollApprovalState = {
  approvedPayrollItems: null,
  invalidPayrollItems: null,
  adjustmentPayrollItems: null,
  pendingPayrollItems: null,
  approvedTotals: null,
  periods: null,
  selectedPeriodID: 0,
  previouslySelectedPeriodID: -1,
  selectedUserID: 0,
  previouslySelectedUserID: -1,
  isLocked: false,
  campuses: [],
  payrollCampuses: [],
  payrollClassifications: [],
  selectedCampusID: 0,
  selectedPayrollClassificationID: 0,
  filteredUsers: [],
  loadingUsersList: false,
  showAll: false,
  approvingPayroll: false,

  selectedPayableItem: null,
  editingPayableItem: false,
  savingPayableItem: false,
};

export const payrollApprovalReducer = createReducer(initialState,
  on(loadPayrollApprovalApprovedTime, (state, action) => {
    return {
      ...state,
      approvedPayrollItems: null,
    };
  }),
  on(loadPayrollApprovalInvalidTime, (state, action) => {
    return {
      ...state,
      invalidPayrollItems: null,
    };
  }),
  on(loadPayrollApprovalAdjustmentTime, (state, action) => {
    return {
      ...state,
      adjustmentPayrollItems: null,
    };
  }),
  on(loadPayrollApprovalPendingTime, (state, action) => {
    return {
      ...state,
      pendingPayrollItems: null,
    };
  }),
  on(loadPayrollApprovalApprovedTotals, (state, action) => {
    return {
      ...state,
      approvedTotals: null,
    };
  }),
  on(loadPayrollApprovalApprovedTimeSuccess, (state, action) => {
    const payrollItems = formatPayableItem(action.payrollItems);
    return {
      ...state,
      approvedPayrollItems: payrollItems,
    };
  }),
  on(loadPayrollApprovalInvalidTimeSuccess, (state, action) => {
    const payrollItems = formatPayableItem(action.payrollItems);
    return {
      ...state,
      invalidPayrollItems: payrollItems,
    };
  }),
  on(loadPayrollApprovalAdjustmentTimeSuccess, (state, action) => {
    const payrollItems = formatPayableItem(action.payrollItems);
    return {
      ...state,
      adjustmentPayrollItems: payrollItems,
    };
  }),
  on(loadPayrollApprovalPendingTimeSuccess, (state, action) => {
    const payrollItems = formatPayableItem(action.payrollItems);
    return {
      ...state,
      pendingPayrollItems: payrollItems,
    };
  }),
  on(loadPayrollApprovalApprovedTotalsSuccess, (state, action) => {
    return {
      ...state,
      approvedTotals: action.approvedTotals,
    };
  }),
  on(loadPayrollApprovalPeriodsSuccess, (state, action) => {
    return {
      ...state,
      periods: action.periods
    };
  }),
  on(changePayrollApprovalPeriod, (state, action) => {
    return {
      ...state,
      selectedPeriodID: action.periodID,
      previouslySelectedPeriodID: state.selectedPeriodID,
      selectedUserID: action.userID,
      previouslySelectedUserID: state.selectedUserID,
      showAll: action.showAll
    };
  }),
  on(getIsLockedPeriodSuccess, (state, action) => {
    return {
      ...state,
      isLocked: action.isLocked
    };
  }),
  on(loadPayrollClassificationsSuccess, (state, action) => {
    return {
      ...state,
      payrollClassifications: action.payrollClassifications,
    };
  }),
  on(loadCampusesSuccess, (state, action) => {
    return {
      ...state,
      campuses: action.campuses,
    };
  }),
  on(loadPayrollCampusesSuccess, (state, action) => {
    return {
      ...state,
      payrollCampuses: action.campuses,
    };
  }),
  on(changePayrollApprovalUserFilter, (state, action) => {
    return {
      ...state,
      filteredUsers: [],
      selectedPayrollClassificationID: action.payrollClassificationID,
      selectedCampusID: action.campusID,
      loadingUsersList: true
    };
  }),
  on(changePayrollApprovalUserFilterSuccess, (state, action) => {
    return {
      ...state,
      filteredUsers: action.usersList,
      loadingUsersList: false
    };
  }),
  on(approvePayrollItems, (state, action) => {
    return {
      ...state,
      approvingPayroll: true,
    };
  }),
  on(approvePayrollItemsSuccess, (state, action) => {
    return {
      ...state,
      approvingPayroll: false,
    };
  }),
  on(approvePayrollItemsFail, (state, action) => {
    return {
      ...state,
      approvingPayroll: false,
    };
  }),
  on(generatePayrollItems, (state, action) => {
    return {
      ...state,
      approvingPayroll: true,
    };
  }),
  on(generatePayrollItemsSuccess, (state, action) => {
    return {
      ...state,
      approvingPayroll: false,
    };
  }),
  on(generatePayrollItemsFail, (state, action) => {
    return {
      ...state,
      approvingPayroll: false,
    };
  }),
  on(savePayableItem, (state, action) => {
    return {
      ...state,
      savingPayableItem: true,
    };
  }),
  on(savePayableItemSuccess, (state, action) => {
    return {
      ...state,
      savingPayableItem: false,
    };
  }),
  on(savePayableItemFail, (state, action) => {
    return {
      ...state,
      savingPayableItem: false,
    };
  }),
  on(showEditPayableItem, (state, action) => {
    return {
      ...state,
      selectedPayableItem: action.payableItem,
      editingPayableItem: true
    };
  }),
  on(closeEditPayableItem, (state, action) => {
    return {
      ...state,
      selectedPayableItem: null,
      editingPayableItem: false
    };
  }),
  on(updatePayableItemForm, (state, action) => {
    const editingPayableItem = { ...state.selectedPayableItem, ...action.formValues };
    const newState = { ...state, ...{ selectedPayableItem: editingPayableItem, formErrors: action.formErrors } };
    return newState;
  }),
);

export function payrollApprovalReducerFunc(state: PayrollApprovalState | undefined, action: Action) {
  return payrollApprovalReducer(state, action);
}

export function formatPayableItem(paramPayrollItems: PayrollItemModel[]): PayrollItemModel[] {
  const payrollItems: PayrollItemModel[] = deepClone(paramPayrollItems);
  if (paramPayrollItems) {
    payrollItems.forEach(payrollItem => {
      const payableItem = payrollItem.payableItem;      


      //2h 30m Normal Rate, 23m Overtime Rate
      if (payableItem.overtimeMinutes > 0) {        
        let normalTimeDiff = payrollItem.is90834Session ? getTimeFormatted(60 - payableItem.overtimeMinutes) :
          payableItem.payableTypeID === 7 || payableItem.payableTypeID === 9 || payableItem.payableTypeID === 15 ? getTimeFormatted(payableItem.ptoMinutesUsed) : //Paid Time Off (int)Minimiser.Data.EnumList.PayableType.PaidTimeOff
            (payableItem.startTime && payableItem.endTime) ?
              getTimeDiffMinusOvertime(payableItem.startTime.toString(), payableItem.endTime.toString(), payableItem.overtimeMinutes) : '0';

        let overtimeDiff = getTimeFormatted(payableItem.overtimeMinutes);

        payrollItem.payableItem.timeDiff = normalTimeDiff + " Normal Rate, " + overtimeDiff + " Overtime Rate";
      }
      else {

        payrollItem.payableItem.timeDiff = payrollItem.is90834Session ? getTimeFormatted(60) :
          payableItem.payableTypeID === 7 || payableItem.payableTypeID === 9 || payableItem.payableTypeID === 15 ? getTimeFormatted(payableItem.ptoMinutesUsed) :
            // Paid Time Off (int)Minimiser.Data.EnumList.PayableType.PaidTimeOff
            (payableItem.startTime && payableItem.endTime) ?
              getTimeDiff(payableItem.startTime.toString(), payableItem.endTime.toString()) : '0';
      }

      payrollItem.payableItem.timeDiffMinutes = payrollItem.is90834Session ? 60 :
        payableItem.payableTypeID === 7 || payableItem.payableTypeID === 9 || payableItem.payableTypeID === 15 ? payableItem.ptoMinutesUsed :
          (payableItem.startTime && payableItem.endTime) ?
            getTimeDiffMinutes(payableItem.startTime.toString(), payableItem.endTime.toString()) : 0;

      if (payableItem.dateSubmitted && payableItem.endTime) { //should also be based on type???
        payrollItem.payableItem.dateSubmittedDiff = ", " + getTimeDiffDays(payableItem.endTime.toString(), payableItem.dateSubmitted.toString());
      }

    });
  }

  return payrollItems;
}

export function initEditPayableItemForm(payableItem: PayableItemModel) {
  if (payableItem == null) {
    payableItem = {} as PayableItemModel;
  }
  if (typeof payableItem.id === 'undefined') { //initializing new payable Item -- not an option yet.....
    payableItem.id = 0;    
    payableItem.date = new Date();

    let startTime = new Date();
    startTime.setHours(8);
    startTime.setMinutes(0);
    startTime.setSeconds(0);
    payableItem.startTime = startTime;

    let endTime = new Date(startTime);
    endTime.setHours(11);
    payableItem.endTime = endTime;
  }

  const form = new FormGroup({
    payableItemId: new FormControl(payableItem.id),
    date: new FormControl(payableItem.date),
    dateString: new FormControl(typeof payableItem.date !== 'undefined' ? formatDate(payableItem.date, 'yyyy-MM-dd', 'en-US') : '', [Validators.required]), //add pattern    
    startTime: new FormControl(moment(payableItem.startTime).toDate()),
    endTime: new FormControl(moment(payableItem.endTime).toDate()),
    startTimeString: new FormControl(getFormattedTime(payableItem.startTime), [Validators.required]), //add pattern
    endTimeString: new FormControl(getFormattedTime(payableItem.endTime), [Validators.required]) //add pattern
  });

  return form
}
