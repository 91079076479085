import { clockInOut, getIsClockedIn, checkIP, loadOptionalPayableTypes } from './../../../../core/store/timecard/timecard.actions';
import { uploadPhoto, getPhoto, loadProfile } from './../../../../core/store/profile/profile.actions';
import { UserProfileModel } from './../../../../models/userProfileModel';
import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-profile-header',
  templateUrl: './profile-header.component.html',
  styleUrls: ['./profile-header.component.scss']
})
export class ProfileHeaderComponent extends BaseComponent implements OnInit {

  @Input() readonly: boolean;

  profile: UserProfileModel;
  profilePicture;
  isClockedIn: boolean;
  ipValid: boolean;
  clockInOutLoading: boolean;
  uploadingPhoto: boolean;
  hasClockPunchRight: boolean;
  optionalPayableTypes: SelectItem[] = [];
  payableTypeId: number = 0;

  constructor(
    private store: Store<State>) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.profileState.profile).subscribe(profile => {
        this.profile = profile;
      }),
      this.store.select(s => s.profileState.photoUrl).subscribe(photoUrl => {
        this.profilePicture = photoUrl;
      }),
      this.store.select(s => s.timecardState.isClockledIn).subscribe(isClockedIn => {
        this.isClockedIn = isClockedIn;
        if (!isClockedIn) {
          this.payableTypeId = 0; //reset optional payable type
        }
      }),
      this.store.select(s => s.timecardState.ipValid).subscribe(ipValid => {
        this.ipValid = ipValid;
      }),
      this.store.select(s => s.timecardState.clockInOutLoading).subscribe(clockInOutLoading => {
        this.clockInOutLoading = clockInOutLoading;
      }),
      this.store.select(s => s.profileState.uploadingPhoto).subscribe(uploadingPhoto => {
        this.uploadingPhoto = uploadingPhoto;
      }),
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.hasClockPunchRight = permissionIDs && !!permissionIDs.find(p => p === 157);
      }),
      this.store.select(s => s.timecardState.optionalPayableTypes).subscribe(optionalTypes => {
        if (optionalTypes && optionalTypes.length > 0) {
          this.optionalPayableTypes = optionalTypes;
        }
      }),
    );

    this.store.dispatch(loadProfile());
    this.store.dispatch(getIsClockedIn());
    this.store.dispatch(checkIP());
    this.store.dispatch(getPhoto());
    this.store.dispatch(loadOptionalPayableTypes());
  }


  onFileChange(event: any) {
    if (event.target.files) {
      const file = event.target.files[0];
      this.store.dispatch(uploadPhoto({ file }));
    }
  }

  activateFileInput(fileInput) {
    fileInput.click();
  }


  clockInOut() {
    this.store.dispatch(clockInOut({payableTypeId: this.payableTypeId}));
  }
}

