import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Actions } from '@ngrx/effects';
import { State } from '../../../../core/store';
import { Store } from '@ngrx/store';
import { AlertService } from 'src/app/shared/services/alert.service';
import { SelectItem } from 'primeng/api';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { EquipmentTypePermissionModel } from 'src/app/models/equipmentTypePermissionModel';
import { loadEquipmentPermissionModalOptions, loadEquipmentPermissions, saveEquipmentPermissions } from 'src/app/core/store/equipment/equipment.actions';
import { EquipmentPermissionsModel } from 'src/app/models/equipmentTypePermissionModel';
import { deepClone } from 'src/app/helpers/utils';

@Component({
  selector: 'app-equipment-type-permissions',
  templateUrl: './equipment-type-permissions.component.html',
  styleUrls: ['./equipment-type-permissions.component.scss']
})
export class EquipmentTypePermissionsComponent extends BaseComponent implements OnInit {
    @Output() myEvent = new EventEmitter<string>();

  loading: boolean = false;
  userList: SelectItem[];
  roleList: SelectItem[];
  equipmentTypePermissions: EquipmentTypePermissionModel[];
  closeMod: boolean = false;

  assignmentTypeOptions = [
    { label: 'Role', value: 1 },
    { label: 'User', value: 2 },
  ];
  assignmentType: number = 1;

  assignUserId: number;
  assignRoleId: number;

  showPermissiontypes: boolean = false;

  constructor(
    private actions$: Actions,
    private alertService: AlertService,
    private store: Store<State>,
    private router: Router,
  ) {
    super();
   }

  ngOnInit(): void {
    this.showPermissiontypes = false;
    this.subs.push(
    this.store.select(s => s.usersState.usersList).subscribe(usersList => {
      let users = usersList && usersList.length > 0 ? usersList.map(u => ({ label: u.firstName + ' ' + u.lastName, value: u.id }))
        : [{ label: 'loading...', value: undefined }];
      users.unshift({ label: 'All', value: 0 });

      this.userList = users;
    }),
    this.store.select(s => s.equipmentState.equipmentPermissionOptions).subscribe(options => {
      if(options){
        let roles = options.roles && options.roles.length > 0 ? options.roles.map(r => ({label: r.label, value: r.value})) : [{ label: 'loading...', value: undefined }];
        roles.unshift({
          label: "Select Role",
          value: 0 
        })
        this.roleList = roles;
      }
    }),
    this.store.select(s => s.equipmentState.equipmentPermissions).subscribe(p => {
      if(p){
        let permissions =  p.length > 0 ? p.map(r => ({id: r.id, type: r.type, selected: r.selected})) : [{id: 0, type: "Loading", selected: false}];
        this.equipmentTypePermissions = permissions;
        this.showPermissiontypes = true;
      }
      else{
        this.showPermissiontypes = false;
      }
    })
    )
  }

  loadEquipmentPermissions(userId: number, roleId: number){
    if (userId != null || roleId != null){
      this.store.dispatch(loadEquipmentPermissions({userId: userId, roleId: roleId}));
    }
    console.log(`User ID: ${userId} --- RoleID: ${roleId}`)
  }

  changeAssignmentType(){
    this.assignRoleId = null;
    this.assignUserId = null;
  }

  saveEquipmentPermissions(){
    let permissions = {
      userId: this.assignUserId,
      roleId: this.assignRoleId,
      permissions: this.equipmentTypePermissions
    }
    this.store.dispatch(saveEquipmentPermissions({equipmentPermissions: permissions}));
  }

  changePermissionSelections(opt){
    let newArr;
    newArr = deepClone(this.equipmentTypePermissions).map(x => {
      x.selected = opt == "All" ? true : false;
      return x;
    })
    this.equipmentTypePermissions = newArr;
  }

  closeModal(){
    this.equipmentTypePermissions = [];
    this.assignRoleId = null;
    this.assignUserId = null;
    this.showPermissiontypes = false;
    this.myEvent.emit('eventDesc');
  }
}
