<form class="card mt-2">
  <div class="card-body">
      <h2 class="mb-3">Surveys to answer</h2>
    <i *ngIf="loadingMySurvey" class="material-icons rotate text-muted">loop</i>
    <p-table  [value]="mySurveys" [responsive]="true"
    [paginator]="true" [rows]="20" [totalRecords]="mySurveys.length" [lazy]="false"
   >
        <ng-template pTemplate="header">
            <tr>
                <th width="90px"></th>
                <th  [pSortableColumn]="'SurveyType.Name'">Survey name <p-sortIcon [field]="'SurveyType.Name'"></p-sortIcon></th>
                <th width="150px" [pSortableColumn]="'CreatedDate'">Assigned Date <p-sortIcon [field]="'CreatedDate'"></p-sortIcon></th>


            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-survey>
            <tr >
                <td>
                  <button class="btn btn-secondary mr-2"  (click)="viewSurvey(survey)" title="Answer Survey">
                      <i class="fas fa-star"></i></button>
                </td>
                <td>
                    <span class="ui-column-title">Type</span>
                    {{survey.surveyType.name}}</td>

                <td>
                  <span class="ui-column-title">Assigned Date</span>
                  {{survey.createdDate | date:'shortDate' }}
                </td>
            </tr>
        </ng-template>
    </p-table>
  </div>
</form>
