import { SelfCareQuestionModel } from './../../../../models/selfcareModels';
import { loadSelfcareCategory, loadSelfcarePreferences, saveSelfcareAnwers } from './../../../../core/store/selfcare/selfcare.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { SurveyModel, SurveyFullAnswerModel } from './../../../../models/surveyModel';
import { Component, OnInit, AfterViewInit, HostListener, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { Actions, ofType } from '@ngrx/effects';
import { AlertService } from '../../../../shared/services/alert.service';
import { SelfCareCategoryModel } from 'src/app/models/selfcareModels';
import { DomSanitizer } from '@angular/platform-browser';
import { deepClone, downloadFile } from 'src/app/helpers/utils';
import { downloadOnboardingUpload } from 'src/app/core/store/onboarding/onboarding.actions';
import { GeneralSettingsModel } from 'src/app/models/generalSettingsModel';
@Component({
  selector: 'app-selfcare-category',
  templateUrl: './selfcare-category.component.html',
  styleUrls: ['../selfcare.component.scss']
})
export class SelfcareCategoryComponent extends BaseComponent implements OnInit, OnDestroy {

  survey: SurveyModel;
  fullAnswers: SurveyFullAnswerModel;
  savingAnswer: boolean;
  loading: boolean;
  surveyQuestionsMap;
  iFrameWidth = 680;
  iFrameHeight = 417;
  categoryID: number;
  category: SelfCareCategoryModel;
  intervalAutoSave: any;
  sanitizedSlideshowLink;

  isPreferences = false;

  userID;
  loadingDoc;
  generalSettings: GeneralSettingsModel;

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private sanitizer: DomSanitizer) {
    super();
  }

  ngOnInit() {
    this.checkSize(window.innerWidth);
    this.route.params.subscribe(params => {
      if (params.userID) {
        this.userID = Number(params.userID);
      } else {
        this.userID = null;
      }

      const categoryIDParam = params.categoryID;
      if (categoryIDParam) {
        if (categoryIDParam === 'preferences') {
          this.isPreferences = true;
          this.store.dispatch(loadSelfcarePreferences({ userID: this.userID }));
        } else {
          this.categoryID = Number(categoryIDParam);
          this.category = null;
          this.isPreferences = false;
          this.store.dispatch(loadSelfcareCategory({ categoryID: this.categoryID, userID: this.userID }));
        }

      } else if (this.router.url.indexOf('preferences') >= 0) {
        this.isPreferences = true;
        this.store.dispatch(loadSelfcarePreferences({ userID: this.userID }));
      }
    });

    this.subs.push(
      this.store.select(s => s.accountState.generalSettings).subscribe(generalSettings => {
        if (generalSettings) {
          this.generalSettings = generalSettings;
        }
      }),
      this.store.select(s => s.selfcareState.category).subscribe(category => {
        if (category) {
          if (!this.category || category.slideshowLink !== this.category.slideshowLink) {
            this.sanitizedSlideshowLink = this.sanitizer.bypassSecurityTrustResourceUrl(category.slideshowLink);
          }
          this.category = { ...deepClone(category) };
          if (this.category.categoryName.toLocaleLowerCase() === 'preferences') {
            this.isPreferences = true;
          }
          this.categoryID = this.category.categoryID;
          this.category.questions = deepClone(category.questions);
          if (this.isPreferences) {
            this.category.questions.forEach(question => {
              question.isPublic = true;
            });
          }
          this.initTextareas();
        }
      }),
      this.store.select(s => s.selfcareState.loading).subscribe(loading => {
        this.loading = loading;
      }),
      this.store.select(s => s.onboardingState.loadingDoc).subscribe(loadingDoc => {
        this.loadingDoc = loadingDoc;
      }),
    );
    this.startAutosave();

  }
  save() {
    if (!this.isReadOnly()) {
      this.store.dispatch(saveSelfcareAnwers({ answers: deepClone(this.category.questions) }));
    }
  }


  prev() {
    let nextCategory = this.categoryID - 1;
    if (nextCategory < 1) {
      nextCategory = 8;
    }
    this.save();

    if (this.userID) {
      this.router.navigate(['/selfcare/oversight/' + this.userID + '/category/' + nextCategory]);
    } else {
      this.router.navigate(['/selfcare/category/' + nextCategory]);
    }
  }

  next() {
    let nextCategory = this.categoryID + 1;
    if (nextCategory > 8) {
      nextCategory = 1;
    }
    this.save();

    if (this.userID) {
      this.router.navigate(['/selfcare/oversight/' + this.userID + '/category/' + nextCategory]);
    } else {
      this.router.navigate(['/selfcare/category/' + nextCategory]);
    }
  }

  planner() {
    this.save();
    if (this.userID) {
      this.router.navigate(['/selfcare/oversight/' + this.userID + '/planner']);
    } else {
      this.router.navigate(['/selfcare/planner']);
    }
  }

  savePreferences() {
    this.save();
    if (this.userID) {
      this.router.navigate(['/selfcare/oversight/' + this.userID + '/main']);
    } else {
      this.router.navigate(['/selfcare']);
    }
  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkSize(event.target.innerWidth);

  }

  checkSize(width) {
    if (width < 830) {
      this.iFrameWidth = width - 200;
      this.iFrameHeight = 350;
    } else {
      this.iFrameWidth = 680;
      this.iFrameHeight = 417;
    }
  }


  open(url) {
    window.open(url);
  }

  willChangeIsPublic(newValue: boolean, questionID: number) {
    if (newValue) {
      this.alertService.confirm(`Making answer public`,
        `You've chosen to share this answer content.  If shared, this information will be visible to others within the company.
      Was that your intention?`)
        .subscribe(
          answer => {
            if (answer) {
              setTimeout(() => {
                this.alertService.confirm(`Making answer public`,
                  `Please confirm that you want to share this personal content with others within the company.
              Are you sure you want to do this?`)
                  .subscribe(
                    answer2 => {
                      const question = this.category.questions.find(x => x.questionID === questionID);
                      if (answer2) {
                        question.isPublic = true;
                      } else {
                        question.isPublic = false;
                      }
                    }, error => {
                      const question = this.category.questions.find(x => x.questionID === questionID);
                      question.isPublic = false;
                    }
                  );
              }, 150);
            } else {
              const question = this.category.questions.find(x => x.questionID === questionID);
              question.isPublic = false;
            }
          }, error => {
            const question = this.category.questions.find(x => x.questionID === questionID);
            question.isPublic = false;
          }
        );
    } else {
      const question = this.category.questions.find(x => x.questionID === questionID);
      question.isPublic = false;
    }
  }

  startAutosave() {
    if (!this.isReadOnly()) {
      if (this.intervalAutoSave) {
        clearInterval(this.intervalAutoSave);
      }
      this.intervalAutoSave = setInterval(() => {
        if (!this.savingAnswer && this.category) {
          this.save();
        }
      }, 60000);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.intervalAutoSave) {
      clearInterval(this.intervalAutoSave);
    }
  }

  isReadOnly() {
    return !!this.userID;
  }


  changeMultiSelectAnswer(value: string[], question: SelfCareQuestionModel) {
    question.answerArray = value;
    question.answer = question.answerArray.filter(x => x !== 'Other').join(',');
    if (question.answerArray.some(x => x === 'Other')) {
      question.answer += ',' + question.answerSelected;
    }
  }
  checkShowOtherMultiSelect(question: SelfCareQuestionModel) {
    return (question.questionType === 2 && question.answerArray && question.answerArray.some(x => x === 'Other'));
  }

  changedAnswerSelectedMulti(value: string, question: SelfCareQuestionModel) {
    question.answer = question.answerArray.filter(x => x !== 'Other').join(',');
    if (question.answerArray.some(x => x === 'Other')) {
      question.answer += ',' + value;
      question.answerSelected = value;
    }
  }

  changedAnswerSelected(value: string, question: SelfCareQuestionModel) {
    if (value === 'Other') {
      question.answerSelected = value;
      question.answer = '';
    } else {
      question.answerSelected = value;
      question.answer = value;
    }
  }

  initTextareas() {
    setTimeout(() => {
      const textAreas = document.getElementsByTagName('textarea');
      for (let i = 0; i < textAreas.length; i++) {
        this.changedTextArea(textAreas[i]);
      }
    }, 200);
  }
  changedTextArea(textarea) {
    console.log(textarea);

    textarea.style.height = '';
    textarea.style.height = textarea.scrollHeight + 'px';
  }

  openDoc(onboarding_UploadDocID: number, fileType: string, fileName: string) {
    this.store.dispatch(downloadOnboardingUpload({ onboardingUploadFileId: onboarding_UploadDocID }));
    const subDocBuffer = this.store.select(s => s.onboardingState.docArrayBuffer).subscribe(docArrayBuffer => {
      if (docArrayBuffer) {
        downloadFile(new Blob([docArrayBuffer], { type: fileType }), fileName);
        subDocBuffer.unsubscribe();
      }
    });
  }

}


