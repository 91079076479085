

<div class="card mb-2">
  <div class="card-body">
    <div class="row">
      <div class="col-12 col-xl-8 offset-xl-2">
        <div class="float-right">

          <!--<button class="benefit-btn  ml-2 " *ngIf="!profile?.benefitsEnrollmentPending && isDuringOpenEnrollment()"  (click)="clickEdit()">Edit</button>-->
          <button class="benefit-btn  ml-2 " *ngIf="hasAnyDoc && editClicked && hasUpdatedProfile()" routerLink="/benefits-enrollment/docs/list">
            See enrollment forms
            <span class="badge badge-danger" *ngIf="notSignedDocs>0">{{notSignedDocs}}</span>
          </button>
        </div>

        <h2 class="benefit-headers-font">Benefits Enrollment</h2>

        <div class="w-100 text-center" *ngIf="loadingBenefitPlanEnrollmentSetList">
          <i class="material-icons rotate text-muted">loop</i>
        </div>
        <div class=" mb-2" *ngIf="editClicked">
          <div class="planner-panel">
            <h2 class="benefit-headers-font">Personal Information</h2>
            <div class="description">Verify all your personal information and save it before proceeding with the benefits enrollment.</div>
            <button class="benefit-btn" routerLink="/profile" [queryParams]="{backTo: '/benefits-enrollment/enrollment'}">Verify personal information</button>

          </div>
        </div>

        <app-dependents [readOnly]="!editClicked || !hasUpdatedProfile()"></app-dependents>

      </div>
    </div>
  </div>
</div>


<div class="card mb-2" *ngIf="openEnrollmentBenefitPlanEnrollmentSet && !loadingBenefitPlanEnrollmentSetList">
  <div class="card-body">
    <div class="row">
      <div class="col-12 col-xl-8 offset-xl-2">
        <app-benefits-enrollment-set 
                                     [isOpenEnrollment]="isDuringOpenEnrollment()"
                                     [availableBenefitPlans]="availableBenefitPlans"
                                     [notSignedDocs]="notSignedDocs"
                                     [(editClicked)]="editClicked"
                                     [hasUpdatedProfile]="hasUpdatedProfile()"          
                                     [previousBenefitPlanEnrollmentSet]="currentBenefitPlanEnrollmentSet"
                                     [benefitPlanEnrollmentSet]="openEnrollmentBenefitPlanEnrollmentSet"></app-benefits-enrollment-set>
      </div>
    </div>
  </div>
</div>
<div class="card mb-2" *ngIf="currentBenefitPlanEnrollmentSet && !loadingBenefitPlanEnrollmentSetList">
  <div class="card-body">
    <div class="row">
      <div class="col-12 col-xl-8 offset-xl-2">
        <app-benefits-enrollment-set 
                                     [isOpenEnrollment]="false"
                                     [isNewHire]="isNewHire()"
                                     [(editClicked)]="editClicked"
                                     [notSignedDocs]="notSignedDocs"
                                     [hasUpdatedProfile]="hasUpdatedProfile()"
                                     [availableBenefitPlans]="availableBenefitPlans"
                                     [previousBenefitPlanEnrollmentSet]="null"
                                     [benefitPlanEnrollmentSet]="currentBenefitPlanEnrollmentSet"></app-benefits-enrollment-set>
      </div>
    </div>
  </div>
</div>
