import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { InternService } from '../../services/intern.service';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { errorHappened } from '../profile/profile.actions';
import {
  createInternshipApplication, createInternshipApplicationSuccess, getManageApplicationsList, getManageApplicationsListSuccess, getMyApplicationsList, getMyApplicationsListSuccess,
  loadInternForms, loadInternFormsSuccess, loadInternshipStatuses, loadInternshipStatusesSuccess, changeApplicationStatus, changeApplicationStatusSuccess, createInternshipApplicationFailure,
  exportManageInternApplicationsListToExcelSuccess, exportManageInternApplicationsListToExcelFailure, exportManageInternApplicationsListToExcel, getInternActionTypeListSuccess, getInternActionTypeList,
  saveInternActionType, saveInternActionTypeSuccess, saveInternActionTypeFail, getInternActionType, getInternActionTypeSuccess, getInternActionTypeFailure, deleteInternActionType, deleteInternActionTypeSuccess,
  deleteInternActionTypeFail, getInternApplication, getInternApplicationSuccess, getInternApplicationFailure, getInternActionItem, getInternActionItemSuccess, getInternActionItemFailure, saveInternActionItem,
  saveInternActionItemSuccess, saveInternActionItemFail, deleteInternActionItem, deleteInternActionItemSuccess, deleteInternActionItemFail, loadInternshipActionTypes, loadInternshipActionTypesSuccess,
  getInternUploads, getInternUploadsSuccess, getInternUploadsFailure, saveInternUploads, saveInternUploadsSuccess, saveInternUploadsFail, deleteInternUpload, deleteInternUploadSuccess,
  deleteInternUploadFail, getInternApplicationUpload, getInternApplicationUploadSuccess, getInternApplicationUploadFailure, updateHourAndProductivityCalculations, updateHourAndProductivityCalculationsSuccess,
  changeApplicationStatusFail, updateHourAndProductivityCalculationsFail, loadInternshipSubstatuses, loadInternshipSubstatusesSuccess, changeApplicationSubstatus, changeApplicationSubstatusSuccess,
  changeApplicationSubstatusFail, getInternshipSubstatus, getInternshipSubstatusList, getInternshipSubstatusListSuccess, saveInternshipSubstatus, saveInternshipSubstatusSuccess, saveInternshipSubstatusFail,
  deleteInternshipSubstatus, deleteInternshipSubstatusSuccess, deleteInternshipSubstatusFail, getInternshipSubstatusSuccess, getInternshipSubstatusFailure, getInternActionItemTemplateList, getInternActionItemTemplateListSuccess,
  saveInternActionItemTemplate, saveInternActionItemTemplateSuccess, saveInternActionItemTemplateFail, deleteInternActionItemTemplate, deleteInternActionItemTemplateSuccess, deleteInternActionItemTemplateFail,
  getInternActionItemTemplate, getInternActionItemTemplateSuccess, getInternActionItemTemplateFailure, saveInternApplicationReview, saveInternApplicationReviewSuccess, saveInternApplicationReviewFail, getInternApplicationReviewForms,
  getInternApplicationReviewFormsSuccess, getInternApplicationReviewFormsFailure, createInternship, createInternshipSuccess, createInternshipFail, loadInternApplicationDocToSign, loadInternApplicationDocToSignFail, loadInternApplicationDocToSignSuccess
} from './intern.action';
import { InternApplicationActionModel, InternApplicationActionTemplateModel, InternApplicationActionTypeModel } from '../../../models/internModels';
import { Store } from '@ngrx/store';
import { State } from '..';


@Injectable()
export class InternEffects {
  constructor(private actions$: Actions,
    private internService: InternService,
    private store: Store<State>
  ) { }

  loadInternForms = createEffect(() => this.actions$.pipe(
    ofType(loadInternForms),
    switchMap(action => this.internService.getInternForms().pipe(
      map(forms => loadInternFormsSuccess({ forms })),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))));

  loadInternshipStatuses = createEffect(() => this.actions$.pipe(
    ofType(loadInternshipStatuses),
    switchMap(action => this.internService.getInternshipStatuses().pipe(
      map(statuses => loadInternshipStatusesSuccess({ statuses })),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))));

  loadInternshipSubstatuses = createEffect(() => this.actions$.pipe(
    ofType(loadInternshipSubstatuses),
    switchMap(action => this.internService.getInternshipSubstatuses().pipe(
      map(statuses => loadInternshipSubstatusesSuccess({ statuses })),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))));

  loadInternshipActionTypes = createEffect(() => this.actions$.pipe(
    ofType(loadInternshipActionTypes),
    switchMap(action => this.internService.getInternshipActionTypes().pipe(
      map(actionTypes => loadInternshipActionTypesSuccess({ actionTypes })),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))));

  getMyApplicationsList = createEffect(() => this.actions$.pipe(
    ofType(getMyApplicationsList),
    switchMap(action => {
      return this.internService.getMyApplicationsList(action.filter).pipe(
        map(list => getMyApplicationsListSuccess({ list })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  getManageApplicationsList = createEffect(() => this.actions$.pipe(
    ofType(getManageApplicationsList),
    switchMap(action => {
      return this.internService.getManageApplicationsList(action.filter).pipe(
        map(list => getManageApplicationsListSuccess({ list })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  getInternApplicationActionTypeList = createEffect(() => this.actions$.pipe(
    ofType(getInternActionTypeList),
    switchMap(action => {
      return this.internService.getInternApplicationActionTypeList(action.filter).pipe(
        map(list => getInternActionTypeListSuccess({ list })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  getInternshipSubstatusList = createEffect(() => this.actions$.pipe(
    ofType(getInternshipSubstatusList),
    switchMap(action => {
      return this.internService.getInternshipSubstatusList(action.filter).pipe(
        map(list => getInternshipSubstatusListSuccess({ list })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  getInternActionItemTemplateList = createEffect(() => this.actions$.pipe(
    ofType(getInternActionItemTemplateList),
    switchMap(action => {
      return this.internService.getInternActionItemTemplateList(action.filter).pipe(
        map(list => getInternActionItemTemplateListSuccess({ list })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  createInternshipApplication = createEffect(() => this.actions$.pipe(
    ofType(createInternshipApplication),
    switchMap(action => {
      return this.internService.createInternshipApplication(action.formId).pipe(
        map(formSubmission => createInternshipApplicationSuccess({ formSubmission })),
        catchError(err => {
          this.store.dispatch(createInternshipApplicationFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  changeApplicationStatus = createEffect(() => this.actions$.pipe(
    ofType(changeApplicationStatus),
    switchMap(action => {
      return this.internService.changeApplicationStatus(action.applicationId, action.statusId).pipe(
        map(application => changeApplicationStatusSuccess()),
        catchError(err => {
          this.store.dispatch(changeApplicationStatusFail(err));
          return of(errorHappened({ err }));
        }));
    })
  ));

  changeApplicationSubstatus = createEffect(() => this.actions$.pipe(
    ofType(changeApplicationSubstatus),
    switchMap(action => {
      return this.internService.changeApplicationSubstatus(action.applicationId, action.substatusId).pipe(
        map(application => changeApplicationSubstatusSuccess()),
        catchError(err => {
          this.store.dispatch(changeApplicationSubstatusFail(err));
          return of(errorHappened({ err }));
        }));
    })
  ));

  updateHourAndProductivityCalculations = createEffect(() => this.actions$.pipe(
    ofType(updateHourAndProductivityCalculations),
    switchMap(action => {
      return this.internService.updateHourAndProductivityCalculations(action.applicationId).pipe(
        map(application => updateHourAndProductivityCalculationsSuccess()),
        catchError(err => {
          this.store.dispatch(updateHourAndProductivityCalculationsFail(err));
          return of(errorHappened({ err }));
        }));
    })
  ));

  exportManageInternApplicationsListToExcel = createEffect(() => this.actions$.pipe(
    ofType(exportManageInternApplicationsListToExcel),
    switchMap(action => {
      return this.internService.exportManageInternApplicationsListToExcel(action.filter).pipe(
        map(doc => exportManageInternApplicationsListToExcelSuccess({ doc })),
        catchError(err => {
          this.store.dispatch(exportManageInternApplicationsListToExcelFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  saveInternActionType = createEffect(() => this.actions$.pipe(
    ofType(saveInternActionType),
    switchMap(action => {
      let observable: Observable<InternApplicationActionTypeModel>;

      if (action.actionType.actionTypeId > 0) {
        observable = this.internService.editInternActionType(action.actionType);
      }
      else {
        observable = this.internService.addInternActionType(action.actionType);
      }

      return observable.pipe(
        map(actionType => saveInternActionTypeSuccess()),
        catchError(err => {
          this.store.dispatch(saveInternActionTypeFail({ err }));
          return of(errorHappened({ err }));
        }));
    })));

  deleteInternActionType = createEffect(() => this.actions$.pipe(
    ofType(deleteInternActionType),
    switchMap(action =>
      this.internService.deleteInternActionType(action.actionTypeId).pipe(
        map(actionType => deleteInternActionTypeSuccess()),
        catchError(err => {
          this.store.dispatch(deleteInternActionTypeFail({ err }));
          return of(errorHappened({ err }));
        }))
    )));

  getInternActionType = createEffect(() => this.actions$.pipe(
    ofType(getInternActionType),
    switchMap(action => {
      return this.internService.getInternActionType(action.actionTypeId).pipe(
        map(actionType => getInternActionTypeSuccess({ actionType })),
        catchError(err => {
          this.store.dispatch(getInternActionTypeFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  getInternApplication = createEffect(() => this.actions$.pipe(
    ofType(getInternApplication),
    switchMap(action => {
      return this.internService.getInternApplication(action.applicationId).pipe(
        map(application => getInternApplicationSuccess({ application })),
        catchError(err => {
          this.store.dispatch(getInternApplicationFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  getInternApplicationReviewForms = createEffect(() => this.actions$.pipe(
    ofType(getInternApplicationReviewForms),
    switchMap(action => {
      return this.internService.getInternApplicationReviewForms(action.applicationId).pipe(
        map(forms => getInternApplicationReviewFormsSuccess({ forms })),
        catchError(err => {
          this.store.dispatch(getInternApplicationReviewFormsFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  getInternActionItem = createEffect(() => this.actions$.pipe(
    ofType(getInternActionItem),
    switchMap(action => {
      return this.internService.getInternAction(action.actionId).pipe(
        map(actionItem => getInternActionItemSuccess({ actionItem })),
        catchError(err => {
          this.store.dispatch(getInternActionItemFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  saveInternActionItem = createEffect(() => this.actions$.pipe(
    ofType(saveInternActionItem),
    switchMap(action => {
      let observable: Observable<InternApplicationActionModel[]>;

      if (action.actionItem.actionId > 0) {
        observable = this.internService.editInternAction(action.actionItem);
      }
      else {
        observable = this.internService.addInternAction(action.actionItem);
      }

      return observable.pipe(
        map(actionItems => saveInternActionItemSuccess({ actionItems })),
        catchError(err => {
          this.store.dispatch(saveInternActionItemFail({ err }));
          return of(errorHappened({ err }));
        }));
    })));

  deleteInternActionItem = createEffect(() => this.actions$.pipe(
    ofType(deleteInternActionItem),
    switchMap(action =>
      this.internService.deleteInternAction(action.actionId).pipe(
        map(actionItems => deleteInternActionItemSuccess({actionItems})),
        catchError(err => {
          this.store.dispatch(deleteInternActionItemFail({ err }));
          return of(errorHappened({ err }));
        }))
    )));

  getInternUploads = createEffect(() => this.actions$.pipe(
    ofType(getInternUploads),
    switchMap(action => {
      return this.internService.getInternUploads(action.applicationId).pipe(
        map(uploads => getInternUploadsSuccess({ uploads })),
        catchError(err => {
          this.store.dispatch(getInternUploadsFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  saveInternUploads = createEffect(() => this.actions$.pipe(
    ofType(saveInternUploads),
    switchMap(action => {      
      return this.internService.editInternUploads(action.uploads).pipe(
        map(uploads => saveInternUploadsSuccess({ uploads })),
        catchError(err => {
          this.store.dispatch(saveInternUploadsFail ({ err }));
          return of(errorHappened({ err }));
        }));
    })));

  deleteInternUpload = createEffect(() => this.actions$.pipe(
    ofType(deleteInternUpload),
    switchMap(action =>
      this.internService.deleteUploadedFile(action.uploadId).pipe(
        map(uploads => deleteInternUploadSuccess({uploads})),
        catchError(err => {
          this.store.dispatch(deleteInternUploadFail({ err }));
          return of(errorHappened({ err }));
        }))
    )));

  getInternUploadedFile = createEffect(() => this.actions$.pipe(
    ofType(getInternApplicationUpload),
    switchMap(action => {
      return this.internService.getInternApplicationUpload(action.uploadId).pipe(
        map(doc => getInternApplicationUploadSuccess({ doc })),
        catchError(err => {
          this.store.dispatch(getInternApplicationUploadFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));



  saveInternshipSubstatus = createEffect(() => this.actions$.pipe(
    ofType(saveInternshipSubstatus),
    switchMap(action => {
      let observable: Observable<InternApplicationActionTypeModel>;

      if (action.substatus.id > 0) {
        observable = this.internService.editInternshipSubstatus(action.substatus);
      }
      else {
        observable = this.internService.addInternshipSubstatus(action.substatus);
      }

      return observable.pipe(
        map(substatus => saveInternshipSubstatusSuccess()),
        catchError(err => {
          this.store.dispatch(saveInternshipSubstatusFail({ err }));
          return of(errorHappened({ err }));
        }));
    })));

  deleteInternshipSubstatus = createEffect(() => this.actions$.pipe(
    ofType(deleteInternshipSubstatus),
    switchMap(action =>
      this.internService.deleteInternshipSubstatus(action.substatusId).pipe(
        map(substatus => deleteInternshipSubstatusSuccess()),
        catchError(err => {
          this.store.dispatch(deleteInternshipSubstatusFail({ err }));
          return of(errorHappened({ err }));
        }))
    )));

  getInternshipStatus = createEffect(() => this.actions$.pipe(
    ofType(getInternshipSubstatus),
    switchMap(action => {
      return this.internService.getInternshipSubstatus(action.substatusId).pipe(
        map(substatus => getInternshipSubstatusSuccess({ substatus })),
        catchError(err => {
          this.store.dispatch(getInternshipSubstatusFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));



  saveInternActionItemTemplate = createEffect(() => this.actions$.pipe(
    ofType(saveInternActionItemTemplate),
    switchMap(action => {
      let observable: Observable<InternApplicationActionTemplateModel>;

      if (action.template.actionTemplateId > 0) {
        observable = this.internService.editInternActionItemTemplate(action.template);
      }
      else {
        observable = this.internService.addInternActionItemTemplate(action.template);
      }

      return observable.pipe(
        map(template => saveInternActionItemTemplateSuccess()),
        catchError(err => {
          this.store.dispatch(saveInternActionItemTemplateFail({ err }));
          return of(errorHappened({ err }));
        }));
    })));

  deleteInternActionItemTemplate = createEffect(() => this.actions$.pipe(
    ofType(deleteInternActionItemTemplate),
    switchMap(action =>
      this.internService.deleteInternActionItemTemplate(action.templateId).pipe(
        map(template => deleteInternActionItemTemplateSuccess()),
        catchError(err => {
          this.store.dispatch(deleteInternActionItemTemplateFail({ err }));
          return of(errorHappened({ err }));
        }))
    )));

  getInternActionItemTemplate = createEffect(() => this.actions$.pipe(
    ofType(getInternActionItemTemplate),
    switchMap(action => {
      return this.internService.getInternActionItemTemplate(action.templateId).pipe(
        map(template => getInternActionItemTemplateSuccess({ template })),
        catchError(err => {
          this.store.dispatch(getInternActionItemTemplateFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));


  saveInternApplicationReview = createEffect(() => this.actions$.pipe(
    ofType(saveInternApplicationReview),
    switchMap(action => {
      return this.internService.saveApplicationReview(action.application).pipe(
        map(application => saveInternApplicationReviewSuccess()),
        catchError(err => {
          this.store.dispatch(saveInternApplicationReviewFail({ err }));
          return of(errorHappened({ err }));
        }));
    })));

  createInternship = createEffect(() => this.actions$.pipe(
    ofType(createInternship),
    switchMap(action => {
      return this.internService.createInternship(action.applicationId).pipe(
        map(application => createInternshipSuccess()),
        catchError(err => {
          this.store.dispatch(createInternshipFail(err));
          return of(errorHappened({ err }));
        }));
    })
  ));

  loadInternApplicationDocToSign = createEffect(() => this.actions$.pipe(
    ofType(loadInternApplicationDocToSign),
    switchMap(action => {
      return this.internService.getInternApplicationDocToSign(action.documentToSignId).pipe(
        map(doc => loadInternApplicationDocToSignSuccess({ doc })),
        catchError(err => {
          this.store.dispatch(loadInternApplicationDocToSignFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));
}
