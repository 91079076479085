import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { SelectItem } from 'primeng/api';
import { getManagePtoEarnedList, loadViewableCampuses, savePtoEarnedSuccess, showEditPtoEarned } from '../../../../core/store/timeoff/timeoff.actions';
import { formatDate } from '@angular/common';
import { deepClone, getDateWithOffset } from '../../../../helpers/utils';
import { PtoEarnedModel } from '../../../../models/timeoffModel';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { tap } from 'rxjs/operators';
import { loadEmployeeUsers } from '../../../../core/store/employee/employee.action';

@Component({
  selector: 'app-list-pto-manage',
  templateUrl: './list-pto-manage.component.html',
  styleUrls: ['./list-pto-manage.component.scss']
})
export class ListPtoManageComponent extends BaseComponent implements OnInit {
  filter: any;
  ptoEarnedList: PagingResultsModel<PtoEarnedModel>;

  loadingList: boolean = true;
  canEdit: boolean = false;
  canViewSchedulingTimeOff: boolean = false;

  campusList: SelectItem[];
  campusId: number = 0;

  prefixText: string;
  userList: SelectItem[];
  userId: number = 0;

  startDate: string = formatDate(getDateWithOffset(-13), 'yyyy-MM-dd', 'en-US');
  endDate: string = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');

  constructor(
    private actions$: Actions,
    private store: Store<State>) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.timeoffState.loadingManagePtoEarnedList).subscribe(loadingList => {
        this.loadingList = loadingList;
      }),
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.canEdit = permissionIDs && !!permissionIDs.find(p => p === 3103);
        this.canViewSchedulingTimeOff = permissionIDs && !!permissionIDs.find(p => p === 186);
      }),
      this.store.select(s => s.timeoffState.campuses).subscribe(campuses => {
        this.campusList = campuses && campuses.length > 0 ? campuses
          : [{ label: 'loading...', value: undefined }];
      }),
      this.store.select(s => s.employeeState.employeeUsers).subscribe(users => {
        this.userList = users;
      }),
      this.store.select(s => s.timeoffState.managePtoEarnedList).subscribe(ptoList => {
        this.ptoEarnedList = ptoList;        
      }),
      this.actions$.pipe(
        ofType(savePtoEarnedSuccess),
        tap(action => {
          this.reloadList();
        })
      ).subscribe(),
    );

    this.store.dispatch(loadViewableCampuses());
  }

  reloadFilter(event) {
    this.filter = event;
    this.reloadList();
  }

  getStaffList(event) {
    this.store.dispatch(loadEmployeeUsers({
      prefixText: event.query,
      programId: this.campusId,
      payrollClassificationId: 0,
      showInactive: true,
      showTerminated: true
    }));
  }

  reloadListAfterStaffChage(selectedUser) {
    if (typeof selectedUser.value !== 'number') {
      return;
    }

    this.userId = selectedUser.value;
    this.reloadList();
  }

  reloadListAfterClear(event) {
    if (event.data !== null) {
      return;
    }

    this.userId = 0;
    this.reloadList();
  }  

  reloadList() {
    this.filter = deepClone(this.filter);

    this.filter.filters.userId = { Name: 'userId', Value: { value: this.userId.toString() } };
    this.filter.filters.programId = { Name: 'programId', Value: { value: this.campusId.toString() } };
    this.filter.filters.startDate = { Name: 'startDate', Value: { value: this.startDate } };
    this.filter.filters.endDate = { Name: 'endDate', Value: { value: this.endDate } };

    this.store.dispatch(getManagePtoEarnedList({
      filter: this.filter
    }));
  }

  editPtoEarned(request) {
    this.store.dispatch(showEditPtoEarned({ ptoEarned: request }));
  }

}
