import { AssignedEquipmentModel, EquipmentModel, EquipmentAcessoryModel, EquipmentWithAssignmentsModel, EquipmentClassCodeModel, EquipmentClassificationModel, EquipmentTypeModel } from './../../../models/equipmentsModel';
import { PagingResultsModel } from './../../../models/pagingResultsModel';
import { createReducer, on, Action } from '@ngrx/store';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FormError } from 'src/app/models/utilModels';
import {
  getAssignedEquipmentList, getAssignedEquipmentListSuccess, getAssignedEquipments, getAssignedEquipmentsSuccess, updateCreateEquipmentForm,
  createEquipment, createEquipmentFail, createEquipmentSuccess, getMyEquipmentList, getMyEquipmentListSuccess,
  getMyEquipmentListFail, addEquipmentToAssign, AddReturnDate, SetReturnDate, RemoveReturnDate, removeEquipmentToAssign, searchEquipment, searchEquipmentSuccess,
  searchEquipmentFail, saveAssignEquipment, saveAssignEquipmentSuccess, saveAssignEquipmentFail,
  changedUserOnEquipmentToAssign,
  changedAssignedProgramOnEquipmentToAssign,
  clearCreateEquipmentForm,
  getEquipmentAssignPdf,
  getEquipmentAssignPdfSuccess,
  saveReturnEquipment,
  saveReturnEquipmentSuccess,
  saveReturnEquipmentFail,
  addAssigningAcessory,
  removeAssigningAcessory,
  loadUserEquipments,
  loadUserEquipmentsFail,
  loadUserEquipmentsSuccess,
  changedReturnEquipment,
  clearEquipmentsToAssign,
  deleteEquipment,
  deleteEquipmentSuccess,
  deleteEquipmentFail,
  editEquipmentForm,
  loadCampusesSuccess,
  loadClassCodesSuccess,
  loadClassificationsSuccess,
  saveEditAssignEquipment,
  saveEditAssignEquipmentSuccess,
  saveEditAssignEquipmentFail,
  loadEquipmentPermissionModalOptionsSuccess,
  loadEquipmentPermissionsSuccess,
  saveEquipmentPermissionsSuccess,
  clearPermissions,
  getEquipmentTypesSuccess,
  setCreateStoredValueOther
} from './equipment.actions';
import { deepClone } from 'src/app/helpers/utils';
import { formatDate } from '@angular/common';
import { CampusModel } from '../../../models/campusModel';
export interface EquipmentState {
  loadingDoc: boolean;
  loadingSign: boolean;
  docArrayBuffer: ArrayBuffer;
  docSignedArrayBuffer: ArrayBuffer;

  assigningEquipment: boolean;
  assigningEquipments: AssignedEquipmentModel[];
  assigningAcessories: EquipmentAcessoryModel[];

  equipmentSearchResult: EquipmentModel[];
  searchingEquipments: boolean;

  classCodes: EquipmentClassCodeModel[];
  classifications: EquipmentClassificationModel[];

  savingAssignEquipments: boolean;

  savingReturnEquipments: boolean;
  assignedEquipmentsToUser: AssignedEquipmentModel[];
  returningEquipments: AssignedEquipmentModel[];

  assignedEquipmentList: PagingResultsModel<EquipmentWithAssignmentsModel>;
  loadingAssignedEquipmentList: boolean;

  assignedEquipments: PagingResultsModel<EquipmentWithAssignmentsModel>;
  loadingAssignedEquipments: boolean;

  equipment: EquipmentModel;
  creatingEquipment: boolean;
  deletingEquipment: boolean;
  equipmentFormErrors: FormError[];
  myEquipments: AssignedEquipmentModel[];
  loadingMyEquipments: boolean;

  userIDReturning: number;
  campuses: CampusModel[];

  equipmentPermissionOptions: any;
  equipmentPermissions: any;
  equipmentTypes: EquipmentTypeModel[];
}

const initialState: EquipmentState = {
  loadingDoc: false,
  loadingSign: false,
  docArrayBuffer: null,
  docSignedArrayBuffer: null,

  assigningEquipment: null,
  assigningEquipments: [],
  assigningAcessories: [],

  equipmentSearchResult: [],
  searchingEquipments: false,

  classCodes: [],
  classifications: [],

  savingAssignEquipments: false,

  savingReturnEquipments: false,
  assignedEquipmentsToUser: [],
  returningEquipments: [],

  assignedEquipmentList: null,
  loadingAssignedEquipmentList: true,

  assignedEquipments: null,
  loadingAssignedEquipments: true,

  equipment: null,
  creatingEquipment: false,
  deletingEquipment: false,
  equipmentFormErrors: [],

  myEquipments: [],
  loadingMyEquipments: false,

  userIDReturning: 0,
  campuses: [],

  equipmentPermissionOptions: null,
  equipmentPermissions: null,
  equipmentTypes: null,
};

export const equipmentReducer = createReducer(initialState,
  on(loadCampusesSuccess, (state, action) => {
    return {
      ...state,
      campuses: action.campuses,
    };
  }),
  on(loadClassCodesSuccess, (state, action) => {
    return {
      ...state,
      classCodes: action.classCode,
    };
  }),
  on(loadClassificationsSuccess, (state, action) => {
    return {
      ...state,
      classifications: action.classification,
    };
  }),
  on(getEquipmentTypesSuccess, (state, action) => {
    return {
      ...state,
      equipmentTypes: action.equipmentTypes,
    };
  }),
  on(loadEquipmentPermissionModalOptionsSuccess, (state, action) => {
    return {
      ...state,
      equipmentPermissionOptions: action.equipmentPermissionOptions,
    };
  }),
  on(loadEquipmentPermissionsSuccess, (state, action) => {
    return {
      ...state,
      equipmentPermissions: action.equipmentPermissions,
    };
  }),  
  on(clearPermissions, (state, action) => {
    return {
      ...state,
      equipmentPermissions: null,
    };
  }),  
  on(getAssignedEquipmentList, (state, action) => {
    return {
      ...state,
      loadingAssignedEquipmentList: true
    };
  }),
  on(getAssignedEquipmentListSuccess, (state, action) => {
    return {
      ...state,
      assignedEquipmentList: action.equipmentWithAssignmentList,
      loadingAssignedEquipmentList: false
    };
  }),
  on(getAssignedEquipments, (state, action) => {
    return {
      ...state,
      loadingAssignedEquipments: true
    };
  }),
  on(getAssignedEquipmentsSuccess, (state, action) => {
    return {
      ...state,
      assignedEquipments: action.equipmentWithAssignment,
      loadingAssignedEquipments: false
    };
  }),
  on(updateCreateEquipmentForm, (state, action) => {
    const newEquipment = { ...state.equipment, ...action.equipmentValues };
    const newState = { ...state, ...{ equipment: newEquipment, equipmentFormErrors: action.formErrors } };
    return newState;
  }),
  on(clearCreateEquipmentForm, (state, action) => {
    return {
      ...state,
      equipment: {} as EquipmentModel,
      equipmentFormErrors: []
    };
  }),
  on(editEquipmentForm, (state, action) => {
    return {
      ...state,
      equipment: action.equipment,
      equipmentFormErrors: []
    };
  }),

  on(createEquipment, (state, action) => {
    return {
      ...state,
      creatingEquipment: true
    };
  }),
  on(createEquipmentSuccess, (state, action) => {
    return {
      ...state,
      equipment: action.equipment,
      creatingEquipment: false
    };
  }),
  on(createEquipmentFail, (state, action) => {
    return {
      ...state,
      creatingEquipment: false
    };
  }),

  on(deleteEquipment, (state, action) => {
    return {
      ...state,
      deletingEquipment: true
    };
  }),
  on(deleteEquipmentSuccess, (state, action) => {
    return {
      ...state,
      equipment: null,
      deletingEquipment: false
    };
  }),
  on(deleteEquipmentFail, (state, action) => {
    return {
      ...state,
      equipment: null,
      deletingEquipment: false
    };
  }),

  on(getMyEquipmentList, (state, action) => {
    return {
      ...state,
      loadingMyEquipments: true
    };
  }),
  on(getMyEquipmentListSuccess, (state, action) => {
    return {
      ...state,
      myEquipments: action.equipments,
      loadingMyEquipments: false
    };
  }),
  on(getMyEquipmentListFail, (state, action) => {
    return {
      ...state,
      myEquipments: null,
      loadingMyEquipments: false
    };
  }),
  on(clearEquipmentsToAssign, (state, action) => {
    return {
      ...state,
      assigningEquipments: []
    };
  }),
  on(addEquipmentToAssign, (state, action) => {
    const newList = state.assigningEquipments.slice(0);
    newList.push(deepClone(action.assignedEquipment));
    return {
      ...state,
      assigningEquipments: newList,
    };
  }),
  on(AddReturnDate, (state, action) => {
    const newList =(deepClone(state.assigningEquipments));
    newList.forEach(item => {
      if(item.equipment.id == action.assignedEquipment.equipmentID){
        item.thereIsReturnDate = true;
      }
    });
    return {
      ...state,
      assigningEquipments: newList,
    };
  }),
  on(RemoveReturnDate, (state, action) => {
    const newList =(deepClone(state.assigningEquipments));
    newList.forEach(item => {
      if(item.equipment.id == action.assignedEquipment.equipmentID){
        item.thereIsReturnDate = false;
        item.returnDateString = "";
      }
    });
    return {
      ...state,
      assigningEquipments: newList,
    };
  }),
  on(SetReturnDate, (state, action) => {
    const newList =(deepClone(state.assigningEquipments));
    newList.forEach(item => {
      if(item.equipment.id == action.assignedEquipment.equipmentID){
        item.returnDateString = action.assignedEquipment.returnDateString;
      }
    });
    return {
      ...state,
      assigningEquipments: newList,
    };
  }),
  on(removeEquipmentToAssign, (state, action) => {
    const newList = state.assigningEquipments.slice(0);
    const index = newList.findIndex(e => e.equipmentID === action.assignedEquipment.equipmentID);
    newList.splice(index, 1);
    return {
      ...state,
      assigningEquipments: newList,
    };
  }),

  on(addAssigningAcessory, (state, action) => {
    const newList = state.assigningEquipments.slice(0);
    const indexEquipment = state.assigningEquipments.findIndex(ae => ae.equipmentID === action.equipmentID);
    const assigningEquipment: AssignedEquipmentModel = deepClone(state.assigningEquipments[indexEquipment]);

    const equipmentAcessories = assigningEquipment.equipmentAcessories ? assigningEquipment.equipmentAcessories : [];
    const acessory: EquipmentAcessoryModel = {
      name: action.name,
      equipmentLabel: assigningEquipment.equipment.brand + ' - ' + assigningEquipment.equipment.model
        + ' - ' + assigningEquipment.equipment.serialNumber
    };
    equipmentAcessories.push(acessory);
    assigningEquipment.equipmentAcessories = equipmentAcessories;

    newList.splice(indexEquipment, 1, assigningEquipment);

    return {
      ...state,
      assigningEquipments: newList,
    };
  }),

  on(removeAssigningAcessory, (state, action) => {
    const newList = state.assigningEquipments.slice(0);
    const indexEquipment = newList.findIndex(e =>
      e.equipmentAcessories && !!e.equipmentAcessories.find(aces =>
        aces.equipmentLabel === action.acessory.equipmentLabel && aces.name === action.acessory.name));
    const assigningEquipment: AssignedEquipmentModel = deepClone(newList[indexEquipment]);
    const indexAcessory = assigningEquipment.equipmentAcessories.findIndex(aces =>
      aces.equipmentLabel === action.acessory.equipmentLabel && aces.name === action.acessory.name);

    assigningEquipment.equipmentAcessories.splice(indexAcessory, 1);

    newList.splice(indexEquipment, 1, assigningEquipment);

    return {
      ...state,
      assigningEquipments: newList,
    };
  }),

  on(changedUserOnEquipmentToAssign, (state, action) => {
    const newList = deepClone(state.assigningEquipments);
    newList.forEach(e => {
      e.userID = action.userID;
    });
    return {
      ...state,
      assigningEquipments: newList,
    };
  }),

  on(changedAssignedProgramOnEquipmentToAssign, (state, action) => {
    const newList = deepClone(state.assigningEquipments);
    newList.forEach(e => {
      e.assignedProgramID = action.assignedProgramID;
    });
    return {
      ...state,
      assigningEquipments: newList,
    };
  }),

  on(searchEquipment, (state, action) => {
    return {
      ...state,
      searchingEquipments: true
    };
  }),
  on(searchEquipmentSuccess, (state, action) => {
    return {
      ...state,
      equipmentSearchResult: action.equipments.result,
      searchingEquipments: false
    };
  }),
  on(searchEquipmentFail, (state, action) => {
    return {
      ...state,
      equipmentSearchResult: null,
      searchingEquipments: false
    };
  }),

  on(saveAssignEquipment, (state, action) => {
    return {
      ...state,
      savingAssignEquipments: true
    };
  }),
  on(saveAssignEquipmentSuccess, (state, action) => {
    return {
      ...state,
      savingAssignEquipments: false
    };
  }),
  on(saveAssignEquipmentFail, (state, action) => {
    return {
      ...state,
      savingAssignEquipments: false
    };
  }),

  on(saveEditAssignEquipment, (state, action) => {
    return {
      ...state,
      savingAssignEquipments: true
    };
  }),
  on(saveEditAssignEquipmentSuccess, (state, action) => {
    return {
      ...state,
      savingAssignEquipments: false
    };
  }),
  on(saveEditAssignEquipmentFail, (state, action) => {
    return {
      ...state,
      savingAssignEquipments: false
    };
  }),

  on(getEquipmentAssignPdf, (state, action) => {
    return {
      ...state,
      docArrayBuffer: null,
      loadingDoc: true
    };
  }),
  on(getEquipmentAssignPdfSuccess, (state, action) => {
    return {
      ...state,
      docArrayBuffer: action.doc,
      loadingDoc: false
    };
  }),

  on(loadUserEquipments, (state, action) => {
    return {
      ...state,
      loadingUserEquipments: true,
      userIDReturning: action.userID
    };
  }),
  on(loadUserEquipmentsSuccess, (state, action) => {
    return {
      ...state,
      assignedEquipmentsToUser: action.assignedEquipments,
      loadingUserEquipments: false
    };
  }),
  on(loadUserEquipmentsFail, (state, action) => {
    return {
      ...state,
      loadingUserEquipments: false
    };
  }),

  on(saveReturnEquipment, (state, action) => {
    return {
      ...state,
      savingReturnEquipments: true
    };
  }),
  on(saveReturnEquipmentSuccess, (state, action) => {
    return {
      ...state,
      savingReturnEquipments: false
    };
  }),
  on(saveReturnEquipmentFail, (state, action) => {
    return {
      ...state,
      savingReturnEquipments: false
    };
  }),
  on(setCreateStoredValueOther, (state, action) => {
    return {
      ...state,
      equipment: action.equipment
    };
  }),

  on(changedReturnEquipment, (state, action) => {
    const newEquipment = deepClone(action.assignedEquipment);
    const equipmentIndex = state.assignedEquipmentsToUser.findIndex(e => e.equipmentID === action.assignedEquipment.equipmentID);
    const newAssignedEquipmentsToUser = state.assignedEquipmentsToUser.slice(0);
    newAssignedEquipmentsToUser.splice(equipmentIndex, 1, newEquipment);

    return {
      ...state,
      assignedEquipmentsToUser: newAssignedEquipmentsToUser
    };
  }),
);

export function equipmentReducerFunc(state: EquipmentState | undefined, action: Action) {
  return equipmentReducer(state, action);
}


export function initCreateEquipmentForm(equipment: EquipmentModel) {
  if (equipment == null) {
    equipment = {} as EquipmentModel;
  }

  let purchaseDate = typeof equipment.purchaseDate !== 'undefined' && equipment.purchaseDate !== null ? equipment.purchaseDate : new Date();

  const form = new FormGroup({
    id: new FormControl(equipment.id),
    model: new FormControl(equipment.model, [Validators.required, Validators.maxLength(255)]),
    purchaseDate: new FormControl(formatDate(purchaseDate, 'yyyy-MM-dd', 'en-US'), [Validators.required]),
    mainProgramID:new FormControl(equipment.mainProgramID, [Validators.required]),
    serialNumber: new FormControl(equipment.serialNumber, [Validators.required, Validators.maxLength(255)]),
    serviceTag: new FormControl(equipment.serviceTag, [Validators.maxLength(255)]),
    equipmentTypeID: new FormControl(equipment.equipmentTypeID, [Validators.required, Validators.maxLength(255)]),
    assetTag: new FormControl(equipment.assetTag, [Validators.required, Validators.maxLength(255)]),
    brand: new FormControl(equipment.brand, [Validators.required, Validators.maxLength(255)]),
    expressServiceCode: new FormControl(equipment.expressServiceCode, [Validators.maxLength(255)]),
    department: new FormControl(equipment.department, [Validators.required, Validators.maxLength(255)]),
    pricing: new FormControl(equipment.pricing, [Validators.required, Validators.maxLength(255)]),
    purchaseVendor: new FormControl(equipment.purchaseVendor, [Validators.required, Validators.maxLength(255)]),
    storedLocationID: new FormControl(equipment.storedLocationID, [Validators.required]),
    storedOther: new FormControl(equipment.storedOther, [Validators.maxLength(255)]),
    paymentMethod: new FormControl(equipment.paymentMethod, [Validators.required, Validators.maxLength(255)]),
    size: new FormControl(equipment.size, [Validators.required, Validators.maxLength(255)]),
    classCodeID: new FormControl(equipment.classCodeID, [Validators.required]),
    classificationID: new FormControl(equipment.classificationID, [Validators.required]),
    classificationOther: new FormControl(equipment.classificationOther, [Validators.maxLength(255)]),
  });

  return form;
}
