import { ActivatedRoute, Router } from '@angular/router';
import {
  loadSurveyInfo, updateSurveyAnswerForm,
  saveSurveyAnswer, saveSurveyAnswerSuccess
} from './../../../../core/store/survey/survey.actions';
import { SurveyModel, SurveyFullAnswerModel } from './../../../../models/surveyModel';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { FormGroup } from '@angular/forms';
import { getAllControlsErrors, markFormGroupTouched } from '../../../../helpers/utils';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { initSurveyAnswerForm } from '../../../../core/store/survey/survey.reducer';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { AlertService } from '../../../../shared/services/alert.service';
import { ProspectiveCandidateReferenceModel } from '../../../../models/prospectiveCandidateModel';
import { loadPropsectiveCandidateReference, loadPropsectiveCandidateReferenceById, savePropsectiveCandidateReference, savePropsectiveCandidateReferenceSuccess, updateProspectiveCandidateSurveyForm } from 'src/app/core/store/prospective-candidates/prospective-candidate.actions';
import { initProspectiveCandidateSurveyForm } from 'src/app/core/store/prospective-candidates/prospective-candidate.reducer';
@Component({
  selector: 'app-survey-prospective-candidates',
  templateUrl: './survey-prospective-candidates.component.html',
  styleUrls: ['./survey-prospective-candidates.component.scss']
})
export class SurveyProspectiveCandidatesComponent extends BaseComponent implements OnInit {

  prospectiveCandidateReference: ProspectiveCandidateReferenceModel;
  form: FormGroup;
  loadingProspectiveCandidateReference: boolean;
  savingProspectiveCandidateReference: boolean;
  answered = false;
  isById = false;
  isFromEmployee = false;

  yesNoOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  yesNoNAOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
    { label: 'N/A', value: null },
  ];

  rateOptions = [
    { label: 'Poor', value: 1 },
    { label: 'Good/Acceptable', value: 2 },
    { label: 'Very Good/Above Average', value: 3 },
    { label: 'Outstanding', value: 4 },
    { label: 'N/A', value: 0 },
  ];

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.prospectiveCandidateState.loadingProspectiveCandidateReference).subscribe(
        loadingProspectiveCandidateReference => {
          this.loadingProspectiveCandidateReference = loadingProspectiveCandidateReference;
        }),

      this.store.select(s => s.prospectiveCandidateState.savingProspectiveCandidateReference).subscribe(
        savingProspectiveCandidateReference => {
          this.savingProspectiveCandidateReference = savingProspectiveCandidateReference;
        }),
      this.store.select(s => s.prospectiveCandidateState.prospectiveCandidateReference).subscribe(prospectiveCandidateReference => {
        this.prospectiveCandidateReference = prospectiveCandidateReference;
        if (prospectiveCandidateReference) {
          if (this.prospectiveCandidateReference.answerDate && !this.isById) {
            this.answered = true;
          } else {
            if (!this.form) {
              this.form = initProspectiveCandidateSurveyForm(this.prospectiveCandidateReference);
              this.subs.push(
                this.form.valueChanges.subscribe(formValues => {
                  const values = this.form.value;
                  const formErrors = getAllControlsErrors(this.form);
                  this.store.dispatch(updateProspectiveCandidateSurveyForm({ prospectiveCandidateReference: values, formErrors }));
                })
              );
            }
          }
        }
      }),
      this.route.queryParams.subscribe(params => {
        const code = params.code;
        if (params.code) {
          this.store.dispatch(loadPropsectiveCandidateReference({ code }));
        } else if (params.id) {
          this.isById = true;
          this.store.dispatch(loadPropsectiveCandidateReferenceById({ propsectiveCandidateReferenceId: params.id }));
        }

        if (params.isFromEmployee){
          this.isFromEmployee = true;
        }
      }),
      this.actions$.pipe(
        ofType(savePropsectiveCandidateReferenceSuccess),
        tap(action => {
          this.alertService.success('Thanks for answering the survey!');
          this.answered = true;
        })
      ).subscribe()
    );

  }



  save() {
    if (this.form.valid) {
      this.store.dispatch(savePropsectiveCandidateReference({ prospectiveCandidateReference: this.prospectiveCandidateReference }));
    } else {
      markFormGroupTouched(this.form);
    }
  }

}

