import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, tap, catchError, filter } from 'rxjs/operators';
import { of, pipe } from 'rxjs';
import { Store, Action } from '@ngrx/store';
import { AlertService } from 'src/app/shared/services/alert.service';
import { State } from 'src/app/core/store';
import { getInputAutocompleteList, getInputAutocompleteListFail, getInputAutocompleteListSuccess } from './input-autocomplete.action';
import { InputAutoCompleteService } from '../../services/input-autocomplete.service';
import { errorHappened } from 'src/app/core/store/profile/profile.actions';

@Injectable()
export class InputAutoCompleteEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private inputAutoCompleteServices: InputAutoCompleteService,
    private alertService: AlertService,
    private router: Router) {}


    getInputAutocompleteList = createEffect(() => this.actions$.pipe(
        ofType(getInputAutocompleteList),
        switchMap(action => {
          return this.inputAutoCompleteServices.getInputAutocompleteList(action.options).pipe(
            map(result => getInputAutocompleteListSuccess({ result: result })),
            catchError(err => {
              this.store.dispatch(getInputAutocompleteListFail({ err }));
              return of(errorHappened({ err }));
            }));
        })
      ));

  }