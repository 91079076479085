
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, tap, catchError, filter } from 'rxjs/operators';
import { of, pipe } from 'rxjs';
import { errorHappened } from '../profile/profile.actions';
import {
    getUser,
  getUsersIncludingTerminated,
  getUsersIncludingTerminatedSuccess,
  getUsersList, getUsersListSuccess, getUsersReportingList, getUsersReportingListSuccess, getUserSuccess,
} from './users.actions';
import { UsersService } from '../../services/users.service';

@Injectable()
export class UsersEffects {
  constructor(private actions$: Actions,
              private usersService: UsersService) {

  }

  getUsersList = createEffect(() => this.actions$.pipe(
    ofType(getUsersList),
    switchMap(action => {
      return this.usersService.getUsers().pipe(
        map(usersList => getUsersListSuccess({ usersList })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  getUsersIncludingTerminated = createEffect(() => this.actions$.pipe(
    ofType(getUsersIncludingTerminated),
    switchMap(action => {
      return this.usersService.getUsersIncludingTerminated().pipe(
        map(usersList => getUsersIncludingTerminatedSuccess({ usersList })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  getUsersReportingList = createEffect(() => this.actions$.pipe(
    ofType(getUsersReportingList),
    switchMap(action => {
      return this.usersService.getUsersReporting().pipe(
        map(usersReportingList => getUsersReportingListSuccess({ usersReportingList })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));
  getUser = createEffect(() => this.actions$.pipe(
    ofType(getUser),
    switchMap(action => {
      return this.usersService.getUser(action.userId).pipe(
        map(user => getUserSuccess({ user })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

}
