import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { Actions } from '@ngrx/effects';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Router } from '@angular/router';
import { CriticalNewsPostCommentModel } from 'src/app/models/criticalNewsFeedModel';
import { archiveComment, editComment, pinComment, saveComment, unpinComment} from 'src/app/core/store/critical-news-feed/critical-news-feed.action';
import { deepClone } from 'src/app/helpers/utils';
import { take } from 'rxjs/operators';


@Component({
  selector: 'app-critical-news-post-comments',
  templateUrl: './critical-news-post-comments.component.html',
  styleUrls: ['./critical-news-post-comments.component.scss']
})
export class CriticalNewsPostCommentsComponent extends BaseComponent implements OnInit {

  @Input() comments: CriticalNewsPostCommentModel[];
  @Input() postId: number;
  @Input() managePinnedComments: boolean;
  @Input() manageAll: boolean;
  @Input() canComment: boolean;

  @ViewChild('commentInput') commentInput: ElementRef;

  @ViewChild('commentEditInput') commentEditInput: ElementRef;

  commentMessageToLong: boolean;
  commentMessage: string;
  editCommentMessage: string = "";
  editCommentMessageTooLong : boolean = false;
  showCommentMenu: boolean = false;
  selectedCommentID: number;
  editSelectedComment: boolean;
  selectedEditCommentID: number = null;
  editMessageTooLong: boolean;
  userID: number;

  newCommentLoading: boolean = false;
  allComments: CriticalNewsPostCommentModel[];
  editCharCount: number = 0;
  commentCharCount: number = 0;

  constructor(
    private actions$: Actions,
    private alertService: AlertService,
    private store: Store<State>,
    private router: Router,
    ) {
    super();
  }

  ngOnInit(): void {
    this.allComments = this.comments != null && this.comments.length > 0 ? deepClone(this.comments) : [];
    let state: State;
    this.store.pipe(take(1)).subscribe(s => state = s)
    this.userID = state.accountState.user.id;
  }

  ngOnChanges(val){
    if(val != null && val.comments != null)
    {
      this.allComments = val.comments.currentValue;
      this.showCommentMenu = false;
      this.editSelectedComment = false;
    }             
  }

  stopPropagation(e) {
    e.stopPropagation();
  }

  saveComment(){
    if(this.commentMessageToLong ){
      this.alertService.error("Comment is too long!")
      return;
    }
    if(this.commentMessage == null || this.commentMessage == ""){
      this.alertService.error("Comment cannot be empty!")
      return;
    }
    var comment = {
      id: 0,
      userID: 0,
      postID: this.postId,
      comment: this.commentMessage,
      userImage: "",
      userFirstName: "",
      userLastName: "",
      createdDate: new Date(),
      updatedDate: null,
    }
    this.newCommentLoading = true;
    this.commentMessage = "";
    setTimeout(()=>{ 
      this.commentInput.nativeElement.focus()
    }, 0); 
    this.store.dispatch(saveComment({comment: comment}));
  }

  saveEditComment(comm){
    if(this.editCommentMessageTooLong ){
      this.alertService.error("Comment is too long!")
      return;
    }
    if(this.editCommentMessage == null || this.editCommentMessage == ""){
      this.alertService.error("Comment cannot be empty!")
      return;
    }
    var comment = {
      id: this.selectedEditCommentID,
      userID: 0,
      postID: this.postId,
      comment: this.editCommentMessage,
      userImage: "",
      userFirstName: "",
      userLastName: "",
      pinned: comm.pinned,
      createdDate: new Date(),
      updatedDate: null,
    }
    this.store.dispatch(editComment({comment: comment}));
  }

  onCommentInput(e) {
    const tx = this.commentInput.nativeElement;
    for (let i = 0; i < tx.length; i++) {
      tx[i].setAttribute("style", "height:" + (tx[i].scrollHeight) + "px;overflow-y:hidden;");
    }
    e.target.style.height = 0;
    e.target.style.height = (e.target.scrollHeight) + "px";
    this.commentCharCount = this.commentMessage != null ? this.commentMessage.length : 0;
  }

  onCommentEditInput(e) {
    const tx = this.commentEditInput.nativeElement;
    for (let i = 0; i < tx.length; i++) {
      tx[i].setAttribute("style", "height:" + (tx[i].scrollHeight) + "px;overflow-y:hidden;");
    }
    e.target.style.height = 0;
    e.target.style.height = (e.target.scrollHeight) + "px";
    this.editCharCount = this.editCommentMessage != null ? this.editCommentMessage.length : 0;

  }
  
  toggleCommentMenu(e, id){
    this.selectedCommentID = id;
    this.showCommentMenu = !this.showCommentMenu;
    e.stopPropagation()
  }

  pinComment(comment){
    let c = deepClone(comment);
    if (comment.pinned ){
      this.store.dispatch(unpinComment({id: comment.id}));
    }
    else{
      c.pinned = true; 
      this.store.dispatch(pinComment({id: comment.id, postId: comment.postID}))
    }
  }

  editComment(comment){
    this.selectedEditCommentID = comment.id;
    this.editCommentMessage = comment.comment;
    this.editSelectedComment = true;
    setTimeout(()=>{ 
      this.commentEditInput.nativeElement.focus();
      this.showCommentMenu = false;
    }, 0); 
  }

  closeCommentMenu(){
    this.showCommentMenu = false;
  }

  closeEditComment(){
    this.editSelectedComment = false;
    this.selectedEditCommentID = null;
  }

  archiveComment(comment){
    this.alertService.confirm("Confirm Action", "Are you sure you want to Archive this Comment?").subscribe(answer =>{
      if(answer){
        this.store.dispatch(archiveComment({id: comment.id}))
      }
    })
  }

  resize() {
    setTimeout(() => {
      this.commentMessage = this.commentMessage + ""
      this.editCommentMessage = this.editCommentMessage + ""
    }, 250);
  }

  countCharacters(){
    var count = this.commentMessage.length;
    if(count > 2000){
      this.commentMessageToLong = true;
    }
    else{
      this.commentMessageToLong = false;
    }
  }

  countEditCommentCharacters(){
    var count = this.editCommentMessage.length;
    if(count > 2000){
      this.editCommentMessageTooLong = true;
    }
    else{
      this.editCommentMessageTooLong = false;
    }
  }
}
