<app-profile-header [readonly]="true"></app-profile-header>
<div class="row">
  <div class="col-12 col-lg-12">
    <div class="card">
      <div class="card-body">
        <div class="float-right">
          <button class="btn btn-secondary" [routerLink]="['/payroll']" style="margin-right: 5px;"> Payroll</button>
          <button class="btn btn-secondary" [routerLink]="['/timeoff/my']"> Request Time Off</button>
        </div>
        <h2 class="">Time Card</h2>
        <div class="row">
          <div class="col-12">
            <div class="d-flex w-100 align-items-center">
            <b>Period:</b>
            <i *ngIf="!payPeriods" class="material-icons rotate text-muted">loop</i>
            <select class="form-control ml-2" *ngIf="payPeriods" [ngModel]="selectedPeriodID" (ngModelChange)="changePeriod($event)">
              <option *ngFor="let period of payPeriods" [value]="period.payPeriodID">{{period.payPeriod}}</option>
            </select>
          </div>
            <div class="ml-2">
              <div class="d-flex mt-3 mb-2">
                  <div class="d-flex align-items-center">
                    <h4 class="mb-0 ">Clock Punches</h4>
                  </div>
                  <div class="d-flex align-items-center">
                    <button class="btn btn-primary ml-2" id="addTimecardButton" *ngIf="!additing && hasClockPunchRight"  (click)="addTimecard()" >Add</button>
                    <button class="btn btn-light ml-2" *ngIf="additing || fixing" (click)="additing=false;fixing=false;" >Close</button>
                    <button class="btn btn-primary ml-2" id="addClientServiceNoteButton" *ngIf="hasClockPunchRight"  (click)="goToClientServiceNoteUrl()" >Client Service Note</button>
                  </div>
              </div>
              <app-timecard-addit *ngIf="additing"></app-timecard-addit>
              <app-timecard-fix *ngIf="fixing"></app-timecard-fix>

            <i *ngIf="!timeCards" class="material-icons rotate text-muted">loop</i>
              <p-table *ngIf="timeCards" [value]="timeCards" [responsive]="true">
                  <ng-template pTemplate="header">
                      <tr>
                          <th width="120px"></th>
                          <th width="90px">Date</th>
                          <th width="90px">Clock In</th>
                          <th width="100px">Clock Out</th>
                          <th width="90px">Total</th>
                          <th width="110px">Status</th>
                          <th>Reason</th>
                          <th >Description</th>
                          <th  width="200px" *ngIf="hasAnyClientService">Client Service Time</th>
                          <th width="200px" *ngIf="hasAnyPayableFixRequest">Payable Fix Request</th>
                          <th width="200px" *ngIf="hasAnyDocumentationFixRequest">Documentation Fix Request</th>
                          <th width="200px" *ngIf="hasAnyFixReason">Fix Reason</th>
                          <th width="200px" *ngIf="hasAnyReply">Reply</th>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-timeCard>
                      <tr >
                          <td>
                            <button class="btn btn-secondary " *ngIf="hasClockPunchRight && !timeCard.approved && !timeCard.denied && !timeCard.isClientServiceNote" (click)="deleteTimeCard(timeCard)"><i class="fas fa-trash" title="Delete"></i></button>
                            <button class="btn btn-secondary ml-2" *ngIf="hasClockPunchRight && !timeCard.approved && !timeCard.denied" (click)="editTimeCard(timeCard)"><i class="fas fa-pencil-alt" title="Edit"></i></button>
                            <!--<button class="btn btn-secondary ml-2" *ngIf="!timeCard.fixReason && (!hasClockPunchRight || timeCard.approved || timeCard.denied)" (click)="fixTimeCard(timeCard)"><i class="fas fa-eraser" title="Fix"></i></button>-->
                            <!--timecard.FixReason is an old way to request for payroll to fix the time card. Only necessary if FeatureSettings.TimeCard_AllowManualEntry() is false.-->
                          </td>
                          <td>
                            <span class="ui-column-title">Date</span>
                            {{timeCard.clockIn | date:'shortDate' }}</td>
                          <td>
                              <span class="ui-column-title">Clock in</span>
                            {{timeCard.clockIn | date:'shortTime' }}</td>
                          <td>
                              <span class="ui-column-title">Clock Out</span>
                            {{timeCard.clockOut | date:'shortTime' }}</td>
                          <td>
                              <span class="ui-column-title">Total</span>
                            {{timeCard.timeDiff }}</td>
                            <td >
                                <span class="ui-column-title">Status</span>
                              {{timeCard.approved?'Approved':(timeCard.denied?'Denied':(timeCard.hasOpenPayableFixRequest?'Adjustment':(timeCard.hasOpenDocumentationFixRequest?'Fix':'Pending')))}}</td>
                          <td>
                            <span class="ui-column-title">Reason</span>
                            {{timeCard.reason}}
                          </td>
                          <td >
                              <span class="ui-column-title">Description</span>
                            {{timeCard.description}}</td>

                          <td *ngIf="hasAnyClientService">
                            <span class="ui-column-title">Client Service Time</span>
                          {{clients && clients[timeCard.patientID] ? clients[timeCard.patientID].clientName : '' }} - {{timeCard.code}} </td>

                          <td *ngIf="hasAnyPayableFixRequest">
                            <span class="ui-column-title">Payable Fix Request</span>
                          {{timeCard.payableFixRequest}}</td>

                          <td *ngIf="hasAnyDocumentationFixRequest">
                            <span class="ui-column-title">Documentation Fix Request</span>
                          {{timeCard.documentationFixRequest}}</td>

                          <td *ngIf="hasAnyFixReason">
                            <span class="ui-column-title">Fix Reason</span>
                          {{timeCard.fixReason}}</td>

                          <td *ngIf="hasAnyReply">>
                            <span class="ui-column-title">Reply</span>
                          {{timeCard.reply}}</td>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="summary" >
                      <span class="text-right mr-2">Total</span>
                      <span>{{totalTime}}</span>
                  </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
