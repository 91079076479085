
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CreateTicketTypeModel, TicketType, UpdateTicketTypeModel } from 'src/app/models/ticketTypeModels'; // Assuming the models exist

@Injectable({
  providedIn: 'root'
})
export class TicketTypesService {
  private apiUrl = `${environment.apiServer.webApiURL}/tickettype`;

  constructor(private http: HttpClient) {}

  
  getAllTicketTypes(): Observable<TicketType[]> {
    return this.http.get<TicketType[]>(this.apiUrl);
  }

  getTicketTypeById(id: number): Observable<TicketType> {
    return this.http.get<TicketType>(`${this.apiUrl}/${id}`);
  }

  createTicketType(ticketType: CreateTicketTypeModel): Observable<TicketType> {
    return this.http.post<TicketType>(this.apiUrl, ticketType);
  }

  updateTicketType(id: number, ticketType: UpdateTicketTypeModel): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}/${id}`, ticketType);
  }

  deleteTicketType(id: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${id}`);
  }
}
