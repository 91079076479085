import {
  rejectPayrollItems, savePayrollItems,
  unapprovePayrollItems, lockPayrollPeriod,
  unlockPayrollPeriod,
  loadPayrollClassifications,
  loadCampuses,
  changePayrollApprovalUserFilter,
  loadPayrollCampuses,
  loadPayrollApprovalAdjustmentTime,
  loadPayrollApprovalPendingTime,
  generatePayrollItems,
  savePayableItem,
  showEditPayableItem
} from './../../../../core/store/payroll-approval/payroll-approval.actions';
import { SelectItem } from 'primeng/api';
import { UserModel } from 'src/app/models/userModel';

import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { loadPayPeriods, changePayPeriod } from 'src/app/core/store/payperiod/payperiod.actions';
import { PayPeriodModel } from 'src/app/models/payPeriodModel';
import { PayrollItemModel, PayrollApprovedTotalsModel, PayableItemModel } from 'src/app/models/payableItem';
import { getTimeFormatted, deepClone, getDateNumberComparison, getShortDateFormatted, formatCurrencyNotZero } from 'src/app/helpers/utils';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { getUsersList } from 'src/app/core/store/users/users.actions';
import {
  loadPayrollApprovalPeriods, changePayrollApprovalPeriod,
  approvePayrollItems
} from 'src/app/core/store/payroll-approval/payroll-approval.actions';
import { AlertService } from '../../../../shared/services/alert.service';
import { saveTimeOffRequestSuccess, showEditTimeOffRequest } from '../../../../core/store/timeoff/timeoff.actions';
import { TimeOffModel } from '../../../../models/timeoffModel';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-payroll-approval',
  templateUrl: './payroll-approval.component.html',
  styleUrls: ['./payroll-approval.component.scss']
})
export class PayrollApprovalComponent extends BaseComponent implements OnInit {

  payPeriods: PayPeriodModel[];
  selectedPeriodID: number;

  approvedPayrollItems: PayrollItemModel[] = null;
  invalidPayrollItems: PayrollItemModel[] = null;
  adjustmentPayrollItems: PayrollItemModel[] = null;
  pendingPayrollItems: PayrollItemModel[] = null;
  approvedTotals: PayrollApprovedTotalsModel;

  differentPendingPayableTypes: { payableType: string, payrollItems: PayrollItemModel[] }[];
  differentApprovedPayableTypes: { payableType: string, payrollItems: PayrollItemModel[] }[];

  approvedOpened = true;
  invalidOpened = true;
  adjustmentsOpened = true;
  pendingOpened = true;
  approvingPayroll: boolean = false;

  usersList: SelectItem[];
  userID: number;

  pendingPayrollItemsSelected: PayrollItemModel[] = [];
  displayRejectItems: boolean;
  selectedItemsToReject: PayrollItemModel[] = [];

  approvedPayrollItemsSelected: PayrollItemModel[] = [];

  adjustmentsPayrollItemsSelected: PayrollItemModel[] = [];
  displayAdjustmentsItems: boolean;


  invalidPayrollItemsSelected: PayrollItemModel[] = [];
  displayInvalidItems: boolean;

  isLocked = false;

  payrollClassificationID = 0;
  campusID = 0;

  campusesList: SelectItem[];
  payrollClassificationList: SelectItem[];

  users = {};

  canEdit: boolean;
  showAllPending: boolean = false;
  canViewAllPending: boolean;
  canEditItems: boolean;

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private alertService: AlertService ) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.canEdit = permissionIDs && !!permissionIDs.find(p => p === 154 || p === 6030 || p === 3120); //PayrollApproval_View OR AA OR Director campus approval
        this.canViewAllPending = permissionIDs && !!permissionIDs.find(p => p === 3069); //Show all option
        this.canEditItems = permissionIDs && !!permissionIDs.find(p => p === 3126); //Can edit payable item date/times/assign PTO
      }),
      this.store.select(s => s.payrollApprovalState.isLocked).subscribe(isLocked => {
        this.isLocked = isLocked;
      }),
      this.store.select(s => s.payrollApprovalState.periods).subscribe(periods => {
        this.payPeriods = periods;
      }),
      this.store.select(s => s.payrollApprovalState.selectedPeriodID).subscribe(selectedPeriodID => {
        this.selectedPeriodID = selectedPeriodID;
      }),
      this.store.select(s => s.payrollApprovalState.approvedPayrollItems).subscribe(approvedPayrollItems => {
        this.approvedPayrollItems = approvedPayrollItems;
        this.approvedPayrollItemsSelected = [];
        this.differentApprovedPayableTypes = null;
        if (approvedPayrollItems) {
          this.differentApprovedPayableTypes = approvedPayrollItems
            .filter((p, i) => approvedPayrollItems.findIndex(pi => pi.payableItem.payableType === p.payableItem.payableType) === i)
            .map(p => ({
              payableType: p.payableItem.payableType,
              payrollItems: approvedPayrollItems.filter(pf => pf.payableItem.payableType === p.payableItem.payableType)
            }));
        }
      }),
      this.store.select(s => s.payrollApprovalState.invalidPayrollItems).subscribe(invalidPayrollItems => {
        this.invalidPayrollItems = invalidPayrollItems;
        this.invalidPayrollItemsSelected = [];
      }),
      this.store.select(s => s.payrollApprovalState.adjustmentPayrollItems).subscribe(adjustmentPayrollItems => {
        this.adjustmentPayrollItems = adjustmentPayrollItems;
        this.adjustmentsPayrollItemsSelected = [];
      }),
      this.store.select(s => s.payrollApprovalState.pendingPayrollItems).subscribe(pendingPayrollItems => {
        this.pendingPayrollItems = pendingPayrollItems;
        this.pendingPayrollItemsSelected = [];
        this.differentPendingPayableTypes = null;
        if (pendingPayrollItems) {
          this.differentPendingPayableTypes = pendingPayrollItems
            .filter((p, i) => pendingPayrollItems.findIndex(pi => pi.payableItem.payableType === p.payableItem.payableType) === i)
            .map(p => ({
              payableType: p.payableItem.payableType,
              payrollItems: pendingPayrollItems.filter(pf => pf.payableItem.payableType === p.payableItem.payableType)
            }));

        }
      }),
      this.store.select(s => s.payrollApprovalState.approvedTotals).subscribe(approvedTotals => {
        this.approvedTotals = approvedTotals;
      }),
      this.store.select(s => s.payrollApprovalState.filteredUsers).subscribe(usersList => {
        this.users = usersList.reduce((acc, cur, i) => { acc[cur.id] = cur; return acc; }, {});
        this.usersList = usersList && usersList.length > 0 ? usersList.map(u => ({ label: u.firstName + ' ' + u.lastName, value: u.id }))
          : [{ label: 'loading...', value: undefined }];
      }),
      this.store.select(s => s.payrollApprovalState.payrollCampuses).subscribe(campuses => {
        const campusesList = campuses && campuses.length > 0 ? campuses.map(c => ({ label: c.programName, value: c.id }))
          : [{ label: 'loading...', value: undefined }];
        if (campusesList.length === 1) {
          this.campusID = campusesList[0].value;
          this.changedUserFilters();
        } else {
          campusesList.unshift({ label: 'All', value: 0 });
        }

        this.campusesList = campusesList;

      }),
      this.store.select(s => s.payrollApprovalState.payrollClassifications).subscribe(payrollClassifications => {
        const payrollClassificationList = payrollClassifications && payrollClassifications.length > 0
          ? payrollClassifications.map(p => ({ label: p.payrollClassification, value: p.id }))
          : [{ label: 'loading...', value: undefined }];
        if (payrollClassificationList.length === 1) {
          this.payrollClassificationID = payrollClassificationList[0].value;
          this.changedUserFilters();
        } else {
          payrollClassificationList.unshift({ label: 'All', value: 0 });
        }

        this.payrollClassificationList = payrollClassificationList;
      }),
      this.store.select(s => s.payrollApprovalState.approvingPayroll).subscribe(approving => {
        this.approvingPayroll = approving;
      }),

      this.actions$.pipe(
        ofType(saveTimeOffRequestSuccess),
        tap(() => {
          this._refresh();
        })
      ).subscribe(),
    );

    this.store.dispatch(changePayrollApprovalUserFilter({ campusID: 0, payrollClassificationID: 0 }));
    this.store.dispatch(loadPayrollClassifications());
    this.store.dispatch(loadPayrollCampuses());
    this.store.dispatch(loadPayrollApprovalPeriods());
  }

  refreshPendingAndAdjusmentTime() {
    if (this.userID == null || this.userID == 0) {
      return;
    }

    this.store.dispatch(loadPayrollApprovalAdjustmentTime({ periodID: this.selectedPeriodID, userID: this.userID, showAll: this.showAllPending }));
    this.store.dispatch(loadPayrollApprovalPendingTime({ periodID: this.selectedPeriodID, userID: this.userID, showAll: this.showAllPending }));
  }

  changePeriod(periodID: number) {
    this.store.dispatch(changePayrollApprovalPeriod(
      {
        periodID,
        userID: this.userID,
        showAll: this.showAllPending
      }));
  }

  getTimeFormatted(time) {
    return getTimeFormatted(time);
  }

  getShortDateFormatted(date) {
    return getShortDateFormatted(date);
  }

  formatCurrencyNotZero(bonusPaid) {
    return formatCurrencyNotZero(bonusPaid);
  }

  changedUser() {
    this.store.dispatch(changePayrollApprovalPeriod(
      {
        periodID: this.selectedPeriodID,
        userID: this.userID,
        showAll: this.showAllPending
      }));
  }
  changedUserFilters() {
    this.store.dispatch(changePayrollApprovalUserFilter(
      {
        campusID: this.campusID,
        payrollClassificationID: this.payrollClassificationID
      }));
  }

  // ### Pending
  pendingPayrollItemsSelectedChanged(pendingPayrollItemsSelected: PayrollItemModel[], payableType: string) {
    const newSelected = this.pendingPayrollItemsSelected.filter(p => p.payableItem.payableType !== payableType);
    newSelected.push(...pendingPayrollItemsSelected);
    this.pendingPayrollItemsSelected = newSelected;
  }

  approveTimes(approvalType: string) {
    let payPeriod = this.payPeriods.find(x => x.payPeriodID == this.selectedPeriodID);
    let itemOutsidePayPeriod = false;
    if (typeof payPeriod !== 'undefined') {
      let dates = payPeriod.payPeriod.split(' - ');      
      if (dates.length == 2) {
        let startDate = getDateNumberComparison(new Date(dates[0]));
        let endDate = getDateNumberComparison(new Date(dates[1]));        
        itemOutsidePayPeriod = this.pendingPayrollItemsSelected.some(x => getDateNumberComparison(x.payableItem.date) - startDate < 0 || endDate - getDateNumberComparison(x.payableItem.date) < 0);        
      }
    }
    
    if (itemOutsidePayPeriod) {
      this.alertService.confirm('WARNING',
        'You are approving 1 or more items with a date outside of the selected pay period. Are you sure you want to approve all of these items?')
        .subscribe(
          answer => {
            if (answer) {
              if (this.approvingPayroll) {
                this.showAlreadySavingError();
                return;
              }              
              this.store.dispatch(approvePayrollItems(
                { payrollItems: this.pendingPayrollItemsSelected, userID: this.userID, payPeriodID: this.selectedPeriodID, approvalType: approvalType}));
            }
          }, error => {
          }
        );
    }
    else {
      if (this.approvingPayroll) {
        this.showAlreadySavingError();
        return;
      }
      this.store.dispatch(approvePayrollItems(
        { payrollItems: this.pendingPayrollItemsSelected, userID: this.userID, payPeriodID: this.selectedPeriodID, approvalType: approvalType }));
    }
  }

  showAlreadySavingError() {
    this.alertService.error("Changes are still saving. Please try again once the data reloads.");
  }

  openRejectTimes() {
    this.displayRejectItems = true;
    this.selectedItemsToReject = deepClone(this.pendingPayrollItemsSelected);
  }

  itemsToRejectChanged(items: PayrollItemModel[]) {
    this.selectedItemsToReject = items;
  }

  executeRejectTimes() {
    this.store.dispatch(rejectPayrollItems(
      { payrollItems: this.selectedItemsToReject, userID: this.userID, payPeriodID: this.selectedPeriodID }));
    this.displayRejectItems = false;
  }

  // ### Approved
  approvedPayrollItemsSelectedChanged(approvedPayrollItemsSelected: PayrollItemModel[], payableType: string) {
    const newSelected = this.approvedPayrollItemsSelected.filter(p => p.payableItem.payableType !== payableType);
    newSelected.push(...approvedPayrollItemsSelected);
    this.approvedPayrollItemsSelected = newSelected;
  }
  executeUnapproveTimes() {
    this.store.dispatch(unapprovePayrollItems(
      { payrollItems: this.approvedPayrollItemsSelected, userID: this.userID, payPeriodID: this.selectedPeriodID }));
  }

  generatePayableItems() {
    if (this.approvingPayroll) {
      this.showAlreadySavingError();
      return;
    }

    this.store.dispatch(generatePayrollItems(
      { userID: this.userID, payPeriodID: this.selectedPeriodID }));
  }

  lockPayperiod() {
    if (this.approvingPayroll) {
      this.showAlreadySavingError();
      return;
    }

    if (this.approvedTotals && this.approvedTotals.matchContractHours) {
      if (this.approvedTotals.totalTime < this.approvedTotals.contractMinutes) {
        this.alertService.error("Employee has not met their contracted hours. Please generate the necessary payable items.");
        return;
      }

      if (this.approvedTotals.totalTime > this.approvedTotals.contractMinutes) {
        this.alertService.error("Employee has exceeded their contract hours. Please unapprove payable items until the approved time does not exceed the contracted hours.");
        return;
      }
    }

    this.store.dispatch(lockPayrollPeriod(
      { userID: this.userID, payPeriodID: this.selectedPeriodID }));
  }

  unlockPayperiod() {
    if (this.approvingPayroll) {
      this.showAlreadySavingError();
      return;
    }

    this.store.dispatch(unlockPayrollPeriod(
      { userID: this.userID, payPeriodID: this.selectedPeriodID }));
  }

  // ### Adjustment
  adjustmentsPayrollItemsSelectedChanged(payrollItemsSelected: PayrollItemModel[]) {
    this.adjustmentsPayrollItemsSelected = payrollItemsSelected;
  }

  adjustmentsPayrollItemsEditClicked(payrollItemsSelected: PayrollItemModel[]) {
    this.adjustmentsPayrollItemsSelected = payrollItemsSelected;
    this.displayAdjustmentsItems = true;
  }

  openChangeAdjustmentsTimes() {
    this.displayAdjustmentsItems = true;
  }

  adjustmentsItemsEditingChanged(items: PayrollItemModel[]) {
    this.adjustmentsPayrollItemsSelected = items;
  }

  saveAdjustmentTimes() {
    this.store.dispatch(savePayrollItems(
      { payrollItems: this.adjustmentsPayrollItemsSelected, userID: this.userID, payPeriodID: this.selectedPeriodID }));
    this.displayAdjustmentsItems = false;
  }

  // ### Invalid
  invalidPayrollItemsSelectedChanged(items: PayrollItemModel[]) {
    this.invalidPayrollItemsSelected = items;
  }

  invalidPayrollItemsEditClicked(items: PayrollItemModel[]) {
    this.invalidPayrollItemsSelected = items;
    this.displayInvalidItems = true;
  }

  openChangeInvalidTimes() {
    this.displayInvalidItems = true;
  }

  invalidItemsEditingChanged(items: PayrollItemModel[]) {
    this.invalidPayrollItemsSelected = items;
  }

  saveInvalidTimes() {
    this.store.dispatch(savePayrollItems(
      { payrollItems: this.invalidPayrollItemsSelected, userID: this.userID, payPeriodID: this.selectedPeriodID }));
    this.displayInvalidItems = false;
  }

  editPayableItemTime(item: PayrollItemModel) {
    this.store.dispatch(showEditPayableItem({ payableItem: item.payableItem }));
  }

  newTimeOffRequest() {
    console.log(this);
    let user = this.usersList.find(x => x.value === this.userID);
    if (typeof user === 'undefined') {
      this.alertService.error("Please select a user.");
    }
    else {
      let date = new Date();
      let payPeriod = this.payPeriods.find(x => x.payPeriodID == this.selectedPeriodID);
      if (typeof payPeriod !== 'undefined') {
        date = new Date(payPeriod.payPeriod.split('- ')[1]);
      }

      let model: TimeOffModel = {
        timeOffId: 0,
        userId: user.value,
        patientId: 0,
        startDate: date,
        endDate: date,
        startTime: date,
        endTime: date,
        dateSubmitted: null,
        lastModified: null,
        allDay: true,
        approved: false,
        denied: false,
        comments: null,
        approverComments: null,
        employeeName: user.label,
        patientName: null,
        isPatientTimeOff: false,
        usePto: true,

        startTimeString: null,
        endTimeString: null,
        startDateString: null,
        endDateString: null,

        ptoHours: 0,
        ptoMinutes: 0,
        ptoMinutesUsed: 0,

        isPayroll: true,
        addedByPayroll: true,
        canEditRequest: true,
        recentChangesMade: "",
      };      
      this.editPtoItem(model);
    }
  }

  editPtoItem(item: TimeOffModel) {
    item.isPayroll = true;
    this.store.dispatch(showEditTimeOffRequest({timeOffRequest: item}));
  }

  _refresh() {
    this.store.dispatch(changePayrollApprovalPeriod(
      {
        periodID: this.selectedPeriodID,
        userID: this.userID,
        showAll: this.showAllPending
      }));
  }

}
