import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OnBoardingDocumentUserModel, UserOnBoardingDocumentsModel, OnBoardingDocumentModel, OnBoardingDocumentModelWithRoles, UpdateOnboardingDocUserForceNotRequiredModel, ResendOnboardingDocUserForceModel } from 'src/app/models/onBoardingDocumentUserModel';
import { PagingResultsModel } from 'src/app/models/pagingResultsModel';


@Injectable()
export class OnboardingDocsService {

  constructor(private http: HttpClient) {
  }

  getOnBoardingDoc(onBoardingDocumentsUserID: number): Observable<ArrayBuffer> {
    return this.http.get<ArrayBuffer>(`${environment.apiServer.webApiURL}/OnBoardingDocs/DocumentToSign/${onBoardingDocumentsUserID}`,
      { responseType: 'arraybuffer' as 'json' });
  }

  getOnBoardingDocInfo(onBoardingDocumentsUserID: number): Observable<OnBoardingDocumentUserModel> {
    return this.http.get<OnBoardingDocumentUserModel>
      (`${environment.apiServer.webApiURL}/OnBoardingDocs/Info/${onBoardingDocumentsUserID}`);
  }


  setFilledInfo(onBoardingDocumentsUserID: number): Observable<OnBoardingDocumentUserModel> {
    return this.http.get<OnBoardingDocumentUserModel>
      (`${environment.apiServer.webApiURL}/OnBoardingDocs/SetFilledInfo/${onBoardingDocumentsUserID}`);
  }


  signOnBoardingDoc(onBoardingDocumentsUserID: number, signedFields: string[]): Observable<ArrayBuffer> {
    return this.http.post<ArrayBuffer>(`${environment.apiServer.webApiURL}/OnBoardingDocs/Sign/${onBoardingDocumentsUserID}`,
      signedFields,
      { responseType: 'arraybuffer' as 'json' });
  }

  getMyDocs(userId: number): Observable<OnBoardingDocumentUserModel[]> {
    if (userId) {
      return this.http.get<OnBoardingDocumentUserModel[]>(`${environment.apiServer.webApiURL}/OnBoardingDocs/ListDocs/${userId}`);

    } else {
      return this.http.get<OnBoardingDocumentUserModel[]>(`${environment.apiServer.webApiURL}/OnBoardingDocs/MyDocs`);
    }
  }


  getAllUserDocs(filter: any)
    : Observable<PagingResultsModel<UserOnBoardingDocumentsModel>> {
    return this.http.post<PagingResultsModel<UserOnBoardingDocumentsModel>>
      (`${environment.apiServer.webApiURL}/OnBoardingDocs/GetUsersDocsTable`,
        filter
      );
  }

  getUsersDocsReport(filter: any)
    : Observable<ArrayBuffer> {
    return this.http.post<ArrayBuffer>
      (`${environment.apiServer.webApiURL}/OnBoardingDocs/GetUsersDocsReport`,
        filter,
        { responseType: 'arraybuffer' as 'json' });
  }

  getUsersDocsReportExcel(filter: any)
    : Observable<ArrayBuffer> {
    return this.http.post<ArrayBuffer>
      (`${environment.apiServer.webApiURL}/OnBoardingDocs/GetUsersDocsReportExcel`,
        filter,
        { responseType: 'arraybuffer' as 'json' });
  }

  getActiveDocs(): Observable<OnBoardingDocumentModel[]> {
    return this.http.get<OnBoardingDocumentModel[]>(`${environment.apiServer.webApiURL}/OnBoardingDocs/ActiveDocs`);
  }

  getAllDocs(filter: any): Observable<PagingResultsModel<OnBoardingDocumentModelWithRoles>> {
    return this.http.post<PagingResultsModel<OnBoardingDocumentModelWithRoles>>(`${environment.apiServer.webApiURL}/OnBoardingDocs/GetAllDocsTable`,
      filter);
  }

  updateDoc(docWithRoles: OnBoardingDocumentModelWithRoles): Observable<OnBoardingDocumentModelWithRoles> {
    return this.http.put<OnBoardingDocumentModelWithRoles>(`${environment.apiServer.webApiURL}/OnBoardingDocs/UpdateOnboardingDocWithRoles`,
      docWithRoles);
  }


  getOnboardingDocWithRoles(id: number): Observable<OnBoardingDocumentModelWithRoles> {
    return this.http.get<OnBoardingDocumentModelWithRoles>(`${environment.apiServer.webApiURL}/OnBoardingDocs/OnboardingDocWithRoles/${id}`,);

  }



  getUserDocs(filter: any): Observable<PagingResultsModel<OnBoardingDocumentUserModel>> {
    return this.http.post<PagingResultsModel<OnBoardingDocumentUserModel>>(`${environment.apiServer.webApiURL}/OnBoardingDocs/GetUserDocsTable`,
      filter);
  }


  updateUserDocForceRequired(forceUserDocRequired: UpdateOnboardingDocUserForceNotRequiredModel): Observable<OnBoardingDocumentUserModel> {
    return this.http.put<OnBoardingDocumentUserModel>(`${environment.apiServer.webApiURL}/OnBoardingDocs/UpdateOnboardingDocUserForce`,
      forceUserDocRequired);
  }


  updateUserDocForceResend(forceUserDocResend: ResendOnboardingDocUserForceModel): Observable<OnBoardingDocumentUserModel> {
    return this.http.put<OnBoardingDocumentUserModel>(`${environment.apiServer.webApiURL}/OnBoardingDocs/ResendOnboardingDocUserForce`,
      forceUserDocResend);
  }


}
