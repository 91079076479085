<h1>Certifications</h1>
<div class="row">
  <div class="col-12 mt-2 text-center" *ngIf="loadingUploadedFiles || downloadingFile">
      <i class="material-icons rotate text-muted ml-2">loop</i>
  </div>
    <ng-container *ngIf="!loadingUploadedFiles">
    <div class="col-12 mt-2" *ngFor="let certType of uploadedFiles">
      <div class="d-flex  align-items-center" [id]="'certType'+certType.id" >
        <h3>{{certType.certificationType}}</h3>
      </div>

      <div class="mt-3 mb-3" *ngIf="certType.files === null || certType.files.length === 0">No files uploaded</div>

      <div class="row mt-3 mb-3" *ngIf="certType.files.length > 0">
        <ng-container *ngFor="let file of certType.files">
          <div class="col-3 text-center" >
              <ng-container>
                <img class="file-image pointer" [src]="getPreviewUrl(file)" (click)="downloadFile(file)" />
                <div class="file-label" [title]="file.fileName"> {{file.fileName}} </div>
                <div class="file-label">Date Uploaded: {{file.dateUploaded != null ? (file.dateUploaded | date: 'MM/dd/yyyy @ hh:mm:ss a') : "-"}}</div>
              </ng-container>
              <div class="mt-1 mb-1">
                <b>Issue Date:</b> {{file.issueDate | date:'shortDate'}}<br>
                <b>Expiration Date:</b> {{file.expirationDate | date:'shortDate'}}
              </div>
          </div>
        </ng-container>
      </div>
  </div>
  </ng-container>
</div>
