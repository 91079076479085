<form class="card mt-2">
  <div class="card-body">
    <h2 class="mb-3">Forms</h2>

    <div class="row mb-1">
      <div class="col-2 d-flex align-items-center">
        <h5>Inactive Forms:</h5>
      </div>
      <div class="col-10 ">
        <p-selectButton styleClass="w-100 ml-2" [options]="yesNoAllOptions" [(ngModel)]="includeArchived" (ngModelChange)="reloadList()" [ngModelOptions]="{standalone: true}"></p-selectButton>
      </div>
    </div>

    <i *ngIf="loadingAvailableForms " class="material-icons rotate text-muted">loop</i>
    <p-table *ngIf="formsList" [value]="formsList" [responsive]="true"
             [paginator]="true" [rows]="10" [totalRecords]="formsList" [lazy]="false">
      <ng-template pTemplate="header">
        <tr>
          <th width="250px"></th>
          <th width="100px" [pSortableColumn]="'Signed'">
            Signed
          </th>
          <th width="90px" [pSortableColumn]="'IsRequired'">
            HR
          </th>
          <th [pSortableColumn]="'Name'">
            Form Name
          </th>
          <th width="100px" [pSortableColumn]="'LastDate'">
            Last time updated
          </th>

          <th width="100px" [pSortableColumn]="'DueDate'">
            Due Date
          </th>
          <th width="100px" [pSortableColumn]="'SignedDate'">
            Signed Date
          </th>
          <th width="90px" [pSortableColumn]="'IsRequired'">
            Required
          </th>

        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-fbForm>
        <tr>
          <td>
            <i *ngIf="loadingDoc && loadingDocAssignmentId===fbForm?.id" class="material-icons rotate text-muted">loop</i>
            <ng-container *ngIf="!loadingDoc">
              <span class="pointer mr-2" *ngIf="!managedUserId && !fbForm.formSubmission?.optOutDate &&  !hasSignedSubmission(fbForm)" (click)="fillForm(fbForm)"><i title="Fill form" class="fab fa-wpforms"></i></span>
              <span class="pointer mr-2" *ngIf="!managedUserId && !fbForm.formSubmission?.optOutDate && !hasSignedSubmission(fbForm) && fbForm.formSubmission?.documentToSignID" (click)="signForm(fbForm)"><i title="Sign form" class="fas fa-file-signature"></i></span>
              <span class="pointer mr-2" *ngIf="!fbForm.formSubmission?.optOutDate && hasSignedSubmission(fbForm)" (click)="seeFormResult(fbForm)"><i title="Signed - See Infos" class="fab fa-wpforms"></i></span>
              <span class="pointer mr-2" *ngIf="!fbForm.formSubmission?.optOutDate && hasSignedSubmission(fbForm)" (click)="downloadForm(fbForm)"><i title="Download signed Form Submission" class="fas fa-file-download"></i></span>
              <span class="pointer mr-2" *ngIf="!fbForm.formSubmission?.optOutDate && canArchive(fbForm)" (click)="toggleArchivedStatus(fbForm)"><i title="Archive" class="fas fa-trash-alt"></i></span>
              <span class="pointer mr-2" *ngIf="!fbForm.formSubmission?.optOutDate && fbForm.archived" (click)="toggleArchivedStatus(fbForm)"><i title="Unarchive" class="fas fa-trash-undo-alt"></i></span>
            <span class="pointer mr-2" *ngIf="!fbForm.formSubmission?.optOutDate && !hasSignedSubmission(fbForm) && !fbForm.archived && fbForm.canOptOut" (click)="optOutForm(fbForm)"><i title="Opt Out" class="fad fa-align-slash"></i></span>
            <span class="text-success" *ngIf="fbForm.formSubmission?.optOutDate">Opted Out - {{fbForm.formSubmission?.optOutDate | date:'shortDate'}}</span>
            </ng-container>
          </td>

          <td>
            <span class="ui-column-title">Signed</span>
            <i class="fas fa-check-circle text-success" title="Signed" *ngIf="fbForm.formSubmission?.signedDate"></i>
            <i class="fas fa-dot-circle" title="Missing" *ngIf="!fbForm.formSubmission?.signedDate"></i>
          </td>

          <td>
            <span class="ui-column-title">HR</span>
            <i class="fas fa-minus-circle text-muted" title="Not needed" *ngIf="!fbForm?.form?.requireHRSignature"></i>
            <i class="fas fa-check-circle text-success" title="Signed" *ngIf="fbForm?.form?.requireHRSignature && fbForm?.formSubmission?.signedByHRDate"></i>
            <i class="fas fa-dot-circle" title="Missing" *ngIf="fbForm?.form?.requireHRSignature && !fbForm?.formSubmission?.signedByHRDate"></i>
            <span class="pointer ml-2" *ngIf="hasSignedSubmission(fbForm) && !hasSignedSubmissionHR(fbForm) && managedUserId && fbForm?.form?.requireHRSignature" (click)="fillHRForm(fbForm)"><i title="Fill and sign HR form" class="fa fa-stamp"></i></span>

          </td>
          <td>
            <span class="ui-column-title">Form Name</span>
            {{fbForm.form.name }}
            <span *ngIf="fbForm.rejectionDate" style="color: red">REJECTED - {{fbForm.rejectionReason}}</span>
            <span *ngIf="fbForm.rejectionReason && !fbForm.rejectionDate" style="font-style: italic; display: block; font-weight: bold; font-size: 0.9rem;">{{fbForm.rejectionReason}}</span>            
          </td>

          <td>
            <span class="ui-column-title">Last time filled</span>
            {{getLastDate(fbForm)}}
          </td>



          <td>
            <span class="ui-column-title">Due Date</span>
            {{fbForm.dueDate | date:'shortDate' }}
          </td>

          <td>
            <span class="ui-column-title">Signed Date</span>
            {{(fbForm?.formSubmission && !fbForm?.formSubmission?.signedDate) ? 'Filling':''  }}
            {{fbForm?.formSubmission?.signedDate ? 'Signed ':'' }}
            {{fbForm.formSubmission?.signedDate?(fbForm.formSubmission.signedDate | date:'shortDate') : ''}}
          </td>
          <td>
            <span class="ui-column-title">Required</span>
            <i *ngIf="!!fbForm.isRequired" class="fas fa-asterisk text-danger" title="Required"></i>
          </td>

        </tr>
      </ng-template>
    </p-table>
  </div>
</form>

<p-confirmDialog></p-confirmDialog>
