import { MedclinicPatientsListComponent } from './medclinic/patients-list/medclinic-patients-list.component';
import { SelfcareComponent } from './selfcare/selfcare.component';
import { SelfcarePlannerComponent } from './selfcare/selfcare-planner/selfcare-planner.component';
import { SignEnrollmentFormDocComponent } from './benefits-enrollment/sign-enrollmentformdoc/sign-enrollmentformdoc.component';
import { BenefitsClassesComponent } from './benefits-management/benefits-classes/benefits-classes.component';
import { BenefitsEnrollmentDetailComponent } from './benefits-enrollment/enrollment-detail/benefits-enrollment-detail.component';
import { ListOnboardingDocsComponent } from './onboardingdocs/manage/list-onboardingdocs/list-onboardingdocs.component';
import { OnboardingManageListComponent } from './onboarding-manage/list/onboarding-manage-list.component';
import { ListMyCoachingLogComponent } from './coachinglogs/list-my-coachinglog/list-my-coachinglog.component';
import { OfferLetterComponent } from './offerletter/offerletter.component';
import { AuthGuard } from './../../core/guards/auth.guard';
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { TimeCardComponent } from './timecard/timecard.component';
import { PayrollComponent } from './payroll/payroll.component';
import { ListPtoSummaryComponent } from './timeoff/list-pto-summary/list-pto-summary.component';
import { ListMyPtoComponent } from './timeoff/list-my-pto/list-my-pto.component';
import { CreateOfferLetterComponent } from './offerletter/create-offerletter/create-offerletter.component';
import { ListOfferLetterComponent } from './offerletter/list-offerletter/list-offerletter.component';
import { CreateEquipmentComponent } from './equipment/create-equipment/create-equipment.component';
import { ListMyEquipmentComponent } from './equipment/list-my-equipment/list-my-equipment.component';
import { ListEquipmentComponent } from './equipment/list-equipment/list-equipment.component';
import { AssignEquipmentComponent } from './equipment/assign-equipment/assign-equipment.component';
import { SignReturnEquipmentComponent } from './equipment/sign-return-equipment/sign-return-equipment.component';
import { SignAssignEquipmentComponent } from './equipment/sign-assign-equipment/sign-assign-equipment.component';
import { ListMyOnboardingDocsComponent } from './onboardingdocs/list-my-onboardingdocs/list-my-onboardingdocs.component';
import { SignOnboardingDocComponent } from './onboardingdocs/sign-onboardingdoc/sign-onboardingdoc.component';
import { ManagementComponent } from './management/management.component';
import { PayrollApprovalComponent } from './payroll/payroll-approval/payroll-approval.component';
import { DocFillInfoComponent } from './onboardingdocs/doc-fill-info/doc-fill-info.component';
import { ListReportOnboardingdocsComponent } from './onboardingdocs/list-report-onboardingdocs/list-report-onboardingdocs.component';
import { DownloadOnboardingdocComponent } from './onboardingdocs/download-onboardingdoc/download-onboardingdoc.component';
import { ListGivenCoachingLogsComponent } from './coachinglogs/list-given-coachinglog/list-given-coachinglog.component';
import { CreateCoachingLogComponent } from './coachinglogs/create-coachinglog/create-coachinglog.component';
import { CreateImprovementPlanComponent } from './improvementplan/create-improvementplan/create-improvementplan.component';
import { SignImprovementPlanComponent } from './improvementplan/sign-improvementPlan/sign-improvementPlan.component';
import { ViewImprovementPlanComponent } from './improvementplan/view-improvementplan/view-improvementplan.component';
// tslint:disable-next-line:max-line-length
import { CreateImprovementPlanReviewComponent } from './improvementplan/create-improvementplanreview/create-improvementplanreview.component';
import { SignImprovementPlanReviewComponent } from './improvementplan/sign-improvementPlanReview/sign-improvementPlanReview.component';
import { ChecksAuthGuard } from 'src/app/core/guards/checksauth.guard';
import { ListHolidayComponent } from './holiday/list-holiday/list-holiday.component';
import { RecurringHolidayComponent } from './holiday/recurring-holiday/recurring-holiday.component';
import { EditHolidayComponent } from './holiday/edit-holiday/edit-holiday.component';
import { EditRecurringComponent } from './holiday/edit-recurring/edit-recurring.component';
import { PayrollApprovalGuard } from '../../core/guards/payroll-approval.guard';
import { EquipmentManageGuard } from '../../core/guards/equipment-manage.guard';
import { EquipmentViewGuard } from '../../core/guards/equipment-view.guard';
import { OfferLetterViewGuard } from '../../core/guards/offer-letter-view.guard';
import { OnboardViewGuard } from '../../core/guards/onboard-view.guard';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { EmployeeFileListComponent } from './employee-file/list/employee-file-list.component';
import { EmployeeFileViewGuard } from 'src/app/core/guards/employeefile-view.guard';
import { DocFillInfoHRComponent } from './onboardingdocs/doc-fill-info/doc-fill-info-hr.component';
import { UploadDocsComponent } from './uploaddocs/uploaddocs.component';
import { ListReportFulltimestatusComponent } from './employee/list-report-fulltimestatus/list-report-fulltimestatus.component';
import { QueueFulltimestatusComponent } from './employee/queue-fulltimestatus/queue-fulltimestatus.component';
import { HolidayGuard } from '../../core/guards/holiday.guard';
import { FullTimeStatusGuard } from '../../core/guards/full-time-status.guard';
import { ReportCensusComponent } from './employee/report-census/report-census.component';
import { EmployeeReportsGuard } from '../../core/guards/employee-reports.guard';
import { ReportTerminatedComponent } from './employee/report-terminated/report-terminated.component';
import { EditOnboardingDocComponent } from './onboardingdocs/manage/edit-onboardingdoc/edit-onboardingdoc.component';
import { ListOnboardingUserDocsComponent } from './onboardingdocs/manage/list-onboardinguserdocs/list-onboardinguserdocs.component';
import { ListProspectiveCandidatesComponent } from './prospective-candidates/list-prospective-candidates/list-prospective-candidates.component';
import { ProspectiveCandidatesViewGuard } from 'src/app/core/guards/prospective-candidates-view.guard';
import { ProspectiveCandidatesAddItComponent } from './prospective-candidates/prospective-candidates-addit/prospective-candidates-addit.component';
import { SurveyAnswerComponent } from './survey/survey-answer/survey-answer.component';
import { SurveyListComponent } from './survey/survey-list/survey-list.component';
import { SurveyReportComponent } from './survey/survey-report/survey-report.component';
import { SurveyReportDetailComponent } from './survey/survey-report-detail/survey-report-detail.component';
import { SurveyProspectiveCandidatesComponent } from './prospective-candidates/survey-prospective-candidates/survey-prospective-candidates.component';
import { EmployeeReferencesComponent } from './onboarding-manage/employee-references/employee-references.component';
import { InjuryQueueComponent } from './injury-queue/injury-queue.component';
import { InjuryQueueGuard } from '../../core/guards/injury-queue.guard';
import { ListMyTimeoffComponent } from './timeoff/list-my-timeoff/list-my-timeoff.component';
import { PaidtimeoffGuard } from '../../core/guards/paidtimeoff.guard';
import { ListPtoManageComponent } from './timeoff/list-pto-manage/list-pto-manage.component';
import { ListTimeoffManageComponent } from './timeoff/list-timeoff-manage/list-timeoff-manage.component';
import { SchedulingTimeoffGuard } from '../../core/guards/scheduling-timeoff.guard';
import { TerminationReasonComponent } from './employee-file/termination-reason/termination-reason.component';
import { NotTermedAuthGuard } from '../../core/guards/not-termed-auth.guard';
import { NotTermedChecksauthGuard } from '../../core/guards/not-termed-checksauth.guard';
import { EmployeeReviewTemplateComponent } from './employee-review/template/template.component';
import { EmployeeReviewTemplateGuard } from '../../core/guards/employee-review-template.guard';
import { ListComponent } from './employee-review/template/list/list.component';
import { ReviewComponent } from './employee-review/player/review/review.component';
import { OversightComponent } from './employee-review/oversight/oversight.component';
import { EmployeeReviewViewComponent } from './employee-review/player/employee-review-view/employee-review-view.component';
import { EmployeeReviewListComponent } from './employee-review/employee-review-list/employee-review-list.component';
import { OversightReviewViewComponent } from './employee-review/oversight/oversight-review-view/oversight-review-view.component';
import { ValueColorManagementComponent } from './employee-review/template/value-color-management/value-color-management.component';
import { ReportCompletedKraComponent } from './employee-review/report-completed-kra/report-completed-kra.component';
import { KraReportGuard } from '../../core/guards/kra-report.guard';
import { ListEthicCodeComponent } from './employee-review/ethic-code/list-ethic-code/list-ethic-code.component';
import { SelfcareCategoryComponent } from './selfcare/selfcare-category/selfcare-category.component';
import { SelfcareOversightComponent } from './selfcare/selfcare-oversight/selfcare-oversight.component';
import { SelfcareManagementComponent } from './selfcare/selfcare-management/selfcare-management.component';
import { ManageSelfcareGuard } from '../../core/guards/manage-selfcare.guard';
import { SelfcareManagementPlannerComponent } from './selfcare/selfcare-management/selfcare-management-planner/selfcare-management-planner.component';
import { SelfcareManageCategoryComponent } from './selfcare/selfcare-management/selfcare-manage-category/selfcare-manage-category.component';
import { SelfcareManagePlannerSectionComponent } from './selfcare/selfcare-management/selfcare-manage-plannersection/selfcare-manage-plannersection.component';
import { SelfcareManagementMenuComponent } from './selfcare/selfcare-management/selfcare-management-menu/selfcare-management-menu.component';
import { ReportPaylocityComponent } from './paylocity/report-paylocity/report-paylocity.component';
import { PaylocityGuard } from '../../core/guards/paylocity.guard';
import { ReportBenefitPlanEDIComponent } from './benefitPlanEDI/report-benefitPlanEDI/report-benefitPlanEDI.component';
import { TransmissionBenefitPlanEDIComponent } from './benefitPlanEDI/transmission-benefitPlanEDI/transmission-benefitPlanEDI.component';
import { BenefitPlanEDIGuard } from '../../core/guards/benefitPlanEDI.guard';
import { ListBenefitsPlansComponent } from './benefits-management/list-benefits-plans/list-benefits-plans.component';
import { BenefitsManagementGuard } from 'src/app/core/guards/benefits-management.guard';
import { BenefitsPlanAddItComponent } from './benefits-management/benefits-plan-addit/benefits-plan-addit.component';
import { DependentsComponent } from './benefits-enrollment/dependents/dependents.component';
import { BenefitsAccessGuard } from 'src/app/core/guards/benefits-access.guard';
import { BenefitsEnrollmentComponent } from './benefits-enrollment/enrollment/benefits-enrollment.component';
import { ListMyEnrollmentFormDocsComponent } from './benefits-enrollment/list-my-enrollmentformdocs/list-my-enrollmentformdocs.component';
import { QueueEligibilityStatusComponent } from './benefits-management/queue-eligibility-status/queue-eligibility-status.component';
import { BenefitsReportGuard } from '../../core/guards/benefits-report.guard';
import { ListBenefitEligibilityStatusComponent } from './benefits-management/list-benefit-eligibility-status/list-benefit-eligibility-status.component';
import { JobApplicationsComponent } from './job-applications/job-applications.component';
import { ApplicationDashboardComponent } from './job-applications/application-dashboard/application-dashboard.component';
import { ManageApplicationsGuard } from 'src/app/core/guards/manage-applications.guard';

import { ListBenefitsEmployeesComponent } from './benefits-management/list-benefits-employees/list-benefits-employees.component';
import { ListBenefitDeclinedComponent } from './benefits-management/list-benefit-declined/list-benefit-declined.component';
import { ListBenefitWithdrawnComponent } from './benefits-management/list-benefit-withdrawn/list-benefit-withdrawn.component';
import { ListBenefitLogsComponent } from './benefits-management/list-benefit-logs/list-benefit-logs.component';
import { ListBenefitHsaComponent } from './benefits-management/list-benefit-hsa/list-benefit-hsa.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { IndeedJobApplicationComponent } from './job-applications/indeed-application/indeed-job-application/indeed-job-application.component';
import { UploadDocsApprovalComponent } from './uploaddocs/uploaddocs-approval/uploaddocs-approval.component';
import { UploadDocsApprovalViewGuard } from 'src/app/core/guards/uploaddocs-approval-view.guard';
import { ListUploaddocsApprovalEmployeesComponent } from './uploaddocs/list-uploaddocs-approval-employees/list-uploaddocs-approval-employees.component';
import { ListTimecardManualEntriesComponent } from './payroll/list-timecard-manual-entries/list-timecard-manual-entries.component';
import { ListUploaddocsVaccineInfoComponent } from './uploaddocs/list-uploaddocs-vaccine-info/list-uploaddocs-vaccine-info.component';
import { FormBuilderHomeComponent } from './form-builder/form-builder-home.component';
import { FormBuilderManagementGuard } from 'src/app/core/guards/form-builder-management.guard';
import { ListFormsComponent } from './form-builder/form-list/list-forms.component';
import { FormsAddItComponent } from './form-builder/form-addit/form-addit.component';
import { FormsAvailableComponent } from './form-filling/forms-available/forms-available.component';
import { FormFillComponent } from './form-filling/form-fill/form-fill.component';
import { FormSignComponent } from './form-filling/form-sign/form-sign.component';
import { FormsAssignmentComponent } from './form-builder/forms-assignment/forms-assignment.component';
import { FormAssignmentCreateComponent } from './form-builder/form-assignment-create/form-assignment-create.component';
import { FormSubmissionComponent } from './form-builder/form-submission/form-submission.component';
import { FormAssignmentRoleComponent } from './form-builder/form-assignment-role/form-assignment-role.component';
import { ListSelfcareCompleteComponent } from './selfcare/list-selfcare-complete/list-selfcare-complete.component';
import { SelfcareReportGuard } from '../../core/guards/selfcare-report.guard';
import { ListCertificationComponent } from './certification/list-certification/list-certification.component';
import { CertificationGuard } from '../../core/guards/certification.guard';
import { InstructorListCertificationComponent } from './certification/instructor-list-certification/instructor-list-certification.component';
import { ApplicationSuccessPageComponent } from './job-applications/application-success-page/application-success-page.component';
import { ReportKraComponent } from './employee-review/report-kra/report-kra.component';
import { SignEnrollmentSetComponent } from './benefits-enrollment/sign-enrollmentset/sign-enrollmentset.component';
import { MedclinicCreatePatientComponent } from './medclinic/create-patient/medclinic-create-patient.component';
import { CriticalNewsFeedViewComponent } from './critical-news-feed/critical-news-feed-view/critical-news-feed-view.component';
import { EditPayableItemComponent } from './payroll/edit-payable-item/edit-payable-item.component';
import { ListCeuEventComponent } from './certification/list-ceu-event/list-ceu-event.component';
import { ListApplicationComponent } from './intern/list-application/list-application.component';
import { ListManageComponent } from './intern/list-manage/list-manage.component';
import { InternGuard } from '../../core/guards/intern.guard';
import { ListInternActionTypeManageComponent } from './intern/list-intern-action-type-manage/list-intern-action-type-manage.component';
import { InternReviewProcessComponent } from './intern/intern-review-process/intern-review-process.component';
import { ListInternSubstatusComponent } from './intern/list-intern-substatus/list-intern-substatus.component';
import { ListInternActionItemTemplateComponent } from './intern/list-intern-action-item-template/list-intern-action-item-template.component';
import { TicketTypesListComponent } from './ticket-types/ticket-types-list/ticket-types-list.component';
import { TicketTypeAdditComponent } from './ticket-types/ticket-types-addit/ticket-types-addit.component';
import { FormAssignmentJobtitleComponent } from './form-builder/form-assignment-jobtitle/form-assignment-jobtitle.component';

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [NotTermedAuthGuard], },
  { path: 'application/utbs', component: JobApplicationsComponent },
  { path: 'application/utbs1', component: IndeedJobApplicationComponent },
  { path: 'application-success', component: ApplicationSuccessPageComponent },
  { path: 'management', component: ManagementComponent, canActivate: [ChecksAuthGuard], },

  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard], },
  { path: 'timecard', component: TimeCardComponent, canActivate: [ChecksAuthGuard], },
  { path: 'payroll', component: PayrollComponent, canActivate: [NotTermedChecksauthGuard], },
  { path: 'payroll/approval', component: PayrollApprovalComponent, canActivate: [PayrollApprovalGuard], },
  { path: 'payroll/manualEntries', component: ListTimecardManualEntriesComponent, canActivate: [PayrollApprovalGuard], },
  { path: 'payroll/edit-item', component: EditPayableItemComponent, canActivate: [PayrollApprovalGuard] },

  { path: 'timeoff/ptoSummary', component: ListPtoSummaryComponent, canActivate: [PaidtimeoffGuard] },
  { path: 'timeoff/pto/manage', component: ListPtoManageComponent, canActivate: [PaidtimeoffGuard] },
  { path: 'timeoff/pto/my', component: ListMyPtoComponent, canActivate: [ChecksAuthGuard] },
  { path: 'timeoff/pto/my/:userId', component: ListMyPtoComponent, canActivate: [PaidtimeoffGuard] },
  { path: 'timeoff', component: ListMyTimeoffComponent, canActivate: [ChecksAuthGuard] },
  { path: 'timeoff/my', component: ListMyTimeoffComponent, canActivate: [ChecksAuthGuard] },
  { path: 'timeoff/scheduling/manage', component: ListTimeoffManageComponent, canActivate: [SchedulingTimeoffGuard] },

  { path: 'offerletter', component: OfferLetterComponent, canActivate: [NotTermedChecksauthGuard], },
  { path: 'offerletter/create', component: CreateOfferLetterComponent, canActivate: [OfferLetterViewGuard], },
  { path: 'offerletter/create/:offerLetterID', component: CreateOfferLetterComponent, canActivate: [OfferLetterViewGuard], },
  { path: 'offerletter/list', component: ListOfferLetterComponent, canActivate: [OfferLetterViewGuard], },

  { path: 'equipment/create', component: CreateEquipmentComponent, canActivate: [EquipmentManageGuard], },
  { path: 'equipment/my', component: ListMyEquipmentComponent, canActivate: [ChecksAuthGuard], },
  { path: 'equipment/list', component: ListEquipmentComponent, canActivate: [EquipmentViewGuard], },
  { path: 'equipment/assign', component: AssignEquipmentComponent, canActivate: [EquipmentManageGuard], },
  { path: 'equipment/signReturn/:assignedEquipmentID', component: SignReturnEquipmentComponent, canActivate: [EquipmentManageGuard], },
  { path: 'equipment/signAssign/:assignedEquipmentID', component: SignAssignEquipmentComponent, canActivate: [ChecksAuthGuard], },

  { path: 'onboardingdocs/sign/:onboardingDocUserId', component: SignOnboardingDocComponent, canActivate: [AuthGuard], },
  { path: 'onboardingdocs/list', component: ListMyOnboardingDocsComponent, canActivate: [AuthGuard], },
  { path: 'onboardingdocs/fill-info/:onboardingDocUserId', component: DocFillInfoComponent, canActivate: [AuthGuard], },
  { path: 'onboardingdocs/fill-info-hr/:onboardingDocUserId', component: DocFillInfoHRComponent, canActivate: [OnboardViewGuard], },
  { path: 'onboardingdocs/report', component: ListReportOnboardingdocsComponent, canActivate: [OnboardViewGuard], },
  { path: 'onboardingdocs/download/:onboardingDocUserId', component: DownloadOnboardingdocComponent, canActivate: [OnboardViewGuard], },
  { path: 'manage/list-onboardingdocs', component: ListOnboardingDocsComponent, canActivate: [OnboardViewGuard], },
  { path: 'manage/edit-onboardingdoc/:onboardingDocId', component: EditOnboardingDocComponent, canActivate: [OnboardViewGuard], },
  { path: 'manage/list-onboardinguserdocs', component: ListOnboardingUserDocsComponent, canActivate: [OnboardViewGuard], },

  {
    path: 'onboarding', component: OnboardingComponent, canActivate: [AuthGuard], children: [
      { path: 'onboardingdocs/sign/:onboardingDocUserId', component: SignOnboardingDocComponent, canActivate: [AuthGuard], },
      { path: 'onboardingdocs/list', component: ListMyOnboardingDocsComponent, canActivate: [AuthGuard], },
      { path: 'onboardingdocs/fill-info/:onboardingDocUserId', component: DocFillInfoComponent, canActivate: [AuthGuard], },


      { path: 'form-filling/form-submission/:formID/:formSubmissionID', component: FormSubmissionComponent, canActivate: [AuthGuard], },
      { path: 'form-filling/list', component: ListMyOnboardingDocsComponent, canActivate: [AuthGuard], },
      { path: 'form-filling/fill/:formID/:formSubmissionID', component: FormFillComponent, canActivate: [AuthGuard], },
      { path: 'form-filling/fill/:formID', component: FormFillComponent, canActivate: [AuthGuard], },
      { path: 'form-filling/sign/:formSubmissionID', component: FormSignComponent, canActivate: [AuthGuard], },
    ]
  },

  { path: 'prospective-candidate/list', component: ListProspectiveCandidatesComponent, canActivate: [ProspectiveCandidatesViewGuard], },
  { path: 'prospective-candidate/addit', component: ProspectiveCandidatesAddItComponent, canActivate: [ProspectiveCandidatesViewGuard], },
  { path: 'prospective-candidate/addit/:prospectiveCandidateID', component: ProspectiveCandidatesAddItComponent, canActivate: [ProspectiveCandidatesViewGuard], },
  { path: 'prospective-candidate/survey', component: SurveyProspectiveCandidatesComponent, },
  { path: 'prospective-candidate/employee-references/:prospectiveCandidateID', component: EmployeeReferencesComponent, canActivate: [ProspectiveCandidatesViewGuard] },

  { path: 'uploaddocs', component: UploadDocsComponent, canActivate: [AuthGuard], },
  { path: 'uploaddocs/approval-list', component: ListUploaddocsApprovalEmployeesComponent, canActivate: [UploadDocsApprovalViewGuard], },
  { path: 'uploaddocs/approval/:userid', component: UploadDocsApprovalComponent, canActivate: [UploadDocsApprovalViewGuard], },
  { path: 'uploaddocs/vaccine-list', component: ListUploaddocsVaccineInfoComponent, canActivate: [UploadDocsApprovalViewGuard], },


  { path: 'coachinglogs/my', component: ListMyCoachingLogComponent, canActivate: [AuthGuard], },
  { path: 'coachinglogs/create', component: CreateCoachingLogComponent, canActivate: [AuthGuard], },
  { path: 'coachinglogs/create/:previousCoachingLogID', component: CreateCoachingLogComponent, canActivate: [AuthGuard], },
  { path: 'coachinglogs/given', component: ListGivenCoachingLogsComponent, canActivate: [AuthGuard], },


  { path: 'improvementplan/create', component: CreateImprovementPlanComponent, canActivate: [AuthGuard], },
  { path: 'improvementplan/create/:improvementPlanID', component: CreateImprovementPlanComponent, canActivate: [AuthGuard], },
  { path: 'improvementplan/continue/:previousCoachingLogID', component: CreateImprovementPlanComponent, canActivate: [AuthGuard], },
  { path: 'improvementplan/sign/:improvementPlanID', component: SignImprovementPlanComponent, canActivate: [AuthGuard], },
  { path: 'improvementplan/view/:improvementPlanID', component: ViewImprovementPlanComponent, canActivate: [AuthGuard], },
  { path: 'improvementplan/:improvementPlanID/review/create', component: CreateImprovementPlanReviewComponent, canActivate: [AuthGuard], },
  {
    path: 'improvementplan/:improvementPlanID/review/create/:improvementPlanReviewID',
    component: CreateImprovementPlanReviewComponent, canActivate: [AuthGuard],
  },
  {
    path: 'improvementplan/:improvementPlanID/review/sign/:improvementPlanReviewID',
    component: SignImprovementPlanReviewComponent, canActivate: [AuthGuard],
  },
  { path: 'onboarding-manage/list', component: OnboardingManageListComponent, canActivate: [OnboardViewGuard] },
  {
    path: 'onboarding-manage/detail/:userid', component: OnboardingComponent, canActivate: [OnboardViewGuard], children: [
      { path: 'onboardingdocs/fill-info-hr/:onboardingDocUserId', component: DocFillInfoHRComponent, canActivate: [OnboardViewGuard], },
      { path: 'onboardingdocs/list', component: ListMyOnboardingDocsComponent, canActivate: [OnboardViewGuard], },
      { path: 'onboardingdocs/sign/:onboardingDocUserId', component: SignOnboardingDocComponent, canActivate: [OnboardViewGuard], },



      { path: 'form-filling/form-submission/:formID/:formSubmissionID', component: FormSubmissionComponent, canActivate: [OnboardViewGuard], },
      { path: 'form-filling/list', component: ListMyOnboardingDocsComponent, canActivate: [OnboardViewGuard], },
      { path: 'form-filling/fill/:formID/:formSubmissionID', component: FormFillComponent, canActivate: [OnboardViewGuard], },
      { path: 'form-filling/fill/:formID', component: FormFillComponent, canActivate: [OnboardViewGuard], },
      { path: 'form-filling/sign/:formSubmissionID', component: FormSignComponent, canActivate: [OnboardViewGuard], },

    ]
  },
  { path: 'employee-file/list', component: EmployeeFileListComponent, canActivate: [EmployeeFileViewGuard] },
  { path: 'employee-file/termination-reason', component: TerminationReasonComponent, canActivate: [EmployeeFileViewGuard] },
  {
    path: 'employee-file/detail/:userid', component: OnboardingComponent, canActivate: [EmployeeFileViewGuard], children: [
      { path: 'onboardingdocs/list', component: ListMyOnboardingDocsComponent, canActivate: [EmployeeFileViewGuard], },
      { path: 'onboardingdocs/fill-info-hr/:onboardingDocUserId', component: DocFillInfoHRComponent, canActivate: [EmployeeFileViewGuard], },
      { path: 'onboardingdocs/sign/:onboardingDocUserId', component: SignOnboardingDocComponent, canActivate: [EmployeeFileViewGuard], },
      { path: 'reviews', component: EmployeeReviewListComponent, canActivate: [EmployeeFileViewGuard] },

      { path: 'form-filling/form-submission/:formID/:formSubmissionID', component: FormSubmissionComponent, canActivate: [EmployeeFileViewGuard], },
      { path: 'form-filling/list', component: ListMyOnboardingDocsComponent, canActivate: [EmployeeFileViewGuard], },
      { path: 'form-filling/fill/:formID/:formSubmissionID', component: FormFillComponent, canActivate: [EmployeeFileViewGuard], },
      { path: 'form-filling/fill/:formID', component: FormFillComponent, canActivate: [EmployeeFileViewGuard], },
      { path: 'form-filling/sign/:formSubmissionID', component: FormSignComponent, canActivate: [EmployeeFileViewGuard], },
    ]
  },
  { path: 'survey/answer/:surveyID', component: SurveyAnswerComponent, canActivate: [AuthGuard], },
  { path: 'survey/list', component: SurveyListComponent, canActivate: [AuthGuard], },
  { path: 'survey/report', component: SurveyReportComponent, canActivate: [AuthGuard], },
  { path: 'survey/report/detail/:surveyTypeID/:mentorUserID', component: SurveyReportDetailComponent, canActivate: [AuthGuard], },

  { path: 'holiday/list', component: ListHolidayComponent, canActivate: [HolidayGuard] },
  { path: 'holiday/recurring', component: RecurringHolidayComponent, canActivate: [HolidayGuard] },
  { path: 'holiday/edit-holiday', component: EditHolidayComponent, canActivate: [HolidayGuard] },
  { path: 'holiday/edit-recurring', component: EditRecurringComponent, canActivate: [HolidayGuard] },

  { path: 'certification/report', component: ListCertificationComponent, canActivate: [CertificationGuard] },
  { path: 'certification/instructors', component: InstructorListCertificationComponent, canActivate: [CertificationGuard] },
  { path: 'certification/events', component: ListCeuEventComponent, canActivate: [CertificationGuard] },

  { path: 'intern/list-application', component: ListApplicationComponent, canActivate: [ChecksAuthGuard] },
  { path: 'intern/list-manage', component: ListManageComponent, canActivate: [InternGuard] },
  { path: 'intern/actionType/list', component: ListInternActionTypeManageComponent, canActivate: [InternGuard] },
  { path: 'intern/reviewProcess/:applicationId', component: InternReviewProcessComponent, canActivate: [InternGuard] },
  { path: 'intern/substatus/list', component: ListInternSubstatusComponent, canActivate: [InternGuard] },
  { path: 'intern/actionItemTemplate/list', component: ListInternActionItemTemplateComponent, canActivate: [InternGuard] },

  { path: 'employee/fullTimeStatus/report', component: ListReportFulltimestatusComponent, canActivate: [FullTimeStatusGuard] },
  { path: 'employee/fullTimeStatus/queue', component: QueueFulltimestatusComponent, canActivate: [FullTimeStatusGuard] },
  { path: 'employee/census', component: ReportCensusComponent, canActivate: [EmployeeReportsGuard] },
  { path: 'employee/terminated', component: ReportTerminatedComponent, canActivate: [EmployeeReportsGuard] },

  { path: 'injuryqueue', component: InjuryQueueComponent, canActivate: [InjuryQueueGuard] },

  { path: 'employee-review/template', component: EmployeeReviewTemplateComponent, canActivate: [EmployeeReviewTemplateGuard] },
  { path: 'employee-review/template/list', component: ListComponent, canActivate: [EmployeeReviewTemplateGuard] },
  { path: 'employee-review/template/:templateid', component: EmployeeReviewTemplateComponent, canActivate: [EmployeeReviewTemplateGuard] },

  { path: 'employee-review/ethics-codes', component: ListEthicCodeComponent, canActivate: [EmployeeReviewTemplateGuard] },

  { path: 'employee-review/player', component: ReviewComponent },
  { path: 'employee-review/player/:reviewId', component: ReviewComponent },
  { path: 'employee-review/oversight/view/:reviewId', component: OversightReviewViewComponent },
  { path: 'employee-review/oversight/:userId', component: OversightComponent },
  { path: 'employee-review/oversight/:userId/:templateID', component: OversightComponent },

  { path: 'employee-review/view/:reviewId', component: EmployeeReviewViewComponent },
  { path: 'employee-review/list/:userId', component: EmployeeReviewListComponent },

  { path: 'employee-review/valuecolor', component: ValueColorManagementComponent, canActivate: [EmployeeReviewTemplateGuard] },

  { path: 'employee-review/list/:userId', component: EmployeeReviewListComponent },

  { path: 'employee-review/report/completedKra', component: ReportCompletedKraComponent, canActivate: [KraReportGuard] },
  { path: 'employee-review/report/kra', component: ReportKraComponent, canActivate: [KraReportGuard] },

  { path: 'selfcare', component: SelfcareComponent },
  { path: 'selfcare/category/:categoryID', component: SelfcareCategoryComponent },
  { path: 'selfcare/preferences', component: SelfcareCategoryComponent },
  { path: 'selfcare/planner', component: SelfcarePlannerComponent },
  { path: 'selfcare/oversight', component: SelfcareOversightComponent },
  { path: 'selfcare/oversight/:userID/main', component: SelfcareComponent },
  { path: 'selfcare/oversight/:userID/planner', component: SelfcarePlannerComponent },
  { path: 'selfcare/oversight/:userID/category/:categoryID', component: SelfcareCategoryComponent },
  { path: 'selfcare/management', component: SelfcareManagementComponent, canActivate: [ManageSelfcareGuard] },
  { path: 'selfcare/management/category/:categoryID', component: SelfcareManageCategoryComponent, canActivate: [ManageSelfcareGuard] },
  { path: 'selfcare/management/planner', component: SelfcareManagementPlannerComponent, canActivate: [ManageSelfcareGuard] },
  {
    path: 'selfcare/management/plannersection/:plannerSectionID',
    component: SelfcareManagePlannerSectionComponent, canActivate: [ManageSelfcareGuard]
  },
  { path: 'selfcare/management/menu', component: SelfcareManagementMenuComponent, canActivate: [ManageSelfcareGuard] },
  { path: 'selfcare/report', component: ListSelfcareCompleteComponent, canActivate: [SelfcareReportGuard]},

  { path: 'paylocity/report', component: ReportPaylocityComponent, canActivate: [PaylocityGuard] },

  { path: 'benefitPlanEDI/tramissions', component: ReportBenefitPlanEDIComponent, canActivate: [BenefitPlanEDIGuard] },
  { path: 'benefitPlanEDI/tramission/:transmissionId', component: TransmissionBenefitPlanEDIComponent, canActivate: [BenefitPlanEDIGuard] },
  { path: 'benefitPlanEDI/current/:carrier', component: TransmissionBenefitPlanEDIComponent, canActivate: [BenefitPlanEDIGuard] },

  { path: 'jobapplications/management', component: ApplicationDashboardComponent, canActivate: [ManageApplicationsGuard] },


  { path: 'benefits-management/benefit-plan/list', component: ListBenefitsPlansComponent, canActivate: [BenefitsManagementGuard] },
  { path: 'benefits-management/benefit-plan/addit', component: BenefitsPlanAddItComponent, canActivate: [BenefitsManagementGuard] },
  {
    path: 'benefits-management/benefit-plan/addit/:benefitPlanID',
    component: BenefitsPlanAddItComponent, canActivate: [BenefitsManagementGuard]
  },
  { path: 'benefits-management/benefit-classes', component: BenefitsClassesComponent, canActivate: [BenefitsManagementGuard] },
  { path: 'benefits-management/status-check/queue', component: QueueEligibilityStatusComponent, canActivate: [BenefitsReportGuard] },
  { path: 'benefits-management/status-check/list', component: ListBenefitEligibilityStatusComponent, canActivate: [BenefitsReportGuard] },
  { path: 'benefits-management/declined', component: ListBenefitDeclinedComponent, canActivate: [BenefitsReportGuard] },
  { path: 'benefits-management/withdrawn', component: ListBenefitWithdrawnComponent, canActivate: [BenefitsReportGuard] },
  { path: 'benefits-management/logs', component: ListBenefitLogsComponent, canActivate: [BenefitsReportGuard] },
  { path: 'benefits-management/hsa', component: ListBenefitHsaComponent, canActivate: [BenefitsReportGuard] },


  { path: 'benefits-enrollment/dependents', component: DependentsComponent, canActivate: [BenefitsAccessGuard] },
  { path: 'benefits-enrollment/enrollment', component: BenefitsEnrollmentComponent, canActivate: [BenefitsAccessGuard] },
  {
    path: 'benefits-enrollment/docs/sign/:benefitPlanFormDocumentsEnrollmentID',
    component: SignEnrollmentFormDocComponent, canActivate: [AuthGuard],
  },
  {
    path: 'benefits-enrollment/sign/:benefitPlanEnrollmentSetID',
    component: SignEnrollmentSetComponent, canActivate: [AuthGuard],
  },
  { path: 'benefits-enrollment/docs/list', component: ListMyEnrollmentFormDocsComponent, canActivate: [AuthGuard], },

  { path: 'benefits-management/:userId/docs/list', component: ListMyEnrollmentFormDocsComponent, canActivate: [AuthGuard], },
  {
    path: 'benefits-management/:userId/docs/sign/:benefitPlanFormDocumentsEnrollmentID',
    component: SignEnrollmentFormDocComponent, canActivate: [AuthGuard],
  },
  {
    path: 'benefits-management/benefit-employees/list',
    component: ListBenefitsEmployeesComponent, canActivate: [BenefitsManagementGuard],
  },

  { path: 'form-builder/home', component: FormBuilderHomeComponent, canActivate: [FormBuilderManagementGuard], },
  { path: 'form-builder/forms', component: ListFormsComponent, canActivate: [FormBuilderManagementGuard], },
  { path: 'form-builder/form', component: FormsAddItComponent, canActivate: [FormBuilderManagementGuard], },
  { path: 'form-builder/form/:formID', component: FormsAddItComponent, canActivate: [FormBuilderManagementGuard], },
  { path: 'form-builder/forms-assignment', component: FormsAssignmentComponent, canActivate: [FormBuilderManagementGuard], },
  { path: 'form-builder/form-assignment-create', component: FormAssignmentCreateComponent, canActivate: [FormBuilderManagementGuard], },
  { path: 'form-builder/form-submission/:formID/:formSubmissionID', component: FormSubmissionComponent, canActivate: [FormBuilderManagementGuard], },
  { path: 'form-builder/form-assignment-roles', component: FormAssignmentRoleComponent, canActivate: [FormBuilderManagementGuard], },
  { path: 'form-builder/form-assignment-roles/:formID', component: FormAssignmentRoleComponent, canActivate: [FormBuilderManagementGuard], },
  { path: 'form-builder/form-assignment-job-titles', component: FormAssignmentJobtitleComponent, canActivate: [FormBuilderManagementGuard], },
  { path: 'form-builder/form-assignment-job-titles/:formID', component: FormAssignmentJobtitleComponent, canActivate: [FormBuilderManagementGuard], },


  { path: 'form-filling/form-submission/:formID/:formSubmissionID', component: FormSubmissionComponent, canActivate: [FormBuilderManagementGuard], },
  { path: 'form-filling/list', component: FormsAvailableComponent, canActivate: [AuthGuard], },
  { path: 'form-filling/fill/:formID/:formSubmissionID', component: FormFillComponent, canActivate: [AuthGuard], },
  { path: 'form-filling/fill/:formID', component: FormFillComponent, canActivate: [AuthGuard], },
  { path: 'form-filling/sign/:formSubmissionID', component: FormSignComponent, canActivate: [AuthGuard], },

  { path: 'medclinic/patients-list', component: MedclinicPatientsListComponent, canActivate: [AuthGuard], },
  { path: 'medclinic/create-patient', component: MedclinicCreatePatientComponent, canActivate: [AuthGuard], },

  { path: 'criticalnewsfeed', component: CriticalNewsFeedViewComponent, canActivate: [AuthGuard], },

  { path: 'ticket-types/list', component: TicketTypesListComponent, canActivate: [AuthGuard], },
  { path: 'ticket-types/new', component: TicketTypeAdditComponent, canActivate: [AuthGuard] },
  { path: 'ticket-types/edit/:id', component: TicketTypeAdditComponent, canActivate: [AuthGuard] },

  { path: 'page-not-found', component: PageNotFoundComponent },
  { path: '**', component: PageNotFoundComponent },


];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class MainRoutingModule { }

