import { ImprovementPlanModel, ImprovementPlanReviewModel } from './../../../models/improvementPlanModel';
import { CoachingLogWithUsersModel } from './../../../models/coachingLogWithUsersModel';
import { createAction, props } from '@ngrx/store';
import { FormError } from 'src/app/models/utilModels';

export const createImprovementPlan = createAction('[Improvement Plan]  Create Improvement Plan',
  props<{ improvementPlanModel: ImprovementPlanModel }>());
export const createImprovementPlanSuccess = createAction('[Improvement Plan]  Create Improvement Plan Success',
  props<{ improvementPlanModel: ImprovementPlanModel }>());
export const createImprovementPlanFail = createAction('[Improvement Plan]  Create Improvement Plan Fail',
  props<{ err: any }>());

export const createImprovementPlanReview = createAction('[Improvement Plan Review]  Create Improvement Plan Review',
  props<{ improvementPlanReviewModel: ImprovementPlanReviewModel }>());
export const createImprovementPlanReviewSuccess = createAction('[Improvement Plan Review]  Create Improvement Plan Review Success',
  props<{ improvementPlanReviewModel: ImprovementPlanReviewModel }>());
export const createImprovementPlanReviewFail = createAction('[Improvement Plan Review]  Create Improvement Plan Review Fail',
  props<{ err: any }>());

export const getImprovementPlan = createAction('[Improvement Plan] Get Improvement Plan',
  props<{ improvementPlanId: any }>());
export const getImprovementPlanSuccess = createAction('[Improvement Plan] Get Improvement Plan Success',
  props<{ improvementPlan: ImprovementPlanModel }>());

export const updateCreateImprovementPlanForm = createAction('[Improvement Plan]  Update Improvement Plan Form',
  props<{ improvementPlan: any; formErrors: FormError[] }>());


export const getImprovementPlanReview = createAction('[Improvement Plan] Get Improvement Plan Review',
  props<{ improvementPlanReviewId: any }>());
export const getImprovementPlanReviewSuccess = createAction('[Improvement Plan] Get Improvement Plan Review Success',
  props<{ improvementPlanReview: ImprovementPlanReviewModel }>());

export const updateCreateImprovementPlanReviewForm = createAction('[Improvement Plan]  Update Improvement Plan Review Form',
  props<{ improvementPlanReview: any; formErrorsReview: FormError[] }>());


export const downloadImprovementPlanDoc = createAction('[Improvement Plan] Download Improvement Plan Doc',
  props<{ improvementPlanID: number }>());
export const downloadImprovementPlanDocSuccess = createAction('[Improvement Plan] Download Improvement Plan Doc Success',
  props<{ doc: ArrayBuffer }>());


export const downloadImprovementPlanReviewDoc = createAction('[Improvement Plan] Download Improvement Plan Review Doc',
  props<{ improvementPlanReviewID: number }>());
export const downloadImprovementPlanReviewDocSuccess = createAction('[Improvement Plan] Download Improvement Plan Review Doc Success',
  props<{ doc: ArrayBuffer }>());

export const signImprovementPlanDoc = createAction('[Improvement Plan] Sign Improvement Plan Doc',
  props<{ improvementPlanID: number, signedFields: string[] }>());
export const signImprovementPlanDocSuccess = createAction('[Improvement Plan]  Sign Improvement Plan Doc Success',
  props<{ docSigned: ArrayBuffer }>());
export const signImprovementPlanDocFailure = createAction('[Improvement Plan]  Sign Improvement Plan Failure');

export const signImprovementPlanReviewDoc = createAction('[Improvement Plan] Sign Improvement Plan Review Doc',
  props<{ improvementPlanReviewID: number, signedFields: string[] }>());
export const signImprovementPlanReviewDocSuccess = createAction('[Improvement Plan]  Sign Improvement Plan Review Doc Success',
  props<{ docSigned: ArrayBuffer }>());
export const signImprovementPlanReviewDocFailure = createAction('[Improvement Plan]  Sign Improvement Plan Review Failure');


export const clearImprovementPlan = createAction('[Improvement Plan]  Clear Improvement Plan ');
