import { SurveyFullAnswerModel, SurveyModel, SurveyReportModel, SurveyReportDetailModel } from './../../../models/surveyModel';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { UserProfileModel } from '../../../models/userProfileModel';
import { createReducer, on, Action } from '@ngrx/store';
import { getAllControlsErrors } from 'src/app/helpers/utils';
import { FormError } from 'src/app/models/utilModels';
import {
  loadSurveyInfo, loadSurveyInfoSuccess, saveSurveyAnswer,
  saveSurveyAnswerSuccess, saveSurveyAnswerFail, loadSurveyInfoFail,
  updateSurveyAnswerForm, loadMyUnansweredSurvey, loadMyUnansweredSurveySuccess,
  loadMyUnansweredSurveyFail, loadSurveyReport, loadSurveyReportSuccess,
  loadSurveyReportFail, loadSurveyReportDetail, loadSurveyReportDetailSuccess, loadSurveyReportDetailFail
} from './survey.actions';

export interface SurveyState {
  surveyFullAnswer: SurveyFullAnswerModel;
  survey: SurveyModel;
  formErrors: FormError[];
  loandingSurvey: boolean;
  savingAnswer: boolean;

  mySurveys: SurveyModel[];
  loadingMySurvey: boolean;

  surveyReport: SurveyReportModel[];
  loadingSurveyReport: boolean;

  surveyReportDetail: SurveyReportDetailModel[];
  loadingSurveyReportDetail: boolean;
}

const initialState: SurveyState = {
  surveyFullAnswer: null,
  survey: null,
  formErrors: [],
  loandingSurvey: false,
  savingAnswer: false,

  mySurveys: [],
  loadingMySurvey: false,

  surveyReport: [],
  loadingSurveyReport: false,

  surveyReportDetail: [],
  loadingSurveyReportDetail: false,
};

export const surveyReducer = createReducer(initialState,
  on(loadSurveyInfo, (state, action) => ({ ...state, loadingMySurvey: true })),
  on(loadSurveyInfoSuccess, (state, action) => {
    let formErrors;
    if (action.survey) {
      const form = initSurveyAnswerForm(action.survey);
      formErrors = getAllControlsErrors(form);
    }
    return {
      ...state,
      survey: action.survey,
      formErrors,
      loadingMySurvey: false,
    };
  }),
  on(loadSurveyInfoFail, (state, action) => ({ ...state, loadingMySurvey: false })),
  on(updateSurveyAnswerForm, (state, action) => {

    const newSurveyFullAnswer = { ...state.surveyFullAnswer, ...action.generalValues, ...{ answers: action.answerValues } };
    const newState = { ...state, ...{ surveyFullAnswer: newSurveyFullAnswer, formErrors: action.formErrors } };
    return newState;
  }),
  on(saveSurveyAnswer, (state, action) => ({ ...state, savingAnswer: true })),
  on(saveSurveyAnswerSuccess, (state, action) => ({ ...state, savingAnswer: false })),
  on(saveSurveyAnswerFail, (state, action) => ({ ...state, savingAnswer: false })),

  on(loadMyUnansweredSurvey, (state, action) => ({ ...state, loandingMySurvey: true })),
  on(loadMyUnansweredSurveySuccess, (state, action) => {
    return {
      ...state,
      mySurveys: action.surveys,
      loandingMySurvey: false,
    };
  }),
  on(loadMyUnansweredSurveyFail, (state, action) => ({ ...state, loandingMySurvey: false })),

  on(loadSurveyReport, (state, action) => ({ ...state, loadingSurveyReport: true })),
  on(loadSurveyReportSuccess, (state, action) => {
    return {
      ...state,
      surveyReport: action.surveyReport,
      loadingSurveyReport: false,
    };
  }),
  on(loadSurveyReportFail, (state, action) => ({ ...state, loadingSurveyReport: false })),

  on(loadSurveyReportDetail, (state, action) => ({ ...state, loadingSurveyReportDetail: true })),
  on(loadSurveyReportDetailSuccess, (state, action) => {
    return {
      ...state,
      surveyReportDetail: action.surveyReportDetail,
      loadingSurveyReportDetail: false,
    };
  }),
  on(loadSurveyReportDetailFail, (state, action) => ({ ...state, loadingSurveyReportDetail: false })),
);

export function surveyReducerFunc(state: SurveyState | undefined, action: Action) {
  return surveyReducer(state, action);
}


export function initSurveyAnswerForm(survey: SurveyModel) {

  const answersArray = new FormArray([]);
  for (const question of survey.surveyType.surveyQuestions) {
    answersArray.push(new FormGroup({
      surveyID: new FormControl(survey.id),
      surveyQuestionID: new FormControl(question.id),
      answer: new FormControl(null, [Validators.required]),
      comment: new FormControl('', [Validators.maxLength(3000)]),
    }));
  }

  const form = new FormGroup({
    general: new FormGroup({
      generalComment: new FormControl('', [Validators.maxLength(3000)]),
      surveyID: new FormControl(survey.id),
    }),
    answers: answersArray
  });

  return form;
}

