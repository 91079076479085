
  <div class="card" >
    <div class="card-body">
      <button class="btn btn-primary float-right pointer" routerLink="/uploaddocs/approval-list">Back to list</button>

      <app-onboarding-uploaddocs *ngIf="profile" [profile]="profile"
      [managedUserId]="managedUserId"
      [showOnlyOnApprovalList]="true"></app-onboarding-uploaddocs>
    </div>
  </div>
