import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { State } from '..';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { errorHappened } from '../profile/profile.actions';
import { SelfcareService } from "../../services/selfcare.service";
import {
  deleteSelfcarePlannerNote,
  deleteSelfcarePlannerNoteFailure,
  deleteSelfcarePlannerNoteSuccess,
  exportSelfCareCompletionListToExcel,
  exportSelfCareCompletionListToExcelFailure,
  exportSelfCareCompletionListToExcelSuccess,
  getSelfCareCompletionList,
  getSelfCareCompletionListSuccess,
  loadSelfcareCategories, loadSelfcareCategoriesFailure, loadSelfcareCategoriesSuccess,
  loadSelfcareCategory, loadSelfcareCategoryFailure, loadSelfcareCategorySuccess,
  loadSelfcareOverSightEmployees,
  loadSelfcareOverSightEmployeesFailure,
  loadSelfcareOverSightEmployeesSuccess,
  loadSelfcarePlanner, loadSelfcarePlannerFailure, loadSelfcarePlannerSuccess, loadSelfcarePreferences, saveSelfcareAnwers, saveSelfcareAnwersFailure, saveSelfcareAnwersSuccess, saveSelfcarePlannerNote, saveSelfcarePlannerNoteFailure, saveSelfcarePlannerNoteSuccess,
  getSelfCareAppURL, getSelfCareAppURLFailure, getSelfCareAppURLSuccess
} from "./selfcare.actions";



@Injectable()
export class SelfcareEffects {
  constructor(
    private actions$: Actions,
    private selfcareService: SelfcareService,
    private store: Store<State>) { }

  loadSelfcarePlanner = createEffect(() => this.actions$.pipe(
    ofType(loadSelfcarePlanner),
    switchMap(action => {
      return this.selfcareService.getPlanner(action.userID).pipe(
        map(plannerSections => loadSelfcarePlannerSuccess({ plannerSections })),
        catchError(err => {
          this.store.dispatch(loadSelfcarePlannerFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));


  loadSelfcareCategories = createEffect(() => this.actions$.pipe(
    ofType(loadSelfcareCategories),
    switchMap(action => {
      return this.selfcareService.getCategories().pipe(
        map(categories => loadSelfcareCategoriesSuccess({ categories })),
        catchError(err => {
          this.store.dispatch(loadSelfcareCategoriesFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));


  loadSelfcareCategory = createEffect(() => this.actions$.pipe(
    ofType(loadSelfcareCategory),
    switchMap(action => {
      return this.selfcareService.getCategory(action.categoryID,action.userID).pipe(
        map(category => loadSelfcareCategorySuccess({ category })),
        catchError(err => {
          this.store.dispatch(loadSelfcareCategoryFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));
  
  loadSelfcarePreferences = createEffect(() => this.actions$.pipe(
    ofType(loadSelfcarePreferences),
    switchMap(action => {
      return this.selfcareService.getPreferences(action.userID).pipe(
        map(category => loadSelfcareCategorySuccess({ category })),
        catchError(err => {
          this.store.dispatch(loadSelfcareCategoryFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));


  saveSelfcareAnwers = createEffect(() => this.actions$.pipe(
    ofType(saveSelfcareAnwers),
    switchMap(action => {
      return this.selfcareService.postSelfcareAnwers(action.answers).pipe(
        map(result => saveSelfcareAnwersSuccess()),
        catchError(err => {
          this.store.dispatch(saveSelfcareAnwersFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  saveSelfcarePlannerNote = createEffect(() => this.actions$.pipe(
    ofType(saveSelfcarePlannerNote),
    switchMap(action => {
      return this.selfcareService.putSelfcareNote(action.note).pipe(
        map(note => saveSelfcarePlannerNoteSuccess({ note })),
        catchError(err => {
          this.store.dispatch(saveSelfcarePlannerNoteFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));


  deleteSelfcarePlannerNote = createEffect(() => this.actions$.pipe(
    ofType(deleteSelfcarePlannerNote),
    switchMap(action => {
      return this.selfcareService.deleteSelfcareNote(action.noteID).pipe(
        map(result => deleteSelfcarePlannerNoteSuccess()),
        catchError(err => {
          this.store.dispatch(deleteSelfcarePlannerNoteFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  loadSelfcareOverSightEmployees = createEffect(() => this.actions$.pipe(
    ofType(loadSelfcareOverSightEmployees),
    switchMap(action => {
      return this.selfcareService.getOversightEmployees().pipe(
        map(employees => loadSelfcareOverSightEmployeesSuccess({ employees })),
        catchError(err => {
          this.store.dispatch(loadSelfcareOverSightEmployeesFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  getSelfCareCompletionList = createEffect(() => this.actions$.pipe(
    ofType(getSelfCareCompletionList),
    switchMap(action => {
      return this.selfcareService.getSelfCareCompletionList(action.filter).pipe(
        map(list => getSelfCareCompletionListSuccess({ list })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  exportSelfCareCompletionListToExcel = createEffect(() => this.actions$.pipe(
    ofType(exportSelfCareCompletionListToExcel),
    switchMap(action => {
      return this.selfcareService.exportSelfCareCompletionListToExcel(action.filter).pipe(
        map(doc => exportSelfCareCompletionListToExcelSuccess({ doc })),
        catchError(err => {
          this.store.dispatch(exportSelfCareCompletionListToExcelFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));


  getSelfCareAppURL = createEffect(() => this.actions$.pipe(
    ofType(getSelfCareAppURL),
    switchMap(action => {
      return this.selfcareService.getSelfCareAppURL().pipe(
        map(url => getSelfCareAppURLSuccess( url )),
        catchError(err => {
          this.store.dispatch(getSelfCareAppURLFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));
}
