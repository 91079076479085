import { CoachingLogWithUsersModel } from './../../../models/coachingLogWithUsersModel';
import { PagingResultsModel } from './../../../models/pagingResultsModel';
import { UserModel } from 'src/app/models/userModel';
import { createReducer, on, Action } from '@ngrx/store';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { FormError } from 'src/app/models/utilModels';
import {
  createImprovementPlan, createImprovementPlanSuccess, createImprovementPlanFail,
  createImprovementPlanReview, createImprovementPlanReviewSuccess, createImprovementPlanReviewFail,
  getImprovementPlan, getImprovementPlanSuccess, updateCreateImprovementPlanForm,
  updateCreateImprovementPlanReviewForm, downloadImprovementPlanDoc, downloadImprovementPlanDocSuccess,
  signImprovementPlanDoc, signImprovementPlanDocSuccess, signImprovementPlanDocFailure,
  downloadImprovementPlanReviewDoc, downloadImprovementPlanReviewDocSuccess, signImprovementPlanReviewDoc,
  signImprovementPlanReviewDocSuccess, signImprovementPlanReviewDocFailure, clearImprovementPlan, getImprovementPlanReviewSuccess, getImprovementPlanReview
} from './improvementPlan.actions';
import { ImprovementPlanModel, ImprovementPlanReviewModel } from 'src/app/models/improvementPlanModel';
import { HasSelectedCheckboxesOrOther } from '../../../helpers/validators';

export interface ImprovementPlanState {
  improvementPlan: ImprovementPlanModel;
  creatingImprovementPlan: boolean;
  formErrors: FormError[];
  loadingImprovementPlan: boolean;

  improvementPlanReview: ImprovementPlanReviewModel;
  loadingImprovementPlanReview: boolean;
  creatingImprovementPlanReview: boolean;
  formErrorsReview: FormError[];

  loadingDoc: boolean;
  loadingSign: boolean;
  docArrayBuffer: ArrayBuffer;
  docSignedArrayBuffer: ArrayBuffer;

  loadingReviewDoc: boolean;
  loadingReviewSign: boolean;
  docReviewArrayBuffer: ArrayBuffer;
  docReviewSignedArrayBuffer: ArrayBuffer;
}

const initialState: ImprovementPlanState = {
  improvementPlan: null,
  creatingImprovementPlan: false,
  formErrors: [],
  loadingImprovementPlan: false,

  improvementPlanReview: null,
  loadingImprovementPlanReview: false,
  creatingImprovementPlanReview: false,
  formErrorsReview: [],

  loadingDoc: false,
  loadingSign: false,
  docArrayBuffer: null,
  docSignedArrayBuffer: null,

  loadingReviewDoc: false,
  loadingReviewSign: false,
  docReviewArrayBuffer: null,
  docReviewSignedArrayBuffer: null,
};

export const improvementPlanReducer = createReducer(initialState,
  on(updateCreateImprovementPlanForm, (state, action) => {
    const newImprovementPlan = { ...state.improvementPlan, ...action.improvementPlan };
    const newState = { ...state, ...{ improvementPlan: newImprovementPlan, formErrors: action.formErrors } };
    return newState;
  }),
  on(updateCreateImprovementPlanReviewForm, (state, action) => {
    const newImprovementPlanReview = { ...state.improvementPlanReview, ...action.improvementPlanReview };
    const newState = { ...state, ...{ improvementPlanReview: newImprovementPlanReview, formErrorsReview: action.formErrorsReview } };
    return newState;
  }),

  on(createImprovementPlan, (state, action) => {
    return {
      ...state,
      creatingImprovementPlan: true
    };
  }),
  on(createImprovementPlanSuccess, (state, action) => {
    return {
      ...state,
      improvementPlan: action.improvementPlanModel,
      creatingImprovementPlan: false
    };
  }),
  on(createImprovementPlanFail, (state, action) => {
    return {
      ...state,
      creatingImprovementPlan: false
    };
  }),
  on(getImprovementPlan, (state, action) => {
    return {
      ...state,
      loadingImprovementPlan: true
    };
  }),
  on(getImprovementPlanSuccess, (state, action) => {
    return {
      ...state,
      improvementPlan: action.improvementPlan,
      loadingImprovementPlan: false
    };
  }),


  on(getImprovementPlanReview, (state, action) => {
    return {
      ...state,
      loadingImprovementPlanReview: true
    };
  }),
  on(getImprovementPlanReviewSuccess, (state, action) => {
    return {
      ...state,
      improvementPlanReview: action.improvementPlanReview,
      loadingImprovementPlanReview: false
    };
  }),

  on(createImprovementPlanReview, (state, action) => {
    return {
      ...state,
      creatingImprovementPlanReview: true
    };
  }),
  on(createImprovementPlanReviewSuccess, (state, action) => {
    return {
      ...state,
      improvementPlanReview: action.improvementPlanReviewModel,
      creatingImprovementPlanReview: false
    };
  }),
  on(createImprovementPlanReviewFail, (state, action) => {
    return {
      ...state,
      creatingImprovementPlanReview: false
    };
  }),

  on(downloadImprovementPlanDoc, (state, action) => {
    return {
      ...state,
      loadingDoc: true,
      docArrayBuffer: null
    };
  }),
  on(downloadImprovementPlanDocSuccess, (state, action) => {
    return {
      ...state,
      docArrayBuffer: action.doc,
      loadingDoc: false
    };
  }),
  on(signImprovementPlanDoc, (state, action) => {
    return {
      ...state,
      loadingSign: true,
      docSignedArrayBuffer: null
    };
  }),
  on(signImprovementPlanDocSuccess, (state, action) => {
    return {
      ...state,
      docSignedArrayBuffer: action.docSigned,
      loadingSign: false
    };
  }),
  on(signImprovementPlanDocFailure, (state, action) => {
    return {
      ...state,
      loadingSign: false
    };
  }),

  on(downloadImprovementPlanReviewDoc, (state, action) => {
    return {
      ...state,
      loadingReviewDoc: true,
      docReviewArrayBuffer: null
    };
  }),
  on(downloadImprovementPlanReviewDocSuccess, (state, action) => {
    return {
      ...state,
      docReviewArrayBuffer: action.doc,
      loadingReviewDoc: false
    };
  }),
  on(signImprovementPlanReviewDoc, (state, action) => {
    return {
      ...state,
      loadingReviewSign: true,
      docReviewSignedArrayBuffer: null
    };
  }),
  on(signImprovementPlanReviewDocSuccess, (state, action) => {
    return {
      ...state,
      docReviewSignedArrayBuffer: action.docSigned,
      loadingReviewSign: false
    };
  }),
  on(signImprovementPlanReviewDocFailure, (state, action) => {
    return {
      ...state,
      loadingReviewSign: false
    };
  }),
  on(clearImprovementPlan, (state, action) => {
    return {
      ...state,
      improvementPlan: {
        noteDate: new Date().toISOString().substr(0, 10)
      }
    };
  }),


);

export function improvementPlanReducerFunc(state: ImprovementPlanState | undefined, action: Action) {
  return improvementPlanReducer(state, action);
}

export function initImprovementPlanForm(improvementPlan: ImprovementPlanModel) {
  const form = new FormGroup({
    improvementPlan: new FormGroup({
      previouslyAddressedCoachingLogID: new FormControl(improvementPlan.previouslyAddressedCoachingLogID),
      additionalInfo: new FormControl(improvementPlan.additionalInfo, [Validators.required, Validators.maxLength(4000)]),
      benchmark: new FormControl(improvementPlan.benchmark, [Validators.required, Validators.maxLength(4000)]),
      expectedStandard: new FormControl(improvementPlan.expectedStandard, [Validators.required, Validators.maxLength(4000)]),
      improvementActions: new FormControl(improvementPlan.improvementActions, [Validators.required, Validators.maxLength(4000)]),
      measuredBy: new FormControl(improvementPlan.measuredBy, [Validators.required, Validators.maxLength(4000)]),
      nextReviewDate: new FormControl(improvementPlan.nextReviewDate, [Validators.required]),
      noteDate: new FormControl(improvementPlan.noteDate, [Validators.required]),
      followUpDate: new FormControl(improvementPlan.followUpDate),

      performanceConcern: new FormControl(improvementPlan.performanceConcern, [Validators.required, Validators.maxLength(4000)]),
      supervisorUserID: new FormControl(improvementPlan.supervisorUserID, [Validators.required]),
      support: new FormControl(improvementPlan.support, [Validators.required, Validators.maxLength(4000)]),
      userID: new FormControl(improvementPlan.userID, [Validators.required]),
      otherCategory: new FormControl(improvementPlan.otherCategory),
      categories: new FormArray([])
    },
      {
        validators: HasSelectedCheckboxesOrOther("categories", "otherCategory")
      })
  });

  return form;
}

export function initImprovementPlanReviewForm(improvementPlanReview: ImprovementPlanReviewModel) {
  const form = new FormGroup({
    improvementPlanReview: new FormGroup({
      dataIncluded: new FormControl(improvementPlanReview.dataIncluded, [Validators.required, Validators.maxLength(4000)]),
      improvementPlanID: new FormControl(improvementPlanReview.improvementPlanID, [Validators.required]),
      nextReviewDate: new FormControl(improvementPlanReview.nextReviewDate, [Validators.required]),
      reviewNotes: new FormControl(improvementPlanReview.reviewNotes, [Validators.required, Validators.maxLength(4000)]),
      success: new FormControl(improvementPlanReview.success,),
    })
  });

  return form;
}
