<p-dialog [(visible)]="display" header="{{title}}" [modal]="true" [style]="{width: '70vw'}" (onHide)="closeModal()" [focusOnShow]="false">
  <form class="card mt-2" [formGroup]="form" (ngSubmit)="saveTerminationReason()">
    <div class="card-body">
      <div class="row">
        <div class="col-12">

          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Separation Date</b>
            </div>
            <div class="col-8">
              <input type="date" class="form-control mt-2" formControlName="dateString" />
              <app-validation-message [form]="form" [path]="'dateString'" [label]="'Date'"></app-validation-message>
            </div>
          </div>

          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Separation Type</b>
            </div>
            <div class="col-8">
              <i *ngIf="!reasonsList" class="material-icons rotate text-muted">loop</i>
              <p-dropdown appendTo="body" styleClass="form-control w-100 mt-2" [options]="reasonsList" formControlName="terminationReasonId" placeholder="Select reason..." (ngModelChange)="changedReason()"></p-dropdown>
              <app-validation-message [form]="form" [path]="'terminationReasonId'" [label]="'Reason'"></app-validation-message>
            </div>
          </div>

          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Separation Reasons</b>
            </div>
            <div class="col-8">
              <i *ngIf="!allSubReasonsList" class="material-icons rotate text-muted">loop</i>
              <p-multiSelect #subReasonsMultiSelect (onFocus)="openMultiSelect(subReasonsMultiSelect)" autofocusFilter="true" appendTo="body" class="w-100" styleClass="form-control w-100 mt-2"
                             inputStyleClass="form-control" [options]="availableSubReasonsList" [defaultLabel]="'Select ...'"
                             formControlName="subReasonIds"
                             ></p-multiSelect>              
            </div>
          </div>

        </div>
      </div>
    </div>    
  </form>
  <p-footer>
    <button class="btn btn-secondary" type="button" (click)="closeModal()">Cancel</button>
    <button class="btn btn-primary" type="submit" (click)="saveTerminationReason()" [disabled]="disableButtons">Save</button>
  </p-footer>
</p-dialog>
