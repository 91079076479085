import { deepClone } from 'src/app/helpers/utils';
import {
  getAssignedEquipmentList, saveAssignEquipmentSuccess,
  getEquipmentAssignPdf,
  saveReturnEquipmentSuccess,
  createEquipmentSuccess,
  clearCreateEquipmentForm,
  changedReturnEquipment,
  loadUserEquipments,
  addEquipmentToAssign,
  clearEquipmentsToAssign,
  changedUserOnEquipmentToAssign,
  deleteEquipment,
  deleteEquipmentSuccess,
  editEquipmentForm,
  unarchiveEquipment,
  unarchiveEquipmentSuccess,
  saveEditAssignEquipmentSuccess,
  getAssignedEquipments,
  loadEquipmentPermissionModalOptions,
  clearPermissions,
  getEquipmentTypes,
  loadCampuses
} from './../../../../core/store/equipment/equipment.actions';
import { AssignedEquipmentModel, EquipmentWithAssignmentsModel, EquipmentModel } from './../../../../models/equipmentsModel';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { AlertService } from 'src/app/shared/services/alert.service';
import { getMyEquipmentList } from 'src/app/core/store/equipment/equipment.actions';
import { Router, ActivatedRoute } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { DateRangeModel } from '../../../../models/dateRangeModel';
import { DropdownFilterService } from '../../../../core/services/dropdown-filter.service';
import { formatDate } from '@angular/common';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { GeneralSettingsModel } from 'src/app/models/generalSettingsModel';
import { getUsersIncludingTerminated, getUsersList } from 'src/app/core/store/users/users.actions';
import {NgbModal, ModalDismissReasons, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-list-equipment',
  templateUrl: './list-equipment.component.html',
  styleUrls: ['./list-equipment.component.scss']
})
export class ListEquipmentComponent extends BaseComponent implements OnInit {

  @ViewChild("equipmentNotReturnedModal") equipmentNotReturnedModal: TemplateRef<any>;

  modalEquipmentService: any;
  modalReference: any;
  modalOptions: NgbModalOptions;
  permissionsModalOptions: NgbModalOptions;
  equipments: PagingResultsModel<EquipmentWithAssignmentsModel>;
  excelEquipments: PagingResultsModel<EquipmentWithAssignmentsModel>;
  canEdit: boolean;
  canEditPermissions: boolean;
  canDelete: boolean = false;
  loadingAssignedEquipmentList = true;
  loadingAssignedEquipments = true;
  filter: any;
  displayAssignEquipment;
  displayReturnEquipment;
  usersNames: any;
  userList: SelectItem[];
  displayCreateEquipment;
  showHideString: string = "Hide";
  hideFilter: boolean = false;

  filterType: string = null;
  filterSerial: string;
  filterBrand: string;
  filterModel: string;
  filterAssetTag: string;
  filterServiceTag: string;
  filterExpressServiceCode: string;
  filterUserId: number;
  filterProgramId: number;
  filterAssignedSigned?: boolean = null;
  filterReturnedSigned?: boolean = null;
  filterArchived?: boolean = false;
  filterAssignedStatus: number = -1;
  myUserID;
  myProgramID?: number;

  // variables for column display
  type: boolean = true;
  serial: boolean = false;
  brandModel: boolean = true;
  tags: boolean = false;
  user: boolean = true;
  campus: boolean = false;
  dateAssigned: boolean = true;
  signed: boolean = false;
  returned: boolean = false;
  department: boolean = false;
  price: boolean = false;
  vendor: boolean = false;
  purchaseLocation: boolean = false;
  storageLocation: boolean = true;
  paymentMethod: boolean = false;
  size: boolean = false;
  classCode: boolean = false;
  classification: boolean = false;
  returnDate: boolean = true;
  includeTerminated: boolean = false;
  mainCampus: boolean = true;

  yesNoAllOptions = [
    { label: 'All', value: null },
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  signedOptions = [
    { label: 'All', value: null },
    { label: 'Signed', value: true },
    { label: 'Not Signed', value: false },
  ];

  assignedStatusOptions = [
    { label: 'All', value: -1 },
    { label: 'Assigned', value: 0 },
    { label: 'Returned', value: 1 },
    { label: 'New', value: 2 },
  ];

  viewTypes = [
    { label: 'Equipment With Assignments', value: 1 },
    { label: 'Equipment Without Assignments', value: 2 },
    { label: 'All Equipment', value: 3 },
    { label: 'Returned Equipment', value: 4 },
    { label: 'Assigned Equipment', value: 5 },
  ]

  dateRangeList: SelectItem[];
  assignedDateRange: DateRangeModel;
  assignedStartDate: string = null;
  assignedEndDate: string = null;
  isCustomAssignedDateRange: boolean = false;

  returnedDateRange: DateRangeModel;
  returnedStartDate: string = null;
  returnedEndDate: string = null;
  isCustomReturnedDateRange: boolean = false;

  programList: SelectItem[];

  typeOptions = [
  "Tablet",
  "Smartphone",
  "Notebook",
  "Apron",
  "Key",
  "Badge",
  "Lanyard",
  "Mouse",
  "Charger",
  "Keyboard",
  "HeadPhones",
  "Stylus",
  "First Aid Kit",
  "Walkie Talkies",
  "Books / Literature",
  "Other",
];
generalSettings: GeneralSettingsModel;
equipmentNotReturned: EquipmentWithAssignmentsModel;
viewType: number = 1; //future ticket to view all equipments even ones without assignments.
equipmentTypes: SelectItem[];

  constructor(private actions$: Actions,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<State>,
    private alertService: AlertService,
    private dropdownFilterService: DropdownFilterService,
    private modalService: NgbModal,
    ) {
    super();
    this.modalOptions = {
      backdrop:'static',
      backdropClass:'customBackdrop',
      size: 'lg'
    }
    this.permissionsModalOptions = {
      backdrop:'static',
      backdropClass:'customBackdrop',
      size: 'lg'
    }
  }

  ngOnInit() {
    this.route.queryParams.subscribe(queryParams => {
      if (queryParams.assignTo) {
        this.alertService.confirm('Assign equipment to this user?',
          'You successfully returned the equipment. Do you want to assign new equipment to this user?')
          .subscribe(
            answer => {
              if (answer) {
                this.displayAssignEquipment = true;
                this.store.dispatch(clearEquipmentsToAssign());
                this.store.dispatch(changedUserOnEquipmentToAssign({ userID: queryParams.assignTo }));
              }
            }, error => {
            }
          );
        this.router.navigate([], { queryParams: { assignTo: null }, queryParamsHandling: 'merge' });
      }
    });
    this.subs.push(
      this.store.select(s => s.accountState.generalSettings).subscribe(generalSettings => {
        if (generalSettings) {
          this.generalSettings = generalSettings;
        }
      }),
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.canEdit = permissionIDs && !!permissionIDs.find(p => p === 3058); //Equipment Manage
        this.canEditPermissions = permissionIDs && !!permissionIDs.find(p => p === 7800); //Equipment Manage Type Permissions
      }),
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.canDelete = permissionIDs && !!permissionIDs.find(p => p === 7000); //Equipment Delete
      }),
      this.store.select(s => s.accountState.user).subscribe(myUser => {
        this.myUserID = myUser.id;
        this.myProgramID = myUser.primaryProgramID;
      }),
      this.store.select(s => s.equipmentState.assignedEquipmentList).subscribe(assignedEquipmentList => {
        this.equipments = assignedEquipmentList;
      }),
      this.store.select(s => s.equipmentState.loadingAssignedEquipmentList).subscribe(loadingAssignedEquipmentList => {
        this.loadingAssignedEquipmentList = loadingAssignedEquipmentList;
      }),
      this.store.select(s => s.equipmentState.assignedEquipments).subscribe(assignedEquipments => {
        this.excelEquipments = assignedEquipments;
      }),
      this.store.select(s => s.equipmentState.loadingAssignedEquipments).subscribe(loadingAssignedEquipments => {
        this.loadingAssignedEquipments = loadingAssignedEquipments;
      }),
      this.store.select(s => s.equipmentState.equipmentTypes).subscribe(equipmentTypes => {
        const equipmentTypesMapped = equipmentTypes && equipmentTypes.length > 0 ? equipmentTypes.map(c => ({ label: c.type, value: c.id }))
          : [{ label: 'loading...', value: undefined }];
        this.equipmentTypes = equipmentTypesMapped;
      }),
      this.store.select(s => s.usersState.usersList).subscribe(usersList => {
        this.usersNames = usersList ?
          usersList.reduce((acc, cur, i) => {
            acc[cur.id] = cur.firstName + ' ' + cur.lastName;
            return acc;
          }, {})
          : [];

        //need to change to employee autocomplete => but for users....can a non-employee be issued equipment?
        let users = usersList && usersList.length > 0 ? usersList.map(u => ({ label: u.firstName + ' ' + u.lastName, value: u.id }))
          : [{ label: 'loading...', value: undefined }];
        users.unshift({ label: 'All', value: 0 });

        this.userList = users;
      }),
      this.store.select(s => s.equipmentState.campuses).subscribe(campuses => {
        const campusesList = campuses && campuses.length > 0 ? campuses.map(c => ({ label: c.programName, value: c.id }))
          : [{ label: 'loading...', value: undefined }];
        campusesList.unshift({ label: 'All', value: 0 });

        this.programList = campusesList;

      }),
      this.actions$.pipe(
        ofType(saveAssignEquipmentSuccess),
        tap(action => {
          this.savedAssignEquipment();
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(saveEditAssignEquipmentSuccess),
        tap(action => {
          this.savedAssignEquipment();
        })
      ).subscribe(),

      this.actions$.pipe(
        ofType(saveReturnEquipmentSuccess),
        tap(action => {
          this.savedReturnEquipment(action.assignedEquipment);
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(createEquipmentSuccess),
        tap(action => {
          this.loadPage(this.filter);
          this.displayCreateEquipment = false;
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(deleteEquipmentSuccess),
        tap(action => {
          this.loadPage(this.filter);
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(unarchiveEquipmentSuccess),
        tap(action => {
          this.loadPage(this.filter);
        })
      ).subscribe(),
    );
    this.store.dispatch(loadEquipmentPermissionModalOptions());
    this.store.dispatch(getUsersIncludingTerminated());
    this.store.dispatch(getEquipmentTypes());
    this.store.dispatch(loadCampuses());
    this.dateRangeList = this.dropdownFilterService.getLast120DayDateRanges().map(c => ({ label: c.text, value: c }));
  }

  loadPage(event) {
    this.filter = event;
    this.filter.filters.filterAssignedStatus = { Name: 'filterAssignedStatus', Value: { value: this.filterAssignedStatus } };
    this.filter.filters.filterArchived = { Name: 'filterArchived', Value: { value: this.filterArchived } };
    this.filter.filters.filterAssignedSigned = { Name: 'filterAssignedSigned', Value: { value: this.filterAssignedSigned } };
    this.filter.filters.filterReturnedSigned = { Name: 'filterReturnedSigned', Value: { value: this.filterReturnedSigned } };
    this.filter.filters.assignedStartDate = { Name: 'assignedStartDate', Value: { value: this.assignedStartDate } };
    this.filter.filters.assignedEndDate = { Name: 'assignedEndDate', Value: { value: this.assignedEndDate } };
    this.filter.filters.returnedStartDate = { Name: 'returnedStartDate', Value: { value: this.returnedStartDate } };
    this.filter.filters.returnedEndDate = { Name: 'returnedEndDate', Value: { value: this.returnedEndDate } };
    this.filter.filters.filterType = { Name: 'filterType', Value: { value: this.filterType } };
    this.filter.filters.filterSerial = { Name: 'filterSerial', Value: { value: this.filterSerial } };
    this.filter.filters.filterBrand = { Name: 'filterBrand', Value: { value: this.filterBrand } };
    this.filter.filters.filterModel = { Name: 'filterModel', Value: { value: this.filterModel } };
    this.filter.filters.filterAssetTag = { Name: 'filterAssetTag', Value: { value: this.filterAssetTag } };
    this.filter.filters.filterServiceTag = { Name: 'filterServiceTag', Value: { value: this.filterServiceTag } };
    this.filter.filters.filterExpressServiceCode = { Name: 'filterExpressServiceCode', Value: { value: this.filterExpressServiceCode } };
    this.filter.filters.filterProgramId = { Name: 'filterProgramId', Value: { value: this.filterProgramId } };
    this.filter.filters.filterUserId = { Name: 'filterUserId', Value: { value: this.filterUserId } };

    this.store.dispatch(getAssignedEquipmentList({
      filter: deepClone(this.filter)
    }));
    this.store.dispatch(getAssignedEquipments({
      filter: deepClone(this.filter)
    }));
  }

  changeViewType(){
    console.log(this.viewType);
    this.store.dispatch(getAssignedEquipmentList({
      filter: deepClone(this.filter)
    }));
  }

  includeTerminatedChange(event){
    if (event.checked == false){
      this.store.select(s => s.usersState.usersList).subscribe(usersList => {
        this.userList = usersList ? usersList.map(u => ({ label: u.firstName + ' ' + u.lastName, value: u.id }))
          : [{ label: 'loading...', value: undefined }];
          this.userList.unshift({ label: 'All', value: 0 });
      })
    }
    else{
      this.store.select(s => s.usersState.usersListIncludingTerminated).subscribe(usersList => {
        this.userList = usersList ? usersList.map(u => ({ label: u.firstName + ' ' + u.lastName, value: u.id }))
          : [{ label: 'loading...', value: undefined }];
          this.userList.unshift({ label: 'All', value: 0 });
      })
    }
  }

  viewAssignedDocument(equipment: EquipmentWithAssignmentsModel) {
    this.store.dispatch(getEquipmentAssignPdf({
      assignedEquipmentID: equipment.lastAssignedEquipment.id,
      returned: false, download: true
    }));
  }

  viewReturnedDocument(equipment: EquipmentWithAssignmentsModel) {
    this.store.dispatch(getEquipmentAssignPdf({ assignedEquipmentID: equipment.lastAssignedEquipment.id, returned: true, download: true }));
  }

  signReturnEquipment(equipment: EquipmentWithAssignmentsModel = null) {
    this.router.navigate(['/equipment/signReturn/' + equipment.lastAssignedEquipment.id]);
  }

  openReturnEquipment(equipment: EquipmentWithAssignmentsModel = null) {
    this.displayReturnEquipment = true;
    if (equipment && equipment.lastAssignedEquipment) {
      this.store.dispatch(loadUserEquipments({ userID: equipment.lastAssignedEquipment.userID }));
    }
  }

  savedReturnEquipment(equipment: AssignedEquipmentModel) {
    this.displayReturnEquipment = false;
    this.loadPage(this.filter);
  }

  openEquipmentNotReturnedModal(equipment: EquipmentWithAssignmentsModel){
    this.alertService.confirm("Confirm Assignment",
    "This equipment has not been returned from previous assignment. Are you sure you want to return and then assign this item?")
    .subscribe(
      answer => {
        if (answer) {
          this.equipmentNotReturned = equipment;
          this.modalEquipmentService = this.modalService.open(this.equipmentNotReturnedModal, this.modalOptions);
          this.modalEquipmentService.result.then((result) => {
            console.log(result);
          }, (reason) => {
          });
        }
        else{
          return;
        }
      }, error => {
      }
    );
  }

  openAssignEquipment(equipment: EquipmentWithAssignmentsModel = null) {
    if(equipment != null && equipment.lastAssignedEquipment != null && equipment.lastAssignedEquipment.dateAssigned != null && equipment.lastAssignedEquipment.dateReturned == null){
      this.openEquipmentNotReturnedModal(equipment);
    }
    else{
      this.store.dispatch(clearEquipmentsToAssign());
      if (equipment) {
        var returnDate = equipment.lastAssignedEquipment != null ? equipment.lastAssignedEquipment.dateReturned : null;
        var returnStatus = equipment.lastAssignedEquipment != null ? equipment.lastAssignedEquipment.returnStatus : null;
        var returnNotes = equipment.lastAssignedEquipment != null ? equipment.lastAssignedEquipment.returnNotes : null;
        const assignedEquipment: AssignedEquipmentModel = {
          userID: null,
          equipmentID: equipment.equipment.id,
          assignedByUserID: this.myUserID,
          equipment: equipment.equipment,
          programID: this.myProgramID,
          dateAssignedString: formatDate(new Date(), 'yyyy-MM-dd', 'en-US'),
          dateReturned: returnDate,
          returnStatus: returnStatus,
          returnNotes: returnNotes
        };
        this.store.dispatch(addEquipmentToAssign({ assignedEquipment }));
      }
      this.displayAssignEquipment = true;
    }
  }

  savedAssignEquipment() {
    this.displayAssignEquipment = false;
    this.loadPage(this.filter);
  }


  openCreateEquipment() {
    this.store.dispatch(clearCreateEquipmentForm());
    this.displayCreateEquipment = true;
  }

  editEquipment(equipmentWithAssignments: EquipmentWithAssignmentsModel) {
    this.store.dispatch(editEquipmentForm({ equipment: equipmentWithAssignments.equipment }));
    this.displayCreateEquipment = true;
  }

  deleteEquipment(equipment: EquipmentWithAssignmentsModel) {
    this.alertService.confirm('Delete Equipment?',
      'Are you sure you want to delete this equipment? If the equipment has already been assigned, it will be archived instead.')
      .subscribe(
        answer => {
          if (answer) {
            this.store.dispatch(deleteEquipment(equipment));
          }
        }, error => {
        }
      );
  }

  unarchiveEquipment(equipmentWithAssignments: EquipmentWithAssignmentsModel) {
    this.store.dispatch(unarchiveEquipment({ equipment: equipmentWithAssignments.equipment }));
  }

  changedAssignedDateRange() {
    if (this.assignedDateRange.isCustom) {
      this.isCustomAssignedDateRange = true;
      return; //reload after manually changing date
    }

    this.isCustomAssignedDateRange = false;
    this.assignedStartDate = this.assignedDateRange.startDate;
    this.assignedEndDate = this.assignedDateRange.endDate;
    this.loadPage(this.filter);
  }

  changedReturnedDateRange() {
    if (this.returnedDateRange.isCustom) {
      this.isCustomReturnedDateRange = true;
      return; //reload after manually changing date
    }

    this.isCustomReturnedDateRange = false;
    this.returnedStartDate = this.returnedDateRange.startDate;
    this.returnedEndDate = this.returnedDateRange.endDate;
    this.loadPage(this.filter);
  }

  toggleHideFilter() {
    if (this.hideFilter) {
      this.hideFilter = false;
      this.showHideString = "Hide";
    }
    else {
      this.hideFilter = true;
      this.showHideString = "Show";
    }
  }

  openPermissionsModal(content) {
    this.modalReference = this.modalService.open(content, this.permissionsModalOptions)
    this.modalReference.result.then((result) => {
    }, (reason) => {
    });
  }

  closePermissionsModal(e){
    this.modalReference.close()
    this.store.dispatch(clearPermissions())
    this.loadPage(this.filter);
  }

  editAssignment(equipment: EquipmentWithAssignmentsModel = null) {
    this.store.dispatch(clearEquipmentsToAssign());
    if (equipment && equipment.lastAssignedEquipment) {
      let assignedEquipment = deepClone(equipment.lastAssignedEquipment) as AssignedEquipmentModel;
      assignedEquipment.dateAssignedString = formatDate(assignedEquipment.dateAssigned, 'yyyy-MM-dd', 'en-US');
      this.store.dispatch(addEquipmentToAssign({ assignedEquipment }));
    }
    this.displayAssignEquipment = true;
  }

  editColumns(columnValue) {
    if (columnValue == "type") { this.type = !this.type }
    else if (columnValue == "serial") { this.serial = !this.serial }
    else if (columnValue == "brandModel") { this.brandModel = !this.brandModel }
    else if (columnValue == "tags") { this.tags = !this.tags }
    else if (columnValue == "user") { this.user = !this.user }
    else if (columnValue == "campus") { this.campus = !this.campus }
    else if (columnValue == "dateAssigned") { this.dateAssigned = !this.dateAssigned }
    else if (columnValue == "returnDate") { this.returnDate = !this.returnDate }
    else if (columnValue == "signed") { this.signed = !this.signed }
    else if (columnValue == "returned") { this.returned = !this.returned }
    else if (columnValue == "department") { this.department = !this.department }
    else if (columnValue == "price") { this.price = !this.price }
    else if (columnValue == "vendor") { this.vendor = !this.vendor }
    else if (columnValue == "purchaseLocation") { this.purchaseLocation = !this.purchaseLocation }
    else if (columnValue == "storageLocation") { this.storageLocation = !this.storageLocation }
    else if (columnValue == "paymentMethod") { this.paymentMethod = !this.paymentMethod }
    else if (columnValue == "size") { this.size = !this.size }
    else if (columnValue == "classCode") { this.classCode = !this.classCode }
    else if (columnValue == "classification") { this.classification = !this.classification }
    else if (columnValue == "mainCampus") { this.mainCampus = !this.mainCampus }

  }

  simplifyData() {

    let newArr = []
    let equipments = this.excelEquipments.result
    let user;
    let users = this.userList
    let campus;
    let campuses = this.programList;
    let pastAssignee;
    let returned;
    let returnDate = "";
    let classification;


    for (let e in equipments) {
      if (equipments[e]['equipment']['classificationOther']) {
        classification = equipments[e]['equipment']['classificationOther'];
      }
      else {
        classification = equipments[e]['equipment']['classification'];
      }
      if (equipments[e]['lastAssignedEquipment'] != null) {
        for (let u in users) {
          if (equipments[e]['lastAssignedEquipment']['returnStatus']) {
            user = "";
            returned = equipments[e]['lastAssignedEquipment']['returnStatus'];
            returnDate = new Date(equipments[e]['lastAssignedEquipment']['dateReturned']).toLocaleDateString()

          }
          else {
            if (equipments[e]['lastAssignedEquipment']['userID'] == users[u]['value']) {
              user = users[u]['label'];
              pastAssignee = "";
              returned = "";
              returnDate = "";
            }
          }
        }

        for (let c in campuses) {
          if (equipments[e]['lastAssignedEquipment']['programID'] == campuses[c]['value']) {
            campus = campuses[c]['label'];
          }
        }

        newArr.push({
          Type: equipments[e]['lastAssignedEquipment']['equipment']['type'],
          Serial: equipments[e]['lastAssignedEquipment']['equipment']['serialNumber'],
          Brand: equipments[e]['lastAssignedEquipment']['equipment']['brand'],
          Tag: equipments[e]['lastAssignedEquipment']['equipment']['assetTag'],
          User: user,
          Campus: campus,
          "Date Assigned": new Date(equipments[e]['lastAssignedEquipment']['dateAssigned']).toDateString(),
          Signed: equipments[e]['lastAssignedEquipment']['assignSigned'],
          "Returned Status": equipments[e]['lastAssignedEquipment']['returnStatus'],
          "Returned Date": returnDate,
          Department: equipments[e]['lastAssignedEquipment']['equipment']['department'],
          Pricing: equipments[e]['lastAssignedEquipment']['equipment']['pricing'],
          Vendor: equipments[e]['lastAssignedEquipment']['equipment']['purchaseVendor'],
          "Purchase Location": equipments[e]['lastAssignedEquipment']['equipment']['purchaseLocation'],
          "Storage Location": equipments[e]['lastAssignedEquipment']['equipment']['storageLocation'],
          "Payment Method": equipments[e]['lastAssignedEquipment']['equipment']['paymentMethod'],
          Size: equipments[e]['lastAssignedEquipment']['equipment']['size'],
          "Class Code": equipments[e]['lastAssignedEquipment']['equipment']['classCode'],
          "Classification": classification,
        })
      }
      else {
        newArr.push({
          Type: equipments[e]['equipment']['type'],
          Serial: equipments[e]['equipment']['serialNumber'],
          Brand: equipments[e]['equipment']['brand'],
          Tag: equipments[e]['equipment']['assetTag'],
          User: "",
          Campus: "",
          "Date Assigned":"",
          Signed: "",
          "Returned Status": "",
          "Returned Date": "",
          Department: equipments[e]['equipment']['department'],
          Pricing: equipments[e]['equipment']['pricing'],
          Vendor: equipments[e]['equipment']['purchaseVendor'],
          "Purchase Location": equipments[e]['equipment']['purchaseLocation'],
          "Storage Location": equipments[e]['equipment']['storageLocation'],
          "Payment Method": equipments[e]['equipment']['paymentMethod'],
          Size: equipments[e]['equipment']['size'],
          "Class Code": equipments[e]['equipment']['classCode'],
          "Classification": classification,
        })
      }
    }
    return newArr;
  }

  exportExcel() {
    let array = this.simplifyData()
    console.log(array)
    const fileName = 'exportequipmentview.xlsx';

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(array);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'equipment');

    XLSX.writeFile(wb, fileName);
  }
}


