import { PayrollItemModel, PayrollApprovedTotalsModel } from './../../../models/payableItem';
import { createReducer, on, Action } from '@ngrx/store';
import {
  loadApprovedTimeSuccess, loadInvalidTimeSuccess, loadAdjustmentTimeSuccess,
  loadPendingTimeSuccess, loadPendingTime, loadAdjustmentTime, loadInvalidTime,
  loadApprovedTime, loadApprovedTotals, loadApprovedTotalsSuccess,
} from './payroll.actions';
import { deepClone, getTimeDiff, getTimeDiffMinusOvertime, getTimeDiffMinutes, getTimeFormatted } from 'src/app/helpers/utils';
import { CampusModel } from 'src/app/models/campusModel';
import { PayrollClassificationModel } from 'src/app/models/payrollClassificationModel';

export interface PayrollState {
  approvedPayrollItems: PayrollItemModel[];
  invalidPayrollItems: PayrollItemModel[];
  adjustmentPayrollItems: PayrollItemModel[];
  pendingPayrollItems: PayrollItemModel[];
  approvedTotals: PayrollApprovedTotalsModel;
}

const initialState: PayrollState = {
  approvedPayrollItems: null,
  invalidPayrollItems: null,
  adjustmentPayrollItems: null,
  pendingPayrollItems: null,
  approvedTotals: null
};

export const payrollReducer = createReducer(initialState,
  on(loadApprovedTime, (state, action) => {
    return {
      ...state,
      approvedPayrollItems: null,
    };
  }),
  on(loadInvalidTime, (state, action) => {
    return {
      ...state,
      invalidPayrollItems: null,
    };
  }),
  on(loadAdjustmentTime, (state, action) => {
    return {
      ...state,
      adjustmentPayrollItems: null,
    };
  }),
  on(loadPendingTime, (state, action) => {
    return {
      ...state,
      pendingPayrollItems: null,
    };
  }),
  on(loadApprovedTotals, (state, action) => {
    return {
      ...state,
      approvedTotals: null,
    };
  }),
  on(loadApprovedTimeSuccess, (state, action) => {
    const payrollItems = formatPayableItem(action.payrollItems);
    return {
      ...state,
      approvedPayrollItems: payrollItems,
    };
  }),
  on(loadInvalidTimeSuccess, (state, action) => {
    const payrollItems = formatPayableItem(action.payrollItems);
    return {
      ...state,
      invalidPayrollItems: payrollItems,
    };
  }),
  on(loadAdjustmentTimeSuccess, (state, action) => {
    const payrollItems = formatPayableItem(action.payrollItems);
    return {
      ...state,
      adjustmentPayrollItems: payrollItems,
    };
  }),
  on(loadPendingTimeSuccess, (state, action) => {
    const payrollItems = formatPayableItem(action.payrollItems);
    return {
      ...state,
      pendingPayrollItems: payrollItems,
    };
  }),
  on(loadApprovedTotalsSuccess, (state, action) => {
    return {
      ...state,
      approvedTotals: action.approvedTotals,
    };
  }),
);

export function payrollReducerFunc(state: PayrollState | undefined, action: Action) {
  return payrollReducer(state, action);
}

export function formatPayableItem(paramPayrollItems: PayrollItemModel[]): PayrollItemModel[] {
  const payrollItems: PayrollItemModel[] = deepClone(paramPayrollItems);
  if (paramPayrollItems) {
    payrollItems.forEach(payrollItem => {
      const payableItem = payrollItem.payableItem;      

      //2h 30m Normal Rate, 23m Overtime Rate
      if (payableItem.overtimeMinutes > 0) {     
        let normalTimeDiff = payrollItem.is90834Session ? getTimeFormatted(60 - payableItem.overtimeMinutes) :
          payableItem.payableTypeID === 7 || payableItem.payableTypeID === 9 || payableItem.payableTypeID === 15 ? getTimeFormatted(payableItem.ptoMinutesUsed) : //Paid Time Off (int)Minimiser.Data.EnumList.PayableType.PaidTimeOff
            (payableItem.startTime && payableItem.endTime) ?
              getTimeDiffMinusOvertime(payableItem.startTime.toString(), payableItem.endTime.toString(), payableItem.overtimeMinutes) : '0';

        let overtimeDiff = getTimeFormatted(payableItem.overtimeMinutes);

        payrollItem.payableItem.timeDiff = normalTimeDiff + " Normal Rate, " + overtimeDiff + " Overtime Rate";
      }
      else {
        payrollItem.payableItem.timeDiff = payrollItem.is90834Session ? getTimeFormatted(60) :
          payableItem.payableTypeID === 7 || payableItem.payableTypeID === 9 || payableItem.payableTypeID === 15 ? getTimeFormatted(payableItem.ptoMinutesUsed) : //Paid Time Off (int)Minimiser.Data.EnumList.PayableType.PaidTimeOff
            (payableItem.startTime && payableItem.endTime) ?
        getTimeDiff(payableItem.startTime.toString(), payableItem.endTime.toString()) : '0';
      }

        payrollItem.payableItem.timeDiffMinutes = payrollItem.is90834Session ? 60 :
          payableItem.payableTypeID === 7 || payableItem.payableTypeID === 9 || payableItem.payableTypeID === 15 ? payableItem.ptoMinutesUsed :
            (payableItem.startTime && payableItem.endTime) ?
                    getTimeDiffMinutes(payableItem.startTime.toString(), payableItem.endTime.toString()) : 0;
    });
  }

  return payrollItems;
}
