<div [formGroup]="form" *ngIf="profile!=null">
  <h1>W-4 Employee’s Withholding Certificate</h1>
  <h5 class="card-title">Complete Form W-4 so that your employer can withhold the correct federal income tax from your pay.</h5>
  Use the estimator at <a href="http://www.irs.gov/W4App" target="_blank">www.irs.gov/W4App</a> for most accurate withholding for this form afterwards, please provide the information
  bellow:<br />
  <div class="row">
    <div class="col-12 col-xl-12">

      <h4 class="mt-3">Personal Information (step 1)</h4>
      <div class="row">
        <div class="col-4 d-flex align-items-center">
          <b>Legal First Name</b>
        </div>
        <div class="col-8 pt-2 pb-2">
          {{ profile.user.firstName }}
        </div>
      </div>
      <div class="row">
        <div class="col-4 d-flex align-items-center">
          <b>Legal Last Name</b>
        </div>
        <div class="col-8 pt-2 pb-2">
          {{ profile.user.lastName }}
        </div>
      </div>


      <div class="row" formGroupName="user">
        <div class="col-4 d-flex align-items-center">
          <b>Middle Name</b>
        </div>
        <div class="col-8">
          <input type="text"
                 class="form-control mt-2"
                 formControlName="middleName" />
          <app-validation-message [form]="form"
                                  [path]="'user.middleName'"
                                  [name]="'middleName'"
                                  [label]="'Middle Name'">
          </app-validation-message>
        </div>
      </div>

      <div class="row" formGroupName="employee">
        <div class="col-4 d-flex align-items-center">
          <b>SSN</b>
        </div>
        <div class="col-8">
          <input type="text"
                 class="form-control mt-2"
                 formControlName="ssn" />
          <app-validation-message [form]="form"
                                  [path]="'employee.ssn'"
                                  [name]="'ssn'"
                                  [label]="'SSN'">
          </app-validation-message>
        </div>
      </div>

      <div class="row" formGroupName="employee">
        <div class="col-4 d-flex align-items-center">
          <b>Address</b>
        </div>
        <div class="col-8">
          <input type="text"
                 class="form-control mt-2"
                 formControlName="address" />
          <app-validation-message [form]="form"
                                  [path]="'employee.address'"
                                  [name]="'address'"
                                  [label]="'Address'">
          </app-validation-message>
        </div>
      </div>
      <div class="row" formGroupName="employee">
        <div class="col-4 d-flex align-items-center">
          <b>Apt Number</b>
        </div>
        <div class="col-8">
          <input type="text"
                 class="form-control mt-2"
                 formControlName="addressExtra" />
          <app-validation-message [form]="form"
                                  [path]="'employee.addressExtra'"
                                  [name]="'addressExtra'"
                                  [label]="'Address Extra'">
          </app-validation-message>
        </div>
      </div>
      <div class="row" formGroupName="employee">
        <div class="col-4 d-flex align-items-center">
          <b>City</b>
        </div>
        <div class="col-8">
          <input type="text" class="form-control mt-2" formControlName="city" />
          <app-validation-message [form]="form"
                                  [path]="'employee.city'"
                                  [name]="'city'"
                                  [label]="'City'">
          </app-validation-message>
        </div>
      </div>
      <div class="row" formGroupName="employee">
        <div class="col-4 d-flex align-items-center">
          <b>State</b>
        </div>
        <div class="col-8">
          <input type="text"
                 class="form-control mt-2"
                 formControlName="state" />
          <app-validation-message [form]="form"
                                  [path]="'employee.state'"
                                  [name]="'state'"
                                  [label]="'State'">
          </app-validation-message>
        </div>
      </div>
      <div class="row" formGroupName="employee">
        <div class="col-4 d-flex align-items-center">
          <b>Zip Code</b>
        </div>
        <div class="col-8">
          <input type="text"
                 class="form-control mt-2"
                 formControlName="zipCode" />
          <app-validation-message [form]="form"
                                  [path]="'employee.zipCode'"
                                  [name]="'zipCode'"
                                  [label]="'Zip Code'">
          </app-validation-message>
        </div>
      </div>

      <div class="row" formGroupName="employeeFederalTaxInformation">
        <div class="col-4 d-flex align-items-center">
          <b>Filing Status</b>
          <i class="fas fa-info-circle ml-2" [escape]="false" pTooltip="Single or Married filing separately<br>
          Married filing jointly or Qualifying widow(er)<br>
          Head of household (Check only if you’re unmarried and pay more than half the costs of keeping up a home for yourself and a qualifying individual.)"></i>
        </div>
        <div class="col-8  pt-2 pb-2">
          <p-selectButton [options]="filingStatusOptions" formControlName="filingStatus"></p-selectButton>
          <app-validation-message [form]="form" [path]="'employeeFederalTaxInformation.filingStatus'" [name]="'filingStatus'" [label]="'Filing Status'"> </app-validation-message>

        </div>
      </div>


      <h4 class="mt-3">Multiple Jobs or Spouse Works (step 2)</h4>

      <div class="row" formGroupName="employeeFederalTaxInformation">
        <div class="col-4 d-flex align-items-center">
          <b>Multiple Jobs (only 2 jobs in total)</b>
          <i class="fas fa-info-circle ml-2" pTooltip="If there are only two jobs total, you may check this box. Do the same on Form W-4 for the other job. This option is accurate for jobs with similar pay; otherwise, more tax than necessary may be withheld."></i>
        </div>
        <div class="col-8  pt-2 pb-2">
          <p-selectButton [options]="yesNoOptions" formControlName="higherRate"></p-selectButton>
          <app-validation-message [form]="form" [path]="'employeeFederalTaxInformation.higherRate'" [name]="'higherRate'" [label]="'Multiple Jobs or a Working Spouse'"> </app-validation-message>


        </div>
      </div>
      <h4 class="mt-2">
        Claim Dependents (step 3)
        <i class="fas fa-info-circle ml-2" pTooltip="If your total income will be $200,000 or less ($400,000 or less if married filing jointly)"></i>
      </h4>
      <div class="row pl-5" formGroupName="employeeFederalTaxInformation">
        <div class="col-4 d-flex align-items-center">
          <b>Child Dependents Amount</b>
          <i class="fas fa-info-circle ml-2" pTooltip="Multiply the number of qualifying children under age 17 by $2,000"></i>

        </div>
        <div class="col-8">
          <input type="text"
                 class="form-control mt-2 col-sm-6 col-md-3"
                 formControlName="childDependentsAmount" />
          <app-validation-message [form]="form"
                                  [path]="'employeeFederalTaxInformation.childDependentsAmount'"
                                  [name]="'childDependentsAmount'"
                                  [label]="'Child Dependents Amount'">
          </app-validation-message>
        </div>
      </div>

      <div class="row  pl-5" formGroupName="employeeFederalTaxInformation">
        <div class="col-4 d-flex align-items-center">
          <b>Other Dependents Amount</b>
          <i class="fas fa-info-circle ml-2" pTooltip="Multiply the number of other dependents by $500"></i>
        </div>
        <div class="col-8">
          <input type="text"
                 class="form-control mt-2 col-sm-6 col-md-3"
                 formControlName="otherDependentsAmount" />
          <app-validation-message [form]="form"
                                  [path]="'employeeFederalTaxInformation.otherDependentsAmount'"
                                  [name]="'otherDependentsAmount'"
                                  [label]="'Other Dependents Amount'">
          </app-validation-message>
        </div>
      </div>

      <div class="row" formGroupName="employeeFederalTaxInformation">
        <div class="col-4 d-flex align-items-center">
          <b>Totals Dependents Amount</b>
          <i class="fas fa-info-circle ml-2" pTooltip="Add the amounts above and enter the total here"></i>
        </div>
        <div class="col-8">
          <input type="text"
                 class="form-control mt-2 col-sm-6 col-md-3"
                 formControlName="totalDependentsAmount" />
          <app-validation-message [form]="form"
                                  [path]="'employeeFederalTaxInformation.totalDependentsAmount'"
                                  [name]="'totalDependentsAmount'"
                                  [label]="'Total Dependents Amount'">
          </app-validation-message>
        </div>
      </div>

      <h4 class="mt-2">
        Other Adjustments (step 4)
        <i class="fas fa-info-circle ml-2" pTooltip="Complete Steps 3–4(b) on Form W-4 for only ONE of these jobs. Leave those steps blank for the other jobs. (Your withholding will be most accurate if you complete Steps 3–4(b) on the Form W-4 for the highest paying job.)"></i>

      </h4>
      <div class="row" formGroupName="employeeFederalTaxInformation">
        <div class="col-4 d-flex align-items-center">
          <b>Other Income Amount</b>
          <i class="fas fa-info-circle ml-2" pTooltip="If you want tax withheld for other income you expect this year that won’t have withholding, enter the amount of other income here. This may include interest, dividends, and retirement income"></i>

        </div>
        <div class="col-8">
          <input type="text"
                 class="form-control mt-2 col-sm-6 col-md-3"
                 formControlName="otherIncomeAmount" />
          <app-validation-message [form]="form"
                                  [path]="'employeeFederalTaxInformation.otherIncomeAmount'"
                                  [name]="'otherIncomeAmount'"
                                  [label]="'Other Income Amount'">
          </app-validation-message>
        </div>
      </div>


      <div class="row" formGroupName="employeeFederalTaxInformation">
        <div class="col-4 d-flex align-items-center">
          <b>Deductions Amount</b>
          <i class="fas fa-info-circle ml-2" pTooltip="If you expect to claim deductions other than the standard deductionand want to reduce your withholding, use the Deductions Worksheet on page 3 and enter the result here"></i>
        </div>
        <div class="col-8">
          <input type="text"
                 class="form-control mt-2 col-sm-6 col-md-3"
                 formControlName="deductionsAmount" />
          <app-validation-message [form]="form"
                                  [path]="'employeeFederalTaxInformation.deductionsAmount'"
                                  [name]="'deductionsAmount'"
                                  [label]="'Deductions Amount'">
          </app-validation-message>
        </div>
      </div>


      <div class="row" formGroupName="employeeFederalTaxInformation">
        <div class="col-4 d-flex align-items-center">
          <b>Extra Withholding Amount</b>
          <i class="fas fa-info-circle ml-2" pTooltip="Enter any additional tax you want withheld each pay period"></i>
        </div>
        <div class="col-8">
          <input type="text"
                 class="form-control mt-2 col-sm-6 col-md-3"
                 formControlName="extraWithholdingAmount" />
          <app-validation-message [form]="form"
                                  [path]="'employeeFederalTaxInformation.extraWithholdingAmount'"
                                  [name]="'extraWithholdingAmount'"
                                  [label]="'Extra Withholding Amount'">
          </app-validation-message>
        </div>
      </div>

      <div class="row" formGroupName="employeeFederalTaxInformation">
        <div class="col-4 d-flex align-items-center">
          <b>Extra STATE Withholding Amount</b>
          <i class="fas fa-info-circle ml-2" pTooltip="Enter any additional amount you want withheld from each pay period for STATE taxes."></i>
        </div>
        <div class="col-8">
          <input type="text"
                 class="form-control mt-2 col-sm-6 col-md-3"
                 formControlName="extraStateWithholdingAmount" />
          <app-validation-message [form]="form"
                                  [path]="'employeeFederalTaxInformation.extraStateWithholdingAmount'"
                                  [name]="'extraStateWithholdingAmount'"
                                  [label]="'Extra STATE Withholding Amount'">
          </app-validation-message>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b>As an employer, we are not responsible for the amounts entered on this form. If you are unsure how to fill out this form, please utilize the provided IRS help pages, or contact your personal accountant.</b>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-6">
          <button class="btn btn-primary"
                  [class.disabled]="form.invalid"
                  (click)="save()">
            Update and Sign
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
