import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { SelectItem } from 'primeng/api';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { deepClone, getDateWithOffset } from '../../../../helpers/utils';
import { KraReportModel } from '../../../../models/employeeReviewModels';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { loadEmployeeUsers, loadViewableCampuses } from '../../../../core/store/employee/employee.action';
import { getKraList, loadTemplateFilter } from '../../../../core/store/employee-review/employee-review.actions';

@Component({
  selector: 'app-report-kra',
  templateUrl: './report-kra.component.html',
  styleUrls: ['./report-kra.component.scss']
})
export class ReportKraComponent extends BaseComponent implements OnInit {
  list: PagingResultsModel<KraReportModel>;
  filter: any;
  loadingList: boolean = true;

  campusList: SelectItem[];
  campusId: number = 0;

  prefixText: string;
  userEmployeeList: SelectItem[];
  userId: number = 0;

  startDate: string = formatDate(getDateWithOffset(-90), 'yyyy-MM-dd', 'en-US');
  endDate: string = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');

  templateList: SelectItem[];
  templateId: number = 0;

  yesNoAllOptions = [
    { label: 'All', value: null },
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  terminatedEmployeesFilter?: boolean = false;
  inactiveUsersFilter?: boolean = false;

  canViewCompleted;

  constructor(
    private router: Router,
    private actions$: Actions,
    private store: Store<State>) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.employeeState.campuses).subscribe(campuses => {
        this.campusList = campuses && campuses.length > 0 ? campuses
          : [{ label: 'loading...', value: undefined }];
      }),
      this.store.select(s => s.employeeState.employeeUsers).subscribe(users => {
        this.userEmployeeList = users;
      }),
      this.store.select(s => s.employeeReviewState.loadingKraList).subscribe(loadingKraList => {
        this.loadingList = loadingKraList;
      }),
      this.store.select(s => s.employeeReviewState.kraList).subscribe(kraList => {
        this.list = kraList;
      }),
      this.store.select(s => s.employeeReviewState.templateFilter).subscribe(templates => {
        this.templateList = templates && templates.length > 0 ? templates.filter(x => x.archived == false)
          : [{ label: 'loading...', value: undefined }];
      }),
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.canViewCompleted = permissionIDs && !!permissionIDs.find(p => p === 3081); //Can view completed KRAs
      }),
    );

    this.store.dispatch(loadViewableCampuses());
    this.store.dispatch(loadTemplateFilter());
  }

  reloadFilter(event) {
    this.filter = event;
    this.reloadList();
  }

  reloadList() {
    this.filter = deepClone(this.filter);
    this.filter.filters.userId = { Name: 'userId', Value: { value: this.userId.toString() } };
    this.filter.filters.programId = { Name: 'programId', Value: { value: this.campusId.toString() } };
    this.filter.filters.startDate = { Name: 'startDate', Value: { value: this.startDate } };
    this.filter.filters.endDate = { Name: 'endDate', Value: { value: this.endDate } };
    this.filter.filters.templateId = { Name: 'templateId', Value: { value: this.templateId.toString() } };
    this.filter.filters.showTerminatedEmployees = { Name: 'showTerminatedEmployees', Value: { value: this.terminatedEmployeesFilter } };
    this.filter.filters.showInactiveUsers = { Name: 'showInactiveUsers', Value: { value: this.inactiveUsersFilter } };

    this.store.dispatch(getKraList({
      filter: this.filter
    }));
  }

  getStaffList(event) {
    this.store.dispatch(loadEmployeeUsers({
      prefixText: event.query,
      programId: this.campusId,
      payrollClassificationId: null,
      showInactive: this.inactiveUsersFilter !== false ? true : false,
      showTerminated: this.terminatedEmployeesFilter !== false ? true : false
    }));
  }

  reloadListAfterStaffChage(selectedUser) {
    if (typeof selectedUser.value !== 'number') {
      return;
    }

    this.userId = selectedUser.value;
    this.reloadList();
  }

  reloadListAfterClear(event) {
    if (event.data !== null) {
      return;
    }

    this.userId = 0;
    this.reloadList();
  }

  openReview(review) {
    this.openLink(`/employee-review/view/${review.employeeReviewID}`);
  }

  openLink(path) {
    this.router.navigate([path]);
  }

  getTerminationString(review) {
    if (review.terminationDate != null) {
      return "(Separated)";
    }

    if (review.inactiveUser) {
      return "(Inactive)"
    }

    return "";
  }
}
