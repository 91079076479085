<div class="row m-0">
  <div class="col-12">
    <h2>{{benefitPlan.benefitPlan.planName}}</h2>
    <h5 class="mt-3 mb-2">Carrier</h5>
    <p>{{benefitPlan.benefitPlan.carrier}}</p>

    <h4 class="mt-3 mb-2">Plan Summary</h4>
    <p>{{benefitPlan.benefitPlan.summary}}</p>

    <ng-container *ngIf="benefitPlan.costs && benefitPlan.costs.length">
    <h4 class="mt-3 mb-2">Costs</h4>
    <p-table  [value]="benefitPlan.costs" [responsive]="true" >
      <ng-template pTemplate="header">
          <tr>
              <th >Coverage Level
              </th>
              <th >Monthly Cost
              </th>
              <th >Pay Period Cost
              </th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-benefitsPlanCost>
          <tr >
            <td>
              <span class="ui-column-title">Coverage Level</span>
              {{benefitsPlanCost.coverageLevelLabel}} - {{benefitsPlanCost.coverageLevelDescription}}
            </td>
            <td>
              <span class="ui-column-title">Monthly Cost</span>
              <div  [innerHtml]="getMonthlyCost(benefitsPlanCost)"></div>
            </td>

            <td>
              <span class="ui-column-title">Pay Period Cost</span>
              <div [innerHtml]="getCostPerPayPeriod(benefitsPlanCost)"></div>
            </td>

          </tr>
      </ng-template>
  </p-table>
</ng-container>
<ng-container *ngIf="benefitPlan.benefitPlan.ellegibilityBeginLabel || benefitPlan.benefitPlan.terminationUponLabel">
  <h4 class="mt-3 mb-2">Eligibility</h4>

  <h6 class="mt-3 mb-2" *ngIf="benefitPlan.benefitPlan.ellegibilityBeginLabel"><b>Eligibility Begins:</b></h6>
  {{benefitPlan.benefitPlan.ellegibilityBeginLabel}}
  <h6 class="mt-3 mb-2" *ngIf="benefitPlan.benefitPlan.terminationUponLabel"><b>Separation Upon:</b></h6>
  {{benefitPlan.benefitPlan.terminationUponLabel}}

</ng-container>

  <h4 class="mt-3 mb-2">Resources</h4>
  <div class="flex flex-column">
    <div *ngFor="let link of benefitPlan.links">
      <a  [href]="formatLink(link.url)" target="newwindow"><i class="fas fa-link"></i> {{link.linkName}}</a>
    </div>
    <div *ngFor="let doc of benefitPlan.documents">
      <a  [href]="doc.url" target="newwindow"> <i class="fas fa-paperclip"></i> {{doc.documentName}}</a>
    </div>
  </div>

  </div>
</div>
