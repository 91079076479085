<form class="card mt-2">
  <div class="card-body">

      <h2 class="mb-3">Improvements</h2>
      <h5>Filter</h5>

      <div class="row ">

        <div class="col-12 col-sm-6">

          <div class="row ">
            <div class="col-4 col-lg-2 col-xl-1 d-flex align-items-center">
              Level:
            </div>
            <div class="col-8 col-lg-10 col-xl-11 ">
              <p-dropdown styleClass="form-control mt-2" [options]="levelOptions" [(ngModel)]="selectedLevelFilter" (ngModelChange)="changedFilter()"
              [ngModelOptions]="{standalone: true}" [placeholder]="'Select level...'"></p-dropdown>
            </div>
          </div>

        </div>
        <div class="col-12 col-sm-6">
          <div class="row ">
            <div class="col-4 col-lg-2 col-xl-2 d-flex align-items-center">
              Acknowledged:
            </div>
            <div class="col-8 col-lg-10 col-xl-10 ">
              <p-dropdown styleClass="form-control mt-2" [options]="seenOptions" [(ngModel)]="selectedSeenFilter" (ngModelChange)="changedFilter()"
              [ngModelOptions]="{standalone: true}" [placeholder]="'Select seen...'"></p-dropdown>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-4 col-lg-2 col-xl-1 d-flex align-items-center">
              Done:
            </div>
            <div class="col-8 col-lg-10 col-xl-11 ">
              <p-dropdown styleClass="form-control mt-2" [options]="checkedOptions" [(ngModel)]="selectedCheckedFilter" (ngModelChange)="changedFilter()"
              [ngModelOptions]="{standalone: true}" [placeholder]="'Select checked...'"></p-dropdown>
            </div>
          </div>
        </div>
    </div>

    <i *ngIf="loadingCoachingLogsList" class="material-icons rotate text-muted">loop</i>
    <p-table  [value]="coachingLogsList?.result" [responsive]="true"
    [paginator]="true" [rows]="20" [totalRecords]="coachingLogsList?.totalRecords" [lazy]="true"
    (onLazyLoad)="loadPage($event)">
        <ng-template pTemplate="header">
          <tr>
            <th width="160px" [pSortableColumn]="'salaryPayRate'">
              Acknowledged
              <p-sortIcon [field]="'seenDate'"></p-sortIcon>
            </th>
            <th width="100px" [pSortableColumn]="'signed'">
              Done
              <p-sortIcon [field]="'checkedDate'"></p-sortIcon>
            </th>
            <th width="90px">Date</th>
            <th width="90px">Follow Up</th>
            <th width="180px">Created by</th>
            <th width="120px">Level</th>
            <th>Description</th>
            <th>Categories of the Issue</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-coachingLogWithUsersModel>
            <tr >
                <td>
                  <span class="ui-column-title">Acknowledged</span>

                  <i class="fas fa-check-circle text-success" *ngIf="!!coachingLogWithUsersModel.seenDate"></i>
                  {{coachingLogWithUsersModel.seenDate  | date:'shortDate'}}
                  <i class="fas fa-minus-circle text-secondary pointer" (click)="markAsSeen(coachingLogWithUsersModel)" *ngIf="!coachingLogWithUsersModel.seenDate"></i>
                </td>
                <td>
                  <span class="ui-column-title">Done</span>
                  <i class="fas fa-check-circle text-success" *ngIf="!!coachingLogWithUsersModel.checkedDate"></i>
                  {{coachingLogWithUsersModel.checkedDate  | date:'shortDate'}}
                  <i class="fas fa-minus-circle text-secondary "  *ngIf="!coachingLogWithUsersModel.checkedDate"></i>
                </td>
                <td>
                  <span class="ui-column-title">Created</span>
                  {{coachingLogWithUsersModel.noteDate | date:'shortDate' }}
                </td>
                 <td>
                  <span class="ui-column-title">Created</span>
                  {{coachingLogWithUsersModel.followUpDate | date:'shortDate' }}
                </td>
                <td>
                  <span class="ui-column-title">Created By</span>
                {{coachingLogWithUsersModel.createdByUser.firstName }} {{coachingLogWithUsersModel.createdByUser.lastName }}
                </td>
                <td>
                  <span class="ui-column-title">Level</span>
                {{getLevel(coachingLogWithUsersModel.level)}}
                </td>
                <td>
                  <span class="ui-column-title">Description</span>
                  <span *ngIf="!coachingLogWithUsersModel.improvementPlanID">{{coachingLogWithUsersModel.description}}</span>
                  <div *ngIf="coachingLogWithUsersModel.improvementPlanID">
                    <span class="font-weight-bold">Supervisor:</span> {{coachingLogWithUsersModel.improvementPlan?.supervisorUser?.firstName}} {{coachingLogWithUsersModel.improvementPlan?.supervisorUser?.lastName}}<br/>
                      <span class="font-weight-bold">Status:</span> {{getImprovementModelStatus(coachingLogWithUsersModel.improvementPlan)}}<br/>
                        <span *ngIf="coachingLogWithUsersModel.improvementPlan?.nextReviewDate"><span class="font-weight-bold">Next Review Date:</span> {{coachingLogWithUsersModel.improvementPlan?.nextReviewDate | date:'shortDate' }}<br></span>
                        <a class="mr-2" href="javascript:void(0)" *ngIf="!coachingLogWithUsersModel.improvementPlan.signedDateEmployee" (click)="markAsSeen(coachingLogWithUsersModel)">Sign</a>
                        <a class="mr-2" [routerLink]="'/improvementplan/view/'+coachingLogWithUsersModel.improvementPlan.id">Open</a>
                        <a class="mr-2" href="javascript:void(0)" (click)="downloadImprovementPlan(coachingLogWithUsersModel.improvementPlan)">Download</a>
                  </div>
                  <div *ngIf="coachingLogWithUsersModel.followUp">
                    <span class="font-weight-bold">First Follow Up:</span><br />
                    {{coachingLogWithUsersModel.followUp}}
                  </div>
                  <div *ngIf="coachingLogWithUsersModel.newFollowUp">
                    <span class="font-weight-bold">Second Follow Up:</span><br />
                    {{coachingLogWithUsersModel.newFollowUp}}
                  </div>
                </td>
                <td>
                  <span class="ui-column-title">Level</span>
                  {{coachingLogWithUsersModel.categories.join(", ")}}
                </td>
            </tr>
        </ng-template>
    </p-table>
  </div>
</form>
