<signature-pad
  *ngIf="changing"
  class="d-block w-100 border-sign"
  [options]="signaturePadOptions"
></signature-pad>
<img [src]="savedSignatureSrc" *ngIf="!changing && savedSignatureSrc && savedSignatureSrc!=='null'" />
<div class="w-100 text-center p-5" *ngIf="!changing && (!savedSignatureSrc || savedSignatureSrc==='null')">
  NO SIGNATURE DEFINED
</div>
<div class="w-100 text-right mt-2">

  <i *ngIf="savingSignature" class="material-icons rotate text-muted">loop</i>
  <button *ngIf="changing && !savingSignature" class="btn btn-primary" (click)="save()">Save</button>
  <button *ngIf="changing && !savingSignature" class="btn btn-secondary ml-2"  (click)="changing = false;">Cancel</button>
  <button *ngIf="!changing && !savedSignatureSrc" class="btn btn-primary" (click)="startChanging()">
    Create Signature
  </button>
  <button *ngIf="!changing && savedSignatureSrc" class="btn btn-primary" (click)="startChanging()">
    Change
  </button>
</div>
