<div class='card w-100 bg-light mb-2 p-2'>
  <div class="w-100">
    <div class="col-12 d-flex align-content-center">
      <h5>{{kpiCategory.category.category}}</h5>
      <button class="btn btn-success ml-2 mr-2" [disabled]="loading" (click)="addNewMeasureable()">Add Measureable</button>
      <button class="btn btn-danger" [disabled]="loading" (click)="remove()">Remove {{kpiCategory.category.category}}</button>
    </div>
  </div>

  <div class="w-100" *ngFor="let measureable of kpiCategory.measureables; let i=index">

    <app-kpi-measureable-template-component [measureable]="measureable" class="w-100"></app-kpi-measureable-template-component>

  </div>
</div>
