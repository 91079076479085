import { downloadImprovementPlanDoc } from './../../../../core/store/improvementPlan/improvementPlan.actions';
import { ImprovementPlanModel } from './../../../../models/improvementPlanModel';
import {
  markCoachingLogAsChecked, markCoachingLogAsCheckedSuccess,
  getMyCoahingLog, markCoachingLogAsSeen, markCoachingLogAsSeenSuccess
} from './../../../../core/store/coachingLog/coahingLog.actions';
import { CoachingLogWithUsersModel } from 'src/app/models/coachingLogWithUsersModel';
import { PagingResultsModel } from './../../../../models/pagingResultsModel';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { Actions, ofType } from '@ngrx/effects';
import { AlertService } from 'src/app/shared/services/alert.service';
import { getUsersList } from 'src/app/core/store/users/users.actions';
import { SelectItem } from 'primeng/api';
import { deepClone, getShortDateFormatted, downloadFile } from 'src/app/helpers/utils';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list-my-coachinglog',
  templateUrl: './list-my-coachinglog.component.html',
  styleUrls: ['./list-my-coachinglog.component.scss']
})
export class ListMyCoachingLogComponent extends BaseComponent implements OnInit {

  coachingLogsList: PagingResultsModel<CoachingLogWithUsersModel>;
  loadingCoachingLogsList = false;
  filter: any;
  usersList: SelectItem[] = [{ label: 'loading...', value: undefined }];
  seenOptions: SelectItem[] = [{ label: 'All', value: null },
  { label: 'Acknowledged', value: true }, { label: 'Not Acknowledged', value: false }];
  checkedOptions: SelectItem[] = [{ label: 'All', value: null }, { label: 'Done', value: true }, { label: 'Not Done', value: false }];
  levelOptions: SelectItem[] = [{ label: 'All', value: null }, { label: 'Re-Focus', value: 1 }, { label: 'Corrective + Plan', value: 2 },
  { label: 'Improvement Plan', value: 4 }];
  selectedUserID: number;
  selectedSeenFilter?: boolean;
  selectedCheckedFilter?: boolean;
  selectedLevelFilter?: number;

  constructor(
    private actions$: Actions,
    private alertService: AlertService,
    private store: Store<State>,
    private router: Router,) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.coachingLogState.myCoachingLogsList).subscribe(myCoachingLogsList => {
        this.coachingLogsList = myCoachingLogsList;
      }),

      this.store.select(s => s.coachingLogState.loadingMyCoachingLogsList).subscribe(loadingMyCoachingLogsList => {
        this.loadingCoachingLogsList = loadingMyCoachingLogsList;
      }),

      this.store.select(s => s.usersState.usersList).subscribe(usersList => {
        this.usersList = usersList ? usersList.map(u => ({ label: u.firstName + ' ' + u.lastName, value: u.id }))
          : [{ label: 'loading...', value: undefined }];
        this.usersList.unshift({ label: 'All', value: null });
      }),
      this.actions$.pipe(
        ofType(markCoachingLogAsSeenSuccess),
        tap(action => {
          this.loadPage(this.filter);
        })
      ).subscribe()
    );
    this.store.dispatch(getUsersList());
  }

  changedFilter() {
    this.loadPage(this.filter);
  }


  loadPage(event) {
    this.filter = event;
    this.filter.filters.userID = { Name: 'userID', Value: { value: this.selectedUserID } };
    this.filter.filters.seen = { Name: 'seen', Value: { value: this.selectedSeenFilter } };
    this.filter.filters.checked = { Name: 'checked', Value: { value: this.selectedCheckedFilter } };
    this.filter.filters.level = { Name: 'level', Value: { value: this.selectedLevelFilter } };

    this.store.dispatch(getMyCoahingLog({ filter: deepClone(this.filter) }));
  }

  hasSeenDate(coachingLogWithUsersModel) {
    return !(coachingLogWithUsersModel.seenDate === new Date(null));
  }

  hasCheckedDate(coachingLogWithUsersModel) {
    return !(coachingLogWithUsersModel.checkedDate === new Date(null));
  }

  getLevel(level: number) {
    if (level === 1) {
      return 'Re-Focus';
    } else if (level === 2) {
      return 'Coaching';
    } else if (level === 3) {
      return 'Coaching + Plan';
    } else if (level === 4) {
      return 'Corrective + Plan';
    }

  }

  markAsSeen(coachingLogWithUsersModel: CoachingLogWithUsersModel) {
    if (coachingLogWithUsersModel.level === 4) {
      this.router.navigate(['/improvementplan/sign/' + coachingLogWithUsersModel.improvementPlanID], { queryParams: { backTo: 'my' } });
    } else {
      this.store.dispatch(markCoachingLogAsSeen({ coachingLogID: coachingLogWithUsersModel.id }));
    }
  }


  getImprovementModelStatus(improvementPlan: ImprovementPlanModel) {
    let status = '';
    if (!improvementPlan.signedDateEmployee) {
      status += 'Missing Employee Signature ';
    }
    if (!improvementPlan.signedDateSupervisor) {
      status += 'Missing Supervisor Signature';
    }
    if (improvementPlan.signedDateSupervisor && improvementPlan.signedDateEmployee) {
      if (improvementPlan.success) {
        status = 'Success';
      } else {
        if (!improvementPlan.nextReviewDate && improvementPlan.success === false) {
          status = 'Failed';
        } else {
          status = 'Active';
        }
      }
    }
    return status;
  }

  downloadImprovementPlan(improvementPlan: ImprovementPlanModel) {
    const subDocBuffer = this.store.select(s => s.improvementPlanState.docArrayBuffer).subscribe(docArrayBuffer => {
      if (docArrayBuffer) {
        downloadFile(new Blob([docArrayBuffer], { type: 'application/pdf' }), 'ImprovementPlan' + improvementPlan.id + '.pdf');
        subDocBuffer.unsubscribe();
      }
    });

    this.store.dispatch(downloadImprovementPlanDoc({ improvementPlanID: improvementPlan.id }));
  }
}

