import { ClientSimpleModel } from './../../../../models/clientSimpleModel';
import { FBFormAssignmentModel } from './../../../../models/formBuilderModels';
import { addFBFormAssignment, getAllForms, addFBFormAssignmentSuccess, getInquiryList, getFormsFilter } from './../../../../core/store/form-builder/form-builder.actions';
import { getUsersList } from './../../../../core/store/users/users.actions';
import { additTimeCard, loadManualEntryReasons, updateTimeCardForm } from './../../../../core/store/timecard/timecard.actions';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { loadClients } from 'src/app/core/store/timecard/timecard.actions';
import { TimeCardModel } from 'src/app/models/timeCardModel';
import { FormGroup } from '@angular/forms';
import { initTimeCardEditForm } from 'src/app/core/store/timecard/timecard.reducer';
import { SelectItem } from 'primeng/api';
import { markFormGroupTouched, getAllControlsErrors, updateFormGroupValidity, deepClone } from 'src/app/helpers/utils';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { Actions, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { InputAutocompleteComponent } from 'src/app/shared/input-helpers/input-autocomplete/input-autocomplete.component';

@Component({
  selector: 'app-form-assignment-create',
  templateUrl: './form-assignment-create.component.html',
  styleUrls: ['./form-assignment-create.component.scss']
})
export class FormAssignmentCreateComponent extends BaseComponent implements OnInit {

  @ViewChild('PatientMultiSelect') multiSelect: InputAutocompleteComponent;
  @Input() internApplicationId?: number;
  @Input() isInternDecisionForm?: boolean;

  formAssignment: FBFormAssignmentModel = {};
  usersOptions: SelectItem[];
  formOptions: SelectItem[];
  addingFormAssignment = false;

  yesNoOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  selectedForm;
  formsList;
  inquiryList: SelectItem[];
  showPatientInput: boolean = false;
  showEmployeeInput: boolean = false;
  inquiryIds: any[] = [];

  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store<State>) {
    super();
  }

  ngOnInit() {
    this.store.dispatch(getFormsFilter());
    this.store.dispatch(getUsersList());
    this.store.dispatch(getInquiryList());
    this.subs.push(
      this.store.select(s => s.usersState.usersList).subscribe(usersList => {
        this.usersOptions = usersList ? usersList.map(u => ({ label: u.firstName + ' ' + u.lastName, value: u.id }))
          : [{ label: 'loading...', value: undefined }];
      }),
      this.store.select(s => s.formBuilderState.inquiryList).subscribe(inquiryList => {
        this.inquiryList = inquiryList ? inquiryList.map(u => ({ label: u.name, value: u.inquiryID }))
          : [{ label: 'loading...', value: undefined }];
      }),
      this.store.select(s => s.formBuilderState.simpleFormsList).subscribe(formsList => {
        let onlyInternDocs = this.internApplicationId > 0;
        this.formOptions = formsList ? formsList.filter(c => c.formTypeID != 17 && (!onlyInternDocs || (this.isInternDecisionForm && c.formTypeID == 28) || (!this.isInternDecisionForm && c.formTypeID == 26))).map(c => ({ label: c.name, value: c.id })) : [];
        this.formsList = formsList ? formsList.filter(c => c.formTypeID != 17) : [];
      }),

      this.store.select(s => s.formBuilderState.addingFormAssignment).subscribe(addingFormAssignment => {
        this.addingFormAssignment = addingFormAssignment;
      }),

      this.actions$.pipe(
        ofType(addFBFormAssignmentSuccess),
        tap(action => {
          if (this.internApplicationId > 0) {
            return;
          }
          this.router.navigate(['/form-builder/forms-assignment']);
        })
      ).subscribe(),
    );
  }

  ngOnChanges() {
    let onlyInternDocs = this.internApplicationId > 0;
    this.formOptions = this.formsList ? this.formsList.filter(c => c.formTypeID != 17 && (!onlyInternDocs || (this.isInternDecisionForm && c.formTypeID == 28) || (!this.isInternDecisionForm && c.formTypeID == 26))).map(c => ({ label: c.name, value: c.id })) : [];
  }

  save() {
    if (!this.didChooseAllOptions()) {
      return;
    }

    let assignment = deepClone(this.formAssignment);

    assignment.internApplicationId = this.internApplicationId > 0 ? this.internApplicationId : null;
    assignment.isInternDecisionForm = this.isInternDecisionForm;
    this.store.dispatch(addFBFormAssignment({ formAssignment: assignment }));
    this.multiSelect.onSave();
  }

  didChooseAllOptions() {
    return this.selectedForm &&
      !((this.selectedForm?.formTypeID === 1
        && (!(this.formAssignment.userID || this.formAssignment.userIDs?.length > 0)))
        ||
        (this.selectedForm?.formTypeID === 2
          && (!(this.formAssignment.inquiryIDs || this.formAssignment.inquiryIDs?.length > 0)))
      );
  }

  setPatient(e) {
    this.inquiryIds = e;
    this.formAssignment.inquiryIDs = e;
    console.log(this.formAssignment.inquiryIDs);
  }

  changedForm() {
    this.selectedForm = this.formsList.find(x => x.id === this.formAssignment.formID);
    if (this.selectedForm.clientOrEmployee == "Client") {
      this.showPatientInput = true;
      this.showEmployeeInput = false;
    }
    else if (this.selectedForm.clientOrEmployee == "Employee") {
      this.showPatientInput = false;
      this.showEmployeeInput = true;
    }
    else {
      this.showPatientInput = false;
      this.showEmployeeInput = false;
    }
  }
}
