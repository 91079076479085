import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InternApplicationActionHasUserModel, InternApplicationActionModel } from '../../../../models/internModels';
import { SelectItem } from 'primeng/api';
import { closeEditInternActionItem, deleteInternActionItem, deleteInternActionItemSuccess, getInternActionItem, loadInternshipActionTypes, saveInternActionItem, saveInternActionItemSuccess } from '../../../../core/store/intern/intern.action';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { getDateWithOffset, getOptoLinkFromUrl, markFormGroupTouched, updateFormGroupValidity } from '../../../../helpers/utils';
import { loadEmployeeUsers } from '../../../../core/store/employee/employee.action';
import { formatDate } from '@angular/common';
import { AlertService } from '../../../../shared/services/alert.service';

@Component({
  selector: 'app-edit-intern-action',
  templateUrl: './edit-intern-action.component.html',
  styleUrls: ['./edit-intern-action.component.scss']
})
export class EditInternActionComponent extends BaseComponent implements OnInit {

  @Input() isEmployeeView: boolean;

  display: boolean = false;
  title: string;
  form: FormGroup;
  disableButtons: boolean = true;
  actionItemId: number = 0;
  actionApplicationId: number = 0;

  showOnlyActiveEmployees: boolean = true;

  actionTypes: SelectItem[];
  userEmployeeList: SelectItem[];

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private alertService: AlertService,
    private fb: FormBuilder) {
      super()
  }

  ngOnInit(): void {
    this.form = this.createForm();
    this.subs.push(      
      this.store.select(s => s.internState.editingActionItem).subscribe(editing => {
        if (this.display && !editing || (editing && this.actionItemId == 0)) {
          this.resetForm();
        }
        this.display = editing;

      }),
      this.store.select(s => s.internState.savingActionItem).subscribe(saving => {
        this.disableButtons = saving;
      }),
      this.store.select(s => s.internState.actionItemId).subscribe(id => {
        this.actionItemId = id;
        this.title = "New Action Item";
        if (typeof id !== 'undefined' && id > 0) {
          this.store.dispatch(getInternActionItem({ actionId: id }));
        }
      }),
      this.store.select(s => s.internState.actionApplicationId).subscribe(id => {
        this.actionApplicationId = id;        
      }),
      this.store.select(s => s.internState.selectedActionItem).subscribe(selectedItem => {
        if (selectedItem === null) {
          this.resetForm();
          return;
        }

        this.title = "Edit Action Item #" + selectedItem.actionId;
        this.form.get("actionId").setValue(selectedItem.actionId);
        this.form.get("applicationId").setValue(selectedItem.applicationId);
        this.form.get("actionTypeId").setValue(selectedItem.actionTypeId);    
        this.form.get("request").setValue(selectedItem.request != null ? selectedItem.request : "");
        this.form.get("response").setValue(selectedItem.response != null ? selectedItem.response : "");
        this.form.get("dateRequestedString").setValue(selectedItem.dateRequestedString);
        this.form.get("dateCompletedString").setValue(selectedItem.dateCompletedString);
        this.form.get("dueDateString").setValue(selectedItem.dueDateString);
        this.form.get("markCompleted").setValue(selectedItem.markCompleted);
        this.form.get("isEmployeeAction").setValue(selectedItem.isEmployeeAction);
        this.form.get("createUpdateTask").setValue(selectedItem.createUpdateTask);
        this.form.get("taskId").setValue(selectedItem.taskId);
        this.form.get("currentUserId").setValue(selectedItem.currentUserId);
        if (selectedItem.assignedUsers != null && selectedItem.assignedUsers.length > 0) {
          this.resetEventUsers(false);
          selectedItem.assignedUsers.forEach(u => {
            this.addUserDataToActionItem(u);
          });

        } else {
          this.resetEventUsers(true);
        }

      }),
      this.store.select(s => s.internState.internshipActionTypes).subscribe(types => {
        if (types && types.length > 0) {
          this.actionTypes = types.map(x => ({ label: x.label, value: x.value, disabled: x.disabled }));
          this.actionTypes.unshift({ label: '', value: undefined, disabled: false });
        }
        else {
          this.actionTypes = [{ label: 'loading...', value: undefined }];
        }
      }),
      this.store.select(s => s.employeeState.employeeUsers).subscribe(users => {
        this.userEmployeeList = users;
      }),
      this.actions$.pipe(
        ofType(saveInternActionItemSuccess),
        tap(action => {
          this.closeModal();
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(deleteInternActionItemSuccess),
        tap(action => {
          this.closeModal();
        })
      ).subscribe(),
    );

    if (this.actionTypes == null || this.actionTypes.length == 0 || this.actionTypes[0].label === 'loading...') {
      this.store.dispatch(loadInternshipActionTypes());
    }
  }

  createForm() {
    let f = this.fb.group({
      actionId: this.fb.control(0),
      applicationId: this.fb.control(0),
      actionTypeId: this.fb.control(0, [Validators.required]),      
      request: this.fb.control(""),
      response: this.fb.control(""),
      dateRequestedString: this.fb.control(""),
      dateCompletedString: this.fb.control(""),
      dueDateString: this.fb.control(formatDate(getDateWithOffset(2), 'yyyy-MM-dd', 'en-US')),
      markCompleted: this.fb.control(false),
      isEmployeeAction: this.fb.control(false),
      createUpdateTask: this.fb.control(false),
      taskId: this.fb.control(0),
      currentUserId: this.fb.control(0),
      assignedUsers: this.fb.array([this.fb.group({
        actionHasUserId: this.fb.control(0),
        actionItemId: this.fb.control(0),
        employeeName: this.fb.control(""),
        userId: this.fb.control(0),
        selectedUser: this.fb.control({
          value: 0,
          label: ""
        })
      })]),
    });    

    return f;
  }

  resetForm() {
    this.form.reset({
      actionId: 0,
      applicationId: 0,
      actionTypeId: 0,
      assignedToUserId: 0,
      taskId: 0,
      currentUserId: 0,
      request: "",
      response: "",
      dateRequestedString: "",
      dateCompletedString: "",
      dueDateString: formatDate(getDateWithOffset(2), 'yyyy-MM-dd', 'en-US'),
      markCompleted: false,
      isEmployeeAction: false,
      createUpdateTask: false
    });

    this.resetEventUsers(true);
  }

  closeModal() {
    this.store.dispatch(closeEditInternActionItem());
  }

  getStaffList(event) {
    this.store.dispatch(loadEmployeeUsers({
      prefixText: event.query,
      programId: null,
      payrollClassificationId: null,
      showTerminated: !this.showOnlyActiveEmployees,
      showInactive: !this.showOnlyActiveEmployees
    }));
  }

  resetEventUsers(addEmpty) {
    let assignedUsers = this.form.get('assignedUsers') as FormArray;
    assignedUsers.clear();
    if (addEmpty) {
      this.addUserToActionItem();
    }
  }

  addUserToActionItem() {
    let assignedUsers = this.form.get('assignedUsers') as FormArray;
    assignedUsers.push(this.fb.group({
      actionHasUserId: this.fb.control(0),
      actionItemId: this.fb.control(0),
      employeeName: this.fb.control(""),
      userId: this.fb.control(0),
      selectedUser: this.fb.control({
        value: 0,
        label: ""
      })
    }));
    return false;
  }

  addUserDataToActionItem(user: InternApplicationActionHasUserModel) {
    if (user == null) {
      return;
    }

    let assignedUsers = this.form.get('assignedUsers') as FormArray;
    assignedUsers.push(this.fb.group({
      actionHasUserId: this.fb.control(user.actionHasUserId),
      actionItemId: this.fb.control(user.actionItemId),
      employeeName: this.fb.control(user.employeeName),
      userId: this.fb.control(user.userId),
      selectedUser: this.fb.control({
        value: user.userId,
        label: user.employeeName
      })
    }));
    return false;
  }

  setUserAfterStaffChange(selectedUser, index) {    
    if (typeof selectedUser.value !== 'number') {
      return;
    }

    let assignedUsers = this.form.get('assignedUsers') as FormArray;
    let userToUpdate = assignedUsers.at(index) as FormGroup;
    userToUpdate.patchValue({
      employeeName: selectedUser.label,
      userId: selectedUser.value
    });  
  }

  clearUserAfterClear(event, index) {    
    if (event.data !== null) {
      return;
    }

    let assignedUsers = this.form.get('assignedUsers') as FormArray;
    let userToUpdate = assignedUsers.at(index) as FormGroup;
    userToUpdate.patchValue({
      employeeName: "",
      userId: 0
    });
  }

  removeUserFromActionItem(index) {
    let assignedUsers = this.form.get('assignedUsers') as FormArray;
    assignedUsers.removeAt(index);
    return false;
  }

  saveActionItem() {
    updateFormGroupValidity(this.form);
    if (this.form.valid) {
      if (this.form.get("applicationId").value <= 0) {
        this.form.get("applicationId").setValue(this.actionApplicationId);
      }
      this.store.dispatch(saveInternActionItem({ actionItem: this.form.value as InternApplicationActionModel }));
    } else {
      markFormGroupTouched(this.form);
    }
  }

  deleteActionItem() {
    if (this.actionItemId > 0) {
      this.store.dispatch(deleteInternActionItem({ actionId: this.actionItemId }));
    }
    else {
      this.closeModal(); //delete button should be hidden if not > 0
    }
  }

  getDateRequested() {
    return this.form.get("dateRequestedString").value;
  }

  getDateCompleted() {
    return this.form.get("dateCompletedString").value;
  }

  getIsEmployeeView() {
    return this.isEmployeeView;
  }

  getResponseReadOnly() {
    if (this.isEmployeeView) {
      return false;
    }

    let currentUserId = this.form.get("currentUserId").value
    if (currentUserId <= 0) {
      return true;
    }

    //can read if currentUser is assigned to action
    let assignedUsers = this.form.get('assignedUsers') as FormArray;
    for (var i = 0; i < assignedUsers.value.length; i++) {
      let u = assignedUsers.value[i];
      if (u.userId == currentUserId) {
        return false;
      }
    }

    return true;
  }

  getActionItemUsers() {
    return (this.form.get('assignedUsers') as FormArray).controls;
  }

  getCreateUpdateTaskString() {
    return this.form.get("taskId").value > 0 ? "Update Task" : "Create Task";
  }

  openTaskInOpto() {
    let taskId = this.form.get("taskId").value;
    if (taskId > 0) {
      window.open(getOptoLinkFromUrl() + "/Dashboard/TaskList/ViewTask/?id=" + taskId + "&partial=false", "_blank");
    }
    else {
      this.alertService.error("Task not found. Please submit a support ticket");
    }
  }

  getTaskString() {
    let taskId = this.form.get("taskId").value;

    return taskId > 0 ? "#"+ taskId : "";    
  }

  hasTask() {
    return this.form.get("taskId").value > 0;
  }
}
