<div>
  <div class="w-100 text-center mb-3 d-sm-flex justify-content-center ">
    <span class="d-inline-flex align-items-center " >
      <h1>Self Care Management</h1>
    </span>
  </div>
  <div class="row">
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mt-2 ">
      <div class="card pointer" (click)="openLink('/selfcare/management/planner')">
        <div class="card-body">
          <div class="text-center font-big-icon">
            <i class="fas fa-window-frame"></i><br />
            Manage Planner
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mt-2 ">
      <div class="card pointer" (click)="openLink('/selfcare/management')">
        <div class="card-body">
          <div class="text-center font-big-icon">
            <i class="fas fa-certificate"></i><br />
            Manage Categories
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
