import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { Actions, ofType } from '@ngrx/effects';
import { State } from '../../../../core/store';
import { Store } from '@ngrx/store';
import { DropdownFilterService } from '../../../../core/services/dropdown-filter.service';
import { SelectItem } from 'primeng/api';
import { CustomFilterModel } from '../../../../models/customFilterModel';
import { DateRangeModel } from '../../../../models/dateRangeModel';
import { loadEmployeeUsers, loadViewableCampuses } from '../../../../core/store/employee/employee.action';
import { BenefitStatusCheckModel } from '../../../../models/benefitsModels';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { getBenefitStatusQueue, markAsExemptBenefit, markAsExemptBenefitSuccess, switchBenefitStatus, switchBenefitStatusSuccess, verifyBenefitCheck, verifyBenefitCheckSuccess } from '../../../../core/store/benefits/benefits.actions';
import { deepClone } from '../../../../helpers/utils';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-queue-eligibility-status',
  templateUrl: './queue-eligibility-status.component.html',
  styleUrls: ['./queue-eligibility-status.component.scss']
})
export class QueueEligibilityStatusComponent extends BaseComponent implements OnInit {
  checks: PagingResultsModel<BenefitStatusCheckModel>;
  filter: any;
  canEdit: boolean = false;

  loadingQueue: boolean = true;

  campusList: SelectItem[];
  campusId: number = 0;

  prefixText: string;
  userEmployeeList: SelectItem[];
  userId: number = 0;

  dateRangeList: SelectItem[];
  dateRange: DateRangeModel;
  startDate: string = null;
  endDate: string = null;
  isCustomDateRange: boolean = false;

  hourRangeList: SelectItem[];
  hourRange: CustomFilterModel;
  minHours: string = null;
  maxHours: string = null;
  isCustomHourRange: boolean = false;

  statusOptions: SelectItem[];
  status: string = null;

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private dropdownFilterService: DropdownFilterService) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.benefitsState.loadingBenefitsQueue).subscribe(loadingQueue => {
        this.loadingQueue = loadingQueue;
      }),
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.canEdit = permissionIDs && !!permissionIDs.find(p => p === 3075);
      }),
      this.store.select(s => s.employeeState.campuses).subscribe(campuses => {
        this.campusList = campuses && campuses.length > 0 ? campuses
          : [{ label: 'loading...', value: undefined }];
      }),
      this.store.select(s => s.employeeState.employeeUsers).subscribe(users => {
        this.userEmployeeList = users;
      }),
      this.store.select(s => s.benefitsState.benefitsQueue).subscribe(queue => {
        this.checks = queue;
      }),
      this.actions$.pipe(
        ofType(verifyBenefitCheckSuccess),
        tap(action => {
          this.reloadQueue();
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(switchBenefitStatusSuccess),
        tap(action => {
          this.reloadQueue();
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(markAsExemptBenefitSuccess),
        tap(action => {
          this.reloadQueue();
        })
      ).subscribe(),
    );

    this.dateRangeList = this.dropdownFilterService.getLast120DayDateRanges().map(c => ({ label: c.text, value: c }));
    this.hourRangeList = this.dropdownFilterService.getFullTimeHourRanges().map(c => ({ label: c.text, value: c }));
    this.statusOptions = [
      { label: 'All', value: null },
      { label: 'Full', value: 'FULL' },
      { label: 'Part', value: 'PART' },
      { label: 'Exempt', value: 'EXEMPT' }
    ];
    this.store.dispatch(loadViewableCampuses());
  }

  changedDateRange() {
    if (this.dateRange.isCustom) {
      this.isCustomDateRange = true;
      return; //reload after manually changing date
    }

    this.isCustomDateRange = false;
    this.startDate = this.dateRange.startDate;
    this.endDate = this.dateRange.endDate;
    this.reloadQueue();
  }

  changedHourRange() {
    if (this.hourRange.isCustom) {
      this.isCustomHourRange = true;
      return; //reload after manually changing hour
    }

    this.isCustomHourRange = false;
    this.minHours = this.hourRange.minValue;
    this.maxHours = this.hourRange.maxValue;
    this.reloadQueue();
  }

  reloadFilter(event) {    
    this.filter = event;
    this.reloadQueue();
  }

  reloadQueue() {
    this.filter = deepClone(this.filter);
    this.filter.filters.userId = { Name: 'userId', Value: { value: this.userId.toString() } };
    this.filter.filters.programId = { Name: 'programId', Value: { value: this.campusId.toString() } };
    this.filter.filters.startDate = { Name: 'startDate', Value: { value: this.startDate } };
    this.filter.filters.endDate = { Name: 'endDate', Value: { value: this.endDate } };
    this.filter.filters.minHours = { Name: 'minHours', Value: { value: this.minHours } };
    this.filter.filters.maxHours = { Name: 'maxHours', Value: { value: this.maxHours } };
    this.filter.filters.fullTimeStatus = { Name: 'fullTimeStatus', Value: { value: this.status } };

    this.store.dispatch(getBenefitStatusQueue({
      filter: this.filter      
    }));
  }

  getStaffList(event) {
    this.store.dispatch(loadEmployeeUsers({
      prefixText: event.query,
      programId: this.campusId,
      payrollClassificationId: null,
      showTerminated: false,
      showInactive: false
    }));
  }

  reloadListAfterStaffChage(selectedUser) {
    if (typeof selectedUser.value !== 'number') {
      return;
    }

    this.userId = selectedUser.value;
    this.reloadQueue();
  }

  reloadListAfterClear(event) {
    if (event.data !== null) {
      return;
    }

    this.userId = 0;
    this.reloadQueue();
  }

  verifyCheck(check) {
    this.store.dispatch(verifyBenefitCheck({ check: check }));
  }

  switchStatus(check) {
    this.store.dispatch(switchBenefitStatus({ check: check }));
  }

  markAsExempt(check) {
    this.store.dispatch(markAsExemptBenefit({ check: check }));
  }

}
