import { UserModel } from 'src/app/models/userModel';
import { createReducer, on, Action } from '@ngrx/store';
import {
    getUser, getUsersIncludingTerminatedSuccess,
  getUsersList, getUsersListSuccess, getUsersReportingList, getUsersReportingListSuccess, getUserSuccess} from './users.actions';

export interface UsersState {
  usersList: UserModel[];
  usersListIncludingTerminated: UserModel[];
  loadingUsersList: boolean;
  usersReportingList: UserModel[];
  loadingUsersReportingList: boolean;
  user: UserModel
}

const initialState: UsersState = {
  usersList: null,
  usersListIncludingTerminated: null,
  loadingUsersList: false,
  usersReportingList: null,
  loadingUsersReportingList: false,
  user: null
};

export const usersStateReducer = createReducer(initialState,
  on(getUsersList, (state, action) => {
    return {
      ...state,
      loadingUsersList: true
    };
  }),
  on(getUsersListSuccess, (state, action) => {
    return {
      ...state,
      usersList: action.usersList,
      loadingUsersList: false
    };
  }),
  on(getUsersIncludingTerminatedSuccess, (state, action) => {
    return {
      ...state,
      usersListIncludingTerminated: action.usersList,
      loadingUsersList: false
    };
  }),
  on(getUsersReportingList, (state, action) => {
    return {
      ...state,
      loadingUsersReportingList: true
    };
  }),
  on(getUsersReportingListSuccess, (state, action) => {
    return {
      ...state,
      usersReportingList: action.usersReportingList,
      loadingUsersReportingList: false
    };
  }),
  on(getUser, (state, action) => {
    return {
      ...state,
      user: null
    };
  }),
  on(getUserSuccess, (state, action) => {
    return {
      ...state,
      user: action.user
    };
  }),
);

export function usersStateReducerFunc(state: UsersState | undefined, action: Action) {
  return usersStateReducer(state, action);
}
