import { GeneralSettingsModel } from 'src/app/models/generalSettingsModel';
import { saveSelfcareAnwers, saveSelfcarePlannerNote, saveSelfcarePlannerNoteSuccess, saveSelfcareAnwersSuccess, saveSelfcarePlannerNoteFailure, saveSelfcareAnwersFailure, deleteSelfcarePlannerNote, deleteSelfcarePlannerNoteSuccess, deleteSelfcarePlannerNoteFailure } from './../../../../core/store/selfcare/selfcare.actions';
import { SelfCarePlannerSectionItemNoteModel } from 'src/app/models/selfcareModels';
import { SelfCarePlannerSectionModel, SelfCareQuestionModel, SelfCarePlannerSectionItemModel } from './../../../../models/selfcareModels';
import { ActivatedRoute, Router } from '@angular/router';
import {
  loadSurveyInfo, updateSurveyAnswerForm,
  saveSurveyAnswer, saveSurveyAnswerSuccess
} from './../../../../core/store/survey/survey.actions';
import { SurveyModel, SurveyFullAnswerModel, SurveyQuestionModel } from './../../../../models/surveyModel';
import { Component, OnInit, AfterViewInit, HostListener, ViewChild } from '@angular/core';
import { resultMemoize, Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { FormGroup } from '@angular/forms';
import { deepClone, getAllControlsErrors, markFormGroupTouched, scrollToId } from '../../../../helpers/utils';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { initSurveyAnswerForm } from '../../../../core/store/survey/survey.reducer';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { AlertService } from '../../../../shared/services/alert.service';
import { loadSelfcarePlanner } from 'src/app/core/store/selfcare/selfcare.actions';
import { OverlayPanel } from 'primeng/overlaypanel';
@Component({
  selector: 'app-selfcare-planner',
  templateUrl: './selfcare-planner.component.html',
  styleUrls: ['../selfcare.component.scss']
})
export class SelfcarePlannerComponent extends BaseComponent implements OnInit {

  survey: SurveyModel;
  fullAnswers: SurveyFullAnswerModel;
  form: FormGroup;
  loading: boolean;
  surveyQuestionsMap;
  iFrameWidth = 680;
  iFrameHeight = 417;
  noteEditing: SelfCarePlannerSectionItemNoteModel;
  questionEditing: SelfCareQuestionModel;

  sections: SelfCarePlannerSectionModel[];

  savingAnswer: SelfCareQuestionModel;
  savingNote: SelfCarePlannerSectionItemNoteModel;
  opQuestion: any;
  opNote: any;
  eventOP: any;

  userID;
  scrollTo;
  generalSettings: GeneralSettingsModel;

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService) {
    super();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params.userID) {
        this.userID = Number(params.userID);
      } else {
        this.userID = null;
      }

    });
    this.route.queryParams.subscribe(queryParams => {
      if (queryParams.scrollTo) {
        this.scrollTo = queryParams.scrollTo;
      }
    });
    this.checkSize(window.innerWidth);

    this.form = new FormGroup({

    });
    this.subs.push(
      this.store.select(s => s.accountState.generalSettings).subscribe(generalSettings => {
        if (generalSettings) {
          this.generalSettings = generalSettings;
        }
      }),
      this.store.select(s => s.selfcareState.plannerSections).subscribe(plannerSections => {
        if (plannerSections) {
          this.sections = JSON.parse(JSON.stringify(plannerSections));
          this.savingAnswer = null;
          this.savingNote = null;
          if (this.scrollTo) {
            scrollToId('section' + this.scrollTo);
            this.scrollTo = null;
          }
        }
      }),
      this.actions$.pipe(
        ofType(saveSelfcarePlannerNoteSuccess, saveSelfcareAnwersSuccess, deleteSelfcarePlannerNoteSuccess),
        tap(action => {
          this.store.dispatch(loadSelfcarePlanner({ userID: this.userID }));
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(saveSelfcarePlannerNoteFailure, saveSelfcareAnwersFailure, deleteSelfcarePlannerNoteFailure),
        tap(action => {
          this.savingAnswer = null;
          this.savingNote = null;
        })
      ).subscribe(),
    );

    this.store.dispatch(loadSelfcarePlanner({ userID: this.userID }));
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkSize(event.target.innerWidth);

  }

  checkSize(width) {
    if (width < 830) {
      this.iFrameWidth = width - 200;
      this.iFrameHeight = 350;
    } else {
      this.iFrameWidth = 680;
      this.iFrameHeight = 417;
    }
  }

  categories() {
    if (this.userID) {
      this.router.navigate(['/selfcare/oversight/' + this.userID + '/main']);
    } else {
      this.router.navigate(['/selfcare']);
    }
  }

  transformSelfcareTitle(sectionTitle) {
    return sectionTitle ? sectionTitle.toUpperCase().replace('[SELFCARE]', '<span class="planner-panel-header-selfcare">Self Care</span>') : '';
  }

  getQuestionText(question: SelfCareQuestionModel) {
    let result = '';
    if (question.answer !== '-') {
      if (!(question.answer) || !(question.displayText)) {
        result = '<b class="mr-2">' + question.questionText + '</b>';
      }
      if (question.answer) {
        const answerFormatted = question.answer.replace(new RegExp('\n', 'g'), '<br>');
        if (question.displayText) {
          if (question.displayText.toLocaleLowerCase().indexOf('[answer]') >= 0) {
            result += question.displayText.replace('[Answer]', answerFormatted).replace('[answer]', answerFormatted).replace('[ANSWER]', answerFormatted);
          } else {
            result = question.displayText + ' ' + answerFormatted;
          }
        } else {
          result += answerFormatted;
        }
      }
    }
    return result;
  }

  deleteNote(note: SelfCarePlannerSectionItemNoteModel) {
    if (note) {
      this.alertService.confirm(`Delete note`,
        `Are you sure you want to delete this note? This can´t be undone.`)
        .subscribe(
          answer => {
            if (answer) {
              this.savingNote = note;
              this.store.dispatch(deleteSelfcarePlannerNote({ noteID: note.plannerSectionItemNoteID }));
            }
          }, error => {

          }
        );
    }
  }

  openAdditNote(opNote: OverlayPanel, event, sectionItem: SelfCarePlannerSectionItemModel, note: SelfCarePlannerSectionItemNoteModel) {
    if (note) {
      this.noteEditing = deepClone(note);
    } else {
      this.noteEditing = {
        plannerSectionItemID: sectionItem.plannerSectionItemID
      };
    }
    opNote.show(event);
  }

  addNote(opNote: OverlayPanel) {
    if (this.noteEditing && this.noteEditing.note) {
      this.savingNote = this.noteEditing;
      this.store.dispatch(saveSelfcarePlannerNote({ note: deepClone(this.noteEditing) }));
    }
    this.noteEditing = null;
    opNote.hide();
  }

  addQuestion(opQuestion: OverlayPanel) {
    if (this.questionEditing) {
      this.savingAnswer = this.questionEditing;
      if (this.questionEditing.noAnswer) {
        this.questionEditing.answer = '-';
      }
      this.store.dispatch(saveSelfcareAnwers({ answers: [deepClone(this.questionEditing)] }));
    }
    this.questionEditing = null;
    opQuestion.hide();
  }

  openAdditQuestion(opQuestion: OverlayPanel, event, sectionItem: SelfCarePlannerSectionItemModel, question: SelfCareQuestionModel) {
    if (question) {
      this.questionEditing = deepClone(question);
    } else {
      this.questionEditing = {
        plannerSectionItemID: sectionItem.plannerSectionItemID,
        questionText: '',
        displayText: '',
        allowPublic: true,
        questionType: 0,
        questionOptions: '',
      };
    }
    opQuestion.show(event);
  }

  willChangeIsPublic(newValue: boolean, questionOrNote: any, isQuestion: boolean) {

    if (newValue) {
      this.alertService.confirm(`Making answer public`,
        `You've chosen to share this answer content.  If shared, this information will be visible to others within the company.
      Was that your intention?`)
        .subscribe(
          answer => {
            if (answer) {
              setTimeout(() => {
                this.alertService.confirm(`Making answer public`,
                  `Please confirm that you want to share this personal content with others within the company.
              Are you sure you want to do this?`)
                  .subscribe(
                    answer2 => {
                      if (answer2) {
                        questionOrNote.isPublic = true;
                      } else {
                        questionOrNote.isPublic = false;
                      }
                    }, error => {
                      questionOrNote.isPublic = false;
                    }
                  );
              }, 150);
            } else {
              questionOrNote.isPublic = false;
            }
          }, error => {
            questionOrNote.isPublic = false;
          }
        );
    } else {
      questionOrNote.isPublic = false;
    }
  }


  isReadOnly() {
    return !!this.userID;
  }


  changeMultiSelectAnswer(value: string[], question: SelfCareQuestionModel) {
    question.answerArray = value;
    question.answer = question.answerArray.filter(x => x !== 'Other').join(',');
    if (question.answerArray.some(x => x === 'Other')) {
      question.answer += ',' + question.answerSelected;
    }
  }
  checkShowOtherMultiSelect(question: SelfCareQuestionModel) {
    return (question.questionType === 2 && question.answerArray && question.answerArray.some(x => x === 'Other'));
  }

  changedAnswerSelectedMulti(value: string, question: SelfCareQuestionModel) {
    question.answer = question.answerArray.filter(x => x !== 'Other').join(',');
    if (question.answerArray.some(x => x === 'Other')) {
      question.answer += ',' + value;
      question.answerSelected = value;
    }
  }

  changedAnswerSelected(value: string, question: SelfCareQuestionModel) {
    if (value === 'Other') {
      question.answerSelected = value;
      question.answer = '';
    } else {
      question.answerSelected = value;
      question.answer = value;
    }
  }

}

