import { Action, createReducer, on } from "@ngrx/store";
import { PagingResultsModel } from "../../../models/pagingResultsModel";
import { PaylocityTransmissionModel } from "../../../models/paylocityTranmissionModel";
import { dismissTransmission, dismissTransmissionSuccess, getPaylocityTransmissions, getPaylocityTransmissionsSuccess } from "./paylocity.action";

export interface PaylocityState {
  transmissions: PagingResultsModel<PaylocityTransmissionModel>;

  loadingTransmissions: boolean;
  savingDismissal: boolean;
}

const initialState: PaylocityState = {
  transmissions: null,

  loadingTransmissions: true,
  savingDismissal: false
}

export const paylocityReducer = createReducer(initialState,
  on(getPaylocityTransmissions, (state, action) => {
    return {
      ...state,
      loadingTransmissions: true
    };
  }),
  on(getPaylocityTransmissionsSuccess, (state, action) => {
    return {
      ...state,
      loadingTransmissions: false,
      transmissions: action.transmissions
    };
  }),
  on(dismissTransmission, (state, action) => {
    return {
      ...state,
      savingDismissal: true
    };
  }),
  on(dismissTransmissionSuccess, (state, action) => {
    return {
      ...state,
      savingDismissal: false      
    };
  }),
)

export function paylocityReducerFunc(state: PaylocityState | undefined, action: Action) {
  return paylocityReducer(state, action);
}
