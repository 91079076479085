import { CoachingLogWithUsersModel } from './../../../models/coachingLogWithUsersModel';
import { PagingResultsModel } from './../../../models/pagingResultsModel';
import { createAction, props } from '@ngrx/store';
import { FormError } from 'src/app/models/utilModels';
import { UserModel } from 'src/app/models/userModel';
import { CoachingLogCategoryModel } from '../../../models/coachingLogCategoryModel';

export const createCoachingLog = createAction('[Coaching Log]  Create Coaching Log',
  props<{ coachingLogWithUsersModel: CoachingLogWithUsersModel }>());
export const createCoachingLogSuccess = createAction('[Coaching Log]  Create Coaching Log Success',
  props<{ coachingLogWithUsersModel: CoachingLogWithUsersModel }>());
export const createCoachingLogFail = createAction('[Coaching Log]  Create Coaching Log Fail',
  props<{ err: any }>());

export const updateCoachingLog = createAction('[Coaching Log] Update Coaching Log',
  props<{ coachingLogWithUsersModel: CoachingLogWithUsersModel }>());

export const updateCoachingLogSuccess = createAction('[Coaching Log]  Update Coaching Log Success',
  props<{ coachingLogWithUsersModel: CoachingLogWithUsersModel }>());

export const updateCoachingLogFail = createAction('[Coaching Log]  Update Coaching Log Fail',
  props<{ err: any }>());

export const closeUpdateCoachingLog = createAction('[Coaching Log] Close Update Coaching log modal')

export const refreshUpdateCoachingLog = createAction('[Coaching Log] Refresh Update Coaching Log',
  props<{ updateCoachingLog: CoachingLogWithUsersModel }>());


export const getMyGivenCoachingLog = createAction('[Coaching Log] Get given list',
  props<{ filter: any }>());
export const getMyGivenCoachingLogSuccess = createAction('[Coaching Log] Get given list Success',
  props<{ coachingLogList: PagingResultsModel<CoachingLogWithUsersModel> }>());

export const getMyCoahingLog = createAction('[Coaching Log] Get my list',
  props<{ filter: any }>());
export const getMyCoahingLogSuccess = createAction('[Coaching Log] Get my list Success',
  props<{ coachingLogList: PagingResultsModel<CoachingLogWithUsersModel> }>());

export const markCoachingLogAsChecked = createAction('[Coaching Log] Mark as checked',
  props<{ coachingLogID: number }>());
export const markCoachingLogAsCheckedSuccess = createAction('[Coaching Log] Mark as checked Success',
  props<{ coachingLogWithUsersModel: CoachingLogWithUsersModel }>());

export const markCoachingLogAsSeen = createAction('[Coaching Log] Mark as seen',
  props<{ coachingLogID: number }>());
export const markCoachingLogAsSeenSuccess = createAction('[Coaching Log] Mark as seen Success',
  props<{ coachingLogWithUsersModel: CoachingLogWithUsersModel }>());

export const updateCreateCoachingLogForm = createAction('[Coaching Log] Update Coaching Log Form',
  props<{ coachingLog: any; formErrors: FormError[] }>());

export const getCoachableUsersList = createAction('[Coaching Log] Get Coachable users list');
export const getCoachableUsersListSuccess = createAction('[Coaching Log] Get Coachable users list Success',
  props<{ usersList: UserModel[] }>());

export const getCategoriesByLevelList = createAction('[Coaching Log] Get coaching log categories.',
  props<{ levelID: number }>());
export const getCategoriesByLevelListSuccess = createAction('[Coaching Log] Get coaching log categories Success',
  props<{ categoriesList: CoachingLogCategoryModel[] }>());

export const getUserCoachingLogsList = createAction('[Coaching Log] User coaching logs.',
  props<{ userID: number }>());
export const getUserCoachingLogsListSuccess = createAction('[Coaching Log] User coaching logs.',
  props<{ userCoachingLogs: CoachingLogWithUsersModel[] }>());


export const getCoachingLog = createAction('[Coaching Log] Get Coaching Log',
  props<{ coachingLogID: any }>());
export const getCoachingLogSuccess = createAction('[Coaching Log] Get Coaching Log Success',
  props<{ coachingLog: CoachingLogWithUsersModel }>());

