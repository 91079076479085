import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { FormGroup, RequiredValidator, Validators } from '@angular/forms';
import { downloadFile, getAllControlsErrors, markFormGroupTouched } from 'src/app/helpers/utils';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { StaffInjuryModel } from '../../../../models/staffInjuryModel';
import { initUpdateStaffInjuryForm } from '../../../../core/store/injuryqueue/injuryqueue.reducer';
import { clearStaffInjuryForm, downloadStaffInjuryInvoice, setStaffInjuryToUpdate, updateStaffInjury, updateStaffInjuryForm, uploadStaffInjuryInvoice, uploadStaffInjuryInvoiceFailure, uploadStaffInjuryInvoiceSuccess } from '../../../../core/store/injuryqueue/injuryqueue.actions';

@Component({
  selector: 'app-update-staff-injury',
  templateUrl: './update-staff-injury.component.html',
  styleUrls: ['./update-staff-injury.component.scss']
})
export class UpdateStaffInjuryComponent extends BaseComponent implements OnInit {
  form: FormGroup;
  staffInjury: StaffInjuryModel;
  updatingStaffInjury: boolean;
  canEdit: boolean;
  uploadingInvoice: boolean;
  changingInvoiceFile: boolean;
  invoiceFileName: string;
  constructor(private actions$: Actions,
    private store: Store<State>,
    private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.updatingStaffInjury = false;
    this.uploadingInvoice = false;
    this.changingInvoiceFile = false;
    this.invoiceFileName = null;
    // Initialize the form
    this.form = initUpdateStaffInjuryForm({} as StaffInjuryModel);

    this.subs.push(
      // Check if user is an HR manager
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.canEdit = permissionIDs && !!permissionIDs.find(p => p === 4053); //HR staff injury access
      }),

      this.store.select(s => s.injuryQueueState.updatingStaffInjury).subscribe(updatingStaffInjury => {
        this.updatingStaffInjury = updatingStaffInjury;
      }),

      this.store.select(s => s.injuryQueueState.staffInjury).subscribe(staffInjury => {
        if (staffInjury === null) {
          this.staffInjury = staffInjury
          return;
        }

        const changedStaffInjury = !this.staffInjury || (this.staffInjury.id !== staffInjury.id);
        this.staffInjury = staffInjury;
        this.invoiceFileName = staffInjury.fileName;
        if (changedStaffInjury) {
          this.form = initUpdateStaffInjuryForm(staffInjury);
          this.subs.push(
            // Update form values on the store
            this.form.valueChanges.subscribe(formValues => {
              const staffInjuryValues = this.form.value;
              const formErrors = getAllControlsErrors(this.form);
              this.store.dispatch(updateStaffInjuryForm({ staffInjuryValues, formErrors }));
            })
          )
        }

      }),
      this.actions$.pipe(
        ofType(clearStaffInjuryForm),
        tap(action => {
          this.form.reset();
        })
      ).subscribe()
    );

    this.actions$.pipe(
      ofType(uploadStaffInjuryInvoiceSuccess),
      tap(action => {
        this.uploadingInvoice = false;
        this.changingInvoiceFile = false;
      })
    ).subscribe();

  }

  uploadInvoiceFile(fileInput) {
    if (fileInput.files) {
      this.uploadingInvoice = true;
      const file = fileInput.files[0];
      this.invoiceFileName = file.name;

      this.store.dispatch(uploadStaffInjuryInvoice({ staffInjuryId: this.staffInjury.id, file }));
    }
  }

  changeInvoiceFile() {
    this.changingInvoiceFile = true;
  }

  downloadInvoice() {
    this.store.dispatch(downloadStaffInjuryInvoice({ staffInjuryId: this.staffInjury.id }));
    const subDocBuffer = this.store.select(s => s.injuryQueueState.invoiceArrayBuffer).subscribe(invoiceArrayBuffer => {
      if (invoiceArrayBuffer) {
        downloadFile(new Blob([invoiceArrayBuffer], { type: this.staffInjury.fileType }), this.invoiceFileName);
        subDocBuffer.unsubscribe();
      }
    });
  }
  
  save() {
    if (this.form.valid) {
      this.store.dispatch(updateStaffInjury({ staffInjury: this.staffInjury }));
    } else {
      markFormGroupTouched(this.form);
    }
  }
  cancel() {
    this.store.dispatch(setStaffInjuryToUpdate({ staffInjury: null }));
  }

}
