<div class='row navbar-fixed-top d-flex justify-content-between'>
  <div class='col-7  align-bottom d-flex '>
    <a [routerLink]="['/']" class="ml-2 mr-2 pointer top-link" title="Back to home" >
      <i class="material-icons ">apps</i> <p>Home</p>
    </a>
    <a class="navbar-brand" [routerLink]='["/"]'  title="Back to home">
      <img [src]="generalSettings?.businessLogoUrl" height="50" [alt]="generalSettings?.businessFullName">
    </a>
    <div class="align-items-center d-none d-sm-flex">
      <h1 class="mb-0">HR&nbsp;Portal</h1>
    </div>
    <div *ngIf="loggedUser!==null" class=" d-none ">
      <a [routerLink]="['/']" class="btn  btn-link" style="color: inherit">
        <i class="material-icons">search</i>
      </a>
      <input type="text" class="searchBox" placeholder="Type your search" />
    </div>
  </div>
  <div class='col-5 text-right w-50 d-flex justify-content-end align-items-center' >
    <a *ngIf="hasManagementPermission" class="btn btn-secondary managementButton" id="managementButton" [routerLink]="['/management']"  >
      <p>Management <span *ngIf="notificationCount > 0" class="badge badge-danger" title="New Notifications">{{notificationCount}}</span></p>
    </a>
    <a (click)="menu.toggle($event)" class="btn btn-link pointer mainMenuButton" *ngIf="loggedUser!==null">
      <i class="material-icons">menu</i>
    </a>
  </div>
</div>
<p-menu #menu [popup]="true" [model]="items"></p-menu>
