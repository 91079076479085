import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PagingResultsModel } from '../../models/pagingResultsModel';
import { BenefitPlanEDITransmissionModel } from '../../models/benefitPlanEDITransmissionModel';
import { deepClone } from '../../helpers/utils';
import { environment } from '../../../environments/environment';
import { BenefitPlanEDIMemberInformationModel } from '../../models/benefitPlanEDIMemberInformationModel';

@Injectable({
  providedIn: 'root'
})
export class BenefitPlanEDIService {

  constructor(private http: HttpClient) {
  }

  getBenefitPlanEDITransmissions(filter: any): Observable<PagingResultsModel<BenefitPlanEDITransmissionModel>> {
    if (!filter) {
      filter = {};
      filter.filters = {};
    }
    filter = deepClone(filter);

    return this.http.post<PagingResultsModel<BenefitPlanEDITransmissionModel>>(`${environment.apiServer.webApiURL}/BenefitPlanEDI/Transmissions`,
      filter)
  }


  getBenefitPlanEDITransmissionMembers(filter: any): Observable<PagingResultsModel<BenefitPlanEDIMemberInformationModel>> {
    if (!filter) {
      filter = {};
      filter.filters = {};
    }
    filter = deepClone(filter);
    if (filter.filters.transmissionId && filter.filters.transmissionId.Value && filter.filters.transmissionId.Value.value)
      return this.http.post<PagingResultsModel<BenefitPlanEDIMemberInformationModel>>(`${environment.apiServer.webApiURL}/BenefitPlanEDI/Transmissions/${filter.filters.transmissionId.Value.value}`, filter);
    else if (filter.filters.carrier && filter.filters.carrier.Value && filter.filters.carrier.Value.value == 'MotivHealth')
      return this.http.post<PagingResultsModel<BenefitPlanEDIMemberInformationModel>>(`${environment.apiServer.webApiURL}/BenefitPlanEDI/Current/MotivHealth`, filter);
    else if (filter.filters.carrier && filter.filters.carrier.Value && filter.filters.carrier.Value.value == 'Samera Health')
      return this.http.post<PagingResultsModel<BenefitPlanEDIMemberInformationModel>>(`${environment.apiServer.webApiURL}/BenefitPlanEDI/Current/SameraHealth`, filter);
    else
      return this.http.post<PagingResultsModel<BenefitPlanEDIMemberInformationModel>>(`${environment.apiServer.webApiURL}/BenefitPlanEDI/Transmissions`, filter);

  }



  putBenefitPlanEDITransmission(carrier: string): Observable<any> {
    if (carrier == 'MotivHealth')
      return this.http.put(`${environment.apiServer.webApiURL}/BenefitPlanEDI/Transmissions/SendMotivHealth`, {});
    else if (carrier == 'Samera Health')
      return this.http.put(`${environment.apiServer.webApiURL}/BenefitPlanEDI/Transmissions/SendSameraHealth`, {});
    else
      return this.http.put(`${environment.apiServer.webApiURL}/BenefitPlanEDI/Transmissions/Send${carrier}`, {});
  }


  exportBenefitPlanEDITransmissionData(transmissionId: number, carrier: string): Observable<ArrayBuffer> {
    if (carrier == 'MotivHealth')
      return this.http.post<ArrayBuffer>(`${environment.apiServer.webApiURL}/BenefitPlanEDI/Current/MotivHealth/Export/`, {}, { responseType: 'arraybuffer' as 'json' });
    else if (carrier == 'Samera Health')
      return this.http.post<ArrayBuffer>(`${environment.apiServer.webApiURL}/BenefitPlanEDI/Current/SameraHealth/Export/`, {}, { responseType: 'arraybuffer' as 'json' });
    else
      return this.http.post<ArrayBuffer>(`${environment.apiServer.webApiURL}/BenefitPlanEDI/Transmissions/${transmissionId}/Export/`, transmissionId, { responseType: 'arraybuffer' as 'json' });
  }
  
}
