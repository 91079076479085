<div  *ngIf="!profile">
</div>

<form [formGroup]="form" *ngIf="profile && form">
  <h5 class="mb-3  mt-4">Main Information</h5>
  <div class="row">
    <div class="col-12 col-sm-6">
      <div class="row"   >
        <div class="col-4 d-flex align-items-center">
          <b>Legal First Name</b>
        </div>
        <div class="col-8">
            <input type="text" class="form-control mt-2 " [value]="profile.user.firstName" readonly />
        </div>
      </div>
      <div class="row"   >
        <div class="col-4 d-flex align-items-center">
          <b>Legal Last Name</b>
        </div>
        <div class="col-8">
          <input type="text" class="form-control mt-2" [value]="profile.user.lastName" readonly/>
        </div>
      </div>

      <div class="row" formGroupName="user"  >
        <div class="col-4 d-flex align-items-center">
          <b>Legal Middle Name</b>
        </div>
        <div class="col-8">
            <input type="text" class="form-control mt-2" formControlName="middleName" [attr.readonly]="this.readOnly?true:null"/>
          <app-validation-message [form]="form" [path]="'user.middleName'" [name]="'middleName'" [label]="'Middle Name'"> </app-validation-message>
        </div>
      </div>

      <div class="row" formGroupName="employee"  >
        <div class="col-4 d-flex align-items-center">
          <b>Suffix</b>
        </div>
        <div class="col-8">
            <input type="text" class="form-control mt-2" formControlName="suffix"  [attr.readonly]="this.readOnly?true:null"/>
          <app-validation-message [form]="form" [path]="'employee.suffix'" [name]="'suffix'" [label]="'Suffix'"> </app-validation-message>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-6">
      <div class="row" formGroupName="user"   >
        <div class="col-4 d-flex align-items-center">
          <b>Maiden Name</b>
        </div>
        <div class="col-8">
            <input type="text" class="form-control mt-2" formControlName="previousName"  [attr.readonly]="this.readOnly?true:null"/>
          <app-validation-message [form]="form" [path]="'user.previousName'" [name]="'previousName'" [label]="'Previous Name'"> </app-validation-message>
        </div>
      </div>

      <div class="row" formGroupName="user"  >
        <div class="col-4 d-flex align-items-center">
          <b>Prefered Name</b>
        </div>
        <div class="col-8">
            <input type="text" class="form-control mt-2" formControlName="preferedName"  [attr.readonly]="this.readOnly?true:null"/>
          <app-validation-message [form]="form" [path]="'user.preferedName'" [name]="'preferedName'" [label]="'Prefered Name'"> </app-validation-message>
        </div>
      </div>

      <div class="row" formGroupName="employee" >
        <div class="col-4 d-flex align-items-center">
          <b>State of Birth</b>
        </div>
        <div class="col-8">
          <input
            type="text"
            class="form-control mt-2"
            formControlName="stateOfBirth"  [attr.readonly]="this.readOnly?true:null"
          />
          <app-validation-message [form]="form" [path]="'employee.stateOfBirth'" [name]="'stateOfBirth'" [label]="'State of Birth'"> </app-validation-message>

        </div>
      </div>

    </div>
  </div>
  <h5 class="mb-3  mt-4">Contact Information</h5>
  <div class="row ">
    <div class="col-12 col-sm-6">
      <div class="row" formGroupName="user"  *ngIf="hasOnBoardingPermission">
        <div class="col-4 d-flex align-items-center">
          <b>Work Email</b>
        </div>
        <div class="col-8">
            <input type="text" class="form-control mt-2" formControlName="email"  [attr.readonly]="this.readOnly?true:null"/>
          <app-validation-message [form]="form" [path]="'user.email'" [name]="'email'" [label]="'Email'"> </app-validation-message>
        </div>
      </div>
      <div class="row" formGroupName="employee">
        <div class="col-4 d-flex align-items-center">
          <b>Personal Email</b>
        </div>
        <div class="col-8">
            <input type="text" class="form-control mt-2" formControlName="personalEmail"  [attr.readonly]="this.readOnly?true:null"/>
          <app-validation-message [form]="form" [path]="'employee.personalEmail'" [name]="'personalEmail'" [label]="'Personal Email'"> </app-validation-message>
        </div>
      </div>
      <div class="row" formGroupName="user">
          <div class="col-4 d-flex align-items-center">
            <b>Phone Number</b>
          </div>
          <div class="col-8">
              <input type="text" class="form-control mt-2" formControlName="phoneNumber"  [attr.readonly]="this.readOnly?true:null"/>
            <app-validation-message [form]="form" [path]="'user.phoneNumber'" [name]="'phoneNumber'" [label]="'Phone Number'"> </app-validation-message>
          </div>
        </div>
    </div>
    <div class="col-12 col-sm-6" >
      <div class="row" formGroupName="employee">
        <div class="col-4 d-flex align-items-center">
          <b>T-Shirt Size</b>
        </div>
        <div class="col-8">
          <select class="form-control" formControlName="tShirtSize" [attr.readonly]="this.readOnly?true:null">
            <option>Men XS</option>
            <option>Women XS</option>
            <option>Men S</option>
            <option>Women S</option>
            <option>Men M</option>
            <option>Women M</option>
            <option>Men L</option>
            <option>Women L</option>
            <option>Men XL</option>
            <option>Women XL</option>
            <option>Men 2XL</option>
            <option>Women 2XL</option>
            <option>Men 3XL</option>
            <option>Women 3XL</option>
            <option>Men 4XL</option>
            <option>Women 4XL</option>
            <option>Men 5XL</option>
            <option>Women 5XL</option>

          </select>
          <app-validation-message [form]="form" [path]="'employee.tShirtSize'" [name]="'tShirtSize'" [label]="'T-Shirt Size'"> </app-validation-message>
        </div>
      </div>

      <div class="row"  formGroupName="employee">
        <div class="col-4 d-flex align-items-center">
          <b>Marital Status</b>
        </div>
        <div class="col-8">
          <p-dropdown styleClass="form-control mt-2 w-100" [options]="maritalStatusOptions"
          formControlName="maritalStatusID" placeholder="Select option..."  [disabled]="this.readOnly?true:null"></p-dropdown>

          <app-validation-message [form]="form" [path]="'employee.maritalStatusID'" [name]="'maritalStatusID'" [label]="'Marital Status'"> </app-validation-message>

        </div>
      </div>
      <div class="row"  formGroupName="employee">
        <div class="col-4 d-flex align-items-center">
          <b>Tobacco User</b>
        </div>
        <div class="col-8 mt-2 mb-2">
          <p-selectButton styleClass="w-100" [options]="yesNoOptions"  formControlName="tobaccoUser"></p-selectButton>
          <app-validation-message [form]="form" [path]="'employee.tobaccoUser'" [name]="'tobaccoUser'" [label]="'Tobacco User'"> </app-validation-message>
        </div>
      </div>
    </div>
  </div>
  <div formGroupName="employee">
    <div class="row mt-4">
      <div class="col-12 col-sm-6">
        <h5 class="mb-3">Home Address</h5>
        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Address</b>
          </div>
          <div class="col-8">
            <input
              type="text"
              class="form-control mt-2"
              formControlName="address"  [attr.readonly]="this.readOnly?true:null"
            />
            <app-validation-message [form]="form" [path]="'employee.address'" [name]="'address'" [label]="'Address'"> </app-validation-message>
          </div>
        </div>
        <div class="row">
          <div class="col-4 d-flex align-items-center">

          </div>
          <div class="col-8">
            <input
              type="text"
              class="form-control mt-2"
              formControlName="addressExtra"  [attr.readonly]="this.readOnly?true:null"
            />
            <app-validation-message [form]="form" [path]="'employee.addressExtra'" [name]="'addressExtra'" [label]="'Address Extra'"> </app-validation-message>
          </div>
        </div>
        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>City</b>
          </div>
          <div class="col-8">
            <input
              type="text"
              class="form-control mt-2"
              formControlName="city"  [attr.readonly]="this.readOnly?true:null"
            />
            <app-validation-message [form]="form" [path]="'employee.city'" [name]="'city'" [label]="'City'"> </app-validation-message>
          </div>
        </div>
        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>State</b>
          </div>
          <div class="col-8">
            <input
              type="text"
              class="form-control mt-2"
              formControlName="state"  [attr.readonly]="this.readOnly?true:null"
            />
            <app-validation-message [form]="form" [path]="'employee.state'" [name]="'state'" [label]="'State'"> </app-validation-message>

          </div>
        </div>
        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Zip Code</b>
          </div>
          <div class="col-8">
            <input
              type="text"
              class="form-control mt-2"
              formControlName="zipCode"  [attr.readonly]="this.readOnly?true:null"
            />
            <app-validation-message [form]="form" [path]="'employee.zipCode'" [name]="'zipCode'" [label]="'Zip Code'"> </app-validation-message>

          </div>
        </div>

        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>County</b>
          </div>
          <div class="col-8">
            <input
              type="text"
              class="form-control mt-2"
              formControlName="county"  [attr.readonly]="this.readOnly?true:null"
            />
            <app-validation-message [form]="form" [path]="'employee.county'" [name]="'county'" [label]="'County'"> </app-validation-message>

          </div>
        </div>
        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Country</b>
          </div>
          <div class="col-8">
            <input
              type="text"
              class="form-control mt-2"
              formControlName="country"  [attr.readonly]="this.readOnly?true:null"
            />
            <app-validation-message [form]="form" [path]="'employee.country'" [name]="'country'" [label]="'Country'"> </app-validation-message>

          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6">
          <div class="row align-items-center">
        <h5 class="mr-3">Mailing Address</h5> <button class="btn btn-secondary" (click)="copyFromHomeAddress()"  [attr.readonly]="this.readOnly?true:null">Copy from Home Address</button>
          </div>
        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Address</b>
          </div>
          <div class="col-8">
            <input
              type="text"
              class="form-control mt-2"
              formControlName="mailingAddress"  [attr.readonly]="this.readOnly?true:null"
            />
            <app-validation-message [form]="form" [path]="'employee.mailingAddress'" [name]="'mailingAddress'" [label]="'Address'"> </app-validation-message>

          </div>
        </div>
        <div class="row">
          <div class="col-4 d-flex align-items-center">

          </div>
          <div class="col-8">
            <input
              type="text"
              class="form-control mt-2"
              formControlName="mailingAddressExtra" [attr.readonly]="this.readOnly?true:null"
            />
            <app-validation-message [form]="form" [path]="'employee.mailingAddressExtra'" [name]="'mailingAddressExtra'" [label]="'Address Extra'"> </app-validation-message>

          </div>
        </div>

        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>City</b>
          </div>
          <div class="col-8">
            <input
              type="text"
              class="form-control mt-2"
              formControlName="mailingCity" [attr.readonly]="this.readOnly?true:null"
            />
            <app-validation-message [form]="form" [path]="'employee.mailingCity'" [name]="'mailingCity'" [label]="'City'"> </app-validation-message>

          </div>
        </div>
        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>State</b>
          </div>
          <div class="col-8">
            <input
              type="text"
              class="form-control mt-2"
              formControlName="mailingState" [attr.readonly]="this.readOnly?true:null"
            />
            <app-validation-message [form]="form" [path]="'employee.mailingState'" [name]="'mailingState'" [label]="'State'"> </app-validation-message>

          </div>
        </div>
        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Zip Code</b>
          </div>
          <div class="col-8">
            <input
              type="text"
              class="form-control mt-2"
              formControlName="mailingZipCode" [attr.readonly]="this.readOnly?true:null"
            />
            <app-validation-message [form]="form" [path]="'employee.mailingZipCode'" [name]="'mailingZipCode'" [label]="'Zip Code'"> </app-validation-message>

          </div>
        </div>

        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>County</b>
          </div>
          <div class="col-8">
            <input
              type="text"
              class="form-control mt-2"
              formControlName="mailingCounty"  [attr.readonly]="this.readOnly?true:null"
            />
            <app-validation-message [form]="form" [path]="'employee.mailingCounty'" [name]="'mailingCounty'" [label]="'Mailing County'"> </app-validation-message>

          </div>
        </div>
        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Country</b>
          </div>
          <div class="col-8">
            <input
              type="text"
              class="form-control mt-2"
              formControlName="mailingCountry"  [attr.readonly]="this.readOnly?true:null"
            />
            <app-validation-message [form]="form" [path]="'employee.mailingCountry'" [name]="'mailingCountry'" [label]="'Mailing Country'"> </app-validation-message>

          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-12 col-sm-6">
        <h5 class="mb-3">Primary Emergency Contact</h5>
        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Name</b>
          </div>
          <div class="col-8">
            <input
              type="text"
              class="form-control mt-2"
              formControlName="emergencyContactName" [attr.readonly]="this.readOnly?true:null"
            />
            <app-validation-message [form]="form" [path]="'employee.emergencyContactName'" [name]="'emergencyContactName'" [label]="'Name'"> </app-validation-message>

          </div>
        </div>
        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Phone</b>
          </div>
          <div class="col-8">
            <input
              type="text"
              class="form-control mt-2"
              formControlName="emergencyContactPhone" [attr.readonly]="this.readOnly?true:null"
            />
            <app-validation-message [form]="form" [path]="'employee.emergencyContactPhone'" [name]="'emergencyContactPhone'" [label]="'Phone'"> </app-validation-message>

          </div>
        </div>
        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Relationship</b>
          </div>
          <div class="col-8">
            <input
              type="text"
              class="form-control mt-2"
              formControlName="emergencyContactRelation" [attr.readonly]="this.readOnly?true:null"
            />
            <app-validation-message [form]="form" [path]="'employee.emergencyContactRelation'" [name]="'emergencyContactRelation'" [label]="'Relationship'"> </app-validation-message>

          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6">
        <h5 class="mb-3">Secondary Emergency Contact</h5>

        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Name</b>
          </div>
          <div class="col-8">
            <input
              type="text"
              class="form-control mt-2"
              formControlName="emergencyContact2Name" [attr.readonly]="this.readOnly?true:null"
            />
            <app-validation-message [form]="form" [path]="'employee.emergencyContact2Name'" [name]="'emergencyContact2Name'" [label]="'Name'"> </app-validation-message>

          </div>
        </div>
        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Phone</b>
          </div>
          <div class="col-8">
            <input
              type="text"
              class="form-control mt-2"
              formControlName="emergencyContact2Phone" [attr.readonly]="this.readOnly?true:null"
            />
            <app-validation-message [form]="form" [path]="'employee.emergencyContact2Phone'" [name]="'emergencyContact2Phone'" [label]="'Phone'"> </app-validation-message>

          </div>
        </div>
        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Relationship</b>
          </div>
          <div class="col-8">
            <input
              type="text"
              class="form-control mt-2"
              formControlName="emergencyContact2Relation" [attr.readonly]="this.readOnly?true:null"
            />
            <app-validation-message [form]="form" [path]="'employee.emergencyContact2Relation'" [name]="'emergencyContact2Relation'" [label]="'Relationship'"> </app-validation-message>

          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4" >
      <div class="col-12 col-sm-6">
        <div class="d-flex">
        <h5 class="mb-3" style="min-width: fit-content;">Bank Information</h5>
        <span class="ml-3 text-danger font-weight-bold" *ngIf="paylocityEnabled">Note: Bank Information also needs to be updated in Paylocity if you are making a change to your direct deposit information</span>
      </div>
        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Bank Name</b>
          </div>
          <div class="col-8">
            <input
              type="text"
              class="form-control mt-2"
              formControlName="bankName" [attr.readonly]="this.readOnly?true:null"
            name="bankName"
            autocomplete="off"
            />
            <app-validation-message [form]="form" [path]="'employee.bankName'" [name]="'bankName'" [label]="'Bank Name'"> </app-validation-message>

          </div>
        </div>
        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Account Number</b>
          </div>
          <div class="col-8">
            <input
              type="password"
              class="form-control mt-2"
              formControlName="bankAccountNumber" [attr.readonly]="this.readOnly?true:null"
              name="bankAccountNumber"
            autocomplete="off"
            />
            <app-validation-message [form]="form" [path]="'employee.bankAccountNumber'" [name]="'bankAccountNumber'" [label]="'Account Number'"> </app-validation-message>
          </div>
        </div>
        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Confirm Account Number</b>
          </div>
          <div class="col-8">
            <input
              type="password"
              class="form-control mt-2"
              formControlName="bankAccountNumberConfirm" [attr.readonly]="this.readOnly?true:null"
              name="bankAccountNumberConfirm"
              autocomplete="off"
            />
            <app-validation-message [form]="form" [path]="'employee.bankAccountNumberConfirm'" [name]="'bankAccountNumberConfirm'" [label]="'Confirm Account Number'"> </app-validation-message>
          </div>
        </div>
        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Routing Number</b>
          </div>
          <div class="col-8">
            <input
              type="password"
              class="form-control mt-2"
              formControlName="bankRoutingNumber" [attr.readonly]="this.readOnly?true:null"
              autocomplete="off"
              name="bankRoutingNumber"
            />
            <app-validation-message [form]="form" [path]="'employee.bankRoutingNumber'" [name]="'bankRoutingNumber'" [label]="'Routing Number'"> </app-validation-message>

          </div>
        </div>

        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Confirm Routing Number</b>
          </div>
          <div class="col-8">
            <input

              type="password"
              class="form-control mt-2"
              formControlName="bankRoutingNumberConfirm" [attr.readonly]="this.readOnly?true:null"
              autocomplete="off"
              name="bankRoutingNumberConfirm"
            />
            <app-validation-message [form]="form" [path]="'employee.bankRoutingNumberConfirm'" [name]="'bankRoutingNumberConfirm'" [label]="'Confirm Routing Number'"> </app-validation-message>
          </div>
        </div>
        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Account Type</b>
          </div>
          <div class="col-8">

          <select class="form-control" formControlName="bankAccountType"  [attr.readonly]="this.readOnly?true:null">
            <option>Checking</option>
            <option>Savings</option>
          </select>

            <app-validation-message [form]=form [path]="'employee.bankAccountType'" [name]="'bankAccountType'" [label]="'Account Type'"> </app-validation-message>

          </div>
        </div>
    </div>
      <div class="col-sm col-sm-6">
        <div class="row align-items-center">
          <h5 class="mr-3">Other Languages</h5> <button class="btn btn-primary" (click)="addOtherLanguage()"  [attr.readonly]="this.readOnly?true:null">Add</button>
          <ng-container *ngIf="profile.otherLanguages && profile.otherLanguages.length">
            <table style="width: 100%;">
              <tr *ngFor="let lang of profile.otherLanguages; index as i;">
                <td>
                  <i class="fas fa-trash mr-2 pointer" title="Remove"  (click)="removeLanguage(i)"></i>
                </td>
                <td>
                  <p-dropdown styleClass="form-control mt-2 w-100" [options]="spokenLanguageOptions" [(ngModel)]="lang.spokenLanguageId"
                    [ngModelOptions]="{standalone: true}" placeholder="Select option..."  [disabled]="this.readOnly?true:null"></p-dropdown>
                </td>
                <td>
                  <textarea rows="1" multiple="multiple" class="form-control mt-2" [disabled]="this.readOnly?true:null" [(ngModel)]="lang.moreInfo"
                    [ngModelOptions]="{standalone: true}" placeholder="Briefly describe fluency..."></textarea>
                </td>                
              </tr>
            </table>            
          </ng-container>
        </div>
      </div>
    </div>

    <h5 class="mb-3  mt-4">Voluntary self identification</h5>
    <div class="row " >
      <div class="col-12 col-sm-6">

        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Disability Status</b>
          </div>
          <div class="col-8">
            <p-dropdown styleClass="form-control mt-2 w-100" [options]="disabilityOptions"
            formControlName="hasDisability" placeholder="Select option..."  [disabled]="this.readOnly?true:null"></p-dropdown>

            <app-validation-message [form]="form" [path]="'employee.hasDisability'" [name]="'hasDisability'" [label]="'Disability Status'"> </app-validation-message>

          </div>
        </div>
        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Veteran Status</b>
          </div>
          <div class="col-8">
            <p-dropdown styleClass="form-control mt-2 w-100" [options]="veteranOptions"
            formControlName="veteranStatus" placeholder="Select option..."  [disabled]="this.readOnly?true:null"></p-dropdown>

            <app-validation-message [form]="form" [path]="'employee.veteranStatus'" [name]="'veteranStatus'" [label]="'Veteran Status'"> </app-validation-message>
        </div>
        </div>
        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Race/Ethnicity</b>
          </div>
          <div class="col-8">
            <p-dropdown styleClass="form-control mt-2 w-100" [options]="raceOptions"
            formControlName="raceEthnicityID" placeholder="Select option..."  [disabled]="this.readOnly?true:null"></p-dropdown>

            <app-validation-message [form]="form" [path]="'employee.raceEthnicityID'" [name]="'raceEthnicityID'" [label]="'Race/Ethnicity'"> </app-validation-message>

          </div>
        </div>
      </div>
      </div>
      <div class="col-12 col-sm-6">
        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Gender at Birth</b>
          </div>
          <div class="col-8">
            <p-dropdown styleClass="form-control mt-2 w-100" [options]="genderOptions"
                        formControlName="genderAtBirth" placeholder="Select option..." [disabled]="this.readOnly?true:null"></p-dropdown>

            <app-validation-message [form]="form" [path]="'employee.genderAtBirth'" [name]="'genderAtBirth'" [label]="'Gender at Birth'"> </app-validation-message>

          </div>
        </div>

        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>I identify differently from my gender at birth.</b>
          </div>
          <div class="col-8 mt-2 mb-2">
            <p-checkbox formControlName="differentGenderIdentity" [binary]="true"></p-checkbox>
            <app-validation-message [form]="form" [path]="'employee.differentGenderIdentity'" [name]="'differentGenderIdentity'" [label]="'Different Gender Identity'"> </app-validation-message>
          </div>
        </div>

        <div class="row" *ngIf="differentGenderIdentity">
          <div class="col-4 d-flex align-items-center">
            <b>Preferred Gender Identity</b>
          </div>
          <div class="col-8">
            <input type="text"
                   class="form-control mt-2"
                   formControlName="preferredGenderIdentity" />
            <app-validation-message [form]="form" [path]="'employee.preferredGenderIdentity'" [name]="'preferredGenderIdentity'" [label]="'Preferred Gender Identity'"> </app-validation-message>

          </div>
        </div>
        <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Preferred Pronouns</b>
            </div>
            <div class="col-8">
              <select class="form-control mt-2" formControlName="customPronouns">
                <option value="She/Her/Hers">She/Her/Hers</option>
                <option value="He/Him/His">He/Him/His</option>
                <option value="They/Them/Theirs">They/Them/Theirs</option>
                <option value="No Preference">No Preference</option>
              </select>
              <app-validation-message [form]="form" [path]="'employee.customPronouns'" [name]="'customPronouns'" [label]="'Custom Pronouns'"> </app-validation-message>
            </div>
        </div>
      </div>

    <div class="row mt-4" *ngIf="hasOnBoardingPermission">
      <div class="col-12 col-sm-6">
        <h5 class="mb-3">Other employee info</h5>
        <div class="row" >
          <div class="col-4 d-flex align-items-center">
            <b>Department</b>
          </div>
          <div class="col-8">
            <input
              type="text"
              class="form-control mt-2"
              formControlName="department"
            />
            <app-validation-message [form]="form" [path]="'employee.department'" [name]="'department'" [label]="'Department'"> </app-validation-message>

          </div>
        </div>
        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Statutory Class</b>
          </div>
          <div class="col-8">
            <p-dropdown styleClass="form-control mt-2 w-100" [options]="statutoryClassOptions"
            formControlName="statutoryClassID" placeholder="Select option..."  [disabled]="this.readOnly?true:null"></p-dropdown>

            <app-validation-message [form]="form" [path]="'employee.statutoryClassID'" [name]="'statutoryClassID'" [label]="'Statutory Class'"> </app-validation-message>

          </div>
        </div>
        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Eeo Job Category</b>
          </div>
          <div class="col-8">
            <p-dropdown styleClass="form-control mt-2 w-100" [options]="eeoJobCategoryOptions"
            formControlName="eeoJobCategoryID" placeholder="Select option..."  [disabled]="this.readOnly?true:null"></p-dropdown>

            <app-validation-message [form]="form" [path]="'employee.eeoJobCategoryID'" [name]="'eeoJobCategoryID'" [label]="'Eeo Job Category'"> </app-validation-message>

          </div>
        </div>

        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Aca Classification</b>
          </div>
          <div class="col-8">
            <p-dropdown styleClass="form-control mt-2 w-100" [options]="acaClassificationOptions"
            formControlName="acaClassificationID" placeholder="Select option..."  [disabled]="this.readOnly?true:null"></p-dropdown>

            <app-validation-message [form]="form" [path]="'employee.acaClassificationID'" [name]="'acaClassificationID'" [label]="'Aca Classification'"> </app-validation-message>

          </div>
        </div>

        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>Orientation Date</b>
          </div>
          <div class="col-8">
            <p-calendar
            inputStyleClass="form-control mt-2"
            [monthNavigator]="true"
            [yearNavigator]="true"
            styleClass="w-75"
            panelStyleClass="calendarPanel"
            [hourFormat]="12"
            [yearRange]="'2010:2100'"
            [showTime]="false"
            formControlName="orientationDate" placeholder="Select date..."
            dataType="string"
            [disabled]="manageOnboardingUserInfo && manageOnboardingUserInfo.onboarding?.showedUpOnOrientation"
          ></p-calendar>
            <app-validation-message [form]="form" [path]="'employee.orientationDate'" [name]="'orientationDate'" [label]="'Orientation Date'"> </app-validation-message>

          </div>
        </div>

        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>i9 Completion Date</b>
          </div>
          <div class="col-8">
            <p-calendar
            inputStyleClass="form-control mt-2"
            [monthNavigator]="true"
            [yearNavigator]="true"
            styleClass="w-75"
            panelStyleClass="calendarPanel"
            [hourFormat]="12"
            [yearRange]="'2010:2100'"
            [showTime]="false"
            formControlName="i9CompletionDate" placeholder="Select date..."
            dataType="string"
          ></p-calendar>
            <app-validation-message [form]="form" [path]="'employee.i9CompletionDate'" [name]="'i9CompletionDate'" [label]="'i9 Completion Date'"> </app-validation-message>

          </div>
        </div>

        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <b>e-Verify Completion Date</b>
          </div>
          <div class="col-8">
            <p-calendar
            inputStyleClass="form-control mt-2"
            [monthNavigator]="true"
            [yearNavigator]="true"
            styleClass="w-75"
            panelStyleClass="calendarPanel"
            [hourFormat]="12"
            [yearRange]="'2010:2100'"
            [showTime]="false"
            formControlName="eVerifyCompletionDate" placeholder="Select date..."
            dataType="string"
          ></p-calendar>
            <app-validation-message [form]="form" [path]="'employee.eVerifyCompletionDate'" [name]="'eVerifyCompletionDate'" [label]="'e-Verify Completion Date'"> </app-validation-message>

          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6" *ngIf="profile.employee.employedDurations !== null && profile.employee.employedDurations.length > 0">
        <h5 class="mb-3">Prior Employment History</h5>
        <div class="row">
          <p-table [value]="profile.employee.employedDurations" [responsive]="true">
            <ng-template pTemplate="header">
              <tr>
                <th>Training Start Date</th>
                <th>Separation Date</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-d>
              <tr>
                <td>
                  <span class="ui-column-title">Training Start Date</span>
                  {{d.trainingStartDate !== null ? (d.trainingStartDate | date: 'shortDate') : ""}}
                </td>
                <td>
                  <span class="ui-column-title">Separation Date</span>
                  {{d.terminationDate | date: 'shortDate'}}
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>



    <div class="row mt-4 mb-2">
      <div class="col-12 col-sm-6">
        <i *ngIf="savingProfile" class="material-icons rotate text-muted">loop</i>
        <button *ngIf="!savingProfile && !readOnly" class="btn btn-primary" [class.disabled]="form.invalid" (click)="save()">Update</button>
      </div>
    </div>
  </div>
</form>
