import { createAction, props } from '@ngrx/store';
import { HolidayModel, RecurringHolidayModel } from '../../../models/holidayModel';
import { PagingResultsModel } from '../../../models/pagingResultsModel';
import { FormError } from '../../../models/utilModels';
import { SelectItem } from 'primeng/api';

export const loadHolidayYears = createAction('[Holiday] Load Holiday Years');
export const loadHolidayYearsSuccess = createAction('[Holiday] Load Holiday Years Success', props<{ years: SelectItem[] }>());

export const changeHolidayUserFilter = createAction('[Holiday] Change User Filters');
export const changeHolidayUserFilterSuccess = createAction('[Holiday] Change User Filters Success', props<{ userList: SelectItem[] }>());

export const getHolidayList = createAction('[Holiday] Get Holiday List',
  props<{filter: any, holidayName: string, year: number, month: number, userID: number, startDate: string, endDate: string }>());
export const getHolidayListSuccess = createAction('[Holiday] Get Holiday List Success',
  props<{ holidayList: PagingResultsModel<HolidayModel> }>());

export const showEditHoliday = createAction('[Holiday] Show Edit Holiday Modal',
  props<{ holiday: HolidayModel }>());
export const closeEditHoliday = createAction('[Holiday] Close Edit Holiday Modal');

export const saveHoliday = createAction('[Holiday] Save Holiday',
  props<{ holiday: HolidayModel }>());
export const saveHolidaySuccess = createAction('[Holiday] Save Holiday Success');

export const updateHolidayForm = createAction('[Holiday] Update Holiday Form',
  props<{ formValues: any, formErrors: FormError[] }>());

export const deleteHoliday = createAction('[Holiday] Delete', props<{ holidayId: number }>());
export const deleteHolidaySuccess = createAction('[Holiday] Delete Success');


export const getRecurringHolidayList = createAction('[Holiday] Get Recurring Holiday List',
  props<{ filter: any, holidayName: string, month: number, startDate: string, endDate: string, showArchived: boolean }>());
export const getRecurringHolidayListSuccess = createAction('[Holiday] Get Recurring Holiday List Success',
  props<{ holidayList: PagingResultsModel<RecurringHolidayModel> }>());

export const updateRecurringHolidayForm = createAction('[Holiday] Update Recurring Holiday Form',
  props<{ formValues: any, formErrors: FormError[] }>());

export const saveRecurringHoliday = createAction('[Holiday] Save Recurring Holiday',
  props<{ holiday: RecurringHolidayModel }>());
export const saveRecurringHolidaySuccess = createAction('[Holiday] Save Recurring Holiday Success');

export const showEditRecurringHoliday = createAction('[Holiday] Show Edit Recurring Holiday Modal',
  props<{ holiday: RecurringHolidayModel }>());
export const closeEditRecurringHoliday = createAction('[Holiday] Close Edit Recurring Holiday Modal');

export const loadRecurringTypes = createAction('[Holiday] Load Recurring Types');
export const loadRecurringTypesSuccess = createAction('[Holiday] Load Recurring Types Success', props<{ types: SelectItem[] }>());

export const loadRecurringWeeks = createAction('[Holiday] Load Recurring Weeks');
export const loadRecurringWeeksSuccess = createAction('[Holiday] Load Recurring Weeks Success', props<{ weeks: SelectItem[] }>());
