import { BenefitPlanDocumentModel } from './../../../models/benefitsModels';
import { deepClone } from 'src/app/helpers/utils';
import { AlertService } from './../../../shared/services/alert.service';
import { ProfileService } from '../../services/profile.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, tap, catchError } from 'rxjs/operators';
import { concat, of } from 'rxjs';
import { SurveyService } from '../../services/survey.service';
import { errorHappened } from '../profile/profile.actions';
import { Store } from '@ngrx/store';
import { State } from '..';
import {
  cancelChangingEnrollment,
  cancelChangingEnrollmentSuccess,
  deleteBenefitClass,
  deleteBenefitClassFail,
  deleteBenefitClassSuccess,
  deleteBenefitsDependent,
  deleteBenefitsDependentFail,
  deleteBenefitsDependentSuccess,
  deleteBenefitsPlans,
  deleteBenefitsPlansFail,
  deleteBenefitsPlansSuccess,
  downloadEnrollmentSetSignedForms,
  downloadEnrollmentSetSignedFormsFailure,
  downloadEnrollmentSetSignedFormsSuccess,
  downloadEnrollmentSignedForms,
  downloadEnrollmentSignedFormsFailure,
  downloadEnrollmentSignedFormsSuccess,
  exportAllDentalSelectEnrollmentListToExcel,
  exportAllDentalSelectEnrollmentListToExcelFailure,
  exportAllDentalSelectEnrollmentListToExcelSuccess,
  exportBenefitHsaReportToExcel,
  exportBenefitHsaReportToExcelFailure,
  exportBenefitHsaReportToExcelSuccess,
  exportBenefitWithdrawnToExcel,
  exportBenefitWithdrawnToExcelFailure,
  exportBenefitWithdrawnToExcelSuccess,
  exportEmployeeBenefitStatusListToExcel,
  exportEmployeeBenefitStatusListToExcelFailure,
  exportEmployeeBenefitStatusListToExcelSuccess,
  exportEnrollmentListToExcel,
  exportEnrollmentListToExcelFailure,
  exportEnrollmentListToExcelSuccess,
  exportManageEmployeesListToExcel,
  exportManageEmployeesListToExcelFailure,
  exportManageEmployeesListToExcelSuccess,
  getBenefitDeclinedList,
  getBenefitDeclinedListSuccess,
  getBenefitHsaReport,
  getBenefitHsaReportSuccess,
  getBenefitLogList,
  getBenefitLogListSuccess,
  getBenefitsFormDocumentsList,
  getBenefitsFormDocumentsListSuccess,
  getBenefitStatusList,
  getBenefitStatusListSuccess,
  getBenefitStatusQueue,
  getBenefitStatusQueueSuccess,
  getBenefitWithdrawnList,
  getBenefitWithdrawnListSuccess,
  loadAllBenefitPlans,
  loadAllBenefitPlansSuccess,
  loadAvailableBenefitPlans,
  loadAvailableBenefitPlansFail,
  loadAvailableBenefitPlansSuccess,
  loadBenefitClasses,
  loadBenefitClassesFail,
  loadBenefitClassesSuccess,
  loadBenefitLogTypes,
  loadBenefitLogTypesSuccess,
  loadBenefitPlanEnrollmentSetList,
  loadBenefitPlanEnrollmentSetListFail,
  loadBenefitPlanEnrollmentSetListSuccess,
  loadBenefitPlanUserWithEnrollmentSet,
  loadBenefitPlanUserWithEnrollmentSetFail,
  loadBenefitPlanUserWithEnrollmentSetSuccess,
  loadBenefitsDeclineReasons,
  loadBenefitsDeclineReasonsFail,
  loadBenefitsDeclineReasonsSuccess,
  loadBenefitsDependentInfo,
  loadBenefitsDependentInfoFail,
  loadBenefitsDependentInfoSuccess,
  loadBenefitsDependents,
  loadBenefitsDependentsFail,
  loadBenefitsDependentsSuccess,
  loadBenefitsFormDocument,
  loadBenefitsFormDocumentInfo,
  loadBenefitsFormDocumentInfoSuccess,
  loadBenefitsFormDocumentSuccess,
  loadBenefitsLifeChangingEvents,
  loadBenefitsLifeChangingEventsFail,
  loadBenefitsLifeChangingEventsSuccess,
  loadBenefitsPlanInfo, loadBenefitsPlanInfoFail,
  loadBenefitsPlanInfoSuccess, loadBenefitsPlans,
  loadBenefitsPlansFail, loadBenefitsPlansSuccess,
  loadBenefitsSetDocument,
  loadBenefitsSetDocumentSuccess,
  loadBenefitsSettings,
  loadBenefitsSettingsFailure,
  loadBenefitsSettingsSuccess,
  loadBenfitPlanTypes, loadBenfitPlanTypesFail,
  loadBenfitPlanTypesSuccess, loadCarrierBenfitPlans, loadCarrierBenfitPlansFail, loadCarrierBenfitPlansSuccess, loadCoverageLevels,
  loadCoverageLevelsFail, loadCoverageLevelsSuccess,
  loadElegibilityBegins, loadElegibilityBeginsFail,
  loadElegibilityBeginsSuccess, loadElegibilityWaitingPeriods,
  loadElegibilityWaitingPeriodsFail, loadElegibilityWaitingPeriodsSuccess,
  loadEmployerContribution,
  loadEmployerContributionFail,
  loadEmployerContributionSuccess,
  loadHelpGuide,
  loadHelpGuideSuccess,
  saveEmployeeEnrollmentSet,
  saveEmployeeEnrollmentSetSuccess,
  saveEmployeeEnrollmentSetFail,
  loadTerminationUpons, loadTerminationUponsFail, loadTerminationUponsSuccess,
  loadWithdrawnReasons,
  loadWithdrawnReasonsSuccess,
  markAsExemptBenefit,
  markAsExemptBenefitSuccess,
  saveBenefitClass,
  saveBenefitClassFail,
  saveBenefitClassSuccess,
  saveBenefitsDependent,
  saveBenefitsDependentFail,
  saveBenefitsDependentSuccess,
  saveBenefitsEnrollment,
  saveBenefitsEnrollmentFail,
  saveBenefitsEnrollmentSuccess,
  saveBenefitsPlan, saveBenefitsPlanFail, saveBenefitsPlanSuccess, signBenefitsFormDocument, signBenefitsFormDocumentFailure, signBenefitsFormDocumentSuccess, signBenefitsSetDocument, signBenefitsSetDocumentFailure, signBenefitsSetDocumentSuccess, startChangingEnrollment, startChangingEnrollmentFail, startChangingEnrollmentSuccess, switchBenefitStatus, switchBenefitStatusSuccess, verifyBenefitCheck, verifyBenefitCheckSuccess, cancelChangingEnrollmentFail
} from './benefits.actions';
import { BenefitsService } from '../../services/benefits.service';

@Injectable()
export class BenefitsEffects {
  constructor(
    private actions$: Actions,
    private benefitsService: BenefitsService,
    private store: Store<State>,
    private alertService: AlertService) { }

  loadBenefitsPlanInfo = createEffect(() => this.actions$.pipe(
    ofType(loadBenefitsPlanInfo),
    switchMap(action => this.benefitsService.getBenfitPlanInfo(action.benefitPlanID).pipe(
      map(benefitPlan => loadBenefitsPlanInfoSuccess({ benefitPlan })),
      catchError(err => {
        this.store.dispatch(loadBenefitsPlanInfoFail());
        return of(errorHappened({ err }));
      })))
  ));

  saveBenefitsPlan = createEffect(() => this.actions$.pipe(
    ofType(saveBenefitsPlan),
    switchMap(action => {
      const postPlanInfo = this.benefitsService.postBenfitPlan(action.benefitPlan);

      return postPlanInfo.pipe(
        switchMap(result => {
          const postsToBeMade = [of({})];
          if (action.benefitPlan.documents) {
            action.benefitPlan.documents.filter(x => x.new).forEach(doc => {
              const docCopy = {} as BenefitPlanDocumentModel;
              Object.assign(docCopy, doc);
              docCopy.benefitPlanID = result.benefitPlan.id;
              postsToBeMade.push(this.benefitsService.postBenfitPlanDocument(docCopy));
            }
            );
          }
          return concat(...postsToBeMade).pipe(
            map(resultUploads => {
              this.store.dispatch(loadBenefitsPlanInfo({ benefitPlanID: result.benefitPlan.id }));
              return saveBenefitsPlanSuccess();
            }),
            catchError(err => {
              return of(errorHappened({ err }));
            }));
        }),
        catchError(err => {
          this.store.dispatch(saveBenefitsPlanFail());
          return of(errorHappened({ err }));
        })
      );
    })));

  loadBenefitsPlans = createEffect(() => this.actions$.pipe(
    ofType(loadBenefitsPlans),
    switchMap(action => this.benefitsService.getBenfitPlans(action.filter).pipe(
      map(benefitPlanList => loadBenefitsPlansSuccess({ benefitPlanList })),
      catchError(err => {
        this.store.dispatch(loadBenefitsPlansFail());
        return of(errorHappened({ err }));
      })))
  ));

  loadCarrierBenfitPlans = createEffect(() => this.actions$.pipe(
    ofType(loadCarrierBenfitPlans),
    switchMap(action => this.benefitsService.getCarrierBenfitPlans().pipe(
      map(carrierBenefitPlanList => loadCarrierBenfitPlansSuccess({ carrierBenefitPlanList })),
      catchError(err => {
        this.store.dispatch(loadCarrierBenfitPlansFail());
        return of(errorHappened({ err }));
      })))
  ));

  loadBenfitPlanTypes = createEffect(() => this.actions$.pipe(
    ofType(loadBenfitPlanTypes),
    switchMap(action => this.benefitsService.getBenfitPlanTypes().pipe(
      map(benefitPlanTypesList => loadBenfitPlanTypesSuccess({ benefitPlanTypesList })),
      catchError(err => {
        this.store.dispatch(loadBenfitPlanTypesFail());
        return of(errorHappened({ err }));
      })))
  ));

  loadElegibilityWaitingPeriods = createEffect(() => this.actions$.pipe(
    ofType(loadElegibilityWaitingPeriods),
    switchMap(action => this.benefitsService.getElegibilityWaitingPeriods().pipe(
      map(ellegibilityWaitingPeriodsList => loadElegibilityWaitingPeriodsSuccess({ ellegibilityWaitingPeriodsList })),
      catchError(err => {
        this.store.dispatch(loadElegibilityWaitingPeriodsFail());
        return of(errorHappened({ err }));
      })))
  ));

  loadElegibilityBegins = createEffect(() => this.actions$.pipe(
    ofType(loadElegibilityBegins),
    switchMap(action => this.benefitsService.getElegibilityBegins().pipe(
      map(ellegibilityBeginsList => loadElegibilityBeginsSuccess({ ellegibilityBeginsList })),
      catchError(err => {
        this.store.dispatch(loadElegibilityBeginsFail());
        return of(errorHappened({ err }));
      })))
  ));

  loadTerminationUpons = createEffect(() => this.actions$.pipe(
    ofType(loadTerminationUpons),
    switchMap(action => this.benefitsService.getTerminationUpons().pipe(
      map(terminationUponsList => loadTerminationUponsSuccess({ terminationUponsList })),
      catchError(err => {
        this.store.dispatch(loadTerminationUponsFail());
        return of(errorHappened({ err }));
      })))
  ));


  loadCoverageLevels = createEffect(() => this.actions$.pipe(
    ofType(loadCoverageLevels),
    switchMap(action => this.benefitsService.getCoverageLevels().pipe(
      map(coverageLevelList => loadCoverageLevelsSuccess({ coverageLevelList })),
      catchError(err => {
        this.store.dispatch(loadCoverageLevelsFail());
        return of(errorHappened({ err }));
      })))
  ));

  loadBenefitsDependents = createEffect(() => this.actions$.pipe(
    ofType(loadBenefitsDependents),
    switchMap(action => this.benefitsService.getbenefitsDependents().pipe(
      map(benefitsDependents => loadBenefitsDependentsSuccess({ benefitsDependents })),
      catchError(err => {
        this.store.dispatch(loadBenefitsDependentsFail());
        return of(errorHappened({ err }));
      })))
  ));

  saveBenefitsDependent = createEffect(() => this.actions$.pipe(
    ofType(saveBenefitsDependent),
    switchMap(action => {
      return this.benefitsService.postbenefitsDependents(action.benefitsDependent).pipe(
        map(benefitsDependent => saveBenefitsDependentSuccess({ benefitsDependent })),
        catchError(err => {
          this.store.dispatch(saveBenefitsDependentFail());
          return of(errorHappened({ err }));
        })
      );
    })));


  loadBenefitsDependentInfo = createEffect(() => this.actions$.pipe(
    ofType(loadBenefitsDependentInfo),
    switchMap(action => this.benefitsService.getbenefitsDependentInfo(action.benefitsDependentId).pipe(
      map(benefitsDependentInfo => loadBenefitsDependentInfoSuccess({ benefitsDependentInfo })),
      catchError(err => {
        this.store.dispatch(loadBenefitsDependentInfoFail());
        return of(errorHappened({ err }));
      })))
  ));


  deleteBenefitsDependent = createEffect(() => this.actions$.pipe(
    ofType(deleteBenefitsDependent),
    switchMap(action => this.benefitsService.deletebenefitsDependent(action.benefitsDependentId).pipe(
      map(benefitsDependentInfo => deleteBenefitsDependentSuccess()),
      catchError(err => {
        this.store.dispatch(deleteBenefitsDependentFail());
        return of(errorHappened({ err }));
      })))
  ));


  loadAvailableBenefitPlans = createEffect(() => this.actions$.pipe(
    ofType(loadAvailableBenefitPlans),
    switchMap(action => this.benefitsService.getAvailableBenefitPlans().pipe(
      map(availableBenefitPlans => loadAvailableBenefitPlansSuccess({ availableBenefitPlans })),
      catchError(err => {
        this.store.dispatch(loadAvailableBenefitPlansFail());
        return of(errorHappened({ err }));
      })))
  ));


  deleteBenefitsPlans = createEffect(() => this.actions$.pipe(
    ofType(deleteBenefitsPlans),
    switchMap(action => this.benefitsService.deleteBenefitPlans(action.benefitsPlanID).pipe(
      map(result => deleteBenefitsPlansSuccess()),
      catchError(err => {
        this.store.dispatch(deleteBenefitsPlansFail());
        return of(errorHappened({ err }));
      })))
  ));


  loadBenefitsDeclineReasons = createEffect(() => this.actions$.pipe(
    ofType(loadBenefitsDeclineReasons),
    switchMap(action => this.benefitsService.getBenefitPlanDeclineReasons().pipe(
      map(benefitDeclineReasons => loadBenefitsDeclineReasonsSuccess({ benefitDeclineReasons })),
      catchError(err => {
        this.store.dispatch(loadBenefitsDeclineReasonsFail());
        return of(errorHappened({ err }));
      })))
  ));

  saveBenefitsEnrollment = createEffect(() => this.actions$.pipe(
    ofType(saveBenefitsEnrollment),
    switchMap(action => {
      return this.benefitsService.postBenefitsEnrollment(action.enrollmentSet).pipe(
        map(enrollmentSet => saveBenefitsEnrollmentSuccess({ enrollmentSet })),
        catchError(err => {
          this.store.dispatch(saveBenefitsEnrollmentFail());
          return of(errorHappened({ err }));
        })
      );
    })));


  loadBenefitPlanEnrollmentSetList = createEffect(() => this.actions$.pipe(
    ofType(loadBenefitPlanEnrollmentSetList),
    switchMap(action => this.benefitsService.getBenefitPlanEnrollmentSetList().pipe(
      map(benefitPlanEnrollmentSetList => loadBenefitPlanEnrollmentSetListSuccess({ benefitPlanEnrollmentSetList })),
      catchError(err => {
        return of(loadBenefitPlanEnrollmentSetListFail());
      })))
  ));

  

  loadEmployerContribution = createEffect(() => this.actions$.pipe(
    ofType(loadEmployerContribution),
    switchMap(action => this.benefitsService.getEmployerContribution().pipe(
      map(employerContribution => loadEmployerContributionSuccess({ employerContribution })),
      catchError(err => {
        this.store.dispatch(loadEmployerContributionFail());
        return of(errorHappened({ err }));
      })))
  ));

  loadBenefitClasses = createEffect(() => this.actions$.pipe(
    ofType(loadBenefitClasses),
    switchMap(action => this.benefitsService.getBenefitClasses().pipe(
      map(benefitClasses => loadBenefitClassesSuccess({ benefitClasses })),
      catchError(err => {
        this.store.dispatch(loadBenefitClassesFail());
        return of(errorHappened({ err }));
      })))
  ));


  saveBenefitClass = createEffect(() => this.actions$.pipe(
    ofType(saveBenefitClass),
    switchMap(action => this.benefitsService.postBenefitClass(action.benefitClass).pipe(
      map(benefitClass => saveBenefitClassSuccess({ benefitClass })),
      catchError(err => {
        this.store.dispatch(saveBenefitClassFail());
        return of(errorHappened({ err }));
      })))
  ));

  deleteBenefitClass = createEffect(() => this.actions$.pipe(
    ofType(deleteBenefitClass),
    switchMap(action => this.benefitsService.deleteBenefitClass(action.benefitClassId).pipe(
      map(result => deleteBenefitClassSuccess()),
      catchError(err => {
        this.store.dispatch(deleteBenefitClassFail());
        return of(errorHappened({ err }));
      })))
  ));


  loadBenefitsFormDocumentInfo = createEffect(() => this.actions$.pipe(
    ofType(loadBenefitsFormDocumentInfo),
    switchMap(action => {
      return this.benefitsService.getFormDocumentsEnrollmentInfo(action.benefitPlanFormDocumentsEnrollmentID).pipe(
        map(doc => loadBenefitsFormDocumentInfoSuccess({ doc })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  loadBenefitsFormDocument = createEffect(() => this.actions$.pipe(
    ofType(loadBenefitsFormDocument),
    switchMap(action => {
      return this.benefitsService.getFormDocumentsEnrollment(action.benefitPlanFormDocumentsEnrollmentID).pipe(
        map(doc => loadBenefitsFormDocumentSuccess({ doc })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  signBenefitsFormDocument = createEffect(() => this.actions$.pipe(
    ofType(signBenefitsFormDocument),
    switchMap(action => {
      return this.benefitsService.signFormDocumentsEnrollment(action.benefitPlanFormDocumentsEnrollmentID, action.signedFields).pipe(
        map(docSigned => signBenefitsFormDocumentSuccess({ docSigned })),
        catchError(err => {
          this.store.dispatch(signBenefitsFormDocumentFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  getBenefitsFormDocumentsList = createEffect(() => this.actions$.pipe(
    ofType(getBenefitsFormDocumentsList),
    switchMap(action => {
      return this.benefitsService.getMyDocs(action.userId).pipe(
        map(docs => getBenefitsFormDocumentsListSuccess({ docs })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));


  loadBenefitsSetDocument = createEffect(() => this.actions$.pipe(
    ofType(loadBenefitsSetDocument),
    switchMap(action => {
      return this.benefitsService.getDocumentEnrollmentSet(action.benefitPlanEnrollmentSetID).pipe(
        map(doc => loadBenefitsSetDocumentSuccess({ doc })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  signBenefitsSetDocument = createEffect(() => this.actions$.pipe(
    ofType(signBenefitsSetDocument),
    switchMap(action => {
      return this.benefitsService.signDocumentEnrollmentSet(action.benefitPlanEnrollmentSetID, action.signedFields).pipe(
        map(docSigned => signBenefitsSetDocumentSuccess({ docSigned })),
        catchError(err => {
          this.store.dispatch(signBenefitsSetDocumentFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  loadAllBenefitPlans = createEffect(() => this.actions$.pipe(
    ofType(loadAllBenefitPlans),
    switchMap(action => {
      return this.benefitsService.getAllBenefitPlans().pipe(
        map(allBenefitPlans => loadAllBenefitPlansSuccess({ allBenefitPlans })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));
  loadBenefitsSettings = createEffect(() => this.actions$.pipe(
    ofType(loadBenefitsSettings),
    switchMap(action => {
      return this.benefitsService.getBenefitsSettings().pipe(
        map(benefitsSettings => loadBenefitsSettingsSuccess({ benefitsSettings })),
        catchError(err => {
          this.store.dispatch(loadBenefitsSettingsFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  getBenefitStatusQueue = createEffect(() => this.actions$.pipe(
    ofType(getBenefitStatusQueue),
    switchMap(action => {
      return this.benefitsService.getBenefitStatusQueue(action.filter).pipe(
        map(queue => getBenefitStatusQueueSuccess({ queue })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  verifyBenefitCheck = createEffect(() => this.actions$.pipe(
    ofType(verifyBenefitCheck),
    switchMap(action =>
      this.benefitsService.verifyBenefitCheck(action.check).pipe(
        map(check => verifyBenefitCheckSuccess()),
        catchError(err => {
          return of(errorHappened({ err }));
        }))
    )));

  switchBenefitStatus = createEffect(() => this.actions$.pipe(
    ofType(switchBenefitStatus),
    switchMap(action =>
      this.benefitsService.switchBenefitStatus(action.check).pipe(
        map(check => switchBenefitStatusSuccess()),
        catchError(err => {
          return of(errorHappened({ err }));
        }))
    )));

  markAsExemptBenefit = createEffect(() => this.actions$.pipe(
    ofType(markAsExemptBenefit),
    switchMap(action =>
      this.benefitsService.markAsExemptBenefit(action.check).pipe(
        map(check => markAsExemptBenefitSuccess()),
        catchError(err => {
          return of(errorHappened({ err }));
        }))
    )));

  getBenefitHsaReport = createEffect(() => this.actions$.pipe(
    ofType(getBenefitHsaReport),
    switchMap(action => {
      return this.benefitsService.getBenefitHsaReport(action.filter).pipe(
        map(list => getBenefitHsaReportSuccess({ list })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  loadLogType = createEffect(() => this.actions$.pipe(
    ofType(loadBenefitLogTypes),
    switchMap(action => this.benefitsService.getLogTypes().pipe(
      map(types => loadBenefitLogTypesSuccess({ types })),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))));

  getBenefitLogList = createEffect(() => this.actions$.pipe(
    ofType(getBenefitLogList),
    switchMap(action => {
      return this.benefitsService.getBenefitLogList(action.filter).pipe(
        map(list => getBenefitLogListSuccess({ list })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  loadWithdrawnReasons = createEffect(() => this.actions$.pipe(
    ofType(loadWithdrawnReasons),
    switchMap(action => this.benefitsService.getWithdrawnReasons().pipe(
      map(reasons => loadWithdrawnReasonsSuccess({ reasons })),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))));

  getBenefitWithdrawnList = createEffect(() => this.actions$.pipe(
    ofType(getBenefitWithdrawnList),
    switchMap(action => {
      return this.benefitsService.getBenefitWithdrawnList(action.filter).pipe(
        map(list => getBenefitWithdrawnListSuccess({ list })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  getBenefitDeclinedList = createEffect(() => this.actions$.pipe(
    ofType(getBenefitDeclinedList),
    switchMap(action => {
      return this.benefitsService.getBenefitDeclinedList(action.filter).pipe(
        map(list => getBenefitDeclinedListSuccess({ list })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  getBenefitStatusList = createEffect(() => this.actions$.pipe(
    ofType(getBenefitStatusList),
    switchMap(action => {
      return this.benefitsService.getBenefitStatusList(action.filter).pipe(
        map(list => getBenefitStatusListSuccess({ list })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  downloadEnrollmentSetSignedForms = createEffect(() => this.actions$.pipe(
    ofType(downloadEnrollmentSetSignedForms),
    switchMap(action => {
      return this.benefitsService.downloadEnrollmentSetSignedForms(action.enrollmentSetId).pipe(
        map(doc => downloadEnrollmentSetSignedFormsSuccess({ doc })),
        catchError(err => {
          this.store.dispatch(downloadEnrollmentSetSignedFormsFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  downloadEnrollmentSignedForms = createEffect(() => this.actions$.pipe(
    ofType(downloadEnrollmentSignedForms),
    switchMap(action => {
      return this.benefitsService.downloadEnrollmentSignedForms(action.benefitPlanId).pipe(
        map(doc => downloadEnrollmentSignedFormsSuccess({ doc })),
        catchError(err => {
          this.store.dispatch(downloadEnrollmentSignedFormsFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  exportEnrollmentListToExcel = createEffect(() => this.actions$.pipe(
    ofType(exportEnrollmentListToExcel),
    switchMap(action => {
      return this.benefitsService.exportEnrollmentListToExcel(action.filter).pipe(
        map(doc => exportEnrollmentListToExcelSuccess({ doc })),
        catchError(err => {
          this.store.dispatch(exportEnrollmentListToExcelFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  exportAllDentalSelectEnrollmentListToExcel = createEffect(() => this.actions$.pipe(
    ofType(exportAllDentalSelectEnrollmentListToExcel),
    switchMap(action => {
      return this.benefitsService.exportAllDentalSelectEnrollmentListToExcel(action.filter).pipe(
        map(doc => exportAllDentalSelectEnrollmentListToExcelSuccess({ doc })),
        catchError(err => {
          this.store.dispatch(exportAllDentalSelectEnrollmentListToExcelFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  exportEmployeeBenefitStatusListToExcel = createEffect(() => this.actions$.pipe(
    ofType(exportEmployeeBenefitStatusListToExcel),
    switchMap(action => {
      return this.benefitsService.exportEmployeeBenefitStatusListToExcel(action.filter).pipe(
        map(doc => exportEmployeeBenefitStatusListToExcelSuccess({ doc })),
        catchError(err => {
          this.store.dispatch(exportEmployeeBenefitStatusListToExcelFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  exportBenefitHsaReportToExcel = createEffect(() => this.actions$.pipe(
    ofType(exportBenefitHsaReportToExcel),
    switchMap(action => {
      return this.benefitsService.exportBenefitHsaReportToExcel(action.filter).pipe(
        map(doc => exportBenefitHsaReportToExcelSuccess({ doc })),
        catchError(err => {
          this.store.dispatch(exportBenefitHsaReportToExcelFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  exportBenefitWithdrawnToExcel = createEffect(() => this.actions$.pipe(
    ofType(exportBenefitWithdrawnToExcel),
    switchMap(action => {
      return this.benefitsService.exportBenefitWithdrawnToExcel(action.filter).pipe(
        map(doc => exportBenefitWithdrawnToExcelSuccess({ doc })),
        catchError(err => {
          this.store.dispatch(exportBenefitWithdrawnToExcelFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  loadBenefitPlanUserWithEnrollmentSet = createEffect(() => this.actions$.pipe(
    ofType(loadBenefitPlanUserWithEnrollmentSet),
    switchMap(action => {
      return this.benefitsService.getManageEmployeesDatatable(action.filter).pipe(
        map(benefitPlanUserWithEnrollmentSetList => loadBenefitPlanUserWithEnrollmentSetSuccess({ benefitPlanUserWithEnrollmentSetList })),
        catchError(err => {
          this.store.dispatch(loadBenefitPlanUserWithEnrollmentSetFail());
          return of(errorHappened({ err }));
        }));
    })
  ));


  loadHelpGuide = createEffect(() => this.actions$.pipe(
    ofType(loadHelpGuide),
    switchMap(action => {
      return this.benefitsService.getHelpGuide().pipe(
        map(doc => loadHelpGuideSuccess({ doc })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));


  startChangingEnrollment = createEffect(() => this.actions$.pipe(
    ofType(startChangingEnrollment),
    switchMap(action => {
      return this.benefitsService.postStartChangingEnrollment
        (action.lifeChangingEventID, action.lifeChangingEventOther, action.lifeChangingEventDate, action.enrollmentYear).pipe(
          map(benefitPlanEnrollmentSet => startChangingEnrollmentSuccess({ benefitPlanEnrollmentSet })),
          catchError(err => {
            this.store.dispatch(startChangingEnrollmentFail());
            return of(errorHappened({ err }));
          })
        );
    })));


  loadBenefitsLifeChangingEvents = createEffect(() => this.actions$.pipe(
    ofType(loadBenefitsLifeChangingEvents),
    switchMap(action => this.benefitsService.getBenefitsLifeChangingEvents().pipe(
      map(benefitsLifeChangingEvents => loadBenefitsLifeChangingEventsSuccess({ benefitsLifeChangingEvents })),
      catchError(err => {
        return of(loadBenefitsLifeChangingEventsFail());
      })))
  ));



  cancelChangingEnrollment = createEffect(() => this.actions$.pipe(
    ofType(cancelChangingEnrollment),
    switchMap(action => {
      return this.benefitsService.cancelChangingEnrollment(action.enrollmentYear).pipe(
        map(benefitPlanEnrollmentSet => cancelChangingEnrollmentSuccess({ benefitPlanEnrollmentSet })),
        catchError(err => {
          this.store.dispatch(cancelChangingEnrollmentFail());
          return of(errorHappened({ err }));
        })
      );
    })));

  exportManageEmployeesListToExcel = createEffect(() => this.actions$.pipe(
    ofType(exportManageEmployeesListToExcel),
    switchMap(action => {
      return this.benefitsService.exportManageEmployeesListToExcel(action.filter).pipe(
        map(doc => exportManageEmployeesListToExcelSuccess({ doc })),
        catchError(err => {
          this.store.dispatch(exportManageEmployeesListToExcelFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));


  saveEmployeeEnrollmentSet = createEffect(() => this.actions$.pipe(
    ofType(saveEmployeeEnrollmentSet),
    switchMap(action => {
      return this.benefitsService.saveEmployeeEnrollmentSet(action.enrollmentSet).pipe(
        map(enrollmentSet => saveEmployeeEnrollmentSetSuccess({ enrollmentSet })),
        catchError(err => {
          this.store.dispatch(saveEmployeeEnrollmentSetFail());
          return of(errorHappened({ err }));
        })
      );
    })));
}
