import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { State } from '../../../../core/store';
import { deleteEmployeeReview, deleteEmployeeReviewOversight, deleteEmployeeReviewOversightSuccess, deleteEmployeeReviewSuccess, getOverseenEmployee, loadReviews } from '../../../../core/store/employee-review/employee-review.actions';
import { getUser } from '../../../../core/store/users/users.actions';
import { deepClone } from '../../../../helpers/utils';
import { EmployeeReviewInfoModel, OverseenEmployeeModel } from '../../../../models/employeeReviewModels';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { UserModel } from '../../../../models/userModel';
import { AlertService } from '../../../../shared/services/alert.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-employee-review-list',
  templateUrl: './employee-review-list.component.html',
  styleUrls: ['./employee-review-list.component.scss']
})
export class EmployeeReviewListComponent extends BaseComponent implements OnInit {
  @Input() paramUserId: number;
  loading: boolean = true;
  reviews: PagingResultsModel<EmployeeReviewInfoModel>;
  filter: any;
  userId: number;
  user: OverseenEmployeeModel;
  isPlayer: boolean;
  
  currentUser: UserModel;

  yesNoAllOptions = [
    { label: 'All', value: null },
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  includeArchived?: boolean = false;

  constructor(
    private actions$: Actions,
    private alertService: AlertService,
    private store: Store<State>,
    private route: ActivatedRoute,
    private router: Router) {
    super();
    this.user = {
      canReview: false,
      firstName: "",
      lastName: "",
      userID: null,
      isOpenPeriod: false
    };
  }

  ngOnInit() {
    this.loading = false;
    this.userId = 0;
    this.user = {
      canReview: false,
      firstName: "",
      lastName: "",
      userID: null,
      isOpenPeriod: false
    };
    this.isPlayer = false;
    
    this.subs.push(
      this.store.select(s => s.employeeReviewState.reviews).subscribe(reviews => {
        this.reviews = reviews;
      }),
      this.store.select(s => s.employeeReviewState.loading).subscribe(loading => {
        this.loading = loading;
      }),
      this.store.select(s => s.accountState.user).subscribe(user => {
        this.currentUser = user;
      }),
      this.store.select(s => s.employeeReviewState.overseenEmployee).subscribe(user => {
        if (user == null) {
          this.user = {
            canReview: false,
            firstName: "",
            lastName: "",
            userID: null,
            isOpenPeriod: false
          };
        } else {
          this.user = user;
        }
        if (this.currentUser != null && this.user != null && this.currentUser.id == this.userId) {
          this.isPlayer = true;
        }
      }),
      this.actions$.pipe(
        ofType(deleteEmployeeReviewSuccess),
        tap(action => {
          this.alertService.success("Review Deleted");
          this.reloadList();
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(deleteEmployeeReviewOversightSuccess),
        tap(action => {
          this.alertService.success("Oversight Review Deleted");
          this.reloadList();
        })
      ).subscribe(),
    );

    this.route.params.subscribe(params => {
      this.userId = parseInt(params.userId);
      if (this.userId) {
        this.init(this.userId);
      }
    });

    if (this.paramUserId) {
      this.init(this.paramUserId);
    }
  }

  init(userId) {
    if (userId && userId > 0) {
      this.userId = userId;
      this.store.dispatch(getOverseenEmployee({ userId: userId }));
    }
  }

  openReview(review) {
    if (review.isOversight) {
      this.openLink(`/employee-review/oversight/view/${review.reviewID}`);
    } else {
      this.openLink(`/employee-review/view/${review.reviewID}`);
    }
  }
  
  editReview(review) {
    if (review.isOversight) {
      this.openLink(`/employee-review/oversight/${this.userId}/${review.templateId}`);
    } else {
      this.openLink(`/employee-review/player/${review.reviewID}`);
    }
  }

  deleteReview(review: EmployeeReviewInfoModel) {
    if (review.reviewID > 0) {
      if (review.isOversight) {
        this.alertService.confirm('Delete Oversight Review',
          'Are you sure you want to delete this oversight review? This action can NOT be undone.')
          .subscribe(
            answer => {
              if (answer) {
                this.store.dispatch(deleteEmployeeReviewOversight({ reviewId: review.reviewID }));
              }
            }, error => {
            }
          );        
      }
      else {
        this.alertService.confirm('Delete Review',
          'Are you sure you want to delete this review? This action can NOT be undone.')
          .subscribe(
            answer => {
              if (answer) {
                this.store.dispatch(deleteEmployeeReview({ reviewId: review.reviewID }));
              }
            }, error => {
            }
          );
        
      }
    }
  }

  openLink(path) {
    this.router.navigate([path]);
  }

  reloadReviews(event) {
    this.filter = event;
    this.reloadList();    
  }

  reloadList() {
    this.setFilter();

    this.store.dispatch(loadReviews({ userId: this.userId, filter: this.filter }));
  }

  setFilter() {
    this.filter = deepClone(this.filter);
    this.filter.filters.includeArchived = { Name: 'includeArchived', Value: { value: this.includeArchived } };
  }
}
