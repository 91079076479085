import { ValidatorRequiredIfBool, ValidatorRequiredIfOtherFieldNotEquals, ValidatorRequiredNotEmptyIfBool, ValidatorRequiredNotEmptyIfFalse } from 'src/app/helpers/validators';
import { CreateEmployeePatientModel, InsuranceProviderModel } from './../../../models/medClinicModels';
import { createReducer, on, Action } from '@ngrx/store';
import { loadMedClinicPatients, loadMedClinicPatientsSuccess, saveMedClinicPatient, saveMedClinicPatientSuccess, saveMedClinicPatientFailure, updateCreatePatientForm, loadInsuranceProvidersSuccess } from './medclinic.actions';
import { FormControl, FormGroup, Validators } from '@angular/forms';

export interface MedClinicState {
  patientsList: CreateEmployeePatientModel[];
  loadingPatients: boolean;
  savingPatients: boolean;
  patient: CreateEmployeePatientModel;
  insuranceProviders: InsuranceProviderModel[];
}

const initialState: MedClinicState = {
  patientsList: null,
  loadingPatients: false,
  savingPatients: false,
  patient: null,
  insuranceProviders: null
};

export const medClinicStateReducer = createReducer(initialState,
  on(loadMedClinicPatients, (state, action) => {
    return {
      ...state,
      loadingPatients: true
    };
  }),
  on(loadMedClinicPatientsSuccess, (state, action) => {
    return {
      ...state,
      patientsList: action.patients,
      loadingPatients: false
    };
  }),

  on(saveMedClinicPatient, (state, action) => {
    return {
      ...state,
      savingPatients: true
    };
  }),
  on(saveMedClinicPatientSuccess, (state, action) => {
    return {
      ...state,
      savingPatients: false
    };
  }),

  on(saveMedClinicPatientFailure, (state, action) => {
    return {
      ...state,
      savingPatients: false
    };
  }),


  on(saveMedClinicPatientFailure, (state, action) => {
    return {
      ...state,
      savingPatients: false
    };
  }),

  on(updateCreatePatientForm, (state, action) => {
    const newState = { ...state, ...{ patient: action.patientValues, formErrors: action.formErrors } };
    return newState;
  }),

  on(loadInsuranceProvidersSuccess, (state, action) => {
    return {
      ...state,
      insuranceProviders: action.insuranceProviders,
    };
  }),

);

export function medClinicStateReducerFunc(state: MedClinicState | undefined, action: Action) {
  return medClinicStateReducer(state, action);
}


export function initCreatePatientForm(email: string) {
  const form = new FormGroup({
    firstName: new FormControl(null, []),
    lastName: new FormControl(null, []),
    relationshipToClient: new FormControl(null, [Validators.required]),
    birthdate: new FormControl(null, []),
    gender: new FormControl(null, []),
    reasonForInquiry: new FormControl(null, [Validators.required]),
    email: new FormControl(email, [Validators.required, Validators.email]),
    insuranceProviderID: new FormControl(null, []),
    memberID: new FormControl(null, []),
    policyNumber: new FormControl(null, []),
    policyHolderFirstName: new FormControl(null, []),
    policyHolderLastName: new FormControl(null, []),
    policyHolderDoB: new FormControl(null, []),
    startDate: new FormControl(null, []),
    expirationDate: new FormControl(null, []),
    isMedClinic: new FormControl(null, []),
    isPsychMed: new FormControl(null, []),
  }, [ValidatorRequiredIfOtherFieldNotEquals(['firstName', 'lastName', 'birthdate', 'gender'], 'relationshipToClient', 'Self'),
ValidatorRequiredIfOtherFieldNotEquals(['policyHolderFirstName', 'policyHolderLastName', 'policyHolderDoB','startDate'], 'insuranceProviderID', null)
  ]);



  return form;
}

