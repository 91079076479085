import { UserProfileModel } from './../../../../../models/userProfileModel';
import { saveProfile, loadProfile, saveProfileSuccess, updateProfileI9Form } from '../../../../../core/store/profile/profile.actions';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from '../../../../../shared/services/alert.service';
import { Component, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../../core/store';
import { Actions, ofType } from '@ngrx/effects';
import * as moment from 'moment';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { FormGroup } from '@angular/forms';
import { getAllControlsErrors, markFormGroupTouched } from 'src/app/helpers/utils';
import { initProfileI9Form } from 'src/app/core/store/profile/profile.reducer';
import { tap } from 'rxjs/operators';
import { setOnboardingDocsInfoFilled, setOnboardingDocsInfoFilledSuccess } from 'src/app/core/store/onboardingdocs/onboardingdocs.actions';

@Component({
  selector: 'app-doc-fill-info-i9form',
  templateUrl: './doc-fill-info-i9form.component.html',
  styleUrls: ['./doc-fill-info-i9form.component.scss']
})
export class DocFillInfoi9FormComponent extends BaseComponent implements OnInit {

  @Input()
  onboardingDocUserId;

  @Input()
  doNotNavigate;

  profile: UserProfileModel;
  yearRange = '1900:' + new Date(new Date().setFullYear(new Date().getFullYear() + 15)).getFullYear();
  i9USSituationOptions = [
    { label: 'A citizen of the United States', value: 'USCitizen' },
    { label: 'A noncitizen national of the United States', value: 'National' },
    { label: 'A lawful permanent resident', value: 'PermanentResident' },
    { label: 'An alien authorized to work', value: 'AuthorizedToWork' },
  ];
  yesNoOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];
  form: FormGroup;
  constructor(
    private store: Store<State>,
    private actions$: Actions,
    private alert: AlertService,
    private router: Router,
    private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.store.dispatch(loadProfile());
    this.subs.push(
      this.store.select(s => s.profileState).subscribe(profileState => {
        if (profileState && profileState.profile) {
          this.profile = JSON.parse(JSON.stringify(profileState.profile));
          if (this.profile.employee.birthdate) {
            this.profile.employee.birthdate = new Date(this.profile.employee.birthdate);
          }
          if (!this.form) {
            this.form = initProfileI9Form(this.profile);
            this.form.get('employee.i9UsedPreparer').valueChanges.subscribe(value => {
              if (!this.form.get('employee.i9UsedPreparer').value) {
                this.form.get('employee.preparerFirstName').setValue(null);
                this.form.get('employee.preparerLastName').setValue(null);
                this.form.get('employee.preparerAddress').setValue(null);
                this.form.get('employee.preparerCity').setValue(null);
                this.form.get('employee.preparerState').setValue(null);
                this.form.get('employee.preparerZip').setValue(null);
              }
            });
            this.subs.push(
              this.form.valueChanges.subscribe(formValues => {
                const userValues = this.form.get('user').value;
                const employeeValues = this.form.get('employee').value;
                const formErrors = getAllControlsErrors(this.form);
                this.store.dispatch(updateProfileI9Form({ userValues, employeeValues, formErrors }));
              })
            );
          }
        }
      }),
      this.actions$.pipe(
        ofType(saveProfileSuccess),
        tap(action => {
          this.store.dispatch(setOnboardingDocsInfoFilled({ onboardingDocUserId: this.onboardingDocUserId }));
        })
      ).subscribe(),

      this.actions$.pipe(
        ofType(setOnboardingDocsInfoFilledSuccess),
        tap(action => {
          if (!this.doNotNavigate) {
            this.router.navigate(['../../sign/' + this.onboardingDocUserId], { relativeTo: this.route });
          }
        })
      ).subscribe()
    );
  }

  save() {
    if (this.form.valid) {
      this.store.dispatch(saveProfile({ profile: this.profile }));
    } else {
      markFormGroupTouched(this.form);
    }
  }

}
