import { updateCreateCoachingLogForm, createCoachingLog, getCoachableUsersList, createCoachingLogSuccess, getCategoriesByLevelList, getCoachingLog, getCoachingLogSuccess } from './../../../../core/store/coachingLog/coahingLog.actions';
import { getUsersList } from './../../../../core/store/users/users.actions';
import { SelectItem } from 'primeng/api';
import { UserModel } from 'src/app/models/userModel';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { getAllControlsErrors, markFormGroupTouched } from 'src/app/helpers/utils';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { CoachingLogWithUsersModel } from 'src/app/models/coachingLogWithUsersModel';
import { initCreateCoachingLogForm } from 'src/app/core/store/coachingLog/coahingLog.reducer';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { CoachingLogCategoryModel } from '../../../../models/coachingLogCategoryModel';
import { format } from 'util';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'app-create-coachinglog',
  templateUrl: './create-coachinglog.component.html',
  styleUrls: ['./create-coachinglog.component.scss']
})
export class CreateCoachingLogComponent extends BaseComponent implements OnInit {
  previousCoachingLogID: number;
  coachingLogWithUsersModel: CoachingLogWithUsersModel;
  previousCoachingLog: CoachingLogWithUsersModel;
  form: FormGroup;
  coachingForm: FormGroup;
  canAddCoach: boolean;
  loadingUsersList = false;
  loadingCategories = false;
  categoriesList: CoachingLogCategoryModel[];
  usersList: SelectItem[] = [{ label: 'loading...', value: undefined }];
  creatingCoachingLog: boolean = false;
  hasOtherCategory: boolean = false;

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private datepipe: DatePipe) {
    super();
  }

  ngOnInit() {
    // Initialize the form
    var coachingLogValues = {
      user: {} as UserModel,
      noteDate: this.datepipe.transform(new Date(), 'yyyy-MM-dd')
    } as CoachingLogWithUsersModel;
    this.form = initCreateCoachingLogForm(this.formBuilder, coachingLogValues);
    const formErrors = getAllControlsErrors(this.form);
    this.coachingForm = this.form.get('coachingLog') as FormGroup;

    this.categoriesList = [];
    this.hasOtherCategory = false;
    this.subs.push(

      // Update form values on the store
      this.form.valueChanges.subscribe(formValues => {
        const coachingLog = this.coachingForm.value;
        const formErrors = getAllControlsErrors(this.form);
        this.store.dispatch(updateCreateCoachingLogForm({ coachingLog, formErrors }));
      }),

      this.coachingForm.get('level').valueChanges.subscribe(value => {
        this.loadingCategories = true;
        var levelId = parseInt(value);
        this.store.dispatch(getCategoriesByLevelList({ levelID: levelId }));
      }),
      this.store.select(s => s.coachingLogState.categoriesList).subscribe(categories => {
        if (categories == null)
          categories = [];
        this.categoriesList = [];
        const formArray: FormArray = this.categoriesFormArray;
        formArray.clear();
        this.hasOtherCategory = false;
        categories.forEach((cl) => {
          if (cl.categoryName == "Other") {
            this.hasOtherCategory = true;
            return;
          }
          this.categoriesList.push(cl);
          var checked = false;
          if (this.coachingLogWithUsersModel && this.coachingLogWithUsersModel.categories)
            checked = this.coachingLogWithUsersModel.categories.indexOf(cl.categoryName) >= 0;

          formArray.push(new FormControl( checked));
        });
      }),

      // Check if user is an HR manager
      this.store.select(s => s.accountState.user.permissionIDs).subscribe(permissionIDs => {
        this.canAddCoach = permissionIDs && !!permissionIDs.find(p => p === 6004 || p === 6005 || p === 6006);
      }),
      this.store.select(s => s.coachingLogState.coachingLogWithUsersModel).subscribe(offerletterWithUser => {
        this.coachingLogWithUsersModel = offerletterWithUser;
      }),
      this.store.select(s => s.coachingLogState.creatingCoachingLog).subscribe(creatingCoachingLog => {
        this.creatingCoachingLog = creatingCoachingLog;
      }),
      
      this.store.select(s => s.coachingLogState.coachableUsersList).subscribe(usersList => {
        this.usersList = usersList ? usersList.map(u => ({ label: u.firstName + ' ' + u.lastName, value: u.id }))
          : [{ label: 'loading...', value: undefined }];
        this.usersList.unshift({ label: '', value: null });
      }),
      this.actions$.pipe(
        ofType(createCoachingLogSuccess),
        tap(action => {
          this.router.navigate(['/coachinglogs/given']);
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(getCoachingLogSuccess),
        tap(action => {
          this.previousCoachingLog = action.coachingLog;
          this.coachingForm.get("level").setValue(2);
          this.coachingForm.get("userID").setValue(action.coachingLog.userID);
          this.coachingForm.get("description").setValue(action.coachingLog.description);
          
          this.previousCoachingLogID = action.coachingLog.id;
          this.coachingForm.get("previouslyAddressedCoachingLogID").setValue(this.previousCoachingLogID);

          
        })
      ).subscribe(),
    );
    this.route.params.subscribe(params => {
      if (params.previousCoachingLogID) {

        this.store.dispatch(getCoachingLog({ coachingLogID: params.previousCoachingLogID }));
      } 
    });
    
    this.store.dispatch(updateCreateCoachingLogForm({ coachingLog: coachingLogValues, formErrors }));
    this.store.dispatch(getCoachableUsersList());
  }

  getForm() {
    return this.form;
  }


  get categoriesFormArray() {
    return this.coachingForm.get('categories') as FormArray;
  }


  getLevel(level: number) {
    if (level === 1) {
      return 'Re-Focus';
    } else if (level === 2) {
      return 'Coaching';
    } else if (level === 3) {
      return 'Coaching + Plan';
    } else if (level === 4) {
      return 'Corrective + Plan';
    }

  }


  save() {
    if (this.form.valid) {
      const selectedCategories = this.categoriesFormArray.value
        .map((v, i) => v ? this.categoriesList[i].categoryName : null)
        .filter(v => v !== null);
      if (this.hasOtherCategory && this.coachingLogWithUsersModel.otherCategory)
        selectedCategories.push(this.coachingLogWithUsersModel.otherCategory);
      
      this.store.dispatch(createCoachingLog({
        coachingLogWithUsersModel: {
          ...this.coachingLogWithUsersModel,
          categories: selectedCategories
        }
      }));
    } else {
      markFormGroupTouched(this.form);
    }
  }


}

