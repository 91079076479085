import {
  deleteBenefitClass,deleteBenefitClassSuccess,
  deleteBenefitsDependent, deleteBenefitsDependentSuccess,
  loadBenefitClasses, saveBenefitClass, saveBenefitClassSuccess,
  saveBenefitsDependent, saveBenefitsDependentSuccess,
  updateBenefitClassForm
} from './../../../../core/store/benefits/benefits.actions';
import { BaseComponent } from './../../../../core/abstracts/baseComponent';
import { BenefitsState, initBenefitClassForm, initBenefitsDependentForm } from './../../../../core/store/benefits/benefits.reducer';
import { BenefitClassModel, BenefitPlanModel, BenefitsDependentModel } from './../../../../models/benefitsModels';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { Actions, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { MultiSelect } from 'primeng/multiselect';
import { AlertService } from '../../../../shared/services/alert.service';
import { deepClone, getAllControlsErrors, markFormGroupTouched, updateFormGroupValidity } from '../../../../helpers/utils';
import { loadBenefitsDependents, loadBenefitsPlans, updateBenefitsDependentForm } from '../../../../core/store/benefits/benefits.actions';
import { FormGroup } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-benefits-classes',
  templateUrl: './benefits-classes.component.html',
  styleUrls: ['./benefits-classes.component.scss']
})
export class BenefitsClassesComponent extends BaseComponent implements OnInit {

  benefitClasses: BenefitClassModel[];
  loadingBenefitClasses = false;
  loading = false;
  openClassInfo = false;
  benefitClassInfo: BenefitClassModel = {} as BenefitClassModel;
  form: FormGroup;

  constructor(
    private actions$: Actions,
    private alertService: AlertService,
    private store: Store<State>,
    private router: Router,
  ) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.benefitsState.benefitClasses).subscribe(benefitClasses => {
        if (benefitClasses) {
          this.benefitClasses = benefitClasses;
        }
      }),

      this.store.select(s => s.benefitsState.loadingBenefitClasses).subscribe(loadingBenefitClasses => {
        this.loadingBenefitClasses = loadingBenefitClasses;
      }),

      this.store.select(s => s.benefitsState.benefitClass).subscribe(benefitClass => {
        if (benefitClass) {
          this.benefitClassInfo = benefitClass;
        }
      }),
      this.actions$.pipe(
        ofType(saveBenefitClassSuccess),
        tap(action => {
          this.alertService.success('Benefits Class saved!');
          this.store.dispatch(loadBenefitClasses());
          this.openClassInfo = false;
          this.benefitClassInfo = {} as BenefitClassModel;
          this.initForm();
        })
      ).subscribe(),

      this.actions$.pipe(
        ofType(deleteBenefitClassSuccess),
        tap(action => {
          this.alertService.success('Benefits Class removed!');
          this.store.dispatch(loadBenefitClasses());
          this.openClassInfo = false;
          this.benefitClassInfo = {} as BenefitClassModel;
          this.initForm();
        })
      ).subscribe(),

    );

    this.store.dispatch(loadBenefitClasses());
  }

  initForm() {
    this.form = initBenefitClassForm(this.benefitClassInfo);
    this.subs.push(
      this.form.valueChanges.subscribe(formValues => {
        const formErrors = getAllControlsErrors(this.form);
        this.store.dispatch(updateBenefitClassForm({ benefitClassValues: formValues, formErrors }));
      }));
  }

  editBenefitClass(benefitClass: BenefitClassModel) {
    this.openClassInfo = true;
    this.benefitClassInfo = benefitClass;
    this.initForm();
  }

  deleteBenefitClass(benefitClass: BenefitClassModel) {
    this.store.dispatch(deleteBenefitClass({ benefitClassId: benefitClass.id }));
  }

  newBenefitClassInfo() {
    this.openClassInfo = true;
    this.benefitClassInfo = {} as BenefitClassModel;
    this.initForm();
  }

  save() {
    updateFormGroupValidity(this.form);
    if (this.form.valid) {
      this.store.dispatch(saveBenefitClass({ benefitClass: this.benefitClassInfo }));
    } else {
      markFormGroupTouched(this.form);
    }
  }

}

function deleteBenefitsClassSuccess(deleteBenefitsClassSuccess: any): import("rxjs").OperatorFunction<import("@ngrx/store").Action, any> {
  throw new Error('Function not implemented.');
}

