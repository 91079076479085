import { clearFormAssignmentTable, deleteFBFormAssignment, deleteFBFormAssignmentSuccess, getAllForms, getFBFormAssignmentTable, getFBFormAssignmentTableSuccess, getFormsFilter, getFormTypesList, getInquiryList, loadFormDoc, loadFormDocSuccess, setFormAssignmentCanOptout } from './../../../../core/store/form-builder/form-builder.actions';
import { FormBuilderState } from './../../../../core/store/form-builder/form-builder.reducer';
import { FBFormModel, FBFormAssignmentModel } from './../../../../models/formBuilderModels';
import { BaseComponent } from './../../../../core/abstracts/baseComponent';
import { BenefitsState } from './../../../../core/store/benefits/benefits.reducer';
import { BenefitPlanModel } from './../../../../models/benefitsModels';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { Actions, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { MultiSelect } from 'primeng/multiselect';
import { AlertService } from '../../../../shared/services/alert.service';
import { deepClone, downloadFile, getTimeStampString } from '../../../../helpers/utils';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { tap } from 'rxjs/operators';
import { DateRangeModel } from '../../../../models/dateRangeModel';
import { DropdownFilterService } from '../../../../core/services/dropdown-filter.service';
import { deleteFBForm, getFBFormTable } from 'src/app/core/store/form-builder/form-builder.actions';
import { getUsersList } from 'src/app/core/store/users/users.actions';
import { loadClients } from 'src/app/core/store/timecard/timecard.actions';
import { Table } from 'primeng';

@Component({
  selector: 'app-form-assignment-modal',
  templateUrl: './form-assignment-modal.component.html',
  styleUrls: ['./form-assignment-modal.component.scss']
})
export class FormAssignmentModalComponent extends BaseComponent implements OnInit {
  
  @ViewChild('userFormsTable') formTable: Table;
  @Input() userID: number = null;
  @Input() inquiryID: number = null;
  @Input() usersName: string = null;
  @Output() myEvent = new EventEmitter<string>();

  formsAssignementList: PagingResultsModel<FBFormAssignmentModel>;
  canEdit: boolean;
  loadingFormsAssignementList = false;
  filter: any;

  showFilter: string = "Show";

  filterFormID;
  filterFormTypeID;
  filterSigned;
  filterRequired;

  loadingDoc;
  loadingDocAssignmentId;


  userOptions: SelectItem[];
  formOptions: SelectItem[];
  formTypeOptions: SelectItem[];
  inquiryList: SelectItem[];

  yesNoAllOptions = [
    { label: 'All', value: null },
    { label: 'Yes', value: 'true' },
    { label: 'No', value: 'false' },
  ];

  selectedForm;
  constructor(
    private actions$: Actions,
    private alertService: AlertService,
    private store: Store<State>,
    private router: Router,
    private confirmationService: ConfirmationService,
    private dropdownFilterService: DropdownFilterService
  ) {
    super();
  }

  ngOnInit() {
    this.store.dispatch(getFormsFilter());
    this.store.dispatch(getFormTypesList());

    this.subs.push(

      this.store.select(s => s.formBuilderState.inquiryList).subscribe(inquiryList => {
        this.inquiryList = inquiryList ? inquiryList.map(u => ({ label: u.name, value: u.inquiryID }))
          : [{ label: 'loading...', value: undefined }];
      }),
      this.store.select(s => s.formBuilderState.simpleFormsList).subscribe(formsList => {
        this.formOptions = formsList ? formsList.map(c => ({ label: c.name, value: c.id }))
          : [{ label: 'loading...', value: undefined }];
      }),
      this.store.select(s => s.formBuilderState.formTypes).subscribe(formTypes => {
        if (formTypes) {
          this.formTypeOptions = formTypes.map(x => ({ label: x.formType, value: x.id }));
        }
      }),
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.canEdit = permissionIDs && !!permissionIDs.find(p => p === 9001);
      }),
      this.store.select(s => s.formBuilderState.formsAssignment).subscribe(formsAssignementList => {
        if (formsAssignementList) {
          this.formsAssignementList = formsAssignementList;
        }
      }),

      this.store.select(s => s.formBuilderState.loadingFormsAssignment).subscribe(loadingFormsAssignementList => {
        this.loadingFormsAssignementList = loadingFormsAssignementList;
      }),
      this.store.select(s => s.formBuilderState.loadingDoc).subscribe(loadingDoc => {
        this.loadingDoc = loadingDoc;
      }),
      this.actions$.pipe(
        ofType(deleteFBFormAssignmentSuccess),
        tap(action => {
          this.alertService.success('Assignment deleted');
          this.loadPage(this.filter);
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(loadFormDocSuccess),
        tap(action => {
          downloadFile(action.doc, 'FormSubmission.pdf');
        })
      ).subscribe(),
    );

  }

  loadPage(event) {
    if (!this.loadingFormsAssignementList) {
      this.loadingFormsAssignementList = true;
      this.filter = deepClone(event);
      this.filter.filters.filterFormID = { Name: 'filterFormID', Value: { value: this.filterFormID ? this.filterFormID.join(',') : null }  };
      this.filter.filters.filterFormTypeID = { Name: 'filterFormTypeID', Value: { value: this.filterFormTypeID } };
      this.filter.filters.filterSigned = { Name: 'filterSigned', Value: { value: this.filterSigned } };
      this.filter.filters.filterRequired = { Name: 'filterRequired', Value: { value: this.filterRequired } };
      this.filter.filters.filterUserID = { Name: 'filterUserID', Value: { value: this.userID } };
      this.filter.filters.filterInquiryID = { Name: 'filterUserID', Value: { value: this.inquiryID } };
      this.store.dispatch(getFBFormAssignmentTable({
        filter: deepClone(this.filter)
      }));
    }
  }

  changedFilters() {
    this.formTable.reset();
    this.loadPage(this.filter);
  }

  toggleFilter(){
    if(this.showFilter == "Hide"){
      this.showFilter = "Show"
    }
    else {
      this.showFilter = "Hide"
    }
  }

  openMultiSelect(multiselect: MultiSelect) {
    multiselect.show();
  }

  seeFormResult(formAssignment: FBFormAssignmentModel) {
    this.router.navigate(['/form-builder/form-submission/ ' + formAssignment.formID + '/' + formAssignment.formSubmissionID], { queryParams: { backLink: '/form-builder/forms-assignment' } });
  }

  deleteForm(formAssignment: FBFormAssignmentModel) {
    this.confirmationService.confirm({
      header: 'Delete',
      message: 'Are you sure you want to delete this form assignment?',
      acceptLabel: 'Delete',
      rejectLabel: 'Cancel',
      accept: () => {
        this.store.dispatch(deleteFBFormAssignment({ formAssignmentID: formAssignment.id }));
      }
    });
  }

  downloadForm(formAssignment: FBFormAssignmentModel) {
    this.loadingDocAssignmentId = formAssignment.id;
    this.store.dispatch(loadFormDoc({ formSubmissionID: formAssignment.formSubmissionID }));
  }
  
  closeModal(){
    this.myEvent.emit('eventDesc');
    this.usersName = "";
    this.store.dispatch(clearFormAssignmentTable());
  }

  changedOptOut(formAssignment: FBFormAssignmentModel) {
    this.store.dispatch(setFormAssignmentCanOptout({ formAssignmentID: formAssignment.id, canOptOut: !formAssignment.canOptOut}));
  }
}
