import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, tap, catchError, filter } from 'rxjs/operators';
import { of, pipe } from 'rxjs';
import { errorHappened } from '../profile/profile.actions';
import { Store, Action } from '@ngrx/store';
import { State } from '..';
import { AlertService } from 'src/app/shared/services/alert.service';
import {
  loadOnboardingDoc, loadOnboardingDocSuccess, signOnboardingDoc,
  signOnboardingDocSuccess, getOnboardingDocsList, getOnboardingDocsListSuccess,
  signOnboardingDocFailure, loadOnboardingDocInfo, loadOnboardingDocInfoSuccess,
  setOnboardingDocsInfoFilled, setOnboardingDocsInfoFilledSuccess, getAllUserDocs,
  getAllUserDocsSuccess, getActiveDocs, getActiveDocsSuccess, getUsersDocsReport,
  getUsersDocsReportSuccess, getUsersDocsReportFailure, getUsersDocsReportExcel,
  getUsersDocsReportExcelSuccess, getUsersDocsReportExcelFailure, getOnboardingDocs, getOnboardingDocsSuccess, getOnboardingDocsFailure, getOnboardingDocFailure, getOnboardingDocSuccess, getOnboardingDoc, updateOnboardingDocFailure, updateOnboardingDocSuccess, updateOnboardingDoc, getOnboardingUserDocsSuccess, getOnboardingUserDocs, getOnboardingUserDocsFailure, updateOnboardingUserDocForceNotRequiredFailure, updateOnboardingUserDocForceNotRequiredSuccess, updateOnboardingUserDocForceNotRequired, resendOnboardingDocUserForce, resendOnboardingDocUserForceSuccess, resendOnboardingDocUserForceFailure
} from './onboardingdocs.actions';
import { OnboardingDocsService } from '../../services/onboardingdocs.service';

@Injectable()
export class OnBoardingDocsEffects {
  constructor(
    private actions$: Actions,
    private onboardingDocsService: OnboardingDocsService,
    private store: Store<State>,
    private alertService: AlertService,
    private router: Router) {

  }

  loadOnboardingDocInfo = createEffect(() => this.actions$.pipe(
    ofType(loadOnboardingDocInfo),
    switchMap(action => {
      return this.onboardingDocsService.getOnBoardingDocInfo(action.onboardingDocUserId).pipe(
        map(doc => loadOnboardingDocInfoSuccess({ doc })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  loadOnboardingDoc = createEffect(() => this.actions$.pipe(
    ofType(loadOnboardingDoc),
    switchMap(action => {
      return this.onboardingDocsService.getOnBoardingDoc(action.onboardingDocUserId).pipe(
        map(doc => loadOnboardingDocSuccess({ doc })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  signOnBoardingDoc = createEffect(() => this.actions$.pipe(
    ofType(signOnboardingDoc),
    switchMap(action => {
      return this.onboardingDocsService.signOnBoardingDoc(action.onboardingDocUserId, action.signedFields).pipe(
        map(docSigned => signOnboardingDocSuccess({ docSigned })),
        catchError(err => {
          this.store.dispatch(signOnboardingDocFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  getOnBoardingDocsList = createEffect(() => this.actions$.pipe(
    ofType(getOnboardingDocsList),
    switchMap(action => {
      return this.onboardingDocsService.getMyDocs(action.userId).pipe(
        map(docs => getOnboardingDocsListSuccess({ docs })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));


  setOnboardingDocsInfoFilled = createEffect(() => this.actions$.pipe(
    ofType(setOnboardingDocsInfoFilled),
    switchMap(action => {
      return this.onboardingDocsService.setFilledInfo(action.onboardingDocUserId).pipe(
        map(doc => setOnboardingDocsInfoFilledSuccess({ doc })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));


  getAllUserDocs = createEffect(() => this.actions$.pipe(
    ofType(getAllUserDocs),
    switchMap(action => {
      return this.onboardingDocsService.getAllUserDocs(action.filter).pipe(
        map(docs => getAllUserDocsSuccess({ docs })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));


  getActiveDocs = createEffect(() => this.actions$.pipe(
    ofType(getActiveDocs),
    switchMap(action => {
      return this.onboardingDocsService.getActiveDocs().pipe(
        map(docs => getActiveDocsSuccess({ docs })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));


  getUsersDocsReport = createEffect(() => this.actions$.pipe(
    ofType(getUsersDocsReport),
    switchMap(action => {
      return this.onboardingDocsService.getUsersDocsReport(action.filter).pipe(
        map(doc => getUsersDocsReportSuccess({ doc })),
        catchError(err => {
          this.store.dispatch(getUsersDocsReportFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  getUsersDocsReportExcel = createEffect(() => this.actions$.pipe(
    ofType(getUsersDocsReportExcel),
    switchMap(action => {
      return this.onboardingDocsService.getUsersDocsReportExcel(action.filter).pipe(
        map(doc => getUsersDocsReportExcelSuccess({ doc })),
        catchError(err => {
          this.store.dispatch(getUsersDocsReportExcelFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  getOnboardingDocs = createEffect(() => this.actions$.pipe(
    ofType(getOnboardingDocs),
    switchMap(action => {
      return this.onboardingDocsService.getAllDocs(action.filter).pipe(
        map(docs => getOnboardingDocsSuccess({ docs })),
        catchError(err => {
          this.store.dispatch(getOnboardingDocsFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));


  getOnboardingDoc = createEffect(() => this.actions$.pipe(
    ofType(getOnboardingDoc),
    switchMap(action => {
      return this.onboardingDocsService.getOnboardingDocWithRoles(action.id).pipe(
        map(doc => getOnboardingDocSuccess({ doc })),
        catchError(err => {
          this.store.dispatch(getOnboardingDocFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  updateOnboardingDoc = createEffect(() => this.actions$.pipe(
    ofType(updateOnboardingDoc),
    switchMap(action => {
      return this.onboardingDocsService.updateDoc(action.doc).pipe(
        map(doc => updateOnboardingDocSuccess({ doc })),
        catchError(err => {
          this.store.dispatch(updateOnboardingDocFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));


  getOnboardingUserDocs = createEffect(() => this.actions$.pipe(
    ofType(getOnboardingUserDocs),
    switchMap(action => {
      return this.onboardingDocsService.getUserDocs(action.filter).pipe(
        map(docs => getOnboardingUserDocsSuccess({ docs })),
        catchError(err => {
          this.store.dispatch(getOnboardingUserDocsFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));


  updateOnboardingUserDocForceNotRequired = createEffect(() => this.actions$.pipe(
    ofType(updateOnboardingUserDocForceNotRequired),
    switchMap(action => {
      return this.onboardingDocsService.updateUserDocForceRequired(action.model).pipe(
        map(doc => updateOnboardingUserDocForceNotRequiredSuccess({ doc })),
        catchError(err => {
          this.store.dispatch(updateOnboardingUserDocForceNotRequiredFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));


  resendOnboardingDocUserForce = createEffect(() => this.actions$.pipe(
    ofType(resendOnboardingDocUserForce),
    switchMap(action => {
      return this.onboardingDocsService.updateUserDocForceResend(action.model).pipe(
        map(doc => resendOnboardingDocUserForceSuccess({ doc })),
        catchError(err => {
          this.store.dispatch(resendOnboardingDocUserForceFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));
}
