import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SelectItem } from 'primeng/api';
import { environment } from '../../../environments/environment';
import { PagingResultsModel } from '../../models/pagingResultsModel';
import { deepClone } from '../../helpers/utils';
import { InternApplicationActionModel, InternApplicationActionTemplateModel, InternApplicationActionTypeModel, InternApplicationModel, InternReviewFormModel, InternshipSubstatusModel, InternUploadedFilesModel } from '../../models/internModels';
import { FBFormSubmissionModel } from '../../models/formBuilderModels';
import { ModifiedSelectItem } from '../../models/utilModels';

@Injectable({
  providedIn: 'root'
})
export class InternService {

  constructor(private http: HttpClient) {
  }

  getInternForms(): Observable<SelectItem[]> {
    return this.http.get<SelectItem[]>(`${environment.apiServer.webApiURL}/Intern/FormFilter`);
  }

  getInternshipStatuses(): Observable<ModifiedSelectItem[]> {
    return this.http.get<ModifiedSelectItem[]>(`${environment.apiServer.webApiURL}/Intern/Statuses`);
  }

  getInternshipSubstatuses(): Observable<ModifiedSelectItem[]> {
    return this.http.get<ModifiedSelectItem[]>(`${environment.apiServer.webApiURL}/Intern/Substatuses`);
  }

  getInternshipActionTypes(): Observable<SelectItem[]> {
    return this.http.get<SelectItem[]>(`${environment.apiServer.webApiURL}/Intern/ActionTypes`);
  }

  getMyApplicationsList(filter: any): Observable<PagingResultsModel<InternApplicationModel>> {
    if (!filter) {
      filter = {};
      filter.filters = {};
    }
    filter = deepClone(filter);

    return this.http.post<PagingResultsModel<InternApplicationModel>>(`${environment.apiServer.webApiURL}/Intern/MyApplicationsList`, filter);
  }

  getManageApplicationsList(filter: any): Observable<PagingResultsModel<InternApplicationModel>> {
    if (!filter) {
      filter = {};
      filter.filters = {};
    }
    filter = deepClone(filter);

    return this.http.post<PagingResultsModel<InternApplicationModel>>(`${environment.apiServer.webApiURL}/Intern/ManageApplicationsList`, filter);
  }

  getInternApplicationActionTypeList(filter: any): Observable<PagingResultsModel<InternApplicationActionTypeModel>> {
    if (!filter) {
      filter = {};
      filter.filters = {};
    }
    filter = deepClone(filter);

    return this.http.post<PagingResultsModel<InternApplicationActionTypeModel>>(`${environment.apiServer.webApiURL}/Intern/ActionTypeList`, filter);
  }

  getInternshipSubstatusList(filter: any): Observable<PagingResultsModel<InternshipSubstatusModel>> {
    if (!filter) {
      filter = {};
      filter.filters = {};
    }
    filter = deepClone(filter);

    return this.http.post<PagingResultsModel<InternshipSubstatusModel>>(`${environment.apiServer.webApiURL}/Intern/SubstatusList`, filter);
  }

  getInternActionItemTemplateList(filter: any): Observable<PagingResultsModel<InternApplicationActionTemplateModel>> {
    if (!filter) {
      filter = {};
      filter.filters = {};
    }
    filter = deepClone(filter);

    return this.http.post<PagingResultsModel<InternApplicationActionTemplateModel>>(`${environment.apiServer.webApiURL}/Intern/ActionItemTemplateList`, filter);
  }

  createInternshipApplication(formID: number) {
    return this.http.get<FBFormSubmissionModel>(`${environment.apiServer.webApiURL}/Intern/FormSubmission/${formID}`);
  }
  
  changeApplicationStatus(applicationId: number, statusId: number): Observable<any> {
    return this.http.post<any>(`${environment.apiServer.webApiURL}/Intern/ChangeApplicationStatus/${applicationId}/${statusId}`, { applicationId: applicationId, statusId: statusId });
  }

  changeApplicationSubstatus(applicationId: number, substatusId: number): Observable<any> {
    return this.http.post<any>(`${environment.apiServer.webApiURL}/Intern/ChangeApplicationSubstatus/${applicationId}/${substatusId}`, { applicationId: applicationId, substatusId: substatusId });
  }
  
  updateHourAndProductivityCalculations(applicationId: number): Observable<any> {
    return this.http.post<any>(`${environment.apiServer.webApiURL}/Intern/UpdateHourAndProductivityCalculations/${applicationId}`, { applicationId: applicationId });
  }

  exportManageInternApplicationsListToExcel(filter: any): Observable<ArrayBuffer> {
    return this.http.post<ArrayBuffer>(`${environment.apiServer.webApiURL}/Intern/ExportManageInternApplicationsListToExcel`, filter, { responseType: 'arraybuffer' as 'json' });
  }

  getInternActionType(actionTypeId: number): Observable<InternApplicationActionTypeModel> {
    return this.http.get<InternApplicationActionTypeModel>(`${environment.apiServer.webApiURL}/Intern/ActionType/${actionTypeId}`);
  }

  editInternActionType(model: InternApplicationActionTypeModel): Observable<any> {
    let formData = new FormData();
    Object.keys(model).forEach(function (key) {
      let value = model[key];
      if (Array.isArray(value)) {
        Object.keys(value).forEach(function (valueIndex) {
          Object.keys(value[valueIndex]).forEach(function (k) {
            let arrayValue = value[valueIndex][k];            
            formData.append(key + "[" + valueIndex + "]." + k, arrayValue);            
          });

        });
      } else {
        formData.append(key, model[key]);
      }
    });

    const options = { headers: new HttpHeaders() };
    options.headers.append('enctype', 'multipart/form-data');

    return this.http.put<InternApplicationActionTypeModel>(`${environment.apiServer.webApiURL}/Intern/ActionType/`, formData, options);
  }

  addInternActionType(model: InternApplicationActionTypeModel): Observable<any> {
    let formData = new FormData();
    Object.keys(model).forEach(function (key) {
      let value = model[key];
      if (Array.isArray(value)) {
        Object.keys(value).forEach(function (valueIndex) {
          
            Object.keys(value[valueIndex]).forEach(function (k) {
              let arrayValue = value[valueIndex][k];              
              formData.append(key + "[" + valueIndex + "]." + k, arrayValue);              
            });
          
        });
      } else {
        formData.append(key, model[key]);
      }
    });

    const options = { headers: new HttpHeaders() };
    options.headers.append('enctype', 'multipart/form-data');
    return this.http.post<InternApplicationActionTypeModel>(`${environment.apiServer.webApiURL}/Intern/ActionType`, formData, options);
  }

  deleteInternActionType(actionTypeId: number): Observable<any> {
    return this.http.delete<any>(`${environment.apiServer.webApiURL}/Intern/ActionType/${actionTypeId}`);
  }  

  getInternApplication(applicationId: number): Observable<InternApplicationModel> {
    return this.http.get<InternApplicationModel>(`${environment.apiServer.webApiURL}/Intern/InternApplication/${applicationId}`);
  }

  getInternApplicationReviewForms(applicationId: number): Observable<InternReviewFormModel[]> {
    return this.http.get<InternReviewFormModel[]>(`${environment.apiServer.webApiURL}/Intern/ApplicationReviewForms/${applicationId}`);
  }

  getInternAction(actionId: number): Observable<InternApplicationActionModel> {
    return this.http.get<InternApplicationActionModel>(`${environment.apiServer.webApiURL}/Intern/ActionItem/${actionId}`);
  }

  editInternAction(model: InternApplicationActionModel): Observable<any> {
    let formData = new FormData();
    Object.keys(model).forEach(function (key) {
      let value = model[key];
      if (Array.isArray(value)) {
        Object.keys(value).forEach(function (valueIndex) {
          Object.keys(value[valueIndex]).forEach(function (k) {
            let arrayValue = value[valueIndex][k];
            formData.append(key + "[" + valueIndex + "]." + k, arrayValue);
          });

        });
      } else {
        formData.append(key, model[key]);
      }
    });

    const options = { headers: new HttpHeaders() };
    options.headers.append('enctype', 'multipart/form-data');

    return this.http.put<InternApplicationActionModel>(`${environment.apiServer.webApiURL}/Intern/ActionItem/`, formData, options);
  }

  addInternAction(model: InternApplicationActionModel): Observable<any> {
    let formData = new FormData();
    Object.keys(model).forEach(function (key) {
      let value = model[key];
      if (Array.isArray(value)) {
        Object.keys(value).forEach(function (valueIndex) {

          Object.keys(value[valueIndex]).forEach(function (k) {
            let arrayValue = value[valueIndex][k];
            formData.append(key + "[" + valueIndex + "]." + k, arrayValue);
          });

        });
      } else {
        formData.append(key, model[key]);
      }
    });

    const options = { headers: new HttpHeaders() };
    options.headers.append('enctype', 'multipart/form-data');
    return this.http.post<InternApplicationActionModel>(`${environment.apiServer.webApiURL}/Intern/ActionItem`, formData, options);
  }

  deleteInternAction(actionId: number): Observable<any> {
    return this.http.delete<any>(`${environment.apiServer.webApiURL}/Intern/ActionItem/${actionId}`);
  }

  getInternUploads(applicationId: number): Observable<InternUploadedFilesModel> {
    return this.http.get<InternUploadedFilesModel>(`${environment.apiServer.webApiURL}/Intern/UploadedFiles/${applicationId}`);
  }

  editInternUploads(model: InternUploadedFilesModel): Observable<any> {
    let formData = new FormData();
    Object.keys(model).forEach(function (key) {
      let value = model[key];
      if (Array.isArray(value)) {
        Object.keys(value).forEach(function (valueIndex) {
          if (value[valueIndex] instanceof File) {
            formData.append(key + ".Index", valueIndex);
            formData.append(key + "[" + valueIndex + "]", value[valueIndex]);
          }
          else {
            Object.keys(value[valueIndex]).forEach(function (k) {
              let arrayValue = value[valueIndex][k];
              if (Array.isArray(arrayValue)) {
                Object.keys(arrayValue).forEach(function (arrayValueIndex) {
                  Object.keys(arrayValue[arrayValueIndex]).forEach(function (kk) {
                    let arrayArrayValue = arrayValue[arrayValueIndex][kk]
                    formData.append(key + "[" + valueIndex + "]." + k + "[" + arrayValueIndex + "]." + kk, arrayArrayValue);
                  });
                });
              } else {
                formData.append(key + "[" + valueIndex + "]." + k, arrayValue);
              }
            });
          }
        });
      } else {
        formData.append(key, model[key]);
      }
    });

    const options = { headers: new HttpHeaders() };
    options.headers.append('enctype', 'multipart/form-data');

    return this.http.put<InternUploadedFilesModel>(`${environment.apiServer.webApiURL}/Intern/UploadedFiles/`, formData, options);
  }
  
  deleteUploadedFile(uploadId: number): Observable<any> {
    return this.http.delete<any>(`${environment.apiServer.webApiURL}/Intern/UploadedFile/${uploadId}`);
  }

  getInternApplicationUpload(uploadId: number): Observable<ArrayBuffer> {
    return this.http.get<ArrayBuffer>(`${environment.apiServer.webApiURL}/Intern/UploadedFile/${uploadId}`,
      { responseType: 'arraybuffer' as 'json' });
  }

  getInternshipSubstatus(substatusId: number): Observable<InternshipSubstatusModel> {
    return this.http.get<InternshipSubstatusModel>(`${environment.apiServer.webApiURL}/Intern/Substatus/${substatusId}`);
  }

  editInternshipSubstatus(model: InternshipSubstatusModel): Observable<any> {
    let formData = new FormData();
    Object.keys(model).forEach(function (key) {
      let value = model[key];
      if (Array.isArray(value)) {
        Object.keys(value).forEach(function (valueIndex) {
          Object.keys(value[valueIndex]).forEach(function (k) {
            let arrayValue = value[valueIndex][k];
            formData.append(key + "[" + valueIndex + "]." + k, arrayValue);
          });

        });
      } else {
        formData.append(key, model[key]);
      }
    });

    const options = { headers: new HttpHeaders() };
    options.headers.append('enctype', 'multipart/form-data');

    return this.http.put<InternshipSubstatusModel>(`${environment.apiServer.webApiURL}/Intern/Substatus/`, formData, options);
  }

  addInternshipSubstatus(model: InternshipSubstatusModel): Observable<any> {
    let formData = new FormData();
    Object.keys(model).forEach(function (key) {
      let value = model[key];
      if (Array.isArray(value)) {
        Object.keys(value).forEach(function (valueIndex) {

          Object.keys(value[valueIndex]).forEach(function (k) {
            let arrayValue = value[valueIndex][k];
            formData.append(key + "[" + valueIndex + "]." + k, arrayValue);
          });

        });
      } else {
        formData.append(key, model[key]);
      }
    });

    const options = { headers: new HttpHeaders() };
    options.headers.append('enctype', 'multipart/form-data');
    return this.http.post<InternshipSubstatusModel>(`${environment.apiServer.webApiURL}/Intern/Substatus`, formData, options);
  }

  deleteInternshipSubstatus(substatusId: number): Observable<any> {
    return this.http.delete<any>(`${environment.apiServer.webApiURL}/Intern/Substatus/${substatusId}`);
  }


  getInternActionItemTemplate(templateId: number): Observable<InternApplicationActionTemplateModel> {
    return this.http.get<InternApplicationActionTemplateModel>(`${environment.apiServer.webApiURL}/Intern/ActionItemTemplate/${templateId}`);
  }

  editInternActionItemTemplate(model: InternApplicationActionTemplateModel): Observable<any> {
    let formData = new FormData();
    Object.keys(model).forEach(function (key) {
      let value = model[key];
      if (Array.isArray(value)) {
        Object.keys(value).forEach(function (valueIndex) {
          Object.keys(value[valueIndex]).forEach(function (k) {
            let arrayValue = value[valueIndex][k];
            formData.append(key + "[" + valueIndex + "]." + k, arrayValue);
          });

        });
      } else {
        formData.append(key, model[key]);
      }
    });

    const options = { headers: new HttpHeaders() };
    options.headers.append('enctype', 'multipart/form-data');

    return this.http.put<InternApplicationActionTemplateModel>(`${environment.apiServer.webApiURL}/Intern/ActionItemTemplate/`, formData, options);
  }

  addInternActionItemTemplate(model: InternApplicationActionTemplateModel): Observable<any> {
    let formData = new FormData();
    Object.keys(model).forEach(function (key) {
      let value = model[key];
      if (Array.isArray(value)) {
        Object.keys(value).forEach(function (valueIndex) {

          Object.keys(value[valueIndex]).forEach(function (k) {
            let arrayValue = value[valueIndex][k];
            formData.append(key + "[" + valueIndex + "]." + k, arrayValue);
          });

        });
      } else {
        formData.append(key, model[key]);
      }
    });

    const options = { headers: new HttpHeaders() };
    options.headers.append('enctype', 'multipart/form-data');
    return this.http.post<InternApplicationActionTemplateModel>(`${environment.apiServer.webApiURL}/Intern/ActionItemTemplate`, formData, options);
  }

  deleteInternActionItemTemplate(templateId: number): Observable<any> {
    return this.http.delete<any>(`${environment.apiServer.webApiURL}/Intern/ActionItemTemplate/${templateId}`);
  }

  saveApplicationReview(application: InternApplicationModel) : Observable<any> {
    return this.http.put<InternApplicationModel>(`${environment.apiServer.webApiURL}/Intern/SaveApplicationReview/${application.internApplicationId}`, application);
  }

  createInternship(applicationId: number): Observable<any> {
    return this.http.post<any>(`${environment.apiServer.webApiURL}/Intern/CreateInternship/${applicationId}`, { applicationId: applicationId });
  }

  getInternApplicationDocToSign(documentToSignId: number): Observable<ArrayBuffer> {
    return this.http.get<ArrayBuffer>(`${environment.apiServer.webApiURL}/Intern/InternApplicationDocumentToSign/${documentToSignId}`,
      { responseType: 'arraybuffer' as 'json' });
  }
}
