import { SelectItem } from 'primeng/api';
import { CommonModule } from '@angular/common';
import { OnBoardingUserModel } from 'src/app/models/onBoardingModels';
import { loadManageOnboardingUserInfo, saveUserProfile, updateUserProfileForm } from './../../../../core/store/onboarding/onboarding.actions';
import { updateProfileForm, loadRaceOptions, loadProfileOptions, saveProfileSuccess, loadProfile, loadSpokenLanguageOptions } from './../../../../core/store/profile/profile.actions';
import { UserProfileModel } from './../../../../models/userProfileModel';
import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { saveProfile } from '../../../../core/store/profile/profile.actions';
import { FormGroup } from '@angular/forms';
import { initProfileForm } from 'src/app/core/store/profile/profile.reducer';
import { getAllControlsErrors, markFormGroupTouched } from 'src/app/helpers/utils';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss']
})
export class ContactInfoComponent extends BaseComponent implements OnInit {

  profile: UserProfileModel;
  form: FormGroup;
  hasOnBoardingPermission: boolean;
  savingProfile: boolean;
  @Input() managedUserId;
  @Input() readOnly = false;
  manageOnboardingUserInfo: OnBoardingUserModel;
  paylocityEnabled: boolean = false;

  maritalStatusOptions: SelectItem[] = [
    { label: 'I have a disability (or previouslyhad a disability)', value: 1 },];
  statutoryClassOptions: SelectItem[] = [
    { label: 'I have a disability (or previouslyhad a disability)', value: 1 },];
  eeoJobCategoryOptions: SelectItem[] = [
    { label: 'I have a disability (or previouslyhad a disability)', value: 1 },];
  acaClassificationOptions: SelectItem[] = [
    { label: 'I have a disability (or previouslyhad a disability)', value: 1 },];

  disabilityOptions: SelectItem[] = [
    { label: 'I have a disability (or previously had a disability)', value: 1 },
    { label: 'I do NOT have a disability', value: 0 },
    { label: 'I decline to state my disability status', value: 2 }];

  veteranOptions: SelectItem[] = [
    { label: 'I am a veteran', value: 1 },
    { label: 'I am NOT a veteran', value: 0 },
    { label: 'I decline to state my veteran status', value: 2 }];

  raceOptions: SelectItem[] = [
    { label: 'loading...', value: null }];

  spokenLanguageOptions: SelectItem[] = [
    { label: 'loading...', value: null }];

  genderOptions: SelectItem[] = [
    { label: 'Male', value: 1 },
    { label: 'Female', value: 0 }];

  yesNoOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  backTo: string;

  constructor(
    private actions$: Actions,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<State>) {
    super();
  }

  ngOnInit() {
    this.route.queryParams.subscribe(queryParams => {
      this.backTo = '';
      if (queryParams.backTo) {
        this.backTo = queryParams.backTo;
      }
    });
    this.subs.push(
      this.store.select(s => s.accountState.featureSwitchesIds).subscribe(featureSwitchesIds => {
        this.paylocityEnabled = featureSwitchesIds && !!featureSwitchesIds.find(p => p === 85);       
      }),
    );
    if (this.managedUserId) {
      //this.readOnly = true;
      this.subs.push(
        this.store.select(s => s.onboardingState.manageOnboardingUserInfo).subscribe(manageOnboardingUserInfo => {
          if (manageOnboardingUserInfo) {
            this.profile = manageOnboardingUserInfo.user;
            this.manageOnboardingUserInfo = manageOnboardingUserInfo;
            this.form = initProfileForm(this.profile);
            this.subs.push(
              this.form.valueChanges.subscribe(formValues => {
                const userValues = this.form.get('user').value;
                const employeeValues = this.form.get('employee').value;
                const formErrors = getAllControlsErrors(this.form);
                this.store.dispatch(updateUserProfileForm({ userValues, employeeValues, formErrors }));
              })
            );
          }
        }),        
      );

      this.store.dispatch(loadManageOnboardingUserInfo({ userId: this.managedUserId }));
    } else {
      this.subs.push(
        this.store.select(s => s.profileState).subscribe(profileState => {
          this.profile = JSON.parse(JSON.stringify(profileState.profile));
          if (!this.form) {
            this.form = initProfileForm(this.profile);
            this.subs.push(
              this.form.valueChanges.subscribe(formValues => {
                const userValues = this.form.get('user').value;
                const employeeValues = this.form.get('employee').value;
                const formErrors = getAllControlsErrors(this.form);
                this.store.dispatch(updateProfileForm({ userValues, employeeValues, formErrors }));
              })
            );
          }
        }),

        this.store.select(s => s.profileState.savingProfile).subscribe(savingProfile => {
          this.savingProfile = savingProfile;
        })
      );
      this.store.dispatch(loadProfile());
    }
    this.subs.push(
      this.store.select(s => s.profileState.raceOptions).subscribe(raceOptions => {
        const raceOptionsMapped = raceOptions && raceOptions.length > 0 ? raceOptions.map(c => ({ label: c.label, value: c.id }))
          : [{ label: 'loading...', value: undefined }];

        this.raceOptions = raceOptionsMapped;

      }),

      this.store.select(s => s.profileState.spokenLanguageOptions).subscribe(options => {
        const optionsMapped = options && options.length > 0 ? options.map(c => ({ label: c.language, value: c.id }))
          : [{ label: 'loading...', value: undefined }];

        this.spokenLanguageOptions = optionsMapped;

      }),

      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.hasOnBoardingPermission = permissionIDs && !!permissionIDs.find(p => p === 3060 //manage on board
        );
      }),

      this.store.select(s => s.profileState.maritalStatusOptions).subscribe(maritalStatusOptions => {
        const maritalStatusOptionsMapped = maritalStatusOptions && maritalStatusOptions.length > 0 ? maritalStatusOptions.map(c => ({ label: c.maritalStatus, value: c.id }))
          : [{ label: 'loading...', value: undefined }];
        this.maritalStatusOptions = maritalStatusOptionsMapped;
      }),
      this.store.select(s => s.profileState.statutoryClassOptions).subscribe(statutoryClassOptions => {
        const statutoryClassOptionsMapped = statutoryClassOptions && statutoryClassOptions.length > 0 ? statutoryClassOptions.map(c => ({ label: c.statutoryClass, value: c.id }))
          : [{ label: 'loading...', value: undefined }];
        this.statutoryClassOptions = statutoryClassOptionsMapped;
      }),
      this.store.select(s => s.profileState.eeoJobCategoryOptions).subscribe(eeoJobCategoryOptions => {
        const eeoJobCategoryOptionsMapped = eeoJobCategoryOptions && eeoJobCategoryOptions.length > 0 ? eeoJobCategoryOptions.map(c => ({ label: c.eeoJobCategory, value: c.id }))
          : [{ label: 'loading...', value: undefined }];
        this.eeoJobCategoryOptions = eeoJobCategoryOptionsMapped;
      }),
      this.store.select(s => s.profileState.acaClassificationOptions).subscribe(acaClassificationOptions => {
        const acaClassificationOptionsMapped = acaClassificationOptions && acaClassificationOptions.length > 0 ? acaClassificationOptions.map(c => ({ label: c.acaClassification, value: c.id }))
          : [{ label: 'loading...', value: undefined }];
        this.acaClassificationOptions = acaClassificationOptionsMapped;
      }),


      this.actions$.pipe(
        ofType(saveProfileSuccess),
        tap(action => {
          if (this.backTo) {
            this.router.navigate([this.backTo]);
          }
        })
      ).subscribe(),
    );

    this.store.dispatch(loadRaceOptions());
    this.store.dispatch(loadProfileOptions());
    this.store.dispatch(loadSpokenLanguageOptions());
  }

  get differentGenderIdentity() {
    return this.form.get('employee.differentGenderIdentity').value;
  }

  save() {
    if (this.form.valid) {
      if (this.managedUserId) {
        this.store.dispatch(saveUserProfile({ userId: this.managedUserId, profile: this.profile }));
      } else {
        this.store.dispatch(saveProfile({ profile: this.profile }));
      }
    } else {
      markFormGroupTouched(this.form);
    }
  }

  copyFromHomeAddress() {
    this.form.get('employee.mailingAddress').setValue(this.form.get('employee.address').value);
    this.form.get('employee.mailingAddressExtra').setValue(this.form.get('employee.addressExtra').value);
    this.form.get('employee.mailingCity').setValue(this.form.get('employee.city').value);
    this.form.get('employee.mailingState').setValue(this.form.get('employee.state').value);
    this.form.get('employee.mailingZipCode').setValue(this.form.get('employee.zipCode').value);
    this.form.get('employee.mailingCounty').setValue(this.form.get('employee.county').value);
    this.form.get('employee.mailingCountry').setValue(this.form.get('employee.country').value);
  }

  addOtherLanguage() {
    if (typeof this.profile.otherLanguages === 'undefined') {
      this.profile.otherLanguages = [];
    }

    this.profile.otherLanguages.push({
      id: 0,
      spokenLanguageId: 0,
      userId: 0,
      moreInfo: ""
    });
  }

  removeLanguage(index) {
    if (index >= 0 && index < this.profile.otherLanguages.length) {
      this.profile.otherLanguages.splice(index, 1);
    }
  }

}

