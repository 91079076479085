import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { Actions, ofType } from '@ngrx/effects';
import { State } from '../../../../core/store';
import { Store } from '@ngrx/store';
import { SelectItem } from 'primeng/api';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { loadEmployeeUsers, loadViewableCampuses } from '../../../../core/store/employee/employee.action';
import { deepClone, downloadFile } from '../../../../helpers/utils';
import { deleteCertification, deleteCertificationSuccess, exportCertificationListToExcel, exportCertificationListToExcelSuccess, getCertificationList, getCertificationPdf, loadCertificationTypes, loadInstructors, saveCertificationSuccess, showEditCertification } from '../../../../core/store/certification/certification.action';
import { CertificationReportModel } from '../../../../models/certificationModel';
import { DateRangeModel } from '../../../../models/dateRangeModel';
import { DropdownFilterService } from '../../../../core/services/dropdown-filter.service';
import { tap } from 'rxjs/operators';
import { AlertService } from '../../../../shared/services/alert.service';

@Component({
  selector: 'app-list-certification',
  templateUrl: './list-certification.component.html',
  styleUrls: ['./list-certification.component.scss']
})
export class ListCertificationComponent extends BaseComponent implements OnInit {
  list: PagingResultsModel<CertificationReportModel>;
  filter: any;

  loadingList: boolean = true;
  loadingExcel: boolean = false;

  campusList: SelectItem[];
  campusId: number = 0;

  prefixText: string;
  userEmployeeList: SelectItem[];
  userId: number = 0;

  instructorPrefixText: string;
  instructorList: SelectItem[];
  instructorId: number = 0;
  
  certificationTypes: SelectItem[];
  certificationTypeId: number = 0;

  issueDateRangeList: SelectItem[];
  issueDateRange: DateRangeModel;
  issueStartDate: string = null;
  issueEndDate: string = null;
  isCustomIssueDateRange: boolean = false;

  expirationDateRangeList: SelectItem[];
  expirationDateRange: DateRangeModel;
  expirationStartDate: string = null;
  expirationEndDate: string = null;
  isCustomExpirationDateRange: boolean = false;

  showOnlyActiveEmployees: boolean = true;
  showOnlyActiveInstructors: boolean = true;
  canManage;

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private dropdownFilterService: DropdownFilterService,
    private alertService: AlertService
  ) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.certificationState.loadingCertificationList).subscribe(loading => {
        this.loadingList = loading;
      }),
      this.store.select(s => s.certificationState.loadingCertificationExcel).subscribe(loading => {
        this.loadingExcel = loading;
      }),
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.canManage = permissionIDs && !!permissionIDs.find(p => p === 3112); //Manage Certifications
      }),
      this.store.select(s => s.employeeState.campuses).subscribe(campuses => {
        this.campusList = campuses && campuses.length > 0 ? campuses
          : [{ label: 'loading...', value: undefined }];
      }),
      this.store.select(s => s.certificationState.certificationTypes).subscribe(types => {
        if (types && types.length > 0) {
          this.certificationTypes = types.map(x => ({ label: x.label, value: x.value }));
          this.certificationTypes.unshift({ label: 'All', value: 0 });
        }
        else {
          this.certificationTypes = [{ label: 'loading...', value: undefined }]
        }
      }),
      this.store.select(s => s.employeeState.employeeUsers).subscribe(users => {
        this.userEmployeeList = users;
      }),
      this.store.select(s => s.certificationState.instructors).subscribe(instructors => {
        this.instructorList = instructors;
      }),
      this.store.select(s => s.certificationState.certificationList).subscribe(report => {
        this.list = report;
      }),
      this.actions$.pipe(
        ofType(saveCertificationSuccess),
        tap(action => {
          this.reloadList();
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(deleteCertificationSuccess),
        tap(action => {
          this.reloadList();
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(exportCertificationListToExcelSuccess),
        tap(action => {
          downloadFile(action.doc, 'CertificationReport.xlsx');
        })
      ).subscribe()
    );

    this.issueDateRangeList = this.dropdownFilterService.getLast120DayDateRanges().map(c => ({ label: c.text, value: c }));
    this.expirationDateRangeList = this.dropdownFilterService.get30DayDateRanges().map(c => ({ label: c.text, value: c }));
    this.store.dispatch(loadViewableCampuses());
    this.store.dispatch(loadCertificationTypes());
  }

  changedIssueDateRange() {
    if (this.issueDateRange.isCustom) {
      this.isCustomIssueDateRange = true;
      return; //reload after manually changing date
    }

    this.isCustomIssueDateRange = false;
    this.issueStartDate = this.issueDateRange.startDate;
    this.issueEndDate = this.issueDateRange.endDate;
    this.reloadList();
  }

  changedExpirationDateRange() {
    if (this.expirationDateRange.isCustom) {
      this.isCustomExpirationDateRange = true;
      return; //reload after manually changing date
    }

    this.isCustomExpirationDateRange = false;
    this.expirationStartDate = this.expirationDateRange.startDate;
    this.expirationEndDate = this.expirationDateRange.endDate;
    this.reloadList();
  }

  reloadFilter(event) {
    this.filter = event;
    this.reloadList();
  }

  reloadList() {
    this.setFilter();

    this.store.dispatch(getCertificationList({
      filter: this.filter
    }));
  }

  getStaffList(event) {
    this.store.dispatch(loadEmployeeUsers({
      prefixText: event.query,
      programId: this.campusId,
      payrollClassificationId: null,
      showTerminated: !this.showOnlyActiveEmployees,
      showInactive: !this.showOnlyActiveEmployees
    }));
  }

  getInstructorList(event) {
    this.store.dispatch(loadInstructors({
      prefixText: event.query,
      certificationTypeId: this.certificationTypeId,
      showArchived: true,
      showTerminated: true,
      showInactive: true
    }));
  }

  reloadListAfterStaffChange(selectedUser) {
    if (typeof selectedUser.value !== 'number') {
      return;
    }

    this.userId = selectedUser.value;
    this.reloadList();
  }

  reloadListAfterClear(event) {
    if (event.data !== null) {
      return;
    }

    this.userId = 0;
    this.reloadList();
  }

  reloadListAfterInstructorChange(selectedInstructor) {
    if (typeof selectedInstructor.value !== 'number') {
      return;
    }

    this.instructorId = selectedInstructor.value;
    this.reloadList();
  }

  reloadListAfterInstructorClear(event) {
    if (event.data !== null) {
      return;
    }

    this.instructorId = 0;
    this.reloadList();
  }

  setFilter() {
    this.filter = deepClone(this.filter);
    this.filter.filters.userId = { Name: 'userId', Value: { value: this.userId.toString() } };
    this.filter.filters.instructorId = { Name: 'instructorId', Value: { value: this.instructorId.toString() } };
    this.filter.filters.programId = { Name: 'programId', Value: { value: this.campusId.toString() } };
    this.filter.filters.certificationTypeId = { Name: 'certificationTypeId', Value: { value: this.certificationTypeId } };
    this.filter.filters.showOnlyActiveEmployees = { Name: 'showOnlyActiveEmployees', Value: { value: this.showOnlyActiveEmployees } };
    this.filter.filters.issueStartDate = { Name: 'issueStartDate', Value: { value: this.issueStartDate } };
    this.filter.filters.issueEndDate = { Name: 'issueEndDate', Value: { value: this.issueEndDate } };
    this.filter.filters.expirationStartDate = { Name: 'expirationStartDate', Value: { value: this.expirationStartDate } };
    this.filter.filters.expirationEndDate = { Name: 'expirationEndDate', Value: { value: this.expirationEndDate } };
  }

  exportToExcel() {
    if (this.loadingList || this.loadingExcel) {
      return;
    }
    this.setFilter();

    this.store.dispatch(exportCertificationListToExcel({
      filter: this.filter
    }));
  }

  getTerminationString(list) {
    if (list.terminationDate != null) {
      return "(Separated)";
    }

    if (list.inactive) {
      return "(Inactive)"
    }

    return "";
  }

  editCertification(cert) {
    this.store.dispatch(showEditCertification({ certification: cert }));
  }

  downloadCertification(cert: CertificationReportModel) {
    this.store.dispatch(getCertificationPdf({ certificationId: cert.certificationId }));
  }

  deleteCertification(list: CertificationReportModel) {
    this.alertService.confirm('Delete Certification',
      'Are you sure you want to delete this certification?')
      .subscribe(
        answer => {
          if (answer) {
            this.store.dispatch(deleteCertification({ certificationId: list.certificationId }));
          }
        }, error => {
        }
      );
  }
}
