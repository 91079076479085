<form class="card mt-2">
  <div class="card-body">
    <div class="float-right">
      <button type="button" routerLink="/employee/terminated" class="btn btn-secondary">Separation Report</button>
    </div>
    <h2 class="mb-3">Census Report</h2>
    <div class="mb-3">

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Campus:</h5>
        </div>
        <div class="col-10">
          <i *ngIf="!campusList" class="material-icons rotate text-muted">loop</i>
          <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="campusList" [(ngModel)]="campusId" (ngModelChange)="reloadList(this.filter)" [ngModelOptions]="{standalone: true}"></p-dropdown>
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Employee:</h5>
        </div>
        <div class="col-10">
          <div class="ml-2 w-100 mb-2">
            <p-autoComplete [style]="{'width': '100%'}" [inputStyle]="{'width': '100%'}" [(ngModel)]="prefixText"
                            [suggestions]="userEmployeeList" (completeMethod)="getStaffList($event)" field="label"
                            [ngModelOptions]="{standalone: true}" [minLength]="1" [autoHighlight]="true"
                            (onSelect)="reloadListAfterStaffChage($event)" [forceSelection]="true"
                            (onClear)="reloadListAfterClear($event)"></p-autoComplete>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Payroll ID:</h5>
        </div>
        <div class="col-10">
          <input type="text" class="form-control ml-2 w-100 mb-2" placeholder="All" [(ngModel)]="employeePayrollNumber" (ngModelChange)="reloadList(this.filter)" [ngModelOptions]="{standalone: true}" />
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Date of Birth:</h5>
        </div>
        <div class="col-10">
          <input type="date" class="form-control ml-2 w-100 mb-2" [(ngModel)]="birthdate" (blur)="reloadList(this.filter)" [ngModelOptions]="{standalone: true}" />
        </div>       
      </div>
      
      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Include Separated Employees:</h5>
        </div>
        <div class="col-10 " style="padding-left: 25px;">
          <p-checkbox [(ngModel)]="showTerminatedEmployees" (ngModelChange)="reloadList(this.filter)" [binary]="true" [ngModelOptions]="{standalone: true}"></p-checkbox>
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Include Inactive Users:</h5>
        </div>
        <div class="col-10 " style="padding-left: 25px;">
          <p-checkbox [(ngModel)]="showInactiveUsers" (ngModelChange)="reloadList(this.filter)" [binary]="true" [ngModelOptions]="{standalone: true}"></p-checkbox>
        </div>
      </div>

    </div>

    <i [hidden]="!loadingCensusList && !loadingCensusExcel" class="material-icons rotate text-muted">loop</i>
    <button type="button" title="Export all pages to excel" class="btn btn-success mr-2 mb-2" (click)="exportToExcel()"><i class="far fa-file-excel"></i></button>
    <p-table [value]="list?.result" [responsive]="true" [paginator]="true" [rows]="20"
             [totalRecords]="list?.totalRecords" [lazy]="true" (onLazyLoad)="reloadList($event)">
      <ng-template pTemplate="header">
        <tr>
          <th width="100px"></th>
          <th>Employee SSN</th>
          <th [pSortableColumn]="'ProviderNPI'">Provider NPI <p-sortIcon [field]="'ProviderNPI'"></p-sortIcon></th>
          <th [pSortableColumn]="'LastName'">Legal Last Name <p-sortIcon [field]="'LastName'"></p-sortIcon></th>
          <th [pSortableColumn]="'FirstName'">Legal First Name <p-sortIcon [field]="'FirstName'"></p-sortIcon></th>
          <th [pSortableColumn]="'Birthdate'">Date of Birth <p-sortIcon [field]="'Birthdate'"></p-sortIcon></th>
          <th [pSortableColumn]="'Gender'">Gender <p-sortIcon [field]="'Gender'"></p-sortIcon></th>
          <th [pSortableColumn]="'HireDate'">Hire Date <p-sortIcon [field]="'HireDate'"></p-sortIcon></th>
          <th [pSortableColumn]="'Class'">Class <p-sortIcon [field]="'Class'"></p-sortIcon></th>
          <th [pSortableColumn]="'PayrollID'">Payroll ID <p-sortIcon [field]="'PayrollID'"></p-sortIcon></th>
          <th [pSortableColumn]="'TerminationDate'">Separation Date <p-sortIcon [field]="'TerminationDate'"></p-sortIcon></th>
          <th [pSortableColumn]="'TerminationReason'">Separation Reason <p-sortIcon [field]="'TerminationReason'"></p-sortIcon></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-list>
        <tr>
          <td>
            <button class="btn btn-secondary mr-2" *ngIf="canEditUser" (click)="editEmployee(list)" title="Edit Employee Info">
              <i class="fas fa-edit"></i>
            </button>
          </td>
          <td>
            <span class="ui-column-title">Employee</span>
            {{list.employeeSSN}}
          </td>
          <td>
            <span class="ui-column-title">Provider NPI</span>
            {{list.providerNPI}}
          </td>
          <td>
            <span class="ui-column-title">Legal Last Name</span>
            {{list.lastName}}
          </td>
          <td>
            <span class="ui-column-title">Legal First Name</span>
            {{list.firstName}}
          </td>
          <td>
            <span class="ui-column-title">Date of Birth</span>
            {{(list.birthdate !== null) ? (list.birthdate | date: 'MM/dd/yyyy') : ""}}
          </td>
          <td>
            <span class="ui-column-title">Gender</span>
            {{list.gender}}
          </td>
          <td>
            <span class="ui-column-title">Hire Date</span>
            {{(list.hireDate !== null) ? (list.hireDate | date: 'MM/dd/yyyy') : ""}}
          </td>
          <td>
            <span class="ui-column-title">Class</span>
            {{list.class}}
          </td>
          <td>
            <span class="ui-column-title">Payroll ID</span>
            {{list.payrollId}}
          </td>
          <td>
            <span class="ui-column-title">Separation Date</span>
            {{list.terminationDate | date: 'MM/dd/yyyy'}}
          </td>
          <td>
            <span class="ui-column-title">Separation Reason</span>
            {{list.terminationReason}}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</form>
