import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'src/app/core/store';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { uploadOnboardingDoc } from 'src/app/core/store/onboarding/onboarding.actions';
import { Actions } from '@ngrx/effects';
import { AlertService } from 'src/app/shared/services/alert.service';
@Component({
  selector: 'app-doc-preview',
  templateUrl: './doc-preview.component.html',
  styleUrls: ['./doc-preview.component.scss']
})
export class DocPreviewComponent extends BaseComponent implements OnInit {

  @Input() docTypeID: number;
  @Input() docType: string;
  @Input() userId: number;
  @Input() visibleToUser: {};
  @Input() managedUserId: number;
  @Input() uploadTypeId: number;
  @Output() closeModal = new EventEmitter<string>();
  @Output() uploadFiles = new EventEmitter<{files: File[], docType: string, id: number}>();

  @ViewChild('fileInput') fileInput: ElementRef;

  uploadedFiles: {id: number, file: File}[];
  files: File[];

  filesDisplay: {id: number, file: File}[];
  imageDisplay: {id: number, fileName: string, fileType: string, url: string}[];
  newFilesDynamicIndex: number = 0;
  base64Response: any;


  constructor(
    private actions$: Actions,
    private alertService: AlertService,
    private store: Store<State>,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    super();

  }
  ngOnInit(): void {
  }

  changedFile(fileInput) {
    let files = Object.assign([], fileInput.files)
    let images = [];
    let othFiles = [];
    this.filesDisplay = [];
    this.imageDisplay = [];
    for (var file of files){
      if(file.type.includes("image")){
        images.push(file)
      }
      else {
        othFiles.push(file);
      }
    }
    for (let file of othFiles) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        this.filesDisplay.push({id: this.newFilesDynamicIndex, file: file});
        this.uploadedFiles = Object.assign([], this.uploadedFiles);
        this.uploadedFiles.push({id: this.newFilesDynamicIndex, file: file});
        this.newFilesDynamicIndex = this.newFilesDynamicIndex + 1;
      }
      reader.readAsDataURL(file);
    }
    for (let file of images) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        this.imageDisplay.push({id: this.newFilesDynamicIndex, fileName: file.name, fileType: file.type, url: e.target.result});
        this.uploadedFiles = Object.assign([], this.uploadedFiles);
        this.uploadedFiles.push({id: this.newFilesDynamicIndex, file: file});
        this.newFilesDynamicIndex = this.newFilesDynamicIndex + 1;
      }
      reader.readAsDataURL(file);
    }
  }

  processFile(file): void {
    let fr = new FileReader();
    fr.readAsDataURL(file.file);
    var blob;
    if (file.file.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'){
      blob = new Blob([file.file], { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" });
    }
    else{
      blob = new Blob([file.file], { type: "application/pdf" });
    }
    var objectURL = window.URL.createObjectURL(blob);
    window.open(objectURL, '_blank');
  }

  dowloadImage(url){
    window.open(url, "_blank")
  }

  removeAddedFile(e, file){
    this.imageDisplay = Array.from(this.imageDisplay).filter( function(x){ 
      if (x.id != file.id){
        return x ;
      }
    });
    this.filesDisplay = Array.from(this.filesDisplay).filter( function(x){ 
      if (x.id != file.id){
        return x ;
      }
    });
    this.uploadedFiles = Array.from(this.uploadedFiles).filter( function(x){ 
      if (x.id != file.id){
        return x ;
      }
    });
    e.stopPropagation();
  }

  close(){
    this.closeModal.emit('close');
  }

  upload(){
    if (this.uploadedFiles == null || this.uploadedFiles.length < 1){
      this.alertService.error("Be sure to select files to upload!")
      return;
    }
    this.files = this.uploadedFiles.map(x => {
      return x.file;
    })

    this.uploadFiles.emit({files: this.files, docType: this.docType ,id: this.docTypeID});
    this.close();
  }
}
