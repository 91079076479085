import { FormGroup, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { createReducer, on, Action } from '@ngrx/store';
import { FormError } from 'src/app/models/utilModels';
import { ManualEntryReasonModel, TimeCardModel } from 'src/app/models/timeCardModel';
import { PayPeriodModel } from 'src/app/models/payPeriodModel';
import { loadTimeCardsSuccess, loadClientsSuccess, getIsClockedInSuccess, selectTimeCardToAdd, updateTimeCardForm, checkIPSuccess, loadTimeCards, loadClients, clockInOut, clockInOutSuccess, selectTimeCardToFix, deleteTimeCardSuccess, deleteTimeCard, loadManualEntryReasonsSuccess, getManualEntriesReportSuccess, exportManualEntriesReportToExcel, exportManualEntriesReportToExcelSuccess, exportManualEntriesReportToExcelFailure, getManualEntriesReport, getManualEntriesReportFailure, loadOptionalPayableTypesSuccess } from './timecard.actions';
import { ClientSimpleModel } from 'src/app/models/clientSimpleModel';
import { getTimeDiff, getTimeDiffMinutes, getTimeFormatted, deepClone } from 'src/app/helpers/utils';
import { ValidatorRequiredIfBool } from 'src/app/helpers/validators';
import * as moment from 'moment';
import { PagingResultsModel } from '../../../models/pagingResultsModel';
import { SelectItem } from 'primeng/api';

export interface TimeCardState {
  timeCards: TimeCardModel[];
  totalTime: string;
  periods: PayPeriodModel[];
  clients: ClientSimpleModel[];
  selectedPeriodID: number;
  isClockledIn: boolean;
  editingTimeCard: TimeCardModel;
  formErrors: FormError[];
  ipValid: boolean;
  clockInOutLoading: boolean;
  fixingTimeCard: TimeCardModel;
  manualEntryReasons: ManualEntryReasonModel[];
  manualEntriesList: PagingResultsModel<TimeCardModel>;
  loadingManualEntriesReport: boolean;
  manualEntriesReportArrayBuffer: ArrayBuffer;
  optionalPayableTypes: SelectItem[];
}

const initialState: TimeCardState = {
  timeCards: null,
  totalTime: '',
  periods: null,
  clients: null,
  selectedPeriodID: 0,
  editingTimeCard: null,
  formErrors: [],
  isClockledIn: false,
  clockInOutLoading: false,
  ipValid: false,
  fixingTimeCard: null,
  manualEntryReasons: null,
  manualEntriesList: null,
  loadingManualEntriesReport: true,
  manualEntriesReportArrayBuffer: null,
  optionalPayableTypes: []
};

export const timeCardReducer = createReducer(initialState,
  on(loadOptionalPayableTypesSuccess, (state, action) => {
    return {
      ...state,
      optionalPayableTypes: action.optionalPayableTypes,
    };
  }),
  on(loadTimeCards, (state, action) => {
    return {
      ...state,
      timeCards: null,
      totalTime: ''
    };
  }),
  on(loadTimeCardsSuccess, (state, action) => {
    let totalTimeMinutes = 0;
    const timeCards = deepClone(action.timeCards);
    if (action.timeCards) {
      timeCards.forEach(tc => {
        tc.timeDiff = (tc.clockIn && tc.clockOut) ? getTimeDiff(tc.clockIn.toString(), tc.clockOut.toString()) : 0;
        totalTimeMinutes += (tc.clockIn && tc.clockOut) ? getTimeDiffMinutes(tc.clockIn.toString(), tc.clockOut.toString()) : 0;
      });
    }
    const totalTime = getTimeFormatted(totalTimeMinutes);
    return {
      ...state,
      timeCards,
      totalTime
    };
  }),
  on(getManualEntriesReport, (state, action) => {
    return {
      ...state,
      loadingManualEntriesReport: true,
    };
  }),
  on(getManualEntriesReportSuccess, (state, action) => {    
    let timeCards = deepClone(action.list);
    if (action.list) {
      timeCards.result.forEach(tc => {
        tc.timeDiff = (tc.clockIn && tc.clockOut) ? getTimeDiff(tc.clockIn.toString(), tc.clockOut.toString()) : 0;
      });
    }
    
    return {
      ...state,
      manualEntriesList: timeCards,
      loadingManualEntriesReport: false
    };
  }),
  on(getManualEntriesReportFailure, (state, action) => {
    return {
      ...state,
      loadingManualEntriesReport: false,
    };
  }),
  on(exportManualEntriesReportToExcel, (state, action) => {
    return {
      ...state,
      loadingManualEntriesReport: true,
      manualEntriesReportArrayBuffer: null
    };
  }),
  on(exportManualEntriesReportToExcelSuccess, (state, action) => {
    return {
      ...state,
      loadingEnrollmentsExcel: false,
      manualEntriesReportArrayBuffer: action.doc
    };
  }),
  on(exportManualEntriesReportToExcelFailure, (state, action) => {
    return {
      ...state,
      loadingEnrollmentsExcel: false
    };
  }),
  on(loadManualEntryReasonsSuccess, (state, action) => {
    return {
      ...state,
      manualEntryReasons: action.reasons,
    };
  }),
  on(loadClientsSuccess, (state, action) => {
    return {
      ...state,
      clients: action.clients,
    };
  }),
  on(checkIPSuccess, (state, action) => {
    return {
      ...state,
      ipValid: action.valid,
    };
  }),
  on(getIsClockedInSuccess, (state, action) => {
    return {
      ...state,
      isClockledIn: action.isClockedIn,
    };
  }),
  on(clockInOut, (state, action) => {
    return {
      ...state,
      clockInOutLoading: true,
    };
  }),
  on(clockInOutSuccess, (state, action) => {
    return {
      ...state,
      clockInOutLoading: false,
    };
  }),
  on(selectTimeCardToAdd, (state, action) => {
    return {
      ...state,
      editingTimeCard: action.timeCard,
    };
  }),

  on(updateTimeCardForm, (state, action) => {
    const editingTimeCard = { ...state.editingTimeCard, ...action.formValues };
    const clockIn = action.formValues.clockIn ? moment(new Date(action.formValues.clockIn).toISOString()).utc(true).toDate() : null;
    const clockOut = action.formValues.clockOut ? moment(new Date(action.formValues.clockOut).toISOString()).utc(true).toDate() : null;
    editingTimeCard.clockIn = clockIn;
    editingTimeCard.clockOut = clockOut;
    const newState = { ...state, ...{ editingTimeCard, formErrors: action.formErrors } };
    return newState;
  }),

  on(selectTimeCardToFix, (state, action) => {
    return {
      ...state,
      fixingTimeCard: action.timeCard,
    };
  }),
);

export function timeCardReducerFunc(state: TimeCardState | undefined, action: Action) {
  return timeCardReducer(state, action);
}

export function initTimeCardEditForm(timeCard: TimeCardModel) {
  const form = new FormGroup({
    clockIn: new FormControl(timeCard.clockIn ? moment(timeCard.clockIn).toDate() : null, [Validators.required]),
    clockOut: new FormControl(timeCard.clockOut ? moment(timeCard.clockOut).toDate() : null, [Validators.required]),
    description: new FormControl(timeCard.description, [Validators.required, Validators.maxLength(255)]),
    isClientServiceTime: new FormControl(timeCard.isClientServiceTime, []),
    patientID: new FormControl(timeCard.patientID),
    code: new FormControl(timeCard.code),
    manualEntryReasonID: new FormControl(timeCard.manualEntryReasonID),
    manualEntryOtherReason: new FormControl(timeCard.manualEntryOtherReason, [Validators.maxLength(4000)]),
  }, { validators: [ValidatorRequiredCode('code'), ValidatorRequiredIfBool('patientID', 'isClientServiceTime')] });

  return form;
}



export function ValidatorRequiredCode(requiredControl: string): ValidatorFn {
  return (form: FormGroup): { [key: string]: any } | null => {
    if (form.get('patientID').value === 2919 ) {
      return null;
    } else if (!form.get('isClientServiceTime').value) {
      return null;
    } else if (!form.get(requiredControl).value) {
      form.get(requiredControl).setErrors({ required: { value: form.get(requiredControl).value } });
      return { required: { value: form.get(requiredControl).value } };
    }
  };
}
