import { formBuilderReducerFunc, FormBuilderState } from './form-builder/form-builder.reducer';
import { OnBoardingEffects } from './onboarding/onboarding.effects';
import { SurveyState, surveyReducerFunc } from './survey/survey.reducer';
import { CoachingLogState, coachingLogReducerFunc } from './coachingLog/coahingLog.reducer';
import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer,
  createAction,
  createReducer,
  on,
  Action,
  select,
  Store
} from '@ngrx/store';
import { environment } from '../../../environments/environment';
import { ProfileState, profileReducerFunc } from './profile/profile.reducer';
import { ProfileEffects } from './profile/profile.effects';
import { TimeCardEffects } from './timecard/timecard.effects';
import { TimeCardState, timeCardReducerFunc } from './timecard/timecard.reducer';
import { PayrollState, payrollReducerFunc } from './payroll/payroll.reducer';
import { PayPeriodState, payPeriodReducerFunc } from './payperiod/payperiod.reducer';
import { PayPeriodEffects } from './payperiod/payperiod.effects';
import { PayrollEffects } from './payroll/payroll.effects';
import { TimeoffState, timeoffReducerFunc } from './timeoff/timeoff.reducer';
import { TimeoffEffects } from './timeoff/timeoff.effects';
import { accountReducerFunc, AccountState } from './account/account.reducer';
import { AccountEffects } from './account/account.effects';
import { clearState } from './rootActions';
import { OfferLetterState, offerLetterReducerFunc } from './offerletter/offerletter.reducer';
import { OfferLetterEffects } from './offerletter/offerletter.effects';
import { UsersState, usersStateReducerFunc } from './users/users.reducer';
import { UsersEffects } from './users/users.effects';
import { equipmentReducerFunc, EquipmentState } from './equipment/equipment.reducer';
import { jobApplicationsReducerFunc, JobApplicationsState } from './job-applications/job-applications.reducer'
import { EquipmentEffects } from './equipment/equipment.effects';
import { OnboardingDocsState, onboardingDocsReducerFunc } from './onboardingdocs/onboardingdocs.reducer';
import { OnBoardingDocsEffects } from './onboardingdocs/onboardingdocs.effects';
import { PayrollApprovalState, payrollApprovalReducerFunc } from './payroll-approval/payroll-approval.reducer';
import { PayrollApprovalEffects } from './payroll-approval/payroll-approval.effects';
import { HolidayState, holidayReducerFunc } from './holiday/holiday.reducer';
import { HolidayEffects } from './holiday/holiday.effects';
import { OnboardingState, onboardingReducerFunc } from './onboarding/onboarding.reducer';
import { EmployeeState, employeeReducerFunc } from './employee/employee.reducer';
import { EmployeeEffects } from './employee/employee.effects';
import { ProspectiveCandidateEffects } from './prospective-candidates/prospective-candidate.effects';
import { prospectiveCandidateReducerFunc, ProspectiveCandidatesState } from './prospective-candidates/prospective-candidate.reducer';
import { SurveyEffects } from './survey/survey.effects';
import { injuryQueueReducer, injuryQueueReducerFunc, InjuryQueueState } from './injuryqueue/injuryqueue.reducer';
import { InjuryQueueEffects } from './injuryqueue/injuryqueue.effects';
import { employeeReviewTemplateReducerFunc, EmployeeReviewTemplateState } from './employee-review-template/employee-review-template.reducer';
import { EmployeeReviewTemplateEffects } from './employee-review-template/employee-review-template.effects';
import { EmployeeReviewEffects } from './employee-review/employee-review.effects';
import { employeeReviewReducerFunc, EmployeeReviewState } from './employee-review/employee-review.reducer';
import { EthicCodeEffects } from './ethic-code/ethic-code.effects';
import { EthicCodeState, ethicCodeStateReducerFunc } from './ethic-code/ethic-code.reducer';
import { SelfcareState, selfcareStateReducerFunc } from './selfcare/selfcare.reducer';
import { SelfcareEffects } from './selfcare/selfcare.effects';
import { ManageSelfcareState, manageSelfcareStateReducerFunc } from './manage-selfcare/manage-selfcare.reducer';
import { ManageSelfcareEffects } from './manage-selfcare/manage-selfcare.effects';
import { JobApplicationsEffects } from './job-applications/job-applications.effects';
import { paylocityReducerFunc, PaylocityState } from './paylocity/paylocity.reducer';
import { PaylocityEffects } from './paylocity/paylocity.effects';
import { benefitsReducerFunc, BenefitsState } from './benefits/benefits.reducer';
import { BenefitsEffects } from './benefits/benefits.effects';
import { FormBuilderEffects } from './form-builder/form-builder.effects';
import { CertificationEffects } from './certification/certification.effects';
import { certificationReducerFunc, CertificationState } from './certification/certification.reducer';
import { CriticalNewsFeedEffects } from './critical-news-feed/critical-news-feed.effects';
import { criticalNewsFeedReducer, CriticalNewsFeedState } from './critical-news-feed/critical-news-feed.reducer';
import { MedClinicState, medClinicStateReducerFunc } from './medclinic/medclinic.reducer';
import { MedClinicEffects } from './medclinic/medclinic.effects';
import { InputAutoCompleteEffects } from 'src/app/shared/input-helpers/store/input-autocomplete/input-autocomplete.effects';
import { inputAutoCompleteReducerFunc, InputAutoCompleteState } from 'src/app/shared/input-helpers/store/input-autocomplete/input-autocomplete.reducer';
import { CoachingLogEffects } from './coachingLog/coahingLog.effects';
import { ImprovementPlanEffects } from './improvementPlan/improvementPlan.effects';
import { ImprovementPlanState, improvementPlanReducerFunc } from './improvementPlan/improvementPlan.reducer';
import { benefitPlanEDIReducer,   benefitPlanEDIReducerFunc,   BenefitPlanEDIState } from './benefitPlanEDI/benefitPlanEDI.reducer';
import { BenefitPlanEDIEffects } from './benefitPlanEDI/benefitPlanEDI.effects';
import { internReducerFunc, InternState } from './intern/intern.reducer';
import { InternEffects } from './intern/intern.effects';
import { TicketTypesState, ticketTypesStateReducerFunc } from './ticket-types/ticket-types.reducer';
import { TicketTypesEffects } from './ticket-types/ticket-types.effects';


export interface State {
  profileState: ProfileState;
  timecardState: TimeCardState;
  payPeriodState: PayPeriodState;
  payrollState: PayrollState;
  timeoffState: TimeoffState;
  accountState: AccountState;
  offerLetterState: OfferLetterState;
  usersState: UsersState;
  equipmentState: EquipmentState;
  onBoardingDocsState: OnboardingDocsState;
  payrollApprovalState: PayrollApprovalState;
  onboardingState: OnboardingState;
  holidayState: HolidayState;
  employeeState: EmployeeState;
  prospectiveCandidateState: ProspectiveCandidatesState;
  surveyState: SurveyState;
  injuryQueueState: InjuryQueueState,
  employeeReviewTemplateState: EmployeeReviewTemplateState,
  employeeReviewState: EmployeeReviewState,
  ethicCodeState: EthicCodeState,
  selfcareState:SelfcareState,
  manageSelfcareState: ManageSelfcareState,
  paylocityState: PaylocityState,
  benefitPlanEDIState: BenefitPlanEDIState,
  jobApplicationsState: JobApplicationsState,
  benefitsState: BenefitsState,
  formBuilderState: FormBuilderState,
  certificationState: CertificationState,
  internState: InternState,
  criticalNewsFeedState: CriticalNewsFeedState,
  medClinicState: MedClinicState,
  inputAutoCompleteState: InputAutoCompleteState,
  ticketTypesState: TicketTypesState,
  coachingLogState: CoachingLogState;
  improvementPlanState: ImprovementPlanState;
}

export const reducers: ActionReducerMap<State> = {
  profileState: profileReducerFunc,
  timecardState: timeCardReducerFunc,
  payPeriodState: payPeriodReducerFunc,
  payrollState: payrollReducerFunc,
  timeoffState: timeoffReducerFunc,
  accountState: accountReducerFunc,
  offerLetterState: offerLetterReducerFunc,
  usersState: usersStateReducerFunc,
  equipmentState: equipmentReducerFunc,
  onBoardingDocsState: onboardingDocsReducerFunc,
  payrollApprovalState: payrollApprovalReducerFunc,
  holidayState: holidayReducerFunc,
  onboardingState: onboardingReducerFunc,
  employeeState: employeeReducerFunc,
  prospectiveCandidateState: prospectiveCandidateReducerFunc,
  surveyState: surveyReducerFunc,
  injuryQueueState: injuryQueueReducerFunc,
  employeeReviewTemplateState: employeeReviewTemplateReducerFunc,
  employeeReviewState: employeeReviewReducerFunc,
  ethicCodeState: ethicCodeStateReducerFunc,
  selfcareState: selfcareStateReducerFunc,
  manageSelfcareState: manageSelfcareStateReducerFunc,
  paylocityState: paylocityReducerFunc,
  benefitPlanEDIState: benefitPlanEDIReducerFunc,
  jobApplicationsState: jobApplicationsReducerFunc,
  benefitsState: benefitsReducerFunc,
  formBuilderState: formBuilderReducerFunc,
  certificationState: certificationReducerFunc,
  internState: internReducerFunc,
  criticalNewsFeedState: criticalNewsFeedReducer,
  medClinicState: medClinicStateReducerFunc,
  inputAutoCompleteState: inputAutoCompleteReducerFunc,
  ticketTypesState: ticketTypesStateReducerFunc,
  coachingLogState: coachingLogReducerFunc,
  improvementPlanState: improvementPlanReducerFunc,
};


export const effects = [
  ProfileEffects,
  TimeCardEffects,
  PayPeriodEffects,
  PayrollEffects,
  TimeoffEffects,
  AccountEffects,
  OfferLetterEffects,
  UsersEffects,
  EquipmentEffects,
  OnBoardingDocsEffects,
  PayrollApprovalEffects,
  HolidayEffects,
  OnBoardingEffects,
  EmployeeEffects,
  CriticalNewsFeedEffects,
  ProspectiveCandidateEffects,
  SurveyEffects,
  InjuryQueueEffects,
  EmployeeReviewTemplateEffects,
  EmployeeReviewEffects,
  EthicCodeEffects,
  SelfcareEffects,
  ManageSelfcareEffects,
  PaylocityEffects,
  BenefitPlanEDIEffects,
  BenefitsEffects,
  JobApplicationsEffects,
  FormBuilderEffects,
  CertificationEffects,
  InternEffects,
  MedClinicEffects,
  InputAutoCompleteEffects,
  TicketTypesEffects,
  CoachingLogEffects,
  ImprovementPlanEffects,
];


export const metaReducers: MetaReducer<State>[] = !environment.production ? [clearState] : [clearState];


export function getAsyncValue(store: Store<State>, selectorFunc) {
  let result;
  const sub = store.pipe(select(s => selectorFunc(s))).subscribe(r => result = r);
  sub.unsubscribe();
  return result;
}
