import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { State } from '../../../../core/store';
import { loadManageSelfcareCategories } from '../../../../core/store/manage-selfcare/manage-selfcare.actions';
import { SelfCareCategoryModel } from '../../../../models/selfcareModels';
import { AlertService } from '../../../../shared/services/alert.service';

@Component({
  selector: 'app-selfcare-management',
  templateUrl: './selfcare-management.component.html',
  styleUrls: ['./selfcare-management.component.scss']
})
export class SelfcareManagementComponent extends BaseComponent implements OnInit {
  loading: boolean = false;
  categories: SelfCareCategoryModel[];

  constructor(
    private router: Router,
    private actions$: Actions,
    private store: Store<State>,
    private alertService: AlertService,) {
    super();
  }
  ngOnInit() {
    this.categories = null;
    this.subs.push(
      this.store.select(s => s.manageSelfcareState.categories).subscribe(categories => {
        this.categories = categories;
      }),
      this.store.select(s => s.manageSelfcareState.loading).subscribe(loading => {
        this.loading = loading;
      }),

    );
    this.reload();
  }

  reload() {
    this.store.dispatch(loadManageSelfcareCategories());
  }
  edit(category: SelfCareCategoryModel) {
    this.router.navigate(['/selfcare/management/category/' + category.categoryID]);
  }
}
