<p-dialog [(visible)]="display" header="{{title}}" [modal]="true" [style]="{width: '70vw'}" (onHide)="closeModal()" [focusOnShow]="false">
  <form class="card mt-2" [formGroup]="form" (ngSubmit)="savePtoRequest()">
    <div class="card-body">
      <div class="row">
        <div class="col-12">

          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Employee</b>
            </div>
            <div class="col-8">
              <span>{{employeeName}}</span>              
            </div>
          </div>

          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Date Range</b>
            </div>
            <div class="col-4">
              <input type="date" class="form-control mt-2" formControlName="startDateString" />
              <app-validation-message [form]="form" [path]="'startDateString'" [label]="'Start Date'"></app-validation-message>
            </div>
            <div class="col-4">
              <input type="date" class="form-control mt-2" formControlName="endDateString" />
              <app-validation-message [form]="form" [path]="'endDateString'" [label]="'End Date'"></app-validation-message>
            </div>
          </div>

          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>PTO Available</b>
            </div>
            <div class="col-8 mt-2">
              <span style="font-style: italic;">{{totalAvailable}}</span>              
            </div>
          </div>

          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>PTO Requested</b>
            </div>
            <div class="col-4">
              <input type="number" min="0" step="1" style="width: 85%; display: inline-block;" placeholder="PTO Hours" class="form-control mt-2" formControlName="ptoHours" /> h
              <app-validation-message [form]="form" [path]="'ptoHours'" [label]="'Hours'"></app-validation-message>
            </div>
            <div class="col-4">
              <input type="number" min="0" max="59" step="1" style="width: 85%; display: inline-block;" placeholder="PTO Minutes" class="form-control mt-2" formControlName="ptoMinutes" /> m
              <app-validation-message [form]="form" [path]="'ptoMinutes'" [label]="'Minutes'"></app-validation-message>
            </div>
          </div>

          <div class="row" [hidden]="ptoRequest == null || ptoRequest.addedByPayroll !== true"> <!--if added by payroll-->
            <div class="col-4 d-flex align-items-center">
              <b>Comments</b>
            </div>
            <div class="col-8">
              <textarea style="width: 100%;" class="form-control mt-2" formControlName="comments"></textarea>
              <app-validation-message [form]="form" [path]="'comments'" [label]="'Comments'"></app-validation-message>
            </div>            
          </div>

        </div>
      </div>
    </div>    
  </form>
  <p-footer>    
    <button class="btn btn-secondary" type="button" (click)="closeModal()">Cancel</button>
    <button class="btn btn-primary" type="submit" (click)="savePtoRequest()" [disabled]="disableButtons">Save</button>
  </p-footer>
</p-dialog>
