import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UserProfileModel } from 'src/app/models/userProfileModel';
import { Store } from '@ngrx/store';
import { State, getAsyncValue } from '../../../core/store';
import { loadProfile, changePassword, getPhoto, getReturnEquipmentAlerts } from '../../../core/store/profile/profile.actions';
import { AccountService } from 'src/app/core/services/account.service';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent extends BaseComponent implements OnInit {

  profile: UserProfileModel;
  changingPassword = false;
  newPassword = '';
  newPasswordConfirm = '';
  profilePicture;
  backTo: string;

  constructor(
    private route: ActivatedRoute,
    private store: Store<State>) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.profileState.profile).subscribe(profile => {
        this.profile = profile;
      }),
      this.store.select(s => s.profileState.photoUrl).subscribe(photoUrl => {
        this.profilePicture = photoUrl;
      }),
      this.store.select(s => s.profileState.changingPassword).subscribe(changingPassword => {
        this.changingPassword = changingPassword;
      }),
    );
    this.store.dispatch(getPhoto());
    this.store.dispatch(loadProfile());
  }

  changePassword() {
    if (this.newPassword) {
      this.store.dispatch(changePassword({ newPassword: this.newPassword }));
    }
  }

}
