import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, AfterViewInit, HostListener } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { State } from 'src/app/core/store';
import { AlertService } from 'src/app/shared/services/alert.service';
import { deepClone } from 'src/app/helpers/utils';
import { loadSelfcareOverSightEmployees } from 'src/app/core/store/selfcare/selfcare.actions';
@Component({
  selector: 'app-selfcare',
  templateUrl: './selfcare.component.html',
  styleUrls: ['./selfcare.component.scss']
})
export class SelfcareComponent extends BaseComponent implements OnInit, AfterViewInit {


  initialWidth = 868;
  areas;
  img;
  map;
  initialCoords;
  userID;

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService) {
    super();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params.userID) {
        this.userID = Number(params.userID);
      } else {
        this.userID = null;
      }
    });
    this.img = document.getElementById('img_ID');
    this.map = document.getElementById('map_ID');
    this.areas = this.map.getElementsByTagName('area');
    this.initialCoords = [];
    for (let n = 0; n < this.areas.length; n++) {
      this.initialCoords[n] = this.areas[n].coords.split(',');
    }

    this.checkSize(window.innerWidth);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.checkSize(window.innerWidth);
    }, 500);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkSize(event.target.innerWidth);

  }

  checkSize(width) {

    let clen;
    const x = this.img.offsetWidth / this.initialWidth;
    const coords = deepClone(this.initialCoords);
    for (let n = 0; n < this.areas.length; n++) {
      clen = coords[n].length;
      for (let m = 0; m < clen; m++) {
        coords[n][m] *= x;
      }
      this.areas[n].coords = coords[n].join(',');
    }
  }

  getCategoryLink(id) {
    if (this.userID) {
      return '/selfcare/oversight/' + this.userID + '/category/' + id;
    } else {
      return '/selfcare/category/' + id;
    }
  }

  getPlannerLink() {
    if (this.userID) {
      return '/selfcare/oversight/' + this.userID + '/planner';
    } else {
      return '/selfcare/planner';
    }
  }


}

