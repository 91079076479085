import { createAction, props } from '@ngrx/store';
import { TicketType, CreateTicketTypeModel, UpdateTicketTypeModel } from 'src/app/models/ticketTypeModels';

// Load all ticket types
export const loadTicketTypes = createAction('[TicketTypes] Load TicketTypes');

export const loadTicketTypesSuccess = createAction(
  '[TicketTypes] Load TicketTypes Success',
  props<{ ticketTypes: TicketType[] }>()
);

export const loadTicketTypesFailure = createAction(
  '[TicketTypes] Load TicketTypes Failure',
  props<{ error: any }>()
);

// Load a single ticket type
export const loadTicketType = createAction(
  '[TicketTypes] Load TicketType',
  props<{ id: number }>()
);

export const loadTicketTypeSuccess = createAction(
  '[TicketTypes] Load TicketType Success',
  props<{ ticketType: TicketType }>()
);

export const loadTicketTypeFailure = createAction(
  '[TicketTypes] Load TicketType Failure',
  props<{ error: any }>()
);

// Create a ticket type
export const createTicketType = createAction(
  '[TicketTypes] Create TicketType',
  props<{ ticketType: CreateTicketTypeModel }>()
);

export const createTicketTypeSuccess = createAction(
  '[TicketTypes] Create TicketType Success',
  props<{ ticketType: TicketType }>()
);

export const createTicketTypeFailure = createAction(
  '[TicketTypes] Create TicketType Failure',
  props<{ error: any }>()
);

// Update a ticket type
export const updateTicketType = createAction(
  '[TicketTypes] Update TicketType',
  props<{ id: number; ticketType: UpdateTicketTypeModel }>()
);

export const updateTicketTypeSuccess = createAction('[TicketTypes] Update TicketType Success');

export const updateTicketTypeFailure = createAction(
  '[TicketTypes] Update TicketType Failure',
  props<{ error: any }>()
);

// Delete a ticket type
export const deleteTicketType = createAction(
  '[TicketTypes] Delete TicketType',
  props<{ id: number }>()
);

export const deleteTicketTypeSuccess = createAction('[TicketTypes] Delete TicketType Success');

export const deleteTicketTypeFailure = createAction(
  '[TicketTypes] Delete TicketType Failure',
  props<{ error: any }>()
);