import { SelectItem } from 'primeng/api';
import { UserModel } from 'src/app/models/userModel';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { FormArray, FormBuilder, FormControl, FormGroup, RequiredValidator, Validators } from '@angular/forms';
import { deepClone, getAllControlsErrors, getImprovementModelStatus, getShortDateFormatted, markFormGroupTouched } from 'src/app/helpers/utils';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import {  closeUpdateCoachingLog, updateCoachingLog, updateCoachingLogFail, updateCoachingLogSuccess, updateCreateCoachingLogForm } from '../../../../core/store/coachingLog/coahingLog.actions';
import { CoachingLogWithUsersModel } from '../../../../models/coachingLogWithUsersModel';
import { Router } from '@angular/router';
import { CoachingLogCategoryModel } from '../../../../models/coachingLogCategoryModel';
import { loadCampuses } from '../../../../core/store/equipment/equipment.actions';
import { Toast } from 'primeng/toast';
import { ImprovementPlanModel } from '../../../../models/improvementPlanModel';
@Component({
  selector: 'app-follow-up-coachinglog',
  templateUrl: './follow-up-coachinglog.component.html',
  styleUrls: ['./follow-up-coachinglog.component.scss']
})
export class FollowUpCoachingLogComponent extends BaseComponent implements OnInit {
  canAddCoach: boolean;
  categoriesList: CoachingLogCategoryModel[];
  updatingCoachingLog: boolean = false;
  hasOtherCategory: boolean = false;
  coachingLogWithUsersModel: CoachingLogWithUsersModel;
  wasUpdated: boolean = false;
  continueAfterSave: boolean = false;
  campusesHash;

  comply: number;
  complyOptions = [
    { label: 'Yes', value: 1 },
    { label: 'No', value: 0 },
    { label: 'No Opportunity to Comply', value: 2 },
  ];

  generalError?: string;
  compliedError?: string;
  followUpError?: string;
  newFollowUpDateError?: string;
  newFollowUpError?: string;

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private router: Router,
    private formBuilder: FormBuilder,
    private datepipe: DatePipe) {
    super();
  }

  ngOnInit() {
    // Initialize the form
    
    this.categoriesList = [];
    this.hasOtherCategory = false;
    this.subs.push(
      
      this.store.select(s => s.coachingLogState.categoriesList).subscribe(categories => {
        if (categories == null)
          categories = [];
        this.categoriesList = [];
        this.hasOtherCategory = false;
        categories.forEach((cl) => {
          if (cl.categoryName == "Other") {
            this.hasOtherCategory = true;
            return;
          }
          this.categoriesList.push(cl);
        });
      }),

      // Check if user is an HR manager
      this.store.select(s => s.accountState.user.permissionIDs).subscribe(permissionIDs => {
        this.canAddCoach = permissionIDs && !!permissionIDs.find(p => p === 6004 || p === 6005 || p === 6006);
      }),
      this.store.select(s => s.coachingLogState.updateCoachingLog).subscribe(coachingLog => {
        this.clearErrors();

        if (coachingLog) {
          this.wasUpdated = false;
          console.log(coachingLog);
          if (coachingLog && coachingLog.newFollowUpDate)
            this.wasUpdated = true;

          if (coachingLog.complied == true)
            this.comply = 1;
          else if (coachingLog.complied == false)
            this.comply = 0;
          else if (coachingLog.noOpportunityToComply == true)
            this.comply = 2;

          this.coachingLogWithUsersModel = deepClone(coachingLog);
         
        }
      }),
      this.store.select(s => s.equipmentState.campuses).subscribe(campuses => {
        this.campusesHash = {};
        campuses.forEach(c => {
          this.campusesHash[c.id] = c.programName;
        });

      }),
      this.actions$.pipe(
        ofType(updateCoachingLogSuccess),
        tap(action => {
          this.updatingCoachingLog = false;
          if (this.continueAfterSave) {
            if (this.coachingLogWithUsersModel.level > 1) {
              this.router.navigate(['/improvementplan/continue/' + this.coachingLogWithUsersModel.id]);
            } else {
              this.router.navigate(['/coachinglogs/create/' + this.coachingLogWithUsersModel.id]);
            }
          }
          else {
            this.cancel();
          }
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(updateCoachingLogFail),
        tap(action => {
          this.updatingCoachingLog = false;
          this.generalError = JSON.parse(action.err);
        })
      ).subscribe(),

    );
    this.store.dispatch(loadCampuses());
  }

  isValid() {
    this.clearErrors();
    if (this.coachingLogWithUsersModel.checkedDate) {
      this.generalError = `This coaching log was marked as done on ${this.getFormatedDate(this.coachingLogWithUsersModel.checkedDate)}.`;
      return false;
    }

    if (this.comply == null) {
      this.compliedError = "Complied is required."
    }

    if (!this.wasUpdated) {
      if (this.comply == 1) {//was complied

      } else if (this.comply == 0) {//not complied
        if (!this.coachingLogWithUsersModel.followUp) {
          this.followUpError = "Follow up is required.";
        }
        if (!this.coachingLogWithUsersModel.newFollowUpDate) {
          this.newFollowUpDateError = "Next Review Date is required.";
        }
        else if (this.coachingLogWithUsersModel.followUpDate > this.coachingLogWithUsersModel.newFollowUpDate) {
          this.newFollowUpDateError = "Next Review Date must be set to a date after First Review Date.";
        } 
      }
      else { //No opportunity to comply

      }
    }

    return !this.compliedError && !this.followUpError && !this.generalError && !this.newFollowUpDateError && !this.newFollowUpError;
  }

  getLevel(level: number) {
    if (level === 1) {
      return 'Re-Focus';
    } else if (level === 2) {
      return 'Coaching';
    } else if (level === 3) {
      return 'Coaching + Plan';
    } else if (level === 4) {
      return 'Corrective + Plan';
    }

  }
  getImprovementModelStatus(improvementPlan: ImprovementPlanModel) {
    return getImprovementModelStatus(improvementPlan);
  }


  clearErrors() {
    this.generalError = null;
    this.compliedError = null;
    this.followUpError = null;
    this.newFollowUpDateError = null;
    this.newFollowUpError = null;
  }

  getFormatedDate(date) {
    if (date) {
      return getShortDateFormatted(new Date(date));
    }
  }
  save() {
    this.generalError = null;
    if (this.isValid()) {

      if (this.comply == 1) {
        this.coachingLogWithUsersModel.noOpportunityToComply = false;
        this.coachingLogWithUsersModel.complied = true;
      } else if (this.comply == 0) {
        this.coachingLogWithUsersModel.noOpportunityToComply = false;
        this.coachingLogWithUsersModel.complied = false;
      }
      else
        this.coachingLogWithUsersModel.noOpportunityToComply = true;

      this.updatingCoachingLog = true;
      this.store.dispatch(updateCoachingLog({
        coachingLogWithUsersModel: {
          ...this.coachingLogWithUsersModel
        }
      }));
    } else {
      //markFormGroupTouched(this.form);
    }
  }

  saveAndContinue() {
    if (this.isValid()) {
      this.continueAfterSave = true;
      this.save();
    }
  }

  cancel() {
    this.store.dispatch(closeUpdateCoachingLog());
  }

  
}

