import { SelfCareCategoryModel, SelfCareCompletionReportModel, SelfCareEmployeeModel, SelfCarePlannerSectionItemNoteModel } from 'src/app/models/selfcareModels';
import { SelfCarePlannerSectionModel, SelfCareQuestionModel } from './../../models/selfcareModels';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PagingResultsModel } from '../../models/pagingResultsModel';
import { deepClone } from '../../helpers/utils';


@Injectable()
export class SelfcareService {

  constructor(private http: HttpClient) {
  }
  getPlanner(userID = null): Observable<SelfCarePlannerSectionModel[]> {
    if (userID) {
      return this.http.get<SelfCarePlannerSectionModel[]>(`${environment.apiServer.webApiURL}/SelfCare/GetOversightPlanner/${userID}`);
    } else {
      return this.http.get<SelfCarePlannerSectionModel[]>(`${environment.apiServer.webApiURL}/SelfCare/GetPlanner`);
    }
  }

  getCategories()
    : Observable<SelfCareCategoryModel[]> {
    return this.http.get<SelfCareCategoryModel[]>(`${environment.apiServer.webApiURL}/SelfCare/GetCategories`);
  }

  getPreferences(userID = null)
    : Observable<SelfCareCategoryModel> {
    if (userID) {
      return this.http.get<SelfCareCategoryModel>(`${environment.apiServer.webApiURL}/SelfCare/GetOversightPreferences/${userID}`);
    } else {
      return this.http.get<SelfCareCategoryModel>(`${environment.apiServer.webApiURL}/SelfCare/GetPreferences/`);
    }
  }

  getCategory(categoryID: number, userID = null)
    : Observable<SelfCareCategoryModel> {
    if (userID) {
      return this.http.get<SelfCareCategoryModel>(`${environment.apiServer.webApiURL}/SelfCare/GetOversightCategory/${categoryID}/${userID}`);
    } else {
      return this.http.get<SelfCareCategoryModel>(`${environment.apiServer.webApiURL}/SelfCare/GetCategory/${categoryID}`);
    }
  }

  postSelfcareAnwers(answers: SelfCareQuestionModel[])
    : Observable<any> {
    return this.http.post<any>(`${environment.apiServer.webApiURL}/SelfCare/Answers`,
      answers);
  }

  putSelfcareNote(note: SelfCarePlannerSectionItemNoteModel)
    : Observable<SelfCarePlannerSectionItemNoteModel> {
    return this.http.put<SelfCarePlannerSectionItemNoteModel>(`${environment.apiServer.webApiURL}/SelfCare/PlannerNote`,
      note);
  }

  deleteSelfcareNote(noteID: number)
    : Observable<any> {
    return this.http.delete<any>(`${environment.apiServer.webApiURL}/SelfCare/PlannerNote/${noteID}`);
  }

  getOversightEmployees()
    : Observable<SelfCareEmployeeModel[]> {
    return this.http.get<SelfCareEmployeeModel[]>(`${environment.apiServer.webApiURL}/SelfCare/OversightEmployees`);
  }

  getSelfCareCompletionList(filter: any): Observable<PagingResultsModel<SelfCareCompletionReportModel>> {
    if (!filter) {
      filter = {};
      filter.filters = {};
    }
    filter = deepClone(filter);

    return this.http.post<PagingResultsModel<SelfCareCompletionReportModel>>(`${environment.apiServer.webApiURL}/SelfCare/SelfCareCompletionList`, filter);
  }

  exportSelfCareCompletionListToExcel(filter: any): Observable<ArrayBuffer> {
    return this.http.post<ArrayBuffer>(`${environment.apiServer.webApiURL}/SelfCare/ExportSelfCareCompletionListToExcel`, filter, { responseType: 'arraybuffer' as 'json' });
  }

  getSelfCareAppURL()
    : Observable<{ url: string }> {
    return this.http.get<{ url: string }>(`${environment.apiServer.webApiURL}/SelfCare/GetSelfCareAppURL`);
  }

}

