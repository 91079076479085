import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { Actions, ofType } from '@ngrx/effects';
import { State } from '../../../../core/store';
import { Store } from '@ngrx/store';
import { SelectItem } from 'primeng/api';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { loadEmployeeUsers, loadViewableCampuses } from '../../../../core/store/employee/employee.action';
import { deepClone, downloadFile } from '../../../../helpers/utils';
import { deleteCertificationSuccess, deleteInstructorSuccess, exportCertificationInstructorListToExcel, exportCertificationInstructorListToExcelSuccess, getCertificationInstructorList, loadCertificationTypes, saveCertificationSuccess, saveInstructorSuccess, showEditInstructor } from '../../../../core/store/certification/certification.action';
import { CertificationInstructorModel } from '../../../../models/certificationModel';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-instructor-list-certification',
  templateUrl: './instructor-list-certification.component.html',
  styleUrls: ['./instructor-list-certification.component.scss']
})
export class InstructorListCertificationComponent extends BaseComponent implements OnInit {
  list: PagingResultsModel<CertificationInstructorModel>;
  filter: any;

  loadingList: boolean = true;
  loadingExcel: boolean = false;

  campusList: SelectItem[];
  campusId: number = 0;

  prefixText: string;
  userEmployeeList: SelectItem[];
  userId: number = 0;

  certificationTypes: SelectItem[];
  certificationTypeId: number = 0;

  showOnlyActiveEmployees: boolean = true;
  canManage;

  constructor(
    private actions$: Actions,
    private store: Store<State>
  ) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.certificationState.loadingCertificationInstructorList).subscribe(loading => {
        this.loadingList = loading;
      }),
      this.store.select(s => s.certificationState.loadingCertificationInstructorExcel).subscribe(loading => {
        this.loadingExcel = loading;
      }),
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.canManage = permissionIDs && !!permissionIDs.find(p => p === 3112); //Manage Certifications
      }),
      this.store.select(s => s.employeeState.campuses).subscribe(campuses => {
        this.campusList = campuses && campuses.length > 0 ? campuses
          : [{ label: 'loading...', value: undefined }];
      }),
      this.store.select(s => s.certificationState.certificationTypes).subscribe(types => {
        if (types && types.length > 0) {
          this.certificationTypes = types.map(x => ({ label: x.label, value: x.value }));
          this.certificationTypes.unshift({ label: 'All', value: 0 });
        }
        else {
          this.certificationTypes = [{ label: 'loading...', value: undefined }]
        }
      }),      
      this.store.select(s => s.employeeState.employeeUsers).subscribe(users => {
        this.userEmployeeList = users;
      }),
      this.store.select(s => s.certificationState.certificationInstructorList).subscribe(report => {
        this.list = report;
      }),
      this.actions$.pipe(
        ofType(saveInstructorSuccess), 
        tap(action => {
          this.reloadList();
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(deleteInstructorSuccess), 
        tap(action => {
          this.reloadList();
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(exportCertificationInstructorListToExcelSuccess),
        tap(action => {
          downloadFile(action.doc, 'CertificationInstructors.xlsx');
        })
      ).subscribe()
    );

    this.store.dispatch(loadViewableCampuses());
    this.store.dispatch(loadCertificationTypes());
  }  

  reloadFilter(event) {
    this.filter = event;
    this.reloadList();
  }

  reloadList() {
    this.setFilter();

    this.store.dispatch(getCertificationInstructorList({
      filter: this.filter
    }));
  }  

  getStaffList(event) {
    this.store.dispatch(loadEmployeeUsers({
      prefixText: event.query,
      programId: this.campusId,
      payrollClassificationId: null,
      showTerminated: !this.showOnlyActiveEmployees,
      showInactive: !this.showOnlyActiveEmployees
    }));
  }

  reloadListAfterStaffChange(selectedUser) {
    if (typeof selectedUser.value !== 'number') {
      return;
    }

    this.userId = selectedUser.value;
    this.reloadList();
  }

  reloadListAfterClear(event) {
    if (event.data !== null) {
      return;
    }

    this.userId = 0;
    this.reloadList();
  }

  setFilter() {
    this.filter = deepClone(this.filter);
    this.filter.filters.userId = { Name: 'userId', Value: { value: this.userId.toString() } };
    this.filter.filters.programId = { Name: 'programId', Value: { value: this.campusId.toString() } };
    this.filter.filters.certificationTypeId = { Name: 'certificationTypeId', Value: { value: this.certificationTypeId } };
    this.filter.filters.showOnlyActiveEmployees = { Name: 'showOnlyActiveEmployees', Value: { value: this.showOnlyActiveEmployees } };
  }

  exportToExcel() {
    if (this.loadingList || this.loadingExcel) {
      return;
    }
    this.setFilter();
   
    this.store.dispatch(exportCertificationInstructorListToExcel({
      filter: this.filter
    }));
  }

  getTerminationString(list: CertificationInstructorModel) {   
    if (list.terminationDate != null) {
      return list.archived ? " - Archived (Separated)" : "(Separated)";
    }

    if (list.inactive) {
      return list.archived ? " - Archived (Inactive)" : "(Inactive)";
    }

    return list.archived ? " - Archived" : "";
  }

  editInstructor(instructorModel) {
    this.store.dispatch(showEditInstructor({ instructor: instructorModel }));
  }
}
