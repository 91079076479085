<div class="row w-100 d-flex justify-content-center align-items-center">
    <div class="col-12 d-flex justify-content-start">
        <label class="btn btn-secondary">
            <input type="file" hidden="true" multiple #fileInput accept="image/*,.pdf,.docx" (change)="changedFile(fileInput)" />
            Add Files
        </label>
        <p *ngIf="uploadedFiles != null && uploadedFiles.length > 0" class="p-0 m-0 mt-2 ml-3">{{ uploadedFiles.length }} files selected</p>
    </div>
    <div class="d-flex flex-column justify-content-center ml-3 align-items-center w-100">
        <div *ngFor="let file of filesDisplay" class="m-2 col-12 btn btn-light" (click)="processFile(file)">
            <div class="w-100 d-flex justify-content-end align-items-center pointer" (click)="removeAddedFile($event, file)">
                <i class="fas fa-trash pointer"></i>
            </div>
            <div class="d-flex justify-content-center align-items-center pb-2">
                <i class="fas fa-file-alt mt-1"></i>
                <p class="m-0 ml-3 p-0">{{ file.file.name }}</p>
            </div>
        </div>
        <div *ngFor="let file of imageDisplay" class="m-2 col-12 pt-1 pb-2 bg-light rounded">
            <div class="w-100 d-flex justify-content-end align-items-center pointer" (click)="removeAddedFile($event, file)">
                <i class="fas fa-trash pointer"></i>
            </div>
            <div class="w-100 d-flex justify-content-center align-items-center pointer">
                <img [src]="file.url" class="w-75" [alt]="file" />
            </div>
        </div>
    </div>
</div>
<div class="modal-footer mt-2">
    <button class="btn btn-primary" (click)="upload()">Upload</button>
    <button type="button" class="btn btn-outline-dark" (click)="close()">Close</button>
</div>
