import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { Actions, ofType } from '@ngrx/effects';
import { State } from '../../../../core/store';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { AlertService } from '../../../../shared/services/alert.service';
import { InternApplicationActionModel, InternApplicationModel, InternUploadModel } from '../../../../models/internModels';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { deepClone, downloadFile } from '../../../../helpers/utils';
import {
  createInternshipApplication, createInternshipApplicationSuccess, getMyApplicationsList, loadInternForms, changeApplicationStatus, changeApplicationStatusSuccess,
  showEditInternActionItem, saveInternActionItemSuccess, getInternApplicationUploadSuccess, getInternApplicationUpload, showEditInternUploads, saveInternUploadsSuccess
} from '../../../../core/store/intern/intern.action';
import { SelectItem } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list-application',
  templateUrl: './list-application.component.html',
  styleUrls: ['./list-application.component.scss']
})
export class ListApplicationComponent extends BaseComponent implements OnInit {
  list: PagingResultsModel<InternApplicationModel>;
  filter: any = { };
  loadingList: boolean = true;
  loadingExcel: boolean = false;
  loadingDownload: boolean = false;
  savingApplication: boolean = false;

  formTemplates: SelectItem[];
  formId: number = 0;

  downloadFileName: string;

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private alertService: AlertService,
    private router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    this.subs.push(
      this.store.select(s => s.internState.loadingMyApplications).subscribe(loading => {
        this.loadingList = loading;
      }),
      this.store.select(s => s.internState.savingMyApplication).subscribe(saving => {
        this.savingApplication = saving;
      }),
      this.store.select(s => s.internState.loadingApplicationUpload).subscribe(loading => {
        this.loadingDownload = loading;
      }),
      this.store.select(s => s.internState.formTemplates).subscribe(forms => {
        if (forms && forms.length > 0) {
          this.formTemplates = forms.map(x => ({ label: x.label, value: x.value }));          
        }
        else {
          this.formTemplates = [{ label: 'loading...', value: undefined }]
        }
      }),
      this.store.select(s => s.internState.myApplicationList).subscribe(report => {
        this.list = report;
      }),
      this.actions$.pipe(
        ofType(createInternshipApplicationSuccess),
        tap(action => {
          if (action.formSubmission.formID > 0) {
            var path = "/form-filling/fill/" + action.formSubmission.formID;
            if (action.formSubmission.id > 0) { //can it have a formId without a form submission?
              path = path + "/" + action.formSubmission.id;
            }

            this.router.navigate([path], { queryParams: { backLink: "/intern/list-application" } });
          } else {
            this.reloadList();
          }          
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(changeApplicationStatusSuccess),
        tap(action => {
          this.alertService.success("Application withdrawn");
          this.reloadList();
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(saveInternActionItemSuccess),
        tap(action => {
          this.alertService.success("Success");
          this.reloadList();
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(saveInternUploadsSuccess),
        tap(action => {
          this.alertService.success("Success");
          this.reloadList();
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(getInternApplicationUploadSuccess),
        tap(action => {
          downloadFile(action.doc, this.downloadFileName);
        })
      ).subscribe(),
    );

    this.store.dispatch(loadInternForms());
  }

  reloadFilter(event) {
    this.filter = event;
    this.reloadList();
  }

  reloadList() {
    this.setFilter();

    this.store.dispatch(getMyApplicationsList({
      filter: this.filter
    }));
  }

  setFilter() {
    this.filter = deepClone(this.filter);
  }

  createNewApplication() {    
    if (this.formId > 0) {
      this.store.dispatch(createInternshipApplication({formId: this.formId}))
    }
    else {
      this.alertService.error("Please select an application");
    }
  }

  editApplication(item: InternApplicationModel) {
    if (item.formId > 0) {
      var path = "/form-filling/fill/" + item.formId;
      if (item.formSubmissionId > 0) { //can it have a formId without a form submission?
        path = path + "/" + item.formSubmissionId;
      }

      this.router.navigate([path], { queryParams: { backLink: "/intern/list-application" } });
    }    
  }

  canEditApplication(item: InternApplicationModel) {
    if (item.statusId === 1 && item.formId > 0) { //Not Submitted 
      return true;
    }

    return false;
  }

  canWithdrawApplication(item: InternApplicationModel) {
    if (item.statusId === 1 || item.statusId === 2) { //Not Submitted, pending
      return true;
    }

    return false;
  }

  canUploadFile(item: InternApplicationModel) {
    return item.internApplicationId > 0;
  }

  withdrawApplication(item: InternApplicationModel) {
    if (item.internApplicationId > 0) {
      this.alertService.confirm('Withdraw application',
        'Are you sure you want to withdraw this application? You will need to start a new application if you choose to apply later.')
        .subscribe(
          answer => {
            if (answer) {
              this.store.dispatch(changeApplicationStatus({ applicationId: item.internApplicationId, statusId: 5 }));
            }
          }, error => {
          }
        );
    }
    else {
      this.alertService.error("Application not found. Please refresh the page. Submit a support ticket if the error persists.");
    }
  }

  editActionItem(item: InternApplicationActionModel) {
    if (typeof item.actionId === 'undefined') {
      this.alertService.error("Action item not found. Please submit a support ticket."); //can only edit from this list
      return false;
    }
    this.store.dispatch(showEditInternActionItem({ actionId: item.actionId, actionApplicationId: item.applicationId }));
  }

  editApplicationUploads(item: InternApplicationModel) {
    if (typeof item.internApplicationId === 'undefined') {
      this.alertService.error("Application not found. Please submit a support ticket."); //can only edit from this list
      return false
    }
    this.store.dispatch(showEditInternUploads({ applicationId: item.internApplicationId }));
  }

  downloadUploadedFile(upload: InternUploadModel) {
    if (!this.loadingDownload) {
      this.downloadFileName = upload.fileName;
      this.store.dispatch(getInternApplicationUpload({ uploadId: upload.id }));
    }
  }
}
