import { ActivatedRoute } from '@angular/router';
import { createEquipment, createEquipmentSuccess, clearCreateEquipmentForm, loadClassCodes, loadClassifications, getEquipmentTypes, setCreateStoredValueOther } from './../../../../core/store/equipment/equipment.actions';
import { EquipmentClassCodeModel, EquipmentModel } from './../../../../models/equipmentsModel';
import { getUsersList } from '../../../../core/store/users/users.actions';
import { SelectItem } from 'primeng/api';
import { UserModel } from 'src/app/models/userModel';
import { OfferLetterModel, OfferLetterWithUserModel } from 'src/app/models/offerletterModel';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { FormGroup, RequiredValidator, Validators } from '@angular/forms';
import { getAllControlsErrors, markFormGroupTouched } from 'src/app/helpers/utils';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { initCreateOfferletterForm } from 'src/app/core/store/offerletter/offerletter.reducer';
import { updateCreateOfferletterForm, createOfferLetter } from 'src/app/core/store/offerletter/offerletter.actions';
import { initCreateEquipmentForm } from 'src/app/core/store/equipment/equipment.reducer';
import { updateCreateEquipmentForm } from 'src/app/core/store/equipment/equipment.actions';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { GeneralSettingsModel } from 'src/app/models/generalSettingsModel';
import { isNullOrUndefined } from 'util';
import { deepClone } from '../../../../helpers/utils';

@Component({
  selector: 'app-create-equipment',
  templateUrl: './create-equipment.component.html',
  styleUrls: ['./create-equipment.component.scss']
})
export class CreateEquipmentComponent extends BaseComponent implements OnInit {
  form: FormGroup;
  canEdit: boolean;
  equipment: EquipmentModel;
  classCode: EquipmentClassCodeModel;
  creatingEquipment = false;
  title: string;
  today = new Date();
  programList: SelectItem[];
  storageProgramList: SelectItem[];

  classCodeOther: boolean = true;

  classCodeOptions: SelectItem[] = [
    { label: '', value: 1 },];

  classificationOptions: SelectItem[] = [
    { label: '', value: 1 },];
  generalSettings: GeneralSettingsModel;
  showStorageOther: boolean;
  equipmentTypes: any;

  constructor(private actions$: Actions,
    private store: Store<State>,
    private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    // Initialize the form
    this.form = initCreateEquipmentForm({} as EquipmentModel);
    this.store.dispatch(loadClassCodes());
    this.store.dispatch(loadClassifications());
    this.subs.push(
      this.store.select(s => s.accountState.generalSettings).subscribe(generalSettings => {
        if (generalSettings) {
          this.generalSettings = generalSettings;
        }
      }),
      this.form.get('equipmentTypeID').valueChanges.subscribe(typeValue => {
        if (['Lanyard', 'Badge', 'Key', 'Apron', 'Charger'].indexOf(typeValue) >= 0) {
          this.form.get('serialNumber').clearValidators();
          this.form.get('serialNumber').setValidators([Validators.maxLength(255)]);
          this.form.get('serialNumber').updateValueAndValidity();
          this.form.get('model').clearValidators();
          this.form.get('model').setValidators([Validators.maxLength(255)]);
          this.form.get('model').updateValueAndValidity();
        } else {
          this.form.get('serialNumber').setValidators([Validators.required, Validators.maxLength(255)]);
          this.form.get('serialNumber').updateValueAndValidity();
          this.form.get('model').setValidators([Validators.required, Validators.maxLength(255)]);
          this.form.get('model').updateValueAndValidity();
        }
      }),
      // Check if user is an HR manager
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.canEdit = permissionIDs && !!permissionIDs.find(p => p === 3058); //manage equipment
      }),
      this.store.select(s => s.equipmentState.campuses).subscribe(campuses => {
        const campusesList = campuses && campuses.length > 0 ? campuses.map(c => ({ label: c.programName, value: c.id }))
          : [{ label: 'loading...', value: undefined }];

        this.programList = campusesList;
        this.storageProgramList = deepClone(campusesList);
        this.storageProgramList.push({label: 'Other (ex. Storage Unit. Please include details such as address or individuals/Business name)', value: 100})

      }),
      this.store.select(s => s.equipmentState.creatingEquipment).subscribe(creatingEquipment => {
        this.creatingEquipment = creatingEquipment;
      }),
      this.store.select(s => s.equipmentState.classCodes).subscribe(classCodeOptions => {
        const classCodeMapped = classCodeOptions && classCodeOptions.length > 0 ? classCodeOptions.map(c => ({ label: c.classCode, value: c.id }))
          : [{ label: 'loading...', value: undefined }];
        this.classCodeOptions = classCodeMapped;
      }),
      this.store.select(s => s.equipmentState.equipmentTypes).subscribe(equipmentTypes => {
        const equipmentTypesMapped = equipmentTypes && equipmentTypes.length > 0 ? equipmentTypes.map(c => ({ label: c.type, value: c.id }))
          : [{ label: 'loading...', value: undefined }];
        this.equipmentTypes = equipmentTypesMapped;
      }),
      this.store.select(s => s.equipmentState.classifications).subscribe(classificationOptions => {
        const classificationOptionsMapped = classificationOptions && classificationOptions.length > 0 ? classificationOptions.map(c => ({ label: c.classification, value: c.id }))
          : [{ label: 'loading...', value: undefined }];
        this.classificationOptions = classificationOptionsMapped;
      }),
      this.store.select(s => s.equipmentState.equipment).subscribe(equipment => {
        if (equipment === null) {
          this.equipment = equipment
          return;
        }

        const changedEquipment = !this.equipment || (this.equipment.id !== equipment.id);
        this.equipment = equipment;

        if (changedEquipment) {
          this.form = initCreateEquipmentForm(equipment);
          this.subs.push(
            // Update form values on the store
            this.form.valueChanges.subscribe(formValues => {
              const equipmentValues = this.form.value;
              const formErrors = getAllControlsErrors(this.form);
              this.store.dispatch(updateCreateEquipmentForm({ equipmentValues, formErrors }));
            })
          )

          if (typeof equipment.id === 'undefined' || equipment.id === null || equipment.id < 1) {
            this.title = "Create equipment";
            const equipmentValues = this.form.value;
            const formErrors = getAllControlsErrors(this.form);
            this.store.dispatch(updateCreateEquipmentForm({ equipmentValues, formErrors }));
          }
          else {
            this.title = "Edit Equipment #" + equipment.id;
            if(equipment.storedLocationID != null && equipment.storedLocationID == 100)
            {
              this.showStorageOther = true
            }
          }
        }
      }),
      this.actions$.pipe(
        ofType(clearCreateEquipmentForm),
        tap(action => {
          this.form.reset();
        })
      ).subscribe()
    );
    this.store.dispatch(getEquipmentTypes());
  }
  typeChange(event: any){
    if (['Lanyard', 'Badge', 'Key', 'Apron', 'Charger'].indexOf(event.originalEvent.target.innerText) >= 0) {
      this.form.get('serialNumber').clearValidators();
      this.form.get('serialNumber').setValidators([Validators.maxLength(255)]);
      this.form.get('serialNumber').updateValueAndValidity();
      this.form.get('model').clearValidators();
      this.form.get('model').setValidators([Validators.maxLength(255)]);
      this.form.get('model').updateValueAndValidity();
    } else {
      this.form.get('serialNumber').setValidators([Validators.required, Validators.maxLength(255)]);
      this.form.get('serialNumber').updateValueAndValidity();
      this.form.get('model').setValidators([Validators.required, Validators.maxLength(255)]);
      this.form.get('model').updateValueAndValidity();
    }
  }

  updateOther(event: any) {
    console.log(event)
    if (event.value === 1) {
      this.classCodeOther = false;
    }
    else {
      this.classCodeOther = true;
    }
  }

  storageLocationChange(event: any){
    console.log(event);
    if(event.value == 100){
      this.showStorageOther = true;
    }
    else{
      this.showStorageOther = false;
    }
  }

  save() {
    if (this.form.valid) {
      this.store.dispatch(createEquipment({ equipment: this.equipment }));
      this.showStorageOther = false;
    } else {
      markFormGroupTouched(this.form);
    }
  }

}


