import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { State } from '../../../../core/store';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { TimeOffModel } from '../../../../models/timeoffModel';
import { getUpcomingTimeOff, getRecentTimeOff, saveTimeOffRequestSuccess, showEditTimeOffRequest, removeTimeOffRequest } from '../../../../core/store/timeoff/timeoff.actions';
import { daysBetween } from '../../../../helpers/utils';
import { tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-list-my-timeoff',
  templateUrl: './list-my-timeoff.component.html',
  styleUrls: ['./list-my-timeoff.component.scss']
})
export class ListMyTimeoffComponent extends BaseComponent implements OnInit {
  userId: number;
  currentUserId: number;
  employeeName: string;
  showUpcoming: boolean = true;

  numberPendingUpcoming: number;
  daysPendingUpcoming: number;

  numberApprovedUpcoming: number;
  daysApprovedUpcoming: number;

  numberDeniedUpcoming: number;
  daysDeniedUpcoming: number; 

  upcomingList: PagingResultsModel<TimeOffModel>;
  upcomingfilter: any;
  loadingUpcomingList: boolean = true;


  numberPendingRecent: number;
  daysPendingRecent: number;

  numberApprovedRecent: number;
  daysApprovedRecent: number;

  numberDeniedRecent: number;
  daysDeniedRecent: number;

  recentList: PagingResultsModel<TimeOffModel>
  recentFilter: any;
  loadingRecentList: boolean = true;


  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private route: ActivatedRoute,
    private alertService: AlertService) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.accountState.user).subscribe(user => {
        this.employeeName = user.firstName + " " + user.lastName;
        this.currentUserId = user.id;
      }),      
      this.store.select(s => s.timeoffState.loadingRecentList).subscribe(loadingRecentList => {
        this.loadingRecentList = loadingRecentList;
      }),
      this.store.select(s => s.timeoffState.loadingUpcomingList).subscribe(loadingUpcomingList => {
        this.loadingUpcomingList = loadingUpcomingList;
      }),
      this.store.select(s => s.timeoffState.recentList).subscribe(recentList => {
        if (recentList == null) {
          return;
        }
        this.recentList = recentList;
        let numApproved = 0;
        let numDenied = 0;
        let numPending = 0;

        let daysApproved = 0;
        let daysDenied = 0;
        let daysPending = 0;

        for (var i = 0; i < this.recentList.result.length; i++) {
          let timeOff = this.recentList.result[i];

          if (timeOff.approved) {
            numApproved++;
            daysApproved += daysBetween(new Date(timeOff.startDate), new Date(timeOff.endDate)) + 1;
          }
          else if (timeOff.denied) {
            numDenied++;
            daysDenied += daysBetween(new Date(timeOff.startDate), new Date(timeOff.endDate)) + 1;
          }
          else {
            numPending++;
            daysPending += daysBetween(new Date(timeOff.startDate), new Date(timeOff.endDate)) + 1;
          }
        }

        this.numberApprovedRecent = numApproved;
        this.daysApprovedRecent = daysApproved;

        this.numberDeniedRecent = numDenied;
        this.daysDeniedRecent = daysDenied;

        this.numberPendingRecent = numPending;
        this.daysPendingRecent = daysPending;

      }),      
      this.store.select(s => s.timeoffState.upcomingList).subscribe(upcomingList => {
        if (upcomingList == null) {
          return;
        }
        this.upcomingList = upcomingList;
        let numApproved = 0;
        let numDenied = 0;
        let numPending = 0;

        let daysApproved = 0;
        let daysDenied = 0;
        let daysPending = 0;

        for (var i = 0; i < this.upcomingList.result.length; i++) {
          let timeOff = this.upcomingList.result[i];

          if (timeOff.approved) {
            numApproved++;
            daysApproved += daysBetween(new Date(timeOff.startDate), new Date(timeOff.endDate)) + 1;
          }
          else if (timeOff.denied) {
            numDenied++;
            daysDenied += daysBetween(new Date(timeOff.startDate), new Date(timeOff.endDate)) + 1;
          }
          else {
            numPending++;
            daysPending += daysBetween(new Date(timeOff.startDate), new Date(timeOff.endDate)) + 1;
          }
        }


        this.numberApprovedUpcoming = numApproved;
        this.daysApprovedUpcoming = daysApproved;

        this.numberDeniedUpcoming = numDenied;
        this.daysDeniedUpcoming = daysDenied;

        this.numberPendingUpcoming = numPending;
        this.daysPendingUpcoming = daysPending;

      }),
      this.actions$.pipe(
        ofType(saveTimeOffRequestSuccess),
        tap(action => {
          this.refreshData();
        })
      ).subscribe(),
    );

    this.route.params.subscribe(params => {
      if (params.userId) {
        this.userId = params.userId;
      }
    });

    if (!this.userId) {
      this.userId = 0;
    }
  }

  reloadUpcomingList(event) {    
    this.upcomingfilter = event;
    this.store.dispatch(getUpcomingTimeOff({
      filter: this.upcomingfilter,
      userId: this.userId
    }));
  }

  reloadRecentList(event) {    
    this.recentFilter = event;
    this.store.dispatch(getRecentTimeOff({
      filter: this.recentFilter,
      userId: this.userId
    }));
  }

  deleteTimeOffRequest(id){
    this.alertService.confirm('Confirmation',
    'Are you sure you want to remove this time off request?')
    .subscribe(
      answer => {
        if (answer) {
          this.store.dispatch(removeTimeOffRequest({timeOffId: id}));
        }
      }, error => {
      }
    );
  }

  refreshData() {
    this.reloadUpcomingList(this.upcomingfilter);
    this.reloadRecentList(this.recentFilter);
  }

  editTimeOffRequest(request) {
    this.store.dispatch(showEditTimeOffRequest({ timeOffRequest: request }));
  }

  toggleShowUpcoming() {
    this.showUpcoming = !this.showUpcoming;
  }
}
