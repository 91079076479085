<form class="card mt-2">
  <div class="card-body">
    <h2 class="mb-3">
      Employee Reviews
    </h2>
    <div class="row mb-1">
      <div class="col-2 d-flex align-items-center">
        <h5>Archived Templates:</h5>
      </div>
      <div class="col-10 ">
        <p-selectButton styleClass="w-100 ml-2" [options]="yesNoAllOptions" [(ngModel)]="includeArchived" (ngModelChange)="reloadList()" [ngModelOptions]="{standalone: true}"></p-selectButton>
      </div>
    </div>
    <div class="mb-3">

      <button *ngIf="isPlayer && user.isOpenPeriod" routerLink="/employee-review/player" class="btn btn-secondary">Answer Review</button>
      <button *ngIf="user.canReview" (click)="openLink('/employee-review/oversight/'+userId)" class="btn btn-secondary">Oversight Review</button>
    </div>
    <i [hidden]="!loading" class="material-icons rotate text-muted">loop</i>
    <p-table [value]="reviews?.result" [responsive]="true" [paginator]="true" [rows]="20"
             [totalRecords]="reviews?.totalRecords" [lazy]="true" (onLazyLoad)="reloadReviews($event)">
      <ng-template pTemplate="header">
        <tr>
          <th width="100px"></th>
          <th [pSortableColumn]="'templateDescription'">Template Description<p-sortIcon [field]="'description'"></p-sortIcon></th>
          <th [pSortableColumn]="'reviewDate'">Review Date<p-sortIcon [field]="'reviewDate'"></p-sortIcon></th>
          <th [pSortableColumn]="'score'">Score<p-sortIcon [field]="'Score'"></p-sortIcon></th>
          <th [pSortableColumn]="'templateMaxScore'">Template Max Score<p-sortIcon [field]="'templateMaxScore'"></p-sortIcon></th>
          <th>Done by<p-sortIcon [field]="'oversightName'"></p-sortIcon></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-review>
        <tr>
          <td class="text-center">
            <span *ngIf="review.reviewDate" class="pointer mr-2" (click)="openReview(review)"><i title="Open Review" style="font-size: 1.5rem;" class="fas fa-file-alt"></i></span>
            <span *ngIf="!review.reviewDate && review.isOversight && user.canReview && !review.incomplete" class="pointer mr-2" (click)="editReview(review)"><i title="Edit Oversight Review" style="font-size: 1.5rem;" class="fas fa-edit"></i></span>
            <span *ngIf="!review.reviewDate && review.isOversight && user.canReview" class="pointer mr-2" (click)="deleteReview(review)"><i title="Delete Review" style="font-size: 1.5rem;" class="fas fa-trash-alt"></i></span>
            <span *ngIf="!review.reviewDate && !review.isOversight && isPlayer && !review.incomplete" class="pointer mr-2" (click)="editReview(review)"><i title="Edit Review" style="font-size: 1.5rem;" class="fas fa-edit"></i></span>
            <span *ngIf="!review.reviewDate && !review.isOversight && isPlayer" class="pointer mr-2" (click)="deleteReview(review)"><i title="Delete Review" style="font-size: 1.5rem;" class="fas fa-trash-alt"></i></span>
          </td>
          <td>
            <span class="ui-column-title">Description</span>
            {{
              review.templateDescription
            }}
          </td>
          <td>
            <span class="ui-column-title">Review Date</span>
            <span *ngIf="review.incomplete" style="color: red; font-style: italic;">Incomplete</span>
            {{review.reviewDate | date: 'shortDate'}}
          </td>
          <td>
            <span class="ui-column-title">Score</span>
            {{review.incomplete ? '-' : review.score}}
          </td>
          <td>
            <span class="ui-column-title">templateMaxScore</span>
            {{review.templateMaxScore}}
          </td>
          <td>
            <span class="ui-column-title">Oversight</span>
            <strong *ngIf="review?.isOversight">Oversight:</strong><ul *ngIf="review?.isOversight">
              <li *ngFor="let on of review.oversightNames">
                {{on}}
              </li>
            </ul>
            <span *ngIf="!review?.isOversight">Self Review</span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</form>

