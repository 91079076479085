import { FormGroup } from '@angular/forms';
import { ImprovementPlanModel } from '../models/improvementPlanModel';
import { FormError } from '../models/utilModels';

export class Utils {
  static getFormUrlEncoded(obj: any) {
    const formBody = [];
    for (const property of Object.keys(obj)) {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(obj[property]);
      formBody.push(encodedKey + '=' + encodedValue);
    }
    return formBody.join('&');
  }
}


export function getAllControlsErrors(form: FormGroup): FormError[] {
  const errors: FormError[] = [];
  addSubErrors(form, errors);
  return errors;
}

function addSubErrors(form: FormGroup, errors: FormError[]) {
  for (const i in form.controls) {
    if ((form.controls[i] as any).controls) {
      addSubErrors(form.controls[i] as any, errors);
    } else {
      if (form.controls[i].errors) {
        // tslint:disable-next-line:forin
        for (const e in form.controls[i].errors) {
          errors.push({ controlName: i, errorType: e, error: form.controls[i].errors[e] });
        }
      }
    }
  }
}

/**
 * Marks all controls in a form group as touched
 * @param formGroup - The form group to touch
 */
export function markFormGroupTouched(formGroup: FormGroup) {
  Object.values(formGroup.controls).forEach(control => {
    control.markAsTouched();
    if ((control as any).controls) {
      markFormGroupTouched(control as any);
    }
  });
}


export function updateFormGroupValidity(formGroup: FormGroup) {
  Object.values(formGroup.controls).forEach(control => {
    control.updateValueAndValidity();
    if ((control as any).controls) {
      updateFormGroupValidity(control as any);
    }
  });
}

export function getClientFromUrl() {
  const url = window.location.href;
  let clientFromUrl = '';
  if (url.indexOf('localhost') > 0) {
    clientFromUrl = 'localhost';
  } else if (url.indexOf('hrportal.optomiser.com') > 0) {
    clientFromUrl = 'utbs';
  } else if (url.indexOf('hrportal-') > 0) {
    clientFromUrl = url.substring(url.indexOf('hrportal-') + 9, url.indexOf('.'));
  }
  return clientFromUrl;
}


export function getOptoLinkFromUrl() {
  const url = window.location.href;
  let optoLinkFromUrl = '';
  if (url.indexOf('optomiser-development') > 0) {
    optoLinkFromUrl = 'https://' + (url.substring(url.indexOf('hrportal-') + 9, url.indexOf('.'))) + '.optomiser-development.com';
  } else if (url.indexOf('localhost') > 0) {
    optoLinkFromUrl = 'https://localhost:44344';
  } else if (url.indexOf('hrportal-') > 0) {
    optoLinkFromUrl = 'https://' + (url.substring(url.indexOf('hrportal-') + 9, url.indexOf('.'))) + '.optomiser.com';
  } else if (url.indexOf('hrportal.optomiser.com') > 0) {
    optoLinkFromUrl = 'https://utbs.optomiser.com';
  }
  return optoLinkFromUrl;
}


export function getTimeDiffMinutes(firstDate: string, secondDate: string) {
  const allmins = Math.abs(new Date(secondDate).getTime() - new Date(firstDate).getTime()) / (1000 * 60);
  return allmins;
}

export function getTimeDiffDays(firstDate: string, secondDate: string) {
  const allmins = Math.abs(new Date(secondDate).getTime() - new Date(firstDate).getTime()) / (1000 * 60);
  return getTimeFormattedDays(allmins);
}

export function getTimeFormattedDays(allMinutes: number) {
  const days = Math.floor(allMinutes / 60 / 24);
  const hours = Math.floor((allMinutes / 60) % 24);

  return days.toFixed(0) + 'd ' + hours.toFixed(0) + 'h';
}

export function getTimeDiff(firstDate: string, secondDate: string) {
  const allmins = getTimeDiffMinutes(firstDate, secondDate);
  return getTimeFormatted(allmins);
}

export function getTimeDiffMinusOvertime(firstDate: string, secondDate: string, overtimeMinutes: number) {
  const allmins = getTimeDiffMinutes(firstDate, secondDate);
  return getTimeFormatted(allmins - overtimeMinutes);
}

export function getTimeFormatted(allMinutes: number) {
  const hours = Math.floor(allMinutes / 60);
  const mins = allMinutes % 60;

  return hours.toFixed(0) + 'h ' + mins.toFixed(0) + 'm';
}


export function getShortDateFormatted(date: Date) {
  if (!date) {
    return '';
  }

  return new Date(date).toLocaleDateString();
}

export function getTimezoneOffsetString() {
  var d = (new Date()).toString();
  var tz = d.split("GMT");
  if (tz.length < 2) {
    return "";
  }
  return tz[1].split(" ")[0]; //i.e. -0700
}

export function formatDateString(dateObject?: string) {
  if (!dateObject) {
    return '';
  }

  let date = new Date(dateObject);

  if (dateObject.indexOf('T') > 0) {
    var s = dateObject.split('T').slice(0, -1);
    date = new Date(s[0]);
    if (date.getHours() > 0 && getTimezoneOffsetString().indexOf('-') > -1) { //can I test with + timezone?
      date.setHours(0);
      date.setDate(date.getDate() + 1);
    }
  }

  return getDateString(date);
}

export function getDateString(dateObject?: Date) {
  if (!dateObject) {
    return '';
  }

  let year = dateObject.getFullYear().toString();
  let monthDigit = dateObject.getMonth() + 1;
  let month = (monthDigit < 10 ? '0' + monthDigit : monthDigit).toString();
  let dayDigit = dateObject.getDate();
  let day = (dayDigit < 10 ? '0' + dayDigit : dayDigit).toString();

  return year + "-" + month + "-" + day;
}

export function getAge(birthDateString) {
  const today = new Date();
  const birthDate = new Date(birthDateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}
export function getAgeAtDate(birthDate: Date, atDate: Date): number {
  let age = atDate.getFullYear() - birthDate.getFullYear();
  const monthDifference = atDate.getMonth() - birthDate.getMonth();
  const dayDifference = atDate.getDate() - birthDate.getDate();

  if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
    age--;
  }

  return age;
}

export function getTimeStampString(dateObject?: Date) {
  if (!dateObject) {
    dateObject = new Date();
  }

  let date = new Date(dateObject);

  let year = date.getFullYear().toString();
  let monthDigit = date.getMonth() + 1;
  let month = (monthDigit < 10 ? '0' + monthDigit : monthDigit).toString();
  let dayDigit = date.getDate();
  let day = (dayDigit < 10 ? '0' + dayDigit : dayDigit).toString();
  let hourDigit = date.getHours();
  let hour = (hourDigit < 10 ? '0' + hourDigit : hourDigit).toString();
  let minuteDigit = date.getMinutes();
  let minute = (minuteDigit < 10 ? '0' + minuteDigit : minuteDigit).toString();
  let secondDigit = date.getSeconds();
  let second = (secondDigit < 10 ? '0' + secondDigit : secondDigit).toString();

  return year + month + day + hour + minute + second;
}

export function getFormattedTime(dateObject?: Date) {
  if (!dateObject) {
    return '';
  }

  let date = new Date(dateObject);

  var h = date.getHours();
  var p = ' AM';
  if (h == 12) {
    p = ' PM';
  }
  else if (h > 12) {
    p = ' PM';
    h = h - 12;
  } else if (h == 0) {
    h = 12;
  }

  var m = (date.getMinutes()) < 10 ? '0' + (date.getMinutes()) : (date.getMinutes());

  return h + ':' + m + p;
}

export function getDateNumberComparison(date?: Date) {
  if (!date) {
    return 0;
  }

  return parseInt(new Date(date).toISOString().slice(0, 10).replace(/-/g, ""));
}

export function getDateWithOffset(offset: number) {
  let tomorrow = new Date();
  tomorrow.setHours(0);
  tomorrow.setMinutes(0);
  tomorrow.setSeconds(0);
  tomorrow.setDate(new Date().getDate() + offset);
  return tomorrow;
}

export function addDays(base: Date, offset: number) {
  let date = new Date(base);
  date.setDate(date.getDate() + offset);
  return date;
}

export function daysBetween(date1: Date, date2: Date) {
  //Get 1 day in milliseconds
  let oneDay = 1000 * 60 * 60 * 24;

  // Calculate the difference in milliseconds
  let difference = date2.getTime() - date1.getTime();

  // Convert back to days and return
  return Math.round(difference / oneDay);
}

export function roundNumber(value, decimals) {
  if (value == null || isNaN(value))
    return 0;
  if (decimals == null)
    decimals = 2;
  return Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
}
export function roundUp(value, decimals=2) {
  if (value == null || isNaN(value))
    return 0;
  if (decimals == null)
    decimals = 2;
  return Math.ceil(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
}

export function formatCurrencyNotZero(total) {  
  if (total === null || isNaN(total) || roundNumber(total, 2) == 0)
    return '-';
  total = roundNumber(total, 2);
  var neg = false;
  if (total < 0) {
    neg = true;
    total = Math.abs(total);
  }
  return (neg ? "-$" : '$') + parseFloat(total).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString();
}

export function deepClone(obj) {
  return JSON.parse(JSON.stringify(obj));
}


export function downloadFile(blob, name: string, newwindow = false) {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  const newBlob = new Blob([blob], { type: '' });

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  let winNav: any = window.navigator;
  if (window.navigator && winNav.msSaveOrOpenBlob) {
    winNav.msSaveOrOpenBlob(newBlob);
    return;
  }

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob);
  const link = document.createElement('a');
  link.href = data;
  link.download = name;
  if (name.endsWith('.pdf') || newwindow) {
    link.target = '_blank';
  }

  link.click();
  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
  }, 100);
}


export function abUint8ToStr(buf) {
  return String.fromCharCode.apply(null, new Uint8Array(buf));
}


export function getImprovementModelStatus(improvementPlan: ImprovementPlanModel) {
  let status = '';
  if (!improvementPlan.signedDateEmployee) {
    status += 'Missing Employee Signature ';
  }
  if (!improvementPlan.signedDateSupervisor) {
    status += 'Missing Supervisor Signature';
  }
  if (improvementPlan.signedDateSupervisor && improvementPlan.signedDateEmployee) {
    if (improvementPlan.success) {
      status = 'Success';
    } else {
      if (!improvementPlan.nextReviewDate && improvementPlan.success === false) {
        status = 'Failed';
      } else {
        status = 'Active';
      }
    }
  }
  return status;
}

export const arrayToObject = (array) =>
  array.reduce((obj, item) => {
    obj[item.id] = item;
    return obj;
  }, {});

export const clearArray = (array) => {
  while (array.length) {
    array.pop();
  }
};

export const scrollToId = (id) => {
  setTimeout(() => {
    const el = document.getElementById(id);
    el.scrollIntoView();
    window.scrollBy(0, -150);
  }, 500);
};

export const dataURItoBlob = (dataURI) => {
  const binary = atob(dataURI.split(',')[1]);
  const array = [];
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
};

export const isBright = (color: string) => {
  const hex = color.replace('#', '');
  const cR = parseInt(hex.substr(0, 2), 16);
  const cG = parseInt(hex.substr(2, 2), 16);
  const cB = parseInt(hex.substr(4, 2), 16);
  const brightness = ((cR * 299) + (cG * 587) + (cB * 114)) / 1000;
  return brightness > 155;
};

export const formatPhoneNumber = (str) => {
  //Filter only numbers from the input
  let cleaned = ('' + str).replace(/\D/g, '');

  //Check if the input is of correct length
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return match[1] + '-' + match[2] + '-' + match[3]
  };

  return null
};

export const base64ToArrayBuffer = (base64) => {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
};

export const arrayBufferToBase64 = (arrayBuffer) => {
  var base64    = ''
  var encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'

  var bytes         = new Uint8Array(arrayBuffer)
  var byteLength    = bytes.byteLength
  var byteRemainder = byteLength % 3
  var mainLength    = byteLength - byteRemainder

  var a, b, c, d
  var chunk

  // Main loop deals with bytes in chunks of 3
  for (var i = 0; i < mainLength; i = i + 3) {
    // Combine the three bytes into a single integer
    chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2]

    // Use bitmasks to extract 6-bit segments from the triplet
    a = (chunk & 16515072) >> 18 // 16515072 = (2^6 - 1) << 18
    b = (chunk & 258048)   >> 12 // 258048   = (2^6 - 1) << 12
    c = (chunk & 4032)     >>  6 // 4032     = (2^6 - 1) << 6
    d = chunk & 63               // 63       = 2^6 - 1

    // Convert the raw binary segments to the appropriate ASCII encoding
    base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d]
  }

  // Deal with the remaining bytes and padding
  if (byteRemainder == 1) {
    chunk = bytes[mainLength]

    a = (chunk & 252) >> 2 // 252 = (2^6 - 1) << 2

    // Set the 4 least significant bits to zero
    b = (chunk & 3)   << 4 // 3   = 2^2 - 1

    base64 += encodings[a] + encodings[b] + '=='
  } else if (byteRemainder == 2) {
    chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1]

    a = (chunk & 64512) >> 10 // 64512 = (2^6 - 1) << 10
    b = (chunk & 1008)  >>  4 // 1008  = (2^6 - 1) << 4

    // Set the 2 least significant bits to zero
    c = (chunk & 15)    <<  2 // 15    = 2^4 - 1

    base64 += encodings[a] + encodings[b] + encodings[c] + '='
  }

  return base64
};
