import { PagingResultsModel } from '../../../models/pagingResultsModel';
import { UserModel } from 'src/app/models/userModel';
import { OfferLetterModel, OfferLetterWithUserModel } from 'src/app/models/offerletterModel';
import { createAction, props } from '@ngrx/store';
import { FormError } from 'src/app/models/utilModels';

export const getUsersList = createAction('[Offer Letter] Get users list');
export const getUsersListSuccess = createAction('[Offer Letter] Get users list Success', props<{ usersList: UserModel[] }>());

export const getUsersIncludingTerminated = createAction('[Users] Get terminated users');
export const getUsersIncludingTerminatedSuccess = createAction('[Users] Toggle terminated users Success',
  props<{ usersList: UserModel[] }>());


export const getUsersReportingList = createAction('[Offer Letter] Get users to report to list');
export const getUsersReportingListSuccess = createAction('[Offer Letter] Get users to report to list Success',
  props<{ usersReportingList: UserModel[] }>());


export const getUser = createAction('[Users] Get user', props < { userId: number }>());
export const getUserSuccess = createAction('[Users] Get user Success',
  props<{ user: UserModel }>());


