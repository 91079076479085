import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { InternApplicationActionTemplateModel } from '../../../../models/internModels';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { Actions, ofType } from '@ngrx/effects';
import { State } from '../../../../core/store';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { AlertService } from '../../../../shared/services/alert.service';
import { deepClone, downloadFile } from '../../../../helpers/utils';
import { SelectItem } from 'primeng/api';
import { deleteInternActionItemTemplate, deleteInternActionItemTemplateSuccess, getInternActionItemTemplateList, loadInternshipStatuses, saveInternActionItemTemplateSuccess, showEditInternActionItemTemplate } from '../../../../core/store/intern/intern.action';

@Component({
  selector: 'app-list-intern-action-item-template',
  templateUrl: './list-intern-action-item-template.component.html',
  styleUrls: ['./list-intern-action-item-template.component.scss']
})
export class ListInternActionItemTemplateComponent extends BaseComponent implements OnInit {
  list: PagingResultsModel<InternApplicationActionTemplateModel>;
  filter: any = {};
  loadingList: boolean = true;
  loadingExcel: boolean = false;
  savingItem: boolean = false;

  canManage;

  primaryStatusList: SelectItem[];
  primaryStatusId: number = 0;

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private alertService: AlertService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.subs.push(
      this.store.select(s => s.accountState.user.permissionIDs).subscribe(permissionIDs => {
        this.canManage = permissionIDs && !!permissionIDs.find(p => p === 3128); //Manage Intern Applications
      }),
      this.store.select(s => s.internState.loadingActionItemTemplateList).subscribe(loading => {
        this.loadingList = loading;
      }),
      this.store.select(s => s.internState.actionItemTemplateList).subscribe(report => {
        this.list = report;
      }),
      this.store.select(s => s.internState.savingTemplate).subscribe(saving => {
        this.savingItem = saving;
      }),
      this.store.select(s => s.internState.internshipStatuses).subscribe(items => {
        if (items && items.length > 0) {
          this.primaryStatusList = items.map(x => ({ label: x.label, value: x.value }));
          this.primaryStatusList.unshift({ label: 'All', value: 0 });
        }
        else {
          this.primaryStatusList = [{ label: 'loading...', value: undefined }]
        }
      }),
      this.actions$.pipe(
        ofType(saveInternActionItemTemplateSuccess),
        tap(action => {
          this.alertService.success("Success");
          this.reloadList();
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(deleteInternActionItemTemplateSuccess),
        tap(action => {
          this.alertService.success("Success");
          this.reloadList();
        })
      ).subscribe(),
    );

    this.store.dispatch(loadInternshipStatuses());
  }



  reloadFilter(event) {
    this.filter = event;
    this.reloadList();
  }

  reloadList() {
    this.setFilter();

    this.store.dispatch(getInternActionItemTemplateList({
      filter: this.filter
    }));
  }

  setFilter() {
    this.filter = deepClone(this.filter);

    this.filter.filters.primaryStatusId = { Name: 'primaryStatusId', Value: { value: this.primaryStatusId.toString() } };
  }

  editTemplate(item?: InternApplicationActionTemplateModel) {
    const templateId = typeof item !== 'undefined' && typeof item.actionTemplateId !== 'undefined' ? item.actionTemplateId : 0;
    this.store.dispatch(showEditInternActionItemTemplate({ templateId: templateId }));
  }

  deleteTemplate(list: InternApplicationActionTemplateModel) {
    this.alertService.confirm('Delete Template',
      'Are you sure you want to delete this action item template? The template will be archived if it has already been assigned to an action item.')
      .subscribe(
        answer => {
          if (answer) {
            this.store.dispatch(deleteInternActionItemTemplate({ templateId: list.actionTemplateId }));
          }
        }, error => {
        }
      );
  }
}
