import { AlertService } from 'src/app/shared/services/alert.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../core/store';
import { loadPayPeriods, changePayPeriod } from 'src/app/core/store/payperiod/payperiod.actions';
import { selectTimeCardToAdd, loadTimeCards, loadTimeCardsSuccess, selectTimeCardToFix, loadClients,
  deleteTimeCard } from 'src/app/core/store/timecard/timecard.actions';

import { TimeCardModel } from 'src/app/models/timeCardModel';
import { PayPeriodModel } from 'src/app/models/payPeriodModel';
import { Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { rootEffectsInit } from '@ngrx/effects';
import { arrayToObject, getOptoLinkFromUrl } from 'src/app/helpers/utils';

@Component({
  selector: 'app-timecard',
  templateUrl: './timecard.component.html',
  styleUrls: ['./timecard.component.scss']
})
export class TimeCardComponent extends BaseComponent implements OnInit, OnDestroy {

  timeCards: TimeCardModel[];
  payPeriods: PayPeriodModel[];
  selectedPeriodID: number;
  totalTime: string;
  additing = false;
  fixing = false;
  hasAnyClientService;
  hasAnyFixReason;
  hasAnyReply;
  hasAnyPayableFixRequest;
  hasAnyDocumentationFixRequest;
  clients;
  hasClockPunchRight = false;

  constructor(
    private store: Store<State>,
    private alertService: AlertService) {
    super();
  }

  ngOnInit() {
    this.store.dispatch(loadClients());
    this.store.dispatch(loadPayPeriods());
    this.subs.push(
      this.store.select(s => s.timecardState.clients).subscribe(clients => {
        this.clients = clients ? arrayToObject(clients) : [];
      }),
      this.store.select(s => s.payPeriodState.periods).subscribe(periods => {
        this.payPeriods = periods;
      }),
      this.store.select(s => s.payPeriodState.selectedPeriodID).subscribe(selectedPeriodID => {
        this.selectedPeriodID = selectedPeriodID;
      }),
      this.store.select(s => s.timecardState.timeCards).subscribe(timeCards => {
        this.timeCards = timeCards;
        if (this.timeCards) {
          this.hasAnyClientService = false;
          this.hasAnyFixReason = false;
          this.hasAnyReply = false;
          this.hasAnyPayableFixRequest = false;
          this.hasAnyDocumentationFixRequest = false;
          for (const timecard of this.timeCards) {
            this.hasAnyClientService = this.hasAnyClientService || timecard.isClientServiceTime;
            this.hasAnyFixReason = this.hasAnyFixReason || !!timecard.fixReason;
            this.hasAnyReply = this.hasAnyReply || !!timecard.reply;
            this.hasAnyPayableFixRequest = this.hasAnyPayableFixRequest || !!timecard.payableFixRequest;
            this.hasAnyDocumentationFixRequest = this.hasAnyDocumentationFixRequest || !!timecard.documentationFixRequest;

          }
        }
        this.additing = false;
      }),
      this.store.select(s => s.timecardState.totalTime).subscribe(totalTime => {
        this.totalTime = totalTime;
      }),
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.hasClockPunchRight = permissionIDs && !!permissionIDs.find(p => p === 157);
      })
    );
  }

  changePeriod(periodID: number) {
    this.store.dispatch(changePayPeriod({ periodID }));
  }

  goToClientServiceNoteUrl() {
    window.location.href = getOptoLinkFromUrl() + "/TreatmentReports/ClientServiceNote/Form";
  }

  addTimecard() {
    this.additing = true;
    this.store.dispatch(selectTimeCardToAdd({ timeCard: {} as TimeCardModel }));
  }

  editTimeCard(timeCard: TimeCardModel) {
    if (timeCard.isClientServiceNote) {
      window.location.href = getOptoLinkFromUrl() + "/TreatmentReports/ClientServiceNote/Form?timeCardId=" + timeCard.id;
    }
    else {
      this.additing = true;
      this.store.dispatch(selectTimeCardToAdd({ timeCard }));
      window.scrollTo(0, 0);
    }
  }

  deleteTimeCard(timeCard: TimeCardModel) {
    this.alertService.confirm('Delete time card entry',
      'Are you sure you want to delete this time card entry?')
      .subscribe(
        answer => {
          if (answer) {
            this.store.dispatch(deleteTimeCard({ timeCardID: timeCard.id }));
          }
        }, error => {
        }
      );
  }

  fixTimeCard(timeCard: TimeCardModel) {
    this.fixing = true;
    this.store.dispatch(selectTimeCardToFix({ timeCard }));
    window.scrollTo(0, 0);
  }

}
