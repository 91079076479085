import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { Store } from '@ngrx/store';
import { State } from 'src/app/core/store';
import { UserProfileModel } from '../../../../models/userProfileModel';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { AlertService } from 'src/app/shared/services/alert.service';
import { downloadFile, scrollToId } from 'src/app/helpers/utils';
import { ActivatedRoute, Router } from '@angular/router';
import { getCertificationPdf, loadCertificationUploads } from '../../../../core/store/certification/certification.action';
import { CertificationFileModel, CertificationTypeUploadModel } from '../../../../models/certificationModel';

@Component({
  selector: 'app-uploads-certification',
  templateUrl: './uploads-certification.component.html',
  styleUrls: ['./uploads-certification.component.scss']
})
export class UploadsCertificationComponent extends BaseComponent implements OnInit {

  uploadedFiles: CertificationTypeUploadModel[];
  loadingUploadedFiles: boolean = true;
  downloadingFile: boolean = false;

  @Input() managedUserId: number;
  @Input() profile: UserProfileModel;

  constructor(
    private actions$: Actions,
    private alertService: AlertService,
    private store: Store<State>,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    super();
  }

  ngOnInit(): void {

    this.subs.push(
      this.store.select(s => s.certificationState.uploadedFiles).subscribe(files => {
        this.uploadedFiles = files;
      }),
      this.store.select(s => s.certificationState.loadingUploadedFiles).subscribe(loadingFiles => {
        this.loadingUploadedFiles = loadingFiles;
      }),
      this.store.select(s => s.certificationState.loadingPdf).subscribe(loadingPdf => {
        this.downloadingFile = loadingPdf;
      }),
    );

    this.store.dispatch(loadCertificationUploads({ userId: this.managedUserId }));
  }


  getPreviewUrl(file: CertificationFileModel) {
    if (file.viewUrl) {
      return file.viewUrl;
    }

    if (file.fileType.indexOf('pdf') >= 0) {
      return '/assets/img/pdf.png';
    }

    if (file.fileType.indexOf('application/vnd.openxmlformats-officedocument.wordprocessingml.document') >= 0
      || file.fileType.indexOf('doc') >= 0
      || file.fileType.indexOf('msword') >= 0) {
      return '/assets/img/doc.png';
    }
  }

  downloadFile(file: CertificationFileModel) {
    this.store.dispatch(getCertificationPdf({ certificationId: file.id }));   
  }

}
