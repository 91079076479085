import { AlertService } from './../../../shared/services/alert.service';
import { ProfileService } from '../../services/profile.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, tap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  loadSurveyInfo, loadSurveyInfoSuccess, loadSurveyInfoFail, saveSurveyAnswer,
  saveSurveyAnswerSuccess, saveSurveyAnswerFail, loadMyUnansweredSurvey,
  loadMyUnansweredSurveySuccess, loadMyUnansweredSurveyFail, loadSurveyReport,
  loadSurveyReportSuccess, loadSurveyReportFail, loadSurveyReportDetail,
  loadSurveyReportDetailSuccess, loadSurveyReportDetailFail
} from './survey.actions';
import { SurveyService } from '../../services/survey.service';
import { errorHappened } from '../profile/profile.actions';
import { Store } from '@ngrx/store';
import { State } from '..';

@Injectable()
export class SurveyEffects {
  constructor(
    private actions$: Actions,
    private surveyService: SurveyService,
    private store: Store<State>,
    private alertService: AlertService) { }

  loadSurveyInfo = createEffect(() => this.actions$.pipe(
    ofType(loadSurveyInfo),
    switchMap(action => this.surveyService.loadSurveyInfo(action.surveyID).pipe(
      map(survey => loadSurveyInfoSuccess({ survey })),
      catchError(err => {
        this.store.dispatch(loadSurveyInfoFail());
        return of(errorHappened({ err }));
      })))
  ));

  saveSurveyAnswer = createEffect(() => this.actions$.pipe(
    ofType(saveSurveyAnswer),
    switchMap(action => this.surveyService.saveAnswer(action.answer).pipe(
      map(result => saveSurveyAnswerSuccess()),
      catchError(err => {
        this.store.dispatch(saveSurveyAnswerFail());
        return of(errorHappened({ err }));
      })
    ))));

  loadMyUnsweredSurvey = createEffect(() => this.actions$.pipe(
    ofType(loadMyUnansweredSurvey),
    switchMap(action => this.surveyService.loadMyUnansweredSurveys().pipe(
      map(surveys => loadMyUnansweredSurveySuccess({ surveys })),
      catchError(err => {
        this.store.dispatch(loadMyUnansweredSurveyFail());
        return of(errorHappened({ err }));
      })))
  ));

  loadSurveyReport = createEffect(() => this.actions$.pipe(
    ofType(loadSurveyReport),
    switchMap(action => this.surveyService.loadSurveyReport(action.filter).pipe(
      map(surveyReport => loadSurveyReportSuccess({ surveyReport })),
      catchError(err => {
        this.store.dispatch(loadSurveyReportFail());
        return of(errorHappened({ err }));
      })))
  ));

  loadSurveyReportDetail = createEffect(() => this.actions$.pipe(
    ofType(loadSurveyReportDetail),
    switchMap(action => this.surveyService.loadSurveyReportDetail(action.filter).pipe(
      map(surveyReportDetail => loadSurveyReportDetailSuccess({ surveyReportDetail })),
      catchError(err => {
        this.store.dispatch(loadSurveyReportDetailFail());
        return of(errorHappened({ err }));
      })))
  ));

}
