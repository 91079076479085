<form class="card mt-2">
  <div class="card-body">
    <div class="float-right">
      <button type="button" routerLink="/certification/events" class="btn btn-secondary mr-2">Events</button>
      <button *ngIf="canManage" type="button" routerLink="/certification/instructors" class="btn btn-secondary">Instructors</button>
    </div>
    <h2 class="mb-3">Certification Report</h2>
    <div class="mb-3">

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Campus:</h5>
        </div>
        <div class="col-10">
          <i *ngIf="!campusList" class="material-icons rotate text-muted">loop</i>
          <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="campusList" [(ngModel)]="campusId" (ngModelChange)="reloadFilter(this.filter)" [ngModelOptions]="{standalone: true}"></p-dropdown>
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Certification Type:</h5>
        </div>
        <div class="col-10">
          <i *ngIf="!certificationTypes" class="material-icons rotate text-muted">loop</i>
          <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="certificationTypes" [(ngModel)]="certificationTypeId" (ngModelChange)="reloadFilter(this.filter)" [ngModelOptions]="{standalone: true}"></p-dropdown>
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Instructor:</h5>
        </div>
        <div class="col-10">
          <div class="ml-2 w-100 mb-2">
            <p-autoComplete [style]="{'width': '100%'}" [inputStyle]="{'width': '100%'}" [(ngModel)]="instructorPrefixText"
                            [suggestions]="instructorList" (completeMethod)="getInstructorList($event)" field="label"
                            [ngModelOptions]="{standalone: true}" [minLength]="1" [autoHighlight]="true"
                            (onSelect)="reloadListAfterInstructorChange($event)" [forceSelection]="true"
                            (onClear)="reloadListAfterInstructorClear($event)"></p-autoComplete>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Employee:</h5>
        </div>
        <div class="col-10">
          <div class="ml-2 w-100 mb-2">
            <p-autoComplete [style]="{'width': '100%'}" [inputStyle]="{'width': '100%'}" [(ngModel)]="prefixText"
                            [suggestions]="userEmployeeList" (completeMethod)="getStaffList($event)" field="label"
                            [ngModelOptions]="{standalone: true}" [minLength]="1" [autoHighlight]="true"
                            (onSelect)="reloadListAfterStaffChange($event)" [forceSelection]="true"
                            (onClear)="reloadListAfterClear($event)"></p-autoComplete>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Issue Date:</h5>
        </div>
        <div class="col-10">
          <i *ngIf="!issueDateRangeList" class="material-icons rotate text-muted">loop</i>
          <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="issueDateRangeList" [(ngModel)]="issueDateRange" (ngModelChange)="changedIssueDateRange()" [ngModelOptions]="{standalone: true}"></p-dropdown>
        </div>
      </div>

      <div class="row" *ngIf="isCustomIssueDateRange">
        <div class="col-2 d-flex align-items-center">
          <h5 style="padding-left:10px;">Start Date:</h5>
        </div>
        <div class="col-10">
          <input type="date" class="form-control ml-2 w-100 mb-2" [(ngModel)]="issueStartDate" (blur)="reloadFilter(this.filter)" [ngModelOptions]="{standalone: true}" />
        </div>
        <div class="col-2 d-flex align-items-center">
          <h5 style="padding-left:10px;">End Date:</h5>
        </div>
        <div class="col-10">
          <input type="date" class="form-control ml-2 w-100 mb-2" [(ngModel)]="issueEndDate" (blur)="reloadFilter(this.filter)" [ngModelOptions]="{standalone: true}" />
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Expiration Date:</h5>
        </div>
        <div class="col-10">
          <i *ngIf="!expirationDateRangeList" class="material-icons rotate text-muted">loop</i>
          <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="expirationDateRangeList" [(ngModel)]="expirationDateRange" (ngModelChange)="changedExpirationDateRange()" [ngModelOptions]="{standalone: true}"></p-dropdown>
        </div>
      </div>

      <div class="row" *ngIf="isCustomExpirationDateRange">
        <div class="col-2 d-flex align-items-center">
          <h5 style="padding-left:10px;">Start Date:</h5>
        </div>
        <div class="col-10">
          <input type="date" class="form-control ml-2 w-100 mb-2" [(ngModel)]="expirationStartDate" (blur)="reloadFilter(this.filter)" [ngModelOptions]="{standalone: true}" />
        </div>
        <div class="col-2 d-flex align-items-center">
          <h5 style="padding-left:10px;">End Date:</h5>
        </div>
        <div class="col-10">
          <input type="date" class="form-control ml-2 w-100 mb-2" [(ngModel)]="expirationEndDate" (blur)="reloadFilter(this.filter)" [ngModelOptions]="{standalone: true}" />
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Show only active employees:</h5>
        </div>
        <div class="col-10" style="padding-left: 25px;">
          <p-checkbox [(ngModel)]="showOnlyActiveEmployees" (ngModelChange)="reloadFilter(this.filter)" [binary]="true" [ngModelOptions]="{standalone: true}"></p-checkbox>
        </div>
      </div>

    </div>

    <i [hidden]="!loadingList && !loadingExcel" class="material-icons rotate text-muted">loop</i>
    <button *ngIf="canManage" (click)="editCertification([])" class="btn btn-secondary mr-2 mb-2">New Certification</button>
    <button type="button" title="Export all pages to excel" class="btn btn-success mr-2 mb-2" (click)="exportToExcel()"><i class="far fa-file-excel"></i></button>
    <p-table [value]="list?.result" [responsive]="true" [paginator]="true" [rows]="20"
             [totalRecords]="list?.totalRecords" [lazy]="true" (onLazyLoad)="reloadFilter($event)">
      <ng-template pTemplate="header">
        <tr>
          <th width="175px"></th>
          <th [pSortableColumn]="'EmployeeName'">Employee <p-sortIcon [field]="'EmployeeName'"></p-sortIcon></th>
          <th [pSortableColumn]="'PrimaryProgramName'">Primary Campus <p-sortIcon [field]="'PrimaryProgramName'"></p-sortIcon></th>
          <th [pSortableColumn]="'CertificationType'">Certification Type<p-sortIcon [field]="'CertificationType'"></p-sortIcon></th>
          <th [pSortableColumn]="'InstructorName'">Instructor<p-sortIcon [field]="'InstructorName'"></p-sortIcon></th>
          <th [pSortableColumn]="'IssueDate'">Issue Date<p-sortIcon [field]="'IssueDate'"></p-sortIcon></th>
          <th [pSortableColumn]="'ExpirationDate'">Expiration Date<p-sortIcon [field]="'ExpirationDate'"></p-sortIcon></th>
          <th [pSortableColumn]="'DateUploaded'">Date Uploaded<p-sortIcon [field]="'DateUploaded'"></p-sortIcon></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-list>
        <tr>
          <td>
            <button class="btn btn-secondary mr-2" *ngIf="canManage" (click)="editCertification(list)" title="Renew Certification">
              <i class="fas fa-repeat"></i>
            </button>
            <button class="btn btn-secondary mr-2" *ngIf="canManage" (click)="deleteCertification(list)" title="Delete Certification">
              <i class="fas fa-trash-alt"></i>
            </button>
            <button class="btn btn-secondary mr-2" (click)="downloadCertification(list)" title="Download Certification">
              <i class="fas fa-file-download"></i>
            </button>            
          </td>
          <td>
            <span class="ui-column-title">Employee</span>
            {{list.firstName}} {{list.lastName}} <span style="font-style:italic; color: red; font: 0.9rem;">{{getTerminationString(list)}}</span>
          </td>
          <td>
            <span class="ui-column-title">Primary Campus</span>
            {{list.primaryProgramName}}
          </td>
          <td>
            <span class="ui-column-title">Certification Type</span>
            {{list.certificationType}}
          </td>
          <td>
            <span class="ui-column-title">Instructor</span>
            {{list.instructorFirstName}} {{list.instructorLastName}}
          </td>
          <td>
            <span class="ui-column-title">Issued Date</span>
            {{list.issueDate | date: 'MM/dd/yyyy'}}
          </td>
          <td>
            <span class="ui-column-title">Expiration Date</span>
            {{list.expirationDate | date: 'MM/dd/yyyy'}}
          </td>
          <td>
            <span class="ui-column-title">Date Uploaded</span>
            {{list.dateUploaded | date: 'MM/dd/yyyy @ hh:mm:ss a'}}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</form>

<app-edit-certification></app-edit-certification>
