import { CriticalNewsPostCommentModel, CriticalNewsPostModel, CriticalNewsPostReactionModel } from 'src/app/models/criticalNewsFeedModel';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { deepClone } from '../../helpers/utils';
import { environment } from '../../../environments/environment';
import { SelectItem } from 'primeng/api';
import { PagingResultsModel } from 'src/app/models/pagingResultsModel';

@Injectable({
  providedIn: 'root'
})
export class CriticalNewsFeedService {

  constructor(private http: HttpClient) { }

  loadPosts(filter: any): Observable<any> {
    if (!filter) {
      filter = {};
      filter.filters = {};
    }
    filter = deepClone(filter);

    return this.http.post<PagingResultsModel<CriticalNewsPostModel>>(`${environment.apiServer.webApiURL}/CriticalNews/LoadPosts`,
      filter)
  }

  filterFeed(filter: any): Observable<any> {
    if (!filter) {
      filter = {};
    }
    filter = deepClone(filter);

    return this.http.post<PagingResultsModel<CriticalNewsPostModel>>(`${environment.apiServer.webApiURL}/CriticalNews/FilterPosts`,
      filter)
  }

  refreshFeed(filter: any): Observable<any> {
    if (!filter) {
      let date = new Date();
      filter = {
        viewLength: filter.viewLength,
        filterType: filter.filterType,
        filterValue: filter.filterValue,
        sortBy: filter.sortBy,
        dateFilterValues: filter.dateFilterValues,
        syncDate: date.toISOString(),
      }
    }
    filter = deepClone(filter);

    return this.http.post<PagingResultsModel<CriticalNewsPostModel>>(`${environment.apiServer.webApiURL}/CriticalNews/RefreshFeed`,
      filter)
  }

  loadPinnedPosts(): Observable<any> {
    return this.http.get<CriticalNewsPostModel[]>(`${environment.apiServer.webApiURL}/CriticalNews/LoadPinnedPosts`)
  }

  loadUserPinnedPosts(): Observable<any> {
    return this.http.get<CriticalNewsPostModel[]>(`${environment.apiServer.webApiURL}/CriticalNews/LoadUsersPinnedPosts`)
  }

  unpinPost(id: number): Observable<any> {
    return this.http.get<CriticalNewsPostModel[]>(`${environment.apiServer.webApiURL}/CriticalNews/UnpinPost/${id}`)
  }

  unpinUserPost(id: number): Observable<any> {
    return this.http.get<CriticalNewsPostModel[]>(`${environment.apiServer.webApiURL}/CriticalNews/UnpinUserPost/${id}`)
  }

  pinPost(id: number): Observable<any> {
    return this.http.get<CriticalNewsPostModel[]>(`${environment.apiServer.webApiURL}/CriticalNews/PinPost/${id}`)
  }

  pinUserPost(id: number): Observable<any> {
    return this.http.get<CriticalNewsPostModel[]>(`${environment.apiServer.webApiURL}/CriticalNews/PinUserPost/${id}`)
  }

  unpinComment(id: number): Observable<any> {
    return this.http.get<CriticalNewsPostCommentModel>(`${environment.apiServer.webApiURL}/CriticalNews/UnpinComment/${id}`)
  }

  pinComment(id: number, postId: number): Observable<any> {
    return this.http.get<CriticalNewsPostCommentModel>(`${environment.apiServer.webApiURL}/CriticalNews/PinComment/${id}/${postId}`)
  }

  archivePost(id: number): Observable<any> {
    return this.http.get<CriticalNewsPostModel>(`${environment.apiServer.webApiURL}/CriticalNews/ArchivePost/${id}`)
  }

  archiveComment(id: number): Observable<any> {
    return this.http.get<CriticalNewsPostCommentModel>(`${environment.apiServer.webApiURL}/CriticalNews/ArchiveComment/${id}`)
  }

  savePost(post: CriticalNewsPostModel): Observable<any> {
    return this.http.post<CriticalNewsPostModel>(`${environment.apiServer.webApiURL}/CriticalNews/SavePost`,
    post)
  }

  savePostWithFiles(post: CriticalNewsPostModel, files: File[]): Observable<any> {
    const formData = new FormData();
    let i = 0;

    for (var file of files){
      formData.append('files.Index', `${i}`);
      formData.append(`files[${i}].File`, file);
      for (const key in post) {
        if (key == "newFileNames" && post[key] != null && post[key].length > 0){
          let fileNames = post[key];
          let index = 0
          for (const file of fileNames)
          {
            for (const k in file){
              formData.append(`files[${i}].${key}[${index}].${k}`, file[k]);
            }
            index++;
          }
        }
        else if (key == "otherFileUrls" && post[key] != null && post[key].length > 0){
          let fileUrls = post[key];
          let index = 0
          for (const file of fileUrls)
          {
            for (const k in file){
              formData.append(`files[${i}].${key}[${index}].${k}`, file[k]);
            }
            index++;
          }
        }
        else if (key == "fileIdsToRemove" && post[key] != null && post[key].length > 0){
          let ids = post[key];
          let index = 0
          for (const file of ids)
          {
            for (const k in file){
              formData.append(`files[${i}].${key}[${index}].${k}`, file[k]);
            }
            index++;
          }
        }
        else if (key == "newLinks" && post[key] != null && post[key].length > 0){
          let ids = post[key];
          let index = 0
          for (const file of ids)
          {
            for (const k in file){
              formData.append(`files[${i}].${key}[${index}].${k}`, file[k]);
            }
            index++;
          }
        }
        else if (key == "currentLinks" && post[key] != null && post[key].length > 0){
          let ids = post[key];
          let index = 0
          for (const file of ids)
          {
            for (const k in file){
              formData.append(`files[${i}].${key}[${index}].${k}`, file[k]);
            }
            index++;
          }
        }
        else {
          formData.append(`files[${i}].` + key, post[key]);
        }
      }
      i++;
    }

    const options = { headers: new HttpHeaders() };
    options.headers.append('enctype', 'multipart/form-data');

    return this.http.post<CriticalNewsPostModel>(`${environment.apiServer.webApiURL}/CriticalNews/SavePostWithFile`, formData, options);
  }

  saveReaction(reaction: CriticalNewsPostReactionModel): Observable<any> {
    return this.http.post<CriticalNewsPostReactionModel>(`${environment.apiServer.webApiURL}/CriticalNews/SaveReaction`,
    reaction)
  }

  deleteReaction(reaction: CriticalNewsPostReactionModel): Observable<any> {
    return this.http.post<CriticalNewsPostReactionModel>(`${environment.apiServer.webApiURL}/CriticalNews/DeleteReaction`,
    reaction)
  }

  saveComment(comment: CriticalNewsPostCommentModel): Observable<any> {
    return this.http.post<CriticalNewsPostCommentModel>(`${environment.apiServer.webApiURL}/CriticalNews/SaveComment`,
    comment)
  }
}
