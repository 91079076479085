import { PagingResultsModel } from './../../models/pagingResultsModel';
import { UserModel } from 'src/app/models/userModel';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CoachingLogWithUsersModel } from 'src/app/models/coachingLogWithUsersModel';
import { CoachingLogCategoryModel } from '../../models/coachingLogCategoryModel';


@Injectable()
export class CoachingLogService {

  constructor(private http: HttpClient) {
  }

  getMyGivenCoches(filters: any): Observable<PagingResultsModel<CoachingLogWithUsersModel>> {
    return this.http.post<PagingResultsModel<CoachingLogWithUsersModel>>
      (`${environment.apiServer.webApiURL}/CoachingLog/MyGivenCoachLogs`, filters);
  }

  getMyCoches(filters: any): Observable<PagingResultsModel<CoachingLogWithUsersModel>> {
    return this.http.post<PagingResultsModel<CoachingLogWithUsersModel>>
      (`${environment.apiServer.webApiURL}/CoachingLog/MyCoachLogs`, filters);
  }

  markAsChecked(coachingLogID: number): Observable<CoachingLogWithUsersModel> {
    return this.http.get<CoachingLogWithUsersModel>(`${environment.apiServer.webApiURL}/CoachingLog/MarkAsChecked/${coachingLogID}`);
  }

  markAsSeen(coachingLogID: number): Observable<CoachingLogWithUsersModel> {
    return this.http.get<CoachingLogWithUsersModel>(`${environment.apiServer.webApiURL}/CoachingLog/MarkAsSeen/${coachingLogID}`);
  }

  getCategories(levelID: number): Observable<CoachingLogCategoryModel[]> {
    return this.http.get<CoachingLogCategoryModel[]>(`${environment.apiServer.webApiURL}/CoachingLog/Categories/${levelID}`);
  }

  getUserCoachingLogs(userID: number): Observable<CoachingLogWithUsersModel[]> {
    return this.http.get<CoachingLogWithUsersModel[]>(`${environment.apiServer.webApiURL}/CoachingLog/UserCoachingLogs/${userID}`);
  }

  addCoachingLog(coachingLog: CoachingLogWithUsersModel) {
    return this.http.post<CoachingLogWithUsersModel>(`${environment.apiServer.webApiURL}/CoachingLog`, coachingLog);
  }

  updateCoachingLog(coachingLog: CoachingLogWithUsersModel) {
    return this.http.put<CoachingLogWithUsersModel>(`${environment.apiServer.webApiURL}/CoachingLog`, coachingLog);
  }

  getCoachableUsers(): Observable<UserModel[]> {
    return this.http.get<UserModel[]>(`${environment.apiServer.webApiURL}/CoachingLog/MyCoachableUsers`);
  }
  getCoachingLog(coachingLogID: number): Observable<CoachingLogWithUsersModel> {
    return this.http.get<CoachingLogWithUsersModel>(`${environment.apiServer.webApiURL}/CoachingLog/${coachingLogID}`);
  }

}
