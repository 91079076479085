<form class="card mt-2" *ngIf="canView">
  <div class="card-body">
      <h2 class="mb-3">Manage Documents</h2>
    <i *ngIf="loadingAllDocs" class="material-icons rotate text-muted">loop</i>
    <p-table  [value]="onboardingDocs?.result" [responsive]="true"
    [paginator]="true" [rows]="25" [totalRecords]="onboardingDocs?.totalRecords" [lazy]="true"
    (onLazyLoad)="loadPage($event)">
        <ng-template pTemplate="header">
            <tr>
                <th width="70px"></th>
                <th [pSortableColumn]="'title'">Title
                  <p-sortIcon [field]="'title'"></p-sortIcon>
                </th>
                <!-- <th width="90px" [pSortableColumn]="'active'">Active
                <p-sortIcon [field]="'active'"></p-sortIcon></th> -->
                <th  width="90px">Renew After</th>
                <th  width="90px">Required</th>
                <th  width="100px">Default Doc</th>
                <th  width="100px">Credential Doc</th>
                <th width="120px">Roles</th>
                <th  width="120px">Not in Roles</th>
                <th  width="135px">Roles Required</th>
                <th  width="135px">Roles Not Required</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-onboardingDoc>
            <tr >
                <td>
                  <span class="pointer mr-2" *ngIf="canEdit"   (click)="editDoc(onboardingDoc)"><i title="Edit doc" class="fas fa-edit"></i></span>
                </td>
                <td>
                  <span class="ui-column-title">Title</span>
                  {{onboardingDoc.onBoardingDocument.title}}
                </td>
                <!-- <td>
                    <span class="ui-column-title">Active</span>
                    <i *ngIf="!!onboardingDoc.onBoardingDocument.active" class="fas fa-check-circle text-success" title="Active"></i>
                    <i *ngIf="!onboardingDoc.onBoardingDocument.active" class="fas fa-minus-circle text-muted" title="Inactive"></i>
                </td> -->
                <td>
                  <span class="ui-column-title">Renew after</span>
                  {{onboardingDoc.onBoardingDocument.renewAfterDays?(onboardingDoc.onBoardingDocument.renewAfterDays+' days'):'-'}}
                </td>

                <td>
                  <span class="ui-column-title">Required</span>
                  <i *ngIf="!!onboardingDoc.onBoardingDocument.required" class="fas fa-check-circle text-success" title="Required"></i>
                  <i *ngIf="!onboardingDoc.onBoardingDocument.required" class="fas fa-minus-circle text-muted" title="Not Required"></i>
              </td>
                <td>
                    <span class="ui-column-title">Default Doc</span>
                    <i *ngIf="!!onboardingDoc.onBoardingDocument.isDefaultDoc" class="fas fa-check-circle text-success" title="Default Doc"></i>
                    <i *ngIf="!onboardingDoc.onBoardingDocument.isDefaultDoc" class="fas fa-minus-circle text-muted" title="-"></i>
                </td>

                <td>
                  <span class="ui-column-title">Credential Doc</span>
                  <i *ngIf="!!onboardingDoc.onBoardingDocument.isCredentialDoc" class="fas fa-check-circle text-success" title="Credential Doc"></i>
                  <i *ngIf="!onboardingDoc.onBoardingDocument.isCredentialDoc" class="fas fa-minus-circle text-muted" title="-"></i>
              </td>
                <td>
                  <span class="ui-column-title">Roles</span>
                  <span [title]="getRolesNames(onboardingDoc,'roles')">  {{getRolesNamesTruncated(getRolesNames(onboardingDoc,'roles'))}} </span>
                </td>
                <td>
                  <span class="ui-column-title">Not in Roles</span>
                  <span [title]="getRolesNames(onboardingDoc,'notInRoles')">  {{getRolesNamesTruncated(getRolesNames(onboardingDoc,'notInRoles'))}} </span>

                </td>
                <td>
                  <span class="ui-column-title">Required Roles</span>
                  <span [title]="getRolesNames(onboardingDoc,'requiredRoles')">  {{getRolesNamesTruncated(getRolesNames(onboardingDoc,'requiredRoles'))}} </span>

                </td>
                <td>
                  <span class="ui-column-title">Not Required Roles</span>
                  <span [title]="getRolesNames(onboardingDoc,'notRequiredRoles')">  {{getRolesNamesTruncated(getRolesNames(onboardingDoc,'notRequiredRoles'))}} </span>

                </td>

            </tr>
        </ng-template>
    </p-table>
  </div>
</form>
