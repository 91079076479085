import { AlertService } from './../../../shared/services/alert.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, tap, catchError, filter } from 'rxjs/operators';
import { of, empty, Observable } from 'rxjs';
import {
  loadTimeCards, loadTimeCardsSuccess, loadClients,
  loadClientsSuccess, clockInOut, clockInOutSuccess, getIsClockedIn,
  getIsClockedInSuccess, additTimeCardSuccess, additTimeCard, checkIP, checkIPSuccess, fixTimeCardSave, fixTimeCardSaveSuccess, deleteTimeCardSuccess, deleteTimeCard, loadManualEntryReasons, loadManualEntryReasonsSuccess, getManualEntriesReport, getManualEntriesReportSuccess, getManualEntriesReportFailure, exportManualEntriesReportToExcel, exportManualEntriesReportToExcelSuccess, exportManualEntriesReportToExcelFailure, loadOptionalPayableTypes, loadOptionalPayableTypesSuccess
} from './timecard.actions';
import { TimeCardService } from '../../services/timecard.service';
import { errorHappened } from '../profile/profile.actions';
import { Store, Action } from '@ngrx/store';
import { State } from '..';
import { identifierName } from '@angular/compiler';
import { TimeCardModel } from 'src/app/models/timeCardModel';

@Injectable()
export class TimeCardEffects {
  constructor(private actions$: Actions,
    private timeCardService: TimeCardService,
    private alertService: AlertService,
    private store: Store<State>) { }

  loadOptionalPayableTypes = createEffect(() => this.actions$.pipe(
    ofType(loadOptionalPayableTypes),
    switchMap(action => this.timeCardService.getOptionalPayableTypes().pipe(
      map(optionalPayableTypes => loadOptionalPayableTypesSuccess({ optionalPayableTypes })),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))));

  loadTimeCards = createEffect(() => this.actions$.pipe(
    ofType(loadTimeCards),
    switchMap(action => this.timeCardService.loadTimeCardsOnPeriod(action.periodID).pipe(
      map(timeCards => loadTimeCardsSuccess({ timeCards })),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))));


  loadClients = createEffect(() => this.actions$.pipe(
    ofType(loadClients),
    switchMap(action => this.timeCardService.loadClients().pipe(
      map(clients => loadClientsSuccess({ clients })),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))));

  loadManualEntriesReport = createEffect(() => this.actions$.pipe(
    ofType(getManualEntriesReport),
    switchMap(action => {
      return this.timeCardService.getManualEntriesReport(action.filter).pipe(
        map(list => getManualEntriesReportSuccess({ list })),
        catchError(err => {
          this.store.dispatch(getManualEntriesReportFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  downloadManualEntriesReport = createEffect(() => this.actions$.pipe(
    ofType(exportManualEntriesReportToExcel),
    switchMap(action => {
      return this.timeCardService.exportManualEntriesReportToExcel(action.filter).pipe(
        map(doc => exportManualEntriesReportToExcelSuccess({ doc })),
        catchError(err => {
          this.store.dispatch(exportManualEntriesReportToExcelFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  loadManualEntryReasons = createEffect(() => this.actions$.pipe(
    ofType(loadManualEntryReasons),
    switchMap(action => this.timeCardService.loadManualEntryReasons().pipe(
      map(reasons => loadManualEntryReasonsSuccess({ reasons })),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))));

  clockInOut = createEffect(() => this.actions$.pipe(
    ofType(clockInOut),
    switchMap(action => this.timeCardService.clockInOut(action.payableTypeId).pipe(
      map(timeCard => clockInOutSuccess({ timeCard })),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))));



  clockInOutSuccess = createEffect(() => this.actions$.pipe(
    ofType(clockInOutSuccess),
    map(action => getIsClockedIn())
  ));


  getIsClockedIn = createEffect(() => this.actions$.pipe(
    ofType(getIsClockedIn),
    switchMap(action => this.timeCardService.getIsClockedIn().pipe(
      map(isClockedIn => getIsClockedInSuccess({ isClockedIn })),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))));

  getIsClockedInSuccess = createEffect(() => this.actions$.pipe(
    ofType(getIsClockedInSuccess),
    map(action => {
      let periodID;
      this.store.select(s => s.payPeriodState.selectedPeriodID).subscribe(id => {
        periodID = id;
      });
      if (periodID) {
        return loadTimeCards({ periodID });
      }
    }),
    filter(action => !!action)
  ));

  additTimeCard = createEffect(() => this.actions$.pipe(
    ofType(additTimeCard),
    switchMap(action => {
      let observable: Observable<TimeCardModel>;
      if (action.timeCard.id) {
        observable = this.timeCardService.editTimeCard(action.timeCard);
      } else {
        observable = this.timeCardService.addTimeCard(action.timeCard);
      }
      return observable.pipe(
        map(timeCard => additTimeCardSuccess({ timeCard })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })));


  additTimeCardSuccess = createEffect(() => this.actions$.pipe(
    ofType(additTimeCardSuccess),
    map(action => {
      this.alertService.success('Saved clock punch');
      let periodID;
      this.store.select(s => s.payPeriodState.selectedPeriodID).subscribe(id => {
        periodID = id;
      });
      if (periodID) {
        return loadTimeCards({ periodID });
      }
    }),
    filter(action => !!action)
  ));

  checkIP = createEffect(() => this.actions$.pipe(
    ofType(checkIP),
    switchMap(action => this.timeCardService.checkIP().pipe(
      map(valid => checkIPSuccess({ valid })),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))));

  fixTimeCardSave = createEffect(() => this.actions$.pipe(
    ofType(fixTimeCardSave),
    switchMap(action => this.timeCardService.fixTimeCard(action.fixReason, action.timecardID).pipe(
      map(timeCard => fixTimeCardSaveSuccess({ timeCard })),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))));


fixTimeCardSaveSuccess = createEffect(() => this.actions$.pipe(
  ofType(fixTimeCardSaveSuccess),
  map(action => {
    this.alertService.success('Saved fix');
    let periodID;
    this.store.select(s => s.payPeriodState.selectedPeriodID).subscribe(id => {
      periodID = id;
    });
    if (periodID) {
      return loadTimeCards({ periodID });
    }
  }),
  filter(action => !!action)
));


deleteTimeCard = createEffect(() => this.actions$.pipe(
  ofType(deleteTimeCard),
  switchMap(action =>
    this.timeCardService.deleteTimeCard(action.timeCardID).pipe(
      map(timeCard => deleteTimeCardSuccess()),
      catchError(err => {
        return of(errorHappened({ err }));
      }))
  )));


  deleteTimeCardSuccess = createEffect(() => this.actions$.pipe(
  ofType(deleteTimeCardSuccess),
  map(action => {
    this.alertService.success('Clock punch deleted');
    let periodID;
    this.store.select(s => s.payPeriodState.selectedPeriodID).subscribe(id => {
      periodID = id;
    });
    if (periodID) {
      return loadTimeCards({ periodID });
    }
  }),
  filter(action => !!action)
));
}
