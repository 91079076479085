import { EquipmentModel, AssignedEquipmentModel, EquipmentWithAssignmentsModel, EquipmentClassCodeModel, EquipmentClassificationModel, EquipmentTypeModel } from './../../models/equipmentsModel';
import { PagingResultsModel } from './../../models/pagingResultsModel';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { deepClone } from 'src/app/helpers/utils';
import { CampusModel } from '../../models/campusModel';
import { EquipmentPermissionsModel } from 'src/app/models/equipmentTypePermissionModel';


@Injectable()
export class EquipmentService {

  constructor(private http: HttpClient) {
  }

  getAllCampuses(): Observable<CampusModel[]> {
    return this.http.get<CampusModel[]>(`${environment.apiServer.webApiURL}/Equipment/AllCampuses`);
  }

  loadEquipmentPermissionModalOptions(): Observable<any>{
    return this.http.get<any>(`${environment.apiServer.webApiURL}/Equipment/LoadEquipmentPermissionModalOptions`);
  }

  saveEquipmentPermissions(equipmentPermissions: EquipmentPermissionsModel): Observable<EquipmentPermissionsModel>{
    return this.http.post<any>(`${environment.apiServer.webApiURL}/Equipment/SaveEquipmentPermissions`, equipmentPermissions);
  }

  loadEquipmentPermissions(userId: number, roleId: number): Observable<any>{
    let route;
    if(userId != null){
      route = "User"
    }
    else{
      route = "Role"
    }
    return this.http.post<any>(`${environment.apiServer.webApiURL}/Equipment/LoadEquipmentPermissions${route}`, {userId: userId, roleId: roleId});
  }

  getSearchEquipments(filterSerial: string): Observable<PagingResultsModel<EquipmentModel>> {
    return this.http.post<PagingResultsModel<EquipmentModel>>(`${environment.apiServer.webApiURL}/Equipment/SearchEquipments`,
      { searchFilter: filterSerial });
  }

  createEquipment(equipment: EquipmentModel): Observable<EquipmentModel> {
    return this.http.post<EquipmentModel>(`${environment.apiServer.webApiURL}/Equipment/Equipment/`,
      equipment);
  }

  editEquipment(equipment: EquipmentModel): Observable<EquipmentModel> {
    return this.http.put<EquipmentModel>(`${environment.apiServer.webApiURL}/Equipment/Equipment/`,
      equipment);
  }

  deleteEquipment(equipment: EquipmentModel): Observable<any> {
    return this.http.delete<any>(`${environment.apiServer.webApiURL}/Equipment/Equipment/${equipment.id}`);
  }

  unarchiveEquipment(equipment: EquipmentModel): Observable<any> {
    return this.http.put<any>(`${environment.apiServer.webApiURL}/Equipment/UnarchiveEquipment/`, equipment);
  }

  getMyAssignedEquipments(userID: number): Observable<AssignedEquipmentModel[]> {
    return this.http.get<AssignedEquipmentModel[]>(`${environment.apiServer.webApiURL}/Equipment/AssignedEquipments/${userID}`);
  }

  getAllEquipments(filter: any)
    : Observable<PagingResultsModel<EquipmentWithAssignmentsModel>> {
    filter = deepClone(filter);
    if (!filter) {
      filter.filters = {};
    }

    
    return this.http.post<PagingResultsModel<EquipmentWithAssignmentsModel>>(`${environment.apiServer.webApiURL}/Equipment/AllEquipments`,
      filter
    );
  }

  getEquipmentTypes(){
    return this.http.get<EquipmentTypeModel[]>(`${environment.apiServer.webApiURL}/Equipment/GetEquipmentTypes`);
  }

  getAllEquipmentsExcel(filter: any)
    : Observable<PagingResultsModel<EquipmentWithAssignmentsModel>> {
    filter = deepClone(filter);
    if (!filter) {
      filter.filters = {};
    }
    return this.http.post<PagingResultsModel<EquipmentWithAssignmentsModel>>(`${environment.apiServer.webApiURL}/Equipment/AllExcelEquipments`,
      filter
    );
  }

  getAllClassCodes(): Observable<EquipmentClassCodeModel[] > {
    return this.http.get<EquipmentClassCodeModel[]>(`${environment.apiServer.webApiURL}/Equipment/AllClassCode`);
  }

  getAllClassifications(): Observable<EquipmentClassificationModel[]> {
    return this.http.get<EquipmentClassificationModel[]>(`${environment.apiServer.webApiURL}/Equipment/AllClassifications`);
  }

  assignEquipments(assignedEquipments: AssignedEquipmentModel[]): Observable<AssignedEquipmentModel[]> {
    return this.http.post<AssignedEquipmentModel[]>(`${environment.apiServer.webApiURL}/Equipment/AssignedEquipments`,
      assignedEquipments);
  }

  editAssignEquipments(assignedEquipments: AssignedEquipmentModel[]): Observable<AssignedEquipmentModel[]> {
    return this.http.post<AssignedEquipmentModel[]>(`${environment.apiServer.webApiURL}/Equipment/EditAssignedEquipments`,
      assignedEquipments);
  }

  returnEquipments(returnedEquipments: AssignedEquipmentModel[]): Observable<AssignedEquipmentModel[]> {
    return this.http.post<AssignedEquipmentModel[]>(`${environment.apiServer.webApiURL}/Equipment/AssignedEquipmentsReturned`,
      returnedEquipments);
  }

  getEquipmentAssignDocument(equipmentAssignID: number, returned: boolean): Observable<ArrayBuffer> {
    if (returned) {
      return this.http.get<ArrayBuffer>(
        `${environment.apiServer.webApiURL}/Equipment/EquipmentAssignedReturnedDocument/${equipmentAssignID}`,
        { responseType: 'arraybuffer' as 'json' });
    } else {
      return this.http.get<ArrayBuffer>(`${environment.apiServer.webApiURL}/Equipment/EquipmentAssignedDocument/${equipmentAssignID}`,
        { responseType: 'arraybuffer' as 'json' });
    }
  }

  signAssignedDocument(equipmentAssignID: number, signedFields: string[]): Observable<ArrayBuffer> {
    return this.http.post<ArrayBuffer>(`${environment.apiServer.webApiURL}/Equipment/SignEquipmentAssigned/${equipmentAssignID}`,
      signedFields, { responseType: 'arraybuffer' as 'json' });
  }
  signReturnDocument(equipmentAssignID: number, signedFields: string[]): Observable<ArrayBuffer> {
    return this.http.post<ArrayBuffer>(`${environment.apiServer.webApiURL}/Equipment/SignEquipmentAssignedReturned/${equipmentAssignID}`,
      signedFields, { responseType: 'arraybuffer' as 'json' });
  }
}
