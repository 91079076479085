import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { State } from '../../../../core/store';
import { Store } from '@ngrx/store';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { TimeOffApprovalModel, TimeOffModel } from '../../../../models/timeoffModel';
import { initApproveTimeOffRequestForm } from '../../../../core/store/timeoff/timeoff.reducer';
import { deepClone, getAllControlsErrors, markFormGroupTouched, updateFormGroupValidity } from '../../../../helpers/utils';
import { closeApproveTimeOffRequest, getTimeOffApprovalSummary, getTimeOffApprovalSummarySuccess, saveTimeOffApproval, saveTimeOffApprovalSuccess, updateTimeOffApprovalForm } from '../../../../core/store/timeoff/timeoff.actions';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-approve-timeoff-request',
  templateUrl: './approve-timeoff-request.component.html',
  styleUrls: ['./approve-timeoff-request.component.scss']
})
export class ApproveTimeoffRequestComponent extends BaseComponent implements OnInit {
  display: boolean = false;
  title: string;
  form: FormGroup;
  disableButtons: boolean = true;
  timeOffRequest: TimeOffModel = {} as TimeOffModel;
  timeOffId: number;  
  approvalSummary: TimeOffApprovalModel = {} as TimeOffApprovalModel;
  showRecentRequests: boolean = false;
  showUpcomingRequests: boolean = false;
  showOtherRequests: boolean = false;
  loadingData: boolean = false;
  summaryLoaded: boolean = false;
  @ViewChild('approvalDialog', { static: false }) dialog;

  constructor(
    private actions$: Actions,
    private store: Store<State>) {
    super();
  }

  ngOnInit() {
    this.form = initApproveTimeOffRequestForm({} as TimeOffModel);
    this.subs.push(
      this.store.select(s => s.timeoffState.timeOffApprovalSummary).subscribe(summary => {
        if (summary == null) {
          this.approvalSummary = {} as TimeOffApprovalModel;
          return;
        }
        
        this.approvalSummary = deepClone(summary);
        if (this.approvalSummary.timeOff != null) {          
          this.form = initApproveTimeOffRequestForm(this.approvalSummary.timeOff);
          this.subs.push(
            this.form.valueChanges.subscribe(formValues => {
              const formErrors = getAllControlsErrors(this.form);
              this.store.dispatch(updateTimeOffApprovalForm({ formValues, formErrors }));
            })
          );          
        }
      }), 
      this.store.select(s => s.timeoffState.approvingTimeOffRequest).subscribe(approvingTimeOffRequest => {
        this.display = approvingTimeOffRequest;
      }),
      this.store.select(s => s.timeoffState.savingTimeOffRequest).subscribe(savingTimeOffRequest => {
        this.disableButtons = savingTimeOffRequest;
      }),
      this.store.select(s => s.timeoffState.loadingTimeOffApprovalSummary).subscribe(loading => {
        this.loadingData = loading;
      }),
      this.store.select(s => s.timeoffState.selectedTimeOffApproval).subscribe(request => {
        if (request === null) {
          this.timeOffRequest = null;
          return;
        }

        const changedRequest = !this.timeOffRequest || (this.timeOffRequest.timeOffId !== request.timeOffId);
        this.timeOffRequest = JSON.parse(JSON.stringify(request));
        if (!changedRequest) return;

        this.timeOffId = request.timeOffId;
        this.title = "Time Off Approval #" + this.timeOffId;
        this.loadTimeOffApprovalSummaryInfo();   
      }),
      this.actions$.pipe(
        ofType(saveTimeOffApprovalSuccess),
        tap(() => {
          this.closeModal();
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(getTimeOffApprovalSummarySuccess),
        tap(() => {
          this.summaryLoaded = true;
        })
      ).subscribe(),
    );
  }

  ngAfterViewChecked() {
    if (this.summaryLoaded) {
      this.checkHeight();
      this.summaryLoaded = false;
    }
  }

  closeModal() {
    this.store.dispatch(closeApproveTimeOffRequest());
    this.approvalSummary = {} as TimeOffApprovalModel;
    this.showRecentRequests = false;
    this.showUpcomingRequests = false;
    this.showOtherRequests = false;
  }

  saveTimeOffApproval(approve) {   
    updateFormGroupValidity(this.form);
    if (this.form.valid) {
      this.timeOffRequest.approved = approve;
      this.timeOffRequest.denied = !approve;
      this.store.dispatch(saveTimeOffApproval({ timeOffRequest: this.timeOffRequest }));
    }
    else {
      markFormGroupTouched(this.form);
    }
  }

  loadTimeOffApprovalSummaryInfo() {
    if (this.timeOffId < 1) {
      return;
    }

    this.store.dispatch(getTimeOffApprovalSummary({ timeOffId: this.timeOffId }));
  }

  toggleShowRecentRequests() {
    this.showRecentRequests = !this.showRecentRequests
  }

  toggleShowUpcomingRequests() {
    this.showUpcomingRequests = !this.showUpcomingRequests;
  }

  toggleShowOtherRequests() {
    this.showOtherRequests = !this.showOtherRequests;
  }

  checkHeight() {
    let height = this.dialog.container.children[1].clientHeight;
    if (height > 750) {
      this.dialog.container.children[1].style.height = '750px';
      this.dialog.center();
    }
  }

  
}
