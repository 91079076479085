import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { TicketType, CreateTicketTypeModel, UpdateTicketTypeModel } from 'src/app/models/ticketTypeModels';
import { updateTicketType, loadTicketType, createTicketType } from 'src/app/core/store/ticket-types/ticket-types.actions';
import { State } from '../../../../core/store';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { SelectItem } from 'primeng';
import { getUsersList } from 'src/app/core/store/users/users.actions';


@Component({
    selector: 'app-ticket-types-addit',
    templateUrl: './ticket-types-addit.component.html',
    styleUrls: ['./ticket-types-addit.component.scss']
})
export class TicketTypeAdditComponent extends BaseComponent implements OnInit {
    ticketTypeForm: FormGroup;
    isEditMode: boolean = false;
    ticketTypeId: number;
    loadingForm: boolean = false;
    yesNoOptions = [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
    ];
    fieldTypesOptions = [
        { label: 'Text', value: 1 },
        { label: 'Big Text', value: 2 },
        { label: 'Date', value: 3 },
        { label: 'One Option', value: 4 },
        { label: 'Equipment', value: 5 },
    ];
    colorOptions = [
        { label: 'Orange', value: 'orange' },
        { label: 'Aqua', value: 'aqua' },
        { label: 'Green', value: 'green' },
        { label: 'Light Blue', value: 'lightblue' },
        { label: 'Dark Blue', value: 'darkblue' },
        { label: 'Dark Yellow', value: 'yellow' },
        
    ];  
    
  usersList: SelectItem[] = [{ label: 'loading...', value: undefined }];
  
    constructor(
        private fb: FormBuilder,
        private store: Store<State>,
        private route: ActivatedRoute,
        private router: Router
    ) {
        super();
        this.ticketTypeForm = this.fb.group({
            name: ['', Validators.required],
            description: ['', Validators.required],
            color: ['', Validators.required],
            customFields: this.fb.array([]),
            ticketStatuses: this.fb.array([]),
            users: this.fb.array([]),
        });
    }

    ngOnInit(): void {
        this.ticketTypeId = Number(this.route.snapshot.params['id']);
        this.isEditMode = !!this.ticketTypeId;


        this.subs.push(
            this.store.select(s => s.usersState.usersList).subscribe(usersList => {
                this.usersList = usersList ? usersList.map(u => ({ label: u.firstName + ' ' + u.lastName, value: u.id }))
                    : [{ label: 'loading...', value: undefined }];
                this.usersList.unshift({ label: '', value: null });
            }),);
        if (this.isEditMode) {
            this.subs.push(
                this.store.select(s => s.ticketTypesState).subscribe(ticketTypesState => {
                    const ticketType = ticketTypesState.selectedTicketType
                    if (ticketType) {
                        this.populateForm(ticketType);
                    }

                    this.loadingForm = ticketTypesState.loading;
                })
            );
            this.loadingForm = true
            this.store.dispatch(loadTicketType({ id: this.ticketTypeId }));
        }
        
    this.store.dispatch(getUsersList());
    }

    get customFields(): FormArray {
        return this.ticketTypeForm.get('customFields') as FormArray;
    }

    get ticketStatuses(): FormArray {
        return this.ticketTypeForm.get('ticketStatuses') as FormArray;
    }

    get users(): FormArray {
        return this.ticketTypeForm.get('users') as FormArray;
    }

    addCustomField(): void {
        this.customFields.push(this.fb.group({
            name: ['', Validators.required],
            fieldTypeId: [1, Validators.required],
            fieldOptions: [''],
            allowOther: [false],
        }));
    }

    removeCustomField(index: number): void {
        this.customFields.removeAt(index);
    }

    addStatus(): void {
        this.ticketStatuses.push(this.fb.group({
            name: ['', Validators.required],
            isFinal: [false],
            isDefault: [false],
            nextStatusIds: this.fb.array([]),
        }));
    }

    removeStatus(index: number): void {
        this.ticketStatuses.removeAt(index);
    }

    addUser(): void {
        this.users.push(this.fb.group({
            userID: [null, Validators.required],
            canBeAssigned: [false],
            canCreateTickets: [false],
            canManageTickets: [false],
            canChangeCreatedBy: [false],
        }));
    }

    removeUser(index: number): void {
        this.users.removeAt(index);
    }

    populateForm(ticketType: TicketType): void {
        this.ticketTypeForm.patchValue({
            name: ticketType.name,
            description: ticketType.description,
            color: ticketType.color
        });

        // Clear existing controls to avoid duplication
        this.customFields.clear();

        ticketType.customFields.forEach(field => {
            this.customFields.push(this.fb.group({
                name: [field.name, Validators.required],
                fieldTypeId: [field.fieldTypeId, Validators.required],
                fieldOptions: [field.fieldOptions],
                allowOther: [field.allowOther],
                id: [field.id],
            }));
        });

        this.ticketStatuses.clear();

        ticketType.ticketStatuses.forEach(status => {
            const nextStatusIds = this.fb.array(
                status.nextStatusIds.map(id => this.fb.control(id))
            );
            this.ticketStatuses.push(this.fb.group({
                name: [status.name, Validators.required],
                isFinal: [status.isFinal],
                isDefault: [status.isDefault],
                nextStatusIds: nextStatusIds,
                id: [status.id],
            }));
        });


        this.users.clear(); // Clear existing users
        ticketType.users.forEach(user => {
            this.users.push(this.fb.group({
                userID: [user.userID, Validators.required],
                canBeAssigned: [user.canBeAssigned],
                canCreateTickets: [user.canCreateTickets],
                canManageTickets: [user.canManageTickets],
                canChangeCreatedBy: [user.canChangeCreatedBy],
                id: [user.id],
            }));
        });
    }

    onSubmit(): void {
        if (this.ticketTypeForm.valid) {
            const ticketTypeData = this.ticketTypeForm.value;
            if (this.isEditMode) {
                const updateModel: UpdateTicketTypeModel = {
                    name: ticketTypeData.name,
                    description: ticketTypeData.description,
                    color: ticketTypeData.color,
                    customFields: ticketTypeData.customFields,
                    ticketStatuses: ticketTypeData.ticketStatuses,
                    users: ticketTypeData.users,
                };
                this.store.dispatch(updateTicketType({ id: this.ticketTypeId, ticketType: updateModel }));
            } else {
                const createModel: CreateTicketTypeModel = {
                    name: ticketTypeData.name,
                    description: ticketTypeData.description,
                    color: ticketTypeData.color,
                    customFields: ticketTypeData.customFields,
                    ticketStatuses: ticketTypeData.ticketStatuses,
                    users: ticketTypeData.users,
                };
                this.store.dispatch(createTicketType({ ticketType: createModel }));
            }
            this.router.navigate(['/ticket-types/list']);
        }
    }
}
