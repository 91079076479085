import { SpokenLanguageModel, UserTypeModel } from './../../models/userProfileModel';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { UserProfileModel, RaceEthnicityModel, JobTitleModel, ProfileOptionsModel } from '../../models/userProfileModel';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { dataURItoBlob } from 'src/app/helpers/utils';
import { ReturnEquipmentAlertModel } from 'src/app/models/equipmentsModel';


@Injectable()
export class ProfileService {

  constructor(private http: HttpClient) {
  }

  /**
   * Get the user login profile
   */
  getUserProfile(): Observable<UserProfileModel> {
    return this.http.get<UserProfileModel>(`${environment.apiServer.webApiURL}/Profile`);
  }

  saveProfile(profile: UserProfileModel): Observable<UserProfileModel> {
    return this.http.put<UserProfileModel>(`${environment.apiServer.webApiURL}/Profile/${profile.userId}`, profile);
  }

  saveSignature(data: any, userId?: number): Observable<UserProfileModel> {

    const formData = new FormData();
    formData.append('File', dataURItoBlob(data));
    formData.append('userId', userId ? userId.toString() : '0');

    const options = { headers: new HttpHeaders() };
    options.headers.append('enctype', 'multipart/form-data');
    return this.http.post<UserProfileModel>(`${environment.apiServer.webApiURL}/Profile/SaveSignature`, formData, options);
  }

  getReturnEquipmentAlerts(id: number){
    return this.http.get<ReturnEquipmentAlertModel>(`${environment.apiServer.webApiURL}/Profile/GetReturnEquipmentAlerts/${id}`)
  }

  changePassword(newPassword: string): Observable<any> {
    return this.http.put<UserProfileModel>(`${environment.apiServer.webApiURL}/Profile/ChangePassword`, { newPassword });
  }

  uploadPhoto(file: File) {
    const formData = new FormData();
    formData.append('File', file);

    const options = { headers: new HttpHeaders() };
    options.headers.append('enctype', 'multipart/form-data');

    return this.http.post(`${environment.apiServer.webApiURL}/Profile/UploadPhoto`, formData, options);
  }

  getPhoto(): Observable<{ url: string }> {
    return this.http.get<{ url: string }>(`${environment.apiServer.webApiURL}/Profile/GetPhoto`);
  }

  getSpokenLanguageOptions(): Observable<SpokenLanguageModel[]> {
    return this.http.get<SpokenLanguageModel[]>(`${environment.apiServer.webApiURL}/Profile/GetSpokenLanguageOptions`);
  }
  getRaceOptions(): Observable<RaceEthnicityModel> {
    return this.http.get<RaceEthnicityModel>(`${environment.apiServer.webApiURL}/Profile/GetRaceOptions`);
  }
  getJobTitles(): Observable<JobTitleModel> {
    return this.http.get<JobTitleModel>(`${environment.apiServer.webApiURL}/Profile/GetJobTitles`);
  }

  loadProfileOptions(): Observable<ProfileOptionsModel> {
    return this.http.get<ProfileOptionsModel>(`${environment.apiServer.webApiURL}/Profile/GetProfileOptions`);
  }

  getAllRoles(): Observable<UserTypeModel[]> {
    return this.http.get<UserTypeModel[]>(`${environment.apiServer.webApiURL}/Profile/GetAllRoles`);
  }
}
