<p-dialog [(visible)]="display" header="{{title}}" [modal]="true" [style]="{width: '70vw'}" (onHide)="closeModal()" [focusOnShow]="false">
  <form class="card mt-2" [formGroup]="form" (ngSubmit)="saveUploads()">
    <i [hidden]="!loadingUploads" class="material-icons rotate text-muted">loop</i>
    <div class="card-body">
      <div class="row">
        <div class="col-12">

          <div class="row mb-2">
            <div class="col-4 d-flex align-items-center" style="font-size: 1.1rem;">
              Uploads
              <label class="btn btn-link" style="margin-top: 9px;">
                <input type="file" hidden="hidden" multiple #fileInput accept="image/*,.pdf,.docx" (change)="changedFile(fileInput)" />
                Add Files
              </label>
            </div>
            <div class="col-8">
              <div *ngIf="form">
                <div formArrayName="uploads">
                  <div *ngFor="let u of getUploads(); let i=index;" class="mt-2" [formGroupName]="i">
                    {{u.value.fileName}}
                    <i class="fal fa-fw fa-trash pointer" style="color: red;" (click)="removeUpload(i)" title="Remove File"></i>
                  </div>
                </div>
                <div formArrayName="uploadedFiles">
                  <div *ngFor="let u of getUploadedFiles(); let i=index;" class="mt-2" [formGroupName]="i">
                    {{u.value.name}}
                    <i class="fal fa-fw fa-trash pointer" style="color: red;" (click)="removeUploaded(i)" title="Remove Added File"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </form>
  <p-footer>
    <i class="material-icons rotate text-muted ml-2" *ngIf="disableButtons">loop</i>
    <button class="btn btn-secondary" type="button" (click)="closeModal()">Cancel</button>
    <button class="btn btn-primary" type="submit" (click)="saveUploads()" [disabled]="disableButtons">Save</button>
  </p-footer>
</p-dialog>
