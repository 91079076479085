import {
  loadOnboardingUploads, uploadOnboardingDocSuccess, deleteOnboardingUpload, downloadOnboardingUpload, approveOnboardingUpload, denyOnboardingUpload, changeVisibilityOnboardingUpload, loadManageOnboardingUserInfo, uploadOnboardingDocFailure,
  requestVaccineCardExemption, getVaccineCardExemption, approveVaccineCardExemption, cancelVaccineCardExemption, cancelVaccineCardExemptionSuccess
} from './../../../../core/store/onboarding/onboarding.actions';
import { SelectItem } from 'primeng/api';
import { OnBoardingUploadType, OnBoardingUploadModel, OnBoardingUploadFileModel, LicenseInfosModel, VaccineCardExemptionModel } from './../../../../models/onBoardingModels';
import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'src/app/core/store';
import { loadProfile, getPhoto } from 'src/app/core/store/profile/profile.actions';
import { UserProfileModel } from 'src/app/models/userProfileModel';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { loadOnboardingUploadTypes, uploadOnboardingDoc } from 'src/app/core/store/onboarding/onboarding.actions';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { AlertService } from 'src/app/shared/services/alert.service';
import { downloadFile, scrollToId } from 'src/app/helpers/utils';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-onboarding-uploaddocs',
  templateUrl: './onboarding-uploaddocs.component.html',
  styleUrls: ['./onboarding-uploaddocs.component.scss']
})
export class OnboardingUploadDocsComponent extends BaseComponent implements OnInit {

  modalReference: any;
  docPreviewModalReference: any;
  modalOptions: NgbModalOptions;
  uploadTypes: OnBoardingUploadType[];
  uploadedFilesPerType = {};
  loadingUploadedFiles: boolean;
  uploadingDoc: boolean;
  hasSelectedFile = {};
  visibleToUser = {};
  deletingOnBoardingUploadId;
  changingDocTypeId;
  denyInfo = {};
  licenseInfos = {};
  panelingInfos = {};
  driversLicenseInfos = {};
  covidTestInfos = {};
  @Input() managedUserId: number;
  @Input() profile: UserProfileModel;
  @Input() readonly: boolean;
  @Input() showOnlyOnApprovalList: boolean;

  vacineExemptionFormOpened;
  exemptionFormNotes;
  exemptionFormType = '0';
  loadingVaccineCardExemption;
  today = new Date();
  viewDocumentURL: string;
  modalDocuments: OnBoardingUploadFileModel[];
  hasPermissionToDeleteDocs: boolean;
  vaccineExemptionDocuments: File[] = null;

  userId: number;
  hasUploadOnlyPermission: boolean;

  constructor(
    private actions$: Actions,
    private alertService: AlertService,
    private store: Store<State>,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
  ) {
    super();
    this.modalOptions = {
      backdropClass:'customBackdrop',
      size: "lg"
    }
  }

  ngOnInit() {
    this.route.queryParams.subscribe(queryParams => {
      if (queryParams.scrollTo) {
        this.changingDocTypeId = queryParams.scrollTo;
      }
    });
    this.subs.push(
      this.store.select(s => s.onboardingState.uploadTypes).subscribe(uploadTypes => {
        this.uploadTypes = uploadTypes;
        if (this.showOnlyOnApprovalList) {
          this.uploadTypes = this.uploadTypes.filter(x => x.showOnApprovalList);
        }
        if (this.uploadTypes) {
          this.uploadTypes.forEach(x => {
            this.visibleToUser[x.id] = false;
            this.licenseInfos[x.id] = { originationDate: null, issueDate: null, expirationDate: null };
            this.panelingInfos[x.id] = { originationDate: null, issueDate: null, expirationDate: null };
            this.driversLicenseInfos[x.id] = { expirationDate: null, isExpired: null };
            this.covidTestInfos[x.id] = { positive: null, testDate: null };
          });
        }
        this.store.dispatch(loadOnboardingUploads({ userId: this.managedUserId }));
      }),
      this.store.select(s => s.onboardingState.uploadedFiles).subscribe(uploadedFiles => {
        this.uploadedFilesPerType = {};
        uploadedFiles.forEach(x => {
          const typeList = this.uploadedFilesPerType[x.onboarding_UploadDocTypeID.toString()];
          if (!typeList) {
            this.uploadedFilesPerType[x.onboarding_UploadDocTypeID.toString()] = [];
          }
          this.uploadedFilesPerType[x.onboarding_UploadDocTypeID.toString()].push(x);
        });
        if (this.changingDocTypeId) {
          scrollToId('doctype' + this.changingDocTypeId);
        }
      }),
      this.store.select(s => s.accountState.user).subscribe(u => {
        if (u) {
          this.hasPermissionToDeleteDocs = u.permissionIDs && !!u.permissionIDs.find(p => p === 6500);
          this.hasUploadOnlyPermission = u.permissionIDs && !!u.permissionIDs.find(p => p === 6020);
        }
      }),
      this.store.select(s => s.onboardingState).subscribe(onboardingState => {
        this.loadingUploadedFiles = onboardingState.loadingUploadedFiles;
        this.uploadingDoc = onboardingState.uploadingDoc;
        this.deletingOnBoardingUploadId = onboardingState.deletingOnBoardingUploadId;
        this.loadingVaccineCardExemption = onboardingState.loadingVaccineCardExemption;
      }),
      this.actions$.pipe(
        ofType(cancelVaccineCardExemptionSuccess),
        tap(action => {
          this.store.dispatch(loadOnboardingUploads({ userId: this.managedUserId }));
        })).subscribe(),
      this.actions$.pipe(
        ofType(uploadOnboardingDocSuccess),
        tap(action => {
          this.alertService.success('File uploaded.');
          this.hasSelectedFile = {};
          this.visibleToUser = {};
          this.licenseInfos = {};
          this.panelingInfos = {};
          this.driversLicenseInfos = {};
          this.covidTestInfos = {};
          if (this.uploadTypes) {
            this.uploadTypes.forEach(x => {
              this.visibleToUser[x.id] = false;
              this.licenseInfos[x.id] = { originationDate: null, issueDate: null, expirationDate: null };
              this.panelingInfos[x.id] = { originationDate: null, issueDate: null, expirationDate: null };
              this.driversLicenseInfos[x.id] = { expirationDate: null, isExpired: null };
              this.covidTestInfos[x.id] = { positive: null, testDate: null };
            });
          }
          this.store.dispatch(loadOnboardingUploads({ userId: this.managedUserId }));
          if (this.managedUserId) {
            this.store.dispatch(loadManageOnboardingUserInfo({ userId: this.managedUserId }));
          }
        })
      ).subscribe()
    );


    this.store.dispatch(loadOnboardingUploadTypes());

  }

  uploadFile(e) {
    let docTypeID = e.id;
    let fileInput = e.files;
    if(e.docType === "Vaccine Card Exemption"){
      this.vaccineExemptionDocuments = fileInput;
      return;
    }
    this.vaccineExemptionDocuments = null;
    if (fileInput) {
      this.changingDocTypeId = docTypeID;
      this.store.dispatch(uploadOnboardingDoc({
        docTypeID: docTypeID.toString(),
        files: Object.assign([], fileInput),
        userId: this.managedUserId ? this.managedUserId : this.profile.user.id,
        visibleToUser: this.managedUserId ? this.visibleToUser[docTypeID] : true,
        licenseInfos: this.licenseInfos[docTypeID],
        panelingInfos: this.panelingInfos[docTypeID],
        driversLicenseInfos: this.driversLicenseInfos[docTypeID],
        covidTestInfos: this.covidTestInfos[docTypeID]
      }));
    }
  }

  getPreviewUrl(file: OnBoardingUploadFileModel) {
    if (!file) {
      return '';
    }

    if (file.viewUrl) {
      return file.viewUrl;
    }

    if (file.fileType.indexOf('pdf') >= 0) {
      return '/assets/img/pdf.png';
    }

    if (file.fileType.indexOf('application/vnd.openxmlformats-officedocument.wordprocessingml.document') >= 0
      || file.fileType.indexOf('doc') >= 0
      || file.fileType.indexOf('msword') >= 0) {
      return '/assets/img/doc.png';
    }
  }

  deleteUploadDoc(file: OnBoardingUploadModel) {
    if(this.modalReference != null){
      this.modalReference.close()
    }
    this.alertService.confirm('Confirm Deletion', 'Are you sure that you want to delete this file?').subscribe(result =>{
      if(result){
        this.changingDocTypeId = file.onboarding_UploadDocTypeID;
        this.store.dispatch(deleteOnboardingUpload({ OnBoardingUpload: file }));
      }
    });
  }

  open(content, app) {
    this.modalDocuments = app;
    this.modalReference = this.modalService.open(content, this.modalOptions);
    this.modalReference.result.then((result) => {
    }, (reason) => {
    });
  }

  openDocPreview(content, uploadType) {
    if (this.shouldOpenPreviewModal(uploadType)) 
    {
      this.alertService.error("Please make sure all information is filled out in this section correctly before uploading.")
      return;
    }
    this.userId = this.managedUserId ? this.managedUserId : this.profile.user.id;
    this.docPreviewModalReference = this.modalService.open(content, this.modalOptions);
    this.docPreviewModalReference.result.then((result) => {
    }, (reason) => {
    });
  }

  shouldOpenPreviewModal(uploadType) {
    return (uploadType.docType.toString() === 'Licenses/Certifications' &&
        (!this.licenseInfos[uploadType.id].issueDate ||
          !this.licenseInfos[uploadType.id].originationDate)) ||
      (uploadType.docType.toString() === 'Covid Test Results' &&
        (!this.covidTestInfos[uploadType.id].testDate ||
          (this.covidTestInfos[uploadType.id].positive == null ||
            this.covidTestInfos[uploadType.id].positive == undefined))) ||
            (uploadType.docType.toString() === 'Drivers License' &&
              (!this.driversLicenseInfos[uploadType.id].expirationDate));
  }

  closePreviewModal(){
    this.docPreviewModalReference.close();
  }

  closeModal(){
    this.modalDocuments = null;
    this.viewDocumentURL = "";
    this.modalReference.close();
  }

  changeVisibility(file: OnBoardingUploadModel) {
    this.changingDocTypeId = file.onboarding_UploadDocTypeID;
    this.store.dispatch(changeVisibilityOnboardingUpload({ OnBoardingUpload: file }));
  }


  downloaFile(file: OnBoardingUploadFileModel) {
    if(this.hasUploadOnlyPermission){
      return;
    }
    this.store.dispatch(downloadOnboardingUpload({ onboardingUploadFileId: file.id }));
    const subDocBuffer = this.store.select(s => s.onboardingState.docArrayBuffer).subscribe(docArrayBuffer => {
      if (docArrayBuffer) {
        downloadFile(new Blob([docArrayBuffer], { type: file.fileType }), file.fileName);
        subDocBuffer.unsubscribe();
      }
    });
  }

  modalDownloadFile(){
    for (let file of this.modalDocuments){
      this.downloaFile(file);
    }
  }

  allFilesApproved(uploadTypeId) {
    const filesOfThisType: OnBoardingUploadModel[] = this.uploadedFilesPerType[uploadTypeId.toString()];
    return filesOfThisType && !filesOfThisType.find(x => !x.approved && (!x.vaccineCardExemption || !x.vaccineCardExemption.removedDate));
  }

  approveFile(file: OnBoardingUploadModel) {
    if(this.modalReference != null){
      this.modalReference.close()
    }
    this.store.dispatch(approveOnboardingUpload({ OnBoardingUpload: file }));
  }

  denyFile(file: OnBoardingUploadModel, denyMessage: string) {
    if(this.modalReference != null){
      this.modalReference.close()
    }
    this.store.dispatch(denyOnboardingUpload({ OnBoardingUpload: file, denyMessage }));
    this.denyInfo[file.id] = {};
  }

  openCloseDenyMessage(file: OnBoardingUploadModel) {
    if (!this.denyInfo[file.id]) {
      this.denyInfo[file.id] = {};
    }
    this.denyInfo[file.id].denyMessageOpen = !this.denyInfo[file.id].denyMessageOpen;
  }

  getDenyInfo(file) {
    if (!this.denyInfo[file.id]) {
      this.denyInfo[file.id] = {};
    }

    return this.denyInfo[file.id];
  }

  isOptional(docType: string) {
    if (this.profile.employee && this.profile.offerLetter) {
      if (docType === 'College Transcripts' && !this.profile.offerLetter.requireTranscripts) {
        return true;
      }
      if (docType === 'Diploma of Highest Education Level' && !this.profile.offerLetter.requireDiploma) {
        return true;
      }
      if (docType === 'Licenses/Certifications' && !this.profile.offerLetter.requireLicenses) {
        return true;
      }
      if (docType === 'Paneling Docs' && !this.profile.offerLetter.requirePanelingDocs) {
        return true;
      }
      if (docType === 'DISC' && !this.profile.offerLetter.requireDisc) {
        return true;
      }
      if (docType === 'StandOut Assessment' && !this.profile.offerLetter.requireStandOutAssessment) {
        return true;
      }
    } else {
      if (docType === 'College Transcripts') {
        return true;
      }
      if (docType === 'Diploma of Highest Education Level' ) {
        return true;
      }
      if (docType === 'Licenses/Certifications' ) {
        return true;
      }
      if (docType === 'Paneling Docs' ) {
        return true;
      }
      if (docType === 'DISC') {
        return true;
      }
      if (docType === 'StandOut Assessment' ) {
        return true;
      }
    }

    if (docType === 'Background Check') {
      return true;
    }
    if (docType === 'Vaccine Card') {
      return true;
    }
    if (docType === 'Vaccine Card Exemption') {
      return true;
    }
    if (docType === 'Covid Test Results') {
      return true;
    }
    if (docType === 'Other') {
      return true;
    }

    return false;
  }

  openVacineExemptionForm() {
    this.exemptionFormNotes = '';
    this.vacineExemptionFormOpened = !this.vacineExemptionFormOpened;
  }

  requestVaccineCardExemption(docTypeID) {
    let fileInput = this.vaccineExemptionDocuments;
    if (fileInput && fileInput.length) {
      this.changingDocTypeId = docTypeID;
      this.store.dispatch(uploadOnboardingDoc({
        docTypeID: docTypeID.toString(),
        files: Object.assign([], fileInput),
        userId: this.managedUserId ? this.managedUserId : this.profile.user.id,
        visibleToUser: this.managedUserId ? this.visibleToUser[docTypeID] : true,
        vaccineExemption: {
          notes: this.exemptionFormNotes,
          exemptionType: this.exemptionFormType,
        }
      }));
    } else {
      this.store.dispatch(requestVaccineCardExemption(
        {
          notes: this.exemptionFormNotes,
          exemptionType: Number(this.exemptionFormType),
          userID: this.managedUserId ? this.managedUserId : this.profile.user.id
        }));
    }

    this.exemptionFormNotes = '';
    this.exemptionFormType = '0';
    this.vaccineExemptionDocuments = null;
  }

  approveVaccineCardExemption(id: number) {
    this.store.dispatch(approveVaccineCardExemption(
      {
        id
      }));
  }

  removeVaccineCardExemption(id: number) {
    this.store.dispatch(cancelVaccineCardExemption(
      {
        id
      }));
  }
}
