import { PayrollService } from './../../services/payroll.service';
import { AlertService } from './../../../shared/services/alert.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, tap, catchError, filter } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { errorHappened } from '../profile/profile.actions';
import { Store, Action } from '@ngrx/store';
import { State } from '..';
import {
  loadPayrollApprovalApprovedTimeSuccess, loadPayrollApprovalInvalidTimeSuccess, loadPayrollApprovalAdjustmentTimeSuccess,
  loadPayrollApprovalPendingTimeSuccess, loadPayrollApprovalPendingTime, loadPayrollApprovalAdjustmentTime, loadPayrollApprovalInvalidTime,
  loadPayrollApprovalApprovedTime, loadPayrollApprovalApprovedTotals, loadPayrollApprovalApprovedTotalsSuccess,
  loadPayrollApprovalPeriodsSuccess, changePayrollApprovalPeriod, loadPayrollApprovalPeriods, approvePayrollItems,
  approvePayrollItemsSuccess,
  rejectPayrollItems,
  rejectPayrollItemsSuccess,
  savePayrollItems,
  savePayrollItemsSuccess,
  unapprovePayrollItems,
  unapprovePayrollItemsSuccess,
  lockPayrollPeriod,
  lockPayrollPeriodSuccess,
  getIsLockedPeriod,
  getIsLockedPeriodSuccess,
  unlockPayrollPeriod,
  unlockPayrollPeriodSuccess,
  loadPayrollClassifications,
  loadCampuses,
  loadPayrollClassificationsSuccess,
  loadCampusesSuccess,
  changePayrollApprovalUserFilter,
  changePayrollApprovalUserFilterSuccess,
  changeClientServiceNoteToManualEntry,
  changeClientServiceNoteToManualEntrySuccess,
  loadPayrollCampuses,
  loadPayrollCampusesSuccess,
  approvePayrollItemsFail,
  generatePayrollItems,
  generatePayrollItemsSuccess,
  generatePayrollItemsFail,
  savePayableItem,
  savePayableItemSuccess,
  savePayableItemFail
} from './payroll-approval.actions';
import { PayPeriodService } from '../../services/payPeriod.service';
import { getUsersList } from '../users/users.actions';
import { UsersService } from '../../services/users.service';
import { PayrollApprovalState } from './payroll-approval.reducer';

@Injectable()
export class PayrollApprovalEffects {
  constructor(private actions$: Actions,
    private payrollService: PayrollService,
    private payPeriodService: PayPeriodService,
    private alertService: AlertService,
    private store: Store<State>,
    private usersService: UsersService) { }

  loadPayrollApprovalApprovedTime = createEffect(() => this.actions$.pipe(
    ofType(loadPayrollApprovalApprovedTime),
    switchMap(action => this.payrollService.loadApprovedTime(action.periodID, action.userID).pipe(
      map(payrollItems => loadPayrollApprovalApprovedTimeSuccess({ payrollItems })),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))));
  loadPayrollApprovalInvalidTime = createEffect(() => this.actions$.pipe(
    ofType(loadPayrollApprovalInvalidTime),
    switchMap(action => this.payrollService.loadInvalidTime(action.periodID, action.userID).pipe(
      map(payrollItems => loadPayrollApprovalInvalidTimeSuccess({ payrollItems })),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))));
  loadPayrollApprovalAdjustmentTime = createEffect(() => this.actions$.pipe(
    ofType(loadPayrollApprovalAdjustmentTime),
    switchMap(action => this.payrollService.loadAdjustmentTime(action.periodID, action.userID, action.showAll).pipe(
      map(payrollItems => loadPayrollApprovalAdjustmentTimeSuccess({ payrollItems })),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))));
  loadPayrollApprovalPendingTime = createEffect(() => this.actions$.pipe(
    ofType(loadPayrollApprovalPendingTime),
    switchMap(action => this.payrollService.loadPendingTime(action.periodID, action.userID, action.showAll).pipe(
      map(payrollItems => loadPayrollApprovalPendingTimeSuccess({ payrollItems })),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))));

  loadPayrollApprovalApprovedTotals = createEffect(() => this.actions$.pipe(
    ofType(loadPayrollApprovalApprovedTotals),
    switchMap(action => this.payrollService.loadApprovedTotals(action.periodID, action.userID).pipe(
      map(approvedTotals => loadPayrollApprovalApprovedTotalsSuccess({ approvedTotals })),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))));

  loadPayrollApprovalPeriods = createEffect(() => this.actions$.pipe(
    ofType(loadPayrollApprovalPeriods),
    switchMap(action => {
      return this.payrollService.loadPayrollPeriods().pipe(
        map(periods => loadPayrollApprovalPeriodsSuccess({ periods })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    }
    )));

  loadPayrollApprovalPeriodsSuccess = createEffect(() => this.actions$.pipe(
    ofType(loadPayrollApprovalPeriodsSuccess),
    map(action => {
      let selectedPeriodID;
      let selectedUserID;
      let selectedShowAll;
      this.store.select(s => s.payrollApprovalState).subscribe(payrollApprovalState => {
        selectedPeriodID = payrollApprovalState.selectedPeriodID;
        selectedUserID = payrollApprovalState.selectedUserID;
        selectedShowAll = payrollApprovalState.showAll;
      }).unsubscribe();
      if (action.periods && action.periods.length > 1
        && !action.periods.find(p => p.payPeriodID.toString() === selectedPeriodID.toString())) {
        return changePayrollApprovalPeriod({ periodID: action.periods[0].payPeriodID, userID: selectedUserID, showAll: selectedShowAll });
      }
    }),
    filter(action => !!action)
  ));

  changePayrollApprovalPeriod = createEffect(() => this.actions$.pipe(
    ofType(changePayrollApprovalPeriod),
    tap(action => {
      if (action.periodID && action.userID) {
        this.store.dispatch(getIsLockedPeriod({ periodID: action.periodID, userID: action.userID }));
        this.store.dispatch(loadPayrollApprovalApprovedTime({ periodID: action.periodID, userID: action.userID }));
        this.store.dispatch(loadPayrollApprovalInvalidTime({ periodID: action.periodID, userID: action.userID }));
        this.store.dispatch(loadPayrollApprovalAdjustmentTime({ periodID: action.periodID, userID: action.userID, showAll: action.showAll }));
        this.store.dispatch(loadPayrollApprovalPendingTime({ periodID: action.periodID, userID: action.userID, showAll: action.showAll }));
        this.store.dispatch(loadPayrollApprovalApprovedTotals({ periodID: action.periodID, userID: action.userID }));
      }
      let payrollApprovalState: PayrollApprovalState;
      this.store.select(s => s.payrollApprovalState).subscribe(payrollApprovalStateParam => {
        payrollApprovalState = payrollApprovalStateParam;
      }).unsubscribe();
      // if it is only changing the user, dont need to reload users
      if (payrollApprovalState.selectedUserID === payrollApprovalState.previouslySelectedUserID) {
        this.store.dispatch(changePayrollApprovalUserFilter({
          campusID: payrollApprovalState.selectedCampusID,
          payrollClassificationID: payrollApprovalState.selectedPayrollClassificationID
        }));
      }
    })
  ), { dispatch: false });

  approvePayrollItems = createEffect(() => this.actions$.pipe(
    ofType(approvePayrollItems),
    switchMap(action => {
      return this.payrollService.approvePayrollItems(action.payrollItems.map(p => p.payableItem), action.userID, action.payPeriodID, action.approvalType).pipe(
        map(payrollItems => approvePayrollItemsSuccess({ payrollItems })),
        catchError(err => {
          this.store.dispatch(approvePayrollItemsFail({ err }));
          return of(errorHappened({ err }));
        }));
    }
    )));

  approvePayrollItemsSuccess = createEffect(() => this.actions$.pipe(
    ofType(approvePayrollItemsSuccess),
    map(action => {
      return this.changePayrollApproval();
    }),
    filter(action => !!action)
  ));


  rejectPayrollItems = createEffect(() => this.actions$.pipe(
    ofType(rejectPayrollItems),
    switchMap(action => {
      return this.payrollService.rejectPayrollItems(action.payrollItems.map(p => p.payableItem), action.userID, action.payPeriodID).pipe(
        map(payrollItems => rejectPayrollItemsSuccess({ payrollItems })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    }
    )));

  rejectPayrollItemsSuccess = createEffect(() => this.actions$.pipe(
    ofType(rejectPayrollItemsSuccess),
    map(action => {
      return this.changePayrollApproval();
    }),
    filter(action => !!action)
  ));


  unapprovePayrollItems = createEffect(() => this.actions$.pipe(
    ofType(unapprovePayrollItems),
    switchMap(action => {
      return this.payrollService.unapprovePayrollItems(action.payrollItems.map(p => p.payableItem), action.userID, action.payPeriodID).pipe(
        map(payrollItems => unapprovePayrollItemsSuccess({ payrollItems })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    }
    )));

  unapprovePayrollItemsSuccess = createEffect(() => this.actions$.pipe(
    ofType(unapprovePayrollItemsSuccess),
    map(action => {
      return this.changePayrollApproval();
    }),
    filter(action => !!action)
  ));


  lockPayrollPeriod = createEffect(() => this.actions$.pipe(
    ofType(lockPayrollPeriod),
    switchMap(action => {
      return this.payrollService.lockPayrollPeriod(action.userID, action.payPeriodID).pipe(
        map(payrollItems => lockPayrollPeriodSuccess()),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    }
    )));

  lockPayrollPeriodSuccess = createEffect(() => this.actions$.pipe(
    ofType(lockPayrollPeriodSuccess),
    map(action => {
      return this.changePayrollApproval();
    }),
    filter(action => !!action)
  ));

  unlockPayrollPeriod = createEffect(() => this.actions$.pipe(
    ofType(unlockPayrollPeriod),
    switchMap(action => {
      return this.payrollService.unlockPayrollPeriod(action.userID, action.payPeriodID).pipe(
        map(payrollItems => unlockPayrollPeriodSuccess()),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    }
    )));

  unlockPayrollPeriodSuccess = createEffect(() => this.actions$.pipe(
    ofType(unlockPayrollPeriodSuccess),
    map(action => {
      return this.changePayrollApproval();
    }),
    filter(action => !!action)
  ));


  savePayrollItems = createEffect(() => this.actions$.pipe(
    ofType(savePayrollItems),
    switchMap(action => {
      return this.payrollService.savePayrollItems(action.payrollItems.map(p => p.payableItem), action.userID, action.payPeriodID).pipe(
        map(payrollItems => savePayrollItemsSuccess({ payrollItems })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    }
    )));

  savePayrollItemsSuccess = createEffect(() => this.actions$.pipe(
    ofType(savePayrollItemsSuccess),
    map(action => {
      return this.changePayrollApproval();
    }),
    filter(action => !!action)
  ));

  getIsLockedPeriod = createEffect(() => this.actions$.pipe(
    ofType(getIsLockedPeriod),
    switchMap(action => {
      return this.payrollService.getIsLockedPeriod(action.userID, action.periodID).pipe(
        map(isLocked => getIsLockedPeriodSuccess({ isLocked })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })));


  loadPayrollClassifications = createEffect(() => this.actions$.pipe(
    ofType(loadPayrollClassifications),
    switchMap(action => this.payrollService.getPayrollClassifications().pipe(
      map(payrollClassifications => loadPayrollClassificationsSuccess({ payrollClassifications })),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))));
  loadCampuses = createEffect(() => this.actions$.pipe(
    ofType(loadCampuses),
    switchMap(action => this.payrollService.getCampuses().pipe(
      map(campuses => loadCampusesSuccess({ campuses })),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))));

  loadPayrollCampuses = createEffect(() => this.actions$.pipe(
    ofType(loadPayrollCampuses),
    switchMap(action => this.payrollService.getPayrollCampuses().pipe(
      map(campuses => loadPayrollCampusesSuccess({ campuses })),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))));

  changePayrollApprovalUserFilter = createEffect(() => this.actions$.pipe(
    ofType(changePayrollApprovalUserFilter),
    switchMap(action => {
      let selectedPeriodID;
      this.store.select(s => s.payrollApprovalState).subscribe(payrollApprovalState => {
        selectedPeriodID = payrollApprovalState.selectedPeriodID;
      }).unsubscribe();
      return this.payrollService.getFilteredUsers(action.campusID, action.payrollClassificationID, selectedPeriodID).pipe(
        map(usersList => changePayrollApprovalUserFilterSuccess({ usersList })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  changePayrollApproval = () => {
    let selectedPeriodID;
    let selectedUserID;
    let selectedShowAll;
    this.store.select(s => s.payrollApprovalState).subscribe(payrollApprovalState => {
      selectedPeriodID = payrollApprovalState.selectedPeriodID;
      selectedUserID = payrollApprovalState.selectedUserID;
      selectedShowAll = payrollApprovalState.showAll;
    }).unsubscribe();
    return changePayrollApprovalPeriod({
      periodID: selectedPeriodID, userID: selectedUserID, showAll: selectedShowAll
    });
  }

  changeClientServiceNoteToManualEntry = createEffect(() => this.actions$.pipe(
    ofType(changeClientServiceNoteToManualEntry),
    switchMap(action => {
      return this.payrollService.changeClientServiceNoteToManualEntry(action.payableItem).pipe(
        map(payrollItems => changeClientServiceNoteToManualEntrySuccess()),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    }
    )));

  changeClientServiceNoteToManualEntrySuccess = createEffect(() => this.actions$.pipe(
    ofType(changeClientServiceNoteToManualEntrySuccess),
    map(action => {
      return this.changePayrollApproval();
    }),
    filter(action => !!action)
  ));

  generatePayrollItems = createEffect(() => this.actions$.pipe(
    ofType(generatePayrollItems),
    switchMap(action => {
      return this.payrollService.generatePayrollItems(action.userID, action.payPeriodID).pipe(
        map(payrollItems => generatePayrollItemsSuccess()),
        catchError(err => {
          this.store.dispatch(generatePayrollItemsFail({ err }));
          return of(errorHappened({ err }));
        }));
    }
    )));

  generatePayrollItemsSuccess = createEffect(() => this.actions$.pipe(
    ofType(generatePayrollItemsSuccess),
    map(action => {
      return this.changePayrollApproval();
    }),
    filter(action => !!action)
  ));

  savePayableItem = createEffect(() => this.actions$.pipe(
    ofType(savePayableItem),
    switchMap(action => {
      return this.payrollService.savePayableItem(action.payableItem).pipe(
        map(payrollItems => savePayableItemSuccess()),
        catchError(err => {
          this.store.dispatch(savePayableItemFail({ err }));
          return of(errorHappened({ err }));
        }));
    }
    )));

  savePayableItemSuccess = createEffect(() => this.actions$.pipe(
    ofType(savePayableItemSuccess),
    map(action => {
      return this.changePayrollApproval();
    }),
    filter(action => !!action)
  ));

}
