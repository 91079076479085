import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { CeuEventCertificationHasCeuModel, CeuEventHasCertificationTypeModel, CeuEventHasUserModel, CeuEventModel } from '../../../../models/certificationModel';
import { deepClone, getAllControlsErrors, markFormGroupTouched, updateFormGroupValidity } from '../../../../helpers/utils';
import { closeEditCeuEvent, getCeuEvent, loadInstructors, saveCeuEvent, saveCeuEventSuccess, updateCeuEventForm } from '../../../../core/store/certification/certification.action';
import { formatDate } from '@angular/common';
import { loadEmployeeUsers } from '../../../../core/store/employee/employee.action';

@Component({
  selector: 'app-edit-ceu-event',
  templateUrl: './edit-ceu-event.component.html',
  styleUrls: ['./edit-ceu-event.component.scss']
})
export class EditCeuEventComponent extends BaseComponent implements OnInit {
  display: boolean = false;
  ceuEvent: CeuEventModel;
  title: string;
  form: FormGroup;
  disableButtons: boolean = true;
  campusId: number = 0;
  showOnlyActiveEmployees: boolean = true;
  showOnlyCurrentInstructors: boolean = true;
  eventId: number = 0;

  ceuCategories: SelectItem[];
  certificationTypeOptions: SelectItem[];
  ceuTypes: SelectItem[];
  selectedUsers: SelectItem[];
  userEmployeeList: SelectItem[];
  instructorList: SelectItem[];
  selectedCertifications: SelectItem[];

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.form = this.createForm();
    this.subs.push(
      this.store.select(s => s.certificationState.editingCeuEvent).subscribe(editingCeu => {
        if (this.display && !editingCeu || (editingCeu && this.eventId == 0)) {
          this.resetForm();          
        }
        this.display = editingCeu;
                
      }),
      this.store.select(s => s.certificationState.savingCeuEvent).subscribe(savingCeu => {
        this.disableButtons = savingCeu;
      }),
      this.store.select(s => s.certificationState.ceuEventId).subscribe(id => {        
        this.eventId = id;
        this.title = "New CEU Event";
        if (typeof id !== 'undefined' && id > 0) {
          this.store.dispatch(getCeuEvent({ ceuEventId: id }));
        }        
      }),
      this.store.select(s => s.certificationState.selectedCeuEvent).subscribe(ceu => {
        if (ceu === null) {
          this.ceuEvent = null;
          return;
        }
        this.title = "Edit CEU Event #" + ceu.ceuEventId;
        this.form.get("ceuEventId").setValue(ceu.ceuEventId);
        this.form.get("eventName").setValue(ceu.eventName);
        this.form.get("modality").setValue(ceu.modality != null ? ceu.modality : "");
        this.form.get("notes").setValue(ceu.notes != null ? ceu.notes : "");
        this.form.get("startDateString").setValue(ceu.startDateString);
        this.form.get("endDateString").setValue(ceu.endDateString);
        this.form.get("instructorId").setValue(ceu.instructorId);
        this.form.get("selectedInstructor").patchValue({
          label: ceu.selectedInstructor.label,
          value: ceu.selectedInstructor.value
        });
        this.resetUploadedFiles();
        if (ceu.eventUsers != null && ceu.eventUsers.length > 0) {
          this.resetEventUsers(false);
          ceu.eventUsers.forEach(u => {
            this.addUserDataToEvent(u);
          });

        } else {
          this.resetEventUsers(true);
        }
        if (ceu.certificationTypes != null && ceu.certificationTypes.length > 0) {
          this.resetCertificationTypes(false);
          ceu.certificationTypes.forEach(c => {
            this.addCertificationDataToEvent(c);
          })

        } else {
          this.resetCertificationTypes(true);
        }

        this.resetUploads();
        if (ceu.uploads != null && ceu.uploads.length > 0) {
          
          let uploads = this.form.get('uploads') as FormArray;          
          ceu.uploads.forEach(u => {
            uploads.push(this.fb.group({
              id: this.fb.control(u.id),
              fileName: this.fb.control(u.fileName),
              fileAwsBucket: this.fb.control(u.fileAwsBucket),
              fileAwsKey: this.fb.control(u.fileAwsKey)              
            }));
          });
        } 
      }),
      this.store.select(s => s.certificationState.ceuCategories).subscribe(categories => {
        if (categories && categories.length > 0) {
          this.ceuCategories = categories.map(x => ({ label: x.label, value: x.value }));
          this.ceuCategories.unshift({ label: 'Select Type', value: 0 });
        }
        else {
          this.ceuCategories = [{ label: 'loading...', value: undefined }];
        }
      }),
      this.store.select(s => s.certificationState.ceuTypes).subscribe(types => {
        if (types && types.length > 0) {
          this.ceuTypes = types.map(x => ({ label: x.label, value: x.value }));
          this.ceuTypes.unshift({ label: 'Select Type', value: 0 });
        }
        else {
          this.ceuTypes = [{ label: 'loading...', value: undefined }];
        }
      }),
      this.store.select(s => s.certificationState.certificationTypes).subscribe(types => {
        if (types && types.length > 0) {
          this.certificationTypeOptions = types.map(x => ({ label: x.label, value: x.value }));          
        }
        else {
          this.certificationTypeOptions = [{ label: 'loading...', value: undefined }]
        }
      }),
      this.store.select(s => s.employeeState.employeeUsers).subscribe(users => {
        this.userEmployeeList = users;
      }),
      this.store.select(s => s.certificationState.instructors).subscribe(instructors => {
        this.instructorList = instructors;
      }),
      this.actions$.pipe(
        ofType(saveCeuEventSuccess),
        tap(action => {
          this.closeModal();
        })
      ).subscribe(),
    );
  }

  createForm() {
    let f = this.fb.group({
      ceuEventId: this.fb.control(0),
      eventName: this.fb.control("", [Validators.required]),
      modality: this.fb.control(""),
      notes: this.fb.control(""),
      startDateString: this.fb.control(formatDate(new Date(), 'yyyy-MM-dd', 'en-US'), [Validators.required]),
      endDateString: this.fb.control(formatDate(new Date(), 'yyyy-MM-dd', 'en-US'), [Validators.required]),
      instructorId: this.fb.control(0, [Validators.required]),
      selectedInstructor: this.fb.control({
        label: "",
        value: 0
      }),
      eventUsers: this.fb.array([this.fb.group({
        ceuEventHasUserId: this.fb.control(0),
        eventId: this.fb.control(0),
        employeeName: this.fb.control(""),
        userId: this.fb.control(0),
        selectedUser: this.fb.control({
          value: 0,
          label: ""
        })
      })]),
      certificationTypes: this.fb.array([this.fb.group({
        ceuEventHasCertificationTypeId: this.fb.control(0),
        eventId: this.fb.control(0),
        certificationTypeId: this.fb.control(0),
        cEUs: this.fb.array([this.fb.group(
          {
            ceuEventCertificationHasCeuId: this.fb.control(0),
            eventHasCertificationTypeId: this.fb.control(0),
            ceuCategoryId: this.fb.control(0),
            ceuTypeId: this.fb.control(0),
            units: this.fb.control(0),
          })
        ])
      })]),
      uploadedFiles: this.fb.array([]),
      uploads: this.fb.array([])
    });
    
    return f;
  }

  resetForm() {
    this.form.reset({
      ceuEventId: 0,
      startDateString: formatDate(new Date(), 'yyyy-MM-dd', 'en-US'),
      endDateString: formatDate(new Date(), 'yyyy-MM-dd', 'en-US'),      
    });
    this.resetEventUsers(true);
    this.resetCertificationTypes(true);
    this.resetUploadedFiles();
    this.resetUploads();
  }

  resetUploads() {
    let files = this.form.get('uploads') as FormArray;
    files.clear();
  }

  resetUploadedFiles() {
    let files = this.form.get('uploadedFiles') as FormArray;
    files.clear();
  }

  resetEventUsers(addEmpty) {
    let eventUsers = this.form.get('eventUsers') as FormArray;
    eventUsers.clear();
    if (addEmpty) {
      this.addUserToEvent();
    }
  }

  resetCertificationTypes(addEmpty) {
    let certifications = this.form.get('certificationTypes') as FormArray;
    certifications.clear();
    if (addEmpty) {
      this.addCertificationToEvent();
    }
  }

  getStaffList(event) {
    this.store.dispatch(loadEmployeeUsers({
      prefixText: event.query,
      programId: this.campusId,
      payrollClassificationId: null,
      showTerminated: !this.showOnlyActiveEmployees,
      showInactive: !this.showOnlyActiveEmployees
    }));
  }

  setUserAfterStaffChange(selectedUser, index) {
    if (typeof selectedUser.value !== 'number') {
      return;
    }

    let eventUsers = this.form.get('eventUsers') as FormArray;
    let userToUpdate = eventUsers.at(index) as FormGroup;
    userToUpdate.patchValue({
      employeeName: selectedUser.label,
      userId: selectedUser.value
    });   
  }

  clearUserAfterClear(event, index) {
    if (event.data !== null) {
      return;
    }

    let eventUsers = this.form.get('eventUsers') as FormArray;
    let userToUpdate = eventUsers.at(index) as FormGroup;
    userToUpdate.patchValue({
      employeeName: "",
      userId: 0
    });
  }

  setCertificationTypeAfterChange(event, index) {
    if (typeof event.value !== 'number') {
      return;
    }

    let certifications = this.form.get('certificationTypes') as FormArray;
    let certType = certifications.at(index) as FormGroup;
    certType.patchValue({
      certificationTypeId: event.value
    });       
  }

  setCategoryAfterChange(event, parentIndex, index) {
    if (typeof event.value !== 'number') {
      return;
    }

    let certifications = this.form.get('certificationTypes') as FormArray;
    let certification = certifications.at(parentIndex) as FormGroup;
    let ceus = certification.get('cEUs') as FormArray;
    let ceu = ceus.at(index);
    ceu.patchValue({
      ceuCategoryId: event.value
    });
  }

  setTypeAfterChange(event, parentIndex, index) {
    if (typeof event.value !== 'number') {
      return;
    }

    let certifications = this.form.get('certificationTypes') as FormArray;
    let certification = certifications.at(parentIndex) as FormGroup;
    let ceus = certification.get('cEUs') as FormArray;
    let ceu = ceus.at(index);
    ceu.patchValue({
      ceuTypeId: event.value
    });
  }

  getInstructorList(event) {
    this.store.dispatch(loadInstructors({
      prefixText: event.query,
      certificationTypeId: 0,
      showArchived: !this.showOnlyCurrentInstructors,
      showTerminated: !this.showOnlyCurrentInstructors,
      showInactive: !this.showOnlyCurrentInstructors
    }));
  }

  setInstructorAfterInstructorChange(selectedInstructor) {
    if (typeof selectedInstructor.value !== 'number') {
      return;
    }

    this.form.get('instructorId').setValue(selectedInstructor.value);
  }

  clearInstructorAfterClear(event) {
    if (event.data !== null) {
      return;
    }

    this.form.get('instructorId').setValue(0);
  }

  closeModal() {
    this.store.dispatch(closeEditCeuEvent());
  }

  saveEventEdits() {    
    updateFormGroupValidity(this.form);
    if (this.form.valid) {           
      this.store.dispatch(saveCeuEvent({ ceuEvent: this.form.value as CeuEventModel }));
    } else {
      markFormGroupTouched(this.form);
    }
  }

  addUserToEvent() {    
    let eventUsers = this.form.get('eventUsers') as FormArray;
    eventUsers.push(this.fb.group({
      ceuEventHasUserId: this.fb.control(0),
      eventId: this.fb.control(0),
      employeeName: this.fb.control(""),
      userId: this.fb.control(0),
      selectedUser: this.fb.control({
        value: 0,
        label: ""
      })
    }));    
    return false;
  }

  addUserDataToEvent(userEvent: CeuEventHasUserModel) {
    if (userEvent == null) {
      return;
    }

    let eventUsers = this.form.get('eventUsers') as FormArray;
    eventUsers.push(this.fb.group({
      ceuEventHasUserId: this.fb.control(userEvent.ceuEventHasUserId),
      eventId: this.fb.control(userEvent.eventId),
      employeeName: this.fb.control(userEvent.employeeName),
      userId: this.fb.control(userEvent.userId),
      selectedUser: this.fb.control({
        value: userEvent.userId,
        label: userEvent.employeeName
      })
    }));
    return false;
  }

  removeUserFromEvent(index) {
    let eventUsers = this.form.get('eventUsers') as FormArray;
    eventUsers.removeAt(index);
    return false;
  }

  addCertificationToEvent() {
    let certifications = this.form.get('certificationTypes') as FormArray;
    certifications.push(this.fb.group({
      ceuEventHasCertificationTypeId: this.fb.control(0),
      eventId: this.fb.control(0),
      certificationTypeId: this.fb.control(0),
      cEUs: this.fb.array([this.fb.group(
        {
          ceuEventCertificationHasCeuId: this.fb.control(0),
          eventHasCertificationTypeId: this.fb.control(0),
          ceuCategoryId: this.fb.control(0),
          ceuTypeId: this.fb.control(0),
          units: this.fb.control(0),         
        })
      ])
    }));
    return false;    
  }

  addCertificationDataToEvent(data: CeuEventHasCertificationTypeModel) {
    if (data == null) {
      return;
    }
    let certifications = this.form.get('certificationTypes') as FormArray;
    certifications.push(this.fb.group({
      ceuEventHasCertificationTypeId: this.fb.control(data.ceuEventHasCertificationTypeId),
      eventId: this.fb.control(data.eventId),
      certificationTypeId: this.fb.control(data.certificationTypeId),
      cEUs: this.fb.array([...data.ceUs.map(c => this.fb.group(
        {
          ceuEventCertificationHasCeuId: this.fb.control(c.ceuEventCertificationHasCeuId),
          eventHasCertificationTypeId: this.fb.control(c.eventHasCertificationTypeId),
          ceuCategoryId: this.fb.control(c.ceuCategoryId),
          ceuTypeId: this.fb.control(c.ceuTypeId != null ? c.ceuTypeId : 0),
          units: this.fb.control(c.units),
        }))
      ])
    }));
    return false;
  }

  removeCertificationFromEvent(index) {
    let certifications = this.form.get('certificationTypes') as FormArray;
    certifications.removeAt(index);
    return false;
  }      

  removeCeu(parentIndex, index) {
    let certifications = this.form.get('certificationTypes') as FormArray;
    let certification = certifications.at(parentIndex) as FormGroup;
    let ceus = certification.get('cEUs') as FormArray;
    ceus.removeAt(index);
    return false;
  }

  addCeuToCertification(index) {
    let certifications = this.form.get('certificationTypes') as FormArray;
    let certification = certifications.at(index) as FormGroup;
    let ceus = certification.get('cEUs') as FormArray;
    ceus.push(this.fb.group(
      {
        ceuEventCertificationHasCeuId: this.fb.control(0),
        eventHasCertificationTypeId: this.fb.control(0),
        ceuCategoryId: this.fb.control(0),
        ceuTypeId: this.fb.control(0),
        units: this.fb.control(0),
      }));
    return false;
  }

  removeUploadFromEvent(index) {
    let uploads = this.form.get('uploads') as FormArray;
    uploads.removeAt(index);
    return false;
  }

  removeUploadedFileFromEvent(index) {
    let uploads = this.form.get('uploadedFiles') as FormArray;
    uploads.removeAt(index);
    return false;
  }

  changedFile(fileInput) {
    let files = Object.assign([], fileInput.files);

    let uploadedFiles = this.form.get('uploadedFiles') as FormArray;
    files.forEach(f => {
      uploadedFiles.push(this.fb.control(f));
    });
    return false;
  }

  getUploads() {
    return (this.form.get('uploads') as FormArray).controls;
  }

  getUploadedFiles() {
    return (this.form.get('uploadedFiles') as FormArray).controls;
  }

  getCertificationTypes() {
    return (this.form.get('certificationTypes') as FormArray).controls;
  }

  getSubCertificationSections(i: number) {
    let certifications = this.form.get('certificationTypes') as FormArray;
    let selectedCert = certifications.at(i) as FormGroup;
    return (selectedCert.get('cEUs') as FormArray).controls;
  }

  getEventUsers() {
    return (this.form.get('eventUsers') as FormArray).controls;
  }
}
