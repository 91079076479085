<p-dialog [(visible)]="display" header="{{title}}" [modal]="true" [style]="{width: '70vw'}" (onHide)="closeModal()" [focusOnShow]="false">
  <form class="card mt-2" [formGroup]="form" (ngSubmit)="saveHolidayEdits()">
    <div class="card-body">
      <div class="row">
        <div class="col-12">

          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Holiday Name</b>
            </div>
            <div class="col-8">
              <input type="text" class="form-control mt-2" formControlName="holidayName" />
              <app-validation-message [form]="form" [path]="'holidayName'" [label]="'Holiday Name'"></app-validation-message>
            </div>
          </div>

          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Date</b>
            </div>
            <div class="col-8">
              <input type="date" class="form-control mt-2" formControlName="dateString" />
              <app-validation-message [form]="form" [path]="'dateString'" [label]="'Date'"></app-validation-message>
            </div>
          </div>

          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Start Time</b>
            </div>
            <div class="col-8">
              <p-calendar appendTo="body" inputStyleClass="form-control mt-2" styleClass="w-100" panelStyleClass="calendarPanel" dataType="string" name="startTimeString" formControlName="startTimeString" [timeOnly]="true" [hourFormat]="12"></p-calendar>
              <app-validation-message [form]="form" [path]="'startTimeString'" [label]="'Start Time'"></app-validation-message>
            </div>
          </div>

          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>End Time</b>
            </div>
            <div class="col-8">              
              <p-calendar appendTo="body" inputStyleClass="form-control mt-2" styleClass="w-100" panelStyleClass="calendarPanel" dataType="string" name="endTimeString" formControlName="endTimeString" [timeOnly]="true" [hourFormat]="12"></p-calendar>
              <app-validation-message [form]="form" [path]="'endTimeString'" [label]="'End Time'"></app-validation-message>
            </div>
          </div>

        </div>
      </div>
    </div>    
  </form>
  <p-footer>
    <button class="btn btn-danger" type="button" (click)="deleteHoliday()" [disabled]="disableButtons" [hidden]="hideDelete">Delete</button>
    <button class="btn btn-secondary" type="button" (click)="closeModal()">Cancel</button>
    <button class="btn btn-primary" type="submit" (click)="saveHolidayEdits()" [disabled]="disableButtons">Save</button>
  </p-footer>
</p-dialog>
