import { createAction, props } from '@ngrx/store';
import { PagingResultsModel } from '../../../models/pagingResultsModel';
import { EmployeeReviewTemplateCategoryModel, EmployeeReviewTemplateKpiCategoryModel, EmployeeReviewTemplateKPIModel, EmployeeReviewTemplateKRAModel, EmployeeReviewTemplateMeasureableModel, EmployeeReviewTemplateModel, EmployeeReviewTemplateDraft, EmployeeReviewTemplateValueColorModel, EmployeeReviewTemplateScoreType, EmployeeReviewTemplateScoreOptionsSet } from '../../../models/employeeReviewTemplateModels';
import { FormError } from 'src/app/models/utilModels';

export const loadTemplates = createAction('[Employee Review Template] Load Templates',
  props<{ filter: any }>());
export const loadTemplatesSuccess = createAction('[Employee Review Template] Load Templates Success',
  props<{ employeeReviewTemplates: PagingResultsModel<EmployeeReviewTemplateModel> }>());

export const getTemplate = createAction('[Employee Review Template] Get Template',
  props<{ templateId: number }>());
export const getTemplateSuccess = createAction('[Employee Review Template] Get Template Success',
  props<{ template: EmployeeReviewTemplateModel }>());

export const loadKRAs = createAction('[Employee Review Template] Load KRAs',
  props());
export const loadKRAsSuccess = createAction('[Employee Review Template] Load KRAs Success',
  props());

export const loadKPIs = createAction('[Employee Review Template] Load KPIs',
  props());
export const loadKPIsSuccess = createAction('[Employee Review Template] Load KPIs Success',
  props());

export const loadCategories = createAction('[Employee Review Template] Load Categories');
export const loadCategoriesSuccess = createAction('[Employee Review Template] Load Categories Success',
  props<{ categories: EmployeeReviewTemplateCategoryModel[] }>());

export const loadMeasureable = createAction('[Employee Review Template] Load Categories',
  props());
export const loadMeasureableSuccess = createAction('[Employee Review Template] Load Categories Success',
  props<{ categories: EmployeeReviewTemplateMeasureableModel[] }>());


export const saveEmployeeReviewTemplate = createAction('[Employee Review Template] Save Employee Review Template', props<{ template: EmployeeReviewTemplateModel }>());
export const saveEmployeeReviewTemplateSuccess = createAction('[Employee Review Template] Save Employee Review Template Success', props<{ template: EmployeeReviewTemplateModel }>());
export const saveEmployeeReviewTemplateFailure = createAction('[Employee Review Template] Save Employee Review Template Failure');

export const saveEmployeeReviewTemplateDraft = createAction('[Employee Review Template] Save Employee Review Template Draft', props<{ template: EmployeeReviewTemplateModel }>());
export const saveEmployeeReviewTemplateDraftSuccess = createAction('[Employee Review Template] Save Employee Review Template Success Draft', props<{ draftId: number }>());

export const findEmployeeReviewTemplateDraft = createAction('[Employee Review Template] Find Employee Review Template Draft', props<{ templateId: number }>());
export const findEmployeeReviewTemplateDraftSuccess = createAction('[Employee Review Template] Find Employee Review Template Success Draft', props<{ templateDraft: EmployeeReviewTemplateDraft }>());
export const findEmployeeReviewTemplateDraftFailure = createAction('[Employee Review Template] Find Employee Review Template Failure Draft');

export const deleteEmployeeReviewTemplateDraft = createAction('[Employee Review Template] Delete Employee Review Template Draft', props<{ draftId: number }>());
export const deleteEmployeeReviewTemplateDraftSuccess = createAction('[Employee Review Template] Delete Employee Review Template Success Draft', props());
export const deleteEmployeeReviewTemplateDraftFailure = createAction('[Employee Review Template] Delete Employee Review Template Failure Draft');

export const saveEmployeeReviewCategory = createAction('[Employee Review Template] Save Employee Review Template Category', props<{ category: EmployeeReviewTemplateCategoryModel }>());
export const saveEmployeeReviewCategorySuccess = createAction('[Employee Review Template] Save Employee Review Template Category Success', props<{ category: EmployeeReviewTemplateCategoryModel }>());
export const saveEmployeeReviewCategoryFailure = createAction('[Employee Review Template] Save Employee Review Template Category Failure');

export const removeMeasureable = createAction('[Employee Review Template] Remove Measureable', props<{ measureable: EmployeeReviewTemplateMeasureableModel }>());
export const removeKpi = createAction('[Employee Review Template] Remove KPI', props<{ kpi: EmployeeReviewTemplateKPIModel }>());
export const removeKra = createAction('[Employee Review Template] Remove KRA', props<{ kra: EmployeeReviewTemplateKRAModel }>());
export const removeKpiCategory = createAction('[Employee Review Template] Remove KPI Category', props<{ kpiCategory: EmployeeReviewTemplateKpiCategoryModel }>());

export const getValueColors = createAction('[Employee Review Template] Get ValueColors');
export const getValueColorsSuccess = createAction('[Employee Review Template] Get ValueColorss Success',
  props<{ valueColors: EmployeeReviewTemplateValueColorModel[] }>());
export const getValueColorsFailure = createAction('[Employee Review Template] Get ValueColors Failure');



export const updateValueColorForm = createAction('[Employee Review Template]  Update ValueColor Form',
  props<{ values: any, formErrors: FormError[] }>());

export const saveValueColor = createAction('[Employee Review Template] Save ValueColor'
  , props<{ valueColor: EmployeeReviewTemplateValueColorModel }>());
export const saveValueColorSuccess = createAction('[Employee Review Template] Save Employee ValueColor Success'
  , props<{ valueColor: EmployeeReviewTemplateValueColorModel }>());
export const saveValueColorFailure = createAction('[Employee Review Template] Save Employee ValueColor Failure');

export const deleteValueColor = createAction('[Employee Review Template] Delete ValueColor', props<{ valueColorID: number }>());
export const deleteValueColorSuccess = createAction('[Employee Review Template] Delete Employee ValueColor Success');
export const deleteValueColorFailure = createAction('[Employee Review Template] Delete Employee ValueColor Failure');

export const showKpiEthicsCodes = createAction('[Employee Review Template] Show KPI Ethics Codes', props<{ kpi: EmployeeReviewTemplateKPIModel }>());
export const closeKpiEthicsCodes = createAction('[Employee Review Template] Close KPI Ethics Codes');
export const addAndCloseKpiEthicsCodes = createAction('[Employee Review Template] Add and Close KPI Ethics Codes', props<{ kpi: EmployeeReviewTemplateKPIModel }>());


export const getScoreTypes = createAction('[Employee Review Template] Get Score Types');
export const getScoreTypesSuccess = createAction('[Employee Review Template] Get Score Types Success',
  props<{ scoreTypes: EmployeeReviewTemplateScoreType[] }>());
export const getScoreTypesFailure = createAction('[Employee Review Template] Get Score Types Failure');

export const getScoreOptionsSets = createAction('[Employee Review Template] Get ScoreOptionsSets');
export const getScoreOptionsSetsSuccess = createAction('[Employee Review Template] Get ScoreOptionsSets Success',
  props<{ scoreOptionsSets: EmployeeReviewTemplateScoreOptionsSet[] }>());
export const getScoreOptionsSetsFailure = createAction('[Employee Review Template] Get ScoreOptionsSets Failure');

export const toggleTemplateArchived = createAction('[Employee Review Template] Toggle Template Archived',
  props<{ templateId: number }>());
export const toggleTemplateArchivedSuccess = createAction('[Employee Review Template] Toggle Templatet Archived Success');
export const toggleTemplateArchivedFail = createAction('[Employee Review Template] Toggle Template Archived Fail',
  props<{ err: any }>());
