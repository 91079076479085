import { Component, OnInit } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { State } from '../../../../core/store';
import { Store } from '@ngrx/store';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { FormGroup } from '@angular/forms';
import { PtoEarnedModel } from '../../../../models/timeoffModel';
import { SelectItem } from 'primeng/api';
import { initEditPtoEarnedForm } from '../../../../core/store/timeoff/timeoff.reducer';
import { getAllControlsErrors, markFormGroupTouched, updateFormGroupValidity } from '../../../../helpers/utils';
import { closeEditPtoEarned, getEmployeePtoSummary, savePtoEarned, savePtoEarnedSuccess, updatePtoEarnedForm } from '../../../../core/store/timeoff/timeoff.actions';
import { tap } from 'rxjs/operators';
import { loadEmployeeUsers } from '../../../../core/store/employee/employee.action';

@Component({
  selector: 'app-edit-pto-earned',
  templateUrl: './edit-pto-earned.component.html',
  styleUrls: ['./edit-pto-earned.component.scss']
})
export class EditPtoEarnedComponent extends BaseComponent implements OnInit {
  display: boolean = false;
  title: string;
  form: FormGroup;
  disableButtons: boolean = true;
  ptoEarned: PtoEarnedModel = {} as PtoEarnedModel;
  userId: number;
  totalNet: string;
  totalYtd: string;
  disableName: boolean = true;
  currentUserId: number;

  prefixText: string;
  userList: SelectItem[];

  constructor(
    private actions$: Actions,
    private store: Store<State>) {
    super();
  }

  ngOnInit() {
    this.form = initEditPtoEarnedForm({} as PtoEarnedModel);
    this.subs.push(
      this.store.select(s => s.accountState.user).subscribe(user => {
        this.currentUserId = user.id;
      }),
      this.store.select(s => s.timeoffState.editingPtoEarned).subscribe(editing => {
        this.display = editing;
      }),
      this.store.select(s => s.timeoffState.savingPtoEarned).subscribe(saving => {
        this.disableButtons = saving;
      }),
      this.store.select(s => s.timeoffState.employeePtoSummary).subscribe(summary => {
        if (summary == null) {
          return;
        }

        this.totalNet = summary.totalNetString;
        this.totalYtd = summary.totalYtdString;
      }),
      this.store.select(s => s.timeoffState.selectedPtoEarned).subscribe(request => {
        if (request === null) {
          this.ptoEarned = null;
          return;
        }

        const changedRequest = !this.ptoEarned || (this.ptoEarned.ptoId !== request.ptoId);
        this.ptoEarned = JSON.parse(JSON.stringify(request));

        if (changedRequest) {
          this.form = initEditPtoEarnedForm(this.ptoEarned);          
          this.subs.push(
            this.form.valueChanges.subscribe(formValues => {
              const formErrors = getAllControlsErrors(this.form);
              this.store.dispatch(updatePtoEarnedForm({ formValues, formErrors }));
            })
          )
          if (typeof request.ptoId === 'undefined' || request.ptoId === null || request.ptoId < 1) {
            this.title = "New PTO Earned";
            this.prefixText = "";
            this.userId = 0;
            this.disableName = false;
            this.store.dispatch(updatePtoEarnedForm({ formValues: this.form.value, formErrors: getAllControlsErrors(this.form) }));
          } else {
            this.title = "Edit PTO Earned #" + request.ptoId;
            this.prefixText = request.employeeName;
            this.userId = request.userId;
            this.disableName = true;
          }

          this.loadPtoSummaryInfo();
        }
      }),
      this.store.select(s => s.employeeState.employeeUsers).subscribe(users => {
        this.userList = users;
      }),
      this.actions$.pipe(
        ofType(savePtoEarnedSuccess),
        tap(() => {
          this.closeModal();
        })
      ).subscribe(),
    );
  }


  closeModal() {
    this.store.dispatch(closeEditPtoEarned());
    this.totalYtd = "";
    this.totalNet = "";
  }

  savePtoEarned() {
    updateFormGroupValidity(this.form);
    if (this.form.valid) {
      this.ptoEarned.userId = this.userId;
      this.store.dispatch(savePtoEarned({ ptoEarned: this.ptoEarned }));
    }
    else {
      markFormGroupTouched(this.form);
    }
  }

  getStaffList(event) {
    this.store.dispatch(loadEmployeeUsers({
      prefixText: event.query,
      programId: 0,
      payrollClassificationId: 0,
      showInactive: false,
      showTerminated: false
    }));
  }

  selectStaff(selectedUser) {
    if (typeof selectedUser.value !== 'number') {
      return;
    }

    this.userId = selectedUser.value;
    this.loadPtoSummaryInfo();
  }

  clearStaff(event) {
    if (event.data !== null) {
      return;
    }

    this.userId = 0;
    this.loadPtoSummaryInfo();
  }

  loadPtoSummaryInfo() {
    if (this.userId < 1) {
      this.totalNet = "";
      this.totalYtd = "";
      return;
    }

    this.store.dispatch(getEmployeePtoSummary({ userId: this.userId }));
  }
}
