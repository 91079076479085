import { TimeCardModel } from 'src/app/models/timeCardModel';
import { fixTimeCardSave } from './../../../../core/store/timecard/timecard.actions';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';

@Component({
  selector: 'app-timecard-fix',
  templateUrl: './timecard-fix.component.html',
  styleUrls: ['./timecard-fix.component.scss']
})
export class TimeCardFixComponent extends BaseComponent implements OnInit {

  fixReason: string;
  clickedSave;
  timeCard: TimeCardModel;

  constructor(
    private store: Store<State>) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.timecardState.fixingTimeCard).subscribe(timeCard => {
        this.timeCard = JSON.parse(JSON.stringify(timeCard));
      })
    );
  }

  save() {
    if (this.fixReason) {
      this.store.dispatch(fixTimeCardSave({ fixReason: this.fixReason, timecardID: this.timeCard.id }));
    } else {
      this.clickedSave = true;
    }
  }

}
