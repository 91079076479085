import { createReducer, on, Action } from '@ngrx/store';
import { SelectItem } from 'primeng';
import { clearAutoCompleteList, getInputAutocompleteList, getInputAutocompleteListFail, getInputAutocompleteListSuccess, toggleLoading } from './input-autocomplete.action';

export interface InputAutoCompleteState {
  loading: boolean;
  autoCompleteList: SelectItem[];
}

const initialState: InputAutoCompleteState = {
    loading: false,
    autoCompleteList: null,
}

export const inputAutoCompleteReducer = createReducer(initialState,
    on(toggleLoading, (state, action) => {
        return {
          ...state,
          loading: !state.loading,
        };
      }),
      on(clearAutoCompleteList, (state, action) => {
        return {
          ...state,
          loading: !state.loading,
          autoCompleteList: null,
        };
      }),
      on(getInputAutocompleteList, (state, action) => {
        return {
          ...state,
          loading: true,
        };
    }),
    on(getInputAutocompleteListSuccess, (state, action) => {
        return {
            ...state,
            loading: false,
            autoCompleteList: action.result

        };
      }),
      on(getInputAutocompleteListFail, (state, action) => {
        return {
          ...state,
          loading: true,
        };
      }),
)

export function inputAutoCompleteReducerFunc(state: InputAutoCompleteState | undefined, action: Action) {
  return inputAutoCompleteReducer(state, action);
}
