import {
  signEquipmentAssign, signEquipmentAssignSuccess,
  getEquipmentAssignPdf
} from './../../../../core/store/equipment/equipment.actions';
import { loadProfile } from './../../../../core/store/profile/profile.actions';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from './../../../../shared/services/alert.service';
import { UserModel } from 'src/app/models/userModel';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { Actions, ofType } from '@ngrx/effects';
import { switchMap, tap } from 'rxjs/operators';
import { Utils, downloadFile } from 'src/app/helpers/utils';
import * as moment from 'moment';
import { SignDocComponentBase } from '../../../abstract/signDocBase.component';

@Component({
  selector: 'app-sign-assign-equipment',
  templateUrl: './sign-assign-equipment.component.html',
  styleUrls: ['./sign-assign-equipment.component.scss']
})
export class SignAssignEquipmentComponent extends SignDocComponentBase implements OnInit, AfterViewInit {

  assignedEquipmentID: number;
  loadingSign = false;
  loadingDoc = true;
  user: UserModel;

  constructor(
    private store: Store<State>,
    private actions$: Actions,
    private alert: AlertService,
    private router: Router,
    private route: ActivatedRoute) {
    super(store);
  }

  ngOnInit() {
    this.store.dispatch(loadProfile());
    this.route.params.subscribe(params => {
      if (params.assignedEquipmentID) {
        this.assignedEquipmentID = params.assignedEquipmentID;
        this.store.dispatch(getEquipmentAssignPdf({ assignedEquipmentID: params.assignedEquipmentID, returned: false, download: false }));
      }
    });
    this.subs.push(
      this.store.select(s => s.profileState.profile).subscribe(p => {
        if (p) {
          this.user = p.user;
        }
      }),
      this.store.select(s => s.equipmentState.docArrayBuffer).subscribe(docArrayBuffer => {
        if (docArrayBuffer) {
          this.url =
            URL.createObjectURL(new Blob([docArrayBuffer], {
              type: 'application/pdf'
            }));
        }
      }),
      this.store.select(s => s.equipmentState.loadingDoc).subscribe(loadingDoc => {
        this.loadingDoc = loadingDoc;
      }),
      this.store.select(s => s.equipmentState.loadingSign).subscribe(loadingSign => {
        this.loadingSign = loadingSign;
      }),
      this.actions$.pipe(
        ofType(signEquipmentAssignSuccess),
        tap(action => {
          this.alert.confirm('Documentation signed succesfully',
            'Documentation signed succesfully. Do you want to download the signed file now?')
            .subscribe(
              answer => {
                if (answer) {
                  downloadFile(action.docSigned, 'Equipment Assign Signed.pdf');
                }
                this.router.navigate(['/equipment/my']);
              }, error => {
                this.router.navigate([`/equipment/my`]);
              }
            );
        })
      ).subscribe()
    );
  }

  get loggedUserName() {
    return !this.user ? '' : (this.user.firstName + ' ' + this.user.lastName);
  }

  get loggedUserEmail() {
    return !this.user ? '' : this.user.email;
  }

  sign() {
    if (!this.finishSignDisabled) {
      this.store.dispatch(signEquipmentAssign({ assignedEquipmentID: this.assignedEquipmentID, signedFields: this.signedFields }));
    }
  }

}
