import { filter, tap } from 'rxjs/operators';
import { updateBenefitsPlanForm, saveBenefitsPlan, loadBenfitPlanTypesSuccess, loadBenfitPlanTypes, loadElegibilityWaitingPeriods, loadElegibilityBegins, loadTerminationUpons, removeBenefitPlanDocument, addBenefitPlanDocument, removeBenefitPlanLink, addBenefitPlanLink, loadCoverageLevels, removeBenefitPlanCost, addBenefitPlanCost, saveBenefitsPlanSuccess, loadAllBenefitPlans, loadBenefitsSettings, changedBenefitClasses, loadBenefitClasses, loadCarrierBenfitPlans } from './../../../../core/store/benefits/benefits.actions';
import { BenefitPlanCostModel, BenefitPlanDocumentModel, BenefitPlanFullModel, BenefitPlanLinkModel, BenefitPlanModel, BenefitsSettingsModel, BenefitClassModel, BenefitPlanCoverageLevelModel } from './../../../../models/benefitsModels';
import { loadCampuses } from './../../../../core/store/payroll-approval/payroll-approval.actions';
import { additTimeCard, updateTimeCardForm } from './../../../../core/store/timecard/timecard.actions';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { EmailValidator, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { markFormGroupTouched, getAllControlsErrors, updateFormGroupValidity, deepClone, formatDateString, getDateString, getShortDateFormatted } from './../../../../../app/helpers/utils';
import { BaseComponent } from './../../../../core/abstracts/baseComponent';
import { initProspectiveCandidateForm } from './../../../../core/store/prospective-candidates/prospective-candidate.reducer';
import { ActivatedRoute, Router } from '@angular/router';
import { clearBenefitsPlanInfo, loadBenefitsPlanInfo } from 'src/app/core/store/benefits/benefits.actions';
import { initBenefitPlanForm } from 'src/app/core/store/benefits/benefits.reducer';
import { Actions, ofType } from '@ngrx/effects';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-benefits-plan-addit',
  templateUrl: './benefits-plan-addit.component.html',
  styleUrls: ['./benefits-plan-addit.component.scss']
})
export class BenefitsPlanAddItComponent extends BaseComponent implements OnInit {

  benefitPlanFull: BenefitPlanFullModel;
  form: FormGroup;
  benefitPlanID: number;
  fileToUpload: File = null;
  periodDates;
  carrierBenefitPlanOptions = [];
  benefitPlanTypeOptions = [];
  elegibilityWaitingPeriodOptions = [];
  elegibilityBeginOptions = [];
  terminationUponOptions = [];
  classesOptions: SelectItem[] = [];
  coverageLevelOptions = [];
  coverageLevelList: BenefitPlanCoverageLevelModel[] = [];
  savingBenefitPlan = false;
  paylocityEnabled: boolean = false;
  motivHealthEnabled: boolean = false;
  sameraHealthEnabled: boolean = false;
  cobraEnabled: boolean = false;

  documentToAdd: BenefitPlanDocumentModel = { new: true } as BenefitPlanDocumentModel;
  documentsToAdd: BenefitPlanDocumentModel[] = [];
  documentForm: FormGroup;
  openAddDocument: boolean;


  linkToAdd: BenefitPlanLinkModel = {} as BenefitPlanLinkModel;
  linkForm: FormGroup;
  openAddLink: boolean;

  costToAdd: BenefitPlanCostModel = {} as BenefitPlanCostModel;
  costForm: FormGroup;
  openAddCost: boolean;
  plansOptions = [];
  benefitsSettings: BenefitsSettingsModel;
  classes: BenefitClassModel[] = [];

  yesNoOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  constructor(
    private actions$: Actions,
    private alertService: AlertService,
    private store: Store<State>,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder) {
    super();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params.benefitPlanID) {
        this.benefitPlanID = params.benefitPlanID;
        this.store.dispatch(loadBenefitsPlanInfo({ benefitPlanID: params.benefitPlanID }));
      } else {
        this.store.dispatch(clearBenefitsPlanInfo());
        var nextYear = new Date().getFullYear() + 1;
        this.benefitPlanFull = {
          benefitPlan: {
            active: true,
            ellegibilityWaitingPeriodID: 1,
            ellegibilityBeginID: 1, // Default to first option
            terminationUponID: 1,

            dateStart: getShortDateFormatted(new Date(nextYear, 0, 1)),
            dateEnd: getShortDateFormatted(new Date(nextYear , 11, 31)),
            renewalDate: getShortDateFormatted(new Date(nextYear+1,0,1))


          } as BenefitPlanModel
        } as BenefitPlanFullModel;
        this.initForm();
      }
    });
    this.subs.push(
      this.store.select(s => s.benefitsState.benefitPlan).subscribe(benefitPlan => {
        if (benefitPlan) {
          this.benefitPlanFull = deepClone(benefitPlan);
          if (!this.form) {
            this.initForm();
            this.classes = benefitPlan.classes;
          }
          this.updateAvailableCoverageLevels();

        }
      }),
      this.store.select(s => s.benefitsState.benefitPlanTypesList).subscribe(benefitPlanTypesList => {
        if (benefitPlanTypesList) {
          this.benefitPlanTypeOptions = benefitPlanTypesList.map(x => ({ label: x.planTypeName, value: x.id }));
        }
      }),
      this.store.select(s => s.benefitsState.carrierBenefitPlanList).subscribe(carrierBenefitPlanList => {
        if (carrierBenefitPlanList) {
          this.carrierBenefitPlanOptions = carrierBenefitPlanList.map(x => ({ label: x.carrier + " - " + x.planName, value: x.id }));
        }
      }),
      this.store.select(s => s.benefitsState.savingBenefitPlan).subscribe(savingBenefitPlan => {
        this.savingBenefitPlan = savingBenefitPlan;
      }),

      this.store.select(s => s.benefitsState.ellegibilityWaitingPeriodsList).subscribe(ellegibilityWaitingPeriodsList => {
        if (ellegibilityWaitingPeriodsList) {
          this.elegibilityWaitingPeriodOptions =
            ellegibilityWaitingPeriodsList.map(x => ({ label: x.ellegibilityWaitingPeriod, value: x.id }));
        }
      }),
      this.store.select(s => s.benefitsState.ellegibilityBeginsList).subscribe(ellegibilityBeginsList => {
        if (ellegibilityBeginsList) {
          this.elegibilityBeginOptions = ellegibilityBeginsList.map(x => ({ label: x.ellegibilityBegin, value: x.id }));
        }
      }),
      this.store.select(s => s.benefitsState.terminationUponsList).subscribe(terminationUponsList => {
        if (terminationUponsList) {
          this.terminationUponOptions = terminationUponsList.map(x => ({ label: x.terminationUpon, value: x.id }));
        }
      }),
      this.store.select(s => s.benefitsState.coverageLevelList).subscribe(coverageLevelList => {
        if (coverageLevelList) {
          this.coverageLevelList = coverageLevelList;
          this.updateAvailableCoverageLevels();
        }
      }),
      this.store.select(s => s.benefitsState.allBenefitPlans).subscribe(allBenefitPlans => {
        if (allBenefitPlans) {
          this.plansOptions = allBenefitPlans.map(x => ({ label: x.planName, value: x.id }));
          this.plansOptions.unshift({ label: '-', value: null });
        }
      }),

      this.store.select(s => s.benefitsState.benefitsSettings).subscribe(benefitsSettings => {
        if (benefitsSettings) {
          this.benefitsSettings = benefitsSettings;
        }
      }),
      this.store.select(s => s.accountState.featureSwitchesIds).subscribe(featureSwitchesIds => {
        this.paylocityEnabled = featureSwitchesIds && !!featureSwitchesIds.find(p => p === 85);
        this.cobraEnabled = featureSwitchesIds && !!featureSwitchesIds.find(p => p === 87);
      }),
      this.store.select(s => s.accountState.featureSwitchesIds).subscribe(featureSwitchesIds => {
        this.motivHealthEnabled = featureSwitchesIds && !!featureSwitchesIds.find(p => p === 121);
        this.sameraHealthEnabled = featureSwitchesIds && !!featureSwitchesIds.find(p => p === 122);
      }),
      this.store.select(s => s.benefitsState.benefitClasses).subscribe(benefitClasses => {
        if (benefitClasses) {
          this.classesOptions = benefitClasses.map(x => ({ label: x.class, value: x }));
        }
      }),
      this.actions$.pipe(
        ofType(saveBenefitsPlanSuccess),
        tap(action => {
          this.alertService.success('Benefits Plan saved!');
          this.router.navigate(['/benefits-management/benefit-plan/list']);
        })
      ).subscribe()

    );

    this.store.dispatch(loadCarrierBenfitPlans());
    this.store.dispatch(loadBenefitClasses());
    this.store.dispatch(loadBenfitPlanTypes());
    this.store.dispatch(loadElegibilityWaitingPeriods());
    this.store.dispatch(loadElegibilityBegins());
    this.store.dispatch(loadTerminationUpons());
    this.store.dispatch(loadCoverageLevels());
    this.store.dispatch(loadAllBenefitPlans());
    this.store.dispatch(loadBenefitsSettings());

  }

  initForm() {
    this.form = initBenefitPlanForm(this.benefitPlanFull.benefitPlan);
    const januaryFirst = new Date(new Date().getFullYear(), 0, 1);
    const december31 = new Date(new Date().getFullYear(), 11, 31);
    this.periodDates = [this.form.get('dateStart').value ? this.form.get('dateStart').value : januaryFirst,
    this.form.get('dateEnd').value ? this.form.get('dateEnd').value : december31];
    this.subs.push(
      this.form.valueChanges.subscribe(formValues => {
        const formErrors = getAllControlsErrors(this.form);
        this.store.dispatch(updateBenefitsPlanForm({ benefitPlanValues: formValues, formErrors }));
      }));

    this.documentForm = new FormGroup({
      documentName: new FormControl('', [Validators.required, Validators.maxLength(255)]),
      file: new FormControl(null, [Validators.required]),
    });

    const regUrl = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    this.linkForm = new FormGroup({
      linkName: new FormControl('', [Validators.required, Validators.maxLength(255)]),
      url: new FormControl('', [Validators.required, Validators.pattern(regUrl)]),
    });

    this.costForm = this.formBuilder.group({
      id: [],
      coverageLevelID: [null, [Validators.required]],
      monthlyCost: [null, [Validators.required]],
      monthlyCost25: [],
      monthlyCost30: [],
      monthlyCost35: [],
      monthlyCost40: [],
      monthlyCost45: [],
      monthlyCost50: [],
      monthlyCost55: [],
      monthlyCost60: [],
      monthlyCost65: [],
      monthlyCost70: [],
      monthlyCost75: [],
      monthlyCost80: [],
      costByAge: [false, [Validators.required]],
    });
  }

  isPlanHSA() {
    const type = this.benefitPlanTypeOptions.find(x => x.value === this.form.get('benefitPlanTypeID').value);
    if (type) {
      return type.label === 'Health Savings Account';
    }
    return false;
  }

  isCobraEligible() {
    return this.form.value.isCobraEligible === true;
  }

  updateAvailableCoverageLevels() {
    if (this.coverageLevelList && this.benefitPlanFull) {
      this.coverageLevelOptions = this.coverageLevelList.reduce((filtered, x) => {
        if (this.costToAdd?.coverageLevelID == x.id || (!this.benefitPlanFull.costs || !this.benefitPlanFull.costs.find(y => y.coverageLevelID === x.id)))
          filtered.push({ label: `${x.coverageLevel} - ${x.description}`, value: x.id });
          return filtered;
        }, []);
    }
  }

  save() {
    if (this.benefitsSettings.irsContributionLimit && this.form.get('elegibleYearlyContribution').value
      && this.form.get('elegibleYearlyContribution').value > this.benefitsSettings.irsContributionLimit) {
      this.alertService
        .error('The HSA Yearly COntribution cannot be higher then the IRS contribution limit ($' + this.benefitsSettings.irsContributionLimit + ')');
      return;
    }
    updateFormGroupValidity(this.form);
    if (this.form.valid) {
      // Files are kept on the component cause when we serialize them on the state they loose its value.
      // Here we set the value back
      if (this.benefitPlanFull.documents) {
        this.benefitPlanFull.documents.forEach(doc => {
          if (doc.new) {
            const i = this.documentsToAdd.findIndex(x => x.documentName === doc.documentName);
            if (i >= 0) {
              doc.file = this.documentsToAdd[i].file;
            }
          }
        }
        );
      }
      this.benefitPlanFull.benefitPlan.dateStart = formatDateString(this.benefitPlanFull.benefitPlan.dateStart);
      this.benefitPlanFull.benefitPlan.dateEnd = formatDateString(this.benefitPlanFull.benefitPlan.dateEnd);
      this.benefitPlanFull.benefitPlan.renewalDate = formatDateString(this.benefitPlanFull.benefitPlan.renewalDate);
      if (this.benefitPlanFull.benefitPlan.id) {
        this.store.dispatch(saveBenefitsPlan({ benefitPlan: this.benefitPlanFull }));
      } else {
        this.store.dispatch(saveBenefitsPlan({ benefitPlan: this.benefitPlanFull }));
      }
    } else {
      markFormGroupTouched(this.form);
    }
  }

  changedFile(fileInput) {
    if (fileInput.files.length > 0) {
      this.documentToAdd.file = fileInput.files[0];
    } else {
      this.documentToAdd.file = null;
    }
  }

  changedDates() {
    this.form.get('dateStart').markAsTouched();
    this.form.get('dateEnd').markAsTouched();
    if (this.periodDates) {
      this.form.get('dateStart').setValue(this.periodDates[0]);
      this.form.get('dateEnd').setValue(this.periodDates[1]);
    }
  }

  changedClasses() {
    this.store.dispatch(changedBenefitClasses({ classes: this.classes }));
  }

  getPreviewUrl(doc) {
    if (doc.fileType.indexOf('image') >= 0) {
      return doc.resumeURL;
    }

    if (doc.fileType.indexOf('pdf') >= 0) {
      return '/assets/img/pdf.png';
    }

    if (doc.fileType.indexOf('application/vnd.openxmlformats-officedocument.wordprocessingml.document') >= 0
      || doc.fileType.indexOf('doc') >= 0
      || doc.fileType.indexOf('msword') >= 0) {
      return '/assets/img/doc.png';
    }
  }

  removeDocument(document: BenefitPlanDocumentModel) {
    this.store.dispatch(removeBenefitPlanDocument({ document }));
  }


  addDocument() {
    updateFormGroupValidity(this.documentForm);
    if (this.documentForm.valid) {
      this.documentsToAdd.push(this.documentToAdd);
      this.store.dispatch(addBenefitPlanDocument({ document: this.documentToAdd }));
      this.documentToAdd = { new: true } as BenefitPlanDocumentModel;
      this.documentForm.reset();
    } else {
      markFormGroupTouched(this.documentForm);
    }
  }


  removeLink(link: BenefitPlanLinkModel) {
    this.store.dispatch(removeBenefitPlanLink({ link }));
  }


  addLink() {
    updateFormGroupValidity(this.linkForm);
    if (this.linkForm.valid) {
      this.store.dispatch(addBenefitPlanLink({ link: this.linkToAdd }));
      this.linkToAdd = {} as BenefitPlanLinkModel;
      this.linkForm.reset();
    } else {
      markFormGroupTouched(this.linkForm);
    }
  }

  removeCost(cost: BenefitPlanCostModel) {
    this.store.dispatch(removeBenefitPlanCost({ cost }));
  }


  addCost() {
    if (this.openAddCost) {
      this.openAddCost = false;
      return;
    }
    this.costToAdd = {
      costByAge: (this.benefitPlanFull
        && this.benefitPlanFull.costs
        && this.benefitPlanFull.costs.length
        && this.benefitPlanFull.costs[this.benefitPlanFull.costs.length - 1].costByAge)
    } as BenefitPlanCostModel;
    this.updateAvailableCoverageLevels();
    if (this.coverageLevelOptions && this.coverageLevelOptions.length)
      this.costToAdd.coverageLevelID = this.coverageLevelOptions[0].value;
    this.costForm.reset();
    this.costForm.patchValue(this.costToAdd);
    this.openAddCost = true;
  }
  editCost(cost: BenefitPlanCostModel) {
    
    this.openAddCost = true;
    this.costToAdd = cost;
    this.costForm.clearValidators();
    this.updateAvailableCoverageLevels();
    this.costForm.patchValue(cost);
    
  }

  saveCost() {
    updateFormGroupValidity(this.costForm);
    if (this.costForm.valid) {
      let cost = this.costForm.getRawValue() as BenefitPlanCostModel ;
      this.store.dispatch(addBenefitPlanCost({ cost: cost }));
      this.costToAdd = {
        costByAge: cost.costByAge
      } as BenefitPlanCostModel;
      this.updateAvailableCoverageLevels();
      this.costForm.reset();
      this.costForm.patchValue(this.costToAdd);
    } else {
      markFormGroupTouched(this.costForm);
    }
  }
  

  getCoverageLevelLabel(id: number) {
    return this.coverageLevelList.find(x => x.id === id)?.coverageLevel || null;
  }
  getMonthlyCostLabel(cost: BenefitPlanCostModel) {
    if (!cost.costByAge) {
      if (cost.monthlyCost)
        return cost.monthlyCost.toFixed(2);
      return '-';
    }

    if (!cost.costByAge) {
      if (cost.monthlyCost)
        return '$' + cost.monthlyCost.toFixed(2);
      return '-';
    }

    var vals = [];

    var lastAge = 17;
    if (cost.monthlyCost25) { vals.push((++lastAge) + '-25: $' + (cost.monthlyCost25).toFixed(2)); lastAge = 25; };
    if (cost.monthlyCost30) { vals.push((++lastAge) + '-30: $' + (cost.monthlyCost30).toFixed(2)); lastAge = 30; };
    if (cost.monthlyCost35) { vals.push((++lastAge) + '-35: $' + (cost.monthlyCost35).toFixed(2)); lastAge = 35; };
    if (cost.monthlyCost40) { vals.push((++lastAge) + '-40: $' + (cost.monthlyCost40).toFixed(2)); lastAge = 40; };
    if (cost.monthlyCost45) { vals.push((++lastAge) + '-45: $' + (cost.monthlyCost45).toFixed(2)); lastAge = 45; };
    if (cost.monthlyCost50) { vals.push((++lastAge) + '-50: $' + (cost.monthlyCost50).toFixed(2)); lastAge = 50; };
    if (cost.monthlyCost55) { vals.push((++lastAge) + '-55: $' + (cost.monthlyCost55).toFixed(2)); lastAge = 55; };
    if (cost.monthlyCost60) { vals.push((++lastAge) + '-60: $' + (cost.monthlyCost60).toFixed(2)); lastAge = 60; };
    if (cost.monthlyCost65) { vals.push((++lastAge) + '-65: $' + (cost.monthlyCost65).toFixed(2)); lastAge = 65; };
    if (cost.monthlyCost70) { vals.push((++lastAge) + '-70: $' + (cost.monthlyCost70).toFixed(2)); lastAge = 70; };
    if (cost.monthlyCost75) { vals.push((++lastAge) + '-75: $' + (cost.monthlyCost75).toFixed(2)); lastAge = 75; };
    if (cost.monthlyCost80) { vals.push((++lastAge) + '-80: $' + (cost.monthlyCost80).toFixed(2)); lastAge = 80; };
    if (cost.monthlyCost) { vals.push((++lastAge) + '+: $' + (cost.monthlyCost).toFixed(2)); };
    if (vals.length == 0) return '-';
    return vals.join('<br/>');

  }
}
