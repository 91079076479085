import { createAction, props } from '@ngrx/store';
import { SelectItem } from 'primeng/api';
import { PagingResultsModel } from '../../../models/pagingResultsModel';
import { EmployeePtoSummaryModel, PtoEarnedModel, PtoRequestedModel, TimeOffApprovalModel, TimeOffModel } from '../../../models/timeoffModel';
import { FormError } from '../../../models/utilModels';


export const loadViewableCampuses = createAction('[Timeoff] Load Campuses');
export const loadViewableCampusesSuccess = createAction('[Timeoff] Load Campuses Success',
  props<{ campuses: SelectItem[] }>());

export const loadClassifications = createAction('[Timeoff] Load Classifications');
export const loadClassificationsSuccess = createAction('[Timeoff] Load Classifications Success',
  props<{ classifications: SelectItem[] }>());

export const getSummaryList = createAction('[Timeoff] Get Summary List',
  props<{ filter: any, campusId: number, payrollClassificationId: number, userId: number, showOnlyActive: boolean }>());
export const getSummaryListSuccess = createAction('[Timeoff] Get Summary List Success',
  props<{ summaryList: PagingResultsModel<EmployeePtoSummaryModel> }>());

export const getEmployeePtoSummary = createAction('[Timeoff] Get Employee Pto Summary',
  props<{userId: number}>());
export const getEmployeePtoSummarySuccess = createAction('[Timeoff] Get Employee Pto Summary Success',
  props<{ summary: EmployeePtoSummaryModel }>());

export const getEmployeeEarnedPto = createAction('[Timeoff] Get Employee Earned Pto',
  props<{ filter: any, userId: number }>());
export const getEmployeeEarnedPtoSuccess = createAction('[Timeoff] Get Employee Earned Pto Success',
  props<{ ptoList: PagingResultsModel<PtoEarnedModel> }>());

export const getEmployeeRequestedPto = createAction('[Timeoff] Get Employee Requested Pto',
  props<{ filter: any, userId: number }>());
export const getEmployeeRequestedPtoSuccess = createAction('[Timeoff] Get Employee Requested Pto Success',
  props<{ ptoList: PagingResultsModel<PtoRequestedModel> }>());

export const showEditPtoRequest = createAction('[Timeoff] Show Edit Pto Request Modal',
  props<{ ptoRequest: PtoRequestedModel }>());
export const closeEditPtoRequest = createAction('[Timeoff] Close Edit Pto Request Modal');

export const savePtoRequest = createAction('[Timeoff] Save Pto Request',
  props<{ ptoRequest: PtoRequestedModel }>());
export const savePtoRequestSuccess = createAction('[Timeoff] Save Pto Request Success');

export const updatePtoRequestForm = createAction('[Timeoff] Update Pto Request Form',
  props<{ formValues: any, formErrors: FormError[] }>());


export const getRecentTimeOff = createAction('[Timeoff] Get Recent TimeOff',
  props<{ filter: any, userId: number }>());
export const getRecentTimeOffSuccess = createAction('[Timeoff] Get Recent TimeOff Success',
  props<{ list: PagingResultsModel<TimeOffModel> }>());

export const getUpcomingTimeOff = createAction('[Timeoff] Get Upcoming TimeOff',
  props<{ filter: any, userId: number }>());
export const getUpcomingTimeOffSuccess = createAction('[Timeoff] Get Upcoming TimeOff Success',
  props<{ list: PagingResultsModel<TimeOffModel> }>());

export const enableSelectTimeOffEmployee = createAction('[Timeoff] Enable employee select when editing time off');
export const showEditTimeOffRequest = createAction('[Timeoff] Show Edit Time Off Request Modal',
  props<{ timeOffRequest: TimeOffModel }>());
export const closeEditTimeOffRequest = createAction('[Timeoff] Close Edit Time Off Request Modal');

export const saveTimeOffRequest = createAction('[Timeoff] Save Time Off Request',
  props<{ timeOffRequest: TimeOffModel }>());
export const saveTimeOffRequestSuccess = createAction('[Timeoff] Save Time Off Request Success');

export const updateTimeOffRequestForm = createAction('[Timeoff] Update Time Off Request Form',
  props<{ formValues: any, formErrors: FormError[] }>());

export const updateTimeOffApprovalForm = createAction('[Timeoff] Update Time Off Approval Form',
  props<{ formValues: any, formErrors: FormError[] }>());

export const getManagePtoEarnedList = createAction('[Timeoff] Get Manage PTO Earned List',
  props<{ filter: any }>());
export const getManagePtoEarnedListSuccess = createAction('[Timeoff] Get Manage PTO Earned List Success',
  props<{ ptoList: PagingResultsModel<PtoEarnedModel> }>());

export const getManageTimeOffList = createAction('[Timeoff] Get Manage Time Off List',
  props<{ filter: any }>());
export const getManageTimeOffListSuccess = createAction('[Timeoff] Get Manage Time Off List Success',
  props<{ list: PagingResultsModel<TimeOffModel> }>());

export const showEditPtoEarned = createAction('[Timeoff] Show Edit PTO Earned Modal',
  props<{ ptoEarned: PtoEarnedModel }>());
export const closeEditPtoEarned = createAction('[Timeoff] Close Edit PTO Earned Modal');

export const savePtoEarned = createAction('[Timeoff] Save PTO Earned',
  props<{ ptoEarned: PtoEarnedModel }>());
export const savePtoEarnedSuccess = createAction('[Timeoff] Save PTO Earned Success');

export const updatePtoEarnedForm = createAction('[Timeoff] Update PTO Earned Form',
  props<{ formValues: any, formErrors: FormError[] }>());

export const saveTimeOffApproval = createAction('[Timeoff] Save Time Off Approval',
  props<{ timeOffRequest: TimeOffModel }>());
export const saveTimeOffApprovalSuccess = createAction('[Timeoff] Save Time Off Approval Success');

export const resetTimeOffApproval = createAction('[Timeoff] Reset Time Off Approval',
  props<{ timeOffRequest: TimeOffModel }>());
export const resetTimeOffApprovalSuccess = createAction('[Timeoff] Reset Time Off Approval Success');

export const showApproveTimeOffRequest = createAction('[Timeoff] Show Approve Time Off Request Modal',
  props<{ timeOffRequest: TimeOffModel }>());
export const closeApproveTimeOffRequest = createAction('[Timeoff] Close Approve Time Off Request Modal');

export const getTimeOffApprovalSummary = createAction('[Timeoff] Get Time Off Approval Summary',
  props<{ timeOffId: number }>());
export const getTimeOffApprovalSummarySuccess = createAction('[Timeoff] Get Time Off Approval Summary Success',
  props<{ summary: TimeOffApprovalModel }>());

export const removeTimeOffRequest = createAction('[Timeoff] Remove Time Off Request',
  props<{ timeOffId: number }>());
export const removeTimeOffRequestSuccess = createAction('[Timeoff] Remove Time Off Request Success',
  props<{ success: any }>());


