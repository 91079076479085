<form class="card mt-2" *ngIf="canView">
  <div class="card-body">
      <h2 class="mb-3">Manage Employee Documents</h2>
      <h5>Filter</h5>
      <div class="row mb-1" >
        <div class="col-6">
          <div class="row mb-1" >
            <div class="col-4 d-flex align-items-center">
              <h6>Employee Name:</h6>
            </div>
            <div class="col-8 ">
              <input type="text" [(ngModel)]="employeeNameFilter" class="form-control" (ngModelChange)="changedFilters()"  [ngModelOptions]="{standalone: true}">
            </div>
          </div>


          <div class="row mb-1" >
            <div class="col-4 d-flex align-items-center">
              <h6>Document Name:</h6>
            </div>
            <div class="col-8 ">
              <input type="text" [(ngModel)]="documentNameFilter" class="form-control" (ngModelChange)="changedFilters()"  [ngModelOptions]="{standalone: true}">
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="row mb-1" >
            <div class="col-4 d-flex align-items-center">
              <h6>Created Date:</h6>
            </div>
            <div class="col-8 ">

              <p-calendar
                inputStyleClass="form-control"
                [monthNavigator]="true"
                [yearNavigator]="true"
                styleClass="w-75"
                panelStyleClass="calendarPanel"
                [hourFormat]="12"
                [yearRange]="'2010:2100'"
                [showTime]="false"
                [selectionMode]="'range'"
                [(ngModel)]="createDateFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"
                dataType="string"
              ></p-calendar>
            </div>
          </div>

          <div class="row mb-1" >
            <div class="col-4 d-flex align-items-center">
              <h6>Signed Date:</h6>
            </div>
            <div class="col-8 ">

              <p-calendar
                inputStyleClass="form-control"
                [monthNavigator]="true"
                [yearNavigator]="true"
                styleClass="w-75"
                panelStyleClass="calendarPanel"
                [hourFormat]="12"
                [yearRange]="'2010:2100'"
                [showTime]="false"
                [selectionMode]="'range'"
                [(ngModel)]="signedDateFilter" (ngModelChange)="changedFilters()" [ngModelOptions]="{standalone: true}"
                dataType="string"
              ></p-calendar>
            </div>
          </div>
        </div>
      </div>

    <i *ngIf="loadingUserDocs" class="material-icons rotate text-muted">loop</i>
    <p-table  [value]="onboardingUserDocs?.result" [responsive]="true"
    [paginator]="true" [rows]="15" [totalRecords]="onboardingUserDocs?.totalRecords" [lazy]="true"
    (onLazyLoad)="loadPage($event)">
        <ng-template pTemplate="header">
            <tr>
                <th [pSortableColumn]="'employee'">Employee
                  <p-sortIcon [field]="'employee'"></p-sortIcon>
                </th>
                <th [pSortableColumn]="'document'">Document
                <p-sortIcon [field]="'document'"></p-sortIcon></th>
                <th  width="90px">Created Date</th>
                <th  width="90px">Signed Date</th>
                <th  width="90px">Required</th>
                <th  width="190px">Force Not Required</th>
                <th  width="150px">Re-assign</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-onboardingUserDoc>
            <tr >
                <td>
                  <span class="ui-column-title">Employee</span>
                  {{onboardingUserDoc.userFullName}}
                </td>
                <td>
                  <span class="ui-column-title">Document</span>
                  {{onboardingUserDoc.onBoarding_Document.title}}
                </td>
                <td>
                  <span class="ui-column-title">Created Date</span>
                  {{(onboardingUserDoc.createdDate) | date:'shortDate' }}
                </td>
                <td>
                  <span class="ui-column-title">Signed Date</span>
                  {{(onboardingUserDoc.signedDate) | date:'shortDate' }}
                </td>

                <td>
                  <span class="ui-column-title">Required</span>
                  <i *ngIf="onboardingUserDoc.isReallyRequired" class="fas fa-check-circle text-success" title="Required"></i>
                  <i *ngIf="!onboardingUserDoc.isReallyRequired" class="fas fa-minus-circle text-muted" title="Not Required"></i>
              </td>
                <td>
                    <span class="ui-column-title">Force Not Required</span>
                    <button (click)="forceNotRequired(onboardingUserDoc)" class="btn btn-primary"  *ngIf="!onboardingUserDoc.signedDate && onboardingUserDoc.isReallyRequired && !onboardingUserDoc.forceNotRequired">Force not required</button>
                    <div *ngIf="!onboardingUserDoc.signedDate && onboardingUserDoc.forceNotRequired">
                    <i (click)="unForceNotRequired(onboardingUserDoc)" class="fas fa-check-circle text-success pointer" title="Forced not required (click to remove)"></i>

                  </div>
                </td>

                <td>
                  <span class="ui-column-title">Re-assign</span>
                  <button (click)="forceReassign(onboardingUserDoc)"  class="btn btn-primary" *ngIf="onboardingUserDoc.signedDate">Re-assign</button>
              </td>


            </tr>
        </ng-template>
    </p-table>
  </div>
</form>
