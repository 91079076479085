<form class="card mt-2">
  <div class="card-body">
    <div class="float-right">      
      <button *ngIf="canSeeSelfcare" type="button" routerLink="/selfcare/oversight" class="btn btn-secondary">Oversight</button>
    </div>
    <h2 class="mb-3">Self Care Completion Report</h2>
    <div class="mb-3">

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Campus:</h5>
        </div>
        <div class="col-10">
          <i *ngIf="!campusList" class="material-icons rotate text-muted">loop</i>
          <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="campusList" [(ngModel)]="campusId" (ngModelChange)="reloadFilter(this.filter)" [ngModelOptions]="{standalone: true}"></p-dropdown>
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Employee:</h5>
        </div>
        <div class="col-10">
          <div class="ml-2 w-100 mb-2">
            <p-autoComplete [style]="{'width': '100%'}" [inputStyle]="{'width': '100%'}" [(ngModel)]="prefixText"
                            [suggestions]="userEmployeeList" (completeMethod)="getStaffList($event)" field="label"
                            [ngModelOptions]="{standalone: true}" [minLength]="1" [autoHighlight]="true"
                            (onSelect)="reloadListAfterStaffChage($event)" [forceSelection]="true"
                            (onClear)="reloadListAfterClear($event)"></p-autoComplete>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5 title="As of the check date">Status:</h5>
        </div>
        <div class="col-10">
          <i *ngIf="!statusOptions" class="material-icons rotate text-muted">loop</i>
          <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="statusOptions" [(ngModel)]="status" optionValue="value" (ngModelChange)="reloadFilter(this.filter)" [ngModelOptions]="{standalone: true}"></p-dropdown>
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Show only active users:</h5>
        </div>
        <div class="col-10" style="padding-left: 25px;">
          <p-checkbox [(ngModel)]="showOnlyActive" (ngModelChange)="reloadFilter(this.filter)" [binary]="true" [ngModelOptions]="{standalone: true}"></p-checkbox>
        </div>
      </div>

    </div>

    <i [hidden]="!loadingList && !loadingExcel" class="material-icons rotate text-muted">loop</i>
    <button type="button" title="Export all pages to excel" class="btn btn-success mr-2 mb-2" (click)="exportToExcel()"><i class="far fa-file-excel"></i></button>
    <p-table [value]="list?.result" [responsive]="true" [paginator]="true" [rows]="20"
             [totalRecords]="list?.totalRecords" [lazy]="true" (onLazyLoad)="reloadFilter($event)">
      <ng-template pTemplate="header">
        <tr>
          <th [pSortableColumn]="'EmployeeName'">Employee <p-sortIcon [field]="'EmployeeName'"></p-sortIcon></th>
          <th [pSortableColumn]="'PrimaryProgramName'">Primary Campus <p-sortIcon [field]="'PrimaryProgramName'"></p-sortIcon></th>
          <th [pSortableColumn]="'CategoriesCompleted'">Categories Completed<p-sortIcon [field]="'CategoriesCompleted'"></p-sortIcon></th>
          <th [pSortableColumn]="'SectionsCompleted'">Sections Completed<p-sortIcon [field]="'SectionsCompleted'"></p-sortIcon></th>
          <th [pSortableColumn]="'DiscUploaded'">DISC Uploaded<p-sortIcon [field]="'DiscUploaded'"></p-sortIcon></th>
          <th [pSortableColumn]="'StandOutUploaded'">Stand Out Uploaded<p-sortIcon [field]="'StandOutUploaded'"></p-sortIcon></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-list>
        <tr>
          <td>
            <span class="ui-column-title">Employee</span>
            {{list.firstName}} {{list.lastName}} <span style="font-style:italic; color: red; font: 0.9rem;">{{getTerminationString(list)}}</span>
          </td>
          <td>
            <span class="ui-column-title">Primary Campus</span>
            {{list.primaryProgramName}}
          </td>
          <td>
            <span class="ui-column-title">Categories Completed</span>
            <span style="color:green; font-weight: bold;">{{(list.categoriesCompleted) ? "COMPLETED" : ""}}</span>
            <div *ngFor="let c of list.categories">
              <span [ngStyle]="{'color': (c.count == c.total) ? '#77BB5E' : '#E32726'}">{{c.selfCareName}} {{c.count}}/{{c.total}}</span><br />
            </div>
          </td>
          <td>
            <span class="ui-column-title">Sections Completed</span>
            <span style="color:green; font-weight: bold;">{{(list.sectionsCompleted) ? "COMPLETED" : ""}}</span>
            <div *ngFor="let c of list.sections">
              <span [ngStyle]="{'color': (c.count == c.total) ? '#77BB5E' : '#E32726'}">{{c.selfCareName}} {{c.count}}/{{c.total}}</span><br />
            </div>
          </td>
          <td>
            <span class="ui-column-title">DISC Uploaded</span>
            <i *ngIf="list.discUploaded" class="fas fa-check-circle text-success"></i>
          </td>
          <td>
            <span class="ui-column-title">Stand Out Uploaded</span>
            <i *ngIf="list.standOutUploaded" class="fas fa-check-circle text-success"></i>
          </td>          
        </tr>
      </ng-template>
    </p-table>
  </div>
</form>
