import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, tap, catchError, filter } from 'rxjs/operators';
import { of, pipe } from 'rxjs';
import { errorHappened, loadProfile } from '../profile/profile.actions';
import { Store } from '@ngrx/store';
import { State } from '..';
import { AccountService } from '../../services/account.service';
import { authenticateUser, authenticateSuccess, authenticateToken, authenticateCode, authenticateFailure, logout, logNavigation, logNavigationSuccess, getGeneralSettingsSuccess, getGeneralSettings, getFeatureSwitches, getFeatureSwitchesSuccess, setSessionConfirm } from './account.actions';
import { Router } from '@angular/router';
import { Logout } from '../rootActions';
import { AlertService } from "src/app/shared/services/alert.service";
import { MessageService } from 'primeng';

declare let ga: any;
declare let sessionConfirm: boolean;

@Injectable()
export class AccountEffects {
  constructor(private actions$: Actions,
    private accountService: AccountService,
    private alertService: AlertService,
    private messageService: MessageService,
    private router: Router,
    private store: Store<State>) {}

  authenticateUser = createEffect(() => this.actions$.pipe(
    ofType(authenticateUser),
    switchMap(action => {
      return this.accountService.authenticate(action.username, action.pass).pipe(
        map(user => authenticateSuccess({ user })),
        catchError(err => {
          this.store.dispatch(authenticateFailure());
          return of(errorHappened({ err }));
        }));
    }
    )));

  logNavigation = createEffect(() => this.actions$.pipe(
    ofType(logNavigation),
    switchMap(action => this.accountService.logNavigation(action.Url, action.parameters).pipe(
      map(response => logNavigationSuccess()),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))));
  
  authenticateToken = createEffect(() => this.actions$.pipe(
    ofType(authenticateToken),
    switchMap(action => {
      return this.accountService.authenticateToken(action.token).pipe(
        map(user => authenticateSuccess({ user })),
        catchError(err => {
          this.store.dispatch(authenticateFailure());
          return of(errorHappened({ err }));
        }));
    }
    )));

  authenticateCode = createEffect(() => this.actions$.pipe(
    ofType(authenticateCode),
    switchMap(action => {
      return this.accountService.authenticateCode(action.code).pipe(
        map(user => authenticateSuccess({ user })),
        catchError(err => {
          this.store.dispatch(authenticateFailure());
          return of(errorHappened({ err }));
        }));
    }
    )));

  authenticateSuccess = createEffect(() => this.actions$.pipe(
    ofType(authenticateSuccess),
    tap(action => {
      let returnUrl = '/';
      const sub = this.store.select(s => s.accountState.returnUrl).subscribe(result => {
        returnUrl = result;
      });
      
      sub.unsubscribe();
      this.router.navigate(['/' + returnUrl]);
    })
  ), { dispatch: false });

  logout = createEffect(() => this.actions$.pipe(
    ofType(logout),
    switchMap(action => {
      localStorage.setItem('currentUser', null);
      localStorage.removeItem('currentUser');
      ga('set', 'userId', '');
      return of(new Logout());
    })));


    getGeneralSettings = createEffect(() => this.actions$.pipe(
      ofType(getGeneralSettings),
      switchMap(action => {
        return this.accountService.getGeneralSettings().pipe(
          map(generalSettings => getGeneralSettingsSuccess({ generalSettings })),
          catchError(err => {
            return of(errorHappened({ err }));
          }));
      }
      )));

  getFeatureSwitches = createEffect(() => this.actions$.pipe(
    ofType(getFeatureSwitches),
    switchMap(action => {
      return this.accountService.getFeatureSwitches().pipe(
        map(featureSwitches => getFeatureSwitchesSuccess({ featureSwitches })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    }
    )));
}
