import { createAction, props } from '@ngrx/store';
import { CriticalNewsPostCommentModel, CriticalNewsPostModel, CriticalNewsPostReactionModel, NewsFeedFilterModel } from 'src/app/models/criticalNewsFeedModel';
import { PagingResultsModel } from 'src/app/models/pagingResultsModel';

export const resetPosts = createAction('[Critical News Feed] Reset Posts');

export const setRefreshFilter = createAction('[Critical News Feed] Set Refresh Filter',
props<{filter: NewsFeedFilterModel}>());

export const startRefreshing = createAction('[Critical News Feed] Start Refreshing');
export const stopRefreshing = createAction('[Critical News Feed] Stop Refreshing');

export const setLastSyncDate = createAction('[Critical News Feed] Set Last Sync Date',
props<{syncDate: Date}>());

export const setScrollLoading = createAction('[Critical News Feed] Set Scroll Loading',
props<{loading: boolean}>());

export const loadPosts = createAction('[Critical News Feed] Load Posts',
props<{ filter: any }>());
export const loadPostsSuccess = createAction('[Critical News Feed] Load Posts Success',
props<{ posts: PagingResultsModel<CriticalNewsPostModel> }>());

export const loadPinnedPosts = createAction('[Critical News Feed] Load Pinned Posts');
export const loadPinnedPostsSuccess = createAction('[Critical News Feed] Load Pinned Posts Success',
props<{ posts: CriticalNewsPostModel[] }>());

export const loadUserPinnedPosts = createAction('[Critical News Feed] Load User Pinned Posts');
export const loadUserPinnedPostsSuccess = createAction('[Critical News Feed] Load User Pinned Posts Success',
props<{ posts: CriticalNewsPostModel[] }>());

export const filterFeed = createAction('[Critical News Feed] Filter Feed',
props<{ filter: any }>());
export const filterFeedSuccess = createAction('[Critical News Feed] Filter Feed Success',
props<{ posts: PagingResultsModel<CriticalNewsPostModel> }>());

export const refreshFeed = createAction('[Critical News Feed] Refresh Feed',
props<{ filter: any}>());
export const refreshFeedSuccess = createAction('[Critical News Feed] Refresh Feed Success',
props<{ posts: PagingResultsModel<CriticalNewsPostModel> }>());

export const unpinPost = createAction('[Critical News Feed] Unpin Post',
props<{id: number}>());
export const unpinPostSuccess = createAction('[Critical News Feed] Unpin Post Success',
props<{ posts: CriticalNewsPostModel[] }>());

export const pinPost = createAction('[Critical News Feed] Pin Post',
props<{id: number}>());
export const pinPostSuccess = createAction('[Critical News Feed] Pin Post Success',
props<{ posts: CriticalNewsPostModel[] }>());

export const pinUserPost = createAction('[Critical News Feed] Pin User Post',
props<{id: number}>());
export const pinUserPostSuccess = createAction('[Critical News Feed] Pin User Post Success',
props<{ posts: CriticalNewsPostModel[] }>());

export const unpinComment = createAction('[Critical News Feed] Unpin Comment',
props<{id: number}>());
export const unpinCommentSuccess = createAction('[Critical News Feed] Unpin Comment Success',
props<{ comment: CriticalNewsPostCommentModel }>());

export const unpinUserPost = createAction('[Critical News Feed] Unpin User Post',
props<{id: number}>());
export const unpinUserPostSuccess = createAction('[Critical News Feed] Unpin User Post Success',
props<{ posts: CriticalNewsPostModel[] }>());

export const archivePost = createAction('[Critical News Feed] Archive Post',
props<{id: number}>());
export const archivePostSuccess = createAction('[Critical News Feed] Archive Post Success',
props<{ post: CriticalNewsPostModel }>());

export const archiveComment = createAction('[Critical News Feed] Archive Comment',
props<{id: number}>());
export const archiveCommentSuccess = createAction('[Critical News Feed] Archive Comment Success',
props<{ comment: CriticalNewsPostCommentModel }>());

export const savePost = createAction('[Critical News Feed] Save Post',
props<{ post: CriticalNewsPostModel}>());
export const savePostSuccess = createAction('[Critical News Feed] Save Post Success',
props<{ post: CriticalNewsPostModel }>());

export const editPost = createAction('[Critical News Feed] Edit Post',
props<{ post: CriticalNewsPostModel}>());
export const editPostSuccess = createAction('[Critical News Feed] Edit Post Success',
props<{ post: CriticalNewsPostModel }>());

export const savePostWithFiles = createAction('[Critical News Feed] Save Post With Files',
props<{ post: CriticalNewsPostModel, files: File[] }>());

export const editPostWithFiles = createAction('[Critical News Feed] Edit Post With Files',
props<{ post: CriticalNewsPostModel, files: File[] }>());

export const saveReaction = createAction('[Critical News Feed] Save Reaction',
props<{ reaction: CriticalNewsPostReactionModel}>());
export const saveReactionSuccess = createAction('[Critical News Feed] Save Reaction Success',
props<{ reaction: CriticalNewsPostReactionModel }>());

export const deleteReaction = createAction('[Critical News Feed] Delete Reaction',
props<{ reaction: CriticalNewsPostReactionModel}>());
export const deleteReactionSuccess = createAction('[Critical News Feed] Delete Reaction Success',
props<{ reaction: CriticalNewsPostReactionModel }>());

export const saveComment = createAction('[Critical News Feed] Save Comment',
props<{ comment: CriticalNewsPostCommentModel}>());
export const saveCommentSuccess = createAction('[Critical News Feed] Save Comment Success',
props<{ comment: CriticalNewsPostCommentModel }>());

export const editComment = createAction('[Critical News Feed] Edit Comment',
props<{ comment: CriticalNewsPostCommentModel}>());
export const editCommentSuccess = createAction('[Critical News Feed] Edit Comment Success',
props<{ comment: CriticalNewsPostCommentModel }>());

export const pinComment = createAction('[Critical News Feed] Pin Comment',
props<{ id: number, postId: number}>());
export const pinCommentSuccess = createAction('[Critical News Feed] Pin Comment Success',
props<{ comment: CriticalNewsPostCommentModel }>());