import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PagingResultsModel } from '../../models/pagingResultsModel';
import { PaylocityTransmissionModel } from '../../models/paylocityTranmissionModel';
import { deepClone } from '../../helpers/utils';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaylocityService {

  constructor(private http: HttpClient) {
  }

  getPaylocityTransmissions(filter: any): Observable<PagingResultsModel<PaylocityTransmissionModel>> {
    if (!filter) {
      filter = {};
      filter.filters = {};
    }
    filter = deepClone(filter);

    return this.http.post<PagingResultsModel<PaylocityTransmissionModel>>(`${environment.apiServer.webApiURL}/Paylocity/PaylocityTransmissions`,
      filter)
  }

  dismissTransmission(transmission: PaylocityTransmissionModel): Observable<any> {
    return this.http.put<PaylocityTransmissionModel>(`${environment.apiServer.webApiURL}/Paylocity/DismissTransmission/${transmission.transmissionId}`, transmission);
  }
}
