import { SelfCareCategoryModel, SelfCarePlannerSectionItemNoteModel } from 'src/app/models/selfcareModels';
import { SelfCarePlannerSectionModel, SelfCareQuestionModel } from './../../models/selfcareModels';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable()
export class ManageSelfcareService {

  constructor(private http: HttpClient) {
  }
  getPlannerSections()
    : Observable<SelfCarePlannerSectionModel[]> {
    return this.http.get<SelfCarePlannerSectionModel[]>(`${environment.apiServer.webApiURL}/management/SelfCare/PlannerSections`);
  }

  getCategories()
    : Observable<SelfCareCategoryModel[]> {
    return this.http.get<SelfCareCategoryModel[]>(`${environment.apiServer.webApiURL}/management/SelfCare/Categories`);
  }

  getCategory(categoryID: number)
    : Observable<SelfCareCategoryModel> {

    return this.http.get<SelfCareCategoryModel>(`${environment.apiServer.webApiURL}/management/SelfCare/Category/${categoryID}`);
  }

  putSelfcareCategory(category: SelfCareCategoryModel)
    : Observable<SelfCareCategoryModel> {
    return this.http.put<SelfCareCategoryModel>(`${environment.apiServer.webApiURL}/management/SelfCare/Category`,
      category);
  }
  getPlannerSection(plannerSectionID: number)
    : Observable<SelfCarePlannerSectionModel> {

    return this.http.get<SelfCarePlannerSectionModel>(`${environment.apiServer.webApiURL}/management/SelfCare/PlannerSection/${plannerSectionID}`);
  }

  putSelfcarePlannerSection(plannerSection: SelfCarePlannerSectionModel)
    : Observable<SelfCarePlannerSectionModel> {
    return this.http.put<SelfCarePlannerSectionModel>(`${environment.apiServer.webApiURL}/management/SelfCare/PlannerSection`,
      plannerSection);
  }



}

