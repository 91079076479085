import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../../core/abstracts/baseComponent';
import { PagingResultsModel } from '../../../../../models/pagingResultsModel';
import { State } from '../../../../../core/store';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { ofType, Actions } from '@ngrx/effects';
import { EmployeeReviewTemplateModel } from '../../../../../models/employeeReviewTemplateModels';
import { loadTemplates, toggleTemplateArchived, toggleTemplateArchivedSuccess } from '../../../../../core/store/employee-review-template/employee-review-template.actions';
import { Router } from '@angular/router';
import { deepClone } from '../../../../../helpers/utils';


@Component({
  selector: 'app-list',
  templateUrl: './list.component.html'
})
export class ListComponent extends BaseComponent implements OnInit {
  templates: PagingResultsModel<EmployeeReviewTemplateModel>;
  loading: boolean = true;
  filter: any;

  yesNoAllOptions = [
    { label: 'All', value: null },
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  includeArchived?: boolean = false;

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private router: Router) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.employeeReviewTemplateState.templates).subscribe(templates => {
        this.templates = templates;
      }),
      this.store.select(s => s.employeeReviewTemplateState.loading).subscribe(loading => {
        this.loading = loading;
      }),
      this.actions$.pipe(
        ofType(toggleTemplateArchivedSuccess),
        tap(action => {
          this.reloadList();
        })
      ).subscribe(),
    );
  }

  editTemplate(template) {
    this.router.navigate([`/employee-review/template/${template.id}`]);
  }

  reloadTemplates(event) {
    this.filter = event;
    this.reloadList();
    
  }  

  reloadList() {
    this.setFilter();

    this.store.dispatch(loadTemplates({ filter: this.filter }));
  }

  setFilter() {
    this.filter = deepClone(this.filter);
    this.filter.filters.includeArchived = { Name: 'includeArchived', Value: { value: this.includeArchived } };
  }

  toggleArchivedStatus(template: EmployeeReviewTemplateModel) {
    if (template.id > 0) {
      this.store.dispatch(toggleTemplateArchived({ templateId: template.id }));
    }
  }

}
