import { GeneralSettingsModel } from './../../models/generalSettingsModel';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { UserModel } from '../../models/userModel';
import { Utils } from '../../helpers/utils';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from '../store';

@Injectable()
export class AccountService {


  constructor(private http: HttpClient,
    private router: Router,
    private store: Store<State>) {
  }


  /**
   * Authenticate with credentials and retrives login profile
   *
   */
  authenticate(userName: string, password: string): Observable<UserModel> {
    return this.http.post<UserModel>(
      `${environment.apiServer.webApiURL}/Account/authenticate`,
      Utils.getFormUrlEncoded({
        userName,
        password
      }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    );
  }

  /**
   * Authenticate with an auth token and retrives login profile
   */
  authenticateToken(authToken: string): Observable<UserModel> {

    const body = new HttpParams()
      .set('authToken', encodeURIComponent(authToken));

    return this.http.post<UserModel>(
      `${environment.apiServer.webApiURL}/Account/authenticatetoken`, body
    );
  }


  /**
   * Authenticate with an access code and retrives login profile
   */
  authenticateCode(accessCode: string): Observable<UserModel> {

    const body = new HttpParams()
      .set('accessCode', encodeURIComponent(accessCode));

    return this.http.post<UserModel>(
      `${environment.apiServer.webApiURL}/Account/authenticatecode`, body
    );
  }

  logNavigation(url: string, parameters: string): Observable<string> {

    if (url.startsWith('/'))
      url = window.location.protocol + '://' + window.location.hostname + url

    const context = localStorage.getItem('currentUser');

    return this.http.post<string>(`${environment.apiServer.webApiURL}/Account/lognavigation`, { Url: url, Parameters: parameters, Context: context });
  }

  getGeneralSettings(): Observable<GeneralSettingsModel> {
    return this.http.get<GeneralSettingsModel>(
      `${environment.apiServer.webApiURL}/Account/GeneralSettings`);
  }

  getFeatureSwitches(): Observable<number[]> {
    return this.http.get<number[]>(
      `${environment.apiServer.webApiURL}/Account/FeatureSwitches`);
  }

}
