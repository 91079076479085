<div class="card mt-2 mb-1">
  <div class="card-header">

    <div class="row">
      <h3>#{{kraIndex + 1}} KRA</h3>
      <button type="button" [disabled]="loading" (click)="addNewKpi()" class="btn btn-success ml-2 mr-2">Add KPI</button>
      <button type="button" [disabled]="loading" (click)="addNewMeasurable()" class="btn btn-success mr-2">Add Measurable</button>
      <button class="btn btn-danger" [disabled]="loading" (click)="remove()">Remove</button>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-2 d-flex">
        <b>Value:</b>
      </div>
      <div class="col-10">
        <p-dropdown styleClass="form-control w-100 mb-2" [showClear]="true" [options]="valueColors" optionLabel="valueName" optionValue="id" [(ngModel)]="kra.valueColor" (ngModelChange)="changedColor($event,kra)" [ngModelOptions]="{standalone: true}" placeholder="Select a value...">
          <ng-template pTemplate="selectedItem">
            <div class="valuecolor-item d-flex" *ngIf="kra.valueColor">
              <div class="valuecolor-colorsquare mr-2" [style.backgroundColor]="kra.valueColor.colorRGB"></div>
              <div>{{kra.valueColor.valueName}}</div>
            </div>
          </ng-template>
          <ng-template let-valueColorOption pTemplate="item">
            <div class="valuecolor-item d-flex">
              <div class="valuecolor-colorsquare mr-2" [style.backgroundColor]="valueColorOption.value.colorRGB"></div>
              <div>{{valueColorOption.value.valueName}}</div>
            </div>
          </ng-template>

        </p-dropdown>

      </div>
    </div>
    <div class="row">
      <div class="col-2 d-flex">
        <b>Score Options Set:</b>
      </div>
      <div class="col-10">
        <p-dropdown styleClass="form-control w-100 mb-2" [showClear]="true" [options]="scoreOptionsSet" optionLabel="scoreSet" [(ngModel)]="kra.optionSet" [ngModelOptions]="{standalone: true}" placeholder="Select a value...">
        </p-dropdown>

      </div>
    </div>
    <div class="row pt-2">
      <div class="col-2 d-flex">
        <b>Description:</b>
      </div>
      <div class="col-10">
        <textarea class="form-control w-100" [(ngModel)]="kra.description" [ngModelOptions]="{standalone: true}"></textarea>
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-2 d-flex">
        <b>How/Core Play:</b>
      </div>
      <div class="col-10">
        <textarea class="form-control w-100" [(ngModel)]="kra.corePlay" [ngModelOptions]="{standalone: true}"></textarea>
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-2 d-flex">
        <b>Measurement:</b>
      </div>
      <div class="col-10">
        <textarea class="form-control w-100" [(ngModel)]="kra.measurement" [ngModelOptions]="{standalone: true}"></textarea>
      </div>
    </div>
    <div *ngFor="let kpi of kra.kpis; let i=index">
      <app-kpi-template-component [kpi]="kpi" [kpiIndex]="i"></app-kpi-template-component>
      <hr class="p-2" *ngIf="i + 1 < kra.kpis.length" />
    </div>

    <div class="w-100" *ngFor="let measureable of kra.measureables; let i=index">

      <app-kpi-measureable-template-component [measureable]="measureable" class="w-100"></app-kpi-measureable-template-component>

    </div>
  </div>
</div>
