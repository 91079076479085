import { Injectable } from "@angular/core";
import { EmployeeService } from '../../services/employee.service';
import { State } from '..';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  exportEmployeeCensusListToExcel, exportEmployeeCensusListToExcelFailure,
  exportEmployeeCensusListToExcelSuccess, exportEmployeeFullTimeStatusListToExcel, exportEmployeeFullTimeStatusListToExcelFailure, exportEmployeeFullTimeStatusListToExcelSuccess, exportEmployeeTerminationListToExcel,
  exportEmployeeTerminationListToExcelFailure, exportEmployeeTerminationListToExcelSuccess,
  getEmployeeCensusList, getEmployeeCensusListSuccess,
  getEmployeeTerminationList, getEmployeeTerminationListSuccess, getFullTimeStatusCheckList,
  getFullTimeStatusCheckListSuccess, getFullTimeStatusQueue, getFullTimeStatusQueueSuccess,
  loadEmployeeUsers, loadEmployeeUsersSuccess, loadTerminationReasons, loadTerminationReasonsSuccess, loadViewableCampuses, loadViewableCampusesSuccess,
  markAsExempt, markAsExemptSuccess, rehireEmployee, rehireEmployeeFailure, rehireEmployeeSuccess, saveTerminationReason, saveTerminationReasonSuccess, switchStatus, switchStatusSuccess, verifyCheck, verifyCheckSuccess,
  willNotRehireEmployee, willNotRehireEmployeeSuccess, willNotRehireEmployeeFailure, loadSeparationSubReasons, loadSeparationSubReasonsSuccess
} from './employee.action';
import { map, switchMap, catchError } from 'rxjs/operators';
import { errorHappened } from '../profile/profile.actions';
import { of } from 'rxjs';

@Injectable()
export class EmployeeEffects {
  constructor(private actions$: Actions,
    private employeeService: EmployeeService,
    private store: Store<State>) { }

  loadViewableCampuses = createEffect(() => this.actions$.pipe(
    ofType(loadViewableCampuses),
    switchMap(action => this.employeeService.getCampusList().pipe(
      map(campuses => loadViewableCampusesSuccess({ campuses })),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))));

  loadEmployeeUsers = createEffect(() => this.actions$.pipe(
    ofType(loadEmployeeUsers),
    switchMap(action => this.employeeService.getEmployeeUserList(action.prefixText, action.programId, action.payrollClassificationId, action.showTerminated, action.showInactive).pipe(
      map(users => loadEmployeeUsersSuccess({ users })),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))));

  getFullTimeStatusCheckList = createEffect(() => this.actions$.pipe(
    ofType(getFullTimeStatusCheckList),
    switchMap(action => {
      return this.employeeService.getFullTimeStatusCheckList(action.filter, action.userId, action.programId, action.startDate, action.endDate, action.minHours, action.maxHours, action.fullTimeStatus, action.includeInactive).pipe(
        map(statusList => getFullTimeStatusCheckListSuccess({ statusList })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  getFullTimeStatusQueue = createEffect(() => this.actions$.pipe(
    ofType(getFullTimeStatusQueue),
    switchMap(action => {
      return this.employeeService.getFullTimeStatusQueue(action.filter, action.userId, action.programId, action.startDate, action.endDate, action.minHours, action.maxHours, action.fullTimeStatus, action.includeInactive).pipe(
        map(queue => getFullTimeStatusQueueSuccess({ queue })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  verifyCheck = createEffect(() => this.actions$.pipe(
    ofType(verifyCheck),
    switchMap(action =>
      this.employeeService.verifyCheck(action.check).pipe(
        map(check => verifyCheckSuccess()),
        catchError(err => {
          return of(errorHappened({ err }));
        }))
    )));

  switchStatus = createEffect(() => this.actions$.pipe(
    ofType(switchStatus),
    switchMap(action =>
      this.employeeService.switchStatus(action.check).pipe(
        map(check => switchStatusSuccess()),
        catchError(err => {
          return of(errorHappened({ err }));
        }))
    )));

  markAsExempt = createEffect(() => this.actions$.pipe(
    ofType(markAsExempt),
    switchMap(action =>
      this.employeeService.markAsExempt(action.check).pipe(
        map(check => markAsExemptSuccess()),
        catchError(err => {
          return of(errorHappened({ err }));
        }))
    )));

  getEmployeeTerminationList = createEffect(() => this.actions$.pipe(
    ofType(getEmployeeTerminationList),
    switchMap(action => {
      return this.employeeService.getEmployeeTerminationList(action.filter).pipe(
        map(terminationList => getEmployeeTerminationListSuccess({ terminationList })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  getEmployeeCensusList = createEffect(() => this.actions$.pipe(
    ofType(getEmployeeCensusList),
    switchMap(action => {
      return this.employeeService.getEmployeeCensusList(action.filter).pipe(
        map(censusList => getEmployeeCensusListSuccess({ censusList })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  exportEmployeeTerminationListToExcel = createEffect(() => this.actions$.pipe(
    ofType(exportEmployeeTerminationListToExcel),
    switchMap(action => {
      return this.employeeService.exportEmployeeTerminationListToExcel(action.filter).pipe(
        map(doc => exportEmployeeTerminationListToExcelSuccess({ doc })),
        catchError(err => {
          this.store.dispatch(exportEmployeeTerminationListToExcelFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  exportEmployeeCensusListToExcel = createEffect(() => this.actions$.pipe(
    ofType(exportEmployeeCensusListToExcel),
    switchMap(action => {
      return this.employeeService.exportEmployeeCensusListToExcel(action.filter).pipe(
        map(doc => exportEmployeeCensusListToExcelSuccess({ doc })),
        catchError(err => {
          this.store.dispatch(exportEmployeeCensusListToExcelFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  exportEmployeeFullTimeStatusListToExcel = createEffect(() => this.actions$.pipe(
    ofType(exportEmployeeFullTimeStatusListToExcel),
    switchMap(action => {
      return this.employeeService.exportEmployeeFullTimeStatusListToExcel(action.filter).pipe(
        map(doc => exportEmployeeFullTimeStatusListToExcelSuccess({ doc })),
        catchError(err => {
          this.store.dispatch(exportEmployeeFullTimeStatusListToExcelFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  willNotRehireEmployee = createEffect(() => this.actions$.pipe(
    ofType(willNotRehireEmployee),
    switchMap(action => {
      return this.employeeService.willNotRehireEmployee(action.employee).pipe(
        map(employeeUser => willNotRehireEmployeeSuccess({ employeeUser })),
        catchError(err => {
          this.store.dispatch(willNotRehireEmployeeFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  rehireEmployee = createEffect(() => this.actions$.pipe(
    ofType(rehireEmployee),
    switchMap(action => {
      return this.employeeService.rehireEmployee(action.employee).pipe(
        map(employeeUser => rehireEmployeeSuccess({ employeeUser })),
        catchError(err => {
          this.store.dispatch(rehireEmployeeFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  loadTerminationReasons = createEffect(() => this.actions$.pipe(
    ofType(loadTerminationReasons),
    switchMap(action => this.employeeService.getTerminationReasons().pipe(
      map(reasons => loadTerminationReasonsSuccess({ reasons })),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))));

  loadSeparationSubReasons = createEffect(() => this.actions$.pipe(
    ofType(loadSeparationSubReasons),
    switchMap(action => this.employeeService.getSeparationSubReasons().pipe(
      map(reasons => loadSeparationSubReasonsSuccess({ reasons })),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))));


  saveTerminationReason = createEffect(() => this.actions$.pipe(
    ofType(saveTerminationReason),
    switchMap(action => this.employeeService.saveTerminationReason(action.employee).pipe(
      map(employee => saveTerminationReasonSuccess()),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))));
}
