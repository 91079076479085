<div class="card mb-2">
    <div class="card-body">
        <i *ngIf="!ticketTypeForm || loadingForm" class="material-icons rotate text-muted">loop</i>
        <div *ngIf="ticketTypeForm">

            <button class="btn btn-secondary float-right pointer" routerLink="/ticket-types/list">Back</button>
            <h2>{{ isEditMode ? 'Edit' : 'Create New' }} Ticket Type</h2>

            <div class="row">
                <div class="col-12">
                    <form [formGroup]="ticketTypeForm" *ngIf="ticketTypeForm">

                        <!-- Ticket Type Name -->
                        <div class="row">
                            <div class="col-12 col-lg-6 ">

                                <div class="row">
                                    <div class="col-4 d-flex align-items-center">
                                        <b>Ticket Type Name</b>
                                    </div>
                                    <div class="col-8">
                                        <input id="nameInput" type="text" class="form-control mt-2"
                                            formControlName="name" />
                                        <app-validation-message [form]="ticketTypeForm" [path]="'name'" [name]="'name'"
                                            [label]="'Ticket Type Name'"> </app-validation-message>
                                    </div>

                                </div>
                                
                                <div class="row">
                                    <div class="col-4 d-flex align-items-center">
                                        <b>Description <small>(shown in the widget)</small></b>
                                    </div>
                                    <div class="col-8">
                                        <input id="descriptionInput" type="text" class="form-control mt-2"
                                            formControlName="description" />
                                        <app-validation-message [form]="ticketTypeForm" [path]="'description'" [name]="'description'"
                                            [label]="'Description'"> </app-validation-message>
                                    </div>

                                </div>
                                
                                <div class="row">
                                    <div class="col-4 d-flex align-items-center">
                                        <b>Color <small>(shown in the widget)</small></b>
                                    </div>
                                    <div class="col-8">
                                        <p-dropdown styleClass="mt-2" [options]="colorOptions"
                                            formControlName="color">
                                            <ng-template pTemplate="selectedItem" let-selectedOption>
                                                <div [class]="'flex align-items-center gap-2 '+selectedOption.value">
                                                    
                                                    <div [class]="'color-square '+selectedOption.value">
                                                    </div>
                                                    <div class="display-inline">{{ selectedOption.label }}</div>
                                                </div>
                                            </ng-template>
                                            <ng-template let-color pTemplate="item">
                                                <div [class]="'flex align-items-center gap-2 '+color.value">
                                                    
                                                    <div [class]="'color-square '+color.value">
                                                    </div>
                                                    <div class="display-inline">{{ color.label }}</div>
                                                </div>
                                            </ng-template>
                                        </p-dropdown>
                                        <app-validation-message [form]="ticketTypeForm" [path]="'color'" [name]="'color'"
                                            [label]="'Color'"> </app-validation-message>
                                    </div>

                                </div>
                            </div>

                            <div class="w-100"></div>

                            <!-- Custom Fields Table -->
                            <div class="col-12 col-lg-8 mt-4">
                                <h4>Custom Fields</h4>
                                <button type="button" class="btn btn-primary mb-3" (click)="addCustomField()">Add Custom
                                    Field</button>

                                <p-table [value]="customFields.controls" *ngIf="customFields.length > 0"
                                    class="p-datatable-sm">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th>Field Name</th>
                                            <th style="width: 250px;">Field Type</th>
                                            <th>Other settings</th>
                                            <th>Actions</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-customField let-i="rowIndex">
                                        <tr [formGroup]="customField">
                                            <td>
                                                <input type="text" class="form-control" formControlName="name" />
                                            </td>
                                            <td>
                                                <p-dropdown styleClass="mt-2" [options]="fieldTypesOptions"
                                                    formControlName="fieldTypeId"></p-dropdown>
                                            </td>
                                            <td>
                                                <ng-container *ngIf="customField.get('fieldTypeId').value == 4 ">
                                                    <span class="font-weight-bold">Field Options</span>
                                                    <textarea class="form-control"
                                                        formControlName="fieldOptions"></textarea>
                                                    <small>Add each option in one line</small>
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="customField.get('fieldTypeId').value == 4 || customField.get('fieldTypeId').value == 5 ">
                                                    <div>
                                                        <span class="font-weight-bold mr-2">Allow other</span>
                                                        <p-checkbox binary="true"
                                                            formControlName="allowOther"></p-checkbox>
                                                    </div>
                                                </ng-container>
                                            </td>
                                            <td>
                                                <button type="button" class="btn btn-danger"
                                                    (click)="removeCustomField(i)">Remove</button>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                                <div *ngIf="customFields.length === 0">No custom fields assigned. Add custom fields
                                    using the button above.</div>
                            </div>

                            <!-- Ticket Statuses Table -->
                            <div class="col-12 col-lg-4  mt-4">
                                <h4>Ticket Statuses</h4>
                                <button type="button" class="btn btn-primary mb-3" (click)="addStatus()">Add
                                    Status</button>

                                <p-table [value]="ticketStatuses.controls" *ngIf="ticketStatuses.length > 0"
                                    class="p-datatable-sm">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th>Status Name</th>
                                            <th>Is Final</th>
                                            <th>Is Default</th>
                                            <th>Actions</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-status let-i="rowIndex">
                                        <tr [formGroup]="status">
                                            <td>
                                                <input type="text" class="form-control" formControlName="name" />
                                            </td>
                                            <td>
                                                <p-checkbox binary="true" formControlName="isFinal"></p-checkbox>
                                            </td>
                                            <td>
                                                <p-checkbox binary="true" formControlName="isDefault"></p-checkbox>
                                            </td>
                                            <td>
                                                <button type="button" class="btn btn-danger"
                                                    (click)="removeStatus(i)">Remove</button>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                                <div *ngIf="ticketStatuses.length === 0">No ticket statuses assigned. Add statuses using
                                    the button above.</div>
                            </div>

                            <div class="col-12 col-lg-12 mt-4">
                                <h4 class="mt-3"> Users</h4>
                                <button type="button" class="btn btn-primary mb-3" (click)="addUser()">Add User</button>

                                <p-table [value]="users.controls" *ngIf="users.length > 0" class="p-datatable-sm">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th style="width: 250px;">User</th>
                                            <th>Can Be Assigned</th>
                                            <th>Can Create Tickets</th>
                                            <th>Can Manage Tickets</th>
                                            <th>Can Change Created By</th>
                                            <th>Actions</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-user let-i="rowIndex">
                                        <tr [formGroup]="user">
                                            <td>
                                                <p-dropdown styleClass="form-control mt-2" [options]="usersList"
                                                    formControlName="userID" placeholder="Select user..."></p-dropdown>

                                            </td>
                                            <td>
                                                <p-checkbox binary="true" formControlName="canBeAssigned"></p-checkbox>
                                            </td>
                                            <td>
                                                <p-checkbox binary="true"
                                                    formControlName="canCreateTickets"></p-checkbox>
                                            </td>
                                            <td>
                                                <p-checkbox binary="true"
                                                    formControlName="canManageTickets"></p-checkbox>
                                            </td>
                                            <td>
                                                <p-checkbox binary="true"
                                                    formControlName="canChangeCreatedBy"></p-checkbox>
                                            </td>
                                            <td>
                                                <button type="button" class="btn btn-danger"
                                                    (click)="removeUser(i)">Remove</button>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>

                                <!-- No Users Message -->
                                <div *ngIf="users.length === 0">No users assigned. Add users using the button above.
                                </div>
                            </div>
                        </div>
                    </form>

                    <button class="btn btn-primary mt-3" id="saveButton" [class.disabled]="ticketTypeForm.invalid"
                        (click)="onSubmit()">Save</button>
                </div>
            </div>
        </div>
    </div>
</div>