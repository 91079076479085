import { createAction, props } from '@ngrx/store';
import { PayrollItemModel, PayrollApprovedTotalsModel, PayableItemModel } from 'src/app/models/payableItem';
import { PayPeriodModel } from 'src/app/models/payPeriodModel';
import { PayrollClassificationModel } from 'src/app/models/payrollClassificationModel';
import { CampusModel } from 'src/app/models/campusModel';
import { UserModel } from 'src/app/models/userModel';
import { FormError } from '../../../models/utilModels';

export const loadPayrollApprovalApprovedTime = createAction('[Payroll Approval] Load Approved Time',
  props<{ periodID: number, userID: number }>());
export const loadPayrollApprovalApprovedTimeSuccess = createAction('[Payroll Approval] Load Approved Time Success',
  props<{ payrollItems: PayrollItemModel[] }>());

export const loadPayrollApprovalInvalidTime = createAction('[Payroll Approval] Load Invalid Time',
  props<{ periodID: number, userID: number }>());
export const loadPayrollApprovalInvalidTimeSuccess = createAction('[Payroll Approval] Load Invalid Time Success',
  props<{ payrollItems: PayrollItemModel[] }>());

export const loadPayrollApprovalAdjustmentTime = createAction('[Payroll Approval] Load Adjustment Time',
  props<{ periodID: number, userID: number, showAll: boolean }>());
export const loadPayrollApprovalAdjustmentTimeSuccess =
  createAction('[Payroll Approval] Load Adjustment Time Success',
    props<{ payrollItems: PayrollItemModel[] }>());

export const loadPayrollApprovalPendingTime = createAction('[Payroll Approval] Load Pending Time',
  props<{ periodID: number, userID: number, showAll: boolean }>());
export const loadPayrollApprovalPendingTimeSuccess = createAction('[Payroll Approval] Load Pending Time Success',
  props<{ payrollItems: PayrollItemModel[] }>());


export const loadPayrollApprovalApprovedTotals = createAction('[Payroll Approval] Load Approved Totals',
  props<{ periodID: number, userID: number }>());
export const loadPayrollApprovalApprovedTotalsSuccess =
  createAction('[Payroll Approval] Load Approved TotalsSuccess',
    props<{ approvedTotals: PayrollApprovedTotalsModel }>());


export const loadPayrollApprovalPeriods = createAction('[Payroll Approval] Load Pay Periods');
export const loadPayrollApprovalPeriodsSuccess = createAction('[Payroll Approval] Load Pay Periods Success',
  props<{ periods: PayPeriodModel[] }>());

export const changePayrollApprovalPeriod = createAction('[Payroll Approval] Change Pay Period',
  props<{ periodID: number, userID: number, showAll: boolean }>());

export const changePayrollApprovalUserFilter = createAction('[Payroll Approval] Change User filters',
  props<{ campusID: number, payrollClassificationID: number }>());
  export const changePayrollApprovalUserFilterSuccess = createAction('[Payroll Approval]  Change User filters Success',
  props<{ usersList: UserModel[] }>());


export const approvePayrollItems = createAction('[Payroll Approval] Approve',
  props<{ payrollItems: PayrollItemModel[], userID: number, payPeriodID: number, approvalType: string }>());
export const approvePayrollItemsSuccess = createAction('[Payroll Approval] Approve Success',
  props<{ payrollItems: PayrollItemModel[] }>());
export const approvePayrollItemsFail = createAction('[Payroll Approval] Approve Fail',
  props<{ err: any }>());


export const rejectPayrollItems = createAction('[Payroll Approval] Reject',
  props<{ payrollItems: PayrollItemModel[], userID: number, payPeriodID: number }>());
export const rejectPayrollItemsSuccess = createAction('[Payroll Approval] Reject Success',
  props<{ payrollItems: PayrollItemModel[] }>());


export const savePayrollItems = createAction('[Payroll Approval] Save',
  props<{ payrollItems: PayrollItemModel[], userID: number, payPeriodID: number }>());
export const savePayrollItemsSuccess = createAction('[Payroll Approval] Save Success',
  props<{ payrollItems: PayrollItemModel[] }>());

export const showEditPayableItem = createAction('[Payroll Approval] Show Edit Payable Item Modal',
  props<{ payableItem: PayableItemModel }>());
export const closeEditPayableItem = createAction('[Payroll Approval] Close Edit Payable Item Modal');

export const updatePayableItemForm = createAction('[Payroll Approval] Update Payable Item Form',
  props<{ formValues: any, formErrors: FormError[] }>());

export const savePayableItem = createAction('[Payroll Approval] Save Payable Item',
  props<{ payableItem: PayableItemModel}>());
export const savePayableItemSuccess = createAction('[Payroll Approval] Save Payable Item Success');
export const savePayableItemFail = createAction('[Payroll Approval] Save Payable Item Fail',
  props<{ err: any }>());

export const unapprovePayrollItems = createAction('[Payroll Approval] Unapprove',
  props<{ payrollItems: PayrollItemModel[], userID: number, payPeriodID: number }>());
export const unapprovePayrollItemsSuccess = createAction('[Payroll Approval] Unapprove Success',
  props<{ payrollItems: PayrollItemModel[] }>());

export const generatePayrollItems = createAction('[Payroll Approval] Generate Payroll Items',
  props<{ userID: number, payPeriodID: number }>());
export const generatePayrollItemsSuccess = createAction('[Payroll Approval] Generate Payroll Items Success');
export const generatePayrollItemsFail = createAction('[Payroll Approval] Generate Payroll Items Fail',
  props<{ err: any }>());

export const lockPayrollPeriod = createAction('[Payroll Approval] Lock Period',
  props<{ userID: number, payPeriodID: number }>());
export const lockPayrollPeriodSuccess = createAction('[Payroll Approval] Lock Period Success');

export const unlockPayrollPeriod = createAction('[Payroll Approval] Unlock Period',
  props<{ userID: number, payPeriodID: number }>());
export const unlockPayrollPeriodSuccess = createAction('[Payroll Approval] Unlock Period Success');


export const getIsLockedPeriod = createAction('[Payroll Approval] Get Is Locked',
  props<{ userID: number, periodID: number }>());
export const getIsLockedPeriodSuccess = createAction('[Payroll Approval] Get Is Locked Success',
  props<{ isLocked: boolean }>());

export const loadPayrollClassifications = createAction('[Payroll] Load Payroll Classifications');
export const loadPayrollClassificationsSuccess = createAction('[Payroll] Load Payroll Classifications Success'
  , props<{ payrollClassifications: PayrollClassificationModel[] }>());


export const loadCampuses = createAction('[Payroll] Load Campuses');
export const loadCampusesSuccess = createAction('[Payroll] Load Campuses Success', props<{ campuses: CampusModel[] }>());


export const loadPayrollCampuses = createAction('[Payroll] Load Payroll Campuses');
export const loadPayrollCampusesSuccess = createAction('[Payroll] Load Payroll Campuses Success', props<{ campuses: CampusModel[] }>());

export const changeClientServiceNoteToManualEntry = createAction('[Payroll Approval] Change Client Service Note To Manual Entry',
  props<{ payableItem: PayableItemModel}>());
export const changeClientServiceNoteToManualEntrySuccess = createAction('[Payroll Approval] Change Client Service Note To Manual Entry Success');
