<form class="card mt-2"  *ngIf="canEdit">
  <div class="card-body">
    <h4 class="mb-3">Assign Equipment</h4>
    <div class="row">
      <div class="col-12 col-xl-6">
        <div class="row" >
          <div class="col-4 d-flex align-items-center">
            <h5>Assignment Type</h5>
          </div>
          <div class="col-8">
            <p-dropdown styleClass="form-control mt-2" [disabled]="isEditingAssignment" [options]="assignmentTypes" [resetFilterOnHide]="true" [(ngModel)]="assignmentType" [ngModelOptions]="{standalone: true}" placeholder="Select Type..."></p-dropdown>
            <small  *ngIf="showErrors && assignmentType == 0" class="text-danger">Select an assignment type</small>
          </div>
        </div>
        <div class="row" *ngIf="assignmentType == 1">
          <div class="col-4 d-flex align-items-center">
            <h5>User</h5>
          </div>
          <div class="col-8">
            <p-dropdown styleClass="form-control mt-2" [disabled]="isEditingAssignment" [options]="usersList" [filter]="true" [resetFilterOnHide]="true" [(ngModel)]="userID" (ngModelChange)="changedUser()" [ngModelOptions]="{standalone: true}" placeholder="Select user..."></p-dropdown>
            <div style="font-size:.75rem;" class="mt-2">
              <label for="includeDerminated">Include Separated Employees: </label>
              <p-checkbox id="includeTerminated" name="includeTerminated" styleClass="form-check-input ml-4" [(ngModel)]="includeTerminated" (onChange)="includeTerminatedChange($event)"></p-checkbox>
            </div>
            <small  *ngIf="showErrors && !this.userID" class="text-danger">Select a user to assign the equipment</small>
          </div>
        </div>        
        <div class="row" *ngIf="assignmentType == 2">
          <div class="col-4 d-flex align-items-center">
            <h5>Campus</h5>
          </div>
          <div class="col-8">
            <p-dropdown styleClass="form-control mt-2" [disabled]="isEditingAssignment" [options]="programList" [filter]="true" [resetFilterOnHide]="true" [(ngModel)]="assignedProgramID" (ngModelChange)="changedAssignedProgramID()" [ngModelOptions]="{standalone: true}" placeholder="Select Campus..."></p-dropdown>
            <small  *ngIf="showErrors && !this.assignedProgramID" class="text-danger">Select a user to assign the equipment</small>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 ">

        <h5 class="mt-3">Equipment</h5>
        <p-table class="mt-2 small-Text"  [value]="assigningEquipments" [responsive]="true" >
            <ng-template pTemplate="header">
                <tr>
                    <th width="110px"></th>
                    <th width="200px" [pSortableColumn]="'dateAssigned'">Date Assigned <p-sortIcon [field]="'dateAssigned'"></p-sortIcon></th>
                    <th width="200px" [pSortableColumn]="'dateAssigned'">Return Date <p-sortIcon [field]="'returnDate'"></p-sortIcon></th>
                    <th width="200px" [pSortableColumn]="'programId'">Campus <p-sortIcon [field]="'programId'"></p-sortIcon></th>
                    <th width="90px" [pSortableColumn]="'type'">Type <p-sortIcon [field]="'type'"></p-sortIcon></th>
                    <th [pSortableColumn]="'serialNumber'">Serial <p-sortIcon [field]="'serialNumber'"></p-sortIcon></th>
                    <th [pSortableColumn]="'brand-model'">Brand - Model <p-sortIcon [field]="'brand-model'"></p-sortIcon></th>
                    <th [pSortableColumn]="'assetTag'">{{generalSettings?.businessName}} # <p-sortIcon [field]="'assetTag'"></p-sortIcon></th>
                    <th [pSortableColumn]="'serviceTag'">Service Tag <p-sortIcon [field]="'serviceTag'"></p-sortIcon></th>
                    <th [pSortableColumn]="'expressServiceCode'">Express Service Code <p-sortIcon [field]="'expressServiceCode'"></p-sortIcon></th>

                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-equipment>
                <tr >
                    <td>
                      <button class="btn btn-secondary mr-2" [disabled]="isEditingAssignment"  (click)="removeEquipment(equipment)">Remove</button>
                    </td>
                    <td>
                      <span class="ui-column-title">Date Assigned</span>
                      <input type="date" class="form-control mt-2" [(ngModel)]="equipment.dateAssignedString" [ngModelOptions]="{standalone: true}" />
                    </td>
                    <td>
                      <span class="ui-column-title">Return Date</span>
                      <div *ngIf="equipment.thereIsReturnDate != false && equipment.thereIsReturnDate != null" class="returndatebuttonwrap">
                        <input type="date" class="form-control mt-2" [min]="currentDate" [(ngModel)]="equipment.returnDateString" (blur)="setReturnDate(equipment)" [ngModelOptions]="{standalone: true}" />
                        <button class="returnDateButtonCancel" (click)="removeReturnDate(equipment)">x</button>
                      </div>
                      <div class="returndatebuttonwrap" *ngIf="equipment.thereIsReturnDate != true">
                        <button class="returnDateButton" (click)="AddReturnDate(equipment)">+</button>
                      </div>
                    </td>
                    <td>
                      <span class="ui-column-title">Campus</span>
                      <p-dropdown styleClass="form-control mt-2 min-width-important" [options]="programList" [(ngModel)]="equipment.programID" [ngModelOptions]="{standalone: true}"></p-dropdown>
                    </td>
                    <td>
                      <span class="ui-column-title">Type</span>
                      {{equipment.equipment.type}}</td>

                      <td>
                        <span class="ui-column-title">Serial</span>
                      {{equipment.equipment.serialNumber }}</td>

                      <td>
                        <span class="ui-column-title">Brand - Model</span>
                      {{equipment.equipment.brand }} - {{equipment.equipment.model }}</td>

                      <td>
                        <span class="ui-column-title">{{generalSettings?.businessName}} #</span>
                      {{equipment.equipment.assetTag }}</td>
                      <td>
                        <span class="ui-column-title">Service Tag</span>
                      {{equipment.equipment.serviceTag }}</td>
                      <td>
                        <span class="ui-column-title">Express Service Code</span>
                      {{equipment.equipment.expressServiceCode }}</td>
                </tr>
            </ng-template>
        </p-table>
        <div class="row mb-4">

          <div class="col-12">
            <small *ngIf="showErrors && this.assigningEquipments.length < 1" class="text-danger">Add at least one equipment to assign</small>
          </div>

          <div class="col-11 d-flex align-items-center">
            <p-autoComplete [disabled]="isEditingAssignment" class="w-100" styleClass="w-100 d-flex mt-2" [dropdown]="true" inputStyleClass="form-control" [(ngModel)]="selectedEquipment" [ngModelOptions]="{standalone: true}" [suggestions]="equipmentSearchResult" field="label"
                            (completeMethod)="searchEquipment($event)" placeholder="Search by serial..."></p-autoComplete>
          </div>
          <div class="col-1 d-flex justify-content-start">
            <div *ngIf="isAlreadyAssigned(selectedEquipment)">
              <button [disabled]="isEditingAssignment" (click)="addEquipment(selectedEquipment)" class="btn btn-secondary mt-2">Add</button>
            </div>
          </div>
          <div *ngIf="selectedEquipmentID != null && !isAlreadyAssigned(selectedEquipment)">
            <p style="color:red;margin-left:20px;">Equipment is queued to be assigned above</p>
          </div>
          <div *ngIf="selectedEquipmentID != null && statusDestroyed(selectedEquipment)">
            <p style="color:red;margin-left:20px;">Equipment is Destroyed</p>
          </div>
          <div class="col-12 d-flex justify-content-end">
            <button (click)="openCreateEquipment()" class="btn btn-secondary mt-2">Create New Equipment</button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 ">

    <h5 class="mt-3">Acessories</h5>
    <p-table class="mt-2"  [value]="assigningAcessories" [responsive]="true" >
        <ng-template pTemplate="header">
            <tr>
                <th width="250px"></th>
                <th [pSortableColumn]="'equipmentAssignedID'">Equipment <p-sortIcon [field]="'equipmentAssignedID'"></p-sortIcon></th>
                <th [pSortableColumn]="'name'">Acessory Name <p-sortIcon [field]="'name'"></p-sortIcon></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-acessory>
            <tr >
                <td>
                  <button class="btn btn-secondary mr-2" [disabled]="isEditingAssignment" (click)="removeAcessory(acessory)">Remove</button>
                <td>
                    <span class="ui-column-title">Equipment</span>
                    {{acessory.equipmentLabel}}</td>

                  <td>
                    <span class="ui-column-title">Acessory Name</span>
                  {{acessory.name}}</td>
            </tr>
        </ng-template>
    </p-table>
    <div class="row mb-4" >
        <div class="col-11 d-flex align-items-center">
          <span class="mr-2">Equipment:</span><p-dropdown styleClass="form-control mt-2 " [options]="equipmentsList" [(ngModel)]="acessoryEquipmentID" [ngModelOptions]="{standalone: true}" placeholder="Select equipment..."></p-dropdown>
          <span  class="mr-2 ml-2">Acessory Name: </span><input type="text" class="form-control mt-2" [(ngModel)]="acessoryName" [ngModelOptions]="{standalone: true}" placeholder="Acessory Name"/>

      </div>
      <div class="col-1 d-flex align-items-center">
        <button [disabled]="isEditingAssignment" (click)="addAcessory(acessoryName,acessoryEquipmentID)" class="btn btn-secondary mt-2">+</button>
      </div>
    </div>
      </div>
    </div>

    <div class="mt-4 float-right">
      <i *ngIf="savingAssignEquipments" class="material-icons rotate text-muted">loop</i>
      <button *ngIf="!savingAssignEquipments" class="btn btn-primary"  (click)="save()">{{assignButtonText}}</button>
    </div>


<p-dialog header="Create New Equipment" [modal]="true" appendTo="body" [style]="{width: '70vw'}" [(visible)]="displayCreateEquipment">
  <app-create-equipment ></app-create-equipment>
</p-dialog>

  </div>
</form>
