<form class="card mt-2">
  <div class="card-body">
    <div class="float-right" *ngIf="canViewPto">
      <button type="button" routerLink="/timeoff/pto/manage" class="btn btn-secondary" style="margin-right: 5px;">Manage PTO</button>
      <button type="button" routerLink="/timeoff/ptoSummary" class="btn btn-secondary">PTO Summary</button>
    </div>
    <h2 class="mb-3">Manage Time Off</h2>
    <div class="mb-3">

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Campus:</h5>
        </div>
        <div class="col-10">
          <i *ngIf="!campusList" class="material-icons rotate text-muted">loop</i>
          <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="campusList" [(ngModel)]="campusId" (ngModelChange)="reloadFilter(this.filter)" [ngModelOptions]="{standalone: true}"></p-dropdown>
        </div>
      </div>      

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Employee:</h5>
        </div>
        <div class="col-10">                    
          <div class="ml-2 w-100 mb-2">
            <p-autoComplete  [style]="{'width': '100%'}" [inputStyle]="{'width': '100%'}" [(ngModel)]="prefixText"
                            [suggestions]="userList" (completeMethod)="getStaffList($event)" field="label"
                            [ngModelOptions]="{standalone: true}" [minLength]="1" [autoHighlight]="true"
                            (onSelect)="reloadListAfterStaffChage($event)" [forceSelection]="true"
                            (onClear)="reloadListAfterClear($event)"></p-autoComplete>
          </div>
        </div>
      </div>      

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Status:</h5>
        </div>
        <div class="col-10">
          <i *ngIf="!statusList" class="material-icons rotate text-muted">loop</i>
          <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="statusList" [(ngModel)]="statusId" (ngModelChange)="reloadFilter(this.filter)" [ngModelOptions]="{standalone: true}"></p-dropdown>
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>From Date:</h5>
        </div>
        <div class="col-10">
          <input type="date" class="form-control ml-2 w-100 mb-2" [(ngModel)]="startDate" (blur)="reloadFilter(this.filter)" [ngModelOptions]="{standalone: true}" />
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>To Date:</h5>
        </div>
        <div class="col-10">
          <input type="date" class="form-control ml-2 w-100 mb-2" [(ngModel)]="endDate" (blur)="reloadFilter(this.filter)" [ngModelOptions]="{standalone: true}" />
        </div>
      </div>
    </div>

    <div class="mb-3">
      <button *ngIf="canEdit" (click)="newTimeOffRequest()" class="btn btn-secondary">New Time Off Request</button>
    </div>

    <i [hidden]="!loadingList" class="material-icons rotate text-muted">loop</i>
    <p-table [value]="timeOffList?.result" [responsive]="true" [paginator]="true" [rows]="20"
             [totalRecords]="timeOffList?.totalRecords" [lazy]="true" (onLazyLoad)="reloadFilter($event)">
      <ng-template pTemplate="header">
        <tr>
          <th width="100px"></th>
          <th [pSortableColumn]="'EmployeeName'">Employee<p-sortIcon [field]="'EmployeeName'"></p-sortIcon></th>
          <th [pSortableColumn]="'DateRange'">Date Range<p-sortIcon [field]="'DateRange'"></p-sortIcon></th>
          <th [pSortableColumn]="'TimeRange'">Time Range<p-sortIcon [field]="'TimeRange'"></p-sortIcon></th>
          <th [pSortableColumn]="'Comments'">Comments<p-sortIcon [field]="'Comments'"></p-sortIcon></th>
          <th width="11%"[pSortableColumn]="'ApproverComments'">Approver Comments<p-sortIcon [field]="'ApproverComments'"></p-sortIcon></th>
          <th [pSortableColumn]="'LastModified'">Last Modified<p-sortIcon [field]="'LastModified'"></p-sortIcon></th>
          <th width="11%" [pSortableColumn]="'Changes'">Recent Changes<p-sortIcon [field]="'Changes'"></p-sortIcon></th>
          <th [pSortableColumn]="'DateSubmitted'">Date Submitted<p-sortIcon [field]="'DateSubmitted'"></p-sortIcon></th>
          <th width="5.5%" [pSortableColumn]="'Status'">Status<p-sortIcon [field]="'Status'"></p-sortIcon></th>
          <th>Action</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr>
          <td>
            <button class="btn btn-secondary mr-2" *ngIf="canEdit && item.canEditRequest" (click)="editTimeOffRequest(item)" title="Edit Time Off">
              <i class="fas fa-edit"></i>
            </button>
          </td>
          <td>
            <span class="ui-column-title">Employee Name</span>            
            {{item.employeeName}}
          </td>
          <td class="small-font">
            <span class="ui-column-title small-font">Date Range</span>
            {{item.startDate | date: 'MM/dd/yyyy'}} - {{item.endDate | date: 'MM/dd/yyyy'}}
          </td>
          <td>
            <span class="ui-column-title">Time Range</span>
            <span *ngIf="item.allDay">All Day</span>
            <span *ngIf="item.allDay == false">{{item.startTime | date: 'shortTime'}} - {{item.endTime | date: 'shortTime'}}</span>            
          </td>
          <td class="small-font">
            <span class="ui-column-title">Comments</span>
            {{item.comments}}
          </td>
          <td class="small-font">
            <span class="ui-column-title">Approver Comments</span>
            {{item.approverComments}}
          </td>
          <td>
            <span class="ui-column-title">Last Modified</span>
            {{item.lastModified | date: 'MM/dd/yyyy' || ''}}
          </td>
          <td class="small-font">
            <span class="ui-column-title">Recent Changes</span>
            <div *ngFor="let x of splitChanges(item.recentChangesMade)">
              <p class="changes-view">{{x}}</p>
            </div>
          </td>
          <td>
            <span class="ui-column-title">Date Submitted</span>
            {{item.dateSubmitted | date: 'MM/dd/yyyy' || ''}}
          </td>
          <td>
            <span class="ui-column-title">Status</span>
            <span *ngIf="item.approved == false && item.denied == false">Pending</span>
            <span *ngIf="item.approved">Approved</span>
            <span *ngIf="item.denied">Denied</span>
          </td>
          <td>
            <span class="ui-column-title">Action</span>
            <button class="btn btn-link mr-1" *ngIf="canEdit && item.approved === false && item.denied === false" (click)="approveTimeOffRequest(item)" title="Approve - No Comments">
              <i class="fas fa-check-circle" style="color: green;"></i>
            </button>
            <button class="btn btn-link mr-1" *ngIf="canEdit && item.approved === false && item.denied === false" (click)="denyTimeOffRequest(item)" title="Deny - No Comments">
              <i class="fas fa-ban" style="color: red;"></i>
            </button>
            <button class="btn btn-link mr-1" *ngIf="canEdit && item.approved === false && item.denied === false" (click)="commentTimeOffRequest(item)" title="Comment and More Info">
              <i class="fas fa-comment-dots"></i>
            </button>
            <button class="btn btn-link mr-1" *ngIf="canEdit && (item.approved === true || item.denied === true)" (click)="resetTimeOffRequest(item)" title="Reset Approval">
              <i class="fas fa-undo"></i>
            </button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</form>

<app-edit-timeoff-request></app-edit-timeoff-request>
<app-approve-timeoff-request></app-approve-timeoff-request>
