import { SelfCareEmployeeModel, SelfCarePlannerSectionItemNoteModel, SelfCarePlannerSectionModel, SelfCareQuestionModel, SelfCareCompletionReportModel } from './../../../models/selfcareModels';
import { SelfCareCategoryModel } from 'src/app/models/selfcareModels';
import { createAction, props } from '@ngrx/store';
import { PagingResultsModel } from '../../../models/pagingResultsModel';

export const loadSelfcarePlanner = createAction('[Selfcare] Load Planner',
  props<{ userID: number }>());
export const loadSelfcarePlannerSuccess = createAction('[Selfcare] Load Planner Success',
  props<{ plannerSections: SelfCarePlannerSectionModel[] }>());
export const loadSelfcarePlannerFailure = createAction('[Selfcare] Load Planner Failure');

export const loadSelfcareCategories = createAction('[Selfcare] Load Categories');
export const loadSelfcareCategoriesSuccess = createAction('[Selfcare] Load Categories Success',
  props<{ categories: SelfCareCategoryModel[] }>());
export const loadSelfcareCategoriesFailure = createAction('[Selfcare] Load Categories Failure');

export const loadSelfcareCategory = createAction('[Selfcare] Load Category',
  props<{ categoryID: number, userID: number }>());
export const loadSelfcareCategorySuccess = createAction('[Selfcare] Load Category Success',
  props<{ category: SelfCareCategoryModel }>());
export const loadSelfcareCategoryFailure = createAction('[Selfcare] Load Category Failure');

export const loadSelfcarePreferences = createAction('[Selfcare] Load Preferences',
  props<{ userID: number }>());

export const saveSelfcareAnwers = createAction('[Selfcare] Save Answers',
  props<{ answers: SelfCareQuestionModel[] }>());
export const saveSelfcareAnwersSuccess = createAction('[Selfcare] Save Answers Success');
export const saveSelfcareAnwersFailure = createAction('[Selfcare] Save Answers Failure');

export const saveSelfcarePlannerNote = createAction('[Selfcare] Save Note',
  props<{ note: SelfCarePlannerSectionItemNoteModel }>());
export const saveSelfcarePlannerNoteSuccess = createAction('[Selfcare] Save Note Success',
  props<{ note: SelfCarePlannerSectionItemNoteModel }>());
export const saveSelfcarePlannerNoteFailure = createAction('[Selfcare] Save Note Failure');

export const deleteSelfcarePlannerNote = createAction('[Selfcare] Delete Note',
  props<{ noteID: number }>());
export const deleteSelfcarePlannerNoteSuccess = createAction('[Selfcare] Delete Note Success');
export const deleteSelfcarePlannerNoteFailure = createAction('[Selfcare] Delete Note Failure');


export const loadSelfcareOverSightEmployees = createAction('[Selfcare] Load OverSight Employees');
export const loadSelfcareOverSightEmployeesSuccess = createAction('[Selfcare] Load OverSight Employees Success',
  props<{ employees: SelfCareEmployeeModel[] }>());
export const loadSelfcareOverSightEmployeesFailure = createAction('[Selfcare] Load OverSight Employees Failure');


export const getSelfCareCompletionList = createAction('[SelfCare] Get Self Care Completion List',
  props<{ filter: any }>());
export const getSelfCareCompletionListSuccess = createAction('[SelfCare] Get Self Care Completion List Success',
  props<{ list: PagingResultsModel<SelfCareCompletionReportModel> }>());

export const exportSelfCareCompletionListToExcel = createAction('[SelfCare] Export Self Care Completion List To Excel',
  props<{ filter: any }>());
export const exportSelfCareCompletionListToExcelSuccess = createAction('[SelfCare] Export Self Care Completion List To Excel Success',
  props<{ doc: ArrayBuffer }>());
export const exportSelfCareCompletionListToExcelFailure = createAction('[SelfCare] Export Self Care Completion List To Excel Failure');

export const getSelfCareAppURL = createAction('[Selfcare] Get SelfCareAppURL');

export const getSelfCareAppURLSuccess = createAction('[Selfcare] Get SelfCareAppURL Success',
  props<{ url: string }>());
export const getSelfCareAppURLFailure = createAction('[Selfcare] Get SelfCareAppURL Failure');
