import { SelfCarePlannerSectionModel } from './../../../../../models/selfcareModels';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { State } from 'src/app/core/store';
import { loadManageSelfcarePlanner } from 'src/app/core/store/manage-selfcare/manage-selfcare.actions';
import { SelfCareCategoryModel } from 'src/app/models/selfcareModels';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-selfcare-management-planner',
  templateUrl: './selfcare-management-planner.component.html',
  styleUrls: ['./selfcare-management-planner.component.scss']
})
export class SelfcareManagementPlannerComponent extends BaseComponent  implements OnInit {
  loading: boolean = false;
  plannerSections: SelfCarePlannerSectionModel[];

  constructor(
    private router: Router,
    private actions$: Actions,
    private store: Store<State>,
    private alertService: AlertService,) {
      super();
  }
  ngOnInit() {
    this.plannerSections = null;
    this.subs.push(
      this.store.select(s => s.manageSelfcareState.plannerSections).subscribe(plannerSections => {
        this.plannerSections = plannerSections;
      }),
      this.store.select(s => s.manageSelfcareState.loading).subscribe(loading => {
        this.loading = loading;
      }),

    );
    this.reload();
  }

  reload() {
    this.store.dispatch(loadManageSelfcarePlanner());
  }
  edit(plannerSection: SelfCarePlannerSectionModel) {
    this.router.navigate(['/selfcare/management/plannersection/' + plannerSection.plannerSectionID]);
  }
}
