import { Component, OnInit } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { State } from '../../../../core/store';
import { Store } from '@ngrx/store';
import { SelectItem } from 'primeng/api';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { loadEmployeeUsers, loadViewableCampuses } from '../../../../core/store/employee/employee.action';
import { deepClone, downloadFile } from '../../../../helpers/utils';
import { EmployeeVaccineListModel } from '../../../../models/onBoardingModels';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { exportEmployeeVaccineInfoListToExcel, exportEmployeeVaccineInfoListToExcelSuccess, getEmployeeVaccineInfoList } from '../../../../core/store/onboarding/onboarding.actions';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DropdownFilterService } from '../../../../core/services/dropdown-filter.service';
import { DateRangeModel } from '../../../../models/dateRangeModel';

@Component({
  selector: 'app-list-uploaddocs-vaccine-info',
  templateUrl: './list-uploaddocs-vaccine-info.component.html',
  styleUrls: ['./list-uploaddocs-vaccine-info.component.scss']
})
export class ListUploaddocsVaccineInfoComponent extends BaseComponent implements OnInit {
  list: PagingResultsModel<EmployeeVaccineListModel>;
  filter: any;
  canEdit: boolean = false;

  loadingList: boolean = true;
  loadingExcel: boolean = false;

  campusList: SelectItem[];
  campusId: number = 0;

  prefixText: string;
  userEmployeeList: SelectItem[];
  userId: number = 0;

  dateRangeList: SelectItem[];
  uploadedDateRange: DateRangeModel;
  uploadedStartDate: string = null;
  uploadedEndDate: string = null;
  isCustomUploadedDateRange: boolean = false;

  approvalDateRange: DateRangeModel;
  approvalStartDate: string = null;
  approvalEndDate: string = null;
  isCustomApprovalDateRange: boolean = false;

  vaccineStatusOptions: SelectItem[] = [
    { label: 'All', value: null },
    { label: 'Approved', value: 'Approved' },
    { label: 'Denied', value: 'Denied' },
    { label: 'Uploaded', value: 'Uploaded' },
    { label: 'Not Uploaded', value: 'Not Uploaded' }
  ];
  vaccineStatus: string = null;

  exemptionStatusOptions: SelectItem[] = [
    { label: 'All', value: null },
    { label: 'Approved', value: 'Approved' },
    { label: 'Denied', value: 'Denied' },
    { label: 'Uploaded', value: 'Uploaded' },
    { label: 'Not Uploaded', value: 'Not Uploaded' }
  ];
  exemptionStatus: string = null; 

  showTerminatedEmployees: boolean = false;
  showInactiveUsers: boolean = false;

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private router: Router,
    private dropdownFilterService: DropdownFilterService) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.onboardingState.loadingEmployeeVaccineInfoList).subscribe(loadingEmployeeVaccineInfoList => {
        this.loadingList = loadingEmployeeVaccineInfoList;
      }),
      this.store.select(s => s.onboardingState.downloadingEmployeeVaccineInfoExcel).subscribe(downloadingEmployeeVaccineInfoExcel => {
        this.loadingExcel = downloadingEmployeeVaccineInfoExcel;
      }),
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.canEdit = permissionIDs && !!permissionIDs.find(p => p === 6100); //Upload Document Approval
      }),
      this.store.select(s => s.employeeState.campuses).subscribe(campuses => {
        this.campusList = campuses && campuses.length > 0 ? campuses
          : [{ label: 'loading...', value: undefined }];
      }),     
      this.store.select(s => s.employeeState.employeeUsers).subscribe(users => {
        this.userEmployeeList = users;
      }),
      this.store.select(s => s.onboardingState.employeeVaccineInfoList).subscribe(list => {
        this.list = list;
      }),      
      this.actions$.pipe(
        ofType(exportEmployeeVaccineInfoListToExcelSuccess),
        tap(action => {
          downloadFile(action.doc, 'EmployeeVaccineInfo.xlsx');
        })
      ).subscribe()
    );

    this.dateRangeList = this.dropdownFilterService.getLast120DayDateRanges().map(c => ({ label: c.text, value: c }));
    this.store.dispatch(loadViewableCampuses());
  }

  changedUploadedDateRange() {
    if (this.uploadedDateRange.isCustom) {
      this.isCustomUploadedDateRange = true;
      return; //reload after manually changing date
    }

    this.isCustomUploadedDateRange = false;
    this.uploadedStartDate = this.uploadedDateRange.startDate;
    this.uploadedEndDate = this.uploadedDateRange.endDate;
    this.reloadList();
  }

  changedApprovalDateRange() {
    if (this.approvalDateRange.isCustom) {
      this.isCustomApprovalDateRange = true;
      return; //reload after manually changing date
    }

    this.isCustomApprovalDateRange = false;
    this.approvalStartDate = this.approvalDateRange.startDate;
    this.approvalEndDate = this.approvalDateRange.endDate;
    this.reloadList();
  }

  reloadFilter(event) {
    this.filter = event;
    this.reloadList();
  }

  reloadList() {
    this.setFilter();
    this.store.dispatch(getEmployeeVaccineInfoList({
      filter: this.filter
    }));
  }

  getStaffList(event) {
    this.store.dispatch(loadEmployeeUsers({
      prefixText: event.query,
      programId: this.campusId,
      payrollClassificationId: null,
      showInactive: false,
      showTerminated: false
    }));
  }

  reloadListAfterStaffChage(selectedUser) {
    if (typeof selectedUser.value !== 'number') {
      return;
    }

    this.userId = selectedUser.value;
    this.reloadList();
  }

  reloadListAfterClear(event) {
    if (event.data !== null) {
      return;
    }

    this.userId = 0;
    this.reloadList();
  }  

  setFilter() {
    this.filter = deepClone(this.filter);
    this.filter.filters.userId = { Name: 'userId', Value: { value: this.userId.toString() } };
    this.filter.filters.programId = { Name: 'programId', Value: { value: this.campusId.toString() } };
    this.filter.filters.approvalStartDate = { Name: 'approvalStartDate', Value: { value: this.approvalStartDate } };
    this.filter.filters.approvalEndDate = { Name: 'approvalEndDate', Value: { value: this.approvalEndDate } };
    this.filter.filters.uploadedStartDate = { Name: 'uploadedStartDate', Value: { value: this.uploadedStartDate } };
    this.filter.filters.uploadedEndDate = { Name: 'uploadedEndDate', Value: { value: this.uploadedEndDate } };
    this.filter.filters.vaccineStatus = { Name: 'vaccineStatus', Value: { value: this.vaccineStatus } };
    this.filter.filters.exemptionStatus = { Name: 'exemptionStatus', Value: { value: this.exemptionStatus } };
    this.filter.filters.showTerminatedEmployees = { Name: 'showTerminatedEmployees', Value: { value: this.showTerminatedEmployees.toString() } };
    this.filter.filters.showInactiveUsers = { Name: 'showInactiveUsers', Value: { value: this.showInactiveUsers.toString() } };
  }

  exportToExcel() {
    this.setFilter();
    this.store.dispatch(exportEmployeeVaccineInfoListToExcel({
      filter: this.filter
    }));
  }

  highlightRed(model: EmployeeVaccineListModel) {
    return model.uploads == null || model.uploads.length == 0;    
  }

  highlightOrange(model: EmployeeVaccineListModel) {
    if (model.uploads == null || model.uploads.length == 0) {
      return false;
    };

    for (var i = 0; i < model.uploads.length; i++) {
      var upload = model.uploads[i];
      if (upload.approved == true || upload.approved == false) {
        return false;
      }      
    }

    return true;
  }

  seeUserDetails(model: EmployeeVaccineListModel) {
    this.router.navigate(['/employee-file/detail/' + model.userId]);
  }

  getVaccineStatus(model: EmployeeVaccineListModel) {    
    if (model.uploads == null || model.uploads.length == 0) {
      return 'Not Uploaded';      
    }

    var uploads = model.uploads.filter(function (item) {
      return item.docType == "Vaccine Card";
    });

    if (uploads.length == 0) {
      return 'Not Uploaded';
    }

    var approvedCount = 0;
    var deniedCount = 0;
    var uploadedCount = 0;

    for (var i = 0; i < uploads.length; i++) {
      var upload = uploads[i];
      if (upload.approved == true) {
        approvedCount++;
      }
      else if (upload.approved == false) {
        deniedCount++;
      }
      else {
        uploadedCount++;
      }
    }

    var status = "";
    if (approvedCount > 0) {      
      status += "Approved";
      if (approvedCount > 1) {
        status += " (" + approvedCount + ")";
      }
      status += "\n";
    }
    if (deniedCount > 0) {
      status += "Denied";
      if (deniedCount > 1) {
        status += " (" + deniedCount + ")";
      }
      status += "\n";
    }
    if (uploadedCount > 0) {
      status += "Uploaded";
      if (uploadedCount > 1) {
        status += " (" + uploadedCount + ")";
      }
      status += "\n";      
    }

    return status;
  }

  getExemptionStatus(model: EmployeeVaccineListModel) {
    if (model.uploads == null || model.uploads.length == 0) {
      return 'Not Uploaded';
    }

    var uploads = model.uploads.filter(function (item) {
      return item.docType == "Vaccine Card Exemption";
    });

    if (uploads.length == 0) {
      return 'Not Uploaded';
    }

    var approvedCount = 0;
    var deniedCount = 0;
    var uploadedCount = 0;
    var approvedString = "";
    var deniedString = "";
    var uploadedString = "";

    for (var i = 0; i < uploads.length; i++) {
      var upload = uploads[i];
      if (upload.approved == true) {
        if (approvedCount > 0) {
          approvedString += ", ";
        }

        approvedString += upload.exemptionType == 0 ? "Medical" : "Other";        
        approvedCount++;        
      }
      else if (upload.approved == false) {
        if (approvedCount > 0) {
          deniedString += ", ";
        }

        deniedString += upload.exemptionType == 0 ? "Medical" : "Other";
        deniedCount++;
      }
      else {
        if (approvedCount > 0) {
          uploadedString += ", ";
        }

        uploadedString += upload.exemptionType == 0 ? "Medical" : "Other";
        uploadedCount++;
      }
    }

    var status = "";
    if (approvedCount > 0) {
      status += "Approved (";
      if (approvedCount > 1) {
        status += approvedCount;
      }
      var hasMedical = approvedString.indexOf("Medical") >= 0;
      var hasOther = approvedString.indexOf("Other") >= 0;
      if (hasMedical && hasOther) {
        status += approvedString;
      }
      else if (hasMedical) {
        status += "Medical";
      }
      else {
        status += "Other";
      }
      
      status += ") \n";
    }
    if (deniedCount > 0) {
      status += "Denied (";
      if (deniedCount > 1) {
        status += deniedCount;
      }
      var hasMedical = deniedString.indexOf("Medical") >= 0;
      var hasOther = deniedString.indexOf("Other") >= 0;
      if (hasMedical && hasOther) {
        status += deniedString;
      }
      else if (hasMedical) {
        status += "Medical";
      }
      else {
        status += "Other";
      }

      status += ") \n";      
    }
    if (uploadedCount > 0) {
      status += "Uploaded (";
      if (uploadedCount > 1) {
        status += uploadedCount;
      }
      var hasMedical = uploadedString.indexOf("Medical") >= 0;
      var hasOther = uploadedString.indexOf("Other") >= 0;
      if (hasMedical && hasOther) {
        status += uploadedString;
      }
      else if (hasMedical) {
        status += "Medical";
      }
      else {
        status += "Other";
      }

      status += ") \n";    
    }

    return status;
  }
}
