import { createAction, props } from '@ngrx/store';
import { SelectItem } from 'primeng';
import { InputAutoCompleteModel } from '../../models/inputAutoCompleteModel';

export const toggleLoading = createAction('[Input Autocomplete] Toggle Loading');

export const clearAutoCompleteList = createAction('[Input Autocomplete] Clear AutoComplete List');

export const getInputAutocompleteList = createAction('[Input Autocomplete] Get Input AutoComplete List',
    props<{ options: any }>());
export const getInputAutocompleteListSuccess = createAction('[Input Autocomplete] Get Input AutoComplete List Success',
    props<{ result: SelectItem[] }>());
export const getInputAutocompleteListFail = createAction('[Input Autocomplete] Get Input AutoComplete List Fail',
    props<{ err: any }>());
