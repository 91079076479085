<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Return Equipment To Assign</h4>
    <button type="button" class="close btn btn-outline-dark" aria-label="Close"
      (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
      <div class="row">
        <h6 class="w-100 col-12 text-left mb-1 pb-0">Please Verify Equipment On Hand Is Correct</h6>
        <hr class="mb-4 col-12" />
        <div class="col-6 d-flex justify-content-between mt-2">
            <div class="ml-3">Date Assigned:</div>
            <div class="mr-3">{{equipment.lastAssignedEquipment.dateAssigned | date:'shortDate'}}</div>
        </div>
        <div class="col-6 d-flex justify-content-between mt-2">
            <div class="ml-3">Type:</div>
            <div class="mr-3">{{equipment.equipment.type}}</div>
        </div>
        <div class="col-6 d-flex justify-content-between mt-2">
            <div class="ml-3">Brand:</div>
            <div class="mr-3">{{equipment.equipment.brand}}</div>
        </div>
        <div class="col-6 d-flex justify-content-between mt-2">
            <div class="ml-3">Model:</div>
            <div class="mr-3">{{equipment.equipment.model}}</div>
        </div>
        <div class="col-6 d-flex justify-content-between mt-2">
            <div class="ml-3">Serial Number:</div>
            <div class="mr-3">{{equipment.equipment.serialNumber}}</div>
        </div>
        <div class="col-6 d-flex justify-content-between mt-2">
            <div class="ml-3">Service Tag:</div>
            <div class="mr-3">{{equipment.equipment.serviceTag}}</div>
        </div>
        <div class="col-6 d-flex justify-content-between mt-2">
            <div class="ml-3">{{generalSettings?.businessName}} #:</div>
            <div class="mr-3">{{equipment.equipment.assetTag }}</div>
        </div>
        <div class="col-6 d-flex justify-content-between mt-2">
            <div class="ml-3">Express Service Code</div>
            <div class="mr-3">{{equipment.equipment.expressServiceCode }}</div>
        </div>
        <div class="col-6 d-flex justify-content-between mt-2">
            <div class="ml-3">Storage Location:</div>
            <div class="mr-3">{{equipment.equipment.storedLocation}}</div>
        </div>
        <div class="col-12 mt-4 d-flex justify-content-center">
            <button class="btn btn-primary" (click)="verifiedEquipment()">I have verified the equipment is the same</button>
        </div>
    </div>
    <div *ngIf="showReturnForm" class="row mt-4">
        <h6 class="w-100 col-12 text-left mb-1 mt-2 pb-0">Please fill in Equipment Status</h6>
        <hr class="mb-4 col-12" />
        <div class="col-12 d-flex justify-content-between mt-3">
            <div class="ml-3 mr-2">Date&nbsp;Returned:</div>
            <input type="date" class="form-control ml-4" [(ngModel)]="dateReturned"/>
        </div>
        <div class="col-12 d-flex justify-content-between mt-3">
            <div class="ml-3">Status:</div>
            <select class="form-control ml-4" [(ngModel)]="returnStatus">
              <option value="Good">Good</option>
              <option value="Damaged">Damaged</option>
            </select>
        </div>
        <div class="col-12 d-flex justify-content-between mt-3">
            <div class="ml-3">Notes:</div>
            <textarea class="form-control ml-4"[(ngModel)]="returnNotes"></textarea>
        </div>
    </div>
  </div>
  <div class="modal-footer">
    <button *ngIf="showReturnForm" type="button" class="btn btn-success" (click)="save()">Add To Assign</button>
    <button type="button" class="btn btn-outline-dark" (click)="close()">Cancel</button>
  </div>