<div class="critical-news-feed-container d-flex flex-column" [style.height]="isIframe === true ? '98vh' : '96vh'"  (scroll)="onScroll($event)" autoscroll="true">
    <div *ngIf="canView" class="w-100 d-flex justify-content-end align-items-center filter-container">
        <p *ngIf="isFiltered" class="m-0 mr-2 p-0 text-sm-italic">Filtered**</p>
        <div class="critical-news-filter d-flex justify-content-center align-items-center pointer" [ngStyle]="{'background-color': !isFiltered  ? 'rgb(245, 245, 245)' : 'rgba(192, 192, 192)'}" (click)="showFilter($event)">
            <i class="fas fa-filter"></i>
        </div>
        <div *ngIf="showFeedFilter" class="filter-menu-options row" (click)="stopPropagation($event)">
            <div class="col-12 row mt-1">
                <label class="col-4 text-sm mt-1">Filter&nbsp;By:</label>
                <select [(ngModel)]="filterType" (ngModelChange)="filterTypeChanged()" [ngModelOptions]="{standalone: true}"class="col-8 form-control form-control-sm">
                    <option value="User">User</option>
                    <option value="PostMessage">Keyword(s)</option>
                    <option value="Date">Date</option>
                </select>
            </div>
            <div *ngIf="filterType == 'User' || filterType == 'PostMessage'" class="col-12 row mt-1">
                <label *ngIf="filterType == 'User'" class="col-4 text-sm mt-1">User:</label>
                <label *ngIf="filterType == 'PostMessage'"class="col-4 text-sm mt-1">Keyword(s):</label>
                <input type="text" [(ngModel)]="filterValue" class="col-8 form-control form-control-sm"/>
            </div>
            <div *ngIf="filterType == 'Date'" class="col-12 row mt-1">
                <label class="col-4 d-flex text-sm mt-1 justify-content-start align-items-center">Date(s):&nbsp;<p *ngIf="filterDateDisplay != null" class="text-primary text-sm-italic p-0 m-0">{{ filterDateDisplay }}***</p></label>
                <div class="col-8 w-100 p-0">
                    <p-calendar #myCalendar
                    inputStyleClass="form-control"
                    [monthNavigator]="true"
                    [yearNavigator]="true"
                    styleClass="w-100"
                    panelStyleClass="calendarPanel"
                    [hourFormat]="12"
                    [yearRange]="'2010:2100'"
                    [showTime]="false"
                    [selectionMode]="'range'"
                    [(ngModel)]="filterDateString" [ngModelOptions]="{standalone: true}"
                    dataType="string"
                  >
                  <p-footer>
                    <button pButton type="button" label="Close" (click)="close();$event.stopPropagation()"></button>
                  </p-footer>
                </p-calendar>
                </div>
            </div>
            <div class="col-12 row mt-1">
                <label class="col-4 text-sm mt-1">Sort&nbsp;By:</label>
                <select [(ngModel)]="sortBy" class="col-8 form-control form-control-sm">
                    <option value="Newest">New</option>
                    <option value="Oldest">Old</option>
                    <option value="UserFirstName">User First Name</option>
                    <option value="UserLastName">User Last Name</option>
                </select>
            </div>
            <div class="col-12 d-flex justify-content-end mt-1">
                <label class="text-sm mt-1">Show&nbsp;Just&nbsp;Pinned</label>
                <input id="justPinned" type="checkbox" class="checkBox mb-2 col-1" style="transform: scale(.5);" [(ngModel)]="showJustPinned" [ngModelOptions]="{standalone: true}"/>
            </div>
            <div class="col-12 d-flex justify-content-center align-items-center mt-2">
                <button type="button" class="btn btn-primary btn-sm" (click)="filterPosts($event)">Filter</button>
                <button type="button" class="btn btn-secondary ml-3 btn-sm" (click)="clearFilterPosts($event)">Clear</button>
                <button type="button" class="btn btn-light ml-3 btn-sm" (click)="closeFilter($event)">Close</button>
            </div>
        </div>
    </div>
    <div class="scroll-top-button-container w-100 d-flex justify-content-center">
        <div *ngIf="showScrollTop" class="text-sm-italic pointer scroll-top-button d-flex justify-content-center align-items-center" (click)="scrollTop()">
            New Posts <i class="fas fa-angle-double-up text-sm-italic ml-2"></i>
        </div>
    </div>
    <div class="w-100 d-flex justify-content-center mb-0 mt-0 p-0">
        <h4>News Feed</h4>
    </div>
    <div class="d-flex justify-content-center align-items-center text-center mt-2 mb-1">
        <div *ngIf="!canView" class="d-flex justify-content-center align-items-center text-center mt-2 mb-3">
            <p>You do not have permission to view the News Feed. If this message is in error please contact your Administrator</p>
        </div>
    </div>
    <div (click)="togglePinnedPosts()" class="w-100 d-flex mb-2 mt-1 justify-content-center align-items-center pointer">
        <div *ngIf="canView" class="w-100 d-flex justify-content-center align-items-center pointer">
            <div class="d-flex justify-content-center align-items-center text-sm-italic">Company Pinned Posts</div>
            <p class="m-0 ml-1 d-flex justify-content-center align-items-center pinned-alert">{{ pinnedPosts != null && pinnedPosts.length > 0 ? pinnedPosts.length : 0 }}</p>
            <div class="w62 h-50 ml-2 border-bottom"></div>
            <i *ngIf="showPinnedPosts" class="fas fa-angle-up pointer mt-1 ml-2 text-sm-italic"></i>
            <i *ngIf="!showPinnedPosts"class="fas fa-angle-down pointer mt-2 ml-2 text-sm-italic"></i>
            <div class="w3 h-50 ml-2 border-bottom"></div>
        </div>
    </div>
    <div *ngIf="canView && pinnedPosts != null && pinnedPosts.length > 0 && showPinnedPosts" [@enterAnimation]>
        <div *ngFor="let post of pinnedPosts" class="post-container individual-post mt-3">
            <app-critical-news-post
                [post] = "post"
            ></app-critical-news-post>
        </div>
    </div>
    <div (click)="toggleUserPinnedPosts()" class="w-100 d-flex mb-1 justify-content-center align-items-center pointer">
        <div *ngIf="canView" class="w-100 d-flex justify-content-center align-items-center pointer">
            <div class="d-flex justify-content-center align-items-center text-sm-italic">My Pinned Posts</div>
            <p class="m-0 ml-1 d-flex justify-content-center align-items-center pinned-alert">{{ userPinnedPosts != null && userPinnedPosts.length > 0 ? userPinnedPosts.length : 0 }}</p>
            <div class="w69 h-50 ml-2 border-bottom"></div>
            <i *ngIf="showUserPinnedPosts" class="fas fa-angle-up pointer mt-2 ml-2 text-sm-italic"></i>
            <i *ngIf="!showUserPinnedPosts"class="fas fa-angle-down pointer mt-2 ml-2 text-sm-italic"></i>
            <div class="w3 h-50 ml-2 border-bottom"></div>
        </div>
    </div>
    <div *ngIf="canView && userPinnedPosts != null && userPinnedPosts.length > 0 && showUserPinnedPosts" class=" pb-3 border-bottom" [@enterAnimation]>
        <div *ngFor="let post of userPinnedPosts" class="post-container individual-post mt-3">
            <app-critical-news-post
                autoscroll="true"
                [post] = "post"
            ></app-critical-news-post>
        </div>
    </div>
    <div *ngIf="canView && showFeed">
        <div *ngFor="let post of posts" #newsFeed class="post-container individual-post mt-3">
            <app-critical-news-post
                autoscroll="true"
                [post] = "post"
            ></app-critical-news-post>
        </div>
    </div>
    <div class="d-flex justify-content-center w-100 align-items-center mt-4 mb-4">
        <i *ngIf="scrollLoading" style="font-size: 2rem;" class="material-icons rotate text-muted">loop</i>
        <div *ngIf="canView && totalPostsLoaded != null && totalPostsLoaded == totalPosts" class="w-100 d-flex justify-content-center align-items-center text-center">
            <p>{{ totalPosts == 0 ? "There are no Posts yet. Come back soon!!!" : "Yay! You have made it through all of the posts!!!" }}  <i class="fas fa-smile-beam text-primary"></i></p>
        </div>
    </div>
    <div *ngIf="canPost && canView" class="add-post mt-4">
        <app-add-post 
            autoscroll="true"
            (scrollTop)="scrollTop()"
        ></app-add-post>
    </div>
</div>