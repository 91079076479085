<div class="row">
  <div class="col-12 col-lg-12">
    <div class="card">
      <div class="card-body">
        <div *ngIf="canEdit" class="float-right">
          <button type="button" routerLink="/payroll/manualEntries" class="btn btn-secondary mr-2">Manual Entry Report</button>          
        </div>
        <h2 class="mb-3">Payroll Approval</h2>

        <div class="row">
          <div class="col-2 d-flex align-items-center">
              <h5>Campus:</h5>
          </div>
          <div class="col-10 ">
            <i *ngIf="!campusesList" class="material-icons rotate text-muted">loop</i>
            <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="campusesList" [(ngModel)]="campusID" (ngModelChange)="changedUserFilters()" [ngModelOptions]="{standalone: true}" placeholder="Select campus..."></p-dropdown>
          </div>
          <div class="col-2   d-flex align-items-center">
              <h5>Payroll Classification:</h5>
          </div>
          <div class="col-10 ">
            <i *ngIf="!payrollClassificationList" class="material-icons rotate text-muted">loop</i>
            <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="payrollClassificationList" [(ngModel)]="payrollClassificationID" (ngModelChange)="changedUserFilters()" [ngModelOptions]="{standalone: true}" placeholder="Select payroll classification..."></p-dropdown>
          </div>

          <div class="col-2   d-flex align-items-center">
              <h5>User:</h5>
          </div>
          <div class="col-10 ">
            <i *ngIf="!usersList" class="material-icons rotate text-muted">loop</i>
            <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="usersList" [(ngModel)]="userID" (ngModelChange)="changedUser()" [ngModelOptions]="{standalone: true}" placeholder="Select user..." id="userSelectionDropdown">
              <ng-template let-item pTemplate="selectedItem">
                <i *ngIf="users[item.value]?.periodLocked" class="fas fa-lock mr-2"></i>
                <span>{{item.label}}</span>
                <span class="text-success ml-2" *ngIf="users[item.value]?.approvedHours || users[item.value]?.approvedMinutes">{{users[item.value].approvedHours}}h {{users[item.value].approvedMinutes}}m</span>
                <span title="Items that need adjustment with comments" class="badge badge-danger  ml-2" *ngIf="users[item.value]?.fixRequestsNotResolvedWithComment">{{users[item.value].fixRequestsNotResolvedWithComment}}</span>
              </ng-template>
            <ng-template let-item pTemplate="item">
              <i *ngIf="users[item.value]?.periodLocked" class="fas fa-lock mr-2"></i>
              <span>{{item.label}}</span>
              <span class="text-success ml-2" *ngIf="users[item.value]?.approvedHours || users[item.value]?.approvedMinutes">{{users[item.value].approvedHours}}h {{users[item.value].approvedMinutes}}m</span>
              <span title="Items that need adjustment with comments" class="badge badge-danger  ml-2" *ngIf="users[item.value]?.fixRequestsNotResolvedWithComment">{{users[item.value].fixRequestsNotResolvedWithComment}}</span>

            </ng-template>
            </p-dropdown>
          </div>

          <div class="col-2  d-flex align-items-center">
            <h5>Period:</h5>
          </div>
          <div class="col-10 ">
            <i *ngIf="!payPeriods" class="material-icons rotate text-muted">loop</i>
            <select class="form-control ml-2" *ngIf="payPeriods"  [ngModel]="selectedPeriodID" (ngModelChange)="changePeriod($event)">
              <option *ngFor="let period of payPeriods" [value]="period.payPeriodID">{{period.payPeriod}}</option>
            </select>
          </div>

          <ng-container *ngIf="canViewAllPending">
            <div class="col-2  d-flex align-items-center">
              <h5>Show All Pending:</h5>
            </div>
            <div class="col-10 " style="padding-left: 25px;">            
              <p-checkbox [(ngModel)]="showAllPending" (ngModelChange)="refreshPendingAndAdjusmentTime()" [binary]="true" [ngModelOptions]="{standalone: true}"></p-checkbox>
            </div>
          </ng-container>

          <div class="col-12" *ngIf="userID && selectedPeriodID">
            <div class="row mt-3 ">
              <div class="col-12 col-lg-4 offset-lg-4">
                <div class="card">
                  <div class="card-body">
                    <h5>Approved</h5>
                    <hr>

                    <i *ngIf="!approvedTotals" class="material-icons rotate text-muted">loop</i>
                    <ng-container *ngIf="approvedTotals">
                      Total hours: <span class="float-right">{{getTimeFormatted(approvedTotals.totalTime) }}</span><br>
                      Pay Period hours: <span class="float-right">{{getTimeFormatted(approvedTotals.payPeriodMinutes) }}</span><br>
                      <ng-container *ngFor="let dateRange of approvedTotals.dateRanges">
                        <span style="margin-left: 5px; font-size: 0.9rem;">{{getShortDateFormatted(dateRange.startDate)}} - {{getShortDateFormatted(dateRange.endDate)}}:</span> <span class="float-right">{{getTimeFormatted(dateRange.totalMinutes) }}</span><br>
                      </ng-container>
                      <ng-container *ngIf="approvedTotals.matchContractHours">
                        Contracted Hours: <span class="float-right">{{getTimeFormatted(approvedTotals.contractMinutes) }}</span><br>
                      </ng-container>
                      <ng-container *ngIf="approvedTotals.overtimeMinutes > 0">
                        Overtime Hours: <span class="float-right">{{getTimeFormatted(approvedTotals.overtimeMinutes) }}</span><br>
                      </ng-container>
                      Productivity:<span class="float-right">{{approvedTotals.productivity.toFixed(0)}} % </span><br>
                      Ceiling Productivity:<span class="float-right">{{approvedTotals.ceilingProductivity.toFixed(0)}} %</span><br>
                      <ng-container *ngIf="approvedTotals.showBonusPaid">
                        Profit Sharing: <span class="float-right">{{formatCurrencyNotZero(approvedTotals.bonusPaid) }}</span><br>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
            <div class="ml-2">
              <button class="btn btn-primary" (click)="_refresh()">Refresh</button>
              <button *ngIf="userID && canEditItems" class="btn btn-primary ml-2" (click)="newTimeOffRequest()">Add PTO</button>
            </div>
            <div class="ml-2">
              <div class=" mt-4 mb-2">
                <div class="d-flex align-items-center">
                  <i class="material-icons mr-2 pointer" (click)="approvedOpened=!approvedOpened">{{approvedOpened?'keyboard_arrow_up':'keyboard_arrow_down'}}</i>
                  <h4 class="mb-0 ">Approved Time</h4>
                </div>

                <div class="card  mt-3">
                  <div class="card-body" *ngIf="approvedOpened">
                    <div class="row mt-2">
                      <i *ngIf="!differentApprovedPayableTypes" class="material-icons rotate text-muted">loop</i>
                      <div class="col-12 col-lg-4" *ngFor="let diffPayableType of differentApprovedPayableTypes">
                        <app-payroll-items-table [payableType]="diffPayableType.payableType" [payrollItems]="diffPayableType.payrollItems"
                                                 (payableItemEditClicked)="editPayableItemTime($event)"
                                                 (ptoEditClicked)="editPtoItem($event)"
                                                 [selectable]="canEdit && !isLocked" (selectedItemsChanged)="approvedPayrollItemsSelectedChanged($event,diffPayableType.payableType)"></app-payroll-items-table>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-12">
                        <button class="btn btn-link text-danger" *ngIf="canEdit && !isLocked && approvedPayrollItemsSelected.length" (click)="executeUnapproveTimes()">Unapprove</button>
                        <button class="btn btn-primary" *ngIf="canEdit && !isLocked" (click)="lockPayperiod()">Lock Pay Period</button>
                        <button class="btn btn-primary" *ngIf="canEdit && isLocked" (click)="unlockPayperiod()">Unlock Pay Period</button>
                        <button class="btn btn-primary ml-2" *ngIf="canEdit && !isLocked && approvedTotals && approvedTotals.matchContractHours && approvedTotals.contractMinutes !== approvedTotals.totalTime" (click)="generatePayableItems()">Generate Items</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="ml-2">
                <div class=" mt-4 mb-2">
                    <div class="d-flex align-items-center">
                        <i class="material-icons mr-2 pointer" (click)="pendingOpened=!pendingOpened" >{{pendingOpened?'keyboard_arrow_up':'keyboard_arrow_down'}}</i>
                        <h4 class="mb-0 ">Pending Approval Time</h4>
                    </div>
                    <div class="card  mt-3">
                      <div class="card-body" *ngIf="pendingOpened">
                        <div class="row mt-2">
                          <i *ngIf="!differentPendingPayableTypes" class="material-icons rotate text-muted">loop</i>
                          <div class="col-12 col-lg-4" *ngFor="let diffPayableType of differentPendingPayableTypes">
                            <app-payroll-items-table [payableType]="diffPayableType.payableType" [payrollItems]="diffPayableType.payrollItems"
                                                     (payableItemEditClicked)="editPayableItemTime($event)"
                                                     (ptoEditClicked)="editPtoItem($event)"
                                                     [selectable]="canEdit && !isLocked"
                                                     (selectedItemsChanged)="pendingPayrollItemsSelectedChanged($event,diffPayableType.payableType)"></app-payroll-items-table>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-12">
                            <button class="btn btn-link text-danger" *ngIf="canEdit && !isLocked && pendingPayrollItemsSelected.length" id="rejectButton" (click)="openRejectTimes()">Reject</button>
                            <button class="btn btn-primary" *ngIf="canEdit && !isLocked && pendingPayrollItemsSelected.length" id="approveButton" (click)="approveTimes('approve')">Approve</button>
                            <button class="btn btn-primary ml-2" *ngIf="canEdit && !isLocked && pendingPayrollItemsSelected.length" id="minWageButton" (click)="approveTimes('minWage')">Approve Minimum Wage</button>
                            <button class="btn btn-primary ml-2" *ngIf="canEdit && !isLocked && pendingPayrollItemsSelected.length" id="exemptButton" (click)="approveTimes('exempt')">Exempt</button>
                            <button class="btn btn-primary ml-2" *ngIf="canEdit && !isLocked && pendingPayrollItemsSelected.length" id="lateContractButton" (click)="approveTimes('contractLate')">Contract Late</button>
                            <button class="btn btn-primary ml-2" *ngIf="canEdit && !isLocked && pendingPayrollItemsSelected.length" id="lateMinWageButton" (click)="approveTimes('minWageLate')">Minimum Wage Late</button>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
            <div class="ml-2">
              <div class=" mt-4 mb-2">
                <div class="d-flex align-items-center">
                  <i class="material-icons mr-2 pointer" (click)="adjustmentsOpened=!adjustmentsOpened">{{adjustmentsOpened?'keyboard_arrow_up':'keyboard_arrow_down'}}</i>
                  <h4 class="mb-0 ">Adjustments Needed</h4>
                </div>
                <div class="card  mt-3">
                  <div class="card-body" *ngIf="adjustmentsOpened">
                    <div class="row mt-2">
                      <div class="col-12 col-lg-4">
                        <app-payroll-items-table id="rejectedItemsTable" [title]="'Rejected'" [payrollItems]="adjustmentPayrollItems"
                                                 [selectable]="canEdit && !isLocked" [adjustmentView]="true"
                                                 (adjustmentEditClicked)="adjustmentsPayrollItemsEditClicked($event)"
                                                 (selectedItemsChanged)="adjustmentsPayrollItemsSelectedChanged($event)"
                                                 (payableItemEditClicked)="editPayableItemTime($event)"
                                                 (ptoEditClicked)="editPtoItem($event)"></app-payroll-items-table>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-12">
                        <button class="btn btn-primary" *ngIf="canEdit && !isLocked && adjustmentsPayrollItemsSelected.length" (click)="openChangeAdjustmentsTimes()">Edit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="ml-2">
              <div class=" mt-4 mb-2">
                <div class="d-flex align-items-center">
                  <i class="material-icons mr-2 pointer" (click)="invalidOpened=!invalidOpened">{{invalidOpened?'keyboard_arrow_up':'keyboard_arrow_down'}}</i>
                  <h4 class="mb-0 ">Invalid</h4>
                </div>
                <div class="card mt-3">
                  <div class="card-body" *ngIf="invalidOpened">
                    <div class="row mt-2">
                      <div class="col-12 col-lg-4">
                        <app-payroll-items-table [title]="'Invalid'" [payrollItems]="invalidPayrollItems"
                                                 [adjustmentView]="true"
                                                 (adjustmentEditClicked)="invalidPayrollItemsEditClicked($event)"
                                                 (payableItemEditClicked)="editPayableItemTime($event)"
                                                 (ptoEditClicked)="editPtoItem($event)"
                                                 [selectable]="canEdit && !isLocked" (selectedItemsChanged)="invalidPayrollItemsSelectedChanged($event)"></app-payroll-items-table>
                      </div>
                    </div>


                    <div class="row mt-2">
                      <div class="col-12">
                        <button class="btn btn-primary" *ngIf="canEdit && !isLocked && invalidPayrollItemsSelected.length" (click)="openChangeInvalidTimes()">Edit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<p-dialog header="Reject Items" [modal]="true" appendTo="body" [style]="{width: '70vw'}" [(visible)]="displayRejectItems">
  <app-payroll-reject-items-table [title]="'Reject Items'"  [payrollItems]="selectedItemsToReject"
  (itemsChanged)="itemsToRejectChanged($event)"></app-payroll-reject-items-table>

  <div class="text-right mt-2">
    <div class="col-12" >
      <button class="btn btn-primary" *ngIf="canEdit && selectedItemsToReject.length" (click)="executeRejectTimes()">Reject</button>
    </div>
  </div>
</p-dialog>


<p-dialog header="Edit Adjustments Items" [modal]="true" appendTo="body" [style]="{width: '70vw'}" [(visible)]="displayAdjustmentsItems">
  <app-payroll-reject-items-table [title]="'Edit Payable Items'"  [payrollItems]="adjustmentsPayrollItemsSelected"
  (itemsChanged)="adjustmentsItemsEditingChanged($event)"></app-payroll-reject-items-table>

  <div class="text-right mt-2">
    <div class="col-12" >
      <button class="btn btn-primary" *ngIf="canEdit && adjustmentsPayrollItemsSelected.length" (click)="saveAdjustmentTimes()">Save</button>
    </div>
  </div>
</p-dialog>

<p-dialog header="Edit Invalid Items" [modal]="true" appendTo="body" [style]="{width: '70vw'}" [(visible)]="displayInvalidItems">
  <app-payroll-reject-items-table [title]="'Edit Payable Items'"  [payrollItems]="invalidPayrollItemsSelected"
  (itemsChanged)="invalidItemsEditingChanged($event)"></app-payroll-reject-items-table>

  <div class="text-right mt-2">
    <div class="col-12" >
      <button class="btn btn-primary" *ngIf="canEdit && invalidPayrollItemsSelected.length" (click)="saveInvalidTimes()">Save</button>
    </div>
  </div>
</p-dialog>

<app-edit-payable-item></app-edit-payable-item>
<app-edit-timeoff-request></app-edit-timeoff-request>
