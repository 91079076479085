<form class="card mt-2">
  <div class="card-body">
    <div class="float-right">
      <button *ngIf="canManage" type="button" routerLink="/intern/actionType/list" class="btn btn-secondary mr-2">Action Item Types</button>
      <button *ngIf="canManage" type="button" routerLink="/intern/substatus/list" class="btn btn-secondary mr-2">Substatuses</button>
      <button type="button" routerLink="/intern/actionItemTemplate/list" class="btn btn-secondary mr-2">Action Item Templates</button>
    </div>
    <h2 class="mb-3">Intern Applications</h2>
    <div class="mb-3">

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Status:</h5>
        </div>
        <div class="col-10">
          <i *ngIf="!statusList" class="material-icons rotate text-muted">loop</i>
          <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="statusList" [(ngModel)]="statusId" (ngModelChange)="reloadFilter(this.filter)" [ngModelOptions]="{standalone: true}"></p-dropdown>
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Type:</h5>
        </div>
        <div class="col-10">
          <i *ngIf="!formTemplates" class="material-icons rotate text-muted">loop</i>
          <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="formTemplates" [(ngModel)]="formId" (ngModelChange)="reloadFilter(this.filter)" [ngModelOptions]="{standalone: true}"></p-dropdown>
        </div>
      </div>

      <div class="row">
        <div class="col-2 d-flex align-items-center">
          <h5>Employee:</h5>
        </div>
        <div class="col-10">
          <div class="ml-2 w-100 mb-2">
            <p-autoComplete [style]="{'width': '100%'}" [inputStyle]="{'width': '100%'}" [(ngModel)]="prefixText"
                            [suggestions]="userEmployeeList" (completeMethod)="getStaffList($event)" field="label"
                            [ngModelOptions]="{standalone: true}" [minLength]="1" [autoHighlight]="true"
                            (onSelect)="reloadListAfterStaffChange($event)" [forceSelection]="true"
                            (onClear)="reloadListAfterClear($event)"></p-autoComplete>
          </div>
        </div>
      </div>

    </div>

    <i [hidden]="!loadingList && !loadingExcel && !loadingDownload" class="material-icons rotate text-muted">loop</i>
    <button type="button" title="Export all pages to excel" class="btn btn-success mr-2 mb-2" (click)="exportToExcel()"><i class="far fa-file-excel"></i></button>
    <p-table [value]="list?.result" [responsive]="true" [paginator]="true" [rows]="20"
             [totalRecords]="list?.totalRecords" [lazy]="true" (onLazyLoad)="reloadFilter($event)">
      <ng-template pTemplate="header">
        <tr>
          <th width="95px"></th>          
          <th [pSortableColumn]="'EmployeeName'">Employee<p-sortIcon [field]="'EmployeeName'"></p-sortIcon></th>
          <th [pSortableColumn]="'FormName'">Type<p-sortIcon [field]="'FormName'"></p-sortIcon></th>
          <th [pSortableColumn]="'Status'">Status<p-sortIcon [field]="'Status'"></p-sortIcon></th>
          <th [pSortableColumn]="'SubmittedDate'">Date Submitted<p-sortIcon [field]="'SubmittedDate'"></p-sortIcon></th>
          <th [pSortableColumn]="'ApplicantLastModified'">Last Modififed by Applicant<p-sortIcon [field]="'ApplicantLastModified'"></p-sortIcon></th>
          <th [pSortableColumn]="'DecisionDate'">Decision Date<p-sortIcon [field]="'DecisionDate'"></p-sortIcon></th>
          <th [pSortableColumn]="'HireDate'">Hire Date<p-sortIcon [field]="'HireDate'"></p-sortIcon></th>
          <th width="130px">Hours / Productivity</th>
          <th>Action Items</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-list>
        <tr>
          <td>
            <i class="fab fa-wpforms mr-2 pointer mb-2" style="font-size: 1.5rem" *ngIf="list.statusId != 1 && !canManage" (click)="viewApplication(list)" title="View Application"></i>
            <i class="far fa-file-search mr-2 pointer mb-2" *ngIf="canManage" type="button" routerLink="/intern/reviewProcess/{{list.internApplicationId}}" title="Review Application" style="font-size: 1.5rem;"></i>
          </td>          
          <td>
            <span class="ui-column-title">Employee Name</span>
            {{list.firstName}} {{list.lastName}}
          </td>
          <td>
            <span class="ui-column-title">Form Name</span>
            {{list.formName}}
          </td>
          <td>
            <span class="ui-column-title">Status</span>
            {{list.status}} <span *ngIf="list.substatusId != null" class="ml-2" style="font-style: italic;">({{list.substatus}})</span>
          </td>
          <td>
            <span class="ui-column-title">Date Submitted</span>
            {{(list.submittedDate !== null) ? (list.submittedDate | date: 'MM/dd/yyyy @ hh:mm:ss a') : ""}}
          </td>
          <td>
            <span class="ui-column-title">Last Modified</span>
            {{list.applicantLastModified | date: 'MM/dd/yyyy @ hh:mm:ss a'}}
          </td>
          <td>
            <span class="ui-column-title">Decision Date</span>
            {{(list.decisionDate !== null) ? (list.decisionDate | date: 'MM/dd/yyyy @ hh:mm:ss a') : ""}}
          </td>
          <td>
            <span class="ui-column-title">Hire Date</span>
            {{(list.hireDate !== null) ? (list.hireDate | date: 'MM/dd/yyyy') : ""}}
          </td>
          <td>
            <span class="ui-column-title">Hours / Productivity</span>
            <div *ngIf="list.submittedDate != null">
              <i *ngIf="canManage" class="fas fa-sync" style="cursor: pointer;" (click)="updateCalculations(list)" title="Update calcuations for hours and productivity"></i>
              <div style="font-weight: 600; text-decoration:underline;">90 Day Avg</div>
              <div>
                Hours: {{list.averageHoursNinetyDays}}
              </div>
              <div>
                Time Card: {{list.timeCardProductivityNinetyDays}}
              </div>
              <div>
                Contract: {{list.contractProductivityNinetyDays}}
              </div>
              <div class="mt-2" style="font-weight: 600; text-decoration:underline;">6 Month Avg</div>
              <div>
                Hours: {{list.averageHoursSixMonths}}
              </div>
              <div>
                Time Card: {{list.timeCardProductivitySixMonths}}
              </div>
              <div>
                Contract: {{list.contractProductivitySixMonths}}
              </div>
            </div>
          </td>
          <td>
            <span class="ui-column-title">Action Items</span>
            <div *ngFor="let a of list.actions">
              <i *ngIf="!a.dateCompleted && canManage" class="fas fa-pencil" style="cursor: pointer;" (click)="editActionItem(a)" title="Edit Action Item"></i>
              <i *ngIf="a.dateCompleted && canManage" class="fa fa-check-circle text-success" style="cursor: pointer;" (click)="editActionItem(a)" title="Edit Action Item"></i>
              <i *ngIf="a.dateCompleted && !canManage" class="fa fa-check-circle text-success" title="Completed"></i>
              <i *ngIf="a.isAssignedToApplicant" class="far fa-user" title="To be completed by applicant"></i>
              {{a.action}}
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</form>

<app-edit-intern-action [isEmployeeView]="false"></app-edit-intern-action>
