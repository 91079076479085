
import { BenefitPlanEllegibilityWaitingPeriodModel, BenefitPlanModel, BenefitPlanTypeModel, BenefitPlanEllegibilityBeginModel, BenefitPlanTerminationUponModel, BenefitPlanFullModel, BenefitPlanDocumentModel, BenefitPlanLinkModel, BenefitPlanCoverageLevelModel, BenefitPlanCostModel, BenefitsDependentModel, BenefitPlanDeclineReasonModel, BenefitPlanEnrollmentSetModel, EmployerContributionModel, BenefitClassModel, BenefitPlanFormDocumentsEnrollmentModel, BenefitsSettingsModel, BenefitStatusCheckModel, BenefitPlanUserWithEnrollmentSetModel, BenefitsLifeChangingEventModel, BenefitPlanDeclinedReportModel, BenefitPlanWithdrawnReportModel, BenefitLogReportModel, BenefitHsaReportModel, CarrierBenefitPlanModel } from './../../../models/benefitsModels';
import { createAction, props } from '@ngrx/store';
import { FormError } from 'src/app/models/utilModels';
import { PagingResultsModel } from 'src/app/models/pagingResultsModel';
import { SelectItem } from 'primeng/api';

export const loadBenefitsPlanInfo = createAction('[Benefits] Load BenefitsPlan info', props<{ benefitPlanID: number }>());
export const loadBenefitsPlanInfoSuccess = createAction('[Benefits] Load BenefitsPlan info Success',
  props<{ benefitPlan: BenefitPlanFullModel }>());
export const loadBenefitsPlanInfoFail = createAction('[Benefits] Load BenefitsPlan info Fail');

export const updateBenefitsPlanForm = createAction('[Benefits] Update BenefitsPlan Form',
  props<{ benefitPlanValues: any; formErrors: FormError[] }>());

export const removeBenefitPlanDocument = createAction('[Benefits] Remove document',
  props<{ document: BenefitPlanDocumentModel }>());
export const addBenefitPlanDocument = createAction('[Benefits] Add document',
  props<{ document: BenefitPlanDocumentModel }>());


export const removeBenefitPlanLink = createAction('[Benefits] Remove link',
  props<{ link: BenefitPlanLinkModel }>());
export const addBenefitPlanLink = createAction('[Benefits] Add link',
  props<{ link: BenefitPlanLinkModel }>());

export const removeBenefitPlanCost = createAction('[Benefits] Remove cost',
  props<{ cost: BenefitPlanCostModel }>());
export const addBenefitPlanCost = createAction('[Benefits] Add cost',
  props<{ cost: BenefitPlanCostModel }>());

export const changedBenefitClasses = createAction('[Benefits] Changed BenefitClasses',
  props<{ classes: BenefitClassModel[] }>());


export const saveBenefitsPlan = createAction('[Benefits] Save BenefitsPlan', props<{ benefitPlan: BenefitPlanFullModel }>());
export const saveBenefitsPlanSuccess = createAction('[Benefits] Save BenefitsPlan Success');
export const saveBenefitsPlanFail = createAction('[Benefits] Save BenefitsPlan Fail');

export const loadBenefitsPlans = createAction('[Benefits] Load Benefits Plans', props<{ filter: any }>());
export const loadBenefitsPlansSuccess = createAction('[Benefits] Load Benefits Plans Success',
  props<{ benefitPlanList: PagingResultsModel<BenefitPlanModel> }>());
export const loadBenefitsPlansFail = createAction('[Benefits] Load Benefits Plans Fail');




export const deleteBenefitsPlans = createAction('[Benefits] Delete BenefitsPlan ',
  props<{ benefitsPlanID: number }>());
export const deleteBenefitsPlansSuccess = createAction('[Benefits]Delete BenefitsPlan  Success');
export const deleteBenefitsPlansFail = createAction('[Benefits] Delete BenefitsPlan  Fail');

export const clearBenefitsPlanInfo = createAction('[Benefits] Clear BenefitsPlan info');

export const loadCarrierBenfitPlans = createAction('[Benefits] Load Carrier Benfit Plans');
export const loadCarrierBenfitPlansSuccess = createAction('[Benefits] Load Carrier Benfit Plans Success',
  props<{ carrierBenefitPlanList: CarrierBenefitPlanModel[] }>());
export const loadCarrierBenfitPlansFail = createAction('[Benefits] Load Carrier Benfit Plans Fail');

export const loadBenfitPlanTypes = createAction('[Benefits] Load Benfit Plan Types');
export const loadBenfitPlanTypesSuccess = createAction('[Benefits] Load Benefits Plan Types Success',
  props<{ benefitPlanTypesList: BenefitPlanTypeModel[] }>());
export const loadBenfitPlanTypesFail = createAction('[Benefits] Load Benefits Plan Types Fail');

export const loadElegibilityWaitingPeriods = createAction('[Benefits] Load ElegibilityWaitingPeriods ');
export const loadElegibilityWaitingPeriodsSuccess = createAction('[Benefits] Load ElegibilityWaitingPeriods Success',
  props<{ ellegibilityWaitingPeriodsList: BenefitPlanEllegibilityWaitingPeriodModel[] }>());
export const loadElegibilityWaitingPeriodsFail = createAction('[Benefits] Load ElegibilityWaitingPeriods Fail');

export const loadElegibilityBegins = createAction('[Benefits] ElegibilityBegins ');
export const loadElegibilityBeginsSuccess = createAction('[Benefits] Load ElegibilityBegins Success',
  props<{ ellegibilityBeginsList: BenefitPlanEllegibilityBeginModel[] }>());
export const loadElegibilityBeginsFail = createAction('[Benefits] Load ElegibilityBegins Fail');

export const loadTerminationUpons = createAction('[Benefits] TerminationUpons ');
export const loadTerminationUponsSuccess = createAction('[Benefits] Load TerminationUpons Success',
  props<{ terminationUponsList: BenefitPlanTerminationUponModel[] }>());
export const loadTerminationUponsFail = createAction('[Benefits] Load TerminationUpons Fail');

export const loadCoverageLevels = createAction('[Benefits] CoverageLevels ');
export const loadCoverageLevelsSuccess = createAction('[Benefits] Load CoverageLevels Success',
  props<{ coverageLevelList: BenefitPlanCoverageLevelModel[] }>());
export const loadCoverageLevelsFail = createAction('[Benefits] Load CoverageLevels Fail');

export const loadBenefitsDependents = createAction('[Benefits] Load BenefitsDependents');
export const loadBenefitsDependentsSuccess = createAction('[Benefits] Load BenefitsDependents Success',
  props<{ benefitsDependents: BenefitsDependentModel[] }>());
export const loadBenefitsDependentsFail = createAction('[Benefits] Load BenefitsDependents Fail');

export const saveBenefitsDependent = createAction('[Benefits] Save BenefitsDependent',
  props<{ benefitsDependent: BenefitsDependentModel }>());
export const saveBenefitsDependentSuccess = createAction('[Benefits] Save BenefitsDependent Success',
  props<{ benefitsDependent: BenefitsDependentModel }>());
export const saveBenefitsDependentFail = createAction('[Benefits] Save BenefitsDependent Fail');


export const loadBenefitsDependentInfo = createAction('[Benefits] Load BenefitsDependentInfo',
  props<{ benefitsDependentId: number }>());
export const loadBenefitsDependentInfoSuccess = createAction('[Benefits] Load BenefitsDependentInfo Success',
  props<{ benefitsDependentInfo: BenefitsDependentModel }>());
export const loadBenefitsDependentInfoFail = createAction('[Benefits] Load BenefitsDependentInfo Fail');


export const updateBenefitsDependentForm = createAction('[Benefits] Update BenefitsDependent Form',
  props<{ benefitsDependentValues: any; formErrors: FormError[] }>());


export const deleteBenefitsDependent = createAction('[Benefits] Delete BenefitsDependent',
  props<{ benefitsDependentId: number }>());
export const deleteBenefitsDependentSuccess = createAction('[Benefits] Delete BenefitsDependent Success');
export const deleteBenefitsDependentFail = createAction('[Benefits] Delete BenefitsDependent Fail');



export const loadAvailableBenefitPlans = createAction('[Benefits] Load Available Benefit Plans');
export const loadAvailableBenefitPlansSuccess = createAction('[Benefits] Load Available Benefit Plans Success',
  props<{ availableBenefitPlans: BenefitPlanFullModel[] }>());
export const loadAvailableBenefitPlansFail = createAction('[Benefits] Load Available Benefit Plans Fail');


export const loadBenefitsDeclineReasons = createAction('[Benefits] Load BenefitsDeclineReasons');
export const loadBenefitsDeclineReasonsSuccess = createAction('[Benefits] Load BenefitsDeclineReasons Success',
  props<{ benefitDeclineReasons: BenefitPlanDeclineReasonModel[] }>());
export const loadBenefitsDeclineReasonsFail = createAction('[Benefits] Load BenefitsDeclineReasons Fail');

export const saveBenefitsEnrollment = createAction('[Benefits] Save BenefitsEnrollment',
  props<{ enrollmentSet: BenefitPlanEnrollmentSetModel }>());
export const saveBenefitsEnrollmentSuccess = createAction('[Benefits] Save BenefitsEnrollment Success',
  props<{ enrollmentSet: BenefitPlanEnrollmentSetModel }>());
export const saveBenefitsEnrollmentFail = createAction('[Benefits] Save BenefitsEnrollment Fail');



export const loadBenefitPlanEnrollmentSetList = createAction('[Benefits] Load BenefitPlanEnrollmentSetList');
export const loadBenefitPlanEnrollmentSetListSuccess = createAction('[Benefits] Load BenefitPlanEnrollmentSetList Success',
  props<{ benefitPlanEnrollmentSetList: BenefitPlanEnrollmentSetModel[] }>());
export const loadBenefitPlanEnrollmentSetListFail = createAction('[Benefits] Load BenefitPlanEnrollmentSetList Fail');


export const loadEmployerContribution = createAction('[Benefits] Load EmployerContribution');
export const loadEmployerContributionSuccess = createAction('[Benefits] Load EmployerContribution Success',
  props<{ employerContribution: EmployerContributionModel }>());
export const loadEmployerContributionFail = createAction('[Benefits] Load EmployerContribution Fail');


export const loadBenefitClasses = createAction('[Benefits] Load BenefitClasses');
export const loadBenefitClassesSuccess = createAction('[Benefits] Load BenefitClasses Success',
  props<{ benefitClasses: BenefitClassModel[] }>());
export const loadBenefitClassesFail = createAction('[Benefits] Load BenefitClasses Fail');

export const saveBenefitClass = createAction('[Benefits] Save BenefitClass',
  props<{ benefitClass: BenefitClassModel }>());
export const saveBenefitClassSuccess = createAction('[Benefits] Save BenefitClass Success',
  props<{ benefitClass: BenefitClassModel }>());
export const saveBenefitClassFail = createAction('[Benefits] Save BenefitClass Fail');

export const deleteBenefitClass = createAction('[Benefits] Delete BenefitClass',
  props<{ benefitClassId: number }>());
export const deleteBenefitClassSuccess = createAction('[Benefits] Delete BenefitClass Success');
export const deleteBenefitClassFail = createAction('[Benefits] Delete BenefitClass Fail');

export const updateBenefitClassForm = createAction('[Benefits] Update BenefitClass Form',
  props<{ benefitClassValues: any; formErrors: FormError[] }>());



export const loadBenefitsFormDocument = createAction('[Benefits Docs] Load Benefits Docs Doc',
  props<{ benefitPlanFormDocumentsEnrollmentID: number }>());
export const loadBenefitsFormDocumentSuccess = createAction('[Benefits Docs] Load Benefits Docs Doc Success',
  props<{ doc: ArrayBuffer }>());

export const signBenefitsFormDocument = createAction('[Benefits Docs] Sign Benefits Docs Doc',
  props<{ benefitPlanFormDocumentsEnrollmentID: number, signedFields: string[] }>());
export const signBenefitsFormDocumentSuccess = createAction('[Benefits Docs]  Sign Benefits Docs Doc Success',
  props<{ docSigned: ArrayBuffer }>());
export const signBenefitsFormDocumentFailure = createAction('[Benefits Docs]  Sign Benefits Docs Failure');


export const getBenefitsFormDocumentsList = createAction('[Benefits Docs] Get Benefits Docs list',
  props<{ userId?: number }>());
export const getBenefitsFormDocumentsListSuccess = createAction('[Benefits Docs] Get Benefits Docs list Success',
  props<{ docs: BenefitPlanFormDocumentsEnrollmentModel[] }>());

export const loadBenefitsFormDocumentInfo = createAction('[Benefits Docs] Load Benefits Docs Info',
  props<{ benefitPlanFormDocumentsEnrollmentID: number }>());
export const loadBenefitsFormDocumentInfoSuccess = createAction('[Benefits Docs] Load Benefits Docs Info Success',
  props<{ doc: BenefitPlanFormDocumentsEnrollmentModel }>());



export const loadBenefitsSetDocument = createAction('[Benefits Docs] Load Benefits Set Doc',
props<{ benefitPlanEnrollmentSetID: number }>());
export const loadBenefitsSetDocumentSuccess = createAction('[Benefits Docs] Load Benefits Set Doc Success',
props<{ doc: ArrayBuffer }>());

export const signBenefitsSetDocument = createAction('[Benefits Docs] Sign Benefits Set Doc',
props<{ benefitPlanEnrollmentSetID: number, signedFields: string[] }>());
export const signBenefitsSetDocumentSuccess = createAction('[Benefits Docs]  Sign Benefits Set Doc Success',
props<{ docSigned: ArrayBuffer }>());
export const signBenefitsSetDocumentFailure = createAction('[Benefits Docs]  Sign Benefits Set Doc Failure');



export const loadAllBenefitPlans = createAction('[Benefits] Load All Benefits Plans');
export const loadAllBenefitPlansSuccess = createAction('[Benefits] Load All Benefits Plans Success',
  props<{ allBenefitPlans: BenefitPlanModel[] }>());

export const loadBenefitsSettings = createAction('[Benefits] Load Benefits Settings');
export const loadBenefitsSettingsSuccess = createAction('[Benefits] Load Benefits Settings Success',
  props<{ benefitsSettings: BenefitsSettingsModel }>());
export const loadBenefitsSettingsFailure = createAction('[Benefits]  Load Benefits Settings Failure');

export const getBenefitStatusQueue = createAction('[Benefits] Get Benefit Status Queue',
  props<{ filter: any }>());
export const getBenefitStatusQueueSuccess = createAction('[Benefits] Get Benefit Status Queue Success',
  props<{ queue: PagingResultsModel<BenefitStatusCheckModel> }>());

export const verifyBenefitCheck = createAction('[Benefits] Verify Benefit Status Check',
  props<{ check: BenefitStatusCheckModel }>());
export const verifyBenefitCheckSuccess = createAction('[Benefits] Verify Benefit Status Check Success');

export const switchBenefitStatus = createAction('[Benefits] Switch Benefit Status',
  props<{ check: BenefitStatusCheckModel }>());
export const switchBenefitStatusSuccess = createAction('[Benefits] Switch Benefit Status Success');

export const markAsExemptBenefit = createAction('[Benefits] Mark Benefit Status Check As Exempt',
  props<{ check: BenefitStatusCheckModel }>());
export const markAsExemptBenefitSuccess = createAction('[Benefits] Mark Benefit Status Check As Exempt Success');

export const loadBenefitLogTypes = createAction('[Benefits] Load Benefit Log Types');
export const loadBenefitLogTypesSuccess = createAction('[Benefits] Load Benefit Log Types Success',
  props<{ types: SelectItem[] }>());

export const getBenefitLogList = createAction('[Benefits] Get Benefit Log List',
  props<{ filter: any }>());
export const getBenefitLogListSuccess = createAction('[Benefits] Get Benefit Log List Success',
  props<{ list: PagingResultsModel<BenefitLogReportModel> }>());

export const getBenefitHsaReport = createAction('[Benefits] Get Benefit HSA Report',
  props<{ filter: any }>());
export const getBenefitHsaReportSuccess = createAction('[Benefits] Get Benefit HSA Report Success',
  props<{ list: PagingResultsModel<BenefitHsaReportModel> }>());

export const loadWithdrawnReasons = createAction('[Benefits] Load Withdrawn Reasons');
export const loadWithdrawnReasonsSuccess = createAction('[Benefits] Load Withdrawn Reasons Success',
  props<{ reasons: SelectItem[] }>());

export const getBenefitWithdrawnList = createAction('[Benefits] Get Benefit Withdrawn List',
  props<{ filter: any }>());
export const getBenefitWithdrawnListSuccess = createAction('[Benefits] Get Benefit Withdrawn List Success',
  props<{ list: PagingResultsModel<BenefitPlanWithdrawnReportModel> }>());

export const getBenefitDeclinedList = createAction('[Benefits] Get Benefit Declined List',
  props<{ filter: any }>());
export const getBenefitDeclinedListSuccess = createAction('[Benefits] Get Benefit Declined List Success',
  props<{ list: PagingResultsModel<BenefitPlanDeclinedReportModel> }>());

export const getBenefitStatusList = createAction('[Benefits] Get Benefit Status List',
  props<{ filter: any }>());
export const getBenefitStatusListSuccess = createAction('[Benefits] Get Benefit Status List Success',
  props<{ list: PagingResultsModel<BenefitStatusCheckModel> }>());

export const downloadEnrollmentSignedForms = createAction('[Benefits] Download Enrollment Signed Forms',
  props<{ benefitPlanId: number }>());
export const downloadEnrollmentSignedFormsSuccess = createAction('[Benefits] Download Enrollment Signed Forms Success',
  props<{ doc: ArrayBuffer }>());
export const downloadEnrollmentSignedFormsFailure = createAction('[Benefits] Download Enrollment Signed Forms Failure');

export const exportEnrollmentListToExcel = createAction('[Benefits] Export Enrollment List To Excel',
  props<{ filter: any }>());
export const exportEnrollmentListToExcelSuccess = createAction('[Benefits] Export Enrollment List To Excel Success',
  props<{ doc: ArrayBuffer }>());
export const exportEnrollmentListToExcelFailure = createAction('[Benefits] Export Enrollment List To Excel Failure');

export const exportAllDentalSelectEnrollmentListToExcel = createAction('[Benefits] Export All Dental Select Enrollment List To Excel',
  props<{ filter: any }>());
export const exportAllDentalSelectEnrollmentListToExcelSuccess = createAction('[Benefits] Export All Dental Select Enrollment List To Excel Success',
  props<{ doc: ArrayBuffer }>());
export const exportAllDentalSelectEnrollmentListToExcelFailure = createAction('[Benefits] Export All Dental Select Enrollment List To Excel Failure');

export const exportEmployeeBenefitStatusListToExcel = createAction('[Benefits] Export Employee Benefit Status List To Excel',
  props<{ filter: any }>());
export const exportEmployeeBenefitStatusListToExcelSuccess = createAction('[Benefits] Export Employee Benefit Status List To Excel Success',
  props<{ doc: ArrayBuffer }>());
export const exportEmployeeBenefitStatusListToExcelFailure = createAction('[Benefits] Export Employee Benefit Status List To Excel Failure');

export const exportBenefitHsaReportToExcel = createAction('[Benefits] Export Benefit Hsa Report To Excel',
  props<{ filter: any }>());
export const exportBenefitHsaReportToExcelSuccess = createAction('[Benefits] Export Benefit Hsa Report To Excel Success',
  props<{ doc: ArrayBuffer }>());
export const exportBenefitHsaReportToExcelFailure = createAction('[Benefits] Export Benefit Hsa Report To Excel Failure');

export const exportBenefitWithdrawnToExcel = createAction('[Benefits] Export Benefit Withdrawn List To Excel',
  props<{ filter: any }>());
export const exportBenefitWithdrawnToExcelSuccess = createAction('[Benefits] Export Benefit Withdrawn List To Excel Success',
  props<{ doc: ArrayBuffer }>());
export const exportBenefitWithdrawnToExcelFailure = createAction('[Benefits] Export Benefit Withdrawn List To Excel Failure');

export const downloadEnrollmentSetSignedForms = createAction('[Benefits] Download Enrollment Set Signed Forms',
  props<{ enrollmentSetId: number }>());
export const downloadEnrollmentSetSignedFormsSuccess = createAction('[Benefits] Download Enrollment Set Signed Forms Success',
  props<{ doc: ArrayBuffer }>());
export const downloadEnrollmentSetSignedFormsFailure = createAction('[Benefits] Download Enrollment Set Signed Forms Failure');

export const loadBenefitPlanUserWithEnrollmentSet = createAction('[Benefits] Load BenefitPlanUserWithEnrollmentSet',
  props<{ filter: any }>());
export const loadBenefitPlanUserWithEnrollmentSetSuccess = createAction('[Benefits] Load BenefitPlanUserWithEnrollmentSet Success',
  props<{ benefitPlanUserWithEnrollmentSetList: PagingResultsModel<BenefitPlanUserWithEnrollmentSetModel> }>());
export const loadBenefitPlanUserWithEnrollmentSetFail = createAction('[Benefits] Load BenefitPlanUserWithEnrollmentSet Fail');


export const loadHelpGuide = createAction('[Benefits] Load HelpGuide');
export const loadHelpGuideSuccess = createAction('[Benefits] Load HelpGuide Success',
  props<{ doc: ArrayBuffer }>());

export const loadBenefitsLifeChangingEvents = createAction('[Benefits] Load BenefitsLifeChangingEvents');
export const loadBenefitsLifeChangingEventsSuccess = createAction('[Benefits] Load BenefitsLifeChangingEvents Success',
  props<{ benefitsLifeChangingEvents: BenefitsLifeChangingEventModel[] }>());
export const loadBenefitsLifeChangingEventsFail = createAction('[Benefits] Load BenefitsLifeChangingEvents Fail');


export const startChangingEnrollment = createAction('[Benefits] Save startChangingEnrollment',
  props<{ lifeChangingEventID?: number, lifeChangingEventOther?: string, lifeChangingEventDate?: string, enrollmentYear?: number, }>());
export const startChangingEnrollmentSuccess = createAction('[Benefits] Save startChangingEnrollment Success',
  props<{ benefitPlanEnrollmentSet: BenefitPlanEnrollmentSetModel }>());
export const startChangingEnrollmentFail = createAction('[Benefits] Save startChangingEnrollment Fail');

export const cancelChangingEnrollment = createAction('[Benefits] Save cancelChangingEnrollment',
  props<{ enrollmentYear?: number, }>());
export const cancelChangingEnrollmentSuccess = createAction('[Benefits] Save cancelChangingEnrollment Success',
  props<{ benefitPlanEnrollmentSet: BenefitPlanEnrollmentSetModel }>());
export const cancelChangingEnrollmentFail = createAction('[Benefits] Save cancelChangingEnrollment Fail');


export const exportManageEmployeesListToExcel = createAction('[Benefits] Export ManageEmployeesListToExcel',
  props<{ filter: any }>());
export const exportManageEmployeesListToExcelSuccess = createAction('[Benefits] Export ManageEmployeesListToExcel Success',
  props<{ doc: ArrayBuffer }>());
export const exportManageEmployeesListToExcelFailure = createAction('[Benefits] Export ManageEmployeesListToExcel Failure');


export const saveEmployeeEnrollmentSet = createAction('[Benefits] Save SaveEmployeeEnrollmentSet',
  props<{ enrollmentSet: BenefitPlanEnrollmentSetModel }>());
export const saveEmployeeEnrollmentSetSuccess = createAction('[Benefits] Save SaveEmployeeEnrollmentSet Success',
  props<{ enrollmentSet: BenefitPlanEnrollmentSetModel }>());
export const saveEmployeeEnrollmentSetFail = createAction('[Benefits] Save SaveEmployeeEnrollmentSet Fail');


