<div class="card">
  <div class="card-body">
    <h5 class="card-title">Sign Equipment Return</h5>
    <div class="row">
      <div class="col-12 col-sm-4">
          Go through the whole documentation and click the Sign buttons to add your signature to all places you want to sign.
          Red Sign buttons means the signature is required and yellow Sign buttons means it is optional.
          When you are done signing it click the button bellow to finish the process.
          <br /><br />
          Signing as<br />
              {{ loggedUserName }}<br />
              {{ loggedUserEmail }}<br />
              <button class="btn btn-primary mt-3" [disabled]="loadingSign || finishSignDisabled" (click)="sign()">Finish Signing</button>
              <i *ngIf="loadingSign" class="material-icons rotate text-muted ml-2">loop</i>
      </div>
      <div class="col-12 col-sm-8">

<div style="width: 100%; height: 70vh">
  <div *ngIf="loadingDoc" class="button-loading" style="width: 50px; height: 50px"></div>
<ng2-pdfjs-viewer #pdfViewer *ngIf="url" [pdfSrc]="url"
[print]="false"
[download]="false"
[fullScreen]="false"
[viewBookmark]="false"
[openFile]="false"
></ng2-pdfjs-viewer>
</div>
      </div>
    </div>
  </div>
</div>
