import { PagingResultsModel } from '../../models/pagingResultsModel';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { deepClone } from 'src/app/helpers/utils';
import {
  EmployeeReviewTemplateCategoryModel, EmployeeReviewTemplateKPIModel, EmployeeReviewTemplateKRAModel,
  EmployeeReviewTemplateMeasureableModel, EmployeeReviewTemplateModel,
  EmployeeReviewTemplateDraft,
  EmployeeReviewTemplateValueColorModel,
  EmployeeReviewTemplateScoreType,
  EmployeeReviewTemplateScoreOptionsSet
} from '../../models/employeeReviewTemplateModels';


@Injectable()
export class EmployeeReviewTemplateService {

  constructor(private http: HttpClient) {
  }
  getTemplates(filter: any)
    : Observable<PagingResultsModel<EmployeeReviewTemplateModel>> {
    if (!filter) {
      filter = {};
      filter.filters = {};
    }
    return this.http.post<PagingResultsModel<EmployeeReviewTemplateModel>>(`${environment.apiServer.webApiURL}/EmployeeReview/GetTemplates`,
      filter)
  }

  getCategories()
    : Observable<EmployeeReviewTemplateCategoryModel[]> {

    return this.http.get<EmployeeReviewTemplateCategoryModel[]>(`${environment.apiServer.webApiURL}/EmployeeReview/GetCategories`);
  }
  getTemplate(templateId: number)
    : Observable<EmployeeReviewTemplateModel> {

    return this.http.get<EmployeeReviewTemplateModel>(`${environment.apiServer.webApiURL}/EmployeeReview/GetTemplate/${templateId}`);
  }

  saveTemplate(template: EmployeeReviewTemplateModel): Observable<EmployeeReviewTemplateModel> {
    return this.http.post<EmployeeReviewTemplateModel>(`${environment.apiServer.webApiURL}/EmployeeReview/PostTemplate`,
      template);
  }

  findTemplateDraft(templateId?: number)
    : Observable<EmployeeReviewTemplateDraft> {
    if (!templateId)
      templateId = 0;
    return this.http.get<EmployeeReviewTemplateDraft>(`${environment.apiServer.webApiURL}/EmployeeReview/Template/Draft/Find/${templateId}`);
  }
  deleteTemplateDraft(draftId: number)
    : Observable<any> {
    return this.http.delete<any>(`${environment.apiServer.webApiURL}/EmployeeReview/Template/Draft/${draftId}`);
  }

  saveTemplateDraft(template: EmployeeReviewTemplateModel): Observable<number>{
    return this.http.post<number>(`${environment.apiServer.webApiURL}/EmployeeReview/Template/Draft/`,
       template
    );
  }

  saveCategory(template: EmployeeReviewTemplateCategoryModel): Observable<EmployeeReviewTemplateCategoryModel> {
    return this.http.post<EmployeeReviewTemplateCategoryModel>(`${environment.apiServer.webApiURL}/EmployeeReview/PostCategory`,
      template);
  }

  getValueColors(): Observable<EmployeeReviewTemplateValueColorModel[]> {
    return this.http.get<EmployeeReviewTemplateValueColorModel[]>(`${environment.apiServer.webApiURL}/EmployeeReview/ValueColors`);
  }

  saveValueColor(valueColor: EmployeeReviewTemplateValueColorModel): Observable<EmployeeReviewTemplateValueColorModel> {
    return this.http.post<EmployeeReviewTemplateValueColorModel>(`${environment.apiServer.webApiURL}/EmployeeReview/ValueColor`,
    valueColor);
  }

  deleteValueColor(valueColorID: number)
    : Observable<any> {
    return this.http.delete<any>(`${environment.apiServer.webApiURL}/EmployeeReview/ValueColor/${valueColorID}`);
  }

  getScoreTypes(): Observable<EmployeeReviewTemplateScoreType[]> {
    return this.http.get<EmployeeReviewTemplateScoreType[]>(`${environment.apiServer.webApiURL}/EmployeeReview/ScoreTypes`);
  }

  getScoreOptionsSets(): Observable<EmployeeReviewTemplateScoreOptionsSet[]> {
    return this.http.get<EmployeeReviewTemplateScoreOptionsSet[]>(`${environment.apiServer.webApiURL}/EmployeeReview/ScoreOptionsSets`);
  }

  toggleTemplateArchived(templateId: number): Observable<any> {
    return this.http.post<any>(`${environment.apiServer.webApiURL}/EmployeeReview/ToggleTemplateArchived/${templateId}/`, { templateId: templateId });
  }
}

