import { SelectItem } from 'primeng/api';
import { manageSelfcareStateReducer, ManageSelfcareState, initSelfcareCategoryForm, initSelfcarePlannerSectionForm } from './../../../../../core/store/manage-selfcare/manage-selfcare.reducer';
import { SelfCareQuestionModel, SelfCarePlannerSectionModel, SelfCarePlannerSectionItemModel } from './../../../../../models/selfcareModels';
import { SelfCareCategoryModel } from 'src/app/models/selfcareModels';
import { loadCampuses } from 'src/app/core/store/payroll-approval/payroll-approval.actions';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../../core/store';
import { EmailValidator, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { markFormGroupTouched, getAllControlsErrors, updateFormGroupValidity, deepClone } from 'src/app/helpers/utils';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { ActivatedRoute } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { AlertService } from 'src/app/shared/services/alert.service';
import { clearManageSelfcarePlannerSection, loadManageSelfcarePlanner, loadManageSelfcarePlannerSection, saveManageSelfcarePlannerSection, saveManageSelfcarePlannerSectionSuccess, updateManageSelfcarePlannerSectionForm } from 'src/app/core/store/manage-selfcare/manage-selfcare.actions';

@Component({
  selector: 'app-selfcare-manage-plannersection',
  templateUrl: './selfcare-manage-plannersection.component.html',
  styleUrls: ['./selfcare-manage-plannersection.component.scss']
})
export class SelfcareManagePlannerSectionComponent extends BaseComponent implements OnInit, OnDestroy {

  plannerSection: SelfCarePlannerSectionModel;
  form: FormGroup;
  itemForm: FormGroup;
  plannerSectionID: number;
  items: SelfCarePlannerSectionItemModel[] = [];
  itemsToAdd: SelfCarePlannerSectionItemModel = {} as SelfCarePlannerSectionItemModel;
  openAddItem;

  layoutOptions: SelectItem[] = [
    { label: '1 - One big panel', value: 1 },
    { label: '2 - Half size panel', value: 2 },
    { label: '3 - With 2 sub-items horizontally', value: 3 },
    { label: '4 - With several sub-items vertically', value: 4 },
  ];
  loading = false;

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private route: ActivatedRoute,
    private alertService: AlertService) {
    super();
  }

  ngOnInit() {
    this.store.dispatch(clearManageSelfcarePlannerSection());
    this.route.params.subscribe(params => {
      if (params.plannerSectionID) {
        this.plannerSectionID = params.plannerSectionID;
        this.store.dispatch(loadManageSelfcarePlannerSection({ plannerSectionID: params.plannerSectionID }));
      } else {
        this.store.dispatch(clearManageSelfcarePlannerSection());
        this.plannerSection = {} as SelfCarePlannerSectionModel;
        this.initForm();
      }
    });
    this.subs.push(
      this.store.select(s => s.manageSelfcareState.plannerSection).subscribe(plannerSection => {
        if (plannerSection) {
          this.plannerSection = deepClone(plannerSection);
          this.items = this.plannerSection.items ? this.plannerSection.items : [];
          if (!this.form) {
            this.initForm();
          }
        }
      }),
      this.store.select(s => s.manageSelfcareState.loading).subscribe(loading => {
        this.loading = loading;
      }),

      this.actions$.pipe(
        ofType(saveManageSelfcarePlannerSectionSuccess),
        tap(action => {
          this.alertService.success('Planner Section Saved');
          this.form = null;
          this.store.dispatch(loadManageSelfcarePlannerSection({ plannerSectionID: this.plannerSectionID }));
        })
      ).subscribe(),
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.store.dispatch(clearManageSelfcarePlannerSection());
  }

  initForm() {
    this.form = initSelfcarePlannerSectionForm(this.plannerSection);
    this.subs.push(
      this.form.valueChanges.subscribe(formValues => {
        const formErrors = getAllControlsErrors(this.form);
        const plannerSectionValues = this.form.get('plannerSection').value;
        const itemsValues = this.form.get('items').value;
        this.store.dispatch(
          updateManageSelfcarePlannerSectionForm({ plannerSectionValues: plannerSectionValues, itemsValues: itemsValues, formErrors }));
      }));
  }

  save() {
    updateFormGroupValidity(this.form);
    if (this.form.valid) {
      this.store.dispatch(saveManageSelfcarePlannerSection({ plannerSection: this.plannerSection }));
    } else {
      markFormGroupTouched(this.form);
    }
  }

  removeItem(index: number) {
    const itemsArray: FormArray = this.form.get('items') as FormArray;
    itemsArray.removeAt(index);
  }


  addItem() {
    const itemsArray: FormArray = this.form.get('items') as FormArray;
    itemsArray.push(new FormGroup({
      plannerSectionItemID: new FormControl(null),
      sectionItem: new FormControl('', [Validators.maxLength(2000)]),
      orderNr: new FormControl(null, [Validators.required]),
    }));
  }

  changedColor(event) {
    this.form.get('plannerSection.mainColor').setValue(event.value);
  }

  changedColorInput(event) {
    this.form.get('plannerSection.mainColor').setValue(event.target.value);
  }

  getControls(control) {
    return control.controls;
  }
}
