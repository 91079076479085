<p-dialog [(visible)]="display" header="{{title}}" [modal]="true" [style]="{width: '70vw'}" (onHide)="closeModal()" [focusOnShow]="false">
  <form class="card mt-2" [formGroup]="form" (ngSubmit)="saveInstructorEdits()">
    <div class="card-body" style="min-height: 200px;">
      <div class="row">
        <div class="col-12">

          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Employee</b>
            </div>
            <div class="col-8">
              <p-autoComplete [style]="{'width': '100%'}" [inputStyle]="{'width': '100%'}" [(ngModel)]="selectedUser"
                              [suggestions]="userEmployeeList" (completeMethod)="getStaffList($event)" field="label"
                              [ngModelOptions]="{standalone: true}" [minLength]="1" [autoHighlight]="true"
                              (onSelect)="setUserAfterStaffChange($event)" [forceSelection]="true"
                              (onClear)="clearUserAfterClear($event)" [disabled]="!hideDelete"></p-autoComplete>
              <app-validation-message [form]="form" [path]="'userId'" [label]="'Employee'"></app-validation-message>
            </div>
          </div>

          <div class="row">
            <div class="col-4 d-flex align-items-center">              
            </div>
            <div class="col-8">
              <p-checkbox [(ngModel)]="showOnlyActiveEmployees" [binary]="true" [ngModelOptions]="{standalone: true}"></p-checkbox>
              <b style="margin-left: 2px;">Show only active employees</b>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-4 d-flex align-items-center">
              <b>Certification Type</b>
            </div>
            <div class="col-8">
              <i *ngIf="!certificationTypes" class="material-icons rotate text-muted">loop</i>
              <p-dropdown styleClass="form-control mt-2" [options]="certificationTypes" formControlName="certificationTypeId" [disabled]="!hideDelete"></p-dropdown>
              <app-validation-message [form]="form" [path]="'certificationTypeId'" [label]="'Certification Type'"></app-validation-message>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-4 d-flex align-items-center">
              Archived
            </div>
            <div class="col-8">
              <p-checkbox formControlName="archived" [binary]="true"></p-checkbox>              
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <p-footer>
    <button class="btn btn-danger" type="button" (click)="deleteInstructor()" [disabled]="disableButtons" [hidden]="hideDelete">Delete</button>
    <button class="btn btn-secondary" type="button" (click)="closeModal()">Cancel</button>
    <button class="btn btn-primary" type="submit" (click)="saveInstructorEdits()" [disabled]="disableButtons">Save</button>
  </p-footer>
</p-dialog>
