<p-dialog #approvalDialog [(visible)]="display" header="{{title}}" [modal]="true" [draggable]="true" [maximizable]="true"
          [style]="{width: '70vw'}" (onHide)="closeModal()" [focusOnShow]="false" [resizable]="true">
  <i [hidden]="!loadingData" class="material-icons rotate text-muted">loop</i>
  <form class="card mt-2" [formGroup]="form" (ngSubmit)="saveTimeOffApproval(true)">
    <div class="card-body">
      <div class="row">
        <div class="col-12">

          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Employee</b>
            </div>
            <div class="col-8">
              {{approvalSummary.timeOff?.employeeName}}
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-4 d-flex align-items-center">
              <b>Date Range</b>
            </div>
            <div class="col-8" *ngIf="approvalSummary.timeOff">
              {{approvalSummary.timeOff?.startDate | date: 'MM/dd/yyyy' || ''}} - {{approvalSummary.timeOff?.endDate | date: 'MM/dd/yyyy' || ''}}
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-4 d-flex align-items-center">
              <b>Time Range</b>
            </div>
            <div class="col-8">
              <span *ngIf="approvalSummary.timeOff?.allDay === true">All Day</span>
              <span *ngIf="approvalSummary.timeOff?.allDay === false">{{approvalSummary.timeOff?.startTime | date: 'shortTime' || ''}} - {{approvalSummary.timeOff?.endTime | date: 'shortTime' || ''}}</span>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-4 d-flex align-items-center">
              <b>Comments</b>
            </div>
            <div class="col-8">              
              {{approvalSummary.timeOff?.comments}}
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-4 d-flex align-items-center">
              <b>Approver Comments</b>
            </div>
            <div class="col-8">
              <textarea style="width: 100%;" class="form-control mt-2" formControlName="approverComments"></textarea>
              <app-validation-message [form]="form" [path]="'approverComments'" [label]="'Approver Comments'"></app-validation-message>
            </div>
          </div>

        </div>
      </div>
    </div>
  </form>
  
  <div class="card mt-2">
    <span style="font-weight: bold; font-size: large; padding-left: 1.25rem; padding-top: 0.5rem;">Staff Time Off</span>
    <div class="card-body">
      <div class="row">
        <div class="col-12">

          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Requests from last 6 months</b>
            </div>
            <div class="col-8" (click)="toggleShowRecentRequests()" style="cursor: pointer;">
              <span title="Pending/Approved/Denied" *ngIf="approvalSummary.pastRequests">
                {{approvalSummary.pastRequests?.numberRequests}} Requests - {{approvalSummary.pastRequests?.totalDays}} Total Days -
                {{approvalSummary.pastRequests?.pendingDays}}/{{approvalSummary.pastRequests?.approvedDays}}/{{approvalSummary.pastRequests?.deniedDays}}
              </span>
              <div *ngIf="showRecentRequests">
                <span *ngIf="approvalSummary.pastRequests?.totalDays < 1" style="font-weight: bold; font-style: italic;">No Requests</span>
                <p-table *ngIf="approvalSummary.pastRequests?.totalDays > 0" [value]="approvalSummary.pastRequests?.timeOffRequests" [responsive]="true" [paginator]="true" [rows]="20"
                         [totalRecords]="approvalSummary.pastRequests?.numberRequests">
                  <ng-template pTemplate="header">
                    <tr>
                      <th>Date Range</th>
                      <th>Time Range</th>
                      <th>Status</th>
                      <th>Total Days</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-item>
                    <tr>
                      <td>
                        <span class="ui-column-title">Date Range</span>
                        {{item.startDate | date: 'MM/dd/yyyy'}} - {{item.endDate | date: 'MM/dd/yyyy'}}
                      </td>
                      <td>
                        <span class="ui-column-title">Time Range</span>
                        {{item.timeRange}}
                      </td>
                      <td>
                        <span class="ui-column-title">Status</span>
                        {{item.status}}
                      </td>
                      <td>
                        {{item.totalDays}}
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-4 d-flex align-items-center">
              <b>Upcoming Requests</b>
            </div>
            <div class="col-8" (click)="toggleShowUpcomingRequests()" style="cursor: pointer;">
              <span title="Pending/Approved/Denied" *ngIf="approvalSummary.upcomingRequests">
                {{approvalSummary.upcomingRequests?.numberRequests}} Requests - {{approvalSummary.upcomingRequests?.totalDays}} Total Days -
                {{approvalSummary.upcomingRequests?.pendingDays}}/{{approvalSummary.upcomingRequests?.approvedDays}}/{{approvalSummary.upcomingRequests?.deniedDays}}
              </span>
              <div *ngIf="showUpcomingRequests">
                <span *ngIf="approvalSummary.upcomingRequests?.totalDays < 1" style="font-weight: bold; font-style: italic;">No Requests</span>
                <p-table *ngIf="approvalSummary.upcomingRequests?.totalDays > 0" [value]="approvalSummary.upcomingRequests?.timeOffRequests" [responsive]="true" [paginator]="true" [rows]="20"
                         [totalRecords]="approvalSummary.upcomingRequests?.numberRequests">
                  <ng-template pTemplate="header">
                    <tr>
                      <th>Date Range</th>
                      <th>Time Range</th>
                      <th>Status</th>
                      <th>Total Days</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-item>
                    <tr>
                      <td>
                        <span class="ui-column-title">Date Range</span>
                        {{item.startDate | date: 'MM/dd/yyyy'}} - {{item.endDate | date: 'MM/dd/yyyy'}}
                      </td>
                      <td>
                        <span class="ui-column-title">Time Range</span>
                        {{item.timeRange}}
                      </td>
                      <td>
                        <span class="ui-column-title">Status</span>
                        {{item.status}}
                      </td>
                      <td>
                        {{item.totalDays}}
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-4 d-flex align-items-center">
              <b>Other Staff Requests {{approvalSummary.otherStaffTimeOffModel?.programName}}</b>
            </div>
            <div class="col-8">
              <span title="Pending/Approved/Denied" *ngIf="approvalSummary.otherStaffTimeOffModel" (click)="toggleShowOtherRequests" style="cursor: pointer;">
                {{approvalSummary.otherStaffTimeOffModel?.numberRequests}} Requests -
                {{approvalSummary.otherStaffTimeOffModel?.numberPending}}/{{approvalSummary.otherStaffTimeOffModel?.numberApproved}}/{{approvalSummary.otherStaffTimeOffModel?.numberDenied}}
              </span>
              <div *ngIf="showOtherRequests">
                <span *ngIf="approvalSummary.otherStaffTimeOffModel?.numberRequests < 1" style="font-weight: bold; font-style: italic;">No Requests</span>
                <p-table *ngIf="approvalSummary.otherStaffTimeOffModel?.numberRequests > 0" [value]="approvalSummary.otherStaffTimeOffModel?.dateModels" [responsive]="true" [paginator]="true" [rows]="20"
                         [totalRecords]="approvalSummary.otherStaffTimeOffModel?.numberRequests">
                  <ng-template pTemplate="header">
                    <tr>
                      <th width="100px">Date</th>
                      <th>Requests</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-item>
                    <tr (click)="item.showRequests = !item.showRequests" style="cursor: pointer;">
                      <td>
                        <span class="ui-column-title">Date</span>
                        {{item.date | date: 'MM/dd/yyyy'}}
                      </td>
                      <td>
                        <span class="ui-column-title">Requests</span>
                        <span title="Pending/Approved/Denied">
                          {{item.timeOffRequestTotal.numberRequests}} Requests -
                          {{item.timeOffRequestTotal.numberPending}}/{{item.timeOffRequestTotal.numberApproved}}/{{item.timeOffRequestTotal.numberDenied}}
                        </span>
                      </td>
                    </tr>
                    <tr *ngIf="item.showRequests">
                      <td style="padding-left: 20px;"></td>
                      <td>
                        <div class="row">
                          <div class="col-12 col-xl-4">
                            <div style="font-weight:bold;">Pending</div>
                            <div *ngFor="let to of item.timeOffRequestTotal.timeOffRequests">
                              <span *ngIf="to.pending">{{to.employeeName}}</span>
                            </div>
                          </div>
                          <div class="col-12 col-xl-4">
                            <div style="font-weight:bold;">Approved</div>
                            <div *ngFor="let to of item.timeOffRequestTotal.timeOffRequests">
                              <span *ngIf="to.approved">{{to.employeeName}}</span>
                            </div>
                          </div>
                          <div class="col-12 col-xl-4">
                            <div style="font-weight:bold;">Denied</div>
                            <div *ngFor="let to of item.timeOffRequestTotal.timeOffRequests">
                              <span *ngIf="to.denied">{{to.employeeName}}</span>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-4 d-flex align-items-center">
              <b>Scheduled Appointments ({{approvalSummary.staffingEvents?.numberAppts}})</b>
            </div>
            <div class="col-8">
              <span *ngIf="approvalSummary.staffingEvents && approvalSummary.staffingEvents.numberAppts < 1"
                    style="font-weight: bold; font-style: italic;">No Appointments</span>
              <p-table *ngIf="approvalSummary.staffingEvents && approvalSummary.staffingEvents?.numberAppts > 0"
                       [value]="approvalSummary.staffingEvents?.staffingEvents" [responsive]="true" [paginator]="true" [rows]="20"
                       [totalRecords]="approvalSummary.staffingEvents?.numberAppts">
                <ng-template pTemplate="header">
                  <tr>
                    <th width="100px">Date</th>
                    <th>Time Range</th>
                    <th>Client</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item>
                  <tr>
                    <td>
                      <span class="ui-column-title">Date</span>
                      {{item.date | date: 'MM/dd/yyyy'}}
                    </td>
                    <td>
                      <span class="ui-column-title">Time Range</span>
                      {{item.startTime | date: 'shortTime'}} - {{item.endTime | date: 'shortTime'}}
                    </td>
                    <td>
                      <span class="ui-column-title">Client</span>
                      {{item.patientName}}
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card mt-2">
    <span style="font-weight: bold; font-size: large; padding-left: 1.25rem; padding-top: 0.5rem;">Date Range Efficiency - {{approvalSummary.efficiencyModel?.programName}}</span>
    <div class="card-body">
      <div class="row">
        <div class="col-12">

          <div class="row">
            <p-table *ngIf="approvalSummary.efficiencyModel" [value]="approvalSummary.efficiencyModel?.dateModels" [responsive]="true" [paginator]="true" [rows]="20">
              <ng-template pTemplate="header">
                <tr>
                  <th>Date</th>
                  <th>
                    Passes Check
                    <i *ngIf="approvalSummary.efficiencyModel?.passesCheck" title="Passes" class="fas fa-check-circle" style="color: green;"></i>
                    <i *ngIf="approvalSummary.efficiencyModel?.passesCheck == false" title="Fails" class="fas fa-ban" style="color: red;"></i>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-item>
                <tr style="cursor: pointer;" (click)="item.showRanges = !item.showRanges">
                  <td>
                    <span class="ui-column-title">Date</span>
                    {{item.date | date: 'MM/dd/yyyy'}}
                  </td>
                  <td>
                    <span class="ui-column-title">Passes Check</span>
                    {{item.passesCheck ? 'Passed' : 'Failed'}}
                    <i *ngIf="item.passesCheck == true" title="Passes" class="fas fa-check-circle" style="color: green;"></i>
                    <i *ngIf="item.passesCheck == false" title="Fails" class="fas fa-ban" style="color: red;"></i>
                  </td>
                </tr>
                <ng-container *ngIf="item.showRanges == true">
                  <ng-container *ngFor="let tr of item.timeRangeEfficiencyModels">
                    <tr style="cursor: pointer;" (click)="tr.showNames = !tr.showNames">
                      <td style="padding-left: 20px;">
                        {{tr.description}} ({{tr.startTime | date: 'shortTime'}} - {{tr.endTime | date: 'shortTime'}})
                      </td>
                      <td>
                        {{tr.passesCheck ? 'Passes' : 'Fails'}} ({{tr.clients.length}}/{{tr.staff.length}})
                        <i *ngIf="tr.overStaffed == true" title="Overstaffed" class="fas fa-check-circle" style="color: green;"></i>
                        <i *ngIf="tr.overStaffed == false" title="Understaffed" class="fas fa-ban" style="color: red;"></i>
                      </td>
                    </tr>
                    <tr *ngIf="item.showRanges && tr.showNames == true">
                      <td style="padding-left: 20px;">
                      </td>
                      <td>
                        <div class="row">
                          <div class="col-12 col-xl-6">
                            <div style="font-weight: bold;">Clients</div>
                            <ng-container *ngFor="let c of tr.clients">
                              <span>{{c.name}}</span><br />
                            </ng-container>
                          </div>
                          <div class="col-12 col-xl-6">
                            <div style="font-weight: bold;">Clinicians</div>
                            <ng-container *ngFor="let s of tr.staff">
                              <span>{{s.name}}</span><br />
                            </ng-container>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                </ng-container>
              </ng-template>
            </p-table>
          </div>

        </div>
      </div>
    </div>
  </div>
  <p-footer>
    <button class="btn btn-secondary" type="button" (click)="closeModal()">Cancel</button>
    <button class="btn btn-danger" type="submit" (click)="saveTimeOffApproval(false)" [disabled]="disableButtons">Deny</button>
    <button class="btn btn-primary" type="submit" (click)="saveTimeOffApproval(true)" [disabled]="disableButtons">Approve</button>
  </p-footer>
</p-dialog>
