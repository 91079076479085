import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { PayrollItemModel } from 'src/app/models/payableItem';
import { getTimeFormatted, deepClone } from 'src/app/helpers/utils';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';

@Component({
  selector: 'app-payroll-reject-items-table',
  templateUrl: './payroll-reject-items-table.component.html',
  styleUrls: ['./payroll-reject-items-table.component.scss']
})
export class PayrollRejectItemsTableComponent extends BaseComponent implements OnInit {

  @Input() set payrollItems(value: PayrollItemModel[]) {
    if (value) {
      let totalTimeMinutes = 0;
      value.forEach(payrollItem => {
        totalTimeMinutes += payrollItem.payableItem.timeDiffMinutes;
      });
      this.totalTime = getTimeFormatted(totalTimeMinutes);
    } else {
      this.totalTime = '';
    }
    this._payrollItems = deepClone(value);
  }

  get payrollItems() {
    return this._payrollItems;
  }

  // tslint:disable-next-line:variable-name
  _payrollItems: PayrollItemModel[];

  @Input() payableType: string;
  @Input() title: string;

  @Output() itemsChanged = new EventEmitter<PayrollItemModel[]>();

  @Input() employeeView: boolean;


  totalTime: string;
  selectedItems = [];
  allSelected: boolean;

  constructor() {
    super();
  }

  ngOnInit() {

  }

  changed() {
    this.itemsChanged.emit(this.payrollItems);
  }

  trackByFunction = (index, item) => {
    return index;
  }

}
