import { Component, OnInit } from '@angular/core';
import { TicketType } from 'src/app/models/ticketTypeModels';

import {  Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { loadTicketTypes } from 'src/app/core/store/ticket-types/ticket-types.actions';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ticket-types-list',
  templateUrl: './ticket-types-list.component.html',
  styleUrls: ['./ticket-types-list.component.scss']
})
export class TicketTypesListComponent  extends BaseComponent implements OnInit {
  ticketTypes: TicketType[];
  loading;

  constructor(private store: Store<State>, private router: Router) {
    super();  
  }

  ngOnInit(): void {
    this.subs.push(
        this.store.select(s => s.ticketTypesState).subscribe(ticketTypesState => {
            if (ticketTypesState){
                this.ticketTypes = ticketTypesState.ticketTypes || [];
            }
            this.loading = ticketTypesState.loading;
        })
    );
    this.store.dispatch(loadTicketTypes());
  }


  editTicketType(ticketTypeId: number): void {
    this.router.navigate(['/ticket-types/edit', ticketTypeId]);
  }
}
