<div class="card mt-3" *ngIf="loading">
  <div class="card-body">Loading ...</div>
</div>
<app-selfcare-oversight-header></app-selfcare-oversight-header>
<div class="row justify-content-center">
  <div class="col" style="max-width: 1000px">
    <form class="card mt-2" [formGroup]="form">
      <div class="card-body text-center">
        <div class="mb-3" [style.color]="'#1a355d'">
          <span class="planner-header-selfcare">Self Care </span>
          <span class="planner-header">PLANNER</span>
        </div>
        <div class="description">
          SELFCARE IS A VITAL PART OF ANY HEALTHY LIFESTYLE.
           HAVING A PLAN OR ROUTINE IN PLACE TO HELP REMIND US TO TAKE CARE OF OURSELVES IS IMPORTANT.
           USE THIS PLANNER TO HELP GUIDE YOUR SLEF CARE ROUTINES AND AS AN ANCHOR TO COME BACK TO WHEN YOUR SELF CARE IS LACKING.
        </div>

        <div class="row  mb-3 mt-3">
        <ng-container *ngFor="let section of sections">
            <ng-container *ngIf="section.layoutType==1">
              <div class="col-12 mb-3 ">
                <div class="planner-panel">
                  <div class="planner-panel-header"  [style.color]="section.mainColor" [innerHTML]="transformSelfcareTitle(section.sectionTitle)"></div>
                  <div class="p-0 description">
                    {{section.sectionDescription?.toUpperCase()}}
                  </div>
                  <div class="text-left" *ngIf="section.items && section.items.length">
                    <ng-template *ngTemplateOutlet="questionsAndNotes;context:{item:section.items[0], section:section}" ></ng-template>
                  </div>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="section.layoutType==2">
              <div class="col-6 d-flex flex-column mb-3">
                <div class="planner-panel flex-grow-1">
                  <div class="planner-panel-header-sm" [style.color]="section.mainColor">{{section.sectionTitle?.toUpperCase()}}</div>
                  <div class="p-0 description">{{section.sectionDescription?.toUpperCase()}}</div>

                  <div class="text-left" *ngIf="section.items && section.items.length">
                    <ng-template *ngTemplateOutlet="questionsAndNotes;context:{item:section.items[0], section:section}" ></ng-template>
                  </div>
                </div>
              </div>
          </ng-container>
          <ng-container *ngIf="section.layoutType==3">
            <div class="col-12 mb-3 ">
              <div class="planner-panel mt-3">
                <div class="planner-panel-header"  [style.color]="section.mainColor" [innerHTML]="transformSelfcareTitle(section.sectionTitle)"></div>
                <div class="p-0 description">{{section.sectionDescription?.toUpperCase()}}</div>
                  <div class="row  mb-3 mt-3">
                    <div class="col-6 pr-1 d-flex flex-column" *ngFor="let item of section.items">
                      <div class="planner-panel flex-grow-1">
                        <div class="planner-panel-header-sm planner-panel-header-selfcare"  [style.color]="section.mainColor">{{item.sectionItem}}</div>
                        <div class="text-left" *ngIf="item">
                          <ng-template *ngTemplateOutlet="questionsAndNotes;context:{item:item, section:section}" ></ng-template>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="section.layoutType==4">
            <div class="col-12 mb-3 ">
              <div class="planner-panel mt-3">
                <div class="planner-panel-header" [id]="'section'+section.plannerSectionID" [style.color]="section.mainColor" [innerHTML]="transformSelfcareTitle(section.sectionTitle)"></div>
                <div class="p-0 description">{{section.sectionDescription?.toUpperCase()}}</div>
                <div class="row  mb-3 mt-3" *ngFor="let item of section.items">
                  <div class="col-12  d-flex flex-column">
                    <div class="planner-panel flex-grow-1 text-left">
                      <div class="planner-panel-header-sm planner-panel-header-selfcare"  [style.color]="section.mainColor">{{item.sectionItem}}</div>
                      <div class="text-left" *ngIf="item">
                        <ng-template *ngTemplateOutlet="questionsAndNotes;context:{item:item, section:section}" ></ng-template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

      </ng-container>
    </div>

        <div class="row mt-4">
          <div class="col-12 text-center">
            <i *ngIf="savingAnswer" class="material-icons rotate text-muted"
              >loop</i
            >
            <button
              *ngIf="!savingAnswer"
              class="btn btn-plan" [style.backgroundColor]="'#00aacc'"
              [class.disabled]="form.invalid"
              (click)="categories()"
            >
              Categories
            </button>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-12 text-center">
            <small>
              {{generalSettings?.businessFullName}}  and Meridian Technologies are not responsible for the content entered in your Self Care program.
            By default, the information entered into your Self Care program is confidential and protected with the same care as your
            employee data.  If you choose to share content entered in your Self Care plan, other people in the company will have
            access to it, and it is no longer private.
          </small>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<ng-template #questionsAndNotes let-item="item" let-section="section">
    <div *ngFor="let question of item.questions"  (mouseover)="question.hover=true" (mouseout)="question.hover=false">
      <ng-container *ngIf="question.answer!='-'">
      <span [innerHTML]="getQuestionText(question)">
      </span>
      <span [class.hide]="!question.hover" *ngIf="!isReadOnly()">
        &nbsp;<i class="fa fa-edit pointer"  [style.color]="section.mainColor" (click)="openAdditQuestion(opQuestion, $event, item,question)"></i>
      </span>
      <i *ngIf="savingAnswer && question.questionID == savingAnswer.questionID" class="fad fa-spinner-third rotate text-muted"></i>
      </ng-container>
    </div>
    <hr *ngIf="item.notes?.length && item.questions.length"/>
    <div class="mt-2" *ngFor="let note of item.notes" (mouseover)="note.hover=true" (mouseout)="note.hover=false" >
      <span>{{note.note}}</span>
      <span [class.hide]="!note.hover" *ngIf="!isReadOnly()">
        &nbsp;<i class="fa fa-trash pointer" [style.color]="section.mainColor" (click)="deleteNote(note)"></i>
        &nbsp;<i class="fa fa-edit pointer" [style.color]="section.mainColor" (click)="openAdditNote(opNote, $event, item,note)"></i>
      </span>
      <i *ngIf="savingNote && note.plannerSectionItemNoteID == savingNote.plannerSectionItemNoteID" class="fad fa-spinner-third rotate text-muted"></i>
    </div>
    <div class="add-button" *ngIf="!isReadOnly()" [style.backgroundColor]="section.mainColor" title="Add note" (click)="openAdditNote(opNote, $event, item, null)">+</div>
    <i *ngIf="savingNote && !savingNote.plannerSectionItemNoteID && savingNote.plannerSectionItemID == item.plannerSectionItemID " class="fad fa-spinner-third rotate text-muted"></i>
</ng-template>


<p-overlayPanel #opNote [dismissable]="false" [showCloseIcon]="true" [style]="{width: '450px'}" >
  <div *ngIf="noteEditing">
    <div class="row" >
      <div class="col-8">
        <textarea class="form-control" [(ngModel)]="noteEditing.note"></textarea>
      </div>
      <div class="col-4">
        <p-checkbox styleClass="ml-2" [binary]="true" (ngModelChange)="willChangeIsPublic($event,noteEditing,null)" [ngModel]="noteEditing.isPublic"></p-checkbox>
        Public?
      </div>
      <div class="col-12 text-right"><button class="btn btn-next" [style.backgroundColor]="'#00aacc'" (click)="addNote(opNote)">{{noteEditing.plannerSectionItemNoteID?'Save':'Add'}}</button></div>
    </div>
  </div>
</p-overlayPanel>


<p-overlayPanel #opQuestion [dismissable]="false" [showCloseIcon]="true" [style]="{width: '450px'}" >
  <div *ngIf="questionEditing">
    <div class="row" >
      <div class="col-8">
        <p-multiSelect  *ngIf="questionEditing.questionType==2" class="w-100" styleClass="w-100 d-flex" inputStyleClass="form-control" [options]="questionEditing.questionOptionsArray" [ngModel]="questionEditing.answerArray" (ngModelChange)="changeMultiSelectAnswer($event,questionEditing)" [ngModelOptions]="{standalone: true}" [disabled]="isReadOnly()" [title]="questionEditing.answer" [defaultLabel]="'Select many ...'"  [maxSelectedLabels]="99" ></p-multiSelect>
                <p-dropdown *ngIf="questionEditing.questionType==1" styleClass="form-control  w-100 " [options]="questionEditing.questionOptionsArray" [ngModel]="questionEditing.answerSelected" (ngModelChange)="changedAnswerSelected($event,questionEditing)" [disabled]="questionEditing.noAnswer || isReadOnly()"></p-dropdown>
                <textarea *ngIf="checkShowOtherMultiSelect(questionEditing)" type="text" class="form-control w-100 mt-1" [ngModel]="questionEditing.answerSelected" (ngModelChange)="changedAnswerSelectedMulti($event,questionEditing)" [disabled]="isReadOnly()" [title]="questionEditing.answer"></textarea>
                <textarea *ngIf="questionEditing.questionType==0 || (questionEditing.questionType==1 && questionEditing.answerSelected==='Other')" type="text" class="form-control w-100 mt-1"  [(ngModel)]="questionEditing.answer" [disabled]="questionEditing.noAnswer || isReadOnly()" [title]="questionEditing.answer"></textarea>

      </div>
      <div class="col-4" *ngIf="questionEditing.allowPublic">
        <p-checkbox styleClass="ml-2" [binary]="true" (ngModelChange)="willChangeIsPublic($event,questionEditing,true)" [ngModel]="questionEditing.isPublic"></p-checkbox>
        Public?
      </div>
      <div class="col-6 pt-2">
        <p-checkbox styleClass="" [binary]="true" [(ngModel)]="questionEditing.noAnswer"></p-checkbox>
        No Answer
      </div>
      <div class="col-6  text-right"><button class="btn btn-next" [style.backgroundColor]="'#00aacc'" (click)="addQuestion(opQuestion)">{{questionEditing.questionID?'Save':'Add'}}</button></div>
    </div>
  </div>
</p-overlayPanel>
