import { BenefitsManagementGuard } from 'src/app/core/guards/benefits-management.guard';
import { ClientInterceptor } from './interceptors/client.interceptor';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { AccountService } from './services/account.service';
import { AuthGuard } from './guards/auth.guard';
import { ProfileService } from './services/profile.service';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers, effects } from './store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TimeCardService } from './services/timecard.service';
import { PayPeriodService } from './services/payPeriod.service';
import { PayrollService } from './services/payroll.service';
import { TimeoffService } from './services/timeoff.service';
import { OfferLetterService } from './services/offerletter.service';
import { EquipmentService } from './services/equipment.service';
import { UsersService } from './services/users.service';
import { OnboardingDocsService } from './services/onboardingdocs.service';
import { CoachingLogService } from './services/coachingLog.service';
import { ImprovementPlanService } from './services/improvementPlan.service';
import { ChecksAuthGuard } from './guards/checksauth.guard';
import { PayrollApprovalGuard } from './guards/payroll-approval.guard';
import { EquipmentViewGuard } from './guards/equipment-view.guard';
import { EquipmentManageGuard } from './guards/equipment-manage.guard';
import { OnboardViewGuard } from './guards/onboard-view.guard';
import { OfferLetterViewGuard } from './guards/offer-letter-view.guard';
import { HolidayService } from './services/holiday.service';
import { OnboardingService } from './services/onboarding.service';
import { EmployeeService } from './services/employee.service';
import { ProspectiveCandidatesService } from './services/prospectiveCandidates.service';
import { BenefitsService } from './services/benefits.service';
import { SurveyAnswerComponent } from '../modules/main/survey/survey-answer/survey-answer.component';
import { SurveyService } from './services/survey.service';
import { EmployeeReviewTemplateGuard } from './guards/employee-review-template.guard';
import { EmployeeReviewTemplateService } from './services/employee-review-template.service';
import { EmployeeReviewService } from './services/employee-review.service';
import { EthicCodesService } from './services/ethic-codes.service';
import { SelfcareService } from './services/selfcare.service';
import { ManageSelfcareGuard } from './guards/manage-selfcare.guard';
import { ManageSelfcareService } from './services/manage-selfcare.service';
import { JobApplicationsService } from './services/job-applications.service';
import { BenefitsAccessGuard } from './guards/benefits-access.guard';
import { FormBuilderService } from './services/formBuilder.service';
import { CriticalNewsFeedService } from './services/critical-news-feed.service';
import { MedClinicService } from './services/medclinic.service';
import { InputAutoCompleteService } from '../shared/input-helpers/services/input-autocomplete.service';
import { InternService } from './services/intern.service';
import { InternGuard } from './guards/intern.guard';
import { TicketTypesService } from './services/ticket-types.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 10
    }),
    EffectsModule.forRoot(effects),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ClientInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    AccountService,
    AuthGuard,
    ChecksAuthGuard,
    PayrollApprovalGuard,
    EquipmentViewGuard,
    EquipmentManageGuard,
    OnboardViewGuard,
    OfferLetterViewGuard,
    EmployeeReviewTemplateGuard,
    ManageSelfcareGuard,
    BenefitsManagementGuard,
    BenefitsAccessGuard,
    InternGuard,
    ProfileService,
    TimeCardService,
    PayPeriodService,
    PayrollService,
    TimeoffService,
    OfferLetterService,
    UsersService,
    EquipmentService,
    OnboardingDocsService,
    HolidayService,
    OnboardingService,
    EmployeeService,
    ProspectiveCandidatesService,
    SurveyService,
    EmployeeReviewTemplateService,
    EmployeeReviewService,
    EthicCodesService,
    SelfcareService,
    ManageSelfcareService,
    JobApplicationsService,
    BenefitsService,
    FormBuilderService,
    CriticalNewsFeedService,
    MedClinicService,
    InputAutoCompleteService,
    InternService,
    TicketTypesService,
    CoachingLogService,
    ImprovementPlanService,
  ]
})
export class CoreModule { }
