<div class="card mt-2">
  <div class="card-body">
    <h2 class="mb-3">My Intern Applications</h2>
    <div class="mb-3">

    </div>

    <i [hidden]="!loadingList && !loadingExcel && !savingApplication && !loadingDownload" class="material-icons rotate text-muted">loop</i>
    <p-dropdown styleClass="form-control mb-2 auto-width" [options]="formTemplates" [(ngModel)]="formId" [disabled]="savingApplication" placeholder="Select an application..."></p-dropdown>
    <button (click)="createNewApplication()" class="btn btn-secondary ml-2 mb-2">New Application</button>
    <i *ngIf="!formTemplates" class="material-icons rotate text-muted">loop</i>
    <p-table [value]="list?.result" [responsive]="true" [paginator]="true" [rows]="20"
             [totalRecords]="list?.totalRecords" [lazy]="true" (onLazyLoad)="reloadFilter($event)">
      <ng-template pTemplate="header">
        <tr>
          <th width="175px"></th>
          <th [pSortableColumn]="'FormName'">Type<p-sortIcon [field]="'FormName'"></p-sortIcon></th>
          <th [pSortableColumn]="'Status'">Status<p-sortIcon [field]="'Status'"></p-sortIcon></th>
          <th [pSortableColumn]="'CreatedDate'">Created Date<p-sortIcon [field]="'CreatedDate'"></p-sortIcon></th>
          <th [pSortableColumn]="'SubmittedDate'">Date Submitted<p-sortIcon [field]="'SubmittedDate'"></p-sortIcon></th>
          <th [pSortableColumn]="'ReviewerLastModified'">Last Modififed by Reviewer<p-sortIcon [field]="'ReviewerLastModified'"></p-sortIcon></th>
          <th [pSortableColumn]="'DecisionDate'">Decision Date<p-sortIcon [field]="'DecisionDate'"></p-sortIcon></th>
          <th>Uploads</th>
          <th>Actions</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-list>
        <tr>
          <td>
            <i class="fas fa-pencil mr-2 pointer" style="font-size: 1.5rem;" *ngIf="canEditApplication(list)" (click)="editApplication(list)" title="Edit Application"></i>
            <i class="fas fa-trash-alt mr-2 pointer" style="font-size: 1.5rem;" *ngIf="canWithdrawApplication(list)" (click)="withdrawApplication(list)" title="Withdraw Application" ></i>
            <i class="fas fa-file-upload pointer" style="font-size: 1.5rem;" *ngIf="canUploadFile(list)" (click)="editApplicationUploads(list)" title="Edit Uploads" ></i>
          </td>
          <td>
            <span class="ui-column-title">Form Name</span>
            {{list.formName}}
          </td>
          <td>
            <span class="ui-column-title">Status</span>
            {{list.status}} <span *ngIf="list.substatusId != null" class="ml-2" style="font-style: italic;">({{list.substatus}})</span>
          </td>
          <td>
            <span class="ui-column-title">Created Date</span>
            {{list.createdDate | date: 'MM/dd/yyyy @ hh:mm:ss a'}}
          </td>
          <td>
            <span class="ui-column-title">Date Submitted</span>
            {{(list.submittedDate !== null) ? (list.submittedDate | date: 'MM/dd/yyyy @ hh:mm:ss a') : ""}}
          </td>
          <td>
            <span class="ui-column-title">Last Modified</span>
            {{(list.reviewerLastModified !== null) ? (list.reviewerLastModified | date: 'MM/dd/yyyy @ hh:mm:ss a') : ""}}
          </td>
          <td>
            <span class="ui-column-title">Decision Date</span>
            {{(list.decisionDate !== null) ? (list.decisionDate | date: 'MM/dd/yyyy @ hh:mm:ss a') : ""}}
          </td>
          <td>
            <span class="ui-column-title">Uploads</span>
            <div *ngFor="let u of list.uploads" class="btn-link pointer" (click)="downloadUploadedFile(u)" title="Download">
              {{u.fileName}}
            </div>
          </td>
          <td>
            <span class="ui-column-title">Action Items</span>
            <div *ngFor="let a of list.actions">
              <i *ngIf="!a.dateCompleted" class="fas fa-pencil pointer" style="cursor: pointer;" (click)="editActionItem(a)" title="Edit Action Item"></i>
              <i *ngIf="a.dateCompleted" class="fa fa-check-circle text-success pointer" style="cursor: pointer;" (click)="editActionItem(a)" title="Edit Action Item"></i>
              {{a.action}}
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<app-edit-intern-action [isEmployeeView]="true"></app-edit-intern-action>
<app-upload-intern-doc></app-upload-intern-doc>
