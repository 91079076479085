<div class="card mb-2">
  <div class="card-body">
    <i *ngIf="!formSubmission || !fbForm || loadingForm" class="material-icons rotate text-muted">loop</i>
    <div class="float-right d-flex">
      <p-dropdown class="mr-3" *ngIf="formSubmission && fbForm && form && fbForm.formLanguages && fbForm.formLanguages.length" styleClass="form-control mt-2 w-100" [options]="formLanguageOptions"  [showClear]="true" placeholder="Select language..." [(ngModel)]="selectedFormLanguage" (ngModelChange)="changedLanguage()" >
        <ng-template let-item pTemplate="selectedItem">
          <div class="flex align-items-center gap-2">
            <span [class]="'fi fi-'+item.value.languageCode"></span> <span [innerHTML]="item.value.languageName"></span>
          </div>
        </ng-template>
        <ng-template let-item pTemplate="item">
          <div class="flex align-items-center gap-2">
            <span [class]="'fi fi-'+item.value.languageCode"></span> <span [innerHTML]="item.value.languageName"></span>
          </div>
        </ng-template>
      </p-dropdown>
      <button *ngIf="!isUsedAsComponent" class="btn btn-secondary float-right pointer" [routerLink]="getBackLink()">Back</button>
    </div>
    <form *ngIf="formSubmission && fbForm && form" [formGroup]="form" >
      <h2 class="text-center" *ngIf="!hideTitle">{{getFormName(fbForm)}}</h2>
      <div class="row">
        <div  [class]="forceFullWidth?'col-12':'col-12 col-xl-8 offset-xl-2 col-lg-10 offset-lg-1'">
          <div class="mb-3" *ngIf="fbForm?.formFields && fbForm.formFields.length==0">
            This form has no fields <span *ngIf="!isUsedAsComponent">, redirecting to sign the document.</span>
          </div>
          <div class="mb-3 row" *ngIf="fbForm.formFields">
            <ng-container *ngFor="let field of fbForm.formFields">
              <div class="mt-3" *ngIf="showField(field)"
              [class.col-6]="field.fieldWidthRatio==2"
              [class.col-4]="field.fieldWidthRatio==3"
              [class.col-3]="field.fieldWidthRatio==4"
              [class.col-2]="field.fieldWidthRatio==5 || field.fieldWidthRatio==6"
              [class.col-1]="field.fieldWidthRatio==12"
              [class.col-12]="!field.fieldWidthRatio || field.fieldWidthRatio==1">
              <app-form-field [field]="field" [readOnly]="readOnly" [isPrefillingForm]="isPrefillingForm" [form]="form" [fbForm]="fbForm" [formSubmission]="formSubmission" [selectedFormLanguage]="selectedFormLanguage"></app-form-field>
              </div>
            </ng-container>
          </div>

        </div>
      </div>
      <div class="w-100 text-center" *ngIf="!readOnly">
        <button class="btn btn-primary mt-3" *ngIf="!(fbForm?.formFields && fbForm.formFields.length==0) && !hideSaveButon"  id="saveButton" [class.disabled]="savingForm " (click)="save()">Save<span *ngIf="!isUsedAsComponent"> and Exit</span></button>
        <i *ngIf="savingForm" class="material-icons rotate text-muted ml-3">loop</i>
        <button *ngIf="!hideFinishAndSign"  class="btn btn-primary mt-3 ml-3" id="finishButton" [class.disabled]="savingForm || form.invalid" (click)="finish()">Finish and Sign</button>
      </div>
    </form>
  </div>
</div>

