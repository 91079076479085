<form class="card mt-2" *ngIf="canSeeCoach">
  <div class="card-body">
    <button class="btn btn-primary float-right pointer mr-3 ml-3" routerLink="/improvementplan/create" *ngIf="canAddCoach">Create Improvement Plan</button>
    <button class="btn btn-primary float-right pointer" routerLink="/coachinglogs/create" *ngIf="canAddCoach">Create Coaching Log</button>
    <h2 class="mb-3">Coaching Employees</h2>
    <h5>Filter</h5>

    <div class="row ">
      <div class="col-12 col-sm-6">
        <div class="row ">
          <div class="col-4 col-lg-2 col-xl-1 d-flex align-items-center">
            User:
          </div>
          <div class="col-8 col-lg-10 col-xl-11 ">
            <p-dropdown styleClass="form-control mt-2" [options]="usersList" [(ngModel)]="selectedUserID" (ngModelChange)="changedFilter()"
                        [ngModelOptions]="{standalone: true}" [placeholder]="'Select user...'"></p-dropdown>
          </div>
        </div>

        <div class="row ">
          <div class="col-4 col-lg-2 col-xl-1 d-flex align-items-center">
            Level:
          </div>
          <div class="col-8 col-lg-10 col-xl-11 ">
            <p-dropdown styleClass="form-control mt-2" [options]="levelOptions" [(ngModel)]="selectedLevelFilter" (ngModelChange)="changedFilter()"
                        [ngModelOptions]="{standalone: true}" [placeholder]="'Select level...'"></p-dropdown>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6">
        <div class="row ">
          <div class="col-4 col-lg-2 col-xl-2 d-flex align-items-center">
            Acknowledged:
          </div>
          <div class="col-8 col-lg-10 col-xl-10 ">
            <p-dropdown styleClass="form-control mt-2" [options]="seenOptions" [(ngModel)]="selectedSeenFilter" (ngModelChange)="changedFilter()"
                        [ngModelOptions]="{standalone: true}" [placeholder]="'Select seen...'"></p-dropdown>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-4 col-lg-2 col-xl-1 d-flex align-items-center">
            Done:
          </div>
          <div class="col-8 col-lg-10 col-xl-11 ">
            <p-dropdown styleClass="form-control mt-2" [options]="checkedOptions" [(ngModel)]="selectedCheckedFilter" (ngModelChange)="changedFilter()"
                        [ngModelOptions]="{standalone: true}" [placeholder]="'Select checked...'"></p-dropdown>
          </div>
        </div>
      </div>
    </div>

    <i *ngIf="loadingCoachingLogsList" class="material-icons rotate text-muted">loop</i>
    <p-table [value]="coachingLogsList?.result" [responsive]="true"
             [paginator]="true" [rows]="20" [totalRecords]="coachingLogsList?.totalRecords" [lazy]="true"
             (onLazyLoad)="loadPage($event)">
      <ng-template pTemplate="header">
        <tr>
          <th width="100px">
            Actions
          </th>
          <!-- <th width="250px"></th> -->
          <th width="100px" [pSortableColumn]="'salaryPayRate'">
            Acknowledged
            <p-sortIcon [field]="'seenDate'"></p-sortIcon>
          </th>
          <th width="100px" [pSortableColumn]="'signed'">
            Done
            <p-sortIcon [field]="'checkedDate'"></p-sortIcon>
          </th>
          <th width="90px">Date</th>
          <th width="90px">Follow Up</th>
          <th width="180px">User</th>
          <th width="180px">Created by</th>
          <th width="120px">Level</th>
          <th>Description</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-coachingLogWithUsersModel>
        <tr>
          <td>
            
            <button *ngIf="!coachingLogWithUsersModel.checkedDate" class="btn btn-secondary mr-2"  title="Update Coaching Log" (click)="viewCoachingLog(coachingLogWithUsersModel)"><i class="fas fa-edit"></i></button>
          </td>

          <td>
            <span class="ui-column-title">Acknowledged</span>

            <i class="fas fa-check-circle text-success" *ngIf="!!coachingLogWithUsersModel.seenDate"></i>
            <span *ngIf="coachingLogWithUsersModel.seenDate">
              {{coachingLogWithUsersModel.seenDate  | date:'shortDate'}}
            </span>
            <i class="fas fa-minus-circle text-secondary" *ngIf="!coachingLogWithUsersModel.seenDate"></i>
          </td>
          <td>
            <span class="ui-column-title">Done</span>
            <i class="fas fa-check-circle text-success" title="Complied" *ngIf="coachingLogWithUsersModel.complied == true"></i>
            <i class="fas fa-times-circle text-danger" title="Not Complied" *ngIf="coachingLogWithUsersModel.complied == false"></i>
            <i class="fas fa-pause-circle text-warning" title="NOC - No Opportunity to Comply" *ngIf="coachingLogWithUsersModel.noOpportunityToComply == true"></i>
            <span *ngIf="coachingLogWithUsersModel.checkedDate">{{coachingLogWithUsersModel.checkedDate  | date:'shortDate'}}</span>
            <!--<i class="fas fa-minus-circle text-secondary " [class.pointer]="canAddCoach" (click)="markAsChecked(coachingLogWithUsersModel)" *ngIf="!coachingLogWithUsersModel.checkedDate"></i>
    -->
          </td>
          <td>
            <span class="ui-column-title">Created</span>
            {{coachingLogWithUsersModel.noteDate | date:'shortDate' }}
          </td>
          <td>
            <span class="ui-column-title">Created</span>
            <span *ngIf="!coachingLogWithUsersModel.newFollowUpDate" [ngClass]="{'text-danger': coachingLogWithUsersModel.isOverdue}">
              {{coachingLogWithUsersModel.followUpDate | date:'shortDate' }}
            </span>
            <span *ngIf="coachingLogWithUsersModel.newFollowUpDate" [ngClass]="{'text-danger': coachingLogWithUsersModel.isOverdue}">
              {{coachingLogWithUsersModel.newFollowUpDate | date:'shortDate' }}
            </span>
          </td>
          <td>
            <span class="ui-column-title">User</span>
            {{coachingLogWithUsersModel.user.firstName }} {{coachingLogWithUsersModel.user.lastName }}
          </td>
          <td>
            <span class="ui-column-title">Created By</span>
            {{coachingLogWithUsersModel.createdByUser.firstName }} {{coachingLogWithUsersModel.createdByUser.lastName }}
          </td>
          <td>
            <span class="ui-column-title">Level</span>
            {{getLevel(coachingLogWithUsersModel.level)}}
          </td>
          <td>
            <span class="ui-column-title">Description</span>
            <span *ngIf="!coachingLogWithUsersModel.improvementPlanID">{{coachingLogWithUsersModel.description}}</span>
            <div *ngIf="coachingLogWithUsersModel.improvementPlanID">
              <span class="font-weight-bold">Supervisor:</span> {{coachingLogWithUsersModel.improvementPlan?.supervisorUser?.firstName}} {{coachingLogWithUsersModel.improvementPlan?.supervisorUser?.lastName}}<br />
              <span class="font-weight-bold">Status:</span> {{getImprovementModelStatus(coachingLogWithUsersModel.improvementPlan)}}<br />
              <span *ngIf="coachingLogWithUsersModel.improvementPlan?.nextReviewDate">
                <span class="font-weight-bold">Next Review Date:</span> <span *ngIf="!coachingLogWithUsersModel.newFollowUpDate" [ngClass]="{'text-danger': coachingLogWithUsersModel.isOverdue}">
                  {{coachingLogWithUsersModel.followUpDate | date:'shortDate' }}
                </span>
                <span *ngIf="coachingLogWithUsersModel.newFollowUpDate" [ngClass]="{'text-danger': coachingLogWithUsersModel.isOverdue}">
                  {{coachingLogWithUsersModel.newFollowUpDate | date:'shortDate' }}
                </span><br>
              </span>
              <a class="mr-2" *ngIf="!coachingLogWithUsersModel.improvementPlan.signedDateSupervisor && coachingLogWithUsersModel.improvementPlan.supervisorUserID?.toString() === currentUserId?.toString()" href="javascript:void(0)" [routerLink]="'/improvementplan/sign/'+coachingLogWithUsersModel.improvementPlan.id" [queryParams]="{backTo:'given'}">Sign as Supervisor</a>
              <a class="mr-2" *ngIf="!coachingLogWithUsersModel.improvementPlan.signedDateSupervisor && !coachingLogWithUsersModel.improvementPlan.signedDateEmployee" [routerLink]="'/improvementplan/create/'+coachingLogWithUsersModel.improvementPlan.id">Edit</a>
              <a class="mr-2" [routerLink]="'/improvementplan/view/'+coachingLogWithUsersModel.improvementPlan.id">Open</a>
              <a class="mr-2" href="javascript:void(0)" (click)="downloadImprovementPlan(coachingLogWithUsersModel.improvementPlan)">Download</a>

            </div>
            <div *ngIf="coachingLogWithUsersModel.followUp">
              <span class="font-weight-bold">First Follow Up:</span><br />
              {{coachingLogWithUsersModel.followUp}}
            </div>
            <div *ngIf="coachingLogWithUsersModel.newFollowUp">
              <span class="font-weight-bold">Second Follow Up:</span><br />
              {{coachingLogWithUsersModel.newFollowUp}}
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</form>
<p-dialog header="Coaching Log Follow Up" [focusTrap]="false" [modal]="true" [styleClass]="'top-dialog'"
          [style]="{width: '70vw'}" [(visible)]="displayCoachingLogFollowUp" (onHide)="hideCoachingLogFollowUp()">
  <app-follow-up-coachinglog></app-follow-up-coachinglog>
</p-dialog>

