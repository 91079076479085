import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from '../store';
import { AlertService } from 'src/app/shared/services/alert.service';
import { loadProfile } from '../store/profile/profile.actions';

@Injectable()
export class ChecksAuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private store: Store<State>,
    private alert: AlertService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem('currentUser')) {
      let profile;
      this.store.dispatch(loadProfile());
      this.store.select(s => s.profileState.profile).subscribe(p => {
        profile = p;
      });

      if (profile) {
        if (profile.employee != null && profile.employee.terminationDate != null) {
          return false;
        }
        if (profile.isOnBoarding) {
          const url: string = route['_routerState'].url;
          if (url !== '/onboarding' && url !== '/' && url !== '/offerletter' && !url.startsWith('/onboarding/')) {
            return false;
          }
          return true;
        }
        if (profile.pendingRequiredDocumentsCount <= 0) {
          return true;
        } else {
          this.alert.info('You must sign all required documents before accessing this page.', true);
          this.router.navigate(['/']);
          return false;
        }

      }

      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    if (state.url) {
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    } else {
      this.router.navigate(['/login']);
    }
    return false;
  }
}
