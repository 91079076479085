import { Injectable } from "@angular/core";
import { State } from '..';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PaylocityService } from "../../services/paylocity.service";
import { dismissTransmission, dismissTransmissionSuccess, getPaylocityTransmissions, getPaylocityTransmissionsSuccess } from "./paylocity.action";
import { map, switchMap, catchError } from 'rxjs/operators';
import { errorHappened } from '../profile/profile.actions';
import { of } from 'rxjs';

@Injectable()
export class PaylocityEffects {
  constructor(private actions$: Actions,
    private paylocityService: PaylocityService,
    private store: Store<State>) { }

  getPaylocityTranmissions = createEffect(() => this.actions$.pipe(
    ofType(getPaylocityTransmissions),
    switchMap(action => {
      return this.paylocityService.getPaylocityTransmissions(action.filter).pipe(
        map(transmissions => getPaylocityTransmissionsSuccess({ transmissions })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  dismissTransmission = createEffect(() => this.actions$.pipe(
    ofType(dismissTransmission),
    switchMap(action => {
      return this.paylocityService.dismissTransmission(action.transmission).pipe(
        map(timeOff => dismissTransmissionSuccess()),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));
}
