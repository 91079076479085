<app-selfcare-oversight-header></app-selfcare-oversight-header>
<div class="row justify-content-center">
  <div class="card mt-3" style="max-width: 800px" *ngIf="loading">
    <div class="card-body">Loading ...</div>
  </div>
  <div class="col" style="max-width: 800px" *ngIf="category && !loading">
    <div class="card mt-2">
      <div class="card-body text-center">
        <div class="category-header mb-3"  [style.color]="category.mainColor" >
          {{category.categoryName}} <img [src]="'/assets/img/'+category.categoryImage" [alt]="category.categoryName" class="category-img"
          *ngIf="category.categoryImage && !isPreferences"/>
          <span *ngIf="!isPreferences">
            Wellness
          </span>
        </div>
        <div class="slideshow" [style.borderColor]="category.mainColor" *ngIf="!isPreferences && category.slideshowLink">
          <iframe *ngIf="category.slideshowLink"
            [src]="sanitizedSlideshowLink"
            frameborder="0"
            [width]="iFrameWidth"
            [height]="iFrameHeight"
            allowfullscreen="true"
            mozallowfullscreen="true"
            webkitallowfullscreen="true"
          ></iframe>
        </div>
        <div class="description" *ngIf="category.categoryDescription">
          {{category.categoryDescription}}
        </div>
        <div class="row mb-4 mt-1 pointer" *ngIf="!isPreferences && (category.linkText || category.linkDescripton ) && !category.onboarding_UploadDocID" [style.color]="category.mainColor" (click)="open(category.linkUrl)" >
          <div class="col-6 assessment-text">{{category.linkText}}</div>
          <div class="col-6 assessment-link">
            <span [innerHTML]="category.linkDescripton"></span>
          </div>
        </div>

        <div class="row mb-4 mt-1 pointer" *ngIf="category.onboarding_UploadDocID" [style.color]="category.mainColor" (click)="openDoc(category.onboarding_UploadDocID,category.onboarding_UploadDocFileType,category.onboarding_UploadDocFileName)" >
          <div class="col-6 assessment-text">{{category.uploadDocLinkText}}</div>
          <div class="col-6 assessment-link">
            <span [innerHTML]="category.uploadDocLinkDescription"></span>
          </div>
          <div class="col-12 text-center" *ngIf="loadingDoc">
            <i  class="material-icons rotate text-muted">loop</i>
          </div>
        </div>
        <div class="questions-panel " [style.borderColor]="category.mainColor">
          <div class="questions-panel-header mb-3 " [style.color]="category.mainColor" *ngIf="!isPreferences">
            {{category.categoryName.toUpperCase()}}
            <span class="questions-panel-selfcare">Self Care</span> ROUTINE
          </div>
          <div class="questions-panel-header mb-3 " [style.color]="category.mainColor" *ngIf="isPreferences">
            {{category.categoryName.toUpperCase()}}
          </div>

          <div class="questions text-left">
            <div class="row" *ngIf="!isReadOnly() && !isPreferences">
              <div class="col-12 question-text text-right">
                PUBLIC
              </div>
            </div>
            <div class="row mb-3 " *ngFor="let question of category.questions">
              <div class="col-5 question-text">
                {{question.questionText.toUpperCase()}}
              </div>
              <div class="col-6 question-input-div">
                <p-multiSelect  *ngIf="question.questionType==2" class="w-100" styleClass="w-100 d-flex navyFont" inputStyleClass="form-control" [options]="question.questionOptionsArray" [ngModel]="question.answerArray" (ngModelChange)="changeMultiSelectAnswer($event,question)" [ngModelOptions]="{standalone: true}" [disabled]="isReadOnly()" [title]="question.answer" [defaultLabel]="'Select many ...'"  [maxSelectedLabels]="99" ></p-multiSelect>
                <p-dropdown *ngIf="question.questionType==1" styleClass="form-control  w-100 navyFont" [options]="question.questionOptionsArray" [ngModel]="question.answerSelected" (ngModelChange)="changedAnswerSelected($event,question)" [disabled]="isReadOnly()"></p-dropdown>
                <textarea #textarea0 (ngModelChange)="changedTextArea(textarea0)" *ngIf="checkShowOtherMultiSelect(question)" type="text" class="" [ngModel]="question.answerSelected" (ngModelChange)="changedAnswerSelectedMulti($event,question)" [disabled]="isReadOnly()" [title]="question.answer"></textarea>

                <textarea #textarea1 *ngIf="question.questionType==0 || (question.questionType==1 && question.answerSelected==='Other')"  [(ngModel)]="question.answer"  (ngModelChange)="changedTextArea(textarea1)" [disabled]="isReadOnly()" [title]="question.answer"></textarea>

              </div>
              <div class="col-1 question-text" *ngIf="!isReadOnly() && !isPreferences">
                <p-checkbox *ngIf="question.allowPublic" styleClass="ml-2" [binary]="true" (ngModelChange)="willChangeIsPublic($event,question.questionID)" [ngModel]="question.isPublic"></p-checkbox>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-12 text-center">
            <i *ngIf="savingAnswer" class="material-icons rotate text-muted">loop</i>
            <button *ngIf="!savingAnswer && !isPreferences"
                    class="btn btn-prev float-left" [style.backgroundColor]="category.mainColor"
                    (click)="prev()">
              Prev
            </button>
            <button *ngIf="!savingAnswer && !isPreferences"
                    class="btn btn-plan " [style.backgroundColor]="category.mainColor"
                    (click)="planner()">
              Self Care Plan
            </button>
            <button *ngIf="!savingAnswer && !isPreferences"
                    class="btn btn-next float-right " [style.backgroundColor]="category.mainColor"
                    (click)="next()">
              Next
            </button>
            <button *ngIf="!savingAnswer && isPreferences"
                    class="btn btn-next float-right " [style.backgroundColor]="category.mainColor"
                    (click)="savePreferences()">
              Save
            </button>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-12 text-center">
            <small>
            {{generalSettings?.businessFullName}} and Meridian Technologies are not responsible for the content entered in your Self Care program.
            By default, the information entered into your Self Care program is confidential and protected with the same care as your
            employee data.  If you choose to share content entered in your Self Care plan, other people in the company will have
            access to it, and it is no longer private.
          </small>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
