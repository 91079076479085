import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { RecurringHolidayModel } from '../../../../models/holidayModel';
import { FormGroup } from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { initEditRecurringHolidayForm } from '../../../../core/store/holiday/holiday.reducer';
import { getAllControlsErrors, getFormattedTime, updateFormGroupValidity, markFormGroupTouched } from '../../../../helpers/utils';
import {
  updateRecurringHolidayForm, saveRecurringHolidaySuccess, closeEditRecurringHoliday, saveRecurringHoliday,
  loadRecurringTypes, loadRecurringWeeks
} from '../../../../core/store/holiday/holiday.actions';
import { formatDate } from '@angular/common';
import { tap } from 'rxjs/operators';
import { SelectItem } from 'primeng/api';
import { DropdownFilterService } from '../../../../core/services/dropdown-filter.service';

@Component({
  selector: 'app-edit-recurring',
  templateUrl: './edit-recurring.component.html',
  styleUrls: ['./edit-recurring.component.scss']
})
export class EditRecurringComponent extends BaseComponent implements OnInit {
  display: boolean = false;
  holiday: RecurringHolidayModel;
  title: string;
  form: FormGroup;
  disableButtons: boolean = true;  

  recurringTypeList: SelectItem[];
  monthList: SelectItem[];
  daysOfWeekList: SelectItem[];
  weekList: SelectItem[];

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private dropdownFilterService: DropdownFilterService) {
    super();
  }

  ngOnInit() {
    this.form = initEditRecurringHolidayForm({} as RecurringHolidayModel);
    this.subs.push(
      this.store.select(s => s.holidayState.editingRecurringHoliday).subscribe(editingHoliday => {
        this.display = editingHoliday;
      }),
      this.store.select(s => s.holidayState.savingRecurringHoliday).subscribe(savingHoliday => {
        this.disableButtons = savingHoliday;
      }),
      this.store.select(s => s.holidayState.recurringTypes).subscribe(types => {
        this.recurringTypeList = types && types.length > 0 ? types
          : [{ label: 'loading...', value: undefined }];
      }),
      this.store.select(s => s.holidayState.recurringWeeks).subscribe(weeks => {
        this.weekList = weeks && weeks.length > 0 ? weeks
          : [{ label: 'loading...', value: undefined }];
      }),
      this.store.select(s => s.holidayState.selectedRecurringHoliday).subscribe(hol => {        
        if (hol === null) {
          this.holiday = null;
          return;
        }

        const changedHoliday = !this.holiday || (this.holiday.recurringHolidayId !== hol.recurringHolidayId);
        this.holiday = JSON.parse(JSON.stringify(hol));

        if (changedHoliday) {
          this.form = initEditRecurringHolidayForm(this.holiday);
          this.subs.push(
            this.form.valueChanges.subscribe(formValues => {
              const formErrors = getAllControlsErrors(this.form);
              this.store.dispatch(updateRecurringHolidayForm({ formValues, formErrors }));
            })
          )
          if (typeof hol.recurringHolidayId === 'undefined' || hol.recurringHolidayId === null || hol.recurringHolidayId < 1) {
            this.title = "Add Holiday";
            this.store.dispatch(updateRecurringHolidayForm({ formValues: this.form.value, formErrors: getAllControlsErrors(this.form) }));
          } else {
            this.title = "Edit Holiday #" + hol.recurringHolidayId;
            this.holiday.dateString = formatDate(hol.date, 'yyyy-MM-dd', 'en-US');
            this.holiday.startTimeString = getFormattedTime(hol.startTime);
            this.holiday.endTimeString = getFormattedTime(hol.endTime);            
          }
        }
      }),
      this.actions$.pipe(
        ofType(saveRecurringHolidaySuccess),
        tap(action => {
          this.closeModal();
        })
      ).subscribe(),
    );

    this.monthList = this.dropdownFilterService.months.map(c => ({ label: c.month, value: c.value }));
    this.daysOfWeekList = this.dropdownFilterService.daysOfWeek.map(c => ({ label: c.day, value: c.value }));
    this.store.dispatch(loadRecurringTypes());
    this.store.dispatch(loadRecurringWeeks());

  }

  closeModal() {
    this.store.dispatch(closeEditRecurringHoliday());
  }

  saveHolidayEdits() {
    updateFormGroupValidity(this.form);
    if (this.form.valid) {
      this.store.dispatch(saveRecurringHoliday({ holiday: this.holiday }));
    } else {
      markFormGroupTouched(this.form);
    }
  }  
}
