import { Component, OnInit, Input } from '@angular/core';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { closeEditHoliday, saveHoliday, updateHolidayForm, deleteHoliday, saveHolidaySuccess, deleteHolidaySuccess } from '../../../../core/store/holiday/holiday.actions';
import { HolidayModel } from '../../../../models/holidayModel';
import { Time, formatDate } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { initEditHolidayForm } from '../../../../core/store/holiday/holiday.reducer';
import { getFormattedTime, getAllControlsErrors, updateFormGroupValidity, markFormGroupTouched } from '../../../../helpers/utils';
import * as moment from 'moment';
import { AlertService } from '../../../../shared/services/alert.service';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-edit-holiday',
  templateUrl: './edit-holiday.component.html',
  styleUrls: ['./edit-holiday.component.scss']
})
export class EditHolidayComponent extends BaseComponent implements OnInit {
  display: boolean = false;
  holiday: HolidayModel;
  title: string;
  form: FormGroup;
  disableButtons: boolean = true;
  hideDelete: boolean = true;

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private alertService: AlertService) {
    super();
  }

  ngOnInit() {
    this.form = initEditHolidayForm({} as HolidayModel);
    this.subs.push(
      this.store.select(s => s.holidayState.editingHoliday).subscribe(editingHoliday => {
        this.display = editingHoliday;
      }),
      this.store.select(s => s.holidayState.savingHoliday).subscribe(savingHoliday => {
        this.disableButtons = savingHoliday;
      }),
      this.store.select(s => s.holidayState.selectedHoliday).subscribe(hol => {
        this.hideDelete = true;
        if (hol === null) {
          this.holiday = null;
          return;
        }

        const changedHoliday = !this.holiday || (this.holiday.holidayId !== hol.holidayId);
        this.holiday = JSON.parse(JSON.stringify(hol));

        if (changedHoliday) {
          this.form = initEditHolidayForm(this.holiday);
          this.subs.push(
            this.form.valueChanges.subscribe(formValues => {
              const formErrors = getAllControlsErrors(this.form);
              this.store.dispatch(updateHolidayForm({ formValues, formErrors }));
            })
          )
          if (typeof hol.holidayId === 'undefined' || hol.holidayId === null || hol.holidayId < 1) {
            this.title = "Add Holiday";
            this.store.dispatch(updateHolidayForm({ formValues: this.form.value, formErrors: getAllControlsErrors(this.form) }));
          } else {
            this.title = "Edit Holiday #" + hol.holidayId;
            this.holiday.dateString = formatDate(hol.date, 'yyyy-MM-dd', 'en-US');
            this.holiday.startTimeString = getFormattedTime(hol.startTime);
            this.holiday.endTimeString = getFormattedTime(hol.endTime);
            this.hideDelete = false; //is there another check for showing so doesn't show if can't delete?
          }
        }
      }),
      this.actions$.pipe(
        ofType(saveHolidaySuccess),
        tap(action => {
          this.closeModal();
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(deleteHolidaySuccess),
        tap(action => {
          this.closeModal();
        })
      ).subscribe(),
    );
  }

  closeModal() {    
    this.store.dispatch(closeEditHoliday());
  }

  saveHolidayEdits() {
    updateFormGroupValidity(this.form);
    if (this.form.valid){
    this.store.dispatch(saveHoliday({holiday: this.holiday}));
    } else{
      markFormGroupTouched(this.form); 
    }
  }

  deleteHoliday() {
    this.alertService.confirm('Delete Holiday',
      'Are you sure you want to delete this holiday?')
      .subscribe(
        answer => {
          if (answer) {
            this.store.dispatch(deleteHoliday({ holidayId: this.holiday.holidayId }));
          }
        }, error => {
        }
      );
  }
}
