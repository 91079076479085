import { Pipe, PipeTransform } from '@angular/core';
/*
 * format duration in minutes to hour and minutes
 * Usage:
 *   value | duration
 * Example:
 *   {{ 150 | duration }}
 *   formats to: 2h 30m
*/
@Pipe({name: 'duration'})
export class DurationPipe implements PipeTransform {
  transform(value: number): string {
    const hours = value >= 0 ? Math.floor(value / 60) : Math.ceil(value/60);
    const mins = value % 60;

  return hours.toFixed(0) + 'h ' + mins.toFixed(0) + 'm';
  }
}
