<form class="card mt-2" [formGroup]="form" *ngIf="canAddCoach">
  <div class="card-body">
    <h4 class="mb-3">Create Coaching Log</h4>
    <div class="row">
      <div class="col-12 col-lg-8 ">
        <div class="row" formGroupName="coachingLog">
          <div class="col-4 d-flex align-items-center">
            <b>User</b>
          </div>
          <div class="col-8">
            <p-dropdown styleClass="form-control mt-2" [disabled]="previousCoachingLogID > 0" [options]="usersList" formControlName="userID" placeholder="Select user..."></p-dropdown>
            <app-validation-message [form]="form" [path]="'coachingLog.userID'" [name]="'userID'" [label]="'User'"> </app-validation-message>
          </div>
        </div>
        <div class="row" formGroupName="coachingLog">
          <div class="col-4 d-flex align-items-center">
            <b>Note Date</b>
          </div>
          <div class="col-8">
            <input type="date" class="form-control mt-2" formControlName="noteDate" />
            <app-validation-message [form]="form" [path]="'coachingLog.noteDate'" [name]="'noteDate'" [label]="'Note Date'"> </app-validation-message>
          </div>
        </div>
        <div class="row" formGroupName="coachingLog">
          <div class="col-4 d-flex align-items-center">
            <b>Level</b>
          </div>
          <div class="col-8">
            <select class="form-control mt-2" [disabled]="previousCoachingLogID > 0" formControlName="level">
              <option value="1">Re-Focus</option>
              <option value="2">Coaching</option>
            </select>
            <app-validation-message [form]="form" [path]="'coachingLog.level'" [name]="'level'" [label]="'Level'"> </app-validation-message>
          </div>
        </div>


        <div *ngIf="categoriesList != null && categoriesList.length > 0" class="row" formGroupName="coachingLog">
          <div class="col-4 d-flex align-items-center">
            <b>Categories of the Issue</b>
          </div>
          <div class="col-8 mt-2 mb-2">

            <label formArrayName="categories" class="col" *ngFor="let category of categoriesFormArray.controls; let i=index">
              <input [formControlName]="i" class="" type="checkbox" [value]="categoriesList[i].categoryName">
              {{categoriesList[i].categoryName}}
            </label>

            <div class="row" *ngIf="hasOtherCategory">
              <div class="col-4 d-flex align-items-center">
                <label>Other Category</label>
              </div>
              <div class="col-8">
                <input type="text" maxlength="200" class="form-control mt-2" formControlName="otherCategory" />
              </div>
            </div>
            <app-validation-message [form]="form" [path]="'coachingLog.categories'" [name]="'categories'" [label]="'Categories'"> </app-validation-message>
          </div>
        </div>
        <div class="row" formGroupName="coachingLog">
          <div class="col-4 d-flex align-items-center">
            <b>Description</b>
          </div>
          <div class="col-8">
            <input type="text" class="form-control mt-2" *ngIf="getForm().get('coachingLog.level').value!='2'" formControlName="description" />
            <textarea class="form-control mt-2" *ngIf="getForm().get('coachingLog.level').value=='2'" formControlName="description"></textarea>
            <app-validation-message [form]="form" [path]="'coachingLog.description'" [name]="'description'" [label]="'Description'"> </app-validation-message>
          </div>
        </div>
        <div class="row" formGroupName="coachingLog">
          <div class="col-4 d-flex align-items-center">
            <b>Follow Up Date</b>
          </div>
          <div class="col-8">
            <input type="date" class="form-control mt-2" formControlName="followUpDate" />
            <app-validation-message [form]="form" [path]="'coachingLog.followUpDate'" [name]="'followUpDate'" [label]="'Follow Up Date'"> </app-validation-message>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-6">
        <i *ngIf="creatingCoachingLog" class="material-icons rotate text-muted">loop</i>
        <button *ngIf="!creatingCoachingLog" class="btn btn-secondary mr-3" [routerLink]="['/coachinglogs/given']">Back</button>
        <button *ngIf="!creatingCoachingLog" class="btn btn-primary" [class.disabled]="form.invalid" (click)="save()">Create</button>


      </div>
    </div>
  </div>
</form>
