<p-dialog [(visible)]="display" header="{{title}}" [modal]="true" [style]="{width: '70vw'}" (onHide)="closeModal()" [focusOnShow]="false">
  <form class="card mt-2" [formGroup]="form" (ngSubmit)="saveEventEdits()">
    <div class="card-body">
      <div class="row">
        <div class="col-12">

          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Event Name</b>
            </div>
            <div class="col-8">
              <input type="text" class="form-control mt-2" formControlName="eventName" />
              <app-validation-message [form]="form" [path]="'eventName'" [label]="'Event Name'"></app-validation-message>
            </div>
          </div>

          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Start Date</b>
            </div>
            <div class="col-8">
              <input type="date" class="form-control mt-2" formControlName="startDateString" />
              <app-validation-message [form]="form" [path]="'startDateString'" [label]="'Start Date'"></app-validation-message>
            </div>
          </div>

          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>End Date</b>
            </div>
            <div class="col-8">
              <input type="date" class="form-control mt-2" formControlName="endDateString" />
              <app-validation-message [form]="form" [path]="'endDateString'" [label]="'End Date'"></app-validation-message>
            </div>
          </div>

          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Modality</b>
            </div>
            <div class="col-8">
              <input type="text" class="form-control mt-2" formControlName="modality" />
              <app-validation-message [form]="form" [path]="'modality'" [label]="'Modality'"></app-validation-message>
            </div>
          </div>
          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Instructor</b>
            </div>
            <div class="col-8 mt-2">
              <p-autoComplete [style]="{'width': '100%'}" [inputStyle]="{'width': '100%'}" formControlName="selectedInstructor"
                              [suggestions]="instructorList" (completeMethod)="getInstructorList($event)" field="label"
                              [minLength]="1" [autoHighlight]="true"
                              (onSelect)="setInstructorAfterInstructorChange($event)" [forceSelection]="true"
                              (onClear)="clearInstructorAfterClear($event)"></p-autoComplete>
              <app-validation-message [form]="form" [path]="'instructorId'" [label]="'Instructor'"></app-validation-message>
            </div>
          </div>

          <div class="row">
            <div class="col-4 d-flex align-items-center">
            </div>
            <div class="col-8">
              <p-checkbox [(ngModel)]="showOnlyCurrentInstructors" [binary]="true" [ngModelOptions]="{standalone: true}"></p-checkbox>
              <b style="margin-left: 2px;">Show only current instructors</b>
            </div>
          </div>

          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Employee(s)</b>
              <i class="fal fa-fw fa-user-plus ml-2" style="cursor: pointer; color: blue;" (click)="addUserToEvent()" title="Add Employee"></i>
            </div>
            <div class="col-8 mt-2">
              <div *ngIf="form">
                <div formArrayName="eventUsers">
                  <div *ngFor="let user of getEventUsers(); let i=index;" class="mt-2" [formGroupName]="i">
                    <p-autoComplete [style]="{'width': '95%'}" [inputStyle]="{'width': '95%'}" formControlName="selectedUser"
                                    [suggestions]="userEmployeeList" (completeMethod)="getStaffList($event)" field="label"
                                    [minLength]="1" [autoHighlight]="true"
                                    (onSelect)="setUserAfterStaffChange($event, i)" [forceSelection]="true"
                                    (onClear)="clearUserAfterClear($event, i)"></p-autoComplete>
                    <i class="fal fa-fw fa-trash pointer" style="color: red;" (click)="removeUserFromEvent(i)" title="Remove Employee"></i>
                  </div>
                  <p-checkbox [(ngModel)]="showOnlyActiveEmployees" [binary]="true" [ngModelOptions]="{standalone: true}"></p-checkbox>
                  <b style="margin-left: 2px;">Show only active employees</b>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Certification(s)</b>
              <i class="fal fa-fw fa-file-plus ml-2" style="cursor: pointer; color: blue;" (click)="addCertificationToEvent()" title="Add Certification"></i><br />
            </div>
            <div class="col-8 mt-2">
              <div formArrayName="certificationTypes">
                <div *ngFor="let cert of getCertificationTypes(); let i=index;" [formGroupName]="i" class="mt-2">
                  <p-dropdown styleClass="form-control mt-2" [options]="certificationTypeOptions" formControlName="certificationTypeId" placeholder="Select Certification..." [style]="{'width': '90%', 'display': 'inline-flex'}"></p-dropdown>
                  <i class="fal fa-fw fa-trash pointer" style="color: red;" (click)="removeCertificationFromEvent(i)" title="Remove Certification"></i>
                  <i class="fal fa-fw fa-plus-circle ml-2" style="cursor: pointer; color: blue;" (click)="addCeuToCertification(i)" title="Add CEU"></i>
                  <div formArrayName="cEUs">
                    <div *ngFor="let ceu of getSubCertificationSections(i); index as j;" class="ml-5" [formGroupName]="j">
                      <p-dropdown styleClass="form-control mt-2" [options]="ceuCategories" formControlName="ceuCategoryId"
                                  [style]="{'width': '40%', 'display': 'inline-flex'}"></p-dropdown>
                      <p-dropdown styleClass="form-control mt-2 ml-2" [options]="ceuTypes" formControlName="ceuTypeId"
                                  [style]="{'width': '40%', 'display': 'inline-flex'}"></p-dropdown>
                      <input type="number" min="0" class="form-control mt-2 ml-2" style="width: 10%; display: inline-flex;" formControlName="units" />
                      <i class="fal fa-fw fa-trash pointer" style="color: red;" (click)="removeCeu(i, j)" title="Remove CEU"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Notes</b>
            </div>
            <div class="col-8">
              <textarea style="width: 100%;" class="form-control mt-2" formControlName="notes"></textarea>
              <app-validation-message [form]="form" [path]="'notes'" [label]="'Notes'"></app-validation-message>
            </div>
          </div>

          <div class="row">
            <div class="col-4 d-flex align-items-center">Uploads</div>
            <div class="col-8">
              <div *ngIf="form">
                <div formArrayName="uploads">
                  <div *ngFor="let u of getUploads(); let i=index;" class="mt-2" [formGroupName]="i">
                    {{u.value.fileName}}
                    <i class="fal fa-fw fa-trash pointer" style="color: red;" (click)="removeUploadFromEvent(i)" title="Remove File"></i>
                  </div>                  
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <label class="btn btn-secondary">
                <input type="file" hidden="hidden" multiple #fileInput accept="image/*,.pdf,.docx" (change)="changedFile(fileInput)"/>
                Add Files
              </label>
            </div>
            <div class="col-8">
              <div *ngIf="form">
                <div formArrayName="uploadedFiles">
                  <div *ngFor="let u of getUploadedFiles(); let i=index;" class="mt-2" [formGroupName]="i">
                    {{u.value.name}}
                    <i class="fal fa-fw fa-trash pointer" style="color: red;" (click)="removeUploadedFileFromEvent(i)" title="Remove Added File"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <p-footer>
    <i class="material-icons rotate text-muted ml-2" *ngIf="disableButtons">loop</i>
    <button class="btn btn-secondary" type="button" (click)="closeModal()">Cancel</button>
    <button class="btn btn-primary" type="submit" (click)="saveEventEdits()" [disabled]="disableButtons">Save</button>
  </p-footer>
</p-dialog>
