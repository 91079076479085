<div
  class="w-100 text-center flex-grow-1 align-items-center d-flex justify-content-center"
  *ngIf="!profile"
>
  <i class="material-icons rotate text-muted ml-2">loop</i>
</div>
<div
  class="w-100 text-center flex-grow-1 align-items-center d-flex justify-content-center"
  *ngIf="profile && !profile.employee"
>
  <div *ngIf="!profile.offerLetter">This user is not an employee</div>
</div>
<div *ngIf="profile?.employee">
  <div class="float-right">
    <button (click)="askHelp()" *ngIf="!managedUserId" style="background-color: red;" class="btn btn-secondary mr-2 mt-1">Ask for Help</button>
    <button (click)="backtoOnboardingEmployees()" *ngIf="managedUserId" class="btn btn-secondary mr-2 mt-1">Back to {{isEmployeeManage?'employees list':'onboarding employees'}} </button>
  </div>
  <div class="text-center w-100 mb-3 mt-3"><h1>{{managedUserId?(profile.user.firstName+' '+profile.user.lastName+ ' - ') :'' }}Employee Onboarding</h1></div>

  <div class="row">
    <div *ngIf="isEmployeeManage"
         class="text-center col-0 col-sm-2 p-2"></div>
    <div *ngIf="!isEmployeeManage"
         class="text-center col-3 col-sm-2 p-2 pointer"
         [class.bg-primary]="step == 'consent' || step == 'start'"
         [class.text-white]="step == 'consent' || step == 'start'"
         (click)="openStep('consent')">
      <i class="fas fa-check-circle text-success"
         *ngIf="onBoardingInfo?.consentDate"></i>
      <i class="fas fa-dot-circle" *ngIf="!onBoardingInfo?.consentDate"></i>

      <div>Eletronic Consent</div>
    </div>
    <div class="text-center col-3 col-sm-2 p-2 pointer"
         [class.bg-primary]="step == 'upload'"
         [class.text-white]="step == 'upload'"
         (click)="openStep('upload')">
      <i class="fas fa-check-circle text-success"
         *ngIf="(managedUserId && onBoardingInfo?.uploadedDocsApprovedDate) || (!managedUserId && allUploaded)"></i>
      <i class="fas fa-dot-circle"
         *ngIf="!((managedUserId && onBoardingInfo?.uploadedDocsApprovedDate) || (!managedUserId && allUploaded))"></i>

      <div>Upload Documents</div>
    </div>
    <div *ngIf="!isEmployeeManage"
         class="text-center col-3 col-sm-2 p-2 pointer"
         [class.bg-primary]="step == 'i9Form'"
         [class.text-white]="step == 'i9Form'"
         (click)="openStep('i9Form')">

      <i class="fas fa-check-circle text-success"
         *ngIf="(managedUserId && onBoardingInfo?.i9ApprovedDate) || (!managedUserId && profile.i9FormSignedDate)"></i>
      <i class="fas fa-dot-circle"
         *ngIf="!((managedUserId && onBoardingInfo?.i9ApprovedDate) || (!managedUserId && profile.i9FormSignedDate))"></i>
      <br />
      <div>I-9 Form</div>
    </div>
    <div class="text-center col-3 col-sm-2 p-2 pointer"
         [class.bg-primary]="step == 'employee info'"
         [class.text-white]="step == 'employee info'"
         (click)="openStep('employee info')">
      <i class="fas fa-check-circle text-success"
         *ngIf="onBoardingInfo?.employeeInfoFilled"></i>
      <i class="fas fa-dot-circle"
         *ngIf="!onBoardingInfo?.employeeInfoFilled"></i>
      <br />
      <div>Employee Info</div>
    </div>
    <div class="text-center col-3 col-sm-2 p-2 pointer"
         [class.bg-primary]="step == 'sign'"
         [class.text-white]="step == 'sign'"
         (click)="openSign()">
      <i class="fas fa-check-circle text-success"
         *ngIf="(managedUserId && onBoardingInfo?.allDocumentsSignedApprovedDate) || (!managedUserId && allDocsSigned)"></i>
      <i class="fas fa-dot-circle"
         *ngIf="!((managedUserId && onBoardingInfo?.allDocumentsSignedApprovedDate) || (!managedUserId && allDocsSigned))"></i>
      <br />
      <div>Sign Documents</div>
    </div>
    <div *ngIf="!isEmployeeManage"
         class="text-center col-3 col-sm-2 p-2 pointer"
         [class.bg-primary]="step == 'summary'"
         [class.text-white]="step == 'summary'"
         (click)="openStep('summary')">
      <i class="fas fa-check-circle text-success" *ngIf="managedUserId && checkEnableFinish()"></i>
      <i class="fas fa-dot-circle" *ngIf="!(managedUserId && checkEnableFinish())"></i>
      <br />
      <div>Summary {{ managedUserId ? "" : "and Finish" }}</div>
    </div>

    <div *ngIf="this.managedUserId || (onBoardingInfo && onBoardingInfo.showedUpOnOrientation)" class="text-center col-3 col-sm-2 p-2 pointer"
         [class.bg-primary]="step == 'employee reviews'"
         [class.text-white]="step == 'employee reviews'"
         (click)="openStep('employee reviews')">
      <i class="fas fa-check-circle text-success"
         *ngIf="oversightEmployeeReviewFilled"
         title="Quarterly oversight review done."></i>
      <i class="fas fa-dot-circle"
         *ngIf="!oversightEmployeeReviewFilled"
         title="Quarterly oversight review missing."></i>
      <br />
      <div>Employee Reviews</div>
    </div>

    <div class="col-12">
      <div class="card pt-3 pb-3 pl-4 pr-4 mt-3"
           *ngIf="step == 'consent' || step == 'start'">
        <div class="card-body">
          <h1>Consent to Proceed Electronically</h1>
          <div *ngIf="!managedUserId">
            <p>
              Please read the following statement and provide your consent to
              proceed electronically.
            </p>
            <div class="rolling-area mb-3">
              Before {{generalSettings?.businessFullName}} can accept your electronic
              signature for personnel documents and agreements related to your
              employment or prospective employment; you should be aware of the
              following information and must affirmatively agree to the
              following:
              <ul>
                <li>
                  If you proceed, you are agreeing to complete this process
                  electronically.
                </li>
                <li>
                  Your responses to all questions throughout the electronic
                  process will be recorded and made part of your electronically
                  signed documents and employment record.
                </li>
                <li>
                  You have the option to complete this process using the
                  traditional paper and signature process. You must contact Utah
                  Behavior Services, Inc 801-255-5131, if you wish to sign your
                  documents and agreements in ink. Please note, if you elect to
                  utilize the traditional paper and signature process, it will
                  slow the speed at which we can complete certain steps in the
                  employment or prospective employment process because we will
                  need first to send the required notices or disclosures to you
                  in paper format, and then wait until we receive back from you
                  your acknowledgment of your receipt of such paper notices of
                  disclosures.
                </li>
                <li>
                  To use this process, you will use the secure link sent to you
                  via your e-mail and the unique password you have created. You
                  must keep this login information confidential as it can be
                  used to electronically sign additional documents.
                </li>
                <li>
                  You acknowledge it is a violation of {{generalSettings?.businessFullName}}
                   policy to execute a document with an electronic signature
                  of someone other than yourself, which could result in
                  discipline or non-hire.
                </li>
                <li>
                  While you are onboarding, you can return to the onboarding
                  process at any time (using the secure link through your email)
                  to review and print the documents you signed electronically.
                </li>
                <li>
                  You have the right to request a paper copy of any documents
                  you signed electronically.
                </li>
                <li>
                  You have read and agree with the verification and security
                  procedures ("Privacy Policy/Statement") adopted by Utah
                  Behavior Services, Inc.
                </li>
              </ul>
              By clicking on the "Accept" button, you acknowledge and agree to
              all of the following:
              <ul>
                <li>I have read and understood the foregoing;</li>
                <li>
                  I agree to proceed with using my electronic signature to sign
                  personnel related documents and agreements and realize this
                  process affects substantial personal rights;
                </li>
                <li>
                  I agree that my electronic signature is the equivalent of a
                  manual signature and that {{generalSettings?.businessFullName}}  may rely
                  on it as such in connection with any and all agreements I may
                  enter into with {{generalSettings?.businessFullName}}  and any documents
                  I electronically sign for {{generalSettings?.businessFullName}} ;
                </li>
                <li>
                  I agree that my electronic signature in this process will
                  consist of my typed name or a checked box (as applicable),
                  {{generalSettings?.businessFullName}}  will accept as my electronic
                  signature.
                </li>
                <li>
                  I acknowledge and agree that it is my obligation to
                  immediately advise {{generalSettings?.businessFullName}}  of any change
                  in my electronic address (i.e., email address) by sending an
                  email to {{generalSettings?.businessFullName}}  and including in the body
                  of such request: my previous email address and my new email
                  address;
                </li>
                <li>
                  I have the necessary hardware/software to complete the
                  process;
                </li>
                <li>
                  I acknowledge and agree that it is my obligation to
                  immediately advise {{generalSettings?.businessFullName}}  in the event
                  that I withdraw my consent to use electronic means to signed
                  personnel documents and agreements (if applicable) by sending
                  an email with my withdrawal request to {{generalSettings?.businessFullName}} ;
                </li>
                <li>
                  I agree that in the event I withdraw my consent, any documents
                  I electronically signed prior to {{generalSettings?.businessFullName}}
                  receiving notification of my withdrawal will be considered
                  validly executed and the withdrawal of my consent does not
                  apply retroactively;
                </li>
                <li>
                  I acknowledge and agree that the various security and
                  verification procedures adopted by {{generalSettings?.businessFullName}}
                   as its Privacy Policy/Statement [INSERT LINK TO YOUR
                  PRIVACY POLICY], applies to the documents that I will affix my
                  electronic signature and constitute reasonable and secure
                  security procedures and that all such signatures are
                  attributable to me;
                </li>
                <li>
                  I acknowledge and agree that in the event that any person
                  known to me (whether it be a family member, member of my
                  household, or otherwise) misappropriates any of the security
                  devices connected with my {{generalSettings?.businessFullName}}
                  login/email/electronic signature account and such
                  misappropriation could not reasonably be detected by Utah
                  Behavior Services, Inc, {{generalSettings?.businessFullName}}  shall have
                  the right to treat all resulting electronic signatures as
                  though they were affixed by the person whose name is typed
                  below;
                </li>
                <li>
                  I acknowledge and agree that the individual completing this
                  Consent to Proceed Electronically is the individual whose name
                  appears on the email account associated with this Consent and
                  whose name is typed below.
                </li>
              </ul>
            </div>

            <p>
              If you understand, accept and agree to the policies, terms and
              conditions set out above, then type your name and press "Accept".
              Then press "Continue" to proceed to the next form. By typing your
              name below, you understand your typed name will be recorded as
              your electronic signature and will be relied upon by {{generalSettings?.businessFullName}}
              to the same extent as if you had signed this consent
              in ink.
            </p>
            <p>
              If you do not understand or accept or agree to the policies, terms
              and conditions set out above, then press "Decline" and you will
              exit this process.
            </p>
          </div>
          <div *ngIf="managedUserId">
            <i class="fas fa-check-circle text-success"
               *ngIf="onBoardingInfo?.consentDate"></i>
            <i class="fas fa-dot-circle"
               *ngIf="!onBoardingInfo?.consentDate"></i>
            &nbsp;&nbsp;&nbsp; {{!onBoardingInfo?.consentDate?'Not yet':'' }} Consented
          </div>
          <div class="card mt-2 mt-lg-0 h-100 mb-2">
            <div class="card-body">
              <h4 class="mb-3">Employee Signature</h4>
              <app-signature class="d-block w-100 mt-3" [managedUserId]="managedUserId"></app-signature>
            </div>
          </div>
          <div>
            <button class="btn btn-primary"
                    *ngIf="!managedUserId"
                    (click)="proceedConsent()"
                    [disabled]="!profile.viewSignatureUrl">
              Consent and Proceed
            </button>
          </div>
        </div>
      </div>
      <div class="card pt-3 pb-3 pl-4 pr-4 mt-3" *ngIf="step == 'upload'">
        <div class="card-body">
          <app-onboarding-uploaddocs [profile]="profile"
                                     [managedUserId]="managedUserId"></app-onboarding-uploaddocs>
          <app-uploads-certification *ngIf="profile" [profile]="profile" [managedUserId]="managedUserId">
                </app-uploads-certification>
          <div class="mb-3" *ngIf="managedUserId && !onBoardingInfo?.uploadedDocsApprovedDate">
            When all needed documents are uploaded, click here to approve this whole section:
            <button class="btn btn-primary" [attr.disabled]="allUploaded?null:true" (click)="approveAllUploads()">
              Finish Approving Uploads
            </button>
          </div>
          <div>
            <button class="btn btn-primary" (click)="proceedUpload()">
              Next
            </button>
          </div>
        </div>
      </div>
      <div class="card pt-3 pb-3 pl-4 pr-4 mt-3" *ngIf="step == 'i9Form'">
        <div class="card-body">
          <div *ngIf="!managedUserId && !profile.i9FormSignedDate">
            <div *ngIf="!onBoardingInfo?.i9ApprovedDate">
              <app-form-fill *ngIf="!showi9sign" [doNotNavigate]="true" [formID]="profile.i9FormId" [formSubmissionID]="profile.i9FormSubmissionId" [hideSaveButon]="true" ></app-form-fill>
              <app-form-sign *ngIf="showi9sign" [doNotNavigate]="true" [formSubmissionID]="profile.i9FormSubmissionId"  ></app-form-sign>
            </div>
          </div>
          <div *ngIf="(managedUserId || profile.i9FormSignedDate) && !openedApprovedi9
            ">
            <h1>I-9 Form</h1>
            &nbsp;&nbsp;&nbsp; Signed &nbsp;
            <i class="fas fa-check-circle text-success"
               *ngIf="profile.i9FormSignedDate"></i>
            &nbsp;&nbsp;&nbsp;
            <i class="fas fa-dot-circle" *ngIf="!profile.i9FormSignedDate"></i>
            &nbsp;&nbsp;&nbsp;Approved &nbsp;
            <i class="fas fa-check-circle text-success"
               *ngIf="onBoardingInfo?.i9ApprovedDate"></i>

            <i [title]="'Rejected: '+onBoardingInfo?.i9DenyMessage"
               class="fas fa-times-circle text-danger"
               *ngIf="onBoardingInfo?.i9DenyMessage"></i>
            <i class="fas fa-dot-circle"
               *ngIf="!onBoardingInfo?.i9ApprovedDate && (!managedUserId || !profile.i9FormSignedDate) && !onBoardingInfo?.i9DenyMessage"></i>
            <i class="fas fa-check-circle "
               [title]="canApproveI9Form?'Click to approve I-9 Form':'You do not have permission to approve the i9Form'"
               (click)="approvei9()"
               [class.text-muted]="!canApproveI9Form" [class.pointer]="canApproveI9Form" [class.text-primary]="canApproveI9Form"
               *ngIf="managedUserId && !onBoardingInfo?.i9ApprovedDate && profile.i9FormSignedDate"></i>

            <i class="fas fa-times-circle ml-1"
               [title]="canApproveI9Form?'Click to reject I-9 Form':'You do not have permission to reject the i9Form'"
               (click)="openCloseI9DenyMessage()"
               *ngIf="managedUserId && !onBoardingInfo?.i9DenyMessage && profile.i9FormSignedDate && !onBoardingInfo?.i9ApprovedDate"
               [class.text-muted]="!canApproveI9Form" [class.pointer]="canApproveI9Form" [class.text-primary]="canApproveI9Form"></i>
            <div *ngIf="i9DenyInfo.denyMessageOpen" class="d-inline-flex">
              <input type="text" class="form-control ml-2" [(ngModel)]="i9DenyInfo.denyMessageToSend" />
              <button class="btn btn-secondary ml-2 " [disabled]="!i9DenyInfo.denyMessageToSend" (click)="denyI9()">Reject</button>
            </div>

            <div class="row mt-3 mb-3" *ngIf="profile.i9FormSignedDate">
              <div class="col-3 text-center">
                <img class="file-image pointer"
                     src="/assets/img/pdf.png"
                     (click)="downloadI9Form()" />
                <div class="file-label" [title]="'I-9 Form'">I-9 Form.pdf</div>
              </div>
            </div>
          </div>
          <div *ngIf="managedUserId && openedApprovedi9 && !onBoardingInfo?.i9ApprovedDate ">

            <div *ngIf="true">
              <button class="btn btn-primary mb-3" (click)="seeDocsOnI9 = !seeDocsOnI9">
                <i class="fas fa-arrow-circle-down"></i>
                See uploaded docs
              </button>
              <div *ngIf="seeDocsOnI9" class="card flyover">
                <div class="card-body">
                  <app-onboarding-uploaddocs [profile]="profile"
                                             [managedUserId]="managedUserId" [readonly]="true"></app-onboarding-uploaddocs>
                </div>
              </div>
            </div>
            <div>
              <app-form-fill *ngIf="!showi9signHR" [doNotNavigate]="true" [editingAsHR]="true" [formID]="profile.i9FormId" [formSubmissionID]="profile.i9FormSubmissionId" [hideSaveButon]="true" ></app-form-fill>
              <app-form-sign *ngIf="showi9signHR" [doNotNavigate]="true" [isSigningAsHRManager]="true" [formSubmissionID]="profile.i9FormSubmissionId"  ></app-form-sign>

            </div>
          </div>
          <div>
            <button class="btn btn-primary mt-3" (click)="proceedi9Form()">
              Next
            </button>
          </div>
        </div>
      </div>

      <div class="card pt-3 pb-3 pl-4 pr-4 mt-3" *ngIf="step == 'employee info'">
        <div class="card-body">
          <h1>Employee Information</h1>
          <div *ngIf="managedUserId">
            <a [href]="optomiserUrl()" target="newwindow">Edit Employee Info on Optomiser</a>
          </div>
          <app-contact-info [managedUserId]="managedUserId"></app-contact-info>
          <div *ngIf="managedUserId">
            <a [href]="optomiserUrl()" target="newwindow">Edit Employee Info on Optomiser</a>
          </div>
          <button class="btn btn-primary mt-3" (click)="proceedEmployeeInfo()" [class.disabled]="!onBoardingInfo?.employeeInfoFilled">
            Next
          </button>
        </div>
      </div>

      <div class="card pt-3 pb-3 pl-4 pr-4 mt-3" *ngIf="step == 'sign'">
        <div class="card-body onboarding">
          <router-outlet></router-outlet>
          <ng-container *ngIf="managedUserId">
            <div class="mb-3 mt-3" *ngIf="managedUserId && !onBoardingInfo?.allDocumentsSignedApprovedDate">
              When all needed documents are signed, click here to approve this whole section:
              <button class="btn btn-primary" [attr.disabled]="allDocsSigned?null:true" (click)="approveSignedDocs()">
                Approve Signed Docs
              </button>
            </div>
          </ng-container>
          <button class="btn btn-primary mt-3" *ngIf="!isEmployeeManage" (click)="proceedSign()">
            Next
          </button>
        </div>
      </div>

       <div class="card pt-3 pb-3 pl-4 pr-4 mt-3" *ngIf="step == 'employee reviews'">
          <app-employee-review-list [paramUserId]="profile.userId"></app-employee-review-list>
      </div>

      <div class="card pt-3 pb-3 pl-4 pr-4 mt-3" *ngIf="step == 'summary'">
        <div class="card-body">
          <h1>Summary</h1>
          <table class="table">
            <thead>
              <tr>
                <th>Edit</th>
                <th>Step</th>
                <th>Employee Complete</th>
                <th>HR Verified</th>
              </tr>
            </thead>

            <tr>
              <td>
                <i class="fas fa-pencil-alt pointer"
                   (click)="step = 'consent'"></i>
              </td>
              <td>Consent</td>
              <td>
                <i class="fas fa-check-circle text-success"
                   *ngIf="onBoardingInfo?.consentDate"></i>
                <i class="fas fa-dot-circle"
                   *ngIf="!onBoardingInfo?.consentDate"></i>
              </td>
              <td>
                <i class="fas fa-check-circle text-success"
                   *ngIf="onBoardingInfo?.consentDate"></i>
                <i class="fas fa-dot-circle"
                   *ngIf="!onBoardingInfo?.consentDate"></i>
              </td>
            </tr>
            <tr>
              <td>
                <i class="fas fa-pencil-alt pointer"
                   (click)="step = 'upload'"></i>
              </td>
              <td>Upload Documents</td>
              <td>
                <i class="fas fa-check-circle text-success"
                   *ngIf="allUploaded"></i>
                <i class="fas fa-dot-circle"
                   *ngIf="!allUploaded"></i>
              </td>
              <td>
                <i class="fas fa-check-circle text-success"
                   *ngIf="onBoardingInfo?.uploadedDocsApprovedDate"></i>
                <i class="fas fa-dot-circle"
                   *ngIf="!onBoardingInfo?.uploadedDocsApprovedDate"></i>
              </td>
            </tr>
            <tr>
              <td>
                <i class="fas fa-pencil-alt pointer"
                   (click)="step = 'i9Form'"></i>
              </td>
              <td>I-9 Form</td>
              <td>
                <i class="fas fa-check-circle text-success"
                   *ngIf="profile?.i9FormSignedDate"></i>
                <i class="fas fa-dot-circle"
                   *ngIf="!profile?.i9FormSignedDate"></i>
              </td>
              <td>
                <i class="fas fa-check-circle text-success"
                   *ngIf="onBoardingInfo?.i9ApprovedDate"></i>
                <i class="fas fa-dot-circle"
                   *ngIf="!onBoardingInfo?.i9ApprovedDate"></i>
              </td>
            </tr>
            <tr>
              <td>
                <i class="fas fa-pencil-alt pointer"
                   (click)="step = 'employee info'"></i>
              </td>
              <td>Employee Info</td>
              <td>
                <i class="fas fa-check-circle text-success"
                   *ngIf="onBoardingInfo?.employeeInfoFilled"></i>
                <i class="fas fa-dot-circle"
                   *ngIf="!onBoardingInfo?.employeeInfoFilled"></i>
              </td>
              <td>
                <i class="fas fa-check-circle text-success"
                   *ngIf="onBoardingInfo?.employeeInfoFilled"></i>
                <i class="fas fa-dot-circle"
                   *ngIf="!onBoardingInfo?.employeeInfoFilled"></i>
              </td>
            </tr>
            <tr>
              <td>
                <i class="fas fa-pencil-alt pointer" (click)="openSign()"></i>
              </td>
              <td>Sign Documents</td>
              <td>
                <i class="fas fa-check-circle text-success"
                   *ngIf="allDocsSigned"></i>
                <i class="fas fa-dot-circle"
                   *ngIf="!allDocsSigned"></i>
              </td>
              <td>
                <i class="fas fa-check-circle text-success"
                   *ngIf="onBoardingInfo?.allDocumentsSignedApprovedDate"></i>
                <i class="fas fa-dot-circle"
                   *ngIf="!onBoardingInfo?.allDocumentsSignedApprovedDate"></i>
              </td>
            </tr>
          </table>
          <div *ngIf="managedUserId && onBoardingInfo && !onBoardingInfo.showedUpOnOrientation">
            <h3>Orientation Date</h3>
            <input type="date" class="form-control" placeholder="orientationDate" [(ngModel)]="orientationDate" min="{{minDate()}}" />
            <span class="badge-danger rounded p-2" *ngIf="onBoardingInfo && onBoardingInfo.rejectOrientationDate">Date Rejected</span>
            <br>
            <button [disabled]="!orientationDate"
                    class="btn btn-primary mt-3"
                    (click)="setOrientationDate()">
              Set Orientation Date
            </button>
          </div>
          <div *ngIf="!managedUserId && onBoardingInfo && !onBoardingInfo?.rejectOrientationDate && profile && profile?.employee?.orientationDate">
            <h3>Orientation Date</h3>
            <div>{{getShortDateFormatted(profile?.employee?.orientationDate)}}</div>
            <button *ngIf="onBoardingInfo && !onBoardingInfo?.finishedDate"
                    class="btn btn-primary mt-3"
                    (click)="finish()">
              Confirm Orientation Date
            </button>
            <button *ngIf="onBoardingInfo && !onBoardingInfo?.finishedDate"
                    class="btn btn-danger ml-3 mt-3"
                    (click)="rejectOrientationDate()">
              Reject Orientation Date
            </button>
          </div>
          <div *ngIf="!managedUserId && ((onBoardingInfo && onBoardingInfo?.rejectOrientationDate) || (profile && !profile?.employee?.orientationDate))">
            <h3>Orientation Date</h3>
            Waiting for Orientation Date
          </div>
          <div *ngIf="!managedUserId && onBoardingInfo?.finishedDate && allDoneByUser()">
            <div class="alert alert-success mt-2">You've completed all your new hire paperwork! We look forward to seeing you for your orientation! HR will be reviewing your completed documents for accuracy and completeness. If there is an issue with any of your documentation, you will receive an email to correct any issues prior to your orientation. If you have any questions, please use the "Ask for Help" button in the upper right corner of your screen to send us a message! We're excited to have you start as part of our team!</div>
          </div>
          <div *ngIf="!managedUserId && onBoardingInfo?.finishedDate && !allDoneByUser()">
            <div class="alert alert-info mt-2">You've accpeted the orientation date but there are still some things pending to complete on your side. Please complete all the required information before coming to the orientation and if you have any questions, please use the "Ask for Help" button in the upper right corner of your screen to send us a message! </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<p-dialog header="Ask for help" [modal]="true" appendTo="body" [style]="{width: '70vw'}" [(visible)]="displayAskForHelp">
  <div [formGroup]="formAskHelp">

    <div class="row  m-0"  >
      <div class="col-4 d-flex align-items-center">
        <b>Email</b>
      </div>
      <div class="col-8">
        <input
          type="text"
          class="form-control mt-2"
          formControlName="email"
        />
        <app-validation-message [form]="formAskHelp" [path]="'email'" [name]="'email'" [label]="'Email'"> </app-validation-message>
      </div>
    </div>
    <div class="row  m-0"  >
      <div class="col-4 d-flex align-items-center">
        <b>Phone</b>
      </div>
      <div class="col-8">
        <input
          type="text"
          class="form-control mt-2"
          formControlName="phone"
        />
        <app-validation-message [form]="formAskHelp" [path]="'phone'" [name]="'phone'" [label]="'Phone'"> </app-validation-message>
      </div>
    </div>
    <div class="row  m-0"  >
      <div class="col-4 d-flex align-items-center">
        <b>Prefered Contact</b>
      </div>
      <div class="col-8 mt-2 mb-2">
        <p-selectButton [options]="emailPhoneOptions" formControlName="preferedContact"></p-selectButton>
        <app-validation-message [form]="formAskHelp" [path]="'preferedContact'" [name]="'preferedContact'" [label]="'Prefered Contact'"> </app-validation-message>
      </div>
    </div>
    <div class="row m-0"  >
      <div class="col-4 d-flex align-items-center">
        <b>Message</b>
      </div>
      <div class="col-8">
        <textarea
          class="form-control mt-2"
          formControlName="message"
        ></textarea>
        <app-validation-message [form]="formAskHelp" [path]="'message'" [name]="'message'" [label]="'Message'"> </app-validation-message>
      </div>
    </div>
  </div>
  <div class="text-right mt-2">
    <div class="col-12" >
      <button class="btn btn-primary" (click)="sendAskForHelp()">Send</button>
    </div>
  </div>
</p-dialog>
