import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { Actions, ofType } from '@ngrx/effects';
import { State } from '../../../../core/store';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { AlertService } from '../../../../shared/services/alert.service';
import { InternApplicationActionTypeModel } from '../../../../models/internModels';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { deepClone, downloadFile } from '../../../../helpers/utils';
import {
  deleteInternActionType, deleteInternActionTypeSuccess, exportManageInternApplicationsListToExcel, exportManageInternApplicationsListToExcelSuccess,
  getInternActionTypeList, saveInternActionTypeSuccess, showEditInternActionType
} from '../../../../core/store/intern/intern.action';

@Component({
  selector: 'app-list-intern-action-type-manage',
  templateUrl: './list-intern-action-type-manage.component.html',
  styleUrls: ['./list-intern-action-type-manage.component.scss']
})
export class ListInternActionTypeManageComponent extends BaseComponent implements OnInit {
  list: PagingResultsModel<InternApplicationActionTypeModel>;
  filter: any = {};
  loadingList: boolean = true;
  loadingExcel: boolean = false;
  savingType: boolean = false;

  canManage;

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private alertService: AlertService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.subs.push(
      this.store.select(s => s.accountState.user.permissionIDs).subscribe(permissionIDs => {
        this.canManage = permissionIDs && !!permissionIDs.find(p => p === 3128); //Manage Intern Applications
      }),
      this.store.select(s => s.internState.loadingActionTypeList).subscribe(loading => {
        this.loadingList = loading;
      }),
      this.store.select(s => s.internState.savingActionType).subscribe(saving => {
        this.savingType = saving;
      }),
      this.store.select(s => s.internState.actionTypeList).subscribe(report => {
        this.list = report;
      }),      
      this.actions$.pipe(
        ofType(saveInternActionTypeSuccess),
        tap(action => {
          this.alertService.success("Success");
          this.reloadList();
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(deleteInternActionTypeSuccess),
        tap(action => {
          this.alertService.success("Success");
          this.reloadList();
        })
      ).subscribe(),
    );
  }

  reloadFilter(event) {
    this.filter = event;
    this.reloadList();
  }

  reloadList() {
    this.setFilter();

    this.store.dispatch(getInternActionTypeList({
      filter: this.filter
    }));
  }      

  setFilter() {    
    this.filter = deepClone(this.filter);
  }

  editActionType(item?: InternApplicationActionTypeModel) {
    const actionTypeId = typeof item !== 'undefined' && typeof item.actionTypeId !== 'undefined' ? item.actionTypeId : 0;
    this.store.dispatch(showEditInternActionType({ actionTypeId: actionTypeId }));
  }

  deleteActionType(list: InternApplicationActionTypeModel) {
    this.alertService.confirm('Delete Action Type',
      'Are you sure you want to delete this action type? The action type will be archived if it has already been assigned to an action item.')
      .subscribe(
        answer => {
          if (answer) {
            this.store.dispatch(deleteInternActionType({ actionTypeId: list.actionTypeId }));
          }
        }, error => {
        }
      );
  }

}
