import { createAction, props } from '@ngrx/store';
import { EthicCodeModel } from '../../../models/ethicCodeModels';
import { PagingResultsModel } from '../../../models/pagingResultsModel';

export const loadEthicCodes = createAction('[Ethic Codes] Load Ethic Codes',
  props());
export const loadEthicCodesSuccess = createAction('[Ethic Codes] Load Ethic Codes Success',
  props<{ ethicCodes: EthicCodeModel[] }>());

export const getEthicCode = createAction('[Ethic Codes] Get Ethic Code',
  props<{ ethicCodeId: number }>());
export const getEthicCodeSuccess = createAction('[Ethic Codes] Get Ethic Code Success',
  props<{ ethicCode: EthicCodeModel }>());

export const showEthicCodeModal = createAction('[Ethic Codes] Open Ethic Code Modal', props<{ ethicCode: EthicCodeModel }>());
export const closeEthicCodeModal = createAction('[Ethic Codes] Close Ethic Code Modal');

export const saveEthicCodes = createAction('[Ethic Codes] Save Ethic Codes', props<{ ethicCode: EthicCodeModel }>());
export const saveEthicCodeSuccess = createAction('[Ethic Codes] Save Ethic Codes Success');
export const saveEthicCodeFailure = createAction('[Ethic Codes] Save Ethic Codes Failure');

export const deleteEthicCode = createAction('[Ethic Codes] Delete Ethic Codes', props<{ ethicCodeId: number }>());
export const deleteEthicCodeSuccess = createAction('[Ethic Codes] Delete Ethic Codes Success');
export const deleteEthicCodeFailure = createAction('[Ethic Codes] Delete Ethic Codes Failure');
