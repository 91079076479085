<form class="card mt-2"  *ngIf="canEdit">
  <div class="card-body">
    <h4 class="mb-3">Return Equipment</h4>
    <div class="row">
      <div class="col-12 col-xl-6">
        <div class="row" >
          <div class="col-4 d-flex align-items-center">
            <h5>User</h5>
          </div>
          <div class="col-8">
            <p-dropdown styleClass="form-control mt-2" [options]="usersList" [(ngModel)]="userID" [filter]="true" [resetFilterOnHide]="true" (ngModelChange)="changedUser()" [ngModelOptions]="{standalone: true}" placeholder="Select user..."></p-dropdown>
            <div style="font-size:.75rem;" class="mt-2">
              <label for="includeDerminated">Include Separated Employees: </label>
              <p-checkbox id="includeTerminated" name="includeTerminated" styleClass="form-check-input ml-4" [(ngModel)]="includeTerminated" (onChange)="includeTerminatedChange($event)"></p-checkbox>
            </div>
            <small  *ngIf="showErrors && !this.userID" class="text-danger">Select a user to assign the equipment</small>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="userID">
      <div class="col-12 ">

        <h5 class="mt-3">Equipment</h5>
        <p-table class="mt-2"  [value]="assignedEquipmentsToUser" [responsive]="true" [rowTrackBy]="trackByFn">
            <ng-template pTemplate="header">
              <tr>
                <th>Returned</th>
                <th width="90px" [pSortableColumn]="'type'">Type <p-sortIcon [field]="'type'"></p-sortIcon></th>
                <th [pSortableColumn]="'serialNumber'">Serial <p-sortIcon [field]="'serialNumber'"></p-sortIcon></th>
                <th [pSortableColumn]="'brand-model'">Brand - Model <p-sortIcon [field]="'brand-model'"></p-sortIcon></th>
                <th [pSortableColumn]="'assetTag'">{{generalSettings?.businessName}} # <p-sortIcon [field]="'assetTag'"></p-sortIcon></th>
                <th [pSortableColumn]="'serviceTag'">Service Tag <p-sortIcon [field]="'serviceTag'"></p-sortIcon></th>
                <th [pSortableColumn]="'expressServiceCode'">Express Service Code <p-sortIcon [field]="'expressServiceCode'"></p-sortIcon></th>
                <th [pSortableColumn]="'docsSigned'">Documents Signed <p-sortIcon [field]="'docsSigned'"></p-sortIcon></th>

              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-equipment>
              <tr>
                <td>
                  <p-checkbox [ngModelOptions]="{standalone: true}" [(ngModel)]="equipment.returned" (ngModelChange)="changedEquipment(equipment)" [binary]="true"></p-checkbox>
                   - Return Date: {{equipment.returnDate | date:'shortDate' }}
                <td>
                  <span class="ui-column-title">Type</span>
                  {{equipment.equipment.type}}
                </td>

                <td>
                  <span class="ui-column-title">Serial</span>
                  {{equipment.equipment.serialNumber }}
                </td>

                <td>
                  <span class="ui-column-title">Brand - Model</span>
                  {{equipment.equipment.brand }} - {{equipment.equipment.model }}
                </td>

                <td>
                  <span class="ui-column-title">{{generalSettings?.businessName}} #</span>
                  {{equipment.equipment.assetTag }}
                </td>
                <td>
                  <span class="ui-column-title">Service Tag</span>
                  {{equipment.equipment.serviceTag }}
                </td>
                <td>
                  <span class="ui-column-title">Express Service Code</span>
                  {{equipment.equipment.expressServiceCode }}
                </td>
                <td>
                  <span class="ui-column-title">Documents Signed</span>
                  {{ equipment.assignSigned ? 'Yes' : 'No' }}
                </td>
              </tr>
                <tr *ngIf="equipment.returned" >
                  <td colspan="2">
                    <b>Date Returned:</b><br />
                    <input type="date" class="form-control mt-2" [ngModelOptions]="{standalone: true}" [(ngModel)]="equipment.dateReturnedString" (ngModelChange)="changedEquipment(equipment)" />
                  </td>
                  <td colspan="2">
                    <b>Status:</b><br>
                    <select class="form-control"  [ngModelOptions]="{standalone: true}" [(ngModel)]="equipment.returnStatus" (ngModelChange)="changedEquipment(equipment)" >
                      <option value="Good">Good</option>
                      <option value="Damaged">Damaged</option>
                      <option value="Destroyed">Destroyed</option>
                      <option value="Not Returned">Not Returned</option>
                    </select>
                  </td>
                  <td colspan="3">
                    <b>Notes:</b><br>
                    <textarea class="form-control"  [ngModelOptions]="{standalone: true}" [(ngModel)]="equipment.returnNotes" (ngModelChange)="changedEquipment(equipment)"></textarea>
                  </td>
                </tr>
            </ng-template>
        </p-table>
        <div class="row mb-4" >

            <div class="col-12">
                <small  *ngIf="showErrors && !returningEquipments.length" class="text-danger">Select at least one equipment to return</small>
            </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="userID">
      <div class="col-12 ">

        <h5 class="mt-3">Acessories</h5>
        <p-table class="mt-2"  [value]="assignedAcessories" [responsive]="true" >
            <ng-template pTemplate="header">
                <tr>

                    <th >Returned</th>
                    <th >Damaged</th>
                    <th [pSortableColumn]="'equipmentAssignedID'">Equipment <p-sortIcon [field]="'equipmentAssignedID'"></p-sortIcon></th>
                    <th [pSortableColumn]="'name'">Acessory Name <p-sortIcon [field]="'name'"></p-sortIcon></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-acessory>
                <tr >
                    <td>
                        <p-checkbox [ngModelOptions]="{standalone: true}" [(ngModel)]="acessory.returned" (ngModelChange)="changedAcessory(acessory)" [binary]="true"></p-checkbox>
                    </td>
                    <td>
                        <p-checkbox [ngModelOptions]="{standalone: true}" [(ngModel)]="acessory.damaged" (ngModelChange)="changedAcessory(acessory)" [binary]="true"></p-checkbox>
                    </td>
                    <td>
                        <span class="ui-column-title">Equipment</span>
                        {{acessory.equipmentLabel}}</td>

                      <td>
                        <span class="ui-column-title">Acessory Name</span>
                      {{acessory.name}}</td>
                </tr>
            </ng-template>
        </p-table>
      </div>
    </div>

    <div class="mt-4 float-right">
      <i *ngIf="savingReturnEquipments" class="material-icons rotate text-muted">loop</i>
      <button *ngIf="!savingReturnEquipments" class="btn btn-primary"  (click)="save()">Return</button>
    </div>

  </div>
</form>
