import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, act } from '@ngrx/effects';
import { TimeoffService } from '../../services/timeoff.service';
import { State } from '..';
import { Store } from '@ngrx/store';
import {
  loadViewableCampuses, loadViewableCampusesSuccess, loadClassifications, loadClassificationsSuccess,
  getSummaryList, getSummaryListSuccess, getEmployeePtoSummary, getEmployeePtoSummarySuccess,
  getEmployeeEarnedPto, getEmployeeEarnedPtoSuccess, getEmployeeRequestedPto, getEmployeeRequestedPtoSuccess,
  savePtoRequest, savePtoRequestSuccess, getRecentTimeOff, getRecentTimeOffSuccess, getUpcomingTimeOff,
  getUpcomingTimeOffSuccess,
  saveTimeOffRequest,
  saveTimeOffRequestSuccess,
  getManagePtoEarnedList,
  getManagePtoEarnedListSuccess,
  getManageTimeOffList,
  getManageTimeOffListSuccess,
  savePtoEarned,
  savePtoEarnedSuccess,
  saveTimeOffApproval,
  resetTimeOffApproval,
  resetTimeOffApprovalSuccess,
  getTimeOffApprovalSummary,
  getTimeOffApprovalSummarySuccess,
  saveTimeOffApprovalSuccess,
  removeTimeOffRequest,
  removeTimeOffRequestSuccess,
} from './timeoff.actions';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { errorHappened } from '../profile/profile.actions';
import { PtoEarnedModel, PtoRequestedModel, TimeOffModel } from '../../../models/timeoffModel';
import { Observable, of } from 'rxjs';
import { PayrollService } from '../../services/payroll.service';
import { EmployeeService } from '../../services/employee.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Router } from '@angular/router';
import { AccountState } from '../account/account.reducer';
import { UserModel } from 'src/app/models/userModel';


@Injectable()
export class TimeoffEffects {
  constructor(private actions$: Actions,
    private timeoffService: TimeoffService,
    private payrollService: PayrollService,
    private alertService: AlertService,
    private router: Router,
    private store: Store<State>,
  ) { }

  loadViewableCampuses = createEffect(() => this.actions$.pipe(
    ofType(loadViewableCampuses),
    switchMap(action => this.timeoffService.getCampusList().pipe(
      map(campuses => loadViewableCampusesSuccess({ campuses })),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))));

  loadClassifications = createEffect(() => this.actions$.pipe(
    ofType(loadClassifications),
    switchMap(action => this.timeoffService.getClassificationList().pipe(
      map(classifications => loadClassificationsSuccess({ classifications })),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))));

  getSummaryList = createEffect(() => this.actions$.pipe(
    ofType(getSummaryList),
    switchMap(action => {
      return this.timeoffService.getSummaryList(action.filter, action.campusId, action.payrollClassificationId, action.userId, action.showOnlyActive).pipe(
        map(summaryList => getSummaryListSuccess({ summaryList })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  getEmployeePtoSummary = createEffect(() => this.actions$.pipe(
    ofType(getEmployeePtoSummary),
    switchMap(action => {
      return this.timeoffService.getEmployeePtoSummary(action.userId).pipe(
        map(summary => getEmployeePtoSummarySuccess({ summary })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  getEmployeeEarnedPto = createEffect(() => this.actions$.pipe(
    ofType(getEmployeeEarnedPto),
    switchMap(action => {
      return this.timeoffService.getEmployeeEarnedPto(action.filter, action.userId).pipe(
        map(ptoList => getEmployeeEarnedPtoSuccess({ ptoList })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  getEmployeeRequestedPto = createEffect(() => this.actions$.pipe(
    ofType(getEmployeeRequestedPto),
    switchMap(action => {
      return this.timeoffService.getEmployeeRequestedPto(action.filter, action.userId).pipe(
        map(ptoList => getEmployeeRequestedPtoSuccess({ ptoList })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  savePtoRequest = createEffect(() => this.actions$.pipe(
    ofType(savePtoRequest),
    switchMap(action => {
      let observable: Observable<PtoRequestedModel>;

      if (action.ptoRequest.payableItemId > 0) {
        observable = this.payrollService.editPtoRequest(action.ptoRequest);
      }
      else {
        observable = this.payrollService.addPtoRequest(action.ptoRequest);
      }

      return observable.pipe(
        map(ptoRequest => savePtoRequestSuccess()),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })));

  getRecentTimeOff = createEffect(() => this.actions$.pipe(
    ofType(getRecentTimeOff),
    switchMap(action => {
      return this.timeoffService.getRecentTimeOff(action.filter, action.userId).pipe(
        map(list => getRecentTimeOffSuccess({ list })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  getUpcomingTimeOff = createEffect(() => this.actions$.pipe(
    ofType(getUpcomingTimeOff),
    switchMap(action => {
      return this.timeoffService.getUpcomingTimeOff(action.filter, action.userId).pipe(
        map(list => getUpcomingTimeOffSuccess({ list })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  saveTimeOffRequest = createEffect(() => this.actions$.pipe(
    ofType(saveTimeOffRequest),
    switchMap(action => {
      let observable: Observable<TimeOffModel>;

      if (action.timeOffRequest.timeOffId > 0) {
        observable = this.timeoffService.editTimeOffRequest(action.timeOffRequest);
      }
      else {
        observable = this.timeoffService.addTimeOffRequest(action.timeOffRequest);
      }

      return observable.pipe(
        map(timeOffRequest => saveTimeOffRequestSuccess()),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })));

  removeTimeOffRequest = createEffect(() => this.actions$.pipe(
    ofType(removeTimeOffRequest),
    switchMap(action => {
      return this.timeoffService.removeTimeOffRequest(action.timeOffId).pipe(
        map(success => removeTimeOffRequestSuccess(success)),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  removeTimeOffRequestSuccess = createEffect(() => this.actions$.pipe(
    ofType(removeTimeOffRequestSuccess),
    tap(action => {
      this.alertService.success('Request Removed');
      let userID;
      this.store.select(s => s.accountState.user).subscribe(u => {
        userID = u.id;
      });
      this.store.dispatch(getUpcomingTimeOff({
        filter: null,
        userId: userID
      }));
    })
  ), { dispatch: false });

  getManagePtoEarned = createEffect(() => this.actions$.pipe(
    ofType(getManagePtoEarnedList),
    switchMap(action => {
      return this.timeoffService.getManagePtoEarnedList(action.filter).pipe(
        map(ptoList => getManagePtoEarnedListSuccess({ ptoList })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  getManageTimeOff = createEffect(() => this.actions$.pipe(
    ofType(getManageTimeOffList),
    switchMap(action => {
      return this.timeoffService.getManageTimeOffList(action.filter).pipe(
        map(list => getManageTimeOffListSuccess({ list })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  savePtoEarned = createEffect(() => this.actions$.pipe(
    ofType(savePtoEarned),
    switchMap(action => {
      let observable: Observable<PtoEarnedModel>;

      if (action.ptoEarned.ptoId > 0) {
        observable = this.timeoffService.editPtoEarned(action.ptoEarned);
      }
      else {
        observable = this.timeoffService.addPtoEarned(action.ptoEarned);
      }

      return observable.pipe(
        map(ptoEarned => savePtoEarnedSuccess()),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })));

  saveTimeOffApproval = createEffect(() => this.actions$.pipe(
    ofType(saveTimeOffApproval),
    switchMap(action => {
      return this.timeoffService.saveTimeOffApproval(action.timeOffRequest).pipe(
        map(timeOff => saveTimeOffApprovalSuccess()),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  resetTimeOffApproval = createEffect(() => this.actions$.pipe(
    ofType(resetTimeOffApproval),
    switchMap(action => {
      return this.timeoffService.resetTimeOffApproval(action.timeOffRequest).pipe(
        map(timeOff => resetTimeOffApprovalSuccess()),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  getTimeOffApprovalSummary = createEffect(() => this.actions$.pipe(
    ofType(getTimeOffApprovalSummary),
    switchMap(action => {
      return this.timeoffService.getTimeOffApprovalSummary(action.timeOffId).pipe(
        map(summary => getTimeOffApprovalSummarySuccess({ summary })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));
}
