import { UploadDocsApprovalListModel } from './../../../../models/onBoardingModels';
import { BaseComponent } from './../../../../core/abstracts/baseComponent';
import { BenefitsState } from './../../../../core/store/benefits/benefits.reducer';
import { BenefitPlanModel } from './../../../../models/benefitsModels';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { Actions, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { MultiSelect } from 'primeng/multiselect';
import { AlertService } from '../../../../shared/services/alert.service';
import { deepClone, downloadFile, getTimeStampString } from '../../../../helpers/utils';
import { ConfirmationService } from 'primeng/api';
import { tap } from 'rxjs/operators';
import { loadUploadDocsApprovalList } from 'src/app/core/store/onboarding/onboarding.actions';

@Component({
  selector: 'app-list-uploaddocs-approval-employees',
  templateUrl: './list-uploaddocs-approval-employees.component.html',
  styleUrls: ['./list-uploaddocs-approval-employees.component.scss']
})
export class ListUploaddocsApprovalEmployeesComponent extends BaseComponent implements OnInit {

  uploadDocsApprovalList: UploadDocsApprovalListModel[];
  canEdit: boolean;
  loadingUploadDocsApprovalList = false;

  filter: any;
  yesNoAllOptions = [
    { label: 'All', value: null },
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];
  datesOptions = [
    { label: 'This Year', value: 1 },
    { label: 'Last Year', value: 2 },
    { label: 'Next Year', value: 3 },
    { label: 'Custom', value: 0 },
  ];


  constructor(
    private actions$: Actions,
    private alertService: AlertService,
    private store: Store<State>,
    private router: Router,
    private confirmationService: ConfirmationService,
  ) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.canEdit = permissionIDs && !!permissionIDs.find(p => p === 6100);
      }),
      this.store.select(s => s.onboardingState.uploadDocsApprovalList).subscribe(uploadDocsApprovalList => {
        if (uploadDocsApprovalList) {
          this.uploadDocsApprovalList = uploadDocsApprovalList;
        }
      }),

      this.store.select(s => s.onboardingState.loadingUploadDocsApprovalList).subscribe(loadingUploadDocsApprovalList => {
        this.loadingUploadDocsApprovalList = loadingUploadDocsApprovalList;
      }),

    );
    this.loadPage({});
  }


  loadPage(event) {
    if (!this.loadingUploadDocsApprovalList) {
      this.loadingUploadDocsApprovalList = true;
      this.filter = deepClone(event);

      this.store.dispatch(loadUploadDocsApprovalList());
    }
  }


  changedFilters() {
    this.loadPage(this.filter);
  }

  openDocsToApprove(userToApprove: UploadDocsApprovalListModel) {
    this.router.navigate(['/uploaddocs/approval/' + userToApprove.userID]);
  }


}

