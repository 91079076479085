<div class="card mb-2">
  <div class="card-body">
    <i *ngIf="!benefitPlanFull?.benefitPlan" class="material-icons rotate text-muted">loop</i>
    <div *ngIf="benefitPlanFull?.benefitPlan">

      <button  class="btn btn-secondary float-right pointer" routerLink="/benefits-management/benefit-plan/list">Back</button>
      <h2 class="">{{benefitPlanFull.benefitPlan.id?'Edit':'Add'}} Benefit Plan</h2>
      <div class="row">
        <div class="col-12 col-lg-6">
          <form class="" [formGroup]="form" *ngIf="form && benefitPlanFull?.benefitPlan">
            <div class="row">
              <div class="col-4 d-flex align-items-center">
                <b>Plan Name</b>
              </div>
              <div class="col-8 ">
                <input id="planNameInput" type="text" class="form-control mt-2" formControlName="planName" />
                <app-validation-message [form]="form" [path]="'planName'" [name]="'planName'" [label]="'Plan Name'"> </app-validation-message>
              </div>
              <div class="col-4 d-flex align-items-center">
                <b>Active</b>
              </div>
              <div class="col-8">
                <p-dropdown styleClass="form-control mt-2 w-100" [options]="yesNoOptions" placeholder="Select..." formControlName="active"></p-dropdown>
              </div>
              <div class="col-4 d-flex align-items-center">
                <b>Plan Type</b>
              </div>
              <div class="col-8">
                <i *ngIf="!benefitPlanTypeOptions" class="material-icons rotate text-muted">loop</i>
                <p-dropdown styleClass="form-control mt-2 w-100" [options]="benefitPlanTypeOptions" placeholder="Select type..." formControlName="benefitPlanTypeID"></p-dropdown>
              </div>
              <ng-container>
                <div class="col-4 d-flex align-items-center">
                  <b>Special Employer Contribution</b>
                </div>
                <div class="col-8">
                  <div class=" d-flex align-items-center ">
                    <input id="SpecialPercentageContributionInput" type="number" class="form-control mt-2 mr-1" formControlName="specialPercentageContribution" /> %
                  </div>
                  <app-validation-message [form]="form" [path]="'specialPercentageContribution'" [name]="'specialPercentageContribution'" [label]="'Special Employer Contribution'"> </app-validation-message>
                </div>
              </ng-container>
              <ng-container *ngIf="isPlanHSA()">
                <div class="col-4 d-flex align-items-center">
                  <b>Eligible Yearly Contribution</b>
                </div>
                <div class="col-8 ">
                  <input id="elegibleYearlyContributionInput" type="text" class="form-control mt-2" formControlName="elegibleYearlyContribution" />
                  <app-validation-message [form]="form" [path]="'elegibleYearlyContribution'" [name]="'elegibleYearlyContribution'" [label]="'Elegible Yearly Contribution'"> </app-validation-message>
                </div>
              </ng-container>

              <div class="col-4 d-flex align-items-center">
                <b>Carrier Plan</b>
              </div>
              <div class="col-8 ">
                <i *ngIf="!carrierBenefitPlanOptions" class="material-icons rotate text-muted">loop</i>
                <p-dropdown styleClass="form-control mt-2 w-100" [options]="carrierBenefitPlanOptions" placeholder="Select carrier plan..." formControlName="carrierPlanID"></p-dropdown>
              </div>
              <div class="col-4 d-flex align-items-center">
                <b>Policy #</b>
              </div>
              <div class="col-8 ">
                <input id="policyNumberInput" type="text" class="form-control mt-2" formControlName="policyNumber" />
                <app-validation-message [form]="form" [path]="'policyNumber'" [name]="'policyNumber'" [label]="'Policy #'"> </app-validation-message>
              </div>

              <div class="col-4 d-flex align-items-center">
                <b>Period</b>
              </div>
              <div class="col-8 ">
                <p-calendar inputStyleClass="form-control mt-2"
                            [monthNavigator]="true"
                            [yearNavigator]="true"
                            styleClass="w-75"
                            panelStyleClass="calendarPanel"
                            [hourFormat]="12"
                            [yearRange]="'2010:2100'"
                            [showTime]="false"
                            placeholder="Select dates..."
                            [selectionMode]="'range'"
                            [(ngModel)]="periodDates" (ngModelChange)="changedDates()" [ngModelOptions]="{standalone: true}"
                            dataType="string"></p-calendar>
                <app-validation-message [form]="form" [path]="'dateStart'" [name]="'dateStart'" [label]="'Date Start'"> </app-validation-message>
                <app-validation-message [form]="form" [path]="'dateEnd'" [name]="'dateEnd'" [label]="'Date End'"> </app-validation-message>
              </div>

              <div class="col-4 d-flex align-items-center">
                <b>Renewal Date</b>
              </div>
              <div class="col-8 ">

                <p-calendar dateMask inputStyleClass="form-control mt-2"
                            [monthNavigator]="true"
                            [yearNavigator]="true"
                            styleClass="w-100"
                            panelStyleClass="calendarPanel"
                            appendTo="body"
                            [hourFormat]="12"
                            [yearRange]="'2021:2100'"
                            [showTime]="false"
                            placeholder="Select date..."
                            formControlName="renewalDate"
                            dataType="string"></p-calendar>
                <app-validation-message [form]="form" [path]="'renewalDate'" [name]="'renewalDate'" [label]="'Renewal Date'"> </app-validation-message>
              </div>

              <div class="col-4 d-flex align-items-center">
                <b>Summary</b>
              </div>
              <div class="col-8 ">
                <textarea id="summaryTextarea" type="text" class="form-control mt-2" formControlName="summary"></textarea>
                <app-validation-message [form]="form" [path]="'summary'" [name]="'summary'" [label]="'Summary'"> </app-validation-message>
              </div>
              <div class="col-4 d-flex align-items-center">
                <b>Disclosure</b>
              </div>
              <div class="col-8 ">
                <textarea id="disclosureTextarea" type="text" class="form-control mt-2" formControlName="disclosure"></textarea>
                <app-validation-message [form]="form" [path]="'disclosure'" [name]="'disclosure'" [label]="'Disclosure'"> </app-validation-message>
              </div>

              <div class="col-4 d-flex align-items-center">
                <b>Ellegibility Waiting Period</b>
              </div>
              <div class="col-8">
                <i *ngIf="!elegibilityWaitingPeriodOptions" class="material-icons rotate text-muted">loop</i>
                <p-dropdown styleClass="form-control mt-2 w-100" [options]="elegibilityWaitingPeriodOptions" placeholder="Select ..." formControlName="ellegibilityWaitingPeriodID"></p-dropdown>
                <app-validation-message [form]="form" [path]="'ellegibilityWaitingPeriodID'" [name]="'ellegibilityWaitingPeriodID'" [label]="'Ellegibility Waiting Period'"> </app-validation-message>
              </div>
              <div class="col-4 d-flex align-items-center">
                <b>Ellegibility Begin</b>
              </div>
              <div class="col-8">
                <i *ngIf="!elegibilityBeginOptions" class="material-icons rotate text-muted">loop</i>
                <p-dropdown styleClass="form-control mt-2 w-100" [options]="elegibilityBeginOptions" placeholder="Select ..." formControlName="ellegibilityBeginID"></p-dropdown>
                <app-validation-message [form]="form" [path]="'ellegibilityBeginID'" [name]="'ellegibilityBeginID'" [label]="'Ellegibility Begin'"> </app-validation-message>
              </div>
              <div class="col-4 d-flex align-items-center">
                <b>Termination Upon</b>
              </div>
              <div class="col-8">
                <i *ngIf="!terminationUponOptions" class="material-icons rotate text-muted">loop</i>
                <p-dropdown styleClass="form-control mt-2 w-100" [options]="terminationUponOptions" placeholder="Select ..." formControlName="terminationUponID"></p-dropdown>
                <app-validation-message [form]="form" [path]="'terminationUponID'" [name]="'terminationUponID'" [label]="'Termination Upon'"> </app-validation-message>
              </div>
              <div class="col-4 d-flex align-items-center">
                <b>Classes</b>
              </div>
              <div class="col-8">
                <i *ngIf="!classesOptions" class="material-icons rotate text-muted">loop</i>
                <p-multiSelect class="w-100" styleClass="w-100 d-flex mt-2" inputStyleClass="form-control" [options]="classesOptions" placeholder="Select ..." [ngModelOptions]="{standalone: true}" [(ngModel)]="classes" (ngModelChange)="changedClasses()"></p-multiSelect>
              </div>
              <ng-container>
                <div class="col-4 d-flex align-items-center">
                  <b>Allow Health Savings Account</b>
                </div>
                <div class="col-8">
                  <p-checkbox styleClass="mt-2" [binary]="true" formControlName="allowHSA"></p-checkbox>
                </div>
              </ng-container>

              <div class="col-4 d-flex align-items-center">
                <b>Depend on plan</b>
              </div>
              <div class="col-8">
                <i *ngIf="!plansOptions" class="material-icons rotate text-muted">loop</i>
                <p-dropdown styleClass="form-control mt-2 w-100" [options]="plansOptions" placeholder="Select ..." formControlName="dependOnPlanID"></p-dropdown>
                <app-validation-message [form]="form" [path]="'dependOnPlanID'" [name]="'dependOnPlanID'" [label]="'Depend On Plan'"> </app-validation-message>
              </div>

              <div class="col-4 d-flex align-items-center" *ngIf="paylocityEnabled">
                <b>Paylocity Deduction Code</b>
              </div>
              <div class="col-8 " *ngIf="paylocityEnabled">
                <input id="paylocityDeductionCode" type="text" class="form-control mt-2" formControlName="paylocityDeductionCode" />
                <app-validation-message [form]="form" [path]="'paylocityDeductionCode'" [name]="'paylocityDeductionCode'" [label]="'Paylocity Deduction Code'"> </app-validation-message>
              </div>

              <div class="col-4 d-flex align-items-center" *ngIf="paylocityEnabled">
                <b>Paylocity Earning Code</b>
              </div>
              <div class="col-8 " *ngIf="paylocityEnabled">
                <input id="paylocityEarningCode" type="text" class="form-control mt-2" formControlName="paylocityEarningCode" />
                <app-validation-message [form]="form" [path]="'paylocityEarningCode'" [name]="'paylocityEarningCode'" [label]="'Paylocity Earning Code'"> </app-validation-message>
              </div>

              <div class="col-4 d-flex align-items-center" *ngIf="motivHealthEnabled">
                <b>Motiv Health Plan Code</b>
              </div>
              <div class="col-8 " *ngIf="motivHealthEnabled">
                <input id="motivHealthPlanCoverage" type="text" class="form-control mt-2" formControlName="motivHealthPlanCoverage" />
                <app-validation-message [form]="form" [path]="'motivHealthPlanCoverage'" [name]="'motivHealthPlanCoverage'" [label]="'Motiv Health Plan Code'"> </app-validation-message>
              </div>
              
              <ng-container *ngIf="cobraEnabled">
                <div class="col-4 d-flex align-items-center">
                  <b>Cobra Eligible</b>
                </div>
                <div class="col-8">
                  <p-dropdown styleClass="form-control mt-2 w-100" [options]="yesNoOptions" placeholder="Select..." formControlName="isCobraEligible"></p-dropdown>
                </div>

                <ng-container *ngIf="isCobraEligible()">
                  <div class="col-4 d-flex align-items-center">
                    <b>Cobra Plan Name</b>
                  </div>
                  <div class="col-8 ">
                    <input id="cobraPlanNameInput" type="text" class="form-control mt-2" formControlName="cobraPlanName" />
                    <app-validation-message [form]="form" [path]="'cobraPlanName'" [name]="'cobraPlanName'" [label]="'Cobra Plan Name'"> </app-validation-message>
                  </div>
                </ng-container>

              </ng-container>
            </div>
          </form>
        </div>

        <div class="col-12 col-lg-6">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <h4>Costs <i class="fas fa-plus-circle pointer"
                    (click)="addCost()"></i></h4>
                  <div class="mb-3" *ngIf="openAddCost">
                    <form class="" *ngIf="costForm" [formGroup]="costForm">
                      <div class="row">
                        <div class="col-4 d-flex align-items-center">
                          <b>Coverage Level</b>
                        </div>
                        <div class="col-8 ">
                          <i *ngIf="!coverageLevelOptions" class="material-icons rotate text-muted">loop</i>
                          <p-dropdown styleClass="form-control mt-2 w-100" [options]="coverageLevelOptions" placeholder="Select type..." formControlName="coverageLevelID" ></p-dropdown>
                          <app-validation-message [form]="costForm" [path]="'coverageLevelID'" [name]="'coverageLevelID'" [label]="'Coverage Level'"> </app-validation-message>
                        </div>
                        <div class="col-4 d-flex align-items-center">
                          <b>Cost by Age</b>
                        </div>
                        <div class="col-8 ">
                          <p-dropdown styleClass="form-control mt-2 w-100" [options]="yesNoOptions" placeholder="Select..." formControlName="costByAge" ></p-dropdown>
                          <app-validation-message [form]="costForm" [path]="'costByAge'" [name]="'costByAge'" [label]="'Cost By Age'"> </app-validation-message>
                        </div>
                      </div>
                      <div class="row" *ngIf="costForm.get('costByAge').value">
                        <div class="col-4 d-flex align-items-center">
                          <b>Monthly Cost (18-25)</b>
                        </div>
                        <div class="col-8 ">
                          <input type="number" class="form-control mt-2" formControlName="monthlyCost25" />
                        </div>
                      </div>
                      <div class="row" *ngIf="costForm.get('costByAge').value">
                        <div class="col-4 d-flex align-items-center">
                          <b>Monthly Cost (26-30)</b>
                        </div>
                        <div class="col-8 ">
                          <input id="monthlyCost30Input" type="number" class="form-control mt-2" formControlName="monthlyCost30"  />
                          <app-validation-message [form]="costForm" [path]="'monthlyCost30'" [name]="'monthlyCost30'" [label]="'MonthlyCost (18-30)'"> </app-validation-message>
                        </div>
                      </div>
                      <div class="row" *ngIf="costForm.get('costByAge').value">
                        <div class="col-4 d-flex align-items-center">
                          <b>Monthly Cost (31-35)</b>
                        </div>
                        <div class="col-8 ">
                          <input id="monthlyCost35Input" type="number" class="form-control mt-2" formControlName="monthlyCost35"  />
                          <app-validation-message [form]="costForm" [path]="'monthlyCost35'" [name]="'monthlyCost35'" [label]="'Monthly Cost (18-35)'"> </app-validation-message>
                        </div>
                      </div>
                      <div class="row" *ngIf="costForm.get('costByAge').value">
                        <div class="col-4 d-flex align-items-center">
                          <b>Monthly Cost (36-40)</b>
                        </div>
                        <div class="col-8 ">
                          <input id="monthlyCost40Input" type="number" class="form-control mt-2" formControlName="monthlyCost40" />
                          <app-validation-message [form]="costForm" [path]="'monthlyCost40'" [name]="'monthlyCost40'" [label]="'Monthly Cost (18-40)'"> </app-validation-message>
                        </div>
                      </div>
                      <div class="row" *ngIf="costForm.get('costByAge').value">
                        <div class="col-4 d-flex align-items-center">
                          <b>Monthly Cost (41-45)</b>
                        </div>
                        <div class="col-8 ">
                          <input id="monthlyCost45Input" type="number" class="form-control mt-2" formControlName="monthlyCost45" />
                          <app-validation-message [form]="costForm" [path]="'monthlyCost45'" [name]="'monthlyCost45'" [label]="'Monthly Cost (18-45)'"> </app-validation-message>
                        </div>
                      </div>
                      <div class="row" *ngIf="costForm.get('costByAge').value">
                        <div class="col-4 d-flex align-items-center">
                          <b>Monthly Cost (46-50)</b>
                        </div>
                        <div class="col-8 ">
                          <input id="monthlyCost50Input" type="number" class="form-control mt-2" formControlName="monthlyCost50"  />
                          <app-validation-message [form]="costForm" [path]="'monthlyCost50'" [name]="'monthlyCost50'" [label]="'Monthly Cost (18-50)'"> </app-validation-message>
                        </div>
                      </div>
                      <div class="row" *ngIf="costForm.get('costByAge').value">
                        <div class="col-4 d-flex align-items-center">
                          <b>Monthly Cost (51-55)</b>
                        </div>
                        <div class="col-8 ">
                          <input id="monthlyCost55Input" type="number" class="form-control mt-2" formControlName="monthlyCost55"  />
                          <app-validation-message [form]="costForm" [path]="'monthlyCost55'" [name]="'monthlyCost55'" [label]="'MonthlyCost (18-55)'"> </app-validation-message>
                        </div>
                      </div>
                      <div class="row" *ngIf="costForm.get('costByAge').value">
                        <div class="col-4 d-flex align-items-center">
                          <b>Monthly Cost (56-60)</b>
                        </div>
                        <div class="col-8 ">
                          <input id="monthlyCost60Input" type="number" class="form-control mt-2" formControlName="monthlyCost60"/>
                          <app-validation-message [form]="costForm" [path]="'monthlyCost60'" [name]="'monthlyCost60'" [label]="'Monthly Cost (18-60)'"> </app-validation-message>
                        </div>
                      </div>
                      <div class="row" *ngIf="costForm.get('costByAge').value">
                        <div class="col-4 d-flex align-items-center">
                          <b>Monthly Cost (61-65)</b>
                        </div>
                        <div class="col-8 ">
                          <input id="monthlyCost65Input" type="number" class="form-control mt-2" formControlName="monthlyCost65" />
                          <app-validation-message [form]="costForm" [path]="'monthlyCost65'" [name]="'monthlyCost65'" [label]="'Monthly Cost (18-65)'"> </app-validation-message>
                        </div>
                      </div>
                      <div class="row" *ngIf="costForm.get('costByAge').value">
                        <div class="col-4 d-flex align-items-center">
                          <b>Monthly Cost (66-70)</b>
                        </div>
                        <div class="col-8 ">
                          <input id="monthlyCost70Input" type="number" class="form-control mt-2" formControlName="monthlyCost70"  />
                          <app-validation-message [form]="costForm" [path]="'monthlyCost70'" [name]="'monthlyCost70'" [label]="'Monthly Cost (18-70)'"> </app-validation-message>
                        </div>
                      </div>
                      <div class="row" *ngIf="costForm.get('costByAge').value">
                        <div class="col-4 d-flex align-items-center">
                          <b>Monthly Cost (71-75)</b>
                        </div>
                        <div class="col-8 ">
                          <input id="monthlyCost75Input" type="number" class="form-control mt-2" formControlName="monthlyCost75" />
                          <app-validation-message [form]="costForm" [path]="'monthlyCost75'" [name]="'monthlyCost75'" [label]="'Monthly Cost (18-75)'"> </app-validation-message>
                        </div>
                      </div>
                      <div class="row" *ngIf="costForm.get('costByAge').value">
                        <div class="col-4 d-flex align-items-center">
                          <b>Monthly Cost (76-80)</b>
                        </div>
                        <div class="col-8 ">
                          <input id="monthlyCost80Input" type="number" class="form-control mt-2" formControlName="monthlyCost80" />
                          <app-validation-message [form]="costForm" [path]="'monthlyCost80'" [name]="'monthlyCost80'" [label]="'Monthly Cost (18-80)'"> </app-validation-message>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-4 d-flex align-items-center">
                          <b *ngIf="!this.costToAdd.costByAge">Monthly Cost</b>
                          <b *ngIf="costForm.get('costByAge').value">Monthly Cost (80+)</b>
                        </div>
                        <div class="col-8 ">
                          <input id="monthlyCostInput" type="number" class="form-control mt-2" formControlName="monthlyCost"/>
                          <app-validation-message [form]="costForm" [path]="'monthlyCost'" [name]="'monthlyCost'" [label]="'Cost'"> </app-validation-message>
                        </div>
                      </div>
                      <button class="btn btn-primary mt-1" id="addCostButton" [class.disabled]="costForm.invalid" (click)="saveCost()">
                        {{costForm.get('id').value?'Change cost':'Add Cost'}}
                      </button>
                    </form>
                  </div>
                  <p-table class="mt-2"  [value]="benefitPlanFull.costs" [responsive]="true" >
                    <ng-template pTemplate="header">
                      <tr>
                        <th width="75px"></th>
                        <th width="120px" [pSortableColumn]="'coverageLevelID'">Coverage<p-sortIcon [field]="'coverageLevelID'"></p-sortIcon></th>
                        <th [pSortableColumn]="'monthlyCost'">Monthly Cost<p-sortIcon [field]="'monthlyCost'"></p-sortIcon></th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-cost>
                      <tr>
                        <td>
                          <i class="fas fa-trash mr-2 pointer" title="Remove" (click)="removeCost(cost)"></i>
                          <i class="fas fa-edit mr-2 pointer" title="Edit" (click)="editCost(cost)"></i>
                        </td>
                        <td>
                          <span class="ui-column-title">Coverage Level</span>
                          {{getCoverageLevelLabel(cost.coverageLevelID)}}
                        </td>
                        <td>
                          <span class="ui-column-title">Monthly Cost</span>
                          <div [innerHtml]="getMonthlyCostLabel(cost)"></div>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="card  mt-3">
                <div class="card-body">
                  <h4>Documents <i class="fas fa-plus-circle pointer"
                    (click)="openAddDocument=!openAddDocument"></i></h4>
                  <div class="mb-3" *ngIf="openAddDocument">
                    <form class="" *ngIf="documentForm" [formGroup]="documentForm">
                      <div class="row">
                        <div class="col-4 d-flex align-items-center">
                          <b>Document Name</b>
                        </div>
                        <div class="col-8 ">
                            <input id="documentNameInput" type="text" class="form-control mt-2" formControlName="documentName" [(ngModel)]="this.documentToAdd.documentName"/>
                            <app-validation-message [form]="documentForm" [path]="'documentName'" [name]="'documentName'" [label]="'Document Name'"> </app-validation-message>
                        </div>
                        <div class="col-4 d-flex align-items-center">
                          <b>File</b>
                        </div>
                        <div class="col-8 ">
                            <input id="fileInput" #fileInput type="file" class="form-control mt-2"  formControlName="file" accept="image/*,.pdf,.doc,.docx" (change)="changedFile(fileInput)"  />
                            <app-validation-message [form]="documentForm" [path]="'file'" [name]="'file'" [label]="'File'"> </app-validation-message>
                        </div>
                      </div>
                      <button class="btn btn-primary mt-1" id="addDocumentButton" [class.disabled]="documentForm.invalid" (click)="addDocument()">Add Document </button>
                    </form>
                  </div>
                  <p-table class="mt-2"  [value]="benefitPlanFull.documents" [responsive]="true" >
                    <ng-template pTemplate="header">
                        <tr>
                            <th width="50px"></th>
                            <th [pSortableColumn]="'documentName'">Document Name <p-sortIcon [field]="'documentName'"></p-sortIcon></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-document>
                        <tr >
                            <td>
                              <i class="fas fa-trash mr-2 pointer" title="Remove"  (click)="removeDocument(document)"></i>
                            </td>
                            <td>
                                <span class="ui-column-title">Document Name</span>

                                <span *ngIf="!document.url">
                                  {{document.documentName}}
                                </span>
                                <a *ngIf="document.url" [href]="document.url" target="newwindow">
                                  {{document.documentName}}
                                </a>
                            </td>
                        </tr>
                    </ng-template>
                  </p-table>
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="card mt-3">
                <div class="card-body">
                  <h4>Links <i class="fas fa-plus-circle pointer"
                    (click)="openAddLink=!openAddLink"></i></h4>
                  <div class="mb-3" *ngIf="openAddLink">
                    <form class="" *ngIf="linkForm" [formGroup]="linkForm">
                      <div class="row">
                        <div class="col-4 d-flex align-items-center">
                          <b>Link Name</b>
                        </div>
                        <div class="col-8 ">
                            <input id="linkNameInput" type="text" class="form-control mt-2" formControlName="linkName" [(ngModel)]="this.linkToAdd.linkName"/>
                            <app-validation-message [form]="linkForm" [path]="'linkName'" [name]="'linkName'" [label]="'Link Name'"> </app-validation-message>
                        </div>
                        <div class="col-4 d-flex align-items-center">
                          <b>Url</b>
                        </div>
                        <div class="col-8 ">
                            <input id="fileInput" type="text" class="form-control mt-2"  formControlName="url" [(ngModel)]="this.linkToAdd.url" />
                            <app-validation-message [form]="linkForm" [path]="'url'" [name]="'url'" [label]="'Url'"> </app-validation-message>
                        </div>
                      </div>
                      <button class="btn btn-primary mt-1" id="addLinkButton" [class.disabled]="linkForm.invalid" (click)="addLink()">Add Link </button>
                    </form>
                  </div>
                  <p-table class="mt-2"  [value]="benefitPlanFull.links" [responsive]="true" >
                    <ng-template pTemplate="header">
                        <tr>
                            <th width="50px"></th>
                            <th [pSortableColumn]="'linkName'">Link Name <p-sortIcon [field]="'linkName'"></p-sortIcon></th>
                            <th [pSortableColumn]="'url'">Url <p-sortIcon [field]="'url'"></p-sortIcon></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-link>
                        <tr >
                            <td>
                              <i class="fas fa-trash mr-2 pointer" title="Remove"  (click)="removeLink(link)"></i>
                            </td>
                            <td>
                                <span class="ui-column-title">Link Name</span>
                                {{link.linkName}}
                            </td>
                            <td>
                                <span class="ui-column-title">Url</span>
                               <a [href]="link.url" target="newwindow"> {{link.url}}</a>
                            </td>
                        </tr>
                    </ng-template>
                  </p-table>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
      <i *ngIf="savingBenefitPlan" class="material-icons rotate text-muted">loop</i>
      <button class="btn btn-primary mt-3" id="saveButton" [class.disabled]="savingBenefitPlan || form.invalid" (click)="save()">Save</button>
    </div>
  </div>
</div>
