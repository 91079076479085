<div class="card mt-2">
  <div class="card-body">
    <div class="float-right">
      <button type="button" routerLink="/intern/list-manage" class="btn btn-secondary mr-2">Applications</button>
      <button type="button" routerLink="/intern/substatus/list" class="btn btn-secondary mr-2">Substatuses</button>
      <button type="button" routerLink="/intern/actionItemTemplate/list" class="btn btn-secondary mr-2">Action Item Templates</button>
    </div>
    <h2 class="mb-3">Action Item Types</h2>
    <div class="mb-3">

    </div>

    <i [hidden]="!loadingList && !loadingExcel && !savingType" class="material-icons rotate text-muted">loop</i>
    <button (click)="editActionType()" class="btn btn-secondary ml-2 mb-2">New Action Type</button>    
    <p-table [value]="list?.result" [responsive]="true" [paginator]="true" [rows]="20"
             [totalRecords]="list?.totalRecords" [lazy]="true" (onLazyLoad)="reloadFilter($event)">
      <ng-template pTemplate="header">
        <tr>
          <th width="175px"></th>
          <th [pSortableColumn]="'Type'">Type<p-sortIcon [field]="'Type'"></p-sortIcon></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-list>
        <tr>
          <td>
            <button class="btn btn-secondary mr-2" (click)="editActionType(list)" title="Edit Action Type" [disabled]="savingType">
              <i class="fas fa-pencil"></i>
            </button>
            <button class="btn btn-secondary mr-2" (click)="deleteActionType(list)" title="Delete Action Type" [disabled]="savingType">
              <i class="fas fa-trash-alt"></i>
            </button>
          </td>
          <td>
            <span class="ui-column-title">Type</span>
            {{list.actionType}}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<app-edit-intern-action-type></app-edit-intern-action-type>
