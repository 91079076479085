
<ng-container>



  <div class=" mb-2" *ngIf="getTotalCost()>0">
    <div class="planner-panel text-center  ">
      <h5 class="benefit-headers-font">Total Cost per Pay Period</h5>
      <h1 class="description-nosize">${{getTotalCost().toFixed(2)}}</h1>
    </div>
  </div>
  <div class=" mb-2">
    <div class="planner-panel" *ngIf="benefitPlanTypes">
      <h2 class="benefit-headers-font">Plans</h2>
      <hr class="mb-2" />
      <div class=" m-3" *ngFor="let planType of benefitPlanTypes">
        <div class="planner-panel">
          <div class="row mb-3">
            <div class="col-3 ">
              <h3 class="mb-4 benefit-headers-font" style="overflow-wrap: normal;">{{planType.planTypeName}}</h3>
              <div class=" text-center align-items-center justify-content-center d-flex">
                <i [class]="getPlanTypeClass(planType.id)"></i>
              </div>
            </div>
            <div class="col-9">
              <div *ngIf="getDeclinedPlanOfType(planType)">
                <h4 class="description-nosize">Coverage declined</h4>
                <div class="description">Reason: {{getDeclinedPlanOfType(planType).reason.reason}}</div>
              </div>

              <div *ngIf="getPlanOfType(planType) && getPlanOfType(planType).length">
                <ng-container *ngFor="let benefitPlan of getPlanOfType(planType)">
                  <h4 class="benefit-headers-font">{{ benefitPlan.benefitPlan.planName }}</h4>
                  <hr class="mb-2" />
                  <div class="row">
                    <div class="col-6 flex-column text-center align-items-center justify-content-center d-flex">
                      <h4 *ngIf="getMyCost(benefitPlan)" class="description-nosize">${{getMyCost(benefitPlan).toFixed(2)}}</h4>
                      <div *ngIf="getMyCost(benefitPlan)" class="description">Cost per pay period</div>
                      <span class="description" *ngIf="benefitPlan.enrollment?.employerContribution">Employer Contribution: ${{benefitPlan.enrollment?.employerContribution}}</span>
                    </div>
                    <div class="col-6 description">
                      <span *ngIf="benefitPlan.benefitPlan.benefitPlanTypeLabel!=='Employee Perk'">Effective on {{getEffectiveDate(benefitPlan) | date:'shortDate'}} <br /></span>
                      <span *ngIf="benefitPlan.benefitPlan.benefitPlanTypeLabel!=='Employee Perk'">Coverage: {{getMyCost(benefitPlan)}}</span><br />
                      <div class="row description" *ngFor="let dependent of dependentsOfPlan(benefitPlan)">
                        <div class="col-12">
                          &nbsp;&nbsp; - {{dependent.firstName}} {{dependent.lastName}} ({{dependent.relationship}})
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-12">
                      <button class="benefit-btn reverse-gray float-left" (click)="openPlanDetail(benefitPlan)">Details</button>
                    </div>
                  </div>
                </ng-container>
              </div>
              <div *ngIf="!getDeclinedPlanOfType(planType) && (!getPlanOfType(planType) || !getPlanOfType(planType).length)">
                No plan selected<br>


                <small *ngIf="getPreviousPlanOfType(planType) && getPreviousPlanOfType(planType).length">
                  Previous plan:<br>

                  <ng-container *ngFor="let previousBenefitPlan of getPreviousPlanOfType(planType)">
                    {{previousBenefitPlan.benefitPlan.benefitPlan.planName}}
                    <ng-container *ngIf="previousBenefitPlan && previousBenefitPlan.dependents && previousBenefitPlan.dependents.length">
                      (<span *ngFor="let dependent of previousBenefitPlan.dependents">
                        &nbsp;{{dependent.firstName}} {{dependent.lastName}} ({{dependent.relationship}})
                      </span>)
                    </ng-container>
                  </ng-container>
                </small>
                <small *ngIf="wasPreviousPlanOfTypeDeclined(planType)">
                  Previuous plans: Declined
                </small>
              </div>
              <div class="float-right d-flex flex-column">
                <button class="benefit-btn mb-2" *ngIf="getCanEditPlanOfType(planType)" (click)="openPlanEnroll(planType)">{{getPlanOfType(planType)?.length?'EDIT':'ENROLL'}}</button>
                <button class="benefit-btn" *ngIf="!activatedDate && hasUpdatedProfile && planType.allowDecline && !isTypeDeclined(planType.id)" (click)="openPlanReject(planType)">DECLINE COVERAGE</button>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
  <div class=" mb-2" *ngIf="false && hsaPlan()">
    <div class="planner-panel">
      <h5 class="benefit-headers-font">Health Savings Account</h5>

      <div class="mb-2 description">
        <b>{{hsaPlan().benefitPlan.planName}}</b><br>
        Per pay: ${{(getMyCost(hsaPlan()) + hsaPlan().enrollment.employerContribution)}}<br />
        Yearly Contribution: ${{(getMyCost(hsaPlan()) + hsaPlan().enrollment.employerContribution) * 26 }}<br />
        Started: {{getEffectiveDate(hsaPlan()) | date:'shortDate'}} <br />
      </div>
    </div>
  </div>
  <div class="card mb-2" *ngIf="false && rejectedBenefitPlanTypes && rejectedBenefitPlanTypes.length">
    <div class="card-body">
      <h5>Declined Coverage</h5>
      <hr />
      <div class="mb-2" *ngFor="let plan of rejectedBenefitPlanTypes">
        <b>{{plan.type.planTypeName}}</b><br>
        <small> Reason: {{plan.reason.reason}}</small>
      </div>
    </div>
  </div>
</ng-container>


<p-dialog header="Plan Detail" [focusTrap]="false" [modal]="true" [styleClass]="'top-dialog'"
          [style]="{width: '70vw'}" [(visible)]="openedPlanDetail"
          [maximizable]="true">
  <app-benefit-plan-detail *ngIf="planDetailToOpen" [benefitPlan]="planDetailToOpen"></app-benefit-plan-detail>
</p-dialog>



<p-dialog [focusTrap]="false" [modal]="true" [styleClass]="'top-dialog'"
          (onHide)="closePlanEnroll()"
          [closable]="false"
          [draggable]="false"
          [resizable]="false"
          [keepInViewport]="true"
          [closeOnEscape]="true"
          [maximizable]="false"
          [style]="{width: '70vw'}" [(visible)]="openedPlanEnroll">

  <p-header>
    <div class="planner-panel">
      <div class="float-left mr-3">
        <i [class]="getPlanTypeClass(planTypeToEnroll?.id)"></i>
      </div>
      <h3 class="benefit-headers-font">
        {{ planTypeToEnroll?.planTypeName }} Plan enrollment
      </h3>
      <p class="description">{{ planTypeToEnroll?.description }}</p>
    </div>
  </p-header>
  <div class="row">
    <div class="col">
      <div class="planner-panel mb-2" *ngIf="checkedDependent && openedPlanEnroll && planTypeToEnroll?.allowDependent && shouldShowCoverageList()">

        <h3 class="benefit-headers-font">Dependents enrolled on this plan</h3>
        <div class="ml-2">
          <i class="fas fa-user mr-2"></i><span class="description">Myself</span><br />
          <ng-container *ngIf="benefitsDependents">
            <div class="mt-2" *ngFor="let dependent of benefitsDependents">
              <div *ngIf="!dependent.isDeleted">
                <p-checkbox [disabled]="savingBenefitEnrollment"
                            styleClass="mr-2" [binary]="true"
                            [(ngModel)]="checkedDependent[planTypeToEnroll.id][dependent.id]"></p-checkbox><span class="description">{{dependent.firstName}} {{dependent.lastName}} ({{dependent.relationship}})</span>
              </div>
            </div>
          </ng-container>
        </div>

      </div>
      <div class="mb-2 planner-panel " *ngIf="shouldShowPlanList()">

        <h3 class="benefit-headers-font">Select the plan to enroll</h3>
        <div class="mb-3" *ngFor="let benefitPlan of availableBenefitPlansToEnroll">
          <div class="planner-panel">
            <div class="row mb-3">
              <div class="col-12">
                <h3 class="benefit-headers-font">{{ benefitPlan.benefitPlan.planName }}</h3>
                <div class="row">
                  <div class="col-6 flex-column text-center align-items-center justify-content-center d-flex" *ngIf="getCost(benefitPlan)">
                    <ng-container>
                      <h3 class="description-nosize">${{getCost(benefitPlan).toFixed(2)}}</h3>
                      <div class="description">Cost per pay period</div>
                    </ng-container>
                  </div>
                  <div class="col-6 description">
                    Effective on {{getEffectiveDate(benefitPlan) | date:'shortDate'}} <br />
                    {{getCostCoverageLabel(benefitPlan)}}

                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <button class="benefit-btn reverse-gray float-left" (click)="openPlanDetail(benefitPlan)">Details</button>
                <button class="benefit-btn float-right" *ngIf="!savingBenefitEnrollment && !activatedDate
                            " [class.reverse]="!isPlanSelected(benefitPlan)" [class.green]="isPlanSelected(benefitPlan)" (click)="selectPlan(benefitPlan)">
                  {{isPlanSelected(benefitPlan)?'Selected':'Select'}}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class=" mb-3" *ngIf="availableBenefitPlansToEnroll && availableBenefitPlansToEnroll.length==0">
          No plans available.
        </div>

      </div>
      <div class=" mb-2" *ngIf="planTypeToEnroll?.id===8">
        <div class="planner-panel">
          <div class=" m-3" *ngFor="let benefitPlan of availableBenefitPlansToEnroll">
            <div class="planner-panel">
              <div class="row mb-3">
                <div class="col-12">
                  <h3 class="benefit-headers-font">{{ benefitPlan.benefitPlan.planName }}</h3>
                  <p class="description">
                    {{ benefitPlan.benefitPlan.summary }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <button class="benefit-btn reverse-gray float-left" (click)="openPlanDetail(benefitPlan)">Details</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class=" mb-2" *ngIf="currentSelectedPlanToEnroll && planTypeToEnroll?.id===3">
        <div class="planner-panel">
          <h3 class="benefit-headers-font">{{currentSelectedPlanToEnroll?.benefitPlan.planName}}</h3>

          <p class="description">Coverage Level: {{getCostCoverageLabel(currentSelectedPlanToEnroll)}}</p>
          <p class="description">{{currentSelectedPlanToEnroll?.benefitPlan.summary}}</p>
        </div>
      </div>
      <div class="mb-2" *ngIf="currentSelectedPlanToEnroll && planTypeToEnroll?.id==7">
        <div class="planner-panel">
          <h3 class="benefit-headers-font">{{currentSelectedPlanToEnroll?.benefitPlan.planName}}</h3>

          <h6>Review the plan information</h6>
          <div class="ml-3">IRS Contribution Limit:  ${{getIrsContributionLimit().toFixed(2)}}</div>
          <div class="ml-3">Effective Date:  {{getEffectiveDate(currentSelectedPlanToEnroll) | date:'shortDate'}}</div>
          <h6>Choose your contribution</h6>
          <div class="ml-3">Eligible Yearly Contribution: ${{getElegibleYearlyContribution(currentSelectedPlanToEnroll).toFixed(2)}} Per Period: ${{getPayperiodElegibleYearContribuion(getElegibleYearlyContribution(currentSelectedPlanToEnroll))}}</div>
          <div class="ml-3"
               *ngIf="!hasSelectedPlanWithSpecialPercentage() && employerContribution?.employerContribution && currentSelectedPlanToEnroll
        && planTypeToEnroll?.planTypeName!=='Employee Perk' && planTypeToEnroll?.planTypeName!=='Universal'">
            Employer contribution used per pay period:  <input class="mb-2" type="number" [disabled]="savingBenefitEnrollment" [(ngModel)]="currentSelectedPlanToEnroll.enrollment.employerContribution" (ngModelChange)="changedEmployeePerPayElection()">
            (Remaining ${{getRemainingEmployerContribution().toFixed(2)}})
          </div>
          <div class="ml-3">Additional employee election per pay period:  <input type="number" [disabled]="savingBenefitEnrollment" [(ngModel)]="currentSelectedPlanToEnroll.enrollment.employeePerPayElection" (ngModelChange)="changedEmployeePerPayElection()"></div>
          <h6>Review Your Selection</h6>
          Your projected deferral through {{getEndDate(currentSelectedPlanToEnroll)| date:'shortDate'}} is
          <span [class.text-danger]=" this.projectedDeferral > getElegibleYearlyContribution(this.currentSelectedPlanToEnroll)"> ${{projectedDeferral?.toFixed(2)}}</span>
        </div>
      </div>

      <div class="planner-panel mb-2" *ngIf="employerContribution?.employerContribution && currentSelectedPlanToEnroll
        && planTypeToEnroll?.planTypeName!=='Employee Perk' && planTypeToEnroll?.planTypeName!=='Universal'
        &&  !hasSelectedPlanWithSpecialPercentage()">

        <div class="float-right description">
          <a href="javascript:void(0)" (click)="openEmployerContributionSummary=!openEmployerContributionSummary">Employer Contribution Summary</a>
          <div class="card position-absolute z10" *ngIf="openEmployerContributionSummary">
            <div class="card-body">
              <div *ngFor="let plan of selectedBenefitPlans">
                <b>{{plan.benefitPlan.benefitPlanTypeLabel}}:</b> ${{plan.enrollment.employerContribution?plan.enrollment.employerContribution:0}}
              </div>
              <hr>
              <div><b>Total Used:</b> ${{totalUsedEmployerContribution()}}</div>
              <div><span [class.text-danger]="getRemainingEmployerContribution()<-0.00999999999999999999"><b>Total Remaining:</b> ${{getRemainingEmployerContribution().toFixed(2)}}</span></div>
            </div>
          </div>
        </div>
        <div>
          <div class="d-flex">
            <h4 class="benefit-headers-font">Check the costs</h4>
          </div>


          <table class="w-100 description">
            <tr>
              <th>
                Plan Cost
              </th>
              <th>
                Employer Contribution <span [class.text-danger]="getRemainingEmployerContribution()<-0.00999999999999999999">
                  (Remaining ${{getRemainingEmployerContribution().toFixed(2)}})
                </span>

                <span class="ml-2 description">
                  <a href="javascript:void(0)" (click)="openWhatIsThis=!openWhatIsThis">What is this?</a>
                  <div class="card position-absolute z10" *ngIf="openWhatIsThis">
                    <div class="card-body">
                      <h4 class="benefit-headers-font">Information about contribution  </h4>
                      <hr>
                      Full time eligible employees are allocated a certain amount of money to apply to their benefits. This is called the Employer Contribution, and it is paid by the employer to help offset the cost of your benefits. The amount for 2022 is the full cost to cover yourself under our Premier MEC Plan, and is $297.41/month, or $137.27 per pay period. You can choose to apply that contribution to any plans that are available to you, in whatever amounts make sense to your family and situation. For more information, please reach out to HR.
                    </div>
                  </div>
                </span>
              </th>
              <th>
                My Cost
              </th>
            </tr>
            <tr>
              <td style="vertical-align: top;">
                ${{getCost(currentSelectedPlanToEnroll)?getCost(currentSelectedPlanToEnroll).toFixed(2):'-'}}
                <div class="float-right mr-3">-</div>
              </td>
              <td style="vertical-align: top;">
                $<input type="number" [disabled]="savingBenefitEnrollment" [(ngModel)]="currentSelectedPlanToEnroll.enrollment.employerContribution" (ngModelChange)="changedEmployeePerPayElection()" />
                <br><span class="description ml-2" style="font-size: 18px;">Enter the amount of your employer contribution you wish to use for this benefit in the box above</span>
              </td>

              <td style="vertical-align: top;">
                <span [class.text-danger]="getMyCost(currentSelectedPlanToEnroll)<0">${{getMyCost(currentSelectedPlanToEnroll).toFixed(2)}}</span>
              </td>

            </tr>
          </table>
        </div>
      </div>

      <div class="planner-panel mb-2" *ngIf="currentSelectedPlanToEnroll && currentSelectedPlanToEnroll.benefitPlan.specialPercentageContribution">

        <div>
          <h4 class="benefit-headers-font">Check the costs</h4>

          <table class="w-100 description">
            <tr>
              <th>
                Plan Cost
              </th>
              <th>
                Employer Contribution ({{currentSelectedPlanToEnroll.benefitPlan.specialPercentageContribution}}% of Employee)

                <span class="ml-2 description">
                  <a href="javascript:void(0)" (click)="openWhatIsThis=!openWhatIsThis">What is this?</a>
                  <div class="card position-absolute z10" *ngIf="openWhatIsThis">
                    <div class="card-body">
                      <h4 class="benefit-headers-font">Information about contribution  </h4>
                      <hr>
                      Full time eligible employees are allocated a certain amount of money to apply to their benefits. This is called the Employer Contribution, and it is paid by the employer to help offset the cost of your benefits. The amount for {{plansYear()+1}} is the full cost to cover yourself under our Premier MEC Plan, and is $297.41/month, or $137.27 per pay period. You can choose to apply that contribution to any plans that are available to you, in whatever amounts make sense to your family and situation. For more information, please reach out to HR.
                    </div>
                  </div>
                </span>
              </th>
              <th>
                My Cost
              </th>
            </tr>
            <tr>
              <td style="vertical-align: top;">
                ${{getCost(currentSelectedPlanToEnroll)?getCost(currentSelectedPlanToEnroll).toFixed(2):'-'}}
                <div class="float-right mr-3">-</div>
              </td>
              <td style="vertical-align: top;">
                $ {{getSpecialPercentageContributionTotal(currentSelectedPlanToEnroll).toFixed(2)}}
                <br><span class="description ml-2" style="font-size: 18px;">Enter the amount of your employer contribution you wish to use for this benefit in the box above</span>
              </td>

              <td style="vertical-align: top;">
                <span [class.text-danger]="getMyCost(currentSelectedPlanToEnroll)<0">${{getMyCost(currentSelectedPlanToEnroll).toFixed(2)}}</span>
              </td>

            </tr>
          </table>
        </div>

      </div>
    </div>
  </div>

  
  <p-footer>
    <div class="row ">
      <div class="col-5  text-left">
        <button [disabled]="savingBenefitEnrollment" class="benefit-btn gray  float-left mb-2" (click)="closePlanEnroll()">BACK</button>
      </div>
      <div class="col-2 text-center">
        <i *ngIf="savingBenefitEnrollment" class="material-icons rotate text-muted">loop</i>
      </div>
      <div class="col-5 text-right">
        <button class="benefit-btn  mb-2" [disabled]="shouldDisableSave()" (click)="confirmSelectedPlanToEnroll()">{{activatedDate?'SAVE':'ENROLL'}}</button>
        <button *ngIf="planTypeToEnroll?.allowDecline
                && !activatedDate" class="benefit-btn bg-danger  ml-3 "
                [disabled]="savingBenefitEnrollment"
                (click)="openPlanReject(planTypeToEnroll)">
          DECLINE COVERAGE
        </button>

      </div>

    </div>
  </p-footer>
</p-dialog>


<p-dialog [focusTrap]="false" [modal]="true"
          [closable]="false"
          [draggable]="false"
          [resizable]="false"
          [keepInViewport]="true"
          [maximizable]="false"
          [style]="{width: '70vw'}"
          [(visible)]="showDeclineReason">
  <p-header>
    <div class="planner-panel">
      <div class="float-left mr-3">
        <i [class]="getPlanTypeClass(planTypeToEnroll?.id)"></i>
      </div>
      <h3 class="benefit-headers-font">
        Decline {{ planTypeToEnroll?.planTypeName }} Plan
      </h3>
      <p class="description">{{ planTypeToEnroll?.description }}</p>
    </div>
  </p-header>

  <div class="row">
    <div class="col">
      <div class="planner-panel mb-2">

        <h3 class="benefit-headers-font">Choose a reason for declining this benefit</h3>
        <div class="ml-2">
          <div *ngFor="let reason of declineReasons">
            <div class="mt-3">
              <p-radioButton name="delcineReason" [value]="reason" [(ngModel)]="selectedReason">
                </p-radioButton> {{reason.reason}}
            </div>
          </div>
          <div *ngIf="selectedReason != null && selectedReason.reason == 'Other'">
            <input type="text" [value]="otherReason" [(ngModel)]="otherReason" class="form-control" />
          </div>
        </div>

      </div>
    </div>
  </div>

  <p-footer>
    <div class="row ">
      <div class="col-5  text-left">
        <button class="benefit-btn gray  float-left mb-2" (click)="cancelRejectCurrentPlan()" [disabled]="savingBenefitEnrollment">Cancel</button>
      </div>
      <div class="col-2 text-center">
        <i *ngIf="savingBenefitEnrollment" class="material-icons rotate text-muted">loop</i>
      </div>
      <div class="col-5 text-right">
        <button class="benefit-btn  mb-2 red" [disabled]="!selectedReason || savingBenefitEnrollment " (click)="confirmRejectCurrentPlan()">DECLINE COVERAGE</button>
      </div>
    </div>
  </p-footer>
</p-dialog>

<p-confirmDialog></p-confirmDialog>
