import { ActivatedRoute, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AccountService } from '../services/account.service';
import { Store } from '@ngrx/store';
import { State } from '../store';
import { logout, setReturnUrl } from '../store/account/account.actions';
import { abUint8ToStr } from 'src/app/helpers/utils';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private store: Store<State>,
    private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if (err.status === 401) {
        // auto logout if 401 response returned from api

        if (!this.router.url.startsWith('/login')) {
          this.store.dispatch(logout());
          this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
          return throwError(`Not Authorized`);
        }
      } else {
        return this.handleError(err);
      }
    }));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      return throwError('Network error: ' + error.error.message);
    } else if (error.error instanceof ArrayBuffer) {
      return throwError(`${abUint8ToStr(error.error)}`);
    } else if (error.error instanceof String) {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      return throwError(`${error.error}`);
    } else if (error.error) {
      return throwError(`${JSON.stringify(error.error)}`);
    } else {
      return throwError(`${JSON.stringify(error)}`);
    }

  }
}
