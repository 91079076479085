import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { CertificationInstructorModel } from '../../../../models/certificationModel';
import { FormGroup } from '@angular/forms';
import { getAllControlsErrors, updateFormGroupValidity, markFormGroupTouched } from '../../../../helpers/utils';
import { AlertService } from '../../../../shared/services/alert.service';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { initEditInstructorForm } from '../../../../core/store/certification/certification.reducer';
import { closeEditInstructor, deleteInstructor, deleteInstructorSuccess, saveInstructor, saveInstructorSuccess, updateInstructorForm } from '../../../../core/store/certification/certification.action';
import { SelectItem } from 'primeng/api';
import { loadEmployeeUsers } from '../../../../core/store/employee/employee.action';

@Component({
  selector: 'app-instructor-edit-certification',
  templateUrl: './instructor-edit-certification.component.html',
  styleUrls: ['./instructor-edit-certification.component.scss']
})
export class InstructorEditCertificationComponent extends BaseComponent implements OnInit {
  display: boolean = false;
  instructor: CertificationInstructorModel;
  title: string;
  form: FormGroup;
  disableButtons: boolean = true;
  hideDelete: boolean = true;
  campusId: number = 0;
  showOnlyActiveEmployees: boolean = true;

  certificationTypes: SelectItem[];
  selectedUser: SelectItem;
  userEmployeeList: SelectItem[];

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private alertService: AlertService) {
    super();
  }

  ngOnInit() {
    this.form = initEditInstructorForm({} as CertificationInstructorModel);
    this.subs.push(
      this.store.select(s => s.certificationState.editingInstructor).subscribe(editingInstructor => {
        this.display = editingInstructor;
      }),
      this.store.select(s => s.certificationState.savingInstructor).subscribe(savingInstructor => {
        this.disableButtons = savingInstructor;
      }),
      this.store.select(s => s.certificationState.selectedInstructor).subscribe(instr => {        
        if (instr === null) {
          this.instructor = null;
          return;
        }

        const changedInstructor = !this.instructor || (this.instructor.instructorId !== instr.instructorId);
        this.instructor = JSON.parse(JSON.stringify(instr));

        if (changedInstructor) {
          this.form = initEditInstructorForm(this.instructor);
          this.subs.push(
            this.form.valueChanges.subscribe(formValues => {
              const formErrors = getAllControlsErrors(this.form);
              this.store.dispatch(updateInstructorForm({ formValues, formErrors }));
            })
          )
          if (typeof instr.instructorId === 'undefined' || instr.instructorId === null || instr.instructorId < 1) {
            this.title = "Add Instructor";
            this.selectedUser = { label: "", value: 0 };
            this.store.dispatch(updateInstructorForm({ formValues: this.form.value, formErrors: getAllControlsErrors(this.form) }));
            this.hideDelete = true;
          } else {
            this.title = "Edit Instructor #" + instr.instructorId;
            this.selectedUser = { label: instr.firstName + " " + instr.lastName, value: instr.userId };           
            this.hideDelete = false;
          }
        }
      }),
      this.store.select(s => s.certificationState.certificationTypes).subscribe(types => {
        if (types && types.length > 0) {
          this.certificationTypes = types.map(x => ({ label: x.label, value: x.value }));
          this.certificationTypes.unshift({ label: '', value: 0 });
        }
        else {
          this.certificationTypes = [{ label: 'loading...', value: undefined }]
        }
      }),
      this.store.select(s => s.employeeState.employeeUsers).subscribe(users => {
        this.userEmployeeList = users;
      }),
      this.actions$.pipe(
        ofType(saveInstructorSuccess),
        tap(action => {
          this.closeModal();
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(deleteInstructorSuccess),
        tap(action => {
          this.closeModal();
        })
      ).subscribe(),
    );
  }

  getStaffList(event) {
    this.store.dispatch(loadEmployeeUsers({
      prefixText: event.query,
      programId: this.campusId,
      payrollClassificationId: null,
      showTerminated: !this.showOnlyActiveEmployees,
      showInactive: !this.showOnlyActiveEmployees
    }));
  }

  setUserAfterStaffChange(selectedUser) {
    if (typeof selectedUser.value !== 'number') {
      return;
    }

    this.form.get('userId').setValue(selectedUser.value);
  }

  clearUserAfterClear(event) {
    if (event.data !== null) {
      return;
    }

    this.form.get('userId').setValue(0);
  }

  closeModal() {
    this.store.dispatch(closeEditInstructor());
  }

  saveInstructorEdits() {
    updateFormGroupValidity(this.form);
    if (this.form.valid) {
      this.store.dispatch(saveInstructor({ instructor: this.instructor }));
    } else {
      markFormGroupTouched(this.form);
    }
  }

  deleteInstructor() {
    this.alertService.confirm('Archive/Delete Instructor',
      'Are you sure you want to archive/delete this instructor?')
      .subscribe(
        answer => {
          if (answer) {
            this.store.dispatch(deleteInstructor({ instructorId: this.instructor.instructorId }));
          }
        }, error => {
        }
      );
  }
}
