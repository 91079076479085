import { Action, createReducer, on } from '@ngrx/store';
import { PagingResultsModel } from '../../../models/pagingResultsModel';
import { FullTimeStatusCheckModel } from '../../../models/fullTimeStatusCheckModel';
import { clearRehireEmployee, closeTerminateEmployee, exportEmployeeCensusListToExcel, exportEmployeeCensusListToExcelFailure, exportEmployeeCensusListToExcelSuccess, exportEmployeeFullTimeStatusListToExcel, exportEmployeeFullTimeStatusListToExcelFailure, exportEmployeeFullTimeStatusListToExcelSuccess, exportEmployeeTerminationListToExcel, exportEmployeeTerminationListToExcelFailure, exportEmployeeTerminationListToExcelSuccess, getEmployeeCensusList, getEmployeeCensusListSuccess, getEmployeeTerminationList, getEmployeeTerminationListSuccess, getFullTimeStatusCheckList, getFullTimeStatusCheckListSuccess, getFullTimeStatusQueue, getFullTimeStatusQueueSuccess, loadEmployeeUsersSuccess, loadSeparationSubReasonsSuccess, loadTerminationReasonsSuccess, loadViewableCampusesSuccess, rehireEmployee, rehireEmployeeFailure, rehireEmployeeSuccess, setRehireEmployee, showTerminateEmployee, updateTerminateEmployeeForm } from './employee.action';
import { SelectItem } from 'primeng/api';
import { EmployeeCensusModel, TerminatedEmployeeModel } from '../../../models/userProfileModel';
import { UserModel } from '../../../models/userModel';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModifiedSelectItem } from '../../../models/utilModels';

export interface EmployeeState {
  campuses: SelectItem[];
  employeeUsers: SelectItem[];
  terminationReasons: SelectItem[];
  separationSubReasons: ModifiedSelectItem[];

  statusList: PagingResultsModel<FullTimeStatusCheckModel>;
  queue: PagingResultsModel<FullTimeStatusCheckModel>;
  terminationList: PagingResultsModel<TerminatedEmployeeModel>;
  censusList: PagingResultsModel<EmployeeCensusModel>;

  loadingStatusList: boolean;
  loadingQueue: boolean;
  loadingTerminationList: boolean;
  loadingCensusList: boolean;
  loadingTerminationExcel: boolean;
  loadingCensusExcel: boolean;
  loadingFullTimeStatusExcel: boolean;
  editingEmployee: boolean;
  terminatingEmployee: boolean;

  terminationExcelArrayBuffer: ArrayBuffer;
  censusExcelArrayBuffer: ArrayBuffer;
  fullTimeStatusExcelArrayBuffer: ArrayBuffer;

  rehireUser: UserModel;
  terminateUser: TerminatedEmployeeModel;
};

const initialState: EmployeeState = {
  campuses: [],
  employeeUsers: [],
  terminationReasons: [],
  separationSubReasons: [],

  statusList: null,
  queue: null,
  terminationList: null,
  censusList: null,

  loadingStatusList: true,
  loadingQueue: true,
  loadingTerminationList: true,
  loadingCensusList: true,
  loadingTerminationExcel: false,
  loadingCensusExcel: false,
  loadingFullTimeStatusExcel: false,
  editingEmployee: false,
  terminatingEmployee: false,

  terminationExcelArrayBuffer: null,
  censusExcelArrayBuffer: null,
  fullTimeStatusExcelArrayBuffer: null,

  rehireUser: null,
  terminateUser: null
};

export const employeeReducer = createReducer(initialState,
  on(loadViewableCampusesSuccess, (state, action) => {
    return {
      ...state,
      campuses: action.campuses,
    };
  }),
  on(loadEmployeeUsersSuccess, (state, action) => {
    return {
      ...state,
      employeeUsers: action.users,
    };
  }),
  on(loadTerminationReasonsSuccess, (state, action) => {
    return {
      ...state,
      terminationReasons: action.reasons,
    };
  }),
  on(loadSeparationSubReasonsSuccess, (state, action) => {
    return {
      ...state,
      separationSubReasons: action.reasons,
    };
  }),
  on(getFullTimeStatusCheckList, (state, action) => {
    return {
      ...state,
      loadingStatusList: true
    };
  }),
  on(getFullTimeStatusCheckListSuccess, (state, action) => {
    return {
      ...state,
      loadingStatusList: false,
      statusList: action.statusList
    };
  }),
  on(getFullTimeStatusQueue, (state, action) => {
    return {
      ...state,
      loadingQueue: true
    };
  }),
  on(getFullTimeStatusQueueSuccess, (state, action) => {
    return {
      ...state,
      loadingQueue: false,
      queue: action.queue
    };
  }),
  on(getEmployeeTerminationList, (state, action) => {
    return {
      ...state,
      loadingTerminationList: true
    };
  }),
  on(getEmployeeTerminationListSuccess, (state, action) => {
    return {
      ...state,
      loadingTerminationList: false,
      terminationList: action.terminationList
    };
  }),
  on(getEmployeeCensusList, (state, action) => {
    return {
      ...state,
      loadingCensusList: true
    };
  }),
  on(getEmployeeCensusListSuccess, (state, action) => {
    return {
      ...state,
      loadingCensusList: false,
      censusList: action.censusList
    };
  }),

  on(exportEmployeeTerminationListToExcel, (state, action) => {
    return {
      ...state,
      loadingTerminationExcel: true,
      terminationExcelArrayBuffer: null
    };
  }),
  on(exportEmployeeTerminationListToExcelSuccess, (state, action) => {
    return {
      ...state,
      terminationExcelArrayBuffer: action.doc,
      loadingTerminationExcel: false
    };
  }),
  on(exportEmployeeTerminationListToExcelFailure, (state, action) => {
    return {
      ...state,
      loadingTerminationExcel: false
    };
  }),

  on(exportEmployeeCensusListToExcel, (state, action) => {
    return {
      ...state,
      loadingCensusExcel: true,
      censusExcelArrayBuffer: null
    };
  }),
  on(exportEmployeeCensusListToExcelSuccess, (state, action) => {
    return {
      ...state,
      censusExcelArrayBuffer: action.doc,
      loadingCensusExcel: false
    };
  }),
  on(exportEmployeeCensusListToExcelFailure, (state, action) => {
    return {
      ...state,
      loadingCensusExcel: false
    };
  }),

  on(exportEmployeeFullTimeStatusListToExcel, (state, action) => {
    return {
      ...state,
      loadingFullTimeStatusExcel: true,
      fullTimeStatusExcelArrayBuffer: null
    };
  }),
  on(exportEmployeeFullTimeStatusListToExcelSuccess, (state, action) => {
    return {
      ...state,
      fullTimeStatusExcelArrayBuffer: action.doc,
      loadingFullTimeStatusExcel: false
    };
  }),
  on(exportEmployeeFullTimeStatusListToExcelFailure, (state, action) => {
    return {
      ...state,
      loadingFullTimeStatusExcel: false
    };
  }),

  on(rehireEmployee, (state, action) => {
    return {
      ...state,
      editingEmployee: true,
      rehireUser: null
    };
  }),
  on(rehireEmployeeSuccess, (state, action) => {
    return {
      ...state,
      rehireUser: action.employeeUser,
      editingEmployee: false
    };
  }),
  on(rehireEmployeeFailure, (state, action) => {
    return {
      ...state,
      editingEmployee: false
    };
  }),

  on(clearRehireEmployee, (state, action) => {
    return {
      ...state,
      rehireUser: null
    };
  }),
  on(setRehireEmployee, (state, action) => {
    return {
      ...state,
      rehireUser: action.employeeUser
    };
  }),

  on(showTerminateEmployee, (state, action) => {
    return {
      ...state,
      terminateUser: action.employeeUser,
      terminatingEmployee: true
    };
  }),
  on(closeTerminateEmployee, (state, action) => {
    return {
      ...state,
      terminateUser: null,
      terminatingEmployee: false
    };
  }),
  on(updateTerminateEmployeeForm, (state, action) => {
    const terminatedEmployee = { ...state.terminateUser, ...action.formValues };
    const newState = { ...state, ...{ terminateUser: terminatedEmployee, formErrors: action.formErrors } };
    return newState;
  }),
);

export function employeeReducerFunc(state: EmployeeState | undefined, action: Action) {
  return employeeReducer(state, action);
}

export function initTerminateEmployee(employee: TerminatedEmployeeModel) {
  if (employee == null) {
    employee = {} as TerminatedEmployeeModel;
  }

  if (typeof employee.userId === 'undefined') {
    employee.userId = 0;
    employee.terminationReasonId = 0;
    employee.dateString = "";
    employee.subReasonIds = [];
  }

  const form = new FormGroup({
    userId: new FormControl(employee.userId),
    dateString: new FormControl(employee.dateString, [Validators.required]),
    terminationReasonId: new FormControl(employee.terminationReasonId, [Validators.required]),
    subReasonIds: new FormControl(employee.subReasonIds)
  });

  return form;
}
