import { downloadImprovementPlanDoc, signImprovementPlanDocSuccess, signImprovementPlanDoc } from './../../../../core/store/improvementPlan/improvementPlan.actions';
import { loadProfile } from './../../../../core/store/profile/profile.actions';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from './../../../../shared/services/alert.service';
import { signOfferLetterDoc, signOfferLetterDocSuccess } from './../../../../core/store/offerletter/offerletter.actions';
import { UserModel } from 'src/app/models/userModel';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { OfferLetterModel } from '../../../../models/offerletterModel';
import { loadOfferLetterDoc } from 'src/app/core/store/offerletter/offerletter.actions';
import { Actions, ofType } from '@ngrx/effects';
import * as moment from 'moment';
import { SignDocComponentBase } from '../../../abstract/signDocBase.component';
import { OnBoardingDocumentUserModel, OnBoardingDocumentModel } from 'src/app/models/onBoardingDocumentUserModel';
import { signOnboardingDoc, loadOnboardingDoc, signOnboardingDocSuccess } from 'src/app/core/store/onboardingdocs/onboardingdocs.actions';
import { tap } from 'rxjs/operators';
import { downloadFile } from 'src/app/helpers/utils';

@Component({
  selector: 'app-sign-improvementplan',
  templateUrl: './sign-improvementPlan.component.html',
  styleUrls: ['./sign-improvementPlan.component.scss']
})
export class SignImprovementPlanComponent extends SignDocComponentBase implements OnInit, AfterViewInit {

  improvementPlanID: number;
  loadingSign = false;
  loadingDoc = true;
  url: any;
  user: UserModel;

  constructor(
    private store: Store<State>,
    private actions$: Actions,
    private alert: AlertService,
    private router: Router,
    private route: ActivatedRoute) {
    super(store);
  }

  ngOnInit() {
    this.store.dispatch(loadProfile());
    this.route.params.subscribe(params => {
      if (params.improvementPlanID) {
        this.improvementPlanID = params.improvementPlanID;
        this.store.dispatch(downloadImprovementPlanDoc({ improvementPlanID: this.improvementPlanID }));
      }
    });
    this.subs.push(
      this.store.select(s => s.profileState.profile).subscribe(p => {
        if (p) {
          this.user = p.user;
        }
      }),
      this.store.select(s => s.improvementPlanState.docArrayBuffer).subscribe(docArrayBuffer => {
        if (docArrayBuffer) {
          this.url =
            URL.createObjectURL(new Blob([docArrayBuffer], {
              type: 'application/pdf'
            })) + '&avoidcache=' + (new Date()).getTime();
        }
      }),
      this.store.select(s => s.improvementPlanState.loadingDoc).subscribe(loadingDoc => {
        this.loadingDoc = loadingDoc;
      }),
      this.store.select(s => s.improvementPlanState.loadingSign).subscribe(loadingSign => {
        this.loadingSign = loadingSign;
      }),
      this.actions$.pipe(
        ofType(signImprovementPlanDocSuccess),
        tap(action => {
          this.store.dispatch(loadProfile());
          this.alert.confirm('Documentation signed succesfully',
            'Documentation signed succesfully. Do you want to download the signed file now?')
            .subscribe(
              answer => {
                if (answer) {
                  downloadFile(action.docSigned, 'document.pdf');
                }
                this.router.navigate(['/coachinglogs/' + this.route.snapshot.queryParams.backTo]);
              }, error => {
                this.router.navigate(['/coachinglogs/' + this.route.snapshot.queryParams.backTo]);
              }
            );
        })
      ).subscribe()
    );
  }

  get loggedUserName() {
    return !this.user ? '' : (this.user.firstName + ' ' + this.user.lastName);
  }

  get loggedUserEmail() {
    return !this.user ? '' : this.user.email;
  }

  sign() {
    if (!this.finishSignDisabled) {
      this.store.dispatch(signImprovementPlanDoc({ improvementPlanID: this.improvementPlanID, signedFields: this.signedFields }));
    }
  }

}
