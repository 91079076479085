import { Injectable } from "@angular/core";
import { State } from '..';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BenefitPlanEDIService } from "../../services/benefitPlanEDI.service";
import {
  getBenefitPlanEDITransmissionMembers, getBenefitPlanEDITransmissionMembersSuccess,
  getBenefitPlanEDITransmissions, getBenefitPlanEDITransmissionsSuccess,
  putBenefitPlanEDITransmission, putBenefitPlanEDITransmissionSuccess, putBenefitPlanEDITransmissionFailure,
  exportBenefitPlanEDITransmissionData, exportBenefitPlanEDITransmissionDataSuccess, exportBenefitPlanEDITransmissionDataFailure
} from "./benefitPlanEDI.action";
import { map, switchMap, catchError } from 'rxjs/operators';
import { errorHappened } from '../profile/profile.actions';
import { of } from 'rxjs';

@Injectable()
export class BenefitPlanEDIEffects {
  constructor(private actions$: Actions,
    private benefitPlanEDIService: BenefitPlanEDIService,
    private store: Store<State>) { }

  getBenefitPlanEDITranmissions = createEffect(() => this.actions$.pipe(
    ofType(getBenefitPlanEDITransmissions),
    switchMap(action => {
      return this.benefitPlanEDIService.getBenefitPlanEDITransmissions(action.filter).pipe(
        map(transmissions => getBenefitPlanEDITransmissionsSuccess({ transmissions })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  getBenefitPlanEDITransmissionMembers = createEffect(() => this.actions$.pipe(
    ofType(getBenefitPlanEDITransmissionMembers),
    switchMap(action => {
      return this.benefitPlanEDIService.getBenefitPlanEDITransmissionMembers(action.filter).pipe(
        map(transmissionMembers => getBenefitPlanEDITransmissionMembersSuccess({ transmissionMembers })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));


  exportBenefitPlanEDITransmissionData = createEffect(() => this.actions$.pipe(
    ofType(exportBenefitPlanEDITransmissionData),
    switchMap(action => {
      return this.benefitPlanEDIService.exportBenefitPlanEDITransmissionData(action.transmissionId, action.carrier).pipe(
        map(doc => exportBenefitPlanEDITransmissionDataSuccess({ doc })),
        catchError(err => {
          this.store.dispatch(exportBenefitPlanEDITransmissionDataFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  putBenefitPlanEDITransmission = createEffect(() => this.actions$.pipe(
    ofType(putBenefitPlanEDITransmission),
    switchMap(action => {
      return this.benefitPlanEDIService.putBenefitPlanEDITransmission(action.carrier).pipe(
        map(transmission => putBenefitPlanEDITransmissionSuccess({ transmission })),
        catchError(err => {
          this.store.dispatch(putBenefitPlanEDITransmissionFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

}
