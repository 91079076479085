import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { State } from '../../../../core/store';
import { Store } from '@ngrx/store';
import { DateRangeModel } from '../../../../models/dateRangeModel';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { EmployeeCensusModel } from '../../../../models/userProfileModel';
import { DropdownFilterService } from '../../../../core/services/dropdown-filter.service';
import { exportEmployeeCensusListToExcel, exportEmployeeCensusListToExcelSuccess, getEmployeeCensusList, loadEmployeeUsers, loadViewableCampuses } from '../../../../core/store/employee/employee.action';
import { deepClone, downloadFile } from '../../../../helpers/utils';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-report-census',
  templateUrl: './report-census.component.html',
  styleUrls: ['./report-census.component.scss']
})
export class ReportCensusComponent extends BaseComponent implements OnInit {
  list: PagingResultsModel<EmployeeCensusModel>;
  filter: any;
  canEditUser: boolean = false;

  loadingCensusList: boolean = true;
  loadingCensusExcel: boolean = false;

  campusList: SelectItem[];
  campusId: number = 0;

  prefixText: string;
  userEmployeeList: SelectItem[];
  userId: number = 0;

  employeePayrollNumber: string = "";

  birthdate: string = null;

  showTerminatedEmployees: boolean = false;
  showInactiveUsers: boolean = false;

  constructor(
    private router: Router,
    private actions$: Actions,
    private store: Store<State>) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.canEditUser = permissionIDs && !!permissionIDs.find(p => p === 6001 || p === 6002); //HR Manager, HR Super Admin
      }),
      this.store.select(s => s.employeeState.loadingCensusList).subscribe(loadingCensusList => {
        this.loadingCensusList = loadingCensusList;
      }),
      this.store.select(s => s.employeeState.loadingCensusExcel).subscribe(loadingCensusExcel => {
        this.loadingCensusExcel = loadingCensusExcel;
      }),
      this.store.select(s => s.employeeState.campuses).subscribe(campuses => {
        this.campusList = campuses && campuses.length > 0 ? campuses
          : [{ label: 'loading...', value: undefined }];
      }),
      this.store.select(s => s.employeeState.employeeUsers).subscribe(users => {
        this.userEmployeeList = users;
      }),
      this.store.select(s => s.employeeState.censusList).subscribe(censusList => {
        this.list = censusList;
      }),
      this.actions$.pipe(
        ofType(exportEmployeeCensusListToExcelSuccess),
        tap(action => {
          downloadFile(action.doc, 'EmployeeCensus.xlsx');
        })
      ).subscribe()
    );

    this.store.dispatch(loadViewableCampuses());
  }

  reloadList(event) {
    this.filter = event;
    this.filter = deepClone(this.filter);
    this.filter.filters.userId = { Name: 'userId', Value: { value: this.userId.toString() } };
    this.filter.filters.programId = { Name: 'programId', Value: { value: this.campusId.toString() } };
    this.filter.filters.employeePayrollNumber = { Name: 'employeePayrollNumber', Value: { value: this.employeePayrollNumber } };
    this.filter.filters.birthdate = { Name: 'birthdate', Value: { value: this.birthdate } };
    this.filter.filters.showTerminatedEmployees = { Name: 'showTerminatedEmployees', Value: { value: this.showTerminatedEmployees.toString() } };
    this.filter.filters.showInactiveUsers = { Name: 'showInactiveUsers', Value: { value: this.showInactiveUsers.toString() } };

    this.store.dispatch(getEmployeeCensusList({
      filter: this.filter
    }));
  }

  getStaffList(event) {
    this.store.dispatch(loadEmployeeUsers({
      prefixText: event.query,
      programId: this.campusId,
      payrollClassificationId: null,
      showInactive: this.showInactiveUsers,
      showTerminated: this.showTerminatedEmployees
    }));
  }

  reloadListAfterStaffChage(selectedUser) {
    if (typeof selectedUser.value !== 'number') {
      return;
    }

    this.userId = selectedUser.value;
    this.reloadList(this.filter);
  }

  reloadListAfterClear(event) {
    if (event.data !== null) {
      return;
    }

    this.userId = 0;
    this.reloadList(this.filter);
  }

  exportToExcel() {
    this.filter = deepClone(this.filter);
    this.filter.filters.userId = { Name: 'userId', Value: { value: this.userId.toString() } };
    this.filter.filters.programId = { Name: 'programId', Value: { value: this.campusId.toString() } };
    this.filter.filters.employeePayrollNumber = { Name: 'employeePayrollNumber', Value: { value: this.employeePayrollNumber } };
    this.filter.filters.birthdate = { Name: 'birthdate', Value: { value: this.birthdate } };
    this.filter.filters.showTerminatedEmployees = { Name: 'showTerminatedEmployees', Value: { value: this.showTerminatedEmployees.toString() } };
    this.filter.filters.showInactiveUsers = { Name: 'showInactiveUsers', Value: { value: this.showInactiveUsers.toString() } };

    this.store.dispatch(exportEmployeeCensusListToExcel({
      filter: this.filter
    }));
  }

  editEmployee(employee: EmployeeCensusModel = null) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/employee-file/detail/' + employee.userId])
    );

    window.open(url, '_blank');

    //this.router.navigate(['/employee-file/detail/' + employee.userId]);
  }
}
