import { OnBoardingDocumentUserModel } from './../../../../models/onBoardingDocumentUserModel';
import { UserProfileModel } from '../../../../models/userProfileModel';
import { loadProfile } from '../../../../core/store/profile/profile.actions';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from '../../../../shared/services/alert.service';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { Actions, ofType } from '@ngrx/effects';
import * as moment from 'moment';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { loadOnboardingDocInfo } from 'src/app/core/store/onboardingdocs/onboardingdocs.actions';

@Component({
  selector: 'app-doc-fill-info',
  templateUrl: './doc-fill-info.component.html',
  styleUrls: ['./doc-fill-info.component.scss']
})
export class DocFillInfoComponent extends BaseComponent implements OnInit {

  profile: UserProfileModel;
  isBCI: boolean;
  isCriminalBackgorundScreening: boolean;
  isPRISMProviderAgreement: boolean;
  isI9Form: boolean;
  isW4Form: boolean;
  onboardingDocUserId;
  docInfo: OnBoardingDocumentUserModel;

  constructor(
    private store: Store<State>,
    private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params.onboardingDocUserId) {
        this.onboardingDocUserId = params.onboardingDocUserId;
        this.store.dispatch(loadOnboardingDocInfo({ onboardingDocUserId: this.onboardingDocUserId }));
      }
    });
    this.subs.push(
      this.store.select(s => s.onBoardingDocsState.docInfo).subscribe(docInfo => {
        if (docInfo) {
          this.docInfo = docInfo;
          if (docInfo.onBoarding_Document.fileName.startsWith('BCI'))
            this.isBCI = true;
          else if (docInfo.onBoarding_Document.fileName.startsWith('CriminalBackgorundScreeningAuthorizationForm'))
            this.isCriminalBackgorundScreening = true;
          else if (docInfo.onBoarding_Document.fileName.startsWith('PrismProviderAgreement'))
            this.isPRISMProviderAgreement = true;
          else if (docInfo.onBoarding_Document.fileName.startsWith('i9Form'))
            this.isI9Form = true;
          else if (docInfo.onBoarding_Document.fileName.startsWith('w4_'))
            this.isW4Form = true;
        }
      }),
    );

  }

}
