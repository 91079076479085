import { UserProfileModel } from './../../models/userProfileModel';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from '../store';
import { loadProfile } from '../store/profile/profile.actions';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private store: Store<State>,) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem('currentUser')) {
      let profile: UserProfileModel;
      this.store.dispatch(loadProfile());
      this.store.select(s => s.profileState.profile).subscribe(p => {
        profile = p;
      });

      if (profile) {
        if (profile.employee != null && profile.employee.terminationDate != null) {
          return false;
        }
        if (profile.isOnBoarding) {
          const url = route['_routerState'].url;
          if (url !== '/onboarding' && url !== '/' && !url.startsWith('/onboarding/')) {
            return false;
          }
          return true;
        }
      }

      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    if (state.url) {
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    } else {
      this.router.navigate(['/login']);
    }
    return false;
  }
}
