<ul class="nav nav-tabs mb-0 pb-0">
  <li class="nav-item w-25 border border-bottom-0 border-left-0 rounded-top" (click)="employeeTabClick()">
    <a #employeeTab class="nav-link active text-secondary text-center font-weight-bold" aria-current="page" href="javascript:void(0)">Employee</a>
  </li>
  <li class="nav-item w-25 border border-bottom-0 border-left-0 rounded-top" (click)="clientTabClick()">
    <a #clientTab class="nav-link text-secondary text-center font-weight-bold" aria-current="page" href="javascript:void(0)">Client</a>
  </li>
</ul>
<form class="card border-top-0" *ngIf="canEdit">
  <div class="card-body">
    <button *ngIf="canEdit" class="btn btn-primary mr-2 float-right pointer" routerLink="/form-builder/form-assignment-job-titles">Manage Job Titles Assignment</button>
    <button *ngIf="canEdit" class="btn btn-primary mr-2 float-right pointer" routerLink="/form-builder/form-assignment-roles">Manage Roles Assignment</button>
    <button *ngIf="canEdit" class="btn btn-primary mr-2 float-right pointer" routerLink="/form-builder/form-assignment-create">Create Direct Assignment</button>
    <button *ngIf="canEdit" class="btn btn-link float-right pointer" routerLink="/form-builder/forms">Forms</button>
    <h2 class="mb-3">Form Assignments - {{ tabSelection }}</h2>

    <h5>Filter <button class="btn btn-link ml-2" (click)="toggleFilter()">{{ showFilter }}</button></h5>
    <div class="row mb-3" [hidden]="showFilter == 'Show'">
      <div class="col-12 col-sm-6">
        <div class="row mb-1">
          <div class="col-4 d-flex align-items-center">
            <h6>Form Assigned to {{ tabSelection }}:</h6>
          </div>
          <div class="col-8 ">
            <p-multiSelect #formNameMultiSelect class="w-100" styleClass="w-100 d-flex mt-2" inputStyleClass="form-control" (onFocus)="openMultiSelect(formNameMultiSelect)" [options]="formOptions" [defaultLabel]="'Select Form...'" [ngModelOptions]="{standalone: true}" [(ngModel)]="filterFormID" (ngModelChange)="changedFilters()"></p-multiSelect>
          </div>
        </div>

        <div class="row mb-1" *ngIf="tabSelection == 'Employees'">
          <div class="col-4 d-flex align-items-center">
            <h6>
              User:
              <p-checkbox id="includeTerminatedUsers" class="ml-1" name="includeTerminatedUsers" styleClass="form-check-input" [(ngModel)]="includeTerminatedUsers" (onChange)="includeTerminatedChange($event)"></p-checkbox>
              <label style="font-size:.55rem;" class=" ml-4" for="includeTerminatedUsers"><em> - Include Separated Employees In Results</em></label>
            </h6>
          </div>
          <div class="col-8 ">
            <p-multiSelect #usersMultiSelect class="w-100" styleClass="w-100 d-flex  mt-2" inputStyleClass="form-control" (onFocus)="openMultiSelect(usersMultiSelect)" autofocusFilter="true" [options]="userOptions" [defaultLabel]="'Select User...'" [ngModelOptions]="{standalone: true}" [(ngModel)]="filterUserID" (ngModelChange)="changedFilters()"></p-multiSelect>
          </div>
        </div>
        <div class="row mb-1" *ngIf="tabSelection == 'Clients'">
          <div class="col-4 d-flex align-items-center">
            <h6>Patient:</h6>
          </div>
          <div class="col-8 position-relative">
            <app-input-autocomplete [length]="15"
                                    [type]="'Inquiry'"
                                    [(model)]="inputPatient"
                                    (modelChange)="setPatient($event)"
                                    [inputClass]="'form-control'"></app-input-autocomplete>
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-6">
        <div class="row mb-1">
          <div class="col-4 d-flex align-items-center">
            <h6>Missing Any Signatures:</h6>
          </div>
          <div class="col-8 ">
            <p-dropdown styleClass="form-control mt-2 w-100" [options]="yesNoAllOptions" placeholder="Select type..." [ngModelOptions]="{standalone: true}" [(ngModel)]="filterSigned" (ngModelChange)="changedFilters()"></p-dropdown>
          </div>
        </div>
        <div class="row mb-1">
          <div class="col-4 d-flex align-items-center">
            <h6>Missing Required Signatures:</h6>
          </div>
          <div class="col-8 ">
            <p-dropdown styleClass="form-control mt-2 w-100" [options]="yesNoAllOptionsRequired" placeholder="Select type..." [ngModelOptions]="{standalone: true}" [(ngModel)]="filterRequired" (ngModelChange)="changedFilters()"></p-dropdown>
          </div>
        </div>
      </div>
    </div>
    <i *ngIf="!formsAssignmentList" class="material-icons rotate text-muted">loop</i>
    <p-table #usersWithFormTable class=" position-relative" [value]="formsAssignmentList?.result" [responsive]="true"
             [paginator]="true" [rows]="20" [totalRecords]="formsAssignmentList?.totalRecords" [lazy]="true"
             (onLazyLoad)="loadPage($event)">
      <ng-template pTemplate="header">
        <tr>
          <th width="145px" class="text-center"></th>
          <th class="text-center" [pSortableColumn]="'UsersName'">
            {{tabSelection == "Employees" ? "Employee's" : "Client's"}} Name
            <p-sortIcon [field]="'Name'"></p-sortIcon>
          </th>
          <th class="text-center" [pSortableColumn]="'UnSignedRequiredCount'">
            # Of Required Forms Not Signed
            <p-sortIcon [field]="'UnSignedRequiredCount'"></p-sortIcon>
          </th>

          <th width="50%" class="text-center">
            Assigned Forms
            <div class="font-weight-light font-italic d-flex justify-content-center w-100 p-0 m-0">Key - Unsigned Forms:&nbsp;<p class="text-warning font-weight-normal">yellow</p>&nbsp;- signature not required,&nbsp;<p class="text-danger font-weight-normal">red</p>&nbsp;- signature required</div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-formAssignment>
        <tr>
          <td class="text-center">
            <span class="pointer mr-2" (click)="open(userformsmodal, formAssignment.userID, formAssignment.inquiryID, formAssignment.usersName)"><i title="View Forms" class="fab fa-wpforms"></i></span>
          </td>
          <td class="text-center">
            <span class="ui-column-title">UsersName</span>
            {{formAssignment.usersName }}
          </td>
          <td class="text-center font-weight-bold"
              [ngClass]="{'text-danger': formAssignment.unSignedRequiredCount > 0 }">
            <span class="ui-column-title">UnSignedRequiredCount</span>
            {{formAssignment.unSignedRequiredCount }}
          </td>
          <td>
            <span class="ui-column-title">AssignedForms</span>
            <ul class="w-100 row">
              <li class="col-6" *ngFor="let item of formAssignment.assignedFormNames">
                <p class="p-0 m-0 rounded" [ngClass]="{'text-warning': item.required == false && item.signed == false, 'text-danger': item.required == true && item.signed == false }">
                  {{ item.formName }}
                </p>
              </li>
            </ul>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</form>
<ng-template #userformsmodal class="modal-index" let-modal>
  <div class="modal-body">
      <app-form-assignment-modal
        [userID]="selectedUserID"
        [inquiryID]="selectedInquiryID"
        [usersName]="selectedUsersName"
        (myEvent)="modal.close($event)"  
      ></app-form-assignment-modal>
  </div>
</ng-template>
<p-confirmDialog></p-confirmDialog>
