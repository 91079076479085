import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';


import { AlertService } from './../../services/alert.service';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';

@Component({
  selector: 'app-alert',
  templateUrl: 'alert.component.html'
})

export class AlertComponent extends BaseComponent implements OnInit, OnDestroy {
  message: any;

  constructor(private alertService: AlertService, private messageService: MessageService) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.alertService.getMessage().subscribe(message => {
        this.message = message;
      })
    );
  }

  onConfirm() {
    this.messageService.clear('confirmToat');
    this.alertService.$confirm.next(true);
    this.alertService.$confirm.complete();

  }

  onReject() {
    this.messageService.clear('confirmToat');
    this.alertService.$confirm.next(false);
    this.alertService.$confirm.complete();
  }
}
