import { PagingResultsModel } from './../../../models/pagingResultsModel';
import { createAction, props } from '@ngrx/store';
import { OnBoardingDocumentUserModel, UserOnBoardingDocumentsModel, OnBoardingDocumentModel, OnBoardingDocumentModelWithRoles, UpdateOnboardingDocUserForceNotRequiredModel, ResendOnboardingDocUserForceModel } from 'src/app/models/onBoardingDocumentUserModel';
import { FormError } from 'src/app/models/utilModels';

export const loadOnboardingDoc = createAction('[Onboarding Docs] Load Onboarding Docs Doc', props<{ onboardingDocUserId: number }>());
export const loadOnboardingDocSuccess = createAction('[Onboarding Docs] Load Onboarding Docs Doc Success', props<{ doc: ArrayBuffer }>());

export const signOnboardingDoc = createAction('[Onboarding Docs] Sign Onboarding Docs Doc',
  props<{ onboardingDocUserId: number, signedFields: string[] }>());
export const signOnboardingDocSuccess = createAction('[Onboarding Docs]  Sign Onboarding Docs Doc Success',
  props<{ docSigned: ArrayBuffer }>());
export const signOnboardingDocFailure = createAction('[Onboarding Docs]  Sign Onboarding Docs Failure');


export const getOnboardingDocsList = createAction('[Onboarding Docs] Get  list',
  props<{ userId?: number }>());
export const getOnboardingDocsListSuccess = createAction('[Onboarding Docs] Get  list Success',
  props<{ docs: OnBoardingDocumentUserModel[] }>());

export const loadOnboardingDocInfo = createAction('[Onboarding Docs] Load Onboarding Docs Info', props<{ onboardingDocUserId: number }>());
export const loadOnboardingDocInfoSuccess = createAction('[Onboarding Docs] Load Onboarding Docs Info Success',
  props<{ doc: OnBoardingDocumentUserModel }>());



export const setOnboardingDocsInfoFilled = createAction('[Onboarding Docs] Set OnboardingDocs InfoFilled',
  props<{ onboardingDocUserId: number }>());
export const setOnboardingDocsInfoFilledSuccess = createAction('[Onboarding Docs]  Set OnboardingDocs InfoFilled Success',
  props<{ doc: OnBoardingDocumentUserModel }>());


export const getAllUserDocs = createAction('[Onboarding Docs] Get All User Docs',
  props<{ filter: any }>());
export const getAllUserDocsSuccess = createAction('[Onboarding Docs] Get All User Docs Success',
  props<{ docs: PagingResultsModel<UserOnBoardingDocumentsModel> }>());

export const getActiveDocs = createAction('[Onboarding Docs] Get All Active Docs');
export const getActiveDocsSuccess = createAction('[Onboarding Docs] Get All Active Docs Success',
  props<{ docs: OnBoardingDocumentModel[] }>());


export const getUsersDocsReport = createAction('[Onboarding Docs] Get Users Docs Report Doc',
  props<{ filter: any }>());
export const getUsersDocsReportSuccess = createAction('[Onboarding Docs]  Get Users Docs Report Doc Success',
  props<{ doc: ArrayBuffer }>());
export const getUsersDocsReportFailure = createAction('[Onboarding Docs]  Get Users Docs Report Doc Failure');



export const getUsersDocsReportExcel = createAction('[Onboarding Docs] Get Users Docs Report Excel Doc',
  props<{ filter: any }>());
export const getUsersDocsReportExcelSuccess = createAction('[Onboarding Docs]  Get Users Docs Report Excel Doc Success',
  props<{ doc: ArrayBuffer }>());
export const getUsersDocsReportExcelFailure = createAction('[Onboarding Docs]  Get Users Docs Report Excel Doc Failure');

export const getOnboardingDocs  = createAction('[Onboarding Docs] Get Docs',
  props<{ filter: any }>());
export const getOnboardingDocsSuccess = createAction('[Onboarding Docs]  Get Docs Success',
  props<{  docs: PagingResultsModel<OnBoardingDocumentModelWithRoles>  }>());
export const getOnboardingDocsFailure = createAction('[Onboarding Docs]  Get Docs Failure');

export const getOnboardingDoc  = createAction('[Onboarding Docs] Get Doc',
  props<{ id: number }>());
export const getOnboardingDocSuccess = createAction('[Onboarding Docs]  Get Doc Success',
  props<{ doc: OnBoardingDocumentModelWithRoles }>());
export const getOnboardingDocFailure = createAction('[Onboarding Docs]  Get Doc Failure');


export const updateOnboardingDoc  = createAction('[Onboarding Docs] Update Doc',
  props<{ doc: OnBoardingDocumentModelWithRoles }>());
export const updateOnboardingDocSuccess = createAction('[Onboarding Docs]  Update Doc Success',
  props<{ doc: OnBoardingDocumentModelWithRoles }>());
export const updateOnboardingDocFailure = createAction('[Onboarding Docs]  Update Doc Failure');


export const updateOnBoardingDocForm = createAction('[Onboarding Docs]  Update Onboarding Docs Form',
  props<{ onBoardingDocumentValues: any; rolesValues: any, formErrors: FormError[] }>());


export const getOnboardingUserDocs  = createAction('[Onboarding Docs] Get User Docs',
props<{ filter: any }>());
export const getOnboardingUserDocsSuccess = createAction('[Onboarding Docs]  Get User Docs Success',
props<{  docs: PagingResultsModel<OnBoardingDocumentUserModel>  }>());
export const getOnboardingUserDocsFailure = createAction('[Onboarding Docs]  Get User Docs Failure');


export const updateOnboardingUserDocForceNotRequired  = createAction('[Onboarding Docs] Update Doc Force Not Required',
  props<{ model: UpdateOnboardingDocUserForceNotRequiredModel }>());
export const updateOnboardingUserDocForceNotRequiredSuccess = createAction('[Onboarding Docs]  Update Doc Force Not Required Success',
  props<{ doc: OnBoardingDocumentUserModel }>());
export const updateOnboardingUserDocForceNotRequiredFailure = createAction('[Onboarding Docs]  Update Doc Force Not Required Failure');

export const resendOnboardingDocUserForce  = createAction('[Onboarding Docs] Send Doc Force',
  props<{ model: ResendOnboardingDocUserForceModel }>());
export const resendOnboardingDocUserForceSuccess = createAction('[Onboarding Docs]  Send Doc Force Success',
  props<{ doc: OnBoardingDocumentUserModel }>());
export const resendOnboardingDocUserForceFailure = createAction('[Onboarding Docs]  Send Doc Force Failure');
