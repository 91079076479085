
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { Actions, ofType } from '@ngrx/effects';
import { Router, ActivatedRoute } from '@angular/router';
import { SurveyModel, SurveyReportModel, SurveyReportDetailModel } from '../../../../models/surveyModel';
import { loadMyUnansweredSurvey, loadSurveyReport, loadSurveyReportDetail } from '../../../../core/store/survey/survey.actions';

@Component({
  selector: 'app-survey-report-detail',
  templateUrl: './survey-report-detail.component.html',
  styleUrls: ['./survey-report-detail.component.scss']
})
export class SurveyReportDetailComponent extends BaseComponent implements OnInit {

  surveyReportDetail: SurveyReportDetailModel[];
  loadingSurveyReportDetail = false;
  dateRange;
  startingDateRange = [
    new Date(new Date().setMonth(new Date().getMonth() - 1)),
    new Date(),
  ];
  surveyTypeID: number;
  mentorUserID: number;
  surveyTypeName: string;
  mentorName: string;

  reloadedReports: boolean;

  constructor(
    private actions$: Actions,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<State>) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.surveyState.surveyReportDetail).subscribe(surveyReportDetail => {
        this.surveyReportDetail = surveyReportDetail;
      }),
      this.store.select(s => s.surveyState.loadingSurveyReportDetail).subscribe(loadingSurveyReportDetail => {
        this.loadingSurveyReportDetail = loadingSurveyReportDetail;
      }),
      this.route.params.subscribe(params => {
        this.surveyTypeID = parseInt(params.surveyTypeID, 10);
        this.mentorUserID = parseInt(params.mentorUserID, 10);
        this.dateRange = this.startingDateRange;
        this.store.dispatch(loadSurveyReportDetail({
          filter: {
            dateRange: this.startingDateRange,
            surveyTypeID: this.surveyTypeID,
            mentorUserID: this.mentorUserID
          }
        }));
        this.subs.push(
          this.store.select(s => s.surveyState.surveyReport).subscribe(surveyReport => {
            if ((!surveyReport || surveyReport.length === 0) && !this.reloadedReports) {
              this.store.dispatch(loadSurveyReport({ filter: { dateRange: this.startingDateRange } }));
              this.reloadedReports = true;
            } else {
              const survey = surveyReport.find(x => x.mentorUserID === this.mentorUserID && x.surveyTypeID === this.surveyTypeID);
              this.surveyTypeName = survey.surveyTypeName;
              this.mentorName = survey.mentorName;
            }
          }));
      }),
    );

  }

  changedFilter() {
    if (this.dateRange && this.dateRange[0] && this.dateRange[1]) {
      this.store.dispatch(loadSurveyReportDetail({
        filter: {
          dateRange: this.startingDateRange,
          surveyTypeID: this.surveyTypeID,
          mentorUserID: this.mentorUserID
        }
      }));
    }
  }

  viewSurvey(survey: SurveyModel) {
    this.router.navigate(['/survey/answer/' + survey.id]);
  }

}

