<div class="card mb-2">
    <div class="card-body">
        <div>
            <button class="btn btn-primary float-right pointer" routerLink="/ticket-types/new">Create new Ticket
                Type</button>
            <h2>Ticket Types</h2>
            
            <i *ngIf="loading" class="material-icons rotate text-muted">loop</i>
            <p-table *ngIf="!loading" [value]="ticketTypes" [paginator]="true" [rows]="10" [responsive]="true" [resizableColumns]="true">
                <ng-template pTemplate="header">
                    <tr>
                        <th pResizableColumn></th>
                        <th pResizableColumn>Id</th>
                        <th pResizableColumn>Name</th>
                        <th pResizableColumn>Custom Fields</th>
                        <th pResizableColumn>Ticket Statuses</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-ticketType>
                    <tr>
                        <td> <button class="btn btn-primary" (click)="editTicketType(ticketType.id)">Edit</button>
                        </td>
                        <td>{{ ticketType.id }}</td>
                        <td>{{ ticketType.name }}</td>
                        <td>
                            <ul>
                                <li *ngFor="let field of ticketType.customFields">
                                    {{ field.name }}
                                </li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li *ngFor="let status of ticketType.ticketStatuses">
                                    {{ status.name }}
                                </li>
                            </ul>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="4">No ticket types found.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>