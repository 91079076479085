import { CreateEquipmentComponent } from './../create-equipment/create-equipment.component';
import { EquipmentModel, AssignedEquipmentModel, EquipmentAcessoryModel } from './../../../../models/equipmentsModel';
import { getUsersList } from '../../../../core/store/users/users.actions';
import { SelectItem } from 'primeng/api';
import { UserModel } from 'src/app/models/userModel';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { FormGroup, RequiredValidator, Validators } from '@angular/forms';
import { getAllControlsErrors, markFormGroupTouched, deepClone } from 'src/app/helpers/utils';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import {
  loadUserEquipments,
  saveReturnEquipment,
  changedReturnEquipment
} from 'src/app/core/store/equipment/equipment.actions';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { formatDate } from '@angular/common';
import { GeneralSettingsModel } from 'src/app/models/generalSettingsModel';
@Component({
  selector: 'app-return-equipment',
  templateUrl: './return-equipment.component.html',
  styleUrls: ['./return-equipment.component.scss']
})
export class ReturnEquipmentComponent extends BaseComponent implements OnInit {
  form: FormGroup;
  canEdit: boolean;
  assignedEquipmentsToUser: AssignedEquipmentModel[];
  returningEquipments: AssignedEquipmentModel[];
  usersList: SelectItem[] = [{ label: 'loading...', value: undefined }];
  userID: number;
  myUserID: number;
  showErrors = false;
  savingReturnEquipments = false;
  includeTerminated = false;
  returningAcessories: EquipmentAcessoryModel[];
  assignedAcessories: EquipmentAcessoryModel[];
  equipmentsList: SelectItem[];
  generalSettings: GeneralSettingsModel;

  constructor(private actions$: Actions,
    private store: Store<State>) {
    super();
  }

  ngOnInit() {
    this.store.dispatch(getUsersList());
    this.subs.push(
      this.store.select(s => s.accountState.generalSettings).subscribe(generalSettings => {
        if (generalSettings) {
          this.generalSettings = generalSettings;
        }
      }),
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.canEdit = permissionIDs && !!permissionIDs.find(p => p === 3058); //equipment manager
      }),
      this.store.select(s => s.accountState.user.id).subscribe(myUserID => {
        this.myUserID = myUserID;
      }),
      this.store.select(s => s.equipmentState.assignedEquipmentsToUser).subscribe(assignedEquipmentsToUser => {
        this.assignedEquipmentsToUser = deepClone(assignedEquipmentsToUser);
        this.returningEquipments = this.assignedEquipmentsToUser.filter(e => e.returned);
        const acessories = [];
        this.returningEquipments.forEach(ea => {
          if (ea.equipmentAcessories) {
            ea.equipmentAcessories.forEach(a => {
              a.equipmentLabel = ea.equipment.type + ' - ' + ea.equipment.brand + ' - ' + ea.equipment.model + ' - ' + ea.equipment.serialNumber;
            });
            acessories.push(...ea.equipmentAcessories);
            ea.dateReturnedString = formatDate(new Date(), 'yyyy-MM-dd', 'en-US')
          }
        });
        this.assignedAcessories = acessories;
      }),
      this.store.select(s => s.equipmentState.savingReturnEquipments).subscribe(savingReturnEquipments => {
        this.savingReturnEquipments = savingReturnEquipments;
      }),
      this.store.select(s => s.usersState.usersList).subscribe(usersList => {
        this.usersList = usersList ? usersList.map(u => ({ label: u.firstName + ' ' + u.lastName, value: u.id }))
          : [{ label: 'loading...', value: undefined }];
        this.usersList.unshift({ label: '', value: null });
      }),
      this.actions$.pipe(
        ofType(loadUserEquipments),
        tap(action => this.userID = action.userID
        )).subscribe()
    );
  }

  save() {
    if (this.userID && this.returningEquipments.length > 0) {
      this.store.dispatch(saveReturnEquipment({ returningEquipments: this.returningEquipments }));
    } else {
      this.showErrors = true;
    }
  }

  includeTerminatedChange(event){
    if (event.checked == false){
      this.store.select(s => s.usersState.usersList).subscribe(usersList => {
        this.usersList = usersList ? usersList.map(u => ({ label: u.firstName + ' ' + u.lastName, value: u.id }))
          : [{ label: 'loading...', value: undefined }];
          this.usersList.unshift({ label: 'All', value: 0 });
      })
    }
    else{
      this.store.select(s => s.usersState.usersListIncludingTerminated).subscribe(usersList => {
        this.usersList = usersList ? usersList.map(u => ({ label: u.firstName + ' ' + u.lastName, value: u.id }))
          : [{ label: 'loading...', value: undefined }];
          this.usersList.unshift({ label: 'All', value: 0 });
      })
    }
  }

  changedUser() {
    this.store.dispatch(loadUserEquipments({ userID: this.userID }));
  }

  changedEquipment(assignedEquipment: AssignedEquipmentModel) {
    this.store.dispatch(changedReturnEquipment({ assignedEquipment }));
  }

  changedAcessory(acessory: EquipmentAcessoryModel) {
    const assignedEquipment = this.returningEquipments.find(e => e.id === acessory.equipmentAssignedID);
    this.changedEquipment(assignedEquipment);
  }

  trackByFn(index, obj){
      return index;
  }
}

