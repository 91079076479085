import { State } from './../index';
import { AlertService } from './../../../shared/services/alert.service';
import { ProfileService } from '../../services/profile.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  loadProfile, loadProfileSuccess, saveProfile, saveProfileSuccess, changePassword,
  changePasswordSuccess, getPhotoSuccess, getPhoto, uploadPhoto, errorHappened, uploadPhotoSuccess,
  loadRaceOptions, loadRaceOptionsSuccess,
  loadJobTitles, loadJobTitlesSuccess, getReturnEquipmentAlerts, getReturnEquipmentAlertsSuccess,
  saveSignature, saveSignatureSuccess, saveSignatureFailure, loadProfileOptions, loadProfileOptionsSuccess, loadUserTypes, loadUserTypesSuccess, loadSpokenLanguageOptions, loadSpokenLanguageOptionsSuccess
} from './profile.actions';
import { map, switchMap, tap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';

@Injectable()
export class ProfileEffects {
  constructor(
    private store: Store<State>,
    private actions$: Actions,
    private profileService: ProfileService,
    private alertService: AlertService) { }

  getProfile = createEffect(() => this.actions$.pipe(
    ofType(loadProfile),
    switchMap(action => this.profileService.getUserProfile().pipe(
      map(profile => loadProfileSuccess({ profile })),
      catchError(err => {
        return of(errorHappened({ err }));
      })))
  ));

  getReturnEquipmentAlerts = createEffect(() => this.actions$.pipe(
    ofType(getReturnEquipmentAlerts),
    switchMap(action => this.profileService.getReturnEquipmentAlerts(action.id).pipe(
      map(alert => getReturnEquipmentAlertsSuccess({ alert }),
        catchError(err => {
          return of(errorHappened({ err }));
        }))
    )
    )));

  saveProfile = createEffect(() => this.actions$.pipe(
    ofType(saveProfile),
    switchMap(action => this.profileService.saveProfile(action.profile).pipe(
      map(profile => saveProfileSuccess({ profile })),
      catchError(err => {
        return of(errorHappened({ err }));
      })
    ))));

  saveProfileSuccess = createEffect(() => this.actions$.pipe(
    ofType(saveProfileSuccess),
    tap(action => {
      this.alertService.success('Saved');      
    })
  ), { dispatch: false });

  saveSignature = createEffect(() => this.actions$.pipe(
    ofType(saveSignature),
    switchMap(action => {
      return this.profileService.saveSignature(action.data).pipe(
        map(result => saveSignatureSuccess({ profile: result })),
        catchError(err => {
          this.store.dispatch(saveSignatureFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  saveSignatureSuccess = createEffect(() => this.actions$.pipe(
    ofType(saveSignatureSuccess),
    tap(action => {
      this.alertService.success('Saved');
      this.store.dispatch(loadProfile());
    })
  ), { dispatch: false });

  errorHappened = createEffect(() => this.actions$.pipe(
    ofType(errorHappened),
    tap(action => {
      var errorMessage = action.err;
      try {
        var errObj = null;
        if (typeof (action.err) == 'string') {
          if (action.err.startsWith('{'))
            errObj = JSON.parse(action.err);
          else
            errObj = { message: action.err };
        } else {
          errObj = action.err;
        }
        if (errObj.message)
          errorMessage = errObj.message;

        errorMessage = errorMessage.toString();
        if (errorMessage.length > 400)
          errorMessage = errorMessage.substring(0, 400);
        console.error(errorMessage, errObj);
      } catch (ex) {

      }
      this.alertService.error(errorMessage);
    })
  ), { dispatch: false });

  changePassword = createEffect(() => this.actions$.pipe(
    ofType(changePassword),
    switchMap(action => this.profileService.changePassword(action.newPassword).pipe(
      map(profile => changePasswordSuccess()),
      catchError(err => {
        return of(errorHappened({ err }));
      })))
  ));

  changePasswordSuccess = createEffect(() => this.actions$.pipe(
    ofType(changePasswordSuccess),
    tap(action => this.alertService.success('Password Changed'))
  ), { dispatch: false });

  getPhoto = createEffect(() => this.actions$.pipe(
    ofType(getPhoto),
    switchMap(action => this.profileService.getPhoto()),
    map(url => getPhotoSuccess(url))
  ));

  uploadPhoto = createEffect(() => this.actions$.pipe(
    ofType(uploadPhoto),
    switchMap(action => this.profileService.uploadPhoto(action.file).pipe(
      map(url => uploadPhotoSuccess(),
        catchError(err => {
          return of(errorHappened({ err }));
        }))
    )
    )));

  uploadPhotoSuccess = createEffect(() => this.actions$.pipe(
    ofType(uploadPhotoSuccess),
    map(action => getPhoto())
  ));

  loadSpokenLanguageOptions = createEffect(() => this.actions$.pipe(
    ofType(loadSpokenLanguageOptions),
    switchMap(action => this.profileService.getSpokenLanguageOptions().pipe(
      map(spokenLanguageOptions => loadSpokenLanguageOptionsSuccess({ spokenLanguageOptions })),
      catchError(err => {
        return of(errorHappened({ err }));
      })))
  ));

  loadRaceOptions = createEffect(() => this.actions$.pipe(
    ofType(loadRaceOptions),
    switchMap(action => this.profileService.getRaceOptions().pipe(
      map(raceOptions => loadRaceOptionsSuccess({ raceOptions })),
      catchError(err => {
        return of(errorHappened({ err }));
      })))
  ));

  loadJobTitles = createEffect(() => this.actions$.pipe(
    ofType(loadJobTitles),
    switchMap(action => this.profileService.getJobTitles().pipe(
      map(jobTitles => loadJobTitlesSuccess({ jobTitles })),
      catchError(err => {
        return of(errorHappened({ err }));
      })))
  ));


  loadUserTypes = createEffect(() => this.actions$.pipe(
    ofType(loadUserTypes),
    switchMap(action => this.profileService.getAllRoles().pipe(
      map(userTypes => loadUserTypesSuccess({ userTypes })),
      catchError(err => {
        return of(errorHappened({ err }));
      })))
  ));


  loadProfileOptions = createEffect(() => this.actions$.pipe(
    ofType(loadProfileOptions),
    switchMap(action => this.profileService.loadProfileOptions().pipe(
      map(profileOptions => loadProfileOptionsSuccess({ profileOptions })),
      catchError(err => {
        return of(errorHappened({ err }));
      })))
  ));
}
