
<div class="card mb-2">
  <div class="card-body">
    <button  class="btn btn-secondary float-right pointer" routerLink="/selfcare/management/menu">Back</button>
    <h2 class="mb-3">Self Care Categories</h2>
    <i [hidden]="!loading" class="material-icons rotate text-muted">loop</i>

    <table class="table table-sm table-striped table-bordered">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Category</th>
          <th scope="col">Image</th>
          <th scope="col">Color</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let cat of categories;">
          <tr>
            <td class="text-center">
              <button class="btn btn-secondary m-2" (click)="edit(cat)" title="Edit Category">
                <i class="fas fa-edit"></i>
              </button>
            </td>
            <td scope="row">
            {{cat.categoryName}}
            </td>
            <td scope="row">
              {{cat.categoryImage}}
              <img [src]="'/assets/img/'+cat.categoryImage" class="category-img" />
            </td>
            <td scope="row" [style.color]="cat.mainColor">
              {{cat.mainColor}}
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
