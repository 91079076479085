import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { SelectItem } from 'primeng/api';
import { State } from '../../../../core/store';
import { Store } from '@ngrx/store';
import { loadHolidayYears, changeHolidayUserFilter, getHolidayList, showEditHoliday, saveHolidaySuccess, deleteHolidaySuccess } from '../../../../core/store/holiday/holiday.actions';
import { DropdownFilterService } from '../../../../core/services/dropdown-filter.service';
import { DateRangeModel } from '../../../../models/dateRangeModel';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { HolidayModel } from '../../../../models/holidayModel';
import { tap } from 'rxjs/operators';
import { ofType, Actions } from '@ngrx/effects';

@Component({
  selector: 'app-list-holiday',
  templateUrl: './list-holiday.component.html',
  styleUrls: ['./list-holiday.component.scss']
})
export class ListHolidayComponent extends BaseComponent implements OnInit {
  holidays: PagingResultsModel<HolidayModel>;
  filter: any;

  loadingHolidayList: boolean = true;
  canEdit: boolean = false;

  holidayName: string = "";

  yearsList: SelectItem[];
  year: number = 0;

  userList: SelectItem[];
  userID: number = 0;

  monthList: SelectItem[];
  month: number = -1;

  dateRangeList: SelectItem[];
  dateRange: DateRangeModel;
  startDate: string = null;
  endDate: string = null;
  isCustomDateRange: boolean = false;

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private dropdownFilterService: DropdownFilterService) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.holidayState.loadingHolidayList).subscribe(loadingHolidayList => {
        this.loadingHolidayList = loadingHolidayList;
      }),
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.canEdit = permissionIDs && !!permissionIDs.find(p => p === 3037);
      }),
      this.store.select(s => s.holidayState.years).subscribe(years => {
        this.yearsList = years && years.length > 0 ? years
          : [{ label: 'loading...', value: undefined }];
      }),
      this.store.select(s => s.holidayState.filteredUsers).subscribe(userList => {
        this.userList = userList && userList.length > 0 ? userList
          : [{ label: 'loading...', value: undefined }];
      }),
      this.store.select(s => s.holidayState.holidayList).subscribe(holidayList => {
        this.holidays = holidayList;
      }),
      this.actions$.pipe(
        ofType(saveHolidaySuccess),
        tap(action => {
          this.reloadHolidays(this.filter);
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(deleteHolidaySuccess),
        tap(action => {
          this.reloadHolidays(this.filter);
        })
      ).subscribe(),
    );

    this.monthList = this.dropdownFilterService.getMonthsWithAll().map(c => ({ label: c.month, value: c.value }));
    this.dateRangeList = this.dropdownFilterService.get30DayDateRanges().map(c => ({ label: c.text, value: c }));
    this.store.dispatch(loadHolidayYears());
    this.store.dispatch(changeHolidayUserFilter());


  }

  changedDateRange() {
    if (this.dateRange.isCustom) {
      this.isCustomDateRange = true;
      return; //reload after manually changing date
    }

    this.isCustomDateRange = false;
    this.startDate = this.dateRange.startDate;
    this.endDate = this.dateRange.endDate;
    this.reloadHolidays(this.filter);
  }

  reloadHolidays(event) {
    this.filter = event;
    this.store.dispatch(getHolidayList({
      filter: this.filter,
      holidayName: this.holidayName,
      year: this.year,
      month: this.month,
      userID: this.userID,
      startDate: this.startDate,
      endDate: this.endDate
    }));
  }

  editHoliday(holiday) {
    this.store.dispatch(showEditHoliday({ holiday: holiday}));
  }
}
