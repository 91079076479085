
<app-forms-available></app-forms-available>
<button type="button"  (click)="seeOldDocs = !seeOldDocs" class="btn btn-link mt-2" *ngIf="onboardingDocs?.length > 0">{{seeOldDocs ?'Hide':'See'}} old documents</button>
<form class="card mt-2" *ngIf="seeOldDocs" >
  <div class="card-body">
    <h2 class="mb-3">Documents</h2>
    <i *ngIf="loadingOnboardingDocsList" class="material-icons rotate text-muted">loop</i>
    <p-table  [value]="onboardingDocs" [responsive]="true"
    [paginator]="true" [rows]="10" [totalRecords]="onboardingDocs?.length"
    >
        <ng-template pTemplate="header">
            <tr>
                <th width="280px"></th>
                <th width="90px">Signed</th>
                <th width="90px">Signed by HR</th>
                <th> Document</th>
                <th  width="90px">Due Date</th>
                <th  width="90px">Signed Date</th>
                <th  width="90px">Required</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-onboardingDocsUser>
            <tr >
                <td>
                  <button class="btn btn-secondary mr-2" *ngIf="onboardingDocsUser?.signedDate && (!onboardingDocsUser?.onBoarding_Document.requireHRSignature || onboardingDocsUser?.signedByHRDate)"  (click)="viewDoc(onboardingDocsUser)">View Doc</button>
                  <button class="btn btn-secondary" *ngIf="!managedUserId  && ((!onboardingDocsUser?.signedDate)) && (onboardingDocsUser?.onBoarding_Document.needFillInfo )" (click)="fillInfo(onboardingDocsUser)">Fill info and sign</button>
                  <button class="btn btn-secondary" *ngIf="managedUserId  && onboardingDocsUser?.signedDate && (onboardingDocsUser?.onBoarding_Document.needFillInfoHR ) && !onboardingDocsUser?.signedByHRDate" (click)="fillInfoHR(onboardingDocsUser)">Fill HR info and sign</button>
                  <button class="btn btn-secondary" *ngIf="!managedUserId  && ((!onboardingDocsUser?.signedDate) || (onboardingDocsUser?.renewAnytime )) && (!onboardingDocsUser?.onBoarding_Document.needFillInfo )" (click)="sign(onboardingDocsUser)">Sign</button>
                </td>
                <td>
                  <span class="ui-column-title">Signed by User</span>
                  <i class="fas fa-check-circle text-success" title="Signed" *ngIf="onboardingDocsUser?.signedDate"></i>
                  <i class="fas fa-dot-circle" title="Missing" *ngIf="!onboardingDocsUser.signedDate"></i>
                </td>
                <td>
                  <span class="ui-column-title">Signed by HR</span>
                  <i class="fas fa-minus-circle text-muted" title="Not needed" *ngIf="!onboardingDocsUser?.onBoarding_Document.requireHRSignature"></i>
                  <i class="fas fa-check-circle text-success" title="Signed" *ngIf="onboardingDocsUser?.onBoarding_Document.requireHRSignature && onboardingDocsUser?.signedByHRDate"></i>
                  <i class="fas fa-dot-circle" title="Missing" *ngIf="onboardingDocsUser?.onBoarding_Document.requireHRSignature && !onboardingDocsUser?.signedByHRDate"></i>
                </td>
                <td>
                  <span class="ui-column-title">Document</span>
                  {{onboardingDocsUser.onBoarding_Document.title}}
                </td>
                <td>
                  <span class="ui-column-title">Due Date</span>
                  {{(onboardingDocsUser.dueDate?onboardingDocsUser.dueDate:onboardingDocsUser.createdDate) | date:'shortDate' }}
                </td>
                <td>
                  <span class="ui-column-title">Signed Date</span>
                  {{onboardingDocsUser.signedDate | date:'shortDate' }}
                </td>
                <td>
                  <span class="ui-column-title">Required</span>
                  <i *ngIf="!!onboardingDocsUser.isReallyRequired" class="fas fa-asterisk text-danger" title="Required"></i>
                </td>
            </tr>
        </ng-template>
    </p-table>

  </div>
</form>

