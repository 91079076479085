<div class="card mb-2">
  <div class="card-body">
    <form class="" [formGroup]="form" *ngIf="form && prospectiveCandidate">

      <button class="btn btn-secondary float-right pointer" routerLink="/prospective-candidate/list">Back</button>
      <button *ngIf="!prospectiveCandidate.offerLetterID && !prospectiveCandidate.removed" class="btn btn-success float-right  mr-2 " title="Send Offer Letter" [routerLink]="'/offerletter/create'" [queryParams]="{ prospectiveCandidateID: prospectiveCandidate.id }">Send Offer Letter</button>
      <button *ngIf="prospectiveCandidateID && showPhoneInterview" class="btn btn-primary float-right mr-2 " (click)="openPhoneScreening(myscreeningmodal, prospectiveCandidate)">View Phone Interview</button>
      <h2 class="">{{prospectiveCandidate.id?'Edit':'Add'}} Prospective Candidate</h2>

      <div class="row mb-2 mt-2" *ngIf="(createdBy != null && createdBy != '') || (lastEditedBy != null && lastEditedBy != '')">
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="row card align-items-center justify-content-center p-0 m-0" style="font-size: 14px;">
            <div class="col-12 d-flex justify-content-start align-items-center text-center mt-2 mb-0" *ngIf="createdBy != null && createdBy != '' && (fromJobApplication == false || fromJobApplication == null)">
              <i><p class="m-0 p-0 ml-2">Created by {{ createdBy }} on {{ createdDate  | date: 'MM/dd/yyyy HH:mm a' }}</p></i>
            </div>
            <div class="col-12 d-flex justify-content-start align-items-center text-center mt-2 mb-0" *ngIf="createdBy != null && createdBy != '' && fromJobApplication == true">
              <i><p class="m-0 p-0 ml-2">Converted from job application by {{ createdBy }} on {{ createdDate  | date: 'MM/dd/yyyy HH:mm a' }}</p></i>
            </div>
            <div class="col-12 d-flex justify-content-start align-items-center text-center mb-2 mt-1" *ngIf="lastEditedBy != null && lastEditedBy != ''">
              <i><p class="m-0 p-0 ml-2">Last Edited by {{ lastEditedBy }} on {{ lastEditDate  | date: 'MM/dd/yyyy HH:mm a' }}</p></i>
            </div>
          </div>  
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-lg-6">
          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Legal First Name</b>
            </div>
            <div class="col-8 ">
              <input id="firstNameInput" type="text" class="form-control mt-2" formControlName="firstName" />
              <app-validation-message [form]="form" [path]="'firstName'" [name]="'firstName'" [label]="'Legal First Name'"> </app-validation-message>
            </div>
            <div class="col-4 d-flex align-items-center">
              <b>Legal Last Name</b>
            </div>
            <div class="col-8 ">
              <input id="lastNameInput" type="text" class="form-control mt-2" formControlName="lastName" />
              <app-validation-message [form]="form" [path]="'lastName'" [name]="'lastName'" [label]="'Legal Last Name'"> </app-validation-message>
            </div>
            <div class="col-4 d-flex align-items-center">
              <b>Phone Number</b>
            </div>
            <div class="col-8 ">
              <input id="phoneNumberInput" type="tel" placeholder="111-111-1111" class="form-control mt-2" formControlName="phoneNumber" />
              <app-validation-message [form]="form" [path]="'phoneNumber'" [name]="'phoneNumber'" [label]="'Phone Number'"> </app-validation-message>
            </div>
            <div class="col-4 d-flex align-items-center">
              <b>Email</b>
            </div>
            <div class="col-8 ">
              <input id="emailInput" type="text" class="form-control mt-2" formControlName="email" />
              <app-validation-message [form]="form" [path]="'email'" [name]="'email'" [label]="'Email'"> </app-validation-message>
            </div>
            <div class="col-4 d-flex align-items-center">
              <b>Region</b>
            </div>
            <div class="col-8">
              <i *ngIf="!campusesList" class="material-icons rotate text-muted">loop</i>
              <p-dropdown styleClass="form-control mt-2 w-100" [options]="campusesList" placeholder="Select campus..." formControlName="programID"></p-dropdown>
            </div>
            <div class="col-4 d-flex align-items-center">
              <b>Position</b>
            </div>
            <div class="col-8">
              <i *ngIf="!jobTitlesList" class="material-icons rotate text-muted">loop</i>
              <p-dropdown styleClass="form-control w-100 mt-2" [options]="jobTitlesList" formControlName="position"></p-dropdown>

              <app-validation-message [form]="form" [path]="'position'" [name]="'position'" [label]="'Position'"> </app-validation-message>
            </div>
            <div class="col-4 d-flex align-items-center">
              <b>Application Date</b>
            </div>
            <div class="col-8">
              <input type="date" id="applicationDate" class="form-control mt-2" formControlName="applicationDate"/>
              <app-validation-message [form]="form" [path]="'applicationDate'" [name]="'applicationDate'" [label]="'Application Date'"> </app-validation-message>
            </div>
            <div class="col-4 d-flex align-items-center">
              <b>How did they hear about us?</b>
            </div>
            <div class="col-8">
              <select id="platformInput" class="form-control mt-2 w-100" formControlName="platform">
                <option value="">Select...</option>
                <option value="LinkedIn">LinkedIn</option>
                <option value="Indeed">Indeed</option>
                <option value="Glassdoor">Glassdoor</option>
                <option value="Ziprecruiter">Ziprecruiter</option>
                <option value="Handshake">Handshake</option>
                <option value="Google Search/Website">Google Search/Website</option>
                <option value="Job Fair">Job Fair</option>
                <option value="Social Media">Social Media</option>
                <option value="Other">Other...</option>
              </select>
              <app-validation-message [form]="form" [path]="'platform'" [name]="'platform'" [label]="'How did they hear about us?'"> </app-validation-message>
            </div>
            <div class="col-4 d-flex align-items-center">
              <b>Employee Referral</b>
            </div>
            <div class="col-8 ">
              <input id="employeeReferralInput" type="checkbox" (click)="changeReferredStatus()" style="transform: scale(1.5); margin-left:5px;" class="checkBox mb-2 mt-3" formControlName="employeeReferral" />
              <app-validation-message [form]="form" [path]="'employeeReferral'" [name]="'employeeReferral'" [label]="'Employee Referral'"> </app-validation-message>
            </div>
            <div class="col-4 d-flex align-items-center">
              <b *ngIf="employeeReferral">Referred By</b>
            </div>
            <div class="col-8 ">
              <input *ngIf="employeeReferral" id="referredByInput" type="text" class="form-control mt-2" formControlName="referredBy" />
              <app-validation-message [form]="form" [path]="'referredBy'" [name]="'referredBy'" [label]="'Referred By'"> </app-validation-message>
            </div>
            <div class="col-4 d-flex align-items-center">
              <b>Interview Date</b>
            </div>
            <div class="col-8 ">
              <p-calendar inputStyleClass="form-control mt-2" [monthNavigator]="true" [yearNavigator]="true"
                          styleClass="w-100" panelStyleClass="calendarPanel" [hourFormat]="12" [showTime]="true"
                          id="dateRange" dateFormat="mm/dd/yy" name="interviewDate" readonly="true" showseconds="true" showButtonBar="true" dataType="string" selectionMode="single" [hideOnDateTimeSelect]="false" formControlName="interviewDate"></p-calendar>
              <app-validation-message [form]="form" [path]="'interviewDate'" [name]="'interviewDate'" [label]="'Interview Date'"> </app-validation-message>
            </div>
            <div class="col-4 d-flex align-items-center">
              <b>Expected Hours</b>
            </div>
            <div class="col-8 ">
              <input id="expectedHoursInput" type="text" class="form-control mt-2" formControlName="expectedHours" />
              <app-validation-message [form]="form" [path]="'expectedHours'" [name]="'expectedHours'" [label]="'Expected Hours'"> </app-validation-message>
            </div>
            <div class="col-4 d-flex align-items-center">
              <b>Stop Future Contact?</b>
            </div>
            <div class="col-8 ">
              <input id="futureContactInput" type="checkbox" (click)="changeContactStatus()" style="transform: scale(1.5); margin-left:5px;" class="checkBox mb-2 mt-3" formControlName="futureContact" />
              <app-validation-message [form]="form" [path]="'futureContact'" [name]="'futureContact'" [label]="'Will There Be Future Contact?'"> </app-validation-message>
            </div>
            <div class="col-4 d-flex align-items-center">
              <b *ngIf="contactStatus">Reasons for Stopping Contact</b>
            </div>
            <div class="col-8 ">
              <input *ngIf="contactStatus" id="noContactReasonInput" type="text" class="form-control mt-2" formControlName="noContactReason" />
              <app-validation-message [form]="form" [path]="'noContactReason'" [name]="'noContactReason'" [label]="'Reasons for Stopping Contact'"> </app-validation-message>
            </div>
            <div class="col-4 d-flex align-items-center">
              <b>Not Hired?</b>
            </div>
            <div class="col-8 ">
              <input id="isHiredInput" type="checkbox" (click)="changeHiredStatus()" style="transform: scale(1.5); margin-left:5px;" class="checkBox mb-2 mt-3" formControlName="isHired" />
              <app-validation-message [form]="form" [path]="'isHired'" [name]="'isHired'" [label]="'Not Hired?'"> </app-validation-message>
            </div>
            <div class="col-4 d-flex align-items-center">
              <b *ngIf="hiredStatus">If Not Hired, Explain</b>
            </div>
            <div class="col-8 ">
              <input *ngIf="hiredStatus" id="notHiredExplanationInput" type="text" class="form-control mt-2" formControlName="notHiredExplanation" />
              <app-validation-message [form]="form" [path]="'notHiredExplanation'" [name]="'notHiredExplanation'" [label]="'If Not Hired, Explain'"> </app-validation-message>
            </div>

            <div class="col-4 d-flex align-items-center">
              <b>Notes</b>
            </div>
            <div class="col-8 ">
              <textarea id="notesInput" type="text" class="form-control mt-2" formControlName="notes"></textarea>
              <app-validation-message [form]="form" [path]="'notes'" [name]="'notes'" [label]="'Notes'"> </app-validation-message>
            </div>
            <div class="col-12 mt-2">
              <div class="card p-2">
                <div class="row w-100">
                  <div class="col-4 mt-2 d-flex align-items-center">
                    <b>Upload Resume</b>
                  </div>
                  <div class="col-8 mt-2">
                    <input class="mt-2" type="file" multiple #fileInput accept="image/*,.pdf,.doc,.docx" (change)="changedFile(fileInput)" />
                  </div>
                  <div class="col-12 mt-1" *ngIf="prospectiveCandidate.resumeURL">
                    <b><u>Resume:</u></b>
                  </div>
                  <div class="col-12 mt-1" *ngIf="prospectiveCandidate.resumeURL">
                    <a [href]="prospectiveCandidate.resumeURL" role="button" target="newwindow">
                      <img class="file-image pointer mt-2" [src]="getPreviewUrl()" style="height: 8rem; width: 8rem;" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 mt-2" *ngIf="prospectiveCandidate.id">
              <div class="card p-2">
                <div class="row w-100">
                  <div class="col-4 mt-2 d-flex align-items-center">
                    <b>Upload Misc. Documents</b>
                  </div>
                  <div class="col-8 mt-2">
                    <input class="mt-2" type="file" multiple #fileMiscInput name="fileMiscInput" accept="image/*,.pdf,.doc,.docx" (change)="changedMiscFile(fileMiscInput)"  />
                    <button class="btn btn-primary btn-sm float-right mt-2" (click)="saveMiscDocuments()">Save Documents</button>
                  </div>
                  <div class="col-12 mt-1" *ngIf="prospectiveCandidate != null && prospectiveCandidate.miscDocuments != null && prospectiveCandidate.miscDocuments.length">
                    <b><u>Misc. Documents:</u></b>
                  </div>
                  <div class="col-12 mt-1" *ngIf="prospectiveCandidate != null && prospectiveCandidate.miscDocuments != null && prospectiveCandidate.miscDocuments.length">
                    <div class="row w-100">
                      <div class="w-100 col-4 position-relative" *ngFor="let doc of prospectiveCandidate.miscDocuments">
                        <span class="m-0 p-0 file-delete-button"><i class="text-danger fas fa-minus-circle" (click)="deleteMiscDocuments(doc.documentID)"></i></span>
                        <a [href]="doc.docURL" role="button" class="d-flex flex-column align-items-center justify-content-center text-decoration-none" target="newwindow">
                          <img class="file-image pointer mt-2" [src]="getMiscDocPreviewUrl(doc)" style="height: 8rem; width: 8rem;" />
                          <span class="p-0 m-0" [innerText]="doc.fileName"></span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-lg-6">
          <div class="card">
            <div class="card-body">
              <div class="float-right">
                <span *ngIf="references && references.length"
                      class="badge pointer"
                      [class.badge-success]="references.length==prospectiveCandidate.referencesAnswered"
                      [class.badge-danger]="references.length!=prospectiveCandidate.referencesAnswered"
                      title="References">{{prospectiveCandidate.referencesAnswered}} of {{references.length}}</span> Answered
              </div>
              <h4>
                References <i class="fas fa-plus-circle pointer"
                              (click)="openAddReference=!openAddReference"></i>
              </h4>
              <div class="mb-3" *ngIf="openAddReference">
                <form class="" [formGroup]="referenceForm">
                  <div class="row">
                    <div class="col-4 d-flex align-items-center">
                      <b>Reference First Name</b>
                    </div>
                    <div class="col-8 ">
                      <input id="firstNameReferenceInput" type="text" class="form-control mt-2" formControlName="firstName" [(ngModel)]="this.referenceToAdd.firstName" />
                      <app-validation-message [form]="referenceForm" [path]="'firstName'" [name]="'firstName'" [label]="'First Name'"> </app-validation-message>
                    </div>
                    <div class="col-4 d-flex align-items-center">
                      <b>Reference Last Name</b>
                    </div>
                    <div class="col-8 ">
                      <input id="lastNameReferenceInput" type="text" class="form-control mt-2" formControlName="lastName" [(ngModel)]="this.referenceToAdd.lastName" />
                      <app-validation-message [form]="referenceForm" [path]="'lastName'" [name]="'lastName'" [label]="'Last Name'"> </app-validation-message>
                    </div>
                    <div class="col-4 d-flex align-items-center">
                      <b>Reference Email</b>
                    </div>
                    <div class="col-8 ">
                      <input id="emailReferenceInput" type="text" class="form-control mt-2" formControlName="email" [(ngModel)]="this.referenceToAdd.email" />
                      <app-validation-message [form]="referenceForm" [path]="'email'" [name]="'email'" [label]="'Email'"> </app-validation-message>
                    </div>
                  </div>
                  <button class="btn btn-primary mt-1" id="addReferenceButton" [class.disabled]="referenceForm.invalid" (click)="addReference()">Add Reference </button>

                </form>
              </div>
              <p-table class="mt-2" [value]="references" [responsive]="true">
                <ng-template pTemplate="header">
                  <tr>
                    <th width="50px"></th>
                    <th [pSortableColumn]="'firstName'">Reference Name <p-sortIcon [field]="'firstName'"></p-sortIcon></th>
                    <th [pSortableColumn]="'email'">Email <p-sortIcon [field]="'email'"></p-sortIcon></th>
                    <th width="120px">Answered</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-reference>
                  <tr>
                    <td>
                      <i class="fas fa-trash mr-2 pointer" title="Remove" (click)="removeReference(reference)"></i>
                    </td>
                    <td>
                      <span class="ui-column-title">Reference Name</span>
                      {{reference.firstName}} {{reference.lastName}}
                    </td>
                    <td>
                      <span class="ui-column-title">Email</span>
                      {{reference.email}}
                    </td>
                    <td>
                      <span class="ui-column-title">Answer</span>
                      <i class="fas fa-minus-circle text-muted" *ngIf="!reference.answerDate" title="No answer"></i>
                      <i class="fas fa-check-circle text-success pointer" *ngIf="reference.answerDate" [routerLink]="'/prospective-candidate/survey'" [queryParams]="{id: reference.id}" title="See answer"></i>

                      <span *ngIf="reference.answerDate && !reference.answerSeen" title="Click here to see the answer" class="badge badge-danger ml-2 pointer"
                            [routerLink]="'/prospective-candidate/survey'" [queryParams]="{id: reference.id}" (click)="markSeen(reference)">New</span>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-lg-6">
          <div class="card">
            <div class="card-body">
              <h4>
                Contact Log <i class="fas fa-plus-circle pointer"
                              (click)="openAddLog=!openAddLog"></i>
              </h4>
              <div class="mb-3" *ngIf="openAddLog">
                <form class="" [formGroup]="contactLogForm">
                  <div class="row">
                    <div class="col-4 d-flex align-items-center">
                      <b>Contact Details</b>
                    </div>
                    <div class="col-8 ">
                      <textarea id="ContactLogDetailsInput" class="form-control mt-2" formControlName="contactDetails" [(ngModel)]="this.logToAdd.contactDetails"></textarea>
                      <app-validation-message [form]="contactLogForm" [path]="'contactDetails'" [name]="'contactDetails'" [label]="'Contact Details'"> </app-validation-message>
                    </div>
                  </div>
                  <button class="btn btn-primary mt-1" id="addContactLogButton" [class.disabled]="contactLogForm.invalid" (click)="addContactLog()">Add</button>
                </form>
              </div>
              <p-table class="mt-2" [value]="contactLogs" [responsive]="true">
                <ng-template pTemplate="header">
                  <tr>
                    <th [pSortableColumn]="'date'">Time of Contact<p-sortIcon [field]="'date'"></p-sortIcon></th>
                    <th [pSortableColumn]="'date'">User<p-sortIcon [field]="'usersName'"></p-sortIcon></th>
                    <th [pSortableColumn]="'details'">Details<p-sortIcon [field]="'details'"></p-sortIcon></th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-contactLog>
                  <tr>
                    <td>
                      <span class="ui-column-title">Contact Date</span>
                      {{ contactLog.contactDate }} {{ "  " }}
                      <i *ngIf="contactLog.canEdit" (click)="open(mymodal, contactLog.id)" class="fas icon-color fa-edit pointer"
                              ></i>
                    </td>
                    <td>
                      <span class="ui-column-title">User</span>
                      {{ contactLog.usersName}}
                    </td>
                    <td>
                      <span class="ui-column-title">Details</span>
                      {{contactLog.contactDetails}}
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </div>
      <ng-template #mymodal let-modal>
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">Edit Contact Log</h4>
          <button type="button" class="close btn btn-outline-dark" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Contact Details: </p>
          <textarea type="text" [(ngModel)]="contactLogEdit" class="form-control" [ngModelOptions]="{standalone: true}"></textarea>
        </div>
        <div class="modal-footer">
          <button type="button" [disabled]="contactLogEdit == ''" class="btn btn-outline-dark" (click)="modal.close('Edit')">Edit</button>
          <button type="button" class="btn btn-outline-dark" (click)="modal.close('Cancel')">Cancel</button>
        </div>
      </ng-template>
      <button class="btn btn-primary mt-3" id="saveButton" [class.disabled]="form.invalid" (click)="save()">Save</button>
    </form>
  </div>
</div>
<ng-template #myscreeningmodal class="modal-index" let-modal>
  <div class="modal-body">
      <app-screening-interview
        [applicationID]="prospectiveCandidate.jobApplicationID"
        [firstName]="prospectiveCandidate.firstName"
        [lastName]="prospectiveCandidate.lastName"
        [formSubmissionID]="prospectiveCandidate.formSubmissionID"
        [position]="prospectiveCandidate.position"
        [programID]="prospectiveCandidate.programID"
        [prospectiveCandidateID]="prospectiveCandidate.id"
        (myEvent)="modal.close($event)"
      ></app-screening-interview>
  </div>
</ng-template>
