import { JobTitleHasJobApplicationFormModel } from './../../../models/screeningInterviewModel';
import { JobApplicationsModel, JobApplicationsWithFileModel } from '../../../models/jobApplicationsModel';
import { PagingResultsModel } from './../../../models/pagingResultsModel';
import { createReducer, on, Action } from '@ngrx/store';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FormError } from './../../../models/utilModels';
import { deepClone } from './../../../helpers/utils';
import { ValidatorRequiredNotEmptyIfBool, ValidatorRequiredNotEmptyIfFalse } from './../../../helpers/validators';
import {
  clearJobApplication,
  getJobApplications,
  getJobApplicationsFail,
  getJobApplicationsSuccess,
  loadCampusesSuccess,
  saveJobApplication,
  saveJobApplicationFail,
  saveJobApplicationSuccess,
  updateJobApplicationsForm,
  setPhoneInterviewNull,
  savePhoneInterview,
  saveAsProspectiveCandidate,
  archiveJobApplication,
  savePhoneInterviewSuccess,
  saveAsProspectiveCandidateSuccess,
  archiveJobApplicationSuccess,
  archiveJobApplicationFail,
  savePhoneInterviewFail,
  saveAsProspectiveCandidateFail,
  loadJobApplicationFormsSuccess,
  loadJobApplicationForms,
  loadJobApplicationFormsFail,
} from './job-applications.action';
import { CampusModel } from 'src/app/models/campusModel';
import { ScreeningInterviewModel } from 'src/app/models/screeningInterviewModel';

export interface JobApplicationsState {
  creatingJobApplication: boolean;
  loadingJobApplications: boolean;
  jobApplications: PagingResultsModel<JobApplicationsModel>;
  jobApplicationWithFile: JobApplicationsWithFileModel;
  campuses: CampusModel[];
  phoneInterview: ScreeningInterviewModel;
  loading: boolean;
  pageAltered: number;
  jobTitleForms: JobTitleHasJobApplicationFormModel[];
}

const initialState: JobApplicationsState = {
  creatingJobApplication: false,
  loadingJobApplications: false,
  jobApplications: null,
  jobApplicationWithFile: null,
  campuses: [],
  phoneInterview: null,
  loading: false,
  pageAltered: 0,
  jobTitleForms: [],
}

export const jobApplicationsReducer = createReducer(initialState,
  on(updateJobApplicationsForm, (state, action) => {
    const jobApplication = { ...state.jobApplicationWithFile, ...action.jobApplication };
    const newState = { ...state, ...{ jobApplication, formErrors: action.formErrors } };
    return newState;
  }),
  on(saveJobApplication, (state, action) => {
    return {
      ...state,
      creatingJobApplication: true,
      loading: true,
    };
  }),
  on(saveJobApplicationSuccess, (state, action) => {
    return {
      ...state,
      jobApplicationWithFile: action.jobApplication,
      creatingJobApplication: false,
      loading: false,
    };
  }),
  on(clearJobApplication, (state, action) => {
    return {
      ...state,
      jobApplicationWithFile: null
    };
  }),
  on(saveJobApplicationFail, (state, action) => {
    return {
      ...state,
      jobApplicationWithFile: null,
      creatingJobApplication: false,
      loading: false,
    };
  }),
  on(loadCampusesSuccess, (state, action) => {
    return {
      ...state,
      campuses: action.campuses,
    };
  }),
  on(getJobApplications, (state, action) => {
    return {
      ...state,
      loadingJobApplications: true
    };
  }),
  on(getJobApplicationsSuccess, (state, action) => {
    return {
      ...state,
      jobApplications: action.jobApplications,
      loadingJobApplications: false
    };
  }),
  on(getJobApplicationsFail, (state, action) => {
    return {
      ...state,
      loadingJobApplications: false
    };
  }),
  on(setPhoneInterviewNull, (state, action) => {
    return {
      ...state,
      phoneInterview: null,
    };
  }),
  on(savePhoneInterview, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(saveAsProspectiveCandidate, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(archiveJobApplication, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(savePhoneInterviewSuccess, (state, action) => {
    return {
      ...state,
      phoneInterview: null,
      loading: false,
    };
  }),
  on(saveAsProspectiveCandidateSuccess, (state, action) => {
    let count = state.pageAltered + 1
    return {
      ...state,
      loading: false,
      phoneInterview: null,
      pageAltered: count,
    };
  }),
  on(archiveJobApplicationSuccess, (state, action) => {
    let count = state.pageAltered + 1
    return {
      ...state,
      phoneInterview: null,
      pageAltered: count,
      loading: false,
    };
  }),
  on(saveAsProspectiveCandidateFail, (state, action) => {
    let count = state.pageAltered + 1
    return {
      ...state,
      loading: false,
    };
  }),
  on(archiveJobApplicationFail, (state, action) => {
    let count = state.pageAltered + 1
    return {
      ...state,
      loading: false,
    };
  }),
  on(savePhoneInterviewFail, (state, action) => {
    let count = state.pageAltered + 1
    return {
      ...state,
      loading: false,
    };
  }),
  on(loadJobApplicationForms, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(loadJobApplicationFormsSuccess, (state, action) => {
    return {
      ...state,
      jobTitleForms: action.jobTitleForms,
      loading: false,
    };
  }),
  on(loadJobApplicationFormsFail, (state, action) => {
    return {
      ...state,
      loading: false,
    };
  }),
)

export function jobApplicationsReducerFunc(state: JobApplicationsState | undefined, action: Action) {
  return jobApplicationsReducer(state, action);
}

export function initJobApplicationForm(jobApplicationWithFile: JobApplicationsWithFileModel, heardOfUsThrough: string) {
  jobApplicationWithFile.heardOfUsThrough = heardOfUsThrough;
  const form = new FormGroup({
    firstName: new FormControl(jobApplicationWithFile.firstName, [Validators.required, Validators.maxLength(50)]),
    lastName: new FormControl(jobApplicationWithFile.lastName, [Validators.required, Validators.maxLength(50)]),
    phoneNumber: new FormControl(jobApplicationWithFile.phoneNumber, [Validators.required, Validators.maxLength(15)]),
    email: new FormControl(jobApplicationWithFile.email, [Validators.required, Validators.maxLength(120)]),
    position: new FormControl(jobApplicationWithFile.position, [Validators.maxLength(120)]),
    regionID: new FormControl(jobApplicationWithFile.regionID, [Validators.required]),
    contactTime: new FormControl(jobApplicationWithFile.contactTime, [Validators.required, Validators.maxLength(120)]),
    heardOfUsThrough: new FormControl(jobApplicationWithFile.heardOfUsThrough, [Validators.required, Validators.maxLength(120)]),
    referredBy: new FormControl(jobApplicationWithFile.referredBy, [Validators.maxLength(120)]),
    isAgeLimit: new FormControl(jobApplicationWithFile.isAgeLimit, [Validators.required]),
    diploma: new FormControl(jobApplicationWithFile.diploma, [Validators.required]),
    backgroundCheck: new FormControl(jobApplicationWithFile.backgroundCheck, [Validators.required]),
    hasValidDriversLicense: new FormControl(jobApplicationWithFile.hasValidDriversLicense, [Validators.required]),
    reliableTransportation: new FormControl(jobApplicationWithFile.reliableTransportation, [Validators.required]),
  })

  return form;
}
