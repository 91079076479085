import { UserProfileModel } from './../../../../../models/userProfileModel';
import { updateProfileBCIForm, saveProfile, loadProfile, saveProfileSuccess } from '../../../../../core/store/profile/profile.actions';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from '../../../../../shared/services/alert.service';
import { Component, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../../core/store';
import { Actions, ofType } from '@ngrx/effects';
import * as moment from 'moment';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { FormGroup } from '@angular/forms';
import { getAllControlsErrors, markFormGroupTouched } from 'src/app/helpers/utils';
import { initProfileBCIForm } from 'src/app/core/store/profile/profile.reducer';
import { tap } from 'rxjs/operators';
import { setOnboardingDocsInfoFilled, setOnboardingDocsInfoFilledSuccess } from 'src/app/core/store/onboardingdocs/onboardingdocs.actions';

@Component({
  selector: 'app-doc-fill-info-bci',
  templateUrl: './doc-fill-info-bci.component.html',
  styleUrls: ['./doc-fill-info-bci.component.scss']
})
export class DocFillInfoBciComponent extends BaseComponent implements OnInit {

  @Input()
  onboardingDocUserId;

  profile: UserProfileModel;
  yearRange = '1900:' + new Date().getFullYear();
  newRenewOptions = [
    { label: 'New Applicant', value: 0 },
    { label: 'Renewal', value: 1 },
    { label: 'Transfer', value: 2 },
  ];
  yesNoOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];
  form: FormGroup;
  constructor(
    private store: Store<State>,
    private actions$: Actions,
    private alert: AlertService,
    private router: Router,
    private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.store.dispatch(loadProfile());
    this.subs.push(
      this.store.select(s => s.profileState).subscribe(profileState => {
        if (profileState && profileState.profile) {
          this.profile = JSON.parse(JSON.stringify(profileState.profile));
          if (this.profile.employee.birthdate) {
            this.profile.employee.birthdate = new Date(this.profile.employee.birthdate);
          }
          if (!this.form) {
            this.form = initProfileBCIForm(this.profile);
            this.subs.push(
              this.form.valueChanges.subscribe(formValues => {
                const userValues = this.form.get('user').value;
                const employeeValues = this.form.get('employee').value;
                const formErrors = getAllControlsErrors(this.form);
                this.store.dispatch(updateProfileBCIForm({ userValues, employeeValues, formErrors }));
              })
            );
          }
        }
      }),
      this.actions$.pipe(
        ofType(saveProfileSuccess),
        tap(action => {
          this.store.dispatch(setOnboardingDocsInfoFilled({ onboardingDocUserId: this.onboardingDocUserId }));
        })
      ).subscribe(),

      this.actions$.pipe(
        ofType(setOnboardingDocsInfoFilledSuccess),
        tap(action => {
          this.router.navigate(['../../sign/' + this.onboardingDocUserId],{relativeTo: this.route});
        })
      ).subscribe()
    );
  }

  save() {
    if (this.form.valid) {
      this.store.dispatch(saveProfile({ profile: this.profile }));
    } else {
      markFormGroupTouched(this.form);
    }
  }

}
