import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { FormGroup } from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { State } from '../../../../core/store';
import { Store } from '@ngrx/store';
import { PtoRequestedModel } from '../../../../models/timeoffModel';
import { initEditPtoRequestForm } from '../../../../core/store/timeoff/timeoff.reducer';
import { getAllControlsErrors, getFormattedTime, updateFormGroupValidity, markFormGroupTouched } from '../../../../helpers/utils';
import { updatePtoRequestForm, savePtoRequestSuccess, closeEditPtoRequest, savePtoRequest, getEmployeePtoSummary } from '../../../../core/store/timeoff/timeoff.actions';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-edit-pto-request',
  templateUrl: './edit-pto-request.component.html',
  styleUrls: ['./edit-pto-request.component.scss']
})
export class EditPtoRequestComponent extends BaseComponent implements OnInit {
  display: boolean = false;
  title: string;
  form: FormGroup;
  disableButtons: boolean = true;
  ptoRequest: PtoRequestedModel = {} as PtoRequestedModel;
  userId: number;
  employeeName: string;
  totalAvailable: string;


  constructor(
    private actions$: Actions,
    private store: Store<State>) {
    super();
  }

  ngOnInit() {
    this.form = initEditPtoRequestForm({} as PtoRequestedModel);
    this.subs.push(
      this.store.select(s => s.timeoffState.editingPtoRequest).subscribe(editingPtoRequest => {
        this.display = editingPtoRequest;
      }),
      this.store.select(s => s.timeoffState.savingPtoRequest).subscribe(savingHoliday => {
        this.disableButtons = savingHoliday;
      }),
      this.store.select(s => s.timeoffState.employeePtoSummary).subscribe(summary => {
        if (summary == null) {
          return;
        }
        this.userId = summary.userId;
        this.employeeName = summary.employeeName;
        this.totalAvailable = summary.totalAvailableString;
      }),
      this.store.select(s => s.timeoffState.selectedPtoRequest).subscribe(request => {        
        if (request === null) {
          this.ptoRequest = null;
          return;
        }

        const changedRequest = !this.ptoRequest || (this.ptoRequest.payableItemId !== request.payableItemId);
        this.ptoRequest = JSON.parse(JSON.stringify(request));

        if (changedRequest) {
          this.form = initEditPtoRequestForm(this.ptoRequest);
          this.store.dispatch(getEmployeePtoSummary({ userId: typeof request.userId !== 'undefined' ? request.userId : 0 })); //need a better way => payroll hours...maybe as part of request...
          this.subs.push(
            this.form.valueChanges.subscribe(formValues => {
              const formErrors = getAllControlsErrors(this.form);
              this.store.dispatch(updatePtoRequestForm({ formValues, formErrors }));
            })
          )
          if (typeof request.payableItemId === 'undefined' || request.payableItemId === null || request.payableItemId < 1) {
            this.title = "New Paid Time Off Request";            
            this.store.dispatch(updatePtoRequestForm({ formValues: this.form.value, formErrors: getAllControlsErrors(this.form) }));
          } else {
            this.title = "Edit Paid Time Off Request #" + request.payableItemId;
            this.ptoRequest.ptoHours = Math.floor(request.ptoMinutesUsed / 60);
            this.ptoRequest.ptoMinutes = Math.floor(request.ptoMinutesUsed % 60);
            this.ptoRequest.startDateString = getFormattedTime(request.startTime);
            this.ptoRequest.endDateString = getFormattedTime(request.endTime);            
          }
        }
      }),
      this.actions$.pipe(
        ofType(savePtoRequestSuccess),
        tap(action => {
          this.closeModal();
        })
      ).subscribe(),      
    );
  }

  closeModal() {
    this.store.dispatch(closeEditPtoRequest());
  }

  savePtoRequest() {
    updateFormGroupValidity(this.form);
    if (this.form.valid) {
      this.ptoRequest.userId = this.userId;
      console.log(this.ptoRequest);
      this.store.dispatch(savePtoRequest({ ptoRequest: this.ptoRequest }));
    }
    else {
      markFormGroupTouched(this.form);
    }
  }


}
