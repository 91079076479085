import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { State } from '..';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { errorHappened } from '../profile/profile.actions';
import { EmployeeReviewTemplateService } from "../../services/employee-review-template.service";
import {
  deleteEmployeeReviewTemplateDraft, deleteEmployeeReviewTemplateDraftFailure, deleteEmployeeReviewTemplateDraftSuccess, deleteValueColor, deleteValueColorFailure, deleteValueColorSuccess, findEmployeeReviewTemplateDraft, findEmployeeReviewTemplateDraftFailure, findEmployeeReviewTemplateDraftSuccess,
  getScoreOptionsSets,
  getScoreOptionsSetsSuccess,
  getScoreTypes,
  getScoreTypesSuccess,
  getTemplate, getTemplateSuccess,
  getValueColors, getValueColorsFailure, getValueColorsSuccess,
  loadCategories, loadCategoriesSuccess,
  loadTemplates, loadTemplatesSuccess,
  saveEmployeeReviewCategory, saveEmployeeReviewCategoryFailure, saveEmployeeReviewCategorySuccess,
  saveEmployeeReviewTemplate, saveEmployeeReviewTemplateDraft, saveEmployeeReviewTemplateDraftSuccess, saveEmployeeReviewTemplateFailure, saveEmployeeReviewTemplateSuccess, saveValueColor, saveValueColorFailure, saveValueColorSuccess, toggleTemplateArchived, toggleTemplateArchivedFail, toggleTemplateArchivedSuccess
} from "./employee-review-template.actions";


@Injectable()
export class EmployeeReviewTemplateEffects {
  constructor(private actions$: Actions,
    private employeeReviewTemplateService: EmployeeReviewTemplateService,
    private store: Store<State>) { }

   loadTemplates = createEffect(() => this.actions$.pipe(
     ofType(loadTemplates),
     switchMap(action => {
       return this.employeeReviewTemplateService.getTemplates(action.filter).pipe(
         map(employeeReviewTemplates => loadTemplatesSuccess({ employeeReviewTemplates })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
   ));
  getTemplate = createEffect(() => this.actions$.pipe(
    ofType(getTemplate),
     switchMap(action => {
       return this.employeeReviewTemplateService.getTemplate(action.templateId).pipe(
         map(template => getTemplateSuccess({ template })));
    })
   ));

  saveTemplate = createEffect(() => this.actions$.pipe(
    ofType(saveEmployeeReviewTemplate),
    switchMap(action => {
      return this.employeeReviewTemplateService.saveTemplate(action.template).pipe(
        map(template => saveEmployeeReviewTemplateSuccess({ template })),
          catchError(err => {
            this.store.dispatch(saveEmployeeReviewTemplateFailure());
            return of(errorHappened({ err }));
          }));
      })
    ));

  loadCategories = createEffect(() => this.actions$.pipe(
    ofType(loadCategories),
    switchMap(action => {
      return this.employeeReviewTemplateService.getCategories().pipe(
        map(categories => loadCategoriesSuccess({ categories })))
    })
  ));
  saveCategory = createEffect(() => this.actions$.pipe(
    ofType(saveEmployeeReviewCategory),
    switchMap(action => {
      return this.employeeReviewTemplateService.saveCategory(action.category).pipe(
        map(category => saveEmployeeReviewCategorySuccess({ category })),
          catchError(err => {
            this.store.dispatch(saveEmployeeReviewCategoryFailure());
            return of(errorHappened({ err }));
          }));
      })
    ));

    getValueColors = createEffect(() => this.actions$.pipe(
      ofType(getValueColors),
      switchMap(action => {
        return this.employeeReviewTemplateService.getValueColors().pipe(
          map(valueColors => getValueColorsSuccess({ valueColors })),
          catchError(err => {
            this.store.dispatch(getValueColorsFailure());
            return of(errorHappened({ err }));
          }));
      })
    ));
  
    getScoreTypes = createEffect(() => this.actions$.pipe(
      ofType(getScoreTypes),
      switchMap(action => {
        return this.employeeReviewTemplateService.getScoreTypes().pipe(
          map(scoreTypes => getScoreTypesSuccess({ scoreTypes})),
          catchError(err => {
            this.store.dispatch(getValueColorsFailure());
            return of(errorHappened({ err }));
          }));
      })
    ));
  
    getScoreOptionsSets = createEffect(() => this.actions$.pipe(
      ofType(getScoreOptionsSets),
      switchMap(action => {
        return this.employeeReviewTemplateService.getScoreOptionsSets().pipe(
          map(scoreOptionsSets => getScoreOptionsSetsSuccess({ scoreOptionsSets })),
          catchError(err => {
            this.store.dispatch(getValueColorsFailure());
            return of(errorHappened({ err }));
          }));
      })
    ));

  saveTemplateDraft = createEffect(() => this.actions$.pipe(
    ofType(saveEmployeeReviewTemplateDraft),
    switchMap(action => {
      return this.employeeReviewTemplateService.saveTemplateDraft(action.template).pipe(
        map(draftId => saveEmployeeReviewTemplateDraftSuccess({ draftId })),
          catchError(err => {
            return of(errorHappened({ err }));
          }));
      })
    ));

  deleteTemplateDraft = createEffect(() => this.actions$.pipe(
    ofType(deleteEmployeeReviewTemplateDraft),
    switchMap(action => {
      return this.employeeReviewTemplateService.deleteTemplateDraft(action.draftId).pipe(
        map(draft => deleteEmployeeReviewTemplateDraftSuccess(draft)),
        catchError(err => {
          this.store.dispatch(deleteEmployeeReviewTemplateDraftFailure());
            return of(errorHappened({ err }));
          }));
      })
    ));


  findTemplateDraft = createEffect(() => this.actions$.pipe(
    ofType(findEmployeeReviewTemplateDraft),
    switchMap(action => {
      return this.employeeReviewTemplateService.findTemplateDraft(action.templateId).pipe(
        map(draft => findEmployeeReviewTemplateDraftSuccess({ templateDraft: draft })),
        catchError(err => {
          this.store.dispatch(findEmployeeReviewTemplateDraftFailure());
            return of(errorHappened({ err }));
          }));
      })
    ));


    saveValueColor = createEffect(() => this.actions$.pipe(
      ofType(saveValueColor),
      switchMap(action => {
        return this.employeeReviewTemplateService.saveValueColor(action.valueColor).pipe(
          map(valueColor => saveValueColorSuccess({ valueColor })),
            catchError(err => {
              this.store.dispatch(saveValueColorFailure());
              return of(errorHappened({ err }));
            }));
        })
      ));

      deleteValueColor = createEffect(() => this.actions$.pipe(
        ofType(deleteValueColor),
        switchMap(action => {
          return this.employeeReviewTemplateService.deleteValueColor(action.valueColorID).pipe(
            map(result => deleteValueColorSuccess()),
              catchError(err => {
                this.store.dispatch(deleteValueColorFailure());
                return of(errorHappened({ err }));
              }));
          })
      ));

  toggleTemplateArchived = createEffect(() => this.actions$.pipe(
    ofType(toggleTemplateArchived),
    switchMap(action => {
      return this.employeeReviewTemplateService.toggleTemplateArchived(action.templateId).pipe(
        map(template => toggleTemplateArchivedSuccess()),
        catchError(err => {
          this.store.dispatch(toggleTemplateArchivedFail(err));
          return of(errorHappened({ err }));
        }));
    })
  ));
}
