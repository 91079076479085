import { loadCampuses } from 'src/app/core/store/payroll-approval/payroll-approval.actions';
import { PagingResultsModel } from './../../../../models/pagingResultsModel';
import {
  getOfferLettersList, getOfferLetterPdf,
  deleteOfferLetter, deleteOfferLetterSuccess, markOfferLetterSeen
} from './../../../../core/store/offerletter/offerletter.actions';
import { OfferLetterWithUserModel } from 'src/app/models/offerletterModel';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Router } from '@angular/router';
import { loadJobTitles } from 'src/app/core/store/profile/profile.actions';
import { deepClone } from 'src/app/helpers/utils';
import { SelectItem } from 'primeng/api';
import { MultiSelect } from 'primeng/multiselect';
import { clearRehireEmployee } from '../../../../core/store/employee/employee.action';

@Component({
  selector: 'app-list-offerletter',
  templateUrl: './list-offerletter.component.html',
  styleUrls: ['./list-offerletter.component.scss']
})
export class ListOfferLetterComponent extends BaseComponent implements OnInit {

  offerLetters: PagingResultsModel<OfferLetterWithUserModel>;
  canEdit: boolean;
  canView: boolean;
  canViewSpecific: boolean;
  canEditBehaviorTechs: boolean;
  canEditBehaviorTechsAA: boolean;
  loadingOfferLetterList = false;
  filter: any;
  jobTitleIdsBehaviotTech: number[];
  jobTitleIdsBehaviotTechAA: number[];
  loading = false;
  positionsList = [
    { label: 'Administrative', value: 'Administrative' },
    { label: 'Mental Health Clinician', value: 'Mental Health Clinician' },
    { label: 'Behavior Clinician', value: 'Behavior Clinician' },
    { label: 'Behavior Technician', value: 'Behavior Technician' },
    { label: 'Dually-Credentialed', value: 'Dually-Credentialed' },
    { label: 'Psychologist', value: 'Psychologist' },
    { label: 'Director', value: 'Director' },
    { label: 'Other', value: 'Other' },
  ];

  yesNoAllOptions = [
    { label: 'All', value: null },
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];
  newExisitngAllOptions = [
    { label: 'All', value: null },
    { label: 'New', value: true },
    { label: 'Existing', value: false },
  ];

  datesOptions = [
    { label: 'Last 30 days', value: 30 },
    { label: 'Last 60 days', value: 60 },
    { label: 'Last 90 days', value: 90 },
    { label: 'Custom', value: 0 },
  ];

  employeeNameFilter;
  emailFilter;
  newExistingFilter;
  sentFilter;
  acceptedFilter;
  expiredFilter;
  sentDateFilter;
  signedDateFilter;
  regionFilter;
  sentDateOptionFilter;
  signedDateOptionFilter;
  positionsFilter;
  excludePositionsFilter;

  campusesList: SelectItem[] = [{ label: 'loading...', value: undefined }];

  constructor(private actions$: Actions,
    private alertService: AlertService,
    private store: Store<State>,
    private router: Router) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.canEdit = permissionIDs && !!permissionIDs.find(p => p === 3062); //manage offer letter
        this.canViewSpecific = permissionIDs && !!permissionIDs.find(p => p === 3061);
        this.canView = permissionIDs && !!permissionIDs.find(p => p === 3061 || p === 3062 || p === 3065 || p === 3066) // view offer letter, manage offer letter
        this.canEditBehaviorTechs = permissionIDs && !!permissionIDs.find(p => p === 3065) // view offer letter, manage offer letter for behavior techs and RBT
        this.canEditBehaviorTechsAA = permissionIDs && !!permissionIDs.find(p => p === 3066) // view offer letter, manage offer letter for behavior techs and RBT and AA
      }),
      this.store.select(s => s.offerLetterState.offerLettersList).subscribe(offerLettersList => {
        if (offerLettersList) {
          this.offerLetters = offerLettersList;
        }
      }),

      this.store.select(s => s.offerLetterState).subscribe(offerLetterState => {
        this.loadingOfferLetterList = offerLetterState.loadingOfferLetterList || offerLetterState.loadingDoc;
        this.loading = this.loadingOfferLetterList;
      }),

      this.actions$.pipe(
        ofType(deleteOfferLetterSuccess),
        tap(action => {
          this.alertService.success('Offer Letter deleted.');
          this.store.dispatch(getOfferLettersList({ filter: this.filter }));
        })
      ).subscribe(),
      this.store.select(s => s.profileState.jobTitles).subscribe(jobTitles => {
        const jobTitlesList = jobTitles && jobTitles.length > 0 ? jobTitles.map(c => ({ label: c.jobTitle, value: c.id }))
          : [{ label: 'loading...', value: undefined }];
        this.jobTitleIdsBehaviotTech = jobTitles.filter(x => x.jobTitle === 'Behavior Techinician' || x.jobTitle === 'Registered Behavior Technician').map(x => x.id);
        this.jobTitleIdsBehaviotTechAA = jobTitles.filter(x => x.jobTitle === 'Behavior Techinician' || x.jobTitle === 'Registered Behavior Technician' || x.jobTitle === 'Administrative Assistant').map(x => x.id);

      }),

      this.store.select(s => s.accountState.user.programIDs).subscribe(programIDs => {
        if (programIDs || this.canEdit) {
          this.subs.push(
            this.store.select(s => s.payrollApprovalState.campuses).subscribe(campuses => {
              let campusesList = campuses && campuses.length > 0 ? campuses.filter(x => this.canEdit || programIDs.includes(x.id)).map(c => ({ label: c.programName, value: c.programName }))
                : [{ label: 'loading...', value: undefined }];
              this.campusesList = campusesList;
              this.campusesList.unshift({ label: 'All', value: null });
            }),
          );
        }
      }),
    );

    this.store.dispatch(clearRehireEmployee());
    this.store.dispatch(loadCampuses());
    this.store.dispatch(loadJobTitles());
  }


  loadPage(event) {
    if (!this.loading) {
      this.loading = true;
      this.filter = deepClone(event);
      this.filter.filters.employeeNameFilter = { Name: 'employeeNameFilter', Value: { value: this.employeeNameFilter } };
      this.filter.filters.emailFilter = { Name: 'emailFilter', Value: { value: this.emailFilter } };
      this.filter.filters.newExistingFilter = { Name: 'newExistingFilter', Value: { value: this.newExistingFilter } };
      this.filter.filters.sentFilter = { Name: 'sentFilter', Value: { value: this.sentFilter } };
      this.filter.filters.acceptedFilter = { Name: 'acceptedFilter', Value: { value: this.acceptedFilter } };
      this.filter.filters.expiredFilter = { Name: 'expiredFilter', Value: { value: this.expiredFilter } };
      this.filter.filters.regionFilter = { Name: 'regionFilter', Value: { value: this.regionFilter } };
      this.filter.filters.positionsFilter = { Name: 'positionsFilter', Value: { value: this.positionsFilter ? this.positionsFilter.join(',') : null } };
      this.filter.filters.excludePositionsFilter = { Name: 'excludePositionsFilter', Value: { value: this.excludePositionsFilter ? this.excludePositionsFilter.join(',') : null } };


      if (this.sentDateOptionFilter > 0) {
        const today = new Date();
        const tomorrow = new Date(new Date().setDate(today.getDate() + 1));
        const priorDate = new Date(new Date().setDate(today.getDate() - this.sentDateOptionFilter));
        this.filter.filters.sentDateFilterStart = { Name: 'sentDateFilterStart', Value: { value: priorDate.toISOString() } };
        this.filter.filters.sentDateFilterEnd = { Name: 'sentDateFilterEnd', Value: { value: tomorrow.toISOString() } };
      } else {
        if (this.sentDateFilter) {
          this.filter.filters.sentDateFilterStart = { Name: 'sentDateFilterStart', Value: { value: this.sentDateFilter[0] } };
          this.filter.filters.sentDateFilterEnd = { Name: 'sentDateFilterEnd', Value: { value: this.sentDateFilter[1] } };
        } else {
          this.filter.filters.sentDateFilterStart = { Name: 'sentDateFilterStart', Value: { value: null } };
          this.filter.filters.sentDateFilterEnd = { Name: 'sentDateFilterEnd', Value: { value: null } };
        }
      }

      if (this.signedDateOptionFilter > 0) {
        const today = new Date();
        const tomorrow = new Date(new Date().setDate(today.getDate() + 1));
        const priorDate = new Date(new Date().setDate(today.getDate() - this.signedDateOptionFilter));
        this.filter.filters.signedDateFilterStart = { Name: 'signedDateFilterStart', Value: { value: priorDate.toISOString() } };
        this.filter.filters.signedDateFilterEnd = { Name: 'signedDateFilterEnd', Value: { value: tomorrow.toISOString() } };
      } else {
        if (this.signedDateFilter) {
          this.filter.filters.signedDateFilterStart = { Name: 'signedDateFilterStart', Value: { value: this.signedDateFilter[0] } };
          this.filter.filters.signedDateFilterEnd = { Name: 'signedDateFilterEnd', Value: { value: this.signedDateFilter[1] } };
        } else {
          this.filter.filters.signedDateFilterStart = { Name: 'signedDateFilterStart', Value: { value: null } };
          this.filter.filters.signedDateFilterEnd = { Name: 'signedDateFilterEnd', Value: { value: null } };
        }
      }

      this.store.dispatch(getOfferLettersList({
        filter: deepClone(this.filter)
      }));
    }
  }


  changedFilters() {
    this.loadPage(this.filter);
  }

  deleteOffer(offerLetter: OfferLetterWithUserModel) {
    this.alertService.confirm('Are you sure?',
      'Deleting the offer will erase any document associated with it. Are you sure?')
      .subscribe(
        anwser => {
          if (anwser) {
            this.store.dispatch(deleteOfferLetter({ offerLetterID: offerLetter.offerLetter.id }));
          }
        }, error => {
        }
      );
  }

  viewOffer(offerLetter: OfferLetterWithUserModel) {
    this.store.dispatch(getOfferLetterPdf({ offerLetterID: offerLetter.offerLetter.id }));
  }

  editOffer(offerLetter: OfferLetterWithUserModel) {
    this.router.navigate(['/offerletter/create/' + offerLetter.offerLetter.id]);
  }

  markAsSeen(offerLetter: OfferLetterWithUserModel) {
    this.store.dispatch(markOfferLetterSeen({ offerLetterID: offerLetter.offerLetter.id }));
  }

  openMultiSelect(multiselect: MultiSelect) {
    multiselect.show();
  }
}

