import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/shared/services/alert.service';
import { FormGroup } from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { State } from '../../../../core/store';
import { Store } from '@ngrx/store';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { TimeOffModel } from '../../../../models/timeoffModel';
import { initEditTimeOffRequestForm } from '../../../../core/store/timeoff/timeoff.reducer';
import { getAllControlsErrors, getFormattedTime, markFormGroupTouched, updateFormGroupValidity } from '../../../../helpers/utils';
import { closeEditTimeOffRequest, getEmployeePtoSummary, saveTimeOffRequest, saveTimeOffRequestSuccess, updateTimeOffRequestForm } from '../../../../core/store/timeoff/timeoff.actions';
import { tap } from 'rxjs/operators';
import { SelectItem } from 'primeng/api';
import { loadEmployeeUsers } from '../../../../core/store/employee/employee.action';

@Component({
  selector: 'app-edit-timeoff-request',
  templateUrl: './edit-timeoff-request.component.html',
  styleUrls: ['./edit-timeoff-request.component.scss']
})
export class EditTimeoffRequestComponent extends BaseComponent implements OnInit {
  display: boolean = false;
  title: string;
  form: FormGroup;
  disableButtons: boolean = true;
  timeOffRequest: TimeOffModel = {} as TimeOffModel;
  userId: number;
  employeeName: string;
  totalAvailable: string;
  disableName: boolean = true;
  currentUserId: number;

  prefixText: string;
  userList: SelectItem[];

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private alertService: AlertService) {
    super();
  }

  ngOnInit() {
    this.form = initEditTimeOffRequestForm({} as TimeOffModel);
    this.subs.push(
      this.store.select(s => s.accountState.user).subscribe(user => {
        this.employeeName = user.firstName + " " + user.lastName;
        this.currentUserId = user.id;
      }),
      this.store.select(s => s.timeoffState.employeePtoSummary).subscribe(summary => {
        if (summary == null) {
          this.totalAvailable = "";
          return;
        }
        
        this.totalAvailable = summary.totalAvailableString;
      }),
      this.store.select(s => s.timeoffState.editingTimeOffRequest).subscribe(editingTimeOffRequest => {
        this.display = editingTimeOffRequest;
      }),
      this.store.select(s => s.timeoffState.savingTimeOffRequest).subscribe(savingTimeOffRequest => {
        this.disableButtons = savingTimeOffRequest;
      }),
      this.store.select(s => s.timeoffState.disableEmployeeName).subscribe(disableName => {
        this.disableName = disableName;
      }),
      this.store.select(s => s.timeoffState.selectedTimeOffRequest).subscribe(request => {
        if (request === null) {
          this.timeOffRequest = null;
          return;
        }
        
        const changedRequest = !this.timeOffRequest || (this.timeOffRequest.timeOffId !== request.timeOffId);
        this.timeOffRequest = JSON.parse(JSON.stringify(request));

        if (changedRequest) {
          if (this.timeOffRequest != null) {
            this.timeOffRequest.ptoHours = this.timeOffRequest.ptoMinutesUsed >= 0 ?
              Math.floor(this.timeOffRequest.ptoMinutesUsed / 60) :
              Math.ceil(this.timeOffRequest.ptoMinutesUsed / 60);

            this.timeOffRequest.ptoMinutes = this.timeOffRequest.ptoMinutesUsed % 60;
          }

          this.form = initEditTimeOffRequestForm(this.timeOffRequest);          
          this.subs.push(
            this.form.valueChanges.subscribe(formValues => {
              const formErrors = getAllControlsErrors(this.form);
              this.store.dispatch(updateTimeOffRequestForm({ formValues, formErrors }));
            })
          )
          if (typeof request.timeOffId === 'undefined' || request.timeOffId === null || request.timeOffId < 1) {
            this.title = "New Time Off Request";
            if (request.employeeName != null && request.userId != null && request.userId > 0) {
              this.prefixText = request.employeeName;
              this.userId = request.userId
            }
            else {
              this.prefixText = this.employeeName;
              this.userId = this.currentUserId;
            }
            this.store.dispatch(updateTimeOffRequestForm({ formValues: this.form.value, formErrors: getAllControlsErrors(this.form) }));
          } else {
            this.title = "Edit Time Off Request #" + request.timeOffId;
            this.timeOffRequest.startDateString = getFormattedTime(request.startDate);
            this.timeOffRequest.endDateString = getFormattedTime(request.endDate);
            this.timeOffRequest.startTimeString = getFormattedTime(request.startTime);
            this.timeOffRequest.endTimeString = getFormattedTime(request.endTime);            
            this.prefixText = request.employeeName;
            this.userId = request.userId;
          }

          this.loadPtoSummaryInfo();
        }
      }),
      this.store.select(s => s.employeeState.employeeUsers).subscribe(users => {
        this.userList = users;
      }),
      this.actions$.pipe(
        ofType(saveTimeOffRequestSuccess),
        tap(() => {
          this.closeModal();
        })
      ).subscribe(),
    );
  }


  closeModal() {
    this.store.dispatch(closeEditTimeOffRequest());
  }

  saveTimeOffRequest() {
    updateFormGroupValidity(this.form);
    if (this.form.valid) {
      this.timeOffRequest.userId = this.userId;
      if(this.timeOffRequest.approved == true){
        this.alertService.confirm("Confirm Edit", "Editing this request will revert the status to Pending. Are you sure you want to continue?")
        .subscribe(
          anwser => {
            if (anwser) {
              this.store.dispatch(saveTimeOffRequest({ timeOffRequest: this.timeOffRequest }));
            }
            else{
              return;
            }
          }, error => {
          }
        );
      }
      else{
        this.store.dispatch(saveTimeOffRequest({ timeOffRequest: this.timeOffRequest }));
      }      
    }
    else {
      markFormGroupTouched(this.form);
    }
  }

  getStaffList(event) {
    this.store.dispatch(loadEmployeeUsers({
      prefixText: event.query,
      programId: 0,
      payrollClassificationId: 0,
      showInactive: false,
      showTerminated: false
    }));
  }

  selectStaff(selectedUser) {
    if (typeof selectedUser.value !== 'number') {
      return;
    }

    this.userId = selectedUser.value;    
  }

  clearStaff(event) {
    if (event.data !== null) {
      return;
    }

    this.userId = 0;    
  }

  loadPtoSummaryInfo() {
    if (this.userId < 1) {
      this.totalAvailable = "";      
      return;
    }

    this.store.dispatch(getEmployeePtoSummary({ userId: this.userId }));
  }
}
