import { Component, Input, OnInit } from '@angular/core';
import { EmployeeReviewTemplateMeasureableModel } from '../../../../../models/employeeReviewTemplateModels';
import { Store } from '@ngrx/store';
import { removeMeasureable } from '../../../../../core/store/employee-review-template/employee-review-template.actions';
import { BaseComponent } from '../../../../../core/abstracts/baseComponent';
import { State } from '../../../../../core/store';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-kpi-measureable-template-component',
  templateUrl: './kpi-measureable-template-component.component.html'
})
export class KpiMeasureableTemplateComponentComponent extends BaseComponent  implements OnInit {
  @Input() measureable: EmployeeReviewTemplateMeasureableModel;

  loading: boolean;
    
  constructor(
    private actions$: Actions,
    private store: Store<State>
  ) {
    super();
  }

  ngOnInit() {
    this.subs.push(
    this.store.select(s => s.employeeReviewTemplateState.loading).subscribe(loading => {
      this.loading = loading;
    }),
      this.actions$.pipe(
        ofType(removeMeasureable),
        tap(action => {
          if (this.measureable.childrenMeasureables.some(s => s == action.measureable)) {
            var index = this.measureable.childrenMeasureables.indexOf(action.measureable);
            this.measureable.childrenMeasureables.splice(index, 1);
          }
        })
      ).subscribe()
    );
  }

  addNewMeasureable() {
    if (this.measureable.childrenMeasureables == null)
      this.measureable.childrenMeasureables = [];

    this.measureable.childrenMeasureables.push({
      measureableId: null,
      childrenMeasureables: [],
      measureable: '',
      isChild: true
    });

  }

  remove() {
    this.store.dispatch(removeMeasureable({ measureable: this.measureable}));
  }
}
