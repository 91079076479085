<p-dialog [(visible)]="display" header="{{title}}" [modal]="true" [style]="{width: '70vw'}" (onHide)="closeModal()" [focusOnShow]="false">
  <form class="card mt-2" [formGroup]="form" (ngSubmit)="saveCertificationEdits()">
    <div class="card-body">
      <div class="row">
        <div class="col-12">

          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Employee</b>
            </div>
            <div class="col-8">
              <p-autoComplete [style]="{'width': '100%'}" [inputStyle]="{'width': '100%'}" [(ngModel)]="selectedUser"
                              [suggestions]="userEmployeeList" (completeMethod)="getStaffList($event)" field="label"
                              [ngModelOptions]="{standalone: true}" [minLength]="1" [autoHighlight]="true"
                              (onSelect)="setUserAfterStaffChange($event)" [forceSelection]="true"
                              (onClear)="clearUserAfterClear($event)"></p-autoComplete>
              <app-validation-message [form]="form" [path]="'userId'" [label]="'Employee'"></app-validation-message>
            </div>
          </div>

          <div class="row">
            <div class="col-4 d-flex align-items-center">              
            </div>
            <div class="col-8">
              <p-checkbox [(ngModel)]="showOnlyActiveEmployees" [binary]="true" [ngModelOptions]="{standalone: true}"></p-checkbox>
              <b style="margin-left: 2px;">Show only active employees</b>
            </div>
          </div>

          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Certification Type</b>
            </div>
            <div class="col-8">
              <i *ngIf="!certificationTypes" class="material-icons rotate text-muted">loop</i>
              <p-dropdown styleClass="form-control mt-2" [options]="certificationTypes" formControlName="certificationTypeId"></p-dropdown>
              <app-validation-message [form]="form" [path]="'certificationTypeId'" [label]="'Certification Type'"></app-validation-message>
            </div>
          </div>

          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Instructor</b>
            </div>
            <div class="col-8 mt-2">
              <p-autoComplete [style]="{'width': '100%'}" [inputStyle]="{'width': '100%'}" [(ngModel)]="selectedInstructor"
                              [suggestions]="instructorList" (completeMethod)="getInstructorList($event)" field="label"
                              [ngModelOptions]="{standalone: true}" [minLength]="1" [autoHighlight]="true"
                              (onSelect)="setInstructorAfterInstructorChange($event)" [forceSelection]="true"
                              (onClear)="clearInstructorAfterClear($event)"></p-autoComplete>
              <app-validation-message [form]="form" [path]="'instructorId'" [label]="'Instructor'"></app-validation-message>
            </div>
          </div>

          <div class="row">
            <div class="col-4 d-flex align-items-center">
            </div>
            <div class="col-8">
              <p-checkbox [(ngModel)]="showOnlyCurrentInstructors" [binary]="true" [ngModelOptions]="{standalone: true}"></p-checkbox>
              <b style="margin-left: 2px;">Show only current instructors</b>
            </div>
          </div>

          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Issue Date</b>
            </div>
            <div class="col-8">
              <input type="date" class="form-control mt-2" formControlName="issueDateString" />
              <app-validation-message [form]="form" [path]="'issueDateString'" [label]="'Issue Date'"></app-validation-message>
            </div>
          </div>

          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Expiration Date</b>
            </div>
            <div class="col-8">
              <input type="date" class="form-control mt-2" formControlName="expirationDateString" />
              <app-validation-message [form]="form" [path]="'expirationDateString'" [label]="'Expiration Date'"></app-validation-message>
            </div>
          </div>

        </div>
      </div>
    </div>
  </form>
  <p-footer>
    <i class="material-icons rotate text-muted ml-2" *ngIf="disableButtons">loop</i>
    <button class="btn btn-secondary" type="button" (click)="closeModal()">Cancel</button>
    <button class="btn btn-primary" type="submit" (click)="saveCertificationEdits()" [disabled]="disableButtons">Save</button>
  </p-footer>
</p-dialog>
