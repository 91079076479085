import { createAction, props } from "@ngrx/store";
import { PagingResultsModel } from "../../../models/pagingResultsModel";
import { BenefitPlanEDITransmissionModel } from "../../../models/benefitPlanEDITransmissionModel";
import { BenefitPlanEDIMemberInformationModel } from "../../../models/benefitPlanEDIMemberInformationModel";

export const getBenefitPlanEDITransmissions = createAction('[BenefitPlanEDI] Load BenefitPlanEDI Transmissions',
  props < { filter: any }>());
export const getBenefitPlanEDITransmissionsSuccess = createAction('[BenefitPlanEDI] Load BenefitPlanEDI Transmissions Success',
  props<{transmissions: PagingResultsModel<BenefitPlanEDITransmissionModel>}>());


export const getBenefitPlanEDITransmissionMembers = createAction('[BenefitPlanEDI] Load BenefitPlanEDI Transmission Members',
  props<{ filter: any }>());
export const getBenefitPlanEDITransmissionMembersSuccess = createAction('[BenefitPlanEDI] Load BenefitPlanEDI Transmission Members Success',
  props<{ transmissionMembers: PagingResultsModel<BenefitPlanEDIMemberInformationModel> }>());


export const exportBenefitPlanEDITransmissionData = createAction('[BenefitPlanEDI] Export BenefitPlanEDI Transmission Members List To Excel',
  props<{ transmissionId: number, carrier: string }>());
export const exportBenefitPlanEDITransmissionDataSuccess = createAction('[BenefitPlanEDI] Export BenefitPlanEDI Transmission Members List To Excell Success',
  props<{ doc: ArrayBuffer }>());
export const exportBenefitPlanEDITransmissionDataFailure = createAction('[BenefitPlanEDI] Export BenefitPlanEDI Transmission Members List To Excel Failure');



export const putBenefitPlanEDITransmission = createAction('[BenefitPlanEDI] Upload BenefitPlanEDI Transmission Members',
  props<{ carrier: string }>());
export const putBenefitPlanEDITransmissionSuccess = createAction('[BenefitPlanEDI] Upload BenefitPlanEDI Transmission Members Success',
  props<{ transmission: any }>());
export const putBenefitPlanEDITransmissionFailure = createAction('[BenefitPlanEDI] Upload BenefitPlanEDI Transmission Members Failure');

