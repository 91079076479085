import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { Actions, ofType } from '@ngrx/effects';
import { State } from '../../../../core/store';
import { Store } from '@ngrx/store';
import { DropdownFilterService } from '../../../../core/services/dropdown-filter.service';
import { tap } from 'rxjs/operators';
import { AlertService } from '../../../../shared/services/alert.service';
import { InternApplicationActionModel, InternApplicationModel, InternUploadModel } from '../../../../models/internModels';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { deepClone, downloadFile } from '../../../../helpers/utils';
import {
  exportManageInternApplicationsListToExcel, exportManageInternApplicationsListToExcelSuccess, getInternApplicationUpload,
  getInternApplicationUploadSuccess, getManageApplicationsList, loadInternForms, loadInternshipStatuses, saveInternActionItemSuccess, showEditInternActionItem,
  updateHourAndProductivityCalculations, updateHourAndProductivityCalculationsSuccess
} from '../../../../core/store/intern/intern.action';
import { SelectItem } from 'primeng/api';
import { loadEmployeeUsers } from '../../../../core/store/employee/employee.action';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list-manage',
  templateUrl: './list-manage.component.html',
  styleUrls: ['./list-manage.component.scss']
})
export class ListManageComponent extends BaseComponent implements OnInit {
  list: PagingResultsModel<InternApplicationModel>;
  filter: any = {};
  loadingList: boolean = true;
  loadingExcel: boolean = false;
  loadingDownload: boolean = false;

  campusList: SelectItem[];
  campusId: number = 0;

  prefixText: string;
  userEmployeeList: SelectItem[];
  userId: number = 0;

  statusList: SelectItem[];
  statusId: number = -99; //need a way to include action items, i.e. confirm withdraw

  formTemplates: SelectItem[];
  formId: number = 0;

  downloadFileName: string;
  canManage;

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private dropdownFilterService: DropdownFilterService,
    private alertService: AlertService,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.subs.push(
      this.store.select(s => s.accountState.user.permissionIDs).subscribe(permissionIDs => {
        this.canManage = permissionIDs && !!permissionIDs.find(p => p === 3128); //Manage Intern Applications
      }),
      this.store.select(s => s.internState.loadingManageApplications).subscribe(loading => {
        this.loadingList = loading;
      }),      
      this.store.select(s => s.internState.loadingManageApplicationsExcel).subscribe(loading => {
        this.loadingExcel = loading;
      }),
      this.store.select(s => s.internState.loadingApplicationUpload).subscribe(loading => {
        this.loadingDownload = loading;
      }),
      this.store.select(s => s.internState.manageApplicationList).subscribe(report => {
        this.list = report;
      }),
      this.store.select(s => s.employeeState.employeeUsers).subscribe(users => {
        this.userEmployeeList = users;
      }),
      this.store.select(s => s.employeeState.campuses).subscribe(campuses => {
        this.campusList = campuses && campuses.length > 0 ? campuses
          : [{ label: 'loading...', value: undefined }];
      }),
      this.store.select(s => s.internState.internshipStatuses).subscribe(items => {
        if (items && items.length > 0) {
          this.statusList = items.map(x => ({ label: x.label, value: x.value }));
          this.statusList.unshift({ label: 'Pending and Incomplete Action Items', value: -99 });
          this.statusList.unshift({ label: 'All', value: 0 });
        }
        else {
          this.statusList = [{ label: 'loading...', value: undefined }]
        }
      }),
      this.store.select(s => s.internState.formTemplates).subscribe(items => {
        if (items && items.length > 0) {
          this.formTemplates = items.map(x => ({ label: x.label, value: x.value }));
          this.formTemplates.unshift({ label: 'All', value: 0 });
        }
        else {
          this.formTemplates = [{ label: 'loading...', value: undefined }]
        }
      }),
      this.actions$.pipe(
        ofType(saveInternActionItemSuccess),
        tap(action => {
          this.alertService.success("Success");
          this.reloadList();
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(updateHourAndProductivityCalculationsSuccess),
        tap(action => {
          this.alertService.success("Success");
          this.reloadList();
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(exportManageInternApplicationsListToExcelSuccess),
        tap(action => {
          downloadFile(action.doc, 'InternshipApplications.xlsx');
        })
      ).subscribe(),
      this.actions$.pipe(
        ofType(getInternApplicationUploadSuccess),
        tap(action => {
          downloadFile(action.doc, this.downloadFileName);
        })
      ).subscribe(),
    );

    this.store.dispatch(loadInternForms());
    this.store.dispatch(loadInternshipStatuses());
  }

  reloadFilter(event) {
    this.filter = event;
    this.reloadList();
  }

  reloadList() {
    this.setFilter();

    this.store.dispatch(getManageApplicationsList({
      filter: this.filter
    }));
  }

  getStaffList(event) {
    this.store.dispatch(loadEmployeeUsers({
      prefixText: event.query,
      programId: this.campusId,
      payrollClassificationId: null,
      showTerminated: false,
      showInactive: false,
    }));
  }

  reloadListAfterStaffChange(selectedUser) {
    if (typeof selectedUser.value !== 'number') {
      return;
    }

    this.userId = selectedUser.value;
    this.reloadList();
  }

  reloadListAfterClear(event) {
    if (event.data !== null) {
      return;
    }

    this.userId = 0;
    this.reloadList();
  }

  setFilter() {
    this.filter = deepClone(this.filter);
    this.filter.filters.userId = { Name: 'userId', Value: { value: this.userId.toString() } };
    this.filter.filters.statusId = { Name: 'statusId', Value: { value: this.statusId.toString() } };
    this.filter.filters.formId = { Name: 'formId', Value: { value: this.formId } };
  }  

  viewApplication(item: InternApplicationModel) {
    if (item.formId > 0 && item.formSubmissionId > 0) {//can it have a formId without a form submission?
      var path = "/form-filling/form-submission/" + item.formId + "/" + item.formSubmissionId;
      
      this.router.navigate([path], { queryParams: { backLink: "/intern/list-manage" } });
    }
  }

  exportToExcel() {
    if (this.loadingList || this.loadingExcel) {
      return;
    }
    this.setFilter();

    this.store.dispatch(exportManageInternApplicationsListToExcel({
      filter: this.filter
    }));
  }

  editActionItem(item: InternApplicationActionModel) {    
    if (typeof item.actionId === 'undefined') { 
      this.alertService.error("Action item not found. Please submit a support ticket.");
      return false;
    }
    this.store.dispatch(showEditInternActionItem({ actionId: item.actionId, actionApplicationId: item.applicationId }));
  }  

  updateCalculations(item: InternApplicationModel) {
    if (typeof item.internApplicationId === 'undefined') {
      this.alertService.error("Application item not found. Please submit a support ticket.");
      return false;
    }
    this.store.dispatch(updateHourAndProductivityCalculations({ applicationId: item.internApplicationId }));
  }

  downloadUploadedFile(upload: InternUploadModel) {
    if (!this.loadingDownload) {
      this.downloadFileName = upload.fileName;
      this.store.dispatch(getInternApplicationUpload({ uploadId: upload.id }));
    }
  }
}
