import { createReducer, on, Action } from '@ngrx/store';
import {
  OnBoardingDocumentUserModel, UserOnBoardingDocumentsModel,
  OnBoardingDocumentModel, OnBoardingDocumentModelWithRoles
} from 'src/app/models/onBoardingDocumentUserModel';
import {
  loadOnboardingDoc, loadOnboardingDocSuccess, signOnboardingDoc,
  signOnboardingDocSuccess, getOnboardingDocsList, getOnboardingDocsListSuccess,
  signOnboardingDocFailure, loadOnboardingDocInfo, loadOnboardingDocInfoSuccess,
  getAllUserDocs, getAllUserDocsSuccess, getActiveDocs, getActiveDocsSuccess,
  getUsersDocsReport, getUsersDocsReportSuccess, getUsersDocsReportFailure, getUsersDocsReportExcel,
  getUsersDocsReportExcelSuccess, getUsersDocsReportExcelFailure, getOnboardingDocs,
  getOnboardingDocsSuccess, getOnboardingDocsFailure, getOnboardingDoc, getOnboardingDocFailure,
  getOnboardingDocSuccess, updateOnBoardingDocForm, getOnboardingUserDocs, getOnboardingUserDocsSuccess, getOnboardingUserDocsFailure
} from './onboardingdocs.actions';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserProfileModel } from 'src/app/models/userProfileModel';
import { PagingResultsModel } from 'src/app/models/pagingResultsModel';

export interface OnboardingDocsState {
  loadingDoc: boolean;
  loadingSign: boolean;
  docArrayBuffer: ArrayBuffer;
  docSignedArrayBuffer: ArrayBuffer;

  docsList: OnBoardingDocumentUserModel[];
  loadingList: boolean;


  loadingDocInfo: boolean;
  docInfo: OnBoardingDocumentUserModel;

  allUserDocs: PagingResultsModel<UserOnBoardingDocumentsModel>;
  loadingAllUserDocs: boolean;

  activeDocs: OnBoardingDocumentModel[];
  loadingActiveDocs: boolean;

  loadingReport: boolean;
  reportArrayBuffer: ArrayBuffer;
  loadingReportExcel: boolean;
  reportExcelArrayBuffer: ArrayBuffer;

  allDocs: PagingResultsModel<OnBoardingDocumentModelWithRoles>;
  loadingAllDocs: boolean;

  loadingOnBoardingDoc: boolean;
  onBoardingDoc: OnBoardingDocumentModelWithRoles;

  usersDocs: PagingResultsModel<OnBoardingDocumentUserModel>;
  loadingUserDocs: boolean;
}

const initialState: OnboardingDocsState = {
  loadingDoc: false,
  loadingSign: false,
  docArrayBuffer: null,
  docSignedArrayBuffer: null,

  docsList: [],
  loadingList: false,

  loadingDocInfo: false,
  docInfo: null,

  allUserDocs: null,
  loadingAllUserDocs: false,


  activeDocs: null,
  loadingActiveDocs: false,

  loadingReport: false,
  reportArrayBuffer: null,
  loadingReportExcel: false,
  reportExcelArrayBuffer: null,


  allDocs: null,
  loadingAllDocs: false,

  loadingOnBoardingDoc: false,
  onBoardingDoc: null,

  usersDocs: null,
  loadingUserDocs: false
};

export const onboardingDocsReducer = createReducer(initialState,
  on(loadOnboardingDoc, (state, action) => {
    return {
      ...state,
      loadingDoc: true,
      docArrayBuffer: null
    };
  }),
  on(loadOnboardingDocSuccess, (state, action) => {
    return {
      ...state,
      docArrayBuffer: action.doc,
      loadingDoc: false
    };
  }),
  on(signOnboardingDoc, (state, action) => {
    return {
      ...state,
      loadingSign: true,
      docSignedArrayBuffer: null
    };
  }),
  on(signOnboardingDocSuccess, (state, action) => {
    return {
      ...state,
      docSignedArrayBuffer: action.docSigned,
      loadingSign: false
    };
  }),
  on(signOnboardingDocFailure, (state, action) => {
    return {
      ...state,
      loadingSign: false
    };
  }),


  on(getOnboardingDocsList, (state, action) => {
    return {
      ...state,
      loadingList: true
    };
  }),
  on(getOnboardingDocsListSuccess, (state, action) => {
    return {
      ...state,
      docsList: action.docs,
      loadingList: false
    };
  }),

  on(loadOnboardingDocInfo, (state, action) => {
    return {
      ...state,
      loadingDocInfo: true,
      docInfo: null
    };
  }),
  on(loadOnboardingDocInfoSuccess, (state, action) => {
    return {
      ...state,
      docInfo: action.doc,
      loadingDocInfo: false
    };
  }),

  on(getAllUserDocs, (state, action) => {
    return {
      ...state,
      loadingAllUserDocs: true
    };
  }),

  on(getAllUserDocsSuccess, (state, action) => {
    return {
      ...state,
      allUserDocs: action.docs,
      loadingAllUserDocs: false
    };
  }),


  on(getActiveDocs, (state, action) => {
    return {
      ...state,
      loadingActiveDocs: true
    };
  }),

  on(getActiveDocsSuccess, (state, action) => {
    return {
      ...state,
      activeDocs: action.docs,
      loadingActiveDocs: false
    };
  }),

  on(getUsersDocsReport, (state, action) => {
    return {
      ...state,
      loadingReport: true,
      reportArrayBuffer: null
    };
  }),
  on(getUsersDocsReportSuccess, (state, action) => {
    return {
      ...state,
      reportArrayBuffer: action.doc,
      loadingReport: false
    };
  }),
  on(getUsersDocsReportFailure, (state, action) => {
    return {
      ...state,
      loadingReport: false
    };
  }),



  on(getUsersDocsReportExcel, (state, action) => {
    return {
      ...state,
      loadingReportExcel: true,
      reportExcelArrayBuffer: null
    };
  }),
  on(getUsersDocsReportExcelSuccess, (state, action) => {
    return {
      ...state,
      reportExcelArrayBuffer: action.doc,
      loadingReportExcel: false
    };
  }),
  on(getUsersDocsReportExcelFailure, (state, action) => {
    return {
      ...state,
      loadingReportExcel: false
    };
  }),


  on(getOnboardingDocs, (state, action) => {
    return {
      ...state,
      loadingAllDocs: true,
      allDocs: null
    };
  }),
  on(getOnboardingDocsSuccess, (state, action) => {
    return {
      ...state,
      allDocs: action.docs,
      loadingAllDocs: false
    };
  }),
  on(getOnboardingDocsFailure, (state, action) => {
    return {
      ...state,
      loadingAllDocs: false
    };
  }),

  on(getOnboardingDoc, (state, action) => {
    return {
      ...state,
      loadingOnBoardingDoc: true,
      onBoardingDoc: null
    };
  }),
  on(getOnboardingDocSuccess, (state, action) => {
    return {
      ...state,
      onBoardingDoc: action.doc,
      loadingOnBoardingDoc: false
    };
  }),
  on(getOnboardingDocFailure, (state, action) => {
    return {
      ...state,
      loadingOnBoardingDoc: false
    };
  }),

  on(updateOnBoardingDocForm, (state, action) => {
    const newOnBoardingDocument = { ...state.onBoardingDoc.onBoardingDocument, ...action.onBoardingDocumentValues };
    const newonBoardingDoc = { ...state.onBoardingDoc, ...action.rolesValues, ... { onBoardingDocument: newOnBoardingDocument } };
    const newState = { ...state, ...{ onBoardingDoc: newonBoardingDoc, formErrors: action.formErrors } };
    return newState;
  }),



  on(getOnboardingUserDocs, (state, action) => {
    return {
      ...state,
      loadingUsersDoc: true,
      usersDocs: null
    };
  }),
  on(getOnboardingUserDocsSuccess, (state, action) => {
    return {
      ...state,
      usersDocs: action.docs,
      loadingUsersDoc: false
    };
  }),
  on(getOnboardingUserDocsFailure, (state, action) => {
    return {
      ...state,
      loadingUsersDoc: false
    };
  }),
);

export function onboardingDocsReducerFunc(state: OnboardingDocsState | undefined, action: Action) {
  return onboardingDocsReducer(state, action);
}



export function initOnBoardingDocEditForm(onBoardingDocument: OnBoardingDocumentModelWithRoles): FormGroup {
  const form = new FormGroup({
    onBoardingDocument: new FormGroup({
      title: new FormControl(onBoardingDocument.onBoardingDocument.title, [Validators.required, Validators.maxLength(255)]),
      active: new FormControl(onBoardingDocument.onBoardingDocument.active, []),
      required: new FormControl(onBoardingDocument.onBoardingDocument.required, []),
      renewAfterDays: new FormControl(onBoardingDocument.onBoardingDocument.renewAfterDays, []),
      isCredentialDoc: new FormControl(onBoardingDocument.onBoardingDocument.isCredentialDoc, []),
      isDefaultDoc: new FormControl(onBoardingDocument.onBoardingDocument.isDefaultDoc, []),
    }),
    roles: new FormControl(onBoardingDocument.roles ? onBoardingDocument.roles : [], []),
    notInRoles: new FormControl(onBoardingDocument.notInRoles ? onBoardingDocument.notInRoles : [], []),
    requiredRoles: new FormControl(onBoardingDocument.requiredRoles ? onBoardingDocument.requiredRoles : [], []),
    notRequiredRoles: new FormControl(onBoardingDocument.notRequiredRoles ? onBoardingDocument.notRequiredRoles : [], []),
  });
  return form;
}
