import { PagingResultsModel } from './../../models/pagingResultsModel';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { JobApplicationsModel, JobApplicationsWithFileModel } from 'src/app/models/jobApplicationsModel';
import { CampusModel } from 'src/app/models/campusModel';
import { DocumentationModel, ResumeURLModel } from 'src/app/models/resumeURLModel';
import { JobTitleHasJobApplicationFormModel, ScreeningInterviewModel } from 'src/app/models/screeningInterviewModel';

@Injectable({
  providedIn: 'root'
})
export class JobApplicationsService {

  constructor(private http: HttpClient) { }

  saveJobApplication(jobApplication: JobApplicationsWithFileModel, resume: File, coverLetter?: File) {
    const formData = new FormData();

    formData.append('files.Index', '0');
    formData.append('files[0].File', resume);
    for (const key in jobApplication) {
      if (jobApplication[key]) {
        formData.append('files[0].' + key, jobApplication[key]);
      }
    }
    formData.append('files.Index', '1');
    formData.append('files[1].File', coverLetter);

    for (const key in jobApplication) {
      if (jobApplication[key]) {
        formData.append('files[1].' + key, jobApplication[key]);
      }
    }

    const options = { headers: new HttpHeaders() };
    options.headers.append('enctype', 'multipart/form-data');

    return this.http.post<JobApplicationsWithFileModel>(`${environment.apiServer.webApiURL}/JobApplications/UploadApplication`, formData, options);
  }

  getJobApplicationTable(filters: any): Observable<PagingResultsModel<JobApplicationsModel>> {
    return this.http.post<PagingResultsModel<JobApplicationsModel>>
      (`${environment.apiServer.webApiURL}/JobApplications/Datatable`, filters);
  }

  getCampuses(): Observable<CampusModel[]> {
    return this.http.get<CampusModel[]>(`${environment.apiServer.webApiURL}/JobApplications/GetCampuses`);
  }

  getResume(applicationID: number){
    return this.http.get<DocumentationModel>(`${environment.apiServer.webApiURL}/JobApplications/GetResume/${applicationID}`);
  }

  getCoverLetter(applicationID: number){
    return this.http.get<DocumentationModel>(`${environment.apiServer.webApiURL}/JobApplications/GetCoverLetter/${applicationID}`);
  }

  logNavigation(): Observable<string> {
    return this.http.get<string>(`${environment.apiServer.webApiURL}/JobApplications/LogJobApplicationNavigation`);
  }

  saveAsProspectiveCandidate(app: JobApplicationsModel){
    return this.http.post<JobApplicationsModel>(`${environment.apiServer.webApiURL}/JobApplications/ApplicantToCandidate`, app);
  }

  archiveJobApplication( jobAppID: number, archiveReason: string){
    return this.http.post<JobApplicationsModel>(`${environment.apiServer.webApiURL}/JobApplications/ArchiveApplicant`, {jobAppID: jobAppID, archiveReason: archiveReason });
  }

  savePhoneInterview(interview: ScreeningInterviewModel){
    return this.http.post<ScreeningInterviewModel>(`${environment.apiServer.webApiURL}/JobApplications/SavePhoneInterview`, interview);
  }

  loadJobApplicationForms(){
    return this.http.get<JobTitleHasJobApplicationFormModel[]>(`${environment.apiServer.webApiURL}/JobApplications/LoadJobApplicationForms`);
  }

}
