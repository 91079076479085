import { deepClone } from 'src/app/helpers/utils';

import { ViewChild, AfterViewInit } from '@angular/core';
import { BaseComponent } from '../../core/abstracts/baseComponent';
import * as moment from 'moment';
import { Store } from '@ngrx/store';
import { State } from 'src/app/core/store';

export class SignDocComponentBase extends BaseComponent implements AfterViewInit {

  @ViewChild('pdfViewer', { static: false }) pdfViewer;
  public signedFields: string[] = [];
  public finishSignDisabled: boolean;
  public currentUserID: string;
  public intervalFormattingSignature;
  public isHRManager;
  public isSigningAsHRManager;
  public optionalFields: string[] = [];
  public url: any;
  public pagesloaded = false;

  constructor(
    private storeBase: Store<State>,) {
    super();
    this.subs.push(
      this.storeBase.select(s => s.profileState.profile).subscribe(p => {
        if (p) {
          this.finishSignDisabled = !!p.user.impersonatedByUserID;
          this.currentUserID = p.userId;
        }
      }),
      this.storeBase.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.isHRManager = permissionIDs && !!permissionIDs.find(p => p === 3060 || p === 3064 || p === 3068);
      })
    );
  }

  ngAfterViewInit() {
    this.afterPdfViewerInit();
  }

  afterPdfViewerInit() {
    const that = this;
    setTimeout(() => {
      if (!this.url || !this.pdfViewer || !this.pdfViewer.PDFViewerApplication || !this.pdfViewer.PDFViewerApplication.eventBus) {
        this.afterPdfViewerInit();
      } else {
        this.pdfViewer.page = 1;
        this.pdfViewer.PDFViewerApplication.eventBus._dispatchToDOM = true;
        this.pdfViewer.PDFViewerApplication.eventBus.on('pagesloaded', (e) => {
          this.pagesloaded = true;
          this.pdfViewer.page = 1;
          if (document.getElementsByTagName('iframe')[0] && document.getElementsByTagName('iframe')[0].contentDocument
          && document.getElementsByTagName('iframe')[0].contentDocument.getElementById('viewerContainer')){
            document.getElementsByTagName('iframe')[0].contentDocument.getElementById('viewerContainer').scrollTo(0, 0);
          }
          this.formatSignatureFields();
        });
        this.pdfViewer.PDFViewerApplication.eventBus.on('pagechanging', (e) => {
          this.pagesloaded = true;
          this.formatSignatureFields();
        });
        if (document.getElementsByTagName('iframe')[0] && document.getElementsByTagName('iframe')[0].contentDocument
          && document.getElementsByTagName('iframe')[0].contentDocument.getElementById('viewerContainer')){
            document.getElementsByTagName('iframe')[0].contentDocument.getElementById('viewerContainer').scrollTo(0, 0);
          }
      }
    }, 500);


    if (!that.intervalFormattingSignature) {
      that.intervalFormattingSignature = setInterval(() => {
        that.formatSignatureFields();
      }, 3000);
    }

    that.formatSignatureFields();
  }

  formatSignatureFields() {
    const that = this;
    if (document.getElementsByTagName('iframe')[0] && document.getElementsByTagName('iframe')[0].contentDocument ) {
      const viewer = document.getElementsByTagName('iframe')[0].contentDocument.getElementById('viewer');
      if (viewer && viewer.querySelectorAll('.page')) {
        console.log('formatSignatureFields');
        const imgs = document.getElementsByTagName('iframe')[0].contentDocument.querySelectorAll('.annotationLayer section img');
        imgs.forEach(img => {
          if (img && img.parentNode) {
            img.parentNode.removeChild(img);
          }
        });

        const freeTextAnnotations =
          document.getElementsByTagName('iframe')[0].contentDocument.querySelectorAll('.annotationLayer section.freeTextAnnotation');
        freeTextAnnotations.forEach(freeTextAnnotation => {
          if (freeTextAnnotation && freeTextAnnotation.parentNode) {
          freeTextAnnotation.parentNode.removeChild(freeTextAnnotation);
          }
        });


        const secondaryToolbarToggle = document.getElementsByTagName('iframe')[0].contentDocument.getElementById('secondaryToolbarToggle');
        if (secondaryToolbarToggle && secondaryToolbarToggle.parentNode){
            secondaryToolbarToggle.parentNode.removeChild(secondaryToolbarToggle);
        }

        const myEles = document.getElementsByTagName('iframe')[0].contentDocument.querySelectorAll('div.popup');
        for (const ele of Array.from(myEles)) {
          if (ele && ele.innerHTML) {
            if (ele.innerHTML.toLowerCase().indexOf('<h1>datesignature') === 0 || ele.innerHTML.toLowerCase().indexOf('<h1>#datesignature#') === 0) {
              if (!this.isSigningAsHRManager ||
                (this.isSigningAsHRManager && ele.innerHTML.toLowerCase().indexOf('#hruser#') >= 0 && this.isHRManager) ) {
                  const divNova = document.getElementsByTagName('iframe')[0].contentDocument.createElement('div');
                  divNova.innerHTML = `<div style='color: rgba(0,0,0,1);
                                      opacity: 1;z-index: 999999;
                                      border: 0px solid black;
                                      margin-top:2px;
                                      text-align: center;
                                      width: 100px;
                                      min-height: 10px;
                                      font-size:10px;
                                      cursor: pointer;'  > ` + moment().format('MM/DD/YYYY') + `
                                      </div>`;
                  ele.parentNode.parentNode.insertBefore(divNova, ele.parentNode.parentNode.firstChild);
                  ele.parentNode.parentNode.removeChild(ele.parentNode);
                }
            }
            if (ele.innerHTML.toLowerCase().indexOf('<h1>signature') === 0 || ele.innerHTML.toLowerCase().indexOf('<h1>initial') === 0
            || ele.innerHTML.toLowerCase().indexOf('<h1>#signature#') === 0 || ele.innerHTML.toLowerCase().indexOf('<h1>#initials#') === 0) {
              const signatureFieldName = ele.innerHTML.split('</h1>')[0].replace('<h1>', '');

              const userID = this.extractUserID(signatureFieldName);
              if (((!userID && signatureFieldName.toLowerCase().indexOf('#hruser#') < 0 && !this.isSigningAsHRManager) || userID === this.currentUserID?.toString())
                || (this.isSigningAsHRManager && signatureFieldName.toLowerCase().indexOf('#hruser#') >= 0 && this.isHRManager)) {

                let isSigned = that.signedFields.indexOf(signatureFieldName) >= 0;
                const isRequired = (ele.innerHTML.toLowerCase().indexOf('optional') < 0) && !(this.optionalFields.indexOf(signatureFieldName) >= 0);

                const divNova = document.getElementsByTagName('iframe')[0].contentDocument.createElement('div');
                divNova.innerHTML = `<div style='color: rgba(0,0,0,1);
                                  opacity: 1;z-index: 999999;
                                  border: 1px solid black;
                                  background-color: `
                  + (isSigned ? 'rgba(66, 245, 66, 1)' : (isRequired ? 'rgba(245, 66, 66,1)' : 'rgba(245, 221, 66,1)')) + `;
                                  text-align: center;
                                  width: ` + ((ele.innerHTML.toLowerCase().indexOf('<h1>signature') === 0 || ele.innerHTML.toLowerCase().indexOf('<h1>#signature#') === 0) ? '100' : '34') + `px;
                                  min-height: 10px;
                                  font-size:10px;
                                  cursor: pointer;
                                  '
                                  title='` + (isSigned ? 'signed' : (isRequired ? 'Required Signature' : 'Optional Signature')) + `'
                                  > ` + (isSigned ? 'Signed' : 'Sign') + `
                                  </div>`;
                divNova.onclick = (e) => {
                  (e.target as any).style.backgroundColor =
                    (isSigned ? (isRequired ? 'rgba(245, 66, 66,1)' : 'rgba(245, 221, 66,1)') : 'rgba(66, 245, 66, 1)');
                  (e.target as any).innerHTML = isSigned ? 'Sign' : 'Signed';
                  if (isSigned) {
                    that.signedFields.splice(that.signedFields.indexOf(signatureFieldName), 1);
                  } else {
                    that.signedFields.push(signatureFieldName);
                  }
                  isSigned = !isSigned;
                };
                ele.parentNode.parentNode.insertBefore(divNova, ele.parentNode.parentNode.firstChild);
              }
              ele.parentNode.parentNode.removeChild(ele.parentNode);
            }
          }
        }
      }
    }
  }

  extractUserID(field: string) {
    if (field.indexOf('UserID#') < 0) {
      return null;
    }
    const userIdStart = field.indexOf('UserID#') + 7;
    const userIdEnd = field.lastIndexOf('#');

    const userIdString = field.substr(userIdStart, userIdEnd - userIdStart);
    return userIdString;
  }

}
