<div [formGroup]="form" *ngIf="profile!=null">
  <h1>I-9 Form</h1>
  <h5 class="card-title">Update profile for filling the document</h5>
  This document require some extra infos to be filled, please provide the infos
  bellow:<br />
  (the info will be saved on your profile and used to fill the document)
  <div class="row">
    <div class="col-12 col-xl-12">
      <div class="row">
        <div class="col-4 d-flex align-items-center">
          <b>Legal First Name</b>
        </div>
        <div class="col-8 pt-2 pb-2">
          {{ profile.user.firstName }}
        </div>
      </div>
      <div class="row">
        <div class="col-4 d-flex align-items-center">
          <b>Legal Last Name</b>
        </div>
        <div class="col-8 pt-2 pb-2">
          {{ profile.user.lastName }}
        </div>
      </div>

      <div class="row" formGroupName="user">
        <div class="col-4 d-flex align-items-center">
          <b>Middle Name</b>
        </div>
        <div class="col-8">
          <input
            type="text"
            class="form-control mt-2"
            formControlName="middleName"
          />
          <app-validation-message
            [form]="form"
            [path]="'user.middleName'"
            [name]="'middleName'"
            [label]="'Middle Name'"
          >
          </app-validation-message>
        </div>
      </div>

      <div class="row" formGroupName="user">
        <div class="col-4 d-flex align-items-center">
          <b>Previous Names</b>
        </div>
        <div class="col-8">
          <input
            type="text"
            class="form-control mt-2"
            formControlName="previousName"
          />
          <app-validation-message
            [form]="form"
            [path]="'user.previousName'"
            [name]="'previousName'"
            [label]="'Previous Name'"
          >
          </app-validation-message>
        </div>
      </div>

      <div class="row" formGroupName="employee">
        <div class="col-4 d-flex align-items-center">
          <b>Birth Date</b>
        </div>
        <div class="col-8">
          <p-calendar
            inputStyleClass="form-control mt-2"
            [monthNavigator]="true"
            [yearNavigator]="true"
            [yearRange]="yearRange"
            styleClass="w-100"
            panelStyleClass="calendarPanel"
            [hourFormat]="12"
            [showTime]="false"
            id="birthdate"
            name="birthdate"
            dataType="string"
            formControlName="birthdate"
          ></p-calendar>

          <app-validation-message
            [form]="form"
            [path]="'employee.birthdate'"
            [name]="'birthdate'"
            [label]="'Birth Date'"
          >
          </app-validation-message>
        </div>
      </div>

      <div class="row" formGroupName="employee">
        <div class="col-4 d-flex align-items-center">
          <b>SSN</b>
        </div>
        <div class="col-8">
          <input
            type="text"
            class="form-control mt-2"
            formControlName="ssn"
          />
          <app-validation-message
            [form]="form"
            [path]="'employee.ssn'"
            [name]="'ssn'"
            [label]="'SSN'"
          >
          </app-validation-message>
        </div>
      </div>

      <div class="row" formGroupName="user">
        <div class="col-4 d-flex align-items-center">
          <b>Phone Number</b>
        </div>
        <div class="col-8">
          <input
            type="text"
            class="form-control mt-2"
            formControlName="phoneNumber"
          />
          <app-validation-message
            [form]="form"
            [path]="'user.phoneNumber'"
            [name]="'phoneNumber'"
            [label]="'Phone Number'"
          >
          </app-validation-message>
        </div>
      </div>
      <div class="row"  formGroupName="employee">
        <div class="col-4 d-flex align-items-center">
          <b>Personal Email</b>
        </div>
        <div class="col-8">
          <input type="text" class="form-control mt-2" formControlName="personalEmail"/>
          <app-validation-message [form]="form" [path]="'employee.personalEmail'" [name]="'personalEmail'" [label]="'Email'"> </app-validation-message>

        </div>
      </div>
      <div class="row" formGroupName="employee">
        <div class="col-4 d-flex align-items-center">
          <b>Address</b>
        </div>
        <div class="col-8">
          <input
            type="text"
            class="form-control mt-2"
            formControlName="address"
          />
          <app-validation-message
            [form]="form"
            [path]="'employee.address'"
            [name]="'address'"
            [label]="'Address'"
          >
          </app-validation-message>
        </div>
      </div>
      <div class="row" formGroupName="employee">
        <div class="col-4 d-flex align-items-center">
          <b>Apt Number</b>
        </div>
        <div class="col-8">
          <input
            type="text"
            class="form-control mt-2"
            formControlName="addressExtra"
          />
          <app-validation-message
            [form]="form"
            [path]="'employee.addressExtra'"
            [name]="'addressExtra'"
            [label]="'Address Extra'"
          >
          </app-validation-message>
        </div>
      </div>
      <div class="row" formGroupName="employee">
        <div class="col-4 d-flex align-items-center">
          <b>City</b>
        </div>
        <div class="col-8">
          <input type="text" class="form-control mt-2" formControlName="city" />
          <app-validation-message
            [form]="form"
            [path]="'employee.city'"
            [name]="'city'"
            [label]="'City'"
          >
          </app-validation-message>
        </div>
      </div>
      <div class="row" formGroupName="employee">
        <div class="col-4 d-flex align-items-center">
          <b>State</b>
        </div>
        <div class="col-8">
          <input
            type="text"
            class="form-control mt-2"
            formControlName="state"
          />
          <app-validation-message
            [form]="form"
            [path]="'employee.state'"
            [name]="'state'"
            [label]="'State'"
          >
          </app-validation-message>
        </div>
      </div>
      <div class="row" formGroupName="employee">
        <div class="col-4 d-flex align-items-center">
          <b>Zip Code</b>
        </div>
        <div class="col-8">
          <input
            type="text"
            class="form-control mt-2"
            formControlName="zipCode"
          />
          <app-validation-message
            [form]="form"
            [path]="'employee.zipCode'"
            [name]="'zipCode'"
            [label]="'Zip Code'"
          >
          </app-validation-message>
        </div>
      </div>


    <div class="row"  formGroupName="employee">
      <div class="col-4 d-flex align-items-center">
        <b>I attest, under penalty of perjury, that I am:</b>
      </div>
      <div class="col-8  pt-2 pb-2">
        <p-selectButton [options]="i9USSituationOptions" formControlName="i9USSituation"></p-selectButton>
        <app-validation-message [form]="form" [path]="'employee.i9USSituation'" [name]="'i9USSituation'" [label]="'I attest, under penalty of perjury, that I am'"> </app-validation-message>

      </div>
    </div>

    <div class="row" formGroupName="employee" *ngIf="form.get('employee.i9USSituation').value == 'PermanentResident'">
      <div class="col-4 d-flex align-items-center">
        <b>Alien Registration Number/USCIS Number</b>
      </div>
      <div class="col-8">
        <input
          type="text"
          class="form-control mt-2"
          formControlName="i9USCIS"
        />
        <app-validation-message
          [form]="form"
          [path]="'employee.i9USCIS'"
          [name]="'i9USCIS'"
          [label]="'Alien Registration Number/USCIS Number'"
        >
        </app-validation-message>
      </div>
    </div>

    <div class="row" formGroupName="employee" *ngIf="form.get('employee.i9USSituation').value == 'AuthorizedToWork'">
      <div class="col-4 d-flex align-items-center">
        <b>Work authorization expiration date</b>
      </div>
      <div class="col-8">
        <input
          type="date"
          class="form-control mt-2"
          formControlName="i9AlienAuthorizationExpirationDate"
        />
        <app-validation-message
          [form]="form"
          [path]="'employee.i9AlienAuthorizationExpirationDate'"
          [name]="'i9AlienAuthorizationExpirationDate'"
          [label]="'Work authorization expiration date'"
        >
        </app-validation-message>
      </div>
    </div>

    <hr  *ngIf="form.get('employee.i9USSituation').value == 'AuthorizedToWork'" />
    <div class="row" formGroupName="employee" *ngIf="form.get('employee.i9USSituation').value == 'AuthorizedToWork'">
      <div class="col-12">
        Aliens authorized to work must provide only one of the following document numbers to complete Form I-9:
An Alien Registration Number/USCIS Number OR Form I-94 Admission Number OR Foreign Passport Number.
<app-validation-message
[form]="form"
[path]="'employee.i9USCIS'"
[name]="'i9USCIS'"
[label]="'Alien Registration Number/USCIS Number'"
>
</app-validation-message>
      </div>
    </div>
    <div class="row" formGroupName="employee" *ngIf="form.get('employee.i9USSituation').value == 'AuthorizedToWork'">
      <div class="col-4 d-flex align-items-center">
        <b>Alien Registration Number/USCIS Number</b>
      </div>
      <div class="col-8">
        <input
          type="text"
          class="form-control mt-2"
          formControlName="i9USCIS"
        />

      </div>
    </div>
    <div class="row" formGroupName="employee" *ngIf="form.get('employee.i9USSituation').value == 'AuthorizedToWork'">
      <div class="col-4 d-flex align-items-center">

      </div>
      <div class="col-8">
        OR
      </div>
    </div>
    <div class="row" formGroupName="employee" *ngIf="form.get('employee.i9USSituation').value == 'AuthorizedToWork'">
      <div class="col-4 d-flex align-items-center">
        <b>Form I-94 Admission Number</b>
      </div>
      <div class="col-8">
        <input
          type="text"
          class="form-control mt-2"
          formControlName="i9i94AdmissionNumber"
        />
        <app-validation-message
          [form]="form"
          [path]="'employee.i9i94AdmissionNumber'"
          [name]="'i9i94AdmissionNumber'"
          [label]="'Form I-94 Admission Number'"
        >
        </app-validation-message>
      </div>
    </div>
    <div class="row" formGroupName="employee" *ngIf="form.get('employee.i9USSituation').value == 'AuthorizedToWork'">
      <div class="col-4 d-flex align-items-center">

      </div>
      <div class="col-8">
        OR
      </div>
    </div>

    <div class="row" formGroupName="employee" *ngIf="form.get('employee.i9USSituation').value == 'AuthorizedToWork'">
      <div class="col-4 d-flex align-items-center">
        <b>Foreign Passport Number</b>
      </div>
      <div class="col-8">
        <input
          type="text"
          class="form-control mt-2"
          formControlName="i9ForeignPassportNumber"
        />
        <app-validation-message
          [form]="form"
          [path]="'employee.i9ForeignPassportNumber'"
          [name]="'i9ForeignPassportNumber'"
          [label]="'Foreign Passport Number'"
        >
        </app-validation-message>
      </div>
    </div>

    <div class="row" formGroupName="employee" *ngIf="form.get('employee.i9USSituation').value == 'AuthorizedToWork'">
      <div class="col-4 d-flex align-items-center">
        <b>Foreign Passport Country</b>
      </div>
      <div class="col-8">
        <input
          type="text"
          class="form-control mt-2"
          formControlName="i9ForeignPassportCountry"
        />
        <app-validation-message
          [form]="form"
          [path]="'employee.i9ForeignPassportCountry'"
          [name]="'i9ForeignPassportCountry'"
          [label]="'Foreign Passport Country'"
        >
        </app-validation-message>
      </div>
    </div>
    <hr  *ngIf="form.get('employee.i9USSituation').value == 'AuthorizedToWork'" />
    <div class="row" formGroupName="employee">
      <div class="col-4 d-flex align-items-center">
        <b>A preparer(s) and/or translator(s) assisted you in completing this form?</b>
      </div>
      <div class="col-8  pt-2 pb-2">
        <p-selectButton [options]="yesNoOptions" formControlName="i9UsedPreparer"></p-selectButton>
        <app-validation-message [form]="form" [path]="'employee.i9UsedPreparer'" [name]="'i9UsedPreparer'" [label]="'Preparer/Translator'"> </app-validation-message>


      </div>
    </div>

    <div class="row" formGroupName="employee" *ngIf="form.get('employee.i9UsedPreparer').value">
      <div class="col-4 d-flex align-items-center">
        <b>Preparer First Name</b>
      </div>
      <div class="col-8">
        <input
          type="text"
          class="form-control mt-2"
          formControlName="preparerFirstname"
        />
        <app-validation-message
          [form]="form"
          [path]="'employee.preparerFirstname'"
          [name]="'preparerFirstname'"
          [label]="'Preparer First Name'"
        >
        </app-validation-message>
      </div>
    </div>
    <div class="row" formGroupName="employee" *ngIf="form.get('employee.i9UsedPreparer').value">
      <div class="col-4 d-flex align-items-center">
        <b>Preparer Last Name</b>
      </div>
      <div class="col-8">
        <input
          type="text"
          class="form-control mt-2"
          formControlName="preparerLastname"
        />
        <app-validation-message
          [form]="form"
          [path]="'employee.preparerLastname'"
          [name]="'preparerLastname'"
          [label]="'Preparer Last Name'"
        >
        </app-validation-message>
      </div>
    </div>
    <div class="row" formGroupName="employee" *ngIf="form.get('employee.i9UsedPreparer').value">
      <div class="col-4 d-flex align-items-center">
        <b>Preparer Address (street and number)</b>
      </div>
      <div class="col-8">
        <input
          type="text"
          class="form-control mt-2"
          formControlName="preparerAddress"
        />
        <app-validation-message
          [form]="form"
          [path]="'employee.preparerAddress'"
          [name]="'preparerAddress'"
          [label]="'Preparer Address'"
        >
        </app-validation-message>
      </div>
    </div>
    <div class="row" formGroupName="employee" *ngIf="form.get('employee.i9UsedPreparer').value">
      <div class="col-4 d-flex align-items-center">
        <b>Preparer City</b>
      </div>
      <div class="col-8">
        <input
          type="text"
          class="form-control mt-2"
          formControlName="preparerCity"
        />
        <app-validation-message
          [form]="form"
          [path]="'employee.preparerCity'"
          [name]="'preparerCity'"
          [label]="'Preparer City'"
        >
        </app-validation-message>
      </div>
    </div>
    <div class="row" formGroupName="employee" *ngIf="form.get('employee.i9UsedPreparer').value">
      <div class="col-4 d-flex align-items-center">
        <b>Preparer State</b>
      </div>
      <div class="col-8">
        <input
          type="text"
          class="form-control mt-2"
          formControlName="preparerState"
        />
        <app-validation-message
          [form]="form"
          [path]="'employee.preparerState'"
          [name]="'preparerState'"
          [label]="'Preparer State'"
        >
        </app-validation-message>
      </div>
    </div>
    <div class="row" formGroupName="employee" *ngIf="form.get('employee.i9UsedPreparer').value">
      <div class="col-4 d-flex align-items-center">
        <b>Preparer Zip</b>
      </div>
      <div class="col-8">
        <input
          type="text"
          class="form-control mt-2"
          formControlName="preparerZip"
        />
        <app-validation-message
          [form]="form"
          [path]="'employee.preparerZip'"
          [name]="'preparerZip'"
          [label]="'Preparer Zip'"
        >
        </app-validation-message>
      </div>
    </div>

      <div class="row mt-4">
        <div class="col-6">
          <button
            class="btn btn-primary"
            [class.disabled]="form.invalid"
            (click)="save()"
          >
            Update and Sign
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
