
<div class="card-body">
  <h2 class="mb-3">Ethics Codes</h2>
  <div class="mb-3">
    <button (click)="addEthicCode()" class="btn btn-secondary">New Ethics Code</button>
    <button routerLink="/employee-review/template/list" class="ml-2 btn btn-secondary">Employee Review Templates</button>
  </div>

  <i [hidden]="!loading" class="material-icons rotate text-muted">loop</i>

  <table class="table table-sm table-striped table-bordered">
    <thead>
      <tr>
        <th scope="col"></th>
        <th scope="col">Section</th>
        <th scope="col">Is in Use</th>
        <th scope="col">Description</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let ec of ethicsCodes;">
        <tr>
          <td class="text-center">
            <button class="btn btn-secondary m-2" (click)="editEthicCode(ec)" title="Edit Ethics Code">
              <i class="fas fa-edit"></i>
            </button>
            <button class="btn btn-secondary m-2" (click)="deleteEthicCode(ec)" title="Edit Ethics Code">
              <i class="fas fa-trash-alt" title="Delete Ethics Code"></i>
            </button>
          </td>
          <th scope="row">{{ec.section}}</th>
          <th scope="row">
            <i *ngIf="!!ec.isBeingUsed" title="Ethics code is being used" class="fas fa-check-circle text-success"></i>
            <i *ngIf="!ec.isBeingUsed" class="fas fa-dot-circle"></i>
          </th>
          <td class="col-md-8 col-sm-4">
            <pre>{{ec.description}}</pre>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
<app-ethic-code></app-ethic-code>
