import { SelfCarePlannerSectionModel, SelfCareQuestionModel } from '../../../models/selfcareModels';
import { SelfCareCategoryModel } from 'src/app/models/selfcareModels';
import { createAction, props } from '@ngrx/store';
import { FormError } from 'src/app/models/utilModels';

export const loadManageSelfcarePlanner = createAction('[Manage Selfcare] Load Planner');
export const loadManageSelfcarePlannerSuccess = createAction('[Manage Selfcare] Load Planner Success',
  props<{ plannerSections: SelfCarePlannerSectionModel[] }>());
export const loadManageSelfcarePlannerFailure = createAction('[Manage Selfcare] Load Planner Failure');

export const loadManageSelfcarePlannerSection = createAction('[Manage Selfcare] Load PlannerSection',
  props<{ plannerSectionID: number }>());
export const loadManageSelfcarePlannerSectionSuccess = createAction('[Manage Selfcare] Load PlannerSection Success',
  props<{ plannerSection: SelfCarePlannerSectionModel }>());
export const loadManageSelfcarePlannerSectionFailure = createAction('[Manage Selfcare] Load PlannerSection Failure');

export const updateManageSelfcarePlannerSectionForm = createAction('[Manage Selfcare] Update PlannerSection Form',
  props<{ plannerSectionValues: any; itemsValues: any, formErrors: FormError[] }>());
export const clearManageSelfcarePlannerSection = createAction('[Manage Selfcare] Clear PlannerSection Form');

export const saveManageSelfcarePlannerSection = createAction('[Manage Selfcare] Save PlannerSection',
  props<{ plannerSection: SelfCarePlannerSectionModel }>());
export const saveManageSelfcarePlannerSectionSuccess = createAction('[Manage Selfcare] Save PlannerSection Success');
export const saveManageSelfcarePlannerSectionFailure = createAction('[Manage Selfcare] Save PlannerSection Failure');


export const loadManageSelfcareCategories = createAction('[Manage Selfcare] Load Categories');
export const loadManageSelfcareCategoriesSuccess = createAction('[Manage Selfcare] Load Categories Success',
  props<{ categories: SelfCareCategoryModel[] }>());
export const loadManageSelfcareCategoriesFailure = createAction('[Manage Selfcare] Load Categories Failure');

export const loadManageSelfcareCategory = createAction('[Manage Selfcare] Load Category',
  props<{ categoryID: number }>());
export const loadManageSelfcareCategorySuccess = createAction('[Manage Selfcare] Load Category Success',
  props<{ category: SelfCareCategoryModel }>());
export const loadManageSelfcareCategoryFailure = createAction('[Manage Selfcare] Load Category Failure');

export const updateManageSelfcareCategoryForm = createAction('[Manage Selfcare] Update Category Form',
  props<{ categoryValues: any; questionValues: any, formErrors: FormError[] }>());
export const clearManageSelfcareCategory = createAction('[Manage Selfcare] Clear Category Form');

export const saveManageSelfcareCategory = createAction('[Manage Selfcare] Save Category',
  props<{ category: SelfCareCategoryModel }>());
export const saveManageSelfcareCategorySuccess = createAction('[Manage Selfcare] Save Category Success');
export const saveManageSelfcareCategoryFailure = createAction('[Manage Selfcare] Save Category Failure');
