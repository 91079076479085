import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { SelectItem } from 'primeng/api';
import { DateRangeModel } from '../../../../models/dateRangeModel';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { DropdownFilterService } from '../../../../core/services/dropdown-filter.service';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { RecurringHolidayModel } from '../../../../models/holidayModel';
import { getRecurringHolidayList, saveRecurringHolidaySuccess, showEditRecurringHoliday } from '../../../../core/store/holiday/holiday.actions';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-recurring-holiday',
  templateUrl: './recurring-holiday.component.html',
  styleUrls: ['./recurring-holiday.component.scss']
})
export class RecurringHolidayComponent extends BaseComponent implements OnInit {
  holidays: PagingResultsModel<RecurringHolidayModel>;
  filter: any;

  loadingHolidayList: boolean = true;
  canEdit: boolean = false;

  holidayName: string = "";

  monthList: SelectItem[];
  month: number = -1;

  dateRangeList: SelectItem[];
  dateRange: DateRangeModel;
  startDate: string = null;
  endDate: string = null;
  isCustomDateRange: boolean = false;

  showArchived: boolean = false;

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private dropdownFilterService: DropdownFilterService) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.holidayState.loadingRecurringHolidayList).subscribe(loadingHolidayList => {
        this.loadingHolidayList = loadingHolidayList;
      }),
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.canEdit = permissionIDs && !!permissionIDs.find(p => p === 3037);
      }),            
      this.store.select(s => s.holidayState.recurringHolidayList).subscribe(holidayList => {
        this.holidays = holidayList;
      }),
      this.actions$.pipe(
        ofType(saveRecurringHolidaySuccess),
        tap(action => {
          this.reloadHolidays(this.filter);
        })
      ).subscribe(),
    );

    this.monthList = this.dropdownFilterService.getMonthsWithAll().map(c => ({ label: c.month, value: c.value }));
    this.dateRangeList = this.dropdownFilterService.get30DayDateRanges().map(c => ({ label: c.text, value: c }));
  }

  changedDateRange() {
    if (this.dateRange.isCustom) {
      this.isCustomDateRange = true;
      return; //reload after manually changing date
    }

    this.isCustomDateRange = false;
    this.startDate = this.dateRange.startDate;
    this.endDate = this.dateRange.endDate;
    this.reloadHolidays(this.filter);
  }

  reloadHolidays(event) {
    this.filter = event;
    this.store.dispatch(getRecurringHolidayList({
      filter: this.filter,
      holidayName: this.holidayName,      
      month: this.month,      
      startDate: this.startDate,
      endDate: this.endDate,
      showArchived: this.showArchived
    }));
  }

  editHoliday(holiday) {
    this.store.dispatch(showEditRecurringHoliday({ holiday: holiday }));
  }

}
