import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FullTimeStatusCheckModel } from '../../models/fullTimeStatusCheckModel';
import { PagingResultsModel } from '../../models/pagingResultsModel';
import { Observable } from 'rxjs';
import { deepClone } from '../../helpers/utils';
import { environment } from '../../../environments/environment';
import { SelectItem } from 'primeng/api';
import { EmployeeCensusModel, EmployeeModel, TerminatedEmployeeModel } from '../../models/userProfileModel';
import { ModifiedSelectItem } from '../../models/utilModels';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  constructor(private http: HttpClient) { }

  getCampusList(): Observable<SelectItem[]> {
    return this.http.get<SelectItem[]>(`${environment.apiServer.webApiURL}/Employee/Campuses`);
  }

  getEmployeeUserList(prefixText: string, programId: number, payrollClassificationId: number, showInactive: boolean, showTerminated: boolean): Observable<SelectItem[]> {
    return this.http.post<SelectItem[]>(`${environment.apiServer.webApiURL}/Employee/EmployeeUsers`, {
      prefixText, programId, payrollClassificationId, showInactive: showInactive, showTerminated: showTerminated
    });
  }

  getFullTimeStatusCheckList(filter: any, userId: number, programId: number, startDate: string, endDate: string, minHours: string, maxHours: string, fullTimeStatus: string, includeInactive: boolean)
    : Observable<PagingResultsModel<FullTimeStatusCheckModel>> {
    if (!filter) {
      filter = {};
      filter.filters = {};
    }
    filter = deepClone(filter);
    
    filter.filters.userId = { Name: 'userId', Value: { value: userId.toString() } };
    filter.filters.programId = { Name: 'programId', Value: { value: programId.toString() } };
    filter.filters.startDate = { Name: 'startDate', Value: { value: startDate } };
    filter.filters.endDate = { Name: 'endDate', Value: { value: endDate } };
    filter.filters.minHours = { Name: 'minHours', Value: { value: minHours } };
    filter.filters.maxHours = { Name: 'maxHours', Value: { value: maxHours } };
    filter.filters.fullTimeStatus = { Name: 'fullTimeStatus', Value: { value: fullTimeStatus } };
    filter.filters.includeInactive = { Name: 'includeInactive', Value: { value: includeInactive } };

    return this.http.post<PagingResultsModel<FullTimeStatusCheckModel>>(`${environment.apiServer.webApiURL}/Employee/FullTimeStatusChecksList`,
      filter)
  }

  getFullTimeStatusQueue(filter: any, userId: number, programId: number, startDate: string, endDate: string, minHours: string, maxHours: string, fullTimeStatus: string, includeInactive: boolean)
    : Observable<PagingResultsModel<FullTimeStatusCheckModel>> {
    if (!filter) {
      filter = {};
      filter.filters = {};
    }
    filter = deepClone(filter);

    filter.filters.userId = { Name: 'userId', Value: { value: userId.toString() } };
    filter.filters.programId = { Name: 'programId', Value: { value: programId.toString() } };
    filter.filters.startDate = { Name: 'startDate', Value: { value: startDate } };
    filter.filters.endDate = { Name: 'endDate', Value: { value: endDate } };
    filter.filters.minHours = { Name: 'minHours', Value: { value: minHours } };
    filter.filters.maxHours = { Name: 'maxHours', Value: { value: maxHours } };
    filter.filters.fullTimeStatus = { Name: 'fullTimeStatus', Value: { value: fullTimeStatus } };
    filter.filters.includeInactive = { Name: 'includeInactive', Value: { value: includeInactive } };

    return this.http.post<PagingResultsModel<FullTimeStatusCheckModel>>(`${environment.apiServer.webApiURL}/Employee/FullTimeStatusQueue`,
      filter)
  }

  verifyCheck(check: FullTimeStatusCheckModel ): Observable<any> {
    return this.http.put<FullTimeStatusCheckModel>(`${environment.apiServer.webApiURL}/Employee/VerifyCheck/${check.fullTimeStatusCheckId}`, check);
  }

  switchStatus(check: FullTimeStatusCheckModel): Observable<any> {
    return this.http.post<FullTimeStatusCheckModel>(`${environment.apiServer.webApiURL}/Employee/SwitchStatus/${check.fullTimeStatusCheckId}`, check);
  }

  markAsExempt(check: FullTimeStatusCheckModel): Observable<any> {
    return this.http.post<FullTimeStatusCheckModel>(`${environment.apiServer.webApiURL}/Employee/MarkAsExempt/${check.fullTimeStatusCheckId}`, check);
  }
  
  getEmployeeTerminationList(filter: any) : Observable<PagingResultsModel<TerminatedEmployeeModel>> {
    if (!filter) {
      filter = {};
      filter.filters = {};
    }
    filter = deepClone(filter);
   
    return this.http.post<PagingResultsModel<TerminatedEmployeeModel>>(`${environment.apiServer.webApiURL}/Employee/EmployeeTerminationList`,
      filter)
  }

  getEmployeeCensusList(filter: any): Observable<PagingResultsModel<EmployeeCensusModel>> {
    if (!filter) {
      filter = {};
      filter.filters = {};
    }
    filter = deepClone(filter);

    return this.http.post<PagingResultsModel<EmployeeCensusModel>>(`${environment.apiServer.webApiURL}/Employee/EmployeeCensusList`,
      filter)
  }

  exportEmployeeTerminationListToExcel(filter: any): Observable<ArrayBuffer> {
    return this.http.post<ArrayBuffer>(`${environment.apiServer.webApiURL}/Employee/ExportTerminationListToExcel`, filter, { responseType: 'arraybuffer' as 'json' });
  }

  exportEmployeeCensusListToExcel(filter: any): Observable<ArrayBuffer> {
    return this.http.post<ArrayBuffer>(`${environment.apiServer.webApiURL}/Employee/ExportCensusListToExcel`, filter, { responseType: 'arraybuffer' as 'json' });
  }

  exportEmployeeFullTimeStatusListToExcel(filter: any): Observable<ArrayBuffer> {
    return this.http.post<ArrayBuffer>(`${environment.apiServer.webApiURL}/Employee/ExportFullTimeStatusListToExcel`, filter, { responseType: 'arraybuffer' as 'json' });
  }

  rehireEmployee(employee: EmployeeModel): Observable<any> {
    return this.http.put<EmployeeModel>(`${environment.apiServer.webApiURL}/Employee/RehireEmployee/${employee.id}`, employee);
  }

  willNotRehireEmployee(employee: EmployeeModel): Observable<any> {
    return this.http.put<EmployeeModel>(`${environment.apiServer.webApiURL}/Employee/WillNotRehireEmployee/${employee.id}`, employee);
  }

  getTerminationReasons(): Observable<SelectItem[]> {
    return this.http.get<SelectItem[]>(`${environment.apiServer.webApiURL}/Employee/TerminationReasons`);
  }
  
  getSeparationSubReasons(): Observable<ModifiedSelectItem[]> {
    return this.http.get<ModifiedSelectItem[]>(`${environment.apiServer.webApiURL}/Employee/SeparationSubReasons`);
  }

  saveTerminationReason(employee: TerminatedEmployeeModel): Observable<any> {
    return this.http.put<TerminatedEmployeeModel>(`${environment.apiServer.webApiURL}/Employee/TerminateEmployee/${employee.userId}`, employee);
  }
}
