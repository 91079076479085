import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { State } from '..';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { errorHappened } from '../profile/profile.actions';
import {
  loadManageSelfcareCategories,
  loadManageSelfcareCategoriesFailure,
  loadManageSelfcareCategoriesSuccess,
  loadManageSelfcareCategory,
  loadManageSelfcareCategoryFailure,
  loadManageSelfcareCategorySuccess,
  loadManageSelfcarePlanner,
  loadManageSelfcarePlannerFailure,
  loadManageSelfcarePlannerSection,
  loadManageSelfcarePlannerSectionFailure,
  loadManageSelfcarePlannerSectionSuccess,
  loadManageSelfcarePlannerSuccess,
  saveManageSelfcareCategory,
  saveManageSelfcareCategoryFailure,
  saveManageSelfcareCategorySuccess,
  saveManageSelfcarePlannerSection,
  saveManageSelfcarePlannerSectionFailure,
  saveManageSelfcarePlannerSectionSuccess
} from "./manage-selfcare.actions";
import { ManageSelfcareService } from "../../services/manage-selfcare.service";

@Injectable()
export class ManageSelfcareEffects {
  constructor(
    private actions$: Actions,
    private manageSelfcareService: ManageSelfcareService,
    private store: Store<State>) { }

  loadSelfcarePlanner = createEffect(() => this.actions$.pipe(
    ofType(loadManageSelfcarePlanner),
    switchMap(action => {
      return this.manageSelfcareService.getPlannerSections().pipe(
        map(plannerSections => loadManageSelfcarePlannerSuccess({ plannerSections })),
        catchError(err => {
          this.store.dispatch(loadManageSelfcarePlannerFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));


  loadSelfcareCategories = createEffect(() => this.actions$.pipe(
    ofType(loadManageSelfcareCategories),
    switchMap(action => {
      return this.manageSelfcareService.getCategories().pipe(
        map(categories => loadManageSelfcareCategoriesSuccess({ categories })),
        catchError(err => {
          this.store.dispatch(loadManageSelfcareCategoriesFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));


  loadSelfcareCategory = createEffect(() => this.actions$.pipe(
    ofType(loadManageSelfcareCategory),
    switchMap(action => {
      return this.manageSelfcareService.getCategory(action.categoryID).pipe(
        map(category => loadManageSelfcareCategorySuccess({ category })),
        catchError(err => {
          this.store.dispatch(loadManageSelfcareCategoryFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));


  saveManageSelfcareCategory = createEffect(() => this.actions$.pipe(
    ofType(saveManageSelfcareCategory),
    switchMap(action => {
      return this.manageSelfcareService.putSelfcareCategory(action.category).pipe(
        map(result => saveManageSelfcareCategorySuccess()),
        catchError(err => {
          this.store.dispatch(saveManageSelfcareCategoryFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));


  loadManageSelfcarePlannerSection = createEffect(() => this.actions$.pipe(
    ofType(loadManageSelfcarePlannerSection),
    switchMap(action => {
      return this.manageSelfcareService.getPlannerSection(action.plannerSectionID).pipe(
        map(plannerSection => loadManageSelfcarePlannerSectionSuccess({ plannerSection })),
        catchError(err => {
          this.store.dispatch(loadManageSelfcarePlannerSectionFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));


  saveManageSelfcarePlannerSection = createEffect(() => this.actions$.pipe(
    ofType(saveManageSelfcarePlannerSection),
    switchMap(action => {
      return this.manageSelfcareService.putSelfcarePlannerSection(action.plannerSection).pipe(
        map(result => saveManageSelfcarePlannerSectionSuccess()),
        catchError(err => {
          this.store.dispatch(saveManageSelfcarePlannerSectionFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

}
