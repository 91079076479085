<div class="card mb-2">
  <div class="card-body">
    <i *ngIf="!fbForm || loadingForm" class="material-icons rotate text-muted">loop</i>
    <div *ngIf="fbForm">

      <button class="btn btn-secondary float-right pointer" routerLink="/form-builder/forms">Back</button>
      <h2 class="">{{fbForm.id?'Edit':'Create new'}} Form <span *ngIf="fbForm.formLanguages && fbForm.formLanguages.length" [class]="' fi fis fi-'+(selectedFormLanguage ? selectedFormLanguage.languageCode : 'us' )"></span></h2>
      <div class="row">
        <div class="col-12 col-lg-6">
          <form class="" [formGroup]="form" *ngIf="form && fbForm">
            <div class="row">
              <div class="col-4 d-flex align-items-center">
                <b>Form Name <span *ngIf="selectedFormLanguage" [class]="' fi fis fi-'+(selectedFormLanguage.languageCode)"></span></b>
              </div>
              <div class="col-8 ">
                <input *ngIf="selectedFormLanguage" id="nameInput" type="text" class="form-control mt-2" formControlName="languageName"  />
                <input  *ngIf="!selectedFormLanguage" id="nameInput" type="text" class="form-control mt-2" formControlName="name" />
                <app-validation-message [form]="form" [path]="'name'" [name]="'name'" [label]="'Form Name'"> </app-validation-message>
              </div>
              <div class="col-4 d-flex align-items-center">
                <b>Form Type</b>
              </div>
              <div class="col-8">
                <i *ngIf="!formTypeOptions" class="material-icons rotate text-muted">loop</i>
                <p-dropdown [disabled]="selectedFormLanguage" styleClass="form-control mt-2 w-100" [options]="formTypeOptions" placeholder="Select type..." formControlName="formTypeID"></p-dropdown>
                <p *ngIf="currentFormTypeID == 17" class="text-warning">In order to implement this notice, please make sure you visit the widget manager after saving, to determine when this notice should be sent.</p>
              </div>
              <div class="col-4 d-flex align-items-center">
                <b></b>
              </div>
              <div  class="col-8 ">
                <p-selectButton [disabled]="selectedFormLanguage" id="ClientOrEmployeeInput" styleClass="mt-2" [options]="clientOrEmployeeOptions" formControlName="clientOrEmployee" (onChange)="checkClientOrEmployeeChange()" ></p-selectButton>
              </div>
              <div class="col-12 d-flex align-items-center" *ngIf="form.get('formTypeID').value != 4">
                <b><a href="javascript:void(0)" *ngIf="!selectedFormLanguage" (click)="openAdvancedSettings=!openAdvancedSettings;">{{openAdvancedSettings?'Hide':'See'}} advanced settings</a></b>
              </div>
              <ng-container *ngIf="openAdvancedSettings">
                <div class="col-4 d-flex align-items-center">
                  <b>Description</b>
                </div>
                <div class="col-8 ">
                  <textarea id="descriptionTextarea" type="text" class="form-control mt-2" formControlName="description"></textarea>
                  <app-validation-message [form]="form" [path]="'description'" [name]="'description'" [label]="'Description'"> </app-validation-message>
                </div>                
                <div class="col-4 d-flex align-items-center">
                  <b>Ignore required signature</b>
                </div>
                <div class="col-8 ">
                  <p-selectButton id="NoSignatureRequiredInput" styleClass="mt-2" [options]="yesNoOptions" formControlName="noSignatureRequired"></p-selectButton>
                  <app-validation-message [form]="form" [path]="'noSignatureRequired'" [name]="'noSignatureRequired'" [label]="'Ignore required signature'"> </app-validation-message>
                </div>
                <div class="col-4 d-flex align-items-center">
                  <b>Can opt out</b>
                </div>
                <div class="col-8 ">
                  <p-selectButton id="CanOptOutInput" styleClass="mt-2" [options]="yesNoOptions" formControlName="canOptOut"></p-selectButton>
                  <app-validation-message [form]="form" [path]="'canOptOut'" [name]="'canOptOut'" [label]="'Can Opt Out'"> </app-validation-message>
                </div>
                <ng-container *ngIf="form.get('formTypeID').value != 27 && form.get('formTypeID').value != 17 && form.get('formTypeID').value != 7 && form.get('formTypeID').value != 5">
                  <div class="col-4 d-flex align-items-center">
                    <b>Assign as default</b>
                  </div>
                  <div class="col-8 ">
                    <p-selectButton id="ShouldAssignAsDefaultInput" styleClass="mt-2" [options]="yesNoOptions" formControlName="shouldAssignAsDefault"></p-selectButton>
                    <app-validation-message [form]="form" [path]="'shouldAssignAsDefault'" [name]="'shouldAssignAsDefault'" [label]="'Assign as default'"> </app-validation-message>
                  </div>
                  <div class="col-4 d-flex align-items-center">
                    <b>Required as Default</b>
                  </div>
                  <div class="col-8 ">
                    <p-selectButton id="RequiredInput" styleClass="mt-2" [options]="yesNoOptions" formControlName="required"></p-selectButton>
                    <app-validation-message [form]="form" [path]="'required'" [name]="'required'" [label]="'Required'"> </app-validation-message>
                  </div>
                  <div class="col-4 d-flex align-items-center">
                    <b>Re-assign after</b>
                  </div>
                  <div class="col-8 d-flex align-items-center">
                    <input id="ReAssignAfterDaysInput" type="number" class="form-control mt-2 mr-2" formControlName="reAssignAfterDays" /> days
                    <app-validation-message [form]="form" [path]="'reAssignAfterDays'" [name]="'reAssignAfterDays'" [label]="'Re-assign After'"> </app-validation-message>
                  </div>
                </ng-container>

                <ng-container *ngIf="form.get('formTypeID').value == 5">
                  <div class="col-4 d-flex align-items-center">
                    <b>Job Titles</b>
                  </div>
                  <div class="col-8">
                    <p-multiSelect autofocusFilter="true" itemSize="30" class="w-100" styleClass="w-100 d-flex  mt-2" inputStyleClass="form-control"
                                   formControlName="jobTitleIDs"
                                   [options]="jobTitleOptions" placeholder="Select Job Titles..."></p-multiSelect>
                    <ng-container>
                      <small>Other job positions (one per line):</small>
                      <textarea id="otherJobPositionsInput" class="form-control mt-2 mr-2" formControlName="otherJobPositions"></textarea>
                    </ng-container>
                  </div>
                </ng-container>

                <ng-container *ngIf="form.get('formTypeID').value == 27">
                  <div class="col-4 d-flex align-items-center">
                    <b>Insurance Payors</b>
                  </div>
                  <div class="col-8">
                    <p-multiSelect autofocusFilter="true" itemSize="30" class="w-100" styleClass="w-100 d-flex mt-2" inputStyleClass="form-control"
                                   formControlName="insuranceProviderIDs"
                                   [options]="insurancePayorsOptions" placeholder="Select Insurance Payors..."></p-multiSelect>
                  </div>
                </ng-container>


                <ng-container *ngIf="form.get('formTypeID').value != 27 && form.get('formTypeID').value != 17 && form.get('formTypeID').value != 7">
                  <div class="col-4 d-flex align-items-center">
                    <b>Due in </b>
                  </div>
                  <div class="col-8 d-flex align-items-center">
                    <input id="dueinDaysInput" type="number" class="form-control mt-2 mr-2" formControlName="dueInDays" /> days
                    <app-validation-message [form]="form" [path]="'dueInDays'" [name]="'dueInDays'" [label]="'Due in Days'"> </app-validation-message>
                  </div>
                </ng-container>
                <ng-container *ngIf="form.get('formTypeID').value == 17 || form.get('formTypeID').value == 19 || form.get('formTypeID').value == 21 || form.get('formTypeID').value == 22 || form.get('formTypeID').value == 23">
                  <div class="col-4 d-flex align-items-center">
                    <b>Show Signature Field</b>
                  </div>
                  <div class="col-8 ">
                    <p-selectButton id="showSignatureInput" styleClass="mt-2" [options]="showSignatureOptions" formControlName="showSignature"></p-selectButton>
                  </div>
                </ng-container>
                <ng-container *ngIf="form.get('formTypeID').value != 27 && (form.get('formTypeID').value == 1 || form.get('clientOrEmployee').value == 'Employee' )">
                  <div class="col-4 d-flex align-items-center">
                    <b>Require HR Signature</b>
                  </div>
                  <div class="col-8 ">
                    <p-selectButton id="RequireHRSignatureInput" styleClass="mt-2" [options]="yesNoOptions" formControlName="requireHRSignature"></p-selectButton>
                    <app-validation-message [form]="form" [path]="'requireHRSignature'" [name]="'requireHRSignature'" [label]="'Require HR Signature'"> </app-validation-message>
                  </div>
                  <div class="col-4 d-flex align-items-center">
                    <b>Is I9 Form</b>
                  </div>
                  <div class="col-8 ">
                    <p-selectButton id="IsI9Input" styleClass="mt-2" [options]="yesNoOptions" formControlName="isI9"></p-selectButton>
                    <app-validation-message [form]="form" [path]="'isI9'" [name]="'isI9'" [label]="'Is I9 Form'"> </app-validation-message>
                  </div>
                  <div class="col-4 d-flex align-items-center">
                    <b>Is intern doc</b>
                  </div>
                  <div class="col-8 ">
                    <p-selectButton id="IsInternFormInput" styleClass="mt-2" [options]="yesNoOptions" formControlName="isInternForm"></p-selectButton>
                    <app-validation-message [form]="form" [path]="'isInternForm'" [name]="'isInternForm'" [label]="'Is intern doc'"> </app-validation-message>
                  </div>
                  <div class="col-4 d-flex align-items-center">
                    <b>Is credential doc</b>
                  </div>
                  <div class="col-8 ">
                    <p-selectButton id="IsCredentialDocInput" styleClass="mt-2" [options]="yesNoOptions" formControlName="isCredentialDoc"></p-selectButton>
                    <app-validation-message [form]="form" [path]="'isCredentialDoc'" [name]="'isCredentialDoc'" [label]="'Is credential doc'"> </app-validation-message>
                  </div>
                  <ng-container *ngIf="!form.get('shouldAssignAsDefault').value && !!form.get('isCredentialDoc').value">
                    <ng-container *ngIf="!form.get('assignAfterNPI').value">
                      <div class="col-4 d-flex align-items-center">
                        <b>Assign after orientation</b>
                      </div>
                      <div class="col-8 ">
                        <p-selectButton id="AssignAfterOrientationInput" styleClass="mt-2" [options]="yesNoOptions" formControlName="assignAfterOrientation"></p-selectButton>
                        <app-validation-message [form]="form" [path]="'assignAfterOrientation'" [name]="'assignAfterOrientation'" [label]="'Assign After Orientation'"> </app-validation-message>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="!form.get('assignAfterOrientation').value">
                      <div class="col-4 d-flex align-items-center">
                        <b>Assign after NPI</b>
                      </div>
                      <div class="col-8 ">
                        <p-selectButton id="AssignAfterNPIInput" styleClass="mt-2" [options]="yesNoOptions" formControlName="assignAfterNPI"></p-selectButton>
                        <app-validation-message [form]="form" [path]="'assignAfterNPI'" [name]="'assignAfterNPI'" [label]="'Assign After NPI'"> </app-validation-message>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="form.get('formTypeID').value != 27 && (form.get('formTypeID').value == 2 || form.get('clientOrEmployee').value == 'Client' )">
                  <div class="col-4 d-flex align-items-center">
                    <b>Show on client forms report</b>
                  </div>
                  <div class="col-8 ">
                    <p-selectButton id="ShowOnClientFormsReportInput" styleClass="mt-2" [options]="yesNoOptions" formControlName="showOnClientFormsReport"></p-selectButton>
                    <app-validation-message [form]="form" [path]="'showOnClientFormsReport'" [name]="'showOnClientFormsReport'" [label]="'Show on client forms report'"> </app-validation-message>
                  </div>
                  <div class="col-4 d-flex align-items-center">
                    <b>Assign for Authorization on categories (IDs separeted by |)</b>
                  </div>
                  <div class="col-8 ">
                    <input id="requiredForAuthorizationCategoryIDsInput" type="text" class="form-control mt-2" formControlName="requiredForAuthorizationCategoryIDs" />
                    <app-validation-message [form]="form" [path]="'requiredForAuthorizationCategoryIDs'" [name]="'requiredForAuthorizationCategoryIDs'" [label]="'Required for Authorization on categories'"> </app-validation-message>
                  </div>
                  <div class="col-4 d-flex align-items-center">
                    <b>Is For Adult</b>
                  </div>
                  <div class="col-8 ">
                    <p-selectButton id="IsForAdultInput" styleClass="mt-2" [options]="yesNoOptions" formControlName="isForAdult"></p-selectButton>
                    <app-validation-message [form]="form" [path]="'isForAdult'" [name]="'isForAdult'" [label]="'Is For Adult'"> </app-validation-message>
                  </div>
                  <div class="col-4 d-flex align-items-center">
                    <b>Is For Child</b>
                  </div>
                  <div class="col-8 ">
                    <p-selectButton id="IsForChildInput" styleClass="mt-2" [options]="yesNoOptions" formControlName="isForChild"></p-selectButton>
                    <app-validation-message [form]="form" [path]="'isForChild'" [name]="'isForChild'" [label]="'Is For Child'"> </app-validation-message>
                  </div>
                  <div class="col-4 d-flex align-items-center">
                    <b>Is For Normal Client</b>
                  </div>
                  <div class="col-8 ">
                    <p-selectButton id="IsForNormalClientInput" styleClass="mt-2" [options]="yesNoOptions" formControlName="isForNormalClient"></p-selectButton>
                    <app-validation-message [form]="form" [path]="'isForNormalClient'" [name]="'isForNormalClient'" [label]="'Is For Normal Client'"> </app-validation-message>
                  </div>
                  <div class="col-4 d-flex align-items-center">
                    <b>Is For Employee Client</b>
                  </div>
                  <div class="col-8 ">
                    <p-selectButton id="IsForEmployeeClientInput" styleClass="mt-2" [options]="yesNoOptions" formControlName="isForEmployeeClient"></p-selectButton>
                    <app-validation-message [form]="form" [path]="'isForEmployeeClient'" [name]="'isForEmployeeClient'" [label]="'Is For Employee Client'"> </app-validation-message>
                  </div>
                  <div class="col-4 d-flex align-items-center">
                    <b>Never show on client portal</b>
                  </div>
                  <div class="col-8 ">
                    <p-selectButton id="NeverShowOnClientPortalInput" styleClass="mt-2" [options]="yesNoOptions" formControlName="neverShowOnClientPortal"></p-selectButton>
                    <app-validation-message [form]="form" [path]="'neverShowOnClientPortal'" [name]="'neverShowOnClientPortal'" [label]="'Never Show On Client Portal'"> </app-validation-message>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </form>

          <h4 class="mt-3" >
            Fields <i class="fas fa-plus-circle pointer"
                    (click)="addField()" *ngIf="!selectedFormLanguage"></i>
          </h4>
          <div class="fields-panel mt-3">
            <div class="row mb-3 " *ngIf="fbForm.formFields">
              <ng-container *ngFor="let field of fbForm.formFields; index as i;trackBy: trackByFn">
                <div [id]="'DivField'+field.localID" class="mt-3" [class.col-6]="field.fieldWidthRatio==2"
                    [class.col-4]="field.fieldWidthRatio==3"
                    [class.col-3]="field.fieldWidthRatio==4"
                    [class.col-2]="field.fieldWidthRatio==5 || field.fieldWidthRatio==6"
                    [class.col-1]="field.fieldWidthRatio==12"
                    [class.col-12]="!field.fieldWidthRatio || field.fieldWidthRatio==1">
                  <div class="float-right">
                    <span class="pointer mr-1 ml-1" *ngIf="i>0 && !selectedFormLanguage" (click)="changeOrderUp(field)"><i class="fas fa-arrow-up"></i></span>
                    <span class="pointer mr-1 ml-1" *ngIf="i<fbForm.formFields.length-1 && !selectedFormLanguage" (click)="changeOrderDown(field)"><i class="fas fa-arrow-down"></i></span>
                    <span class="pointer mr-1 ml-1" (click)="openFieldSettings(field,opFieldSettings,$event)"><i class="fas fa-cog"></i></span>
                  </div>
                  <div class="">
                  <div class="text-danger"><small>{{field.sectionName}}</small></div>
                  <div>{{field.fieldName}}</div>
                  </div>
                  <div class="float-right text-primary" *ngIf="field.formFieldGroupID" title="Group"><small>{{getFieldGroupName(field.formFieldGroupID)}}</small></div>
                  <hr>
                  <input disabled [placeholder]="getFieldTypeLabel(field)" class="form-control w-100" />
                </div>
              </ng-container>
            </div>
          </div>

          <h4 class="mt-3" >
            Field Groups <i class="fas fa-plus-circle pointer"
                    (click)="addFieldGroup()" *ngIf="!selectedFormLanguage"></i>
          </h4>
          <small>Field Groups will divide your form into separate parts to help better organize the user interface and help the user filling it. If you add any Field Group any field you have will need to be edited to belong to a group, otherwise it will not be shown.</small>


          <div class="fields-panel mt-3">
            <div class="mb-3 " *ngIf="fbForm.formFieldGroups">
              <ng-container *ngFor="let fieldGroup of fbForm.formFieldGroups; index as i;trackBy: trackByFn">
                <div class="col-12"  >
                  <div class="float-right">
                    <span class="pointer mr-1 ml-1" *ngIf="i>0 && !selectedFormLanguage" (click)="changeGroupOrderUp(fieldGroup)"><i class="fas fa-arrow-up"></i></span>
                    <span class="pointer mr-1 ml-1" *ngIf="i<fbForm.formFieldGroups.length-1 && !selectedFormLanguage" (click)="changeGroupOrderDown(fieldGroup)"><i class="fas fa-arrow-down"></i></span>
                    <span class="pointer mr-1 ml-1" *ngIf="!selectedFormLanguage" (click)="deleteFieldGroup(fieldGroup)"><i class="fas fa-times-circle"></i></span>
                    </div>
                    <div *ngIf="selectedFormLanguage" class="d-flex">
                      <span  [class]="'mr-3 fi fis fi-'+(selectedFormLanguage.languageCode)"></span>
                      <input [ngModel]="getFieldGroupLanguage(fieldGroup).groupName" class="form-control w-100" (ngModelChange)="editFieldGroupLanguage($event,getFieldGroupLanguage(fieldGroup),fieldGroup)"/>
                    </div>
                    <div *ngIf="selectedFormLanguage"><small><span class="mr-3 fi fis fi-us"></span> {{fieldGroup.groupName}}</small></div>

                    <input *ngIf="!selectedFormLanguage" [(ngModel)]="fieldGroup.groupName" class="form-control w-100" (ngModelChange)="editFieldGroup(fieldGroup)"/>
                    <hr>
                </div>
              </ng-container>
            </div>
          </div>
          <h4 class="mt-3" >
            Form Languages <i class="fas fa-plus-circle pointer"
                    (click)="addLanguageOpened=!addLanguageOpened" *ngIf="!selectedFormLanguage"></i>
          </h4>
          <small>Add here the languages in whcih this form will be available</small>
          <div class="w-100" *ngIf="addLanguageOpened">
              <p-dropdown styleClass="form-control mt-2 w-100" [options]="formLanguageOptions"  [showClear]="true" placeholder="Select language..." [(ngModel)]="addingLanguage" >

                <ng-template let-item pTemplate="selectedItem">
                  <div class="flex align-items-center gap-2">
                    <span [class]="'fi fi-'+item.value.languageCode"></span><span [innerHTML]="item.value.languageName"></span>
                  </div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div class="flex align-items-center gap-2">
                    <span [class]="'fi fi-'+item.value.languageCode"></span><span [innerHTML]="item.value.languageName"></span>
                  </div>
                </ng-template>
              </p-dropdown>
              <button class="btn btn-primary mt-2" (click)="addLanguage()">Add</button>
            </div>
          <div class="fields-panel mt-3">
            <div class="mb-1 " *ngIf="fbForm.formLanguages && fbForm.formLanguages.length">

              <small>Select the language here to edit language specific information</small>
              <hr>
              <div [class.bg-primary]="selectedFormLanguage==null" [class.text-light]="selectedFormLanguage==null">
                <span [class]="'fi fi-us'"></span><span [innerHTML]="'English (default)'" class="pointer" (click)="selectLanguage(null)"></span>
              </div>
              <hr>
              <ng-container *ngFor="let formLanguage of fbForm.formLanguages; index as i;trackBy: trackByFn">
                <div class="col-12" >
                  <div class="float-right">
                    <span class="pointer mr-1 ml-1"  (click)="deleteLanguage(formLanguage)"><i class="fas fa-times-circle"></i></span>
                  </div>
                  <div [class.bg-primary]="selectedFormLanguage && selectedFormLanguage.languageID==formLanguage.languageID" [class.text-light]="selectedFormLanguage && selectedFormLanguage.languageID==formLanguage.languageID">
                    <span [class]="'fi fi-'+formLanguage.languageCode"></span><span [innerHTML]="formLanguage.languageName" class="pointer" (click)="selectLanguage(formLanguage)"></span>
                  </div>
                  <hr>
                </div>
              </ng-container>
            </div>
          </div>

        </div>

        <div class="col-12 col-lg-6" *ngIf="this.fbForm.formTypeID != 5">
          <h4>Document <span *ngIf="selectedFormLanguage" [class]="'mr-2 fi fis fi-'+(selectedFormLanguage.languageCode)"></span>
            <i class="fa fa-info-circle pointer" title="See Instructions" (click)="toggleInstructions()"></i></h4>
          <small>
            You can create the document inline here or you can have the form based on a existing PDF and just add the fields you need.
          </small>
          <div>
          <p-selectButton *ngIf="!selectedFormLanguage" id="createOrPDFOptions" styleClass="mt-2" [options]="createOrPDFOptions" [(ngModel)]="hmtlOrPDF" (ngModelChange)="changedHmtlOrPDF()" ></p-selectButton>
          </div>
          <ng-container *ngIf="hmtlOrPDF=='pdf'">
            <small *ngIf="showInstructions == 'Show'">
              Please upload a PDF file to be used as a base for creating the final document.
              You can use any PDF editor tool (try this online option: <a href="https://www.grapecity.com/documents-api-pdfviewer/demos/sample/editor-features/forms-editor/edit-forms/purejs/" target="newwindow">PDF Editor</a>) to create/edit this PDF and add form fields to be replaced by its value. Use as field name #NameOfField# to have the value added to it when creating the final document.
              And you can add the special fields (#Signature#, #DateSignature#, #Initials#, #SignatureOptional#, #InitialsOptional#) for the places the user will have to sign the document.
              <span *ngIf="this.fbForm.requireHRSignature">For adding an HR signature use #Signature#HRUser# as the form field name</span>
            </small>
            <div class="row mt-2 mb-2">
              <div class="col-6">
                <ng-container *ngIf="changeFile || !this.currentPDFFile">
                  <b>Select PDF file:</b>
                  <input type="file" class="ml-3" #PostPDFFile accept=".pdf" (change)="selectedPDFFile($event)" />
                </ng-container>
                <ng-container *ngIf="!changeFile && this.currentPDFFile">
                  <b><a href="javascript:void(0)" (click)="downloadCurrentPDF()"> Download current PDF file</a></b>  <br>
                  <b>Change PDF file:</b><br>
                  <button class="btn btn-primary" id="ChangePDFFileButton" (click)="changeFile=true;">Change PDF File</button>
                </ng-container>
              </div>

            </div>
            <div id="pdf-viewer" style="width: 100%; height: 65vh"  *ngIf="false">
            </div>
          </ng-container>
          <ng-container *ngIf="hmtlOrPDF=='html'">
          <div *ngIf="showInstructions == 'Show'" class="w-100 mt-0 mb-2" [innerHTML]="editorDescription"></div>

          <div class="d-flex w-100 justify-content-end">
            <button (click)="changeSeeAsHTML()">See as HTML</button>
          </div>
          <ng-container *ngIf="selectedFormLanguage">
            <textarea class="codeHTLMText" *ngIf="seeAsHTML" [(ngModel)]="selectedFormLanguage.baseDocHTML" (ngModelChange)="changedBaseDocHTML(selectedFormLanguage)"></textarea>
            <div *ngIf="!seeAsHTML"  id="divDocumentEditor" class="document-editor">
              <div id="divDocumentEditorEditableContainter" class="document-editor__editable-container">

              </div>
            </div>
            <ckeditor *ngIf="!seeAsHTML && editorConfig && fbForm" [editor]="initialEditor" [config]="editorConfig" [data]="selectedFormLanguage.baseDocHTML" (change)="changedEditorData($event,selectedFormLanguage)" (ready)="onReady($event)"></ckeditor>
          </ng-container>
          <ng-container *ngIf="!selectedFormLanguage">
            <textarea class="codeHTLMText" *ngIf="seeAsHTML" [(ngModel)]="fbForm.baseDocHTML" (ngModelChange)="changedBaseDocHTML()"></textarea>
            <div *ngIf="!seeAsHTML"  id="divDocumentEditor" class="document-editor">
              <div id="divDocumentEditorEditableContainter" class="document-editor__editable-container">

              </div>
            </div>
            <ckeditor *ngIf="!seeAsHTML && editorConfig && fbForm" [editor]="initialEditor" [config]="editorConfig" [data]="fbForm.baseDocHTML" (change)="changedEditorData($event)" (ready)="onReady($event)"></ckeditor>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="hmtlOrPDF=='html' && (this.fbForm.formTypeID == 22 || this.fbForm.formTypeID == 24 || this.fbForm.formTypeID == 25)">
          <h4 class="mb-0 mt-3">Documentation Summary <span *ngIf="selectedFormLanguage" [class]="' fi fis fi-'+(selectedFormLanguage.languageCode)"></span></h4>
          <small>
            This is an alternative view of this form when being rendered as part of a bigger documentation file.
          </small>
            <div class="d-flex w-100 justify-content-end">
              <button (click)="changeSeeSummaryAsHTML()">See as HTML</button>
            </div>
            <ng-container *ngIf="selectedFormLanguage">
              <textarea class="codeHTLMText" *ngIf="seeSummaryAsHTML" [(ngModel)]="selectedFormLanguage.baseSummaryDocHTML" (ngModelChange)="changedBaseSummaryDocHTML(selectedFormLanguage)"></textarea>
              <div *ngIf="!seeSummaryAsHTML" id="divDocumentSummaryEditor" class="document-editor">
                <div id="divDocumentSummaryEditorEditableContainter" class="document-editor__editable-container">

                </div>
              </div>
              <ckeditor *ngIf="!seeSummaryAsHTML && editorSummaryConfig && fbForm" [editor]="initialSummaryEditor" [config]="editorSummaryConfig" [data]="selectedFormLanguage.baseSummaryDocHTML" (change)="changedSummaryEditorData($event,selectedFormLanguage)" (ready)="onSummaryReady($event)"></ckeditor>

            </ng-container>
            <ng-container *ngIf="!selectedFormLanguage">
              <textarea class="codeHTLMText" *ngIf="seeSummaryAsHTML" [(ngModel)]="fbForm.baseSummaryDocHTML" (ngModelChange)="changedBaseSummaryDocHTML()"></textarea>
              <div *ngIf="!seeSummaryAsHTML" id="divDocumentSummaryEditor" class="document-editor">
                <div id="divDocumentSummaryEditorEditableContainter" class="document-editor__editable-container">

                </div>
              </div>
              <ckeditor *ngIf="!seeSummaryAsHTML && editorSummaryConfig && fbForm" [editor]="initialSummaryEditor" [config]="editorSummaryConfig" [data]="fbForm.baseSummaryDocHTML" (change)="changedSummaryEditorData($event)" (ready)="onSummaryReady($event)"></ckeditor>
            </ng-container>
        </ng-container>
        </div>
      </div>
      <i *ngIf="savingForm" class="material-icons rotate text-muted">loop</i>
      <button class="btn btn-primary mt-3" id="saveButton" [class.disabled]="savingForm || form.invalid" (click)="save()">Save</button>
    </div>
  </div>
</div>


<p-overlayPanel #opFieldSettings [dismissable]="true" [showCloseIcon]="true" [style]="{width: '450px'}">
  <form class="" *ngIf="(fieldForm && fieldOpened) && (!selectedFormLanguage || fieldLanguageOpened)" [formGroup]="fieldForm">
    <div class="row">
      <div class="col-4 d-flex align-items-center">
        <b>Field Name</b>
      </div>
      <div class="col-8 ">
        <input  [attr.disabled]="selectedFormLanguage?true:null" id="fieldNameInput" type="text" class="form-control mt-2" formControlName="fieldName" [(ngModel)]="this.fieldOpened.fieldName" (ngModelChange)="editField(fieldOpened)" />
        <app-validation-message [form]="fieldForm" [path]="'fieldName'" [name]="'fieldName'" [label]="'Field Name'"> </app-validation-message>
      </div>
      <div class="col-4 d-flex align-items-center">
        <b>Field Description <span *ngIf="selectedFormLanguage" [class]="' fi fis fi-'+(selectedFormLanguage.languageCode)"></span></b>
      </div>
      <div class="col-8 ">
        <textarea *ngIf="selectedFormLanguage" id="fieldDescriptionInput" type="text" class="form-control mt-2" formControlName="fieldDescription" [(ngModel)]="this.fieldLanguageOpened.fieldDescription" (ngModelChange)="editField(fieldOpened)"></textarea>

        <textarea *ngIf="!selectedFormLanguage" id="fieldDescriptionInput" type="text" class="form-control mt-2" formControlName="fieldDescription" [(ngModel)]="this.fieldOpened.fieldDescription" (ngModelChange)="editField(fieldOpened)"></textarea>
        <app-validation-message [form]="fieldForm" [path]="'fieldDescription'" [name]="'fieldDescription'" [label]="'Field Description'"> </app-validation-message>
      </div>
      <div class="col-4 d-flex align-items-center">
        <b>Hide Label</b>
      </div>
      <div class="col-8 ">
        <p-selectButton  [disabled]="selectedFormLanguage" id="hideLabelInput" styleClass="mt-2" [options]="yesNoOptions" formControlName="hideLabel" [(ngModel)]="this.fieldOpened.hideLabel" (ngModelChange)="editField(fieldOpened)"></p-selectButton>
        <app-validation-message [form]="fieldForm" [path]="'hideLabel'" [name]="'hideLabel'" [label]="'Hide Label'"> </app-validation-message>
      </div>
      <div class="col-4 d-flex align-items-center">
        <b>Type</b>
      </div>
      <div class="col-8 ">
        <p-dropdown  [disabled]="selectedFormLanguage" styleClass="form-control mt-2 w-100" [options]="formFieldTypeOptions" placeholder="Select type..." formControlName="fieldTypeID" [(ngModel)]="this.fieldOpened.fieldTypeID" (ngModelChange)="editField(fieldOpened)"></p-dropdown>
        <app-validation-message [form]="fieldForm" [path]="'fieldTypeID'" [name]="'fieldTypeID'" [label]="'Field Type'"> </app-validation-message>
      </div>
      <div class="col-4 d-flex align-items-center">
        <b>Tootlip <span *ngIf="selectedFormLanguage" [class]="' fi fis fi-'+(selectedFormLanguage.languageCode)"></span></b>
      </div>
      <div class="col-8 ">
        <textarea  *ngIf="selectedFormLanguage" id="tooltipInput" type="text" class="form-control mt-2" formControlName="tooltip" [(ngModel)]="this.fieldLanguageOpened.tooltip" (ngModelChange)="editField(fieldOpened)"></textarea>
        <textarea  *ngIf="!selectedFormLanguage" id="tooltipInput" type="text" class="form-control mt-2" formControlName="tooltip" [(ngModel)]="this.fieldOpened.tooltip" (ngModelChange)="editField(fieldOpened)"></textarea>
        <app-validation-message [form]="fieldForm" [path]="'tooltip'" [name]="'tooltip'" [label]="'Field Tooltip'"> </app-validation-message>
      </div>
      <ng-container *ngIf="this.fieldOpened.formFieldType?.formFieldType == 'Sum' || this.fieldOpened.formFieldType?.formFieldType == 'Average' || this.fieldOpened.formFieldType?.formFieldType == 'Map' || this.fieldOpened.formFieldType?.formFieldType =='PercentageOfMax'">
        <div class="col-4 d-flex align-items-center">
          <b>Related Fields</b>
        </div>
        <div class="col-8">
          <p-multiSelect [disabled]="selectedFormLanguage"  autofocusFilter="true" [virtualScroll]="true" itemSize="30" class="w-100" styleClass="w-100 d-flex  mt-2" inputStyleClass="form-control"
                         formControlName="calculatedRelatedFieldIDsArray" [(ngModel)]="this.fieldOpened.calculatedRelatedFieldIDsArray" (ngModelChange)="editField(fieldOpened)"
                         [options]="numberFieldsOptions" placeholder="Select fields..."></p-multiSelect>
          <small>Save the form so the fields saved will show up here</small>
        </div>
      </ng-container>

      <ng-container *ngIf="this.fieldOpened.formFieldType?.formFieldType =='LastValue' || this.fieldOpened.formFieldType?.formFieldType =='LastValueFieldName'">
        <div class="col-4 d-flex align-items-center">
          <b>Related Fields</b>
        </div>
        <div class="col-8">
          <p-multiSelect [disabled]="selectedFormLanguage"  autofocusFilter="true" [virtualScroll]="true" itemSize="30" class="w-100" styleClass="w-100 d-flex  mt-2" inputStyleClass="form-control"
                         formControlName="calculatedRelatedFieldIDsArray" [(ngModel)]="this.fieldOpened.calculatedRelatedFieldIDsArray" (ngModelChange)="editField(fieldOpened)"
                         [options]="fieldsToSelect" placeholder="Select fields..."></p-multiSelect>
          <small>Save the form so the fields saved will show up here</small>
        </div>
      </ng-container>


      <ng-container *ngIf="this.fieldOpened.formFieldType?.formFieldType == 'Map'">
        <div class="col-4 d-flex align-items-center">
          <b>Mapping Options</b>
        </div>
        <div class="col-8 ">
          <textarea [attr.disabled]="selectedFormLanguage?true:null"  id="fieldOptionsInput" class="form-control mt-2" formControlName="fieldOptions" [(ngModel)]="this.fieldOpened.fieldOptions" (ngModelChange)="editField(fieldOpened)"></textarea>
          <small class="mb-1">Add the mapping options one at a line on this format (StartRange-EndRange|MappedValue) Example: 2.1-2.9|3</small>
          <app-validation-message [form]="fieldForm" [path]="'fieldOptions'" [name]="'fieldOptions'" [label]="'Field Options'"> </app-validation-message>
        </div>
      </ng-container>
      <ng-container *ngIf="this.fieldOpened.formFieldType?.formFieldType == 'Link Button'">
        <div class="col-4 d-flex align-items-center">
          <b>URL</b>
        </div>
        <div class="col-8 ">
          <textarea [attr.disabled]="selectedFormLanguage?true:null"  id="fieldOptionsInput" class="form-control mt-2" formControlName="fieldOptions" [(ngModel)]="this.fieldOpened.fieldOptions" (ngModelChange)="editField(fieldOpened)"></textarea>
          <app-validation-message [form]="fieldForm" [path]="'fieldOptions'" [name]="'fieldOptions'" [label]="'Field Options'"> </app-validation-message>
        </div>
      </ng-container>
      <ng-container *ngIf="this.fieldOpened.formFieldType?.formFieldType == 'Text'">
        <div class="col-4 d-flex align-items-center">
          <b>Autocomplete Option <span *ngIf="selectedFormLanguage" [class]="' fi fis fi-'+(selectedFormLanguage.languageCode)"></span></b>
        </div>
        <div class="col-8 ">
          <textarea *ngIf="selectedFormLanguage" id="fieldOptionsInput" class="form-control mt-2" formControlName="fieldOptions" [(ngModel)]="this.fieldLanguageOpened.fieldOptions" (ngModelChange)="editField(fieldOpened)"></textarea>
          <textarea *ngIf="!selectedFormLanguage" id="fieldOptionsInput" class="form-control mt-2" formControlName="fieldOptions" [(ngModel)]="this.fieldOpened.fieldOptions" (ngModelChange)="editField(fieldOpened)"></textarea>
          <small class="mb-1">Add the options one at a line</small>
          <app-validation-message [form]="fieldForm" [path]="'fieldOptions'" [name]="'fieldOptions'" [label]="'Field Options'"> </app-validation-message>
        </div>
        
      </ng-container>
      <ng-container *ngIf="this.fieldOpened.formFieldType?.formFieldType == 'One Option' || this.fieldOpened.formFieldType?.formFieldType == 'Many Options' ">
        <div class="col-4 d-flex align-items-center">
          <b>Autocomplete Options <span *ngIf="selectedFormLanguage" [class]="' fi fis fi-'+(selectedFormLanguage.languageCode)"></span></b>
        </div>
        <div class="col-8 ">
          <textarea *ngIf="selectedFormLanguage" id="fieldOptionsInput" class="form-control mt-2" formControlName="fieldOptions" [(ngModel)]="this.fieldLanguageOpened.fieldOptions" (ngModelChange)="editField(fieldOpened)"></textarea>
          <textarea *ngIf="!selectedFormLanguage" id="fieldOptionsInput" class="form-control mt-2" formControlName="fieldOptions" [(ngModel)]="this.fieldOpened.fieldOptions" (ngModelChange)="editField(fieldOpened)"></textarea>
          <small class="mb-1">Add the options one at a line</small>
          <app-validation-message [form]="fieldForm" [path]="'fieldOptions'" [name]="'fieldOptions'" [label]="'Field Options'"> </app-validation-message>
        </div>

        <div class="col-4 d-flex align-items-center">
          <b>Allow Option "Other"</b>
        </div>
        <div class="col-8 ">
          <p-selectButton [disabled]="selectedFormLanguage" id="allowOtherInput" styleClass="mt-2" [options]="yesNoOptions" formControlName="allowOther" [(ngModel)]="this.fieldOpened.allowOther" (ngModelChange)="editField(fieldOpened)"></p-selectButton>
          <app-validation-message [form]="fieldForm" [path]="'allowOther'" [name]="'allowOther'" [label]="'Allow Other'"> </app-validation-message>
        </div>
        
        <div  class="col-4 d-flex align-items-center">
          <b>Options Table</b>
        </div>
        <div  class="col-8">
          <input [attr.disabled]="selectedFormLanguage?true:null" id="OptionsTableInput" type="text" class="form-control mt-2" formControlName="optionsTable" [(ngModel)]="this.fieldOpened.optionsTable" (ngModelChange)="editField(fieldOpened)" />
        </div>
      </ng-container>
      <div class="col-4  d-flex  align-items-center">
        <b>Fillable Before Sending</b>
      </div>
      <div class="col-8 ">
        <p-selectButton id="fillableBeforeSendingInput" styleClass="mt-2" [options]="yesNoOptions" formControlName="fillableBeforeSending" [(ngModel)]="this.fieldOpened.fillableBeforeSending" (ngModelChange)="editField(fieldOpened)"></p-selectButton>
        <app-validation-message [form]="fieldForm" [path]="'fillableBeforeSending'" [name]="'fillableBeforeSending'" [label]="'FillableBefore Sending'"> </app-validation-message>
      </div>
      <div class="col-4 d-flex align-items-center">
        <b>Required</b>
      </div>
      <div class="col-8 ">
        <p-selectButton  [disabled]="selectedFormLanguage" id="isRequiredInput" styleClass="mt-2" [options]="yesNoOptions" formControlName="isRequired" [(ngModel)]="this.fieldOpened.isRequired" (ngModelChange)="editField(fieldOpened)"></p-selectButton>
        <app-validation-message [form]="fieldForm" [path]="'isRequired'" [name]="'isRequired'" [label]="'Required'"> </app-validation-message>
      </div>
      <div class="col-4 d-flex align-items-center">
        <b>Width Ratio</b>
      </div>
      <div class="col-8 ">
        <p-dropdown  [disabled]="selectedFormLanguage" styleClass="form-control mt-2 w-100" [options]="formFieldWidthRatioOptions" placeholder="Select type..." formControlName="fieldWidthRatio" [(ngModel)]="this.fieldOpened.fieldWidthRatio" (ngModelChange)="editField(fieldOpened)"></p-dropdown>
        <app-validation-message [form]="fieldForm" [path]="'fieldWidthRatio'" [name]="'fieldWidthRatio'" [label]="'Field Width Ratio'"> </app-validation-message>
      </div>
      <div class="col-4 d-flex align-items-center">
        <b>Order</b>
      </div>
      <div class="col-8 ">
        <input [attr.disabled]="selectedFormLanguage?true:null" id="fieldOrderInput" type="number" class="form-control mt-2" formControlName="fieldOrder" [(ngModel)]="this.fieldOpened.fieldOrder" (blur)="editField(fieldOpened)" />
        <app-validation-message [form]="fieldForm" [path]="'fieldOrder'" [name]="'fieldOrder'" [label]="'Field Order'"> </app-validation-message>
      </div>
      <div class="col-12 d-flex align-items-center" *ngIf="form.get('formTypeID').value != 17">
        <span class="text-primary pointer" (click)="advancedOpened=!advancedOpened">Advanced</span>
      </div>

      <ng-container *ngIf="advancedOpened">
        <div class="col-4 d-flex align-items-center">
          <b>Field Group</b>
        </div>
        <div class="col-8">
          <p-dropdown [disabled]="selectedFormLanguage" styleClass="form-control mt-2 w-100" [options]="fieldGroupsOptions" formControlName="formFieldGroupID" [(ngModel)]="this.fieldOpened.formFieldGroupID" (ngModelChange)="editField(fieldOpened)" placeholder="Select Field Group..."></p-dropdown>
        </div>

        <div class="col-4 d-flex align-items-center">
          <b>Field Section</b>
        </div>
        <div class="col-8">
          <p-dropdown [disabled]="selectedFormLanguage" styleClass="form-control mt-2 w-100" [options]="sectionsOptions" formControlName="sectionFormFieldID" [(ngModel)]="this.fieldOpened.sectionFormFieldID" (ngModelChange)="editField(fieldOpened)" placeholder="Select Section..."></p-dropdown>
        </div>
        <div *ngIf="this.fieldOpened.fieldTypeID == 8" class="col-4  d-flex  align-items-center">
          <b>Show as Dropdown</b>
        </div>
        <div *ngIf="this.fieldOpened.fieldTypeID == 8" class="col-8 ">
          <p-selectButton [disabled]="selectedFormLanguage" id="showAsDropdownInput" styleClass="mt-2" [options]="yesNoOptions" formControlName="showAsDropdown" [(ngModel)]="this.fieldOpened.showAsDropdown" (ngModelChange)="editField(fieldOpened)"></p-selectButton>
          <app-validation-message [form]="fieldForm" [path]="'showAsDropdown'" [name]="'showAsDropdown'" [label]="'Show As Dropdown'"> </app-validation-message>
        </div>
        <div *ngIf="this.fieldOpened.fieldTypeID == 1" class="col-4  d-flex  align-items-center">
          <b>Is Global Figure Counter</b>
        </div>
        <div *ngIf="this.fieldOpened.fieldTypeID == 1" class="col-8 ">
          <p-selectButton id="showAsDropdownInput" styleClass="mt-2" [options]="yesNoOptions" formControlName="showAsDropdown" [(ngModel)]="this.fieldOpened.hasGlobalCounter" (ngModelChange)="editField(fieldOpened)"></p-selectButton>
          <app-validation-message [form]="fieldForm" [path]="'hasGlobalCounter'" [name]="'hasGlobalCounter'" [label]="'Is Global Figure Counter'"> </app-validation-message>
        </div>
        <div *ngIf="this.fieldOpened.sectionFormFieldID" class="col-4  d-flex  align-items-center">
          <b>Numbered</b>
        </div>
        <div *ngIf="this.fieldOpened.sectionFormFieldID" class="col-8 ">
          <p-selectButton [disabled]="selectedFormLanguage" id="NumberedInput" styleClass="mt-2" [options]="yesNoOptions" formControlName="numbered" [(ngModel)]="this.fieldOpened.numbered" (ngModelChange)="editField(fieldOpened)"></p-selectButton>
          <app-validation-message [form]="fieldForm" [path]="'numbered'" [name]="'numbered'" [label]="'Numbered'"> </app-validation-message>
        </div>

        <div class="col-4 d-flex align-items-center">
          <b>Default Value <span *ngIf="selectedFormLanguage" [class]="' fi fis fi-'+(selectedFormLanguage.languageCode)"></span></b>
        </div>
        <div class="col-8 ">
          <textarea *ngIf="selectedFormLanguage" id="defaultTextInput" type="text" class="form-control mt-2" formControlName="defaultText" [(ngModel)]="this.fieldLanguageOpened.defaultText" (ngModelChange)="editField(fieldOpened)"></textarea>
          <textarea *ngIf="!selectedFormLanguage" id="defaultTextInput" type="text" class="form-control mt-2" formControlName="defaultText" [(ngModel)]="this.fieldOpened.defaultText" (ngModelChange)="editField(fieldOpened)"></textarea>
          <app-validation-message [form]="fieldForm" [path]="'defaultText'" [name]="'defaultText'" [label]="'Default Text'"> </app-validation-message>
        </div>
        <div class="col-4 d-flex align-items-center">
          <b>Is HR field</b>
        </div>
        <div class="col-8 ">
          <p-selectButton [disabled]="selectedFormLanguage" id="isHRFieldInput" styleClass="mt-2" [options]="yesNoOptions" formControlName="isHRField" [(ngModel)]="this.fieldOpened.isHRField" (ngModelChange)="editField(fieldOpened)"></p-selectButton>
          <app-validation-message [form]="fieldForm" [path]="'isHRField'" [name]="'isHRField'" [label]="'HR Field'"> </app-validation-message>
        </div>
        <div class="col-4 d-flex align-items-center">
          <b>Encript</b>
        </div>
        <div class="col-8 ">
          <p-selectButton [disabled]="selectedFormLanguage" id="encriptInput" styleClass="mt-2" [options]="yesNoOptions" formControlName="isEncrypted" [(ngModel)]="this.fieldOpened.isEncrypted" (ngModelChange)="editField(fieldOpened)"></p-selectButton>
          <app-validation-message [form]="fieldForm" [path]="'isEncrypted'" [name]="'isEncrypted'" [label]="'Encrypt'"> </app-validation-message>
        </div>
        <div class="col-4 d-flex align-items-center">
          <b>Get from DB</b>
        </div>
        <div class="col-8">
          <p-dropdown [disabled]="selectedFormLanguage" styleClass="form-control mt-2 w-100" [options]="mappingColumnsFiltered" formControlName="mappingColumnGet" [(ngModel)]="this.fieldOpened.mappingColumnGet" (ngModelChange)="editField(fieldOpened)" placeholder="Select DB Column..."></p-dropdown>
        </div>

        <div *ngIf="this.fieldOpened.mappingColumnGet" class="col-4  d-flex  align-items-center">
          <b>Read only</b>
        </div>
        <div *ngIf="this.fieldOpened.mappingColumnGet" class="col-8 ">
          <p-selectButton [disabled]="selectedFormLanguage" id="readOnlyInput" styleClass="mt-2" [options]="yesNoOptions" formControlName="readOnly" [(ngModel)]="this.fieldOpened.readOnly" (ngModelChange)="editField(fieldOpened)"></p-selectButton>
          <app-validation-message [form]="fieldForm" [path]="'readOnly'" [name]="'readOnly'" [label]="'Read only'"> </app-validation-message>
        </div>
        <div class="col-4 d-flex align-items-center">
          <b>Save to DB</b>
        </div>
        <div class="col-8">
          <p-multiSelect [disabled]="selectedFormLanguage" autofocusFilter="true" [virtualScroll]="true" itemSize="30" class="w-100" styleClass="w-100 d-flex  mt-2" inputStyleClass="form-control"
                         formControlName="mappingColumnsSet" [(ngModel)]="this.fieldOpened.mappingColumnsSet" (ngModelChange)="editField(fieldOpened)"
                         [options]="mappingColumnsFiltered" placeholder="Select DB Columns..."></p-multiSelect>
        </div>

        <div class="col-4 d-flex align-items-center">
          <b>Related Field</b>
        </div>
        <div class="col-8">
          <p-dropdown [disabled]="selectedFormLanguage" styleClass="form-control mt-2 w-100" [options]="fieldsToSelect" formControlName="relatedFormFieldID" [(ngModel)]="this.fieldOpened.relatedFormFieldID" (ngModelChange)="editField(fieldOpened)" placeholder="Select Field..."></p-dropdown>
        </div>
        <div class="col-4 d-flex align-items-center">
          <b>Show if Related Field is</b>
        </div>
        <div class="col-8">
          <input [attr.disabled]="selectedFormLanguage?true:null" id="ShowIfRelatedAnswerIs" type="text" class="form-control mt-2" formControlName="showIfRelatedAnswerIs" [(ngModel)]="this.fieldOpened.showIfRelatedAnswerIs" (ngModelChange)="editField(fieldOpened)" />
        </div>

        <div class="col-4 d-flex align-items-center">
          <b>Required Related Field</b>
        </div>
        <div class="col-8">
          <p-dropdown [disabled]="selectedFormLanguage" styleClass="form-control mt-2 w-100" [options]="fieldsToSelect" formControlName="requiredIfFieldID" [(ngModel)]="this.fieldOpened.requiredIfFieldID" (ngModelChange)="editField(fieldOpened)" placeholder="Select Field..."></p-dropdown>
        </div>
        <div class="col-4 d-flex align-items-center">
          <b>Required if is</b>
        </div>
        <div class="col-8">
          <input [attr.disabled]="selectedFormLanguage?true:null" id="RequiredIfFieldAnswerIs" type="text" class="form-control mt-2" formControlName="requiredIfFieldAnswerIs" [(ngModel)]="this.fieldOpened.requiredIfFieldAnswerIs" (ngModelChange)="editField(fieldOpened)" />
        </div>
        <ng-container *ngIf="">
          <div class="col-4 d-flex align-items-center">
            <b>Min Value</b>
          </div>
          <div class="col-8">
            <input [attr.disabled]="selectedFormLanguage?true:null" id="MinValue" type="number" class="form-control mt-2" formControlName="minValue" [(ngModel)]="this.fieldOpened.minValue" (ngModelChange)="editField(fieldOpened)" />
          </div>
          <div class="col-4 d-flex align-items-center">
            <b>Max Value</b>
          </div>
          <div class="col-8">
            <input [attr.disabled]="selectedFormLanguage?true:null" id="MaxValue" type="number" class="form-control mt-2" formControlName="maxValue" [(ngModel)]="this.fieldOpened.maxValue" (ngModelChange)="editField(fieldOpened)" />
          </div>
        </ng-container>
        <ng-container *ngIf="this.fieldOpened.fieldTypeID == 1 || this.fieldOpened.fieldTypeID == 2">
          <div class="col-4 d-flex align-items-center">
            <b>Min Length</b>
          </div>
          <div class="col-8">
            <input [attr.disabled]="selectedFormLanguage?true:null" id="MinLength" type="number" class="form-control mt-2" formControlName="minLength" [(ngModel)]="this.fieldOpened.minLength" (ngModelChange)="editField(fieldOpened)" />
          </div>
          <div class="col-4 d-flex align-items-center">
            <b>Max Length</b>
          </div>
          <div class="col-8">
            <input [attr.disabled]="selectedFormLanguage?true:null" id="FieldSize" type="number" class="form-control mt-2" formControlName="fieldSize" [(ngModel)]="this.fieldOpened.fieldSize" (ngModelChange)="editField(fieldOpened)" />
          </div>
        </ng-container>
      </ng-container>
    </div>
    <div class="d-flex align-items-center mt-2">
      <button class="btn btn-primary mr-2" id="removeFieldButton" (click)="closeField($event, opFieldSettings)">Done </button>
      <button class="btn btn-danger " id="removeFieldButton" (click)="removeField(fieldOpened)">Remove </button>
    </div>
  </form>
</p-overlayPanel>
