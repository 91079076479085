import { ProspectiveCandidateContactLogModel, ProspectiveCandidateReferenceModel } from 'src/app/models/prospectiveCandidateModel';
import { ProspectiveCandidateModel } from './../../models/prospectiveCandidateModel';
import { PagingResultsModel } from './../../models/pagingResultsModel';
import { ResumeURLModel } from './../../models/resumeURLModel';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable()
export class ProspectiveCandidatesService {

  constructor(private http: HttpClient) {
  }

  getProspectiveCandidateTable(filters: any): Observable<PagingResultsModel<ProspectiveCandidateModel>> {
    return this.http.post<PagingResultsModel<ProspectiveCandidateModel>>
      (`${environment.apiServer.webApiURL}/ProspectiveCandidate/Datatable`, filters);
  }

  newProspectiveCandidate(prospectiveCandidate: ProspectiveCandidateModel, file: File) {
    if (file) {
      return this.saveProspectiveCandidateWithFile(prospectiveCandidate, file);
    } else {
      return this.http.post<ProspectiveCandidateModel>(`${environment.apiServer.webApiURL}/ProspectiveCandidate`,
        prospectiveCandidate);
    }
  }

  addProspectiveContactLog(log: ProspectiveCandidateContactLogModel) {
      return this.http.post<ProspectiveCandidateContactLogModel>(`${environment.apiServer.webApiURL}/ProspectiveCandidate/AddContactLog`, log);
  }

  editProspectiveContactLog(log: ProspectiveCandidateContactLogModel) {
    return this.http.post<ProspectiveCandidateContactLogModel>(`${environment.apiServer.webApiURL}/ProspectiveCandidate/EditContactLog`, log);
}

  saveProspectiveCandidateWithFile(prospectiveCandidate: ProspectiveCandidateModel, file: File) {
    const formData = new FormData();

    formData.append('files.Index', '0');
    formData.append('files[0].File', file);

    for (const key in prospectiveCandidate) {
      if (prospectiveCandidate[key] && key !== 'references' && key !== 'contactLogs') {
        formData.append('files[0].' + key, prospectiveCandidate[key]);
      }
    }

    if (prospectiveCandidate.references && prospectiveCandidate.references.length > 0) {
      let i = 0;
      for (const reference of prospectiveCandidate.references) {
        formData.append('references.Index', i.toString());
        for (const key in reference) {
          if (reference[key]) {
            formData.append('references[' + i.toString() + '].' + key, reference[key]);
          }
        }
        i++;
      }
    }

    if (prospectiveCandidate.contactLogs && prospectiveCandidate.contactLogs.length > 0) {
      let i = 0;
      for (const log of prospectiveCandidate.contactLogs) {
        formData.append('logs.Index', i.toString());
        for (const key in log) {
          if (log[key]) {
            formData.append('logs[' + i.toString() + '].' + key, log[key]);
          }
        }
        i++;
      }
    }

    const options = { headers: new HttpHeaders() };
    options.headers.append('enctype', 'multipart/form-data');

    return this.http.post<ProspectiveCandidateModel>(`${environment.apiServer.webApiURL}/ProspectiveCandidate/UploadFile`, formData, options);
  }

  editProspectiveCandidate(prospectiveCandidate: ProspectiveCandidateModel, file: File) {
    if (file) {
      return this.saveProspectiveCandidateWithFile(prospectiveCandidate, file);
    } else {
      return this.http.put<ProspectiveCandidateModel>(`${environment.apiServer.webApiURL}/ProspectiveCandidate/${prospectiveCandidate.id}`,
        prospectiveCandidate);
    }
  }

  saveProspectiveCandidateMiscDocuments(prospectiveCandidateID: number, files: File[]): Observable<any> {
    const formData = new FormData();
    let i = 0;

    for (var file of files){
      formData.append('files.Index', `${i}`);
      formData.append(`files[${i}].File`, file);
      formData.append('files[0].ID' , prospectiveCandidateID + '');
      i++;
    }

    const options = { headers: new HttpHeaders() };
    options.headers.append('enctype', 'multipart/form-data');

    return this.http.post<any>(`${environment.apiServer.webApiURL}/ProspectiveCandidate/UploadMiscDocument`, formData, options);
  }

  deleteProspectiveCandidateMiscDocuments(documentID: number, prospectiveCandidateID: number) {
    console.log(`${environment.apiServer.webApiURL}/ProspectiveCandidate/DeleteMiscDocument/${documentID}/${prospectiveCandidateID}`);
    return this.http.delete<any>(`${environment.apiServer.webApiURL}/ProspectiveCandidate/DeleteMiscDocument/${documentID}/?prospectiveCandidateID=${prospectiveCandidateID}`);
  }

  getResume( prospectiveCandidateID: number){
    return this.http.get<ResumeURLModel>(`${environment.apiServer.webApiURL}/ProspectiveCandidate/GetResume/${prospectiveCandidateID}`);
  }

  deleteProspectiveCandidate(prospectiveCandidateID: number) {
    return this.http.delete<any>(`${environment.apiServer.webApiURL}/ProspectiveCandidate/${prospectiveCandidateID}`);
  }

  noShowProspectiveCandidate(prospectiveCandidateID: number) {
    return this.http.get<ProspectiveCandidateModel>
      (`${environment.apiServer.webApiURL}/ProspectiveCandidate/NoShow/${prospectiveCandidateID}`);
  }


  getProspectiveCandidate(prospectiveCandidateID: number) {
    return this.http.get<ProspectiveCandidateModel>(`${environment.apiServer.webApiURL}/ProspectiveCandidate/${prospectiveCandidateID}`);
  }

  getProspectiveCandidatesList() {
    return this.http.get<ProspectiveCandidateModel[]>(`${environment.apiServer.webApiURL}/ProspectiveCandidate/List`);
  }


  getProspectiveCandidateReferenceByCode(code: string) {
    return this.http.get<ProspectiveCandidateReferenceModel>(`${environment.apiServer.webApiURL}/ProspectiveCandidate/ReferenceByCode?code=${code}`);
  }
  getProspectiveCandidateReferenceById(id: string) {
    return this.http.get<ProspectiveCandidateReferenceModel>(`${environment.apiServer.webApiURL}/ProspectiveCandidate/ReferenceById?id=${id}`);
  }

  editProspectiveCandidateReference(prospectiveCandidateReference: ProspectiveCandidateReferenceModel) {
    return this.http.put<ProspectiveCandidateReferenceModel>(`${environment.apiServer.webApiURL}/ProspectiveCandidate/ReferenceAnswer`,
      prospectiveCandidateReference);
  }
}
