import { SelfCareCategoryModel, SelfCareCompletionReportModel, SelfCareEmployeeModel } from 'src/app/models/selfcareModels';
import { SelfCarePlannerSectionModel, SelfCareQuestionModel } from './../../../models/selfcareModels';
import { createReducer, on, Action } from '@ngrx/store';
import { loadSelfcareCategories, loadSelfcareCategoriesFailure, loadSelfcareCategoriesSuccess, loadSelfcareCategory, loadSelfcareCategoryFailure, loadSelfcareCategorySuccess, loadSelfcarePlanner, loadSelfcarePlannerFailure, loadSelfcarePlannerSuccess, saveSelfcareAnwers, saveSelfcareAnwersFailure, saveSelfcareAnwersSuccess, saveSelfcarePlannerNote, saveSelfcarePlannerNoteSuccess, saveSelfcarePlannerNoteFailure, loadSelfcareOverSightEmployees, loadSelfcareOverSightEmployeesSuccess, loadSelfcareOverSightEmployeesFailure, loadSelfcarePreferences, getSelfCareCompletionList, getSelfCareCompletionListSuccess, exportSelfCareCompletionListToExcel, exportSelfCareCompletionListToExcelSuccess, exportSelfCareCompletionListToExcelFailure, getSelfCareAppURL, getSelfCareAppURLSuccess, getSelfCareAppURLFailure } from './selfcare.actions';
import { deepClone } from 'src/app/helpers/utils';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { PagingResultsModel } from '../../../models/pagingResultsModel';

export interface SelfcareState {
  plannerSections: SelfCarePlannerSectionModel[];
  category: SelfCareCategoryModel;
  categories: SelfCareCategoryModel[];
  loading: boolean;
  savingAnswer: boolean;
  savingNote: boolean;
  oversightList: SelfCareEmployeeModel[];
  loadingOversightList: boolean;

  loadingCompletionList: boolean;
  completionList: PagingResultsModel<SelfCareCompletionReportModel>;

  loadingCompletionExcel: boolean;
  completionExcelArrayBuffer: ArrayBuffer;
}

const initialState: SelfcareState = {
  plannerSections: null,
  category: null,
  categories: null,
  loading: false,
  savingAnswer: false,
  savingNote: false,
  oversightList: null,
  loadingOversightList: false,

  loadingCompletionList: true,
  completionList: null,

  loadingCompletionExcel: false,
  completionExcelArrayBuffer: null
};

export const selfcareStateReducer = createReducer(initialState,
  on(loadSelfcarePlanner, (state, action) => {
    return {
      ...state,
      loading: true
    };
  }),
  on(loadSelfcarePlannerSuccess, (state, action) => {
    const plannerSections: SelfCarePlannerSectionModel[] = deepClone(action.plannerSections);
    plannerSections.forEach(plannerSection => {
      plannerSection.items.forEach(item => {
        processQuestions(item.questions);
      });
    });
    return {
      ...state,
      plannerSections,
      loading: false
    };
  }),
  on(loadSelfcarePlannerFailure, (state, action) => {
    return {
      ...state,
      loading: false
    };
  }),

  on(loadSelfcareCategories, (state, action) => {
    return {
      ...state,
      loading: true
    };
  }),
  on(loadSelfcareCategoriesSuccess, (state, action) => {
    return {
      ...state,
      categories: action.categories,
      loading: false
    };
  }),
  on(loadSelfcareCategoriesFailure, (state, action) => {
    return {
      ...state,
      loading: false
    };
  }),

  on(loadSelfcarePreferences, (state, action) => {
    return {
      ...state,
      loading: true
    };
  }),
  on(loadSelfcareCategory, (state, action) => {
    return {
      ...state,
      loading: true
    };
  }),
  on(loadSelfcareCategorySuccess, (state, action) => {
    const category: SelfCareCategoryModel = deepClone(action.category);
    processQuestions(category.questions);
    return {
      ...state,
      category,
      loading: false
    };
  }),
  on(loadSelfcareCategoryFailure, (state, action) => {
    return {
      ...state,
      loading: false
    };
  }),
  on(saveSelfcareAnwers, (state, action) => ({ ...state, savingAnswer: true })),
  on(saveSelfcareAnwersSuccess, (state, action) => ({ ...state, savingAnswer: false })),
  on(saveSelfcareAnwersFailure, (state, action) => ({ ...state, savingAnswer: false })),

  on(saveSelfcarePlannerNote, (state, action) => ({ ...state, savingNote: true })),
  on(saveSelfcarePlannerNoteSuccess, (state, action) => ({ ...state, savingNote: false })),
  on(saveSelfcarePlannerNoteFailure, (state, action) => ({ ...state, savingNote: false })),

  on(loadSelfcareOverSightEmployees, (state, action) => {
    return {
      ...state,
      loadingOversightList: true
    };
  }),
  on(loadSelfcareOverSightEmployeesSuccess, (state, action) => {
    return {
      ...state,
      oversightList: deepClone(action.employees),
      loadingOversightList: false
    };
  }),
  on(loadSelfcareOverSightEmployeesFailure, (state, action) => {
    return {
      ...state,
      loadingOversightList: false
    };
  }),
  on(getSelfCareCompletionList, (state, action) => {
    return {
      ...state,
      loadingCompletionList: true
    };
  }),
  on(getSelfCareCompletionListSuccess, (state, action) => {
    return {
      ...state,
      loadingCompletionList: false,
      completionList: action.list
    };
  }),
  on(exportSelfCareCompletionListToExcel, (state, action) => {
    return {
      ...state,
      loadingCompletionExcel: true,
      completionExcelArrayBuffer: null
    };
  }),
  on(exportSelfCareCompletionListToExcelSuccess, (state, action) => {
    return {
      ...state,
      loadingCompletionExcel: false,
      completionExcelArrayBuffer: action.doc
    };
  }),
  on(exportSelfCareCompletionListToExcelFailure, (state, action) => {
    return {
      ...state,
      loadingCompletionExcel: false
    };
  }),

  on(getSelfCareAppURL, (state, action) => {
    return {
      ...state,
      loading: true
    };
  }),
  on(getSelfCareAppURLSuccess, (state, action) => {
    const url = action.url;
    
    return {
      ...state,
      url,
      loading: false
    };
  }),
  on(getSelfCareAppURLFailure, (state, action) => {
    return {
      ...state,
      loading: false
    };
  }),

);

export function selfcareStateReducerFunc(state: SelfcareState | undefined, action: Action) {
  return selfcareStateReducer(state, action);
}

export function processQuestions(questions: SelfCareQuestionModel[]) {
  if (questions) {
    questions.forEach(question => {
      if (question.questionOptions) {
        if (question.questionType === 1) {
          question.questionOptionsArray = [{ label: '-', value: null as string }];
        } else {
          question.questionOptionsArray = [];
        }
        question.questionOptionsArray.push(...question.questionOptions.split(',').map(x => ({ label: x, value: x })));

        question.questionOptionsArray.push({ label: 'Other', value: 'Other' });

      }

      if (question.questionType === 1) {
        if (question.questionOptions.split(',').indexOf(question.answer) >= 0) {
          question.answerSelected = question.answer;
        } else {
          question.answerSelected = 'Other';
        }
      }

      if (question.questionType === 2) {
        if (question.answer) {
          const answersIn = question.answer.split(',').filter(a => (question.questionOptions.split(',').indexOf(a) >= 0));
          question.answerArray = answersIn;


          const answersOthers = question.answer.split(',').filter(a => !(question.questionOptions.split(',').indexOf(a) >= 0));
          if (answersOthers.length > 0) {
            question.answerSelected = answersOthers.join(',');
            if (question.answerArray) {
              question.answerArray.push('Other');
            }
          } else {
            question.answerSelected = '';
          }
        }
      }
    });
  }
}
