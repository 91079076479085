import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MonthModel, DayOfWeekModel } from '../../models/monthModel';
import { DateRangeModel } from '../../models/dateRangeModel';
import { getDateWithOffset, deepClone, addDays } from '../../helpers/utils';
import { formatDate } from '@angular/common';
import { CustomFilterModel } from '../../models/customFilterModel';

@Injectable({
  providedIn: 'root'
})
export class DropdownFilterService {

  constructor() { }

  months: MonthModel[] = [{ month: 'January', value: 1, jsValue: 0, abbreviation: 'Jan' },
    { month: 'February', value: 2, jsValue: 1, abbreviation: 'Feb' },
    { month: 'March', value: 3, jsValue: 2, abbreviation: 'Mar' },
    { month: 'April', value: 4, jsValue: 3, abbreviation: 'Apr' },
    { month: 'May', value: 5, jsValue: 4, abbreviation: 'May' },
    { month: 'June', value: 6, jsValue: 5, abbreviation: 'Jun' },
    { month: 'July', value: 7, jsValue: 6, abbreviation: 'Jul' },
    { month: 'August', value: 8, jsValue: 7, abbreviation: 'Aug' },
    { month: 'September', value: 9, jsValue: 8, abbreviation: 'Sep' },
    { month: 'October', value: 10, jsValue: 9, abbreviation: 'Oct' },
    { month: 'November', value: 11, jsValue: 10, abbreviation: 'Nov' },
    { month: 'December', value: 12, jsValue: 11, abbreviation: 'Dec' },
    ];

  daysOfWeek: DayOfWeekModel[] = [
    { day: 'Sunday', value: 0, abbreviation: 'Sun' },
    { day: 'Monday', value: 1, abbreviation: 'Mon' },
    { day: 'Tuesday', value: 2, abbreviation: 'Tue' },
    { day: 'Wednesday', value: 3, abbreviation: 'Wed' },
    { day: 'Thursday', value: 4, abbreviation: 'Thu' },
    { day: 'Friday', value: 5, abbreviation: 'Fri' },
    { day: 'Saturday', value: 6, abbreviation: 'Sat' },
  ];

  getMonthsWithAll() : MonthModel[] {
    let allMonths: MonthModel[] = deepClone(this.months);
    allMonths.unshift({ month: 'All', value: -1, jsValue: -1, abbreviation: 'All' });
    return allMonths;
  }

  get30DayDateRanges(): DateRangeModel[] {
    let today = new Date();
    let next30 = getDateWithOffset(30);  
    let previous30 = getDateWithOffset(-30);

    let dateRanges: DateRangeModel[] = [
      { text: 'All', startDate: null, endDate: null, isCustom: false },
      { text: 'Last 30 Days', startDate: formatDate(previous30, 'yyyy-MM-dd', 'en-US'), endDate: formatDate(today, 'yyyy-MM-dd', 'en-US'), isCustom: false },
      { text: 'Next 30 Days', startDate: formatDate(today, 'yyyy-MM-dd', 'en-US'), endDate: formatDate(next30, 'yyyy-MM-dd', 'en-US'), isCustom: false },
      { text: 'Custom', startDate: null, endDate: null, isCustom: true },

    ];
    return dateRanges;
  }

  getLast120DayDateRanges(): DateRangeModel[] {
    let today = new Date();

    let dateRanges: DateRangeModel[] = [
      { text: 'All', startDate: null, endDate: null, isCustom: false },
      { text: 'Last 30 Days', startDate: formatDate(getDateWithOffset(-30), 'yyyy-MM-dd', 'en-US'), endDate: formatDate(today, 'yyyy-MM-dd', 'en-US'), isCustom: false },
      { text: 'Last 60 Days', startDate: formatDate(getDateWithOffset(-60), 'yyyy-MM-dd', 'en-US'), endDate: formatDate(today, 'yyyy-MM-dd', 'en-US'), isCustom: false },
      { text: 'Last 90 Days', startDate: formatDate(getDateWithOffset(-90), 'yyyy-MM-dd', 'en-US'), endDate: formatDate(today, 'yyyy-MM-dd', 'en-US'), isCustom: false },
      { text: 'Last 120 Days', startDate: formatDate(getDateWithOffset(-120), 'yyyy-MM-dd', 'en-US'), endDate: formatDate(today, 'yyyy-MM-dd', 'en-US'), isCustom: false },
      { text: 'Custom', startDate: null, endDate: null, isCustom: true },

    ];
    return dateRanges;
  }

  getFullTimeHourRanges(): CustomFilterModel[] {
    let hourRanges: CustomFilterModel[] = [
      { text: 'All', minValue: null, maxValue: null, isCustom: false },
      { text: 'Full Time (30+)', minValue: '30.00', maxValue: null, isCustom: false },
      { text: 'Part Time (0-29.99)', minValue: '0.00', maxValue: '29.99', isCustom: false },
      { text: 'Custom', minValue: null, maxValue: null, isCustom: true },
    ]

    return hourRanges;
  }

  getWeekMonthAllDateRanges(): DateRangeModel[] {
    let today = new Date();
    let thisWeekSunday = getDateWithOffset(-today.getDay());
    let thisMonthStart = getDateWithOffset(-today.getDate() + 1);
    let nextMonthStart = new Date(thisMonthStart.getFullYear(), thisMonthStart.getMonth() + 1, 1);
    let nextMonthEnd = addDays(new Date(nextMonthStart.getFullYear(), nextMonthStart.getMonth() + 1, 1), -1)
    let lastMonthEnd = addDays(thisMonthStart, -1);
    let lastMonthStart = addDays(lastMonthEnd, -lastMonthEnd.getDate() + 1)

    let dateRanges: DateRangeModel[] = [
      { text: 'All', startDate: null, endDate: null, isCustom: false },
      { text: 'This Week', startDate: formatDate(thisWeekSunday, 'yyyy-MM-dd', 'en-US'), endDate: formatDate(today, 'yyyy-MM-dd', 'en-US'), isCustom: false },
      { text: 'This Month', startDate: formatDate(thisMonthStart, 'yyyy-MM-dd', 'en-US'), endDate: formatDate(today, 'yyyy-MM-dd', 'en-US'), isCustom: false },
      { text: 'Next Month', startDate: formatDate(nextMonthStart, 'yyyy-MM-dd', 'en-US'), endDate: formatDate(nextMonthEnd, 'yyyy-MM-dd', 'en-US'), isCustom: false },
      { text: 'Last Week', startDate: formatDate(addDays(thisWeekSunday, -7), 'yyyy-MM-dd', 'en-US'), endDate: formatDate(addDays(thisWeekSunday, -1), 'yyyy-MM-dd', 'en-US'), isCustom: false },
      { text: 'Last Month', startDate: formatDate(lastMonthStart, 'yyyy-MM-dd', 'en-US'), endDate: formatDate(lastMonthEnd, 'yyyy-MM-dd', 'en-US'), isCustom: false },
      { text: 'Custom', startDate: null, endDate: null, isCustom: true },
    ];

    return dateRanges;
  }
}
