import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SelectItem } from 'primeng/api';
import { tap } from 'rxjs/operators';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { DropdownFilterService } from '../../../../core/services/dropdown-filter.service';
import { State } from '../../../../core/store';
import { getCompletedKraList, saveMeetsExpectations, saveMeetsExpectationsSuccess } from '../../../../core/store/employee-review/employee-review.actions';
import { loadEmployeeUsers, loadViewableCampuses } from '../../../../core/store/employee/employee.action';
import { deepClone, getDateWithOffset } from '../../../../helpers/utils';
import { DateRangeModel } from '../../../../models/dateRangeModel';
import { CompletedKraModel } from '../../../../models/employeeReviewModels';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';

@Component({
  selector: 'app-report-completed-kra',
  templateUrl: './report-completed-kra.component.html',
  styleUrls: ['./report-completed-kra.component.scss']
})
export class ReportCompletedKraComponent extends BaseComponent implements OnInit {
  list: PagingResultsModel<CompletedKraModel>;
  filter: any;
  loadingList: boolean = true;

  campusList: SelectItem[];
  campusId: number = 0;

  prefixText: string;
  userEmployeeList: SelectItem[];
  userId: number = 0;

  startDate: string = formatDate(getDateWithOffset(-90), 'yyyy-MM-dd', 'en-US');
  endDate: string = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');

  meetsRequirements: string = "null";
  statusOptions = [
    { label: 'All', value: 'all' },
    { label: 'Offer letter sent', value: 'true' },
    { label: 'Does not meet expectations', value: 'false' },
    { label: 'Not assigned', value: 'null' },
  ];

  canViewKraReport;

  constructor(
    private router: Router,
    private actions$: Actions,
    private store: Store<State>,
    private dropdownFilterService: DropdownFilterService  ) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.employeeState.campuses).subscribe(campuses => {
        this.campusList = campuses && campuses.length > 0 ? campuses
          : [{ label: 'loading...', value: undefined }];
      }),
      this.store.select(s => s.employeeState.employeeUsers).subscribe(users => {
        this.userEmployeeList = users;
      }),
      this.store.select(s => s.employeeReviewState.loadingCompletedKraList).subscribe(loadingCompletedKraList => {
        this.loadingList = loadingCompletedKraList;
      }),
      this.store.select(s => s.employeeReviewState.completedKraList).subscribe(completedKraList => {
        this.list = completedKraList;
      }),
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.canViewKraReport = permissionIDs && !!permissionIDs.find(p => p === 3208); //Can view KRAs
      }),
      this.actions$.pipe(
        ofType(saveMeetsExpectationsSuccess),
        tap(action => {
          this.reloadList();
        })
      ).subscribe(),
    );

    this.store.dispatch(loadViewableCampuses());    
  }

  reloadFilter(event) {
    this.filter = event;
    this.reloadList();
  }

  reloadList() {
    this.filter = deepClone(this.filter);
    this.filter.filters.userId = { Name: 'userId', Value: { value: this.userId.toString() } };
    this.filter.filters.programId = { Name: 'programId', Value: { value: this.campusId.toString() } };
    this.filter.filters.startDate = { Name: 'startDate', Value: { value: this.startDate } };
    this.filter.filters.endDate = { Name: 'endDate', Value: { value: this.endDate } };
    this.filter.filters.meetsRequirements = { Name: 'meetsRequirements', Value: { value: this.meetsRequirements } };
    
    this.store.dispatch(getCompletedKraList({
      filter: this.filter
    }));
  }

  getStaffList(event) {
    this.store.dispatch(loadEmployeeUsers({
      prefixText: event.query,
      programId: this.campusId,
      payrollClassificationId: null,
      showInactive: false,
      showTerminated: false
    }));
  }

  reloadListAfterStaffChage(selectedUser) {
    if (typeof selectedUser.value !== 'number') {
      return;
    }

    this.userId = selectedUser.value;
    this.reloadList();
  }

  reloadListAfterClear(event) {
    if (event.data !== null) {
      return;
    }

    this.userId = 0;
    this.reloadList();
  }

  openReview(review) {
      this.openLink(`/employee-review/oversight/view/${review.oversightReviewID}`);    
  }

  openLink(path) {
    this.router.navigate([path]);
  }

  saveOfferLetterSent(review: CompletedKraModel) {
    let rev = deepClone(review) as CompletedKraModel;
    rev.meetsNewOfferLetterRequirements = true;
    this.store.dispatch(saveMeetsExpectations({ review: rev }));
  }

  saveDoesNotMeetExpections(review: CompletedKraModel) {
    let rev = deepClone(review) as CompletedKraModel;
    rev.meetsNewOfferLetterRequirements = false;
    this.store.dispatch(saveMeetsExpectations({ review: rev }));
  }

  saveReset(review: CompletedKraModel) {
    let rev = deepClone(review) as CompletedKraModel;
    rev.meetsNewOfferLetterRequirements = null;
    this.store.dispatch(saveMeetsExpectations({ review: rev }));
  }
}
