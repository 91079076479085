import { UserProfileModel } from './../../../../../models/userProfileModel';
import {
  updateProfileBCIForm, saveProfile, loadProfile,
  saveProfileSuccess, updateProfileCBSForm, updateProfilePRISMForm
} from '../../../../../core/store/profile/profile.actions';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from '../../../../../shared/services/alert.service';
import { Component, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../../core/store';
import { Actions, ofType } from '@ngrx/effects';
import * as moment from 'moment';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { FormGroup } from '@angular/forms';
import { getAllControlsErrors, markFormGroupTouched } from 'src/app/helpers/utils';
import { initProfileBCIForm, initProfileCBSForm, initProfilePRISMForm } from 'src/app/core/store/profile/profile.reducer';
import { tap } from 'rxjs/operators';
import { setOnboardingDocsInfoFilled, setOnboardingDocsInfoFilledSuccess } from 'src/app/core/store/onboardingdocs/onboardingdocs.actions';

@Component({
  selector: 'app-doc-fill-info-prism-provider-agreement',
  templateUrl: './doc-fill-info-prism-provider-agreement.component.html',
  styleUrls: ['./doc-fill-info-prism-provider-agreement.component.scss']
})
export class DocFillInfoPrismProviderAgreementComponent extends BaseComponent implements OnInit {

  @Input()
  onboardingDocUserId;

  profile: UserProfileModel;
  form: FormGroup;


  yearRange = (new Date().getFullYear() - 1) + ':' + (new Date().getFullYear() + 20);

  constructor(
    private store: Store<State>,
    private actions$: Actions,
    private alert: AlertService,
    private router: Router,
    private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.store.dispatch(loadProfile());
    this.subs.push(
      this.store.select(s => s.profileState).subscribe(profileState => {
        if (profileState && profileState.profile) {
          this.profile = JSON.parse(JSON.stringify(profileState.profile));
          if (this.profile.employee.prismRequestDate) {
            this.profile.employee.prismRequestDate = new Date(this.profile.employee.prismRequestDate);
          }
          if (this.profile.employee.prismEffectiveDate) {
            this.profile.employee.prismEffectiveDate = new Date(this.profile.employee.prismEffectiveDate);
          }
          if (!this.form) {
            this.form = initProfilePRISMForm(this.profile);
            this.subs.push(
              this.form.valueChanges.subscribe(formValues => {
                const employeeValues = this.form.get('employee').value;
                const formErrors = getAllControlsErrors(this.form);
                this.store.dispatch(updateProfilePRISMForm({ employeeValues, formErrors }));
              })
            );
          }
        }
      }),
      this.actions$.pipe(
        ofType(saveProfileSuccess),
        tap(action => {
          this.store.dispatch(setOnboardingDocsInfoFilled({ onboardingDocUserId: this.onboardingDocUserId }));
        })
      ).subscribe(),

      this.actions$.pipe(
        ofType(setOnboardingDocsInfoFilledSuccess),
        tap(action => {
          this.router.navigate(['../../sign/' + this.onboardingDocUserId],{relativeTo: this.route});
        })
      ).subscribe()
    );
  }

  save() {
    if (this.form.valid && this.profile.renderingProvider && this.profile.renderingProvider.npi) {
      this.store.dispatch(saveProfile({ profile: this.profile }));
    } else {
      markFormGroupTouched(this.form);
    }
  }

}
