import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, tap, catchError, filter } from 'rxjs/operators';
import { of, pipe } from 'rxjs';
import { errorHappened, loadProfile, saveSignature, saveSignatureSuccess, saveSignatureFailure } from '../profile/profile.actions';
import { Store, Action } from '@ngrx/store';
import { State } from '..';
import { AlertService } from 'src/app/shared/services/alert.service';
import {
  loadOnboardingUploadTypes, loadOnboardingUploadTypesSuccess, loadOnboardingUploadTypesFailure, uploadOnboardingDoc, uploadOnboardingDocFailure, uploadOnboardingDocSuccess, loadOnboardingUploads, loadOnboardingUploadsSuccess, loadOnboardingUploadsFailure, loadOnboardingInfo, loadOnboardingInfoSuccess, loadOnboardingInfoFailure, deleteOnboardingUpload, deleteOnboardingUploadSuccess, deleteOnboardingUploadFailure, consentToProceedOnboarding, consentToProceedOnboardingSuccess, consentToProceedOnboardingFailure, loadOnBoardingUsersList, loadOnBoardingUsersListSuccess, loadOnBoardingUsersListFailure, downloadOnboardingUpload, downloadOnboardingUploadSuccess, loadManageOnboardingUserInfo, loadManageOnboardingUserInfoSuccess, loadManageOnboardingUserInfoFailure, approveOnboardingUploadSuccess, approveOnboardingUpload, approveOnboardingUploadFailure, approveAlluploads, approveAlluploadsSuccess, approveAlluploadsFailure, approveI9, approveI9Success, approveI9Failure, approveSignedDocs, approveSignedDocsSuccess, approveSignedDocsFailure, finishOnboarding, finishOnboardingSuccess, finishOnboardingFailure, saveUserProfile, saveUserProfileSuccess, saveUserProfileFailure, uploadSignedI9, uploadSignedI9Success, uploadSignedI9Failure, denyOnboardingUpload, denyOnboardingUploadSuccess, denyOnboardingUploadFailure, denyI9, denyI9Success, denyI9Failure, setOrientationDate, setOrientationDateSuccess, setOrientationDateFailure, rejectOrientationDate, rejectOrientationDateSuccess, rejectOrientationDateFailure, changeVisibilityOnboardingUpload, changeVisibilityOnboardingUploadSuccess, changeVisibilityOnboardingUploadFailure, loadEmployeeFileUsersList, loadEmployeeFileUsersListSuccess, loadEmployeeFileUsersListFailure, forceOnBoarding, forceOnBoardingSuccess, forceOnBoardingFailure, saveUserSignatureSuccess, saveUserSignatureFailure, saveUserSignature
  , sendOnBoardingMessage, sendOnBoardingMessageSuccess, sendOnBoardingMessageFailure, completeHRFile, completeHRFileFailure, completeHRFileSuccess, requestVaccineCardExemption, requestVaccineCardExemptionSuccess, requestVaccineCardExemptionFailure, getVaccineCardExemption, getVaccineCardExemptionSuccess, getVaccineCardExemptionFailure, approveVaccineCardExemption, approveVaccineCardExemptionSuccess, approveVaccineCardExemptionFailure, loadUploadDocsApprovalList, loadUploadDocsApprovalListSuccess, loadUploadDocsApprovalListFailure
  , cancelVaccineCardExemptionFailure, cancelVaccineCardExemption, cancelVaccineCardExemptionSuccess, getEmployeeVaccineInfoList, getEmployeeVaccineInfoListSuccess, exportEmployeeVaccineInfoListToExcel, exportEmployeeVaccineInfoListToExcelSuccess, exportEmployeeVaccineInfoListToExcelFailure
} from './onboarding.actions';
import { OnboardingService } from '../../services/onboarding.service';
import { ProfileService } from '../../services/profile.service';

@Injectable()
export class OnBoardingEffects {
  constructor(
    private actions$: Actions,
    private onboardingService: OnboardingService,
    private store: Store<State>,
    private alertService: AlertService,
    private router: Router,
    private profileService: ProfileService) {

  }

  loadOnboardingUploadTypes = createEffect(() => this.actions$.pipe(
    ofType(loadOnboardingUploadTypes),
    switchMap(action => {
      return this.onboardingService.getOnBoardingUploadTypes().pipe(
        map(uploadTypes => loadOnboardingUploadTypesSuccess({ uploadTypes })),
        catchError(err => {
          this.store.dispatch(loadOnboardingUploadTypesFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  uploadOnboardingDoc = createEffect(() => this.actions$.pipe(
    ofType(uploadOnboardingDoc),
    switchMap(action => this.onboardingService.uploadFile(action.docTypeID, action.files, action.userId, action.visibleToUser, action.uploadID, action.licenseInfos, action.panelingInfos, action.covidTestInfos, action.vaccineExemption, action.driversLicenseInfos).pipe(
      map(url => uploadOnboardingDocSuccess({ userId: action.userId }),
        catchError(err => {
          this.store.dispatch(uploadOnboardingDocFailure());
          return of(errorHappened({ err }));
        }))
    )
    )));



  loadOnboardingUploads = createEffect(() => this.actions$.pipe(
    ofType(loadOnboardingUploads),
    switchMap(action => {
      return this.onboardingService.getUploadedFiles(action.userId).pipe(
        map(uploadedFiles => loadOnboardingUploadsSuccess({ uploadedFiles })),
        catchError(err => {
          this.store.dispatch(loadOnboardingUploadsFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));


  loadOnboardingInfo = createEffect(() => this.actions$.pipe(
    ofType(loadOnboardingInfo),
    switchMap(action => {
      return this.onboardingService.loadOnBoardingInfo().pipe(
        map(onBoardingInfo => loadOnboardingInfoSuccess({ onBoardingInfo })),
        catchError(err => {
          this.store.dispatch(loadOnboardingInfoFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));


  deleteOnboardingUpload = createEffect(() => this.actions$.pipe(
    ofType(deleteOnboardingUpload),
    switchMap(action => {
      return this.onboardingService.deleteOnboardingUpload(action.OnBoardingUpload).pipe(
        map(userId => deleteOnboardingUploadSuccess(userId != null ? {userId:userId} : {})),
        catchError(err => {
          this.store.dispatch(deleteOnboardingUploadFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  deleteOnboardingUploadSuccess = createEffect(() => this.actions$.pipe(
    ofType(deleteOnboardingUploadSuccess),
    tap(action => {
      this.store.dispatch(loadManageOnboardingUserInfo({ userId: action.userId }));
      this.store.dispatch(loadOnboardingUploads({ userId: action.userId }));
    })
  ), { dispatch: false });



  changeVisibilityOnboardingUpload = createEffect(() => this.actions$.pipe(
    ofType(changeVisibilityOnboardingUpload),
    switchMap(action => {
      return this.onboardingService.changeVisibilityOnboardingUpload(action.OnBoardingUpload).pipe(
        map(result => changeVisibilityOnboardingUploadSuccess({ userId: action.OnBoardingUpload.userID })),
        catchError(err => {
          this.store.dispatch(changeVisibilityOnboardingUploadFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  changeVisibilityOnboardingUploadSuccess = createEffect(() => this.actions$.pipe(
    ofType(changeVisibilityOnboardingUploadSuccess),
    tap(action => {
      this.store.dispatch(loadOnboardingUploads({ userId: action.userId }));
    })
  ), { dispatch: false });



  approveOnboardingUpload = createEffect(() => this.actions$.pipe(
    ofType(approveOnboardingUpload),
    switchMap(action => {
      return this.onboardingService.approveOnboardingUpload(action.OnBoardingUpload).pipe(
        map(result => approveOnboardingUploadSuccess({ userId: action.OnBoardingUpload.userID })),
        catchError(err => {
          this.store.dispatch(approveOnboardingUploadFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  approveOnboardingUploadSuccess = createEffect(() => this.actions$.pipe(
    ofType(approveOnboardingUploadSuccess),
    tap(action => {
      this.store.dispatch(loadOnboardingUploads({ userId: action.userId }));
    })
  ), { dispatch: false });


  denyOnboardingUpload = createEffect(() => this.actions$.pipe(
    ofType(denyOnboardingUpload),
    switchMap(action => {
      return this.onboardingService.denyOnboardingUpload(action.OnBoardingUpload, action.denyMessage).pipe(
        map(result => denyOnboardingUploadSuccess({ userId: action.OnBoardingUpload.userID })),
        catchError(err => {
          this.store.dispatch(denyOnboardingUploadFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  denyOnboardingUploadSuccess = createEffect(() => this.actions$.pipe(
    ofType(denyOnboardingUploadSuccess),
    tap(action => {
      this.store.dispatch(loadOnboardingUploads({ userId: action.userId }));
    })
  ), { dispatch: false });

  approveAlluploads = createEffect(() => this.actions$.pipe(
    ofType(approveAlluploads),
    switchMap(action => {
      return this.onboardingService.approveAlluploads(action.userId).pipe(
        map(result => approveAlluploadsSuccess({ userId: action.userId })),
        catchError(err => {
          this.store.dispatch(approveAlluploadsFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  approveAlluploadsSuccess = createEffect(() => this.actions$.pipe(
    ofType(approveAlluploadsSuccess),
    tap(action => {
      this.store.dispatch(loadManageOnboardingUserInfo({ userId: action.userId }));
    })
  ), { dispatch: false });


  approveSignedDocs = createEffect(() => this.actions$.pipe(
    ofType(approveSignedDocs),
    switchMap(action => {
      return this.onboardingService.approveSignedDocs(action.userId).pipe(
        map(result => approveSignedDocsSuccess({ userId: action.userId })),
        catchError(err => {
          this.store.dispatch(approveSignedDocsFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  approveSignedDocsSuccess = createEffect(() => this.actions$.pipe(
    ofType(approveSignedDocsSuccess),
    tap(action => {
      this.store.dispatch(loadManageOnboardingUserInfo({ userId: action.userId }));
    })
  ), { dispatch: false });



  finishOnboarding = createEffect(() => this.actions$.pipe(
    ofType(finishOnboarding),
    switchMap(action => {
      return this.onboardingService.finishOnboarding().pipe(
        map(result => finishOnboardingSuccess()),
        catchError(err => {
          this.store.dispatch(finishOnboardingFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  finishOnboardingSuccess = createEffect(() => this.actions$.pipe(
    ofType(finishOnboardingSuccess),
    tap(action => {
      this.store.dispatch(loadOnboardingInfo());
    })
  ), { dispatch: false });

  rejectOrientationDate = createEffect(() => this.actions$.pipe(
    ofType(rejectOrientationDate),
    switchMap(action => {
      return this.onboardingService.rejectOrientationDate().pipe(
        map(result => rejectOrientationDateSuccess()),
        catchError(err => {
          this.store.dispatch(rejectOrientationDateFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  rejectOrientationDateSuccess = createEffect(() => this.actions$.pipe(
    ofType(rejectOrientationDateSuccess),
    tap(action => {
      this.store.dispatch(loadOnboardingInfo());
    })
  ), { dispatch: false });

  setOrientationDate = createEffect(() => this.actions$.pipe(
    ofType(setOrientationDate),
    switchMap(action => {
      return this.onboardingService.setOrientationDate(action.userId, action.orientationDate).pipe(
        map(result => setOrientationDateSuccess({ userId: action.userId })),
        catchError(err => {
          this.store.dispatch(setOrientationDateFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  setOrientationDateSuccess = createEffect(() => this.actions$.pipe(
    ofType(setOrientationDateSuccess),
    tap(action => {
      this.alertService.success('Orientation date set');
      this.store.dispatch(loadManageOnboardingUserInfo({ userId: action.userId }));
    })
  ), { dispatch: false });

  approveI9 = createEffect(() => this.actions$.pipe(
    ofType(approveI9),
    switchMap(action => {
      return this.onboardingService.approveI9(action.userId).pipe(
        map(result => approveI9Success({ userId: action.userId })),
        catchError(err => {
          this.store.dispatch(approveI9Failure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  approveI9Success = createEffect(() => this.actions$.pipe(
    ofType(approveI9Success),
    tap(action => {
      this.store.dispatch(loadManageOnboardingUserInfo({ userId: action.userId }));
    })
  ), { dispatch: false });

  denyI9 = createEffect(() => this.actions$.pipe(
    ofType(denyI9),
    switchMap(action => {
      return this.onboardingService.denyI9(action.userId, action.denyMessage).pipe(
        map(result => denyI9Success({ userId: action.userId })),
        catchError(err => {
          this.store.dispatch(denyI9Failure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  denyI9Success = createEffect(() => this.actions$.pipe(
    ofType(denyI9Success),
    tap(action => {
      this.store.dispatch(loadManageOnboardingUserInfo({ userId: action.userId }));
    })
  ), { dispatch: false });


  consentToProceedOnboarding = createEffect(() => this.actions$.pipe(
    ofType(consentToProceedOnboarding),
    switchMap(action => {
      return this.onboardingService.consentToProceedOnboarding(action.onBoardingInfo).pipe(
        map(onBoardingInfo => consentToProceedOnboardingSuccess({ onBoardingInfo })),
        catchError(err => {
          this.store.dispatch(consentToProceedOnboardingFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));


  consentToProceedOnboardingSuccess = createEffect(() => this.actions$.pipe(
    ofType(consentToProceedOnboardingSuccess),
    tap(action => {
      this.store.dispatch(loadOnboardingInfo());
    })
  ), { dispatch: false });

  loadOnBoardingUsersList = createEffect(() => this.actions$.pipe(
    ofType(loadOnBoardingUsersList),
    switchMap(action => {
      return this.onboardingService.loadOnBoardingUsers(action.filter).pipe(
        map(onBoardingUsers => loadOnBoardingUsersListSuccess({ onBoardingUsers })),
        catchError(err => {
          this.store.dispatch(loadOnBoardingUsersListFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));


  loadEmployeeFileUsersList = createEffect(() => this.actions$.pipe(
    ofType(loadEmployeeFileUsersList),
    switchMap(action => {
      return this.onboardingService.loadEmployeeFileUsers(action.filter).pipe(
        map(employeeFileUsers => loadEmployeeFileUsersListSuccess({ employeeFileUsers })),
        catchError(err => {
          this.store.dispatch(loadEmployeeFileUsersListFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  downloadOnboardingUpload = createEffect(() => this.actions$.pipe(
    ofType(downloadOnboardingUpload),
    switchMap(action => {
      return this.onboardingService.downloadOnboardingUpload(action.onboardingUploadFileId).pipe(
        map(doc => downloadOnboardingUploadSuccess({ doc })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));


  loadManageOnboardingUserInfo = createEffect(() => this.actions$.pipe(
    ofType(loadManageOnboardingUserInfo),
    switchMap(action => {
      return this.onboardingService.getOnboardingUser(action.userId).pipe(
        map(onBoardingUser => loadManageOnboardingUserInfoSuccess({ onBoardingUser })),
        catchError(err => {
          this.store.dispatch(loadManageOnboardingUserInfoFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  saveUserProfile = createEffect(() => this.actions$.pipe(
    ofType(saveUserProfile),
    switchMap(action => {
      return this.profileService.saveProfile(action.profile).pipe(
        map(result => saveUserProfileSuccess({ userId: action.userId })),
        catchError(err => {
          this.store.dispatch(saveUserProfileFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  saveUserProfileSuccess = createEffect(() => this.actions$.pipe(
    ofType(saveUserProfileSuccess),
    tap(action => {
      this.alertService.success('Saved');
      this.store.dispatch(loadManageOnboardingUserInfo({ userId: action.userId }));
    })
  ), { dispatch: false });


  saveUserSignature = createEffect(() => this.actions$.pipe(
    ofType(saveUserSignature),
    switchMap(action => {
      return this.profileService.saveSignature(action.data, action.userId).pipe(
        map(result => saveUserSignatureSuccess({ userId: action.userId })),
        catchError(err => {
          this.store.dispatch(saveUserSignatureFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  saveUserSignatureSuccess = createEffect(() => this.actions$.pipe(
    ofType(saveUserSignatureSuccess),
    tap(action => {
      this.alertService.success('Saved');
      this.store.dispatch(loadManageOnboardingUserInfo({ userId: action.userId }));
    })
  ), { dispatch: false });


  forceOnBoarding = createEffect(() => this.actions$.pipe(
    ofType(forceOnBoarding),
    switchMap(action => {
      return this.onboardingService.forceOnBoarding(action.userId).pipe(
        map(result => forceOnBoardingSuccess({ userId: action.userId })),
        catchError(err => {
          this.store.dispatch(forceOnBoardingFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));



  uploadSignedI9 = createEffect(() => this.actions$.pipe(
    ofType(uploadSignedI9),
    switchMap(action => this.onboardingService.uploadSignedI9(action.formSubmissionID, action.file).pipe(
      map(url => uploadSignedI9Success(),
        catchError(err => {
          this.store.dispatch(uploadSignedI9Failure());
          return of(errorHappened({ err }));
        }))
    )
    )));


  sendOnBoardingMessage = createEffect(() => this.actions$.pipe(
    ofType(sendOnBoardingMessage),
    switchMap(action => {
      return this.onboardingService.sendOnboardingMessage(action.onBoardingMessage).pipe(
        map(result => sendOnBoardingMessageSuccess()),
        catchError(err => {
          this.store.dispatch(sendOnBoardingMessageFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  sendOnBoardingMessageSuccess = createEffect(() => this.actions$.pipe(
    ofType(sendOnBoardingMessageSuccess),
    tap(action => {
      this.alertService.success('Message sent. ');
    })
  ), { dispatch: false });



  completeHRFile = createEffect(() => this.actions$.pipe(
    ofType(completeHRFile),
    switchMap(action => {
      return this.onboardingService.completeHRFile(action.userId, action.eVerifyCompletionDate).pipe(
        map(result => completeHRFileSuccess({ userId: action.userId })),
        catchError(err => {
          this.store.dispatch(completeHRFileFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  completeHRFileSuccess = createEffect(() => this.actions$.pipe(
    ofType(completeHRFileSuccess),
    tap(action => {
      this.alertService.success('HR File completed. ');
    })
  ), { dispatch: false });


  requestVaccineCardExemption = createEffect(() => this.actions$.pipe(
    ofType(requestVaccineCardExemption),
    switchMap(action => {
      return this.onboardingService.requestVaccineCardExemption({ notes: action.notes, userID: action.userID, exemptionType: action.exemptionType }).pipe(
        map(vaccineCardExemptionModel => requestVaccineCardExemptionSuccess({ vaccineCardExemptionModel })),
        catchError(err => {
          this.store.dispatch(requestVaccineCardExemptionFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  requestVaccineCardExemptionSuccess = createEffect(() => this.actions$.pipe(
    ofType(requestVaccineCardExemptionSuccess),
    tap(action => {
      this.alertService.success('Vaccine Card exemption requested');
    })
  ), { dispatch: false });


  cancelVaccineCardExemption = createEffect(() => this.actions$.pipe(
    ofType(cancelVaccineCardExemption),
    switchMap(action => {
      return this.onboardingService.cancelVaccineCardExemption({ id: action.id}).pipe(
        map(vaccineCardExemptionModel => cancelVaccineCardExemptionSuccess()),
        catchError(err => {
          this.store.dispatch(cancelVaccineCardExemptionFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  cancelVaccineCardExemptionSuccess = createEffect(() => this.actions$.pipe(
    ofType(cancelVaccineCardExemptionSuccess),
    tap(action => {
      this.alertService.success('Vaccine Card exemption removed');
    })
  ), { dispatch: false });

  getVaccineCardExemption = createEffect(() => this.actions$.pipe(
    ofType(getVaccineCardExemption),
    switchMap(action => {
      return this.onboardingService.getVaccineCardExemption(action.userID).pipe(
        map(vaccineCardExemptionModel => getVaccineCardExemptionSuccess({ vaccineCardExemptionModel })),
        catchError(err => {
          this.store.dispatch(getVaccineCardExemptionFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  approveVaccineCardExemption = createEffect(() => this.actions$.pipe(
    ofType(approveVaccineCardExemption),
    switchMap(action => {
      return this.onboardingService.approveVaccineCardExemption(action.id).pipe(
        map(vaccineCardExemptionModel => approveVaccineCardExemptionSuccess({ vaccineCardExemptionModel })),
        catchError(err => {
          this.store.dispatch(approveVaccineCardExemptionFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));


  loadUploadDocsApprovalList = createEffect(() => this.actions$.pipe(
    ofType(loadUploadDocsApprovalList),
    switchMap(action => {
      return this.onboardingService.loadUploadDocsApprovalList().pipe(
        map(uploadDocsApprovalList => loadUploadDocsApprovalListSuccess({ uploadDocsApprovalList })),
        catchError(err => {
          this.store.dispatch(loadUploadDocsApprovalListFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));

  getEmployeeVaccineInfoList = createEffect(() => this.actions$.pipe(
    ofType(getEmployeeVaccineInfoList),
    switchMap(action => {
      return this.onboardingService.getEmployeeVaccineInfoList(action.filter).pipe(
        map(list => getEmployeeVaccineInfoListSuccess({ list })),
        catchError(err => {
          return of(errorHappened({ err }));
        }));
    })
  ));

  exportEmployeeVaccineInfoListToExcel = createEffect(() => this.actions$.pipe(
    ofType(exportEmployeeVaccineInfoListToExcel),
    switchMap(action => {
      return this.onboardingService.exportEmployeeVaccineInfoToExcel(action.filter).pipe(
        map(doc => exportEmployeeVaccineInfoListToExcelSuccess({ doc })),
        catchError(err => {
          this.store.dispatch(exportEmployeeVaccineInfoListToExcelFailure());
          return of(errorHappened({ err }));
        }));
    })
  ));
}
