import { loadProfile } from './../../../../core/store/profile/profile.actions';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from './../../../../shared/services/alert.service';
import { signOfferLetterDoc, signOfferLetterDocSuccess } from './../../../../core/store/offerletter/offerletter.actions';
import { UserModel } from 'src/app/models/userModel';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { BaseComponent } from '../../../../core/abstracts/baseComponent';
import { OfferLetterModel } from '../../../../models/offerletterModel';
import { loadOfferLetterDoc } from 'src/app/core/store/offerletter/offerletter.actions';
import { Actions, ofType } from '@ngrx/effects';
import { switchMap, tap } from 'rxjs/operators';
import { Utils, downloadFile } from 'src/app/helpers/utils';
import * as moment from 'moment';
import { SignDocComponentBase } from '../../../abstract/signDocBase.component';
import { getEquipmentAssignPdf, signEquipmentReturn, signEquipmentReturnSuccess } from 'src/app/core/store/equipment/equipment.actions';

@Component({
  selector: 'app-sign-return-equipment',
  templateUrl: './sign-return-equipment.component.html',
  styleUrls: ['./sign-return-equipment.component.scss']
})
export class SignReturnEquipmentComponent extends SignDocComponentBase implements OnInit, AfterViewInit {

  assignedEquipmentID: number;
  loadingSign = false;
  loadingDoc = true;
  user: UserModel;
  returningEquipmentsUserID: number;

  constructor(
    private store: Store<State>,
    private actions$: Actions,
    private alert: AlertService,
    private router: Router,
    private route: ActivatedRoute) {
      super(store);
  }

  ngOnInit() {
    this.store.dispatch(loadProfile());
    this.route.params.subscribe(params => {
      if (params.assignedEquipmentID) {
        this.assignedEquipmentID = params.assignedEquipmentID;
        this.store.dispatch(getEquipmentAssignPdf({ assignedEquipmentID: params.assignedEquipmentID, returned: true, download: false }));
      }
    });
    this.subs.push(
      this.store.select(s => s.profileState.profile).subscribe(p => {
        if (p) {
          this.user = p.user;
        }
      }),
      this.store.select(s => s.equipmentState.docArrayBuffer).subscribe(docArrayBuffer => {
        if (docArrayBuffer) {
          this.url =
            URL.createObjectURL(new Blob([docArrayBuffer], {
              type: 'application/pdf'
            }));
        }
      }),
      this.store.select(s => s.equipmentState.loadingDoc).subscribe(loadingDoc => {
        this.loadingDoc = loadingDoc;
      }),

      this.store.select(s => s.equipmentState.userIDReturning).subscribe(userIDReturning => {
        this.returningEquipmentsUserID = userIDReturning;
      }),
      this.store.select(s => s.equipmentState.loadingSign).subscribe(loadingSign => {
        this.loadingSign = loadingSign;
      })

    );
  }

  get loggedUserName() {
    return !this.user ? '' : (this.user.firstName + ' ' + this.user.lastName);
  }

  get loggedUserEmail() {
    return !this.user ? '' : this.user.email;
  }

  sign() {
    if (!this.finishSignDisabled) {
      this.store.dispatch(signEquipmentReturn({ assignedEquipmentID: this.assignedEquipmentID, signedFields: this.signedFields }));
      this.subs.push(
        this.actions$.pipe(
          ofType(signEquipmentReturnSuccess),
          tap(action => {
            this.alert.confirm('Documentation signed succesfully',
              'Documentation signed succesfully. Do you want to download the signed file now?')
              .subscribe(
                answer => {
                  if (answer) {
                    downloadFile(action.docSigned, 'Equipment Return Signed.pdf');
                  }
                  this.router.navigate(['/equipment/list'], { queryParams: { assignTo: this.returningEquipmentsUserID } });
                }, error => {
                    this.router.navigate(['/equipment/list'], { queryParams: { assignTo: this.returningEquipmentsUserID } });
                }
              );
          })
        ).subscribe()
      );
    }
  }

}
