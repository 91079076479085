import { FormBuilderState } from './../../../../core/store/form-builder/form-builder.reducer';
import { FBFormModel } from './../../../../models/formBuilderModels';
import { BaseComponent } from './../../../../core/abstracts/baseComponent';
import { BenefitsState } from './../../../../core/store/benefits/benefits.reducer';
import { BenefitPlanModel } from './../../../../models/benefitsModels';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { Actions, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { MultiSelect } from 'primeng/multiselect';
import { AlertService } from '../../../../shared/services/alert.service';
import { deepClone, downloadFile, getTimeStampString } from '../../../../helpers/utils';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { tap } from 'rxjs/operators';
import { DateRangeModel } from '../../../../models/dateRangeModel';
import { DropdownFilterService } from '../../../../core/services/dropdown-filter.service';
import { deleteFBForm, duplicateFBForm, duplicateFBFormSuccess, getAllForms, getFBFormTable, getFormsFilter, getFormTypesList } from 'src/app/core/store/form-builder/form-builder.actions';
import { Table } from 'primeng';

@Component({
  selector: 'app-list-forms',
  templateUrl: './list-forms.component.html',
  styleUrls: ['./list-forms.component.scss']
})
export class ListFormsComponent extends BaseComponent implements OnInit {
  @ViewChild('formTable') formTable: Table;
  formsList: PagingResultsModel<FBFormModel>;
  canEdit: boolean;
  loadingFormsList = false;
  formTypes = [];
  formNames = [];
  filter: any;

  formNameFilter;
  formTypeFilter: string = null;
  formActiveFilter: boolean = null;
  showHideString: string = "Hide";
  hideFilter: boolean = false;
  
  yesNoAllOptions = [
    { label: 'All', value: null },
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  constructor(
    private actions$: Actions,
    private alertService: AlertService,
    private store: Store<State>,
    private router: Router,
    private confirmationService: ConfirmationService,
    private dropdownFilterService: DropdownFilterService
  ) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.store.select(s => s.accountState.user?.permissionIDs).subscribe(permissionIDs => {
        this.canEdit = permissionIDs && !!permissionIDs.find(p => p === 9001);
      }),
      this.store.select(s => s.formBuilderState.forms).subscribe(formsList => {
        if (formsList) {
          this.formsList = formsList;
        }
      }),
      this.store.select(s => s.formBuilderState.formTypes).subscribe(types => {
        let formTypes = types && types.length > 0 ? types.map(c => ({ label: c.formType, value: c.id }))
          : [{ label: 'loading...', value: undefined }];
        this.formTypes = formTypes;
        this.formTypes.unshift({ label: 'All', value: null });
      }),
      this.store.select(s => s.formBuilderState.simpleFormsList).subscribe(names => {
        let formNames = names && names.length > 0 ? names.map(c => ({ label: c.name, value: c.name }))
        : [{ label: 'loading...', value: undefined }];
        this.formNames = formNames;
      }),
      this.store.select(s => s.formBuilderState.loadingForms).subscribe(loadingFormsList => {
        this.loadingFormsList = loadingFormsList;
      }),
      this.actions$.pipe(
        ofType(duplicateFBFormSuccess),
        tap(action => {          
          this.router.navigate(['/form-builder/form/' + action.form.id]);
        })
      ).subscribe(),
    );
    if (window.sessionStorage && window.sessionStorage.getItem('formFilter')) {
      var storedFilter = JSON.parse(sessionStorage.getItem('formFilter'));
      if(storedFilter.formNameFilter){
        this.formNameFilter = storedFilter.formNameFilter;
      }
      if(storedFilter.formTypeFilter){
        this.formTypeFilter = storedFilter.formTypeFilter;
      }
      if(storedFilter.formActiveFilter){
        this.formActiveFilter = storedFilter.formActiveFilter;
      }
    }
    this.store.dispatch(getFormTypesList());
    this.store.dispatch(getFormsFilter());

  }

  loadPage(event) {
    if (!this.loadingFormsList) {
      this.loadingFormsList = true;
      this.filter = deepClone(event);
      this.filter.filters.formNameFilter = { Name: 'formNameFilter', Value: { value: this.formNameFilter ? this.formNameFilter.join(',') : null } };
      this.filter.filters.formTypeFilter = { Name: 'formTypeFilter', Value: { value: this.formTypeFilter } };
      this.filter.filters.formActiveFilter = { Name: 'formActiveFilter', Value: { value: this.formActiveFilter } };
      this.store.dispatch(getFBFormTable({
        filter: deepClone(this.filter)
      }));
    }
  }

  changedFilters() {
    if (this.formNameFilter != null && this.formNameFilter.length <= 0){
      this.formNameFilter = null;
    }
    this.formTable.reset();
    this.loadPage(this.filter);
    this.saveFilters();
  }

  clearFilter() {
    this.filter.first = 0;
    this.formNameFilter = null;
    this.formTypeFilter = null;
    this.formActiveFilter = null;
    sessionStorage.clear();
    this.changedFilters();
    this.formTable.reset();
  }

  saveFilters(){
    var jsonData ={}
    if (window.sessionStorage) {
      // save filter to storage
      jsonData = {
        formNameFilter: this.formNameFilter,
        formTypeFilter: this.formTypeFilter,
        formActiveFilter: this.formActiveFilter,
      };

    }
    sessionStorage.setItem("formFilter", JSON.stringify(jsonData));
  }

  openMultiSelect(multiselect: MultiSelect) {
    multiselect.show();
  }

  toggleHideFilter() {
    if (this.hideFilter) {
      this.hideFilter = false;
      this.showHideString = "Hide";
    }
    else {
      this.hideFilter = true;
      this.showHideString = "Show";
    }
  }
  
  editForm(form: FBFormModel) {
    this.router.navigate(['/form-builder/form/' + form.id]);
  }

  deleteForm(form: FBFormModel) {
    this.confirmationService.confirm({
      header: 'Delete',
      message: 'Are you sure you want to delete this form?',
      acceptLabel: 'Delete',
      rejectLabel: 'Cancel',
      accept: () => {
        this.store.dispatch(deleteFBForm({ formID: form.id }));
      }
    });
  }

  copyForm(form: FBFormModel) {
    this.store.dispatch(duplicateFBForm({ formID: form.id }));
  }

  editFormRolesAssignment(form: FBFormModel) {
    this.router.navigate(['/form-builder/form-assignment-roles/' + form.id]);
  }

}

