<form class="card mt-2">
  <div class="card-body">
    <div class="float-right" *ngIf="canEdit">
      <button *ngIf="canEditPermissions == true" (click)="openPermissionsModal(mypermissionsmodal)" data-toggle="modal" data-target="#mypermissionsmodal" class="btn btn-success mr-2 mt-1">Permissions</button>
      <button (click)="openCreateEquipment()" class="btn btn-secondary mr-2 mt-1">Create New Equipment</button>
      <button (click)="openAssignEquipment()" class="btn btn-secondary mr-2 mt-1">Assign Equipment</button>
      <button (click)="openReturnEquipment()" class="btn btn-secondary  mt-1">Return Equipment</button>
    </div>
    <h2 class="mb-3">Equipment</h2>
    <h5>Filter <button class="btn btn-link" (click)="toggleHideFilter()">{{showHideString}}</button></h5>
    <div class="row mb-1" [hidden]="hideFilter">
      <div class="col-6">
        <div class="row mb-1">
          <div class="col-4 d-flex align-items-center">
            <h6>Type:</h6>
          </div>
          <div class="col-8 ">
            <select class="form-control ml-2 w-100 mb-2" placeholder="All" [(ngModel)]="filterType" (ngModelChange)="loadPage(this.filter)" [ngModelOptions]="{standalone: true}" required>
              <option [ngValue]="null">All</option>
              <option value="HeadPhones">AirPods/Headphones</option>
              <option value="Stylus">Apple Pen/Stylus</option>
              <option value="Apron">Apron</option>
              <option value="Badge">Badge</option>
              <option value="Books / Literature">Books/Literature</option>
              <option value="Charger">Charger</option>
              <option value="Desktop Computer">Desktop Computer</option>
              <option value="First Aid Kit">First Aid Kit</option>
              <option value="Key">Key/Key Card</option>
              <option value="Keyboard">Keyboard</option>
              <option value="Lanyard">Lanyard</option>
              <option value="Monitor">Monitor</option>
              <option value="Mouse">Mouse</option>
              <option value="Notebook">Notebook</option>
              <option value="Tablet">Tablet</option>
              <option value="Smartphone">Smartphone</option>
              <option value="Software License">Software License</option>
              <option value="Walkie Talkies">Walkie Talkies</option>
              <option value="Other">Other</option>
            </select>
          </div>
        </div>

        <div class="row mb-1">
          <div class="col-4 d-flex align-items-center">
            <h6>Serial:</h6>
          </div>
          <div class="col-8 ">
            <input type="text" class="form-control ml-2 w-100 mb-2" placeholder="All" [(ngModel)]="filterSerial" (ngModelChange)="loadPage(this.filter)" [ngModelOptions]="{standalone: true}" />
          </div>
        </div>

        <div class="row mb-1">
          <div class="col-4 d-flex align-items-center">
            <h6>Brand:</h6>
          </div>
          <div class="col-8 ">
            <input type="text" class="form-control ml-2 w-100 mb-2" placeholder="All" [(ngModel)]="filterBrand" (ngModelChange)="loadPage(this.filter)" [ngModelOptions]="{standalone: true}" />
          </div>
        </div>

        <div class="row mb-1">
          <div class="col-4 d-flex align-items-center">
            <h6>Model:</h6>
          </div>
          <div class="col-8 ">
            <input type="text" class="form-control ml-2 w-100 mb-2" placeholder="All" [(ngModel)]="filterModel" (ngModelChange)="loadPage(this.filter)" [ngModelOptions]="{standalone: true}" />
          </div>
        </div>

        <div class="row mb-1">
          <div class="col-4 d-flex align-items-center">
            <h6>{{generalSettings?.businessName}} #:</h6>
          </div>
          <div class="col-8 ">
            <input type="text" class="form-control ml-2 w-100 mb-2" placeholder="All" [(ngModel)]="filterAssetTag" (ngModelChange)="loadPage(this.filter)" [ngModelOptions]="{standalone: true}" />
          </div>
        </div>

        <div class="row mb-1">
          <div class="col-4 d-flex align-items-center">
            <h6>Service Tag:</h6>
          </div>
          <div class="col-8 ">
            <input type="text" class="form-control ml-2 w-100 mb-2" placeholder="All" [(ngModel)]="filterServiceTag" (ngModelChange)="loadPage(this.filter)" [ngModelOptions]="{standalone: true}" />
          </div>
        </div>

        <div class="row mb-1">
          <div class="col-4 d-flex align-items-center">
            <h6>ExpressServiceCode:</h6>
          </div>
          <div class="col-8 ">
            <input type="text" class="form-control ml-2 w-100 mb-2" placeholder="All" [(ngModel)]="filterExpressServiceCode" (ngModelChange)="loadPage(this.filter)" [ngModelOptions]="{standalone: true}" />
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="row mb-1">
          <div class="col-4 d-flex align-items-center">
            <h6>User: - 
              <p-checkbox id="includeTerminated" name="includeTerminated" styleClass="form-check-input" [(ngModel)]="includeTerminated" (onChange)="includeTerminatedChange($event)"></p-checkbox>
              <label style="font-size:.55rem;" class=" ml-4" for="includeTerminated">: (Include Separated Employees)</label>
            </h6>
          </div>
          <div class="col-8 ">
            <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [filter]="true" [resetFilterOnHide]="true" [options]="userList" [(ngModel)]="filterUserId" (ngModelChange)="loadPage(this.filter)" [ngModelOptions]="{standalone: true}"></p-dropdown>
          </div>
        </div>

        <div class="row mb-1">
          <div class="col-4 d-flex align-items-center">
            <h6>Campus:</h6>
          </div>
          <div class="col-8 ">
            <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [filter]="true" [resetFilterOnHide]="true" [options]="programList" [(ngModel)]="filterProgramId" (ngModelChange)="loadPage(this.filter)" [ngModelOptions]="{standalone: true}"></p-dropdown>
          </div>
        </div>

        <div class="row mb-1">
          <div class="col-4 d-flex align-items-center">
            <h6>Assigned Status:</h6>
          </div>
          <div class="col-8 ">
            <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="assignedStatusOptions" [(ngModel)]="filterAssignedStatus" (ngModelChange)="loadPage(this.filter)" [ngModelOptions]="{standalone: true}"></p-dropdown>
          </div>
        </div>

        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <h6>Assigned Date Range:</h6>
          </div>
          <div class="col-8">
            <i *ngIf="!dateRangeList" class="material-icons rotate text-muted">loop</i>
            <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="dateRangeList" [(ngModel)]="assignedDateRange" (ngModelChange)="changedAssignedDateRange()" [ngModelOptions]="{standalone: true}"></p-dropdown>
          </div>
        </div>

        <div class="row" *ngIf="isCustomAssignedDateRange">
          <div class="col-4 d-flex align-items-center" style="padding-left: 35px;">
            <h6>Start Date:</h6>
          </div>
          <div class="col-8">
            <input type="date" class="form-control ml-2 w-100 mb-2" [(ngModel)]="assignedStartDate" (blur)="loadPage(this.filter)" [ngModelOptions]="{standalone: true}" />
          </div>
          <div class="col-4 d-flex align-items-center" style="padding-left: 35px;">
            <h6>End Date:</h6>
          </div>
          <div class="col-8">
            <input type="date" class="form-control ml-2 w-100 mb-2" [(ngModel)]="assignedEndDate" (blur)="loadPage(this.filter)" [ngModelOptions]="{standalone: true}" />
          </div>
        </div>

        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <h6>Returned Date Range:</h6>
          </div>
          <div class="col-8">
            <i *ngIf="!dateRangeList" class="material-icons rotate text-muted">loop</i>
            <p-dropdown styleClass="form-control ml-2 w-100 mb-2" [options]="dateRangeList" [(ngModel)]="returnedDateRange" (ngModelChange)="changedReturnedDateRange()" [ngModelOptions]="{standalone: true}"></p-dropdown>
          </div>
        </div>

        <div class="row" *ngIf="isCustomReturnedDateRange">
          <div class="col-4 d-flex align-items-center" style="padding-left: 35px;">
            <h6>Start Date:</h6>
          </div>
          <div class="col-8">
            <input type="date" class="form-control ml-2 w-100 mb-2" [(ngModel)]="returnedStartDate" (blur)="loadPage(this.filter)" [ngModelOptions]="{standalone: true}" />
          </div>
          <div class="col-4 d-flex align-items-center" style="padding-left: 35px;">
            <h6>End Date:</h6>
          </div>
          <div class="col-8">
            <input type="date" class="form-control ml-2 w-100 mb-2" [(ngModel)]="returnedEndDate" (blur)="loadPage(this.filter)" [ngModelOptions]="{standalone: true}" />
          </div>
        </div>

        <div class="row mb-1">
          <div class="col-4 d-flex align-items-center">
            <h6>Assigned Signed:</h6>
          </div>
          <div class="col-8 " style="padding-left: 25px;">
            <p-selectButton styleClass="w-100" [options]="signedOptions" [(ngModel)]="filterAssignedSigned" (ngModelChange)="loadPage(this.filter)" [ngModelOptions]="{standalone: true}"></p-selectButton>
          </div>
        </div>

        <div class="row mb-1">
          <div class="col-4 d-flex align-items-center">
            <h6>Returned Signed:</h6>
          </div>
          <div class="col-8 " style="padding-left: 25px;">
            <p-selectButton styleClass="w-100" [options]="signedOptions" [(ngModel)]="filterReturnedSigned" (ngModelChange)="loadPage(this.filter)" [ngModelOptions]="{standalone: true}"></p-selectButton>
          </div>
        </div>

        <div class="row mb-1">
          <div class="col-4 d-flex align-items-center">
            <h6>Archived:</h6>
          </div>
          <div class="col-8 " style="padding-left: 25px;">
            <p-selectButton styleClass="w-100" [options]="yesNoAllOptions" [(ngModel)]="filterArchived" (ngModelChange)="loadPage(this.filter)" [ngModelOptions]="{standalone: true}"></p-selectButton>
          </div>
        </div>
      </div>
    </div>

    <div class="ui-column-buttons">
      <div>Display: </div>
      <div>
        <button (click)="editColumns($event.target.value)" [style.background-color]="!type ? 'white': 'lightgrey'" value="type">Type</button>
        <button (click)="editColumns($event.target.value)" [style.background-color]="!serial ? 'white': 'lightgrey'" value="serial">Serial</button>
        <button (click)="editColumns($event.target.value)" [style.background-color]="!brandModel ? 'white': 'lightgrey'" value="brandModel">Brand</button>
        <button (click)="editColumns($event.target.value)" [style.background-color]="!tags ? 'white': 'lightgrey'" value="tags">Tags</button>
        <button (click)="editColumns($event.target.value)" [style.background-color]="!user ? 'white': 'lightgrey'" value="user">User</button>
        <button (click)="editColumns($event.target.value)" [style.background-color]="!campus ? 'white': 'lightgrey'" value="campus">Assignment&nbsp;Campus</button>
        <button (click)="editColumns($event.target.value)" [style.background-color]="!mainCampus ? 'white': 'lightgrey'" value="mainCampus">Main&nbsp;Campus</button>
        <button (click)="editColumns($event.target.value)" [style.background-color]="!dateAssigned ? 'white': 'lightgrey'" value="dateAssigned">Date&nbsp;Assigned</button>
        <button (click)="editColumns($event.target.value)" [style.background-color]="!returnDate ? 'white': 'lightgrey'" value="returnDate">Return&nbsp;Date</button>
        <button (click)="editColumns($event.target.value)" [style.background-color]="!signed ? 'white': 'lightgrey'" value="signed">Signed</button>
        <button (click)="editColumns($event.target.value)" [style.background-color]="!returned ? 'white': 'lightgrey'" value="returned">Returned</button>
        <button (click)="editColumns($event.target.value)" [style.background-color]="!department ? 'white': 'lightgrey'" value="department">Dept.</button>
        <button (click)="editColumns($event.target.value)" [style.background-color]="!price ? 'white': 'lightgrey'" value="price">Cost</button>
        <button (click)="editColumns($event.target.value)" [style.background-color]="!vendor ? 'white': 'lightgrey'" value="vendor">Vendor</button>
        <button (click)="editColumns($event.target.value)" [style.background-color]="!purchaseLocation ? 'white': 'lightgrey'" value="purchaseLocation">Purchased&nbsp;From</button>
        <button (click)="editColumns($event.target.value)" [style.background-color]="!storageLocation ? 'white': 'lightgrey'" value="storageLocation">Storage&nbsp;Location</button>
        <button (click)="editColumns($event.target.value)" [style.background-color]="!paymentMethod ? 'white': 'lightgrey'" value="paymentMethod">Payment&nbsp;Method</button>
        <button (click)="editColumns($event.target.value)" [style.background-color]="!size ? 'white': 'lightgrey'" value="size">Size</button>
        <button (click)="editColumns($event.target.value)" [style.background-color]="!classCode ? 'white': 'lightgrey'" value="classCode">Class&nbsp;Code</button>
        <button (click)="editColumns($event.target.value)" [style.border-right]="'solid 1px black'" [style.background-color]="!classification ? 'white': 'lightgrey'" value="classification">Classification</button>
      </div>
    </div>

    <i *ngIf="loadingAssignedEquipmentList" class="material-icons rotate text-muted">loop</i>
    <p-table [value]="equipments?.result" [responsive]="true"
             [paginator]="true" [rows]="20" [totalRecords]="equipments?.totalRecords" [lazy]="true"
             (onLazyLoad)="loadPage($event)">
      <ng-template pTemplate="caption" class="d-flex">
        <div class="p-d-flex exportbuttons">
          <div>
            <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-excel p-mr-2" pTooltip="XLS" tooltipPosition="bottom"></button>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th width="245px" textAlign="center"></th>
          <th [hidden]="!type" [pSortableColumn]="'equipment.Type'">Type <p-sortIcon [field]="'equipment.Type'"></p-sortIcon></th>
          <th [hidden]="!serial" [pSortableColumn]="'equipment.serialNumber'">Serial <p-sortIcon [field]="'equipment.serialNumber'"></p-sortIcon></th>
          <th [hidden]="!brandModel" [pSortableColumn]="'brand-model'">Brand - Model <p-sortIcon [field]="'brand-model'"></p-sortIcon></th>
          <th width="150px" [hidden]="!tags" [pSortableColumn]="'equipment.assetTag'">Tags <p-sortIcon [field]="'equipment.assetTag'"></p-sortIcon></th>
          <th [hidden]="!user" [pSortableColumn]="'lastAssignedEquipment.userName'">User <p-sortIcon [field]="'lastAssignedEquipment.userName'"></p-sortIcon></th>
          <th [hidden]="!campus" [pSortableColumn]="'lastAssignedEquipment.programName'">Assignment Campus <p-sortIcon [field]="'lastAssignedEquipment.programName'"></p-sortIcon></th>
          <th [hidden]="!mainCampus" [pSortableColumn]="'equipment.mainProgramName'">Main Campus <p-sortIcon [field]="'equipment.mainProgramName'"></p-sortIcon></th>
          <th [hidden]="!dateAssigned" [pSortableColumn]="'lastAssignedEquipment.dateAssigned'">Date Assigned <p-sortIcon [field]="'lastAssignedEquipment.dateAssigned'"></p-sortIcon></th>
          <th [hidden]="!returnDate" [pSortableColumn]="'lastAssignedEquipment.returnDate'">Return Date<p-sortIcon [field]="'lastAssignedEquipment.returnDate'"></p-sortIcon></th>
          <th width="78px" [hidden]="!signed" [pSortableColumn]="'lastAssignedEquipment.assignSigned'">Signed<p-sortIcon [field]="'lastAssignedEquipment.assignSigned'"></p-sortIcon></th>
          <th [hidden]="!returned" [pSortableColumn]="'lastAssignedEquipment.dateReturned'">Returned <p-sortIcon [field]="'lastAssignedEquipment.dateReturned'"></p-sortIcon></th>
          <th [hidden]="!department" [pSortableColumn]="'equipment.department'">Dept. <p-sortIcon [field]="'equipment.department'"></p-sortIcon></th>
          <th [hidden]="!price" [pSortableColumn]="'equipment.pricing'">Cost <p-sortIcon [field]="'equipment.pricing'"></p-sortIcon></th>
          <th [hidden]="!vendor" [pSortableColumn]="'equipment.purchaseVendor'">Vendor <p-sortIcon [field]="'equipment.purchaseVendor'"></p-sortIcon></th>
          <th [hidden]="!purchaseLocation" [pSortableColumn]="'equipment.purchaseLocation'">Purchased From<p-sortIcon [field]="'equipment.purchaseLocation'"></p-sortIcon></th>
          <th [hidden]="!storageLocation" [pSortableColumn]="'equipment.storedLocation'">Storage Location <p-sortIcon [field]="'equipment.storedLocation'"></p-sortIcon></th>
          <th [hidden]="!paymentMethod" [pSortableColumn]="'equipment.paymentMethod'">Payment Method <p-sortIcon [field]="'equipment.paymentMethod'"></p-sortIcon></th>
          <th [hidden]="!size" [pSortableColumn]="'equipment.size'">Size <p-sortIcon [field]="'equipment.size'"></p-sortIcon></th>
          <th [hidden]="!classCode">Class Code</th>
          <th [hidden]="!classification">Classification</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-equipment>
        <tr [attr.title]="equipment.lastAssignedEquipment && equipment.lastAssignedEquipment.needsToUpdateAssignedPDF ? 'Need to update assigned PDF' : ''" [ngClass]="{'red-highlight-row': equipment.lastAssignedEquipment && equipment.lastAssignedEquipment.needsToUpdateAssignedPDF}">
          <td>
            <button class="btn btn-secondary mr-2 mb-2" *ngIf="canEdit" (click)="editEquipment(equipment)" title="Edit equipment">
              <i class="fas fa-edit"></i>
            </button>
            <button class="btn btn-secondary mr-2 mb-2" *ngIf="canEdit && !equipment.equipment.archived" (click)="openAssignEquipment(equipment)" title="Assign Equipment">
              <i class="fas fa-long-arrow-alt-down"></i>
            </button>
            <button class="btn btn-secondary mr-2 mb-2" *ngIf="canEdit && equipment.lastAssignedEquipment && !equipment.lastAssignedEquipment.dateReturned && equipment.lastAssignedEquipment.assignSigned" (click)="openReturnEquipment(equipment)" title="Return Equipment">
              <i class="fas fa-undo"></i>
            </button>
            <button class="btn btn-secondary mr-2 mb-2" *ngIf="canEdit && equipment.lastAssignedEquipment && !!equipment.lastAssignedEquipment.dateReturned && !equipment.lastAssignedEquipment.returnSigned" (click)="signReturnEquipment(equipment)" title="Sign Return">
              <i class="fas fa-file-signature"></i>
            </button>
            <button class="btn btn-secondary mr-2 mb-2" *ngIf="canDelete && canEdit && equipment.equipment && !equipment.equipment.archived" (click)="deleteEquipment(equipment)" title="Delete equipment">
              <i class="far fa-trash-alt"></i>
            </button>
            <button class="btn btn-secondary mr-2 mb-2" *ngIf="canEdit && equipment.equipment && !!equipment.equipment.archived" (click)="unarchiveEquipment(equipment)" title="Unarchive equipment">
              <i class="fas fa-redo"></i>
            </button>
            <button class="btn btn-secondary mr-2 mb-2" *ngIf="equipment.lastAssignedEquipment && (equipment.lastAssignedEquipment.userID != null || equipment.lastAssignedEquipment.programID != null)" (click)="editAssignment(equipment)" title="Edit Assigned Info">
              <i class="far fa-calendar"></i>
            </button>
            <button class="btn btn-secondary mr-2 mb-2" *ngIf="equipment.lastAssignedEquipment" (click)="viewAssignedDocument(equipment)" title="View Assign Document">
              <i class="fas fa-file-download"></i>
            </button>
            <button class="btn btn-secondary mr-2 mb-2" *ngIf="equipment.lastAssignedEquipment && !!equipment.lastAssignedEquipment.dateReturned" (click)="viewReturnedDocument(equipment)" title="View Return Document">
              <i class="fas fa-file-upload"></i>
            </button>
          </td>
          <td [hidden]="!type">
            <span class="ui-column-title">Type</span>
            {{equipment.equipment.type}}
          </td>
          <td [hidden]="!serial">
            <span class="ui-column-title">Serial</span>
            {{equipment.equipment.serialNumber }}
          </td>

          <td [hidden]="!brandModel">
            <span class="ui-column-title">Brand - Model</span>
            {{equipment.equipment.brand }} - {{equipment.equipment.model }}
          </td>

          <td style="font-size:10px;" [hidden]="!tags">
            <span class="ui-column-title">Tags</span>
            {{generalSettings?.businessName}} #: {{equipment.equipment.assetTag }}<br>
            Service Tag: {{equipment.equipment.serviceTag }}<br>
            Express Service Code: {{equipment.equipment.expressServiceCode }}
          </td>
          <td [hidden]="!user">
            <span class="ui-column-title">User</span>
            <span *ngIf="!equipment.lastAssignedEquipment?.dateReturned">
              {{usersNames[equipment.lastAssignedEquipment?.userID]}}
            </span>
          </td>
          <td [hidden]="!campus">
            <span class="ui-column-title">Assignment Campus</span>
            {{equipment.lastAssignedEquipment?.programName}}
          </td>
          <td [hidden]="!mainCampus">
            <span class="ui-column-title">Main Campus</span>
            {{equipment.equipment.mainProgramName}}
          </td>
          <td [hidden]="!dateAssigned">
            <span class="ui-column-title">Date Assigned</span>
            {{equipment.lastAssignedEquipment?.dateAssigned | date:'shortDate' }}
          </td>
          <td [hidden]="!returnDate">
            <span class="ui-column-title">Return Date</span>
            {{equipment.lastAssignedEquipment?.returnDate | date:'shortDate' }}
          </td>

          <td [hidden]="!signed">
            <span class="ui-column-title">Signed</span>
            <i *ngIf="equipment.lastAssignedEquipment?.assignSigned" class="fas fa-check-circle text-success"></i>
            <i *ngIf="!equipment.lastAssignedEquipment?.assignSigned" class="fas fa-times-circle text-danger"></i>


          </td>
          <td [title]="equipment.lastAssignedEquipment?.returnNotes" style="font-size:9px;" [hidden]="!returned">
            <span class="ui-column-title">Returned </span>
            <span *ngIf="equipment.lastAssignedEquipment?.dateReturned">
              Date: {{equipment.lastAssignedEquipment?.dateReturned? (equipment.lastAssignedEquipment.dateReturned | date:'shortDate'):'-' }}<br>
              Returned by:  {{usersNames[equipment.lastAssignedEquipment?.userID]}} <br>
              Status: {{equipment.lastAssignedEquipment?.returnStatus }}<br>
              Signed:
              <i *ngIf="equipment.lastAssignedEquipment?.returnSigned" class="fas fa-check-circle text-success"></i>
              <i *ngIf="!equipment.lastAssignedEquipment?.returnSigned" class="fas fa-times-circle text-danger"></i>
            </span>
          </td>
          <td [hidden]="!department">
            <span class="ui-column-title">Dept.</span>
            {{equipment.equipment.department }}
          </td>
          <td [hidden]="!price">
            <span class="ui-column-title">Price</span>
            {{equipment.equipment.pricing }}
          </td>
          <td [hidden]="!vendor">
            <span class="ui-column-title">Vendor</span>
            {{equipment.equipment.purchaseVendor }}
          </td>
          <td [hidden]="!purchaseLocation">
            <span class="ui-column-title">Purchased From</span>
            {{equipment.equipment.purchaseLocation }}
          </td>
          <td [hidden]="!storageLocation">
            <span class="ui-column-title">Storage Location</span>
            {{equipment.equipment.storedLocation }}
          </td>
          <td [hidden]="!paymentMethod">
            <span class="ui-column-title">Payment Method</span>
            {{equipment.equipment.paymentMethod }}
          </td>
          <td [hidden]="!size">
            <span class="ui-column-title">Size</span>
            {{equipment.equipment.size }}
          </td>
          <td [hidden]="!classCode">
            <span class="ui-column-title">Class Code</span>
            {{equipment.equipment.classCode }}
          </td>
          <td [hidden]="!classification">
            <span class="ui-column-title">Classification</span>
            {{equipment.equipment.classificationOther ? equipment.equipment.classificationOther : equipment.equipment.classification}}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</form>

<ng-template #equipmentNotReturnedModal let-modal>
  <app-equipment-not-returned
  [equipment]="equipmentNotReturned"
  (myEvent)="modal.close($event)"
  (saveEquipment)="openAssignEquipment($event)" 
  >
  </app-equipment-not-returned>
</ng-template>

<ng-template #mypermissionsmodal class="modal-index" let-modal>
  <div class="modal-body">
      <app-equipment-type-permissions 
      (myEvent)="closePermissionsModal($event)"  
      ></app-equipment-type-permissions>
  </div>
</ng-template>

<p-dialog *ngIf="displayAssignEquipment" header="Assign Equipment" [focusTrap]="false" [modal]="true" [styleClass]="'top-dialog'"
          [style]="{width: '95vw'}" [(visible)]="displayAssignEquipment">
  <app-assign-equipment></app-assign-equipment>
</p-dialog>

<p-dialog header="Return Equipment" [modal]="true" [styleClass]="'top-dialog'"
          [style]="{width: '95vw'}" [(visible)]="displayReturnEquipment">
  <app-return-equipment></app-return-equipment>
</p-dialog>


<p-dialog [modal]="true" appendTo="body" [style]="{width: '100vw'}" [(visible)]="displayCreateEquipment">
  <app-create-equipment></app-create-equipment>
</p-dialog>
