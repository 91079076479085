import { BaseComponent } from './../../../../core/abstracts/baseComponent';
import { BenefitPlanModel, BenefitsDependentModel, BenefitPlanTypeModel, BenefitPlanFullModel, BenefitPlanDeclineReasonModel, EmployerContributionModel, BenefitPlanDeclinedModel, BenefitPlanEnrollmentSetModel, BenefitPlanEnrollmentModel } from './../../../../models/benefitsModels';
import { PagingResultsModel } from '../../../../models/pagingResultsModel';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../core/store';
import { Actions, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { MultiSelect } from 'primeng/multiselect';
import { AlertService } from '../../../../shared/services/alert.service';
import { FormGroup } from '@angular/forms';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { tap, filter } from 'rxjs/operators';
import { loadProfile } from 'src/app/core/store/profile/profile.actions';
import {
  //startChangingEnrollment,
  //cancelChangingEnrollment,
  //startChangingEnrollmentSuccess,
  //cancelChangingEnrollmentSuccess,
  //loadBenefitPlanEnrollmentSetListFail,
  //loadBenefitPlanEnrollmentSetList,
  //saveBenefitsEnrollmentSuccess,
  getBenefitsFormDocumentsList
} from './../../../../core/store/benefits/benefits.actions';
import { UserProfileModel } from 'src/app/models/userProfileModel';
import { addDays, deepClone, getDateString } from '../../../../helpers/utils';
import { BenefitsService } from '../../../../core/services/benefits.service';

@Component({
  selector: 'app-benefits-enrollment-set',
  templateUrl: './benefits-enrollment-set.component.html',
  styleUrls: ['./benefits-enrollment-set.component.scss']
})
export class BenefitsEnrollmentSetComponent extends BaseComponent implements OnInit {


  _benefitPlanEnrollmentSet: BenefitPlanEnrollmentSetModel;
  get benefitPlanEnrollmentSet(): BenefitPlanEnrollmentSetModel {
    return this._benefitPlanEnrollmentSet;
  }
  @Input() set benefitPlanEnrollmentSet(value: BenefitPlanEnrollmentSetModel) {
    this._benefitPlanEnrollmentSet = value;
    this.initBenefitPlanEnrollmentSet();
  }
  
  @Input() previousBenefitPlanEnrollmentSet: BenefitPlanEnrollmentSetModel | null;
  @Input() isOpenEnrollment: boolean = false;
  @Input() isNewHire: boolean = false;
  @Input() hasUpdatedProfile: boolean = false;
  @Input() notSignedDocs: number = 0;
  @Input() editClicked: boolean;
  @Output() editClickedChange = new EventEmitter<boolean>();

  @Input() availableBenefitPlans: BenefitPlanFullModel[];

  profile: UserProfileModel;
  
  allBenefitPlanTypes: BenefitPlanTypeModel[];
  benefitPlanTypes: BenefitPlanTypeModel[];


  selectedBenefitPlans: BenefitPlanFullModel[] = [];
  checkedDependent;
  rejectedBenefitPlanTypes: { type: BenefitPlanTypeModel, reason: BenefitPlanDeclineReasonModel, otherReason: string }[] = [];
  
  // change

  openChangeEnrollment = false;
  lifeEventOptions: SelectItem[] = [
  ];
  lifeEventSelected;
  lifeEventOther;
  lifeEventDate;
  lifeEventMinDate: Date;
  lifeEventMaxDate: Date;
  
  savingBenefitEnrollment: boolean = false;
  constructor(
    private actions$: Actions,
    private alertService: AlertService,
    private confirmationService: ConfirmationService,
    private store: Store<State>,
    private router: Router,
    private benefitService: BenefitsService,
  ) {
    super();
    this.lifeEventMinDate = addDays(new Date(), -30);
    this.lifeEventMaxDate = new Date();
  }

  ngOnInit() {


    this.subs.push(


      this.store.select(s => s.profileState.profile).subscribe(profile => {
        this.profile = profile;
        if (this.profile && this.profile.employee && this.profile.employee.trainingStartDate  &&  new Date(this.profile.employee.trainingStartDate) > this.lifeEventMinDate)
          this.lifeEventMinDate = new Date(this.profile.employee.trainingStartDate); // not before hire date
      }),

      this.store.select(s => s.benefitsState.benefitsLifeChangingEvents).subscribe(benefitsLifeChangingEvents => {
        if (benefitsLifeChangingEvents) {
          this.lifeEventOptions = benefitsLifeChangingEvents.map(x => ({ label: x.eventLabel, value: x.id }));
        }
      }),
      /*
      this.actions$.pipe(
        ofType(startChangingEnrollmentSuccess, cancelChangingEnrollmentSuccess),
        tap(action => {
          
          if (action.benefitPlanEnrollmentSet) {
            this.benefitPlanEnrollmentSet = action.benefitPlanEnrollmentSet
          } else {
            this.benefitPlanEnrollmentSet = {
              plansYear: this.benefitPlanEnrollmentSet.plansYear
            } as BenefitPlanEnrollmentSetModel;
          }
          this.editClicked = this.benefitPlanEnrollmentSet?.activatedDate == null && !this.benefitPlanEnrollmentSet?.createdDate;
          
        })
      ).subscribe(),


      */
    );

  }

  clickChangeEnrollment() {
    if (this.isNewHire && !this.benefitPlanEnrollmentSet.createdDate) {
      //
      this.lifeEventDate = getDateString(new Date(this.profile.employee.trainingStartDate));
      this.startChangingEnrollment(2);
    } else if (this.isOpenEnrollment) {
      this.startChangingEnrollment(1);
    } else {

      this.openChangeEnrollment = true;
    }
  }
  clickCancelChangingEnrollment() {
    this.openChangeEnrollment = false;

  }
  clickStartChangingEnrollment() {
    if (this.lifeEventSelected === 7 && !this.lifeEventOther) {
      this.alertService.error('Please fill in the other life changing event field.');
      return;
    }
    if (!this.lifeEventDate) {
      this.alertService.error('Please fill in date the life changing event occured.');
      return;
    }
    this.openChangeEnrollment = false;

    this.startChangingEnrollment(this.lifeEventSelected);
    /*
    this.store.dispatch(startChangingEnrollment({
      enrollmentYear: this.benefitPlanEnrollmentSet.plansYear,
      lifeChangingEventID: this.lifeEventSelected,
      lifeChangingEventOther: this.getSelectedLifeEventLabel() === 'Other' ? this.lifeEventOther : '',
      lifeChangingEventDate: this.getSelectedLifeEventLabel() !== 'New Hire' ? this.lifeEventDate : null,
    }));
    */
  }

  startChangingEnrollment(lifeChangingEventID: number)
  {
    /*
     
    this.store.dispatch(startChangingEnrollment({
      enrollmentYear: this.benefitPlanEnrollmentSet.plansYear,
      lifeChangingEventID: this.lifeEventSelected,
      lifeChangingEventOther: this.getSelectedLifeEventLabel() === 'Other' ? this.lifeEventOther : '',
      lifeChangingEventDate: this.getSelectedLifeEventLabel() !== 'New Hire' ? this.lifeEventDate : null,
    }));
    */
    this.savingBenefitEnrollment = true;
    this.benefitService.postStartChangingEnrollment(
      lifeChangingEventID,
      lifeChangingEventID == 7 ? this.lifeEventOther : null,
      lifeChangingEventID > 1 ? this.lifeEventDate : null,
      this.benefitPlanEnrollmentSet.plansYear).subscribe(result => {
        this.openChangeEnrollment = false;
        this.savingBenefitEnrollment = false;
        if (result) {
          this.benefitPlanEnrollmentSet = result
        } else {
          this.benefitPlanEnrollmentSet = {
            plansYear: this.benefitPlanEnrollmentSet.plansYear
          } as BenefitPlanEnrollmentSetModel;
        }
        this.editClicked = !this.benefitPlanEnrollmentSet?.activatedDate && !!this.benefitPlanEnrollmentSet?.createdDate;
        this.editClickedChange.emit(this.editClicked);
      }, (error) => {
        this.alertService.error('Error saving enrollment.' + error.message);
      });
  }

  clickCancelEnrollment() {
    /*
    this.store.dispatch(cancelChangingEnrollment({
      enrollmentYear: this.benefitPlanEnrollmentSet.plansYear,
    }));
    */
    this.savingBenefitEnrollment = true;
    this.benefitService.cancelChangingEnrollment(
      this.benefitPlanEnrollmentSet.plansYear).subscribe(result => {

        this.savingBenefitEnrollment = false;
        if (result) {
          this.benefitPlanEnrollmentSet = result
        } else {
          this.benefitPlanEnrollmentSet = {
            plansYear: this.benefitPlanEnrollmentSet.plansYear
          } as BenefitPlanEnrollmentSetModel;
        }

        this.editClicked = !this.benefitPlanEnrollmentSet?.activatedDate && !!this.benefitPlanEnrollmentSet?.createdDate;
        this.editClickedChange.emit(this.editClicked);
      }, (error) => {
        this.alertService.error('Error on cancel enrollment. ' + (error.message || error));
      });
  }


  initBenefitPlanEnrollmentSet()
  {
    this.selectedBenefitPlans = [];
    this.rejectedBenefitPlanTypes = [];
    this.checkedDependent = { };
    
    if (!this.benefitPlanEnrollmentSet)
      return;
    
    if (this.benefitPlanEnrollmentSet.enrollments) {
      this.benefitPlanEnrollmentSet.enrollments.forEach(enrollment => {
        const plan: BenefitPlanFullModel = deepClone(enrollment.benefitPlan);
        plan.enrollment = deepClone(enrollment);
        this.selectedBenefitPlans.push(plan);
        if (enrollment.dependents && enrollment.dependents.length) {
          if (!this.checkedDependent[plan.benefitPlan.benefitPlanTypeID + '']) {
            this.checkedDependent[plan.benefitPlan.benefitPlanTypeID + ''] = {};
          }
          enrollment.dependents.forEach(dependent => {
            this.checkedDependent[plan.benefitPlan.benefitPlanTypeID + ''][dependent.id + ''] = true;
          });
        }
      });
    }
    if (this.benefitPlanEnrollmentSet.declineds) {
      this.benefitPlanEnrollmentSet.declineds.forEach(declined => {
        this.rejectedBenefitPlanTypes.push({ type: declined.benefitPlanType, reason: declined.declineReason, otherReason: declined.otherReason });
      });
    }

    if (!this.selectedBenefitPlans.some(x => x.benefitPlan.benefitPlanTypeID === 8)) { // 'Employee Perk'
      const perkPlans = this.availableBenefitPlans.filter(x => x.benefitPlan.benefitPlanTypeID === 8);
      for (const plan of perkPlans) {

        if (!plan.benefitPlan.active)
          continue;

        if (this.benefitPlanEnrollmentSet.plansYear) {
          if (plan.benefitPlan.dateStart && new Date(plan.benefitPlan.dateStart).getFullYear() > this.benefitPlanEnrollmentSet.plansYear)
            continue;
          if (plan.benefitPlan.dateEnd && new Date(plan.benefitPlan.dateEnd).getFullYear() < this.benefitPlanEnrollmentSet.plansYear)
            continue;
        }
        else if (plan.benefitPlan.dateEnd && new Date(plan.benefitPlan.dateEnd) < new Date())
          continue;


        this.selectedBenefitPlans.push(plan);
      }
    }



    //this.lifeEventMinDate = addDays(new Date(), -30);
    if (this.benefitPlanEnrollmentSet.lifeChangingEventDate
      && new Date(this.benefitPlanEnrollmentSet.lifeChangingEventDate) > this.lifeEventMinDate) {
      this.lifeEventMinDate = new Date(this.benefitPlanEnrollmentSet.lifeChangingEventDate);
    }
    else if (this.profile && this.profile.employee && this.profile.employee.trainingStartDate && new Date(this.profile.employee.trainingStartDate) > this.lifeEventMinDate) { 
      this.lifeEventMinDate = new Date(this.profile.employee.trainingStartDate); // not before hire date
    }
  }
  

  getLifeEventLabel(benefitPlanEnrollmentSet: BenefitPlanEnrollmentSetModel) {
    if (benefitPlanEnrollmentSet.lifeChangingEventID == 7)
      return benefitPlanEnrollmentSet.lifeChangingEventOther;
    if (benefitPlanEnrollmentSet.lifeChangingEventID == 2)
      return 'New Hire';
    if (benefitPlanEnrollmentSet.lifeChangingEventID == 1)
      return 'Open Enrollment';
    return  this.lifeEventOptions.find(x => x.value === benefitPlanEnrollmentSet.lifeChangingEventID)?.label ;
  }
  

    
  benefitPlanEnrollmentSaved(value: BenefitPlanEnrollmentSetModel) {

    console.info('benefitPlanEnrollmentSaved', value);

    this.benefitPlanEnrollmentSet = value;

    this.editClicked = !this.benefitPlanEnrollmentSet?.activatedDate && !!this.benefitPlanEnrollmentSet?.createdDate;
    this.editClickedChange.emit(this.editClicked);



    //this.store.dispatch(loadProfile());
    this.store.dispatch(getBenefitsFormDocumentsList({ userId: null }));
    if (value.generateDocs) {
      this.router.navigate(['/benefits-enrollment/docs/list'], { replaceUrl: true });
    }
  }
  
}

