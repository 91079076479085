import { createAction, props } from "@ngrx/store";
import { PagingResultsModel } from "../../../models/pagingResultsModel";
import { PaylocityTransmissionModel } from "../../../models/paylocityTranmissionModel";

export const getPaylocityTransmissions = createAction('[Paylocity] Load Paylocity Transmissions',
  props < { filter: any }>());
export const getPaylocityTransmissionsSuccess = createAction('[Paylocity] Load Paylocity Transmissions Success',
  props<{transmissions: PagingResultsModel<PaylocityTransmissionModel>}>());


export const dismissTransmission = createAction('[Paylocity] Dismiss Transmission',
  props<{ transmission: PaylocityTransmissionModel }>());
export const dismissTransmissionSuccess = createAction('[Paylocity] Dismission Tranmission Success');
