import { getOnboardingDoc, updateOnboardingDoc, updateOnBoardingDocForm, updateOnboardingDocSuccess } from './../../../../../core/store/onboardingdocs/onboardingdocs.actions';
import { OnBoardingDocumentModelWithRoles } from './../../../../../models/onBoardingDocumentUserModel';
import { initOnBoardingDocEditForm } from './../../../../../core/store/onboardingdocs/onboardingdocs.reducer';
import { UserTypeModel } from './../../../../../models/userProfileModel';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../../core/store';
import { FormGroup } from '@angular/forms';
import { markFormGroupTouched, getAllControlsErrors, updateFormGroupValidity } from 'src/app/helpers/utils';
import { BaseComponent } from 'src/app/core/abstracts/baseComponent';
import { loadUserTypes } from 'src/app/core/store/profile/profile.actions';
import { SelectItem } from 'primeng/api';
import { MultiSelect } from 'primeng/multiselect';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-edit-onboardingdoc',
  templateUrl: './edit-onboardingdoc.component.html',
  styleUrls: ['./edit-onboardingdoc.component.scss']
})
export class EditOnboardingDocComponent extends BaseComponent implements OnInit {

  onBoardingDoc: OnBoardingDocumentModelWithRoles;

  userTypes: SelectItem[] = [{ label: 'loading...', value: undefined }];
  form: FormGroup;

  constructor(
    private actions$: Actions,
    private route: ActivatedRoute,
    private store: Store<State>,
    private router: Router) {
    super();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params.onboardingDocId) {
        const onboardingDocId = parseInt(params.onboardingDocId, 0);
        this.store.dispatch(getOnboardingDoc({ id: onboardingDocId }));
      }
    });
    this.store.dispatch(loadUserTypes());
    this.subs.push(
      this.store.select(s => s.profileState.userTypes).subscribe(userTypes => {
        if (userTypes) {
          this.userTypes = userTypes.map(x => ({ label: x.userType, value: x.id }));
        }
      }),
      this.store.select(s => s.onBoardingDocsState.onBoardingDoc).subscribe(onBoardingDoc => {
        this.onBoardingDoc = onBoardingDoc;
        if (onBoardingDoc) {
          this.form = initOnBoardingDocEditForm(this.onBoardingDoc);
          this.subs.push(
            this.form.valueChanges.subscribe(formValues => {
              const onBoardingDocumentValues = this.form.get('onBoardingDocument').value;
              const rolesValues = this.form.value;
              const formErrors = getAllControlsErrors(this.form);
              this.store.dispatch(updateOnBoardingDocForm({ onBoardingDocumentValues, rolesValues, formErrors }));
            }));
        }
      }),
      this.actions$.pipe(
        ofType(updateOnboardingDocSuccess),
        tap(action => {
          this.router.navigate(['/manage/list-onboardingdocs/']);
        })
      ).subscribe(),
    );
  }

  save() {
    updateFormGroupValidity(this.form);
    if (this.form.valid) {
      this.store.dispatch(updateOnboardingDoc({ doc: this.onBoardingDoc }));
    } else {
      markFormGroupTouched(this.form);
    }
  }


  openMultiSelect(multiselect: MultiSelect) {
    multiselect.show();
  }

}
