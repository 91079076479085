<p-dialog [(visible)]="display" header="RBT Ethic Code" [modal]="true" [style]="{width: '70vw'}" (onHide)="closeModal()"
          [focusOnShow]="false">
  <form class="card mt-2" (ngSubmit)="saveEthicCode()">
    <div class="card-body">
      <div class="row">
        <div class="col-12" *ngIf="ethicCode">

          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Ethics Code Section:</b>
            </div>
            <div class="col-8">
              <input type="text" placeholder="x.x" class="form-control mt-2" [(ngModel)]="ethicCode.section" maxlength="150" [ngModelOptions]="{standalone: true}"/>
            </div>
          </div>

          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <b>Description:</b>
            </div>
            <div class="col-8">
              <textarea rows="3" type="date" class="form-control mt-2" [(ngModel)]="ethicCode.description" maxlength="4000" [ngModelOptions]="{standalone: true}"></textarea>
            </div>
          </div>

        </div>
      </div>
    </div>
  </form>
  <p-footer>
    <button class="btn btn-secondary" type="button" (click)="closeModal()">Cancel</button>
    <button class="btn btn-primary" type="submit" (click)="saveEthicCode()" [disabled]="savingEthicCode">Save</button>
    <i *ngIf="!!savingEthicCode" class="material-icons rotate text-muted">loop</i>
  </p-footer>
</p-dialog>
