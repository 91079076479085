import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, tap, catchError, filter } from 'rxjs/operators';
import { of, pipe } from 'rxjs';
import { errorHappened } from '../profile/profile.actions';
import { Store, Action } from '@ngrx/store';
import { State } from '..';
import { AlertService } from 'src/app/shared/services/alert.service';
import { downloadFile } from 'src/app/helpers/utils';
import { FormBuilderService } from '../../services/formBuilder.service';
import { deleteFBForm, deleteFBFormFail, deleteFBFormSuccess, getAvailableForms, 
  getAvailableFormsFail, getAvailableFormsSuccess, getFBForm, getFBFormFail, getFBFormSuccess, 
  getFBFormTable, getFBFormTableFail, getFBFormTableSuccess, getFormFieldTypesList, getFormFieldTypesListFail,
  getFormFieldTypesListSuccess, getFormTypesList, getFormTypesListFail, getFormTypesListSuccess, saveFBForm, saveFBFormFail, 
  saveFBFormSubmissionForm, saveFBFormSuccess, saveFBFormSubmissionFormSuccess, saveFBFormSubmissionFormFail, getFBFormSubmission, 
  getFBFormSubmissionSuccess, getFBFormSubmissionFail, finishFBFormSubmissionForm, finishFBFormSubmissionFormSuccess, finishFBFormSubmissionFormFail, 
  loadFormSubmissionDocToSign, loadFormSubmissionDocToSignSuccess, loadFormSubmissionDocToSignFail, signFormSubmissionDoc, signFormSubmissionDocSuccess, 
  signFormSubmissionDocFail, getFBFormAssignmentTable, getFBFormAssignmentTableSuccess, getFBFormAssignmentTableFail, addFBFormAssignmentSuccess, 
  addFBFormAssignmentFail, addFBFormAssignment, getAllForms, getAllFormsSuccess, getAllFormsFail, deleteFBFormAssignment, deleteFBFormAssignmentSuccess,
  deleteFBFormAssignmentFail, loadFormDoc, loadFormDocSuccess, loadFormDocFail, getFBFormRoleAssignment, getFBFormRoleAssignmentSuccess, getFBFormRoleAssignmentFail, 
  updateFBFormRoleAssignment, updateFBFormRoleAssignmentSuccess, updateFBFormRoleAssignmentFail, getInquiryList, getInquiryListSuccess, getInquiryListFail, 
  getMappingColumnsList, getMappingColumnsListSuccess, getMappingColumnsListFail, getAllUsersWithForms, getAllUsersWithFormsSuccess, getAllUsersWithFormsFail,
  getUsersWithFormAssignmentTable, getUsersWithFormAssignmentTableSuccess, getUsersWithFormAssignmentTableFail, getFormsFilter, getFormsFilterFail, getFormsFilterSuccess,
  toggleFormAssignmentArchived, toggleFormAssignmentArchivedSuccess, toggleFormAssignmentArchivedFail,
  setFormAssignmentCanOptout,
  setFormAssignmentCanOptoutFail,
  setFormAssignmentCanOptoutSuccess,
  duplicateFBForm,
  duplicateFBFormFail,
  duplicateFBFormSuccess,
  getFBFormJobTitleAssignment,
  getFBFormJobTitleAssignmentSuccess,
  getFBFormJobTitleAssignmentFail,
  updateFBFormJobTitleAssignment,
  updateFBFormJobTitleAssignmentSuccess,
  updateFBFormJobTitleAssignmentFail,
  optOutFormAssignment,
  optOutFormAssignmentSuccess,
  optOutFormAssignmentFail
} from './form-builder.actions';


@Injectable()
export class FormBuilderEffects {
  constructor(
    private actions$: Actions,
    private formBuilderService: FormBuilderService,
    private store: Store<State>,
    private alertService: AlertService,
    private router: Router) {

  }

  saveFBForm = createEffect(() => this.actions$.pipe(
    ofType(saveFBForm),
    switchMap(action => {
      return this.formBuilderService.saveForm(action.form).pipe(
        map(form => saveFBFormSuccess({ form })),
        catchError(err => {
          this.store.dispatch(saveFBFormFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));

  saveFBFormSuccess = createEffect(() => this.actions$.pipe(
    ofType(saveFBFormSuccess),
    tap(action => {
    })
  ), { dispatch: false });

  getFBFormTable = createEffect(() => this.actions$.pipe(
    ofType(getFBFormTable),
    switchMap(action => {
      return this.formBuilderService.getFormsDatatable(action.filter).pipe(
        map(forms => getFBFormTableSuccess({ forms })),
        catchError(err => {
          this.store.dispatch(getFBFormTableFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));

  deleteFBForm = createEffect(() => this.actions$.pipe(
    ofType(deleteFBForm),
    switchMap(action => {
      return this.formBuilderService.deleteForm(action.formID).pipe(
        map(form => deleteFBFormSuccess({ form })),
        catchError(err => {
          this.store.dispatch(deleteFBFormFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));



  getFBForm = createEffect(() => this.actions$.pipe(
    ofType(getFBForm),
    switchMap(action => {
      return this.formBuilderService.getForm(action.formID).pipe(
        map(form => getFBFormSuccess({ form })),
        catchError(err => {
          this.store.dispatch(getFBFormFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));




  getFormTypesList = createEffect(() => this.actions$.pipe(
    ofType(getFormTypesList),
    switchMap(action => {
      return this.formBuilderService.getFormTypes().pipe(
        map(formTypesList => getFormTypesListSuccess({ formTypesList })),
        catchError(err => {
          this.store.dispatch(getFormTypesListFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));



  getFormFieldTypesList = createEffect(() => this.actions$.pipe(
    ofType(getFormFieldTypesList),
    switchMap(action => {
      return this.formBuilderService.getFormFieldTypes().pipe(
        map(formFieldTypesList => getFormFieldTypesListSuccess({ formFieldTypesList })),
        catchError(err => {
          this.store.dispatch(getFormFieldTypesListFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));

  getMappingColumnsList = createEffect(() => this.actions$.pipe(
    ofType(getMappingColumnsList),
    switchMap(action => {
      return this.formBuilderService.getMappingColumns().pipe(
        map(mappingColumnsList => getMappingColumnsListSuccess({ mappingColumnsList })),
        catchError(err => {
          this.store.dispatch(getMappingColumnsListFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));


  getAvailableForms = createEffect(() => this.actions$.pipe(
    ofType(getAvailableForms),
    switchMap(action => {
      return this.formBuilderService.getAvailableForms(action.userID, action.includeArchived).pipe(
        map(availableForms => getAvailableFormsSuccess({ availableForms })),
        catchError(err => {
          this.store.dispatch(getAvailableFormsFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));


  saveFBFormSubmissionForm = createEffect(() => this.actions$.pipe(
    ofType(saveFBFormSubmissionForm),
    switchMap(action => {
      return this.formBuilderService.saveFormSubmissionForm(action.formSubmission).pipe(
        map(formSubmission => saveFBFormSubmissionFormSuccess({ formSubmission })),
        catchError(err => {
          this.store.dispatch(saveFBFormSubmissionFormFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));


  getFBFormSubmission = createEffect(() => this.actions$.pipe(
    ofType(getFBFormSubmission),
    switchMap(action => {
      return this.formBuilderService.getFormSubmission(action.formID, action.formSubmissionID, action.jobApplicationID, action.prospectiveCandidateID).pipe(
        map(formSubmission => getFBFormSubmissionSuccess({ formSubmission })),
        catchError(err => {
          this.store.dispatch(getFBFormSubmissionFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));



  finishFBFormSubmissionForm = createEffect(() => this.actions$.pipe(
    ofType(finishFBFormSubmissionForm),
    switchMap(action => {
      return this.formBuilderService.finishFormSubmissionForm(action.formSubmission).pipe(
        map(formSubmission => finishFBFormSubmissionFormSuccess({ formSubmission })),
        catchError(err => {
          this.store.dispatch(finishFBFormSubmissionFormFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));


  loadFormSubmissionDocToSign = createEffect(() => this.actions$.pipe(
    ofType(loadFormSubmissionDocToSign),
    switchMap(action => {
      return this.formBuilderService.getFormSubmissionDocToSign(action.formSubmissionID).pipe(
        map(doc => loadFormSubmissionDocToSignSuccess({ doc })),
        catchError(err => {
          this.store.dispatch(loadFormSubmissionDocToSignFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));


  signFormSubmissionDoc = createEffect(() => this.actions$.pipe(
    ofType(signFormSubmissionDoc),
    switchMap(action => {
      return this.formBuilderService.signFormSubmissionDoc(action.formSubmissionID, action.signedFields).pipe(
        map(docSigned => signFormSubmissionDocSuccess({ docSigned })),
        catchError(err => {
          this.store.dispatch(signFormSubmissionDocFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));


  getAllForms = createEffect(() => this.actions$.pipe(
    ofType(getAllForms),
    switchMap(action => {
      return this.formBuilderService.getAllForms().pipe(
        map(forms => getAllFormsSuccess({ forms })),
        catchError(err => {
          this.store.dispatch(getAllFormsFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));

  getFormsFilter = createEffect(() => this.actions$.pipe(
    ofType(getFormsFilter),
    switchMap(action => {
      return this.formBuilderService.getFormsFilter().pipe(
        map(forms => getFormsFilterSuccess({ forms })),
        catchError(err => {
          this.store.dispatch(getFormsFilterFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));

  getAllUsersWithForms = createEffect(() => this.actions$.pipe(
    ofType(getAllUsersWithForms),
    switchMap(action => {
      return this.formBuilderService.getAllUsersWithForms(action.tab).pipe(
        map(forms => getAllUsersWithFormsSuccess({ forms })),
        catchError(err => {
          this.store.dispatch(getAllUsersWithFormsFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));

  getFBFormAssignmentTable = createEffect(() => this.actions$.pipe(
    ofType(getFBFormAssignmentTable),
    switchMap(action => {
      return this.formBuilderService.getFormsAssignmentDatatable(action.filter).pipe(
        map(formsAssignment => getFBFormAssignmentTableSuccess({ formsAssignment })),
        catchError(err => {
          this.store.dispatch(getFBFormAssignmentTableFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));

  getUsersWithFormAssignmentTable = createEffect(() => this.actions$.pipe(
    ofType(getUsersWithFormAssignmentTable),
    switchMap(action => {
      return this.formBuilderService.getUsersWithFormAssignmentTable(action.filter).pipe(
        map(formsAssignment => getUsersWithFormAssignmentTableSuccess({ formsAssignment })),
        catchError(err => {
          this.store.dispatch(getUsersWithFormAssignmentTableFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));

  addFBFormAssignment = createEffect(() => this.actions$.pipe(
    ofType(addFBFormAssignment),
    switchMap(action => {
      return this.formBuilderService.addFormAssignment(action.formAssignment).pipe(
        map(formAssignment => addFBFormAssignmentSuccess({ formAssignment })),
        catchError(err => {
          this.store.dispatch(addFBFormAssignmentFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));

  setFormAssignmentCanOptout= createEffect(() => this.actions$.pipe(
    ofType(setFormAssignmentCanOptout),
    switchMap(action => {
      return this.formBuilderService.setFormAssignmentCanOptout(action.formAssignmentID,action.canOptOut).pipe(
        map(formAssignment => setFormAssignmentCanOptoutSuccess({ formAssignment })),
        catchError(err => {
          this.store.dispatch(setFormAssignmentCanOptoutFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));


  deleteFBFormAssignment = createEffect(() => this.actions$.pipe(
    ofType(deleteFBFormAssignment),
    switchMap(action => {
      return this.formBuilderService.deleteFBFormAssignment(action.formAssignmentID).pipe(
        map(result => deleteFBFormAssignmentSuccess()),
        catchError(err => {
          this.store.dispatch(deleteFBFormAssignmentFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));
  
  duplicateFBForm = createEffect(() => this.actions$.pipe(
    ofType(duplicateFBForm),
    switchMap(action => {
      return this.formBuilderService.duplicateFBForm(action.formID).pipe(
        map(result => duplicateFBFormSuccess({form:result})),
        catchError(err => {
          this.store.dispatch(duplicateFBFormFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));


  loadFormDoc = createEffect(() => this.actions$.pipe(
    ofType(loadFormDoc),
    switchMap(action => {
      return this.formBuilderService.loadFormDoc(action.formSubmissionID).pipe(
        map(doc => loadFormDocSuccess({ doc })),
        catchError(err => {
          this.store.dispatch(loadFormDocFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));



  getFBFormRoleAssignment = createEffect(() => this.actions$.pipe(
    ofType(getFBFormRoleAssignment),
    switchMap(action => {
      return this.formBuilderService.getFormsRolesAssignment(action.formID).pipe(
        map(formRoleAssignmentModel => getFBFormRoleAssignmentSuccess({ formRoleAssignmentModel })),
        catchError(err => {
          this.store.dispatch(getFBFormRoleAssignmentFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));

  updateFBFormRoleAssignment = createEffect(() => this.actions$.pipe(
    ofType(updateFBFormRoleAssignment),
    switchMap(action => {
      return this.formBuilderService.updateFormsRolesAssignment(action.formRoleAssignmentModel).pipe(
        map(formRoleAssignmentModel => updateFBFormRoleAssignmentSuccess({ formRoleAssignmentModel })),
        catchError(err => {
          this.store.dispatch(updateFBFormRoleAssignmentFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));


  getFBFormJobTitleAssignment = createEffect(() => this.actions$.pipe(
    ofType(getFBFormJobTitleAssignment),
    switchMap(action => {
      return this.formBuilderService.getFormsJobTitlesAssignment(action.formID).pipe(
        map(formJobTitleAssignmentModel => getFBFormJobTitleAssignmentSuccess({ formJobTitleAssignmentModel })),
        catchError(err => {
          this.store.dispatch(getFBFormJobTitleAssignmentFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));

  updateFBFormJobTitleAssignment = createEffect(() => this.actions$.pipe(
    ofType(updateFBFormJobTitleAssignment),
    switchMap(action => {
      return this.formBuilderService.updateFormsJobTitlesAssignment(action.formJobTitleAssignmentModel).pipe(
        map(formJobTitleAssignmentModel => updateFBFormJobTitleAssignmentSuccess({ formJobTitleAssignmentModel })),
        catchError(err => {
          this.store.dispatch(updateFBFormJobTitleAssignmentFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));

  getInquiryList = createEffect(() => this.actions$.pipe(
    ofType(getInquiryList),
    switchMap(action => {
      return this.formBuilderService.getInquiryList().pipe(
        map(inquiryList => getInquiryListSuccess({ inquiryList })),
        catchError(err => {
          this.store.dispatch(getInquiryListFail({ err }));
          return of(errorHappened({ err }));
        }));
    })
  ));

  toggleFormAssignmentArchived = createEffect(() => this.actions$.pipe(
    ofType(toggleFormAssignmentArchived),
    switchMap(action => {
      return this.formBuilderService.toggleFormAssignmentArchived(action.assignmentId).pipe(
        map(assignment => toggleFormAssignmentArchivedSuccess()),
        catchError(err => {
          this.store.dispatch(toggleFormAssignmentArchivedFail(err));
          return of(errorHappened({ err }));
        }));
    })
  ));

  optOutFormAssignment = createEffect(() => this.actions$.pipe(
    ofType(optOutFormAssignment),
    switchMap(action => {
      return this.formBuilderService.optOutFormAssignment(action.assignmentId).pipe(
        map(assignment => optOutFormAssignmentSuccess()),
        catchError(err => {
          this.store.dispatch(optOutFormAssignmentFail(err));
          return of(errorHappened({ err }));
        }));
    })
  ));
}
