<p-dialog [(visible)]="display" header="Ethics Codes" [modal]="true" [style]="{width: '70vw'}" (onHide)="closeModal()"
          [focusOnShow]="false">

  <form *ngIf="kpi" class="card mt-2" >

    <div class="row m-2 p-0" *ngFor="let ec of availableEthicsCodes">

      <div class="col-md-2 col-sm-4">
        <select [(ngModel)]="ec.showOnReport" class="form-control " (ngModelChange)="onchangeitem(ec, $event)" [ngModelOptions]="{standalone: true}">
          <option value="0">Do not show</option>
          <option value="1">Show Normal</option>
          <option value="2">Show Highlighted</option>
        </select>
      </div>
      <strong class="col-md-2 col-sm-4">RBT Ethic Code {{ec.section}}</strong>
      <pre class="col-md-8 col-sm-4">{{ec.description}}</pre>

    </div>

    <div class="col-12" *ngIf="ethicCode">
      <hr />
      <h4>New Ethic Code</h4>
      <div class="row">
        <div class="col-4 d-flex align-items-center">
          <strong>Ethics Code Section:</strong>
        </div>
        <div class="col-8">
          <input type="text" placeholder="x.x" class="form-control mt-2" [(ngModel)]="ethicCode.section" maxlength="150" [ngModelOptions]="{standalone: true}" />
        </div>
      </div>

      <div class="row">
        <div class="col-4 d-flex align-items-center">
          <b>Description:</b>
        </div>
        <div class="col-8">
          <textarea rows="3" type="date" class="form-control mt-2" [(ngModel)]="ethicCode.description" maxlength="4000" [ngModelOptions]="{standalone: true}"></textarea>
        </div>
      </div>
      <button class="btn btn-success" type="submit" (click)="saveEthicCode()" [disabled]="savingEthicCode">Add Ethic Code</button>
      <i *ngIf="!!savingEthicCode" class="material-icons rotate text-muted">loop</i>
    </div>
  </form>
  <p-footer>
    <button class="btn btn-secondary" type="button" (click)="closeModal()">Cancel</button>
    <button class="btn btn-primary" type="submit" (click)="done()" [disabled]="loading">Done</button>
  </p-footer>
</p-dialog>
