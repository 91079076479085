<div class="card mb-2">
  <div class="card-body">
    <div class="" *ngIf="prospectiveCandidate">

      <button  class="btn btn-secondary float-right pointer" (click)="back()">Back</button>
      <h2 class="">Employee References - {{prospectiveCandidate.firstName}} {{prospectiveCandidate.lastName}}</h2>
      <div class="row mt-3">
        <div class="col-12 col-lg-6">
          <div class="card">
            <div class="card-body">
              <h4>References </h4>

                <p-table class="mt-2"  [value]="references" [responsive]="true" >
                  <ng-template pTemplate="header">
                      <tr>
                          <th [pSortableColumn]="'firstName'">Reference Name <p-sortIcon [field]="'firstName'"></p-sortIcon></th>
                          <th [pSortableColumn]="'email'">Email <p-sortIcon [field]="'email'"></p-sortIcon></th>
                          <th width="120px">Answered</th>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-reference>
                      <tr >
                          <td>
                              <span class="ui-column-title">Reference Name</span>
                              {{reference.firstName}} {{reference.lastName}}
                          </td>
                          <td>
                              <span class="ui-column-title">Email</span>
                            {{reference.email}}
                          </td>
                          <td>
                              <span class="ui-column-title">Answer</span>
                              <i class="fas fa-minus-circle text-muted" *ngIf="!reference.answerDate" title="No answer"></i>
                              <i class="fas fa-check-circle text-success pointer" *ngIf="reference.answerDate" [routerLink]="'/prospective-candidate/survey'" [queryParams]="{id: reference.id, isFromEmployee:true}" [queryParamsHandling]="'merge'" title="See answer"></i>
                          </td>
                      </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
