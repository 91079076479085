<form class="card mt-2">
  <div class="card-body">
      <h2 class="mb-3">Survey Report</h2>
      <div class="row mb-3">
        <div class="col-2 d-flex align-items-center">
            <h5>Date range:</h5>
        </div>
        <div class="col-10 col-lg-5 ">
          <p-calendar inputStyleClass="form-control mt-2" [monthNavigator]="true" [yearNavigator]="true"
          styleClass="w-100" panelStyleClass="calendarPanel" [hourFormat]="12" [showTime]="false"
          id="dateRange" dateFormat="yy-mm-dd" name="dateRange" dataType="string" selectionMode="range" [hideOnDateTimeSelect]="true" [(ngModel)]="dateRange" (ngModelChange)="changedFilter()" ></p-calendar>
        </div>
      </div>
      <div class="row ">
        <div class="col-2 d-flex align-items-center">
            <h5> Profit Sharing tier:</h5>
        </div>
        <div class="col-10 col-lg-5 ">
        </div>
      </div>

      <div class="row ">
        <div class="col-2 d-flex align-items-center">
            <h5> Min:</h5>
        </div>
        <div class="col-10 col-lg-5 ">
          <input type="number" class="form-control mt-2" [(ngModel)]="minTier" [ngModelOptions]="{standalone: true}" min="0.01" [max]="midTier-0.01" step="0.01"/>
        </div>
      </div>

      <div class="row ">
        <div class="col-2 d-flex align-items-center">
            <h5> Mid:</h5>
        </div>
        <div class="col-10 col-lg-5 ">
          <input type="number" class="form-control mt-2" [(ngModel)]="midTier" [ngModelOptions]="{standalone: true}" [min]="minTier+0.01" [max]="maxTier-0.01" step="0.01"/>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-2 d-flex align-items-center">
            <h5> Max:</h5>
        </div>
        <div class="col-10 col-lg-5 ">
          <input type="number" class="form-control mt-2" [(ngModel)]="maxTier" [ngModelOptions]="{standalone: true}" [min]="midTier+0.01" max="5" step="0.01"/>
        </div>
      </div>

    <i *ngIf="loadingSurveyReport" class="material-icons rotate text-muted">loop</i>
    <p-table  [value]="surveyReport" [responsive]="true"
    [paginator]="true" [rows]="20" [totalRecords]="surveyReport.length" [lazy]="false"
   >
        <ng-template pTemplate="header">
            <tr>
                <th width="200px"  [pSortableColumn]="'SurveyTypeName'">Survey name <p-sortIcon [field]="'SurveyTypeName'"></p-sortIcon></th>
                <th  [pSortableColumn]="'MentorName'">Mentor name <p-sortIcon [field]="'MentorName'"></p-sortIcon></th>
                <th width="120px"  [pSortableColumn]="'ProfitSharing'">Profit Sharing Tier <p-sortIcon [field]="'ProfitSharing'"></p-sortIcon></th>
                <th width="120px"  [pSortableColumn]="'Average'">Average <p-sortIcon [field]="'Average'"></p-sortIcon></th>
                <th width="130px"  [pSortableColumn]="'Total'">Total Surveys<p-sortIcon [field]="'Total'"></p-sortIcon></th>
                <th width="130px"  [pSortableColumn]="'TotalAnswered'">Total Answered <p-sortIcon [field]="'TotalAnswered'"></p-sortIcon></th>


            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-survey>
            <tr >
                <td>
                    <span class="ui-column-title">Survey name</span>
                  <a href="javascript:void(0)" (click)="loadDetail(survey)">  {{survey.surveyTypeName}}</a>
                </td>

                <td>
                  <span class="ui-column-title">Mentor name</span>
                  <a href="javascript:void(0)" (click)="loadDetail(survey)"> {{survey.mentorName}}</a>
                </td>

                <td>
                  <span class="ui-column-title">Profit Sharing Tier</span>
                  {{getTier(survey.average)}}
                </td>
                <td>
                  <span class="ui-column-title">Average</span>
                  {{survey.average}}
                </td>

                <td>
                  <span class="ui-column-title">Total</span>
                  {{survey.total}}
                </td>

                <td>
                  <span class="ui-column-title">Total Answered</span>
                  {{survey.totalAnswered}}
                </td>
            </tr>
        </ng-template>
    </p-table>
  </div>
</form>
